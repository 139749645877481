import React, { useContext, useEffect, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import {
  UpdateProduct,
  InspectionList,
  Inspection,
  InspectionDetailView
} from './views';

import {
  Client,
  ConstancyProduct,
  ConstancyProductIndicators,
  DictumProduct,
  RevisionProduct,
  RevisionProductIndicators,
  Inspection as TypeInspection
} from './interfaces';
import {
  emptyClient,
  emptyConstancyProduct,
  emptyDictumProduct,
  emptyRevisionProduct
} from './emptyObjects';
import Modal from './components/Modal';
import { useResource } from './hooks';
import { AppContext } from './context/AppContext';
import { utils } from './helpers';

export const InspectionRoutes = () => {
  const inspectionResource = useResource<TypeInspection>();
  const dictumProductResource = useResource<DictumProduct>();
  const constancyProductResource = useResource<ConstancyProduct>();
  const revisionProductResource = useResource<RevisionProduct>();

  const {
    apiType,
    setOpenModal,
    inspection,
    setInspection,
    soundEffects,
    informationReceivedFromWebsocket
  } = useContext(AppContext);

  const {
    fetchResource
  } = useResource<Client>();

  const [lookInTheTrash, setLookInTheTrash] = useState<string>('');
  const [showProductInformation, setShowProductInformation] = useState<boolean>(false);
  const [inspectionID, setInspectionID] = useState<string>('');
  const [fetchInspection, setFetchInspection] = useState<boolean | null>(null);
  const [reFetchInspection, setReFetchInspection] = useState<boolean>(false);
  const [dictumProduct, setDictumProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [constancyProduct, setConstancyProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [revisionProduct, setRevisionProduct] = useState<RevisionProduct>(emptyRevisionProduct);
  const [client, setClient] = useState<Client>(emptyClient);

  const onFetchInspection = (value?: TypeInspection) => {
    if (value) {
      setInspection(value);
    }

    setReFetchInspection(!reFetchInspection);
  };

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const getClient = (clientID: string) => {
    if (clientID && clientID !== 'none') {
      fetchResource(
        `/admin/clients/${clientID}`,
        (data) => {
          setClient(data);
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  };

  useEffect(() => {
    if (
      inspectionID.length > 0
      && (fetchInspection === true ? true : inspection.id === '')
    ) {
      inspectionResource.fetchResource(
        `/${apiType}/inspections/${inspectionID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash] || ''}`,
        (data) => {
          setInspection(data);

          if (dictumProduct.id === '' || constancyProduct.id === '' || revisionProduct.id === '') {
            if (['DC', 'DN'].includes(data.service.code)) {
              dictumProductResource.fetchResource(
                `/${apiType}/dictum-products/${data.product.id}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash] || ''}`,
                (productData) => {
                  setDictumProduct(productData);

                  setConstancyProduct(emptyConstancyProduct);
                  setRevisionProduct(emptyRevisionProduct);

                  getClient(data.client.id);
                },
                (error) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            } else if (['CC', 'CN'].includes(data.service.code)) {
              constancyProductResource.fetchResource(
                `/${apiType}/constancy-products/${data.product.id}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash] || ''}`,
                (productData) => {
                  setConstancyProduct(productData);

                  setDictumProduct(emptyDictumProduct);
                  setRevisionProduct(emptyRevisionProduct);

                  getClient(data.client.id);
                },
                (error) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            } else if (['REV'].includes(data.service.code)) {
              revisionProductResource.fetchResource(
                `/${apiType}/revision-products/${data.product.id}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash] || ''}`,
                (productData) => {
                  setRevisionProduct(productData);

                  setDictumProduct(emptyDictumProduct);
                  setConstancyProduct(emptyConstancyProduct);

                  getClient(data.client.id);
                },
                (error) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            }
          }
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [inspectionID, reFetchInspection, lookInTheTrash, apiType, fetchInspection]);

  useEffect(() => {
    if ((inspection
      && inspection.rounds
      && utils.getCurrentRound(inspection.rounds) !== undefined
      && utils.getCurrentRound(inspection.rounds).steps !== undefined)
      || (informationReceivedFromWebsocket.action === 're-fetch-comments'
        && informationReceivedFromWebsocket.inspectionID === inspectionID
      )) {
      if (inspection.id !== '' && utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'product-updated') === undefined && inspectionID.length > 0) {
        inspectionResource.fetchResource(
          `/${apiType}/inspections/${inspectionID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash] || ''}`,
          (data) => {
            setInspection(data);
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  }, [apiType, informationReceivedFromWebsocket]);

  const setProductIndicators = ():
    ConstancyProductIndicators
    | RevisionProductIndicators
    | undefined => {
    if (['CC', 'CN', 'REV'].includes(inspection.service.code)) {
      return constancyProduct.id !== '' ? constancyProduct.indicators : revisionProduct.indicators;
    }

    return undefined;
  };

  const setNOM003Complement = (): boolean => {
    if (['CC', 'CN'].includes(inspection.service.code)) {
      return constancyProduct.indicators.NOM003Complement;
    }
    if (['REV'].includes(inspection.service.code)) {
      return revisionProduct.indicators.NOM003Complement;
    }
    if (['DC', 'DN'].includes(inspection.service.code)) {
      return dictumProduct.indicators.NOM003Complement;
    }

    return false;
  };

  return (
    <Routes>
      <Route element={
        <Inspection
          showProductInformation={showProductInformation}
          constancyProduct={constancyProduct}
          dictumProduct={dictumProduct}
          revisionProduct={revisionProduct}
        />
      }>
        <Route path='detail/:inspectionID/:lookInTheTrash/:section/:fetchInspection' element={
          <InspectionDetailView
            setShowProductInformation={setShowProductInformation}
            inspectionID={inspectionID}
            setInspectionID={setInspectionID}
            dictumProduct={dictumProduct}
            constancyProduct={constancyProduct}
            revisionProduct={revisionProduct}
            setLookInTheTrash={setLookInTheTrash}
            onFetchInspection={onFetchInspection}
            setFetchInspection={setFetchInspection}
          />
        } />
        {
          apiType === 'admin' && (
            <>
              <Route path='update-product/:inspectionID' element={
                <UpdateProduct
                  setInspectionID={setInspectionID}
                  setShowProductInformation={setShowProductInformation}
                  constancyProduct={constancyProduct}
                  setConstancyProduct={setConstancyProduct}
                  dictumProduct={dictumProduct}
                  setDictumProduct={setDictumProduct}
                  revisionProduct={revisionProduct}
                  setRevisionProduct={setRevisionProduct}
                />
              } />
              <Route path='inspection-list/:inspectionID/:roundID' element={
                <InspectionList
                  setInspectionID={setInspectionID}
                  setShowProductInformation={setShowProductInformation}
                  onFetchInspection={onFetchInspection}
                  labelsToPut={dictumProduct.labelsToPut}
                  isGrouped={dictumProduct.indicators.isGrouped}
                  productIndicators={setProductIndicators()}
                  client={client}
                  NOM003Complement={setNOM003Complement()}
                />
              } />
            </>
          )
        }
      </Route>
    </Routes>
  );
};

export default InspectionRoutes;
