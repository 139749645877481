import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  ActionButtons,
  Button,
  Filters,
  Header,
  Modal,
  Table
} from '../../components';

import {
  User,
  TableOrderDirection,
  FilterTypes,
  ActionButton
} from '../../interfaces';

import './styles.scss';
import UserForm from '../UserDetail/components/UserForm';
import { AppContext } from '../../context/AppContext';

type UserFilters = 'name' | 'email' | 'role' | 'approver' | 'none';

const UsersList = () => {
  const {
    fetchResources
  } = useResource<User>();

  const navigate = useNavigate();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<UserFilters>('none');
  const [filterValue, setFilterValue] = useState<string>('none');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderDirection, setOrderDirection] = useState<TableOrderDirection>('DESC');
  const [users, setUsers] = useState<User[]>([]);
  const [userFormVisible, setUserFormVisible] = useState<boolean>(false);
  const [buttons, setButtons] = useState<ActionButton[]>([]);

  const handleTriggerFilter = (filter: FilterTypes, value: string) => {
    setFilterBy((filter as UserFilters));
    setFilterValue(value);
  };

  useEffect(() => {
    const request: any = {
      resourcePath: 'admin/users',
      filters: {
        s_name: filterBy === 'name' ? filterValue : '',
        s_email: filterBy === 'email' ? filterValue : '',
        s_role: filterBy === 'role' ? filterValue : '',
        b_approver: filterBy === 'approver' ? filterValue : ''
      },
      pagination: {
        n_currentPage: currentPage,
        n_perPage: 10,
        n_totalItems: 10
      },
      ordering: {
        s_orderBy: orderBy,
        s_orderDirection: orderDirection
      }
    };

    fetchResources(
      request,
      (data) => {
        setUsers(data.items);
        setCurrentPage(data.pagination.currentPage);
        setTotalItems(data.pagination.totalItems);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, [
    refetch,
    orderBy,
    orderDirection,
    currentPage,
    filterValue
  ]);

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              setUserFormVisible(true);
            }}
            type='primary-outlined'
            label={t('users.createUser') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='plusWhite'
            alignContent='left'
          />
        ),
        available: ['master'].includes(userRole),
        specialValidation: ['master'].includes(userRole)
      }
    ]);
  }, [userRole, i18n.language]);

  return (
    <div className="users-list">
      <Header
        title={t('users.title') || ''}
        showBackbutton={false}
      />
      <UserForm
        title={t('users.createUserForm') || ''}
        visible={userFormVisible}
        onClose={() => {
          setUserFormVisible(false);
        }}
        onSuccess={() => {
          setUserFormVisible(false);

          Modal.fireSuccess(
            t('global.correct'),
            t('users.createUserForm'),
            setOpenModal,
            () => {
              setUserFormVisible(false);
              setRefetch(!refetch);
            },
            soundEffects
          );
        }}
        onError={(error: string) => {
          setUserFormVisible(false);
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <div className="users-list__main">
        <div className="users-list__main__left-container">
          <Accordeon
            items={[
              {
                title: t('global.filer'),
                element: (
                  <Filters
                    options={[
                      {
                        text: t('global.name'),
                        value: 'name'
                      },
                      {
                        text: t('global.role'),
                        value: 'role'
                      },
                      {
                        text: t('users.approver'),
                        value: 'approver'
                      },
                      {
                        text: t('global.email'),
                        value: 'email'
                      }
                    ]}
                    onTriggerFilter={handleTriggerFilter}
                    disableSearchOnPressKey={userFormVisible}
                  />
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="users-list__main__right-container">
          <Table
            headers={[
              {
                label: t('global.name'),
                value: 'name',
                format: 'none'
              },
              {
                label: t('global.role'),
                value: 'role',
                format: 'role'
              },
              {
                label: t('users.approver'),
                value: 'approver',
                format: 'boolean'
              },
              {
                label: t('global.email'),
                value: 'email',
                format: 'none'
              },
              {
                label: t('global.active'),
                value: 'isActive',
                format: 'boolean'
              },
              {
                label: t('global.createdAt'),
                value: 'createdAt',
                format: 'date-time'
              }
            ]}
            items={users}
            currentPage={currentPage}
            perPage={10}
            totalItems={totalItems}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onChangeCurrentPage={(newCurrentPage: number) => {
              setCurrentPage(newCurrentPage);
            }}
            onChangeOrder={(newOrderBy: string, newOrderDirection: TableOrderDirection) => {
              setOrderBy(newOrderBy);
              setOrderDirection(newOrderDirection);
            }}
            onClickRow={(user: User) => {
              navigate(`/user/detail/${user.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersList;
