import React from 'react';
import { useTranslation } from 'react-i18next';

import DataDisplay from '../../../../components/DataDisplay';
import { RevisionProduct } from '../../../../interfaces';

interface Props {
  product: RevisionProduct
  revisionServiceLookInTheTrash: boolean
  inspectionLookInTheTrash: boolean
}

const RevisionProductDetailGeneralInfo: React.FC<Props> = (props) => {
  const {
    product,
    revisionServiceLookInTheTrash,
    inspectionLookInTheTrash
  } = props;

  const { t } = useTranslation();

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={product.normCode === '050' ? [
        {
          label: t('global.status.title'),
          value: product.status,
          format: 'status'
        },
        {
          label: t('global.client'),
          value: product.client.businessName,
          format: 'none',
          navigateRoute: `/client/detail/${product.client.id}`,
          onlyAdmin: true
        },
        {
          label: t('global.invoice'),
          value: product.invoice,
          navigateRoute: `/revision-products/detail/${product.id}/none/false`,
          format: 'none'
        },
        {
          label: t('global.collaborator'),
          value: product.collaborator.name,
          format: 'none',
          onlyAdmin: true
        },
        {
          label: t('global.request'),
          value: product.service.number,
          format: 'none',
          navigateRoute: `/revision-services/detail/${product.service.id}/${revisionServiceLookInTheTrash ? 'true' : 'none'}`
        },
        {
          label: 'Inspector',
          value: product.inspector?.name || '',
          format: 'none',
          navigateRoute: product.inspector ? `/user/detail/${product.inspector?.id}` : '',
          onlyAdmin: true
        },
        {
          label: t('inspections.round'),
          value: product.round || '',
          format: 'none'
        },
        {
          label: t('tasks.inspection'),
          value: product.inspection?.number || '',
          format: 'none',
          navigateRoute: product.inspection ? `/inspection/detail/${product.inspection?.id}/${inspectionLookInTheTrash ? 'true' : 'none'}/none/none` : ''
        },
        {
          label: t('global.norm'),
          value: product.normCode,
          format: 'none'
        },
        {
          label: t('global.complement003'),
          value: product.indicators.NOM003Complement,
          format: 'boolean'
        },
        {
          label: 'Fase',
          value: product.fase,
          format: 'none'
        },
        {
          label: t('services.codeInput'),
          value: product.code,
          format: 'none'
        },
        {
          label: t('global.brand'),
          value: product.brand,
          format: 'none'
        },
        {
          label: t('global.presentation'),
          value: product.presentation,
          format: 'none'
        },
        {
          label: t('global.description'),
          value: product.description,
          format: 'none'
        },
        {
          label: t('global.design'),
          value: product.indicators.design,
          format: 'boolean'
        },
        {
          label: t('global.urgent'),
          value: product.indicators.urgent,
          format: 'boolean'
        },
        {
          label: t('global.createdAtTitle'),
          value: product.createdAt,
          format: 'date-time'
        },
        {
          label: t('global.expirationDate'),
          value: product.createdAt,
          format: 'constancy-expired-date'
        }
      ] : [
        {
          label: t('global.status.title'),
          value: product.status,
          format: 'status'
        },
        {
          label: t('global.client'),
          value: product.client.businessName,
          format: 'none',
          navigateRoute: `/client/detail/${product.client.id}`,
          onlyAdmin: true
        },
        {
          label: t('global.invoice'),
          value: product.invoice,
          navigateRoute: `/revision-products/detail/${product.id}/none/false`,
          format: 'none'
        },
        {
          label: t('global.collaborator'),
          value: product.collaborator.name,
          format: 'none',
          onlyAdmin: true
        },
        {
          label: t('global.request'),
          value: product.service.number,
          format: 'none',
          navigateRoute: `/revision-services/detail/${product.service.id}/${revisionServiceLookInTheTrash ? 'true' : 'none'}`
        },
        {
          label: 'Inspector',
          value: product.inspector?.name || '',
          format: 'none',
          navigateRoute: product.inspector ? `/user/detail/${product.inspector?.id}` : '',
          onlyAdmin: true
        },
        {
          label: t('inspections.round'),
          value: product.round || '',
          format: 'none'
        },
        {
          label: t('tasks.inspection'),
          value: product.inspection?.number || '',
          format: 'none',
          navigateRoute: product.inspection ? `/inspection/detail/${product.inspection?.id}/${inspectionLookInTheTrash ? 'true' : 'none'}/none/none` : ''
        },
        {
          label: t('global.norm'),
          value: product.normCode,
          format: 'none'
        },
        {
          label: 'Fase',
          value: product.fase,
          format: 'none'
        },
        {
          label: t('services.codeInput'),
          value: product.code,
          format: 'none'
        },
        {
          label: t('global.brand'),
          value: product.brand,
          format: 'none'
        },
        {
          label: t('global.presentation'),
          value: product.presentation,
          format: 'none'
        },
        {
          label: t('global.description'),
          value: product.description,
          format: 'none'
        },
        {
          label: t('global.design'),
          value: product.indicators.design,
          format: 'boolean'
        },
        {
          label: t('global.urgent'),
          value: product.indicators.urgent,
          format: 'boolean'
        },
        {
          label: t('global.createdAtTitle'),
          value: product.createdAt,
          format: 'date-time'
        },
        {
          label: t('global.expirationDate'),
          value: product.createdAt,
          format: 'constancy-expired-date'
        }
      ]}
    />
  );
};

export default RevisionProductDetailGeneralInfo;
