/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import JSZip from 'jszip';

import { saveAs } from 'file-saver';

import File from '../../../../components/File';

import '../../styles.scss';
import { Modal, Title } from '../../../../components';
import { RevisionService, Inspection, InspectionStatus } from '../../../../interfaces';
import { useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  revision: RevisionService
  revisionServiceID: string
  serviceNumber: string
  clientShortName: string
}

const DictumServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    revisionServiceID,
    serviceNumber,
    clientShortName,
    revision
  } = props;

  const {
    fetchResource,
    fetchResources
  } = useResource<any>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    apiType,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [
    allProductsAndSomeInspectionsAreConcluded,
    setallProductsAndSomeInspectionsAreConcluded
  ] = useState<boolean>(true);
  const [inspectionsStatus, setInspectionsStatus] = useState<InspectionStatus[]>([]);

  const generateRevisionDocuments = async () => {
    fetchResource(
      `/${apiType}/revision-services/${revisionServiceID}/bulk/revision-of-inspection`,
      (data) => {
        Modal.fireLoading();
        const keys = Object.keys(data.files);

        const zip = new JSZip();

        const bytes: any[] = [];
        const blob: Blob[] = [];

        for (let i = 0; i < keys.length; i += 1) {
          bytes[i] = new Uint8Array(data.files[keys[i]].data);
          blob[i] = new Blob([bytes[i]], { type: 'application/pdf' });
          zip.file(`${keys[i]}-Constancia.pdf`, blob[i]);
        }

        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, `Constancias_${serviceNumber}.zip`);
        });

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  const handleGenerateServiceRequestDocument = async () => {
    fetchResource(
      `/${apiType}/documents/service-request/REV/${revisionServiceID}`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${serviceNumber} - SOLICITUD DE SERVICIOS - ${clientShortName}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  useEffect(() => {
    fetchResources(
      {
        resourcePath: `/${apiType}/revision-products`,
        filters: {
          s_serviceID: revisionServiceID
        }
      },
      (data) => {
        const products = data.items;

        if (products.every((item) => item.status === 'concluded')) {
          for (let index = 0; index < products.length; index += 1) {
            const inspectionID = products[index].inspection?.id;

            if (inspectionID) {
              getInspection(
                `/${apiType}/inspections/${inspectionID}`,
                (inspection) => {
                  setInspectionsStatus([...inspectionsStatus, inspection.status]);
                },
                (error: string) => Modal.fireError(error, undefined, soundEffects)
              );
            }
          }
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [revisionServiceID]);

  useEffect(() => {
    setallProductsAndSomeInspectionsAreConcluded(!(inspectionsStatus.some((item) => item === 'concluded')));
  }, [inspectionsStatus]);

  return (
    <div className="revision-service-detail__documents" id='data-section'>
      <Title title={t('services.documents')} type="primary" />
      <br />
      <div>
        <Title title={t('services.documentsCECSA') || ''} type="secondary" />
        <div className="revision-service-detail__documents__container">
          <File
            name={'Solicitud de servicio'}
            type={'service'}
            label={'Solicitud de servicio'}

            disabled={revision.indicators.requestIsCompleted === false}
            onDownload={handleGenerateServiceRequestDocument}
            orientation='horizontal'
            showToolTip={false}
          />
          {/* <File
            name='Descargar Constancias de Cumplimiento.zip'
            onDownload={generateRevisionDocuments}
            disabled={allProductsAndSomeInspectionsAreConcluded}
            orientation='horizontal'
            showToolTip={false}
          /> */}
        </div>
      </div>
      <br />
    </div>
  );
};

export default DictumServiceDetailDocuments;
