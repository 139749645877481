import React, { useContext, useEffect, useState } from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  Separator,
  DictumProductForm,
  Switch
} from '../../../../../../components';
import { AppContext } from '../../../../../../context/AppContext';
import { emptyDictumProduct } from '../../../../../../emptyObjects';
import { useResource } from '../../../../../../hooks';
import { DictumProduct, DictumProductWithCodes } from '../../../../../../interfaces';
import CSVForm from './components';
import './styles.scss';

interface Props {
  clientID: string
  collaboratorID: string
  dictumServiceID: string
  preloadedProduct?: DictumProduct
  onChangeCurrentComponent: (_value: 'product-list') => void
}

export const ProductForm: React.FC<Props> = (props) => {
  const {
    createResource,
    updateResource
  } = useResource<DictumProduct>();

  const {
    dictumServiceID,
    clientID,
    collaboratorID,
    preloadedProduct = emptyDictumProduct,
    onChangeCurrentComponent
  } = props;

  const { t } = useTranslation();

  const [currentForm, setCurrentForm] = useState<'manual-form' | 'csv-form' | 'none'>('manual-form');

  const {
    apiType,
    setOpenModal,
    soundEffects,
    userToursDone,
    userRole,
    showDictumRequestTour
  } = useContext(AppContext);

  const onChangeInput = (value: string | number) => {
    setCurrentForm(`${value as 'manual-form' | 'csv-form' | 'none'}`);
  };

  const buildBody = (products: DictumProductWithCodes[]) => {
    if (apiType === 'admin') {
      return {
        clientID,
        collaboratorID,
        dictumServiceID,
        products
      };
    }

    return {
      dictumServiceID,
      products
    };
  };

  const handleCreateSingleProduct = (_product: DictumProductWithCodes) => {
    createResource(
      `/${apiType}/dictum-products`,
      buildBody([_product]),
      () => {
        Modal.fireSuccess(
          t('global.correct'),
          t('services.registeredProduct'),
          setOpenModal,
          () => onChangeCurrentComponent('product-list'),
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const handleCreateManyProducts = (_products: DictumProductWithCodes[]) => {
    createResource(
      `/${apiType}/dictum-products`,
      buildBody(_products),
      () => {
        Modal.fireSuccess(
          t('global.correct'),
          t('services.registeredProducts'),
          setOpenModal,
          () => onChangeCurrentComponent('product-list'),
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const handleUpdateProduct = (_product: DictumProduct) => {
    updateResource(
      `/${apiType}/dictum-products/${_product.id}`,
      _product,
      () => {
        Modal.fireSuccess(
          t('global.correct'),
          t('services.updatedProduct'),
          setOpenModal,
          () => onChangeCurrentComponent('product-list'),
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const formType = {
    'manual-form': (
      <DictumProductForm
        preloadedProduct={preloadedProduct}
        onCreateProduct={handleCreateSingleProduct}
        onUpdateProduct={handleUpdateProduct}
      />
    ),
    'csv-form': (
      <CSVForm
        onCreateProducts={handleCreateManyProducts}
      />
    ),
    none: (<p>{t('services.creationproductMethod')}</p>)
  };

  const driverObj = driver({
    showProgress: false,
    allowClose: false,
    smoothScroll: true,
    disableActiveInteraction: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#create-products-options', popover: { title: t('tours.waysToCreateProducts') || '', description: t('tours.waysToCreateProductsDescription') || '' } }
    ]
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('dictum-request') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }

    if (showDictumRequestTour) {
      driverObj.drive();
    }
  }, [userToursDone, showDictumRequestTour]);

  return (
    <div className='product-form'>
      <div className='product-form__top-container' id='create-products-options'>
        <Switch
          leftLabel={{
            text: t('services.creationproductMethodForm'),
            value: 'manual-form'
          }}
          rigthLabel={{
            text: t('services.creationproductMethodTemplate'),
            value: 'csv-form'
          }}
          onChange={onChangeInput}
        />
        <br />
        <Separator orientation='horizontal' />
        <br />
      </div>
      {formType[currentForm]}
      <br />
      <Button
        onClick={() => onChangeCurrentComponent('product-list')}
        type='primary'
        label={t('global.list') || ''}
        icon='leftArrow'
        iconPosition='left'
      />
    </div>
  );
};

export default ProductForm;
