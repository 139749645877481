import React, { ReactElement, useEffect, useId } from 'react';

import '../styles.scss';

interface Props {
  type: 'sunday' | 'today' | 'disabled' | 'normal'
  number: number
  setAlertSquares: (_day: string) => ReactElement[]
  setCurrentDate: (_date: Date) => void
  selectedDate: boolean
  month: number
  year: number
}

const DayComponent: React.FC<Props> = (props) => {
  const {
    type,
    number,
    setAlertSquares,
    setCurrentDate,
    selectedDate,
    month,
    year
  } = props;

  const id = useId();

  useEffect(() => {
    if (type === 'today') {
      setCurrentDate(new Date(`${year}/${month}/${number}`));
    }
  }, []);

  const day = {
    today: (
      <div
        key={id}
        onClick={() => setCurrentDate(new Date(`${year}/${month}/${number}`))}
        className='root-calendar__content__left__calendar__item'
      >
        <div className="root-calendar__content__left__calendar__item__today">
          {number}
        </div>
      </div>
    ),
    normal: (
      <div
        onClick={() => setCurrentDate(new Date(`${year}/${month}/${number}`))}
        className={`root-calendar__content__left__calendar__item
        ${selectedDate ? 'root-calendar__content__left__calendar__item--current-day' : ''}`}
      >
        <p key={id} className="root-calendar__content__left__calendar__item__number">{number}</p>
        <div className="root-calendar__content__left__calendar__item__alert-container">
          {setAlertSquares(String(number))}
        </div>
      </div>
    ),
    sunday: (
      <div
        key={id}
        onClick={() => setCurrentDate(new Date(`${year}/${month}/${number}`))}
        className={`root-calendar__content__left__calendar__item root-calendar__content__left__calendar__item--red
        ${selectedDate ? 'root-calendar__content__left__calendar__item--current-day' : ''}`}
      >
        <p className="root-calendar__content__left__calendar__item__number">{number}</p>
        <div className="root-calendar__content__left__calendar__item__alert-container">
          {setAlertSquares(String(number))}
        </div>
      </div>
    ),
    disabled: (
      <div
        key={id}
        onClick={() => setCurrentDate(new Date(`${year}/${month}/${number}`))}
        className='root-calendar__content__left__calendar__item root-calendar__content__left__calendar__item--disable'
      >
        <p className="root-calendar__content__left__calendar__item__number">{number}</p>
      </div>
    )
  };

  return day[type];
};

export default DayComponent;
