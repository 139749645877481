import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { RevisionServiceStatus } from '../../../../interfaces';

interface Props {
  status: RevisionServiceStatus
}

const RevisionServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const revisionServiceStatuses: RevisionServiceStatus[] = ['inspecting-products', 'concluded'];

  const translatedRevisionServiceStatuses: string[] = [];

  revisionServiceStatuses.forEach((element: RevisionServiceStatus) => {
    translatedRevisionServiceStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="revision-service-detail__progress-indicator" id='data-section'>
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedRevisionServiceStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default RevisionServiceDetailDocuments;
