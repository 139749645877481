import React from 'react';

import { Icon } from '..';

interface Props {
  label: string
  checked: boolean
  orientation?: 'vertical' | 'horizontal'
  onChange: () => void
  disabled?: boolean
}

const CheckBox: React.FC<Props> = (props) => {
  const {
    label = '',
    checked = false,
    orientation = 'vertical',
    onChange,
    disabled = false
  } = props;

  const onClick = () => {
    if (disabled === true) {
      return;
    }

    onChange();
  };

  return (
    <div className={`check-list__item check-list__item--${orientation}`}>
      <div
        className={`check-list__box check-list__box${checked ? '--checked' : ''} check-list__box${disabled ? '--disabled' : ''}`}
        onClick={onClick}
      >
        {
          checked && (
            <Icon type='checkMarkWhite' className='check-list__box__icon' />
          )
        }
      </div>
      <span className="check-list__label">{label}</span>
    </div>
  );
};

export default CheckBox;
