import React, { useRef, useEffect, useId } from 'react';

interface Props {
  pdfUrl: string
  data?: any
  documentName?: string
}

const PDFViewer: React.FC<Props> = (props) => {
  const pdfRef = useRef<HTMLObjectElement | null>(null);

  useEffect(() => {
    if (pdfRef.current) {
      pdfRef.current.data = props.pdfUrl;
    }
  }, [props.pdfUrl]);

  return (
    <object
      aria-label="PDF Viewer"
      ref={pdfRef}
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center'
      }}
      type="application/pdf"
      key={useId()}
    >
      <p>
        Tu navegador no soporta la visualización de archivos PDF.
        {
          props.data && props.documentName && (
            <>
              <br />Puedes descargar el archivo{' '}
              <a href={`${URL.createObjectURL(props.data)}`} download={props.documentName}>aquí</a>.
            </>
          )
        }
      </p>
    </object>
  );
};

export default PDFViewer;
