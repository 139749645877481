import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import {
  ModalView,
  Modal,
  Input,
  Separator
} from '../../../../components';

import {
  DictumService
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  visible: boolean
  dictumServiceID: string
  onOutOfCustomsDateAssigned: (_service: DictumService) => void
  onClose: () => void
  serviceCreatedAt: Date
}

export const AssignOutOfCustomsDate: React.FC<Props> = (props) => {
  const {
    visible,
    dictumServiceID,
    onOutOfCustomsDateAssigned = (_service: DictumService) => { },
    onClose,
    serviceCreatedAt
  } = props;

  const { t } = useTranslation();

  const { updateResource } = useResource<DictumService>();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [outOfCustomsDate, setOutOfCustomsDate] = useState<string>('');

  const validData = (): boolean => {
    if (outOfCustomsDate.length === 0) {
      setErrors({
        outOfCustomsDate: t('services.errors.emptyCustomsClearanceDate')
      });
      return false;
    }

    if (
      Date.parse(dayjs(outOfCustomsDate).format('YYYY-MM-DD'))
      > Date.parse(dayjs(new Date()).format('YYYY-MM-DD'))
    ) {
      setErrors({
        outOfCustomsDate: t('services.errors.customsClearanceDate')
      });
      return false;
    }
    if (
      Date.parse(dayjs(serviceCreatedAt).format('YYYY-MM-DD'))
      > Date.parse(dayjs(outOfCustomsDate).format('YYYY-MM-DD'))
    ) {
      setErrors({
        outOfCustomsDate: t('services.errors.customsClearanceDate2')
      });
      return false;
    }

    return true;
  };
  const onConfirmDate = () => {
    if (validData()) {
      setErrors({});
      updateResource(
        `/${apiType}/dictum-services/${dictumServiceID}`,
        { outOfCustomsDate },
        (data: DictumService) => {
          onOutOfCustomsDateAssigned((data));
          onClose();
        },
        (error: string) => {
          onClose();
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  useEffect(() => {
    setOutOfCustomsDate('');
    setErrors({});
  }, [visible]);

  useEffect(() => {
    if ((dayjs(outOfCustomsDate).add(30, 'days').diff(new Date(), 'day') + 1) <= 0) {
      setErrors({
        outOfCustomsDate: t('services.errors.customsClearanceDate3')
      });
    } else {
      setErrors({});
    }
  }, [outOfCustomsDate]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={onConfirmDate}
      customComponent={
        <div className='dictum-service-detail__assing-out-of-customs-date'>
          {
            (dayjs(outOfCustomsDate).add(30, 'days').diff(new Date(), 'day') + 1) <= 0 && (
              <span className='dictum-service-detail__assing-out-of-customs-date__error'>
                {t('services.errors.customsClearanceDateAlert', { outOfCustomsDatePlus30: `${dayjs(outOfCustomsDate).add(30, 'days').format('DD-MMM-YYYY')}` })}
                <Separator orientation='horizontal' />
              </span>
            )
          }
          <Input
            title={t('services.customsClearanceDate') || ''}
            type="date"
            value={String(outOfCustomsDate)}
            onChange={(_value: string | number, _id: string) => {
              setOutOfCustomsDate(String(_value));
            }}
            toolTipPosition="left"
            hasError={!!errors.outOfCustomsDate}
            helperText={errors.outOfCustomsDate || t('services.customsClearanceDateExplication') || ''}
            maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
            minDate={dayjs(serviceCreatedAt).format('YYYY-MM-DD')}
          />
        </div>
      }
      fullSzie={false}
    />
  );
};

export default AssignOutOfCustomsDate;
