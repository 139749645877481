/* eslint-disable no-console */
/* eslint-disable max-len */
import dayjs from 'dayjs';

import { Link, useParams } from 'react-router-dom';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Header,
  Icon,
  Input,
  Modal,
  PDFViewer,
  Spinner,
  SubTitle
} from '../../components';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { useNavigate, useResource } from '../../hooks';
import {
  Client,
  ConstancyProduct,
  ConstancyProductIndicators,
  DictumProduct,
  Inspection,
  MonthlyClosing,
  RevisionProduct,
  RevisionProductIndicators,
  SelectedInspection,
  User
} from '../../interfaces';
import {
  emptyClient,
  emptyConstancyProduct,
  emptyDictumProduct,
  emptyMonthlyClosing,
  emptyRevisionProduct,
  emptySelectedInspection,
  emptyUser
} from '../../emptyObjects';
import { utils } from '../../helpers';
import monthsEs from '../../dictionaries/months-es';
import monthsEn from '../../dictionaries/months-en';
import { ListForm } from '../../components/InspectionDetail/components';

const MonthlyClosingsDetail = () => {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const {
    monthlyClosingID,
    inspectionID
  } = params;

  const {
    setOpenModal,
    soundEffects,
    userRole,
    setInspection,
    inspection
  } = useContext(AppContext);

  const dictumProductResource = useResource<DictumProduct>();
  const constancyProductResource = useResource<ConstancyProduct>();
  const revisionProductResource = useResource<RevisionProduct>();

  const {
    fetchResource,
    createResource
  } = useResource<MonthlyClosing>();

  const inspectionResource = useResource<Inspection>();

  const {
    fetchResource: getClient
  } = useResource<Client>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    fetchResource: getDictumProductDocument
  } = useResource<any>();

  const [user, setUser] = useState<User>(emptyUser);
  const [client, setClient] = useState<Client>(emptyClient);
  const [monthlyClosing, setMonthlyClosing] = useState<MonthlyClosing>(emptyMonthlyClosing);
  const [selectedInspection, setSelectedInspection] = useState<SelectedInspection>(emptySelectedInspection);
  const [comments, setComments] = useState<string>('');
  const [pdfBlob, setPdfBlob] = useState<any>(null);
  const [dictumProduct, setDictumProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [constancyProduct, setConstancyProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [revisionProduct, setRevisionProduct] = useState<RevisionProduct>(emptyRevisionProduct);
  const [isYouOwnWork, setIsYouOwnWork] = useState<boolean>(false);
  const [generatedCertificate, setGeneratedCertificate] = useState<boolean>(false);
  const [certificateIsLoading, setCertificateIsLoading] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const setproductIndicators = (): ConstancyProductIndicators | RevisionProductIndicators | undefined => {
    if (['CC', 'CN', 'REV'].includes(selectedInspection.serviceCode)) {
      return selectedInspection.serviceCode.startsWith('REV') ? revisionProduct.indicators : constancyProduct.indicators;
    }

    return undefined;
  };

  const validateForm = (approved: boolean) => {
    if (comments === '' && approved === false) {
      setErrors({
        comments: t('global.commentsValidate')
      });

      return false;
    }

    return true;
  };

  const reviewMonthlyClosing = (approved: boolean) => {
    if (validateForm(approved)) {
      Modal.fire(
        'warning',
        t('global.alert'),
        t('global.reviewMonthlyClosing', { result: approved ? t('global.accepted') : t('global.rejected') }),
        () => {
          createResource(
            `/admin/monthly-closings/${monthlyClosing.id}/${inspectionID}/review`,
            {
              approved,
              comments
            },
            (data) => {
              setMonthlyClosing(data);
              Modal.fireSuccess(
                t('global.correct'),
                t('global.successReviewMonthlyClosing'),
                setOpenModal,
                () => {
                  navigate(`/monthly-closings/${monthlyClosingID}`);
                }
              );
            },
            (error: string) => {
              Modal.fireError(error, setOpenModal, soundEffects);
            }
          );
        },
        undefined,
        undefined,
        undefined,
        setOpenModal
      );
    }
  };

  const getMonthlyClosing = () => {
    fetchResource(
      `admin/monthly-closings/${monthlyClosingID}`,
      (data) => {
        setMonthlyClosing(data);

        setSelectedInspection(data.inspections.find((item) => item.id === inspectionID) || emptySelectedInspection);

        inspectionResource.fetchResource(
          `/admin/inspections/${inspectionID}`,
          (dataFetch) => {
            setInspection(dataFetch);
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleShowCertificate = async () => {
    if (selectedInspection.product.id) {
      setTimeout(() => {
        getDictumProductDocument(
          ['DC', 'DN'].includes(selectedInspection.serviceCode) ? `/admin/dictum-products/${selectedInspection.product.id}/dictum-of-inspection` : `/admin/constancy-products/${selectedInspection.product.id}/constancy-of-inspection`,
          (data) => {
            Modal.fireLoading(setOpenModal);

            const bytes = new Uint8Array(data.file.data);

            const blob = new Blob([bytes], { type: 'application/pdf' });

            setPdfBlob(blob);

            Modal.close(setOpenModal);
            setGeneratedCertificate(true);
            setCertificateIsLoading(false);
          },
          (error) => {
            setGeneratedCertificate(false);
            setCertificateIsLoading(false);
            // eslint-disable-next-line no-console
            console.log(error);
          }
        );
      }, 300);
    }
  };

  useEffect(() => {
    getMonthlyClosing();
  }, [monthlyClosingID, inspectionID]);

  useEffect(() => {
    handleShowCertificate();

    if ((dictumProduct.id === '' || constancyProduct.id === '' || revisionProduct.id === '') && selectedInspection.product.id !== '' && selectedInspection.product.id) {
      if (['DC', 'DN'].includes(selectedInspection.serviceCode)) {
        dictumProductResource.fetchResource(
          `/admin/dictum-products/${selectedInspection.product.id}`,
          (productData) => {
            setDictumProduct(productData);

            setConstancyProduct(emptyConstancyProduct);
            setRevisionProduct(emptyRevisionProduct);
          },
          (error) => console.log(error)
        );
      } else if (['CC', 'CN'].includes(selectedInspection.serviceCode)) {
        constancyProductResource.fetchResource(
          `/admin/constancy-products/${selectedInspection.product.id}`,
          (productData) => {
            setConstancyProduct(productData);

            setDictumProduct(emptyDictumProduct);
            setRevisionProduct(emptyRevisionProduct);
          },
          (error) => console.log(error)
        );
      } else if (['REV'].includes(selectedInspection.serviceCode)) {
        revisionProductResource.fetchResource(
          `/admin/revision-products/${selectedInspection.product.id}`,
          (productData) => {
            setRevisionProduct(productData);

            setDictumProduct(emptyDictumProduct);
            setConstancyProduct(emptyConstancyProduct);
          },
          (error) => console.log(error)
        );
      }
    }
  }, [inspection, selectedInspection.serviceCode]);

  useEffect(() => {
    if (userRole !== 'collaborator') {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);

          if (data.id === selectedInspection.approver?.id
            || data.id === selectedInspection.inspector?.id
          ) {
            setIsYouOwnWork(true);
          }
        },
        (error: string) => console.log(error)
      );
    }

    if (selectedInspection.client.id !== 'none') {
      getClient(
        `/admin/clients/${selectedInspection.client.id}`,
        (data) => {
          setClient(data);
        },
        (error) => console.log(error)
      );
    }
  }, [userRole, selectedInspection.client.id]);

  const setCompliesLabel = () => {
    if (selectedInspection.complies === true) {
      return t('global.accepted');
    }
    if (selectedInspection.complies === false) {
      return t('global.rejected');
    }
    return t('global.undefined');
  };

  const setCompliesIcon = () => {
    if (selectedInspection.complies === false) {
      return 'circleError';
    }
    if (selectedInspection.complies === true) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const setNOM003Complement = useCallback((): boolean => {
    if (['CC', 'CN'].includes(inspection.service.code) && constancyProduct.indicators.NOM003Complement) {
      return constancyProduct.indicators.NOM003Complement;
    }
    if (['REV'].includes(inspection.service.code) && revisionProduct.indicators.NOM003Complement) {
      return revisionProduct.indicators.NOM003Complement;
    }
    if (['DC', 'DN'].includes(inspection.service.code) && dictumProduct.indicators.NOM003Complement) {
      return dictumProduct.indicators.NOM003Complement;
    }

    return false;
  }, [
    constancyProduct,
    revisionProduct,
    dictumProduct
  ]);

  return (
    <div className="monthly-closings">
      <Header
        title={t('global.monthlyClosing', {
          month: i18n.language === 'es' ? (monthsEs as any)[dayjs(monthlyClosing.beginningPeriod).format('MMMM').toUpperCase()] : (monthsEn as any)[dayjs(monthlyClosing.beginningPeriod).format('MMMM').toUpperCase()],
          year: dayjs(monthlyClosing.beginningPeriod).year(),
          invoice: `${t('global.invoice')}: ${inspection.product.invoice}`,
          status: ''
        }) || ''}
        showBackbutton={true}
        onGoBack={() => navigate(`/monthly-closings/${monthlyClosingID}`)}
      />
      <div className="monthly-closings__main">
        {
          isYouOwnWork === true && (
            <p><SubTitle subTitle={t('global.ownWorkAlert')} red={true} /></p>
          )
        }
        <div className="monthly-closings__main__header-info">
          <span><b>{t('global.norm')}:</b> {selectedInspection.norm}</span>
          <span><b>{t('global.client')}:</b> <Link to={`/client/detail/${selectedInspection.client.id}`}>{selectedInspection.client.shortName}</Link></span>
          <span><b>{t('global.invoice')}:</b> <Link to={['DC', 'DN'].includes(selectedInspection.serviceCode) ? `/dictum-products/detail/${selectedInspection.product.id}/false` : `/constancy-products/detail/${selectedInspection.product.id}/false/false`}>{selectedInspection.product.invoice}</Link></span>
          <span><b>{t('global.subInvoice')}:</b> {selectedInspection.product.subInvoice}</span>
          <span><b>{t('global.inspectionDate')}:</b> {selectedInspection.inspectionDate ? dayjs(selectedInspection.inspectionDate).format('DD/MM/YYYY') : t('global.undefined')}</span>
          <span><b>{t('services.inspectorName')}:</b> {selectedInspection.inspector?.name}</span>
          <span><b>{t('inspections.round')}:</b> {selectedInspection.roundID}</span>
          <span><b>{t('global.approver')}:</b> {selectedInspection.approver?.name}</span>
          <span><b>{t('global.inspectionType')}:</b> {['DC', 'DN'].includes(selectedInspection.serviceCode) ? t('services.onSite') : t('global.remoteInspection')}</span>
          <span><b>{t('global.result')}:</b> {setCompliesLabel()} <Icon type={setCompliesIcon()} className='task-detail__alert-status__icon' /></span>
        </div>
        <div className="monthly-closings__main__documents">
          <div className="monthly-closings__main__documents__document">
            <SubTitle subTitle={t('global.inspectionList')} />
            {
              inspection.id !== '' && inspection.listCompleted ? (
                <ListForm
                  disabled={true}
                  roundID={utils.getCurrentRound(inspection.rounds).id || 1}
                  labelsToPut={['DC', 'DN'].includes(inspection.service.code) ? dictumProduct.labelsToPut : 0}
                  isGrouped={dictumProduct.indicators.isGrouped}
                  productIndicators={setproductIndicators()}
                  user={user}
                  client={client}
                  showNavigateButtons={false}
                  NOM003Complement={setNOM003Complement()}
                />
              ) : (
                <SubTitle subTitle={t(('global.notAbaible'))} />
              )
            }
          </div>
          <div className="monthly-closings__main__documents__document">
            <SubTitle subTitle={t('global.certificate')} />
            {
              generatedCertificate ? (
                <>
                  {
                    pdfBlob && generatedCertificate ? (
                      <>
                        <PDFViewer
                          pdfUrl={`${URL.createObjectURL(pdfBlob)}#toolbar=0`}
                        />
                      </>
                    ) : (
                      <div className='loading-page'>
                        <Spinner />
                      </div>
                    )
                  }
                </>
              ) : (
                <>
                  {
                    certificateIsLoading ? (
                      <Spinner />
                    ) : (
                      <SubTitle subTitle={t(('global.notAbaible'))} />
                    )
                  }
                </>
              )
            }
          </div>
        </div>
        {
          selectedInspection.complies === null
            && isYouOwnWork === false
            && ['in-progress', 'available'].includes(monthlyClosing.status)
            ? (
              <>
                <Input
                  type='textarea'
                  value={comments}
                  onChange={(value) => setComments(`${value}`)}
                  placeholder={t('global.comments') || ''}
                  disabled={selectedInspection.complies !== null}
                  maxLength={1000}
                  hasError={!!errors.comments}
                  helperText={errors.comments}
                />
                <div className="monthly-closings__main__buttons">
                  <Button
                    type={'primary'}
                    onClick={() => reviewMonthlyClosing(true)}
                    label={t('global.accepted') || ''}
                    icon='like'
                    size='big'
                    disabled={selectedInspection.complies !== null}
                  />
                  <Button
                    type={'red'}
                    onClick={() => reviewMonthlyClosing(false)}
                    label={t('global.rejected') || ''}
                    icon='dislike'
                    size='big'
                    disabled={selectedInspection.complies !== null}
                  />
                </div>
              </>
            ) : (
              <Input
                type='textarea'
                value={selectedInspection.comments || ''}
                onChange={() => { }}
                placeholder={t('global.comments') || ''}
                disabled={true}
                maxLength={1000}
              />
            )
        }
        <div className="monthly-closings__main__button-back">
          <Button
            type={'secondary'}
            onClick={() => navigate(`/monthly-closings/${monthlyClosingID}`)}
            label={t('global.goBack') || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyClosingsDetail;
