export default {
  master: 'Master',
  operator: 'Operador',
  inspector: 'Inspector',
  inspectorApprover: 'Inspector - Aprobador',
  collaborator: 'Colaborador',
  trainee: 'Becario',
  quality: 'Calidad',
  finance: 'Finanzas',
  'sys-admin': 'Sysadmin'
};
