import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataDisplay from '../../../../components/DataDisplay';
import { emptyRevisionService } from '../../../../emptyObjects';
import { RevisionService, DataDisplayItem } from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  revision: RevisionService
}

const RevisionServiceDetailGeneralInfo: React.FC<Props> = (props) => {
  const {
    revision = emptyRevisionService
  } = props;

  const {
    userRole
  } = useContext(AppContext);

  const { t } = useTranslation();

  const dataItems: DataDisplayItem[] = [
    {
      label: t('services.serviceNumber'),
      value: revision.requestNumber,
      format: 'none'
    },
    {
      label: t('global.status.title'),
      value: revision.status,
      format: 'status'
    },
    {
      label: t('global.client'),
      value: revision.client.businessName,
      format: 'none',
      navigateRoute: `/client/detail/${revision.client.id}`,
      onlyAdmin: true
    },
    {
      label: t('global.collaborator'),
      value: revision.collaborator.name,
      format: 'none'
    },
    {
      label: t('global.proposedValidationDate'),
      value: revision.proposedValidationDate || '',
      format: 'date'
    },
    {
      label: t('global.creationDate'),
      value: revision.createdAt,
      format: 'date-time'
    },
    {
      label: t('global.expirationDate'),
      value: revision.createdAt,
      format: 'constancy-expired-date'
    },
    {
      label: t('services.requestIsCompleted'),
      value: revision.indicators.requestIsCompleted,
      format: 'boolean'
    },
    {
      label: t('global.urgent'),
      value: revision.indicators.urgent,
      format: 'boolean'
    }
  ];

  const getItems = (): DataDisplayItem[] => {
    if (['finance', 'master'].includes(userRole)) {
      dataItems.push(
        {
          label: t('global.invoiced'),
          value: revision.indicators.invoiced,
          format: 'boolean'
        },
        {
          label: t('global.invoiceNumber'),
          value: revision.indicators.invoicedInfo.invoiceNumber,
          format: 'none'
        },
        {
          label: t('global.dateBilling'),
          value: revision.indicators.invoicedInfo.date,
          format: 'date'
        },
        {
          label: 'UUID',
          value: revision.indicators.invoicedInfo.UUID,
          format: 'none'
        }
      );
      return dataItems;
    }

    return dataItems;
  };

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={getItems()}
    />
  );
};

export default RevisionServiceDetailGeneralInfo;
