import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  ModalInspectionDetail,
  ModalComponentProps
} from './ModalInspectionDetail';

// eslint-disable-next-line no-undef
const container = document.getElementById('modal');

const modal = createRoot(container!);

class Modal {
  fire(
    type: 'custom'
  ) {
    this.render({
      type,
      visible: true,
      onCancel: this.close
    });
  }

  close() {
    modal.render(
      React.createElement(ModalInspectionDetail, {
        type: 'custom',
        visible: false,
        onCancel: () => {}
      })
    );
  }

  private render(props: ModalComponentProps) {
    modal.render(
      React.createElement(ModalInspectionDetail, props)
    );
  }
}

const singleton = new Modal();

export default singleton;
