import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, ModalView } from '../..';
import { AppContext } from '../../../context/AppContext';
import { emptyRevisionProduct, emptyInspection } from '../../../emptyObjects';
import { useResource } from '../../../hooks';
import { RevisionProduct, Inspection, UpdatedRevisionProduct } from '../../../interfaces';
import RevisionProductForm from '../RevisionProductForm';

import './styles.scss';
import { utils } from '../../../helpers';

interface Props {
  visible: boolean
  title?: string
  clientID?: string
  collaboratorID?: string
  serviceID?: string
  preloadedProduct?: RevisionProduct
  onCreateProduct?: (_product: RevisionProduct) => void
  onUpdateProduct?: (_product: RevisionProduct) => void
  onClose: () => void
  onError?: (_error: string) => void
  inspectionLookInTheTrash?: boolean
}

const ModalViewRevisionProduct: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    visible = true,
    title = t('services.productFormTitle') || '',
    preloadedProduct = emptyRevisionProduct,
    onCreateProduct = (_product: RevisionProduct) => { },
    onUpdateProduct = (_product: RevisionProduct) => { },
    onClose = () => { },
    onError = (_error: string) => { },
    inspectionLookInTheTrash = false
  } = props;

  let {
    clientID,
    collaboratorID,
    serviceID
  } = props;

  const {
    fetchResource
  } = useResource<Inspection>();

  const [inspection, setInspection] = useState<Inspection>(emptyInspection);

  if (preloadedProduct) {
    if (!clientID) {
      clientID = preloadedProduct.client.id;
    }
    if (!collaboratorID) {
      collaboratorID = preloadedProduct.collaborator.id;
    }
    if (!serviceID) {
      serviceID = preloadedProduct.service.id;
    }
  }

  const {
    updateResource
  } = useResource<UpdatedRevisionProduct>();

  const {
    createResource
  } = useResource<RevisionProduct[]>();

  const {
    apiType,
    soundEffects,
    setOpenModal
  } = useContext(AppContext);

  useEffect(() => {
    if (
      preloadedProduct.inspection
      && preloadedProduct.inspection?.id !== ''
      && preloadedProduct.inspection?.id !== undefined
    ) {
      fetchResource(
        `/${apiType}/inspections/${preloadedProduct.inspection?.id}?b_lookInTheTrash=${inspectionLookInTheTrash ? 'true' : ''}`,
        (data) => {
          setInspection(data);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [preloadedProduct]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      customComponent={
        <div className='form-container'>
          <RevisionProductForm
            title={title}
            cleanStates={visible}
            preloadedProduct={preloadedProduct}
            disableInputs={
              inspection.id
                && (inspection.complies !== null
                  || !!utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'product-updated')
                  || utils.getCurrentRound(inspection.rounds).list !== null)
                ? ['normCode', 'fase', 'code']
                : []}
            onCreateProduct={(product: RevisionProduct) => {
              createResource(
                `/${apiType}/constancy-products`,
                {
                  products: [product],
                  clientID,
                  collaboratorID,
                  constancyServiceID: serviceID
                },
                (data) => {
                  onCreateProduct(data[0]);
                  onClose();
                },
                (error) => {
                  onError(error);
                }
              );
            }}
            onUpdateProduct={(product: RevisionProduct) => {
              updateResource(
                `/${apiType}/constancy-products/${product?.id}`,
                product,
                (data) => {
                  onUpdateProduct(data.product);
                  onClose();
                },
                (error: string) => {
                  onError(error);
                }
              );
            }}
          />
        </div>
      }
    />
  );
};

export default ModalViewRevisionProduct;
