import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { DictumProductStatus } from '../../../../interfaces';

interface Props {
  status: DictumProductStatus
}

const DictumProductDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const dictumProductStatuses: DictumProductStatus[] = [
    'invoice-assigned',
    'validation-in-progress',
    'validated',
    'not-validated',
    'cancellation-in-progress',
    'cancelled',
    'in-inspection',
    'concluded'
  ];

  const translatedDictumProductStatuses: string[] = [];

  dictumProductStatuses.forEach((element: DictumProductStatus) => {
    translatedDictumProductStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="dictum-product-detail__progress-indicator">
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedDictumProductStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default DictumProductDetailDocuments;
