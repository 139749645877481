/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

import Icon from '../Icon';
import { IconTypes } from '../Icon/iconList';
import ToolTip from '../ToolTip';

import './styles.scss';

type fileExtensions = 'jpg' | 'png' | 'jpeg' | 'csv' | 'xlsx' | 'pdf' | 'ai' | 'docx' | 'zip' | 'defaultFile';

interface Props {
  name: string
  error?: string
  type?: 'dictum' | 'constancy' | 'paidPetition' | 'petition' | 'commercialInvoice' | 'service' | 'rectifiedPetition'
  label?: string
  disabled?: boolean
  disabledButton?: boolean
  onDownload?: () => void
  onDelete?: () => void
  toolTipPosition?: 'top' | 'bottom' | 'left' | 'right'
  orientation?: 'horizontal' | 'vertical'
  showToolTip?: boolean
  onClick?: () => void
  hoverEffect?: boolean
}

const File: React.FC<Props> = (props) => {
  const {
    name,
    error,
    label,
    type,
    disabled = false,
    onDownload,
    onDelete,
    toolTipPosition = 'bottom',
    orientation = 'vertical',
    showToolTip = true,
    onClick,
    hoverEffect = false,
    disabledButton = false
  } = props;

  const { t } = useTranslation();

  const setIcon = (): fileExtensions | IconTypes => {
    if (type) {
      return `${type}File`;
    }

    if (name) {
      const nameArr = name.split('.');

      const fileExtension = nameArr[nameArr.length - 1].toLowerCase();

      if (fileExtension === 'jpg'
        || fileExtension === 'png'
        || fileExtension === 'jpeg'
        || fileExtension === 'csv'
        || fileExtension === 'xlsx'
        || fileExtension === 'pdf'
        || fileExtension === 'ai'
        || fileExtension === 'docx'
        || fileExtension === 'zip'
      ) {
        return fileExtension;
      }
    }

    return 'defaultFile';
  };

  const setTopButton = () => {
    if (disabled) {
      return (<></>);
    }

    if (onDelete) {
      return <Icon type='deleteFile' className={`file__button-top file__button-top--${orientation}`} onClick={onDelete} />;
    }

    return <></>;
  };

  const setFooter = () => {
    if (disabled) {
      return (<div className={`file__disable-text file__disable-text--${orientation}`}>{t('global.notAbaible')}</div>);
    }

    if (onDownload !== undefined) {
      return (
        <div className={`file__button-container file__button-container--${orientation}`}>
          <Button
            onClick={onDownload}
            label={t('global.download') || ''}
            type='secondary'
            disabled={disabledButton}
          />
        </div>
      );
    }

    return (<></>);
  };

  return (
    <div className={`file file--${disabled ? 'disabled' : ''} file--${orientation}-no-hover`} >
      {setTopButton()}
      <Icon type={setIcon()} className={`file__file-icon file__file-icon--${orientation}${hoverEffect ? '' : '-no-hover'}`} onClick={onClick} />
      {/* {
        showToolTip ? (
          <ToolTip
            text={error || label || name}
            position={toolTipPosition}
            component={
              <p className={`file__file-name file__file-name--${orientation}`}>
                {label || name}
              </p>}
          />
        ) : (
          <p className={`file__file-name file__file-name--${orientation}`}>
            {label || name}
          </p>
        )
      } */}
      <p className={`file__file-name file__file-name--${orientation}`}>
        {label || name}
      </p>
      {setFooter()}
    </div>
  );
};

export default File;
