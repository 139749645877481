import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import JSZip from 'jszip';

import { saveAs } from 'file-saver';

import File from '../../../../components/File';

import '../../styles.scss';
import { Modal, SubTitle, Title } from '../../../../components';
import { ConstancyService, Inspection } from '../../../../interfaces';
import { useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  constancy: ConstancyService
  constancyServiceID: string
  serviceNumber: string
  clientShortName: string
}

const DictumServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    constancyServiceID,
    serviceNumber,
    clientShortName,
    constancy
  } = props;

  const {
    fetchResource,
    fetchResources
  } = useResource<any>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getConstancyProductDocument
  } = useResource<any>();

  const {
    apiType,
    soundEffects,
    setOpenModal
  } = useContext(AppContext);

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const [ableDownloadAllDocuments, setAbleDownloadAllDocuments] = useState<boolean>(true);
  const [productsCertificatesLimit, setProductsCertificatesLimit] = useState<string>('');

  const generateConstancyDocuments = async () => {
    // fetchResource(
    //   `/${apiType}/constancy-services/${constancyServiceID}/bulk/constancy-of-inspection`,
    //   (data) => {
    //     Modal.fireLoading();
    //     const keys = Object.keys(data.files);

    //     const zip = new JSZip();

    //     const bytes: any[] = [];
    //     const blob: Blob[] = [];

    //     for (let i = 0; i < keys.length; i += 1) {
    //       bytes[i] = new Uint8Array(data.files[keys[i]].data);
    //       blob[i] = new Blob([bytes[i]], { type: 'application/pdf' });
    //       zip.file(`${keys[i]}-Constancia.pdf`, blob[i]);
    //     }

    //     zip.generateAsync({ type: 'blob' }).then((content) => {
    //       saveAs(content, `Constancias_${serviceNumber}.zip`);
    //     });

    //     Modal.close();
    //   },
    //   (error) => Modal.fireError(error as string, undefined, soundEffects)
    // );

    fetchResources(
      {
        resourcePath: `/${apiType}/dictum-products`,
        filters: {
          s_status: 'concluded',
          s_serviceID: constancy.id
        }
      },
      async (data) => {
        let documentsDownloaded: number = 0;

        Modal.fireLoading(undefined, 0, t('global.downloading') || '');

        const zip = new JSZip();

        // eslint-disable-next-line no-restricted-syntax
        for (const product of data.items) {
          // eslint-disable-next-line no-await-in-loop, no-loop-func
          await new Promise<void>((resolve, reject) => {
            getConstancyProductDocument(
              `/${apiType}/constancy-products/${product.id}/constancy-of-inspection`,
              (data2) => {
                Modal.fireLoading();

                const bytes = new Uint8Array(data2.file.data);
                const blob = new Blob([bytes], { type: 'application/pdf' });

                zip.file(`${product.invoice}-Constancia.pdf`, blob);

                documentsDownloaded += 1;

                // Actualizar el porcentaje y continuar con el siguiente documento
                const porcentaje = (documentsDownloaded / data.items.length) * 100;
                Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)), t('global.downloading') || '');

                resolve();
              },
              (error) => {
                Modal.fireError(error as string, undefined, soundEffects);
                reject(error);
              },
              false
            );
          });
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        saveAs(zipBlob, `Constancias_${serviceNumber}.zip`);

        Modal.fireSuccess(
          t('global.correct'),
          t('global.downloadedFiles'),
          setOpenModal,
          () => {
            Modal.close();
          },
          soundEffects
        );
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  };

  const handleGenerateServiceRequestDocument = async () => {
    fetchResource(
      `/${apiType}/documents/service-request/CC/${constancyServiceID}`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${serviceNumber} - SOLICITUD DE SERVICIOS - ${clientShortName}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  useEffect(() => {
    fetchResources(
      {
        resourcePath: `/${apiType}/constancy-products`,
        filters: {
          s_serviceID: constancyServiceID
        }
      },
      async (data) => {
        const products = data.items;

        if (products.some((item) => item.status === 'concluded')) {
          const inspectionsStatus: string[] = [];
          const productsStatus: string[] = [];

          // eslint-disable-next-line array-callback-return, max-len
          await Promise.all(products.filter(item => item.indicators.attachedToTheService === true).map(async (product) => {
            productsStatus.push(product.status);

            const inspectionID = product.inspection?.id;

            if (inspectionID) {
              await getInspection(
                `/${apiType}/inspections/${inspectionID}`,
                (inspection) => {
                  inspectionsStatus.push(inspection.status);
                },
                (error: string) => Modal.fireError(error, undefined, soundEffects)
              );
            }
          }));

          if (inspectionsStatus.every(status => status === 'concluded')
            && productsStatus.every(status => status === 'concluded')) {
            setAbleDownloadAllDocuments(false);
          }

          if (products.length > 80) {
            setProductsCertificatesLimit(t('global.limitCertificateGeneration') || '');
          }
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [constancyServiceID]);

  return (
    <div className="constancy-service-detail__documents" id="data-section">
      <Title title={t('services.documents')} type="primary" />
      <br />
      <div>
        <Title title={t('services.documentsCECSA') || ''} type="secondary" />
        <div className="constancy-service-detail__documents__container">
          <File
            name={'Solicitud de servicio'}
            type={'service'}
            label={'Solicitud de servicio'}
            disabled={constancy.indicators.requestIsCompleted === false}
            onDownload={handleGenerateServiceRequestDocument}
            orientation='horizontal'
            showToolTip={false}
          />
          {
            productsCertificatesLimit !== '' && (
              <SubTitle subTitle={t('global.limitCertificateGeneration')} red={true} />
            )
          }
          <File
            name='Descargar Constancias de Cumplimiento.zip'
            onDownload={generateConstancyDocuments}
            disabled={ableDownloadAllDocuments || productsCertificatesLimit !== ''}
            orientation='horizontal'
            showToolTip={false}
          />
        </div>
      </div>
      <br />
    </div>
  );
};

export default DictumServiceDetailDocuments;
