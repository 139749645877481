import React from 'react';

import './styles.scss';

const Spinner: React.FC = () => {
  return (
    // <div className="lds-spinner">
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    //   <div></div>
    // </div>
    <div className="loader"></div>
  );
};

export default Spinner;
