/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-45',
    documentDescription: 'Lista de inspección para Dictamen de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/10/2021',
    hasPackingTable: false,
    hasSamplingTable: true,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />
            Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener
            cuando menos la siguiente información comercial obligatoria, misma que puede aparecer
            en cualquier superficie del envase:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple
            vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad en forma escrita o gráfica;
            <br />
            <ul>
              <li>
                Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su
                contenido, no requieren presentar declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver
                su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar
                declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los
                supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la
                cantidad en forma escrita o gráfica.
              </li>

              <li>
                En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible
                y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor
                su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial
                Mexicana.
              </li>
            </ul>
            <br />

            Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la
            cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al
            consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente
            Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable
            de la fabricación para productos nacionales.
            <br />
            En el caso de productos importados, esta información debe ser proporcionada a la
            Secretaría de Economía oa la Procuraduría Federal del Consumidor por el importador
            a solicitud de cualquiera de ellas;
            <br />
            La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta
            información a los consumidores que así lo soliciten cuando existan quejas sobre los 2
            productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo:
            "Producto de...","Hecho en...", "Manufacturado en..." u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos
            Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio
            fiscal del importador. Esta información puede incorporarse al producto en territorio
            nacional, después del despacho aduanero y antes de su comercialización;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el
            fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de
            alimentación del juguete menor oigual a 24 V, en forma gráfica o escrita que requiera
            para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete,
            cuando éste consuma para suoperación una tensión mayor a 24 V.
            <br />
            Nota.- La información contenida en los incisos g) y h) puede
            ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />
            La información obligatoria que se ostente en las etiquetas de los juguetes debe:
            <br />
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin
            perjuicio de que adicionalmente se pueda expresar en otro sistema de unidad de medida.
            La información que se exprese en un sistema de unidades distinto al Sistema General de
            Unidades de Medida, debe expresarse también en este último, cuando menos con el mismo
            tamaño de letra y/o números.
            <br />
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este
            sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición
            por el consumidor.
            <br />
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su
            lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse
            en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por
            pila o batería o por cualquier otra fuentede alimentación, deben cumplir sólo con lo
            dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un
            documento anexo, el instructivo demontaje o una explicación escrita o gráfica. Cuando
            el juguete esté destinado a ser ensamblado por un adulto, se debe indicar demodo claro
            esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia,
            así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de
            instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento
            periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y
            precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede
            provocar una caída o volcar.
            <br />
            Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro
            para el consumidor, si así lo determinael fabricante.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe
            advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los
            recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar
            que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y
            que sean guardados en los bolsillos.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para
            informar que no proporcionan protección en caso de accidente o una leyenda similar de
            acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes,
            deben llevar en el producto, en su empaque o en un documento anexo, una leyenda o
            gráfico
            alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el
            producto que indique que se trata de unjuguete y que debe ser utilizado bajo la
            supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar
            con el cuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por
            medio de hilos, cuerdas, elásticos o cintas,deben incluir en el producto, en su
            envase, empaque, instructivo o en un documento anexo, una leyenda precautoria
            para evitar posibles riesgos para los infantes..
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <br />
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una
            etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del
            Sistema General de Unidades de Medida y concentración en porcentaje
            <br />
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres
            colores diferentes como a continuación seindica:
            <br />
            Azul: productos alcalinos
            <br />
            Rojo productos ácidos
            <br />
            Amarillo: productos neutros.
            <br />
            Asimismo, deben indicar la característica principal de la sustancia utilizada, de
            acuerdo a la recomendación del fabricante (astringente, irritante, etc.), para que,
            en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
            <br />
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
            <br />
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo
            experimental, que puede serpeligroso si no se siguen adecuadamente las indicaciones del
            instructivo determinadas por el fabricante.
            <br />
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
            <br />
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
            <br />
            <br />
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5
            mm de altura.
            <br />
            <br />
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de
            precaución y seguridad pertinentes, las cualesson, entre otras:
            <br />
            a) "Todo producto químico debe ser manejado como si fuera tóxico".
            <br />
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente
            a un médico llevando elproducto químico y su recipiente".
            <br />
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />
            a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
            <br />
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el
            experimento";
            <br />
            c) "PROTEGER los ojos en los experimentos indicados";
            <br />
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
            <br />
            e) "LIMPIAR la mesa y los materiales utilizados";
            <br />
            f) "LAVARSE las manos, una vez terminados los experimentos";
            <br />
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
            <br />
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
            <br />
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos
            químicos";
            <br />
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no
            utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los
            niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda
            precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su
            empaque, envase, instructivo o enetiqueta:
            <br />
            "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <br />
            Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así
            como de la indicación de las precauciones que debe seguir el usuario, precisando que,
            el no respetar estas indicaciones lo expondría a riesgos inherentes alabuso del
            juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de
            los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-44',
    documentDescription: 'Lista de inspección para Constancia de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/10/2021',
    hasSamplingTable: false,
    hasPackingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />
            Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener
            cuando menos la siguiente información comercial obligatoria, misma que puede aparecer
            en cualquier superficie del envase:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple
            vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad en forma escrita o gráfica;
            <br />
            <ul>
              <li>
                Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su
                contenido, no requieren presentar declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver
                su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar
                declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los
                supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la
                cantidad en forma escrita o gráfica.
              </li>

              <li>
                En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible
                y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor
                su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial
                Mexicana.
              </li>
            </ul>
            <br />

            Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la
            cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al
            consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente
            Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable
            de la fabricación para productos nacionales.
            <br />
            En el caso de productos importados, esta información debe ser proporcionada a la
            Secretaría de Economía oa la Procuraduría Federal del Consumidor por el importador
            a solicitud de cualquiera de ellas;
            <br />
            La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta
            información a los consumidores que así lo soliciten cuando existan quejas sobre los2
            productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo:
            "Producto de...","Hecho en...", "Manufacturado en..." u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos
            Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio
            fiscal del importador. Estai nformación puede incorporarse al producto en territorio
            nacional, después del despacho aduanero y antes de su comercialización;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el
            fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de
            alimentación del juguete menor oigual a 24 V, en forma gráfica o escrita que requiera
            para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete,
            cuando éste consuma para suoperación una tensión mayor a 24 V.
            <br />
            Nota.- La información contenida en los incisos g) y h)
            puede ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />
            La información obligatoria que se ostente en las etiquetas de los juguetes debe:
            <br />
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin
            perjuicio de que adicionalmentese pueda expresar en otro sistema de unidad de medida.
            La información que se exprese en un sistema de unidades distinto al Sistema General de
            Unidades de Medida, debe expresarse también en este último, cuando menos con elmismo
            tamaño de letra y/o números.
            <br />
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este
            sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición
            por el consumidor.
            <br />
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su
            lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse
            en idioma español, sin perjuicio de presentarse ademásen otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por
            pila o batería o por cualquier otra fuentede alimentación, deben cumplir sólo con lo
            dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un
            documento anexo, el instructivo demontaje o una explicación escrita o gráfica. Cuando
            el juguete esté destinado a ser ensamblado por un adulto, se debe indicar demodo claro
            esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia,
            así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de
            instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento
            periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y
            precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede
            provocar una caída o volcar.
            <br />
            Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro
            para el consumidor, si así lo determina el fabricante.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe
            advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los
            recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar
            que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y
            que sean guardados en los bolsillos.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para
            informar que no proporcionan protección en caso de accidente o una leyenda similar de
            acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes,
            deben llevar en el producto, en suempaque o en un documento anexo, una leyenda o gráfico
            alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el
            producto que indique que se trata de un juguete y que debe ser utilizado bajo la
            supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar
            con elcuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por
            medio de hilos, cuerdas, elásticos o cintas, deben incluir en el producto, en su
            envase, empaque, instructivo o en un documento anexo, una leyenda precautoria
            para evitar posibles riesgos para los infantes..
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <br />
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una
            etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del
            Sistema General de Unidades de Medida y concentración en porcentaje
            <br />
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres
            colores diferentes como a continuación seindica:
            <br />
            Azul: productos alcalinos
            <br />
            Rojo productos ácidos
            <br />
            Amarillo: productos neutros.
            <br />
            Asimismo, deben indicar la característica principal de la sustancia utilizada, de
            acuerdo a la recomendación del fabricante(astringente, irritante, etc.), para que,
            en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
            <br />
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
            <br />
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo
            experimental, que puede serpeligroso si no se siguen adecuadamente las indicaciones del
            instructivo determinadas por el fabricante.
            <br />
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
            <br />
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
            <br />
            <br />
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5
            mm de altura.
            <br />
            <br />
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de
            precaución y seguridad pertinentes, las cualesson, entre otras:
            <br />
            a) "Todo producto químico debe ser manejado como si fuera tóxico".
            <br />
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente
            a un médico llevando elproducto químico y su recipiente".
            <br />
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />
            a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
            <br />
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el
            experimento";
            <br />
            c) "PROTEGER los ojos en los experimentos indicados";
            <br />
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
            <br />
            e) "LIMPIAR la mesa y los materiales utilizados";
            <br />
            f) "LAVARSE las manos, una vez terminados los experimentos";
            <br />
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
            <br />
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
            <br />
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos
            químicos";
            <br />
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no
            utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los
            niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda
            precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su
            empaque, envase, instructivo o enetiqueta:
            <br />
            "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <br />
            Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así
            como de la indicación de las precauciones que debe seguir el usuario, precisando que,
            el no respetar estas indicaciones lo expondría a riesgos inherentes alabuso del
            juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de
            los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos, advertencias y garantías
            <br />
            6.1 Requisitos generales
            <br />
            La información señalada en el presente capítulo debe expresarse en idioma español,
            sin perjuicio de presentarse además en otros idiomas.
            <br />
            6.2 Instructivos
            <br />
            Los juguetes objeto de la aplicación de esta Norma Oficial Mexicana deben ir acompañados
            de instructivos o presentarse en el empaque, cuando así lo determine el fabricante, sin
            cargo adicional. Dichos instructivos deben contener las indicaciones claras y precisas
            para su uso normal, conservación y mejor aprovechamiento, así como de las advertencias
            necesarias para el manejo seguro y confiable de los mismos.
            <br />
            6.3 Advertencias
            <br />
            Ya sea en el producto, empaque o instructivo, cuando así lo determine el fabricante,
            se debe ostentar la siguiente información:
            <br />
            - Precauciones que deba tomar el usuario para el manejo, uso o disfrute del juguete.
            <br />
            - Indicaciones de conexión o ensamble para su adecuado funcionamiento.
            <br />
            - Las leyendas de advertencia relativas a la inclusión de productos o partes pequeñas
            contenidas en un juguete, sólodeben presentarse cuando el juguete contenga dichas partes
            y esté destinado a niños menores de cinco años. Los juguetes destinados a niños de hasta
            3 años, no deben contener piezas pequeñas.
            <br />
            6.4 Garantía
            <br />
            La garantía es opcional. Unicamente cuando el fabricante nacional, importador o
            comercializador responsable del juguete ofrezca garantía del mismo, ésta debe
            establecerse en los términos dispuestos por la Ley Federal de Protección al Consumidor.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-44',
    documentDescription: 'Lista de inspección para Constancia de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/10/2021',
    hasSamplingTable: false,
    hasPackingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />
            Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener
            cuando menos la siguiente información comercial obligatoria, misma que puede aparecer
            en cualquier superficie del envase:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple
            vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad en forma escrita o gráfica;
            <br />
            <ul>
              <li>
                Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su
                contenido, no requieren presentar declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver
                su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar
                declaración de cantidad.
              </li>

              <li>
                Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los
                supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la
                cantidad en forma escrita o gráfica.
              </li>

              <li>
                En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible
                y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor
                su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial
                Mexicana.
              </li>
            </ul>
            <br />

            Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la
            cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al
            consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente
            Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable
            de la fabricación para productos nacionales.
            <br />
            En el caso de productos importados, esta información debe ser proporcionada a la
            Secretaría de Economía oa la Procuraduría Federal del Consumidor por el importador
            a solicitud de cualquiera de ellas;
            <br />
            La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta
            información a los consumidores que así lo soliciten cuando existan quejas sobre los2
            productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo:
            "Producto de...","Hecho en...", "Manufacturado en..." u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos
            Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio
            fiscal del importador. Estai nformación puede incorporarse al producto en territorio
            nacional, después del despacho aduanero y antes de su comercialización;
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el
            fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de
            alimentación del juguete menor oigual a 24 V, en forma gráfica o escrita que requiera
            para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete,
            cuando éste consuma para suoperación una tensión mayor a 24 V.
            <br />
            Nota.- La información contenida en los incisos g) y h)
            puede ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />
            La información obligatoria que se ostente en las etiquetas de los juguetes debe:
            <br />
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin
            perjuicio de que adicionalmentese pueda expresar en otro sistema de unidad de medida.
            La información que se exprese en un sistema de unidades distinto al Sistema General de
            Unidades de Medida, debe expresarse también en este último, cuando menos con elmismo
            tamaño de letra y/o números.
            <br />
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este
            sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición
            por el consumidor.
            <br />
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su
            lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse
            en idioma español, sin perjuicio de presentarse ademásen otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por
            pila o batería o por cualquier otra fuentede alimentación, deben cumplir sólo con lo
            dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un
            documento anexo, el instructivo demontaje o una explicación escrita o gráfica. Cuando
            el juguete esté destinado a ser ensamblado por un adulto, se debe indicar demodo claro
            esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia,
            así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de
            instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento
            periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y
            precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede
            provocar una caída o volcar.
            <br />
            Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro
            para el consumidor, si así lo determina el fabricante.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe
            advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los
            recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar
            que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y
            que sean guardados en los bolsillos.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para
            informar que no proporcionan protección en caso de accidente o una leyenda similar de
            acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes,
            deben llevar en el producto, en suempaque o en un documento anexo, una leyenda o gráfico
            alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el
            producto que indique que se trata de un juguete y que debe ser utilizado bajo la
            supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar
            con elcuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por
            medio de hilos, cuerdas, elásticos o cintas, deben incluir en el producto, en su
            envase, empaque, instructivo o en un documento anexo, una leyenda precautoria
            para evitar posibles riesgos para los infantes..
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <br />
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una
            etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del
            Sistema General de Unidades de Medida y concentración en porcentaje
            <br />
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres
            colores diferentes como a continuación seindica:
            <br />
            Azul: productos alcalinos
            <br />
            Rojo productos ácidos
            <br />
            Amarillo: productos neutros.
            <br />
            Asimismo, deben indicar la característica principal de la sustancia utilizada, de
            acuerdo a la recomendación del fabricante(astringente, irritante, etc.), para que,
            en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
            <br />
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
            <br />
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo
            experimental, que puede serpeligroso si no se siguen adecuadamente las indicaciones del
            instructivo determinadas por el fabricante.
            <br />
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
            <br />
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
            <br />
            <br />
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5
            mm de altura.
            <br />
            <br />
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de
            precaución y seguridad pertinentes, las cualesson, entre otras:
            <br />
            a) "Todo producto químico debe ser manejado como si fuera tóxico".
            <br />
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente
            a un médico llevando elproducto químico y su recipiente".
            <br />
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />
            a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
            <br />
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el
            experimento";
            <br />
            c) "PROTEGER los ojos en los experimentos indicados";
            <br />
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
            <br />
            e) "LIMPIAR la mesa y los materiales utilizados";
            <br />
            f) "LAVARSE las manos, una vez terminados los experimentos";
            <br />
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
            <br />
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
            <br />
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos
            químicos";
            <br />
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no
            utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los
            niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda
            precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su
            empaque, envase, instructivo o enetiqueta:
            <br />
            "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <br />
            Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así
            como de la indicación de las precauciones que debe seguir el usuario, precisando que,
            el no respetar estas indicaciones lo expondría a riesgos inherentes alabuso del
            juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de
            los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos, advertencias y garantías
            <br />
            6.1 Requisitos generales
            <br />
            La información señalada en el presente capítulo debe expresarse en idioma español,
            sin perjuicio de presentarse además en otros idiomas.
            <br />
            6.2 Instructivos
            <br />
            Los juguetes objeto de la aplicación de esta Norma Oficial Mexicana deben ir acompañados
            de instructivos o presentarse en el empaque, cuando así lo determine el fabricante, sin
            cargo adicional. Dichos instructivos deben contener las indicaciones claras y precisas
            para su uso normal, conservación y mejor aprovechamiento, así como de las advertencias
            necesarias para el manejo seguro y confiable de los mismos.
            <br />
            6.3 Advertencias
            <br />
            Ya sea en el producto, empaque o instructivo, cuando así lo determine el fabricante,
            se debe ostentar la siguiente información:
            <br />
            - Precauciones que deba tomar el usuario para el manejo, uso o disfrute del juguete.
            <br />
            - Indicaciones de conexión o ensamble para su adecuado funcionamiento.
            <br />
            - Las leyendas de advertencia relativas a la inclusión de productos o partes pequeñas
            contenidas en un juguete, sólodeben presentarse cuando el juguete contenga dichas partes
            y esté destinado a niños menores de cinco años. Los juguetes destinados a niños de hasta
            3 años, no deben contener piezas pequeñas.
            <br />
            6.4 Garantía
            <br />
            La garantía es opcional. Unicamente cuando el fabricante nacional, importador o
            comercializador responsable del juguete ofrezca garantía del mismo, ésta debe
            establecerse en los términos dispuestos por la Ley Federal de Protección al Consumidor.
          </p>
        )
      }
    ]
  }
};
