/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-40',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-142-SSA1/SCFI-2014 Bebidas alcohólicas. Especificaciones sanitarias. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            El etiquetado de los productos objeto de esta Norma, además de cumplir con lo
            establecido en el Reglamento y las demás disposiciones jurídicas aplicables,
            se sujetará a lo siguiente:
            <br />
            9.1 Disposiciones generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            9.1.1 Las bebidas alcohólicas preenvasadas deben presentarse con una etiqueta
            en la que se describa el producto o se empleen palabras, ilustraciones u otras
            representaciones gráficas que se refieran al producto, permitiéndose la
            descripción gráfica de la sugerencia de uso, empleo, preparación, a condición
            de que aparezca una leyenda alusiva al respecto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            9.1.2 Las etiquetas que ostenten deben fijarse de manera tal que permanezcan
            disponibles hasta el momento de su uso y consumo en condiciones normales y
            deben aplicarse por cada unidad, envase múltiple o colectivo; con caracteres
            claros, visibles, indelebles y en colores contrastantes, fáciles de leer por
            el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            9.1.3 La información contenida en las etiquetas debe presentarse y describirse
            en forma clara, veraz y comprobable, evitando que sea falsa, equívoca o que
            induzca a error al consumidor con respecto a la naturaleza y características
            el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            9.1.4 En la superficie principal de exhibición, debe aparecer cuando menos, el nombre
            o la denominación genérica del producto, graduación alcohólica y la marca comercial,
            así como la indicación de la cantidad conforme al punto 2.2 del Capítulo de
            Referencias de esta Norma; el resto de la información a que se refiere esta Norma
            puede incorporarse en cualquier otra parte del envase.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.2 Presentación de la información.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            9.2.1 Las bebidas alcohólicas preenvasadas deben ostentar la información obligatoria
            en idioma español, sin perjuicio de que se presente también en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            9.2.2 La información debe aparecer de manera ostensible en caracteres contrastantes y
            legibles, clara en condiciones normales de compra y uso, a excepción de la información
            que en otras disposiciones jurídicas se le solicite, como características específicas de
            tamaño y presentación.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.3 Requisitos obligatorios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            9.3.1 Nombre o denominación genérica y marca comercial del producto.
            <br />
            9.3.1.1 El nombre o la denominación del producto preenvasado debe corresponder con lo
            establecido en los ordenamientos jurídicos aplicables; en ausencia de éstos, puede
            indicarse el nombre de uso común, o bien, emplearse una descripción de acuerdo a las
            características básicas de la composición y naturaleza de la bebida alcohólica, que no
            induzca a error o engaño al consumidor.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2 Nombre, denominación o razón social y domicilio fiscal.
            <br />
            9.3.2.1 Debe indicarse el nombre, denominación o razón social y domicilio fiscal del
            responsable del producto de manera enunciativa mas no limitativa: calle, número, código
            postal y entidad federativa en que se encuentre.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.2 Tratándose de productos importados: nombre, denominación o razón social y
            domicilio fiscal del importador. Esta información puede incorporarse al producto en
            territorio nacional, antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.3 Tratándose de la cerveza y de las bebidas alcohólicas preparadas a base de
            una mezcla de limonada y cerveza o vino, o de una mezcla de cerveza y vino importadas,
            deben incluir: el nombre y el domicilio del importador o registro federal de
            contribuyentes, así como las leyendas y símbolos a que se hace referencia en los
            puntos 9.3.7.2.1, 9.3.7.2.4 y en el Apéndice A Normativo, de esta Norma, antes de su
            internación al país.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            9.3.3 País de origen.
            <br />
            9.3.3.1 Los productos nacionales o de procedencia extranjera deben incorporar la leyenda
            que identifique el país de origen o gentilicio, por ejemplo: "Producto de ____",
            "Hecho en ______", "Manufacturado en _____", "Fabricado en _____", u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los cuales los Estados Unidos
            Mexicanos sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            9.3.4 Identificación de Lote.
            <br />
            9.3.4.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación
            del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
            <br />
            9.3.4.2 La identificación del lote, que incorpore el fabricante en el producto, debe
            estar siempre de manera claramente legible, visible e indeleble para el consumidor.
            <br />
            9.3.4.3 La clave del lote debe ser precedida por cualquiera de las siguientes
            indicaciones "LOTE", "Lot", "L", "Lote", "lote", "lot", "l", "lt", "LT", "LOT", o
            bien incluir una referencia del lugar donde aparece
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            9.3.5 Fecha de consumo preferente.
            <br />
            Aquellas bebidas con contenido alcohólico medio o alto, con duración menor o igual a 12
            meses deben declarar la fecha de consumo preferente, la cual deberá cumplir con lo
            siguiente:
            <br />
            9.3.5.1 Declararse en el envase o etiqueta y consistir por lo menos de:
            <br />
            9.3.5.1.1 El día y mes para productos de duración menor o igual a 3 meses, y
            <br />
            9.3.5.1.2 El mes y año para productos de duración mayor a 3 meses.
            <br />
            9.3.5.2 La fecha debe estar precedida por una leyenda que especifique la fecha de
            consumo preferente y ésta debe indicarse anteponiendo alguna de las siguientes
            leyendas, sus abreviaturas o leyendas análogas: "Consumir preferentemente antes del
            ____", "Cons. Pref. antes del ___" y "Cons Pref ___".
            <br />
            En el espacio en blanco indicar la fecha misma o una referencia al lugar donde
            aparece la fecha.
            <br />
            <br />
            9.3.5.3 Tratándose de productos de importación, cuando el codificado de la fecha de
            consumo preferente no corresponda al formato establecido en el punto 9.3.5.1, de esta
            Norma, éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su
            caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En
            ninguno de estos casos los ajustes serán considerados como alteración.
            <br />
            9.3.5.4 Al declarar la fecha de consumo preferente se debe indicar en la etiqueta
            cualquier condición especial que se requiera para la conservación de la bebida
            alcohólica, si de su cumplimiento depende la validez de la fecha.
            <br />
            Por ejemplo, "una vez abierto, consérvese en refrigeración", o leyendas análogas.
            <br />
            9.3.5.5 La fecha de consumo preferente que incorpore el fabricante en el producto
            preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            9.3.6 Contenido alcohólico (Por ciento de alcohol en volumen).
            <br />
            9.3.6.1 Indicar el contenido alcohólico [por ciento de alcohol en volumen a
            293 K (20 ºC)], debiendo usarse para ello las siguientes abreviaturas:
            % Alc. Vol.; % Alc Vol; % alc. vol.; % alc vol.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.1.1 La lista de ingredientes debe figurar en la etiqueta de las bebidas
            alcohólicas preparadas, licores o cremas y todas estas bebidas alcohólicas, que después
            de destiladas y/o antes de embotellar utilicen ingredientes opcionales y/o aditivos que
            causen hipersensibilidad, intolerancia o alergia y se encuentren presentes en el
            producto final. Se ha comprobado que los.
            <br />
            siguientes alimentos e ingredientes causan hipersensibilidad y deben declararse siempre:
            <br />
            9.3.7.1.1.1 Cereales que contienen gluten, por ejemplo: trigo, centeno, cebada, avena,
            espelta o sus cepas híbridas, y productos de éstos;
            <br />
            9.3.7.1.1.2 Huevo y sus productos;
            <br />
            9.3.7.1.1.3 Cacahuate y sus productos
            <br />
            9.3.7.1.1.4 Soya y sus productos, con excepción del aceite de soya;
            <br />
            9.3.7.1.1.5 Leche y productos lácteos, incluida la lactosa;
            <br />
            9.3.7.1.1.6 Nueces de árboles y sus derivados, y
            <br />
            9.3.7.1.1.7 Sulfito en concentraciones de 10 mg/kg o más.
            <br />
            9.3.7.1.2 La lista de ingredientes debe ir encabezada o precedida por el término
            "ingredientes".
            <br />
            9.3.7.1.3 Los ingredientes deben declararse por orden cuantitativo decreciente.
            <br />
            9.3.7.1.4 Los aditivos usados en la elaboración de la bebida alcohólica, deben declararse
            con el nombre común o en su defecto algunos de los sinónimos establecidos en el Acuerdo,
            a excepción de los saborizantes, aromatizantes y enzimas que podrán declararse de manera
            genérica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 18,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2 Leyendas precautorias.
              <br />
              9.3.7.2.1 El etiquetado de las bebidas alcohólicas deberá ostentar la leyenda
              precautoria "EL ABUSO EN EL CONSUMO DE ESTE PRODUCTO ES NOCIVO PARA LA SALUD",
              conforme a lo establecido en el artículo 218, de la Ley, en color contrastante
              con el fondo, en letra mayúscula helvética condensada, en caracteres claros y
              fácilmente legibles, de conformidad con la siguiente tabla:
            </p>

            <br />

            <table className="table-content__row__cell__table">
              <tr>
                <th>VOLMEN</th>
                <th>ALTURA MÍNIMA DEL TIPO DE LETRA</th>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 50,1 hasta 190 ml</td>
                <td>2,0 mm</td>
              </tr>

              <tr>
                <td>De 190,1 hasta 500 ml</td>
                <td>2,5 mm</td>
              </tr>

              <tr>
                <td>De 500,1 hasta 1000 ml</td>
                <td>3,0 mm</td>
              </tr>

              <tr>
                <td>De 1000,1 hasta 4000 ml</td>
                <td>5,0 mm</td>
              </tr>

              <tr>
                <td>Mayores de 4000 ml</td>
                <td>7,0 mm</td>
              </tr>
            </table>
            <br />
            <p className="table-content__row__cell--text table-content__row__cell--text-center">
              Alrededor de la leyenda deberá existir un espacio libre de cuando menos 3 mm.
            </p>
          </div>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.2 La leyenda podrá aparecer, además, en el tapón o en el faldón de la
            corcholata, siempre y cuando sea visible y no esté cubierta por ningún tipo de sello,
            timbre o envoltura que impida al consumidor leer la leyenda completa, con las mismas
            características señaladas en el punto 9.3.7.2.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.3 En las canastillas y cartones de empaque, las leyendas deberán ostentarse
            además, en la parte superior y caras laterales, en caracteres no menores de 4mm.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.4 En la etiqueta de los productos con  contenido alcohólico medio y alto deberán
            incluirse los símbolos conforme a lo dispuesto en el punto A.2, del Apéndice A Normativo,
            de esta Norma, que se refieren a la prohibición de consumo a menores de 18 años, a
            mujeres embarazadas y la conducción bajo los influjos del alcohol.
            <br />

            A.2 Los Símbolos a utilizar son
            <br />
            A.2.1 Símbolo 1, Prohibición de consumo en menores de 18 años:
            <br />
            <img src="/images/no_younger.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.2 Símbolo 2, Prohibición de consumo por mujeres embarazadas:
            <br />
            <img src="/images/no_pregnant_woman.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.3 Símbolo 3, Prohibición de conducción bajo los influjos del alcohol
            <br />
            <img src="/images/no_vehicle.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            Cuando se incluyan los tres símbolos simultáneamente, éstos deberán: tener un diámetro
            mínimo de 7mm.
            <br />
            El contenido bajo va de 6.1 a 20%
            <br />
            Contenido alto: de 20.1% a 55%
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 22,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2.4 Las bebidas de contenido alcohólico bajo, deberán incluir únicamente el
              símbolo:
              <br />
            </p>

            <table>
              <tr>
                <td>De contenido alchólico bajo</td>
                <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
              </tr>
            </table>

            <p className="table-content__row__cell--text"> A.6 En aquellas bebidas con contenido alcohólico bajo, se deberá incluir únicamente
              el siguiente símbolo
              <br />
            </p>

            <img src="/images/younger_warning.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            <p className="table-content__row__cell--text">El cual debe de tener un diámetro mínimo de 10mm.<br /></p>
          </div>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            <b> 9.3.7.2.5 </b> En las canastillas y cartones de empaque de los productos que recibe el
            consumidor final, exceptuando al material de embalaje, los símbolos deberán ostentarse
            además, en la parte superior y caras laterales, en un tamaño mayor al que aparezca en
            la etiqueta, tapón o corcholata.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            <b>9.3.7.3 Bebidas alcohólicas con bajo contenido energético.</b>
            <br />
            9.3.7.3.1 Los productos con bajo contenido energético deben ostentar en la etiqueta la
            declaración "bajo contenido energético".
            <br />
            5.8.1 Para ser consideradas como bebidas alcohólicas con bajo contenido energético deben
            reducir el mismo, al menos en un 24% con relación al contenido energético de la bebida
            alcohólica original.
            <br />
            9.3.7.3.2 No está permitido emplear términos descriptivos relacionados con la
            modificación en el contenido energético, distinto al definido en esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.3.3 Aquellas bebidas alcohólicas con bajo contenido energético deberán incluir
            las leyendas precautorias correspondientes, establecidas en el Acuerdo.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8 Información comercial.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.1 En el Brandy, se incluirá la palabra "Brandy" en forma ostensible y la leyenda
            "100% de uva", la que deberá ser comprobable.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.2 Indicación de la cantidad conforme a lo establecido en la Norma Oficial
            Mexicana, citada en el punto 2.2, del Capítulo de Referencias de esta Norma.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.3 Si durante la elaboración de vino se emplea viruta de roble, no se podrá
            utilizar ningún termino que confunda al consumidor haciendo referencia al uso de
            barricas de roble, como "añejado en barrica" o similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4 Información adicional.
            <br />
            9.4.1 Información sanitaria adicional.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.1 Las etiquetas podrán contener la clasificación señalada en la siguiente tabla,
            la cual, cuando se utilice, deberá aparecer como se señala y no deberá ser utilizada con
            fines promocionales, ni de publicidad de los productos. Los textos de las leyendas
            precautoriasno podrán modificarse ni usar superlativos relativos a la clasificación.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 32,
        text: (
          <table className="table-content__row__cell__table">
            <tr>
              <td>De contendio alchohólico bajo</td>
              <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico medio</td>
              <td>Las bebidas con un contenido alcohólico de 6,1% y hasta 20.0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico alto</td>
              <td>Las bebidas con un contenido alcohólico de 20,1% y hasta 55,0% en volumen.</td>
            </tr>
          </table>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.2.1 Se podrá declarar el contenido energético por porción expresado ya sea en kJ
            o kcal, de acuerdo a los siguientes tamaños de porción de bebida estándar, con
            aproximadamente 13g de alcohol y considerando que su gravedad específica es de
            0,785g/ml:
            <br />
            9.4.1.2.1.1 Cerveza con 5% Alc. Vol., 330ml;
            <br />
            9.4.1.2.1.2 Vino con 12% Alc. Vol., 140ml;
            <br />
            9.4.1.2.1.3 Vinos fortificados (por ejemplo jerez) con 18% Alc. Vol., 90ml;
            <br />
            9.4.1.2.1.4 Licor o aperitivo con 25% Alc. Vol., 70ml, y
            <br />
            9.4.1.2.1.5 Bebidas espirituosas con 40% Alc. Vol., 40ml.
            <br />
            9.4.1.2.2 La declaración sobre el contenido energético se debe calcular utilizando los
            siguientes factores de conversión:
            <br />
            9.4.1.2.2.1 Alcohol etílico 5,5kcal/ml (23kJ/ml), y
            <br />
            9.4.1.2.2.2 Carbohidratos 4,0kcal/g (17kJ/g).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.3 En la etiqueta puede presentarse cualquier información o representación gráfica,
            así como material escrito, impreso o gráfico, por ejemplo, tratamientos o procesos
            especiales de elaboración u otras leyendas precautorias, siempre que esté de acuerdo
            con los requisitos obligatorios de la presente Norma.
            <br />
            Para efectos de esta Norma, se considera como información sanitaria aquella tendiente a
            proteger y preservar la salud de la persona y de la colectividad, a fin de alcanzar un
            estado físicamente sano de la población del país, de manera individual o concurrente.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.4 En el etiquetado de las bebidas alcohólicas se podrá incluir la leyenda:
            "Para mayor información ingresa a la página: www.conadic.salud.gob.mx, donde se cuenta
            con información sobre el uso nocivo del alcohol".
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 36,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4.2 Información comercial adicional.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            La información comercial diferente a la prevista en el punto 9.3.8, de esta Norma, se
            considera información comercial adicional. Esta podrá consistir en antecedentes
            históricos de la bebida, recetas, dichos, frases, símbolos, abreviaturas, dígitos,
            frases publicitarias, refranes, etc., así como material escrito, impreso o gráfico,
            siempre y cuando no induzcan a error o engaño al consumidor y podrá ostentarse en
            idioma distinto al español sin necesidad de ser traducida.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-40',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-142-SSA1/SCFI-2014 Bebidas alcohólicas. Especificaciones sanitarias. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            El etiquetado de los productos objeto de esta Norma, además de cumplir con lo
            establecido en el Reglamento y las demás disposiciones jurídicas aplicables,
            se sujetará a lo siguiente:
            <br />
            9.1 Disposiciones generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            9.1.1 Las bebidas alcohólicas preenvasadas deben presentarse con una etiqueta
            en la que se describa el producto o se empleen palabras, ilustraciones u otras
            representaciones gráficas que se refieran al producto, permitiéndose la
            descripción gráfica de la sugerencia de uso, empleo, preparación, a condición
            de que aparezca una leyenda alusiva al respecto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            9.1.2 Las etiquetas que ostenten deben fijarse de manera tal que permanezcan
            disponibles hasta el momento de su uso y consumo en condiciones normales y
            deben aplicarse por cada unidad, envase múltiple o colectivo; con caracteres
            claros, visibles, indelebles y en colores contrastantes, fáciles de leer por
            el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            9.1.3 La información contenida en las etiquetas debe presentarse y describirse
            en forma clara, veraz y comprobable, evitando que sea falsa, equívoca o que
            induzca a error al consumidor con respecto a la naturaleza y características
            el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            9.1.4 En la superficie principal de exhibición, debe aparecer cuando menos, el nombre
            o la denominación genérica del producto, graduación alcohólica y la marca comercial,
            así como la indicación de la cantidad conforme al punto 2.2 del Capítulo de
            Referencias de esta Norma; el resto de la información a que se refiere esta Norma
            puede incorporarse en cualquier otra parte del envase.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.2 Presentación de la información.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            9.2.1 Las bebidas alcohólicas preenvasadas deben ostentar la información obligatoria
            en idioma español, sin perjuicio de que se presente también en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            9.2.2 La información debe aparecer de manera ostensible en caracteres contrastantes y
            legibles, clara en condiciones normales de compra y uso, a excepción de la información
            que en otras disposiciones jurídicas se le solicite, como características específicas de
            tamaño y presentación.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.3 Requisitos obligatorios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            9.3.1 Nombre o denominación genérica y marca comercial del producto.
            <br />
            9.3.1.1 El nombre o la denominación del producto preenvasado debe corresponder con lo
            establecido en los ordenamientos jurídicos aplicables; en ausencia de éstos, puede
            indicarse el nombre de uso común, o bien, emplearse una descripción de acuerdo a las
            características básicas de la composición y naturaleza de la bebida alcohólica, que no
            induzca a error o engaño al consumidor.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2 Nombre, denominación o razón social y domicilio fiscal.
            <br />
            9.3.2.1 Debe indicarse el nombre, denominación o razón social y domicilio fiscal del
            responsable del producto de manera enunciativa mas no limitativa: calle, número, código
            postal y entidad federativa en que se encuentre.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.2 Tratándose de productos importados: nombre, denominación o razón social y
            domicilio fiscal del importador. Esta información puede incorporarse al producto en
            territorio nacional, antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.3 Tratándose de la cerveza y de las bebidas alcohólicas preparadas a base de
            una mezcla de limonada y cerveza o vino, o de una mezcla de cerveza y vino importadas,
            deben incluir: el nombre y el domicilio del importador o registro federal de
            contribuyentes, así como las leyendas y símbolos a que se hace referencia en los
            puntos 9.3.7.2.1, 9.3.7.2.4 y en el Apéndice A Normativo, de esta Norma, antes de su
            internación al país.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            9.3.3 País de origen.
            <br />
            9.3.3.1 Los productos nacionales o de procedencia extranjera deben incorporar la leyenda
            que identifique el país de origen o gentilicio, por ejemplo: "Producto de ____",
            "Hecho en ______", "Manufacturado en _____", "Fabricado en _____", u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los cuales los Estados Unidos
            Mexicanos sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            9.3.4 Identificación de Lote.
            <br />
            9.3.4.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación
            del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
            <br />
            9.3.4.2 La identificación del lote, que incorpore el fabricante en el producto, debe
            estar siempre de manera claramente legible, visible e indeleble para el consumidor.
            <br />
            9.3.4.3 La clave del lote debe ser precedida por cualquiera de las siguientes
            indicaciones "LOTE", "Lot", "L", "Lote", "lote", "lot", "l", "lt", "LT", "LOT", o
            bien incluir una referencia del lugar donde aparece
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            9.3.5 Fecha de consumo preferente.
            <br />
            Aquellas bebidas con contenido alcohólico medio o alto, con duración menor o igual a 12
            meses deben declarar la fecha de consumo preferente, la cual deberá cumplir con lo
            siguiente:
            <br />
            9.3.5.1 Declararse en el envase o etiqueta y consistir por lo menos de:
            <br />
            9.3.5.1.1 El día y mes para productos de duración menor o igual a 3 meses, y
            <br />
            9.3.5.1.2 El mes y año para productos de duración mayor a 3 meses.
            <br />
            9.3.5.2 La fecha debe estar precedida por una leyenda que especifique la fecha de
            consumo preferente y ésta debe indicarse anteponiendo alguna de las siguientes
            leyendas, sus abreviaturas o leyendas análogas: "Consumir preferentemente antes del
            ____", "Cons. Pref. antes del ___" y "Cons Pref ___".
            <br />
            En el espacio en blanco indicar la fecha misma o una referencia al lugar donde
            aparece la fecha.
            <br />
            <br />
            9.3.5.3 Tratándose de productos de importación, cuando el codificado de la fecha de
            consumo preferente no corresponda al formato establecido en el punto 9.3.5.1, de esta
            Norma, éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su
            caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En
            ninguno de estos casos los ajustes serán considerados como alteración.
            <br />
            9.3.5.4 Al declarar la fecha de consumo preferente se debe indicar en la etiqueta
            cualquier condición especial que se requiera para la conservación de la bebida
            alcohólica, si de su cumplimiento depende la validez de la fecha.
            <br />
            Por ejemplo, "una vez abierto, consérvese en refrigeración", o leyendas análogas.
            <br />
            9.3.5.5 La fecha de consumo preferente que incorpore el fabricante en el producto
            preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            9.3.6 Contenido alcohólico (Por ciento de alcohol en volumen).
            <br />
            9.3.6.1 Indicar el contenido alcohólico [por ciento de alcohol en volumen a
            293 K (20 ºC)], debiendo usarse para ello las siguientes abreviaturas:
            % Alc. Vol.; % Alc Vol; % alc. vol.; % alc vol.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.1.1 La lista de ingredientes debe figurar en la etiqueta de las bebidas
            alcohólicas preparadas, licores o cremas y todas estas bebidas alcohólicas, que después
            de destiladas y/o antes de embotellar utilicen ingredientes opcionales y/o aditivos que
            causen hipersensibilidad, intolerancia o alergia y se encuentren presentes en el
            producto final. Se ha comprobado que los.
            <br />
            siguientes alimentos e ingredientes causan hipersensibilidad y deben declararse siempre:
            <br />
            9.3.7.1.1.1 Cereales que contienen gluten, por ejemplo: trigo, centeno, cebada, avena,
            espelta o sus cepas híbridas, y productos de éstos;
            <br />
            9.3.7.1.1.2 Huevo y sus productos;
            <br />
            9.3.7.1.1.3 Cacahuate y sus productos
            <br />
            9.3.7.1.1.4 Soya y sus productos, con excepción del aceite de soya;
            <br />
            9.3.7.1.1.5 Leche y productos lácteos, incluida la lactosa;
            <br />
            9.3.7.1.1.6 Nueces de árboles y sus derivados, y
            <br />
            9.3.7.1.1.7 Sulfito en concentraciones de 10 mg/kg o más.
            <br />
            9.3.7.1.2 La lista de ingredientes debe ir encabezada o precedida por el término
            "ingredientes".
            <br />
            9.3.7.1.3 Los ingredientes deben declararse por orden cuantitativo decreciente.
            <br />
            9.3.7.1.4 Los aditivos usados en la elaboración de la bebida alcohólica, deben declararse
            con el nombre común o en su defecto algunos de los sinónimos establecidos en el Acuerdo,
            a excepción de los saborizantes, aromatizantes y enzimas que podrán declararse de manera
            genérica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 18,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2 Leyendas precautorias.
              <br />
              9.3.7.2.1 El etiquetado de las bebidas alcohólicas deberá ostentar la leyenda
              precautoria "EL ABUSO EN EL CONSUMO DE ESTE PRODUCTO ES NOCIVO PARA LA SALUD",
              conforme a lo establecido en el artículo 218, de la Ley, en color contrastante
              con el fondo, en letra mayúscula helvética condensada, en caracteres claros y
              fácilmente legibles, de conformidad con la siguiente tabla:
            </p>

            <br />

            <table className="table-content__row__cell__table">
              <tr>
                <th>VOLMEN</th>
                <th>ALTURA MÍNIMA DEL TIPO DE LETRA</th>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 50,1 hasta 190 ml</td>
                <td>2,0 mm</td>
              </tr>

              <tr>
                <td>De 190,1 hasta 500 ml</td>
                <td>2,5 mm</td>
              </tr>

              <tr>
                <td>De 500,1 hasta 1000 ml</td>
                <td>3,0 mm</td>
              </tr>

              <tr>
                <td>De 1000,1 hasta 4000 ml</td>
                <td>5,0 mm</td>
              </tr>

              <tr>
                <td>Mayores de 4000 ml</td>
                <td>7,0 mm</td>
              </tr>
            </table>
            <br />
            <p className="table-content__row__cell--text table-content__row__cell--text-center">
              Alrededor de la leyenda deberá existir un espacio libre de cuando menos 3 mm.
            </p>
          </div>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.2 La leyenda podrá aparecer, además, en el tapón o en el faldón de la
            corcholata, siempre y cuando sea visible y no esté cubierta por ningún tipo de sello,
            timbre o envoltura que impida al consumidor leer la leyenda completa, con las mismas
            características señaladas en el punto 9.3.7.2.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.3 En las canastillas y cartones de empaque, las leyendas deberán ostentarse
            además, en la parte superior y caras laterales, en caracteres no menores de 4mm.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.4 En la etiqueta de los productos con  contenido alcohólico medio y alto deberán
            incluirse los símbolos conforme a lo dispuesto en el punto A.2, del Apéndice A Normativo,
            de esta Norma, que se refieren a la prohibición de consumo a menores de 18 años, a
            mujeres embarazadas y la conducción bajo los influjos del alcohol.
            <br />

            A.2 Los Símbolos a utilizar son
            <br />
            A.2.1 Símbolo 1, Prohibición de consumo en menores de 18 años:
            <br />
            <img src="/images/no_younger.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.2 Símbolo 2, Prohibición de consumo por mujeres embarazadas:
            <br />
            <img src="/images/no_pregnant_woman.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.3 Símbolo 3, Prohibición de conducción bajo los influjos del alcohol
            <br />
            <img src="/images/no_vehicle.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            Cuando se incluyan los tres símbolos simultáneamente, éstos deberán: tener un diámetro
            mínimo de 7mm.
            <br />
            El contenido bajo va de 6.1 a 20%
            <br />
            Contenido alto: de 20.1% a 55%
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 22,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2.4 Las bebidas de contenido alcohólico bajo, deberán incluir únicamente el
              símbolo:
              <br />
            </p>

            <table>
              <tr>
                <td>De contenido alchólico bajo</td>
                <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
              </tr>
            </table>

            <p className="table-content__row__cell--text"> A.6 En aquellas bebidas con contenido alcohólico bajo, se deberá incluir únicamente
              el siguiente símbolo
              <br />
            </p>

            <img src="/images/younger_warning.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            <p className="table-content__row__cell--text">El cual debe de tener un diámetro mínimo de 10mm.<br /></p>
          </div>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            <b> 9.3.7.2.5 </b> En las canastillas y cartones de empaque de los productos que recibe el
            consumidor final, exceptuando al material de embalaje, los símbolos deberán ostentarse
            además, en la parte superior y caras laterales, en un tamaño mayor al que aparezca en
            la etiqueta, tapón o corcholata.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            <b>9.3.7.3 Bebidas alcohólicas con bajo contenido energético.</b>
            <br />
            9.3.7.3.1 Los productos con bajo contenido energético deben ostentar en la etiqueta la
            declaración "bajo contenido energético".
            <br />
            5.8.1 Para ser consideradas como bebidas alcohólicas con bajo contenido energético deben
            reducir el mismo, al menos en un 24% con relación al contenido energético de la bebida
            alcohólica original.
            <br />
            9.3.7.3.2 No está permitido emplear términos descriptivos relacionados con la
            modificación en el contenido energético, distinto al definido en esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.3.3 Aquellas bebidas alcohólicas con bajo contenido energético deberán incluir
            las leyendas precautorias correspondientes, establecidas en el Acuerdo.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8 Información comercial.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.1 En el Brandy, se incluirá la palabra "Brandy" en forma ostensible y la leyenda
            "100% de uva", la que deberá ser comprobable.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.2 Indicación de la cantidad conforme a lo establecido en la Norma Oficial
            Mexicana, citada en el punto 2.2, del Capítulo de Referencias de esta Norma.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.3 Si durante la elaboración de vino se emplea viruta de roble, no se podrá
            utilizar ningún termino que confunda al consumidor haciendo referencia al uso de
            barricas de roble, como "añejado en barrica" o similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4 Información adicional.
            <br />
            9.4.1 Información sanitaria adicional.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.1 Las etiquetas podrán contener la clasificación señalada en la siguiente tabla,
            la cual, cuando se utilice, deberá aparecer como se señala y no deberá ser utilizada con
            fines promocionales, ni de publicidad de los productos. Los textos de las leyendas
            precautoriasno podrán modificarse ni usar superlativos relativos a la clasificación.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 32,
        text: (
          <table className="table-content__row__cell__table">
            <tr>
              <td>De contendio alchohólico bajo</td>
              <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico medio</td>
              <td>Las bebidas con un contenido alcohólico de 6,1% y hasta 20.0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico alto</td>
              <td>Las bebidas con un contenido alcohólico de 20,1% y hasta 55,0% en volumen.</td>
            </tr>
          </table>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.2.1 Se podrá declarar el contenido energético por porción expresado ya sea en kJ
            o kcal, de acuerdo a los siguientes tamaños de porción de bebida estándar, con
            aproximadamente 13g de alcohol y considerando que su gravedad específica es de
            0,785g/ml:
            <br />
            9.4.1.2.1.1 Cerveza con 5% Alc. Vol., 330ml;
            <br />
            9.4.1.2.1.2 Vino con 12% Alc. Vol., 140ml;
            <br />
            9.4.1.2.1.3 Vinos fortificados (por ejemplo jerez) con 18% Alc. Vol., 90ml;
            <br />
            9.4.1.2.1.4 Licor o aperitivo con 25% Alc. Vol., 70ml, y
            <br />
            9.4.1.2.1.5 Bebidas espirituosas con 40% Alc. Vol., 40ml.
            <br />
            9.4.1.2.2 La declaración sobre el contenido energético se debe calcular utilizando los
            siguientes factores de conversión:
            <br />
            9.4.1.2.2.1 Alcohol etílico 5,5kcal/ml (23kJ/ml), y
            <br />
            9.4.1.2.2.2 Carbohidratos 4,0kcal/g (17kJ/g).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.3 En la etiqueta puede presentarse cualquier información o representación gráfica,
            así como material escrito, impreso o gráfico, por ejemplo, tratamientos o procesos
            especiales de elaboración u otras leyendas precautorias, siempre que esté de acuerdo
            con los requisitos obligatorios de la presente Norma.
            <br />
            Para efectos de esta Norma, se considera como información sanitaria aquella tendiente a
            proteger y preservar la salud de la persona y de la colectividad, a fin de alcanzar un
            estado físicamente sano de la población del país, de manera individual o concurrente.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.4 En el etiquetado de las bebidas alcohólicas se podrá incluir la leyenda:
            "Para mayor información ingresa a la página: www.conadic.salud.gob.mx, donde se cuenta
            con información sobre el uso nocivo del alcohol".
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 36,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4.2 Información comercial adicional.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            La información comercial diferente a la prevista en el punto 9.3.8, de esta Norma, se
            considera información comercial adicional. Esta podrá consistir en antecedentes
            históricos de la bebida, recetas, dichos, frases, símbolos, abreviaturas, dígitos,
            frases publicitarias, refranes, etc., así como material escrito, impreso o gráfico,
            siempre y cuando no induzcan a error o engaño al consumidor y podrá ostentarse en
            idioma distinto al español sin necesidad de ser traducida.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-40',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-142-SSA1/SCFI-2014 Bebidas alcohólicas. Especificaciones sanitarias. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            El etiquetado de los productos objeto de esta Norma, además de cumplir con lo
            establecido en el Reglamento y las demás disposiciones jurídicas aplicables,
            se sujetará a lo siguiente:
            <br />
            9.1 Disposiciones generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            9.1.1 Las bebidas alcohólicas preenvasadas deben presentarse con una etiqueta
            en la que se describa el producto o se empleen palabras, ilustraciones u otras
            representaciones gráficas que se refieran al producto, permitiéndose la
            descripción gráfica de la sugerencia de uso, empleo, preparación, a condición
            de que aparezca una leyenda alusiva al respecto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            9.1.2 Las etiquetas que ostenten deben fijarse de manera tal que permanezcan
            disponibles hasta el momento de su uso y consumo en condiciones normales y
            deben aplicarse por cada unidad, envase múltiple o colectivo; con caracteres
            claros, visibles, indelebles y en colores contrastantes, fáciles de leer por
            el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            9.1.3 La información contenida en las etiquetas debe presentarse y describirse
            en forma clara, veraz y comprobable, evitando que sea falsa, equívoca o que
            induzca a error al consumidor con respecto a la naturaleza y características
            el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            9.1.4 En la superficie principal de exhibición, debe aparecer cuando menos, el nombre
            o la denominación genérica del producto, graduación alcohólica y la marca comercial,
            así como la indicación de la cantidad conforme al punto 2.2 del Capítulo de
            Referencias de esta Norma; el resto de la información a que se refiere esta Norma
            puede incorporarse en cualquier otra parte del envase.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.2 Presentación de la información.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            9.2.1 Las bebidas alcohólicas preenvasadas deben ostentar la información obligatoria
            en idioma español, sin perjuicio de que se presente también en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            9.2.2 La información debe aparecer de manera ostensible en caracteres contrastantes y
            legibles, clara en condiciones normales de compra y uso, a excepción de la información
            que en otras disposiciones jurídicas se le solicite, como características específicas de
            tamaño y presentación.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text table-content__row__cell--text-center">
            9.3 Requisitos obligatorios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            9.3.1 Nombre o denominación genérica y marca comercial del producto.
            <br />
            9.3.1.1 El nombre o la denominación del producto preenvasado debe corresponder con lo
            establecido en los ordenamientos jurídicos aplicables; en ausencia de éstos, puede
            indicarse el nombre de uso común, o bien, emplearse una descripción de acuerdo a las
            características básicas de la composición y naturaleza de la bebida alcohólica, que no
            induzca a error o engaño al consumidor.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2 Nombre, denominación o razón social y domicilio fiscal.
            <br />
            9.3.2.1 Debe indicarse el nombre, denominación o razón social y domicilio fiscal del
            responsable del producto de manera enunciativa mas no limitativa: calle, número, código
            postal y entidad federativa en que se encuentre.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.2 Tratándose de productos importados: nombre, denominación o razón social y
            domicilio fiscal del importador. Esta información puede incorporarse al producto en
            territorio nacional, antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            9.3.2.3 Tratándose de la cerveza y de las bebidas alcohólicas preparadas a base de
            una mezcla de limonada y cerveza o vino, o de una mezcla de cerveza y vino importadas,
            deben incluir: el nombre y el domicilio del importador o registro federal de
            contribuyentes, así como las leyendas y símbolos a que se hace referencia en los
            puntos 9.3.7.2.1, 9.3.7.2.4 y en el Apéndice A Normativo, de esta Norma, antes de su
            internación al país.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            9.3.3 País de origen.
            <br />
            9.3.3.1 Los productos nacionales o de procedencia extranjera deben incorporar la leyenda
            que identifique el país de origen o gentilicio, por ejemplo: "Producto de ____",
            "Hecho en ______", "Manufacturado en _____", "Fabricado en _____", u otros análogos,
            sujeto a lo dispuesto en los tratados internacionales de los cuales los Estados Unidos
            Mexicanos sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            9.3.4 Identificación de Lote.
            <br />
            9.3.4.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación
            del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
            <br />
            9.3.4.2 La identificación del lote, que incorpore el fabricante en el producto, debe
            estar siempre de manera claramente legible, visible e indeleble para el consumidor.
            <br />
            9.3.4.3 La clave del lote debe ser precedida por cualquiera de las siguientes
            indicaciones "LOTE", "Lot", "L", "Lote", "lote", "lot", "l", "lt", "LT", "LOT", o
            bien incluir una referencia del lugar donde aparece
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            9.3.5 Fecha de consumo preferente.
            <br />
            Aquellas bebidas con contenido alcohólico medio o alto, con duración menor o igual a 12
            meses deben declarar la fecha de consumo preferente, la cual deberá cumplir con lo
            siguiente:
            <br />
            9.3.5.1 Declararse en el envase o etiqueta y consistir por lo menos de:
            <br />
            9.3.5.1.1 El día y mes para productos de duración menor o igual a 3 meses, y
            <br />
            9.3.5.1.2 El mes y año para productos de duración mayor a 3 meses.
            <br />
            9.3.5.2 La fecha debe estar precedida por una leyenda que especifique la fecha de
            consumo preferente y ésta debe indicarse anteponiendo alguna de las siguientes
            leyendas, sus abreviaturas o leyendas análogas: "Consumir preferentemente antes del
            ____", "Cons. Pref. antes del ___" y "Cons Pref ___".
            <br />
            En el espacio en blanco indicar la fecha misma o una referencia al lugar donde
            aparece la fecha.
            <br />
            <br />
            9.3.5.3 Tratándose de productos de importación, cuando el codificado de la fecha de
            consumo preferente no corresponda al formato establecido en el punto 9.3.5.1, de esta
            Norma, éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su
            caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En
            ninguno de estos casos los ajustes serán considerados como alteración.
            <br />
            9.3.5.4 Al declarar la fecha de consumo preferente se debe indicar en la etiqueta
            cualquier condición especial que se requiera para la conservación de la bebida
            alcohólica, si de su cumplimiento depende la validez de la fecha.
            <br />
            Por ejemplo, "una vez abierto, consérvese en refrigeración", o leyendas análogas.
            <br />
            9.3.5.5 La fecha de consumo preferente que incorpore el fabricante en el producto
            preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            9.3.6 Contenido alcohólico (Por ciento de alcohol en volumen).
            <br />
            9.3.6.1 Indicar el contenido alcohólico [por ciento de alcohol en volumen a
            293 K (20 ºC)], debiendo usarse para ello las siguientes abreviaturas:
            % Alc. Vol.; % Alc Vol; % alc. vol.; % alc vol.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.1.1 La lista de ingredientes debe figurar en la etiqueta de las bebidas
            alcohólicas preparadas, licores o cremas y todas estas bebidas alcohólicas, que después
            de destiladas y/o antes de embotellar utilicen ingredientes opcionales y/o aditivos que
            causen hipersensibilidad, intolerancia o alergia y se encuentren presentes en el
            producto final. Se ha comprobado que los.
            <br />
            siguientes alimentos e ingredientes causan hipersensibilidad y deben declararse siempre:
            <br />
            9.3.7.1.1.1 Cereales que contienen gluten, por ejemplo: trigo, centeno, cebada, avena,
            espelta o sus cepas híbridas, y productos de éstos;
            <br />
            9.3.7.1.1.2 Huevo y sus productos;
            <br />
            9.3.7.1.1.3 Cacahuate y sus productos
            <br />
            9.3.7.1.1.4 Soya y sus productos, con excepción del aceite de soya;
            <br />
            9.3.7.1.1.5 Leche y productos lácteos, incluida la lactosa;
            <br />
            9.3.7.1.1.6 Nueces de árboles y sus derivados, y
            <br />
            9.3.7.1.1.7 Sulfito en concentraciones de 10 mg/kg o más.
            <br />
            9.3.7.1.2 La lista de ingredientes debe ir encabezada o precedida por el término
            "ingredientes".
            <br />
            9.3.7.1.3 Los ingredientes deben declararse por orden cuantitativo decreciente.
            <br />
            9.3.7.1.4 Los aditivos usados en la elaboración de la bebida alcohólica, deben declararse
            con el nombre común o en su defecto algunos de los sinónimos establecidos en el Acuerdo,
            a excepción de los saborizantes, aromatizantes y enzimas que podrán declararse de manera
            genérica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 18,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2 Leyendas precautorias.
              <br />
              9.3.7.2.1 El etiquetado de las bebidas alcohólicas deberá ostentar la leyenda
              precautoria "EL ABUSO EN EL CONSUMO DE ESTE PRODUCTO ES NOCIVO PARA LA SALUD",
              conforme a lo establecido en el artículo 218, de la Ley, en color contrastante
              con el fondo, en letra mayúscula helvética condensada, en caracteres claros y
              fácilmente legibles, de conformidad con la siguiente tabla:
            </p>

            <br />

            <table className="table-content__row__cell__table">
              <tr>
                <th>VOLMEN</th>
                <th>ALTURA MÍNIMA DEL TIPO DE LETRA</th>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 0 hasta 50 ml</td>
                <td>1,5 mm</td>
              </tr>

              <tr>
                <td>De 50,1 hasta 190 ml</td>
                <td>2,0 mm</td>
              </tr>

              <tr>
                <td>De 190,1 hasta 500 ml</td>
                <td>2,5 mm</td>
              </tr>

              <tr>
                <td>De 500,1 hasta 1000 ml</td>
                <td>3,0 mm</td>
              </tr>

              <tr>
                <td>De 1000,1 hasta 4000 ml</td>
                <td>5,0 mm</td>
              </tr>

              <tr>
                <td>Mayores de 4000 ml</td>
                <td>7,0 mm</td>
              </tr>
            </table>
            <br />
            <p className="table-content__row__cell--text table-content__row__cell--text-center">
              Alrededor de la leyenda deberá existir un espacio libre de cuando menos 3 mm.
            </p>
          </div>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.2 La leyenda podrá aparecer, además, en el tapón o en el faldón de la
            corcholata, siempre y cuando sea visible y no esté cubierta por ningún tipo de sello,
            timbre o envoltura que impida al consumidor leer la leyenda completa, con las mismas
            características señaladas en el punto 9.3.7.2.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.3 En las canastillas y cartones de empaque, las leyendas deberán ostentarse
            además, en la parte superior y caras laterales, en caracteres no menores de 4mm.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.2.4 En la etiqueta de los productos con  contenido alcohólico medio y alto deberán
            incluirse los símbolos conforme a lo dispuesto en el punto A.2, del Apéndice A Normativo,
            de esta Norma, que se refieren a la prohibición de consumo a menores de 18 años, a
            mujeres embarazadas y la conducción bajo los influjos del alcohol.
            <br />

            A.2 Los Símbolos a utilizar son
            <br />
            A.2.1 Símbolo 1, Prohibición de consumo en menores de 18 años:
            <br />
            <img src="/images/no_younger.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.2 Símbolo 2, Prohibición de consumo por mujeres embarazadas:
            <br />
            <img src="/images/no_pregnant_woman.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            A.2.3 Símbolo 3, Prohibición de conducción bajo los influjos del alcohol
            <br />
            <img src="/images/no_vehicle.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            Cuando se incluyan los tres símbolos simultáneamente, éstos deberán: tener un diámetro
            mínimo de 7mm.
            <br />
            El contenido bajo va de 6.1 a 20%
            <br />
            Contenido alto: de 20.1% a 55%
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 22,
        text: (
          <div>
            <p className="table-content__row__cell--text">
              9.3.7.2.4 Las bebidas de contenido alcohólico bajo, deberán incluir únicamente el
              símbolo:
              <br />
            </p>

            <table>
              <tr>
                <td>De contenido alchólico bajo</td>
                <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
              </tr>
            </table>

            <p className="table-content__row__cell--text"> A.6 En aquellas bebidas con contenido alcohólico bajo, se deberá incluir únicamente
              el siguiente símbolo
              <br />
            </p>

            <img src="/images/younger_warning.png" alt="No apto para mujeres embarzadas" style={{ width: '1.5cm' }} />
            <br />

            <p className="table-content__row__cell--text">El cual debe de tener un diámetro mínimo de 10mm.<br /></p>
          </div>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            <b> 9.3.7.2.5 </b> En las canastillas y cartones de empaque de los productos que recibe el
            consumidor final, exceptuando al material de embalaje, los símbolos deberán ostentarse
            además, en la parte superior y caras laterales, en un tamaño mayor al que aparezca en
            la etiqueta, tapón o corcholata.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            <b>9.3.7.3 Bebidas alcohólicas con bajo contenido energético.</b>
            <br />
            9.3.7.3.1 Los productos con bajo contenido energético deben ostentar en la etiqueta la
            declaración "bajo contenido energético".
            <br />
            5.8.1 Para ser consideradas como bebidas alcohólicas con bajo contenido energético deben
            reducir el mismo, al menos en un 24% con relación al contenido energético de la bebida
            alcohólica original.
            <br />
            9.3.7.3.2 No está permitido emplear términos descriptivos relacionados con la
            modificación en el contenido energético, distinto al definido en esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            9.3.7.3.3 Aquellas bebidas alcohólicas con bajo contenido energético deberán incluir
            las leyendas precautorias correspondientes, establecidas en el Acuerdo.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8 Información comercial.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.1 En el Brandy, se incluirá la palabra "Brandy" en forma ostensible y la leyenda
            "100% de uva", la que deberá ser comprobable.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.2 Indicación de la cantidad conforme a lo establecido en la Norma Oficial
            Mexicana, citada en el punto 2.2, del Capítulo de Referencias de esta Norma.
            <br />
            2.2  Norma Oficial Mexicana NOM-030-SCFI-2006, Información comercial. Declaración de
            cantidad en la etiqueta-Especificaciones.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            9.3.8.3 Si durante la elaboración de vino se emplea viruta de roble, no se podrá
            utilizar ningún termino que confunda al consumidor haciendo referencia al uso de
            barricas de roble, como "añejado en barrica" o similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4 Información adicional.
            <br />
            9.4.1 Información sanitaria adicional.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.1 Las etiquetas podrán contener la clasificación señalada en la siguiente tabla,
            la cual, cuando se utilice, deberá aparecer como se señala y no deberá ser utilizada con
            fines promocionales, ni de publicidad de los productos. Los textos de las leyendas
            precautoriasno podrán modificarse ni usar superlativos relativos a la clasificación.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 32,
        text: (
          <table className="table-content__row__cell__table">
            <tr>
              <td>De contendio alchohólico bajo</td>
              <td>Las bebidas con un contenido alcohólico de 2,0% y hasta 6,0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico medio</td>
              <td>Las bebidas con un contenido alcohólico de 6,1% y hasta 20.0% en volumen.</td>
            </tr>

            <tr>
              <td>De contenido alcohólico alto</td>
              <td>Las bebidas con un contenido alcohólico de 20,1% y hasta 55,0% en volumen.</td>
            </tr>
          </table>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.2.1 Se podrá declarar el contenido energético por porción expresado ya sea en kJ
            o kcal, de acuerdo a los siguientes tamaños de porción de bebida estándar, con
            aproximadamente 13g de alcohol y considerando que su gravedad específica es de
            0,785g/ml:
            <br />
            9.4.1.2.1.1 Cerveza con 5% Alc. Vol., 330ml;
            <br />
            9.4.1.2.1.2 Vino con 12% Alc. Vol., 140ml;
            <br />
            9.4.1.2.1.3 Vinos fortificados (por ejemplo jerez) con 18% Alc. Vol., 90ml;
            <br />
            9.4.1.2.1.4 Licor o aperitivo con 25% Alc. Vol., 70ml, y
            <br />
            9.4.1.2.1.5 Bebidas espirituosas con 40% Alc. Vol., 40ml.
            <br />
            9.4.1.2.2 La declaración sobre el contenido energético se debe calcular utilizando los
            siguientes factores de conversión:
            <br />
            9.4.1.2.2.1 Alcohol etílico 5,5kcal/ml (23kJ/ml), y
            <br />
            9.4.1.2.2.2 Carbohidratos 4,0kcal/g (17kJ/g).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.3 En la etiqueta puede presentarse cualquier información o representación gráfica,
            así como material escrito, impreso o gráfico, por ejemplo, tratamientos o procesos
            especiales de elaboración u otras leyendas precautorias, siempre que esté de acuerdo
            con los requisitos obligatorios de la presente Norma.
            <br />
            Para efectos de esta Norma, se considera como información sanitaria aquella tendiente a
            proteger y preservar la salud de la persona y de la colectividad, a fin de alcanzar un
            estado físicamente sano de la población del país, de manera individual o concurrente.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            9.4.1.4 En el etiquetado de las bebidas alcohólicas se podrá incluir la leyenda:
            "Para mayor información ingresa a la página: www.conadic.salud.gob.mx, donde se cuenta
            con información sobre el uso nocivo del alcohol".
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 36,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            9.4.2 Información comercial adicional.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            La información comercial diferente a la prevista en el punto 9.3.8, de esta Norma, se
            considera información comercial adicional. Esta podrá consistir en antecedentes
            históricos de la bebida, recetas, dichos, frases, símbolos, abreviaturas, dígitos,
            frases publicitarias, refranes, etc., así como material escrito, impreso o gráfico,
            siempre y cuando no induzcan a error o engaño al consumidor y podrá ostentarse en
            idioma distinto al español sin necesidad de ser traducida.
          </p>
        )
      }
    ]
  }
};
