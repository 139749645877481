import React, { ReactElement, useContext } from 'react';

import { ModalComponent } from '../Modal/ModalComponent';
import { AppContext } from '../../context/AppContext';

interface Props {
  visible?: boolean
  onConfirm?: (() => void) | null
  onClose: (() => void)
  customComponent?: ReactElement | null
  fullSzie?: boolean,
  confirmationButonLabel?: string
  ableFuncionalityEnterToConfirm?: boolean
}

const ModalView: React.FC<Props> = (props) => {
  const {
    visible = false,
    customComponent = null,
    onConfirm = null,
    onClose,
    fullSzie = true,
    confirmationButonLabel = 'Ok',
    ableFuncionalityEnterToConfirm = true
  } = props;

  const {
    setOpenModal
  } = useContext(AppContext);

  return (
    <ModalComponent
      type='custom'
      visible={visible}
      fullSize={fullSzie}
      customComponent={customComponent}
      onConfirm={onConfirm}
      onCancel={onClose}
      confirmationButonLabel={confirmationButonLabel}
      setOpenModal={setOpenModal}
      ableFuncionalityEnterToConfirm={ableFuncionalityEnterToConfirm}
    />
  );
};

export default ModalView;
