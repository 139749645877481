import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  ModalView,
  Title
} from '../../../../components';

import { useResource } from '../../../../hooks';
import {
  Collaborator
} from '../../../../interfaces';

import '../ClientForm/styles.scss';
import { emptyCollaborator } from '../../../../emptyObjects';
import { validate } from '../../../../helpers';

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (_data: Collaborator) => void
  onError: (_error: string) => void
  title: string
  clientID: string
  collaborator?: Collaborator
}

const CollaboratorForm: React.FC<Props> = (props) => {
  const {
    visible = false,
    onClose = () => { },
    onSuccess = (_data: Collaborator) => { },
    onError = (_error: string) => { },
    clientID,
    collaborator = emptyCollaborator
  } = props;

  const { t } = useTranslation();

  const {
    createResource: createCollaborator,
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const [formCollaborator, setFormCollaborator] = useState<Collaborator>(collaborator);
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const onChangeInput = (value: string | number, id: string) => {
    setFormCollaborator({
      ...formCollaborator,
      [id]: value
    });
  };

  const handleCreateCollaborator = () => {
    if (validData()) {
      createCollaborator(
        'admin/collaborators',
        {
          clientID,
          name: formCollaborator.name,
          email: formCollaborator.email,
          phone: formCollaborator.phone
        },
        (data: Collaborator) => {
          setFormCollaborator(collaborator);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  const handleEditCollaborator = () => {
    if (validData()) {
      updateCollaborator(
        `admin/collaborators/${collaborator?.id}`,
        {
          name: formCollaborator.name,
          phone: formCollaborator.phone
        },
        (data: Collaborator) => {
          setFormCollaborator(collaborator);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  const validData = (): boolean => {
    if (validate.text(formCollaborator.name, 1) !== 'success') {
      setErrors({
        name: t('clients.errors.name')
      });

      return false;
    }
    if (validate.text(formCollaborator.email, 1) !== 'success') {
      setErrors({
        email: t('clients.errors.contactEmail')
      });

      return false;
    }
    if (validate.email(formCollaborator.email) !== 'success') {
      setErrors({
        email: validate.email(formCollaborator.email)
      });

      return false;
    }

    return true;
  };

  useEffect(() => {
    if (collaborator) {
      setFormCollaborator(collaborator);
    }
  }, [collaborator]);

  useEffect(() => {
    setErrors({});
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={collaborator.id
        ? () => handleEditCollaborator()
        : () => handleCreateCollaborator()}
      customComponent={
        <div className="client-form">
          <Title title={collaborator.id ? t('clients.editCollaborator') : t('clients.newCollaborator')} />
          <br />
          <div className="client-form__inputs-container">
            <Input
              title={t('clients.fullName') || ''}
              type="text"
              value={formCollaborator.name}
              id='name'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Carlos Leon Hernandez`}
              hasError={!!errors.name}
              helperText={errors.name}
            />
            <Input
              title="E-mail"
              type="email"
              value={formCollaborator.email}
              id='email'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: example@contacto.com`}
              hasError={!!errors.email}
              helperText={errors.email}
              disabled={!!collaborator.id}
            />
            <Input
              title={t('clients.phone') || ''}
              type="number"
              value={formCollaborator.phone}
              id='phone'
              onChange={(value: string | number) => {
                if (String(value).length <= 10) {
                  onChangeInput(value, 'phone');
                }
              }}
              placeholder={t('global.optional') || ''}
              hasError={!!errors.phone}
              helperText={errors.phone}
            />
          </div>
        </div>
      }
    />
  );
};

export default CollaboratorForm;
