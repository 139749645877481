/* eslint-disable max-len */
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import {
  Icon,
  Input,
  Modal,
  Table,
  Title,
  File,
  Accordeon,
  LateralMenu,
  ActionButtons
} from '..';
import { emptyTask, emptyUser } from '../../emptyObjects';
import { utils } from '../../helpers';
import { useNavigate, useResource } from '../../hooks';
import {
  ActionButton,
  Inspection,
  InspectionStatus,
  LateralMenuOption,
  Round,
  TableHeader,
  Task,
  TaskStatus,
  User
} from '../../interfaces';
import Button from '../Button';
import { IconTypes } from '../Icon/iconList';
import { ModalTaskDetail } from './components/ModalTaskDetail/ModalTaskDetail';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import storage from '../../storage';
import configuration from '../../configuration';

interface LocalInspection extends Inspection {
  'status-icon': ReactElement
  taskRound: ReactElement
  'productPresented-icon'?: ReactElement
  'productUpdated-icon': ReactElement
  'list-icon': ReactElement
  'inspection-icon': ReactElement
  'uploadedEvidence-icon': ReactElement
  'sendToReview-icon': ReactElement
}

interface TaskData {
  label: string,
  type: 'row' | 'column',
  component: ReactElement,
  icon: IconTypes
}

interface TaskDetailModal {
  visible: boolean
  taskID: string
}

type TaskDetailContentType = 'info' | 'inspections' | 'documents' | 'history';

interface Props {
  onClose: () => void
  setVisibleTaskDetailModal: (_value: TaskDetailModal) => void
  visibleTaskDetailModal: TaskDetailModal
  task?: Task
  lookInTheTrash: boolean
  onDeletedTask?: () => void
  refreshTask?: boolean
}

const TasksDetail: React.FC<Props> = (props) => {
  const {
    onClose,
    setVisibleTaskDetailModal,
    visibleTaskDetailModal,
    task: propsTask = emptyTask,
    lookInTheTrash,
    onDeletedTask,
    refreshTask = true
  } = props;

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects,
    userID
  } = useContext(AppContext);

  const {
    fetchResources
  } = useResource<Inspection>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    fetchResource: getTask,
    eraseResource: deleteTask,
    createResource,
    updateResource
  } = useResource<Task>();

  const {
    createResource: getDictumProductDocument,
    createResource: getDictumInspectionCertificateDocument
  } = useResource<any>();

  const ableDeleteUpdateTaskStatuses: TaskStatus[] = ['scheduled'];

  const [lateralMenuValue, setLateralMenuValue] = useState<TaskDetailContentType>('info');
  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [customInspections, setCustomInspections] = useState<LocalInspection[]>([]);
  const [user, setUser] = useState<User>(emptyUser);
  const [task, setTask] = useState<Task>(emptyTask);
  const [startTaskMessage, setStartTaskMessage] = useState<string>('');
  const [imageLoaded, setImageLoaded] = useState(true);
  const [pdfBlob, setPdfBlob] = useState<any>(null);
  const handleImageError = () => {
    setImageLoaded(false);
  };

  const nowFormat = `${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`;

  const TaskCardTypeDictionary = {
    'onsite-inspection': t('tasks.form.inSiteInspection'),
    'remote-inspection': t('tasks.form.remoteInspection'),
    'constancy-inspection': t('tasks.form.remoteInspection'),
    'revision-inspection': t('tasks.form.revisionInspection')
  };

  const TaskCardStatusDictionary = {
    scheduled: t('tasks.status.assigned'),
    'in-progress': t('tasks.status.inProgress'),
    concluded: t('tasks.status.concluded'),
    deleted: t('tasks.status.deleted'),
    'in-review': t('tasks.status.inReview')
  };

  const translationsTaskDetail = (key: string): string => {
    const translations: any = {
      es: {
        number: 'Número',
        description: 'Descripción',
        titleSingle: 'Tarea',
        productUpdated: 'Producto actualizado',
        uploadedEvidence: 'Evidencia subida',
        productPresented: 'Producto presentado',
        sendInspection: '¿Seguro que deseas mandar a aprobar todas las inspecciones de la tarea en su estado actual?, serán enviadas todas las inspecciones que estén dictaminadas y tengan evidencia subida.',
        sentInspection: 'Inspecciones mandadas a aprobar',
        registerHour: 'Hora de llegada registrada correctamente',
        responsible: 'Responsable',
        type: 'Tipo de Tarea',
        status: 'Estatus',
        products: 'Productos Inspeccionados',
        inspectionsLists: 'Listas de Inspección Realizadas',
        invoices: 'Folios a Inspeccionar',
        invoice: 'Folio',
        subInvoice: 'Subfolio',
        services: 'Pedimento(s)',
        askDeleteTask: '¿Seguro que deseas eliminar la tarea?',
        askCloseTask: '¿Seguro que deseas cerrar la tarea?',
        taskDeleted: 'Tarea eliminada',
        taskClosed: 'Tarea cerrada',
        client: 'Cliente',
        direcction: 'Dirección de Almacén',
        date: 'Fecha de la visita en almacén',
        time: 'Hora de la visita en almacén',
        dateAssignment: 'Fecha de asignación',
        dateScheduledTo: 'Agendada para el',
        startDate: 'Fecha de Inicio',
        finishDate: 'Fecha de Finalización',
        comments: 'Comentarios adicionales',
        author: 'Autor',
        action: 'Acción',
        dateHistory: 'Fecha',
        startDateButton: 'Registrar hora de llegada',
        sendReview: 'Mandar a aprobar',
        sings: 'Registrar firmas de testigos',
        planificationSing: 'Registrar firma del cliente',
        witnessComments: 'Registrar declaraciones de cliente e inspector',
        edit: 'Editar',
        delete: 'Eliminar',
        closeTask: 'Cerrar visita',
        startTime: 'Registra tu hora de llegada',
        actions: 'Acciones',
        generalInformation: 'Información General',
        inspections: 'Inspecciones',
        history: 'Historial',
        documents: 'Documentos',
        model: 'Modelo',
        model2: 'Modelo 2',
        norm: 'Norma',
        complies: '¿Cumple?',
        listCompleted: '¿Lista hecha?',
        sendToReview: '¿Mandado a aprobar?',
        concluded: '¿Concluida?',
        alertMessage: 'La fecha de asignación de la tarea ya pasó, es necesario reasignar la fecha para continuar con las inspecciones.',
        pastAlertMessage: 'La fecha de asignación de la tarea aún no ha llegado, espera el día o solicita la edición de la tarea.',
        startTaskAlert: 'Se debe registrar hora de llegada y firma del cliente para poder iniciar las inspecciones.',
        petitionNumber: 'Pedimento',
        round: 'Ronda',
        design: 'Diseño de etiquetas',
        closetaskAlert: 'Todas las inspecciones deben estar aprobadas para poder cerrar la tarea'
      },
      en: {
        number: 'Number',
        description: 'Description',
        titleSingle: 'Task',
        productUpdated: 'Updated product',
        uploadedEvidence: 'Uploaded evidence',
        productPresented: 'Product presented',
        sendInspection: 'Are you sure you want to send all the inspections of the task in its current state for review? All the inspections that are approved and have uploaded evidence will be sent.',
        sentInspection: 'Inspections sent for review',
        registerHour: 'Arrival time registered correctly',
        responsible: 'Responsible',
        type: 'Task type',
        status: 'Status',
        products: 'Selected products',
        inspectionsLists: 'Inspection lists completed',
        invoices: 'Invoices to inspect',
        invoice: 'Invoice',
        subInvoice: 'Subinvoice',
        services: 'Petition(s)',
        askDeleteTask: 'Are you sure you want to delete the task?',
        askCloseTask: 'Are you sure you want to close the task?',
        taskDeleted: 'Task deleted',
        taskClosed: 'Tarea closed',
        closeTask: 'Close visit',
        client: 'Client',
        direcction: 'Warehouse dddress',
        date: 'Warehouse visit date',
        time: 'Warehouse visit hour',
        dateAssignment: 'Date of assignment',
        dateScheduledTo: 'Scheduled to',
        startDate: 'Start date',
        finishDate: 'Finish date',
        comments: 'Additional comments',
        author: 'Author',
        action: 'Action',
        dateHistory: 'Date',
        startDateButton: 'Register arrival time',
        sendReview: 'Send for review',
        sings: 'Witness signatures',
        planificationSing: 'Register customer signature',
        witnessComments: 'Register customer and inspector statements',
        edit: 'Edit',
        delete: 'Delete',
        startTime: 'Register your arrival time',
        actions: 'Actions',
        generalInformation: 'General information',
        inspections: 'Inspections',
        history: 'History',
        documents: 'Documents',
        model: 'Model',
        model2: 'Model 2',
        norm: 'Norm',
        complies: 'Complies?',
        listCompleted: 'List completed?',
        sendToReview: 'Sent to approve?',
        concluded: 'Concluded?',
        alertMessage: 'The task assignment date has already passed, it is necessary to reassign the date to continue with the inspections.',
        pastAlertMessage: 'The task assignment date has not yet arrived, wait for the assigned day or request to edit the task',
        startTaskAlert: 'The time of arrival and signature of the client must be recorded in order to start the inspections.',
        petitionNumber: 'Petition',
        round: 'Round',
        design: 'Design',
        closetaskAlert: 'All inspections must be approved in order to close the task'
      }
    };

    return translations[i18n.language][key];
  };

  const tableTabs: LateralMenuOption[] = [
    {
      label: translationsTaskDetail('generalInformation'),
      value: 'info'
    },
    {
      label: translationsTaskDetail('inspections'),
      value: 'inspections'
    },
    {
      label: translationsTaskDetail('history'),
      value: 'history'
    }
  ];

  const onSiteTableTabs: LateralMenuOption[] = [
    {
      label: translationsTaskDetail('generalInformation'),
      value: 'info'
    },
    {
      label: translationsTaskDetail('inspections'),
      value: 'inspections'
    },
    {
      label: translationsTaskDetail('documents'),
      value: 'documents'
    },
    {
      label: translationsTaskDetail('history'),
      value: 'history'
    }
  ];

  const remoteTableHeaders: TableHeader[] = [
    {
      label: translationsTaskDetail('status'),
      value: 'status-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: translationsTaskDetail('model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: translationsTaskDetail('description'),
      value: 'product.description',
      format: 'none',
      limitLength: 40
    },
    {
      label: translationsTaskDetail('norm'),
      value: 'norm',
      format: 'none'
    },
    {
      label: translationsTaskDetail('design'),
      value: 'indicators.design',
      format: 'boolean'
    },
    {
      label: translationsTaskDetail('round'),
      value: 'taskRound',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('productUpdated'),
      value: 'productUpdated-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('listCompleted'),
      value: 'list-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('uploadedEvidence'),
      value: 'uploadedEvidence-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('complies'),
      value: 'inspection-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('sendToReview'),
      value: 'sendToReview-icon',
      format: 'custom-component'
    }
  ];

  const tableHeaders: TableHeader[] = [
    {
      label: translationsTaskDetail('petitionNumber'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: translationsTaskDetail('status'),
      value: 'status-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: translationsTaskDetail('subInvoice'),
      value: 'product.subInvoice',
      format: 'none'
    },
    {
      label: translationsTaskDetail('model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: translationsTaskDetail('model2'),
      value: 'product.code2',
      format: 'none'
    },
    {
      label: translationsTaskDetail('description'),
      value: 'product.description',
      format: 'none',
      limitLength: 90
    },
    {
      label: translationsTaskDetail('norm'),
      value: 'norm',
      format: 'none'
    },
    {
      label: translationsTaskDetail('round'),
      value: 'taskRound',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('productPresented'),
      value: 'productPresented-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('productUpdated'),
      value: 'productUpdated-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('listCompleted'),
      value: 'list-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('uploadedEvidence'),
      value: 'uploadedEvidence-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('complies'),
      value: 'inspection-icon',
      format: 'custom-component'
    },
    {
      label: translationsTaskDetail('sendToReview'),
      value: 'sendToReview-icon',
      format: 'custom-component'
    }
  ];

  const handleCloseTask = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      translationsTaskDetail('askCloseTask'),
      () => {
        updateResource(
          `admin/tasks/${task.id}/concluded`,
          {},
          (data) => {
            Modal.fireSuccess(
              t('global.correct'),
              translationsTaskDetail('taskClosed'),
              setOpenModal,
              () => {
                setTask(data);
                if (onDeletedTask) {
                  onDeletedTask();
                }
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const handleDeleteTask = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      translationsTaskDetail('askDeleteTask'),
      () => {
        deleteTask(
          `admin/tasks/${task.id}/${task.type}/delete`,
          {},
          () => {
            setVisibleTaskDetailModal({
              taskID: '',
              visible: false
            });

            storage.set('lastTaskDetailID', '');

            Modal.fireSuccess(
              t('global.correct'),
              translationsTaskDetail('taskDeleted'),
              setOpenModal,
              () => {
                if (onDeletedTask) {
                  onDeletedTask();
                }
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const handleGenerateAssignmentAndInspectionPlanningDocument = async (
    petitionNumber: string
  ) => {
    getDictumProductDocument(
      `/${apiType}/tasks/${task.id}/assignation-and-planification-of-the-inspection`,
      {
        petitionNumber
      },
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `PLANIFICACION - ${petitionNumber} - ${task.client.shortName} - ${task.number}.pdf`;
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleGenerateInspectionCertificateDocument = async (petitionNumber: string) => {
    getDictumInspectionCertificateDocument(
      `/${apiType}/tasks/${task.id}/inspection-certificate`,
      {
        petitionNumber
      },
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `ACTA DE INSPECCION - ${petitionNumber} - ${task.client.shortName} - ${task.number}.pdf`;
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleShowInspectionCertificateDocument = async (petitionNumber: string) => {
    getDictumInspectionCertificateDocument(
      `/${apiType}/tasks/${task.id}/inspection-certificate`,
      {
        petitionNumber
      },
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        setPdfBlob(blob);

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as TaskDetailContentType));
  };

  const handleRegisterCheckIn = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('tasks.form.checkInAsk', { checkInTime: `${dayjs(new Date()).format('hh:mm a')}` }),
      () => {
        createResource(
          `/admin/tasks/${task.id}/check-in`,
          {
            hour: Number(dayjs(new Date()).format('HH')),
            minute: Number(dayjs(new Date()).format('mm'))
          },
          (data) => {
            Modal.fireSuccess(
              t('global.correct'),
              translationsTaskDetail('registerHour'),
              setOpenModal,
              () => {
                setTask(data);
                setVisibleTaskDetailModal({ ...visibleTaskDetailModal, visible: true });
                if (onDeletedTask) {
                  onDeletedTask();
                }
              },
              soundEffects
            );
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const showAlertMessage = (): string => {
    const scheduledTo = dayjs(String(task.datesRegistry.scheduledTo).substring(0, 10));

    if (task.type === 'onsite-inspection'
      && dayjs(nowFormat).diff(scheduledTo, 'day') >= 1
      && task.status === 'scheduled') {
      return translationsTaskDetail('alertMessage');
    }

    if (task.type === 'onsite-inspection'
      && dayjs(nowFormat).diff(scheduledTo, 'day') <= 1
      && dayjs(nowFormat).diff(scheduledTo, 'day') !== 0
      && task.status === 'scheduled'
      && user.id === task.responsible.id) {
      return translationsTaskDetail('pastAlertMessage');
    }

    return '';
  };

  const setdisableInspectionCertificate = (): boolean => {
    if (task.visit) {
      if (task.visit?.witnesses.length > 0
        && task.visit?.visitorStatements !== null
        && task.datesRegistry.endedAt !== null) {
        return false;
      }
    }

    return true;
  };

  const getContent = useCallback(() => {
    let remoteTaskData: TaskData[] = [];

    if (apiType === 'admin') {
      remoteTaskData.push(
        {
          label: translationsTaskDetail('responsible'),
          type: 'row',
          component: (
            <div onClick={() => navigate(`/user/detail/${task.responsible.id}`)} className='task-detail__link'>
              {
                task.responsible.picture.length > 0
                && imageLoaded && (
                  <img
                    className='task-detail__info__data__profile-picture'
                    src={task.responsible.picture}
                    onError={handleImageError}
                  />
                )
              }
              <p className='task-detail__data'>{task.responsible.name}</p>
            </div>
          ),
          icon: 'inspector'
        }
      );
    }

    const auxRemoteTaskData: TaskData[] = [
      // {
      //   label: translationsTaskDetail('responsible'),
      //   type: 'row',
      //   component: (
      //     <div onClick={() => navigate(`/user/detail/${task.responsible.id}`)} className='task-detail__link'>
      //       {
      //         task.responsible.picture.length > 0
      //         && imageLoaded && (
      //           <img
      //             className='task-detail__info__data__profile-picture'
      //             src={task.responsible.picture}
      //             onError={handleImageError}
      //           />
      //         )
      //       }
      //       <p className='task-detail__data'>{task.responsible.name}</p>
      //     </div>
      //   ),
      //   icon: 'inspector'
      // },
      {
        label: translationsTaskDetail('type'),
        type: 'row',
        component: (
          <span className={`task-detail__task-type task-detail__task-type--${task.type}`}>{TaskCardTypeDictionary[task.type]}</span>
        ),
        icon: 'taskDocument'
      },
      {
        label: translationsTaskDetail('status'),
        type: 'row',
        component: (
          <span className='task-detail__task-status'>{TaskCardStatusDictionary[task.status]}</span>
        ),
        icon: 'taskStatus'
      },
      {
        label: translationsTaskDetail('products'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{`${task.indicators.completedInspectionsQuantity} de ${task.indicators.inspectionsQuantity}`}</p>
        ),
        icon: 'inspectorGrey'
      },
      {
        label: translationsTaskDetail('inspectionsLists'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{`${task.indicators.completedInspectionListsQuantity} de ${task.indicators.inspectionsQuantity}`}</p>
        ),
        icon: 'inspectionList'
      },
      {
        label: translationsTaskDetail('client'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{task.client.businessName}</p>
        ),
        icon: 'clientsLogoGrey'
      },
      {
        label: translationsTaskDetail('dateAssignment'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date', task.createdAt)}</p>
        ),
        icon: 'editService'
      },
      {
        label: translationsTaskDetail('dateScheduledTo'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date', task.datesRegistry.scheduledTo)}</p>
        ),
        icon: 'clock'
      },
      {
        label: translationsTaskDetail('startDate'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date-time', task.datesRegistry.startedAt)}</p>
        ),
        icon: 'calendarMinimal'
      },
      {
        label: translationsTaskDetail('finishDate'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date-time', task.datesRegistry.endedAt)}</p>
        ),
        icon: 'calendarMinimal'
      },
      {
        label: translationsTaskDetail('invoices'),
        type: 'column',
        component: (
          <p className='task-detail__data'>{utils.formatValue('list', task.invoices)}</p>
        ),
        icon: 'magnifyingGlass'
      },
      {
        label: translationsTaskDetail('services'),
        type: 'column',
        component: (
          <p className='task-detail__data'>{utils.formatValue('list', task.services)}</p>
        ),
        icon: 'closed'
      },
      {
        label: translationsTaskDetail('comments'),
        type: 'column',
        component: (
          <Input
            type='textarea'
            value={task.comments || ''}
            onChange={(_value) => { }}
            disabled={true}
          />
        ),
        icon: 'validInvoices'
      }
    ];

    remoteTaskData = [...remoteTaskData, ...auxRemoteTaskData];

    let onSiteTaskData: TaskData[] = [];

    if (apiType === 'admin') {
      onSiteTaskData.push({
        label: translationsTaskDetail('responsible'),
        type: 'row',
        component: (
          <div onClick={() => navigate(`/user/detail/${task.responsible.id}`)} className='task-detail__link'>
            {
              imageLoaded && (
                <img
                  className='task-detail__info__data__profile-picture'
                  src={task.responsible.picture}
                  onError={handleImageError}
                />
              )
            }
            <p className='task-detail__data'>{task.responsible.name}</p>
          </div>
        ),
        icon: 'inspector'
      });
    }

    const auxOnSiteTaskData: TaskData[] = [
      // {
      //   label: translationsTaskDetail('responsible'),
      //   type: 'row',
      //   component: (
      //     <div onClick={() => navigate(`/user/detail/${task.responsible.id}`)} className='task-detail__link'>
      //       {
      //         imageLoaded && (
      //           <img
      //             className='task-detail__info__data__profile-picture'
      //             src={task.responsible.picture}
      //             onError={handleImageError}
      //           />
      //         )
      //       }
      //       <p className='task-detail__data'>{task.responsible.name}</p>
      //     </div>
      //   ),
      //   icon: 'inspector'
      // },
      {
        label: translationsTaskDetail('type'),
        type: 'row',
        component: (
          <span className={`task-detail__task-type task-detail__task-type--${task.type}`}>{TaskCardTypeDictionary[task.type]}</span>
        ),
        icon: 'taskDocument'
      },
      {
        label: translationsTaskDetail('status'),
        type: 'row',
        component: (
          <span className='task-detail__task-status'>{TaskCardStatusDictionary[task.status]}</span>
        ),
        icon: 'taskStatus'
      },
      {
        label: translationsTaskDetail('products'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{`${task.indicators.completedInspectionsQuantity} de ${task.indicators.inspectionsQuantity}`}</p>
        ),
        icon: 'inspectorGrey'
      },
      {
        label: translationsTaskDetail('inspectionsLists'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{`${task.indicators.completedInspectionListsQuantity} de ${task.indicators.inspectionsQuantity}`}</p>
        ),
        icon: 'inspectionList'
      },
      {
        label: translationsTaskDetail('client'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{task.client.businessName}</p>
        ),
        icon: 'clientsLogoGrey'
      },
      {
        label: translationsTaskDetail('direcction'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{task.visit?.address}</p>
        ),
        icon: 'mapDot'
      },
      {
        label: translationsTaskDetail('dateAssignment'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date', task.createdAt)}</p>
        ),
        icon: 'editService'
      },
      {
        label: translationsTaskDetail('date'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date', task.datesRegistry.scheduledTo)}</p>
        ),
        icon: 'calendar'
      },
      {
        label: translationsTaskDetail('time'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('time', task.visit?.time || '')}</p>
        ),
        icon: 'clock'
      },
      {
        label: translationsTaskDetail('startDate'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date-time', task.datesRegistry.startedAt)}</p>
        ),
        icon: 'calendarMinimal'
      },
      {
        label: translationsTaskDetail('finishDate'),
        type: 'row',
        component: (
          <p className='task-detail__data'>{utils.formatValue('date-time', task.datesRegistry.endedAt)}</p>
        ),
        icon: 'calendarMinimal'
      },
      {
        label: translationsTaskDetail('invoices'),
        type: 'column',
        component: (
          <p className='task-detail__data'>{utils.formatValue('list', task.invoices)}</p>
        ),
        icon: 'magnifyingGlass'
      },
      {
        label: translationsTaskDetail('services'),
        type: 'column',
        component: (
          <p className='task-detail__data'>{utils.formatValue('list', task.services)}</p>
        ),
        icon: 'closed'
      },
      {
        label: translationsTaskDetail('comments'),
        type: 'column',
        component: (
          <Input
            type='textarea'
            value={task.comments || ''}
            onChange={(_value) => { }}
            disabled={true}
          />
        ),
        icon: 'validInvoices'
      }
    ];

    onSiteTaskData = [...onSiteTaskData, ...auxOnSiteTaskData];

    const contents = {
      info: (
        <div className='task-detail__info-container'>
          {translationsTaskDetail('titleSingle')}: {task.number}
          <br />
          <div className='task-detail__info-container__share-container'>
            <Button
              type='secondary-outlined'
              onClick={async () => {
                try {
                  // eslint-disable-next-line no-undef
                  await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/tasks.${task.id}`).then(() => {
                    Modal.fireSuccess(
                      t('global.correct'),
                      t('global.shareSuccess'),
                      setOpenModal,
                      undefined,
                      soundEffects
                    );
                  });
                } catch (err) {
                  Modal.fireSuccess(
                    t('global.correct'),
                    `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/tasks.${task.id}`,
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                }
              }}
              icon='share'
              rounded={true}
              label={t('global.share') || ''}
              size='small'
            />
          </div>
          {
            showAlertMessage() !== '' && task.indicators.waitingToExpired === false && apiType === 'admin' && (
              <p className='task-detail__alert-message'>{showAlertMessage()}</p>
            )
          }
          {
            startTaskMessage !== '' && task.indicators.waitingToExpired === false && (
              <p className='task-detail__alert-message'>{startTaskMessage}</p>
            )
          }
          {
            inspections.every((inspection) => ['in-review', 'concluded'].includes(inspection.status))
            && task.status === 'in-progress' && (
              <p className='task-detail__alert-message'>{translationsTaskDetail('closetaskAlert')}</p>
            )
          }
          {
            (task.type === 'onsite-inspection' ? onSiteTaskData : remoteTaskData).map((item: TaskData) => {
              return (
                <div className={`task-detail__info task-detail__info${item.type === 'column' ? '--column' : ''}`} key={item.label}>
                  <div className={`task-detail__info__title task-detail__info__title${item.type === 'column' ? '--full-width' : ''}`}>
                    <div className='task-detail__info__title__icon-container'>
                      <Icon type={item.icon} className='task-detail__info__title__icon' />
                    </div>
                    <p className='task-detail__data'>{item.label}</p>
                  </div>
                  <div className={`task-detail__info__data task-detail__info__data${item.type === 'column' ? '--full-width' : ''}`}>
                    {item.component}
                  </div>
                </div>
              );
            })
          }
        </div>
      ),
      inspections: (
        <Table
          headers={task.type === 'onsite-inspection' ? tableHeaders : remoteTableHeaders}
          items={customInspections}
          onClickRow={(item: LocalInspection) => {
            storage.set('lastTaskDetailID', '');
            const inspectionDetailRoute = '/inspection/detail/';
            const inspectionIDDetailRoute = `/inspection/detail/${item.id}/none/none/none`;

            // eslint-disable-next-line no-undef
            if (window.location.href.includes(inspectionIDDetailRoute)) {
              setVisibleTaskDetailModal({ ...visibleTaskDetailModal, visible: false });
              // eslint-disable-next-line no-undef
            } else if (window.location.href.includes(inspectionDetailRoute)) {
              setVisibleTaskDetailModal({ ...visibleTaskDetailModal, visible: false });
              navigate(`/inspection/detail/${item.id}/none/none/none`);
            } else {
              navigate(`/inspection/detail/${item.id}/none/none/none`);
            }
          }}
        />
      ),
      documents: (
        <div className='task-detail__documents'>
          <Title title={t('tasks.documents')} />
          <div className='task-detail__documents__list'>
            {
              task.services.map((service: string) => (
                <div className='task-detail__documents__list__items'>
                  <File
                    name={`ACTA DE INSPECCION - ${service} - ${task.client.shortName} - ${task.number}.pdf`}
                    onDownload={() => handleGenerateInspectionCertificateDocument(service)}
                    disabledButton={setdisableInspectionCertificate()}
                    orientation='horizontal'
                    showToolTip={false}
                    hoverEffect={true}
                    onClick={() => handleShowInspectionCertificateDocument(service)}
                  />
                  <File
                    name={`PLANIFICACION - ${service} - ${task.client.shortName} - ${task.number}.pdf`}
                    onDownload={() => handleGenerateAssignmentAndInspectionPlanningDocument(service)}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                </div>
              ))
            }
            {
              pdfBlob && (
                <div className='task-detail__documents__list__pdf'>
                  <object
                    aria-label="PDF Viewer"
                    data={`${URL.createObjectURL(pdfBlob)}#toolbar=0`}
                    style={{
                      width: '100%',
                      height: '90vh',
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                      justifyContent: 'center'
                    }}
                    type="application/pdf"
                  >
                    <p>
                      Tu navegador no soporta la visualización de archivos PDF.
                    </p>
                  </object>
                </div>
              )
            }
          </div>
        </div>
      ),
      history: (
        <div className='task-detail__history-table-container'>
          <Title title="Historial" type="primary" />
          <br />
          <Table
            headers={[
              {
                label: translationsTaskDetail('author'),
                value: 'author',
                format: 'none'
              },
              {
                label: translationsTaskDetail('action'),
                value: 'action',
                format: 'none'
              },
              {
                label: translationsTaskDetail('dateHistory'),
                value: 'date',
                format: 'date-time'
              }
            ]}
            items={task.tracking}
          />
        </div>
      )
    };

    return contents[lateralMenuValue];
  }, [
    lookInTheTrash,
    lateralMenuValue,
    task,
    customInspections,
    inspections,
    pdfBlob,
    apiType
  ]);

  useEffect(() => {
    setPdfBlob(null);
  }, [lateralMenuValue]);

  const uploadedEvidenceIcon = (
    _productNotPresented: boolean,
    _uploadedEvidence: boolean
  ): IconTypes => {
    if (_productNotPresented) {
      return 'circleError';
    }

    if (_uploadedEvidence) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const listIcon = (
    _productNotPresented: boolean,
    _listCompletedIndicator: boolean,
    _itsFirstRound: boolean
  ): IconTypes => {
    if (_productNotPresented) {
      return 'circleError';
    }

    if (!_itsFirstRound) {
      if (_listCompletedIndicator) {
        return 'circleSucces';
      }

      return 'circleUnconcluded';
    }

    if (_listCompletedIndicator) {
      return 'circleSucces';
    }

    return 'circleError';
  };

  const productUpdatedIcon = (
    _productNotPresented: boolean,
    _productUpdated: string | undefined
  ): IconTypes => {
    if (_productNotPresented) {
      return 'circleError';
    }

    if (_productUpdated) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const setCompliesIcon = (
    _complies: boolean | null | undefined,
    _productNotPresented: boolean
  ): IconTypes => {
    if (_productNotPresented) {
      return 'circleError';
    }

    if (_complies === null || _complies === undefined) {
      return 'circleUnconcluded';
    }
    if (_complies === true) {
      return 'circleSucces';
    }

    if (_complies === false) {
      return 'circleError';
    }

    return 'circleUnconcluded';
  };

  const setSendToReviewIcon = (
    status: InspectionStatus,
    itsFirstRound: boolean
  ) => {
    if (['concluded', 'deleted', 'in-review'].includes(status)
      || itsFirstRound) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const setProductPresentedIcon = (
    _productNotPresented: boolean,
    _productUpdated: boolean
  ): IconTypes => {
    if (_productNotPresented === true) {
      return 'circleError';
    }

    if (!_productUpdated) {
      return 'circleUnconcluded';
    }

    if (_productNotPresented === false) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const getRound = (inspection: Inspection): Round => {
    let result: Round = utils.getCurrentRound(inspection.rounds);

    const findedRound = inspection.rounds.find((_round) => _round.task?.id === task.id);

    if (findedRound !== undefined) {
      result = findedRound;
    } else {
      for (let index = 0; index < inspection.rounds.length; index += 1) {
        if (inspection.rounds[index].task !== null) {
          const taskRoundID = inspection.rounds[index].task?.id;
          if (taskRoundID === task.id) {
            result = inspection.rounds[index];
          }
        }
      }
    }

    return result;
  };

  useEffect(() => {
    setCustomInspections(
      inspections.map((inspection: Inspection) => {
        const round = getRound(inspection);

        const itsFirstRound = utils.getCurrentRound(inspection.rounds).id > round.id;

        if (['DC', 'DN'].includes(inspection.service.code)) {
          return ({
            ...inspection,
            'status-icon': (<div className='task-detail__alert-status'>
              {
                inspection.indicators.editInspection && (
                  <Icon type={'alertRed'} className='task-detail__alert-status__icon' />
                )
              }
              {
                inspection.indicators.urgent === true && (
                  <span className="task-detail__alert-status__alert">
                    <p>{t('global.urgent').toLocaleUpperCase()}</p>
                  </span>
                )
              }
              {utils.formatValue('status', inspection.status)}
            </div>),
            taskRound: (
              <>{round.id}</>
            ),
            'productPresented-icon': (
              <Icon type={setProductPresentedIcon(
                round.productNotPresented,
                round.steps.find(element => element === 'product-updated') !== undefined
              )} className='task-detail__table-icon' />
            ),
            'productUpdated-icon': (
              <Icon type={productUpdatedIcon(
                round.productNotPresented,
                round.steps.find(element => element === 'product-updated')
              )} className='task-detail__table-icon' />
            ),
            'list-icon': (
              <Icon type={listIcon(
                round.productNotPresented,
                round.steps.find(element => element === 'list-completed') !== undefined,
                itsFirstRound
              )} className='task-detail__table-icon' />
            ),
            'inspection-icon': (
              <Icon type={setCompliesIcon(
                round.complies,
                round.productNotPresented
              )} className='task-detail__table-icon' />
            ),
            'uploadedEvidence-icon': (
              <Icon type={uploadedEvidenceIcon(
                round.productNotPresented,
                round.steps.find(element => element === 'uploaded-evidence') !== undefined
              )} className='task-detail__table-icon' />
            ),
            'sendToReview-icon': (
              <Icon type={setSendToReviewIcon(
                inspection.status,
                itsFirstRound
              )} className='task-detail__table-icon' />
            )
          });
        }

        return ({
          ...inspection,
          'status-icon': (<div className='task-detail__alert-status'>
            {
              inspection.indicators.editInspection && (
                <Icon type={'alertRed'} className='task-detail__alert-status__icon' />
              )
            }
            {
              inspection.indicators.urgent === true && (
                <span className="task-detail__alert-status__alert">
                  <p>{t('global.urgent').toLocaleUpperCase()}</p>
                </span>
              )
            }
            {utils.formatValue('status', inspection.status)}
          </div>),
          taskRound: (
            <>{round.id}</>
          ),
          'productUpdated-icon': (
            <Icon type={productUpdatedIcon(
              round.productNotPresented,
              round.steps.find(element => element === 'product-updated')
            )} className='task-detail__table-icon' />
          ),
          'list-icon': (
            <Icon type={listIcon(
              round.productNotPresented,
              round.steps.find(element => element === 'list-completed') !== undefined,
              itsFirstRound
            )} className='task-detail__table-icon' />
          ),
          'inspection-icon': (
            <Icon type={setCompliesIcon(
              round.complies,
              round.productNotPresented
            )} className='task-detail__table-icon' />
          ),
          'uploadedEvidence-icon': (
            <Icon type={uploadedEvidenceIcon(
              round.productNotPresented,
              round.steps.find(element => element === 'uploaded-evidence') !== undefined
            )} className='task-detail__table-icon' />
          ),
          'sendToReview-icon': (
            <Icon type={setSendToReviewIcon(
              inspection.status,
              itsFirstRound
            )} className='task-detail__table-icon' />
          )
        });
      })
    );
  }, [
    inspections,
    user,
    task,
    apiType
  ]);

  useEffect(() => {
    if (visibleTaskDetailModal.visible) {
      storage.set('lastTaskDetailID', task.id);
      fetchResources(
        {
          resourcePath: `/${apiType}/inspections`,
          filters: {
            s_roundTaskID: visibleTaskDetailModal.taskID,
            b_lookInTheTrash: lookInTheTrash ? 'true' : ''
          }
        },
        (data) => {
          setInspections(data.items);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [visibleTaskDetailModal, task, lookInTheTrash]);

  useEffect(() => {
    setLateralMenuValue('info');
  }, [visibleTaskDetailModal.visible]);

  useEffect(() => {
    if (apiType === 'admin' && userRole !== 'collaborator') {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [apiType, userRole]);

  useEffect(() => {
    if (visibleTaskDetailModal.taskID && visibleTaskDetailModal.visible) {
      if (propsTask.id === '') {
        getTask(
          `/${apiType}/tasks/${visibleTaskDetailModal.taskID}?b_lookInTheTrash=${lookInTheTrash ? 'true' : ''}`,
          (data) => {
            setTask(data);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  }, [
    lookInTheTrash,
    apiType,
    refreshTask,
    visibleTaskDetailModal
  ]);

  useEffect(() => {
    const today = dayjs();

    const scheduledTo = dayjs(String(task.datesRegistry.scheduledTo).substring(0, 10));

    if (task.type === 'onsite-inspection'
      && user.id === task.responsible.id
      && scheduledTo.isSame(today, 'day')
      && (task.datesRegistry.startedAt === null
        || (task.visit ? task.visit?.planificationSignature.signature === '' : false))
    ) {
      setStartTaskMessage(translationsTaskDetail('startTaskAlert'));
    } else {
      setStartTaskMessage('');
    }
  }, [task, user]);

  const ifTaskHaveWitnessSigns = (): boolean => {
    if (task.visit) {
      if (task.visit?.witnesses.length === 0) {
        return false;
      }
    }

    return true;
  };

  const ableUploadWitnessInformation = (): boolean => {
    let concludedInspections = 0;
    let inProgressInspections = 0;
    let inReviewInspections = 0;
    let unAtachedInspections = 0;

    for (let index = 0; index < inspections.length; index += 1) {
      if (inspections[index].status === 'concluded') {
        concludedInspections += 1;
      }
      if (inspections[index].status === 'in-progress'
        && utils.getCurrentRound(inspections[index].rounds).task?.id === task.id) {
        inProgressInspections += 1;
      }
      if (inspections[index].status === 'in-review') {
        // eslint-disable-next-line no-unused-vars
        inReviewInspections += 1;
      }
      if (utils.getCurrentRound(inspections[index].rounds).task?.id !== task.id) {
        unAtachedInspections += 1;
      }
    }

    if (task.status === 'in-progress'
      && (inProgressInspections === inspections.length
        || ((inProgressInspections + concludedInspections + unAtachedInspections) === inspections.length))) {
      return true;
    }

    if (task.status === 'in-progress'
      && concludedInspections === inspections.length) {
      return true;
    }

    if (task.status === 'in-progress'
      && unAtachedInspections === inspections.length) {
      return true;
    }

    if (task.status === 'in-progress'
      && (unAtachedInspections + concludedInspections) === inspections.length) {
      return true;
    }

    return false;
  };

  const ableClosetask = (): boolean => {
    let unattachedInspections = 0;
    let inReviewOrConcludedInspections = 0;

    for (let index = 0; index < inspections.length; index += 1) {
      if (utils.getCurrentRound(inspections[index].rounds).task?.id !== task.id) {
        unattachedInspections += 1;
      }
      if (['concluded', 'in-review'].includes(inspections[index].status)
        && utils.getCurrentRound(inspections[index].rounds).task?.id === task.id) {
        inReviewOrConcludedInspections += 1;
      }
    }

    if (['in-progress'].includes(task.status)
      && ((unattachedInspections + inReviewOrConcludedInspections) === inspections.length)
      && (task.visit !== null ? task.visit!.visitorStatements !== null : false)
      && (task.visit !== null ? task.visit!.witnesses.length > 0 : false)) {
      return true;
    }

    return false;
  };

  const ableToEditOrDeleteTask = (): boolean => {
    const scheduledTo = dayjs(String(task.datesRegistry.scheduledTo).substring(0, 10));

    if (dayjs(nowFormat).diff(scheduledTo, 'day') >= 1 && task.datesRegistry.endedAt === null) {
      return true;
    }

    return ableDeleteUpdateTaskStatuses.includes(task.status);
  };

  useEffect(() => {
    let ifEveryInspectionsConcludedOrDetached = true;
    let scheduledToToday = false;

    if (inspections.length > 0) {
      for (let index = 0; index < inspections.length; index += 1) {
        const round = utils.getCurrentRound(inspections[index].rounds);

        if (round.task !== null) {
          if (round.task.id === task.id) {
            if (!['concluded'].includes(inspections[index].status)) {
              ifEveryInspectionsConcludedOrDetached = false;
            }
          }
        }
      }
    }

    const today = dayjs();

    const scheduledTo = dayjs(String(task.datesRegistry.scheduledTo).substring(0, 10));

    if (scheduledTo.isSame(today, 'day')) {
      scheduledToToday = true;
    } else {
      scheduledToToday = false;
    }

    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              handleRegisterCheckIn();
            }}
            type='primary-outlined'
            label={translationsTaskDetail('startDateButton')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='checkIn'
            alignContent='left'
          />
        ),
        available: task.type === 'onsite-inspection'
          && task.datesRegistry.startedAt === null
          && user.id === task.responsible.id
          && task.indicators.waitingToExpired === false
          && scheduledToToday,
        specialValidation: task.type === 'onsite-inspection' && userRole !== 'quality'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/planification-signature/${task.id}`)}
            type='primary-outlined'
            label={translationsTaskDetail('planificationSing')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='signature'
            alignContent='left'
          />
        ),
        available: user.id === task.responsible.id
          && task.type === 'onsite-inspection'
          && (task.visit ? task.visit?.planificationSignature.signature === '' : false)
          && task.indicators.waitingToExpired === false
          && scheduledToToday
          && task.datesRegistry.startedAt !== null,
        specialValidation: task.type === 'onsite-inspection' && userRole !== 'quality'
      },
      {
        button: (
          <Button
            onClick={() => {
              storage.set('lastTaskDetailID', task.id);
              navigate(`/witnesses-information/${task.id}`);
            }}
            type='primary-outlined'
            label={translationsTaskDetail('sings')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='signature'
            alignContent='left'
          />
        ),
        available: (ifEveryInspectionsConcludedOrDetached
          && user.id === task.responsible.id
          && ifTaskHaveWitnessSigns() === false
          && ableUploadWitnessInformation()
          && ['in-progress'].includes(task.status))
          || (ifEveryInspectionsConcludedOrDetached
            && user.id === task.responsible.id
            && ifTaskHaveWitnessSigns() === false
            && ableUploadWitnessInformation()
            && ['in-progress'].includes(task.status)
            && task.indicators.waitingToExpired === true),
        specialValidation: task.type === 'onsite-inspection' && userRole !== 'quality'
      },
      {
        button: (
          <Button
            onClick={() => {
              storage.set('lastTaskDetailID', task.id);
              navigate(`/client-inspector-statements/${task.id}`);
            }}
            type='primary-outlined'
            label={translationsTaskDetail('witnessComments')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editService'
            alignContent='left'
          />
        ),
        available: (user.id === task.responsible.id
          && task.type === 'onsite-inspection'
          && ifTaskHaveWitnessSigns() === true
          && task.visit?.visitorStatements === null
          && ableUploadWitnessInformation()
          && ['in-progress'].includes(task.status))
          || (user.id === task.responsible.id
            && task.type === 'onsite-inspection'
            && ifTaskHaveWitnessSigns() === true
            && task.visit?.visitorStatements === null
            && ableUploadWitnessInformation()
            && ['in-progress'].includes(task.status)
            && task.indicators.waitingToExpired === true),
        specialValidation: task.type === 'onsite-inspection' && userRole !== 'quality'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/${task.id}/none/none`)}
            type='primary-outlined'
            label={translationsTaskDetail('edit')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='edit'
            alignContent='left'
          />
        ),
        available: ['master', 'operator', 'inspector'].includes(userRole || '')
          && ableToEditOrDeleteTask()
          && ['scheduled'].includes(task.status)
          && (userRole === 'inspector' ? userID === '65b19002e12252003bda789e' : true),
        specialValidation: ['master', 'operator', 'inspector'].includes(userRole || '') && (userRole === 'inspector' ? userID === '65b19002e12252003bda789e' : true)
      },
      {
        button: (
          <Button
            onClick={handleDeleteTask}
            type='primary-outlined'
            label={translationsTaskDetail('delete')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='trash'
            alignContent='left'
          />
        ),
        available: ['master', 'operator', 'inspector'].includes(userRole || '')
          && ableToEditOrDeleteTask()
          && ['scheduled'].includes(task.status)
          && (userRole === 'inspector' ? userID === '65b19002e12252003bda789e' : true),
        specialValidation: ['master', 'operator', 'inspector'].includes(userRole || '') && (userRole === 'inspector' ? userID === '65b19002e12252003bda789e' : true)
      },
      {
        button: (
          <Button
            onClick={handleCloseTask}
            type='primary-outlined'
            label={translationsTaskDetail('closeTask')}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='closed'
            alignContent='left'
          />
        ),
        available: (user.id === task.responsible.id
          && task.datesRegistry.endedAt === null
          && ableClosetask())
          || (user.id === task.responsible.id
            && task.datesRegistry.endedAt === null
            && ableClosetask()
            && task.indicators.waitingToExpired === true),
        specialValidation: task.type === 'onsite-inspection' && userRole !== 'quality'
      }
    ]);
  }, [task, user, i18n.language, inspections, userID]);

  return (
    <>
      <ModalTaskDetail
        type='custom'
        visible={visibleTaskDetailModal.visible}
        customComponent={
          <div className='task-detail'>
            <div className='task-detail__main'>
              <div className='task-detail__left-container'>
                <Accordeon
                  items={[
                    {
                      title: 'Menu',
                      element: (
                        <div >
                          <LateralMenu
                            value='info'
                            onSelect={handleSelectLateralMenu}
                            options={task.type === 'onsite-inspection' ? onSiteTableTabs : tableTabs}
                          />
                        </div>
                      )
                    },
                    {
                      title: translationsTaskDetail('actions'),
                      element: (
                        <ActionButtons buttons={buttons} />
                      )
                    }
                  ]}
                />
              </div>
              <div className='task-detail__right-container'>
                {getContent()}
              </div>
            </div>
          </div>
        }
        onCancel={onClose}
      />
    </>
  );
};

export default TasksDetail;
