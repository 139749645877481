import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Header, Title } from '../../components';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

export const Profile = () => {
  const { t } = useTranslation();

  const {
    // eslint-disable-next-line no-unused-vars
    userRole
  } = useContext(AppContext);

  return (
    <div className="help">
      <Header
        title={t('menu.help')}
        showBackbutton={true}
      />
      <div className="help__main">
        <Title title={t('global.support')} />
        <a
          href="mailto:contacto@cecsatrade.mx"
          target="_blank"
        >
          contacto@cecsatrade.mx
        </a>
        <br />
        <br />
        <Title title={t('global.tecnicalSupport')} />
        <a
          href="mailto:sistemas@cecsatrade.mx"
          target="_blank"
        >
          sistemas@cecsatrade.mx
        </a>
        <br />
        <br />
        <Title title={t('global.socialMedia')} />
        <a
          href="https://www.facebook.com/CECSATRADE"
          target="_blank"
        >
          Facebook
        </a>
        <a
          href="https://www.linkedin.com/company/cecsatrade/"
          target="_blank"
        >
          Linkedin
        </a>
        <a
          href="https://wa.me/message/YZR3TYWZRARDH1"
          target="_blank"
        >Whatsapp</a>
        <div className="help__main__video">
          <Title title='Cómo crear una Solicitud de Constancia/Revisión' />
          <video controls muted>
            <source src="/videos/Cómo crear una Solicitud de Constancia - CECSA Trade.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="help__main__video">
          <Title title='Cómo ingresar una UVA' />
          <video controls muted>
            <source src="/videos/Cómo ingresar una UVA - CECSA Trade.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};

export default Profile;
