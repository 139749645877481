import React, { useContext } from 'react';
import { useNavigate } from '../../hooks';

import Icon from '../Icon';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

interface subTitleType {
  label: string | number
  title: string
}

interface Props {
  title: string
  subTitle?: subTitleType[]
  showBackbutton?: boolean
  onGoBack?: () => void
}

const Header: React.FC<Props> = (props) => {
  const {
    title,
    subTitle = [],
    showBackbutton,
    onGoBack = () => navigate(-1)
  } = props;

  const navigate = useNavigate();
  const {
    menuOpen
  } = useContext(AppContext);

  return (
    <div className={`header header--${showBackbutton ? '' : 'whithout-back-button'} header--${menuOpen ? '' : 'lateral-menu-closed'}`} id='header'>
      {
        showBackbutton && (
          <>
            <Icon type='backButton' className='header__back-button' onClick={onGoBack} />
            <div className='header__back-button__complement'></div>
          </>
        )
      }
      <p className='header__title'>
        {title}
      </p>
      <div className='header__sub-title-container'>
        {
          subTitle.map((subTitleItem: subTitleType, index: number) => (
            <p className='header__sub-title' key={index}>
              <span className='header__sub-title--label'>{`${subTitleItem.title} ${subTitleItem.title ? ': ' : ''}`}</span>
              {subTitleItem.label}
            </p>
          ))
        }
      </div>
    </div>
  );
};

export default Header;
