import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '../../components';

import './styles.scss';
import { useNavigate } from '../../hooks';

const AlreadyOpen: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className='error-404'>
      <Icon type='bulletLeftTop' alt='Bullet 1' className='error-404__icon-1' />
      <Icon type='bulletBlue' alt='Bullet 2' className='error-404__icon-2' />
      <Icon type='bulletYellow' alt='Bullet 3' className='error-404__icon-3' />
      <Icon type='bulletRightBottom' alt='Bullet 4' className='error-404__icon-4' />
      <Icon type='stain' alt='stain' className='error-404__icon-5' />
      <p>{t('global.appAlreadyOpen')}</p>
      <Icon type='error404' alt='Error 404' className='error-404__icon' />
      <Button
        type='primary'
        icon='leftArrow'
        onClick={() => navigate(-1)}
        label='Regresar'
        iconPosition='left'
      />
    </div>
  );
};

export default AlreadyOpen;
