import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  Modal,
  Select,
  Separator,
  Spinner,
  Switch,
  Table,
  Title
} from '../../../components';

import './styles.scss';
import { useResource } from '../../../hooks';
import { AppContext } from '../../../context/AppContext';
import {
  ComplianceWithOpeningHoursReport,
  InspectionTimeAndDisaprovals,
  InspectorReport,
  SelectOption
} from '../../../interfaces';
import { utils } from '../../../helpers';

const UsersList = () => {
  const { t, i18n } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    fetchResource: getInspectors
  } = useResource<InspectorReport[]>();

  const {
    fetchResource: getTasksOpeningInTime
  } = useResource<ComplianceWithOpeningHoursReport[]>();

  const {
    fetchResource: getInspectionsTimeAndDisaprovals
  } = useResource<InspectionTimeAndDisaprovals[]>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [inspectorsReport, setInspectorsReport] = useState<InspectorReport[]>([]);
  const [
    inspectionTimeAndDisapprovals,
    setInspectionTimeAndDisapprovals
  ] = useState<InspectionTimeAndDisaprovals[]>([]);
  const [
    complianceWithOpeningHoursReport,
    setComplianceWithOpeningHoursReport
  ] = useState<ComplianceWithOpeningHoursReport[]>([]);
  const [openingHoursPeriod, setOpeningHoursPeriod] = useState<'weekly' | 'monthly'>('weekly');
  const [inspectionTimeAndDisaprovalsPeriod, setInspectionTimeAndDisaprovalsPeriod] = useState<'weekly' | 'monthly'>('weekly');
  const [inspectorsSelectedYear, setInspectorsSelectedYear] = useState<number>(dayjs().year());
  const [
    inspectorsSelectedMonth,
    setInspectorsSelectedMonth
  ] = useState<number>(dayjs().month() + 1);
  const [
    selectedYearInspectionTimeAndDisaprovals,
    setSelectedYearInspectionTimeAndDisaprovals
  ] = useState<number>(dayjs().year());
  const [
    selectedYearComplianceWithOpeningHours,
    setSelectedYearComplianceWithOpeningHours
  ] = useState<number>(dayjs().year());

  const getInspectorsReport = () => {
    getInspectors(
      'admin/notifications/inspectors-report',
      (data) => {
        setInspectorsReport(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getTasksOpeningInTimeReport = () => {
    getTasksOpeningInTime(
      `admin/notifications/tasks-opening-in-time-report?openingHoursPeriod=${openingHoursPeriod}`,
      (data) => {
        setComplianceWithOpeningHoursReport(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getInspectionsTimeAndDisaprovalsList = () => {
    getInspectionsTimeAndDisaprovals(
      `admin/notifications/inspection-time-and-disaprovals-report?period=${inspectionTimeAndDisaprovalsPeriod}`,
      (data) => {
        setInspectionTimeAndDisapprovals(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getYearsFromDatesToInspectorsReport = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    inspectorsReport.forEach(report => {
      years.add(report.year);
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  const getWeeksFromDatesToInspectorsReport = (): SelectOption[] => {
    const weeks = new Set<number>();
    const data: SelectOption[] = [];

    const months = {
      en: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December'
      },
      es: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre'
      }
    };

    inspectorsReport.filter(item => item.year === inspectorsSelectedYear).forEach(operation => {
      weeks.add(operation.monthOfTheYear);
    });

    const weeksArray = Array.from(weeks);

    for (let index = 0; index < weeksArray.length; index += 1) {
      data.push({
        value: weeksArray[index],
        text: `${(months as any)[i18n.language][weeksArray[index]]}`
      });
    }

    return data;
  };

  const getYearsFromDatesDisaprobals = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    inspectionTimeAndDisapprovals.forEach(operation => {
      years.add(operation.year);
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  const getYearsFromDates = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    complianceWithOpeningHoursReport.forEach(operation => {
      years.add(operation.year);
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  const throttledHandleRequest = utils.throttle(getTasksOpeningInTimeReport, 1000);

  // eslint-disable-next-line max-len
  const throttledHandleRequestGetInspectionsTimeAndDisaprovalsList = utils.throttle(getInspectionsTimeAndDisaprovalsList, 1000);

  // eslint-disable-next-line no-unused-vars
  const throttledHandleRequestGetInspectorsReport = utils.throttle(getInspectorsReport, 1000);

  useEffect(() => {
    throttledHandleRequest();
  }, [openingHoursPeriod]);

  useEffect(() => {
    throttledHandleRequestGetInspectionsTimeAndDisaprovalsList();
  }, [inspectionTimeAndDisaprovalsPeriod]);

  // useEffect(() => {
  //   throttledHandleRequestGetInspectorsReport();
  // }, []);

  return (
    <div className="reports">
      <Header
        title={t('menu.inspectors') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          <Title title='Ranking de inspectores' />
          {
            inspectorsReport.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="reports__main__container__selects-container">
                  <Select
                    options={getYearsFromDatesToInspectorsReport()}
                    onChange={(_value: string, _id: string) => {
                      setInspectorsSelectedYear(Number(_value));
                    }}
                    value={inspectorsSelectedYear}
                    title={t('global.year') || ''}
                  />
                  <Select
                    options={getWeeksFromDatesToInspectorsReport()}
                    onChange={(_value: string, _id: string) => {
                      setInspectorsSelectedMonth(Number(_value));
                    }}
                    value={inspectorsSelectedMonth}
                    title={t('global.month') || ''}
                  />
                </div>
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: 'Ranking',
                        value: 'ranking',
                        format: 'none'
                      },
                      {
                        label: 'Nombre',
                        value: 'name',
                        format: 'none'
                      },
                      {
                        label: 'Puntos por inspecciones',
                        value: 'inspectionsQuantity',
                        format: 'none',
                        helperText: 'Minutos sumados por norma: 004, 015, 020, 024 y 050 por 10min, 141, 142, 189 y 003 por 20min, 051, 187, 235 y 173 por 30min.',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Puntos por incidencias',
                        value: 'percentageOfIncidents',
                        format: 'incidents',
                        helperText: 'Desaprobaciones de inspecciones por 10 puntos',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Puntos por visitas',
                        value: 'visits',
                        format: 'none',
                        helperText: 'Cantidad de visitas en sitio agrupadas por dirección por 30 puntos',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Total',
                        value: 'points',
                        format: 'none',
                        helperText: 'Total de puntos por normas - puntos de incidencias + puntos por visitas en sitio',
                        helperTextTooltip: true,
                        helperTextTooltipPosition: 'left'
                      }
                    ]}
                    // eslint-disable-next-line max-len
                    items={inspectorsReport.filter(item => item.year === inspectorsSelectedYear && item.monthOfTheYear === inspectorsSelectedMonth)}
                    ranking={true}
                  />
                </div>
              </>
            )
          }
          <Separator orientation='horizontal' />
          <Title title='Tiempos de inspección y desaprobaciones' />
          {
            inspectionTimeAndDisapprovals.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="reports__main__container__selects-container">
                  {
                    getYearsFromDatesDisaprobals().length > 1 && (
                      <Select
                        options={getYearsFromDates()}
                        onChange={(_value: string, _id: string) => {
                          setSelectedYearInspectionTimeAndDisaprovals(Number(_value));
                        }}
                        value={selectedYearInspectionTimeAndDisaprovals}
                        title={t('global.year') || ''}
                      />
                    )
                  }
                  <Switch
                    leftLabel={{
                      text: 'Semanal',
                      value: 'weekly'
                    }}
                    rigthLabel={{
                      text: 'Mensual',
                      value: 'monthly'
                    }}
                    onChange={(value: string | number) => {
                      setInspectionTimeAndDisaprovalsPeriod(`${value}` as 'weekly' | 'monthly');
                    }}
                  />
                </div>
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: openingHoursPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none',
                        rowSpan: true
                      },
                      {
                        label: 'Nombre',
                        value: 'name',
                        format: 'none'
                      },
                      {
                        label: 'Norma',
                        value: 'norm',
                        format: 'none'
                      },
                      {
                        label: 'Inspecciones completadas',
                        value: 'completedInspections',
                        format: 'none'
                      },
                      {
                        label: 'tiempo promedio de inspección',
                        value: 'averageInspectionTime',
                        format: 'none'
                      },
                      {
                        label: 'Tiempo estimado',
                        value: 'estimatedTime',
                        format: 'minutes',
                        helperText: 'Minutos estimados por norma: 004, 015, 020, 024 y 050 por 10min, 141, 142, 189 y 003 por 15min, 051, 187, 235 y 173 por 20min.',
                        helperTextTooltip: true
                      },
                      {
                        label: '% de rendimiento',
                        value: 'yieldPercentage',
                        format: 'none'
                      },
                      {
                        label: 'Desaprobaciones',
                        value: 'disaprovals',
                        format: 'none'
                      },
                      {
                        label: '% de error',
                        value: 'errorPorcentage',
                        format: 'none'
                      }
                    ]}
                    // eslint-disable-next-line max-len
                    items={inspectionTimeAndDisapprovals.filter(item => item.year === selectedYearInspectionTimeAndDisaprovals)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
          <Separator orientation='horizontal' />
          <Title title='Cumplimiento en la hora de apertura de visitas en sitio' />
          {
            complianceWithOpeningHoursReport.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="reports__main__container__selects-container">
                  {
                    getYearsFromDates().length > 1 && (
                      <Select
                        options={getYearsFromDates()}
                        onChange={(_value: string, _id: string) => {
                          setSelectedYearComplianceWithOpeningHours(Number(_value));
                        }}
                        value={selectedYearComplianceWithOpeningHours}
                        title={t('global.year') || ''}
                      />
                    )
                  }
                  <Switch
                    leftLabel={{
                      text: 'Semanal',
                      value: 'weekly'
                    }}
                    rigthLabel={{
                      text: 'Mensual',
                      value: 'monthly'
                    }}
                    onChange={(value: string | number) => {
                      setOpeningHoursPeriod(`${value}` as 'weekly' | 'monthly');
                    }}
                  />
                </div>
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: openingHoursPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none',
                        rowSpan: true
                      },
                      {
                        label: 'Nombre',
                        value: 'inspector.name',
                        format: 'none'
                      },
                      {
                        label: 'Cantidad de tareas asignadas',
                        value: 'assignedTasks',
                        format: 'none'
                      },
                      {
                        label: 'Apertura en tiempo (30min máximos posteriores a la hora programada)',
                        value: 'openingInTime',
                        format: 'none'
                      },
                      {
                        label: '% de cumplimiento',
                        value: 'compliesPorcent',
                        format: 'none'
                      }
                    ]}
                    // eslint-disable-next-line max-len
                    items={complianceWithOpeningHoursReport.filter(item => item.year === selectedYearComplianceWithOpeningHours)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UsersList;
