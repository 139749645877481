import React, { useEffect, useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '../../interfaces';
import ToolTip from '../ToolTip';

import './styles.scss';

interface Props {
  options: SelectOption[]
  value?: string | number
  onChange: (_value: string, _id: string) => void
  id?: string
  disabled?: boolean
  title?: string
  toolTipText?: string
  toolTipPosition?: 'bottom' | 'left' | 'right'
  helperText?: string
  hasError?: boolean
  backgroundClear?: boolean
  type?: 'yellow'
}

const Select: React.FC<Props> = (props) => {
  const {
    options,
    value,
    onChange,
    disabled = false,
    title = '',
    toolTipText,
    toolTipPosition,
    id = useId(),
    helperText,
    hasError,
    backgroundClear,
    type = ''
  } = props;

  const { t } = useTranslation();

  const [helperTextClasses, setHelperTextClasses] = useState<string>('select__helper-text');

  const onClickArrow = () => {
    // eslint-disable-next-line no-undef
    const selectElement = document.getElementById(id);

    if (selectElement) {
      selectElement.focus();

      const event = new MouseEvent('mousedown', {
        bubbles: true,
        cancelable: true,
        // eslint-disable-next-line no-undef
        view: window
      });
      selectElement.dispatchEvent(event);
    }
  };

  useEffect(() => {
    let _helperTextClasses = 'select__helper-text';

    if (hasError) {
      _helperTextClasses += ' select__helper-text--with-error';
    }

    if (disabled) {
      _helperTextClasses += ' select__helper-text--disabled';
    }

    setHelperTextClasses(_helperTextClasses);
  }, [disabled, hasError]);

  useEffect(() => {
    if (hasError) {
      // eslint-disable-next-line no-undef
      const errorElement = document.querySelectorAll('#input-error');

      if (errorElement[0]) {
        errorElement[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [hasError, helperText]);

  return (
    <div className={`select select--${title || toolTipText ? 'with-title' : ''}`} id={hasError ? 'input-error' : ''}>
      {
        (title || toolTipText) && (
          <div className='select__title-container'>
            <p className={`select__title select__title--${hasError ? 'with-error' : ''}`}>{title}</p>
            {
              toolTipText && (
                <ToolTip text={toolTipText} position={toolTipPosition} component={<div className='select__title-container__tooltip'>?</div>} />
              )
            }
          </div>
        )
      }
      <select
        className={`select__content select__content--${type} ${backgroundClear ? 'select__content--backgroundClear' : ''} select__content--${disabled ? 'disabled' : ''} select__content--${hasError ? 'with-error' : ''}`}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onChange(event.target.value, id)}
        id={id}
        disabled={disabled}
        value={value}
      >
        <option value='none'>{t('global.select')}</option>
        {
          value !== 'none' && options.length === 0 && (
            <option value={value} key={value}>{value}</option>
          )
        }
        {
          options.map((option: SelectOption, index: number) => (
            <option
              value={option.value}
              key={index}
            >
              {option.text}
            </option>
          ))
        }
      </select>
      <i
        className={`select__arrow select__arrow--${title || toolTipText ? 'with-title' : ''}`}
        onClick={onClickArrow}
      ></i>
      {
        helperText && (
          <p className={helperTextClasses}>{helperText}</p>
        )
      }
    </div>
  );
};

export default Select;
