import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import './styles.scss';
import { IconTypes } from '../Icon/iconList';

interface Props {
  title: string
  complies: boolean | null
  onClick: () => void
  isSelected: boolean
}

const InspectionRound: React.FC<Props> = (props) => {
  const {
    title,
    complies,
    onClick,
    isSelected
  } = props;

  const { t } = useTranslation();

  const setComplies = () => {
    if (complies === null) {
      return t('inspections.inProgress');
    }

    if (complies) {
      return t('global.complies');
    }

    return t('global.notComplies');
  };

  const icons: { [name: string]: IconTypes } = {
    true: 'roundComplies',
    false: 'roundNotComplies',
    null: 'roundInProgress'
  };

  return (
    <div className={isSelected ? 'inspection-round inspection-round--selected' : 'inspection-round'} onClick={onClick}>
      <Icon type={icons[`${complies}`]} className='inspection-round__icon' />
      <p className='inspection-round__title'>{title}</p>
      <p className='inspection-round__complies'>{setComplies()}</p>
    </div>
  );
};

export default InspectionRound;
