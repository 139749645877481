import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  FileFolder,
  FolderExpanded,
  Modal,
  Separator,
  StepsIndicator,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { emptyConstancyProduct } from '../../../../emptyObjects';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import {
  ConstancyProduct,
  ConstancyService,
  FileInputData,
  FileToSign,
  SignedFile
} from '../../../../interfaces';

import './styles.scss';
import { utils } from '../../../../helpers';

export const Step3 = () => {
  const {
    fetchResources,
    eraseResource,
    updateResource
  } = useResource<ConstancyProduct>();
  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();
  const {
    fetchResource
  } = useResource<ConstancyService>();

  const filesLimit = 10;

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const [constancyProducts, setConstancyProducts] = useState<ConstancyProduct[]>([]);
  const [foldersOpen, setFoldersOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [requestIsCompleted, setRequestIsCompleted] = useState<boolean>(false);

  const updateConstancyProductState = (productID: string, newData: ConstancyProduct) => {
    const auxConstancyProducts: ConstancyProduct[] = constancyProducts;

    for (let index = 0; index < auxConstancyProducts.length; index += 1) {
      if (auxConstancyProducts[index].id === productID) {
        auxConstancyProducts[index] = newData;
      }
    }

    setConstancyProducts(auxConstancyProducts);
  };

  const handleUploadFile = (files: FileInputData[]) => {
    if (files.length > filesLimit) {
      Modal.fireError(t('services.errors.only10Products'), setOpenModal, soundEffects);
      return;
    }

    const paths: FileToSign[] = [];

    files.forEach(fileItem => {
      paths.push({
        path: fileItem.fileName,
        type: fileItem.data.type,
        documentName: utils.deleteDotsOnFileName(fileItem.fileName)
      });
    });

    registerUploadedFiles(
      `/${apiType}/constancy-products/${selectedProduct.id}/documents`,
      {
        filePaths: paths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map(async (item) => {
            // eslint-disable-next-line max-len
            const file = files.find((fileItem) => item.url.endsWith(utils.deleteDotsOnFileName(fileItem.fileName)));

            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/${apiType}/constancy-products/${selectedProduct.id}/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (product) => {
              setSelectedProduct(product);
              updateConstancyProductState(selectedProduct.id, product);
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleDeleteFile = (documentPath: string) => {
    eraseResource(
      `/${apiType}/constancy-products/${selectedProduct.id}/documents`,
      {
        s_documentPath: documentPath
      },
      (data: ConstancyProduct) => {
        setSelectedProduct(data);
        updateConstancyProductState(selectedProduct.id, data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getConstancyProducts = () => {
    fetchResources(
      {
        resourcePath: `/${apiType}/constancy-products`,
        filters: {
          s_serviceID: params.constancyServiceID || ''
        }
      },
      (data) => {
        setConstancyProducts(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleContinue = () => {
    let productsHasErrors: boolean = false;
    const auxConstancyProducts: ConstancyProduct[] = constancyProducts;

    for (let index = 0; index < constancyProducts.length; index += 1) {
      if (constancyProducts[index].documents.length === 0) {
        auxConstancyProducts[index].documentsAlert = true;
        productsHasErrors = true;
      } else {
        auxConstancyProducts[index].documentsAlert = false;
      }
    }

    setConstancyProducts(auxConstancyProducts);

    if (productsHasErrors) {
      Modal.fireError(t('services.errors.atLeast1Products'), setOpenModal, soundEffects);
      return;
    }

    if (apiType === 'admin') {
      updateResource(
        `/${apiType}/constancy-services/${params.constancyServiceID}`,
        {
          requestIsCompleted: true
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.requestSaved'),
            setOpenModal,
            () => navigate(`/constancy-services/detail/${params.constancyServiceID}/none`),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
      return;
    }

    navigate(`/constancy-request/step_4/${params.constancyServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`);
  };

  useEffect(() => {
    getConstancyProducts();
  }, [apiType]);

  useEffect(() => {
    if (params.constancyServiceID) {
      fetchResource(
        `/${apiType}/constancy-services/${params.constancyServiceID}`,
        (data) => {
          setRequestIsCompleted(data.indicators.requestIsCompleted);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [apiType, params.constancyServiceID]);

  useKeyPress(
    openModal ? () => { } : () => handleContinue(),
    [
      constancyProducts,
      openModal,
      selectedProduct
    ]
  );

  return (
    <div className='step-3-constancy'>
      <div className='step-3-constancy__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={3}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={2}
            />
          )
        }
      </div>
      <div className='step-3-constancy__content'>
        <div className='step-3-constancy'>
          <Title title={apiType === 'admin' ? t('services.step3Documents') : t('services.step2Documents')} />
        </div>
        <p className='step-3-constancy__help-text'>
          {t('services.documentsExplication', { filesLimit })}
        </p>
        <div className='step-3-constancy__folders'>
          {
            foldersOpen ? (
              <>
                <div className='step-3-constancy__folders__left-container'>
                  {
                    constancyProducts.map((constancyProduct: ConstancyProduct, index: number) => (
                      <div key={index}>
                        {
                          selectedProduct.id === constancyProduct.id ? (
                            <FileFolder
                              id={selectedProduct.id}
                              folderName={`${selectedProduct.brand} - ${selectedProduct.description}`}
                              hasItems={!!selectedProduct.documents.length}
                              filesQuantity={selectedProduct.documents.length}
                              selected={true}
                              subtitle={selectedProduct.invoice}
                              onClick={(_id: string) => {
                                setFoldersOpen(true);
                              }}
                              hasError={constancyProduct.documentsAlert}
                            />
                          ) : (
                            <FileFolder
                              id={constancyProduct.id}
                              folderName={`${constancyProduct.brand} - ${constancyProduct.description}`}
                              hasItems={!!constancyProduct.documents.length}
                              filesQuantity={constancyProduct.documents.length}
                              selected={false}
                              subtitle={constancyProduct.invoice}
                              onClick={(_id: string) => {
                                setFoldersOpen(true);
                                setSelectedProduct(constancyProduct);
                              }}
                              hasError={constancyProduct.documentsAlert}
                            />
                          )
                        }
                      </div>
                    ))
                  }
                </div>
                <Separator orientation='vertical' />
                <div className='step-3-constancy__folders__right-container'>
                  <FolderExpanded
                    folderName={`${selectedProduct.brand} - ${selectedProduct.description}`}
                    filesQuantity={selectedProduct.documents.length}
                    files={selectedProduct.documents}
                    onClose={() => setFoldersOpen(false)}
                    onUploadFile={handleUploadFile}
                    onDelete={handleDeleteFile}
                    filesLimit={filesLimit}
                    requestIsCompleted={requestIsCompleted}
                  />
                </div>
              </>
            ) : (
              <div className='step-3-constancy__folders__list'>
                {
                  constancyProducts.map((constancyProduct: ConstancyProduct) => (
                    <FileFolder
                      id={constancyProduct.id}
                      folderName={constancyProduct.description}
                      hasItems={!!constancyProduct.documents.length}
                      filesQuantity={constancyProduct.documents.length}
                      selected={false}
                      subtitle={constancyProduct.invoice}
                      onClick={(_id: string) => {
                        setFoldersOpen(true);
                        setSelectedProduct(constancyProduct);
                      }}
                      hasError={constancyProduct.documentsAlert}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
        <div className='step-2__button-container'>
          <Button
            onClick={() => {
              if (foldersOpen) {
                setFoldersOpen(false);
              } else {
                navigate(`/constancy-request/step_2/${params.constancyServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`);
              }
            }}
            type='primary'
            label={t('global.goBack') || ''}
            icon='leftArrow'
            iconPosition='left'
          />
          <Button
            onClick={handleContinue}
            type='primary'
            label={apiType === 'admin' ? t('global.finish') || '' : t('global.continue') || ''}
            icon='rightArrow'
            iconPosition='right'
          />
        </div>
      </div>
    </div>
  );
};

export default Step3;
