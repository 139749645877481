export default {
  master: 'Master',
  operator: 'Operator',
  inspector: 'Inspector',
  inspectorApprover: 'Inspector - Approver',
  collaborator: 'Collaborator',
  trainee: 'Scholarchip',
  quality: 'Quality',
  finance: 'Finance',
  'sys-admin': 'Sysadmin'
};
