/* eslint-disable max-len */
import dayjs from 'dayjs';
import {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useNavigate, useResource } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  LateralMenu,
  Modal,
  ActionButtons,
  ModalViewConstancyProductForm
} from '../../components';

import {
  ActionButton,
  Client,
  ConstancyProduct,
  ConstancyServiceByID,
  InspectionByID,
  LateralMenuOption
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Tracing,
  History,
  Documents,
  UploadFiles
} from './components';

import {
  emptyClient,
  emptyConstancyProduct,
  emptyConstancyServiceByID,
  emptyInspectionByID
} from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import configuration from '../../configuration';
import { utils } from '../../helpers';

type ConstancyProductDetailContentType = 'info' | 'history' | 'tracing';

const ConstancyProductDetail = () => {
  const params = useParams();

  const {
    constancyProductID,
    lookInTheTrash,
    uploadFilesModal
  } = params;

  const {
    fetchResource,
    eraseResource
  } = useResource<ConstancyProduct>();

  const {
    fetchResource: getConstancy
  } = useResource<ConstancyServiceByID>();

  const {
    fetchResource: getInspection
  } = useResource<InspectionByID>();

  const {
    fetchResource: getClient
  } = useResource<Client>();

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [product, setProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [constancy, setConstancy] = useState<ConstancyServiceByID>(emptyConstancyServiceByID);
  const [lateralMenuValue, setLateralMenuValue] = useState<ConstancyProductDetailContentType>('info');
  const [showEditProductModal, setShowEditModal] = useState<boolean>(false);
  const [inspection, setInspection] = useState<InspectionByID>(emptyInspectionByID);
  const [isExpiredProduct, setIsExpiredProduct] = useState<boolean>(false);
  const [client, setClient] = useState<Client>(emptyClient);
  const [
    showUploadFilesModal,
    setShowUploadFilesModal
  ] = useState<boolean>(false);
  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('services.documents'),
      value: 'documents'
    },
    {
      label: t('services.history'),
      value: 'history'
    },
    {
      label: t('services.tracing'),
      value: 'tracing'
    }
  ];

  const ableDeleteDocument = (): boolean => {
    return (((inspection.indicators.waitingForFilesForRemoteRound
      && inspection.indicators.changedFilesForRemoteRound === false
      && ['operator', 'collaborator', 'master'].includes(userRole)))
      && product.status === 'in-inspection'
      && isExpiredProduct === false
      && product.task === null
      && product.inspector === null);
  };

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo
        product={product}
        constancyServiceLookInTheTrash={constancy.lookInTheTrash}
        inspectionLookInTheTrash={inspection.lookInTheTrash}
      />),
      history: (<History history={product.tracking} />),
      tracing: (<Tracing status={product.status} />),
      documents: (
        <Documents
          documents={product.documents}
          onChangeProduct={(_product: ConstancyProduct) => {
            setProduct(_product);
          }}
          productID={product.id}
          productInvoice={product.invoice}
          productStatus={product.status}
          inspection={inspection}
          ableDeleteDocument={ableDeleteDocument()}
          client={client}
        />)
    };

    return contents[lateralMenuValue];
  }, [lateralMenuValue, product, lookInTheTrash, inspection, client]);

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as ConstancyProductDetailContentType));
  };

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    '': '',
    true: 'true',
    false: 'false'
  };

  useEffect(() => {
    if (uploadFilesModal === 'true'
      && inspection.indicators.waitingForFilesForRemoteRound
      && !inspection.indicators.changedFilesForRemoteRound) {
      setShowUploadFilesModal(true);
    }
  }, [uploadFilesModal, inspection]);

  const throttledHandleRequest = utils.throttle(
    () => {
      fetchResource(
        `/${apiType}/constancy-products/${constancyProductID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash || ''] || ''}`,
        (data) => {
          setProduct(data);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledHandleRequest();
  }, [constancyProductID, apiType, userRole, lookInTheTrash]);

  useEffect(() => {
    if (product.inspection?.id) {
      getInspection(
        `/${apiType}/inspections/${product.inspection?.id}`,
        (_inspection) => {
          setInspection(_inspection);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [product, lookInTheTrash]);

  useEffect(() => {
    if (product.service.id) {
      getConstancy(
        `/${apiType}/constancy-services/${product.service.id}`,
        (data) => {
          setConstancy(data);
        },
        (error) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  }, [product]);

  useEffect(() => {
    if (product.client.id !== 'none' && product.client.id && apiType === 'admin') {
      getClient(
        `/admin/clients/${product.client.id}`,
        (data) => {
          setClient(data);
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [product.client.id, apiType]);

  useEffect(() => {
    const requestIsCompleted = constancy.indicators ? constancy.indicators.requestIsCompleted : false;

    if (dayjs(new Date()).diff(dayjs(product.createdAt), 'day') >= 91
      || dayjs(new Date()).diff(dayjs(constancy.createdAt), 'day') >= 91) {
      setIsExpiredProduct(true);
    }

    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              setShowEditModal(true);
            }}
            type='primary-outlined'
            label={t('global.edit') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editFile'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('edit-products'),
        specialValidation: (['master', 'operator'].includes(userRole)
          || (['collaborator'].includes(userRole) && requestIsCompleted === false))
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => setShowUploadFilesModal(true)}
            type='primary-outlined'
            label={t('global.uploadFile', { roundNumber: inspection.rounds.length }) || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='upload'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('upload-files'),
        specialValidation: ['master', 'operator', 'collaborator'].includes(userRole) && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              Modal.fireWarning(
                t('services.deleteInvoiceAsk'),
                () => {
                  eraseResource(
                    `/${apiType}/constancy-products`,
                    {
                      b_deleteAll: false,
                      s_productID: product.id,
                      s_serviceID: constancy.id
                    },
                    () => {
                      Modal.fireSuccess(
                        t('global.correct'),
                        t('services.deletedInvoice'),
                        setOpenModal,
                        () => { navigate(-1); },
                        soundEffects
                      );
                    },
                    (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
                  );
                },
                setOpenModal
              );
            }}
            type='primary-outlined'
            label={t('services.deleteInvoice') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paperShredder'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('delete-invoice')
          && ['false', 'none'].includes(lookInTheTrash || ''),
        specialValidation: apiType === 'admin' ? (userRole === 'master' || userRole === 'operator') && lookInTheTrash !== 'true' : (['collaborator'].includes(userRole) && requestIsCompleted === false) && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${product.service.id}/CC`)}
            type='primary-outlined'
            label={t('services.assignProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('assign-products'),
        specialValidation: apiType === 'admin' && ['master', 'operator'].includes(userRole) && lookInTheTrash !== 'true'
      }
    ]);
  }, [product, i18n.language, userRole, inspection, apiType, constancy, lookInTheTrash]);

  return (
    <div className="constancy-product-detail">
      <Header
        title={t('services.productDetail', { type: t('global.constancy') })}
        subTitle={[
          {
            label: product.invoice,
            title: t('global.invoice') || ''
          }
        ]}
        showBackbutton={true}
      />
      <UploadFiles
        visible={showUploadFilesModal}
        onClose={() => setShowUploadFilesModal(false)}
        onChangeProduct={(_product: ConstancyProduct) => {
          setProduct(_product);
        }}
        productID={product.id}
        roundID={inspection.rounds.length}
        productFiles={product.documents}
      />
      <ModalViewConstancyProductForm
        visible={showEditProductModal}
        preloadedProduct={product}
        onUpdateProduct={(_product: ConstancyProduct) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.updatedProduct'),
            setOpenModal,
            () => setProduct(_product),
            soundEffects
          );
        }}
        onClose={() => {
          setShowEditModal(false);
        }}
        onError={(error: string) => {
          setShowEditModal(false);
          Modal.fireError(error, undefined, soundEffects);
        }}
      />
      <div className="constancy-product-detail__main">
        <div className="constancy-product-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={handleSelectLateralMenu}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="constancy-product-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/constancy-products.detail.${product.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}.false`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/constancy-products.detail.${product.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}.false`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
          />
          {
            inspection.indicators.waitingForFilesForRemoteRound
            && !inspection.indicators.changedFilesForRemoteRound
            && ableDeleteDocument() && (
              <div className='constancy-product-detail__alert-constancy-product-files'>
                <p>{t('global.uploadConstancyFilesToNewRoundAlert')}</p>
              </div>
            )
          }
          {getContent()}
        </div>
      </div>
    </div>
  );
};

export default ConstancyProductDetail;
