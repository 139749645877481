/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ConstancyProduct,
  DictumProduct,
  DictumProductInput,
  Inspection,
  RevisionProduct,
  UpdatedConstancyProduct,
  UpdatedDictumProduct,
  UpdatedRevisionProduct
} from '../../../../interfaces';

import {
  ConstancyProductForm,
  RevisionProductForm,
  DictumProductForm,
  Header,
  Modal
} from '../../../../components';
import { useNavigate, useResource } from '../../../../hooks';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';
import { utils } from '../../../../helpers';

interface Props {
  setInspectionID: (_value: string) => void
  setShowProductInformation: (_value: boolean) => void
  constancyProduct: ConstancyProduct
  setConstancyProduct: (_value: ConstancyProduct) => void
  revisionProduct: RevisionProduct
  setRevisionProduct: (_value: RevisionProduct) => void
  dictumProduct: DictumProduct
  setDictumProduct: (_value: DictumProduct) => void
}

const Step1: React.FC<Props> = (props) => {
  const {
    setInspectionID,
    setShowProductInformation,
    constancyProduct,
    setConstancyProduct,
    revisionProduct,
    setRevisionProduct,
    dictumProduct,
    setDictumProduct
  } = props;

  const { t } = useTranslation();

  const {
    setOpenModal,
    inspection,
    soundEffects,
    setInspection,
    setNotReFetchInspection,
    userName
  } = useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    updateResource
  } = useResource<UpdatedDictumProduct>();
  const {
    updateResource: updateConstancyProduct
  } = useResource<UpdatedConstancyProduct>();
  const {
    updateResource: updateRevisionProduct
  } = useResource<UpdatedRevisionProduct>();

  const {
    fetchResource
  } = useResource<DictumProduct>();
  const {
    fetchResource: getConstancyProduct
  } = useResource<ConstancyProduct>();
  const {
    fetchResource: getRevisionProduct
  } = useResource<RevisionProduct>();

  const {
    fetchResource: getInspection,
    updateResource: updateInspectionStep
  } = useResource<Inspection>();

  const { inspectionID } = params;

  const [fetchProduct, setFetchProduct] = useState<boolean>(false);

  useEffect(() => {
    if (fetchProduct === false) {
      setShowProductInformation(false);
      setInspectionID(inspectionID || '');

      if (['DC', 'DN'].includes(inspection.service.code)) {
        fetchResource(
          `/admin/dictum-products/${inspection.product.id}`,
          (productData) => {
            setDictumProduct(productData);
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else if (['CC', 'CN'].includes(inspection.service.code)) {
        getConstancyProduct(
          `/admin/constancy-products/${inspection.product.id}`,
          (productData) => {
            setConstancyProduct(productData);
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else if (['REV'].includes(inspection.service.code)) {
        getRevisionProduct(
          `/admin/revision-products/${inspection.product.id}`,
          (productData) => {
            setRevisionProduct(productData);
          },
          (error) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }

      setFetchProduct(true);
    }
  }, [inspectionID, inspection]);

  // useEffect(() => {
  //   if (location.pathname.includes('/inspection/update-product/')
  // eslint-disable-next-line max-len
  //     && utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'product-updated') !== undefined) {
  //     navigate(-2);
  //   }
  // }, [inspection]);

  const setDictumDisableInputs = (): DictumProductInput[] => {
    if (['051', '173', '187', '235'].includes(dictumProduct.normCode)) {
      return ['normCode', 'tariffFraction', 'umc', 'umcQuantity', 'labelingMode', 'code', 'labelsToPut', '003Complement'];
    }
    return ['normCode', 'fase', 'tariffFraction', 'umc', 'umcQuantity', 'labelingMode', 'code', 'labelsToPut', '003Complement'];
  };

  return (
    <div className="inspection-update-product">
      <Header
        title='Actualizar el producto'
        subTitle={[
          {
            label: `${inspection.rounds.length}`,
            title: t('inspections.round') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className="inspection-update-product__product-form">
        {
          dictumProduct.id
          && (
            <DictumProductForm
              title='Actualiza la presentación, etiquetas a colocar, marca y descripción si no se han ingresado ya'
              preloadedProduct={dictumProduct}
              disableInputs={setDictumDisableInputs()}
              onUpdateProduct={(product: DictumProduct, productHasChanged: boolean) => {
                if (productHasChanged) {
                  updateResource(
                    `/admin/dictum-products/${product?.id}`,
                    {
                      ...product,
                      updateInspection: true
                    },
                    (data) => {
                      updateInspectionStep(
                        `/admin/inspections/${inspectionID}/add-updated-product-step`,
                        {},
                        (inspectionData) => {
                          setInspection(inspectionData);

                          Modal.fireSuccess(
                            t('global.correct'),
                            t('services.updatedProduct'),
                            setOpenModal,
                            () => {
                              // setInspection(data.inspection);

                              setDictumProduct(data.product);
                              setNotReFetchInspection(true);

                              navigate(`/inspection/detail/${inspectionID}/none/none/false`);
                            },
                            soundEffects
                          );
                        },
                        (error: string) => {
                          Modal.fireError(error, setOpenModal, soundEffects);
                        }
                      );
                    },
                    (error: string) => {
                      Modal.fireError(error, setOpenModal, soundEffects);
                    }
                  );
                } else {
                  navigate(`/inspection/detail/${inspectionID}/none/none/none`);
                }
              }}
            />
          )
        }
        {
          constancyProduct.id && (
            <ConstancyProductForm
              title='¿Deseas hacer una actualización de último minuto?'
              preloadedProduct={constancyProduct}
              disableInputs={[
                'normCode',
                'fase',
                'code',
                'design',
                'package',
                'long',
                'high',
                'radio',
                'urgent',
                '003Complement'
              ]}
              onUpdateProduct={(product: ConstancyProduct, productHasChanged: boolean) => {
                if (productHasChanged) {
                  updateConstancyProduct(
                    `/admin/constancy-products/${product?.id}`,
                    {
                      ...product,
                      package: product.indicators.package,
                      long: product.indicators.long,
                      high: product.indicators.high,
                      radius: product.indicators.radio,
                      updateInspection: true
                    },
                    (data) => {
                      updateInspectionStep(
                        `/admin/inspections/${inspectionID}/add-updated-product-step`,
                        {},
                        (inspectionData) => {
                          setInspection(inspectionData);

                          Modal.fireSuccess(
                            t('global.correct'),
                            t('services.updatedProduct'),
                            setOpenModal,
                            () => {
                              // setInspection(data.inspection);

                              setConstancyProduct(data.product);
                              setNotReFetchInspection(true);

                              navigate(`/inspection/detail/${inspectionID}/none/none/false`);
                            },
                            soundEffects
                          );
                        },
                        (error: string) => {
                          Modal.fireError(error, setOpenModal, soundEffects);
                        }
                      );
                    },
                    (error: string) => {
                      Modal.fireError(error, setOpenModal, soundEffects);
                    }
                  );
                } else {
                  navigate(`/inspection/detail/${inspectionID}/none/none/none`);
                }
              }}
            />
          )
        }
        {
          revisionProduct.id && (
            <RevisionProductForm
              title='¿Deseas hacer una actualización de último minuto?'
              preloadedProduct={revisionProduct}
              disableInputs={[
                'normCode',
                'fase',
                'code',
                'design',
                'package',
                'long',
                'high',
                'radio',
                'urgent',
                '003Complement'
              ]}
              onUpdateProduct={(product: RevisionProduct, productHasChanged: boolean) => {
                if (productHasChanged) {
                  updateRevisionProduct(
                    `/admin/revision-products/${product?.id}`,
                    {
                      ...product,
                      package: product.indicators.package,
                      long: product.indicators.long,
                      high: product.indicators.high,
                      radius: product.indicators.radio,
                      updateInspection: true
                    },
                    (data) => {
                      updateInspectionStep(
                        `/admin/inspections/${inspectionID}/add-updated-product-step`,
                        {},
                        (inspectionData) => {
                          setInspection(inspectionData);

                          Modal.fireSuccess(
                            t('global.correct'),
                            t('services.updatedProduct'),
                            setOpenModal,
                            () => {
                              // setInspection(data.inspection);

                              setRevisionProduct(data.product);
                              setNotReFetchInspection(true);

                              navigate(`/inspection/detail/${inspectionID}/none/none/false`);
                            },
                            soundEffects
                          );
                        },
                        (error: string) => {
                          Modal.fireError(error, setOpenModal, soundEffects);
                        }
                      );
                    },
                    (error: string) => {
                      Modal.fireError(error, setOpenModal, soundEffects);
                    }
                  );
                } else {
                  navigate(`/inspection/detail/${inspectionID}/none/none/none`);
                }
              }}
            />
          )
        }
      </div>
    </div>
  );
};

export default Step1;
