import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { utils } from '../../helpers';
import { TableHeader } from '../../interfaces';
import Button from '../Button';
import Icon from '../Icon';
import Pagination from '../Pagination';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

interface Props {
  headers: TableHeader[]
  items: any[]
  currentPage: number
  perPage: number
  totalItems: number
  onEditItem?: (_item: any) => void
  onDeleteItem?: (_item: any) => void
  onChangeCurrentPage: (_currentPage: number) => void
  type?: null | 'dictumproducts' | 'inspection-addresses'
}

const FullTable: React.FC<Props> = (props) => {
  const {
    headers = [],
    items = [],
    currentPage = 0,
    perPage = 0,
    totalItems = 0,
    onEditItem = () => { },
    onDeleteItem = () => { },
    onChangeCurrentPage,
    type = null
  } = props;

  const { t } = useTranslation();

  const {
    userRole
  } = useContext(AppContext);

  const disabledEdit = (status: string): boolean => {
    if (type === 'dictumproducts') {
      return !['invoice-assigned', 'not-validated'].includes(status);
    }

    if (type === 'inspection-addresses') {
      return !['master', 'finance'].includes(userRole);
    }

    return false;
  };

  const disabledDelete = (status: string): boolean => {
    if (type === 'dictumproducts') {
      return ![
        'invoice-assigned',
        'validation-in-progress',
        'validated',
        'not-validated',
        'cancellation-in-progress',
        'cancelled'
      ].includes(status);
    }

    if (type === 'inspection-addresses') {
      return !['master', 'finance'].includes(userRole);
    }

    return false;
  };

  return (
    <div className='full-table'>
      {
        items.length <= 0 ? (
          <div className='full-table__empty-state'>
            <p>{t('global.itemsNotFound')}</p>
            <Icon type='emptyState' className='full-table__empty-state__icon' />
          </div>
        ) : (
          items.map((item: any, index: number) => (
            <div className={`full-table__item full-table__item--${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
              {
                headers.map((header: TableHeader, hIndex: number) => (
                  <div className='full-table__item__value' key={hIndex}>
                    <p className='full-table__item__value__label'>{header.label}</p>
                    <p>
                      {
                        header.value === 'description' ? (
                          utils.limitValueLength(
                            utils.formatValue(
                              header.format,
                              utils.getNestedValue(item, header.value),
                              null,
                              index
                            ),
                            50
                          )
                        ) : (
                          utils.formatValue(
                            header.format,
                            utils.getNestedValue(item, header.value),
                            null,
                            index
                          )
                        )
                      }
                    </p>
                  </div>
                ))
              }
              <div className='full-table__item__buttons-container'>
                <Button onClick={() => onEditItem(item)} type='secondary-outlined' label={t('global.edit') || ''} disabled={disabledEdit(item.status)} />
                <Button onClick={() => onDeleteItem(item)} type='secondary' border='secondary-outlined' label={t('global.delete') || ''} transparent={true} disabled={disabledDelete(item.status)} />
              </div>
            </div>
          ))
        )
      }
      {
        totalItems > 0
        && <Pagination
          currentPage={currentPage}
          perPage={perPage}
          totalItems={totalItems}
          onChangeCurrentPage={onChangeCurrentPage}
        />
      }
    </div>
  );
};

export default FullTable;
