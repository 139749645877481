export default {
  DC: {
    documentCode: 'F7-39',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-189-SSA1/SCFI-2018, Productos y servicios. Etiquetado y envasado para productos de aseo de uso doméstico',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Etiquetado sanitario
            <br />
            6.1.1 Denominación genérica y específica del producto.
            <br />
            6.1.1.1 En la superficie principal de exhibición del envase
            primario o secundario de los productos preenvasados objeto de
            esta Norma, debe figurar la denominación genérica, o en su caso, específica.
            <br />
            6.1.1.2 La denominación genérica se podrá establecer haciendo
            referencia a la presentación o función del producto.
            <br />
            6.1.1.3 La denominación específica se podrá establecer
            haciendo referencia al lugar donde se aplicará el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 Identificación del responsable del producto
            <br />
            6.1.2.1 En los productos nacionales, debe figurar el nombre,
            la denominación o la razón social y domicilio (calle, número,
            código postal, ciudad y estado) del productor o responsable de
            la fabricación. En el caso de productos importados, esta
            información debe ser proporcionada por el importador a la
            autoridad competente, a solicitud de ésta.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.2 Para el producto nacional que sea maquilado,
            la leyenda "Hecho para" seguido del nombre y domicilio de
            la persona física o moral, licenciatario o causahabiente propietario de la marca.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.3 Tratándose de productos importados debe figurar el nombre, la
            denominación o la razón social y el domicilio del importador (calle,
            número, código postal, ciudad y entidad federativa), mismos que podrán
            incorporarse en la etiqueta del producto, en el territorio nacional
            después del despacho aduanero y antes de su comercialización
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.4 Para los productos de importación que hayan sido
            envasados en México deben dar cumplimiento a lo dispuesto
            en los incisos 6.1.2.3 y 6.2.2.1, de esta de Norma.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3 Declaración de la lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1 La lista de ingredientes de los productos objeto de esta
            Norma, debe figurar en la superficie de información del envase
            primario o, en su caso, envase secundario. La declaración debe
            hacerse de la siguiente manera
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.1 Debe ir precedida por el término ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.2 Los ingredientes corrosivos, tóxicos o inflamables
            que den origen a las leyendas precautorias, deben declararse
            con el nombre químico o técnico más comúnmente usado o
            utilizar una nomenclatura química.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.3 Los demás ingredientes deben declararse y, para
            ello, podrá emplearse la denominación genérica, la
            denominación química para grupos o familias ya establecidas
            o la denominación funcional del ingrediente, entre los que
            se encuentran, de manera enunciativa mas no limitativa, los siguientes
            <ul>
              <li>Fosfatos</li>
              <li>Fosfonatos</li>
              <li>Tensoactivos aniónicos</li>
              <li>Tensoactivos catiónicos</li>
              <li>Tensoactivos anfotéricos</li>
              <li>Tensoactivos no iónicos</li>
              <li>Blanqueadores base oxígeno</li>
              <li>Blanqueadores base cloro</li>
              <li>EDTA</li>
              <li>Ácido nitrilotriacético</li>
              <li>Fenoles y fenoles halogenados</li>
              <li>Hidrocarburos aromáticos</li>
              <li>Hidrocarburos alifáticos</li>
              <li>Hidrocarburos halogenados</li>
              <li>Ceras</li>
              <li>Silicones</li>
              <li>Sulfatos</li>
              <li>Carbonatos</li>
              <li>Silicatos</li>
              <li>Zeolitas</li>
              <li>Policarboxilatos</li>
            </ul>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.4 En el caso de que una empresa haya desarrollado un
            ingrediente o una mezcla de ingredientes que promuevan una
            innovación en el mercado de los productos objeto de esta
            Norma, podrán declararse utilizando el nombre patentado
            o registrado por ejemplo: Bitrex , Teflon , etc. Los
            documentos que avalen la patente o el registro estarán a
            disposición de la Secretaría, cuando ésta lo solicite.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.5 Las enzimas, colorantes, aromas, fragancias y
            coadyuvantes o ayudas de proceso se podrán incluir con
            sus nombres genéricos, a menos que sean las causantes
            del riesgo del producto, de ser éste el caso se cumplirá
            con lo indicado en el inciso 6.1.3.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            6.1.4 Instrucciones de uso
            <br />
            6.1.4.1 Cuando el uso, manejo, dosificación o conservación del
            producto requiera de instrucciones, por las características
            del mismo, dicha información debe presentarse en la superficie
            de información de la etiqueta del envase primario o en su caso,
            del envase secundario. Sólo en caso de no existir espacio
            suficiente, las instrucciones de uso podrán ir impresas en
            un instructivo anexo al envase y en este último se indicará
            en la superficie de información el siguiente texto: Léase
            instructivo anexo u otras leyendas análogas.
            <br />
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir en la
            etiqueta, las leyendas precautorias y de considerarse necesario,
            las recomendaciones de seguridad referidas a los aspectos que se
            mencionan en este apartado de manera contrastante y visibles en
            condiciones normales de compra y uso y cuya redacción debe ser
            clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            6.1.5 Declaración del lote
            <br />
            6.1.5.1 En cualquier parte del envase primario o secundario,
            debe figurar en todos los productos objeto de esta Norma, la
            identificación del lote o dato que permita la rastreabilidad
            del producto con una indicación en clave o en lenguaje claro,
            ya sea grabado, marcado con tinta indeleble o de cualquier otro modo similar.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6 Leyendas precautorias y recomendaciones
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir
            en la etiqueta, las leyendas precautorias y de considerarse
            necesario, las recomendaciones de seguridad referidas a los
            aspectos que se mencionan en este apartado de manera contrastante y
            visibles en condiciones normales de compra y uso y cuya redacción
            debe ser clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1 En los productos tóxicos debe figurar:
            <br />
            6.1.6.1.1 Que contiene sustancias tóxicas cuya ingestión,
            inhalación o contacto directo inmediato o prolongado,
            según corresponda, pueden originar graves daños a la salud.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1.2 Que no se deje al alcance de los niños.
            <br />
            6.1.6.1.3 Incluir cualquiera de los siguientes pictogramas para
            identificar a un producto con propiedades tóxicas, de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro.png" alt="6-1-6-1-3" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2 En los productos corrosivos debe figurar:
            <br />
            6.1.6.2.1 En la superficie principal de exhibición:
            Lea la etiqueta antes de usar este producto.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.2 Que no reutilice el envase y que
            una vez terminado el contenido se deseche el mismo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.3 Que para su empleo se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.4 Lo dispuesto en el numeral 6.1.6.1.2, de esta de Norma,
            así como la inclusión de cualquiera de los siguientes
            pictogramas para identificar a un producto con propiedades
            corrosivas. El pictograma se debe incluir de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro-piel.png" alt="6-1-6-2-4" style={{ width: '70%' }} />
            <br />
            No será necesario incluir en la etiqueta la leyenda
            que determine el significado de los pictogramas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.5 La palabra "corrosivo" en un tamaño proporcional a la capacidad del envase.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6 Leyendas que señalen:
            <br />
            Las acciones a seguir en caso de ingesta, mal
            uso o mala aplicación del producto, por ejemplo:
            <br />
            6.1.6.2.6.1 Que, en caso de ingestión accidental,
            no se provoque vómito y se obtenga ayuda médica de inmediato.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6.2 Que, en caso de contacto con la piel
            o con los ojos, enjuague con abundante agua y obtenga ayuda médica.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3 En los productos inflamables debe figurar:
            <br />
            6.1.6.3.1 Los textos: "inflamable" en un tamaño proporcional
            a la capacidad del envase y "Mantener lejos del fuego y de instalaciones eléctricas".
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3.2 Las demás leyendas señaladas en los incisos 6.1.6.1 y 6.1.6.2,
            de esta de Norma, que sean aplicables de acuerdo a la fórmula del producto
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4 Los productos que se expendan en envases presurizados,
            deben presentar las leyendas siguientes:
            <br />
            6.1.6.4.1 Que no se use cerca de los ojos o flama.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.2 Que no se queme o perfore el envase.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.3 Que no se exponga al calor.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.4 Lo dispuesto en el inciso 6.1.6.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.5 Cuando la composición de la fórmula no permita la mezcla
            con otras sustancias por representar un riesgo a la salud
            se debe manifestar dicha acción de manera clara.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.6 En los productos en cuya formulación intervengan sustancias
            o compuestos, tales como enzimas y oxidantes, entre otros, que por
            su concentración y características en producto terminado presenten
            problemas de irritación o sensibilización en piel o en mucosa bajo
            condiciones normales de uso harán figurar leyendas que se refieran
            a los siguientes aspectos: Que puede provocar irritación en la piel
            y en mucosas, y que se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Etiquetado comercial
            <br />
            6.2.1 Denominación genérica o en su caso denominación
            específica, y marca comercial del producto.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            6.2.2 País de origen.
            <br />
            6.2.2.1 Leyenda que identifique el país de origen del producto o gentilicio,
            por ejemplo: "producto de...", o "producto...", "Hecho en...",
            "Manufacturado en..." u otros análogos, sujeta a lo dispuesto en
            los tratados internacionales de los cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            6.2.3 Indicación de cantidad
            <br />
            6.2.3.1 El etiquetado de los productos de aseo debe cumplir con lo
            que establecen las Normas Oficiales Mexicanas citadas en los
            incisos 2.2 y 2.3, respectivamente, del Capítulo de Referencias
            normativas, de esta Norma. La unidad de medida puede adicionalmente
            figurar en otro sistema de unidades de medida con el mismo tipo de
            letra y por lo menos con el mismo tamaño.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Generalidades
            <br />
            6.3.1 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en envase múltiple o colectivo,
            éste debe ostentar cuando menos la siguiente información:
            marca, denominación e indicación de cantidad, a menos que
            los materiales del envase múltiple o colectivo permita leer
            la etiqueta individual.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            6.3.2 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en un envase múltiple o colectivo,
            cuyas presentaciones individuales sean utilizadas en una sola
            aplicación, toda la información a que se refiere esta Norma,
            debe estar contenida en éste, e incluir además, la instrucción
            de que se conserve el envase hasta agotar el producto. Asimismo,
            la presentación individual o el envase múltiple o colectivo debe
            ostentar la leyenda: "No etiquetado para su venta individual".
            Lo anterior no será necesario en caso de que las presentaciones
            individuales cumplan con la información citada en esta Norma,
            por lo que el etiquetado del envase múltiple o colectivo debe
            cumplir con lo establecido en el inciso 6.3.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 La presentación individual debe contener el etiquetado completo
            de acuerdo con esta Norma, a excepción de lo mencionado
            en el numeral 6.3.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 El responsable del producto de aseo de uso doméstico que se
            expende a granel debe garantizar que el envase del producto
            que sea suministrado al consumidor ostente una etiqueta que
            cumpla con las disposiciones establecidas en esta Norma, en
            las que se establezca la denominación genérica o en su caso
            específica del producto, lista de ingredientes, nombre del
            responsable del producto, instrucciones de uso, leyendas
            precautorias y recomendaciones correspondientes, indicando
            la cantidad, declaración de lote, país de origen y el contenido
            neto, especificaciones con las que debe estar identificado el contenedor de origen.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            6.3.5 Las muestras de obsequio de productos deben contener en su
            etiquetado al menos: denominación genérica y en su caso específica,
            datos del responsable del producto, instrucciones de uso,
            leyendas precautorias, contenido neto y lote.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Características de la etiqueta
            <br />
            6.4.1 Todas las etiquetas deben ser diseñadas, elaboradas y fijadas
            de tal forma que la información contenida en las mismas
            permanezca disponible durante el uso normal del producto,
            inclusive cuando se trate de productos de venta a granel que se expenden al consumidor.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            7. Requisitos de envasado
            <br />
            7.1 Los envases de los productos objeto de esta Norma deben cumplir con lo siguiente:
            <br />
            7.1.1 Los productos que representen un riesgo a la salud por su acción tóxica,
            corrosiva o inflamable deben contar con dispositivos de seguridad y estar
            contenidos en envases resistentes a niños.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            7.1.2 Los aromatizantes sólidos o pastillas desodorantes deben
            contar con un envase primario que sea de un material
            resistente que no permita su fácil apertura por los niños o
            contar con un envase secundario para su comercialización.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Los comercializadores de los productos de aseo de uso doméstico que
            expenden a granel deben garantizar que el envase en el que se suministra
            el producto al consumidor cumpla con las disposiciones establecidas
            en esta Norma, siendo responsabilidad del comercializador que los
            envases que se utilizan para su venta a granel sean resistentes y
            cuenten con los dispositivos de seguridad necesarios para su venta
            al consumidor. En este caso no se podrán reutilizar envases que se
            hayan utilizado para contener alimentos y bebidas preenvasados.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            8. Presentación de la información
            <br />
            8.1 La información que debe aparecer en la etiqueta de los productos objeto de esta
            Norma, debe indicarse con caracteres claros, visibles, indelebles y fáciles de leer
            por el consumidor en circunstancias normales de compra y uso.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            8.2 Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información a que se refiere esta
            Norma en idioma español, independientemente de que también pueda
            estar en otros idiomas, cuidando de que los caracteres sean por lo
            menos de igual tamaño, proporcionalidad y tipográfica a aquellos
            en los que se presente la información en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            8.3 Tanto las muestras de obsequio como el envase que se utilice para venta
            de los productos a granel, deberán contener una etiqueta en idioma
            español en la cual se establezcan los requisitos a que se refieren
            los incisos 6.3.5 y 6.3.4, de esta Norma, respectivamente.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            8.4 Los términos champú y espray como presentaciones de los
            productos de aseo de uso doméstico, podrán declararse en idioma inglés sin traducción.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            9. Declaraciones
            <br />
            9.1 La información contenida en la etiqueta debe ser veraz, comprobable
            y describirse y presentarse de tal forma que no induzca a error al
            consumidor con respecto a la naturaleza y características del producto.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-39',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-189-SSA1/SCFI-2018, Productos y servicios. Etiquetado y envasado para productos de aseo de uso doméstico',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Etiquetado sanitario
            <br />
            6.1.1 Denominación genérica y específica del producto.
            <br />
            6.1.1.1 En la superficie principal de exhibición del envase
            primario o secundario de los productos preenvasados objeto de
            esta Norma, debe figurar la denominación genérica, o en su caso, específica.
            <br />
            6.1.1.2 La denominación genérica se podrá establecer haciendo
            referencia a la presentación o función del producto.
            <br />
            6.1.1.3 La denominación específica se podrá establecer
            haciendo referencia al lugar donde se aplicará el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 Identificación del responsable del producto
            <br />
            6.1.2.1 En los productos nacionales, debe figurar el nombre,
            la denominación o la razón social y domicilio (calle, número,
            código postal, ciudad y estado) del productor o responsable de
            la fabricación. En el caso de productos importados, esta
            información debe ser proporcionada por el importador a la
            autoridad competente, a solicitud de ésta.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.2 Para el producto nacional que sea maquilado,
            la leyenda "Hecho para" seguido del nombre y domicilio de
            la persona física o moral, licenciatario o causahabiente propietario de la marca.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.3 Tratándose de productos importados debe figurar el nombre, la
            denominación o la razón social y el domicilio del importador (calle,
            número, código postal, ciudad y entidad federativa), mismos que podrán
            incorporarse en la etiqueta del producto, en el territorio nacional
            después del despacho aduanero y antes de su comercialización
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.4 Para los productos de importación que hayan sido
            envasados en México deben dar cumplimiento a lo dispuesto
            en los incisos 6.1.2.3 y 6.2.2.1, de esta de Norma.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3 Declaración de la lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1 La lista de ingredientes de los productos objeto de esta
            Norma, debe figurar en la superficie de información del envase
            primario o, en su caso, envase secundario. La declaración debe
            hacerse de la siguiente manera
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.1 Debe ir precedida por el término ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.2 Los ingredientes corrosivos, tóxicos o inflamables
            que den origen a las leyendas precautorias, deben declararse
            con el nombre químico o técnico más comúnmente usado o
            utilizar una nomenclatura química.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.3 Los demás ingredientes deben declararse y, para
            ello, podrá emplearse la denominación genérica, la
            denominación química para grupos o familias ya establecidas
            o la denominación funcional del ingrediente, entre los que
            se encuentran, de manera enunciativa mas no limitativa, los siguientes
            <ul>
              <li>Fosfatos</li>
              <li>Fosfonatos</li>
              <li>Tensoactivos aniónicos</li>
              <li>Tensoactivos catiónicos</li>
              <li>Tensoactivos anfotéricos</li>
              <li>Tensoactivos no iónicos</li>
              <li>Blanqueadores base oxígeno</li>
              <li>Blanqueadores base cloro</li>
              <li>EDTA</li>
              <li>Ácido nitrilotriacético</li>
              <li>Fenoles y fenoles halogenados</li>
              <li>Hidrocarburos aromáticos</li>
              <li>Hidrocarburos alifáticos</li>
              <li>Hidrocarburos halogenados</li>
              <li>Ceras</li>
              <li>Silicones</li>
              <li>Sulfatos</li>
              <li>Carbonatos</li>
              <li>Silicatos</li>
              <li>Zeolitas</li>
              <li>Policarboxilatos</li>
            </ul>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.4 En el caso de que una empresa haya desarrollado un
            ingrediente o una mezcla de ingredientes que promuevan una
            innovación en el mercado de los productos objeto de esta
            Norma, podrán declararse utilizando el nombre patentado
            o registrado por ejemplo: Bitrex , Teflon , etc. Los
            documentos que avalen la patente o el registro estarán a
            disposición de la Secretaría, cuando ésta lo solicite.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.5 Las enzimas, colorantes, aromas, fragancias y
            coadyuvantes o ayudas de proceso se podrán incluir con
            sus nombres genéricos, a menos que sean las causantes
            del riesgo del producto, de ser éste el caso se cumplirá
            con lo indicado en el inciso 6.1.3.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            6.1.4 Instrucciones de uso
            <br />
            6.1.4.1 Cuando el uso, manejo, dosificación o conservación del
            producto requiera de instrucciones, por las características
            del mismo, dicha información debe presentarse en la superficie
            de información de la etiqueta del envase primario o en su caso,
            del envase secundario. Sólo en caso de no existir espacio
            suficiente, las instrucciones de uso podrán ir impresas en
            un instructivo anexo al envase y en este último se indicará
            en la superficie de información el siguiente texto: Léase
            instructivo anexo u otras leyendas análogas.
            <br />
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir en la
            etiqueta, las leyendas precautorias y de considerarse necesario,
            las recomendaciones de seguridad referidas a los aspectos que se
            mencionan en este apartado de manera contrastante y visibles en
            condiciones normales de compra y uso y cuya redacción debe ser
            clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            6.1.5 Declaración del lote
            <br />
            6.1.5.1 En cualquier parte del envase primario o secundario,
            debe figurar en todos los productos objeto de esta Norma, la
            identificación del lote o dato que permita la rastreabilidad
            del producto con una indicación en clave o en lenguaje claro,
            ya sea grabado, marcado con tinta indeleble o de cualquier otro modo similar.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6 Leyendas precautorias y recomendaciones
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir
            en la etiqueta, las leyendas precautorias y de considerarse
            necesario, las recomendaciones de seguridad referidas a los
            aspectos que se mencionan en este apartado de manera contrastante y
            visibles en condiciones normales de compra y uso y cuya redacción
            debe ser clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1 En los productos tóxicos debe figurar:
            <br />
            6.1.6.1.1 Que contiene sustancias tóxicas cuya ingestión,
            inhalación o contacto directo inmediato o prolongado,
            según corresponda, pueden originar graves daños a la salud.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1.2 Que no se deje al alcance de los niños.
            <br />
            6.1.6.1.3 Incluir cualquiera de los siguientes pictogramas para
            identificar a un producto con propiedades tóxicas, de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro.png" alt="6-1-6-1-3" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2 En los productos corrosivos debe figurar:
            <br />
            6.1.6.2.1 En la superficie principal de exhibición:
            Lea la etiqueta antes de usar este producto.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.2 Que no reutilice el envase y que
            una vez terminado el contenido se deseche el mismo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.3 Que para su empleo se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.4 Lo dispuesto en el numeral 6.1.6.1.2, de esta de Norma,
            así como la inclusión de cualquiera de los siguientes
            pictogramas para identificar a un producto con propiedades
            corrosivas. El pictograma se debe incluir de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro-piel.png" alt="6-1-6-2-4" style={{ width: '70%' }} />
            <br />
            No será necesario incluir en la etiqueta la leyenda
            que determine el significado de los pictogramas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.5 La palabra "corrosivo" en un tamaño proporcional a la capacidad del envase.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6 Leyendas que señalen:
            <br />
            Las acciones a seguir en caso de ingesta, mal
            uso o mala aplicación del producto, por ejemplo:
            <br />
            6.1.6.2.6.1 Que, en caso de ingestión accidental,
            no se provoque vómito y se obtenga ayuda médica de inmediato.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6.2 Que, en caso de contacto con la piel
            o con los ojos, enjuague con abundante agua y obtenga ayuda médica.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3 En los productos inflamables debe figurar:
            <br />
            6.1.6.3.1 Los textos: "inflamable" en un tamaño proporcional
            a la capacidad del envase y "Mantener lejos del fuego y de instalaciones eléctricas".
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3.2 Las demás leyendas señaladas en los incisos 6.1.6.1 y 6.1.6.2,
            de esta de Norma, que sean aplicables de acuerdo a la fórmula del producto
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4 Los productos que se expendan en envases presurizados,
            deben presentar las leyendas siguientes:
            <br />
            6.1.6.4.1 Que no se use cerca de los ojos o flama.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.2 Que no se queme o perfore el envase.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.3 Que no se exponga al calor.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.4 Lo dispuesto en el inciso 6.1.6.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.5 Cuando la composición de la fórmula no permita la mezcla
            con otras sustancias por representar un riesgo a la salud
            se debe manifestar dicha acción de manera clara.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.6 En los productos en cuya formulación intervengan sustancias
            o compuestos, tales como enzimas y oxidantes, entre otros, que por
            su concentración y características en producto terminado presenten
            problemas de irritación o sensibilización en piel o en mucosa bajo
            condiciones normales de uso harán figurar leyendas que se refieran
            a los siguientes aspectos: Que puede provocar irritación en la piel
            y en mucosas, y que se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Etiquetado comercial
            <br />
            6.2.1 Denominación genérica o en su caso denominación
            específica, y marca comercial del producto.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            6.2.2 País de origen.
            <br />
            6.2.2.1 Leyenda que identifique el país de origen del producto o gentilicio,
            por ejemplo: "producto de...", o "producto...", "Hecho en...",
            "Manufacturado en..." u otros análogos, sujeta a lo dispuesto en
            los tratados internacionales de los cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            6.2.3 Indicación de cantidad
            <br />
            6.2.3.1 El etiquetado de los productos de aseo debe cumplir con lo
            que establecen las Normas Oficiales Mexicanas citadas en los
            incisos 2.2 y 2.3, respectivamente, del Capítulo de Referencias
            normativas, de esta Norma. La unidad de medida puede adicionalmente
            figurar en otro sistema de unidades de medida con el mismo tipo de
            letra y por lo menos con el mismo tamaño.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Generalidades
            <br />
            6.3.1 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en envase múltiple o colectivo,
            éste debe ostentar cuando menos la siguiente información:
            marca, denominación e indicación de cantidad, a menos que
            los materiales del envase múltiple o colectivo permita leer
            la etiqueta individual.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            6.3.2 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en un envase múltiple o colectivo,
            cuyas presentaciones individuales sean utilizadas en una sola
            aplicación, toda la información a que se refiere esta Norma,
            debe estar contenida en éste, e incluir además, la instrucción
            de que se conserve el envase hasta agotar el producto. Asimismo,
            la presentación individual o el envase múltiple o colectivo debe
            ostentar la leyenda: "No etiquetado para su venta individual".
            Lo anterior no será necesario en caso de que las presentaciones
            individuales cumplan con la información citada en esta Norma,
            por lo que el etiquetado del envase múltiple o colectivo debe
            cumplir con lo establecido en el inciso 6.3.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 La presentación individual debe contener el etiquetado completo
            de acuerdo con esta Norma, a excepción de lo mencionado
            en el numeral 6.3.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 El responsable del producto de aseo de uso doméstico que se
            expende a granel debe garantizar que el envase del producto
            que sea suministrado al consumidor ostente una etiqueta que
            cumpla con las disposiciones establecidas en esta Norma, en
            las que se establezca la denominación genérica o en su caso
            específica del producto, lista de ingredientes, nombre del
            responsable del producto, instrucciones de uso, leyendas
            precautorias y recomendaciones correspondientes, indicando
            la cantidad, declaración de lote, país de origen y el contenido
            neto, especificaciones con las que debe estar identificado el contenedor de origen.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            6.3.5 Las muestras de obsequio de productos deben contener en su
            etiquetado al menos: denominación genérica y en su caso específica,
            datos del responsable del producto, instrucciones de uso,
            leyendas precautorias, contenido neto y lote.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Características de la etiqueta
            <br />
            6.4.1 Todas las etiquetas deben ser diseñadas, elaboradas y fijadas
            de tal forma que la información contenida en las mismas
            permanezca disponible durante el uso normal del producto,
            inclusive cuando se trate de productos de venta a granel que se expenden al consumidor.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            7. Requisitos de envasado
            <br />
            7.1 Los envases de los productos objeto de esta Norma deben cumplir con lo siguiente:
            <br />
            7.1.1 Los productos que representen un riesgo a la salud por su acción tóxica,
            corrosiva o inflamable deben contar con dispositivos de seguridad y estar
            contenidos en envases resistentes a niños.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            7.1.2 Los aromatizantes sólidos o pastillas desodorantes deben
            contar con un envase primario que sea de un material
            resistente que no permita su fácil apertura por los niños o
            contar con un envase secundario para su comercialización.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Los comercializadores de los productos de aseo de uso doméstico que
            expenden a granel deben garantizar que el envase en el que se suministra
            el producto al consumidor cumpla con las disposiciones establecidas
            en esta Norma, siendo responsabilidad del comercializador que los
            envases que se utilizan para su venta a granel sean resistentes y
            cuenten con los dispositivos de seguridad necesarios para su venta
            al consumidor. En este caso no se podrán reutilizar envases que se
            hayan utilizado para contener alimentos y bebidas preenvasados.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            8. Presentación de la información
            <br />
            8.1 La información que debe aparecer en la etiqueta de los productos objeto de esta
            Norma, debe indicarse con caracteres claros, visibles, indelebles y fáciles de leer
            por el consumidor en circunstancias normales de compra y uso.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            8.2 Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información a que se refiere esta
            Norma en idioma español, independientemente de que también pueda
            estar en otros idiomas, cuidando de que los caracteres sean por lo
            menos de igual tamaño, proporcionalidad y tipográfica a aquellos
            en los que se presente la información en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            8.3 Tanto las muestras de obsequio como el envase que se utilice para venta
            de los productos a granel, deberán contener una etiqueta en idioma
            español en la cual se establezcan los requisitos a que se refieren
            los incisos 6.3.5 y 6.3.4, de esta Norma, respectivamente.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            8.4 Los términos champú y espray como presentaciones de los
            productos de aseo de uso doméstico, podrán declararse en idioma inglés sin traducción.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            9. Declaraciones
            <br />
            9.1 La información contenida en la etiqueta debe ser veraz, comprobable
            y describirse y presentarse de tal forma que no induzca a error al
            consumidor con respecto a la naturaleza y características del producto.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-39',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-189-SSA1/SCFI-2018, Productos y servicios. Etiquetado y envasado para productos de aseo de uso doméstico',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Etiquetado sanitario
            <br />
            6.1.1 Denominación genérica y específica del producto.
            <br />
            6.1.1.1 En la superficie principal de exhibición del envase
            primario o secundario de los productos preenvasados objeto de
            esta Norma, debe figurar la denominación genérica, o en su caso, específica.
            <br />
            6.1.1.2 La denominación genérica se podrá establecer haciendo
            referencia a la presentación o función del producto.
            <br />
            6.1.1.3 La denominación específica se podrá establecer
            haciendo referencia al lugar donde se aplicará el producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 Identificación del responsable del producto
            <br />
            6.1.2.1 En los productos nacionales, debe figurar el nombre,
            la denominación o la razón social y domicilio (calle, número,
            código postal, ciudad y estado) del productor o responsable de
            la fabricación. En el caso de productos importados, esta
            información debe ser proporcionada por el importador a la
            autoridad competente, a solicitud de ésta.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.2 Para el producto nacional que sea maquilado,
            la leyenda "Hecho para" seguido del nombre y domicilio de
            la persona física o moral, licenciatario o causahabiente propietario de la marca.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.3 Tratándose de productos importados debe figurar el nombre, la
            denominación o la razón social y el domicilio del importador (calle,
            número, código postal, ciudad y entidad federativa), mismos que podrán
            incorporarse en la etiqueta del producto, en el territorio nacional
            después del despacho aduanero y antes de su comercialización
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2.4 Para los productos de importación que hayan sido
            envasados en México deben dar cumplimiento a lo dispuesto
            en los incisos 6.1.2.3 y 6.2.2.1, de esta de Norma.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3 Declaración de la lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1 La lista de ingredientes de los productos objeto de esta
            Norma, debe figurar en la superficie de información del envase
            primario o, en su caso, envase secundario. La declaración debe
            hacerse de la siguiente manera
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.1 Debe ir precedida por el término ingredientes
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.2 Los ingredientes corrosivos, tóxicos o inflamables
            que den origen a las leyendas precautorias, deben declararse
            con el nombre químico o técnico más comúnmente usado o
            utilizar una nomenclatura química.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.3 Los demás ingredientes deben declararse y, para
            ello, podrá emplearse la denominación genérica, la
            denominación química para grupos o familias ya establecidas
            o la denominación funcional del ingrediente, entre los que
            se encuentran, de manera enunciativa mas no limitativa, los siguientes
            <ul>
              <li>Fosfatos</li>
              <li>Fosfonatos</li>
              <li>Tensoactivos aniónicos</li>
              <li>Tensoactivos catiónicos</li>
              <li>Tensoactivos anfotéricos</li>
              <li>Tensoactivos no iónicos</li>
              <li>Blanqueadores base oxígeno</li>
              <li>Blanqueadores base cloro</li>
              <li>EDTA</li>
              <li>Ácido nitrilotriacético</li>
              <li>Fenoles y fenoles halogenados</li>
              <li>Hidrocarburos aromáticos</li>
              <li>Hidrocarburos alifáticos</li>
              <li>Hidrocarburos halogenados</li>
              <li>Ceras</li>
              <li>Silicones</li>
              <li>Sulfatos</li>
              <li>Carbonatos</li>
              <li>Silicatos</li>
              <li>Zeolitas</li>
              <li>Policarboxilatos</li>
            </ul>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.4 En el caso de que una empresa haya desarrollado un
            ingrediente o una mezcla de ingredientes que promuevan una
            innovación en el mercado de los productos objeto de esta
            Norma, podrán declararse utilizando el nombre patentado
            o registrado por ejemplo: Bitrex , Teflon , etc. Los
            documentos que avalen la patente o el registro estarán a
            disposición de la Secretaría, cuando ésta lo solicite.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            6.1.3.1.5 Las enzimas, colorantes, aromas, fragancias y
            coadyuvantes o ayudas de proceso se podrán incluir con
            sus nombres genéricos, a menos que sean las causantes
            del riesgo del producto, de ser éste el caso se cumplirá
            con lo indicado en el inciso 6.1.3.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            6.1.4 Instrucciones de uso
            <br />
            6.1.4.1 Cuando el uso, manejo, dosificación o conservación del
            producto requiera de instrucciones, por las características
            del mismo, dicha información debe presentarse en la superficie
            de información de la etiqueta del envase primario o en su caso,
            del envase secundario. Sólo en caso de no existir espacio
            suficiente, las instrucciones de uso podrán ir impresas en
            un instructivo anexo al envase y en este último se indicará
            en la superficie de información el siguiente texto: Léase
            instructivo anexo u otras leyendas análogas.
            <br />
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir en la
            etiqueta, las leyendas precautorias y de considerarse necesario,
            las recomendaciones de seguridad referidas a los aspectos que se
            mencionan en este apartado de manera contrastante y visibles en
            condiciones normales de compra y uso y cuya redacción debe ser
            clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            6.1.5 Declaración del lote
            <br />
            6.1.5.1 En cualquier parte del envase primario o secundario,
            debe figurar en todos los productos objeto de esta Norma, la
            identificación del lote o dato que permita la rastreabilidad
            del producto con una indicación en clave o en lenguaje claro,
            ya sea grabado, marcado con tinta indeleble o de cualquier otro modo similar.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6 Leyendas precautorias y recomendaciones
            <br />
            De acuerdo al tipo de producto, el fabricante debe incluir
            en la etiqueta, las leyendas precautorias y de considerarse
            necesario, las recomendaciones de seguridad referidas a los
            aspectos que se mencionan en este apartado de manera contrastante y
            visibles en condiciones normales de compra y uso y cuya redacción
            debe ser clara y que no induzca error al consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1 En los productos tóxicos debe figurar:
            <br />
            6.1.6.1.1 Que contiene sustancias tóxicas cuya ingestión,
            inhalación o contacto directo inmediato o prolongado,
            según corresponda, pueden originar graves daños a la salud.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.1.2 Que no se deje al alcance de los niños.
            <br />
            6.1.6.1.3 Incluir cualquiera de los siguientes pictogramas para
            identificar a un producto con propiedades tóxicas, de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro.png" alt="6-1-6-1-3" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2 En los productos corrosivos debe figurar:
            <br />
            6.1.6.2.1 En la superficie principal de exhibición:
            Lea la etiqueta antes de usar este producto.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.2 Que no reutilice el envase y que
            una vez terminado el contenido se deseche el mismo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.3 Que para su empleo se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.4 Lo dispuesto en el numeral 6.1.6.1.2, de esta de Norma,
            así como la inclusión de cualquiera de los siguientes
            pictogramas para identificar a un producto con propiedades
            corrosivas. El pictograma se debe incluir de manera
            contrastante y de tamaño proporcional a la capacidad del envase.
            <br />
            <img src="/images/peligro-piel.png" alt="6-1-6-2-4" style={{ width: '70%' }} />
            <br />
            No será necesario incluir en la etiqueta la leyenda
            que determine el significado de los pictogramas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.5 La palabra "corrosivo" en un tamaño proporcional a la capacidad del envase.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6 Leyendas que señalen:
            <br />
            Las acciones a seguir en caso de ingesta, mal
            uso o mala aplicación del producto, por ejemplo:
            <br />
            6.1.6.2.6.1 Que, en caso de ingestión accidental,
            no se provoque vómito y se obtenga ayuda médica de inmediato.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.2.6.2 Que, en caso de contacto con la piel
            o con los ojos, enjuague con abundante agua y obtenga ayuda médica.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3 En los productos inflamables debe figurar:
            <br />
            6.1.6.3.1 Los textos: "inflamable" en un tamaño proporcional
            a la capacidad del envase y "Mantener lejos del fuego y de instalaciones eléctricas".
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.3.2 Las demás leyendas señaladas en los incisos 6.1.6.1 y 6.1.6.2,
            de esta de Norma, que sean aplicables de acuerdo a la fórmula del producto
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4 Los productos que se expendan en envases presurizados,
            deben presentar las leyendas siguientes:
            <br />
            6.1.6.4.1 Que no se use cerca de los ojos o flama.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.2 Que no se queme o perfore el envase.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.3 Que no se exponga al calor.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.4.4 Lo dispuesto en el inciso 6.1.6.1.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.5 Cuando la composición de la fórmula no permita la mezcla
            con otras sustancias por representar un riesgo a la salud
            se debe manifestar dicha acción de manera clara.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            6.1.6.6 En los productos en cuya formulación intervengan sustancias
            o compuestos, tales como enzimas y oxidantes, entre otros, que por
            su concentración y características en producto terminado presenten
            problemas de irritación o sensibilización en piel o en mucosa bajo
            condiciones normales de uso harán figurar leyendas que se refieran
            a los siguientes aspectos: Que puede provocar irritación en la piel
            y en mucosas, y que se utilicen guantes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Etiquetado comercial
            <br />
            6.2.1 Denominación genérica o en su caso denominación
            específica, y marca comercial del producto.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            6.2.2 País de origen.
            <br />
            6.2.2.1 Leyenda que identifique el país de origen del producto o gentilicio,
            por ejemplo: "producto de...", o "producto...", "Hecho en...",
            "Manufacturado en..." u otros análogos, sujeta a lo dispuesto en
            los tratados internacionales de los cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            6.2.3 Indicación de cantidad
            <br />
            6.2.3.1 El etiquetado de los productos de aseo debe cumplir con lo
            que establecen las Normas Oficiales Mexicanas citadas en los
            incisos 2.2 y 2.3, respectivamente, del Capítulo de Referencias
            normativas, de esta Norma. La unidad de medida puede adicionalmente
            figurar en otro sistema de unidades de medida con el mismo tipo de
            letra y por lo menos con el mismo tamaño.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Generalidades
            <br />
            6.3.1 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en envase múltiple o colectivo,
            éste debe ostentar cuando menos la siguiente información:
            marca, denominación e indicación de cantidad, a menos que
            los materiales del envase múltiple o colectivo permita leer
            la etiqueta individual.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            6.3.2 Para la comercialización de los productos objeto de esta
            Norma que se encuentren en un envase múltiple o colectivo,
            cuyas presentaciones individuales sean utilizadas en una sola
            aplicación, toda la información a que se refiere esta Norma,
            debe estar contenida en éste, e incluir además, la instrucción
            de que se conserve el envase hasta agotar el producto. Asimismo,
            la presentación individual o el envase múltiple o colectivo debe
            ostentar la leyenda: "No etiquetado para su venta individual".
            Lo anterior no será necesario en caso de que las presentaciones
            individuales cumplan con la información citada en esta Norma,
            por lo que el etiquetado del envase múltiple o colectivo debe
            cumplir con lo establecido en el inciso 6.3.1, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 La presentación individual debe contener el etiquetado completo
            de acuerdo con esta Norma, a excepción de lo mencionado
            en el numeral 6.3.2, de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 El responsable del producto de aseo de uso doméstico que se
            expende a granel debe garantizar que el envase del producto
            que sea suministrado al consumidor ostente una etiqueta que
            cumpla con las disposiciones establecidas en esta Norma, en
            las que se establezca la denominación genérica o en su caso
            específica del producto, lista de ingredientes, nombre del
            responsable del producto, instrucciones de uso, leyendas
            precautorias y recomendaciones correspondientes, indicando
            la cantidad, declaración de lote, país de origen y el contenido
            neto, especificaciones con las que debe estar identificado el contenedor de origen.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            6.3.5 Las muestras de obsequio de productos deben contener en su
            etiquetado al menos: denominación genérica y en su caso específica,
            datos del responsable del producto, instrucciones de uso,
            leyendas precautorias, contenido neto y lote.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Características de la etiqueta
            <br />
            6.4.1 Todas las etiquetas deben ser diseñadas, elaboradas y fijadas
            de tal forma que la información contenida en las mismas
            permanezca disponible durante el uso normal del producto,
            inclusive cuando se trate de productos de venta a granel que se expenden al consumidor.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            7. Requisitos de envasado
            <br />
            7.1 Los envases de los productos objeto de esta Norma deben cumplir con lo siguiente:
            <br />
            7.1.1 Los productos que representen un riesgo a la salud por su acción tóxica,
            corrosiva o inflamable deben contar con dispositivos de seguridad y estar
            contenidos en envases resistentes a niños.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            7.1.2 Los aromatizantes sólidos o pastillas desodorantes deben
            contar con un envase primario que sea de un material
            resistente que no permita su fácil apertura por los niños o
            contar con un envase secundario para su comercialización.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Los comercializadores de los productos de aseo de uso doméstico que
            expenden a granel deben garantizar que el envase en el que se suministra
            el producto al consumidor cumpla con las disposiciones establecidas
            en esta Norma, siendo responsabilidad del comercializador que los
            envases que se utilizan para su venta a granel sean resistentes y
            cuenten con los dispositivos de seguridad necesarios para su venta
            al consumidor. En este caso no se podrán reutilizar envases que se
            hayan utilizado para contener alimentos y bebidas preenvasados.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            8. Presentación de la información
            <br />
            8.1 La información que debe aparecer en la etiqueta de los productos objeto de esta
            Norma, debe indicarse con caracteres claros, visibles, indelebles y fáciles de leer
            por el consumidor en circunstancias normales de compra y uso.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            8.2 Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información a que se refiere esta
            Norma en idioma español, independientemente de que también pueda
            estar en otros idiomas, cuidando de que los caracteres sean por lo
            menos de igual tamaño, proporcionalidad y tipográfica a aquellos
            en los que se presente la información en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            8.3 Tanto las muestras de obsequio como el envase que se utilice para venta
            de los productos a granel, deberán contener una etiqueta en idioma
            español en la cual se establezcan los requisitos a que se refieren
            los incisos 6.3.5 y 6.3.4, de esta Norma, respectivamente.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            8.4 Los términos champú y espray como presentaciones de los
            productos de aseo de uso doméstico, podrán declararse en idioma inglés sin traducción.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            9. Declaraciones
            <br />
            9.1 La información contenida en la etiqueta debe ser veraz, comprobable
            y describirse y presentarse de tal forma que no induzca a error al
            consumidor con respecto a la naturaleza y características del producto.
          </p>
        )
      }
    ]
  }
};
