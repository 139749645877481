/* eslint-disable max-len */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { saveAs } from 'file-saver';

import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  CheckBox,
  Header,
  Input,
  Modal,
  Separator,
  Table
} from '../../components';

import './styles.scss';
import { useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import {
  InternalAuditInvoice
} from '../../interfaces';

interface LocalInspection extends InternalAuditInvoice {
  'check-box': ReactElement
}

interface InvoiceItem {
  invoice: string
  selected: boolean
  type: 'DC' | 'CC'
}

const UsersList = () => {
  const { t } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    createResource: getOperations
  } = useResource<InternalAuditInvoice[]>();

  const {
    createResource: getInvoicesDocuments
  } = useResource<any>();

  const {
    createResource
  } = useResource<any>();

  const {
    setOpenModal,
    soundEffects,
    userRole
  } = useContext(AppContext);

  const [invoices, setInvoices] = useState<InternalAuditInvoice[]>([]);
  const [dates, setDates] = useState({ start: '', end: '' });
  const [customInvoices, setCustomInvoices] = useState<LocalInspection[]>([]);
  const [checkBoxValues, setCheckBoxValues] = useState<{ [invoice: string]: InvoiceItem }>({});
  const [processed, setProcessed] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [textArea, setTextArea] = useState<string>('');
  const [onlyInspectionLists, setOnlyInspectionLists] = useState<boolean>(false);

  const getInvoicesInternalaudit = () => {
    getOperations(
      'admin/notifications/internal-audit-invoices',
      {
        startDate: dates.start,
        endDate: dates.end
      },
      (data) => {
        setInvoices(data);

        data.forEach(item => {
          checkBoxValues[item.invoice] = {
            invoice: item.invoice,
            selected: false,
            type: item.type
          };
        });

        setCheckBoxValues({ ...checkBoxValues });
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getInvoicesReport = () => {
    createResource(
      '/admin/notifications/internal-audit-invoices-report',
      {
        startDate: dates.start,
        endDate: dates.end
      },
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        link.download = `Reporte folios de auditoria interna del ${dayjs(dates.start).format('DD-MM-YYYY')} al ${dayjs(dates.end).format('DD-MM-YYYY')}.csv`;
        link.click();
        Modal.close();
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  useEffect(() => {
    setCustomInvoices(
      invoices.map((invoice: InternalAuditInvoice, index: number) => {
        return ({
          ...invoice,
          'check-box': (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {
                checkBoxValues[invoice.invoice] && (
                  <CheckBox
                    key={index}
                    label=''
                    // eslint-disable-next-line max-len
                    checked={checkBoxValues[invoice.invoice].selected}
                    onChange={() => {
                      if (checkBoxValues[invoice.invoice] === undefined) {
                        checkBoxValues[invoice.invoice].selected = true;
                        checkBoxValues[invoice.invoice].type = invoice.type;

                        setCheckBoxValues({ ...checkBoxValues });
                      } else {
                        // eslint-disable-next-line max-len
                        checkBoxValues[invoice.invoice].selected = !checkBoxValues[invoice.invoice].selected;
                        checkBoxValues[invoice.invoice].type = invoice.type;

                        setCheckBoxValues({ ...checkBoxValues });
                      }
                    }}
                  />
                )
              }
            </div>
          )
        }
        );
      })
    );
  }, [invoices, checkBoxValues]);

  const donwloadFiles = async () => {
    const invoicesArray: InvoiceItem[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const key in checkBoxValues) {
      // eslint-disable-next-line max-len, no-prototype-builtins
      if (checkBoxValues.hasOwnProperty(key)) {
        if (checkBoxValues[key].selected === true) {
          invoicesArray.push(checkBoxValues[key]);
        }
      }
    }

    // getInvoicesDocuments(
    //   'admin/notifications/bulk-invoices-internal-audit',
    //   {
    //     invoices: invoicesArray
    //   },
    //   (data) => {
    //     Modal.fireLoading();

    //     const blob = new Blob([data], { type: 'application/zip' });
    //     saveAs(blob, `Expedientes ${dayjs(dates.start).format('DD-MMM-YYYY')} al ${dayjs(dates.end).format('DD-MMM-YYYY')}.zip`);

    //     Modal.close();
    //   },
    //   (error) => Modal.fireError(error as string, undefined, soundEffects),
    //   {
    //     responseType: 'blob'
    //   }
    // );
    setTotalItems(invoicesArray.length);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of invoicesArray) {
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      await new Promise<void>((resolve, reject) => {
        getInvoicesDocuments(
          'admin/notifications/bulk-invoices-internal-audit',
          {
            invoices: [item],
            onlyInspectionLists
          },
          (data) => {
            const blob = new Blob([data], { type: 'application/zip' });
            saveAs(blob, `${item.invoice}.zip`);

            resolve();
          },
          (error) => {
            Modal.fireError(error as string, undefined, soundEffects);
            reject(error);
          },
          {
            responseType: 'blob'
          },
          false
        );
      });

      setProcessed(processed + 1);
    }

    setProcessed(0);
    setTotalItems(null);
    Modal.close();
  };

  const donwloadFilesAudit = async () => {
    const dataInvoices: string[] = textArea.trim().replace(/\n/g, '').split(',');

    setTotalItems(dataInvoices.length);

    // eslint-disable-next-line no-restricted-syntax
    for (const item of dataInvoices) {
      // eslint-disable-next-line no-await-in-loop, no-loop-func
      await new Promise<void>((resolve, reject) => {
        getInvoicesDocuments(
          'admin/notifications/bulk-invoices-internal-audit',
          {
            invoices: [item.trim()],
            onlyInspectionLists
          },
          (data) => {
            const blob = new Blob([data], { type: 'application/zip' });
            saveAs(blob, `${item}.zip`);

            resolve();
          },
          (error) => {
            Modal.fireError(error as string, undefined, soundEffects);
            reject(error);
          },
          {
            responseType: 'blob'
          },
          false
        );
      });

      setProcessed(processed + 1);
    }

    setProcessed(0);
    setTotalItems(null);
    Modal.close();
  };

  useEffect(() => {
    if (totalItems !== null) {
      Modal.fireLoading(undefined, Math.round(((processed / totalItems) || 0) * 100), t('global.downloading') || 'Descargando...');
    }
  }, [processed, totalItems]);

  const ableButton = (): number => {
    let itemsSelected: number = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const key in checkBoxValues) {
      // eslint-disable-next-line max-len, no-prototype-builtins
      if (checkBoxValues.hasOwnProperty(key)) {
        if (checkBoxValues[key].selected === true) {
          itemsSelected += 1;
        }
      }
    }

    return itemsSelected;
  };

  useEffect(() => {
    if (dates.start !== '' && dates.end !== '') {
      getInvoicesInternalaudit();
    }
  }, [dates]);

  return (
    <div className="reports">
      <Header
        title={t('menu.internalAudit') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          <>
            <Input
              type={'textarea'}
              value={textArea}
              onChange={(value: string | number) => {
                setTextArea(`${value}`);
              }}
              className="reports__main__container__textarea"
              title={t('global.invoices') || ''}
              placeholder={t('global.internalAuditInput') || ''}
            />
            <Button
              label={t('global.internalAuditInvoicesDownloadFiles') || ''}
              type={'secondary-outlined'}
              size='big'
              icon='downloadWhite'
              onClick={donwloadFilesAudit}
              disabled={textArea.trim() === ''}
            />
            {
              (userRole === 'sys-admin' || userRole === 'master') && (
                <CheckBox
                  label={'Sólo listas de inspección'}
                  checked={onlyInspectionLists}
                  onChange={() => {
                    setOnlyInspectionLists(!onlyInspectionLists);
                  }}
                />
              )
            }
          </>
          <Separator orientation='horizontal' />
          <div className="reports__main__container__inputs">
            <div className="reports__main__container__inputs__dates-container">
              <Input
                title='Fecha inicio'
                type={'date'}
                value={dates.start}
                onChange={(_value: string | number, _id: string) => {
                  setDates({
                    ...dates,
                    start: `${_value}`
                  });
                }}
              />
              <Input
                title='Fecha fin'
                type={'date'}
                value={dates.end}
                onChange={(_value: string | number, _id: string) => {
                  setDates({
                    ...dates,
                    end: `${_value}`
                  });
                }}
              />
            </div>
          </div>
          {/* <Button
            label={t('global.internalAuditInvoices', { startDate: dates.start ? dayjs(dates.start).format('DD-MMM-YYYY') : '------', endDate: dates.end ? dayjs(dates.end).format('DD-MMM-YYYY') : '------' }) || ''}
            type={'secondary-outlined'}
            size='big'
            icon='search'
            onClick={getInvoicesInternalaudit}
            disabled={dates.start === '' || dates.end === ''}
          /> */}
          <Button
            label={t('global.internalAuditInvoicesDownload', { quantity: ableButton() }) || ''}
            type={'primary'}
            size='big'
            icon='downloadWhite'
            onClick={donwloadFiles}
            disabled={ableButton() === 0}
          />
          <Button
            label={t('global.internalAuditInvoicesDownloadCSV', { startDate: dates.start ? dayjs(dates.start).format('DD-MMM-YYYY') : '------', endDate: dates.end ? dayjs(dates.end).format('DD-MMM-YYYY') : '------' }) || ''}
            type={'secondary-outlined'}
            size='big'
            icon='downloadWhite'
            onClick={getInvoicesReport}
            disabled={dates.start === '' || dates.end === '' || customInvoices.length === 0}
          />
          <div className="reports__main__container__table-container">
            <Table
              headers={[
                {
                  label: t('global.invoice'),
                  value: 'invoice',
                  format: 'none'
                },
                {
                  label: t('global.requestInvoice'),
                  value: 'requestInvoice',
                  format: 'none'
                },
                {
                  label: t('services.visitDate'),
                  value: 'visitDate',
                  format: 'date'
                },
                {
                  label: t('global.result'),
                  value: 'result',
                  format: 'none'
                },
                {
                  label: t('global.inspectionDate'),
                  value: 'inspectionDate',
                  format: 'date'
                },
                {
                  label: t('inspections.round'),
                  value: 'round',
                  format: 'none'
                },
                {
                  label: t('services.inspectorName'),
                  value: 'inspector',
                  format: 'none'
                },
                {
                  label: t('global.measureEquipement'),
                  value: 'measurementEquipment',
                  format: 'none'
                },
                {
                  label: t('global.documentIssueDate'),
                  value: 'documentIssueDate',
                  format: 'date'
                },
                {
                  label: t('global.norm'),
                  value: 'norm',
                  format: 'none'
                },
                {
                  label: t('global.client'),
                  value: 'client',
                  format: 'none'
                },
                {
                  label: t('services.service'),
                  value: 'service',
                  format: 'none'
                },
                {
                  label: t('global.serviceType'),
                  value: 'type',
                  format: 'internalAuditServiceType'
                },
                {
                  label: t('tasks.table.select'),
                  value: 'check-box',
                  format: 'custom-component'
                }
              ]}
              items={customInvoices}
              floatingHeader={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersList;
