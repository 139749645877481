import React from 'react';
import { Title } from '..';
import Icon from '../Icon';

import './styles.scss';

interface Props {
  progressTitle: string
  status: string[]
  currenStatus: string
}

const ProgressIndicator: React.FC<Props> = (props: Props) => {
  const {
    progressTitle,
    status,
    currenStatus
  } = props;

  const displayStatus: any[] = [];
  let completedStatus: boolean = true;

  for (let i = 0; i < status.length; i += 1) {
    displayStatus.push(
      <div className={completedStatus ? 'progress-indicator__status--completed' : 'progress-indicator__status--uncompleted'} key={i}>
        <div className={completedStatus ? 'progress-indicator__status--completed__ctn' : 'progress-indicator__status--uncompleted__ctn'}>
          <div className={completedStatus ? 'progress-indicator__status--completed__ctn__circle' : 'progress-indicator__status--uncompleted__ctn__circle'}>
            {
              completedStatus && (
                <Icon
                  type={'check'}
                  alt={'Si'}
                  onClick={() => {}}
                  className=""
                />
              )
            }
          </div>
          {
            i < status.length - 1 && (
              <div className={completedStatus ? 'progress-indicator__status--completed__ctn__separator' : 'progress-indicator__status--uncompleted__ctn__separator'} key={`${i}-separtor`} />
            )
          }
        </div>
        <div className={completedStatus ? 'progress-indicator__status--completed__label' : 'progress-indicator__status--uncompleted__label'}>
          <span>{status[i]}</span>
        </div>
      </div>
    );

    if (currenStatus === status[i]) {
      completedStatus = false;
    }
  }

  return (
    <div className="progress-indicator">
      <Title title={progressTitle} type="primary"/>
      <br/>

      {displayStatus}
    </div>
  );
};

export default ProgressIndicator;
