import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  ModalView,
  Modal,
  FileInput
} from '../../../../components';

import {
  FileInputData,
  SignedFile,
  User
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { AppContext } from '../../../../context/AppContext';

const imageSize = 300; // px

interface Props {
  userID: string
  visible: boolean
  onClose: () => void
  onSuccess: () => void
}

export const UploadProfilePicture: React.FC<Props> = (props) => {
  const {
    userID,
    visible,
    onClose,
    onSuccess
  } = props;

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    updateResource
  } = useResource<User>();

  const {
    soundEffects,
    setOpenModal
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [imgSource, setImgSource] = useState<string>('');

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const uploadProfilePicture = () => {
    // eslint-disable-next-line no-undef
    const canvas: any = document.getElementById('canvas');

    registerUploadedFiles(
      '/admin/users/files/picture',
      {
        filePaths: [{
          path: dataURLtoBlob(canvas.toDataURL()),
          type: 'image/png'
        }]
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', dataURLtoBlob(canvas.toDataURL()));

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPaths.push(item.url);
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length === 1) {
          updateResource(
            `admin/users/${userID}/update-picture-path`,
            {
              picturePath: uploadedPaths[0]
            },
            (_user) => {
              onSuccess();
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleConfirmModal = () => {
    if (imgSource) {
      uploadProfilePicture();
    } else {
      Modal.fireError(t('profile.shouldSelectImage'), undefined, soundEffects);
      onClose();
    }
  };

  const setWidthDestinationImage = (width: number) => {
    if (width < imageSize) {
      const borders = imageSize - width;
      return borders / 2;
    }

    return 0;
  };

  const setHeightDestinationImage = (height: number) => {
    if (height < imageSize) {
      const borders = imageSize - height;
      return borders / 2;
    }

    return 0;
  };

  const setWidthCropImage = (width: number) => {
    if (width > imageSize) {
      const borders = width - imageSize;
      return borders / 2;
    }

    return 0;
  };

  const setHeightCropImage = (height: number) => {
    if (height > imageSize) {
      const borders = height - imageSize;
      return borders / 2;
    }

    return 0;
  };

  useEffect(() => {
    if (imgSource) {
      // eslint-disable-next-line no-undef
      const canvas: any = document.getElementById('canvas');
      const ctx = canvas.getContext('2d');

      // eslint-disable-next-line no-undef
      const image = new Image();
      image.src = imgSource;

      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;

        ctx.clearRect(0, 0, imageSize, imageSize);
        ctx.drawImage(
          image,
          setWidthCropImage(imageWidth),
          setHeightCropImage(imageHeight),
          imageSize,
          imageSize,
          setWidthDestinationImage(imageWidth),
          setHeightDestinationImage(imageHeight),
          imageSize,
          imageSize
        );
      };
    }
  }, [imgSource]);

  useEffect(() => {
    setImgSource('');
    if (imgSource) {
      // eslint-disable-next-line no-undef
      const canvas: any = document.getElementById('canvas');
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, imageSize, imageSize);
    }
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={handleConfirmModal}
      customComponent={
        <div className='upload-file-modal'>
          <p className='upload-file-modal__title-normal'>{t('profile.uploadImage')} {imageSize}px x {imageSize}px</p>
          <FileInput
            isMultiple={false}
            acceptedFileFormats='.jpg,.png'
            onSelectFile={(payload: FileInputData[]) => {
              setImgSource(URL.createObjectURL(payload[0].data));
            }}
          />
          {
            imgSource && (
              <div className="canvas-container">
                <p>{t('profile.confirmImage')}</p>
                <canvas id='canvas' width={`${imageSize}px`} height={`${imageSize}px`} className="canvas-container__canvas" />
              </div>
            )
          }
        </div>
      }
    />
  );
};

export default UploadProfilePicture;
