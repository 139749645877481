export default {
  DC: {
    documentCode: 'F7-49',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-020-SCFI-1997: Información comercial, etiquetado de cueros y pieles curtidas naturales y materiales sintéticos o artificiales con esa apariencia, calzado, marroquinería, así como los productos elaborados con dichos materiales.',
    documentIssueDate: '10/08/2022',
    hasPackingTable: false,
    hasSamplingTable: true,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. información comercial
            <br />
            4.1 Etiquetado y/o marcado de productos terminados
            Todos los materiales y productos terminados objeto de esta
            Norma, deben marcarse en idioma español en forma
            permanente y lugar visible con la información siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre, denominación o razón social completo o abreviado
            del fabricante nacional o importador o su marca registrada.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Definición genérica o específica de los materiales y
            opcionalmente su acabado. (Esta información puede ser ostentada en una etiqueta).
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Para los efectos de esta Norma deben especificarse
            claramente los principales elementos de los artículos
            elaborados con piel o de productos con esa apariencia o
            de sus substitutos de otros materiales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            a) En el calzado: el corte, el forro y la suela. <br />
            Cuando exista combinación de dos o más materiales dentro de
            alguna de estas tres partes, debe especificarse el que predomine.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            b) En las prendas de vestir como abrigos, chamarras, sacos,
            pantalones, faldas, bolsas para dama, guantes, cinturones y
            artículos para viaje como velices, portafolios, mochilas,
            etc., la parte externa del producto y el forro.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            c) En artículos como monederos, carteras, billeteras,
            llaveros, estuches, artículos para escritorio, para oficina,
            deportivos y otros no especificados, se deben indicar el
            material de la vista, sin considerar armazones, divisiones
            y otras partes, excepto el forro cuando éste sea piel.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            d) En los artículos de cuero para uso industrial, como son
            los rollos de banda de cuero, artículos tales como
            refacciones de cuero, empaques de cuero, etc.,
            únicamente debe marcarse en un lugar visible de su embalaje.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            e) Cuando los artículos a que se refiere esta Norma se
            comercialicen como pares, la información podrá presentarse en una o ambas piezas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Etiquetado y/o marcado de piel y cuero curtido
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Las pieles o cueros curtidos únicamente deben
            marcarse en un lugar visible de su embalaje, pudiendo
            hacerlo mediante etiqueta adherida de acuerdo a las
            definiciones establecidas en el capítulo 3. A elección
            del fabricante, puede anteponerse la palabra piel o
            cuero y/o nombre genérico o específico como se muestra a continuación:
            <br /> - Los genéricos: vacuno, equino, caprino u otros.
            <br />- Los específicos: becerro, ternera, cabra, tiburón u otros.
            <br />- Los dos términos, genérico y específico, como se muestra a continuación:
            <br />* vacuno; piel de becerro.
            <br />* Ovino; piel de cordero.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Si se usan los términos de acabado como ante y gamuza,
            deben indicarse en el mismo tipo de tamaño de letra,
            primeramente el nombre específico del animal del que
            proviene y a continuación la palabra acabado. Ejemplos:
            <br />Ternera acabado ante.
            <br />Ternera acabado gamuza.
            <br />Carnaza acabado gamuza.
            <br />Carnaza acabado ante.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Etiquetado y/o marcado de materiales artificiales o sintéticos
            Los materiales terminados con apariencia de piel, cuando se
            encuentren enrollados se pueden marcar colocando una
            etiqueta dentro de la bobina y cuando estén en forma
            de plancha u hojas se deben marcar por medio de etiquetas
            adheridas o colgadas firmemente. Dichos materiales deben
            estar marcados con impresión o etiqueta en forma legible
            y en lugar visible, ostentando una leyenda en español con
            los datos que se indican en el presente capítulo.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-49',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-020-SCFI-1997: Información comercial, etiquetado de cueros y pieles curtidas naturales y materiales sintéticos o artificiales con esa apariencia, calzado, marroquinería, así como los productos elaborados con dichos materiales.',
    documentIssueDate: '10/08/2022',
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. información comercial
            <br />
            4.1 Etiquetado y/o marcado de productos terminados
            Todos los materiales y productos terminados objeto de esta
            Norma, deben marcarse en idioma español en forma
            permanente y lugar visible con la información siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre, denominación o razón social completo o abreviado
            del fabricante nacional o importador o su marca registrada.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Definición genérica o específica de los materiales y
            opcionalmente su acabado. (Esta información puede ser ostentada en una etiqueta).
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Para los efectos de esta Norma deben especificarse
            claramente los principales elementos de los artículos
            elaborados con piel o de productos con esa apariencia o
            de sus substitutos de otros materiales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            a) En el calzado: el corte, el forro y la suela. <br />
            Cuando exista combinación de dos o más materiales dentro de
            alguna de estas tres partes, debe especificarse el que predomine.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            b) En las prendas de vestir como abrigos, chamarras, sacos,
            pantalones, faldas, bolsas para dama, guantes, cinturones y
            artículos para viaje como velices, portafolios, mochilas,
            etc., la parte externa del producto y el forro.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            c) En artículos como monederos, carteras, billeteras,
            llaveros, estuches, artículos para escritorio, para oficina,
            deportivos y otros no especificados, se deben indicar el
            material de la vista, sin considerar armazones, divisiones
            y otras partes, excepto el forro cuando éste sea piel.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            d) En los artículos de cuero para uso industrial, como son
            los rollos de banda de cuero, artículos tales como
            refacciones de cuero, empaques de cuero, etc.,
            únicamente debe marcarse en un lugar visible de su embalaje.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            e) Cuando los artículos a que se refiere esta Norma se
            comercialicen como pares, la información podrá presentarse en una o ambas piezas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Etiquetado y/o marcado de piel y cuero curtido
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Las pieles o cueros curtidos únicamente deben
            marcarse en un lugar visible de su embalaje, pudiendo
            hacerlo mediante etiqueta adherida de acuerdo a las
            definiciones establecidas en el capítulo 3. A elección
            del fabricante, puede anteponerse la palabra piel o
            cuero y/o nombre genérico o específico como se muestra a continuación:
            <br /> - Los genéricos: vacuno, equino, caprino u otros.
            <br />- Los específicos: becerro, ternera, cabra, tiburón u otros.
            <br />- Los dos términos, genérico y específico, como se muestra a continuación:
            <br />* vacuno; piel de becerro.
            <br />* Ovino; piel de cordero.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Si se usan los términos de acabado como ante y gamuza,
            deben indicarse en el mismo tipo de tamaño de letra,
            primeramente el nombre específico del animal del que
            proviene y a continuación la palabra acabado. Ejemplos:
            <br />Ternera acabado ante.
            <br />Ternera acabado gamuza.
            <br />Carnaza acabado gamuza.
            <br />Carnaza acabado ante.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Etiquetado y/o marcado de materiales artificiales o sintéticos
            Los materiales terminados con apariencia de piel, cuando se
            encuentren enrollados se pueden marcar colocando una
            etiqueta dentro de la bobina y cuando estén en forma
            de plancha u hojas se deben marcar por medio de etiquetas
            adheridas o colgadas firmemente. Dichos materiales deben
            estar marcados con impresión o etiqueta en forma legible
            y en lugar visible, ostentando una leyenda en español con
            los datos que se indican en el presente capítulo.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-49',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-020-SCFI-1997: Información comercial, etiquetado de cueros y pieles curtidas naturales y materiales sintéticos o artificiales con esa apariencia, calzado, marroquinería, así como los productos elaborados con dichos materiales.',
    documentIssueDate: '10/08/2022',
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. información comercial
            <br />
            4.1 Etiquetado y/o marcado de productos terminados
            Todos los materiales y productos terminados objeto de esta
            Norma, deben marcarse en idioma español en forma
            permanente y lugar visible con la información siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            a) Nombre, denominación o razón social completo o abreviado
            del fabricante nacional o importador o su marca registrada.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) La leyenda "Hecho (u otros análogos) en México" o bien, el nombre del país de origen.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Definición genérica o específica de los materiales y
            opcionalmente su acabado. (Esta información puede ser ostentada en una etiqueta).
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Para los efectos de esta Norma deben especificarse
            claramente los principales elementos de los artículos
            elaborados con piel o de productos con esa apariencia o
            de sus substitutos de otros materiales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            a) En el calzado: el corte, el forro y la suela. <br />
            Cuando exista combinación de dos o más materiales dentro de
            alguna de estas tres partes, debe especificarse el que predomine.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            b) En las prendas de vestir como abrigos, chamarras, sacos,
            pantalones, faldas, bolsas para dama, guantes, cinturones y
            artículos para viaje como velices, portafolios, mochilas,
            etc., la parte externa del producto y el forro.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            c) En artículos como monederos, carteras, billeteras,
            llaveros, estuches, artículos para escritorio, para oficina,
            deportivos y otros no especificados, se deben indicar el
            material de la vista, sin considerar armazones, divisiones
            y otras partes, excepto el forro cuando éste sea piel.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            d) En los artículos de cuero para uso industrial, como son
            los rollos de banda de cuero, artículos tales como
            refacciones de cuero, empaques de cuero, etc.,
            únicamente debe marcarse en un lugar visible de su embalaje.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            e) Cuando los artículos a que se refiere esta Norma se
            comercialicen como pares, la información podrá presentarse en una o ambas piezas.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Etiquetado y/o marcado de piel y cuero curtido
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Las pieles o cueros curtidos únicamente deben
            marcarse en un lugar visible de su embalaje, pudiendo
            hacerlo mediante etiqueta adherida de acuerdo a las
            definiciones establecidas en el capítulo 3. A elección
            del fabricante, puede anteponerse la palabra piel o
            cuero y/o nombre genérico o específico como se muestra a continuación:
            <br /> - Los genéricos: vacuno, equino, caprino u otros.
            <br />- Los específicos: becerro, ternera, cabra, tiburón u otros.
            <br />- Los dos términos, genérico y específico, como se muestra a continuación:
            <br />* vacuno; piel de becerro.
            <br />* Ovino; piel de cordero.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Si se usan los términos de acabado como ante y gamuza,
            deben indicarse en el mismo tipo de tamaño de letra,
            primeramente el nombre específico del animal del que
            proviene y a continuación la palabra acabado. Ejemplos:
            <br />Ternera acabado ante.
            <br />Ternera acabado gamuza.
            <br />Carnaza acabado gamuza.
            <br />Carnaza acabado ante.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Etiquetado y/o marcado de materiales artificiales o sintéticos
            Los materiales terminados con apariencia de piel, cuando se
            encuentren enrollados se pueden marcar colocando una
            etiqueta dentro de la bobina y cuando estén en forma
            de plancha u hojas se deben marcar por medio de etiquetas
            adheridas o colgadas firmemente. Dichos materiales deben
            estar marcados con impresión o etiqueta en forma legible
            y en lugar visible, ostentando una leyenda en español con
            los datos que se indican en el presente capítulo.
          </p>
        )
      }
    ]
  }
};
