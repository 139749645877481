import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskCard, Title } from '../../../../components';
import { Task } from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';
import storage from '../../../../storage';

interface Counter {
  scheduled: number
  'in-progress': number
}

interface Props {
  items: Task[]
  statusCounter: Counter
}

const TasksList: React.FC<Props> = (props) => {
  const {
    items,
    statusCounter
  } = props;

  const {
    setVisibleTaskDetailModal
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [tasks, setTasks] = useState<Task[]>(items);

  useEffect(() => {
    setTasks(items);
  }, [items]);

  return (
    <>
      <div className="tasks-view__main__right-container__status-column tasks-view__main__right-container__status-column--grey-2">
        <Title title={`${t('tasks.inProgress')} (${statusCounter['in-progress']})`} type='secondary' />
        {
          tasks.map((item: Task, index: number) => (
            item.status === 'in-progress' && (
              <TaskCard
                key={index}
                task={item}
                onClick={(_task: Task) => {
                  storage.set('lastTaskDetailID', _task.id);
                  storage.set('lastInspectionDetailID', '');
                  setVisibleTaskDetailModal({
                    taskID: _task.id,
                    visible: true
                  });
                }}
              />
            )
          ))
        }
      </div>
    </>
  );
};

export default TasksList;
