import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal, Table } from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { emptyCollaborator } from '../../../../emptyObjects';
import { useResource } from '../../../../hooks';
import {
  Collaborator,
  TableHeader
} from '../../../../interfaces';
import CollaboratorForm from '../CollaboratorForm';

interface CustomCollaborator extends Collaborator {
  'buttons': ReactElement
}

interface Props {
  clientID: string
  collaborators: Collaborator[]
}

export const Collaborators: React.FC<Props> = (props) => {
  const {
    clientID,
    collaborators
  } = props;

  const {
    eraseResource,
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [customCollaborators, setCustomCollaborators] = useState<CustomCollaborator[]>([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState<Collaborator>(emptyCollaborator);
  const [editCollaboratorModalVisible, setEditCollaboratorModalVisible] = useState<boolean>(false);

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.name'),
      value: 'name',
      format: 'none'
    },
    {
      label: 'E-mail',
      value: 'email',
      format: 'none'
    },
    {
      label: t('clients.phone'),
      value: 'phone',
      format: 'none'
    },
    {
      label: t('global.active'),
      value: 'isActive',
      format: 'boolean'
    },
    {
      label: t('global.options'),
      value: 'buttons',
      format: 'custom-component'
    }
  ];

  const buildCustomCollaborators = (_collaborators: Collaborator[]) => {
    setCustomCollaborators(
      _collaborators.map((collaborator: Collaborator) => {
        return {
          ...collaborator,
          buttons: (
            <div className="client-service-detail__buttons-container">
              <Button
                onClick={() => {
                  setSelectedCollaborator(collaborator);
                  setEditCollaboratorModalVisible(true);
                }}
                type='secondary-outlined'
                label={t('global.edit') || ''}
                disabled={!['master', 'finance'].includes(userRole)}
              />
              <Button
                onClick={collaborator.isActive
                  ? () => handleDeleteCollaborator(collaborator.id)
                  : () => handleAbleCollaborator(collaborator.id)}
                type='secondary-outlined'
                label={collaborator.isActive ? t('global.disable') || '' : t('global.able') || ''}
                transparent={true}
                border='secondary-outlined'
                disabled={!['master', 'finance'].includes(userRole)}
              />
            </div>
          )
        };
      })
    );
  };

  const handleAbleCollaborator = (
    collaboratorID: string
  ) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('clients.ableCollaboratorAsk'),
      () => {
        const auxCollaborators = collaborators;

        const editCollaborator: Collaborator = {
          ...auxCollaborators.find(element => element.id === collaboratorID) || emptyCollaborator,
          isActive: true
        };

        let editCollaboratorIndex = 0;

        for (let index = 0; index < auxCollaborators.length; index += 1) {
          if (auxCollaborators[index].id === collaboratorID) {
            editCollaboratorIndex = index;
          }
        }

        auxCollaborators[editCollaboratorIndex] = editCollaborator;

        updateCollaborator(
          `admin/collaborators/${collaboratorID}/enable`,
          {},
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('clients.ableCollaborator'),
              setOpenModal,
              () => buildCustomCollaborators(auxCollaborators),
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const handleDeleteCollaborator = (collaboratorID: string) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('clients.disableCollaborator'),
      () => {
        const auxCollaborators = collaborators;

        const editCollaborator: Collaborator = {
          ...auxCollaborators.find(element => element.id === collaboratorID) || emptyCollaborator,
          isActive: false
        };

        let editCollaboratorIndex = 0;

        for (let index = 0; index < auxCollaborators.length; index += 1) {
          if (auxCollaborators[index].id === collaboratorID) {
            editCollaboratorIndex = index;
          }
        }

        auxCollaborators[editCollaboratorIndex] = editCollaborator;

        eraseResource(
          `admin/collaborators/${collaboratorID}`,
          {},
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('clients.disabledCollaborator'),
              setOpenModal,
              () => buildCustomCollaborators(auxCollaborators),
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    buildCustomCollaborators(collaborators);
  }, [collaborators]);

  return (
    <>
      <Table
        headers={tableHeaders}
        items={customCollaborators}
        currentPage={0}
        perPage={0}
        totalItems={0}
        orderBy=''
        orderDirection='ASC'
        onChangeCurrentPage={() => { }}
        onChangeOrder={() => { }}
      />
      <CollaboratorForm
        visible={editCollaboratorModalVisible}
        title='Editar colaborador'
        clientID={clientID}
        collaborator={selectedCollaborator}
        onClose={() => {
          setEditCollaboratorModalVisible(false);
        }}
        onSuccess={(collaborator: Collaborator) => {
          setEditCollaboratorModalVisible(false);

          const auxCollaborators = customCollaborators;

          for (let index = 0; index < auxCollaborators.length; index += 1) {
            if (auxCollaborators[index].id === selectedCollaborator.id) {
              auxCollaborators[index] = {
                ...collaborator,
                buttons: (
                  <div className="client-service-detail__buttons-container">
                    <Button
                      onClick={() => {
                        setSelectedCollaborator(auxCollaborators[index]);
                        setEditCollaboratorModalVisible(true);
                      }}
                      type='secondary-outlined'
                      label={t('global.edit') || ''}
                    />
                    <Button
                      onClick={() => handleDeleteCollaborator(auxCollaborators[index].id)}
                      type='secondary-outlined'
                      label={t('global.delete') || ''}
                      transparent={true}
                      border='secondary-outlined'
                    />
                  </div>
                )
              };
            }
          }

          buildCustomCollaborators(auxCollaborators);
          Modal.fireSuccess(t('global.correct'), t('clients.updatedCollaborator'), setOpenModal, undefined, soundEffects);
        }}
        onError={(error: string) => {
          setEditCollaboratorModalVisible(false);
          Modal.fireError(error, undefined, soundEffects);
        }}
      />
    </>
  );
};

export default Collaborators;
