import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import {
  Button,
  Header,
  Input,
  Modal
} from '../../..';
import { emptyTask } from '../../../../emptyObjects';
import { useNavigate, useResource } from '../../../../hooks';
import { Task } from '../../../../interfaces';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface Statements {
  client: string
  inspector: string
}

export const ClientAndInspectorStatements = () => {
  const params = useParams();

  const {
    fetchResource: getTask,
    updateResource
  } = useResource<Task>();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [task, setTask] = useState<Task>(emptyTask);
  const [statements, setStatements] = useState<Statements>({
    client: '',
    inspector: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: boolean }>({});

  const {
    setOpenModal,
    soundEffects,
    setTaskIDModalAfterActions
  } = useContext(AppContext);

  const handleSaveStatements = () => {
    if (statements.inspector) {
      setErrors({
        inspectorStatement: false
      });

      updateResource(
        `/admin/tasks/${task.id}/statements`,
        {
          clientStatements: statements.client ? statements.client : 'Sin declaraciones por parte del cliente',
          visitorStatements: statements.inspector
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('tasks.savedDeclarations'),
            setOpenModal,
            () => navigate(-1),
            soundEffects
          );
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    } else {
      setErrors({
        inspectorStatement: true
      });
    }
  };

  useEffect(() => {
    if (params.taskID !== 'none') {
      getTask(
        `/admin/tasks/${params.taskID}`,
        (data) => {
          setTask(data);
          setStatements({
            client: data.visit?.clientStatements || '',
            inspector: data.visit?.visitorStatements || ''
          });
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [params.taskID]);

  useEffect(() => {
    setTaskIDModalAfterActions(params.taskID || '');
  }, [params.taskID]);

  return (
    <div className='statements' >
      <Header
        title={t('tasks.witnessComments')}
        subTitle={[
          {
            label: task.number,
            title: t('tasks.titleSingle') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className='statements__content'>
        <p className='statements__content__title'>
          {t('tasks.clientStatements1')}
        </p>
        <p className='statements__content__sub-title'>
          {t('tasks.clientStatements2')}
        </p>
        <div className='statements__content__spacer'></div>
        <Input
          title={t('tasks.instectorStatements') || ''}
          type='textarea'
          value={statements.inspector}
          onChange={(_value: string | number) => {
            setStatements(
              {
                ...statements,
                inspector: String(_value)
              }
            );
          }}
          placeholder={t('tasks.inspectorStatementsExplication') || ''}
          helperText={errors.inspectorStatement ? t('tasks.emptyStatements') || '' : `${t('tasks.statementsOf')} ${task.responsible.name}`}
          hasError={errors.inspectorStatement}
        />
        <div className='statements__content__spacer'></div>
        <Input
          title={t('tasks.clientStatements') || ''}
          type='textarea'
          value={statements.client}
          onChange={(_value: string | number) => {
            setStatements(
              {
                ...statements,
                client: String(_value)
              }
            );
          }}
          placeholder={t('tasks.clientStatementsExplication') || ''}
          helperText={`${t('tasks.statementsOf')} ${task.client.businessName} (${t('global.optional')})`}
        />
        <div className='statements__content__footer-buttons'>
          <Button
            type='primary'
            onClick={handleSaveStatements}
            label={t('global.save') || ''}
          />
        </div>
      </div>
    </div >
  );
};

export default ClientAndInspectorStatements;
