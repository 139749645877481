import React, {
  ReactElement,
  Suspense,
  useContext,
  useEffect,
  useState
} from 'react';
import { driver } from 'driver.js';
import { unparse } from 'papaparse';
import { useTranslation } from 'react-i18next';
import { useResource } from '../../hooks';

import {
  Accordeon,
  Header,
  Title,
  Modal,
  SubTitle,
  Icon,
  TaskCard,
  TaskDetail,
  InspectionDetail,
  Table,
  Button,
  Separator
} from '../../components';
import {
  Collaborator,
  ConstancyProduct,
  DictumProduct,
  FilterTypes,
  Inspection,
  RevisionProduct,
  ServiceCodes,
  TableHeader,
  Task
} from '../../interfaces';
import {
  Calendar
} from './components';
import { ModalInspectionDetail } from './components/ModalInspectionDetail/ModalInspectionDetail';
import './styles.scss';
import storage from '../../storage';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';
import { emptyDictumProduct, emptyConstancyProduct, emptyRevisionProduct } from '../../emptyObjects';

const dayjs = require('dayjs');

interface CSVData {
  Folio: string
  Subfolio: string
  Ronda: string
  Modelo: string
  'Modelo 2'?: string
  'Numero de solicitud': string
  'Diseño de etiquetas': string
  Norma: string
  'Fecha limite de cierre': string
}

interface CustomInspection extends Inspection {
  proposedValidationDate: Date
}

interface AccordeonItem {
  title: string
  element: ReactElement
}

const Tasks = () => {
  const {
    fetchResources
  } = useResource<Task>();

  const {
    // fetchResources: fetchInspections,
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getDictumProduct
  } = useResource<DictumProduct>();

  const {
    fetchResource: getConstancyProduct
  } = useResource<ConstancyProduct>();

  const {
    fetchResource: getRevisionProduct
  } = useResource<RevisionProduct>();

  const {
    fetchResources: getInspectionsList
  } = useResource<CustomInspection>();

  const {
    fetchResource: getCollaborator
  } = useResource<Collaborator>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const { t } = useTranslation();

  const {
    userRole,
    soundEffects,
    inspection,
    visibleInspectionDetailModal,
    setVisibleInspectionDetailModal,
    visibleTaskDetailModal,
    setVisibleTaskDetailModal,
    setOpenModal,
    setInspection,
    setUserToursDone,
    userToursDone
  } = useContext(AppContext);

  const tableHeadersOnSite: TableHeader[] = [
    {
      label: t('services.closedDte'),
      value: 'proposedValidationDate',
      format: 'date'
    },
    {
      label: t('tasks.table.invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: t('global.subInvoice'),
      value: 'product.subInvoice',
      format: 'none'
    },
    {
      label: t('inspections.round'),
      value: 'rounds',
      format: 'quantity'
    },
    {
      label: t('tasks.table.model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: t('tasks.table.model2'),
      value: 'product.code2',
      format: 'none'
    },
    {
      label: t('tasks.table.service'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('tasks.table.norm'),
      value: 'norm',
      format: 'none'
    }
  ];

  const tableHeadersRemote: TableHeader[] = [
    {
      label: t('services.closedDte'),
      value: 'proposedValidationDate',
      format: 'constancy-expired-date'
    },
    {
      label: t('tasks.table.invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: t('global.subInvoice'),
      value: 'product.subInvoice',
      format: 'none'
    },
    {
      label: t('inspections.round'),
      value: 'rounds',
      format: 'quantity'
    },
    {
      label: t('tasks.table.model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: t('services.constancyNumberProduct'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('global.design'),
      value: 'indicators.design',
      format: 'boolean'
    },
    {
      label: t('tasks.table.norm'),
      value: 'norm',
      format: 'none'
    }
  ];

  const [selectedOption, setSelectedOption] = useState<FilterTypes>(storage.get('selectedOptionOnTasksView') as FilterTypes || 'none');
  const [filterValue, setFilterValue] = useState<string>(storage.get('filterValueOnTasksView') || '');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [inspectionsCustom, setInspectionsCustom] = useState<CustomInspection[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [itemsAccordeon, setItemsAccordeon] = useState<AccordeonItem[]>([]);
  const [reFetchData, setReFetchData] = useState<boolean>(false);
  const [clientId, setClientId] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [dictumProduct, setDictumProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [constancyProduct, setConstancyProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [revisionProduct, setRevisionProduct] = useState<RevisionProduct>(emptyRevisionProduct);
  const [inspectionsType, setInspectionsType] = useState<'on-site' | 'constancy' | 'revision'>('on-site');

  const exportToCSV = () => {
    Modal.fireLoading();

    const data: CSVData[] = [];

    if (['constancy', 'revision'].includes(inspectionsType)) {
      inspectionsCustom.forEach(inspectionItem => {
        data.push({
          Folio: inspectionItem.product.invoice,
          Subfolio: `${inspectionItem.product.subInvoice || 'Sin definir'}`,
          Ronda: `${inspectionItem.rounds.length}`,
          Modelo: inspectionItem.product.code,
          'Numero de solicitud': inspectionItem.service.number,
          'Diseño de etiquetas': inspectionItem.indicators.design ? 'Si' : 'No',
          Norma: inspectionItem.norm,
          'Fecha limite de cierre': dayjs(inspectionItem.proposedValidationDate).add(90, 'day').format('DD/MM/YYYY hh:mm a')
        });
      });
    } else {
      inspectionsCustom.forEach(inspectionItem => {
        data.push({
          Folio: inspectionItem.product.invoice,
          Subfolio: `${inspectionItem.product.subInvoice || 'Sin definir'}`,
          Ronda: `${inspectionItem.rounds.length}`,
          Modelo: inspectionItem.product.code,
          'Modelo 2': inspectionItem.product.code2,
          'Numero de solicitud': inspectionItem.service.number,
          'Diseño de etiquetas': inspectionItem.indicators.design ? 'Si' : 'No',
          Norma: inspectionItem.norm,
          'Fecha limite de cierre': dayjs(String(inspectionItem.proposedValidationDate).substring(0, 10)).format('DD/MM/YYYY')
        });
      });
    }

    const csv = unparse(data);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    // eslint-disable-next-line no-undef
    link.href = window.URL.createObjectURL(blob);

    const inspectionsTypes: { [name: string]: string } = {
      'on-site': 'en sitio',
      constancy: 'de constancia',
      revision: 'de revision'
    };

    link.download = `Inspecciones ${inspectionsTypes[inspectionsType]} por agendar - ${clientName} - ${dayjs().format('DD-MMM-YYYY')}.csv`;
    link.click();
    Modal.close();
  };

  const getTodayTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['scheduled', 'in-progress'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        if (tasks[index].status !== 'concluded') {
          auxTasks.push(
            <TaskCard
              key={index}
              task={tasks[index]}
              onClick={(_task: Task) => {
                storage.set('lastTaskDetailID', _task.id);
                setVisibleTaskDetailModal({
                  visible: true,
                  taskID: _task.id
                });
              }}
              showInspector={false}
            />
          );
        }
      }
    }

    return auxTasks;
  };

  const getTodayConcludedTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['concluded'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        auxTasks.push(
          <TaskCard
            key={index}
            task={tasks[index]}
            onClick={(_task: Task) => {
              storage.set('lastTaskDetailID', _task.id);
              setVisibleTaskDetailModal({
                visible: true,
                taskID: _task.id
              });
            }}
            showInspector={false}
          />
        );
      }
    }

    return auxTasks;
  };

  const getTasks = (clientID: string) => {
    fetchResources(
      {
        resourcePath: '/public/tasks',
        filters: {
          // s_status: 'in-progress',
          s_clientID: clientID
        }
      },
      (data) => {
        setTasks(data.items);
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  // const getInspections = (clientID: string) => {
  //   fetchInspections(
  //     {
  //       resourcePath: '/public/inspections',
  //       filters: {
  //         s_status: 'in-review',
  //         s_clientID: clientID
  //       }
  //     },
  //     (data) => {
  //       setInspections(data.items);
  //     },
  //     (error) => {
  //       Modal.fireError(error, undefined, soundEffects);
  //     }
  //   );
  // };

  useEffect(() => {
    const auxSelectedOption = storage.get('selectedOptionOnTasksView');
    const auxFilterValue = storage.get('filterValueOnTasksView');

    if (auxSelectedOption !== 'none') {
      setSelectedOption(auxSelectedOption as FilterTypes);
    }

    if (auxFilterValue) {
      setFilterValue(auxFilterValue);
    }
  }, []);

  const throttledGetMe = utils.throttle(
    () => {
      getCollaborator(
        '/public/collaborators/me',
        (data) => {
          setClientId(data.client.id);
          setClientName(data.client.businessName);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledGetMe();
  }, [
    filterValue,
    selectedOption,
    userRole,
    reFetchData
  ]);

  useEffect(() => {
    if (visibleInspectionDetailModal.visible) {
      getInspection(
        `/public/inspections/${visibleInspectionDetailModal.inspectionID}`,
        (data) => {
          setInspection(data);

          if (['DC', 'DN'].includes(data.service.code)) {
            getDictumProduct(
              `/public/dictum-products/${data.product.id}`,
              (productData) => {
                setDictumProduct(productData);

                setConstancyProduct(emptyConstancyProduct);
                setRevisionProduct(emptyRevisionProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['CC', 'CN'].includes(data.service.code)) {
            getConstancyProduct(
              `/public/constancy-products/${data.product.id}`,
              (productData) => {
                setConstancyProduct(productData);

                setDictumProduct(emptyDictumProduct);
                setRevisionProduct(emptyRevisionProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['REV'].includes(data.service.code)) {
            getRevisionProduct(
              `/public/revision-products/${data.product.id}`,
              (productData) => {
                setRevisionProduct(productData);

                setDictumProduct(emptyDictumProduct);
                setConstancyProduct(emptyConstancyProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [visibleInspectionDetailModal]);

  const getAbleInspections = (_inspections: CustomInspection[]): CustomInspection[] => {
    const auxInspectionsItems: CustomInspection[] = [];

    for (let index = 0; index < _inspections.length; index += 1) {
      auxInspectionsItems.push(_inspections[index]);
    }

    // eslint-disable-next-line max-len
    return auxInspectionsItems.sort((a, b) => dayjs(a.proposedValidationDate).diff(dayjs(b.proposedValidationDate)));
  };

  useEffect(() => {
    if (
      clientId !== ''
    ) {
      getTasks(clientId);

      let filters: any = {};
      let serviceCode: ServiceCodes = 'DC';

      if (inspectionsType === 'constancy') {
        serviceCode = 'CC';
      }

      if (inspectionsType === 'revision') {
        serviceCode = 'REV';
      }

      filters = {
        b_scheduled: false,
        s_clientID: clientId,
        s_serviceCode: serviceCode,
        s_status: 'in-progress',
        b_requestIsCompleted: true,
        b_isReadyForVisit: true
      };

      if (['constancy-inspection', 'revision-inspection', 'remote-inspection'].includes(inspectionsType)) {
        filters.b_waitingForFilesForRemoteRound = true;
        filters.b_changedFilesForRemoteRound = true;
      }

      getInspectionsList(
        {
          resourcePath: 'public/inspections',
          filters
        },
        (data) => {
          if (data.items.length === 0) {
            let filters2: any = {};

            filters2 = {
              b_scheduled: false,
              s_clientID: clientId,
              s_serviceCode: serviceCode,
              s_status: 'in-progress',
              b_requestIsCompleted: true,
              b_isReadyForVisit: true
            };

            if (['remote-inspection', 'constancy-inspection', 'revision-inspection'].includes(inspectionsType)) {
              filters2.b_waitingForFilesForRemoteRound = false;
              filters2.b_changedFilesForRemoteRound = false;
            }

            getInspectionsList(
              {
                resourcePath: 'public/inspections',
                filters: filters2
              },
              (data2) => {
                setInspectionsCustom(getAbleInspections(data2.items));
              },
              (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
            );
          } else {
            setInspectionsCustom(getAbleInspections(data.items));
          }
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    } else {
      setInspectionsCustom([]);
    }
  }, [clientId, inspectionsType]);

  useEffect(() => {
    const auxItemsAccordeon: AccordeonItem[] = [];

    auxItemsAccordeon.push(
      {
        title: t('tasks.tasksCalendar'),
        element: (
          <>
            <Calendar
              tasks={tasks}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
            <div className="tasks-view-collaborator__main__left-container__today-tasks">
              <Icon type='tape' className="tasks-view-collaborator__main__left-container__today-tasks__icon" />
              {
                dayjs(currentDate).format('DD/MM/YYYY') !== dayjs(new Date()).format('DD/MM/YYYY') ? (
                  // eslint-disable-next-line max-len
                  <SubTitle subTitle={`${t('tasks.tasksTo')} ${dayjs(currentDate).format('DD MMM YYYY')} (${getTodayTasks().length})`} />
                ) : (
                  <SubTitle subTitle={`${t('tasks.todayTasks')} (${getTodayTasks().length})`} />
                )
              }
              {
                getTodayTasks().map((task: ReactElement) => (
                  task
                ))
              }
              {
                getTodayConcludedTasks().length > 0 && (
                  <div className='tasks-view-collaborator__concluded-task'>
                    <div className='tasks-view-collaborator__concluded-task__separator'></div>
                    <p className='tasks-view-collaborator__concluded-task__text'>{t('tasks.concluded')}</p>
                    <div className='tasks-view-collaborator__concluded-task__separator'></div>
                  </div>
                )
              }
              {
                getTodayConcludedTasks().map((task: ReactElement) => (
                  task
                ))
              }
            </div>
          </>
        )
      }
    );

    setItemsAccordeon(auxItemsAccordeon);
  }, [
    userRole,
    tasks,
    currentDate,
    t
  ]);

  const driverObj = driver({
    showProgress: true,
    allowClose: false,
    smoothScroll: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#task-view', popover: { title: t('tours.inspectionsControl') || '', description: t('tours.inspectionsControlDescription') || '' } },
      { element: '#calendar', popover: { title: t('tours.calendarControl') || '', description: t('tours.calendarControlDescription') || '' } },
      { element: '#inspections-list', popover: { title: t('tours.inspectionsControlList') || '', description: t('tours.inspectionsControlListDescription') || '' } },
      { element: '#inspections-types', popover: { title: t('tours.inspectionsTypeList') || '', description: t('tours.inspectionsTypeListDescription') || '' } },
      { element: '#inspections-report', popover: { title: t('tours.inspectionsReportList') || '', description: t('tours.inspectionsReportListDescription') || '' } }
    ],
    onDestroyed: () => {
      updateCollaborator(
        'public/collaborators/tour-done',
        {
          tourDone: 'inspections-control'
        },
        (data) => {
          setUserToursDone(data.toursDone || []);
          storage.set('userToursDone', JSON.stringify(data.toursDone));
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('inspections-control') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <div className='tasks-view-collaborator' id='task-view'>
      <Header
        title={t('global.inspectionsControl')}
        showBackbutton={false}
      />
      <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
        <TaskDetail
          onClose={() => {
            setVisibleTaskDetailModal({
              taskID: '',
              visible: false
            });
            storage.set('lastTaskDetailID', '');
          }}
          setVisibleTaskDetailModal={setVisibleTaskDetailModal}
          visibleTaskDetailModal={visibleTaskDetailModal}
          lookInTheTrash={false}
        />
        <ModalInspectionDetail
          type='custom'
          visible={visibleInspectionDetailModal.visible}
          customComponent={
            <>
              <Title title={`${t('tasks.inspection')}: ${inspection.number}`} />
              <InspectionDetail
                lookInTheTrash={false}
                setShowProductInformation={() => { }}
                dictumProduct={dictumProduct}
                constancyProduct={constancyProduct}
                revisionProduct={revisionProduct}
              // getInspections={() => getInspections(clientId)}
              />
            </>
          }
          onCancel={() => {
            setVisibleInspectionDetailModal({
              inspectionID: '',
              visible: false
            });
            storage.set('lastInspectionDetailID', '');
            setReFetchData(!reFetchData);
          }}
        />
      </Suspense>
      <div className="tasks-view-collaborator__main">
        <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
          <div className="tasks-view-collaborator__main__left-container">
            <Accordeon items={itemsAccordeon} />
          </div>
        </Suspense>
        <div className="tasks-view-collaborator__main__right-container" id='inspections-list'>
          <div className="tasks-view-collaborator__main__right-container__status-column-container">
            <Title title={t('global.inspectionsList')} type='secondary' />
            <SubTitle subTitle={t('tasks.selectList')} />
            <div className='tasks-view-collaborator__main__right-container__top-buttons' id='inspections-types'>
              <Button
                type={inspectionsType === 'on-site' ? 'primary' : 'secondary-outlined'}
                border='primary'
                onClick={() => setInspectionsType('on-site')}
                label={'Inspecciones en sitio'}
              />
              <Button
                type={inspectionsType === 'constancy' ? 'primary' : 'secondary-outlined'}
                border='primary'
                onClick={() => setInspectionsType('constancy')}
                label={'Inspecciones de constancia'}
              />
              <Button
                type={inspectionsType === 'revision' ? 'primary' : 'secondary-outlined'}
                border='primary'
                onClick={() => setInspectionsType('revision')}
                label={'Inspecciones de revisión'}
              />
            </div>
            <Separator orientation={'horizontal'} />
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}>
              <Button
                type={'primary'}
                onClick={exportToCSV}
                label='Descargar información de inspecciones'
                icon='downloadWhite'
                disabled={inspectionsCustom.length === 0}
                id='inspections-report'
              />
            </div>
            <Table
              disableAnimation={true}
              headers={['constancy', 'revision'].includes(inspectionsType) ? tableHeadersRemote : tableHeadersOnSite}
              items={inspectionsCustom}
              onClickRow={(item) => {
                setVisibleInspectionDetailModal({
                  inspectionID: item.id,
                  visible: true
                });
              }}
            />
          </div>
        </div>
      </div>
    </div >
  );
};

export default Tasks;
