import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Accordeon,
  ActionButtons,
  Button,
  Header,
  LateralMenu,
  Modal
} from '../../components';
import { AppContext } from '../../context/AppContext';
import { emptyUser } from '../../emptyObjects';
import { useResource } from '../../hooks';
import { LateralMenuOption, User } from '../../interfaces';
import GeneralInfo from './components/GeneralInfo';

import EditUser from './components/UserForm';

import './styles.scss';

type UserDetailContentType = 'info';

export const UserDetail = () => {
  const params = useParams();
  const { userID } = params;

  const {
    fetchResource,
    eraseResource
  } = useResource<User>();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [lateralMenuValue, setLateralMenuValue] = useState<UserDetailContentType>('info');
  const [editUserModalVisible, setEditUserModalVisible] = useState<boolean>(false);
  const [user, setUser] = useState<User>(emptyUser);

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    }
  ];

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo user={user} />)
    };

    return contents[lateralMenuValue];
  }, [lateralMenuValue, user]);

  useEffect(() => {
    fetchResource(
      `/admin/users/${userID}`,
      (data) => {
        setUser(data);
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [userID]);

  const setSubTitle = useCallback(() => {
    let result = {
      label: '',
      title: ''
    };

    if (user.substituteTechnicalManager) {
      result = {
        title: user.name,
        label: t('users.substituteTechnicalManager') || ''
      };
    } else if (user.technicalManager) {
      result = {
        title: user.name,
        label: t('users.technicalManager') || ''
      };
    }

    return result;
  }, [user]);

  return (
    <div className="user-detail">
      <Header
        title={t('users.titleS')}
        subTitle={[setSubTitle()]}
        showBackbutton={true}
      />
      <EditUser
        visible={editUserModalVisible}
        title={t('users.editUserInfo')}
        user={user}
        onClose={() => {
          setEditUserModalVisible(false);
        }}
        onSuccess={(_user: User) => {
          setEditUserModalVisible(false);
          setUser(_user);
          Modal.fireSuccess(t('global.correct'), t('users.updatedUser'), setOpenModal, undefined, soundEffects);
        }}
        onError={(error: string) => {
          setEditUserModalVisible(false);
          Modal.fireError(error, setOpenModal, soundEffects);
        }}
      />
      <div className="user-detail__main">
        <div className="user-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={(value: string) => {
                        setLateralMenuValue((value as UserDetailContentType));
                      }}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={[
                    {
                      button: (
                        <Button
                          onClick={() => {
                            setEditUserModalVisible(true);
                          }}
                          type='primary-outlined'
                          label={t('users.editUser') || ''}
                          iconPosition='left'
                          fullWidth={true}
                          size='big'
                          icon='edit'
                          alignContent='left'
                          disabled={user.role === 'master' && ['master'].includes(userRole)}
                        />
                      ),
                      available: true,
                      specialValidation: userRole !== 'quality'
                    },
                    {
                      button: (
                        <Button
                          onClick={() => {
                            Modal.fireWarning(
                              user.isActive ? t('users.deletedUserAsk') : t('users.enableUserAsk'),
                              () => {
                                eraseResource(
                                  `/admin/users/${userID}`,
                                  {},
                                  (data) => {
                                    Modal.fireSuccess(
                                      t('global.correct'),
                                      t('users.disabledUser'),
                                      setOpenModal,
                                      () => {
                                        setUser(data);
                                      },
                                      soundEffects
                                    );
                                  },
                                  (error) => Modal.fireError(error, setOpenModal, soundEffects)
                                );
                              },
                              setOpenModal
                            );
                          }}
                          type='primary-outlined'
                          label={user.isActive ? t('users.deleteUser') || '' : t('users.ableUser') || ''}
                          iconPosition='left'
                          fullWidth={true}
                          size='big'
                          icon={user.isActive ? 'paperShredder' : 'circleSucces'}
                          alignContent='left'
                          disabled={!['master'].includes(userRole) || user.role === 'master'}
                        />
                      ),
                      available: true,
                      specialValidation: userRole !== 'quality' && ['master'].includes(userRole)
                    }
                  ]} />
                )
              }
            ]}
          />
        </div>
        <div className="user-detail__right-container">
          {getContent()}
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
