import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  LateralMenu,
  Modal,
  ActionButtons,
  ModalViewDictumProductForm
} from '../../components';

import {
  ActionButton,
  Client,
  DictumProduct,
  DictumProductByID,
  DictumServiceByID,
  InspectionByID,
  InspectionStatus,
  LateralMenuOption
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Tracing,
  History,
  Documents
} from './components';

import {
  emptyClient,
  emptyDictumProduct,
  emptyDictumServiceByID
} from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import configuration from '../../configuration';
import { utils } from '../../helpers';

type DictumProductDetailContentType = 'info' | 'history' | 'tracing';

const DictumProductDetail = () => {
  const params = useParams();
  const {
    dictumProductID,
    lookInTheTrash
  } = params;

  const { t, i18n } = useTranslation();

  const {
    eraseResource
  } = useResource<DictumProduct>();

  const {
    fetchResource
  } = useResource<DictumProductByID>();

  const {
    fetchResource: getDictum
  } = useResource<DictumServiceByID>();

  const {
    fetchResource: getInspection
  } = useResource<InspectionByID>();

  const {
    fetchResource: getClient
  } = useResource<Client>();

  const navigate = useNavigate();

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [product, setProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [dictum, setDictum] = useState<DictumServiceByID>(emptyDictumServiceByID);
  const [lateralMenuValue, setLateralMenuValue] = useState<DictumProductDetailContentType>('info');
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [inspectionStatus, setInspectionStatus] = useState<InspectionStatus>('deleted');
  const [inspectionLookInTheTrash, setInspectionLookInTheTrash] = useState<boolean>(false);
  const [client, setClient] = useState<Client>(emptyClient);

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('services.documents'),
      value: 'documents'
    },
    {
      label: t('services.history'),
      value: 'history'
    },
    {
      label: t('services.tracing'),
      value: 'tracing'
    }
  ];

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo
        product={product}
        dictumServiceLookInTheTrash={dictum.lookInTheTrash}
        inspectionLookInTheTrash={inspectionLookInTheTrash}
      />),
      history: (<History history={product.tracking} />),
      tracing: (<Tracing status={product.status} />),
      documents: (
        <Documents
          product={product}
          serviceNumber={product.service.number}
          hasOutOfCustomsDate={dictum.outOfCustomsDate !== null}
          inspectionStatus={inspectionStatus}
          client={client}
        />)
    };

    return contents[lateralMenuValue];
  }, [
    lateralMenuValue,
    product,
    dictum,
    inspectionLookInTheTrash
  ]);

  useEffect(() => {
    if (product.client.id !== 'none' && product.client.id) {
      getClient(
        `/admin/clients/${product.client.id}`,
        (data) => {
          setClient(data);
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [product.client.id]);

  const handleSelectLateralMenu = (value: string) => {
    setLateralMenuValue((value as DictumProductDetailContentType));
  };

  const lookInTheTrashValues: { [name: string]: string } = {
    none: '',
    true: 'true',
    false: 'false'
  };

  const throttledHandleRequest = utils.throttle(
    () => {
      fetchResource(
        `/${apiType}/dictum-products/${dictumProductID}?b_lookInTheTrash=${lookInTheTrashValues[lookInTheTrash || ''] || ''}`,
        (data) => {
          setProduct(data);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledHandleRequest();
  }, [dictumProductID, apiType]);

  useEffect(() => {
    if (product.inspection?.id) {
      getInspection(
        `/${apiType}/inspections/${product.inspection?.id}`,
        (_inspection) => {
          setInspectionStatus(_inspection.status);
          setInspectionLookInTheTrash(_inspection.lookInTheTrash);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [product, lookInTheTrash]);

  const setActionPathToSEView = () => {
    if (['validation-in-progress'].includes(product.status)) {
      return 'validate';
    }

    if (['cancellation-in-progress'].includes(product.status)) {
      return 'cancelate';
    }

    return 'none';
  };

  const setSectionPathToSEView = () => {
    if (product.indicators.waitingToExpired && product.indicators.attachedToTheService) {
      return 'cancellation';
    }

    if (product.status === 'validated' && !product.indicators.attachedToTheService) {
      return 'cancellation';
    }

    if (product.status === 'invoice-assigned') {
      return 'layout-1';
    }

    return 'none';
  };

  useEffect(() => {
    if (product.service.id) {
      getDictum(
        `/${apiType}/dictum-services/${product.service.id}`,
        (data) => {
          setDictum(data);
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  }, [product.service.id]);

  useEffect(() => {
    const requestIsCompleted = dictum.indicators ? dictum.indicators.requestIsCompleted : false;

    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              setShowEditModal(true);
            }}
            type='primary-outlined'
            label={t('global.edit') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='editFile'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('edit-products'),
        specialValidation: (['master', 'operator'].includes(userRole)
          || (['collaborator'].includes(userRole) && requestIsCompleted === false))
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              Modal.fireWarning(
                t('services.deleteProductAsk'),
                () => {
                  eraseResource(
                    product.indicators.isGrouped
                      ? `/${apiType}/dictum-products/grouped/${product.invoice}`
                      : `/${apiType}/dictum-products`,
                    {
                      b_deleteAll: false,
                      s_serviceID: dictum.id,
                      s_productID: product.id
                    },
                    () => {
                      Modal.fireSuccess(
                        t('global.correct'),
                        t('services.deletedInvoice'),
                        setOpenModal,
                        () => { navigate(-1); },
                        soundEffects
                      );
                    },
                    (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
                  );
                },
                setOpenModal
              );
            }}
            type='primary-outlined'
            label={t('services.deleteInvoice') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='paperShredder'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('delete-invoice'),
        specialValidation: (['master', 'operator'].includes(userRole)
          || (['collaborator'].includes(userRole) && requestIsCompleted === false))
          && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => navigate(`/task-form/none/${product.service.id}/DC`)}
            type='primary-outlined'
            label={t('services.assignProducts') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='inspector'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('assign-products'),
        specialValidation: apiType === 'admin' && ['master', 'operator'].includes(userRole) && lookInTheTrash !== 'true'
      },
      {
        button: (
          <Button
            onClick={() => {
              navigate(`/se-procedures/none/${setActionPathToSEView()}/${setSectionPathToSEView()}`);
            }}
            type='primary-outlined'
            label={t('services.proceduresSE') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='SELogoDark'
            alignContent='left'
          />
        ),
        available: product.actionsEnabled.includes('have-procedure-se'),
        specialValidation: apiType === 'admin' && lookInTheTrash !== 'true'
      }
    ]);
  }, [
    product,
    apiType,
    i18n.language,
    userRole,
    dictum,
    lookInTheTrash
  ]);

  return (
    <div className="dictum-product-detail">
      <Header
        title={t('services.productDetail', { type: t('global.dictum') })}
        subTitle={[
          {
            label: product.invoice,
            title: t('global.invoice') || ''
          },
          {
            label: product.subInvoice,
            title: t('global.subInvoice') || ''
          }
        ]}
        showBackbutton={true}
      />

      <div className="dictum-product-detail__main">
        <div className="dictum-product-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={handleSelectLateralMenu}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="dictum-product-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/dictum-products.detail.${product.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/dictum-products.detail.${product.id}.${lookInTheTrashValues[lookInTheTrash || ''] || 'false'}`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
          />
          {getContent()}
        </div>
      </div>
      <ModalViewDictumProductForm
        visible={showEditModal}
        preloadedProduct={product}
        onUpdateProduct={(_product: DictumProduct) => {
          setProduct(_product);
          Modal.fireSuccess(t('global.correct'), t('services.updatedProduct'), setOpenModal, undefined, soundEffects);
        }}
        onClose={() => {
          setShowEditModal(false);
        }}
        onError={(error: string) => {
          setShowEditModal(false);
          Modal.fireError(error, undefined, soundEffects);
        }}
      />
    </div>
  );
};

export default DictumProductDetail;
