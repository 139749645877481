/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckBox from '../CheckBox';

import './styles.scss';

interface Props {
  showCheckBox?: boolean
  onAccept?: (_checked: boolean) => void
}

const TermsAndConditions: React.FC<Props> = (props: Props) => {
  const {
    showCheckBox = true,
    onAccept = () => { }
  } = props;

  const { i18n } = useTranslation();

  const [localCheck, setLocalCheck] = useState<boolean>(false);

  if (i18n.language === 'es') {
    return (
      <div className="terms-and-conditions">
        <div>
          <span className="terms-and-conditions__service">Para el servicio de Constancia</span>
          <br />
          <p>
            El cliente deberá de presentar toda la información correspondiente para hacer la evaluación.
          </p>
          <br />
          <p>
            El número de ocasiones en que puede ingresar correcciones no podrá ser mayor a 3, de ser así se emitirá una negación de constancia.
          </p>
          <br />
          <p>
            El cliente cuenta con un máximo de 30 días naturales para ingresar correcciones de las evaluaciones correspondientes, caso contrario, se dará por cerrado el servicio.
          </p>
          <br />
          <p>
            En caso de que el cliente requiera una modificación a la constancia, está tendrá un costo del 50% del valor original
          </p>
          <br />
          <span className="terms-and-conditions__service">Para el servicio de Dictamen</span>
          <br />
          <p>
            Para el trámite de folios es requerido: Proforma del pedimento, facturas de compra y llenado del LayOut proporcionado por CECSA en formato .xlsx (Excel)
          </p>
          <br />
          <p>
            Una vez que CECSA envié la solicitud de servicios, la validación de folios se hará de la siguiente manera de acuerdo a los horarios establecidos por Secretaría de Economía.:
            Si el envío de la información se hace antes de la 1:00 pm – los folios saldrán validados ese mismo día.
            Si el envío de la información se hace después de la 1:00 pm – los folios saldrán validados al siguiente día hábil.
          </p>
          <br />
          <p>
            La validación de los folios podrá corroborarse a través de la siguiente liga:
            https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx
          </p>
          <br />
          <p>
            La validación de folios es un proceso de Secretaria de Economía, por lo tanto, CECSA no puede determinar de manera exacta la hora en la que saldrán validados. Por lo que se solicita revisar la validación a través del portal proporcionado en el punto anterior.
          </p>
          <br />
          <p>
            Una vez desaduanadas las mercancías, es necesario proporcionar a CECSA copia del pedimento desaduanado que contenga los números de folios.
            <br />
            Es importante mencionar que de acuerdo al ‘ACUERDO por el que la Secretaría de Economía emite Reglas y criterios de carácter general en materia de comercio exterior.’ se cuenta con un plazo de 30 días naturales posterior a la fecha de desaduanamiento para llevar a cabo la inspección del etiquetado.
          </p>
          <br />
          <p>
            El cliente debe de notificar a CECSA cuando las mercancías se encuentren etiquetadas para llevar acabo la visita de inspección.
          </p>
          <br />
          <p>
            Es requerido notificar con al menos 24 horas para la programación de la visita de inspección.
            CECSA revisará y notificará cuando será llevada a cabo la visita de inspección
          </p>
          <br />
          <p>
            Un inspector de CECSA se presentará en el lugar y fecha acordados y presentará la planificación de la inspección. En el cual se detallará los productos que se contemplan en la visita.
          </p>
          <br />
          <p>
            El inspector procederá a hacer la evaluación y el muestreo correspondiente a cada lote de productos.
            No es permitido que el cliente sea quien seleccione los productos del muestreo.
          </p>
          <br />
          <p>
            Una vez que el inspector termine de evaluar los productos, pedirá firmas de los testigos y procederá dejar una copia del acta. <br />
            El cliente podrá firmar y asentar en el acta lo que su derecho convenga.
          </p>
          <br />
          <p>
            La emisión de dictámenes será de al menos 24 horas hábiles posteriores a la visita de inspección.
          </p>
          <br />
          <span className="terms-and-conditions__service">General</span>
          <br />
          <p>
            Las solicitudes de servicios emitidos o expedidos por CECSA ENTERPRISE SOLUTIONS tienen validez exclusiva para el servicio de inspección que fue solicitado a CECSA ENTERPRISE SOLUTIONS y de los cuales se presenten las solicitudes originales correspondientes en donde se detallen las características del servicio y que ostente el acuse de recibido por personal autorizado por CECSA ENTERPRISE SOLUTIONS.
          </p>
          <br />
          <p>
            La solicitud de servicios no debe presentar tachaduras, raspaduras o enmendaduras, debiendo presentarse llenada en su totalidad a máquina o a mano.  Queda prohibida la reproducción total o parcial de esta solicitud de servicios.
          </p>
          <br />
          <p>
            La solicitud de servicios será automáticamente cancelada en los siguientes casos:
            <br />1. Cuando se detecte alguna alteración o falsificación de la solicitud de servicios
            <br />2. Cuando se detecte alguna modificación por personal no autorizado y ajeno a CECSA ENTERPRISE SOLUTIONS
            <br />3. Cuando se use indebidamente el nombre de CECSA ENTERPRISE SOLUTIONS
          </p>
          <br />
          <p>
            El uso indebido de esta Solicitud de Servicios, por parte del Titular o de un Tercero, deriva en la cancelación del Contrato de Prestación de Servicios, quedando CECSA ENTERPRISE SOLUTIONS con la libertad de ejercer sus derechos por la vía jurídica o judicial que más convenga a sus intereses.
          </p>
          <br />
          <p>
            La unidad de inspección CECSA ENTERPRISE SOLUTIONS asegura la confidencialidad de la información a través de procedimientos y carta de confidencialidad. <br />
            Asimismo, la unidad de inspección cuenta con un seguro de responsabilidad civil
          </p>
          <br />
          <p>
            La unidad de inspección cuenta con un procedimiento documentado de Quejas y apelaciones (P7-06). Este puede solicitarlo al personal de la unidad de inspección CECSA ENTERPRISE SOLUTIONS o a través de su página web www.cecsatrade.mx <br />
            Adicional, este procedimiento forma parte integral del Contrato de Servicios de esta unidad de Inspección.
          </p>
          <br />
          <p style={{ color: 'red' }}>Si la solicitud es finalizada después de las 12:30hrs. (hora centro) los folios se validarán al siguiente día hábil</p>
        </div>
        <br />
        {
          showCheckBox && (
            <div className="terms-and-conditions__accept-checkbox">
              <CheckBox
                label='Acepto los terminos y condiciones'
                checked={localCheck}
                orientation='horizontal'
                onChange={() => {
                  setLocalCheck(!localCheck);
                  onAccept(!localCheck);
                }}
              />
            </div>
          )
        }
      </div>
    );
  }

  return (<div className="terms-and-conditions">
    <div>
      <span className="terms-and-conditions__service">For the Evidence service</span>
      <br />
      <p>
        The client must present all the corresponding information to make the evaluation.
      </p>
      <br />
      <p>
        The number of times you can enter corrections may not be greater than 3, if so, a denial of proof will be issued.
      </p>
      <br />
      <p>
        The client has a maximum of 30 calendar days to make corrections to the corresponding evaluations, otherwise, the service will be considered closed.
      </p>
      <br />
      <p>
        In the event that the client requires a modification to the certificate, it will have a cost of 50% of the original value
      </p>
      <br />
      <span className="terms-and-conditions__service">For the Opinion service</span>
      <br />
      <p>
        For the processing of folios, the following is required: Proforma of the request, purchase invoices and completion of the LayOut provided by CECSA in .xlsx (Excel) format.
      </p>
      <br />
      <p>
        Once CECSA sends the request for services, the validation of pages will be done as follows according to the schedules established by the Ministry of Economy:
        If the information is sent before 1:00 pm - the pages will be validated that same day.
        If the information is sent after 1:00 pm - the pages will be validated the next business day.
      </p>
      <br />
      <p>
        The validation of the folios can be corroborated through the following link:
        https://aplicacionesc.mat.sat.gob.mx/SOIANET/oia_wfConsultaRapNoms.aspx
      </p>
      <br />
      <p>
        The validation of folios is a process of the Ministry of Economy, therefore, CECSA cannot determine exactly the time in which they will be validated. Therefore, it is requested to review the validation through the portal provided in the previous point.
      </p>
      <br />
      <p>
        Once the merchandise is cleared, it is necessary to provide CECSA with a copy of the customs clearance document containing the page numbers.
        <br />
        It is important to mention that according to the 'AGREEMENT by which the Ministry of Economy issues Rules and criteria of a general nature in foreign trade matters,' there is a period of 30 calendar days after the date of customs clearance to carry out the label inspection.
      </p>
      <br />
      <p>
        The client must notify CECSA when the goods are labeled to carry out the inspection visit.
      </p>
      <br />
      <p>
        It is required to notify at least 24 hours to schedule the inspection visit.
        CECSA will review and notify when the inspection visit will be carried out
      </p>
      <br />
      <p>
        A CECSA inspector will appear at the agreed place and date and will present the inspection schedule. In which the products that are contemplated in the visit will be detailed.
      </p>
      <br />
      <p>
        The inspector will proceed to make the evaluation and sampling corresponding to each batch of products.
        It is not allowed that the client is the one who selects the sampling products.
      </p>
      <br />
      <p>
        Once the inspector finishes evaluating the products, he will ask for the signatures of the witnesses and proceed to leave a copy of the record. <br />
        The client will be able to sign and settle in the record what his right is convenient.
      </p>
      <br />
      <p>
        The issuance of opinions will be at least 24 business hours after the inspection visit.
      </p>
      <br />
      <span className="terms-and-conditions__service">General</span>
      <br />
      <p>
        Requests for services issued or issued by CECSA ENTERPRISE SOLUTIONS are exclusively valid for the inspection service that was requested from CECSA ENTERPRISE SOLUTIONS and for which the corresponding original requests are submitted detailing the characteristics of the service and bearing the acknowledgment of received by personnel authorized by CECSA ENTERPRISE SOLUTIONS.
      </p>
      <br />
      <p>
        The request for services must not present deletions, scratches or amendments, and must be submitted in its entirety by machine or by hand. The total or partial reproduction of this request for services is prohibited.
      </p>
      <br />
      <p>
        The request for services will be automatically canceled in the following cases:
        <br />1. When any alteration or falsification of the request for services is detected
        <br />2. When any modification is detected by unauthorized personnel outside CECSA ENTERPRISE SOLUTIONS
        <br />3. When the name of CECSA ENTERPRISE SOLUTIONS is misused
      </p>
      <br />
      <p>
        The improper use of this Request for Services, by the Owner or a Third Party, results in the cancellation of the Contract for the Provision of Services, leaving CECSA ENTERPRISE SOLUTIONS free to exercise its rights through the legal or judicial means that best suits your interests.
      </p>
      <br />
      <p>
        The CECSA ENTERPRISE SOLUTIONS inspection unit ensures the confidentiality of the information through procedures and confidentiality letter. <br />
        Likewise, the inspection unit has civil liability insurance
      </p>
      <br />
      <p>
        The inspection unit has a documented Complaints and Appeals procedure (P7-06). This can be requested from the CECSA ENTERPRISE SOLUTIONS inspection unit staff or through its website www.cecsatrade.mx <br />
        Additionally, this procedure is an integral part of the Service Contract of this Inspection unit.
      </p>
      <br />
      <p style={{ color: 'red' }}>If the application is completed after 12:30 p.m. (Central Time), the invoices will be validated the following business day.</p>
    </div>
    <br />
    {
      showCheckBox && (
        <div className="terms-and-conditions__accept-checkbox">
          <CheckBox
            label='Acepto los terminos y condiciones'
            checked={localCheck}
            orientation='horizontal'
            onChange={() => {
              setLocalCheck(!localCheck);
              onAccept(!localCheck);
            }}
          />
        </div>
      )
    }
  </div>
  );
};

export default TermsAndConditions;
