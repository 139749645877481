import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  FileFolder,
  FolderExpanded,
  Modal,
  Separator,
  StepsIndicator,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { emptyRevisionProduct } from '../../../../emptyObjects';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import {
  RevisionProduct,
  RevisionService,
  FileInputData,
  FileToSign,
  SignedFile
} from '../../../../interfaces';

import './styles.scss';
import { utils } from '../../../../helpers';

export const Step3 = () => {
  const {
    fetchResources,
    eraseResource,
    updateResource
  } = useResource<RevisionProduct>();
  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();
  const {
    fetchResource
  } = useResource<RevisionService>();

  const filesLimit = 10;

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const [revisionProducts, setRevisionProducts] = useState<RevisionProduct[]>([]);
  const [foldersOpen, setFoldersOpen] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<RevisionProduct>(emptyRevisionProduct);
  const [requestIsCompleted, setRequestIsCompleted] = useState<boolean>(false);

  const updateRevisionProductState = (productID: string, newData: RevisionProduct) => {
    const auxRevisionProducts: RevisionProduct[] = revisionProducts;

    for (let index = 0; index < auxRevisionProducts.length; index += 1) {
      if (auxRevisionProducts[index].id === productID) {
        auxRevisionProducts[index] = newData;
      }
    }

    setRevisionProducts(auxRevisionProducts);
  };

  const handleUploadFile = (files: FileInputData[]) => {
    if (files.length > filesLimit) {
      Modal.fireError(t('services.errors.only10Products'), setOpenModal, soundEffects);
      return;
    }

    const paths: FileToSign[] = [];

    files.forEach(fileItem => {
      paths.push({
        path: fileItem.fileName,
        type: fileItem.data.type,
        documentName: utils.deleteDotsOnFileName(fileItem.fileName)
      });
    });

    registerUploadedFiles(
      `/${apiType}/revision-products/${selectedProduct.id}/documents`,
      {
        filePaths: paths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            // eslint-disable-next-line max-len
            const file = files.find((fileItem) => item.url.endsWith(utils.deleteDotsOnFileName(fileItem.fileName)));

            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/${apiType}/revision-products/${selectedProduct.id}/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (product) => {
              setSelectedProduct(product);
              updateRevisionProductState(selectedProduct.id, product);
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleDeleteFile = (documentPath: string) => {
    eraseResource(
      `/${apiType}/revision-products/${selectedProduct.id}/documents`,
      {
        s_documentPath: documentPath
      },
      (data: RevisionProduct) => {
        setSelectedProduct(data);
        updateRevisionProductState(selectedProduct.id, data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getRevisionProducts = () => {
    fetchResources(
      {
        resourcePath: `/${apiType}/revision-products`,
        filters: {
          s_serviceID: params.revisionServiceID || ''
        }
      },
      (data) => {
        setRevisionProducts(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleContinue = () => {
    let productsHasErrors: boolean = false;
    const auxRevisionProducts: RevisionProduct[] = revisionProducts;

    for (let index = 0; index < revisionProducts.length; index += 1) {
      if (revisionProducts[index].documents.length === 0) {
        auxRevisionProducts[index].documentsAlert = true;
        productsHasErrors = true;
      } else {
        auxRevisionProducts[index].documentsAlert = false;
      }
    }

    setRevisionProducts(auxRevisionProducts);

    if (productsHasErrors) {
      Modal.fireError(t('services.errors.atLeast1Products'), setOpenModal, soundEffects);
      return;
    }

    if (apiType === 'admin') {
      updateResource(
        `/${apiType}/revision-services/${params.revisionServiceID}`,
        {
          requestIsCompleted: true
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.requestSaved'),
            setOpenModal,
            () => navigate(`/revision-services/detail/${params.revisionServiceID}/none`),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
      return;
    }

    navigate(`/revision-request/step_4/${params.revisionServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`);
  };

  useEffect(() => {
    getRevisionProducts();
  }, [apiType]);

  useEffect(() => {
    if (params.revisionServiceID) {
      fetchResource(
        `/${apiType}/revision-services/${params.revisionServiceID}`,
        (data) => {
          setRequestIsCompleted(data.indicators.requestIsCompleted);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [apiType, params.revisionServiceID]);

  useKeyPress(
    openModal ? () => { } : () => handleContinue(),
    [
      revisionProducts,
      openModal,
      selectedProduct
    ]
  );

  return (
    <div className='step-3-revision'>
      <div className='step-3-revision__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={3}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={2}
            />
          )
        }
      </div>
      <div className='step-3-revision__content'>
        <div className='step-3-revision'>
          <Title title={apiType === 'admin' ? t('services.step3Documents') : t('services.step2Documents')} />
        </div>
        <p className='step-3-revision__help-text'>
          {t('services.documentsExplication', { filesLimit })}
        </p>
        <div className='step-3-revision__folders'>
          {
            foldersOpen ? (
              <>
                <div className='step-3-revision__folders__left-container'>
                  {
                    revisionProducts.map((revisionProduct: RevisionProduct, index: number) => (
                      <div>
                        {
                          selectedProduct.id === revisionProduct.id ? (
                            <FileFolder
                              id={selectedProduct.id}
                              folderName={`${selectedProduct.brand} - ${selectedProduct.description}`}
                              hasItems={!!selectedProduct.documents.length}
                              filesQuantity={selectedProduct.documents.length}
                              selected={true}
                              subtitle={selectedProduct.invoice}
                              onClick={(_id: string) => {
                                setFoldersOpen(true);
                              }}
                              hasError={revisionProduct.documentsAlert}
                              key={index}
                            />
                          ) : (
                            <FileFolder
                              id={revisionProduct.id}
                              folderName={`${revisionProduct.brand} - ${revisionProduct.description}`}
                              hasItems={!!revisionProduct.documents.length}
                              filesQuantity={revisionProduct.documents.length}
                              selected={false}
                              subtitle={revisionProduct.invoice}
                              onClick={(_id: string) => {
                                setFoldersOpen(true);
                                setSelectedProduct(revisionProduct);
                              }}
                              hasError={revisionProduct.documentsAlert}
                              key={index}
                            />
                          )
                        }
                      </div>
                    ))
                  }
                </div>
                <Separator orientation='vertical' />
                <div className='step-3-revision__folders__right-container'>
                  <FolderExpanded
                    folderName={`${selectedProduct.brand} - ${selectedProduct.description}`}
                    filesQuantity={selectedProduct.documents.length}
                    files={selectedProduct.documents}
                    onClose={() => setFoldersOpen(false)}
                    onUploadFile={handleUploadFile}
                    onDelete={handleDeleteFile}
                    filesLimit={filesLimit}
                    requestIsCompleted={requestIsCompleted}
                  />
                </div>
              </>
            ) : (
              <div className='step-3-revision__folders__list'>
                {
                  revisionProducts.map((revisionProduct: RevisionProduct, index: number) => (
                    <FileFolder
                      id={revisionProduct.id}
                      folderName={revisionProduct.description}
                      hasItems={!!revisionProduct.documents.length}
                      filesQuantity={revisionProduct.documents.length}
                      selected={false}
                      subtitle={revisionProduct.invoice}
                      onClick={(_id: string) => {
                        setFoldersOpen(true);
                        setSelectedProduct(revisionProduct);
                      }}
                      hasError={revisionProduct.documentsAlert}
                      key={index}
                    />
                  ))
                }
              </div>
            )
          }
        </div>
        <div className='step-2__button-container'>
          <Button
            onClick={() => {
              if (foldersOpen) {
                setFoldersOpen(false);
              } else {
                navigate(`/revision-request/step_2/${params.revisionServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`);
              }
            }}
            type='primary'
            label={t('global.goBack') || ''}
            icon='leftArrow'
            iconPosition='left'
          />
          <Button
            onClick={handleContinue}
            type='primary'
            label={apiType === 'admin' ? t('global.finish') || '' : t('global.continue') || ''}
            icon='rightArrow'
            iconPosition='right'
          />
        </div>
      </div>
    </div>
  );
};

export default Step3;
