/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  FileInput,
  ModalView,
  Modal,
  Input,
  File,
  Table
} from '../../../../components';

import {
  DictumProduct,
  DictumService,
  FileInputData,
  SignedFile,
  TableHeader
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { validate } from '../../../../helpers';
import { AppContext } from '../../../../context/AppContext';

interface ModalViewOptions {
  customComponent?: ReactElement
  onConfirm?: () => void
}

interface Response {
  foundProducts: DictumProduct[],
  notFoundProducts: DictumProduct[],
}

interface Props {
  visible: boolean
  dictumServiceID: string
  onClose: () => void
  setRefetchDictum: (_item: boolean) => void
  refetchDictum: boolean
}

export const UploadPaidPetition: React.FC<Props> = (props) => {
  const {
    visible,
    dictumServiceID,
    onClose,
    setRefetchDictum,
    refetchDictum
  } = props;

  const { t } = useTranslation();

  const { createResource } = useResource<Response>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    updateResource
  } = useResource<DictumService>();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [rectifiedPetitionNumber, setRectifiedPetitionNumber] = useState<string>('');
  const [rectifiedPetitionFile, setRectifiedPetitionFile] = useState<FileInputData>({
    fileName: '',
    data: {}
  });
  const [modalViewOptions, setModalViewOptions] = useState<ModalViewOptions>({
    onConfirm: () => onUploadRectifiedPetition(rectifiedPetitionFile)
  });

  const tableHeaders: { [name: string]: TableHeader[] } = {
    admin: [
      {
        label: t('global.invoice'),
        value: 'invoice',
        format: 'none'
      },
      {
        label: t('global.status.title'),
        value: 'usedOnPaidPetition',
        format: 'custom-component'
      },
      {
        label: t('tasks.table.model'),
        value: 'code',
        format: 'none'
      },
      {
        label: t('services.petitionTitle'),
        value: 'service.number',
        format: 'none'
      },
      {
        label: t('tasks.filters.client'),
        value: 'client.businessName',
        format: 'none',
        onlyAdmin: true
      },
      {
        label: t('global.norm'),
        value: 'normCode',
        format: 'none'
      },
      {
        label: t('global.tariffFraction'),
        value: 'tariffFraction',
        format: 'none'
      },
      {
        label: 'UMC',
        value: 'umc',
        format: 'none'
      },
      {
        label: t('services.umcQuantity'),
        value: 'umcQuantity',
        format: 'none'
      },
      {
        label: t('global.status.title'),
        value: 'status',
        format: 'status'
      }
    ],
    public:
      [
        {
          label: t('global.invoice'),
          value: 'invoice',
          format: 'none'
        },
        {
          label: t('global.status.title'),
          value: 'usedOnPaidPetition',
          format: 'custom-component'
        },
        {
          label: t('tasks.table.model'),
          value: 'code',
          format: 'none'
        },
        {
          label: t('services.petitionTitle'),
          value: 'service.number',
          format: 'none'
        },
        {
          label: t('global.norm'),
          value: 'normCode',
          format: 'none'
        },
        {
          label: t('global.tariffFraction'),
          value: 'tariffFraction',
          format: 'none'
        },
        {
          label: 'UMC',
          value: 'umc',
          format: 'none'
        },
        {
          label: t('services.umcQuantity'),
          value: 'umcQuantity',
          format: 'none'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status'
        }
      ]
  };

  const validRectifiedPetition = () => {
    if (!rectifiedPetitionFile?.fileName) {
      setErrors({
        rectifiedPetitionFile: t('services.errors.rectifiedPetitionFile')
      });

      return false;
    }

    if (validate.text(rectifiedPetitionNumber, 1) !== 'success') {
      setErrors({
        rectifiedPetition: t('services.errors.rectifiedPetition')
      });

      return false;
    }

    return true;
  };

  const uploadRectifiedPetition = (
    file: FileInputData,
    petitionDocumentInvoicesResult: Response
  ) => {
    registerUploadedFiles(
      `/${apiType}/dictum-services/${dictumServiceID}/documents/rectified-petition`,
      {
        filePaths: [{
          path: file.fileName,
          type: file.data.type,
          documentName: file.fileName
        }]
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', file.data);

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPaths.push(item.url);
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/${apiType}/dictum-services/${dictumServiceID}/documents-paths/rectified-petition`,
            {
              filePaths: uploadedPaths,
              documentInvoicesResult: petitionDocumentInvoicesResult,
              rectifiedPetitionNumber
            },
            () => {
              Modal.fireSuccess(
                t('global.correct'),
                t('services.rectifiedPaidPetitionSaved'),
                setOpenModal,
                () => {
                  setRefetchDictum(!refetchDictum);
                  onClose();
                },
                soundEffects
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const onUploadRectifiedPetition = (file: FileInputData) => {
    if (validRectifiedPetition()) {
      const payloadInvoice = new FormData();

      payloadInvoice.append('documents', rectifiedPetitionFile.data, `${rectifiedPetitionNumber}.pdf`.replace(/[%&?¿=#/+]/g, ''));

      createResource(
        `/${apiType}/dictum-services/${dictumServiceID}/documents-upload-rectified-petition`,
        payloadInvoice,
        (data: Response) => {
          console.log(`Response: ${data}`);
          let fondProductsCount = 0;

          const products = [];

          products.push(...data.foundProducts.map((product: DictumProduct) => {
            fondProductsCount += 1;
            return {
              ...product,
              usedOnPaidPetition: (
                <p className='paid-petition-modal__used-invoice'>
                  {t('global.used')}
                </p>
              )
            };
          }));

          products.push(...data.notFoundProducts.map((product: DictumProduct) => {
            return {
              ...product,
              usedOnPaidPetition: (
                <p className='paid-petition-modal__not-used-invoice'>
                  {t('global.notUsed')}
                </p>
              )
            };
          }));

          setModalViewOptions({
            onConfirm: () => { uploadRectifiedPetition(file, data); },
            customComponent: (
              <div className='dictum-service-detail__table-paid-petition'>
                <p className='dictum-service-detail__text-paid-petition'>{t('services.productsRectifiedPaidPetition')}</p>
                <p className='dictum-service-detail__title-paid-petition'>{t('services.productsPaidPetitionAsk')}</p>
                <p className='dictum-service-detail__text-paid-petition'>{t('services.fondProducts')}: {fondProductsCount}</p>
                <br />
                <Table
                  headers={tableHeaders[apiType]}
                  items={products}
                  currentPage={0}
                  perPage={0}
                  totalItems={0}
                  onChangeCurrentPage={() => { }}
                  orderBy=''
                  orderDirection='ASC'
                />
              </div>
            )
          });
        },
        (error: string) => {
          console.log(`error: ${error}`);
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  useEffect(() => {
    setErrors({});
    setRectifiedPetitionNumber('');
    setRectifiedPetitionFile({
      data: {},
      fileName: ''
    });
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setModalViewOptions({
        customComponent: (
          <div className='paid-petition-modal'>
            <p className='paid-petition-modal__title'>{t('services.uploadRectifiedPaidPetitionTitle')}</p>
            <p className='paid-petition-modal__text'>{t('services.uploadPaidPetitionTitleExplication')}</p>
            <div className='paid-petition-modal__file-container'>
              {
                !rectifiedPetitionFile?.fileName ? (
                  <FileInput
                    isMultiple={false}
                    acceptedFileFormats='.pdf'
                    onSelectFile={(payload: FileInputData[]) => {
                      console.log(`onSelectFile - payload[0]: ${payload[0]}`);
                      setRectifiedPetitionFile({
                        data: payload[0].data,
                        fileName: payload[0].fileName.replace(/%/g, '')
                      });
                    }}
                    fileName={t('services.rectifiedPetitionNumber') || ''}
                    hasError={!!errors.rectifiedPetitionFile}
                    helperText={errors.rectifiedPetitionFile}
                  />
                ) : (
                  <File
                    name={rectifiedPetitionFile.fileName}
                    onDelete={() => {
                      setRectifiedPetitionFile({
                        fileName: '',
                        data: {}
                      });
                    }}
                  />
                )
              }
            </div>
            <br />
            <Input
              type='petitionNumber'
              value={rectifiedPetitionNumber}
              onChange={(value: string | number) => {
                setRectifiedPetitionNumber(String(value));
              }}
              title={t('services.rectifiedPetitionNumberInput') || ''}
              placeholder={`${t('global.example')}: 34 67 8742 8756439`}
              hasError={!!errors.rectifiedPetition}
              helperText={errors.rectifiedPetition || t('services.rectifiedPetitionExplication') || ''}
            />
            <br />
          </div>
        ),
        onConfirm: () => onUploadRectifiedPetition(rectifiedPetitionFile)
      });
    }
  }, [visible, errors, rectifiedPetitionFile, rectifiedPetitionNumber]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={modalViewOptions.onConfirm}
      customComponent={modalViewOptions.customComponent}
    />
  );
};

export default UploadPaidPetition;
