import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalView,
  RadioList,
  Table,
  Title
} from '../../../../components';

import {
  DictumProduct,
  Pagination,
  TableHeader
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';
import { utils } from '../../../../helpers';

interface CustomProduct extends DictumProduct {
  'radio-buttons': ReactElement
}

interface Props {
  visible: boolean
  onClose: () => void
  onDone: (_wasSuccessful: boolean, _message: string) => void
}

export const InvoiceCancellation: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onDone
  } = props;
  const {
    fetchResources,
    updateResources
  } = useResource<DictumProduct>();

  const { t } = useTranslation();

  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [customProducts, setCustomProducts] = useState<CustomProduct[]>([]);
  const [radioButtonValues, setRadioButtonValues] = useState<{ [invoice: string]: string }>({});
  const [allOption, setAllOption] = useState<string>('');
  const [pagination, setPagination] = useState<Pagination>({
    perPage: 20,
    currentPage: 1,
    totalItems: 0
  });

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('services.petitionNumber'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('clients.titleS'),
      value: 'client.shortName',
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: t('global.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: 'UMC',
      value: 'umc',
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: 'umcQuantity',
      format: 'none'
    },
    {
      label: t('global.options'),
      value: 'radio-buttons',
      format: 'custom-component'
    }
  ];

  const handleChangePage = (page: number) => setPagination({ ...pagination, currentPage: page });

  const handleUpdateInvoicesStatus = () => {
    const dataToSent: any[] = [];

    Object.keys(radioButtonValues).forEach((key: string) => {
      if (radioButtonValues[key] !== 'cancellation-in-progress') {
        const data: { [invoice: string]: string } = {
          invoice: key,
          status: radioButtonValues[key]
        };

        dataToSent.push(data);
      }
    });

    updateResources(
      '/admin/dictum-products/se/status',
      dataToSent,
      () => onDone(true, t('seProcedures.invoicesChanged')),
      (error: string) => onDone(false, error)
    );
  };

  useEffect(() => {
    setCustomProducts(
      products.map((product: DictumProduct) => {
        return {
          ...product,
          'radio-buttons': (
            <RadioList
              value={radioButtonValues[product.invoice]}
              options={[
                { label: t('global.undefined'), value: 'cancellation-in-progress' },
                { label: t('global.status.cancelled'), value: 'cancelled' },
                { label: t('global.status.notCancelled'), value: 'not-cancelled' }
              ]}
              onChange={(value) => {
                radioButtonValues[product.invoice] = value as string;
                setRadioButtonValues({ ...radioButtonValues });
              }}
            />
          )
        };
      })
    );
  }, [products, radioButtonValues]);

  useEffect(() => {
    if (visible) {
      fetchResources(
        {
          resourcePath: '/admin/dictum-products',
          filters: {
            s_status: 'cancellation-in-progress',
            b_distinctInvoices: true
          },
          pagination: {
            n_currentPage: pagination.currentPage,
            n_perPage: pagination.perPage,
            n_totalItems: pagination.totalItems
          },
          ordering: {
            s_orderBy: 'invoice',
            s_orderDirection: 'ASC'
          }
        },
        (data) => {
          const _radioButtonValues: { [invoice: string]: string } = {};

          data.items.forEach((product: DictumProduct) => {
            _radioButtonValues[product.invoice] = 'cancellation-in-progress';
          });

          setProducts(data.items);
          setRadioButtonValues({ ..._radioButtonValues, ...radioButtonValues });

          if (utils.paginationHasChange(pagination, data.pagination)) {
            setPagination(data.pagination);
          }
        },
        (error: string) => onDone(false, error)
      );
    } else {
      setProducts([]);
      setRadioButtonValues({});
      setAllOption('cancellation-in-progress');
    }
  }, [visible, pagination]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={customProducts.length > 0 ? handleUpdateInvoicesStatus : null}
      customComponent={(
        <>
          <Title title={t('seProcedures.markCanceledInvoices') || ''} />
          {
            customProducts.length > 0 && (
              <RadioList
                value={allOption}
                options={[
                  { label: `${t('global.all')} "${t('global.undefined')}"`, value: 'cancellation-in-progress' },
                  { label: `${t('global.all')} "${t('global.status.cancelled')}"`, value: 'cancelled' },
                  { label: `${t('global.all')} "${t('global.status.notCancelled')}"`, value: 'not-cancelled' }
                ]}
                onChange={(value) => {
                  setAllOption(`${value}`);

                  const keys = Object.keys(radioButtonValues);

                  for (let index = 0; index < keys.length; index += 1) {
                    radioButtonValues[keys[index]] = `${value}`;
                  }
                  setRadioButtonValues({ ...radioButtonValues });
                }}
              />
            )
          }
          <Table
            headers={tableHeaders}
            items={customProducts}
            currentPage={pagination.currentPage}
            perPage={pagination.perPage}
            totalItems={pagination.totalItems}
            onChangeCurrentPage={handleChangePage}
            orderBy=''
            orderDirection='ASC'
          />
        </>
      )}
    />
  );
};

export default InvoiceCancellation;
