import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Accordeon,
  ActionButtons,
  Button,
  Filters,
  Header,
  LateralMenu,
  Modal
} from '../../components';

import {
  LateralMenuOption,
  FilterTypes,
  DictumProduct,
  ActionButton
} from '../../interfaces';

import CancellationLayout from './components/CancellationLayout';
import InvoiceValidation from './components/InvoiceValidation';
import Layout1 from './components/Layout1';
import InvoiceCancellation from './components/InvoiceCancellation';

import './styles.scss';
import storage from '../../storage';
import { AppContext } from '../../context/AppContext';
import { useResource } from '../../hooks';
import Layout2 from './components/Layout2';

export const SEProcedures = () => {
  const params = useParams();
  const { serviceNumber, action, section } = params;

  const {
    setOpenModal,
    userRole,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const { fetchResources } = useResource<DictumProduct>();
  const { createResource } = useResource<any>();

  const [showInvoiceValidationModal, setShowInvoiceValidationModal] = useState<boolean>(false);
  const [showInvoiceCancellationModal, setShowInvoiceCancellationModal] = useState<boolean>(false);
  const [hasProductosToLayout2, setHasProductosToLayout2] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterTypes>('none');
  const [filterValue, setFilterValue] = useState<string>('');
  const [lateralMenuValue, setLateralMenuValue] = useState<string>(storage.get('lateralMenuSEProceduresValue') || 'layout-1');
  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [haveProductsToCancellate, setHaveProductsToCancellate] = useState<boolean>(false);
  const [haveProductsToValidate, setHaveProductsToValidate] = useState<boolean>(false);
  const [fetchProducts, setFetchProducts] = useState<boolean>(false);

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: 'Layout 1',
      value: 'layout-1',
      icon: 'sendIcon'
    },
    {
      label: t('seProcedures.cancelationLayout'),
      value: 'cancellation-layout',
      icon: 'cancelIcon'
    },
    // {
    //   label: t('seProcedures.outOfDateInvoices'),
    //   value: 'out-of-date-invoices',
    //   icon: 'cancelIcon'
    // },
    {
      label: t('global.downloadLayout', { number: 2 }),
      value: 'layout-2',
      icon: 'sendIcon'
    }
  ];

  const filterOptions = [
    {
      text: t('global.invoice'),
      value: 'invoice'
    },
    {
      text: t('services.petitionNumber'),
      value: 'petitionNumber'
    },
    {
      text: t('clients.titleS'),
      value: 'clientBusinessName'
    }
  ];

  const downloadLayout2 = () => {
    createResource(
      '/admin/se-procedures/layout-2',
      {},
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        const now = new Date();
        const date: string = dayjs(now).format('DD-MMM-YYYY');
        const time: string = dayjs(now).format('hh mm a');

        link.download = `Layout 2 del ${date}, ${time}.csv`;
        link.click();

        getProductsToLayout2();
        Modal.close();
      },
      (error: string) => handleActionError(error)
    );
  };

  const handleActionResult = (wasSuccessful: boolean, message: string) => {
    setShowInvoiceValidationModal(false);
    setShowInvoiceCancellationModal(false);

    if (wasSuccessful) {
      handleSuccessfulAction(message);
    } else {
      handleActionError(message);
    }
  };

  const handleSuccessfulAction = (message: string) => {
    Modal.fireSuccess(
      t('global.correct'),
      message,
      setOpenModal,
      () => {
        setFetchProducts(!fetchProducts);
      },
      soundEffects
    );
  };

  const handleActionError = (error: string) => {
    Modal.fireError(
      error,
      () => {
        setFetchProducts(!fetchProducts);
      },
      soundEffects
    );
  };

  const components: { [key: string]: ReactElement } = {
    'layout-1': (
      <Layout1
        selectedFilter={selectedFilter}
        filterValue={filterValue}
        onError={handleActionError}
        fetchProducts={fetchProducts}
        setFetchProducts={setFetchProducts}
      />
    ),
    'cancellation-layout': (
      <CancellationLayout
        selectedFilter={selectedFilter}
        filterValue={filterValue}
        onError={handleActionError}
        fetchProducts={fetchProducts}
        setFetchProducts={setFetchProducts}
      />
    ),
    // 'out-of-date-invoices': (
    //   <ExpiredInvoices
    //     selectedFilter={selectedFilter}
    //     filterValue={filterValue}
    //     onError={handleActionError}
    //     fetchProducts={fetchProducts}
    //     setFetchProducts={setFetchProducts}
    //   />
    // ),
    'layout-2': (
      <Layout2
        filterValue={filterValue}
      />
    )
  };

  const handleTriggerFilter = (filter: FilterTypes, value: string) => {
    setSelectedFilter(filter);
    setFilterValue(value);
  };

  useEffect(() => {
    storage.set('lateralMenuSEProceduresValue', lateralMenuValue);
  }, [lateralMenuValue]);

  useEffect(() => {
    if (serviceNumber !== 'none') {
      handleTriggerFilter('petitionNumber', serviceNumber || '');
    }
  }, [serviceNumber]);

  const getProductsToLayout2 = () => {
    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters: {
          s_status: 'concluded',
          b_sendInLayout2: false,
          b_attachedToTheService: true
        }
      },
      (data) => {
        setHasProductosToLayout2(data.items.length > 0);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    getProductsToLayout2();
  }, []);

  useEffect(() => {
    if (action === 'validate') {
      setShowInvoiceValidationModal(true);
    }
    if (action === 'cancelate') {
      setShowInvoiceCancellationModal(true);
    }
  }, [action]);

  useEffect(() => {
    if (section === 'layout-1') {
      setLateralMenuValue('layout-1');
    }
    if (section === 'cancellation' || section === 'expired') {
      setLateralMenuValue('cancellation-layout');
    }
    if (section === 'layout-2') {
      setLateralMenuValue('layout-2');
    }
    if (section === 'none') {
      setLateralMenuValue('layout-1');
    }
  }, [section]);

  useEffect(() => {
    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters: {
          s_status: 'cancellation-in-progress',
          b_distinctInvoices: true
        },
        pagination: {
          n_currentPage: 1,
          n_perPage: 10,
          n_totalItems: 0
        },
        ordering: {
          s_orderBy: 'invoice',
          s_orderDirection: 'ASC'
        }
      },
      (data) => {
        setHaveProductsToCancellate(data.items.length > 0);
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );

    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters: {
          s_status: 'validation-in-progress',
          b_distinctInvoices: true
        },
        pagination: {
          n_currentPage: 1,
          n_perPage: 10,
          n_totalItems: 0
        },
        ordering: {
          s_orderBy: 'invoice',
          s_orderDirection: 'ASC'
        }
      },
      (data) => {
        setHaveProductsToValidate(data.items.length > 0);
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  }, [fetchProducts]);

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => setShowInvoiceValidationModal(true)}
            type='primary-outlined'
            label={t('seProcedures.markInvoices') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='validInvoices'
            alignContent='left'
          />
        ),
        available: haveProductsToValidate,
        specialValidation: ['master', 'operator'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => setShowInvoiceCancellationModal(true)}
            type='primary-outlined'
            label={t('seProcedures.markCanceledInvoices') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='cancelInvoices'
            alignContent='left'
          />
        ),
        available: haveProductsToCancellate,
        specialValidation: ['master', 'operator'].includes(userRole)
      }
    ]);
  }, [hasProductosToLayout2, userRole, haveProductsToCancellate, haveProductsToValidate]);

  return (
    <>
      <InvoiceValidation
        visible={showInvoiceValidationModal}
        onClose={() => {
          setShowInvoiceValidationModal(false);
          getProductsToLayout2();
        }}
        onDone={handleActionResult}
      />
      <InvoiceCancellation
        visible={showInvoiceCancellationModal}
        onClose={() => {
          setShowInvoiceCancellationModal(false);
          getProductsToLayout2();
        }}
        onDone={handleActionResult}
      />
      <Header
        title={t('seProcedures.title')}
        showBackbutton={true}
      />
      <div className='procedures-se'>
        <div className='procedures-se__left-container'>
          <Accordeon
            items={[
              {
                title: t('seProcedures.procedures'),
                element: (
                  <>
                    <LateralMenu
                      onSelect={(value: string) => {
                        if (value === 'downloadLayout2') {
                          if (!hasProductosToLayout2) {
                            Modal.fireError(t('seProcedures.noInvoicesAvaibleForLayout2'));
                          } else if (!['master', 'operator'].includes(userRole)) {
                            Modal.fireError(t('seProcedures.noPermissions'));
                          } else {
                            downloadLayout2();
                          }
                        } else {
                          setLateralMenuValue(value);
                        }
                      }}
                      options={lateralMenuOptions}
                      value={lateralMenuValue}
                    />
                  </>
                )
              },
              {
                title: t('global.filer'),
                element: (
                  <>
                    <Filters
                      options={filterOptions}
                      onTriggerFilter={handleTriggerFilter}
                      defaultSelectedFilter={selectedFilter}
                      defaultFilterValue={filterValue}
                    />
                  </>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="procedures-se__right-container">
          {components[lateralMenuValue]}
        </div>
      </div>
    </>
  );
};

export default SEProcedures;
