/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-50',
    documentDescription: 'Lista de inspección para Dictamen para la NORMA Oficial Mexicana NOM-004-SE-2021 Información comercial-Etiquetado de productos textiles, prendas de vestir, sus accesorios y ropa de casa. ',
    documentIssueDate: '01/09/2022',
    hasPackingTable: false,
    hasSamplingTable: true,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones de información
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1 Información comercial
            <br />
            La información acerca de los productos objeto de
            esta Norma Oficial Mexicana, debe presentarse en idioma
            español, ser veraz, describirse y presentarse de
            forma tal que no induzca a error con respecto a la
            naturaleza y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 Prendas de vestir, accesorios y ropa de casa elaborados
            con productos textiles aun cuando contengan plásticos u otros materiales
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Las prendas de vestir, sus accesorios y ropa de casa, deben
            ostentar la siguiente información en forma legible, en una
            o más etiquetas permanentes colocadas en la parte inferior
            del cuello o cintura, o en cualquier otro lugar visible,
            de acuerdo con las características de la prenda, sus
            accesorios y ropa de casa en los casos y términos que
            señala esta Norma Oficial Mexicana, pudiéndose adicionar etiquetas códigos QR.s y
            términos que señala esta Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br />
            <br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una marca
            comercial que es igual a su nombre, denominación o razón
            social, no es obligatorio señalar la marca comercial aludida.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.

            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.
            <br />
            Para productos nacionales se debe incluir lo siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            • Para personas físicas o morales: nombre,
            domicilio fiscal y RFC del fabricante. En el caso de
            Marcas internacionales puede ser el licenciatario.<br />
            Para productos importados se debe incluir lo siguiente:<br />
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso f), deben presentarse en cualquiera de
            las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Textiles<br />
            Los textiles y demás productos no incluidos en la sección
            anterior deben ostentar la siguiente información en forma
            legible, en los casos y términos que señala esta Norma Oficial Mexicana:
            <br /><br />
            3.26<br />
            Textil producto elaborado a partir de fibras naturales
            y/o fibras químicas o una mezcla de éstas, incluyéndose
            entre ellos, en forma enunciativa mas no limitativa,
            hilados, hilos de coser, hilos de bordar, estambres,
            telas en crudo y acabadas, tejidas y no tejidas, casimires,
            pasamanerías (encajes, listones, bordados, elásticos), y similares.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto
            Para productos nacionales se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            Para productos importados se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso c), deben presentarse en
            cualquiera de las etiquetas permanentes o temporales
            (ver 3.8.1,3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Cuando el producto se comercialice en empaque
            cerrado que no permita ver el contenido, adicionalmente
            a la información señalada en 4.1.1 o 4.1.2, según
            corresponda, en dicho empaque debe indicarse la
            denominación del producto y la cantidad de productos contenidos en el mismo.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 Toda la información comercial requerida en la
            presente Norma Oficial Mexicana debe presentarse en
            idioma español, en los términos de la Ley Federal de
            Protección al Consumidor, sin perjuicio de que además
            pueda presentarse en cualquier otro idioma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Cuando las prendas de vestir se comercialicen
            como pares confeccionados del mismo material, pueden
            presentar la etiqueta en una sola de las piezas.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.1.6 Cuando el producto tenga forro
            La información comercial puede presentarse en la
            misma etiqueta o en otra, siempre que se indique
            expresamente que es la información correspondiente
            al forro, mediante la indicación "forro: ..." u otra equivalente.
            <br />
            <br />
            Cuando el forro sea del mismo contenido de fibra
            que el de la prenda de vestir no será obligatorio
            declarar la información del forro.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Marca comerciales
            <br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial
            Mexicana ostente el nombre, denominación o razón
            social del responsable del producto y dicha persona
            utilice una marca comercial que es igual a su nombre,
            denominación o razón social, no es obligatorio señalar
            la marca comercial aludida.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Descripción de insumos
            Para efectos de esta Norma Oficial Mexicana,
            el responsable del producto debe expresar los
            insumos en porcentaje, con relación a la masa
            de las diferentes fibras que integran el
            producto en orden del predominio de dicho porcentaje,
            conforme a las siguientes indicaciones:
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.3.1
            La denominación de las fibras, debe señalarse con los
            nombres genéricos conforme a lo establecido en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013,
            (véase 2. Referencias normativas), por lo que hace aquellas
            fibras no comprendidas en dichas Normas Mexicanas, debe
            señalarse el nombre técnico.
            <br />
            <br />
            Para estos efectos, es obligatorio el uso de nombres
            genéricos de las fibras, contenidos en las normas antes
            señaladas, por lo que no es aceptable el uso de abreviaturas
            o nombres diferentes a los ahí indicados.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            Cuando la norma citada contemple más de un término
            para denominar una fibra se podrá utilizar cualquiera
            de los términos señalados siempre que corresponda a la fibra de que se trate.
            <br /> Ejemplo 1.-: 70 % poliéster 30% Algodón.
            <br /> Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100%
            poliéster (en el caso de los suéteres que tengan parches en los codos).
            <br /> Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20%
            elastano; encaje 100% poliéster; forro de encaje
            100% poliamida; copa100% poliéster; tirante 75% poliamida-
            25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.3.2 Toda fibra que se encuentre presente en
            un porcentaje igual o mayor al 5 % del total de
            la masa, debe expresarse por su nombre genérico.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            Puede usarse el nombre comercial o la marca
            registrada de alguna fibra si se tiene la autorización
            del titular, siempre que se use en conjunción al nombre
            genérico de la fibra en caracteres de igual tamaño dentro
            de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.3.3 <br />
            Las fibras presentes en un porcentaje menor al 5 % del
            total, pueden designarse como "otras" u "otras fibras",
            siendo indistinto el uso de letras mayúsculas, minúsculas
            o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
            <br /><br />
            Cuando estas fibras o insumos están presentes en más
            de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras"
            <br /><br />
            Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida,
            4 % elastano y 2 % acrílico; puede declararse como: 60 %
            Algodón,30 % poliéster, 10 % otras.
            <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 %
            poliamida, 4 % elastano y 2 % acrílico; puede declararse
            como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.3.4 En los productos objeto de esta norma integrados
            por dos o más fibras, debe mencionarse cada una de
            aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            El término "Lana" incluye fibra proveniente del pelaje
            de oveja o cordero, o de pelo de Angora o Cachemira
            (y puede incluir fibras provenientes del pelo de camello,
            alpaca, llama y vicuña), la cual nunca ha sido obtenida de
            algún tejido o producto fieltrado de lana. Ejemplo: 55 %
            Llama, 45 % Alpaca; se puede expresar como: 100 % Lana,
            exclusivamente para los artículos de calcetería.
            Para las demás prendas, telas e hilados debe expresarse el
            nombre correspondiente de acuerdo con la Norma Mexicana
            NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            4.3.5 <br />
            Cuando los productos objeto de esta norma, hayan sido
            elaborados o confeccionados con desperdicios, sobrantes,
            lotes diferentes, subproductos textiles, que sean
            desconocidos o cuyo origen no se pueda demostrar,
            debe indicarse el porcentaje de fibras que encuadren
            en este supuesto, o en su defecto con
            la leyenda ".... (porcentaje) de fibras recuperadas".
            <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
            <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
            Ejemplo 3. 100 % fibras recuperadas
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.3.6<br /><br />
            Cuando se usen fibras recuperadas o recicladas o
            mezclas de éstas con otras fibras vírgenes o con
            otras fibras recuperadas o recicladas, deben señalarse
            los porcentajes y los nombres genéricos de cada una de
            las fibras que integren los productos, anotando las
            palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
            <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas
            (Algodón / viscosa / acrílico);
            <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) /
            20 % acrílico / 3 % elastodieno.
            <br />Ejemplo 3. 100 % fibras recuperadas.
            (Ver 4.3.5)
            <br />De los ejemplos indicados que aplicarían a los incisos
            4.3.5 y 4.3.6, para los productos objeto de esta norma,
            la verificación sólo podrá ser realizada en las fibras
            componentes y no en sus porcentajes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.3.7 Sólo se permite utilizar los términos "virgen"
            o "nuevo" cuando la totalidad de las fibras integrantes
            de los productos objeto de esta norma sean vírgenes o nuevas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            4.3.8 No se debe utilizar el nombre de animal alguno al
            referirse a las fibras que integren a los productos
            objeto de esta norma, a menos que la fibra o el textil
            estén elaborados con el pelo desprendido de la piel del animal de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.3.9
            Se permite una tolerancia de ± 3 puntos porcentuales
            para los insumos de textiles, sobre lo declarado en
            la etiqueta permanente para, ropa de casa, prendas
            de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
            <br />
            <br />
            Ejemplo: cuando se declara 40 % Algodón, la cantidad
            de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
            Para el caso de textiles técnicos que contengan un
            contenido de fibra iguales o menores al 4.9 %, se
            permite una tolerancia de+/- 0.5 porcentual y hasta
            valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
            <br /><br />
            Ejemplo: cuando se declara 3 % de grafeno, la
            cantidad de fibra puede variar de 2.5 % a 3.5 %.
            Para los otros productos objeto de esta norma que declaren
            contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
            <br />La evaluación de dicha tolerancia debe considerarse sobre
            la masa que represente el porcentaje señalado, respecto de la masa total del producto.
            <br />Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la
            presente Norma Oficial Mexicana.
            <br /><br />
            Cuando se utilicen expresiones como "100 %", "Pura..." o
            "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            4.3.10<br /><br />
            Se permite una tolerancia de ± 3 puntos porcentuales
            considerada sobre lo declarado en la etiqueta.
            La evaluación de dicha tolerancia debe considerarse
            sobre la masa que represente el porcentaje señalado,
            respecto de la masa total del producto, y lo señalado
            en el inciso 4.3.9, en los siguientes casos:
            <br />a) cintas elásticas;
            <br />b) medias y pantimedias en cuya manufactura intervienen
            insumos elaborados con fibras elastoméricas
            de origen natural o sintético;
            <br />a) entorchados, hilos, hilados e hilazas de fantasía.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.3.11<br /><br />
            Para los productos siguientes se permite una tolerancia
            de ± 6 puntos porcentuales cuando hay presencia de dos
            o más fibras presentes, considerados sobre la información
            comercial que se indique en la etiqueta de cada una de las
            fibras o insumos. La evaluación de dicha tolerancia debe
            considerarse sobre la masa que represente el porcentaje
            señalado, respecto de la masa total del producto:
            <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
            <br /><br />
            Ejemplo 1.- Calcetín 95% algodón 5% elastodieno;
            dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
            <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno;
            bordado 100% acrílico (en el caso de dibujos sea por bordado).
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            4.3.12<br /><br />
            Deben indicarse en la etiqueta aquellos insumos de los
            productos objeto de esta norma que haya sido incorporados
            a las mismas exclusivamente para efectos ornamentales, de
            protección o armado, cuando su masa exceda de 5 % sobre la
            masa total del producto o su superficie exceda de 15 % de la
            superficie total del mismo. Pudiéndose declarar por separado.
            <br /><br />
            Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
            <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.3.13<br /><br />
            Para el caso de los productos objeto de esta norma
            elaborados o confeccionados con materiales textiles, aun
            cuando contengan plásticos u otros materiales, la declaración
            de los insumos diferentes a las fibras textiles se deberá
            realizar conforme a lo siguiente:
            <br /><br />
            Para el caso de productos textiles elaborados con más del
            50 % de materia textil y que estén combinados con insumos
            de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
            <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100%
            piel vacuna, FORRO: 100 % poliéster.
            <br />Nota: el ejemplo 1 corresponde a un producto denominado
            mochila, objeto de la presente NOM.
            <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 %
            piel ovina, FORRO: 100 % poliéster.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            No debe utilizarse la mezcla de palabras que
            impliquen o tiendan a hacer creer la existencia
            de componentes derivados de la piel o el pelo o
            producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares
            para referirse a materiales sintéticos o artificiales.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 39,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Tallas
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1
            Las tallas de las prendas de vestir deben expresarse
            en idioma español, sin perjuicio de que puedan indicarse
            además en cualquier otro idioma en segundo término,
            admitiéndose para tal efecto las expresiones  abreviaturas
            que tradicionalmente se vienen utilizando de acuerdo con
            el uso cotidiano y las costumbres o aquellas abreviaturas
            nuevas que reflejen la inclusión detallas nuevas, acorde
            a las necesidades de la población mexicana.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2
            Las medidas de la ropa de casa y de los textiles
            deben expresarse de acuerdo con las unidades de
            medida y símbolos que correspondan al Sistema
            General de Unidades de Medida, como son: m, cm,
            mm (véase NOM-008-SCFI-2002), sin perjuicio de
            que se exprese en otros sistemas de unidades de medida.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5 Instrucciones de cuidado
            Las prendas de vestir, ropa de casa y los accesorios
            deben ostentar exclusivamente la información relativa
            al tratamiento adecuado e instrucciones de cuidado y
            conservación que requiera el producto y determine el
            fabricante, debiendo comprender, en su caso, los siguientes datos:
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.5.1 Lavado
            <br /> a) A mano, en lavadora, en seco o proceso especial o
            recomendación en contrario de alguno de estos tipos de lavado.
            <br />b) Temperatura del agua.
            <br />c) Con jabón o detergente.
            <br />d) No lavar.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Blanqueo<br /><br />
            a) Uso o no de compuestos clorados u otros blanqueadores.
            <br />b) No usar blanqueador.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Secado<br /><br />
            a) Exprimir o no exprimir, centrifugar o no centrifugar.
            <br />b) Al sol o a la sombra.
            <br />c) Secado de línea o secado horizontal.
            <br />d) Uso o recomendación en contrario de equipo especial,
            máquina o secadora limpieza en húmedo profesional.
            <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.5.4 Planchado<br /><br />
            a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
            <br />b) Condiciones especiales, si las hubiere.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.5.5<br /><br />
            Recomendaciones particulares, haciendo mención específica
            de las tendencias al encogimiento o deformación cuando
            lesean propias, indicando instrucciones para atenderlas.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.5.6<br /><br />
            Las instrucciones de cuidado y conservación del producto
            deben indicarse por medio de leyendas breves y claras o
            los símbolos, conforme a lo dispuesto en la Norma Mexicana
            NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas),
            sin quesea indispensable que éstos se acompañen de leyendas.
            <br />
            <br />
            Pueden utilizarse símbolos distintos a los previstos en
            dicha norma, sólo cuando además aparezca en idioma
            español, la leyenda relativa al tratamiento adecuado e
            instrucciones de cuidado y conservación.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.6<br /><br />
            País de origen<br />
            La información de país de origen debe cumplir con lo siguiente:
            4.6.1<br /><br />
            Cuando el producto terminado, así como todos sus insumos
            se hayan elaborado o producido en el mismo país, se debe
            utilizar preferentemente la expresión "hecho en ...
            (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
            <br /><br />
            El país de origen será expresado en idioma español o
            conforme a las claves de países que las Reglas de Carácter
            General en Materia de Comercio Exterior vigentes.
            Podrán utilizarse las siguientes abreviaturas, Estados
            Unidos de América: EUA, USA, EE.UU., en mayúsculas o
            minúsculas, con puntos o sin puntos. Para Reino Unido de
            la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.6.2<br /><br />
            Cuando el producto haya sido elaborado en un país con
            insumos de otros, se debe utilizar la leyenda "Hecho
            en ... (país de elaboración) con insumos importados",
            pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
            El país de elaboración será expresado en idioma español
            o conforme al listado de países indicados en las Reglas
            Generales de Comercio Exterior vigentes.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.7<br /><br />
            Acabados<br />
            Cuando se utilice información sobre acabado del producto,
            ésta debe acompañarse del nombre del proceso, por ejemplo:
            "Impermeabilizado, pre encogido, mercerizado, etc.",
            mencionado de manera enunciativa más no limitativa.
            <br /><br />
            Para los textiles o ropa de protección debe indicar
            como parte del etiquetado la protección de acuerdo con el Apéndice A(normativo).
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Instrumentación de la información comercial
            <br />5.1 Prendas de vestir, accesorios y ropa de casa
            <br />5.1.1 Ropa de casa
            <br />La información requerida en los literales a), b), d) y e) del
            inciso 4.1.1 debe presentarse en etiqueta permanente
            (véase 3.8.1) en los siguientes artículos:
            <br />a) Sábanas.
            <br />b) Cobijas y cobertores, excepto los cobertores eléctricos.
            <br />c) Sobrecamas.
            <br />d) Manteles.
            <br />e) Manteles individuales.
            <br />f) Servilletas.
            <br />g) Protectores.
            <br />h) Cortinas confeccionadas.
            <br />i) Toallas.
            <br />j) Colchones y bases de colchones elaborados o forrados con textiles.
            <br />k) Prendas reversibles.
            <br />La información requerida en los literales c) y f), del
            inciso 4.1.1 excepto el inciso b) relacionado con cobijas
            y cobertores, debe presentarse en etiqueta temporal o permanente.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del responsable
            del producto y dicha persona utilice una marca comercial que
            es igual a su nombre, denominación o razón social, no es
            obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio
            fiscal y RFC del fabricante. En el caso de
            marcasinternacionales puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f),
            deben presentarse en cualquiera de las etiquetas
            permanentes o temporal (ver 3.8.1, 3.8.3)o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2<br /><br />
            La información requerida en el inciso 4.1.1 debe presentarse
            en etiqueta temporal o permanente, en la caja, contenedor,
            empaque, fajilla en el que se ofrezca al público consumidor,
            o en el producto mismo, en los siguientes casos:
            <br />a) Pantimedias.
            <br />b) Medias y tobimedias.
            <br />c) Calcetines y calcetas.
            <br />d) Bandas elásticas para la cabeza.
            <br />e) Muñequeras.
            <br />f) Aquellos otros productos que determine la Secretaría
            de Economía mediante criterios que dará a conocer en la
            plataforma informática del Sistema Integral de Normas y
            Evaluación de la Conformidad (SINEC).
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial<br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una
            marca comercial que es igual a su nombre, denominación
            o razón social, no es obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden
            de predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            d) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal
            y RFC del fabricante. En el caso de marcasinternacionales
            puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f), deben presentarse en
            cualquiera de las etiquetas permanentes o temporal
            (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 68,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Textiles y otros productos elaborados con fibras o telas
            <br />5.2.1 La información que se indica en el inciso 4.1.2 debe
            presentarse en etiqueta permanente o temporal (véase 3.8.1
            y 3.8.3), en los siguientes casos:
            <br />Cortes de tela acondicionados para la venta al por menor,
            que contengan 45 % o más de lana peinada, que no excedan
            de cinco metros (Casimires), Bolsos de mano, Maletas,
            Monederos, Billeteras, Estuches, Mochilas, Paraguas y
            parasoles, Cubreasientos, Artículos para cubrir
            electrodomésticos y domésticos, Cubiertas para
            planchadores, Cubiertas para muebles de baño, Cubiertas
            para muebles, Cojines, Artículos de limpieza, Pañales,
            Lienzos para pintores, Canguro para bebé, Pañaleras,
            Baberos, Cambiadores, Cinturones textiles.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 72,
        text: (
          <p className="table-content__row__cell--text">
            c)
            Responsable del producto
            <br />Para productos nacionales se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 73,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 74,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 75,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas Mexicanas
            NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013 (véase 2.
            Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Cuando se comercialicen conjuntos que incluyan diferentes
            productos sujetos a la presente Norma Oficial Mexicana, cada
            uno de ellos debe cumplirla individualmente.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            5.4 La inclusión de una etiqueta con código QR, en cualquier
            caso, se constituye como estrictamente OPCIONAL y ADICIONAL,
            y la misma no sustituye al etiquetado temporal y/o permanente.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-50',
    documentDescription: 'Lista de inspección para Constancia para la NORMA Oficial Mexicana NOM-004-SE-2021 Información comercial-Etiquetado de productos textiles, prendas de vestir, sus accesorios y ropa de casa. ',
    documentIssueDate: '01/09/2022',
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones de información
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1 Información comercial
            <br />
            La información acerca de los productos objeto de
            esta Norma Oficial Mexicana, debe presentarse en idioma
            español, ser veraz, describirse y presentarse de
            forma tal que no induzca a error con respecto a la
            naturaleza y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 Prendas de vestir, accesorios y ropa de casa elaborados
            con productos textiles aun cuando contengan plásticos u otros materiales
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Las prendas de vestir, sus accesorios y ropa de casa, deben
            ostentar la siguiente información en forma legible, en una
            o más etiquetas permanentes colocadas en la parte inferior
            del cuello o cintura, o en cualquier otro lugar visible,
            de acuerdo con las características de la prenda, sus
            accesorios y ropa de casa en los casos y términos que
            señala esta Norma Oficial Mexicana, pudiéndose adicionar etiquetas códigos QR.s y
            términos que señala esta Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br />
            <br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una marca
            comercial que es igual a su nombre, denominación o razón
            social, no es obligatorio señalar la marca comercial aludida.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.

            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.
            <br />
            Para productos nacionales se debe incluir lo siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            • Para personas físicas o morales: nombre,
            domicilio fiscal y RFC del fabricante. En el caso de
            Marcas internacionales puede ser el licenciatario.<br />
            Para productos importados se debe incluir lo siguiente:<br />
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso f), deben presentarse en cualquiera de
            las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Textiles<br />
            Los textiles y demás productos no incluidos en la sección
            anterior deben ostentar la siguiente información en forma
            legible, en los casos y términos que señala esta Norma Oficial Mexicana:
            <br /><br />
            3.26<br />
            Textil producto elaborado a partir de fibras naturales
            y/o fibras químicas o una mezcla de éstas, incluyéndose
            entre ellos, en forma enunciativa mas no limitativa,
            hilados, hilos de coser, hilos de bordar, estambres,
            telas en crudo y acabadas, tejidas y no tejidas, casimires,
            pasamanerías (encajes, listones, bordados, elásticos), y similares.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto
            Para productos nacionales se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            Para productos importados se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso c), deben presentarse en
            cualquiera de las etiquetas permanentes o temporales
            (ver 3.8.1,3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Cuando el producto se comercialice en empaque
            cerrado que no permita ver el contenido, adicionalmente
            a la información señalada en 4.1.1 o 4.1.2, según
            corresponda, en dicho empaque debe indicarse la
            denominación del producto y la cantidad de productos contenidos en el mismo.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 Toda la información comercial requerida en la
            presente Norma Oficial Mexicana debe presentarse en
            idioma español, en los términos de la Ley Federal de
            Protección al Consumidor, sin perjuicio de que además
            pueda presentarse en cualquier otro idioma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Cuando las prendas de vestir se comercialicen
            como pares confeccionados del mismo material, pueden
            presentar la etiqueta en una sola de las piezas.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.1.6 Cuando el producto tenga forro
            La información comercial puede presentarse en la
            misma etiqueta o en otra, siempre que se indique
            expresamente que es la información correspondiente
            al forro, mediante la indicación "forro: ..." u otra equivalente.
            <br />
            <br />
            Cuando el forro sea del mismo contenido de fibra
            que el de la prenda de vestir no será obligatorio
            declarar la información del forro.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Marca comerciales
            <br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial
            Mexicana ostente el nombre, denominación o razón
            social del responsable del producto y dicha persona
            utilice una marca comercial que es igual a su nombre,
            denominación o razón social, no es obligatorio señalar
            la marca comercial aludida.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Descripción de insumos
            Para efectos de esta Norma Oficial Mexicana,
            el responsable del producto debe expresar los
            insumos en porcentaje, con relación a la masa
            de las diferentes fibras que integran el
            producto en orden del predominio de dicho porcentaje,
            conforme a las siguientes indicaciones:
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.3.1
            La denominación de las fibras, debe señalarse con los
            nombres genéricos conforme a lo establecido en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013,
            (véase 2. Referencias normativas), por lo que hace aquellas
            fibras no comprendidas en dichas Normas Mexicanas, debe
            señalarse el nombre técnico.
            <br />
            <br />
            Para estos efectos, es obligatorio el uso de nombres
            genéricos de las fibras, contenidos en las normas antes
            señaladas, por lo que no es aceptable el uso de abreviaturas
            o nombres diferentes a los ahí indicados.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            Cuando la norma citada contemple más de un término
            para denominar una fibra se podrá utilizar cualquiera
            de los términos señalados siempre que corresponda a la fibra de que se trate.
            <br /> Ejemplo 1.-: 70 % poliéster 30% Algodón.
            <br /> Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100%
            poliéster (en el caso de los suéteres que tengan parches en los codos).
            <br /> Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20%
            elastano; encaje 100% poliéster; forro de encaje
            100% poliamida; copa100% poliéster; tirante 75% poliamida-
            25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.3.2 Toda fibra que se encuentre presente en
            un porcentaje igual o mayor al 5 % del total de
            la masa, debe expresarse por su nombre genérico.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            Puede usarse el nombre comercial o la marca
            registrada de alguna fibra si se tiene la autorización
            del titular, siempre que se use en conjunción al nombre
            genérico de la fibra en caracteres de igual tamaño dentro
            de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.3.3 <br />
            Las fibras presentes en un porcentaje menor al 5 % del
            total, pueden designarse como "otras" u "otras fibras",
            siendo indistinto el uso de letras mayúsculas, minúsculas
            o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
            <br /><br />
            Cuando estas fibras o insumos están presentes en más
            de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras"
            <br /><br />
            Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida,
            4 % elastano y 2 % acrílico; puede declararse como: 60 %
            Algodón,30 % poliéster, 10 % otras.
            <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 %
            poliamida, 4 % elastano y 2 % acrílico; puede declararse
            como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.3.4 En los productos objeto de esta norma integrados
            por dos o más fibras, debe mencionarse cada una de
            aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            El término "Lana" incluye fibra proveniente del pelaje
            de oveja o cordero, o de pelo de Angora o Cachemira
            (y puede incluir fibras provenientes del pelo de camello,
            alpaca, llama y vicuña), la cual nunca ha sido obtenida de
            algún tejido o producto fieltrado de lana. Ejemplo: 55 %
            Llama, 45 % Alpaca; se puede expresar como: 100 % Lana,
            exclusivamente para los artículos de calcetería.
            Para las demás prendas, telas e hilados debe expresarse el
            nombre correspondiente de acuerdo con la Norma Mexicana
            NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            4.3.5 <br />
            Cuando los productos objeto de esta norma, hayan sido
            elaborados o confeccionados con desperdicios, sobrantes,
            lotes diferentes, subproductos textiles, que sean
            desconocidos o cuyo origen no se pueda demostrar,
            debe indicarse el porcentaje de fibras que encuadren
            en este supuesto, o en su defecto con
            la leyenda ".... (porcentaje) de fibras recuperadas".
            <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
            <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
            Ejemplo 3. 100 % fibras recuperadas
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.3.6<br /><br />
            Cuando se usen fibras recuperadas o recicladas o
            mezclas de éstas con otras fibras vírgenes o con
            otras fibras recuperadas o recicladas, deben señalarse
            los porcentajes y los nombres genéricos de cada una de
            las fibras que integren los productos, anotando las
            palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
            <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas
            (Algodón / viscosa / acrílico);
            <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) /
            20 % acrílico / 3 % elastodieno.
            <br />Ejemplo 3. 100 % fibras recuperadas.
            (Ver 4.3.5)
            <br />De los ejemplos indicados que aplicarían a los incisos
            4.3.5 y 4.3.6, para los productos objeto de esta norma,
            la verificación sólo podrá ser realizada en las fibras
            componentes y no en sus porcentajes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.3.7 Sólo se permite utilizar los términos "virgen"
            o "nuevo" cuando la totalidad de las fibras integrantes
            de los productos objeto de esta norma sean vírgenes o nuevas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            4.3.8 No se debe utilizar el nombre de animal alguno al
            referirse a las fibras que integren a los productos
            objeto de esta norma, a menos que la fibra o el textil
            estén elaborados con el pelo desprendido de la piel del animal de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.3.9
            Se permite una tolerancia de ± 3 puntos porcentuales
            para los insumos de textiles, sobre lo declarado en
            la etiqueta permanente para, ropa de casa, prendas
            de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
            <br />
            <br />
            Ejemplo: cuando se declara 40 % Algodón, la cantidad
            de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
            Para el caso de textiles técnicos que contengan un
            contenido de fibra iguales o menores al 4.9 %, se
            permite una tolerancia de+/- 0.5 porcentual y hasta
            valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
            <br /><br />
            Ejemplo: cuando se declara 3 % de grafeno, la
            cantidad de fibra puede variar de 2.5 % a 3.5 %.
            Para los otros productos objeto de esta norma que declaren
            contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
            <br />La evaluación de dicha tolerancia debe considerarse sobre
            la masa que represente el porcentaje señalado, respecto de la masa total del producto.
            <br />Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la
            presente Norma Oficial Mexicana.
            <br /><br />
            Cuando se utilicen expresiones como "100 %", "Pura..." o
            "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            4.3.10<br /><br />
            Se permite una tolerancia de ± 3 puntos porcentuales
            considerada sobre lo declarado en la etiqueta.
            La evaluación de dicha tolerancia debe considerarse
            sobre la masa que represente el porcentaje señalado,
            respecto de la masa total del producto, y lo señalado
            en el inciso 4.3.9, en los siguientes casos:
            <br />a) cintas elásticas;
            <br />b) medias y pantimedias en cuya manufactura intervienen
            insumos elaborados con fibras elastoméricas
            de origen natural o sintético;
            <br />a) entorchados, hilos, hilados e hilazas de fantasía.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.3.11<br /><br />
            Para los productos siguientes se permite una tolerancia
            de ± 6 puntos porcentuales cuando hay presencia de dos
            o más fibras presentes, considerados sobre la información
            comercial que se indique en la etiqueta de cada una de las
            fibras o insumos. La evaluación de dicha tolerancia debe
            considerarse sobre la masa que represente el porcentaje
            señalado, respecto de la masa total del producto:
            <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
            <br /><br />
            Ejemplo 1.- Calcetín 95% algodón 5% elastodieno;
            dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
            <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno;
            bordado 100% acrílico (en el caso de dibujos sea por bordado).
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            4.3.12<br /><br />
            Deben indicarse en la etiqueta aquellos insumos de los
            productos objeto de esta norma que haya sido incorporados
            a las mismas exclusivamente para efectos ornamentales, de
            protección o armado, cuando su masa exceda de 5 % sobre la
            masa total del producto o su superficie exceda de 15 % de la
            superficie total del mismo. Pudiéndose declarar por separado.
            <br /><br />
            Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
            <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.3.13<br /><br />
            Para el caso de los productos objeto de esta norma
            elaborados o confeccionados con materiales textiles, aun
            cuando contengan plásticos u otros materiales, la declaración
            de los insumos diferentes a las fibras textiles se deberá
            realizar conforme a lo siguiente:
            <br /><br />
            Para el caso de productos textiles elaborados con más del
            50 % de materia textil y que estén combinados con insumos
            de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
            <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100%
            piel vacuna, FORRO: 100 % poliéster.
            <br />Nota: el ejemplo 1 corresponde a un producto denominado
            mochila, objeto de la presente NOM.
            <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 %
            piel ovina, FORRO: 100 % poliéster.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            No debe utilizarse la mezcla de palabras que
            impliquen o tiendan a hacer creer la existencia
            de componentes derivados de la piel o el pelo o
            producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares
            para referirse a materiales sintéticos o artificiales.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 39,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Tallas
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1
            Las tallas de las prendas de vestir deben expresarse
            en idioma español, sin perjuicio de que puedan indicarse
            además en cualquier otro idioma en segundo término,
            admitiéndose para tal efecto las expresiones  abreviaturas
            que tradicionalmente se vienen utilizando de acuerdo con
            el uso cotidiano y las costumbres o aquellas abreviaturas
            nuevas que reflejen la inclusión detallas nuevas, acorde
            a las necesidades de la población mexicana.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2
            Las medidas de la ropa de casa y de los textiles
            deben expresarse de acuerdo con las unidades de
            medida y símbolos que correspondan al Sistema
            General de Unidades de Medida, como son: m, cm,
            mm (véase NOM-008-SCFI-2002), sin perjuicio de
            que se exprese en otros sistemas de unidades de medida.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5 Instrucciones de cuidado
            Las prendas de vestir, ropa de casa y los accesorios
            deben ostentar exclusivamente la información relativa
            al tratamiento adecuado e instrucciones de cuidado y
            conservación que requiera el producto y determine el
            fabricante, debiendo comprender, en su caso, los siguientes datos:
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.5.1 Lavado
            <br /> a) A mano, en lavadora, en seco o proceso especial o
            recomendación en contrario de alguno de estos tipos de lavado.
            <br />b) Temperatura del agua.
            <br />c) Con jabón o detergente.
            <br />d) No lavar.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Blanqueo<br /><br />
            a) Uso o no de compuestos clorados u otros blanqueadores.
            <br />b) No usar blanqueador.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Secado<br /><br />
            a) Exprimir o no exprimir, centrifugar o no centrifugar.
            <br />b) Al sol o a la sombra.
            <br />c) Secado de línea o secado horizontal.
            <br />d) Uso o recomendación en contrario de equipo especial,
            máquina o secadora limpieza en húmedo profesional.
            <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.5.4 Planchado<br /><br />
            a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
            <br />b) Condiciones especiales, si las hubiere.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.5.5<br /><br />
            Recomendaciones particulares, haciendo mención específica
            de las tendencias al encogimiento o deformación cuando
            lesean propias, indicando instrucciones para atenderlas.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.5.6<br /><br />
            Las instrucciones de cuidado y conservación del producto
            deben indicarse por medio de leyendas breves y claras o
            los símbolos, conforme a lo dispuesto en la Norma Mexicana
            NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas),
            sin quesea indispensable que éstos se acompañen de leyendas.
            <br />
            <br />
            Pueden utilizarse símbolos distintos a los previstos en
            dicha norma, sólo cuando además aparezca en idioma
            español, la leyenda relativa al tratamiento adecuado e
            instrucciones de cuidado y conservación.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.6<br /><br />
            País de origen<br />
            La información de país de origen debe cumplir con lo siguiente:
            4.6.1<br /><br />
            Cuando el producto terminado, así como todos sus insumos
            se hayan elaborado o producido en el mismo país, se debe
            utilizar preferentemente la expresión "hecho en ...
            (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
            <br /><br />
            El país de origen será expresado en idioma español o
            conforme a las claves de países que las Reglas de Carácter
            General en Materia de Comercio Exterior vigentes.
            Podrán utilizarse las siguientes abreviaturas, Estados
            Unidos de América: EUA, USA, EE.UU., en mayúsculas o
            minúsculas, con puntos o sin puntos. Para Reino Unido de
            la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.6.2<br /><br />
            Cuando el producto haya sido elaborado en un país con
            insumos de otros, se debe utilizar la leyenda "Hecho
            en ... (país de elaboración) con insumos importados",
            pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
            El país de elaboración será expresado en idioma español
            o conforme al listado de países indicados en las Reglas
            Generales de Comercio Exterior vigentes.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.7<br /><br />
            Acabados<br />
            Cuando se utilice información sobre acabado del producto,
            ésta debe acompañarse del nombre del proceso, por ejemplo:
            "Impermeabilizado, pre encogido, mercerizado, etc.",
            mencionado de manera enunciativa más no limitativa.
            <br /><br />
            Para los textiles o ropa de protección debe indicar
            como parte del etiquetado la protección de acuerdo con el Apéndice A(normativo).
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Instrumentación de la información comercial
            <br />5.1 Prendas de vestir, accesorios y ropa de casa
            <br />5.1.1 Ropa de casa
            <br />La información requerida en los literales a), b), d) y e) del
            inciso 4.1.1 debe presentarse en etiqueta permanente
            (véase 3.8.1) en los siguientes artículos:
            <br />a) Sábanas.
            <br />b) Cobijas y cobertores, excepto los cobertores eléctricos.
            <br />c) Sobrecamas.
            <br />d) Manteles.
            <br />e) Manteles individuales.
            <br />f) Servilletas.
            <br />g) Protectores.
            <br />h) Cortinas confeccionadas.
            <br />i) Toallas.
            <br />j) Colchones y bases de colchones elaborados o forrados con textiles.
            <br />k) Prendas reversibles.
            <br />La información requerida en los literales c) y f), del
            inciso 4.1.1 excepto el inciso b) relacionado con cobijas
            y cobertores, debe presentarse en etiqueta temporal o permanente.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del responsable
            del producto y dicha persona utilice una marca comercial que
            es igual a su nombre, denominación o razón social, no es
            obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio
            fiscal y RFC del fabricante. En el caso de
            marcasinternacionales puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f),
            deben presentarse en cualquiera de las etiquetas
            permanentes o temporal (ver 3.8.1, 3.8.3)o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2<br /><br />
            La información requerida en el inciso 4.1.1 debe presentarse
            en etiqueta temporal o permanente, en la caja, contenedor,
            empaque, fajilla en el que se ofrezca al público consumidor,
            o en el producto mismo, en los siguientes casos:
            <br />a) Pantimedias.
            <br />b) Medias y tobimedias.
            <br />c) Calcetines y calcetas.
            <br />d) Bandas elásticas para la cabeza.
            <br />e) Muñequeras.
            <br />f) Aquellos otros productos que determine la Secretaría
            de Economía mediante criterios que dará a conocer en la
            plataforma informática del Sistema Integral de Normas y
            Evaluación de la Conformidad (SINEC).
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial<br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una
            marca comercial que es igual a su nombre, denominación
            o razón social, no es obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden
            de predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            d) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal
            y RFC del fabricante. En el caso de marcasinternacionales
            puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f), deben presentarse en
            cualquiera de las etiquetas permanentes o temporal
            (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 68,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Textiles y otros productos elaborados con fibras o telas
            <br />5.2.1 La información que se indica en el inciso 4.1.2 debe
            presentarse en etiqueta permanente o temporal (véase 3.8.1
            y 3.8.3), en los siguientes casos:
            <br />Cortes de tela acondicionados para la venta al por menor,
            que contengan 45 % o más de lana peinada, que no excedan
            de cinco metros (Casimires), Bolsos de mano, Maletas,
            Monederos, Billeteras, Estuches, Mochilas, Paraguas y
            parasoles, Cubreasientos, Artículos para cubrir
            electrodomésticos y domésticos, Cubiertas para
            planchadores, Cubiertas para muebles de baño, Cubiertas
            para muebles, Cojines, Artículos de limpieza, Pañales,
            Lienzos para pintores, Canguro para bebé, Pañaleras,
            Baberos, Cambiadores, Cinturones textiles.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 72,
        text: (
          <p className="table-content__row__cell--text">
            c)
            Responsable del producto
            <br />Para productos nacionales se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 73,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 74,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 75,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas Mexicanas
            NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013 (véase 2.
            Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Cuando se comercialicen conjuntos que incluyan diferentes
            productos sujetos a la presente Norma Oficial Mexicana, cada
            uno de ellos debe cumplirla individualmente.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            5.4 La inclusión de una etiqueta con código QR, en cualquier
            caso, se constituye como estrictamente OPCIONAL y ADICIONAL,
            y la misma no sustituye al etiquetado temporal y/o permanente.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-50',
    documentDescription: 'Lista de inspección para Revisión para la NORMA Oficial Mexicana NOM-004-SE-2021 Información comercial-Etiquetado de productos textiles, prendas de vestir, sus accesorios y ropa de casa. ',
    documentIssueDate: '01/09/2022',
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones de información
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1 Información comercial
            <br />
            La información acerca de los productos objeto de
            esta Norma Oficial Mexicana, debe presentarse en idioma
            español, ser veraz, describirse y presentarse de
            forma tal que no induzca a error con respecto a la
            naturaleza y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 Prendas de vestir, accesorios y ropa de casa elaborados
            con productos textiles aun cuando contengan plásticos u otros materiales
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Las prendas de vestir, sus accesorios y ropa de casa, deben
            ostentar la siguiente información en forma legible, en una
            o más etiquetas permanentes colocadas en la parte inferior
            del cuello o cintura, o en cualquier otro lugar visible,
            de acuerdo con las características de la prenda, sus
            accesorios y ropa de casa en los casos y términos que
            señala esta Norma Oficial Mexicana, pudiéndose adicionar etiquetas códigos QR.s y
            términos que señala esta Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br />
            <br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una marca
            comercial que es igual a su nombre, denominación o razón
            social, no es obligatorio señalar la marca comercial aludida.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.

            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.
            <br />
            Para productos nacionales se debe incluir lo siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            • Para personas físicas o morales: nombre,
            domicilio fiscal y RFC del fabricante. En el caso de
            Marcas internacionales puede ser el licenciatario.<br />
            Para productos importados se debe incluir lo siguiente:<br />
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso f), deben presentarse en cualquiera de
            las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Textiles<br />
            Los textiles y demás productos no incluidos en la sección
            anterior deben ostentar la siguiente información en forma
            legible, en los casos y términos que señala esta Norma Oficial Mexicana:
            <br /><br />
            3.26<br />
            Textil producto elaborado a partir de fibras naturales
            y/o fibras químicas o una mezcla de éstas, incluyéndose
            entre ellos, en forma enunciativa mas no limitativa,
            hilados, hilos de coser, hilos de bordar, estambres,
            telas en crudo y acabadas, tejidas y no tejidas, casimires,
            pasamanerías (encajes, listones, bordados, elásticos), y similares.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto
            Para productos nacionales se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            Para productos importados se debe incluir lo siguiente:
            • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            Los datos referidos en el inciso c), deben presentarse en
            cualquiera de las etiquetas permanentes o temporales
            (ver 3.8.1,3.8.3) o en su empaque cerrado.
            <br />
            <br />
            <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Cuando el producto se comercialice en empaque
            cerrado que no permita ver el contenido, adicionalmente
            a la información señalada en 4.1.1 o 4.1.2, según
            corresponda, en dicho empaque debe indicarse la
            denominación del producto y la cantidad de productos contenidos en el mismo.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 Toda la información comercial requerida en la
            presente Norma Oficial Mexicana debe presentarse en
            idioma español, en los términos de la Ley Federal de
            Protección al Consumidor, sin perjuicio de que además
            pueda presentarse en cualquier otro idioma.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Cuando las prendas de vestir se comercialicen
            como pares confeccionados del mismo material, pueden
            presentar la etiqueta en una sola de las piezas.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.1.6 Cuando el producto tenga forro
            La información comercial puede presentarse en la
            misma etiqueta o en otra, siempre que se indique
            expresamente que es la información correspondiente
            al forro, mediante la indicación "forro: ..." u otra equivalente.
            <br />
            <br />
            Cuando el forro sea del mismo contenido de fibra
            que el de la prenda de vestir no será obligatorio
            declarar la información del forro.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Marca comerciales
            <br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial
            Mexicana ostente el nombre, denominación o razón
            social del responsable del producto y dicha persona
            utilice una marca comercial que es igual a su nombre,
            denominación o razón social, no es obligatorio señalar
            la marca comercial aludida.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Descripción de insumos
            Para efectos de esta Norma Oficial Mexicana,
            el responsable del producto debe expresar los
            insumos en porcentaje, con relación a la masa
            de las diferentes fibras que integran el
            producto en orden del predominio de dicho porcentaje,
            conforme a las siguientes indicaciones:
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.3.1
            La denominación de las fibras, debe señalarse con los
            nombres genéricos conforme a lo establecido en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013,
            (véase 2. Referencias normativas), por lo que hace aquellas
            fibras no comprendidas en dichas Normas Mexicanas, debe
            señalarse el nombre técnico.
            <br />
            <br />
            Para estos efectos, es obligatorio el uso de nombres
            genéricos de las fibras, contenidos en las normas antes
            señaladas, por lo que no es aceptable el uso de abreviaturas
            o nombres diferentes a los ahí indicados.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            Cuando la norma citada contemple más de un término
            para denominar una fibra se podrá utilizar cualquiera
            de los términos señalados siempre que corresponda a la fibra de que se trate.
            <br /> Ejemplo 1.-: 70 % poliéster 30% Algodón.
            <br /> Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100%
            poliéster (en el caso de los suéteres que tengan parches en los codos).
            <br /> Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20%
            elastano; encaje 100% poliéster; forro de encaje
            100% poliamida; copa100% poliéster; tirante 75% poliamida-
            25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.3.2 Toda fibra que se encuentre presente en
            un porcentaje igual o mayor al 5 % del total de
            la masa, debe expresarse por su nombre genérico.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            Puede usarse el nombre comercial o la marca
            registrada de alguna fibra si se tiene la autorización
            del titular, siempre que se use en conjunción al nombre
            genérico de la fibra en caracteres de igual tamaño dentro
            de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.3.3 <br />
            Las fibras presentes en un porcentaje menor al 5 % del
            total, pueden designarse como "otras" u "otras fibras",
            siendo indistinto el uso de letras mayúsculas, minúsculas
            o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
            <br /><br />
            Cuando estas fibras o insumos están presentes en más
            de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras"
            <br /><br />
            Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida,
            4 % elastano y 2 % acrílico; puede declararse como: 60 %
            Algodón,30 % poliéster, 10 % otras.
            <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 %
            poliamida, 4 % elastano y 2 % acrílico; puede declararse
            como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.3.4 En los productos objeto de esta norma integrados
            por dos o más fibras, debe mencionarse cada una de
            aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            El término "Lana" incluye fibra proveniente del pelaje
            de oveja o cordero, o de pelo de Angora o Cachemira
            (y puede incluir fibras provenientes del pelo de camello,
            alpaca, llama y vicuña), la cual nunca ha sido obtenida de
            algún tejido o producto fieltrado de lana. Ejemplo: 55 %
            Llama, 45 % Alpaca; se puede expresar como: 100 % Lana,
            exclusivamente para los artículos de calcetería.
            Para las demás prendas, telas e hilados debe expresarse el
            nombre correspondiente de acuerdo con la Norma Mexicana
            NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            4.3.5 <br />
            Cuando los productos objeto de esta norma, hayan sido
            elaborados o confeccionados con desperdicios, sobrantes,
            lotes diferentes, subproductos textiles, que sean
            desconocidos o cuyo origen no se pueda demostrar,
            debe indicarse el porcentaje de fibras que encuadren
            en este supuesto, o en su defecto con
            la leyenda ".... (porcentaje) de fibras recuperadas".
            <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
            <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
            Ejemplo 3. 100 % fibras recuperadas
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.3.6<br /><br />
            Cuando se usen fibras recuperadas o recicladas o
            mezclas de éstas con otras fibras vírgenes o con
            otras fibras recuperadas o recicladas, deben señalarse
            los porcentajes y los nombres genéricos de cada una de
            las fibras que integren los productos, anotando las
            palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
            <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas
            (Algodón / viscosa / acrílico);
            <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) /
            20 % acrílico / 3 % elastodieno.
            <br />Ejemplo 3. 100 % fibras recuperadas.
            (Ver 4.3.5)
            <br />De los ejemplos indicados que aplicarían a los incisos
            4.3.5 y 4.3.6, para los productos objeto de esta norma,
            la verificación sólo podrá ser realizada en las fibras
            componentes y no en sus porcentajes.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.3.7 Sólo se permite utilizar los términos "virgen"
            o "nuevo" cuando la totalidad de las fibras integrantes
            de los productos objeto de esta norma sean vírgenes o nuevas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            4.3.8 No se debe utilizar el nombre de animal alguno al
            referirse a las fibras que integren a los productos
            objeto de esta norma, a menos que la fibra o el textil
            estén elaborados con el pelo desprendido de la piel del animal de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.3.9
            Se permite una tolerancia de ± 3 puntos porcentuales
            para los insumos de textiles, sobre lo declarado en
            la etiqueta permanente para, ropa de casa, prendas
            de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
            <br />
            <br />
            Ejemplo: cuando se declara 40 % Algodón, la cantidad
            de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
            Para el caso de textiles técnicos que contengan un
            contenido de fibra iguales o menores al 4.9 %, se
            permite una tolerancia de+/- 0.5 porcentual y hasta
            valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
            <br /><br />
            Ejemplo: cuando se declara 3 % de grafeno, la
            cantidad de fibra puede variar de 2.5 % a 3.5 %.
            Para los otros productos objeto de esta norma que declaren
            contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
            <br />La evaluación de dicha tolerancia debe considerarse sobre
            la masa que represente el porcentaje señalado, respecto de la masa total del producto.
            <br />Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la
            presente Norma Oficial Mexicana.
            <br /><br />
            Cuando se utilicen expresiones como "100 %", "Pura..." o
            "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            4.3.10<br /><br />
            Se permite una tolerancia de ± 3 puntos porcentuales
            considerada sobre lo declarado en la etiqueta.
            La evaluación de dicha tolerancia debe considerarse
            sobre la masa que represente el porcentaje señalado,
            respecto de la masa total del producto, y lo señalado
            en el inciso 4.3.9, en los siguientes casos:
            <br />a) cintas elásticas;
            <br />b) medias y pantimedias en cuya manufactura intervienen
            insumos elaborados con fibras elastoméricas
            de origen natural o sintético;
            <br />a) entorchados, hilos, hilados e hilazas de fantasía.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.3.11<br /><br />
            Para los productos siguientes se permite una tolerancia
            de ± 6 puntos porcentuales cuando hay presencia de dos
            o más fibras presentes, considerados sobre la información
            comercial que se indique en la etiqueta de cada una de las
            fibras o insumos. La evaluación de dicha tolerancia debe
            considerarse sobre la masa que represente el porcentaje
            señalado, respecto de la masa total del producto:
            <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
            <br /><br />
            Ejemplo 1.- Calcetín 95% algodón 5% elastodieno;
            dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
            <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno;
            bordado 100% acrílico (en el caso de dibujos sea por bordado).
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            4.3.12<br /><br />
            Deben indicarse en la etiqueta aquellos insumos de los
            productos objeto de esta norma que haya sido incorporados
            a las mismas exclusivamente para efectos ornamentales, de
            protección o armado, cuando su masa exceda de 5 % sobre la
            masa total del producto o su superficie exceda de 15 % de la
            superficie total del mismo. Pudiéndose declarar por separado.
            <br /><br />
            Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
            <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.3.13<br /><br />
            Para el caso de los productos objeto de esta norma
            elaborados o confeccionados con materiales textiles, aun
            cuando contengan plásticos u otros materiales, la declaración
            de los insumos diferentes a las fibras textiles se deberá
            realizar conforme a lo siguiente:
            <br /><br />
            Para el caso de productos textiles elaborados con más del
            50 % de materia textil y que estén combinados con insumos
            de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
            <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100%
            piel vacuna, FORRO: 100 % poliéster.
            <br />Nota: el ejemplo 1 corresponde a un producto denominado
            mochila, objeto de la presente NOM.
            <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 %
            piel ovina, FORRO: 100 % poliéster.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            No debe utilizarse la mezcla de palabras que
            impliquen o tiendan a hacer creer la existencia
            de componentes derivados de la piel o el pelo o
            producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares
            para referirse a materiales sintéticos o artificiales.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 39,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Tallas
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1
            Las tallas de las prendas de vestir deben expresarse
            en idioma español, sin perjuicio de que puedan indicarse
            además en cualquier otro idioma en segundo término,
            admitiéndose para tal efecto las expresiones  abreviaturas
            que tradicionalmente se vienen utilizando de acuerdo con
            el uso cotidiano y las costumbres o aquellas abreviaturas
            nuevas que reflejen la inclusión detallas nuevas, acorde
            a las necesidades de la población mexicana.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2
            Las medidas de la ropa de casa y de los textiles
            deben expresarse de acuerdo con las unidades de
            medida y símbolos que correspondan al Sistema
            General de Unidades de Medida, como son: m, cm,
            mm (véase NOM-008-SCFI-2002), sin perjuicio de
            que se exprese en otros sistemas de unidades de medida.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5 Instrucciones de cuidado
            Las prendas de vestir, ropa de casa y los accesorios
            deben ostentar exclusivamente la información relativa
            al tratamiento adecuado e instrucciones de cuidado y
            conservación que requiera el producto y determine el
            fabricante, debiendo comprender, en su caso, los siguientes datos:
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.5.1 Lavado
            <br /> a) A mano, en lavadora, en seco o proceso especial o
            recomendación en contrario de alguno de estos tipos de lavado.
            <br />b) Temperatura del agua.
            <br />c) Con jabón o detergente.
            <br />d) No lavar.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Blanqueo<br /><br />
            a) Uso o no de compuestos clorados u otros blanqueadores.
            <br />b) No usar blanqueador.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Secado<br /><br />
            a) Exprimir o no exprimir, centrifugar o no centrifugar.
            <br />b) Al sol o a la sombra.
            <br />c) Secado de línea o secado horizontal.
            <br />d) Uso o recomendación en contrario de equipo especial,
            máquina o secadora limpieza en húmedo profesional.
            <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.5.4 Planchado<br /><br />
            a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
            <br />b) Condiciones especiales, si las hubiere.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.5.5<br /><br />
            Recomendaciones particulares, haciendo mención específica
            de las tendencias al encogimiento o deformación cuando
            lesean propias, indicando instrucciones para atenderlas.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.5.6<br /><br />
            Las instrucciones de cuidado y conservación del producto
            deben indicarse por medio de leyendas breves y claras o
            los símbolos, conforme a lo dispuesto en la Norma Mexicana
            NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas),
            sin quesea indispensable que éstos se acompañen de leyendas.
            <br />
            <br />
            Pueden utilizarse símbolos distintos a los previstos en
            dicha norma, sólo cuando además aparezca en idioma
            español, la leyenda relativa al tratamiento adecuado e
            instrucciones de cuidado y conservación.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.6<br /><br />
            País de origen<br />
            La información de país de origen debe cumplir con lo siguiente:
            4.6.1<br /><br />
            Cuando el producto terminado, así como todos sus insumos
            se hayan elaborado o producido en el mismo país, se debe
            utilizar preferentemente la expresión "hecho en ...
            (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
            <br /><br />
            El país de origen será expresado en idioma español o
            conforme a las claves de países que las Reglas de Carácter
            General en Materia de Comercio Exterior vigentes.
            Podrán utilizarse las siguientes abreviaturas, Estados
            Unidos de América: EUA, USA, EE.UU., en mayúsculas o
            minúsculas, con puntos o sin puntos. Para Reino Unido de
            la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.6.2<br /><br />
            Cuando el producto haya sido elaborado en un país con
            insumos de otros, se debe utilizar la leyenda "Hecho
            en ... (país de elaboración) con insumos importados",
            pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
            El país de elaboración será expresado en idioma español
            o conforme al listado de países indicados en las Reglas
            Generales de Comercio Exterior vigentes.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.7<br /><br />
            Acabados<br />
            Cuando se utilice información sobre acabado del producto,
            ésta debe acompañarse del nombre del proceso, por ejemplo:
            "Impermeabilizado, pre encogido, mercerizado, etc.",
            mencionado de manera enunciativa más no limitativa.
            <br /><br />
            Para los textiles o ropa de protección debe indicar
            como parte del etiquetado la protección de acuerdo con el Apéndice A(normativo).
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1<br /><br />
            La leyenda "Hecha a mano" puede utilizarse únicamente cuando
            el producto haya sido confeccionado, elaborado o producido totalmente a mano.
            <br />La indicación "a mano" debe ir acompañada de la descripción
            de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Instrumentación de la información comercial
            <br />5.1 Prendas de vestir, accesorios y ropa de casa
            <br />5.1.1 Ropa de casa
            <br />La información requerida en los literales a), b), d) y e) del
            inciso 4.1.1 debe presentarse en etiqueta permanente
            (véase 3.8.1) en los siguientes artículos:
            <br />a) Sábanas.
            <br />b) Cobijas y cobertores, excepto los cobertores eléctricos.
            <br />c) Sobrecamas.
            <br />d) Manteles.
            <br />e) Manteles individuales.
            <br />f) Servilletas.
            <br />g) Protectores.
            <br />h) Cortinas confeccionadas.
            <br />i) Toallas.
            <br />j) Colchones y bases de colchones elaborados o forrados con textiles.
            <br />k) Prendas reversibles.
            <br />La información requerida en los literales c) y f), del
            inciso 4.1.1 excepto el inciso b) relacionado con cobijas
            y cobertores, debe presentarse en etiqueta temporal o permanente.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del responsable
            del producto y dicha persona utilice una marca comercial que
            es igual a su nombre, denominación o razón social, no es
            obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio
            fiscal y RFC del fabricante. En el caso de
            marcasinternacionales puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f),
            deben presentarse en cualquiera de las etiquetas
            permanentes o temporal (ver 3.8.1, 3.8.3)o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2<br /><br />
            La información requerida en el inciso 4.1.1 debe presentarse
            en etiqueta temporal o permanente, en la caja, contenedor,
            empaque, fajilla en el que se ofrezca al público consumidor,
            o en el producto mismo, en los siguientes casos:
            <br />a) Pantimedias.
            <br />b) Medias y tobimedias.
            <br />c) Calcetines y calcetas.
            <br />d) Bandas elásticas para la cabeza.
            <br />e) Muñequeras.
            <br />f) Aquellos otros productos que determine la Secretaría
            de Economía mediante criterios que dará a conocer en la
            plataforma informática del Sistema Integral de Normas y
            Evaluación de la Conformidad (SINEC).
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            a) Marca comercial (ver inciso 4.2).
            <br /><br />
            4.2 Marca comercial<br />
            Debe señalarse la marca comercial del producto.
            Cuando el producto objeto de esta Norma Oficial Mexicana
            ostente el nombre, denominación o razón social del
            responsable del producto y dicha persona utilice una
            marca comercial que es igual a su nombre, denominación
            o razón social, no es obligatorio señalar la marca comercial aludida.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            b) Descripción de insumos (porcentaje en masa en orden
            de predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013),
            véase 2. Referencias normativas debiendo ser con númeroarábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            d) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            d) Instrucciones de cuidado (ver inciso 4.5).
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            e) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            f) Responsable del producto.<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal
            y RFC del fabricante. En el caso de marcasinternacionales
            puede ser el licenciatario.
            <br />Para productos importados se debe incluir lo siguiente:
            <br />- Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
            <br />Los datos referidos en el inciso f), deben presentarse en
            cualquiera de las etiquetas permanentes o temporal
            (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 68,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Textiles y otros productos elaborados con fibras o telas
            <br />5.2.1 La información que se indica en el inciso 4.1.2 debe
            presentarse en etiqueta permanente o temporal (véase 3.8.1
            y 3.8.3), en los siguientes casos:
            <br />Cortes de tela acondicionados para la venta al por menor,
            que contengan 45 % o más de lana peinada, que no excedan
            de cinco metros (Casimires), Bolsos de mano, Maletas,
            Monederos, Billeteras, Estuches, Mochilas, Paraguas y
            parasoles, Cubreasientos, Artículos para cubrir
            electrodomésticos y domésticos, Cubiertas para
            planchadores, Cubiertas para muebles de baño, Cubiertas
            para muebles, Cojines, Artículos de limpieza, Pañales,
            Lienzos para pintores, Canguro para bebé, Pañaleras,
            Baberos, Cambiadores, Cinturones textiles.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas
            Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013
            (véase 2. Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 72,
        text: (
          <p className="table-content__row__cell--text">
            c)
            Responsable del producto
            <br />Para productos nacionales se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:

            <br />Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 73,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 74,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 La información requerida en 4.1.2 debe presentarse
            en etiqueta permanente o temporal adherida o amarrada
            al producto, en los siguientes casos:
            Telas tejidas y no tejidas de cualquier índole, Alfombras,
            bajo alfombras y tapetes de materiales textiles, Pelucas,
            Artículos para el cabello (salvo aquellos que por sus
            pequeñas dimensiones deban empacarse a granel). Cuando
            la presentación para su venta al consumidor final de
            estos artículos se presente en paquete, la información
            comercial debe estar contenida en el paquete, Corbatas
            de moño, Artículos destinados a ser utilizados en una
            sola ocasión (desechables). En este caso, la información
            a que se refiere el inciso 4.1.2 puede presentarse en el envase.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 75,
        text: (
          <p className="table-content__row__cell--text">
            a) Descripción de insumos porcentaje en masa en orden de
            predominio, conforme a lo dispuesto en las Normas Mexicanas
            NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013 (véase 2.
            Referencias normativas) debiendo ser con número arábigo del 1 al 100.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            b) País de origen.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            c) Responsable del producto<br />
            Para productos nacionales se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante.
            <br />Para productos importados se debe incluir lo siguiente:
            <br /><br />
            Para personas físicas o morales: nombre, domicilio fiscal y
            RFC del importador. Los datos referidos en el inciso c),
            deben presentarse en cualquiera de las etiquetas permanentes
            o temporales (ver 3.8.1, 3.8.3) o en su empaque cerrado.
            <br /><br />
            <b>Nota: Corroborar que este dato se encuentre en una etiqueta permanente</b>
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Cuando se comercialicen conjuntos que incluyan diferentes
            productos sujetos a la presente Norma Oficial Mexicana, cada
            uno de ellos debe cumplirla individualmente.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            5.4 La inclusión de una etiqueta con código QR, en cualquier
            caso, se constituye como estrictamente OPCIONAL y ADICIONAL,
            y la misma no sustituye al etiquetado temporal y/o permanente.
          </p>
        )
      }
    ]
  }
};
