import React, { useId } from 'react';

import { Icon } from '..';
import { IconTypes } from '../Icon/iconList';

import './styles.scss';

interface Props {
  type: 'primary' | 'secondary' | 'primary-outlined' | 'red' | 'secondary-outlined'
  border?: 'primary' | 'secondary' | 'primary-outlined' | 'red' | 'secondary-outlined' | 'none'
  label?: string
  onClick: any
  icon?: IconTypes
  iconPosition?: 'left' | 'right' | 'top' | 'bottom'
  disabled?: boolean
  transparent?: boolean
  rounded?: boolean
  size?: 'small' | 'big' | 'giant'
  fullWidth?: boolean
  alignContent?: 'left' | 'right'
  id?: string
  submit?: boolean
  onlyIconOnMobile?: boolean
}

const Button: React.FC<Props> = (props) => {
  const {
    type,
    label = '',
    onClick,
    icon = '',
    iconPosition = 'right',
    disabled = false,
    transparent = false,
    border = 'none',
    rounded = false,
    size = '',
    fullWidth = false,
    alignContent,
    id = useId(),
    submit = false,
    onlyIconOnMobile = false
  } = props;

  const borderTypes = {
    none: '',
    primary: 'button__borderBlue',
    secondary: 'button__borderLowBlue',
    'primary-outlined': 'button__borderLowGrey',
    'secondary-outlined': 'button__borderHighGrey',
    red: 'button__borderRed'
  };

  const handleOnClickbutton = () => {
    // eslint-disable-next-line no-undef
    const button = document.getElementById(id);

    if (button) {
      button.blur();
    }

    onClick();
  };

  return (
    <button
      id={id}
      disabled={disabled}
      className={`
        button button__${type}
        ${disabled ? 'button--button-disabled' : ''}
        ${transparent ? 'button__borderNoneTransparent' : ''}
        ${borderTypes[border] || ''}
        ${rounded ? 'button__rounded' : ''}
        ${`button--${size}`}
        ${fullWidth ? 'button--fullWidth' : ''}
        ${`button--align-${alignContent}`}
        ${`button--icon-${iconPosition === 'top' || iconPosition === 'bottom' ? 'center' : ''}`}
        `
      }
      onClick={handleOnClickbutton}
      type={submit ? 'submit' : 'button'}
    >
      {
        ((iconPosition === 'left' || iconPosition === 'top') && icon) && (
          <Icon
            type={icon}
            className={label
              ? `button__icon button__icon--${size}`
              : `button__icon button__icon--unique button__icon--${size}`
            }
          />
        )
      }
      <b className={onlyIconOnMobile ? 'button__label-mobile' : ''}>{label}</b>
      {
        (!icon && !label) && (
          'empty content'
        )
      }
      {
        ((iconPosition === 'right' || iconPosition === 'bottom') && icon) && (
          <Icon
            type={icon}
            className={label
              ? `button__icon button__icon--${size}`
              : `button__icon button__icon--unique button__icon--${size}`
            }
          />
        )
      }
    </button>
  );
};

export default Button;
