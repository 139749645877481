import { useEffect } from 'react';

export default function useKeyPress(
  action: any,
  dependencies?: any[],
  validation = true,
  key = 'Enter'
) {
  const downHandler = (event: { key: string; }) => {
    if (event.key === key && validation) {
      action();
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.addEventListener('keydown', downHandler);

    return () => {
      // eslint-disable-next-line no-undef
      document.removeEventListener('keydown', downHandler);
    };
  }, dependencies);
}
