import React from 'react';

import './styles.scss';

interface Props {
  title: string
  type?: 'primary' | 'secondary' | 'error'
  className?: string
  fontSize?: number
}

const Title: React.FC<Props> = (props) => {
  const {
    title,
    type = 'primary',
    className = '',
    fontSize = 1
  } = props;

  return (
    <p className={`title title--${type} ${className} title--font-size-${fontSize}`}>{title}</p>
  );
};

export default Title;
