/* eslint-disable no-undef */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useNavigate } from '../../hooks';

export const EmptyLayout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (Notification.permission !== 'granted') {
    Notification.requestPermission();
  }

  useEffect(() => {
    const channel = new BroadcastChannel('cecsa_trade_channel');

    channel.postMessage({ type: 'new_tab' });

    channel.onmessage = (event) => {
      if (event.data.type === 'tab_opened') {
        // eslint-disable-next-line no-alert
        alert(t('global.appAlreadyOpen'));
        navigate('/app-already-open');
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  return (
    <div className='empty-layout'>
      <Outlet />
      {/* <OnlineStatus /> */}
    </div>
  );
};

export default EmptyLayout;
