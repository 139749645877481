import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  ModalView,
  Select,
  Title,
  CheckBox,
  Separator
} from '../../../../components';

import { useResource } from '../../../../hooks';
import {
  NormCodes,
  User
} from '../../../../interfaces';

import { emptyUser } from '../../../../emptyObjects';
import { validate } from '../../../../helpers';
import CheckList from '../CheckList';

interface NormCertified {
  norm: string
  certified: boolean
}

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (_data: User) => void
  onError: (_error: string) => void
  title: string
  user?: User
}

const UserForm: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onSuccess,
    onError,
    user = emptyUser
  } = props;

  const {
    updateResource,
    createResource
  } = useResource<User>();

  const { t } = useTranslation();

  const [formUser, setFormUser] = useState<User>(user);
  const [substituteTechnicalManager, setSubstituteTechnicalManager] = useState<boolean>(false);
  const [technicalManager, setTechnicalManager] = useState<boolean>(false);
  const [followUpInOperationsReport, setFollowUpInOperationsReport] = useState<boolean>(false);

  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const onChangeInput = (value: string | number, id: string) => {
    setFormUser({
      ...formUser,
      [id]: value
    });
  };

  const validData = (): boolean => {
    if (validate.text(formUser.name, 1) !== 'success') {
      setErrors({
        name: t('users.errors.name')
      });

      return false;
    }
    if (validate.text(formUser.email, 1) !== 'success') {
      setErrors({
        email: t('users.errors.email')
      });

      return false;
    }
    if (validate.email(formUser.email) !== 'success') {
      setErrors({
        email: validate.email(formUser.email)
      });

      return false;
    }
    if (formUser.phoneNumber?.length !== 13) {
      setErrors({
        phoneNumber: t('global.errorPhoneNumber')
      });

      return false;
    }
    if (formUser.role.length === 4) {
      setErrors({
        role: t('users.errors.role')
      });

      return false;
    }

    return true;
  };

  const handleEditClient = () => {
    if (validData()) {
      updateResource(
        `admin/users/${formUser.id}`,
        {
          name: formUser.name,
          email: formUser.email,
          role: formUser.role,
          approver: formUser.approver,
          substituteTechnicalManager,
          technicalManager,
          followUpInOperationsReport,
          normCodes: formUser.norms,
          phoneNumber: formUser.phoneNumber
        },
        (data) => {
          setFormUser(user);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  const handleCreateClient = () => {
    if (validData()) {
      createResource(
        'admin/users',
        {
          name: formUser.name,
          email: formUser.email,
          role: formUser.role,
          approver: formUser.approver,
          substituteTechnicalManager,
          technicalManager,
          followUpInOperationsReport,
          normCodes: formUser.norms,
          phoneNumber: formUser.phoneNumber
        },
        (data) => {
          setFormUser(user);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (user.id) {
      setFormUser(user);
    }

    if (user.substituteTechnicalManager === false || user.substituteTechnicalManager === true) {
      setSubstituteTechnicalManager(user.substituteTechnicalManager);
    }

    if (user.technicalManager === false || user.technicalManager === true) {
      setTechnicalManager(user.technicalManager);
    }
  }, [user]);

  useEffect(() => {
    setErrors({});
  }, [visible]);

  const setPhoneValue = () => {
    let value: string = '';

    if (formUser.phoneNumber) {
      const number = formUser.phoneNumber.slice(-10);
      const countryCode = formUser.phoneNumber.slice(0, -10).replace('1', '');
      value = `${countryCode}${number}`;
    }

    return value;
  };

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={user.id ? () => handleEditClient() : () => handleCreateClient()}
      fullSzie={false}
      customComponent={
        <div className="user-form">
          <Title title={user.id ? t('users.editUser') : t('users.newUser')} />
          <Input
            title={t('global.name') || ''}
            type="text"
            value={formUser.name}
            id='name'
            onChange={onChangeInput}
            placeholder={t('global.name') || ''}
            hasError={!!errors.name}
            helperText={errors.name}
          />
          <Input
            title="E-mail"
            type="email"
            value={formUser.email}
            id='email'
            onChange={onChangeInput}
            placeholder={t('global.emailExample') || ''}
            hasError={!!errors.email}
            helperText={errors.email}
          />
          <Input
            title={t('global.mobilePhoneNumber') || ''}
            type="mobilePhoneNumber"
            value={setPhoneValue() || ''}
            id='phoneNumber'
            onChange={onChangeInput}
            placeholder={t('global.examplePhoneNumber') || ''}
            hasError={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />
          {
            user.role !== 'master' && (
              <Select
                title="Rol"
                options={[
                  {
                    text: 'Inspector',
                    value: 'inspector'
                  },
                  {
                    text: 'Operador',
                    value: 'operator'
                  },
                  {
                    text: 'Calidad',
                    value: 'quality'
                  },
                  {
                    text: 'Finanzas',
                    value: 'finance'
                  }
                ]}
                value={formUser.role}
                id='role'
                onChange={onChangeInput}
                toolTipText={t('users.roleExplication') || ''}
                toolTipPosition="left"
                hasError={!!errors.role}
                helperText={errors.role}
              />
            )
          }
          {
            formUser.role === 'inspector' && (
              <>
                <CheckBox
                  label={t('users.substituteTechnicalManager') || ''}
                  checked={substituteTechnicalManager}
                  onChange={() => {
                    setSubstituteTechnicalManager(!substituteTechnicalManager);

                    if (!substituteTechnicalManager === true
                      && formUser.approver === false) {
                      setFormUser({
                        ...formUser,
                        approver: !formUser.approver
                      });
                    }

                    setTechnicalManager(false);
                  }}
                  orientation='horizontal'
                />
                <CheckBox
                  label={t('users.technicalManager') || ''}
                  checked={technicalManager}
                  onChange={() => {
                    setTechnicalManager(!technicalManager);

                    if (!technicalManager === true
                      && formUser.approver === false) {
                      setFormUser({
                        ...formUser,
                        approver: !formUser.approver
                      });
                    }

                    setSubstituteTechnicalManager(false);
                  }}
                  orientation='horizontal'
                />
              </>
            )
          }
          {
            formUser.role === 'inspector' && user.role !== 'master' && (
              <>
                <Separator orientation='horizontal' />
                <CheckBox
                  label={t('users.approver') || ''}
                  checked={formUser.approver}
                  onChange={() => {
                    setFormUser({
                      ...formUser,
                      approver: !formUser.approver
                    });

                    if (!formUser.approver === true
                      && substituteTechnicalManager === false
                      && technicalManager === false) {
                      setSubstituteTechnicalManager(true);
                    }
                  }}
                  orientation='horizontal'
                />
                <Separator orientation='horizontal' />
                <CheckBox
                  label={t('users.followUpInOperationsReport') || ''}
                  checked={formUser.followUpInOperationsReport}
                  onChange={() => {
                    setFormUser({
                      ...formUser,
                      followUpInOperationsReport: !formUser.followUpInOperationsReport
                    });
                    setFollowUpInOperationsReport(!followUpInOperationsReport);
                  }}
                  orientation='horizontal'
                />
                <Separator orientation='horizontal' />
                <p>{t('users.norms')}</p>
                <CheckList
                  items={[
                    {
                      id: '003',
                      label: 'NOM-003-SSA1-2006',
                      checked: formUser.norms.some(item => item.norm === '003') || false,
                      certified: formUser.norms.find(item => item.norm === '003' && item.training === false) !== undefined || false
                    },
                    {
                      id: '004',
                      label: 'NOM-004-SE-2021',
                      checked: formUser.norms.some(item => item.norm === '004') || false,
                      certified: formUser.norms.find(item => item.norm === '004' && item.training === false) !== undefined || false
                    },
                    {
                      id: '015',
                      label: 'NOM-015-SCFI-2007',
                      checked: formUser.norms.some(item => item.norm === '015') || false,
                      certified: formUser.norms.find(item => item.norm === '015' && item.training === false) !== undefined || false
                    },
                    {
                      id: '020',
                      label: 'NOM-020-SCFI-1997',
                      checked: formUser.norms.some(item => item.norm === '020') || false,
                      certified: formUser.norms.find(item => item.norm === '020' && item.training === false) !== undefined || false
                    },
                    {
                      id: '024',
                      label: 'NOM-024-SCFI-2013',
                      checked: formUser.norms.some(item => item.norm === '024') || false,
                      certified: formUser.norms.find(item => item.norm === '024' && item.training === false) !== undefined || false
                    },
                    {
                      id: '050',
                      label: 'NOM-050-SCFI-2004',
                      checked: formUser.norms.some(item => item.norm === '050') || false,
                      certified: formUser.norms.find(item => item.norm === '050' && item.training === false) !== undefined || false
                    },
                    {
                      id: '051',
                      label: 'NOM-051-SCFI/SSA1-2010',
                      checked: formUser.norms.some(item => item.norm === '051') || false,
                      certified: formUser.norms.find(item => item.norm === '051' && item.training === false) !== undefined || false
                    },
                    {
                      id: '141',
                      label: 'NOM-141-SSA1/SCFI-2012',
                      checked: formUser.norms.some(item => item.norm === '141') || false,
                      certified: formUser.norms.find(item => item.norm === '141' && item.training === false) !== undefined || false
                    },
                    {
                      id: '142',
                      label: 'NOM-142-SSA1/SCFI-2014',
                      checked: formUser.norms.some(item => item.norm === '142') || false,
                      certified: formUser.norms.find(item => item.norm === '142' && item.training === false) !== undefined || false
                    },
                    {
                      id: '173',
                      label: 'NOM-173-SE-2021',
                      checked: formUser.norms.some(item => item.norm === '173') || false,
                      certified: formUser.norms.find(item => item.norm === '173' && item.training === false) !== undefined || false
                    },
                    {
                      id: '187',
                      label: 'NOM-187-SSA1/SCFI-2002',
                      checked: formUser.norms.some(item => item.norm === '187') || false,
                      certified: formUser.norms.find(item => item.norm === '187' && item.training === false) !== undefined || false
                    },
                    {
                      id: '189',
                      label: 'NOM-189-SSA1/SCFI-2018',
                      checked: formUser.norms.some(item => item.norm === '189') || false,
                      certified: formUser.norms.find(item => item.norm === '189' && item.training === false) !== undefined || false
                    },
                    {
                      id: '235',
                      label: 'NOM-235-SE-2020',
                      checked: formUser.norms.some(item => item.norm === '235') || false,
                      certified: formUser.norms.find(item => item.norm === '235' && item.training === false) !== undefined || false
                    }
                  ]}
                  orientation="vertical"
                  onCheck={(values: NormCertified[]) => {
                    setFormUser({
                      ...formUser,
                      norms: values.map(item => {
                        return ({
                          training: !item.certified,
                          norm: item.norm as NormCodes
                        });
                      })
                    });
                  }}
                />
              </>
            )
          }
        </div>
      }
    />
  );
};

export default UserForm;
