import React, { useContext, useId } from 'react';

import { AppContext } from '../../context/AppContext';

import icons, { IconTypes } from './iconList';
import iconsDarkMode, { IconTypesDarkMode } from './iconListDarkMode';

interface Props {
  type: IconTypes
  className?: string
  alt?: string
  onClick?: () => void
  id?: string
}

const Icon: React.FC<Props> = (props) => {
  const {
    type = 'CECSAlogo',
    className = '',
    alt = 'icon',
    onClick = () => { },
    id = useId()
  } = props;

  const { themeMode } = useContext(AppContext);

  const iconsWhitDarkMode: { [name: string]: IconTypesDarkMode } = {
    error404: 'error404DarkMode',
    edit: 'editDarkMode',
    upload: 'uploadDarkMode',
    needAproval: 'needAprovalDarkMode',
    inspector: 'inspectorDarkMode',
    editFile: 'editFileDarkMode',
    paperShredder: 'paperShredderDarkMode',
    add: 'addDarkMode',
    paidPetitionFile: 'paidPetitionFileDarkMode',
    calendar: 'calendarDarkMode',
    rectifiedPetitionFile: 'rectifiedPetitionFileDarkMode',
    onSiteVisit: 'onSiteVisitDarkMode',
    jpg: 'jpgDarkMode',
    png: 'pngDarkMode',
    jpeg: 'jpegDarkMode',
    csv: 'csvDarkMode',
    xlsx: 'xlsxDarkMode',
    pdf: 'pdfDarkMode',
    ai: 'aiDarkMode',
    docx: 'docxDarkMode',
    zip: 'zipDarkMode',
    defaultFile: 'defaultFileDarkMode',
    deleteFile: 'deleteFileDarkMode',
    invoice: 'invoiceDarkMode',
    petitionFile: 'petitionFileDarkMode',
    serviceFile: 'serviceFileDarkMode',
    commercialInvoiceFile: 'commercialInvoiceFileDarkMode',
    validInvoices: 'validInvoicesDarkMode',
    cancelInvoices: 'cancelInvoicesDarkMode',
    plusWhite: 'plusWhiteDarkMode',
    signature: 'signatureDarkMode',
    changePassword: 'changePasswordDarkMode',
    inspectionList: 'inspectionListDarkMode',
    clock: 'clockDarkMode',
    magnifyingGlass: 'magnifyingGlassDarkMode',
    closed: 'closedDarkMode',
    mapDot: 'mapDotDarkMode',
    trash: 'trashDarkMode',
    clockDetailed: 'clockDetailedDarkMode',
    inReview: 'inReviewDarkMode',
    updateProduct: 'updateProductDarkMode',
    roundNotComplies: 'roundNotCompliesDarkMode',
    roundComplies: 'roundCompliesDarkMode',
    send: 'sendDarkMode',
    taskDocument: 'taskDocumentDarkMode',
    taskStatus: 'taskStatusDarkMode',
    inspectorGrey: 'inspectorGreyDarkMode',
    checkIn: 'checkInDarkMode',
    editService: 'editServiceDarkMode',
    home: 'homeDarkMode',
    order: 'orderDarkMode',
    filterActive: 'filterActiveDarkMode',
    filter: 'filterDarkMode',
    resetFilter: 'resetFilterDarkMode',
    SELogoDark: 'SELogoDarkMode',
    languaje: 'languaje',
    share: 'share',
    sound: 'sound',
    notSound: 'notSound',
    CCIcon: 'CCIcon',
    downloadWhite: 'download',
    rotateLeft: 'rotateLeftDarkMode',
    rotateRigth: 'rotateRigthDarkMode',
    zoomIn: 'zoomInDarkMode',
    zoomOut: 'zoomOutDarkMode',
    tour: 'tourDarkMode'
  };

  const setIcon = () => {
    if (themeMode === 'dark' && iconsWhitDarkMode[type]) {
      return iconsDarkMode[iconsWhitDarkMode[type]];
    }

    return icons[type];
  };

  return (
    <img
      className={className}
      src={setIcon()}
      alt={alt}
      onClick={onClick}
      role="presentation"
      id={id}
    />
  );
};

export default Icon;
