import React from 'react';

import './styles.scss';

interface Props {
  subTitle: string
  red?: boolean
}

const SubTitle: React.FC<Props> = (props) => {
  const {
    subTitle,
    red
  } = props;

  return (
    <p className={`sub-title ${red ? 'sub-title__red' : ''}`}>{subTitle}</p>
  );
};

export default SubTitle;
