/* eslint-disable no-undef */
/* eslint-disable max-len */

import { utils } from '../../../../../../helpers';

const list = {
  documentCode: 'F7-51 V1',
  documentDescription: 'Lista de inspección para la para la NOM-173-SE-2021',
  documentIssueDate: '01/04/2024',
  hasPackingTable: true,
  hasSamplingTable: true,
  hasMeasurementEquipment: true,
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          4. Especificaciones
          <br />
          4.1 Requisitos generales de etiquetado
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,

      text: (
        <p className="table-content__row__cell--text">
          4.1.1 La información contenida en las etiquetas de los alimentos y bebidas no alcohólicas preenvasados <b>debe ser veraz</b> y <b>describirse</b> y presentarse de forma tal que <b>no induzca a error al consumidor</b> con <b>respecto</b> a la <b>naturaleza</b> y características del producto
          <br />
          <br />
          4.1.3 Los alimentos y bebidas no alcohólicas preenvasados <b>no deberán describirse ni presentarse</b> con una etiqueta en los que se utilicen palabras, textos, diálogos, ilustraciones, imágenes, denominaciones de origen y otras descripciones <b>que se refieran</b> o <b>sugieran, directa</b> o <b>indirectamente</b> a <b>cualquier otro producto con el que pueda confundirse</b>, o que pueda inducir al consumidor a suponer que el alimento se relaciona en forma alguna con aquel otro producto.

          <br />
          <br />
          4.4 Información adicional
          En la etiqueta <b>puede presentarse cualquier información</b> o representación gráfica, así como materia escrita, impresa o gráfica, <b>siempre que no esté en contradicción</b> con los requisitos obligatorios de la presente Norma Oficial Mexicana, incluidos los referentes a la declaración de propiedades establecidos en el apartado 4.1.1.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Para evaluar este punto el inspector puede guiarse con las siguientes preguntas:
                <br />¿El producto corresponde a lo que se muestra en la etiqueta?
                <br />¿La denominación concuerda con lo que está en la lista de ingredientes?
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,

      text: (
        <p className="table-content__row__cell--text">
          4.1.2 Los productos preenvasados deben presentarse con una etiqueta en la que se describa o se empleen palabras, ilustraciones u otras representaciones gráficas que se refieran al producto.
          <br />
          <br />
          Las etiquetas que contengan los productos preenvasados <b>pueden</b> incorporar la <b>descripción gráfica</b> o descriptiva de la <b>sugerencia de uso, empleo o preparación</b>, a condición de que aparezca una leyenda alusiva al respecto.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.1.2.png" alt="4.1.2" />
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,

      text: (
        <p className="table-content__row__cell--text">
          4.2 Salvo lo indicado en el siguiente párrafo, son requisitos obligatorios de información comercial y sanitaria los siguientes.
          <br />
          <br />
          A menos de que se trate de especias y de hierbas aromáticas, los productos pequeños en que la superficie más amplia sea inferior a 10 cm2 podrán quedar exentos de los requisitos: lista de ingredientes, instrucciones de uso, lote y fecha de caducidad o de consumo preferente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.1 Nombre o denominación de los productos preenvasados</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      text: (
        <p className="table-content__row__cell--text">
          4.2.1.1. La denominación del producto preenvasado debe aparecer en <br />
          <b>1. Negrillas</b> <br />
          2. Dentro de la <b>superficie principal de exhibición</b> de la etiqueta, <br />
          3. En <b>línea paralela</b> a la base como se encuentra diseñado el producto y <br />
          4. cumpliendo con las disposiciones de denominación contenidas en una Norma Oficial Mexicana de producto preenvasado.
          <br /><br />
          <b>Junto a la denominación pueden adicionarse las palabras</b> o frases necesarias para evitar que se induzca al error o engaño al consumidor con respecto a la naturaleza y que incluyen, pero no se limitan a:
          <br /><br />
          a) el <b>tipo de medio de cobertura</b>;
          <br />b) la <b>forma</b> de <b>presentación</b> o su condición;
          <br />c) en el caso de que haya sido objeto de algún tipo de tratamiento, se puede indicar el nombre de éste, con excepción de aquellos que de acuerdo con los ordenamientos correspondientes sean de carácter obligatorio.
          <br /><br />
          4.2.1.1.1. La denominación del producto preenvasado debe corresponder a los <b>establecidos en las Normas Oficiales Mexicanas</b> u ordenamientos jurídicos específicos y en ausencia de éstos, se debe usar el siguiente orden de prelación para el nombre de una denominación de producto preenvasado:
          <br /><br />
          a) <b>Nombre de uso común</b>;
          <br /><br />
          b) <b>Descripción</b> de acuerdo con las <b>características básicas</b> de la <b>composición</b> y <b>naturaleza</b> del producto preenvasado,
          <br /><br />
          c) Norma internacional del Codex Alimentarius, en su caso.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de la denominación del producto
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-denominaci%C3%B3n-del-producto.png" alt="Ejemplo de la denominación del producto" />
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      text: (
        <p className="table-content__row__cell--text">
          Los elementos descritos anteriormente, forman parte de la denominación del producto preenvasado y deben describirse en forma conjunta, <b>con un tamaño igual o mayor al del dato cuantitativo del contenido neto</b> conforme a la <b>NOM-030-SCFI-2006</b> (ver 2.2 Referencias Normativas) <b>y con la misma proporcionalidad tipográfica</b>, para ser igualmente visibles en la etiqueta y cumplir con lo establecido en este numeral.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">

                    <tr>
                      <td colSpan={2}>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>SPE</td>
                      <td>Contenido Neto</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="question-7-cell-1">...</td>
                      <td className="cell-result" id="question-7-cell-2">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="question-7-cell-3">...</td>
                      <td className="cell-result" id="question-7-cell-4">...</td>
                    </tr>

                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>El tamaño mínimo para la denominación debe de ser de:</td>
                      <td className="cell-result" id="question-7-cell-5">...</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      text: (
        <p className="table-content__row__cell--text">
          Para el caso de los <b>productos imitación</b>, la denominación del mismo aparecerá en la parte <b>superior izquierda</b> de la superficie principal de exhibición, colocando la palabra IMITACIÓN al principio en mayúsculas, con negrillas en fondo claro en un tamaño del doble al resto de la denominación. No se permite el uso de la palabra imitación en productos preenvasados que cuenten con denominación de origen o indicación geográfica protegida o reconocida por el Estado mexicano.
          <br />
          <br />
          4.2.1.1.2. Los productos <b>imitación</b> no deben hacer uso de las palabras tales como “<b>tipo”</b>, “<b>estilo</b>” o <b>algún otro término similar</b>, en la denominación del producto preenvasado o dentro de la etiqueta.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Tamaño mínimo de letra para el resto de la denominación</td>
                      <td className="cell-result" id='question-8-cell-1'>...</td>
                    </tr>
                    <tr>
                      <td>Tamaño mínimo de letra para la palabra ‘IMITACION’</td>
                      <td className="cell-result" id='question-8-cell-2'>...</td>
                    </tr>
                  </table>
                </div>
                <br />
                Imagen reprtesentativa
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/imagen-representativa.png" alt="Imagen representativa" />
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.2 Lista de ingredientes</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1. En la etiqueta del producto preenvasado cuya comercialización se haga en forma individual, <b>debe figurar una lista de ingredientes</b>, salvo cuando se trate de alimentos de un único ingrediente y no incluya algún aditivo.
          <br />
          <br />
          4.2.2.1.1 La lista de <b>ingredientes</b> debe ir <b>encabezada</b> o precedida por el término <b>Ingredientes</b>:
          <br />
          <br />

          4.2.2.1.2 Los ingredientes del alimento o bebida no alcohólica preenvasado deben enumerarse por <b>orden cuantitativo decreciente</b> (m/m).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.3. Los <b>ingredientes compuestos</b> deben declararse como tal en la lista de ingredientes, siempre que vayan acompañados inmediatamente de una <b>lista entre paréntesis</b> de sus ingredientes por orden decreciente de proporciones (m/m).
          <br />
          <br />
          Cuando un <b>ingrediente compuesto</b>, constituya menos del 5 por ciento del producto preenvasado, no será necesario declarar los ingredientes que lo conforman, salvo los aditivos alimentarios que desempeñan una función tecnológica en el producto terminado, o aditivos e ingredientes que se asocien a reacciones alérgicas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.4. Se debe indicar en la lista de ingredientes el <b>agua añadida</b> por orden de predominio, excepto cuando ésta forme parte de un ingrediente compuesto, por ejemplo, de manera enunciativa más no limitativa: la salmuera, el jarabe o el caldo, empleados y declarado como tal en la lista y la que se utilice en los procesos de cocción y reconstitución. <b>No es necesario declarar el agua u otros ingredientes volátiles que se evaporan durante la fabricación.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.5 Cuando se trate de alimentos <b>deshidratados o condensados, destinados a ser reconstituidos</b>, pueden enumerarse sus ingredientes por orden cuantitativo decreciente (m/m) en el producto reconstituido, siempre que se incluya una indicación como la que sigue: <b>"ingredientes del producto cuando se prepara según las instrucciones de la etiqueta"</b>, u otras leyendas análogas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.6 En la lista de ingredientes <b>debe emplearse una denominación específica</b> de acuerdo con lo previsto en el punto 4.2.2.1, incluyendo los ingredientes mencionados en 4.2.2.2.3, con <b>excepción</b> de los ingredientes cuyas denominaciones genéricas están señalados en la tabla 1.  <b>Revisar Tabla 1 Denominación genérica de ingredientes</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__text">
              <td colSpan={2}>Tabla 1 Denominación genérica de ingredientes</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Clase de ingrediente</b></td>
              <td><b>Denominación genérica</b></td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Aceites refinados distintos del aceite de oliva</td>
              <td>Aceite seguido del término vegetal o animal, calificado con el término hidrogenado o parcialmente hidrogenado, según sea el caso.</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Grasas refinadas</td>
              <td>Grasas seguido del término vegetal o animal o mixta o compuesta, calificado con el término hidrogenado, según sea el caso. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Almidones</b>, distintos a los almidones modificados químicamente. </td>
              <td>Almidón </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Todas las especies de pescado</b>, cuando éste constituya un ingrediente de otro alimento y siempre que en la etiqueta y presentación del producto preenvasado no se haga referencia a una determinada especie de pescado. </td>
              <td>Pescado </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Todos los tipos de carne de aves de corral</b>, cuando dicha carne constituya un ingrediente de otro alimento y siempre que en la etiqueta y la presentación del producto preenvasado no se haga referencia a un tipo específico de carne de aves de corral. </td>
              <td>Carne de ave  </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Todos los tipos de quesos</b>, cuando el queso o una mezcla de quesos constituya un ingrediente de otro alimento y siempre que en la etiqueta y la presentación del producto preenvasado no se haga referencia a un tipo específico de queso. </td>
              <td>Queso o quesos o mezcla de quesos, según sea el caso. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Todas las especias</b> y extractos de especias en cantidad no superior al 2% en peso, solas o mezcladas en el producto preenvasado. </td>
              <td>Especia, especias o mezclas de especias según sea el caso. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todas las <b>hierbas aromáticas</b> o partes de hierbas aromáticas en cantidad no superior al 2% en peso, solas o mezcladas en el producto preenvasado. </td>
              <td>Hierba aromática, Hierbas aromáticas o mezclas de hierbas aromáticas, según sea el caso. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todos los tipos de <b>preparados de goma</b> utilizados en la fabricación de la goma base para el producto goma de mascar. </td>
              <td>Goma de base o Goma base </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todos los tipos de <b>sacarosa</b>  </td>
              <td>Azúcar</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>La <b>dextrosa anhidra</b> y la dextrosa monohidratada </td>
              <td>Dextrosa o Glucosa </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todos los tipos de <b>caseinatos</b> </td>
              <td>Caseinatos</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Productos lácteos</b> que contienen un mínimo de 50% de proteína láctea (m/m) en el extracto seco</td>
              <td>Proteína láctea</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Manteca de cacao</b> obtenida por presión o extracción o refinada.</td>
              <td>Manteca de cacao</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todas las <b>frutas confitadas</b>, sin exceder del 10% del peso del producto preenvasado.</td>
              <td>Frutas confitadas</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todos los <b>condimentos</b> en cantidad no superior al 2% en peso, solos o mezclados en el producto preenvasado.</td>
              <td>Condimento, Condimentos o Mezcla de condimentos, según sea el caso.</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todos los tipos de <b>microorganismos</b> utilizados en la fermentación de lactosa, siempre que en la etiqueta y la presentación de dicho producto no se haga referencia a un tipo específico de microorganismo.</td>
              <td>Cultivo láctico o cultivos lácticos</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Suero de mantequilla</b>, suero de queso, suero de leche, concentrado de proteína de leche, proteína de suero o lactosa</td>
              <td>Sólidos de la leche</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Todas las especies de <b>chile o mezclas</b></td>
              <td>Chile o Chiles o Mezcla de Chiles</td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.7 No obstante lo estipulado en el punto anterior, la <b>manteca de cerdo</b>, la <b>grasa de bovino</b> o <b>sebo</b>, se deben declarar siempre por sus denominaciones específicas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.1.8 Los <b>azúcares añadidos</b> se deben declarar conforme a lo siguiente:
          <br />a) agrupados <b>anteponiendo</b> las palabras “<b>azúcares añadidos</b>” seguido de la <b>lista entre paréntesis</b> con las denominaciones específicas de todos los azúcares libres añadidos presentes en el producto preenvasado, excepto de aquellos que formen parte de un ingrediente compuesto, en caso de existir;
          <br />b) en orden cuantitativo decreciente m/m según corresponda a la suma de todos los azúcares añadidos considerados en el inciso a), y
          <br />c) cuando existan ingredientes compuestos en los que formen parte varios azúcares añadidos, éstos también deben agruparse dentro del mismo, conforme a lo establecido en los incisos a) y b),
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Los principales azucares añadidos son</b>: Azúcar, maltosa, melaza, azúcar moreno, fructosa, dextrosa, cualquier jugo de frutas o de verduras, miel, extractos de frutas, trehalosa
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                En caso de que solamente tenga 1 azúcar añadido, deberá de colocarse ‘azucares añadidos (azúcar)’
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Todos los azucares añadidos deberán de agruparse en un paréntesis, a menos de que formen parte de un ingrediente compuesto.  Ejemplo.

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Lista original</td>
                      <td>Lista modificada</td>
                    </tr>
                    <tr>
                      <td>Ingredientes: Agua, dextrosa, harina de trigo, miel, mezcla sabor chocolate (cacao, azúcar, manteca de cacao) </td>
                      <td>Ingredientes: Agua, azucares añadidos (dextrosa, miel), harina de trigo, mezcla sabor chocolate (cacao, azucares añadidos (azúcar), manteca de cacao))</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.2.2 Coadyuvantes de elaboración y transferencia de aditivos</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.2.1 Debe ser incluido en la lista de ingredientes todo aditivo que haya sido empleado en los ingredientes de un alimento o bebida no alcohólica preenvasado y que se transfiera a otro producto preenvasado en cantidad notable o suficiente para desempeñar en él una función tecnológica.
          <br />
          <br />
          4.2.2.2.4. En la <b>declaración de aditivos</b> utilizados en la producción de productos preenvasados, <b>debe utilizarse el nombre común o</b> en su defecto, alguno de los <b>sinónimos</b> establecidos en el Acuerdo.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Deberán de buscarse que todo aditivo</b> se encuentre correctamente escrito de acuerdo con el ACUERDO por el que se determinan los aditivos y coadyuvantes en alimentos, bebidas y suplementos alimenticios, su uso y disposiciones sanitarias, ya sea en su forma <b>genérica</b> o <b>especifica</b>.

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Los aditivos se encuentran correctamente escritos?</td>
                      <td>
                        <p id='question-17-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-17-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="No aplica">No aplica</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b> 4.2.2.2.2 Están exentos de su declaración en la lista de ingredientes los aditivos transferidos a los alimentos y bebidas no alcohólicas preenvasados que ya no cumplen una función tecnológica en el producto terminado, así como los coadyuvantes de elaboración, excepto aquellos que puedan causar hipersensibilidad</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes
          <br />a) Los siguientes alimentos e ingredientes pueden <b>causar hipersensibilidad</b> y deben declararse siempre:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      text: (
        <p className="table-content__row__cell--text">
          -Cereales que contienen gluten (<b>trigo, centeno, avena, cebada, espelta o sus cepas híbridas</b>, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Huevos</b>, sus productos y sus derivados.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Crustáceos</b> y sus productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Pescado</b> y sus productos. Se exceptúan: gelatina de pescado utilizada como soporte de vitaminas, aromatizantes o preparados de carotenoides.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Moluscos</b> y sus productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Cacahuate</b> y sus productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Soya</b> y sus productos. Se exceptúan: aceite y grasa de soya totalmente refinados; tocoferoles naturales mezclados, d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soya; fitoesteroles y ésteres de fitoesteroles derivados de aceites vegetales de soya; ésteres de fitoestanol derivados de fitoesteroles de aceite de soya.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Leche</b>, productos de la leche y derivados lácteos (lactosa incluida). Se exceptúa el lactitol.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      text: (
        <p className="table-content__row__cell--text">
          -<b>Nueces</b> de árboles y sus productos derivados, tales como las almendras (Prunus amygdalus) y nueces (especies del género Juglans), pero se aplica de modo general a todas las nueces producidas por árboles, incluidas las avellanas (Corylus spp.), pecanas (Carya illinoensis), nuez del Brasil (Bertholletia excelsa), nuez de la india (Anacardium occidentale), castañas (Castanae spp.), nuez de macadamia (Macadamia spp.).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      text: (
        <p className="table-content__row__cell--text">
          <b>Sulfito</b> en concentraciones de 10 mg/kg o más.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      text: (
        <p className="table-content__row__cell--text">
          b) Cuando el alimento, ingrediente o derivado sea o contenga alguno de los causantes de <b>hipersensibilidad</b> (alérgenos alimentarios) reconocidos en el listado correspondiente, el o los alérgenos deberán declararse al final de la lista de ingredientes.
          <br />
          <br />
          i) con letra en negrillas de igual o mayor tamaño a las letras de los ingredientes generales;
          <br />ii) anteponiendo la palabra bajo el título “<b>Contiene”</b>, y
          <br />iii) si el ingrediente es un derivado que contiene albúmina, caseína o gluten puede rotularse declarando su
          origen, como el ejemplo siguiente: contiene: caseína (leche) o caseína de leche.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Deberá de declararse al final de la lista de ingredientes
                <br />
                Ingredientes: Pan rallado (harina enriquecida (harina de trigo, cebada malteada, niacina, sulfato ferroso, mononitrato de tiamina, riboflavina, ácido fólico))
                <br /><b>Contiene: Trigo y cebada.</b>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Todos los alergenos se encuentran en la lista de ‘<b>Contiene</b>:’</td>
                      <td>
                        <p id='question-30-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-30-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                          <option value="No aplica">No aplica</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      text: (
        <p className="table-content__row__cell--text">
          c) <b>Si existe la posibilidad de contaminación</b> durante el proceso de producción o elaboración hasta el envasado, por parte del fabricante, se deberá incluir al final de la lista de ingredientes, la siguiente frase: "<b>Puede contener</b>", con letra en negrillas, de igual o mayor tamaño a las letras de los ingredientes generales, indicando el alérgeno de que se trate.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Deberá de declararse al final de la lista de ingredientes
                <br />
                Ingredientes: Pan rallado (harina enriquecida (harina de trigo, cebada malteada, niacina, sulfato ferroso, mononitrato de tiamina, riboflavina, ácido fólico))
                <br /><b>Contiene: Trigo y cebada.</b>
                <br /><b>Puede contener: Cacahuate..</b>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      text: (
        <p className="table-content__row__cell--text">
          Las <b>enzimas y saborizantes</b>, saboreador o aromatizantes pueden ser declarados como denominaciones genéricas, excepto la cafeína, la cual debe ser declarada de forma específica.
          <br />
          <br />
          Los saborizantes, saboreadores o aromatizantes pueden estar calificados con los términos "<b>natural</b>", "<b>idéntico al natural</b>", "<b>artificial</b>" o con una combinación de los mismos según corresponda, a menos que se destaque su presencia de alguna manera, lo cual obliga a la declaración con el término específico.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.2.3. Etiquetado cuantitativo de los ingredientes</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 34,
      text: (
        <p className="table-content__row__cell--text">
          4.2.2.3.1 En todo alimento o bebida no alcohólica preenvasado que se <b>venda como mezcla o combinación</b>, se declarará el porcentaje del ingrediente, con respecto al peso o al volumen que corresponda del ingrediente, al momento de la elaboración del alimento (incluyendo los ingredientes compuestos o categorías de ingredientes), cuando este ingrediente:
          <br />
          a) <b>se enfatiza en la etiqueta como presente</b>, por medio de <b>palabras</b> o <b>imágenes</b> o <b>gráficos</b>; o
          <br />b) no figura en el nombre o denominación del alimento o bebida no alcohólica preenvasados y es esencial para caracterizar al mismo, ya que los consumidores asumen su presencia en el producto preenvasado y la omisión de la declaración cuantitativa de ingredientes engaña o lleva a error al consumidor.
          <br /><br />
          4.2.2.3.2 La información requerida en el numeral 4.2.2.3.1 será declarada en la etiqueta del producto <b>como un porcentaje numérico</b>.
          <br /><br />
          El porcentaje del ingrediente, por peso o volumen, según corresponda, se declarará en la etiqueta muy cerca de las palabras o imágenes o gráficos que enfaticen el ingrediente particular, o al lado del nombre común o denominación del alimento o bebida no alcohólica preenvasado, o adyacente al ingrediente que corresponda en la lista de ingredientes. <b>Se declarará como un porcentaje</b> mínimo cuando el énfasis sea sobre la presencia del ingrediente, y como un porcentaje máximo cuando el énfasis sea sobre el bajo nivel del ingrediente.
          <br /><br />
          Para alimentos que han perdido humedad luego de un tratamiento térmico u otro tratamiento, el porcentaje (con respecto al peso o al volumen) corresponderá a la cantidad del ingrediente o ingredientes usados, en relación con el producto terminado.
          <br /><br />
          <b>Tales declaraciones no se requieren cuando</b>:
          <br />a) <b>El ingrediente es utilizado en pequeñas cantidades con el propósito de impartir sabor y/o aroma</b>; Respecto al numeral 4.2.2.3.1 (a):
          <br />b) La referencia en el nombre del alimento, ingrediente o categoría de ingredientes enfatizados en la etiqueta no requiera una declaración cuantitativa si es que:
          <br />La referencia al ingrediente enfatizado no conduce a error o engaño, o crea una impresión errónea en el consumidor respecto a la naturaleza del alimento o bebida no alcohólica preenvasado, porque la variación en la cantidad del ingrediente o ingredientes entre productos no es necesaria para caracterizar al mismo o distinguirlo de similares.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo ilustrativo
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>DENOMINACION</td>
                      <td>LISTA DE INGREDIENTES</td>
                    </tr>
                    <tr>
                      <td><img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/denominacion-1.png" /></td>
                      <td><img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ingredientes-1.png" /></td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.2.3.1.png" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 35,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.3 Contenido neto y masa drenada</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 36,
      text: (
        <p className="table-content__row__cell--text">
          4.2.3.1 <b>Debe declararse</b> el <b>contenido neto</b> y <b>cuando aplique</b>, la <b>masa drenada</b> en unidades del Sistema General de Unidades de Medida de conformidad a lo que establece la NOM-030-SCFI-2006, independientemente de que también pueda expresarse en otras unidades (véase Referencias).
          <br />
          <br />
          NOM-030-SCFI-2006
          <br />4.1.4 A las leyendas CONTENIDO, CONTENIDO NETO o sus abreviaturas CONT., CONT. NET. y CONT. NETO no se les aplican las especificaciones de las tablas 1 y 2 y pueden ser escritas con letras mayúsculas y/o minúsculas.

          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">

                    <tr>
                      <td colSpan={2}>Medidas mínimas</td>
                    </tr>
                    <tr>
                      <td>SPE</td>
                      <td>Contenido Neto</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id='question-45-cell-1'>...</td>
                      <td className="cell-result" id='question-45-cell-2'>...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id='question-45-cell-3'>...</td>
                      <td className="cell-result" id='question-45-cell-4'>...</td>
                    </tr>

                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>El tamaño mínimo para el contenido neto debe de ser de:</td>
                      <td className="cell-result" id='question-45-cell-5'>...</td>
                    </tr>
                  </table>
                </div>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.3.1.png" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 37,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.4 Nombre, denominación o razón social y domicilio fiscal.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 38,
      text: (
        <p className="table-content__row__cell--text">
          4.2.4.1. En un producto preenvasado, debe indicarse en la etiqueta el nombre,
          <br /><br />
          • Razón social y
          <br />• Domicilio fiscal del responsable del producto de manera enunciativa mas no limitativa:
          <br /><b>◦ Calle</b>,
          <br /><b> ◦ Número</b>,
          <br /><b>◦ Código postal y</b>
          <br /><b>◦ Entidad federativa en que se encuentre.</b>
          <br /><br />
          En el caso de los productos importados, el nombre y domicilio del importador, en ambos casos, puede incluirse la expresión “fabricado o envasado por o para”, seguido por el nombre y domicilio según corresponda.
          <br /><br />
          4.2.4.2. Para productos preenvasados importados debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto. Esta información puede incorporarse al producto preenvasado en territorio nacional, después del despacho aduanero y antes de la comercialización del producto.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Razón Social</td>
                      <td>Dirección Fiscal</td>
                    </tr>
                    <tr>
                      <td id='businessName-item'></td>
                      <td id='fiscalAddress-item'></td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable en la etiqueta con lo de arriba?</td>
                      <td className="cell-result">
                        <p id='question-38-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-38-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 39,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.6 País de origen</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 40,
      text: (
        <p className="table-content__row__cell--text">
          4.2.5.1 Los alimentos y bebidas no alcohólicas preenvasados nacionales o de procedencia extranjera deben incorporar la leyenda que identifique el país de origen de los productos, por ejemplo: <b>“Hecho en…”; “Producto de …”; “Fabricado en …”,</b> u otras análogas, seguida del país de origen del producto, sujeto a lo dispuesto en los tratados internacionales de que los Estados Unidos Mexicanos sean parte. Se permite el uso de gentilicios y otros términos análogos, siempre y cuando sean precisos y no induzcan a error en cuanto al origen del producto. Por ejemplo: “Producto español”, “Producto estadounidense”, entre otros.

        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 41,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.6 Identificación del lote</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 42,
      text: (
        <p className="table-content__row__cell--text">
          4.2.6.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
          <br />
          <br />
          4.2.6.2 La identificación del lote que incorpore el fabricante en el producto preenvasado debe marcarse en forma indeleble y permanente, y no debe ser alterada u ocultada de forma alguna hasta que sea adquirido por el consumidor.
          <br /><br />
          4.2.6.3 La clave del lote debe ser precedida por cualquiera de las siguientes indicaciones: “LOTE”, “Lot”, “L”, “Lote”, “lote”, “lot”, “l”, “lt”, “LT”, “LOT”, o <b>bien incluir una referencia al lugar donde aparece.</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>CONSTANCIA</td>
              <td>DICTAMEN</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Se considera suficiente que contenga la leyenda ‘Lote: Impreso en el envase’</td>
              <td>Debe de tener el número de lote ya sea impreso o sobre una etiqueta</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.6.1-constancy.png" />
              </td>
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.6.1-dictum.png" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 43,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.7 Fecha de caducidad o de consumo preferente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 44,
      text: (
        <p className="table-content__row__cell--text">
          4.2.7.1 Si no está determinado de otra manera en una Norma Oficial Mexicana la fecha de caducidad o la fecha de consumo preferente deberá cumplir con lo siguiente:
          <br /><br />
          i) El fabricante debe declararla en el envase o etiqueta, la cual debe consistir por lo menos de:
          <br />- El <b>día y el mes</b> para los productos de duración máxima de tres meses;
          <br />- El <b>mes y el año</b> para productos de duración superior a tres meses.
          <br /><br />
          <b>ii) La fecha debe estar precedida por una leyenda que especifique que dicha fecha se refiere a la fecha de caducidad o al consumo preferente.</b>
          <br /><br />
          - Para el caso de fecha de caducidad, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
          “Fecha de caducidad ___”, “Caducidad ____”, “Fech Cad ____”, CAD, Cad, cad, Fecha de expiración,
          Expira, Exp, EXP, exp, Fecha de vencimiento, Vencimiento.
          <br />- Para el caso de consumo preferente, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
          “Consumir preferentemente antes del____”, “Cons. Pref. antes del ___”. y “Cons Pref”.
          <br /><br />
          iii) Las palabras prescritas en el apartado ii) deberán ir acompañadas de:
          <br />• la fecha misma; o
          <br />• una referencia al lugar donde aparece la fecha.
          <br />Tratándose de productos de importación, cuando el codificado de la fecha de caducidad o de consumo preferente no corresponda al formato establecido en el numeral 4.2.7.1 inciso i), éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En ninguno de estos casos los ajustes serán considerados como alteración.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>CONSTANCIA</td>
              <td>DICTAMEN</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Se considera suficiente que contenga la leyenda ‘Fecha de caducidad impresa en el envase’</td>
              <td>Debe de tener la fecha de caducidad ya sea impresa o sobre una etiqueta</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.7.1-constancy.png" />
              </td>
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.2.7.1-dictum.png" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 45,
      text: (
        <p className="table-content__row__cell--text">
          4.2.7.2 Al declarar la fecha de caducidad o de consumo preferente se debe indicar en la etiqueta cualquiera de las <b>condiciones especiales que se requieran para la conservación</b> del alimento o bebida no alcohólica preenvasado, si de su cumplimiento depende la validez de la fecha.
          <br />
          <br />
          Por ejemplo, se pueden incluir leyendas como: "manténgase en refrigeración"; "consérvese en congelación"; "una vez descongelado no deberá volverse a congelar"; "una vez abierto, consérvese en refrigeración", u otras análogas.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={3}>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td colSpan={3}>Se sugiere que se agreguen estas leyendas por tipo de producto</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Para un producto seco</td>
              <td>Para un producto refrigerado</td>
              <td>Para un producto congelado</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Mantener en un lugar fresco y seco</td>
              <td>Mantener en refrigeración a _°C</td>
              <td>Mantener en congelación a _°C</td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 46,
      text: (
        <p className="table-content__row__cell--text">
          4.2.7.3 La <b>fecha de caducidad</b> o de consumo preferente que incorpore el fabricante en el producto preenvasado <b>no puede ser alterada</b> en ningún caso y bajo ninguna circunstancia.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 47,
      text: (
        <p className="table-content__row__cell--text">
          4.2.7.4 No se requerirá la declaración de fecha de caducidad o consumo preferente, para:
          <br />● Vinagre;
          <br />● Sal de calidad alimentaria;
          <br />● Azúcar sólido;
          <br />● Productos de confitería consistentes en azúcares aromatizados y/o coloreados;
          <br />● Goma de mascar.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 48,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2.8 Productos preenvasados con Norma Oficial Mexicana</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 49,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          4.2.8.1. Los productos preenvasados deben exhibir la contraseña oficial cuando así lo determine la Norma Oficial Mexicana que regule su denominación o la Ley Federal sobre Metrología y Normalización, lo que se hará considerando lo establecido en el numeral 4.2.8.3 de esta Norma Oficial Mexicana y de conformidad a lo establecido en la NOM-106-SCFI-2017 (ver 2.4 Referencias Normativas).
          <br /><br />
          4.2.8.2. Los productos preenvasados, cuya presentación individual indique la leyenda de “No etiquetado para su venta individual” o similar, y requieran usar la contraseña oficial en términos del numeral 4.2.8.1, lo debe hacer únicamente en el empaque múltiple o colectivo.
          <br /><br />
          4.2.8.3. Los productos preenvasados que ostenten la contraseña oficial incluida en un producto preenvasado conforme al numeral 4.2.8.1 deben incluir, ya sea debajo de la contraseña oficial o del lado derecho de la misma, los tres dígitos correspondientes a la clave o código de la norma oficial mexicana específica para la denominación de producto, con la misma proporcionalidad y tipografía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 50,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5. Etiquetado nutrimental</b>
          <br />4.5.1 Componentes
          <br />El etiquetado nutrimental es obligatorio en la etiqueta de los productos preenvasados, y comprende la declaración nutrimental y la información nutrimental complementaria.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 51,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.3 Quedan exceptuados de incluir la <b>declaración nutrimental</b> los productos siguientes, <b>siempre y cuando no incluyan alguna declaración de propiedades nutrimentales o saludables:</b>
          <br /><br />
          i. Productos que incluyan un <b>solo ingrediente</b>;
          <br />ii. <b>Hierbas</b>, especias o mezcla de ellas;
          <br />iii. Extractos de café, granos de <b>café</b> enteros o molidos descafeinados o no y que no contengan ingredientes añadidos diferentes a aromas;
          <br />iv. <b>Infusiones</b> de hierbas, té descafeinado o no, instantáneo y/o soluble que no contengan ingredientes añadidos;
          <br />v. <b>Vinagres</b> fermentados y sucedáneos;
          <br />vi. <b>Agua</b> para consumo humano y agua mineral natural; y
          <br />vii. Los productos en que la <b>superficie más amplia sea inferior a 78 centímetros cuadrados</b>, siempre que incluyan un número telefónico o página Web en la que el consumidor pueda obtener información sobre la declaración nutrimental. Por ejemplo, “Para información sobre declaración nutrimental llame, 800-123-4567”, “<b>Declaración nutrimental disponible en (indicar página Web o número telefónico de atención a clientes)</b> o leyendas análogas". En este caso, los productos no deben incluir alguna declaración de propiedades en el producto mismo, su etiqueta o su publicidad.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 52,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.2 Declaración nutrimental</b>
          <br />
          Se deben declarar los nutrimentos siguientes, excepto en el producto preenvasado regulado por otros ordenamientos jurídicos aplicables:
          <br /><br />
          <b>a) el contenido de energía;</b>
          <br />b) la cantidad de proteína;
          <br />c) la cantidad de hidratos de carbono disponibles, indicando la cantidad correspondiente a azúcares y a <b>azúcares añadidos</b>.
          <br />d) la cantidad de grasas especificando la cantidad que corresponda a <b>grasas saturadas</b> y a <b>grasas trans</b>, no incluyendo las grasas trans presentes en ingredientes lácteos y cárnicos de manera natural.
          <br />e) la cantidad de fibra dietética;
          <br />f) la cantidad de <b>sodio</b>;
          <br />g) la cantidad de cualquier otro nutrimento acerca del cual se haga una declaración de propiedades;
          <br />h) la cantidad de cualquier otro nutrimento que se considere importante, regulado por los ordenamientos jurídicos aplicables.
          <br /><br />
          4.5.2.4.2 La declaración del <b>Contenido energético</b> (Calorías) <b>debe expresarse en kcal (kJ)</b> por 100 g, o por 100 ml,
          <br />-así como por el contenido total del envase kcal (kJ) Adicionalmente se puede declarar por porción.
          <br /><br />
          4.5.2.4.3 La declaración sobre la cantidad de, <b>Proteínas, de hidratos de carbono disponibles</b>, de <b>grasas</b>, de <b>fibra dietética</b> y de <b>sodio</b> que contienen los alimentos y las bebidas no alcohólicas preenvasados debe expresarse en unidades de <b>medida por 100 g o por 100 mL</b>.
          <br /><br />
          Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          <br /><br />
          4.5.2.4.4 La declaración numérica sobre: <b>Vitaminas y Nutrimentos</b> inorgánicos (minerales) debe expresarse en unidades de medida por <b>100 g o por 100 mL</b> o en porcentaje de los valores nutrimentales de referencia por porción.
          <br /><br />
          Adicionalmente se puede declarar por <b>porción en envases que contengan varias porciones</b>, o por envase cuando éste contiene sólo una porción.
          <br /><br />
          4.5.2.4.7 La declaración nutrimental puede presentarse de la siguiente manera o en cualquier otro formato que contenga la información requerida conforme lo indicado en la tabla 3:
          <br /><br />
          <b>y destacarse en negrillas</b>
          <br /><br />
          <b>• la declaración y la cantidad el contenido energético por 100g</b>
          <br /><b>• la declaración y la cantidad el contenido energético por envase</b>
          <br /><b>• la cantidad de grasa saturada,</b>
          <br /><b>• la cantidad de grasas trans y</b>
          <br /><b>• la cantidad de azúcares añadidos,</b>
          <br /><b>• la cantidad de sodio.</b>
          <br /><br />
          4.5.2.4.1 La declaración nutrimental debe hacerse en las unidades que correspondan al <b>Sistema General de Unidades de Medida</b> NOM-008-SCFI-2002, citada en el capítulo de referencias. Adicionalmente, se pueden emplear otras unidades de medidas. Tratándose de vitaminas y de nutrimentos inorgánicos (minerales), éstos se deben sujetar a lo establecido en el inciso 4.5.2.4.5.
          <br /><br />
          4.5.2.4.12 Se puede señalar el <b>número de porciones</b> contenidas en el envase, usando el término “<b>aproximadamente</b>” o “<b>aprox</b>”.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 53,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.4.8 La <b>declaración del contenido de vitaminas y de nutrimentos</b> inorgánicos (minerales) es opcional, excepto en los alimentos y en las bebidas no alcohólicas modificados en su composición, debiendo cumplir con la NOM-086-SSA1-1996 (Ver referencias).
          <br /><br />
          4.5.2.4.9 La inclusión de uno de los siguientes nutrimentos no obliga a incluir uno de los otros y sólo se realiza si se tiene asignado un VNR y el contenido de la porción sea igual o esté por arriba del 5% del VNR referido (ya sea IDR o IDS).
          <br /><br />
          Vitamina A (% VNR), Vitamina E (% VNR), Vitamina C (% VNR), Vitamina B1 (% VNR), Vitamina B2 (% VNR), Vitamina B6 (% VNR), Vitamina B12 (% VNR), Vitamina D (% VNR), Vitamina K (% VNR), Ácido pantoténico (% VNR), Ácido fólico (% VNR), Niacina (% VNR), Calcio (% VNR), Fósforo (% VNR), Magnesio (% VNR), Hierro (% VNR), Zinc (% VNR), Yodo (% VNR), Cobre (% VNR), Cromo (% VNR), Flúor (% VNR),
          <br />Selenio (% VNR).
          <br /><br />
          4.5.2.4.5 Para estos casos, se debe emplear la siguiente tabla de ingestión diaria sugerida e ingestión
          diaria recomendada, para la población mexicana, según corresponda.
          <br />
          <table className="table-content__inspector-table__table">
            <tr>
              <td rowSpan={2}>NUTRIMENTO</td>
              <td rowSpan={2}>UNIDAD</td>
              <td colSpan={2}>VNR</td>
            </tr>
            <tr>
              <td>IDR</td>
              <td>IDS</td>
            </tr>
            <tr>
              <td>Ácido fólico</td>
              <td>μg</td>
              <td></td>
              <td>380</td>
            </tr>
            <tr>
              <td>Acido pantoténico</td>
              <td>mg</td>
              <td></td>
              <td>4</td>
            </tr>
            <tr>
              <td>Calcio</td>
              <td>mg</td>
              <td></td>
              <td>900</td>
            </tr>
            <tr>
              <td>Cobre</td>
              <td>μg</td>
              <td></td>
              <td>650</td>
            </tr>
            <tr>
              <td>Cromo</td>
              <td>μg</td>
              <td></td>
              <td>22</td>
            </tr>
            <tr>
              <td>Flúor</td>
              <td>mg</td>
              <td></td>
              <td>2.2</td>
            </tr>
            <tr>
              <td>Fósforo</td>
              <td>mg</td>
              <td>664</td>
              <td></td>
            </tr>
            <tr>
              <td>Hierro</td>
              <td>mg</td>
              <td></td>
              <td>17</td>
            </tr>
            <tr>
              <td>Magnesio</td>
              <td>mg</td>
              <td></td>
              <td>248</td>
            </tr>
            <tr>
              <td>Nacina</td>
              <td>mg</td>
              <td></td>
              <td>11</td>
            </tr>
            <tr>
              <td>Potasio</td>
              <td>mg</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Selenio</td>
              <td>μg</td>
              <td></td>
              <td>41</td>
            </tr>
            <tr>
              <td>Vitamina A (equivalentes de retinol)</td>
              <td>μg</td>
              <td></td>
              <td>568</td>
            </tr>
            <tr>
              <td>Vitamina B1</td>
              <td>μg</td>
              <td></td>
              <td>800</td>
            </tr>
            <tr>
              <td>Vitamina B12</td>
              <td>μg</td>
              <td></td>
              <td>2.1</td>
            </tr>
            <tr>
              <td>Vitamina B2</td>
              <td>μg</td>
              <td></td>
              <td>840</td>
            </tr>
            <tr>
              <td>Vitamina B6</td>
              <td>μg</td>
              <td></td>
              <td>930</td>
            </tr>
            <tr>
              <td>Vitamina C</td>
              <td>mg</td>
              <td>60</td>
              <td></td>
            </tr>
            <tr>
              <td>Vitamina D (como colecalciferol) </td>
              <td>μg</td>
              <td></td>
              <td>10</td>
            </tr>
            <tr>
              <td>Vitamina E (equivalente a tocoferol) </td>
              <td>mg</td>
              <td></td>
              <td>11</td>
            </tr>
            <tr>
              <td>Vitamina K</td>
              <td>μg</td>
              <td></td>
              <td>78</td>
            </tr>
            <tr>
              <td>Yodo</td>
              <td>μg</td>
              <td></td>
              <td>150</td>
            </tr>
            <tr>
              <td>Zinc</td>
              <td>mg</td>
              <td></td>
              <td>10</td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 54,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.1 Cuando se haga una declaración específica de propiedades referente a la cantidad o tipo de <b>hidrato de carbono</b>, pueden indicarse también las cantidades de almidón y, o en su caso, de otros tipos
          de hidratos de carbono.
          <br />
          <br />
          4.5.2.4.11 La inclusión de uno de los siguientes no obliga a incluir a los otros
          <br /><b>Almidones</b> ___ g; <b>polialcoholes</b> ___ g; <b>polidextrosas</b> ___ g.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 55,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.2 Cuando se haga una declaración de propiedades con respecto a la cantidad o al tipo de grasas o la cantidad de <b>colesterol</b> deben declararse las cantidades de: <b>grasas monoinsaturadas, grasas poliinsaturadas y colesterol</b>.
          <br /><br />
          4.5.2.4.10 Todos o ninguno de los siguientes
          <br />Grasa poliinsaturada ___ g; grasa monoinsaturada __ g; colesterol ___ mg.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 56,
      text: (
        <p className="table-content__row__cell--text">
          <b>5. Cálculos
            <br />5.1 Cálculo de nutrimentos</b>
          <br />5.1.1. Cálculos de energía
          <br />La cantidad de energía que debe declararse debe calcularse utilizando los siguientes factores de conversión:
          <br />Hidratos de carbono disponibles 4 kcal/g-17 kJ/g
          <br />Proteínas 4 kcal/g-17 kJ/g
          <br />Grasas 9 kcal/g-37 kJ/g
          <br />Alcohol (etanol) 7 kcal/g-29 kJ/g
          <br />Polioles (*) 2.4 kcal/g-10 kJ/g (sorbitol, xilitol, maltitol, isomalt, isomaltitol, lactitol, manitol)
          <br />Eritritol (*) 0 kcal/g-0 kJ/g
          <br />Alulosa (*) 0 kcal/g-0 kJ/g
          <br />Tagatosa (*) 1.5 kcal/g-6.276 kJ/g
          <br />(*) Cuando se hace un cálculo teórico del contenido energético se deben usar los factores de conversión específicos para polioles, eritritol, tagatosa y alulosa y no calcularse dentro de los hidratos de carbono disponibles
          <br />
          <br />
          <b>4.3 Instrucciones para el uso</b>
          La etiqueta debe contener las <b>instrucciones de uso</b> cuando sean necesarias sobre el modo de empleo, incluida la reconstitución, si es el caso, para asegurar una correcta utilización del producto preenvasado.
          <br /><br />
          4.5.2.4.6 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la <b>declaración nutrimental</b> debe realizarse de acuerdo con las instrucciones para el uso indicadas en la etiqueta.
          <table className="table-content__inspector-table" id='nutrimental-declaration-table'>

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width text-sm">
                    <tr id='alert-product-type'></tr>
                    <tr>
                      <td><b>INGRESA LOS DATOS</b></td>
                      <td><b>DECLARACIÓN NUTRIMENTAL</b></td>
                    </tr>
                    <tr>
                      <td className="width-50">
                        <div className="full-width">
                          <table className="table-content__inspector-table__table full-width">
                            <tr>
                              <td>¿El producto requiere preparación?</td>
                              <td>
                                <select defaultValue='' onChange={() => utils.calculateNOM51()} id='require-preparation' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                                  <option value="">Selecciona...</option>
                                  <option value="SI">SI</option>
                                  <option value="NO">NO</option>
                                </select>
                              </td>
                            </tr>
                            <tr>
                              <td>Total, de la mezcla</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id='total-mix'
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Porción del producto</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="product-portion"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                        <br />

                        <div className="full-width">
                          <table className="table-content__inspector-table__table full-width">
                            <tr>
                              <td></td>
                              <td>Porción actual</td>
                              <td>Por 100g/100ml</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="Current portion"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-1">100</div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Proteinas g</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="proteins-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-2"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Grasas Totales g</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="totalFat-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-3"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Grasas Saturadas g</b></td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="saturatedFat-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-4"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Grasas Trans <b className="text-red">(mg)*</b></b></td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="transFat-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-5"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Hidratos de Carbono disponibles g</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="carbohydrates-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-6"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Azucares g</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="sugars-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-7"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Azucares Añadidos g</b></td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="sugarsAdded-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-8"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Fibra dietética g</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="fiber-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-9"></div>
                              </td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Sodio mg</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="sodium-input"
                                  type="number"
                                  min="0"
                                  onChange={() => utils.calculateNOM51()}
                                />
                              </td>
                              <td>
                                <div id="result-10"></div>
                              </td>
                            </tr>
                            {/* <tr>
                              <td colSpan={3}>Vitaminas y Minerales</td>
                            </tr>
                            <tr>
                              <td>Ácido fólico μg</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="input-mm-no-ostenta"
                                  type="number"
                                />
                              </td>
                              <td>
                                <div id="result"></div>
                              </td>
                            </tr>
                            <tr>
                              <td>Acido pantoténico mg</td>
                              <td>
                                <input
                                  className="table-content__inspector-table__input"
                                  id="input-mm-no-ostenta"
                                  type="number"
                                />
                              </td>
                              <td>
                                <div id="result-1"></div>
                              </td>
                            </tr> */}
                          </table>
                        </div>
                      </td>
                      <td className="width-50">
                        <div className="full-width">
                          <table className="table-content__inspector-table__table full-width">
                            {/* <tr>
                              <td colSpan={2}><b>DECLARACIÓN NUTRIMENTAL</b></td>
                            </tr> */}
                            <tr>
                              <td colSpan={2}>Por envase</td>
                            </tr>
                            <tr>
                              <td><b>Contenido Energético</b></td>
                              <td><b id='result-1-table-2'></b></td>
                            </tr>
                            <tr>
                              <td colSpan={2} id='solid-liquid-label'>Por 100g</td>
                            </tr>
                            <tr>
                              <td><b>Contenido Energético</b></td>
                              <td><b id='result-2-table-2'></b></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Proteínas</td>
                              <td id='result-3-table-2'></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Grasas Totales</td>
                              <td id='result-4-table-2'></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Grasas saturadas</b></td>
                              <td><b id='result-5-table-2'></b></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Grasas trans</b></td>
                              <td><b id='result-6-table-2'></b></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Hidratos de carbono disponibles</td>
                              <td id='result-7-table-2'></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Azúcares</td>
                              <td id='result-8-table-2'></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Azúcares añadidos</b></td>
                              <td><b id='result-9-table-2'></b></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify">Fibra dietética</td>
                              <td id='result-10-table-2'></td>
                            </tr>
                            <tr>
                              <td className="table-content__inspector-table__cell-justify"><b>Sodio</b></td>
                              <td><b id='result-11-table-2'></b></td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                <p className='text-left'>
                                  DECLARACIÓN NUTRIMENTAL <br />
                                  <p id='declaration'></p>
                                </p>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 57,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.4.7. BIS La información impresa en la declaración nutrimental debe presentarse en un tamaño de fuente de cuando menos 1.5 mm de altura,
          <br />
          <br />
          4.5.2.4.7 BIS-1 No obstante lo establecido en 4.5.2.4.7 BIS, la <b>declaración nutrimental debe mostrarse, al menos, en un tamaño de fuente de 1 mm de altura</b> en los siguientes casos:
          <br />a) productos cuya superficie principal de exhibición sea igual o inferior a 32 cm2,
          <br />b) productos obligados a declarar más de 20 nutrimentos, y su superficie principal de exhibición, sea igual o inferior a 161 cm2, y
          <br />c) en envases retornables en los que la información se encuentra en la corcholata o taparrosca. Tabla 3-Presentación de la declaración nutrimental
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>El tamaño mínimo para la declaración nutrimental debe de ser de:</td>
                      <td className="cell-result" id='question-87-result'>...</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 58,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.4.13 Se puede declarar información basada en valores de referencia recomendados para poblaciones distintas a la mexicana, siempre que ésta se presente junto con la información indicada en 4.5.2.4.7 y se le distinga claramente. Dicha información puede presentarse conforme a lo indicado en la tabla 4 o en cualquier otro formato que contenga la información requerida.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/tabla-4.png" alt="Tabla 4" />
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 59,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.4.14 Tolerancias y cumplimiento
          <br />La Secretaría de Salud puede establecer límites de tolerancia en relación con las exigencias de salud pública, en materia de la declaración nutrimental. La estabilidad en almacén, la precisión de los análisis, el diverso grado de elaboración y la inestabilidad y variabilidad propias del nutrimento en el producto, dependiendo de si el nutrimento ha sido añadido al producto o se encuentra naturalmente presente en él, se regularán a través de normas oficiales mexicanas.
          <br /><br />
          4.5.2.4.15 Los valores de composición bromatológica que figuren en la declaración nutrimental del producto preenvasado, deben ser valores medios ponderados derivados por análisis, bases de datos o tablas reconocidas internacionalmente.
          <br /><br />
          Para cumplir con el contenido declarado de vitaminas y minerales hasta el final de la vida útil se acepta una cantidad superior a lo declarado, dentro de las buenas prácticas de manufactura, siempre y cuando las empresas mantengan los antecedentes técnicos que lo justifiquen.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 60,
      text: (
        <p className="table-content__row__cell--text">
          4.5.2.4.16 Para la expresión de la declaración nutrimental se puede utilizar los parámetros de redondeo de la tabla 5, conforme corresponda al nutrimento respectivo. <b>Revisar Tabla 5. Parámetros de redondeo</b>
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                El cumplimiento o no cumplimiento dependerá si la etiqueta está o no aplicando los criterios de redondeo.
                <br />
                <br />
                Si redondea, y redondea bien {'->'} Cumple
                <br />Si redondea, y redondea mal {'->'} No cumple
                <br />Si no redondea {'->'} No aplica
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/tabla-5.png" alt="Tabla 4" />
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 61,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.3 Información nutrimental complementaria.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 62,
      text: (
        <p className="table-content__row__cell--text">
          4.5.3.3 Quedan <b>exceptuados</b> de la información nutrimental complementaria los productos siguientes:
          <br />
          <br />
          a) los <b>productos que están exceptuados</b> de la <b>declaración nutrimental</b>, conforme se establece en el numeral 4.5.2.3 excepto los señalados en el inciso vii;
          <br />b) las <b>fórmulas para lactantes</b>, las fórmulas para lactantes con necesidades especiales de nutrición, las fórmulas de continuación y las fórmulas de continuación para necesidades especiales de nutrición;
          <br />c) los <b>alimentos</b> y las bebidas no alcohólicas <b>para lactantes</b> y para niños de corta edad que tengan especificaciones nutrimentales para alguno de los siguientes nutrimentos: grasas, azúcares y sodio; conforme se establece en los ordenamientos jurídicos aplicables.
          <br />d) <b>aceites vegetales</b>, grasas vegetales o animales; <b>azúcar, miel, sal yodada</b> y sal yodada fluorurada, así como <b>harinas de cereal</b>.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 63,
      fase: 'II',
      text: (
        <p className="table-content__row__cell--text">
          FASE II
          <br /><br />
          4.5.3.2 En <b>los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos</b>, la información nutrimental complementaria se debe declarar conforme a los contenidos de energía, de azúcares libres, grasas saturadas, grasas trans (con excepción de las presentes en productos lácteos y cárnicos de manera natural para el caso de las grasas trans), o de sodio del producto tal como se consume, <b>de acuerdo con las instrucciones indicadas en la etiqueta</b>.
          <br /><br />
          El cálculo y evaluación
          de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
          <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
          <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
          <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
          <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
          <br /><b>Revisar Perfiles Nutrimentales de la Segunda Fase</b>
          <br />
          <img className="table-content__inspector-table__text__image" style={{ maxWidth: '70%' }} src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+II.PNG" alt="Tabla 4" />
          4.5.3.1 Para los efectos del inciso anterior se entiende por:
          <br /><br />
          a) <b>producto preenvasado añadido de azúcares libres</b>, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
          <br />b) <b>producto preenvasado añadido de grasas</b>, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
          <br />c) <b>producto preenvasado añadido de sodio</b>, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr id='alert-product-type-2'></tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td colSpan={2}><b>¿Contiene añadidos?</b>
                        <br />
                        <p id='added-alert-text' className="table-content__inspector-table__table__error-text"></p></td>
                    </tr>
                    <tr>
                      <td>Azúcares</td>
                      <td>
                        <select
                          name="select"
                          className="table-content__inspector-table__input table-content__inspector-table__input--select"
                          placeholder="Resultado"
                          id='azucares-select'
                          onChange={() => { utils.calculateNOM51(); }}
                          defaultValue=''
                        >
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Grasas</td>
                      <td>
                        <select
                          name="select"
                          className="table-content__inspector-table__input table-content__inspector-table__input--select"
                          placeholder="Resultado"
                          id='grasas-select'
                          onChange={() => { utils.calculateNOM51(); }}
                          defaultValue=''
                        >
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Sodio</td>
                      <td>
                        <select
                          name="select"
                          className="table-content__inspector-table__input table-content__inspector-table__input--select"
                          placeholder="Resultado"
                          id='sodio-select'
                          onChange={() => { utils.calculateNOM51(); }}
                          defaultValue=''
                        >
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>ADVERTENCIAS PARA EL INSPECTOR</td>
                    </tr>
                    <tr>
                      <td>
                        <p id='question-98-alert-1' className='text-red'></p><br />
                        <p id='question-98-alert-2' className='text-red'></p><br />
                        <p id='question-98-alert-3' className='text-red'></p><br />
                        <p id='question-98-alert-4' className='text-red'></p><br />
                        <p id='question-98-alert-5' className='text-red'></p><br />
                        <p id='question-98-alert-6' className='text-red'></p>

                        <button
                          className="table-content__inspector-table__button disapear"
                          id='button-alerts-messages'
                          onClick={() => {
                            const nutrimentalDeclarationTable = document.getElementById('nutrimental-declaration-table');

                            if (nutrimentalDeclarationTable) {
                              nutrimentalDeclarationTable.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                              });
                            }
                          }}
                        >IR A DECLARACIÓN NUTRIMENTAL ⇒</button>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>El producto debe llevar los siguientes sellos y medidas</td>
                    </tr>
                    <tr>
                      <td className='result__stampsContainer'>
                        <div id="imagenes-sellos" className='result__tagImgContainer'></div>
                      </td>
                    </tr>
                    <tr>
                      <td id='ubicación-de-los-sellos'></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 64,
      fase: 'III',
      text: (
        <p className="table-content__row__cell--text">
          FASE III
          <br />
          <br />
          4.5.3.2 En <b>los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos</b>, la información nutrimental complementaria se debe declarar conforme a los contenidos de energía, de azúcares libres, grasas saturadas, grasas trans (con excepción de las presentes en productos lácteos y cárnicos de manera natural para el caso de las grasas trans), o de sodio del producto tal como se consume, <b>de acuerdo con las instrucciones indicadas en la etiqueta</b>.
          <br /><br />
          4.5.3.1 Para los efectos del inciso anterior se entiende por:
          <br />a) <b>producto preenvasado añadido de azúcares libres</b>, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
          <br />b) <b>producto preenvasado añadido de grasas</b>, aquellos a los que durante el proceso de elaboración se
          haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos
          e ingredientes que los contengan agregados; y
          <br />c) <b>producto preenvasado añadido de sodio</b>, aquellos a los que durante el proceso de elaboración se
          haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente
          que contenga sales de sodio agregadas.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr id='alert-product-type-2'></tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td colSpan={2}><b>¿Contiene añadidos?</b>
                        <br />
                        <p id='added-alert-text' className="table-content__inspector-table__table__error-text"></p></td>
                    </tr>
                    <tr>
                      <td>Azúcares</td>
                      <td>
                        <select defaultValue='' onChange={() => { utils.calculateNOM51(); }} id='azucares-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Grasas</td>
                      <td>
                        <select defaultValue='' onChange={() => { utils.calculateNOM51(); }} id='grasas-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>Sodio</td>
                      <td>
                        <select defaultValue='' onChange={() => { utils.calculateNOM51(); }} id='sodio-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>ADVERTENCIAS PARA EL INSPECTOR</td>
                    </tr>
                    <tr>
                      <td>
                        <p id='question-98-alert-1' className='text-red'></p><br />
                        <p id='question-98-alert-2' className='text-red'></p><br />
                        <p id='question-98-alert-3' className='text-red'></p><br />
                        <p id='question-98-alert-4' className='text-red'></p><br />
                        <p id='question-98-alert-5' className='text-red'></p><br />
                        <p id='question-98-alert-6' className='text-red'></p>

                        <button
                          className="table-content__inspector-table__button"
                          id='button-alerts-messages'
                          onClick={() => {
                            const nutrimentalDeclarationTable = document.getElementById('nutrimental-declaration-table');

                            if (nutrimentalDeclarationTable) {
                              nutrimentalDeclarationTable.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                              });
                            }
                          }}
                        >IR A DECLARACIÓN NUTRIMENTAL ⇒</button>
                      </td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>El producto debe llevar los siguientes sellos y medidas</td>
                    </tr>
                    <tr>
                      <td className='result__stampsContainer'>
                        <div id="imagenes-sellos" className='result__tagImgContainer'></div>
                      </td>
                    </tr>
                    <tr>
                      <td id='ubicación-de-los-sellos'></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 65,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.3.4 Sistema de etiquetado frontal</b>
          <br />El sistema de etiquetado frontal incluye la información nutrimental complementaria y las leyendas precautorias descritas en los numerales 7.1.3 y 7.1.4.
          <br />
          4.5.3.4.1 La información nutrimental complementaria debe realizarse utilizando los sellos, según corresponda y conforme a lo establecido en el Apéndice A (Normativo).
          <br />
          <img className="table-content__inspector-table__text__image" style={{ width: '70%' }} src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos1.PNG" alt="Tabla 4" />
          4.5.3.4.2 Los productos cuya superficie principal de exhibición sea ≤40 cm2 sólo deben incluir un sello con el número que corresponda a la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 en un tamaño mínimo de conformidad a lo establecido en la tabla A1 del Apéndice A (Normativo) de la presente Norma.
          <br />Aquellos productos cuya superficie principal de exhibición sea ≤ 5 cm2 el sello descrito en el párrafo anterior debe de cumplir con las características descritas en el numeral A.4.5 del Apéndice A (Normativo).
          <img className="table-content__inspector-table__text__image" style={{ width: '70%' }} src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos2.PNG" alt="Tabla 4" />
          <b>4.5.3.4.6 Ubicación y orden de los sellos</b>
          <br />El o los sellos deben colocarse en la esquina superior derecha de la superficie principal de exhibición,
          conforme se establece en el Apéndice A (Normativo). En aquellos productos con superficie principal de exhibición menor a 60 cm2 se podrán colocar los sellos en cualquier área de dicha superficie.
          <br />Cuando se deban incluir más de un sello, el orden de inclusión debe ser de izquierda a derecha el siguiente:
          <br />1. EXCESO CALORÍAS
          <br />2. EXCESO AZÚCARES
          <br />3. EXCESO GRASAS SATURADAS
          <br />4. EXCESO GRASAS TRANS
          <br />5. EXCESO SODIO
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                El producto debe llevar los siguientes sellos y medidas
              </td>
            </tr>
            <tr>
              <td className='result__stampsContainer'>
                <div id="imagenes-sellos-2" className='result__tagImgContainer'></div>
              </td>
            </tr>
            <tr>
              <td id='ubicación-de-los-sellos-2'></td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 66,
      text: (
        <p className="table-content__row__cell--text">
          4.5.3.4.3 Para el caso de productos en <b>envases retornables</b> utilizados como contenedores para más de un tipo de producto o de sabor, los productores deben expresar únicamente en la parte externa de la tapa el sello correspondiente al número de la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 y conforme se establece en 4.5.3.4.2.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 67,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>Envases Múltiples</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 68,
      text: (
        <p className="table-content__row__cell--text">
          4.5.3.4.4 Los productos cuya presentación individual indique la leyenda de “No etiquetado para su venta individual”, o similar, y que se encuentren en un empaque múltiple o colectivo, únicamente éste debe incluir los sellos que correspondan, conforme a lo establecido en los numerales 4.5.3 y 4.5.3.4.1
          <br />
          <br />
          4.7.1.2 Cuando la información comercial obligatoria de los productos preenvasados que van destinados al consumidor final se encuentre en un envase múltiple o colectivo, no será necesario que dicha información aparezca en la superficie del producto individual.
          <br />
          <br />
          Sin embargo, la indicación del lote y la fecha de caducidad o de consumo preferente deben aparecer en el producto preenvasado individual. Además, en el producto preenvasado se debe indicar siempre en lo individual la leyenda "No etiquetado para su venta individual", cuando éstos no tengan toda la información obligatoria o una frase equivalente.
          <br />
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/empaque-multiple.png" alt="empaque-multiple" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 69,
      text: (
        <p className="table-content__row__cell--text">
          4.5.3.4.5 Aquellos envases colectivos que contengan más de un tipo de producto deben estar etiquetados de manera individual. Adicionalmente, el envase colectivo debe incluir tantos sellos como corresponda a los productos que contiene, conforme se establece en 4.5.3 de la presente Norma Oficial Mexicana.
          <br />
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/empaque-multiple-2-v3.png" alt="empaque-multiple 2" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 70,
      text: (
        <p className="table-content__row__cell--text">
          4.5.3.4.7 Cuando proceda incluir las leyendas “CONTIENE CAFEÍNA EVITAR EN NIÑOS” o “CONTIENE EDULCORANTES - NO RECOMENDABLE EN NIÑOS”, deben ir en la parte superior derecha de la superficie principal de exhibición y en caso de que el producto preenvasado tenga sellos, deben ir debajo de los mismos, conforme se establece en el Apéndice A (Normativo). Ver Apéndice A (Normativo).
          <br />
          <br />
          7.1.3 Si la lista de ingredientes incluye edulcorantes, se debe colocar la leyenda precautoria frontal en
          letras mayúsculas “CONTIENE EDULCORANTES, NO RECOMENDABLE EN NIÑOS”.
          <br />
          <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/edulcorantes-v3.png" alt="edulcorantes" />
          <br />
          7.1.4. Cuando el producto preenvasado contenga cafeína adicionada dentro de la lista de ingredientes en cualquier cantidad, se debe incluir la leyenda precautoria en letras mayúsculas “CONTIENE CAFEÍNA EVITAR EN NIÑOS”, la cual forma parte del sistema de etiquetado frontal, conforme se establece en el Apéndice A (Normativo).
          <br />
          <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cafeina-v3.png" alt="cafeina" />
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr id='alert-product-type-3'></tr>
            <tr className="table-content__inspector-table__text">
              <td className="width-50">
                <p id='added-edulcorntes-cafeina-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>¿EL producto ostenta edulcorantes?</td>
                      <td>
                        <select defaultValue='' onChange={() => { utils.calculateNOM51(); }} id='edulcorantes-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>¿El producto ostenta cafeína?</td>
                      <td>
                        <select defaultValue='' onChange={() => { utils.calculateNOM51(); }} id='cafeina-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td><b>EDULCORANTES</b></td>
                      <td><b>CAFEINA</b></td>
                    </tr>
                    <tr>
                      <td className="width-50">
                        <div className="measures-container">
                          <div id="strips-images-1" className='measures-container__container'>
                          </div>
                        </div>
                      </td>
                      <td className="width-50">
                        <div className="measures-container">
                          <div id="strips-images-2" className='measures-container__container'></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div id="measures-images-1"></div>
                      </td>
                      <td>
                        <div id="measures-images-2"></div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 71,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Presentación de los requisitos obligatorios
            <br />4.7.1 Generalidades</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 72,
      text: (
        <p className="table-content__row__cell--text">
          4.7.1.1 Las etiquetas que ostenten los productos preenvasados deben fijarse de manera tal que permanezcan <b>disponibles hasta el momento del consumo</b> en condiciones normales, y deben aplicarse por cada unidad, envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 73,
      text: (
        <p className="table-content__row__cell--text">
          4.7.1.3 Los datos que deben aparecer en la etiqueta deben indicarse con <b>caracteres claros, visibles, indelebles</b> y en colores contrastantes, fáciles de leer por el consumidor en circunstancias normales de compra y uso. El dato relativo al lote, fecha de caducidad o de consumo preferente puede ser colocado en cualquier parte del envase.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 74,
      text: (
        <p className="table-content__row__cell--text">
          4.7.1.4 Cuando el envase esté <b>cubierto por una envoltura</b>, debe figurar en ésta toda la información aplicable, a menos de que la etiqueta del envase pueda leerse fácilmente a través de la envoltura exterior.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 75,
      text: (
        <p className="table-content__row__cell--text">
          4.7.1.5 Deben <b>aparecer en la superficie principal de exhibición</b> del producto cuando menos
          <br />a) la <b>marca</b>,
          <br />b) la <b>declaración de cantidad</b>,
          <br />c) la <b>denominación del producto</b> preenvasado,
          <br />d) el <b>etiquetado frontal</b> y
          <br />e) aquella cuya ubicación se haya especificado.
          <br /><br />
          El resto de la información a que se refiere esta Norma Oficial Mexicana puede incorporarse en cualquier otra parte del envase.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 76,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.8 Idioma</b>
          <br />4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana <b>en idioma español</b>, sin <b>perjuicio de que se exprese en otros idiomas</b>. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          <br /><br />
          4.8.2 La presentación de información o representación gráfica adicional en la etiqueta a la señalada en esta Norma Oficial Mexicana, que puede estar presente en otro idioma, es facultativa y, en su caso, no debe sustituir, sino añadirse a los requisitos de etiquetado de la presente Norma Oficial Mexicana, siempre y cuando dicha información resulte necesaria para evitar que se induzca a error o engaño al consumidor
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 77,
      text: (
        <p className="table-content__row__cell--text">
          4.4.2 Asimismo, en la etiqueta puede presentarse <b>cualquier información</b> o representación gráfica que indique que el envase que contiene el producto preenvasado <b>no afecta al ambiente</b>, evitando que sea falsa o equívoca para el consumidor.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 78,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>4.4.1 Designaciones de calidad</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 79,
      text: (
        <p className="table-content__row__cell--text">
          4.4.1 Cuando se empleen <b>designaciones de calidad</b>, éstas deben ser fácilmente comprensibles, evitando ser equívocas o engañosas en forma alguna para el consumidor
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td className="width-50">
                Aquí puede haber declaraciones como:
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td colSpan={3}>Ejemplo de declaraciones de calidad</td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.4.1-1.png" alt="4.4.1 1" />
                      </td>
                      <td>
                        <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.4.1-2.png" alt="4.4.1 2" />
                      </td>
                      <td>
                        <img className="table-content__inspector-table__text__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/4.4.1-3.png" alt="4.4.1 3" />
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                Original
                <br />Calidad internacional
                <br />Calidad suprema
                <br /><br />
                En todo caso podrás utilizar el siguiente comentario:
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td >En el etiquetado del producto se ostenta la siguiente designación de calidad: "_________", designación que no es fácilmente comprensible, pudiendo ser equivoca o engañosa para el consumidor, por lo tanto deberá modificarla con la finalidad de que sea clara o, en su caso, eliminarla</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 80,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>6. Declaraciones de Propiedades y Declaración de Propiedades Potencialmente Engañosas</b>
          <br />Este apartado se basa en el principio de que ningún alimento y bebida no alcohólica preenvasado deberá describirse o presentarse en forma falsa, equívoca o engañosa, o de ninguna manera que pueda crear en el consumidor una impresión errónea en cuanto a su naturaleza.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 81,
      text: (
        <p className="table-content__row__cell--text">
          <b>6.1 Se prohíbe el uso de las siguientes declaraciones:</b>
          <br /><b>6.1.1 De propiedades</b>
          <br />• Declaraciones que hagan suponer que una alimentación equilibrada a base de alimentos ordinarios no puede suministrar cantidades suficientes de todos los elementos nutritivos.
          <br />• <b>Declaraciones que no pueden comprobarse.</b>
          <br />• Declaraciones sobre la utilidad de un alimento o bebida no alcohólica, para prevenir, aliviar, tratar o curar una enfermedad, trastorno o estado fisiológico.
          <br />• Declaraciones que pueden suscitar dudas sobre la inocuidad de alimentos o bebidas no alcohólicas análogos, o puedan suscitar o provocar miedo en el consumidor.
          <br />• Declaraciones que afirmen que un determinado alimento constituye una fuente adecuada de todos los nutrimentos esenciales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 82,
      text: (
        <p className="table-content__row__cell--text">
          <b>6.1 Se prohíbe el uso de las siguientes declaraciones:
            <br />6.1.2 De propiedades potencialmente engañosas</b>
          <br />A continuación, se presentan ejemplos de declaraciones de propiedades que pueden ser engañosas:
          <br />• Declaraciones de propiedades que <b>carecen de sentido</b>, incluidos los comparativos y superlativos incompletos.
          <br />• Declaraciones de propiedades respecto a prácticas correctas de higiene o comercio, tales como: "<b>genuinidad</b>", "<b>salubridad</b>", "<b>sanidad</b>", “<b>sano”</b>, “<b>saludable”</b>, excepto las señaladas en otros ordenamientos jurídicos aplicables.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td className="width-50">
                Ejemplos de este tipo de declaraciones son:
                <br />PREMIUM, CALIDAD SUPREMA, SANO, SALUDABLE, ORIGINAL, RECETA TRADICIONAL.
                <br /><br />
                Estas declaraciones deberán de sustentarse en la misma etiqueta o el cliente deberá de presentar información fidedigna y comprobable para el uso de tales declaraciones
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 83,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>6.2 Declaraciones de propiedades condicionales</b>
          <br />Se <b>permiten</b> las siguientes declaraciones de propiedades condicionadas a la particular condición asignada a cada una de ellas:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 84,
      text: (
        <p className="table-content__row__cell--text">
          c) Términos como “<b>orgánico”</b>, “<b>ecológico”</b>. “<b>biológico</b>”  y las denominaciones con prefijos “bio” y “eco”, deben ajustarse a lo establecido en <b>la Ley de Productos Orgánicos</b> (ver Bibliografía), y aplicar los demás términos que se establezcan en alguna otra Norma Oficial Mexicana o marco jurídico aplicable.
          <br />
          <br />
          El uso de estos términos debe estar en consonancia con las prohibiciones establecidas en el numeral 6.1.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td >
                El inspector deberá de corroborar por medio de un certificado que se cumple con este apartado.
                <br />
                En caso de no tenerlo, se deberá de colocar no cumplimiento indicando que se comparta el certificado correspondiente para corroborar los requisitos de ese apartado.
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 85,
      text: (
        <p className="table-content__row__cell--text">
          d) <b>Declaraciones</b> de propiedades que afirmen <b>que el alimento tiene características especiales cuando todos</b> los <b>alimentos de ese tipo tienen esas mismas características</b>, si este hecho es aparente en la declaración de propiedades.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 86,
      text: (
        <p className="table-content__row__cell--text">
          e) Pueden utilizarse <b>declaraciones</b> de propiedades que <b>destaquen la ausencia o no adición de determinadas</b> sustancias a los alimentos, siempre que no sean engañosas y la sustancia:
          <br /><br />
          i. no esté sujeta a requisitos específicos en ninguna norma;
          <br />ii. sea una de las que los consumidores esperan encontrar normalmente en el alimento;
          <br />iii. no haya sido sustituida por otra que confiera al alimento características equivalentes a menos que la naturaleza de la sustitución se declare explícitamente con igual prominencia; y
          <br />iv. sea un ingrediente cuya presencia o adición en el alimento esté permitida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 87,
      text: (
        <p className="table-content__row__cell--text">
          g) Puede declararse la <b>preparación ritual</b> o <b>religiosa</b> de un alimento (ejemplo, <b>Halal, Kosher</b>), siempre que se ajuste a las exigencias de las autoridades religiosas o del ritual competente y sin importar la presencia de sellos de advertencia.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td >
                El inspector deberá de corroborar por medio de un certificado que se cumple con este apartado.
                <br />
                En caso de no tenerlo, se deberá de colocar no cumplimiento indicando que se comparta el certificado correspondiente para corroborar los requisitos de ese apartado.
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 88,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>6.3 Declaraciones de propiedades nutrimentales</b>
          <br />Este tipo de declaraciones pueden referirse al valor de energía, proteínas, hidratos de carbono, grasas y los derivados de las mismas, fibra dietética, sodio, vitaminas y nutrimentos inorgánicos (minerales) para los cuales se han establecido valores nutrimentales de referencia.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 89,
      text: (
        <p className="table-content__row__cell--text">
          6.3.1 <b>Por declaración de propiedades relativas al contenido de nutrimentos se entiende</b> una declaración de propiedades nutrimentales que describe el nivel de un determinado nutrimento contenido en un alimento.
          <br /><br />
          (Ejemplos: “Fuente de calcio”; “alto contenido de fibra y bajo en grasa”.
          <br /><br />
          6.3.2 Por declaración de propiedades de comparación de nutrimentos se entiende una declaración de propiedades que compara los niveles de nutrimentos y/o el valor energético de dos o más alimentos.
          <br /><br />
          (Ejemplos: “reducido”; “menos que”; “menos”; “aumentado”; “más que”.)
          <br /><br />
          6.3.3.2 Otras Declaraciones de propiedades de función - Estas declaraciones de propiedades conciernen efectos benéficos específicos del consumo de alimentos o sus constituyentes en el contexto de una dieta total sobre las funciones o actividades biológicas normales del organismo. Tales declaraciones de propiedades se relacionan a una contribución positiva a la salud o a la mejora de una función o la modificación o preservación de la salud. Ejemplo: “La sustancia A (nombrando los efectos de la sustancia A sobre el mejoramiento o modificación de una función fisiológica o la actividad biológica asociada con la salud). El alimento Y contiene X gramos de sustancia A”.
          <br /><br />
          6.2 Declaraciones de propiedades condicionales
          <br />Se permiten las siguientes declaraciones de propiedades condicionadas a la particular condición asignada a cada una de ellas:
          <br /><br />
          a) <b>Puede indicarse que un alimento ha adquirido un valor nutritivo especial</b> o superior gracias a la <b>adición</b> de nutrimentos, tales como <b>vitaminas, nutrimentos inorgánicos</b> (minerales) y aminoácidos, sólo si dicha adición ha sido hecha sobre la base de consideraciones nutrimentales de acuerdo con el marco jurídico aplicable
          <br /><br />
          b) Las <b>indicaciones</b> de que el alimento tiene <b>cualidades nutricionales especiales gracias</b> a la <b>reducción</b> u <b>omisión</b> de un <b>nutrimento</b>, se deberán hacer sobre la base de consideraciones nutrimentales y estar sujetas al marco jurídico aplicable.
          <br /><br />
          f) Las declaraciones de propiedades que pongan de relieve la <b>ausencia</b> o no <b>adición</b> de uno o más <b>nutrimentos</b> deberán considerarse como declaraciones de propiedades nutrimentales y, por consiguiente, deberán ajustarse a la declaración obligatoria de nutrimentos, estipulada en el marco jurídico aplicable.
          <br /><br />
          <b>4.6 Declaración de propiedades nutrimentales</b>
          <br />4.6.1 No obstante lo establecido en la presente Norma Oficial Mexicana, toda declaración respecto de las propiedades nutrimentales debe sujetarse a lo dispuesto en la NOM-086-SSA1-1994
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__text">
              <td colSpan={2} className="text-center">NOM-086-SSA1-1994</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>Declaración</td>
              <td>Restricción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Libre de sodio <br />Sin Sodio</b></td>
              <td>7.1.1 Productos libres de o sin sodio: su contenido de sodio es menor de 5 mg/porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Muy bajo en sodio</b></td>
              <td>7.1.2 Productos muy bajos en sodio: su contenido de sodio es menor o igual a 35 mg/porción. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Bajo en sodio</b></td>
              <td>7.1.3 Productos bajos en sodio: su contenido de sodio es menor o igual a 140 mg/porción. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en sodio</b></td>
              <td>7.1.4 Productos reducidos en sodio: el contenido de sodio es al menos un 25% menor en relación al contenido de sodio del alimento original o de su similar</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Sin grasa</b></td>
              <td>7.4.1 Producto sin grasa: su contenido de grasa es menor a 0,5 g/ porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Bajo en grasa</b></td>
              <td>7.4.2 Producto bajo en grasa: su contenido de grasa es menor o igual a 3 g/porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en grasa</b></td>
              <td>7.4.3 Producto reducido en grasa, aquel cuyo contenido de grasa es al menos un 25% menor en relación al contenido de grasa del alimento original o de su similar</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Bajo en grasa saturada</b></td>
              <td>7.5.1 Producto bajo en grasa saturada: su contenido de grasa saturada es igual o menor a 1 g/porción y no más del 15% de energía proveniente de la grasa saturada. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en grasa saturada</b></td>
              <td>7.5.2 Producto reducido en grasa saturada, aquel cuyo contenido de grasa saturada es al menos un 25% menor en relación al contenido de grasa saturada del producto original o de su similar</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Sin colesterol</b></td>
              <td>7.6.1 Producto sin colesterol: su contenido de colesterol es menor de 2 mg/porción y el de grasa saturada es menor o igual a 2 g/porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Bajo en colesterol</b></td>
              <td>7.6.2 Producto bajo en colesterol: su contenido de colesterol es menor o igual a 20 mg/porción. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en colesterol</b></td>
              <td>7.6.3 Producto reducido en colesterol: aquel cuyo contenido de colesterol es al menos un 25% menor en relación al contenido de colesterol del producto original o de su similar y contiene 2 g o menos de grasa saturada por porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Sin calorías</b></td>
              <td>7.7.1 Producto sin calorías: su contenido de calorías debe ser menor de 5 calorías/porción</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Bajo en calorías</b></td>
              <td>7.7.2 Producto bajo en calorías: su contenido debe ser menor o igual a 40 calorías/porción. </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en calorías</b></td>
              <td>7.7.3 Producto reducido en calorías: es aquel donde el contenido de calorías es al menos un 25% menor en relación al contenido de calorías del alimento original o de su similar</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Sin gluten</b></td>
              <td>
                7.8 Los productos sin gluten son aquellos a los que éste se les ha eliminado y cumplen con lo siguiente:
                <br />7.8.1 Que contenga básicamente como ingredientes cereales tales como trigo, triticale, centeno, cebada o avena o sus constituyentes de los que se les ha quitado el gluten, o
                <br />7.8.2 Que todos los ingredientes normalmente presentes y que contienen gluten hayan sido sustituidos por otros ingredientes que no lo contienen.
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Exento de gluten</b></td>
              <td>
                7.8.3 Para denominarse alimento exento de gluten, se requiere que el contenido total de nitrógeno de los granos de cereal que se empleen y que contengan gluten no excedan de 0,05 g /100g expresados en materia seca.
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Sin azúcar</b></td>
              <td>
                7.9.1 Producto sin azúcar: su contenido de azúcar es menor a 0,5 g/porción
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Reducido en azúcar</b></td>
              <td>
                7.9.2 Producto reducido en azúcar: el contenido de azúcar se ha reducido por lo menos en un 25% del contenido del alimento original o de su similar
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td><b>Adicionado con Fibra</b></td>
              <td>
                7.17 Los productos adicionados de fibra: son aquellos en los que el contenido de fibra es igual o mayor de 2,5 g/porción en relación al contenido del alimento original o de su similar.
              </td>
            </tr>

          </table>
          Sin embargo, en el <b>caso de que el producto preenvasado incluya en su etiquetado alguno de los sellos</b> señalados en 4.5.3.4.1 y cualquiera de las leyendas establecidas en 7.1.3 y 7.1.4, la declaración de propiedades nutrimentales y saludables debe cumplir con lo siguiente:
          <br />
          <br />
          b) <b>no deben</b> realizarse <b>declaraciones de propiedades nutrimentales relacionadas</b> directamente con el sello que <b>haya sido declarado</b> en la etiqueta, y
          <br />
          <br />
          c) las declaraciones de propiedades nutrimentales que pueden realizarse deben ostentarse en la superficie de información con una altura máxima de la letra que debe corresponder con la altura mínima de la letra establecida en 4.1.3 de la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas).
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td colSpan={6}>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td colSpan={6} id="alert-message-question-135" className='text-red full-width text-center'></td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td className='text-sm'>Declaración de propiedades nutrimentales</td>
              <td className='text-sm'>¿Puede comprobarse?</td>
              <td className='text-sm'>Ubicación</td>
              <td className='text-sm'>Tamaño de letra (mm)</td>
              <td className='text-sm'>Tamaño de letra permitido (mm)</td>
              <td className='text-sm'>Análisis del sistema</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-1-question-135'
                  type="text"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-2-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-3-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="Superficie Principal de Exhibición">Superficie Principal de Exhibición</option>
                  <option value="Superficie de Información">Superficie de Información</option>
                </select>
              </td>
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-4-question-135'
                  type="number"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td className='text-sm' id='result-1-question-135'></td>
              <td className='text-sm' id='result-2-question-135'></td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-5-question-135'
                  type="text"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-6-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-7-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="Superficie Principal de Exhibición">Superficie Principal de Exhibición</option>
                  <option value="Superficie de Información">Superficie de Información</option>
                </select>
              </td>
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-8-question-135'
                  type="number"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td className='text-sm' id='result-3-question-135'></td>
              <td className='text-sm' id='result-4-question-135'></td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-9-question-135'
                  type="text"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-10-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
              <td>
                <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id='select-11-question-135' className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="Superficie Principal de Exhibición">Superficie Principal de Exhibición</option>
                  <option value="Superficie de Información">Superficie de Información</option>
                </select>
              </td>
              <td>
                <input
                  className="table-content__inspector-table__input"
                  id='input-12-question-135'
                  type="number"
                  onChange={() => utils.calculateNOM51()}
                />
              </td>
              <td className='text-sm' id='result-5-question-135'></td>
              <td className='text-sm' id='result-6-question-135'></td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 90,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>6.3 Declaraciones de propiedades saludables</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 91,
      text: (
        <p className="table-content__row__cell--text">
          6.3.3 Declaración de propiedades saludables significa cualquier representación que declara, sugiere o implica que existe una relación entre un alimento, o un constituyente de dicho alimento, y la salud. Las declaraciones de propiedades saludables incluyen lo siguiente:
          <br />
          <br />
          6.3.3.1 Por declaración de función de los nutrimentos se entiende una declaración de propiedades nutrimentales que describe la función fisiológica del nutrimento en el crecimiento, el desarrollo y las funciones normales del organismo.
          <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
          <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
          <br /><br />
          6.3.4 <b>No se podrán hacer declaraciones de propiedades</b> cuando se pretenda atribuir al producto características que no contiene o posee ni declaraciones asociadas a la disminución o reducción de riesgo de enfermedad.
          <br /><br />

          Sin embargo, en el <b>caso de que el producto preenvasado incluya en su etiquetado alguno de los sellos</b> señalados en 4.5.3.4.1 y cualquiera de las leyendas establecidas en 7.1.3 y 7.1.4, la declaración de propiedades nutrimentales y saludables debe cumplir con lo siguiente:
          <br /><br />
          <b>a) no deben realizarse declaraciones de propiedades saludables;</b>
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                El producto ostenta o debe ostentar la siguiente información nutrimental complementaria
                <br />

                <table className="table-content__inspector-table__table full-width">
                  <tr>
                    <td className='text-left'>EXCESO CALORIAS</td>
                    <td id="EXCESO CALORIAS" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>EXCESO AZUCARES</td>
                    <td id="EXCESO AZUCARES" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>EXCESO GRASAS SATURADAS</td>
                    <td id="EXCESO GRASAS SATURADAS" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>EXCESO GRASAS TRANS</td>
                    <td id="EXCESO GRASAS TRANS" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>EXCESO SODIO</td>
                    <td id="EXCESO SODIO" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>EDULCORANTES</td>
                    <td id="EDULCORANTES" className='cell-result'></td>
                  </tr>
                  <tr>
                    <td className='text-left'>CAFEINA</td>
                    <td id="CAFEINA" className='cell-result'></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td id='Por lo tanto' className='cell-result'>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 92,
      text: (
        <p className="table-content__row__cell--text">
          <b>7. Leyendas
            <br />7.1 Leyendas precautorias</b>
          <br />7.1.1 Las leyendas precautorias deben hacer referencia al ingrediente u origen del ingrediente que, basado en información científica reconocida, se asocie a riesgos reales o potenciales relacionados con la intolerancia digestiva, alergias o enfermedades metabólicas o toxicidad.
          <br />7.1.2 Las leyendas precautorias específicas por producto, se establecerán en las normas oficiales mexicanas correspondientes u otros ordenamientos jurídicos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 93,
      text: (
        <p className="table-content__row__cell--text">
          4.1.4 En la etiqueta de los productos preenvasados pueden incluirse sellos o leyendas de <b>recomendación o reconocimiento por organizaciones o asociaciones profesionales cuando presenten la documentación apropiada</b> que soporte con evidencia científica, objetiva y fehaciente, la evaluación del producto de acuerdo con lo establecido en el artículo 32 de la Ley Federal de Protección al Consumidor.
          <br /><br />
          Para el otorgamiento del respaldo los productos no deben exceder uno o más de los nutrimentos críticos añadidos establecidos en la tabla 6, y deben especificar la población objetivo con una condición de salud específica. Se exceptúan las certificaciones de propiedades condicionales señaladas en el numeral 6.2
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td >
                Para este apartado pueden ponerse declaraciones como
                <br />Recomendado para celiacos
                <br />Recomendado para personas intolerantes a la lactosa
                <br /><br />
                Siempre que el cliente presente la documentación apropiada y sea comprobable.
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 94,
      text: (
        <p className="table-content__row__cell--text">
          4.1.4 BIS La etiqueta de los productos preenvasados que no contengan los sellos y leyendas precautorias, puede declararlo únicamente de forma escrita mediante la frase “<b>Este producto no contiene sellos ni leyendas</b>” y no debe utilizar elementos gráficos o descriptivo alusivos a los mismos. La declaración debe ser colocada en la superficie de información y, su tipografía y tamaño debe ser igual o menor al tamaño mínimo cuantitativo del contenido neto conforme a la NOM-030-SCFI-2006
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <p id='sellos-leyendas-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>¿El producto ostenta la leyenda ‘Este producto no contiene sellos ni leyendas”</td>
                      <td>
                        <select defaultValue='' name="select" id="select-leyenda-question-144" onChange={() => utils.calculateNOM51()} className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>¿En qué superficie se encuentra la leyenda?</td>
                      <td>
                        <select defaultValue='' name="select" id="select-superficie-leyenda-question-144" onChange={() => utils.calculateNOM51()} className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="S.P.E.">S.P.E.</option>
                          <option value="S.I.">S.I.</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td>¿Cuánto mide la leyenda? En milímetros</td>
                      <td>
                        <input
                          className="table-content__inspector-table__input"
                          type="number"
                          onChange={() => utils.calculateNOM51()}
                          id="input-medida-leyenda-question-144"
                        />
                      </td>
                    </tr>
                  </table>
                </div>
                Resultado:
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td className="text-left cell-result" id="result-question-144"></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 95,
      text: (
        <p className="table-content__row__cell--text">
          4.1.5 Los productos preenvasados que ostenten uno o más sellos de advertencia o la leyenda de edulcorantes, no deben:
          <br /><br />
          a) <b>incluir en la etiqueta personajes infantiles</b>, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos visual – espaciales o descargas digitales, <b>que, estando dirigidos</b> a niños, <b>inciten, promueven</b> o fomenten el <b>consumo</b>, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes, y
          <br /><br />
          b) hacer referencia en la etiqueta a elementos ajenos al mismo con las mismas finalidades del párrafo anterior.
          <br /><br />
          La aplicación de este numeral se debe hacer en concordancia con lo dispuesto por otros ordenamientos legales aplicables.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <p id='dibujos-animados-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>¿El producto va dirigidos a niños (3-12 años) y ostenta imágenes, dibujos animados, celebridades, juegos interactivos que pudieran fomentar el consumo, compra o elección?</td>
                      <td className="min-width">
                        <select defaultValue='' name="select" id="question-145-select" onChange={() => utils.calculateNOM51()} className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
                Resultado:
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td className="text-left cell-result" id="result-question-145"></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 96,
      text: (
        <p className="table-content__row__cell--text">
          REGLAMENTO DE CONTROL SANITARIO DE PRODUCTOS Y SERVICIOS
          <br />APÉNDICE DEL REGLAMENTO DE CONTROL SANITARIO DE PRODUCTOS Y SERVICIOS.
          <br />II. Etiquetado
          <br /><br />
          <b>Los productos</b> terminados que <b>contengan alcohol</b> etílico o bebidas alcohólicas en cantidades menores al 2% en volumen, deberán incluir en la superficie principal de exhibición de la etiqueta la siguiente leyenda:
          <br /><br />
          "Este producto contiene _% de alcohol. No recomendable para niños".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 97,
      text: (
        <p className="table-content__row__cell--text">
          REGLAMENTO DE CONTROL SANITARIO DE PRODUCTOS Y SERVICIOS
          <br />XI.15. <b>El etiquetado del té</b> o infusión deberá cumplir con lo siguiente:
          <br />XI.15.1. Se indicará el <b>nombre común y botánico</b>, con el señalamiento del género y especie.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 98,
      text: (
        <p className="table-content__row__cell--text">
          NOM-008-SE-2021, Sistema general de unidades de medida
          <br />6.3 Reglas de escritura
          <br />6.3.1 Reglas de escritura para las Unidades
          <br /><br />
          El símbolo de la unidad no debe pluralizarse, ni terminarse con un punto, excepto cuando se encuentre al final de un párrafo o se use como punto y seguido ortográfico.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td >
                Puedes basarte en los siguientes ejemplos.
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>Correcto</td>
                      <td>Incorrecto</td>
                    </tr>
                    <tr>
                      <td>45 kg</td>
                      <td>45 kg.</td>
                    </tr>
                    <tr>
                      <td>La masa de la mezcla no debe rebasar los 45 kg.</td>
                      <td>650mg.</td>
                    </tr>
                    <tr>
                      <td>La masa de la mezcla no debe rebasar los 45 kg. Los ingredientes deben conservarse en un lugar fresco y seco</td>
                      <td>635kcal. (1788kJ).</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 99,
      text: (
        <p className="table-content__row__cell--text">
          Ley General de Salud
          <br />DECRETO por el que se adiciona un artículo 216 Bis a la Ley General de Salud.
          <br /><br />
          Artículo 216 Bis.- Los <b>aceites y grasas comestibles</b>, así como los alimentos y bebidas no alcohólicas, no podrán contener en su presentación para venta al público aceites parcialmente hidrogenados, conocidos como grasas trans, que hayan sido añadidos durante su proceso de elaboración industrial.
          <br /><br />
          Los alimentos, bebidas no alcohólicas, aceites y grasas no podrán exceder dos partes de ácidos grasos trans de producción industrial por cada cien partes del total de ácidos grasos.
          <table className="table-content__inspector-table">

            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <p id='aceites-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td>¿El producto contiene 'aceites vegetales' o 'aceites vegetales parcialmente hidrogenados'? - Articulo 216 BIS</td>
                      <td className="min-width">
                        <select defaultValue='' name="select" onChange={() => utils.calculateNOM51()} id="article-216-select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
                <br />
                <div className="full-width">
                  <table className="table-content__inspector-table__table full-width">
                    <tr>
                      <td id="leyend-216" className='cell-result'></td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>

          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 100,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          NOM-173-SE-2021
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 101,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.1. Jugos</b>
          <br />Los jugos deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.14, 3.16 y 3.17 de esta Norma Oficial Mexicana, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.1 y 5.3.1 de esta Norma Oficial Mexicana, asimismo, el contenido de jugo de vegetales o frutas u hortalizas debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana.
          <br /><br />
          Nota 1: Los jugos deben cumplir con las especificaciones descritas en la Tabla 1.
          <br />5.1.1.1 Jugo de __________ (el nombre del vegetal o fruta u hortaliza).
          <br />5.1.1.2 Jugo de __________ (el nombre del vegetal o fruta u hortaliza) de concentrado.
          <br />5.1.1.3 Jugo mixto de __________ (describir los vegetales o frutas o agua de coco o coco u hortalizas que contiene el producto).
          Nota 2: Los jugos mixtos con más de dos vegetales o frutas u hortalizas, agua de coco o coco, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.1.
          <br /><br />
          Se pueden elaborar junto a sus pepitas, semillas y pieles que normalmente no se incorporan al jugo, aunque pueden ser aceptables algunas partes o componentes de pepitas, semillas y pieles que no puedan eliminarse mediante los procesos físicos industriales convencionales.
          <div className="full-width">
            <table className="table-content__inspector-table__table full-width">
              <tr className="table-content__inspector-table__text">
                <td>
                  <b>Tabla 1 - Especificaciones para Jugos, Agua de coco y Jugos de Concentrados una vez Reconstituidos</b>
                  <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/table-1-nom-173.png" alt="Tabla 1" className="table-content__inspector-table__text__image" />
                </td>
              </tr>
            </table>
          </div>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 102,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Nota 9: Cuando un jugo proceda de una fruta no mencionada en la Tabla 1, el nivel mínimo de grados Brix de la fruta será el normado internacionalmente por el Codex Alimentarius.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 103,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Nota 10: Para el caso del jugo de uva se puede tener una relación isotópica en el intervalo de -23 a -24 únicamente cuando la uva provenga de una región geográfica distinta a México, lo cual debe ser comprobable en la actividad de verificación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 104,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5.3.1 Ingredientes en jugos
          <br />Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
          <br />a) Espesantes
          <br />b) Azúcares añadidos
          <br />c) Colorantes
          <br />d) Edulcorantes
          <br />e) Saborizantes
          <br />f) Acidulantes
          <br /><br />
          Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 105,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
          <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 106,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5.3.1.1 Ingredientes opcionales en jugos
          <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
          <br />a) Sal
          <br />b) Especias
          <br />c) Hierbas aromáticas (y sus extractos naturales)
          <br />d) Semillas
          <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 107,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Jugos, Agua de coco y Néctares
          <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 108,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.2 Agua de coco</b>
          <br />El agua de coco debe cumplir con cualquiera de las definiciones establecidas en los incisos 3.2, 3.4 y cumplir con lo establecido en el inciso 5.3.1, usando las siguientes denominaciones de producto según corresponda, el contenido de agua de coco debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana:
          <br /><br />
          <b>5.1.2.1. Agua de coco</b>
          <br /><b>5.1.2.2 Agua de coco de concentrado</b>
          <br /><br />
          <b>3.2 agua de coco</b>
          <br />líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.
          <br /><br />
          <b>3.4 agua de coco de concentrado</b>
          <br />agua de coco elaborada a partir de agua de coco concentrada.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 109,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          Nota 3: El agua de coco y agua de coco de concentrado debe cumplir con las especificaciones descritas en la Tabla 1.
          <br />
          <div className="full-width">
            <table className="table-content__inspector-table__table full-width">
              <tr className="table-content__inspector-table__text">
                <td>
                  <b>Parámetro</b>
                </td>
                <td>
                  <b>Agua de coco</b>
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  * Sólidosdisueltosmínimos de lafrutacorrespondiente(°Brix)
                </td>
                <td>
                  <b>5</b>
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  RelaciónIsotópica deCarbono (13C/12C), expresadaen d13CVPDB (%).
                </td>
                <td>
                  <b>-23 a -25</b>
                </td>
              </tr>
            </table>
          </div>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 110,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.1 Ingredientes en agua de coco</b>
          <br />Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
          <br />a) Espesantes
          <br />b) Azúcares añadidos
          <br />c) Colorantes
          <br />d) Edulcorantes
          <br />e) Saborizantes
          <br />f) Acidulantes
          <br /><br />
          Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
          <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
          <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 111,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.1.1 Ingredientes opcionales en agua de coco</b>
          <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
          <br />a) Sal
          <br />b) Especias
          <br />c) Hierbas aromáticas (y sus extractos naturales)
          <br />d) Semillas
          <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 112,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Jugos, Agua de coco y Néctares
          <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 113,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.3 Néctares</b>
          <br />Los néctares deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.19 y 3.20, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.2 y 5.3.2.
          <br /><br />
          <b>5.1.3.1 Néctar de</b> ___________ (el nombre del vegetal o fruta o coco u hortaliza).
          <br /><br />
          <b>5.1.3.2 Néctar mixto de</b> ________ (describir los vegetales o frutas o coco u hortalizas, según corresponda la combinación entre éstos).
          <br /><br />
          <b>Nota 4</b>: Los néctares mixtos con más de dos vegetales o frutas o coco u hortalizas, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.2.
          <br /><br />
          <b>3.19 néctar</b>
          <br />producto líquido, con pulpa o sin pulpa, elaborado con jugo o pulpa o puré de vegetales o frutas, o coco u hortalizas (maduras y sanas), de la variedad correspondiente, lavadas, finamente divididas y tamizadas, de concentrados o no, de congelados o no y deben cumplir con los parámetros establecidos en la Tabla 2 de esta Norma Oficial Mexicana, pudiendo ser adicionado de agua potable, azúcares añadidos, edulcorantes, así como los aditivos y coadyuvantes permitidos en la Referencia normativa 2.10.
          <br /><br />
          <b>3.20 néctar mixto</b>
          <br />néctar puede elaborarse a partir de la mezcla de dos o más vegetales o frutas u hortalizas o agua de coco o coco y que cumplen con las definiciones establecidas en el inciso 3.19.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 114,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.2.2 Néctares</b>
          <br />Los néctares descritos en los incisos 3.19, 3.20 y 5.1.3 deben cumplir con lo establecido en la Tabla 2.
          <br /><br />
          <b>Tabla 2
            <br />Especificaciones para néctares naturales o reconstituidos</b>
          <br />
          <div className="full-width">
            <table className="table-content__inspector-table__table full-width">
              <tr className="table-content__inspector-table__text">
                <td>
                  Nombre botánico
                </td>
                <td>
                  Nombre Común
                </td>
                <td>
                  Valor de °Bx del jugo separado
                </td>
                <td>
                  Contenido minimo de jugo y/o pulpa (% v/v) en néctares de frutas
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Cocos nucifera L.
                </td>
                <td>
                  Coco
                </td>
                <td>
                  5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Cucumis melo L.
                </td>
                <td>
                  Melon
                </td>
                <td>
                  8
                </td>
                <td>
                  35
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Cucumis melo L. subsp. melo var.inodorus H. Jacq
                </td>
                <td>
                  Melon casaba
                </td>
                <td>
                  7.5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Cucumis melo L subsp. melo var.inodorus H. Jacq.
                </td>
                <td>
                  Melon dulce de piel lisa
                </td>
                <td>
                  10
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Fragaria x. ananassaDuchense(Fragaria chiloensisDuchesne x Fragaria virginianaDuchesne)
                </td>
                <td>
                  Fresa
                </td>
                <td>
                  7.5
                </td>
                <td>
                  40
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Lycopersicum esculentum L.
                </td>
                <td>
                  Tomate
                </td>
                <td>
                  5
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Malus domestica Borkh.
                </td>
                <td>
                  Manzana
                </td>
                <td>
                  11.5
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Mangifera indica L.
                </td>
                <td>
                  Mango
                </td>
                <td>
                  13.5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Prunus persica (L.) Batsch var.persica
                </td>
                <td>
                  Durazno
                </td>
                <td>
                  10.5
                </td>
                <td>
                  40
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Psidium guajava L.
                </td>
                <td>
                  Guayaba
                </td>
                <td>
                  8.5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Pyrus communis L
                </td>
                <td>
                  Pera
                </td>
                <td>
                  12
                </td>
                <td>
                  40
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Vitis Vinifera L. o sus híbridos VitisLabrusca o sus híbridos
                </td>
                <td>
                  Uva
                </td>
                <td>
                  16
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Ananas comosus (L.)MerrillAnanas sativis L. Schult. f.
                </td>
                <td>
                  Piña
                </td>
                <td>
                  16
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Citrus sinensis (L.)
                </td>
                <td>
                  Naranja
                </td>
                <td>
                  10
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Citrus paradisi Macfad
                </td>
                <td>
                  Toronja
                </td>
                <td>
                  10
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Punica granatum L.
                </td>
                <td>
                  Granada
                </td>
                <td>
                  12
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Citrus reticulata Blanca
                </td>
                <td>
                  Mandarina
                </td>
                <td>
                  11.8
                </td>
                <td>
                  50
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Carica papaya L.
                </td>
                <td>
                  Papaya
                </td>
                <td>
                  11.5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Annona muricata L
                </td>
                <td>
                  Guanábana
                </td>
                <td>
                  14.5
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Musa species incluidas M.acuminata y M. paradisiaca peroexcluyendo los otros plátanos
                </td>
                <td>
                  Platano
                </td>
                <td>
                  22
                </td>
                <td>
                  25
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Prunus armeniaca L.
                </td>
                <td>
                  Chabacano
                </td>
                <td>
                  11.5
                </td>
                <td>
                  40
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Apium graveolens
                </td>
                <td>
                  Apio
                </td>
                <td>
                  3.1
                </td>
                <td>
                  20
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Daucus carota
                </td>
                <td>
                  Zanahoria
                </td>
                <td>
                  8
                </td>
                <td>
                  20
                </td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Vaccinium macrocarpon AitonVaccinium oxycoccos L.
                </td>
                <td>
                  Arandano agrio
                </td>
                <td>
                  7.5
                </td>
                <td>
                  30
                </td>
              </tr>
            </table>
          </div>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 115,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          Nota 11: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de fruta, deben contener un porcentaje total mínimo de 25 % de jugo, puré y/o pulpa (% v/v).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 116,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          Nota 12: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de vegetales u hortalizas deben contener un porcentaje total mínimo de 20 % de jugo, puré y/o pulpa (% v/v).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 117,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5.3.2 Ingredientes en néctares
          <br />Se pueden añadir los siguientes ingredientes a los néctares:
          <br />a) Agua potable
          <br />b) Extractos de vegetales o frutas u hortalizas
          <br />c) Azúcares añadidos
          <br />d) Edulcorantes conforme a la Referencia normativa 2.10
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 118,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5.3.2.1 Ingredientes opcionales en néctares
          <br />Se pueden añadir otros ingredientes opcionales a los productos que usen la denominación de producto néctar y su uso debe ser declarado dentro de la denominación del producto.
          <br />Nota 14: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 119,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Jugos, Agua de coco y Néctares
          <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 120,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.4 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</b>
          <br />Estas bebidas no alcohólicas deben cumplir con la definición establecida en el inciso 3.7 y describir el porcentaje de vegetal o fruta, agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, usando la siguiente denominación de producto
          <br /><br />
          <b>5.1.4.1 Bebida con</b> ____ % (dos dígitos) de __________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
          <br /><br />
          <b>Nota 5</b>: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo el Aloe vera) deben representar números enteros a través de dos dígitos (mayores a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 10.9, 11.4, 13.6,etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
          <br /><br />
          <b>3.7 bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</b>
          <br />aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 121,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.2.3 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza</b>
          <br />Las Bebidas descritas en 5.1.4 deben tener un porcentaje mayor o igual del 10 % del contenido de vegetales o frutas o aguade coco o coco u hortaliza (incluyendo al Aloe vera) y debe ser menor al contenido mínimo de jugo y/o pulpa (% v/v) en néctares de frutas, descrito en la Tabla 2.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 122,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.3 Ingredientes en bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas</b>
          <br />Las bebidas descritas en el inciso 5.1.4, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 123,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>6.2 Bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas</b>
          <br />Las bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas descritas en el inciso 5.1.4, pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 124,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.5 Bebidas saborizadas no alcohólicas</b>
          <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza deben cumplir con la definición establecida en el inciso 3.8 y su denominación de producto debe ser como a continuación se describe.
          <br /><br />
          <b>5.1.5.1</b> Las bebidas saborizadas no alcohólicas con un porcentaje menor al 10 % de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, deben usar la siguiente denominación de producto:
          <br /><br />
          <b>Bebida sabor</b> _____ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera) con ___ % (un dígito) de _________________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
          <br /><br />
          <b>Nota 6</b>: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo al Aloe vera) deben representar números enteros a través de un dígito (menor a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 9.9, 5.4, 3.6, etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
          <br /><br />
          <b>Nota 7</b>: En caso de que a una bebida saborizada no alcohólica se le adicione un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza distinto al sabor que dice contener, debe declarar de igual forma el porcentaje de contenido al final de la denominación del producto conforme al 5.1.5.1.
          <br /><br />

          <b>3.8 bebidas saborizadas no alcohólicas</b>
          <br />aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contenga nningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, aguade coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 125,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.1.5.2</b> Las bebidas saborizadas no alcohólicas que no contengan un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, deben usar la siguiente denominación de producto:
          <br /><br />
          <b>Bebida sabor</b> _______ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso decombinaciones entre éstos, incluyendo al Aloe vera).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 126,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          Nota 8: Para el caso de las bebidas saborizadas no alcohólicas de este inciso 5.1.5, pueden usar alguna otra denominación establecida en un instrumento normativo o jurídico en lugar de la palabra "bebida", con la finalidad de describir al consumidor final correctamente en la etiqueta el contenido real del producto, como puede ser: agua mineral, agua, bebida carbonatada o refresco u otro cumpliendo con lo establecido en los incisos 5.1.5.1 y 5.1.5.2. Se puede utilizar el término de uso común después de la palabra Bebida como parte de la denominación y que no incurra en engaño al consumidor. No se considera que alimento líquido sea un término de uso común.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 127,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.2.4 Bebidas saborizadas no alcohólicas</b>
          <br />Las Bebidas descritas en el inciso 5.1.5 contienen un porcentaje menor al 10 % del contenido de vegetales o frutas o agua de coco o coco u hortalizas (incluyendo al Aloe vera) o contienen únicamente extractos, saborizantes naturales o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 128,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.4 Ingredientes en bebidas saborizadas no alcohólicas</b>
          <br />Las bebidas descritas en el inciso 5.1.5 que adicionen menos del 10 % de vegetal o fruta o agua de coco u hortaliza, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 129,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>6.3 Bebidas saborizadas no alcohólicas</b>
          <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza descritas en el inciso 5.1.5,pueden hacer uso de dibujos, sin ser animados en caso de colocar sellos o la leyenda de edulcorantes conforme a la Referencia normativa 2.4, de vegetales o frutas o coco u hortalizas (incluyendo al Aloe vera), dichos dibujos no deben tener un tamaño superior al 5 % del área de la superficie principal de exhibición y deben incluir la frase "dibujo ilustrativo al sabor", esta frase debe tener un tamaño de al menos el 50 % de la declaración cuantitativa del contenido neto (ver Referencia normativa 2.3) y ambos elementos deben ir cercanos a la denominación del producto.
        </p>
      )
    }
  ]
};

export default {
  DC: list,
  CC: {
    ...list,
    hasSamplingTable: false
  },
  REV: {
    ...list,
    hasSamplingTable: false
  }
};
