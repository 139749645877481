/* eslint-disable max-len */
import {
  useCallback,
  useContext,
  useEffect
} from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Storage from './storage';

import {
  EmptyLayout,
  MainLayout
} from './layouts';
import {
  SignIn,
  Error404,
  AlreadyOpen,
  Binnacle,
  DictumServiceDetail,
  ConstancyServiceDetail,
  DictumProductDetail,
  ConstancyProductDetail,
  DictumRequest,
  Step1Dictum,
  Step2Dictum,
  Step3Dictum,
  ConstancyRequest,
  Step1Constancy,
  Step2Constancy,
  Step3Constancy,
  Step4Constancy,
  Step1Revision,
  Step2Revision,
  Step3Revision,
  Step4Revision,
  SEProcedures,
  Clients,
  ClientDetail,
  UserDetail,
  Users,
  Profile,
  Tasks,
  Autologin,
  Notifications,
  OperationsReport,
  CSVReports,
  InspectorsRanking,
  Charts,
  Help,
  ApproverView,
  Errors,
  RevisionRequest,
  RevisionServiceDetail,
  RevisionProductDetail,
  MonthlyClosings,
  MonthlyClosingsDetail,
  MonthlyClosingnsFinish,
  CorrectiveActions,
  CorrectiveActionForm,
  ClientServiceReports,
  InternalAudit,
  TrainingRecords,
  TrainingRecordForm,
  LayoutsBackup,
  TasksCollaborator
} from './views';
import { TaskForm } from './views/Tasks/components';
import { Session } from './interfaces';
import { InspectionRoutes } from './InspectionRoutes';
import WitnessesInformation from './components/TaskDetail/components/WitnessesInformation';
import PlanificationSignature from './components/TaskDetail/components/PlanificationSignature';
import ClientAndInspectorStatements from './components/TaskDetail/components/ClientAndInspectorStatements';
import { useResource } from './hooks';
import { Modal } from './components';
import configuration from './configuration';
import { AppContext } from './context/AppContext';

export const App = () => {
  const {
    updateResource
  } = useResource<Session>();

  const {
    userRole,
    apiType,
    themeMode,
    userName,
    soundEffects
  } = useContext(AppContext);

  const setRoutes = useCallback(() => {
    const routesByRole = {
      quality: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='se-procedures/:serviceNumber/:action/:section' element={<SEProcedures />} />
            <Route path='profile' element={<Profile />} />
            <Route path='clients' element={<Clients />} />
            <Route path='users' element={<Users />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
            <Route path='user/detail/:userID' element={<UserDetail />} />
            <Route path='tasks/:taskID' element={<Tasks />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='help' element={<Help />} />
            <Route path='monthly-closings' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID/:inspectionID' element={<MonthlyClosingsDetail />} />
            <Route path='corrective-actions' element={<CorrectiveActions />} />
            <Route path='corrective-actions/:correctiveActionID' element={<CorrectiveActionForm />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='internal-audit' element={<InternalAudit />} />
            <Route path='training-records' element={<TrainingRecords />} />
            <Route path='training-records/:trainingRecordID' element={<TrainingRecordForm />} />
          </Route>
        </>
      ),
      master: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='dictum-request' element={<DictumRequest />}>
              <Route path='step_1/:dictumServiceID' element={<Step1Dictum />} />
              <Route path='step_2/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step2Dictum />} />
              <Route path='step_3/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step3Dictum />} />
            </Route>
            <Route path='constancy-request' element={<ConstancyRequest />}>
              <Route path='step_1/:constancyServiceID' element={<Step1Constancy />} />
              <Route path='step_2/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Constancy />} />
              <Route path='step_3/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Constancy />} />
              <Route path='step_4/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Constancy />} />
            </Route>
            <Route path='revision-request' element={<RevisionRequest />}>
              <Route path='step_1/:revisionServiceID' element={<Step1Revision />} />
              <Route path='step_2/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Revision />} />
              <Route path='step_3/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Revision />} />
              <Route path='step_4/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Revision />} />
            </Route>
            <Route path='se-procedures/:serviceNumber/:action/:section' element={<SEProcedures />} />
            <Route path='profile' element={<Profile />} />
            <Route path='clients' element={<Clients />} />
            <Route path='users' element={<Users />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
            <Route path='user/detail/:userID' element={<UserDetail />} />
            <Route path='tasks/:taskID' element={<Tasks />} />
            <Route path='task-form/:taskID/:serviceID/:serviceType' element={<TaskForm />} />
            <Route path='witnesses-information/:taskID' element={<WitnessesInformation />} />
            <Route path='planification-signature/:taskID' element={<PlanificationSignature />} />
            <Route path='client-inspector-statements/:taskID' element={<ClientAndInspectorStatements />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='approver-view' element={<ApproverView />} />
            <Route path='help' element={<Help />} />
            <Route path='monthly-closings' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID/:inspectionID' element={<MonthlyClosingsDetail />} />
            <Route path='monthly-closings-finish/:monthlyClosingID' element={<MonthlyClosingnsFinish />} />
            <Route path='corrective-actions' element={<CorrectiveActions />} />
            <Route path='corrective-actions/:correctiveActionID' element={<CorrectiveActionForm />} />
            <Route path='internal-audit' element={<InternalAudit />} />
            <Route path='training-records' element={<TrainingRecords />} />
            <Route path='training-records/:trainingRecordID' element={<TrainingRecordForm />} />
            <Route path='layouts-list' element={<LayoutsBackup />} />
          </Route>
        </>
      ),
      operator: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='dictum-request' element={<DictumRequest />}>
              <Route path='step_1/:dictumServiceID' element={<Step1Dictum />} />
              <Route path='step_2/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step2Dictum />} />
              <Route path='step_3/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step3Dictum />} />
            </Route>
            <Route path='constancy-request' element={<ConstancyRequest />}>
              <Route path='step_1/:constancyServiceID' element={<Step1Constancy />} />
              <Route path='step_2/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Constancy />} />
              <Route path='step_3/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Constancy />} />
              <Route path='step_4/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Constancy />} />
            </Route>
            <Route path='revision-request' element={<RevisionRequest />}>
              <Route path='step_1/:revisionServiceID' element={<Step1Revision />} />
              <Route path='step_2/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Revision />} />
              <Route path='step_3/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Revision />} />
              <Route path='step_4/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Revision />} />
            </Route>
            <Route path='se-procedures/:serviceNumber/:action/:section' element={<SEProcedures />} />
            <Route path='profile' element={<Profile />} />
            <Route path='clients' element={<Clients />} />
            <Route path='users' element={<Users />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
            <Route path='user/detail/:userID' element={<UserDetail />} />
            <Route path='tasks/:taskID' element={<Tasks />} />
            <Route path='task-form/:taskID/:serviceID/:serviceType' element={<TaskForm />} />
            <Route path='witnesses-information/:taskID' element={<WitnessesInformation />} />
            <Route path='planification-signature/:taskID' element={<PlanificationSignature />} />
            <Route path='client-inspector-statements/:taskID' element={<ClientAndInspectorStatements />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='help' element={<Help />} />
            <Route path='layouts-list' element={<LayoutsBackup />} />
          </Route>
        </>
      ),
      inspector: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='profile' element={<Profile />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
            <Route path='user/detail/:userID' element={<UserDetail />} />
            <Route path='tasks/:taskID' element={<Tasks />} />
            <Route path='witnesses-information/:taskID' element={<WitnessesInformation />} />
            <Route path='planification-signature/:taskID' element={<PlanificationSignature />} />
            <Route path='client-inspector-statements/:taskID' element={<ClientAndInspectorStatements />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='help' element={<Help />} />
            <Route path='approver-view' element={<ApproverView />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='monthly-closings' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID/:inspectionID' element={<MonthlyClosingsDetail />} />
            <Route path='monthly-closings-finish/:monthlyClosingID' element={<MonthlyClosingnsFinish />} />
            <Route path='corrective-actions' element={<CorrectiveActions />} />
            <Route path='corrective-actions/:correctiveActionID' element={<CorrectiveActionForm />} />
            <Route path='task-form/:taskID/:serviceID/:serviceType' element={<TaskForm />} />
            <Route path='internal-audit' element={<InternalAudit />} />
            <Route path='training-records' element={<TrainingRecords />} />
            <Route path='training-records/:trainingRecordID' element={<TrainingRecordForm />} />
          </Route>
        </>
      ),
      collaborator: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='dictum-request' element={<DictumRequest />}>
              <Route path='step_1/:dictumServiceID' element={<Step1Dictum />} />
              <Route path='step_2/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step2Dictum />} />
              <Route path='step_3/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step3Dictum />} />
            </Route>
            {/* <Route path='constancy-request' element={<ConstancyRequest />}>
              <Route path='step_1/:constancyServiceID' element={<Step1Constancy />} />
              <Route path='step_2/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Constancy />} />
              <Route path='step_3/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Constancy />} />
              <Route path='step_4/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Constancy />} />
            </Route>
            <Route path='revision-request' element={<RevisionRequest />}>
              <Route path='step_1/:revisionServiceID' element={<Step1Revision />} />
              <Route path='step_2/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Revision />} />
              <Route path='step_3/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Revision />} />
              <Route path='step_4/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Revision />} />
            </Route> */}
            <Route path='profile' element={<Profile />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='tasks-collaborator' element={<TasksCollaborator />} />
            <Route path='help' element={<Help />} />
          </Route>
        </>
      ),
      trainee: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='profile' element={<Profile />} />
          </Route>
        </>
      ),
      finance: (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='profile' element={<Profile />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='clients' element={<Clients />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
          </Route>
        </>
      ),
      'sys-admin': (
        <>
          <Route path='/' element={<EmptyLayout />}>
            <Route path='binnacle' element={<Binnacle />} />
          </Route>
          <Route path='/' element={<MainLayout />}>
            <Route path='dictum-request' element={<DictumRequest />}>
              <Route path='step_1/:dictumServiceID' element={<Step1Dictum />} />
              <Route path='step_2/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step2Dictum />} />
              <Route path='step_3/:dictumServiceID/:petitionNumber/:clientID/:collaboratorID' element={<Step3Dictum />} />
            </Route>
            <Route path='constancy-request' element={<ConstancyRequest />}>
              <Route path='step_1/:constancyServiceID' element={<Step1Constancy />} />
              <Route path='step_2/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Constancy />} />
              <Route path='step_3/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Constancy />} />
              <Route path='step_4/:constancyServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Constancy />} />
            </Route>
            <Route path='revision-request' element={<RevisionRequest />}>
              <Route path='step_1/:revisionServiceID' element={<Step1Revision />} />
              <Route path='step_2/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step2Revision />} />
              <Route path='step_3/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step3Revision />} />
              <Route path='step_4/:revisionServiceID/:requestNumber/:clientID/:collaboratorID' element={<Step4Revision />} />
            </Route>
            <Route path='se-procedures/:serviceNumber/:action/:section' element={<SEProcedures />} />
            <Route path='profile' element={<Profile />} />
            <Route path='clients' element={<Clients />} />
            <Route path='users' element={<Users />} />
            <Route path='dictum-services/detail/:dictumServiceID/:lookInTheTrash' element={<DictumServiceDetail />} />
            <Route path='constancy-services/detail/:constancyServiceID/:lookInTheTrash' element={<ConstancyServiceDetail />} />
            <Route path='revision-services/detail/:revisionServiceID/:lookInTheTrash' element={<RevisionServiceDetail />} />
            <Route path='revision-products/detail/:revisionProductID/:lookInTheTrash/:uploadFilesModal' element={<RevisionProductDetail />} />
            <Route path='dictum-products/detail/:dictumProductID/:lookInTheTrash' element={<DictumProductDetail />} />
            <Route path='constancy-products/detail/:constancyProductID/:lookInTheTrash/:uploadFilesModal' element={<ConstancyProductDetail />} />
            <Route path='client/detail/:clientID' element={<ClientDetail />} />
            <Route path='user/detail/:userID' element={<UserDetail />} />
            <Route path='tasks/:taskID' element={<Tasks />} />
            <Route path='task-form/:taskID/:serviceID/:serviceType' element={<TaskForm />} />
            <Route path='witnesses-information/:taskID' element={<WitnessesInformation />} />
            <Route path='planification-signature/:taskID' element={<PlanificationSignature />} />
            <Route path='client-inspector-statements/:taskID' element={<ClientAndInspectorStatements />} />
            <Route path='inspection/*' element={<InspectionRoutes />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='reports/operations-report' element={<OperationsReport />} />
            <Route path='reports/inspectors-ranking' element={<InspectorsRanking />} />
            <Route path='reports/csv-reports' element={<CSVReports />} />
            <Route path='reports/charts' element={<Charts />} />
            <Route path='reports/client-service' element={<ClientServiceReports />} />
            <Route path='approver-view' element={<ApproverView />} />
            <Route path='help' element={<Help />} />
            <Route path='errors/:errorID' element={<Errors />} />
            <Route path='monthly-closings' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID' element={<MonthlyClosings />} />
            <Route path='monthly-closings/:monthlyClosingID/:inspectionID' element={<MonthlyClosingsDetail />} />
            <Route path='monthly-closings-finish/:monthlyClosingID' element={<MonthlyClosingnsFinish />} />
            <Route path='corrective-actions' element={<CorrectiveActions />} />
            <Route path='corrective-actions/:correctiveActionID' element={<CorrectiveActionForm />} />
            <Route path='internal-audit' element={<InternalAudit />} />
            <Route path='training-records' element={<TrainingRecords />} />
            <Route path='training-records/:trainingRecordID' element={<TrainingRecordForm />} />
            <Route path='layouts-list' element={<LayoutsBackup />} />
            <Route path='tasks-collaborator' element={<TasksCollaborator />} />
          </Route>
        </>
      )
    };

    return routesByRole[userRole];
  }, [userRole]);

  useEffect(() => {
    setInterval(() => {
      const apiTypeAux = Storage.get('apiType');
      if (Storage.get('sessionToken') && userName !== '' && apiTypeAux) {
        updateResource(
          `/${apiTypeAux}/sessions`,
          {},
          (data) => {
            Storage.set('sessionToken', data.token);
          },
          (error: string) => Modal.fireError(error, undefined, soundEffects)
        );
      }
    }, ((configuration.jwtDurationInMinutes - 1) * 60000));
  }, [apiType, userName]);

  useEffect(() => {
    if (Storage.get('sessionToken') && userName !== '') {
      updateResource(
        `/${apiType}/sessions`,
        {},
        (data) => {
          Storage.set('sessionToken', data.token);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [apiType, userName]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const body: any = document.getElementsByTagName('body');

    if (body[0].classList) {
      if (themeMode === 'dark') {
        body[0].classList.add('body--dark-mode');
      } else {
        body[0].classList.remove('body--dark-mode');
      }
    }
  }, [themeMode]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const body: any = document.getElementsByTagName('body');

    if (body[0].classList) {
      if (process.env.REACT_APP_ENVIRONMENT === 'develop') {
        body[0].classList.add('body--develop');
      } else {
        body[0].classList.remove('body--develop');
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<EmptyLayout />}>
          <Route index element={<Navigate to='/signin/no-route' replace={true} />} />
          <Route path='signin/:route' element={<SignIn />} />
          <Route path='autologin/:userToken' element={<Autologin />} />
          <Route path='*' element={<Error404 />} />
          <Route path='app-already-open' element={<AlreadyOpen />} />
        </Route>
        {setRoutes()}
      </Routes>
    </BrowserRouter >
  );
};

export default App;
