import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../components';

import Table from '../../../../components/Table';
import { ActionEntry, TableOrderDirection } from '../../../../interfaces';

interface Props {
  history: ActionEntry[]
}

const DictumProductDetailHistory: React.FC<Props> = (props) => {
  const {
    history
  } = props;

  const { t } = useTranslation();

  return (
    <div className="dictum-product-detail__history">
      <Title title={t('services.history')} type="primary"/>
      <br/>
      <Table
        headers={[
          {
            label: 'Autor',
            value: 'author',
            format: 'none'
          },
          {
            label: t('global.action'),
            value: 'action',
            format: 'none'
          },
          {
            label: t('global.date'),
            value: 'date',
            format: 'date-time'
          }
        ]}
        items={history}
        orderBy={'date'}
        orderDirection={'DESC'}
        onChangeOrder={(_orderBy: string, _orderDirection: TableOrderDirection) => {}}
      />
    </div>
  );
};

export default DictumProductDetailHistory;
