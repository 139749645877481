/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-45',
    documentDescription: 'Lista de inspección para Dictamen de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/04/2024',
    hasPackingTable: false,
    hasSamplingTable: true,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener cuando menos la siguiente información comercial obligatoria, misma que puede aparecer en cualquier superficie del envase:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            A) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            B) Indicación de cantidad en forma escrita o gráfica;
            <br />• Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su contenido, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la cantidad en forma escrita o gráfica.
            <br />• En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial Mexicana.
            <br />Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable de la fabricación para productos nacionales.
            <br /><br />
            En el caso de productos importados, esta información debe ser proporcionada a la Secretaría de Economía o a la Procuraduría Federal del Consumidor por el importador a solicitud de cualquiera de ellas;
            <br />La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo: "Producto de...", "Hecho en...", "Manufacturado en..." u otros análogos, sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio fiscal del importador. Esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de su comercialización;
            <table className="table-content__inspector-table" id='table-on-site-1'>

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                        <td>
                          <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                          <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                            <option value="">Selecciona...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de alimentación del juguete menor o igual a 24 V, en forma gráfica o escrita que requiera para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete, cuando éste consuma para su operación una tensión mayor a 24 V.
            <br />Nota.- La información contenida en los incisos g) y h) puede ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />La información obligatoria que se ostente en las etiquetas de los juguetes debe:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin perjuicio de que adicionalmente se pueda expresar en otro sistema de unidad de medida. La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida debe expresarse también en este último, cuando menos con el mismo tamaño de letra y/o números.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>
                          <b>Magnitudes de base</b>
                        </td>
                        <td colSpan={2}>
                          Unidades SI de base
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Símbolo</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          tiempo
                        </td>
                        <td>
                          segundo
                        </td>
                        <td>
                          s
                        </td>
                      </tr>
                      <tr>
                        <td>
                          longitud
                        </td>
                        <td>
                          metro
                        </td>
                        <td>
                          m
                        </td>
                      </tr>
                      <tr>
                        <td>
                          masa
                        </td>
                        <td>
                          kilogramo
                        </td>
                        <td>
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td>
                          corriente eléctrica
                        </td>
                        <td>
                          ampere
                        </td>
                        <td>
                          A
                        </td>
                      </tr>
                      <tr>
                        <td>
                          temperatura termodinámica
                        </td>
                        <td>
                          kelvin
                        </td>
                        <td>
                          K
                        </td>
                      </tr>
                      <tr>
                        <td>
                          cantidad de sustancia
                        </td>
                        <td>
                          mol
                        </td>
                        <td>
                          mol
                        </td>
                      </tr>
                      <tr>
                        <td>
                          intensidad luminosa
                        </td>
                        <td>
                          candela
                        </td>
                        <td>
                          cd
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por pila o batería o por cualquier otra fuente de alimentación, deben cumplir sólo con lo dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 15,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un documento anexo, el instructivo de montaje o una explicación escrita o gráfica. Cuando el juguete esté destinado a ser ensamblado por un adulto, se debe indicar de modo claro esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia, así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede provocar una caída o volcar.
            <br />Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro para el consumidor, si así lo determina el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y que sean guardados en los bolsillos.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.9 Fulminante
                  <br />Cápsula de carácter explosivo, inflamable al choque o fricción
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para informar que no proporcionan protección en caso de accidente o una leyenda similar de acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes, deben llevar en el producto, en su empaque o en un documento anexo, una leyenda o gráfico alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el producto que indique que se trata de un juguete y que debe ser utilizado bajo la supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar con el cuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.1 Juguete acuático
                  <br />Es aquel que ha sido concebido, diseñado y construido para ser utilizado en el agua
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por medio de hilos, cuerdas, elásticos o cintas, deben incluir en el producto, en su envase, empaque, instructivo o en un documento anexo, una leyenda precautoria para evitar posibles riesgos para los infantes.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 24,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.2 Juguete científico o equipo experimental
                  <br />Es aquel que cumple una función de entretenimiento, que además de ser instructiva, educativa o de educación vocacional, conjuga la habilidad mental con la manual, especializándose en alguna ciencia o en cualquiera de las ramas de la misma, con instructivos adecuados para su uso
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del Sistema General de Unidades de Medida y concentración en porcentaje
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres colores diferentes como a continuación se indica:
            <br />Azul: productos alcalinos
            <br />Rojo productos ácidos
            <br />Amarillo: productos neutros.
            <br />Asimismo, deben indicar la característica principal de la sustancia utilizada, de acuerdo a la recomendación del fabricante (astringente, irritante, etc.), para que, en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 27,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo experimental, que puede ser peligroso si no se siguen adecuadamente las indicaciones del instructivo determinadas por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5 mm de altura.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de precaución y seguridad pertinentes, las cuales son, entre otras:
            <br />a) "Todo producto químico debe ser manejado como si fuera tóxico".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente a un médico llevando el producto químico y su recipiente".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            c) "PROTEGER los ojos en los experimentos indicados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            e) "LIMPIAR la mesa y los materiales utilizados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            f) "LAVARSE las manos, una vez terminados los experimentos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos químicos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su empaque, envase, instructivo o en etiqueta:
            <br /> "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.6 Juguete funcional
                  <br />Es aquel que cumple o simula cumplir la misma función que realizan productos, aparatos o instalaciones destinadas a los adultos, constituyendo a menudo un modelo a escala.
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así como de la indicación de las precauciones que debe seguir el usuario, precisando que, el no respetar estas indicaciones lo expondría a riesgos inherentes al abuso del juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-44',
    documentDescription: 'Lista de inspección para Constancia de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/04/2024',
    hasSamplingTable: false,
    hasPackingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener cuando menos la siguiente información comercial obligatoria, misma que puede aparecer en cualquier superficie del envase:
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Corroborar que la etiqueta se encuentre adherida al envase.
                  <br />En caso de que el cliente solo envíe la etiqueta, se deberá de declarar NO CUMPLIMIENTO hasta que demuestre que se encuentra en la superficie del envase.
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            A) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            B) Indicación de cantidad en forma escrita o gráfica;
            <br />• Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su contenido, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la cantidad en forma escrita o gráfica.
            <br />• En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial Mexicana.
            <br />Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable de la fabricación para productos nacionales.
            <br /><br />
            En el caso de productos importados, esta información debe ser proporcionada a la Secretaría de Economía o a la Procuraduría Federal del Consumidor por el importador a solicitud de cualquiera de ellas;
            <br />La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo: "Producto de...", "Hecho en...", "Manufacturado en..." u otros análogos, sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio fiscal del importador. Esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de su comercialización;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de alimentación del juguete menor o igual a 24 V, en forma gráfica o escrita que requiera para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete, cuando éste consuma para su operación una tensión mayor a 24 V.
            <br />Nota.- La información contenida en los incisos g) y h) puede ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />La información obligatoria que se ostente en las etiquetas de los juguetes debe:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin perjuicio de que adicionalmente se pueda expresar en otro sistema de unidad de medida. La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida debe expresarse también en este último, cuando menos con el mismo tamaño de letra y/o números.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>
                          <b>Magnitudes de base</b>
                        </td>
                        <td colSpan={2}>
                          Unidades SI de base
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Símbolo</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          tiempo
                        </td>
                        <td>
                          segundo
                        </td>
                        <td>
                          s
                        </td>
                      </tr>
                      <tr>
                        <td>
                          longitud
                        </td>
                        <td>
                          metro
                        </td>
                        <td>
                          m
                        </td>
                      </tr>
                      <tr>
                        <td>
                          masa
                        </td>
                        <td>
                          kilogramo
                        </td>
                        <td>
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td>
                          corriente eléctrica
                        </td>
                        <td>
                          ampere
                        </td>
                        <td>
                          A
                        </td>
                      </tr>
                      <tr>
                        <td>
                          temperatura termodinámica
                        </td>
                        <td>
                          kelvin
                        </td>
                        <td>
                          K
                        </td>
                      </tr>
                      <tr>
                        <td>
                          cantidad de sustancia
                        </td>
                        <td>
                          mol
                        </td>
                        <td>
                          mol
                        </td>
                      </tr>
                      <tr>
                        <td>
                          intensidad luminosa
                        </td>
                        <td>
                          candela
                        </td>
                        <td>
                          cd
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por pila o batería o por cualquier otra fuente de alimentación, deben cumplir sólo con lo dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 15,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un documento anexo, el instructivo de montaje o una explicación escrita o gráfica. Cuando el juguete esté destinado a ser ensamblado por un adulto, se debe indicar de modo claro esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia, así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede provocar una caída o volcar.
            <br />Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro para el consumidor, si así lo determina el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y que sean guardados en los bolsillos.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.9 Fulminante
                  <br />Cápsula de carácter explosivo, inflamable al choque o fricción
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para informar que no proporcionan protección en caso de accidente o una leyenda similar de acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes, deben llevar en el producto, en su empaque o en un documento anexo, una leyenda o gráfico alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el producto que indique que se trata de un juguete y que debe ser utilizado bajo la supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar con el cuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.1 Juguete acuático
                  <br />Es aquel que ha sido concebido, diseñado y construido para ser utilizado en el agua
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por medio de hilos, cuerdas, elásticos o cintas, deben incluir en el producto, en su envase, empaque, instructivo o en un documento anexo, una leyenda precautoria para evitar posibles riesgos para los infantes.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 24,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.2 Juguete científico o equipo experimental
                  <br />Es aquel que cumple una función de entretenimiento, que además de ser instructiva, educativa o de educación vocacional, conjuga la habilidad mental con la manual, especializándose en alguna ciencia o en cualquiera de las ramas de la misma, con instructivos adecuados para su uso
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del Sistema General de Unidades de Medida y concentración en porcentaje
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres colores diferentes como a continuación se indica:
            <br />Azul: productos alcalinos
            <br />Rojo productos ácidos
            <br />Amarillo: productos neutros.
            <br />Asimismo, deben indicar la característica principal de la sustancia utilizada, de acuerdo a la recomendación del fabricante (astringente, irritante, etc.), para que, en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 27,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo experimental, que puede ser peligroso si no se siguen adecuadamente las indicaciones del instructivo determinadas por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5 mm de altura.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de precaución y seguridad pertinentes, las cuales son, entre otras:
            <br />a) "Todo producto químico debe ser manejado como si fuera tóxico".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente a un médico llevando el producto químico y su recipiente".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            c) "PROTEGER los ojos en los experimentos indicados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            e) "LIMPIAR la mesa y los materiales utilizados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            f) "LAVARSE las manos, una vez terminados los experimentos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos químicos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su empaque, envase, instructivo o en etiqueta:
            <br /> "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.6 Juguete funcional
                  <br />Es aquel que cumple o simula cumplir la misma función que realizan productos, aparatos o instalaciones destinadas a los adultos, constituyendo a menudo un modelo a escala.
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así como de la indicación de las precauciones que debe seguir el usuario, precisando que, el no respetar estas indicaciones lo expondría a riesgos inherentes al abuso del juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos, advertencias y garantías
            <br />6.1 Requisitos generales
            <br />La información señalada en el presente capítulo debe expresarse en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Instructivos
            <br />Los juguetes objeto de la aplicación de esta Norma Oficial Mexicana deben ir acompañados de instructivos o presentarse en el empaque, cuando así lo determine el fabricante, sin cargo adicional.
            <br />Dichos instructivos deben contener las indicaciones claras y precisas para su uso normal, conservación y mejor aprovechamiento, así como de las advertencias necesarias para el manejo seguro y confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Advertencias
            <br />Ya sea en el producto, empaque o instructivo, cuando así lo determine el fabricante, se debe ostentar la siguiente información:
            <br />- Precauciones que deba tomar el usuario para el manejo, uso o disfrute del juguete.
            <br />- Indicaciones de conexión o ensamble para su adecuado funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            - Las leyendas de advertencia relativas a la inclusión de productos o partes pequeñas contenidas en un juguete, sólo deben presentarse cuando el juguete contenga dichas partes y esté destinado a niños menores de cinco años. Los juguetes destinados a niños de hasta 3 años, no deben contener piezas pequeñas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Garantía
            <br />La garantía es opcional. Únicamente cuando el fabricante nacional, importador o comercializador responsable del juguete ofrezca garantía del mismo, ésta debe establecerse en los términos dispuestos por la Ley Federal de Protección al Consumidor
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-44',
    documentDescription: 'Lista de inspección para Constancia de la NOM-015-SCFI-2007, Información comercial-Etiquetado para juguetes',
    documentIssueDate: '01/04/2024',
    hasSamplingTable: false,
    hasPackingTable: false,
    hasMeasurementEquipment: false,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Especificaciones de información comercial
            <br />
            5.1 Requisitos generales de información comercial
            <br />
            5.1.1 La información de los juguetes debe ser veraz; describirse y presentarse de forma
            tal que no induzca a error o engaño al consumidor con respecto a la naturaleza y
            características de los juguetes, observándose en todo caso lo dispuesto por la Ley
            Federal de Protección al Consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Información comercial obligatoria
            <br />Los productos sujetos a la aplicación de esta Norma Oficial Mexicana deben contener cuando menos la siguiente información comercial obligatoria, misma que puede aparecer en cualquier superficie del envase:
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Corroborar que la etiqueta se encuentre adherida al envase.
                  <br />En caso de que el cliente solo envíe la etiqueta, se deberá de declarar NO CUMPLIMIENTO hasta que demuestre que se encuentra en la superficie del envase.
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            A) Nombre genérico del producto, cuando éste no sea plenamente identificable a simple vista por el consumidor
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            B) Indicación de cantidad en forma escrita o gráfica;
            <br />• Los juguetes que se comercialicen por cuenta numérica en empaques que permitan ver su contenido, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica en envases que no permitan ver su contenido, pero éste sea obvio y contengan una sola unidad, no requieren presentar declaración de cantidad.
            <br />• Los juguetes que se comercialicen por cuenta numérica, pero no se encuentren en los supuestos a que se refieren los incisos b.1 y b.2 antes citados, deben indicar la cantidad en forma escrita o gráfica.
            <br />• En caso de que la declaración sea escrita, ésta debe expresarse de manera ostensible y fácilmente legible de forma tal que el tamaño y tipo de letra permita al consumidor su lectura, como lo dispone el inciso c) del punto 5.1.3 de la presente Norma Oficial Mexicana.
            <br />Los juguetes que se comercialicen en unidades de masa o de volumen deben indicar la cantidad en forma escrita, de forma tal que el tamaño y tipo de letra permitan al consumidor su lectura, como lo dispone el inciso a) del punto 5.1.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal del fabricante o responsable de la fabricación para productos nacionales.
            <br /><br />
            En el caso de productos importados, esta información debe ser proporcionada a la Secretaría de Economía o a la Procuraduría Federal del Consumidor por el importador a solicitud de cualquiera de ellas;
            <br />La Secretaría de Economía o la Procuraduría Federal del Consumidor proporcionará esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d) Leyenda que identifique el país de origen del producto, por ejemplo: "Producto de...", "Hecho en...", "Manufacturado en..." u otros análogos, sujeto a lo dispuesto en los tratados internacionales de los que los Estados Unidos Mexicanos sean parte;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            e) Tratándose de productos importados: nombre, denominación o razón social y domicilio fiscal del importador. Esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de su comercialización;
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            f) Leyenda o símbolo que indique la edad del consumidor recomendada sólo por el fabricante para su uso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            g) En su caso, el tipo y cantidad de pilas y/o baterías o de cualquier otra fuente de alimentación del juguete menor o igual a 24 V, en forma gráfica o escrita que requiera para su funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            h) En su caso, las características eléctricas nominales de alimentación del juguete, cuando éste consuma para su operación una tensión mayor a 24 V.
            <br />Nota.- La información contenida en los incisos g) y h) puede ostentarse adicionalmente en el instructivo del juguete.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Idioma y términos.
            <br />La información obligatoria que se ostente en las etiquetas de los juguetes debe:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            a) Cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002, sin perjuicio de que adicionalmente se pueda expresar en otro sistema de unidad de medida. La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida debe expresarse también en este último, cuando menos con el mismo tamaño de letra y/o números.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>
                          <b>Magnitudes de base</b>
                        </td>
                        <td colSpan={2}>
                          Unidades SI de base
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Símbolo</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          tiempo
                        </td>
                        <td>
                          segundo
                        </td>
                        <td>
                          s
                        </td>
                      </tr>
                      <tr>
                        <td>
                          longitud
                        </td>
                        <td>
                          metro
                        </td>
                        <td>
                          m
                        </td>
                      </tr>
                      <tr>
                        <td>
                          masa
                        </td>
                        <td>
                          kilogramo
                        </td>
                        <td>
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td>
                          corriente eléctrica
                        </td>
                        <td>
                          ampere
                        </td>
                        <td>
                          A
                        </td>
                      </tr>
                      <tr>
                        <td>
                          temperatura termodinámica
                        </td>
                        <td>
                          kelvin
                        </td>
                        <td>
                          K
                        </td>
                      </tr>
                      <tr>
                        <td>
                          cantidad de sustancia
                        </td>
                        <td>
                          mol
                        </td>
                        <td>
                          mol
                        </td>
                      </tr>
                      <tr>
                        <td>
                          intensidad luminosa
                        </td>
                        <td>
                          candela
                        </td>
                        <td>
                          cd
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            b) Presentarse en la etiqueta del juguete o del producto que lo contiene, cuando este sea el caso, de manera tal que permanezca disponible hasta el momento de su adquisición por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            c) Ostentarse de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura. Exclusivamente la información señalada en el presente capítulo debe expresarse en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Los accesorios elaborados con cualquier material, incluyendo los operados por pila o batería o por cualquier otra fuente de alimentación, deben cumplir sólo con lo dispuesto en esta Norma Oficial Mexicana, cuando se vendan por separado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 15,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Requisitos específicos de información comercial
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Los juguetes destinados a ser ensamblados deben presentar en el empaque o en un documento anexo, el instructivo de montaje o una explicación escrita o gráfica. Cuando el juguete esté destinado a ser ensamblado por un adulto, se debe indicar de modo claro esta circunstancia.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Las resbaladillas (toboganes), columpios, anillos y trapecios para hacer gimnasia, así como las cuerdas y juguetes similares, fijados a un pórtico, deben ir acompañados de instrucciones que llamen la atención sobre la necesidad de verificar y dar mantenimiento periódicamente a sus partes principales (suspensiones, fijaciones, anclados, etc.) y precisar que en el caso de que estas verificaciones no se efectúen, el juguete puede provocar una caída o volcar.
            <br />Asimismo, el instructivo debe indicar aquellas partes que puedan presentar un peligro para el consumidor, si así lo determina el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 En el instructivo o en el empaque de los juguetes con proyectiles, se debe advertir que no se deben utilizar otros proyectiles distintos a los suministrados o los recomendados por el fabricante, y que no se deben disparar a personas ni animales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Los fulminantes de los juguetes deben llevar una leyenda precautoria para evitar que se detonen cerca de: los ojos, delos oídos, productos y/o envases inflamables, y que sean guardados en los bolsillos.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.9 Fulminante
                  <br />Cápsula de carácter explosivo, inflamable al choque o fricción
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 Las imitaciones de equipos de protección deben llevar una leyenda precautoria para informar que no proporcionan protección en caso de accidente o una leyenda similar de acuerdo al tipo de producto, por ejemplo: "Esto es un juguete. No proporciona seguridad
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 Los monopatines y patines de rueda para niños, que se distribuyen como juguetes, deben llevar en el producto, en su empaque o en un documento anexo, una leyenda o gráfico alusivo que indique que se deben utilizar acompañados del equipo de protección adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.2.7 Los juguetes acuáticos inflables deben llevar una leyenda precautoria en el producto que indique que se trata de un juguete y que debe ser utilizado bajo la supervisión de un adulto. Dicha leyenda debe ser indeleble y su color contrastar con el cuerpo del juguete. Las letras deben de tener una altura mínima de 1,5 mm.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.1 Juguete acuático
                  <br />Es aquel que ha sido concebido, diseñado y construido para ser utilizado en el agua
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            5.2.8 Los juguetes destinados a ser fijados a partes de cunas o de carriolas por medio de hilos, cuerdas, elásticos o cintas, deben incluir en el producto, en su envase, empaque, instructivo o en un documento anexo, una leyenda precautoria para evitar posibles riesgos para los infantes.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 24,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9 Los juguetes científicos deben contener las siguientes indicaciones
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.2 Juguete científico o equipo experimental
                  <br />Es aquel que cumple una función de entretenimiento, que además de ser instructiva, educativa o de educación vocacional, conjuga la habilidad mental con la manual, especializándose en alguna ciencia o en cualquiera de las ramas de la misma, con instructivos adecuados para su uso
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.1 Los recipientes individuales de sustancias químicas deben ostentar en una etiqueta su nombre químico, nombre comercial, en su caso, contenido en unidades del Sistema General de Unidades de Medida y concentración en porcentaje
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.2 Las etiquetas de los envases de los productos químicos deben ser de tres colores diferentes como a continuación se indica:
            <br />Azul: productos alcalinos
            <br />Rojo productos ácidos
            <br />Amarillo: productos neutros.
            <br />Asimismo, deben indicar la característica principal de la sustancia utilizada, de acuerdo a la recomendación del fabricante (astringente, irritante, etc.), para que, en caso de accidente se pueda disponer de los medios oportunos de solución inmediata.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 27,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.3 El envase debe indicar en una de sus caras laterales la siguiente información:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            a) Leyenda precautoria en la que se mencione que es un juguete científico o un equipo experimental, que puede ser peligroso si no se siguen adecuadamente las indicaciones del instructivo determinadas por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            b) Recomendación de conservar a la mano el instructivo para el uso adecuado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            c) Edad del consumidor recomendada por el fabricante, para el uso del juguete científico
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.4 El tamaño mínimo de la letra de la leyenda precautoria debe ser al menos de 1,5 mm de altura.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.5 Debe llevar un instructivo explícito que contenga todas las leyendas de precaución y seguridad pertinentes, las cuales son, entre otras:
            <br />a) "Todo producto químico debe ser manejado como si fuera tóxico".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            b) "En caso de existir duda, por ingestión, accidente o herida, consultar urgentemente a un médico llevando el producto químico y su recipiente".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.2.9.6 Asimismo, deben indicarse las reglas generales de seguridad siguientes:
            <br />a) "LEER las instrucciones, seguirlas y conservarlas como referencia";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            b) "MANTENER ALEJADOS a los niños menores de 5 años de la zona donde se realiza el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            c) "PROTEGER los ojos en los experimentos indicados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            d) "GUARDAR los juegos de química fuera del alcance de los niños menores de 5 años";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            e) "LIMPIAR la mesa y los materiales utilizados";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            f) "LAVARSE las manos, una vez terminados los experimentos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            g) "NO UTILIZAR otros materiales que no hayan sido recomendados por el fabricante";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            h) "NO COMER, BEBER, NI FUMAR en la zona donde se realice el experimento";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            i) "EVITAR todo contacto con los ojos y piel, así como la ingestión de los productos químicos";
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            j) "NO UTILIZAR los recipientes originales para guardar alimentos".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.2.10 En los disfraces se debe contener una leyenda precautoria que prevenga la no utilización del mismo cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.2.11 En las tiendas de campaña y en las casas de juguete, concebidas para que los niños puedan introducirse, debe incluirse en el instructivo o empaque, una leyenda precautoria para no utilizarse cerca del fuego.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Los juguetes funcionales deben ostentar la indicación siguiente, ya sea en su empaque, envase, instructivo o en etiqueta:
            <br /> "¡ATENCION! UTILIZAR BAJO LA VIGILANCIA DE UN ADULTO"
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  4.13.1.6 Juguete funcional
                  <br />Es aquel que cumple o simula cumplir la misma función que realizan productos, aparatos o instalaciones destinadas a los adultos, constituyendo a menudo un modelo a escala.
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.2.12 Además de ir acompañados de instrucciones de uso establecidas por el fabricante, así como de la indicación de las precauciones que debe seguir el usuario, precisando que, el no respetar estas indicaciones lo expondría a riesgos inherentes al abuso del juguete. También debe indicarse que el juguete debe mantenerse fuera del alcance de los niños menores a la edad recomendada por el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos, advertencias y garantías
            <br />6.1 Requisitos generales
            <br />La información señalada en el presente capítulo debe expresarse en idioma español, sin perjuicio de presentarse además en otros idiomas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Instructivos
            <br />Los juguetes objeto de la aplicación de esta Norma Oficial Mexicana deben ir acompañados de instructivos o presentarse en el empaque, cuando así lo determine el fabricante, sin cargo adicional.
            <br />Dichos instructivos deben contener las indicaciones claras y precisas para su uso normal, conservación y mejor aprovechamiento, así como de las advertencias necesarias para el manejo seguro y confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Advertencias
            <br />Ya sea en el producto, empaque o instructivo, cuando así lo determine el fabricante, se debe ostentar la siguiente información:
            <br />- Precauciones que deba tomar el usuario para el manejo, uso o disfrute del juguete.
            <br />- Indicaciones de conexión o ensamble para su adecuado funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            - Las leyendas de advertencia relativas a la inclusión de productos o partes pequeñas contenidas en un juguete, sólo deben presentarse cuando el juguete contenga dichas partes y esté destinado a niños menores de cinco años. Los juguetes destinados a niños de hasta 3 años, no deben contener piezas pequeñas.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Garantía
            <br />La garantía es opcional. Únicamente cuando el fabricante nacional, importador o comercializador responsable del juguete ofrezca garantía del mismo, ésta debe establecerse en los términos dispuestos por la Ley Federal de Protección al Consumidor
          </p>
        )
      }
    ]
  }
};
