import React, {
  ReactElement,
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useResource, useNavigate } from '../../hooks';

import {
  Accordeon,
  Button,
  Header,
  Title,
  Filters,
  Modal,
  SubTitle,
  Icon,
  TaskCard,
  TaskDetail,
  InspectionDetail,
  Switch
} from '../../components';
import {
  ConstancyProduct,
  DictumProduct,
  FilterItem,
  FilterTypes,
  Inspection,
  Query,
  RevisionProduct,
  SelectOption,
  Task,
  User
} from '../../interfaces';
import {
  Calendar,
  TasksList,
  InspectionsList
} from './components';
import { ModalInspectionDetail } from './components/ModalInspectionDetail/ModalInspectionDetail';
import './styles.scss';
import {
  emptyConstancyProduct,
  emptyDictumProduct,
  emptyQuery,
  emptyRevisionProduct,
  emptyUser
} from '../../emptyObjects';
import storage from '../../storage';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';

const dayjs = require('dayjs');

type ListsTypes = 'tasks' | 'inspections';

interface Counter {
  scheduled: number
  'in-progress': number
}

interface CounterInspections {
  'in-progress': number
  'in-review': number
  concluded: number
}

interface AccordeonItem {
  title: string
  element: ReactElement
}

const Tasks = () => {
  const params = useParams();
  const {
    taskID: paramTaskID
  } = params;

  const {
    fetchResources
  } = useResource<Task>();

  const {
    fetchResources: fetchInspections,
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getDictumProduct
  } = useResource<DictumProduct>();

  const {
    fetchResource: getConstancyProduct
  } = useResource<ConstancyProduct>();

  const {
    fetchResource: getRevisionProduct
  } = useResource<RevisionProduct>();

  const {
    fetchResources: getUsers,
    fetchResource: getUser
  } = useResource<User>();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    userRole,
    soundEffects,
    inspection,
    setInspection,
    visibleInspectionDetailModal,
    setVisibleInspectionDetailModal,
    visibleTaskDetailModal,
    setVisibleTaskDetailModal,
    informationReceivedFromWebsocket,
    userID
  } = useContext(AppContext);

  const [isPending, startTransition] = useTransition();
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const [statusTasksCounter, setStatusTasksCounter] = useState<Counter>({
    scheduled: 0,
    'in-progress': 0
  });
  const [statusInspectionsCounter, setStatusInspectionsCounter] = useState<CounterInspections>({
    'in-progress': 0,
    'in-review': 0,
    concluded: 0
  });
  const [selectedOption, setSelectedOption] = useState<FilterTypes>(storage.get('selectedOptionOnTasksView') as FilterTypes || 'none');
  const [filterValue, setFilterValue] = useState<string>(storage.get('filterValueOnTasksView') || '');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [user, setUser] = useState<User>(emptyUser);
  const [itemsAccordeon, setItemsAccordeon] = useState<AccordeonItem[]>([]);
  const [reFetchData, setReFetchData] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<ListsTypes>(storage.get('selectedListOnTasksView') as ListsTypes || 'tasks');
  const [inspectorsList, setInspectorsList] = useState<SelectOption[]>([]);
  const [dictumProduct, setDictumProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [constancyProduct, setConstancyProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [revisionProduct, setRevisionProduct] = useState<RevisionProduct>(emptyRevisionProduct);

  const getTodayTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['scheduled', 'in-progress'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        if (tasks[index].status !== 'concluded') {
          auxTasks.push(
            <TaskCard
              key={index}
              task={tasks[index]}
              onClick={(_task: Task) => {
                storage.set('lastTaskDetailID', _task.id);
                setVisibleTaskDetailModal({
                  visible: true,
                  taskID: _task.id
                });
              }}
            />
          );
        }
      }
    }

    return auxTasks;
  };

  const getTodayConcludedTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['concluded'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        auxTasks.push(
          <TaskCard
            key={index}
            task={tasks[index]}
            onClick={(_task: Task) => {
              storage.set('lastTaskDetailID', _task.id);
              setVisibleTaskDetailModal({
                visible: true,
                taskID: _task.id
              });
            }}
          />
        );
      }
    }

    return auxTasks;
  };

  const handleTriggerFilter = (filter: FilterTypes, value: string) => {
    setSelectedOption(filter);
    setFilterValue(value);
    storage.set('selectedOptionOnTasksView', filter);
    storage.set('filterValueOnTasksView', value);
  };

  const getInspectorIDFilter = () => {
    if (selectedOption === 'person') {
      return filterValue;
    }

    if (
      [
        'scheduled',
        'taskStatus',
        'inspectionStatus'
      ].includes(selectedOption)) {
      return '';
    }

    if (userRole === 'inspector') {
      return user.id;
    }

    return '';
  };

  useEffect(() => {
    if (informationReceivedFromWebsocket.action === 're-fetch-tasks-and-inspections') {
      if (selectedList === 'inspections') {
        throttledGetInspections();
      }
      if (selectedList === 'tasks') {
        throttledGetTasks();
      }
    }
  }, [informationReceivedFromWebsocket]);

  const getTasks = () => {
    let fetchBody: Query = emptyQuery;

    let auxFilters = {};

    if (filterValue !== 'all') {
      auxFilters = {
        s_responsibleID: getInspectorIDFilter(),
        s_type: selectedOption === 'taskType' ? filterValue : '',
        s_status: selectedOption === 'taskStatus' ? filterValue : '',
        n_number: selectedOption === 'number' ? filterValue : ''
      };
    }

    fetchBody = {
      resourcePath: '/admin/tasks',
      filters: auxFilters
    };

    fetchResources(
      fetchBody,
      (data) => {
        startTransition(() => {
          setTasks(data.items);
        });
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const getInspections = () => {
    let fetchBody: Query = emptyQuery;

    fetchBody = {
      resourcePath: '/admin/inspections',
      filters: {
        s_inspectorID: getInspectorIDFilter(),
        s_number: selectedOption === 'number' ? filterValue : '',
        s_clientBusinessName: selectedOption === 'clientBusinessName' ? filterValue : '',
        s_norm: selectedOption === 'normCode' ? filterValue : '',
        s_productCode: selectedOption === 'productCode' ? filterValue : '',
        s_productInvoice: selectedOption === 'productInvoice' ? filterValue : '',
        b_listCompleted: selectedOption === 'listCompleted' ? filterValue : '',
        s_status: selectedOption === 'inspectionStatus' ? filterValue : ''
      }
    };

    if (selectedOption === 'scheduled') {
      fetchBody = {
        resourcePath: fetchBody.resourcePath,
        filters: {
          ...fetchBody.filters,
          b_scheduled: filterValue
        }
      };
    }

    if (selectedOption === 'complies') {
      fetchBody = {
        resourcePath: fetchBody.resourcePath,
        filters: {
          ...fetchBody.filters,
          b_complies: filterValue
        }
      };
    }

    if (filterValue === 'all') {
      fetchBody = {
        resourcePath: fetchBody.resourcePath,
        filters: {}
      };
    }

    fetchInspections(
      fetchBody,
      (data) => {
        startTransition(() => {
          setInspections(data.items);
        });
      },
      (error) => {
        Modal.fireError(error, undefined, soundEffects);
      }
    );
  };

  const throttledGetTasks = utils.throttle(getTasks, 1000);
  const throttledGetInspections = utils.throttle(getInspections, 1000);

  useEffect(() => {
    const auxSelectedOption = storage.get('selectedOptionOnTasksView');
    const auxFilterValue = storage.get('filterValueOnTasksView');

    if (auxSelectedOption !== 'none') {
      setSelectedOption(auxSelectedOption as FilterTypes);
    }

    if (auxFilterValue) {
      setFilterValue(auxFilterValue);
    }
  }, []);

  useEffect(() => {
    if (selectedList === 'inspections') {
      throttledGetInspections();
    }
    if (selectedList === 'tasks') {
      throttledGetTasks();
    }
  }, [
    filterValue,
    selectedOption,
    selectedList,
    userRole,
    user,
    reFetchData
  ]);

  useEffect(() => {
    if (selectedList === 'inspections' && visibleInspectionDetailModal.visible) {
      getInspection(
        `/admin/inspections/${visibleInspectionDetailModal.inspectionID}`,
        (data) => {
          setInspection(data);

          if (['DC', 'DN'].includes(data.service.code)) {
            getDictumProduct(
              `/admin/dictum-products/${data.product.id}`,
              (productData) => {
                setDictumProduct(productData);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['CC', 'CN'].includes(data.service.code)) {
            getConstancyProduct(
              `/admin/constancy-products/${data.product.id}`,
              (productData) => {
                setConstancyProduct(productData);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['REV'].includes(data.service.code)) {
            getRevisionProduct(
              `/admin/revision-products/${data.product.id}`,
              (productData) => {
                setRevisionProduct(productData);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [selectedList, visibleInspectionDetailModal]);

  const throttledGetUsers = utils.throttle(
    () => {
      getUsers(
        {
          resourcePath: '/admin/users',
          filters: {
            s_role: 'inspector'
          }
        },
        (data) => {
          const auxCustomItems: SelectOption[] = [];
          const users = data.items.filter(item => item.isActive === true);

          for (let index = 0; index < users.length; index += 1) {
            auxCustomItems.push(
              {
                text: users[index].name,
                value: users[index].id
              }
            );
          }

          if (users.length === 0) {
            auxCustomItems.push(
              {
                text: t('tasks.inspectorsNotFound'),
                value: 'none'
              }
            );
          }

          setInspectorsList(auxCustomItems);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  const throttledGetUser = utils.throttle(
    () => {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    if (userRole === 'inspector') {
      throttledGetUser();
    }

    let lastTaskID = storage.get('lastTaskDetailID');

    if ((lastTaskID === undefined || lastTaskID === '')
      && paramTaskID !== 'none'
      && paramTaskID !== undefined) {
      lastTaskID = paramTaskID;
    }

    if (lastTaskID) {
      setVisibleTaskDetailModal({
        visible: true,
        taskID: lastTaskID
      });
    }

    const lastInspectionID = storage.get('lastInspectionDetailID');

    if (lastInspectionID) {
      setVisibleInspectionDetailModal({
        visible: true,
        inspectionID: lastInspectionID
      });
    }

    throttledGetUsers();
  }, [userRole]);

  useEffect(() => {
    let scheduledCounter: number = 0;
    let inProgressCounter: number = 0;

    const setCounters: { [name: string]: () => void } = {
      scheduled: () => {
        scheduledCounter += 1;
      },
      'in-progress': () => {
        inProgressCounter += 1;
      },
      concluded: () => { },
      deleted: () => { }
    };

    for (let index = 0; index < tasks.length; index += 1) {
      setCounters[tasks[index].status]();
    }

    setStatusTasksCounter({
      scheduled: scheduledCounter,
      'in-progress': inProgressCounter
    });
  }, [tasks]);

  useEffect(() => {
    let concludedCounter: number = 0;
    let inProgressCounter: number = 0;
    let inReviewCounter: number = 0;

    const setCounters: { [name: string]: () => void } = {
      'in-progress': () => {
        inProgressCounter += 1;
      },
      'in-review': () => {
        inReviewCounter += 1;
      },
      concluded: () => {
        concludedCounter += 1;
      }
    };

    for (let index = 0; index < inspections.length; index += 1) {
      if (inspections[index].status === 'in-progress') {
        if (inspections[index].indicators.waitingForFilesForRemoteRound === false
          && inspections[index].inspector !== null) {
          setCounters[inspections[index].status]();
        }
      } else {
        setCounters[inspections[index].status]();
      }
    }

    setStatusInspectionsCounter({
      'in-progress': inProgressCounter,
      'in-review': inReviewCounter,
      concluded: concludedCounter
    });
  }, [inspections]);

  useEffect(() => {
    if (selectedList === 'inspections') {
      setFilters([
        {
          text: t('tasks.filters.assigned'),
          value: 'scheduled'
        },
        {
          text: t('tasks.filters.number'),
          value: 'number'
        },
        {
          text: t('tasks.filters.client'),
          value: 'clientBusinessName'
        },
        {
          text: 'Inspector',
          value: 'person',
          selectOptions: inspectorsList
        },
        {
          text: t('tasks.filters.norm'),
          value: 'normCode'
        },
        {
          text: t('tasks.filters.complies'),
          value: 'complies'
        },
        {
          text: t('tasks.filters.productCode'),
          value: 'productCode'
        },
        {
          text: t('tasks.filters.invoice'),
          value: 'productInvoice'
        },
        {
          text: t('tasks.listCompleted'),
          value: 'listCompleted'
        },
        {
          text: t('global.status.title'),
          value: 'inspectionStatus'
        },
        {
          text: t('global.allInspections'),
          value: 'all'
        }
      ]);
    } else if (selectedList === 'tasks') {
      setFilters([
        {
          text: t('tasks.filters.taskResponsable'),
          value: 'person',
          selectOptions: inspectorsList
        },
        {
          text: t('tasks.filters.type'),
          value: 'taskType'
        },
        {
          text: t('global.status.title'),
          value: 'taskStatus'
        },
        {
          text: t('tasks.filters.number'),
          value: 'number'
        },
        {
          text: t('global.allTasks'),
          value: 'all'
        }
      ]);
    }
  }, [selectedList, inspectorsList]);

  useEffect(() => {
    const auxItemsAccordeon: AccordeonItem[] = [];

    if (['master', 'operator'].includes(userRole) || userID === '65b19002e12252003bda789e') {
      auxItemsAccordeon.push({
        title: t('tasks.actions'),
        element: (
          <div >
            <Button
              onClick={() => navigate('/task-form/none/none/none')}
              type='primary-outlined'
              label={t('tasks.createTask') || ''}
              iconPosition='left'
              fullWidth={true}
              size='big'
              icon='inspector'
              alignContent='left'
              disabled={!['master', 'operator', 'inspector'].includes(userRole) || (userRole === 'inspector' && userID !== '65b19002e12252003bda789e')}
            />
          </div>
        )
      });
    }

    auxItemsAccordeon.push(
      {
        title: t('tasks.calendar'),
        element: (
          <>
            <Calendar
              tasks={tasks}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
            <div className="tasks-view__main__left-container__today-tasks">
              <Icon type='tape' className="tasks-view__main__left-container__today-tasks__icon" />
              {
                dayjs(currentDate).format('DD/MM/YYYY') !== dayjs(new Date()).format('DD/MM/YYYY') ? (
                  // eslint-disable-next-line max-len
                  <SubTitle subTitle={`${t('tasks.tasksTo')} ${dayjs(currentDate).format('DD MMM YYYY')} (${getTodayTasks().length})`} />
                ) : (
                  <SubTitle subTitle={`${t('tasks.todayTasks')} (${getTodayTasks().length})`} />
                )
              }
              {
                getTodayTasks().map((task: ReactElement) => (
                  task
                ))
              }
              {
                getTodayConcludedTasks().length > 0 && (
                  <div className='tasks-view__concluded-task'>
                    <div className='tasks-view__concluded-task__separator'></div>
                    <p className='tasks-view__concluded-task__text'>{t('tasks.concluded')}</p>
                    <div className='tasks-view__concluded-task__separator'></div>
                  </div>
                )
              }
              {
                getTodayConcludedTasks().map((task: ReactElement) => (
                  task
                ))
              }
            </div>
          </>
        )
      }
    );

    setItemsAccordeon(auxItemsAccordeon);
  }, [
    userRole,
    tasks,
    selectedList,
    currentDate,
    t
  ]);

  return (
    <div className='tasks-view'>
      <Header
        title={t('tasks.title')}
        showBackbutton={false}
      />
      <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
        <TaskDetail
          onClose={() => {
            setVisibleTaskDetailModal({
              taskID: '',
              visible: false
            });
            storage.set('lastTaskDetailID', '');
          }}
          setVisibleTaskDetailModal={setVisibleTaskDetailModal}
          visibleTaskDetailModal={visibleTaskDetailModal}
          lookInTheTrash={false}
          onDeletedTask={getTasks}
        />
        <ModalInspectionDetail
          type='custom'
          visible={visibleInspectionDetailModal.visible}
          customComponent={
            <>
              <Title title={`${t('tasks.inspection')}: ${inspection.number}`} />
              <InspectionDetail
                lookInTheTrash={false}
                setShowProductInformation={() => { }}
                dictumProduct={dictumProduct}
                constancyProduct={constancyProduct}
                revisionProduct={revisionProduct}
                getInspections={getInspections}
              />
            </>
          }
          onCancel={() => {
            setVisibleInspectionDetailModal({
              inspectionID: '',
              visible: false
            });
            storage.set('lastInspectionDetailID', '');
            setReFetchData(!reFetchData);
          }}
        />
      </Suspense>
      <div className="tasks-view__main">
        <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
          <div className="tasks-view__main__left-container">
            <Accordeon items={itemsAccordeon} />
          </div>
        </Suspense>
        <div className="tasks-view__main__right-container">
          <div className="tasks-view__main__right-container__top-content">
            <div className='tasks-view__main__right-container__top-content__left-container'>
              <Title title={t('tasks.selectList')} type='secondary' />
              <Switch
                leftLabel={{
                  text: t('tasks.tasks'),
                  value: 'tasks'
                }}
                rigthLabel={{
                  text: t('tasks.inspections'),
                  value: 'inspections'
                }}
                defaultChecked={storage.get('selectedListOnTasksView') === 'tasks'}
                onChange={() => {
                  if (selectedList === 'inspections') {
                    setSelectedList('tasks');
                    storage.set('selectedListOnTasksView', 'tasks');
                  } else {
                    setSelectedList('inspections');
                    storage.set('selectedListOnTasksView', 'inspections');
                  }
                }}
              />
            </div>
            <div className='tasks-view__main__right-container__top-content__rigth-container'>
              <Title title={t('tasks.filterBy')} type='secondary' />
              <Filters
                options={filters}
                onTriggerFilter={handleTriggerFilter}
                orientation='horizontal'
                defaultSelectedFilter={storage.get('selectedOptionOnTasksView') as FilterTypes}
                defaultFilterValue={(storage.get('filterValueOnTasksView') || '')}
              />
            </div>
          </div>
          <div className="tasks-view__main__right-container__status-column-container">
            {
              selectedList === 'inspections' ? (
                <InspectionsList
                  items={inspections}
                  statusCounter={statusInspectionsCounter}
                  isPending={isPending}
                />
              ) : (
                <TasksList
                  items={tasks}
                  statusCounter={statusTasksCounter}
                />
              )
            }
          </div>
        </div>
      </div>
    </div >
  );
};

export default Tasks;
