import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '..';
import { IconTypes } from '../Icon/iconList';

import './styles.scss';

interface Props {
  id: string
  folderName: string
  hasItems: boolean
  filesQuantity: number
  selected: boolean
  onClick: (_id: string) => void
  hasError: boolean
  subtitle?: string
}

const FileFolder: React.FC<Props> = (props: Props) => {
  const {
    id = '',
    folderName = '',
    hasItems = false,
    filesQuantity = 0,
    selected,
    onClick = () => { },
    hasError,
    subtitle = ''
  } = props;

  const { t } = useTranslation();

  const setClassFolder = () => {
    if (hasError) {
      return 'file-folder__box file-folder__box--error';
    }

    if (hasItems) {
      return 'file-folder__box file-folder__box--active';
    }
    return 'file-folder__box file-folder__box--inactive';
  };

  const setFolderIcon = (): IconTypes => {
    let iconName: IconTypes = 'folder';

    if (selected) {
      iconName = 'folderOpen';
    }

    if (!hasItems) {
      iconName = 'folderInactive';
    }

    if (selected && !hasItems) {
      iconName = 'folderInactiveOpen';
    }

    return iconName;
  };

  return (
    <div className={selected ? 'file-folder file-folder--selected' : 'file-folder'} onClick={() => { onClick(id); }}>
      <div className={setClassFolder()}>
        {
          hasError && (
            <Icon
              type='alertRed'
              alt={'Folder Error'}
              onClick={() => { }}
              className='file-folder__box__error-icon'
            />
          )
        }
        <Icon
          type={setFolderIcon()}
          alt={'Carpeta Inactiva'}
          onClick={() => { }}
          className=""
        />
      </div>
      {
        subtitle.length > 0 && (
          <div className="file-folder__folder-text-small">
            <span>{subtitle}</span>
          </div>
        )
      }
      <div className="file-folder__folder-name">
        <span className="file-folder__folder-name__label">{folderName}</span>
      </div>
      <div className="file-folder__folder-text-small">
        <span>{`${filesQuantity} ${t('global.files')}`}</span>
      </div>
    </div>
  );
};

export default FileFolder;
