import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import {
  Header,
  InspectionDetail
} from '../../components';
import {
  DictumProduct,
  ConstancyProduct,
  InspectionSections,
  RevisionProduct
} from '../../interfaces';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

interface Props {
  setShowProductInformation: (_value: boolean) => void
  inspectionID: string
  setInspectionID: (_value: string) => void
  dictumProduct: DictumProduct
  constancyProduct: ConstancyProduct
  revisionProduct: RevisionProduct
  setLookInTheTrash: (_value: string) => void
  onFetchInspection: () => void
  setFetchInspection: (_value: boolean) => void
}

const InspectionDetailView: React.FC<Props> = (props) => {
  const {
    setShowProductInformation,
    setInspectionID,
    dictumProduct,
    constancyProduct,
    revisionProduct,
    setLookInTheTrash,
    onFetchInspection,
    setFetchInspection
  } = props;

  const {
    inspection
  } = useContext(AppContext);

  const params = useParams();

  const { t } = useTranslation();

  const {
    inspectionID,
    lookInTheTrash,
    section,
    fetchInspection
  } = params;

  useEffect(() => {
    // eslint-disable-next-line no-unneeded-ternary
    setFetchInspection(fetchInspection === 'false' ? false : true);
    setInspectionID(inspectionID || '');
  }, [inspectionID, fetchInspection]);

  useEffect(() => {
    setLookInTheTrash(lookInTheTrash || '');
  }, [lookInTheTrash]);

  return (
    <div className='inspection-detail'>
      <Header
        title={t('inspections.detail', { design: constancyProduct.indicators.design ? t('global.forDesign') : '' })}
        subTitle={[
          {
            label: inspection.product.invoice,
            title: t('global.invoice') || ''
          },
          {
            label: `${inspection.rounds.length > 0 ? inspection.rounds[inspection.rounds.length - 1].id : ''}`,
            title: t('inspections.round') || ''
          },
          {
            label: `${inspection.product.subInvoice}`,
            title: t('global.subInvoice') || ''
          }
        ]}
        showBackbutton={true}
      />
      {
        inspection.id && (
          <InspectionDetail
            lookInTheTrash={lookInTheTrash === 'true'}
            setShowProductInformation={setShowProductInformation}
            dictumProduct={dictumProduct}
            constancyProduct={constancyProduct}
            revisionProduct={revisionProduct}
            onFetchInspection={onFetchInspection}
            selectedSection={section as InspectionSections}
          />
        )
      }
    </div>
  );
};

export default InspectionDetailView;
