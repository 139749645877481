import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useResource, useNavigate } from '../../hooks';
import CreateClient from '../ClientDetail/components/ClientForm';

import {
  Accordeon,
  Button,
  Header,
  Filters,
  Modal,
  Table,
  ActionButtons
} from '../../components';

import {
  ActionButton,
  Client,
  Collaborator,
  FilterTypes,
  TableOrderDirection
} from '../../interfaces';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

const ClientsList = () => {
  const {
    fetchResources
  } = useResource<Client>();

  const navigate = useNavigate();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const [refetch, setRefetch] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState<FilterTypes>('none');
  const [filterValue, setFilterValue] = useState<string>('none');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderDirection, setOrderDirection] = useState<TableOrderDirection>('DESC');
  const [clients, setClients] = useState<Client[]>([]);
  const [createClientModalVisible, setCreateClientModalVisible] = useState<boolean>(false);
  const [buttons, setButtons] = useState<ActionButton[]>([]);

  const handleTriggerFilter = (filter: FilterTypes, value: string) => {
    setFilterBy(filter);
    setFilterValue(value);
  };

  useEffect(() => {
    const request: any = {
      resourcePath: 'admin/clients',
      filters: {
        s_status: filterBy === 'clientStatus' ? filterValue : '',
        s_shortName: filterBy === 'shortName' ? filterValue : ''
      },
      pagination: {
        n_currentPage: currentPage,
        n_perPage: 10,
        n_totalItems: 10
      },
      ordering: {
        s_orderBy: orderBy,
        s_orderDirection: orderDirection
      }
    };

    fetchResources(
      request,
      (data) => {
        setClients(data.items);
        setCurrentPage(data.pagination.currentPage);
        setTotalItems(data.pagination.totalItems);
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [
    refetch,
    orderBy,
    orderDirection,
    currentPage,
    filterValue
  ]);

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              setCreateClientModalVisible(true);
            }}
            type='primary-outlined'
            label={t('clients.createClient') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='plusWhite'
            alignContent='left'
          />
        ),
        available: ['master', 'finance'].includes(userRole),
        specialValidation: ['master', 'finance'].includes(userRole)
      }
    ]);
  }, [userRole, i18n.language]);

  return (
    <div className="clients-list">
      <Header
        title={t('clients.title')}
        showBackbutton={false}
      />
      <CreateClient
        visible={createClientModalVisible}
        title={t('clients.registerClient')}
        onClose={() => {
          setCreateClientModalVisible(false);
        }}
        onSuccess={(_client: Client | Collaborator) => {
          setCreateClientModalVisible(false);

          Modal.fireSuccess(
            t('global.correct'),
            t('clients.createdClient'),
            setOpenModal,
            () => {
              setCreateClientModalVisible(false);
              setRefetch(!refetch);
            },
            soundEffects
          );
        }}
        onError={(error: string) => {
          setCreateClientModalVisible(false);
          Modal.fireError(error, undefined, soundEffects);
        }}
      />
      <div className="clients-list__main">
        <div className="clients-list__main__left-container">
          <Accordeon
            items={[
              {
                title: t('global.filer'),
                element: (
                  <Filters
                    options={[
                      {
                        text: t('global.name'),
                        value: 'shortName'
                      },
                      {
                        text: t('global.status.title'),
                        value: 'clientStatus'
                      }
                    ]}
                    onTriggerFilter={handleTriggerFilter}
                    disableSearchOnPressKey={createClientModalVisible}
                  />
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <div className="clients-list__main__right-container">
          <Table
            headers={[
              {
                label: t('clients.shortName'),
                value: 'shortName',
                format: 'none'
              },
              {
                label: t('clients.businessName'),
                value: 'businessName',
                format: 'none'
              },
              {
                label: 'RFC',
                value: 'rfc',
                format: 'none'
              },
              {
                label: t('global.status.title'),
                value: 'status',
                format: 'status'
              },
              {
                label: t('global.createdAtTitle'),
                value: 'createdAt',
                format: 'date-time'
              }
            ]}
            items={clients}
            currentPage={currentPage}
            perPage={10}
            totalItems={totalItems}
            orderBy={orderBy}
            orderDirection={orderDirection}
            onChangeCurrentPage={(newCurrentPage: number) => {
              setCurrentPage(newCurrentPage);
            }}
            onChangeOrder={(newOrderBy: string, newOrderDirection: TableOrderDirection) => {
              setOrderBy(newOrderBy);
              setOrderDirection(newOrderDirection);
            }}
            onClickRow={(client: Client) => {
              navigate(`/client/detail/${client.id}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientsList;
