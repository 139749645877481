import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { ConstancyServiceStatus } from '../../../../interfaces';

interface Props {
  status: ConstancyServiceStatus
}

const ConstancyServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const constancyServiceStatuses: ConstancyServiceStatus[] = ['inspecting-products', 'concluded'];

  const translatedConstancyServiceStatuses: string[] = [];

  constancyServiceStatuses.forEach((element: ConstancyServiceStatus) => {
    translatedConstancyServiceStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="constancy-service-detail__progress-indicator" id="data-section">
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedConstancyServiceStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default ConstancyServiceDetailDocuments;
