import React, {
  ReactElement
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  RadioList,
  Select
} from '../../../../../components';
import rejectReasonsEn from '../../../../../dictionaries/rejectReasons-en';
import rejectReasonsEs from '../../../../../dictionaries/rejectReasons-es';
import statusDictionaryEn from '../../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../../dictionaries/statusDictionary-es';
import dictumServiceActionsEnabledEn from '../../../../../dictionaries/dictumServiceActionsEnabled-en';
import dictumServiceActionsEnabledEs from '../../../../../dictionaries/dictumServiceActionsEnabled-es';
import dictumProductActionsEnabledEn from '../../../../../dictionaries/dictumProductActionsEnabled-en';
import dictumProductActionsEnabledEs from '../../../../../dictionaries/dictumProductActionsEnabled-es';
import revisionServiceActionsEnabledEn from '../../../../../dictionaries/revisionServiceActionsEnabled-en';
import revisionServiceActionsEnabledEs from '../../../../../dictionaries/revisionServiceActionsEnabled-es';
import revisionProductActionsEnabledEn from '../../../../../dictionaries/revisionProductActionsEnabled-en';
import revisionProductActionsEnabledEs from '../../../../../dictionaries/revisionProductActionsEnabled-es';
import constancyServiceActionsEnabledEn from '../../../../../dictionaries/constancyServiceActionsEnabled-en';
import constancyServiceActionsEnabledEs from '../../../../../dictionaries/constancyServiceActionsEnabled-es';
import constancyProductActionsEnabledEn from '../../../../../dictionaries/constancyProductActionsEnabled-en';
import constancyProductActionsEnabledEs from '../../../../../dictionaries/constancyProductActionsEnabled-es';
import { SelectOption } from '../../../../../interfaces';

interface Props {
  filterValue: string
  onChangeInput: (_value: string | number) => void
  selectedFilter: string
}

const Filters: React.FC<Props> = (props) => {
  const {
    filterValue,
    onChangeInput,
    selectedFilter
  } = props;

  const { t, i18n } = useTranslation();

  const rejectReasonsSelectOptions = (): SelectOption[] => {
    const keys = Object.keys(i18n.language === 'es' ? rejectReasonsEs : rejectReasonsEn);
    const options: SelectOption[] = [];

    for (let index = 0; index < keys.length; index += 1) {
      options.push(
        {
          text: i18n.language === 'es' ? (rejectReasonsEs as { [name: string]: string })[keys[index]] : (rejectReasonsEn as { [name: string]: string })[keys[index]],
          value: `${index + 1}`
        }
      );
    }

    return options;
  };

  const onChangePetitionNumber = (_value: string | number, _id: string) => {
    let userInput = `${_value}`;

    if (userInput.length > `${filterValue}`.length) {
      if (userInput.length === 2 && userInput[2] !== ' ') {
        userInput += ' ';
      }
      if (userInput.length === 5 && userInput[5] !== ' ') {
        userInput += ' ';
      }
      if (userInput.length === 10 && userInput[10] !== ' ') {
        userInput += ' ';
      }
    }

    if (userInput.length <= 18) {
      onChangeInput(userInput);
    }
  };

  const filters: { [name: string]: ReactElement } = {
    role: (
      <Select
        options={[
          {
            text: t('global.rolesNames.operator'),
            value: 'operator'
          },
          {
            text: 'Inspector',
            value: 'inspector'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    email: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('global.email') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    clientStatus: (
      <Select
        options={[
          {
            text: t('global.active'),
            value: 'active'
          },
          {
            text: t('global.inactive'),
            value: 'inactive'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    shortName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.shortName') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    person: (
      <Select
        options={[]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    clientID: (
      <Select
        options={[]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    name: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.name') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    petitionNumber: (
      <Input
        type='petitionNumber'
        value={filterValue}
        onChange={onChangePetitionNumber}
        placeholder={t('services.petitionNumber') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    clientBusinessName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.titleS') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    inspectorName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.inspectorName') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    collaboratorName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.collaboratorName') || ''}
        id='input-filter'
        autofocus={true}
      />
    ),
    createdAt: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        autofocus={true}
      />
    ),
    updatedAt: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        autofocus={true}
      />
    ),
    onSiteVisitDate: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        autofocus={true}
      />
    ),
    requestNumber: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.constancyNumber') || ''}
        autofocus={true}
      />
    ),
    productStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['invoice-assigned'] : statusDictionaryEn['invoice-assigned'],
            value: 'invoice-assigned'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['validation-in-progress'] : statusDictionaryEn['validation-in-progress'],
            value: 'validation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.validated : statusDictionaryEn.validated,
            value: 'validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['not-validated'] : statusDictionaryEn['not-validated'],
            value: 'not-validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['cancellation-in-progress'] : statusDictionaryEn['cancellation-in-progress'],
            value: 'cancellation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-inspection'] : statusDictionaryEn['in-inspection'],
            value: 'in-inspection'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    dictumServiceStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['validating-invoices'] : statusDictionaryEn['validating-invoices'],
            value: 'validating-invoices'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['inspecting-products'] : statusDictionaryEn['inspecting-products'],
            value: 'inspecting-products'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    constancyProductStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-inspection'] : statusDictionaryEn['in-inspection'],
            value: 'in-inspection'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    inspectionStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-progress'] : statusDictionaryEn['in-progress'],
            value: 'in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-review'] : statusDictionaryEn['in-review'],
            value: 'in-review'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    fase: (
      <Select
        options={[
          {
            text: 'II',
            value: 'II'
          },
          {
            text: 'III',
            value: 'III'
          },
          {
            text: 'N/A',
            value: 'N/A'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    labelingMode: (
      <Select
        options={[
          {
            text: 'PA',
            value: 'PA'
          },
          {
            text: 'PB',
            value: 'PB'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    constancyServiceStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['inspecting-products'] : statusDictionaryEn['inspecting-products'],
            value: 'inspecting-products'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    dictumProductStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs['invoice-assigned'] : statusDictionaryEn['invoice-assigned'],
            value: 'invoice-assigned'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['validation-in-progress'] : statusDictionaryEn['validation-in-progress'],
            value: 'validation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.validated : statusDictionaryEn.validated,
            value: 'validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['not-validated'] : statusDictionaryEn['not-validated'],
            value: 'not-validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['cancellation-in-progress'] : statusDictionaryEn['cancellation-in-progress'],
            value: 'cancellation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.cancelled : statusDictionaryEn.cancelled,
            value: 'cancelled'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-inspection'] : statusDictionaryEn['in-inspection'],
            value: 'in-inspection'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    taskStatus: (
      <Select
        options={[
          {
            text: i18n.language === 'es' ? statusDictionaryEs.scheduled : statusDictionaryEn.scheduled,
            value: 'scheduled'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-progress'] : statusDictionaryEn['in-progress'],
            value: 'in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    invoice: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.invoices') || ''}
        autofocus={true}
      />
    ),
    normCode: (
      <Select
        options={[
          {
            text: '003',
            value: '003'
          },
          {
            text: '004',
            value: '004'
          },
          {
            text: '015',
            value: '015'
          },
          {
            text: '024',
            value: '024'
          },
          {
            text: '020',
            value: '020'
          },
          {
            text: '050',
            value: '050'
          },
          {
            text: '051',
            value: '051'
          },
          {
            text: '141',
            value: '141'
          },
          {
            text: '142',
            value: '142'
          },
          {
            text: '173',
            value: '173'
          },
          {
            text: '187',
            value: '187'
          },
          {
            text: '189',
            value: '189'
          },
          {
            text: '235',
            value: '235'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
        id='input-filter'
      />
    ),
    serviceCode: (
      <Select
        options={[
          {
            text: t('services.dictum'),
            value: 'DC'
          },
          {
            text: t('services.constancy'),
            value: 'CC'
          },
          {
            text: t('services.dictumNegation'),
            value: 'DN'
          },
          {
            text: t('services.constancyNegation'),
            value: 'CN'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
        id='input-filter'
      />
    ),
    SEInvalidationReason: (
      <Select
        options={rejectReasonsSelectOptions()}
        onChange={onChangeInput}
        value={filterValue}
        id='input-filter'
      />
    ),
    brand: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.brand') || ''}
        autofocus={true}
      />
    ),
    number: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.number') || ''}
        autofocus={true}
      />
    ),
    complies: (
      <RadioList
        options={[
          {
            label: t('global.complies'),
            value: 'true'
          },
          {
            label: t('global.notComplies'),
            value: 'false'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    listCompleted: (
      <RadioList
        options={[
          {
            label: t('services.complete'),
            value: 'true'
          },
          {
            label: t('services.notComplete'),
            value: 'false'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    serviceNumber: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.serviceNumber') || ''}
        autofocus={true}
      />
    ),
    date: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        autofocus={true}
      />
    ),
    text: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('global.searchData') || ''}
        autofocus={true}
      />
    ),
    boolean: (
      <RadioList
        options={[
          {
            label: t('global.yes'),
            value: 'true'
          },
          {
            label: 'No',
            value: 'false'
          },
          {
            label: t('global.undefined'),
            value: 'null'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    revisionServiceActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? revisionServiceActionsEnabledEs['register-invoiced'] : revisionServiceActionsEnabledEn['register-invoiced'],
              value: 'register-invoiced'
            },
            {
              text: i18n.language === 'es' ? revisionServiceActionsEnabledEs['assign-products'] : revisionServiceActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? revisionServiceActionsEnabledEs['edit-service'] : revisionServiceActionsEnabledEn['edit-service'],
              value: 'edit-service'
            }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    revisionProductActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? revisionProductActionsEnabledEs['upload-files'] : revisionProductActionsEnabledEn['upload-files'],
              value: 'upload-files'
            },
            {
              text: i18n.language === 'es' ? revisionProductActionsEnabledEs['assign-products'] : revisionProductActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? revisionProductActionsEnabledEs['edit-products'] : revisionProductActionsEnabledEn['edit-products'],
              value: 'edit-products'
            }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    constancyServiceActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? constancyServiceActionsEnabledEs['register-invoiced'] : constancyServiceActionsEnabledEn['register-invoiced'],
              value: 'register-invoiced'
            },
            {
              text: i18n.language === 'es' ? constancyServiceActionsEnabledEs['assign-products'] : constancyServiceActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? constancyServiceActionsEnabledEs['edit-service'] : constancyServiceActionsEnabledEn['edit-service'],
              value: 'edit-service'
            }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    constancyProductActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? constancyProductActionsEnabledEs['upload-files'] : constancyProductActionsEnabledEn['upload-files'],
              value: 'upload-files'
            },
            {
              text: i18n.language === 'es' ? constancyProductActionsEnabledEs['assign-products'] : constancyProductActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? constancyProductActionsEnabledEs['edit-products'] : constancyProductActionsEnabledEn['edit-products'],
              value: 'edit-products'
            }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    dictumServiceActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['register-invoiced'] : dictumServiceActionsEnabledEn['register-invoiced'],
              value: 'register-invoiced'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['assign-products'] : dictumServiceActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['request-inspection'] : dictumServiceActionsEnabledEn['request-inspection'],
              value: 'request-inspection'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['assign-out-of-customs-date'] : dictumServiceActionsEnabledEn['assign-out-of-customs-date'],
              value: 'assign-out-of-customs-date'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['upload-paid-petition'] : dictumServiceActionsEnabledEn['upload-paid-petition'],
              value: 'upload-paid-petition'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['validate-products'] : dictumServiceActionsEnabledEn['validate-products'],
              value: 'validate-products'
            },
            {
              text: i18n.language === 'es' ? dictumServiceActionsEnabledEs['edit-service'] : dictumServiceActionsEnabledEn['edit-service'],
              value: 'edit-service'
            }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    ),
    dictumProductActionsEnabled: (
      <Select
        options={
          [
            {
              text: i18n.language === 'es' ? dictumProductActionsEnabledEs['have-procedure-se'] : dictumProductActionsEnabledEn['have-procedure-se'],
              value: 'have-procedure-se'
            },
            {
              text: i18n.language === 'es' ? dictumProductActionsEnabledEs['assign-products'] : dictumProductActionsEnabledEn['assign-products'],
              value: 'assign-products'
            },
            {
              text: i18n.language === 'es' ? dictumProductActionsEnabledEs['edit-products'] : dictumProductActionsEnabledEn['edit-products'],
              value: 'edit-products'
            }
            // {
            // eslint-disable-next-line max-len
            //   text: i18n.language === 'es' ? dictumProductActionsEnabledEs['delete-invoice'] : dictumProductActionsEnabledEn['delete-invoice'],
            //   value: 'delete-invoice'
            // }
          ]}
        value={filterValue}
        onChange={onChangeInput}
        id='input-filter'
      />
    )
  };

  return (filters[selectedFilter] || <></>);
};

export default Filters;
