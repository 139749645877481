import configuration from '../configuration';

const CONTACT_SUPPORT_MESSAGE = `Please try again and if the problem persists contact support.  ${configuration.supportPhoneNumber}`;
const RESOURCE_WAS_NOT_CREATED = `Due to an unknown error, the record could not be created ${CONTACT_SUPPORT_MESSAGE}`;
const RESOURCE_WAS_NOT_UPDATED = `Due to an unknown error, the registry could not be updated. ${CONTACT_SUPPORT_MESSAGE}`;
const RESOURCE_WAS_NOT_FOUND = 'We did not find the resource';
const RESOURCE_WAS_NOT_DELETED = `Due to an unknown error, the record could not be deleted. ${CONTACT_SUPPORT_MESSAGE}`;

const errorsDictionary = {
  RESOURCE_WAS_NOT_CREATED,
  RESOURCE_WAS_NOT_UPDATED,
  RESOURCE_WAS_NOT_FOUND,
  RESOURCE_WAS_NOT_DELETED,
  INSPECTIONS_WERE_NOT_FOUND: `No inspections found. ${CONTACT_SUPPORT_MESSAGE}`,
  RFC_ALREADY_EXIST: `RFC already exists in our records.${CONTACT_SUPPORT_MESSAGE}`,
  SHORT_NAME_ALREADY_EXIST: `Short name already exists in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  EMAIL_ALREADY_EXIST: `The email already exists in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  PETITION_NUMBER_ALREADY_EXIST: `The petition number already exists in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_STATUS_CHANGE: `Due to the current status of the record, the action could not be completed. ${CONTACT_SUPPORT_MESSAGE}`,
  ENTITY_CANNOT_BE_UPDATED: `Due to the current status of the record, it will not be updated. ${CONTACT_SUPPORT_MESSAGE}`,
  FILE_DOES_NOT_EXIST: `The file does not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_CONTRACT_DATE: `The contract signing date cannot be later than today. ${CONTACT_SUPPORT_MESSAGE}`,
  ADDRESS_WAS_NOT_FOUND: `Address does not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_FASE_FOR_NORM_051: `For norms 051, 173, 187 and 235 only phases 2 or 3 are accepted. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_ALREADY_HAVE_A_TASK_SCHEDULED: `The chosen products have already been assigned in another task. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_WAS_NOT_FOUND: `The document does not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  FILE_WAS_NOT_SENT: `Failed to include the file(s). ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_ALREADY_EXIST: `The document you are trying to send already exists whit this name. ${CONTACT_SUPPORT_MESSAGE}`,
  COLLABORATOR_WAS_NOT_FOUND: `The collaborator does not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_WERE_NOT_FOUND: `The products do not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  MISSING_INVALIDATION_REASON: `An invalidated product was flagged and no reason was specified, please review your data. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_DOES_NOT_ACCEPT_MORE_PRODUCTS: `Due to its current state, no more products can be added. ${CONTACT_SUPPORT_MESSAGE}`,
  GROUPED_PRODUCTS_CANNOT_BE_DELETED_BY_ID: `Grouped products cannot be deleted individually. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_CANNOT_BE_UPDATED: `Due to their status, products cannot be updated. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_DOES_NOT_ACCEPT_DOCUMENT_MODIFICATIONS: `Document upload is no longer available. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ONLY_ACCEPTS_ONE_OF_THIS_TYPE_OF_DOCUMENT: `You can only upload this type of document once. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_READ_FILE: `Could not read file. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOES_NOT_BELONG_TO_SERVICE: `The file does not correspond to this request. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT: `We did not find invoices in the paid motion, make sure you upload the correct file. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_DOCUMENT_TYPE: `The document type is not valid. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_DOES_NOT_BELONG_TO_USER: `This inspection is not assigned to you, so you cannot make changes to it. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_HAS_BEEN_CONCLUDED: `The inspection has already finished, therefore it is not possible to complete the action. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_WAS_NOT_FOUND: `The round does not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  CLIENTS_WERE_NOT_FOUND: `"Customers do not exist in our records. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_DOES_NOT_BELONG_TO_THE_SAME_CLIENT: `The selected invoices do not belong to the same client. ${CONTACT_SUPPORT_MESSAGE}`,
  VISIT_ADDRESS_WAS_NOT_SENT: `You must choose the visiting address for remote inspections. ${CONTACT_SUPPORT_MESSAGE}`,
  TIME_WAS_NOT_SENT: `The visit time must be chosen for remote inspections. ${CONTACT_SUPPORT_MESSAGE}`,
  MASTER_USER_CANNOT_BE_DELETED: `This user cannot be deleted. ${CONTACT_SUPPORT_MESSAGE}`,
  UNAUTHORIZED: `You do not have permission to perform this action. ${CONTACT_SUPPORT_MESSAGE}`,
  UNEXPECTED_ERROR: `Unexpected error. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_CREDENTIALS: `Incorrect email or password. ${CONTACT_SUPPORT_MESSAGE}`,
  VALIDATION_ERROR: `Invalid data entered. ${CONTACT_SUPPORT_MESSAGE}`,
  INVALID_FILE_TYPE: `Invalid file type. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOES_NOT_BELONG_TO_THE_SERVICE: `The selected file does not correspond to this service, check that the request numbers match. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_IMAGES_FOUND: `No images were found to generate the document. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_GENERATE_DOCUMENT: `Due to an unexpected error, the document(s) could not be generated. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_IS_NOT_READY_FOR_A_VISIT: `The service is not yet ready to request a visit. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_IS_ALREADY_WAITING_FOR_A_VISIT: `A visit has already been requested for this service. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_PRODUCTS_ARE_ABLE_TO_BE_INSPECTED: `The service has no products to inspect. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_CURRENTLY_IN_REVIEW: `Inspection is not currently in review. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTOR_CANNOT_APPROVE_HIMSELF: `You cannot pass your own inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  APPROVER_DOES_NOT_HAS_THE_REQUIRED_NORMS: `You do not have the required standards to pass the inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_COMPLETE: `The inspection has not been completed, therefore it cannot be submitted for review, please upload evidence of the inspection or their respective "Inspection Format" in the round. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ONSITE: `The task is not on site, therefore it does not require witness signatures or arrival time. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_DOES_NOT_BELONG_TO_USER: `The task does not belong to you, so you cannot make changes to it. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NO_VISIT_RECORDS: `A visit has not yet been scheduled for the task, so witness signatures cannot be uploaded yet.} ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ABLE_TO_CLOSE: `The task is not in review or in progress, therefore it cannot be closed. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_IS_NOT_IN_REVIEW: `The task cannot be closed as all its inspections must be in review or assigned to new task. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WAS_NOT_FOUND: `Inspection not found. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ON_SITE: `The task is not on site, therefore declarations from the client or inspector are not required. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_CONCLUDED: `The task has already finished, therefore no changes can be made to it. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOM_DATE_CANNOT_BE_BEFORE_SERVICE_CREATION_DATE: `The clearance date cannot be before the service creation date. ${CONTACT_SUPPORT_MESSAGE}`,
  DICTUM_SERVICE_CANNOT_BE_UPDATED: `The service cannot be updated due to its current status. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_DOCUMENT_IS_REQUIRED_FIRST: `To upload the rectified petition it is necessary to upload the paid petition first. ${CONTACT_SUPPORT_MESSAGE}`,
  ORIGINAL_PETITION_NUMBER_WAS_NOT_FOUND_IN_THE_DOCUMENT: `The document does not contain a reference to the original petition number. ${CONTACT_SUPPORT_MESSAGE}`,
  SOME_INVOICES_WERE_NOT_FOUND_IN_THE_DOCUMENT: `Some invoices were not found in the document, it is necessary that all the invoices related to this petition appear in the document. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_IS_NOT_ABLE_TO_UPDATE_BECAUSE_YOUR_INSPECTION_IS_IN_PROGRESS: `Product is not able to update, because your inspections is already in progress. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOM_DATE_CANNOT_BE_MORE_THAN_TODAY: `Out of custom date cannot be more than today. ${CONTACT_SUPPORT_MESSAGE}`,
  THE_CHECK_IN_TIME_IS_NOT_WITHIN_WORKING_HOURS: `The check-in time is not within business hours, from 7am to 10pm. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NOT_HAVE_PLANIFICATION_SIGNATURE: `The task does not have the signature of the client of the "Planning and Inspection" registered. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_HAS_NOT_HAVE_VISITOR_STATEMENTS: `No statements from the inspector have yet been recorded. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTOR_IS_NOT_APPROVER: `You cannot pass the inspection since you are not an approver. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_DONT_ABLE_TO_UPLOAD_DOCUMENTS: `The product is already being inspected therefore it is no longer possible to upload documents. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_NOT_IN_PROGRESS: `The inspection is not in progress, therefore it cannot be sent for review. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_ABLE_TO_CLOSE_BECAUSE_INSPECTIONS_STATUS: `The task cannot be closed, all its inspections must be assigned to another task or must be finished or in review. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_IS_NOT_COCNLUDED: `The task cannot be closed, all its inspections must be finished. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_WERE_NOT_FOUND_TO_LAYOUT_2: `No products were found to send in Layout 2. ${CONTACT_SUPPORT_MESSAGE}`,
  NO_INVOICE_IS_CONCLUDED: `No completed products were found to generate dictums. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_ARE_NOT_UNATACCHED: `All task inspections are not finished yet. ${CONTACT_SUPPORT_MESSAGE}`,
  ALREADY_SET_PRODUCT_NOT_PRESENTED: `The product has already been declared as not presented. ${CONTACT_SUPPORT_MESSAGE}`,
  THE_PRODUCT_CANNOT_BE_UPDATED_DUE_TO_THE_STATUS_OF_ITS_INSPECTION: `The product cannot be updated due to the status of its inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_LIST_ALREADY_SAVE: `The inspection list has already been saved, it cannot be saved again. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_IS_ALREADY_DICTAMINATE: `The inspection has already been dictaminated. ${CONTACT_SUPPORT_MESSAGE}`,
  EVIDENCE_FILE_LIMIT_EXCEEDED: `Evidence file limit exceeded (maximum 50 documents). ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_FEEDBACK_FILE_HAS_ALREADY_UPLOADED: `The inspection form was already uploaded before, it is not possible to upload it again. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_FORM_IS_NOT_IN_PDF_FORMAT: `The file is not in the correct format, it must be a PDF file. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_INSPECTIONS_NOT_IN_VALID_STATUS: `All task inspections must be in approval, completed or in different round to upload witness signatures. ${CONTACT_SUPPORT_MESSAGE}`,
  WITNESSES_SIGNS_ARE_NOT_UPLOADED_YET: `Witness signature(s) must be uploaded in order to upload statements. ${CONTACT_SUPPORT_MESSAGE}`,
  TOO_MANY_REQUESTS: `Too many requests. ${CONTACT_SUPPORT_MESSAGE}`,
  STEP_ALREADY_DONE: `Action not allowed, due to the current state of the product and inspection, this step cannot be performed again when carrying out the inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_DOES_NOT_ACCEPT_DOCUMENTS: `Due to the status of the inspection, documents are no longer accepted. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_FORMAT_IS_REQUIRED: `It is necessary to upload the "Inspection Form" to be able to send the inspection to be approved. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_STEPS_HAVE_NOT_BEEN_DONE_YET: `The steps to carry out the inspection (update product, make inspection list, dictaminate and upload evidence) must be completed to send it for approval. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_LIST_NOT_COMPLETED: `The inspection list must be made to be able to dictaminate. ${CONTACT_SUPPORT_MESSAGE}`,
  QUESTIONS_NOT_UPLOADED_YET: `The questions have not been uploaded yet, therefore the inspection list cannot be generated yet. ${CONTACT_SUPPORT_MESSAGE}`,
  QUESTIONS_ARE_MANDATORY: `The questions are mandatory to save the Inspection List. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_HAS_NOT_UPLOADED_EVIDENCE: `The round has no files uploaded yet. ${CONTACT_SUPPORT_MESSAGE}`,
  SAMPLING_REJECTED_IS_ZERO: `At least one item on the inspection list has been answered as 'not comply', therefore the number of rejected items cannot be 0 (zero). ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY_FROM_EXPIRED_INVOICE: `You cannot generate a Constancy of an expired product. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_ARE_EXPIRED_ONLY_CAN_BE_APPROVED: `The inspection has expired, therefore it can only be approved. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_EMPTY: `There are no invoices available to generate document, the file is empty. ${CONTACT_SUPPORT_MESSAGE}`,
  UPLOADED_DOCUMENT_EMPTY: `The file you are trying to upload is empty. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_COMPLY_INSPECTION_LIST_WHIT_NOT_REJECTED_SAMPLING: `At least one item on the inspection list was marked as "Not comply", therefore at least 1 sample rejected in sampling must be marked. ${CONTACT_SUPPORT_MESSAGE}`,
  COMPLY_INSPECTION_LIST_WHIT_REJECTED_SAMPLING: `Rejected samples were marked in the sampling, therefore at least one item on the inspection list must be marked as "Not comply". ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WITH_NOT_COMPLY_INSPECTION_LIST_DOESNT_COMPLY: `At least one item on the inspection list was marked as "Not Compliant", therefore the inspection cannot be dictaminate as "Compliant". ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY_WHITOUT_INSPECTION_LIST: `The Constancy cannot be generated since the folio inspection list has not been made. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DICTUM_WHITOUT_INSPECTION_LIST: `The Dictum cannot be generated since the folio inspection list has not been made. ${CONTACT_SUPPORT_MESSAGE}`,
  EXPIRED_PRODUCT_CANNOT_BE_DELETED: `You cannot delete an expired invoice. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_NEW_ROUND_FROM_COMPLIES_ROUND: `You cannot send a new round to be generated from a round that was marked as 'Complies'. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_WITH_NOT_COMPLY_INSPECTION_LIST_DOESNT_NOT_COMPLY: `No item on the 'Inspection List' was marked as 'Not compliant', therefore it cannot be ruled as 'Not compliant'. ${CONTACT_SUPPORT_MESSAGE}`,
  RECTIFIED_PETITION_NUMBER_ITS_SAME_AS_PETITION_NUMBER: `The rectified petition number is the same as the petition number. ${CONTACT_SUPPORT_MESSAGE}`,
  RESPONSIBLE_DOES_NOT_HAS_THE_REQUIRED_NORMS: `The inspector does not manage the nom necessary to make the inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_DOCUMENT_FROM_ANOTHER_ROUND: `Documents from past rounds cannot be deleted. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_TO_DELETE_DOCUMENTS: `Documents cannot be removed from inspection due to their current status. ${CONTACT_SUPPORT_MESSAGE}`,
  COULD_NOT_GENERATE_SERVICE_REQUEST: `The 'Service Request' cannot be downloaded due to the status of the request, it must be completed in order to download the document. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_SERVICE: `The service cannot be deleted due to its current status. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_DELETE_INVOICE: `The invoice cannot be deleted due to its current status. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_IS_NOT_CLOSED: `The task is not closed yet, the document cannot be downloaded. ${CONTACT_SUPPORT_MESSAGE}`,
  CURRENTLY_THE_ROUND_DOES_NOT_ACCEPT_UPLOADING_EVIDENCE: `Currently the round does not accept uploading round files. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_TO_GENERATE_DICTUMS: `The documents cannot be generated, since there is no paid petition document. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_NOT_PRESENTED_IN_ROUND: `You cannot save the inspection list for a round where the product was not presented. ${CONTACT_SUPPORT_MESSAGE}`,
  TASK_SCHEDULED_FOR_TODAY: `You cannot start the task because it is not scheduled for today. ${CONTACT_SUPPORT_MESSAGE}`,
  ALL_GROUPED_PRODUCTS_MUST_BE_INCLUDED: `The dictum cannot be generated, since all the grouped products must be concluded. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCT_CANNOT_BE_DELETED_BECAUSE_STATUS: `The product cannot be eliminated, since it is in actions before H.E. ${CONTACT_SUPPORT_MESSAGE}`,
  DESIGN_FILE_HAS_ALREADY_UPLOADED: `The design file has already been uploaded previously. ${CONTACT_SUPPORT_MESSAGE}`,
  DESIGN_INSPECTOR_CANNOT_BE_SAME_AT_LATER_INSPECTORS: `The person responsible for the task cannot be assigned, since it cannot be the same as the inspector of the first round of label design for a selected inspection. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ALREADY_INVOICED: `The service has already been marked as billed. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_ABLE_EDIT_TASK: `You cannot edit the task due to its current status. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICE_ALREADY_COMPLETED: `The service has already been completed, therefore no modifications can be made. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_PRODUCT: `Due to the current state of the product, no modifications can be made to it. ${CONTACT_SUPPORT_MESSAGE}`,
  ROUND_DOCUMENTS_ALREADY_UPLOADED: `The round documents have already been uploaded, reload the page to see the updated information. ${CONTACT_SUPPORT_MESSAGE}`,
  USER_CANNOT_BE_APPROVER_WHITOUT_BE_TECHNICAL_MANAGER: `The user cannot be marked as an approver without being a "Technical Manager" or "Substitute Technical Manager". ${CONTACT_SUPPORT_MESSAGE}`,
  USER_CANNOT_BE_TECHNICAL_MANAGER_WHITOUT_BE_APPROVER: `The user cannot be marked as "Technical Manager" or "Substitute Technical Manager" without being an approver. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_NOT_READY_FOR_VISIT: `The selected inspection(s) is not yet ready for the visit. ${CONTACT_SUPPORT_MESSAGE}`,
  OUT_OF_CUSTOMS_DATE_ALREADY_SET: `The customs clearance date has already been defined previously. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_CREATE_USER_SYS_ADMIN: `It is not possible to create a user with that role. ${CONTACT_SUPPORT_MESSAGE}`,
  DATE_ARE_AFTER_THAN_TODAY: `The date cannot be older than today. ${CONTACT_SUPPORT_MESSAGE}`,
  PAID_PETITION_NEEDS_UPLOADED_BEFORE: `To enter billing information, you must first upload the paid petition. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_UPLOADED_EVIDENCE: `The evidence has not yet been uploaded, it must be uploaded before dictaminate the round. ${CONTACT_SUPPORT_MESSAGE}`,
  NOT_INSPECTION_LIST_UPLOADED_YET: `The 'Inspection List' must be completed before uploading evidence of the round. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_CONSTANCY: `Currently, the constancy cannot be generated, because its inspection has not yet been completed. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DICTUM: `Currently, the dictum cannot be generated, because its inspection has not yet been completed. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_INDICATORS_PRODUCT: `Invalid data entered, specified product properties cannot be updated. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_UPDATE_SERVICE_BY_INVOICED: `The service cannot be modified because it has already been invoiced. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_MODIFY_PRODUCT_BY_INVOICED: `The product cannot be modified because its service has already been invoiced. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_CREATE_PRODUCT_BY_INVOICED: `The product cannot be created because its service has already been invoiced. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_INVOICED_SERVICE_WHITOUT_COMPLETE_REQUEST: `The service cannot be billed yet since your request has not been completed. ${CONTACT_SUPPORT_MESSAGE}`,
  413: `The file is too large, try compressing the file and try again. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTIONS_TO_REPORT_NOT_FOUND: `No inspections were found to generate the report. ${CONTACT_SUPPORT_MESSAGE}`,
  TASKS_TO_REPORT_NOT_FOUND: `No tasks were found to generate the report. ${CONTACT_SUPPORT_MESSAGE}`,
  COMMENTS_TO_REPORT_NOT_FOUND: `No comments were found to generate the report. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_DOCUMENT_NOT_SIGNED_CONTRACT: `The document cannot be downloaded since the client there is no signed contract. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_TYPE_NOT_ACCEPTED: `The document type is not accepted. ${CONTACT_SUPPORT_MESSAGE}`,
  REMOTE_INSPECTIONS_NOT_ABLE_TO_ASSIGN: `One or more of the selected inspections are not yet ready to be assigned to a task. ${CONTACT_SUPPORT_MESSAGE}`,
  SOME_DONT_COMPLY_QUESTION_DONT_HAVE_EVIDENCE: `Any reagent marked as non-compliant does not have uploaded evidence, check the inspection list. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_GENERATE_INSPECTION_FORMAT: `It is not possible to generate the "Inspection Form". ${CONTACT_SUPPORT_MESSAGE}`,
  EVIDENCE_SHOULD_BE_IMAGE: `The evidence must be an image. ${CONTACT_SUPPORT_MESSAGE}`,
  INSPECTION_ALREADY_SUPERVISED: `The inspection has already been supervised. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_SUPERVISED_OWN_JOB: `You can't supervise your own work. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_SUPERVISED_INSPECTION_BY_STATUS: `You cannot supervise the inspection due to the current status of the monthly closing. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_FINISH_MONTHLY_CLOSING_BY_STATUS: `You cannot close the monthly closing due to its current status. ${CONTACT_SUPPORT_MESSAGE}`,
  DOCUMENT_NOT_AVAILABLE: `Document not available. ${CONTACT_SUPPORT_MESSAGE}`,
  PRODUCTS_TO_REPORT_NOT_FOUND: `No products were found to generate the report. ${CONTACT_SUPPORT_MESSAGE}`,
  RESPONSIBLE_DOES_NOT_HAS_THE_REQUIRED_CERTIFICATE_NORMS: `The inspector cannot be assigned to this task since he/she has the standard but is not certified in it, for non "design" rounds in the selected inspections, the inspector must be certified. ${CONTACT_SUPPORT_MESSAGE}`,
  ACTION_IN_NOT_BELONG_THE_USER: `The action does not belong to you, therefore you cannot make changes to it. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_APPROVE_OWN_ACTION: `You cannot approve an action created by you. ${CONTACT_SUPPORT_MESSAGE}`,
  CANNOT_MODIFY_ACTION: `The action has already been approved, no changes can be made to it. ${CONTACT_SUPPORT_MESSAGE}`,
  ACTION_IS_NOT_APPROVED: `Due to the current status of the record, the document cannot be generated. ${CONTACT_SUPPORT_MESSAGE}`,
  SERVICES_TO_REPORT_NOT_FOUND: `No services were found for the report. ${CONTACT_SUPPORT_MESSAGE}`,
  INVOICES_NOT_FOUND: `No invoices found. ${CONTACT_SUPPORT_MESSAGE}`,
  AUDIT_DOCUMENTS_NOT_FOUND: `No documents were found for the file. ${CONTACT_SUPPORT_MESSAGE}`,
  GENERATE_PRE_SIGNED_URL_ERROR: `There was a problem uploading the file(s), please wait a few moments before trying again and if the problem persists, contact support. ${configuration.supportPhoneNumber}`,
  SELECTED_NORM_REQUIRE_MEASUREMENT_EQUIPMENT: `The norm to which the inspection list belongs requires specifying measuring equipment but this was not provided.  ${configuration.supportPhoneNumber}`,
  COLLABORATOR_EMAIL_ALREADY_EXIST: `The collaborator's email already exists in the client's records, please use another one.  ${configuration.supportPhoneNumber}`,
  CANNOT_UPDATE_TRAININ_RECORD: `The registration cannot be updated, all participants have already signed up for the training. ${configuration.supportPhoneNumber}`,
  THE_RECORD_HAS_NOT_BEEN_SIGNED_IN_FULL: `The registration has not yet been fully signed, the document cannot be generated. ${configuration.supportPhoneNumber}`,
  EXPIRED_INSPECTION_CANNOT_DISAPROVE: `An expired inspection cannot be disaprove. ${configuration.supportPhoneNumber}`,
  INSPECTOR_NOT_HAVE_003_IN_050_COMPLEMENT_INSPECTION: `The inspector does not have the required standard, one or more inspections have a complement to NOM-003 in NOM-050, and the inspector does not have NOMN-003. ${configuration.supportPhoneNumber}`,
  PASSWORD_MAIL_WAS_NOT_SENT: `The recovery email has not been sent. ${configuration.supportPhoneNumber}`,
  CANNOT_MARK_COMPLIES_FIRST_DESIGN_ROUND: `The first round of design cannot be marked as "compliant". ${configuration.supportPhoneNumber}`
};

export default errorsDictionary;
