import React from 'react';
import { useTranslation } from 'react-i18next';

import { InspectionCard, Spinner, Title } from '../../../../components';
import { Inspection } from '../../../../interfaces';

interface Counter {
  'in-progress': number
  'in-review': number
  concluded: number
}

interface Props {
  items: Inspection[]
  statusCounter: Counter
  isPending: boolean
}

const InspectionsList: React.FC<Props> = (props) => {
  const {
    items,
    statusCounter,
    isPending
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <div className="tasks-view__main__right-container__status-column tasks-view__main__right-container__status-column--grey-1">
        <Title title={`${t('tasks.inProgress')} (${statusCounter['in-progress']})`} type='secondary' />
        {
          isPending ? (
            <div className='spinner-container'>
              <Spinner />
            </div>
          ) : (
            items.map((item: Inspection, index: number) => (
              item.status === 'in-progress'
              && item.inspector !== null
              && item.indicators.waitingForFilesForRemoteRound === false && (
                <InspectionCard
                  key={index}
                  inspection={item}
                />
              )
            ))
          )
        }
      </div>
      <div className="tasks-view__main__right-container__status-column tasks-view__main__right-container__status-column--grey-2">
        <Title title={`${t('tasks.inReview')} (${statusCounter['in-review']})`} type='secondary' />
        {
          isPending ? (
            <div className='spinner-container'>
              <Spinner />
            </div>
          ) : (
            items.map((item: Inspection, index: number) => (
              item.status === 'in-review' && (
                <InspectionCard
                  key={index}
                  inspection={item}
                />
              )
            ))
          )
        }
      </div>
    </>
  );
};

export default InspectionsList;
