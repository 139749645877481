import React from 'react';

import { Outlet } from 'react-router-dom';
import { ConstancyProduct, DictumProduct, RevisionProduct } from '../../interfaces';
import ProductInformation from './components/ProductInformation';

import './styles.scss';

interface Props {
  showProductInformation: boolean
  constancyProduct: ConstancyProduct
  dictumProduct: DictumProduct
  revisionProduct: RevisionProduct
}

export const InspectionComponent: React.FC<Props> = (props) => {
  const {
    showProductInformation,
    constancyProduct,
    dictumProduct,
    revisionProduct
  } = props;

  return (
    <div className='inspection'>
      {
        showProductInformation && (
          <ProductInformation
            constancyProduct={constancyProduct}
            dictumProduct={dictumProduct}
            revisionProduct={revisionProduct}
          />
        )
      }
      <Outlet />
    </div>
  );
};

export default InspectionComponent;
