/* eslint-disable max-len */
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import axios from 'axios';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

import {
  Inspection,
  User,
  ReviewComment,
  FileInputData,
  SignedFile
} from '../../../../interfaces';
import {
  Button,
  FileInput,
  Input,
  Modal,
  Spinner,
  Title
} from '../../..';
import { useKeyPress, useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  setInspection: (_value: Inspection) => void
  user: User
}

const ReviewComments: React.FC<Props> = (props) => {
  const {
    setInspection,
    user
  } = props;

  const {
    createResource,
    updateResource,
    fetchResource
  } = useResource<Inspection>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    fetchResource: signFile
  } = useResource<string>();

  const {
    setOpenModal,
    openModal,
    inspection,
    soundEffects,
    informationReceivedFromWebsocket
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [isPending, startTransition] = useTransition();
  const [message, setMessage] = useState<string>('');

  const disableSendMessage = useCallback(() => {
    if ((['inspector', 'master', 'sys-admin', 'operator'].includes(user.role)) && (inspection.status === 'in-review' || inspection.status === 'in-progress')) {
      return false;
    }

    return true;
  }, [inspection, user]);

  const scrollToBottom = () => {
    // eslint-disable-next-line no-undef
    const commentsBottom = document.getElementById('bottom-comments');

    if (commentsBottom) {
      commentsBottom.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  const handleDownloadFile = async (filePath: string, documentName: string) => {
    Modal.fireLoading();
    try {
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error(`${t('global.alertMessage')}: ${response.status}`);
      }

      const blob = await response.blob();
      saveAs(blob, documentName);

      Modal.close();
    } catch (error) {
      Modal.fireError(`${error}`, undefined, soundEffects);
    }
  };

  const commentInspection = () => {
    createResource(
      `/admin/inspections/${inspection.id}/review-comment`,
      {
        comment: message
      },
      (data) => {
        setInspection(data);
        setMessage('');
        setTimeout(() => { scrollToBottom(); }, 500);
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const handleUploadCommentFile = (paramFiles: FileInputData) => {
    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/review-comment/file`,
      {
        filePaths: [{
          path: paramFiles.fileName.replace(/[%&?¿=#/+]/g, ''),
          type: paramFiles.data.type
        }]
      },
      async (data) => {
        let uploadedPath: string = '';
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', paramFiles.data);

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPath = item.url;
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPath !== '') {
          updateResource(
            `/admin/inspections/${inspection.id}/review-comment-file/add-document-path`,
            {
              filePaths: [uploadedPath]
            },
            (data2) => {
              setInspection(data2);
              setTimeout(() => { scrollToBottom(); }, 500);
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    if (inspection.reviewComments.some((comment: ReviewComment) => comment.isAFile === true && !comment.text.includes('https://s3.amazonaws.com/'))) {
      startTransition(() => {
        const auxInspection = inspection;

        inspection.reviewComments.forEach((comment: ReviewComment, index: number) => {
          if (comment.isAFile === true) {
            signFile(
              `/admin/documents/signature?s_filePath=${comment.text}`,
              (signedPath: string) => {
                auxInspection.reviewComments[index].text = signedPath;
              },
              (error: string) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        });

        setInspection(auxInspection);
      });
    }
  }, [inspection]);

  useEffect(() => {
    setTimeout(() => { scrollToBottom(); }, 1000);
  }, [isPending]);

  useEffect(() => {
    scrollToBottom();
  }, [inspection.reviewComments]);

  useKeyPress(
    openModal ? () => { } : () => commentInspection(),
    [
      inspection,
      user,
      message,
      openModal
    ],
    !disableSendMessage()
  );

  const renderRoundSeparator = (lastComment: ReviewComment | undefined, comment: ReviewComment) => {
    if (lastComment === undefined) {
      return false;
    }

    if ('round' in lastComment) {
      if (comment.round !== lastComment.round) {
        return true;
      }
    }

    if (!('round' in lastComment) && 'round' in comment) {
      return true;
    }

    return false;
  };

  const renderFormatedComment = (text: string): ReactElement => {
    const splitedText = text.split('\n');
    const elements: ReactElement[] = [];

    for (let index = 0; index < splitedText.length; index += 1) {
      elements.push(<>{splitedText[index]}<br /> </>);
    }

    return <>
      {
        elements.map((item: ReactElement) => (
          <>
            {item}
          </>
        ))
      }
    </>;
  };

  useEffect(() => {
    if (informationReceivedFromWebsocket.action === 're-fetch-comments'
      && informationReceivedFromWebsocket.inspectionID === inspection.id) {
      fetchResource(
        `/admin/inspections/${inspection.id}`,
        (data) => {
          setInspection(data);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [informationReceivedFromWebsocket]);

  const renderComments = (comments: ReviewComment[]) => {
    if (comments.length === 0) {
      return (<div className='review-comments__comments review-comments__comments--empty'>
        <p>{t('inspections.emptyComments')}</p>
      </div>);
    }

    return (<div className='review-comments__comments'>
      {
        comments.map((comment: ReviewComment, index: number) => (
          // eslint-disable-next-line max-len
          renderRoundSeparator(comments[index - 1], comment) ? (
            <>
              <div className='review-comments-separator'>
                <span className='review-comments-separator__title'>{t('inspections.round')} {comment.round}</span>
                <div className='review-comments-separator__separator'></div>
              </div>
              <div key={index} className={`review-comments__comments${user.id === comment.author.id ? '__own-comment' : '__comment'}`} id={`comment-${comment.id}`}>
                <div className={`review-comments__comments__arrow review-comments__comments__arrow${user.id === comment.author.id ? '--right' : '--left'}`}></div>
                <div>
                  <p className='review-comments__comments__author'>{comment.author.name}</p>
                  {
                    comment.isAFile ? (
                      <img src={comment.text} alt={`image-${index}`} className='review-comments__comments__image' />
                    ) : (
                      <p className='review-comments__comments__message'>{renderFormatedComment(comment.text)}</p>
                    )
                  }
                  {
                    comment.isAFile && (
                      <Button
                        type={user.id === comment.author.id ? 'secondary-outlined' : 'secondary'}
                        onClick={() => handleDownloadFile(comment.text, `image-${comment.author.name}-${comment.date}`)}
                        label={t('global.download') || ''}
                        icon='downloadWhite'
                      />
                    )
                  }
                  <p className='review-comments__comments__date'>{comment.date}</p>
                </div>
              </div>
            </>
          ) : (
            <div key={index} className={`review-comments__comments${user.id === comment.author.id ? '__own-comment' : '__comment'}`} id={`comment-${comment.id}`}>
              <div className={`review-comments__comments__arrow review-comments__comments__arrow${user.id === comment.author.id ? '--right' : '--left'}`}></div>
              <div>
                <p className='review-comments__comments__author'>{comment.author.name}</p>
                {
                  comment.isAFile ? (
                    <img src={comment.text} alt={`image-${index}`} className='review-comments__comments__image' />
                  ) : (
                    <p className='review-comments__comments__message'>{renderFormatedComment(comment.text)}</p>
                  )
                }
                {
                  comment.isAFile && (
                    <Button
                      type={user.id === comment.author.id ? 'secondary-outlined' : 'secondary'}
                      onClick={() => handleDownloadFile(comment.text, `image-${comment.author.name}-${comment.date}`)}
                      label={t('global.download') || ''}
                      icon='downloadWhite'
                    />
                  )
                }
                <p className='review-comments__comments__date'>{comment.date}</p>
              </div>
            </div>
          )
        ))
      }
      <div id="bottom-comments"></div>
    </div>);
  };

  return (
    <div className='review-comments'>
      <div className='review-comments__title'>
        <Title title={t('inspections.comments') || ''} type='secondary' />
      </div>
      {
        isPending ? (
          <div className='review-comments__comments' >
            <Spinner />
          </div>
        ) : (
          renderComments(inspection.reviewComments)
        )
      }
      <div className='review-comments__input-container'>
        <div className='review-comments__input-container__left'>
          <Input
            type='text'
            value={message}
            placeholder={t('inspections.writeComments') || ''}
            onChange={(value: string | number) => {
              setMessage(String(value));
            }}
            disabled={disableSendMessage()}
            autofocus={true}
            maxLength={500}
            upperCase={false}
          />
          {
            !disableSendMessage() && (
              <>
                <br />
                <FileInput
                  onSelectFile={(_file: FileInputData[]) => {
                    handleUploadCommentFile(_file[0]);
                  }}
                  acceptedFileFormats={'.jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.svg,.SVG'}
                  isMultiple={false}
                />
              </>
            )
          }
        </div>
        <Button
          onClick={commentInspection}
          type='primary'
          icon='send'
          transparent={true}
          size='big'
          disabled={disableSendMessage()}
        />
      </div>
    </div>
  );
};

export default ReviewComments;
