import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { RevisionProductStatus } from '../../../../interfaces';

interface Props {
  status: RevisionProductStatus
}

const RevisionProductDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const revisionProductStatuses: RevisionProductStatus[] = [
    'in-inspection',
    'concluded'
  ];

  const translatedRevisionProductStatuses: string[] = [];

  revisionProductStatuses.forEach((element: RevisionProductStatus) => {
    translatedRevisionProductStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="revision-product-detail__progress-indicator">
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedRevisionProductStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default RevisionProductDetailDocuments;
