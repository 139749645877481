import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  ModalView
} from '../../../../components';

import { useResource } from '../../../../hooks';
import {
  Collaborator, User
} from '../../../../interfaces';

import '../../styles.scss';
import { validate } from '../../../../helpers';
import { AppContext } from '../../../../context/AppContext';

interface Passwords {
  newPassword: string
  repeatNewPassword: string
}

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (_data: Collaborator | User) => void
  onError: (_error: string) => void
}

const ChangePasswordForm: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onSuccess,
    onError
  } = props;

  const { t } = useTranslation();

  const {
    updateResource: updatePasswordUser
  } = useResource<User>();

  const {
    updateResource: updatePasswordCollaborator
  } = useResource<Collaborator>();

  const {
    apiType
  } = useContext(AppContext);

  const [password, setPassword] = useState<Passwords>({
    newPassword: '',
    repeatNewPassword: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const onChangeInput = (value: string | number, id: string) => {
    setPassword({
      ...password,
      [id]: value
    });
  };

  const handleUpdatePassword = () => {
    if (validData()) {
      if (apiType === 'admin') {
        updatePasswordUser(
          'admin/users/password/new',
          {
            newPassword: password.newPassword
          },
          (data) => {
            onSuccess(data);
          },
          (error: string) => {
            onError(error);
          }
        );
      } else if (apiType === 'public') {
        updatePasswordCollaborator(
          'public/collaborators/password',
          {
            newPassword: password.newPassword
          },
          (data) => {
            onSuccess(data);
          },
          (error: string) => {
            onError(error);
          }
        );
      }
    }
  };

  const validData = (): boolean => {
    if (validate.text(password.newPassword, 8, 16) !== 'success') {
      setErrors({
        newPassword: validate.text(password.newPassword, 8, 16)
      });

      return false;
    }
    if (password.repeatNewPassword !== password.newPassword) {
      setErrors({
        repeatNewPassword: t('profile.passwordNotmatch'),
        newPassword: t('profile.passwordNotmatch')
      });

      return false;
    }

    return true;
  };

  useEffect(() => {
    setErrors({});
    setPassword({
      newPassword: '',
      repeatNewPassword: ''
    });
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={handleUpdatePassword}
      customComponent={
        <div className="password-form">
          <Input
            title={t('profile.newPassword') || ''}
            type="password"
            value={password.newPassword}
            id='newPassword'
            onChange={onChangeInput}
            placeholder="********"
            hasError={!!errors.newPassword}
            helperText={errors.newPassword}
            ableAutocomplete={false}
          />
          <Input
            title={t('profile.repeatNewPassword') || ''}
            type="password"
            value={password.repeatNewPassword}
            id='repeatNewPassword'
            onChange={onChangeInput}
            placeholder="********"
            hasError={!!errors.repeatNewPassword}
            helperText={errors.repeatNewPassword}
            ableAutocomplete={false}
          />
        </div >
      }
    />
  );
};

export default ChangePasswordForm;
