import React, {
  ReactElement,
  useEffect,
  useState
} from 'react';

import './styles.scss';

interface Props {
  stepsQuantity: number
  currentStep: number
}

const StepsIndicator: React.FC<Props> = (props: Props) => {
  const [stepsQuantity, _setStepsQuantity] = useState<number>(props.stepsQuantity || 0);
  const [currentStep, _setCurrentStep] = useState<number>(props.currentStep || 0);
  const [steps, setSteps] = useState<ReactElement[]>([]);

  useEffect(() => {
    const stepsAux: ReactElement[] = [];

    for (let i = 0; i < stepsQuantity; i += 1) {
      stepsAux.push(
        <div className={i === currentStep - 1 ? 'steps-indicator__step--current' : 'steps-indicator__step'} key={i} />
      );

      if (i < stepsQuantity - 1) {
        stepsAux.push(
          <div className='steps-indicator__step__separator' key={`${i}-separtor`} />
        );
      }
    }

    setSteps(stepsAux);
  }, [stepsQuantity, currentStep]);

  return (
    <div className="steps-indicator">
      {steps}
    </div>
  );
};

export default StepsIndicator;
