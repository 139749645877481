import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import './styles.scss';

interface Props {
  title?: string
  emptyStateTitle?: string
  elements: string[]
  onDelete: (_element: string) => void
  ableDeleteElements?: boolean
}

const BubbleElements: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    title = t('bubbleElements.defaultTitle'),
    emptyStateTitle = t('bubbleElements.notSelecteditems'),
    elements,
    onDelete,
    ableDeleteElements = true
  } = props;

  return (
    <div className='bubble-elements'>
      <p className='bubble-elements__title'>{title}</p>
      <div className='bubble-elements__container'>
        {
          elements.map((element: string, index: number) => (
            <div className='bubble-elements__container__element' key={index}>
              <p className='bubble-elements__container__element__label'>{element}</p>
              {
                ableDeleteElements && (
                  <Icon type='close' onClick={() => onDelete(element)} className='bubble-elements__container__element__icon' />
                )
              }
            </div>
          ))
        }
        {
          elements.length === 0 && (
            <p className='bubble-elements__container__empty-title'>{emptyStateTitle}</p>
          )
        }
      </div>
    </div>
  );
};

export default BubbleElements;
