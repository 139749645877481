import {
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  Header, Modal, Spinner
} from '../../components';

import {
  BugReport,
  Error,
  TabItem
} from '../../interfaces';

import './styles.scss';
import { useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import Tabs from '../../components/Tabs';

const UsersList = () => {
  const {
    fetchResources
  } = useResource<Error>();

  const {
    fetchResources: getBugsReports
  } = useResource<BugReport>();

  const {
    fetchResource: signFile
  } = useResource<string>();

  const { t } = useTranslation();

  const params = useParams();
  const { errorID } = params;

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [isPending, startTransition] = useTransition();
  const [errors, setErrors] = useState<Error[]>([]);
  const [bugReports, setBugReports] = useState<BugReport[]>([]);
  const [errorsCompleteList, setErrorsCompleteList] = useState<Error[]>([]);
  const [itemACtive, setItemACtive] = useState<string>('');
  const [quantityElementsOnList, setQuantityElementsOnList] = useState<number>(20);
  const [selectedSection, setSelectedSection] = useState<number>(1);

  const getMoreItems = () => {
    startTransition(() => {
      if (errorsCompleteList.length > errors.length) {
        setQuantityElementsOnList(quantityElementsOnList + 20);
      }
    });
  };

  const formatData = (key: string, data: any) => {
    if (key === 'createdAt') {
      return dayjs(data).format('DD/MM/YYYY hh:mm a');
    }
    if (key === 'user') {
      return `Usuario: ${data.id} - ${data.name}`;
    }
    if (key === 'routeOnApp') {
      return (<>
        <Link
          to={data}
        >
          {data}
        </Link>
      </>);
    }
    if (key === 'image') {
      if (data) {
        return (<img src={data} alt='image' className='image-bug' />);
      }

      return 'Sin imagen';
    }

    return data;
  };

  useEffect(() => {
    startTransition(() => {
      setErrors(errorsCompleteList.slice(0, quantityElementsOnList));
    });
  }, [quantityElementsOnList]);

  useEffect(() => {
    fetchResources(
      {
        resourcePath: 'admin/errors'
      },
      (data) => {
        setErrors(data.items.slice(0, quantityElementsOnList));
        setErrorsCompleteList(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, []);

  useEffect(() => {
    getBugsReports(
      {
        resourcePath: 'admin/errors/bug-comment'
      },
      (data) => {
        setBugReports(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, []);

  useEffect(() => {
    startTransition(() => {
      const auxReport = bugReports;

      bugReports.forEach((item, index) => {
        if (item.image !== '') {
          signFile(
            `/admin/documents/signature?s_filePath=${item.image}`,
            (signedPath: string) => {
              auxReport[index].image = signedPath;
            },
            (error: string) => Modal.fireError(error, undefined, soundEffects)
          );
        }
      });

      setBugReports(auxReport);
    });
  }, [bugReports]);

  useEffect(() => {
    if (errorID !== 'none') {
      setSelectedSection(2);
      setItemACtive(`${errorID}`);
    }
  }, [errorID]);

  return (
    <div className="errors-list">
      <Header
        title={t('global.errors') || ''}
        showBackbutton={true}
      />
      <div className="errors-list__container">
        <Tabs
          items={[
            {
              id: 1,
              label: 'Errores logs'
            },
            {
              id: 2,
              label: 'Reportes de bugs'
            }
          ]}
          onSelectTab={(item: TabItem) => {
            setSelectedSection(item.id);
          }}
          preselectedTab={selectedSection}
        />
        {
          selectedSection === 2 && isPending === false && (
            <>
              {bugReports.map((error, index) => (
                <div key={index} className="errors-list__container__code">
                  {
                    itemACtive !== error.id && (
                      <div className="errors-list__container__code__collapse-item" key={index} onClick={() => setItemACtive(error.id)}>
                        <b>{index + 1} - </b>{' '}
                        <b>ID:</b> {error.id}{' '}
                        <b>Fecha:</b> {dayjs(error.createdAt).format('DD/MM/YYYY hh:mm a')}
                      </div>
                    )
                  }
                  {Object.entries(error).map(([key, value], index2) => (
                    <div>
                      {
                        itemACtive === error.id && (
                          <code className="errors-list__container__code__text" key={index2}>
                            <strong>{key}:</strong>{'  '}
                            {formatData(key, value)}
                          </code>
                        )
                      }
                    </div>
                  ))}
                </div>
              ))}
            </>
          )
        }
        {
          selectedSection === 1 && (
            <>
              {errors.map((error, index) => (
                <div key={index} className="errors-list__container__code">
                  {
                    itemACtive !== error.id && (
                      <div className="errors-list__container__code__collapse-item" key={index} onClick={() => setItemACtive(error.id)}>
                        <b>{index + 1} - </b>{' '}
                        <b>ID:</b> {error.id}{' '}
                        <b>Fecha:</b> {dayjs(error.createdAt).format('DD/MM/YYYY hh:mm a')}
                      </div>
                    )
                  }
                  {Object.entries(error).map(([key, value], index2) => (
                    <div>
                      {
                        itemACtive === error.id && (
                          <code className="errors-list__container__code__text" key={index2}>
                            <strong>{key}:</strong>{'  '}
                            {formatData(key, value)}
                          </code>
                        )
                      }
                    </div>
                  ))}
                </div>
              ))}
              <p
                className='errors-list__container__loading-label'
                onClick={getMoreItems}
              >
                {
                  errorsCompleteList.length > errors.length && (
                    isPending ? <Spinner /> : t('tasks.showMore')
                  )
                }
              </p>
            </>
          )
        }
      </div>
    </div>
  );
};

export default UsersList;
