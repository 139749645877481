export default function useNotification(
  text: string,
  title?: string,
  onClick?: () => void
) {
  const triggerNotification = () => {
    if (Notification) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }

      const notification = new Notification(
        `CECSA Trade${title ? ` - ${title}` : ''}`,
        {
          body: text,
          icon: '/favicon.ico',
          badge: '/favicon.ico'
        }
      );

      notification.onclick = () => {
        if (onClick) {
          onClick();
        }
      };
    }
  };

  triggerNotification();
}
