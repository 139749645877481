import React, { ReactElement } from 'react';

import './styles.scss';

interface Props {
  text?: string,
  position?: 'top' | 'bottom' | 'left' | 'right'
  component: ReactElement
}

const ToolTip: React.FC<Props> = (props: Props) => {
  const {
    text = '',
    position = 'bottom',
    component
  } = props;

  return (
    <div className='tooltip'>
      {component}
      {
        text !== '' && (
          <div className={`tooltip__text tooltip__text--${position}`}>
            <p>{text}</p>
          </div>
        )
      }
    </div>
  );
};

export default ToolTip;
