import React, { useEffect, useState } from 'react';

import './styles.scss';
import { SelectOption } from '../../interfaces';

interface Props {
  leftLabel: SelectOption
  rigthLabel: SelectOption
  onChange: (_value: string | number) => void
  defaultChecked?: boolean
  disabled?: boolean
}

const Switch: React.FC<Props> = (props) => {
  const {
    leftLabel,
    rigthLabel,
    onChange,
    defaultChecked,
    disabled = false
  } = props;

  const [checked, setChecked] = useState<boolean>(true);

  useEffect(() => {
    if (defaultChecked !== undefined) {
      setChecked(defaultChecked);
    }
  }, [defaultChecked]);

  return (
    <div className={`switch__item switch__item${disabled ? '--disabled' : ''}`}>
      <span className={`switch__label switch__label--${checked ? 'selected' : ''}`}>{leftLabel.text}</span>
      <div
        onClick={() => {
          if (!disabled) {
            onChange(checked ? `${rigthLabel.value}` : `${leftLabel.value}`);
            setChecked(!checked);
          }
        }}
        className={`switch__bullet-container switch__bullet-container${disabled ? '--disabled' : ''}`}
      >
        <div className={checked ? 'switch__bullet-container__bullet switch__bullet-container__bullet--normal' : 'switch__bullet-container__bullet switch__bullet-container__bullet--checked'}></div>
      </div>
      <span className={`switch__label switch__label--${!checked ? 'selected' : ''}`}>{rigthLabel.text}</span>
    </div>
  );
};

export default Switch;
