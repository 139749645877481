import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Input,
  Modal,
  RadioList,
  Select
} from '..';
import statusDictionaryEn from '../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../dictionaries/statusDictionary-es';
import { useKeyPress } from '../../hooks';
import { FilterTypes, FilterItem } from '../../interfaces';

import './styles.scss';
import { AppContext } from '../../context/AppContext';

interface Props {
  options: FilterItem[]
  onTriggerFilter: (_selectedFilter: FilterTypes, _filterValue: string) => void
  orientation?: 'vertical' | 'horizontal'
  disableSearchOnPressKey?: boolean
  defaultSelectedFilter?: FilterTypes
  defaultFilterValue?: string
}

const Filters: React.FC<Props> = (props) => {
  const {
    options,
    onTriggerFilter,
    orientation = 'vertical',
    disableSearchOnPressKey = false,
    defaultSelectedFilter = 'none',
    defaultFilterValue = ''
  } = props;

  const {
    openModal,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const [selectedFilter, setSelectedFilter] = useState<FilterTypes>('none');
  const [filterValue, setFilterValue] = useState<string>('');

  const onChangeInput = (value: string | number) => {
    setFilterValue(`${value}`);
  };

  const getCustomSelectOptions = () => {
    for (let index = 0; index < options.length; index += 1) {
      if (options[index].value === selectedFilter) {
        return options[index].selectOptions;
      }
    }

    return false;
  };

  useEffect(() => {
    if (defaultSelectedFilter !== 'none') {
      setSelectedFilter(defaultSelectedFilter);
    }

    if (defaultFilterValue !== '') {
      setFilterValue(defaultFilterValue);
    }
  }, [defaultSelectedFilter, defaultFilterValue]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const input = document.getElementById('input-filter');

    if (input) {
      input.focus();
    }

    if (selectedFilter === 'complies' || selectedFilter === 'listCompleted') {
      setFilterValue('true');
    }
  }, [selectedFilter]);

  useKeyPress(
    openModal ? () => { } : () => {
      if (
        selectedFilter !== 'none'
        && !disableSearchOnPressKey
      ) {
        handleSearch();
      }
    },
    [selectedFilter, disableSearchOnPressKey, filterValue, openModal]
  );

  useKeyPress(
    openModal ? () => { } : () => handleClearAll(),
    [
      options,
      onTriggerFilter,
      orientation,
      disableSearchOnPressKey,
      openModal,
      filterValue,
      selectedFilter
    ],
    true,
    'Escape'
  );

  const filtersInputs: { [name: string]: ReactElement } = {
    scheduled: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('tasks.notAssigned'),
            value: 'false'
          },
          {
            text: t('tasks.assigned'),
            value: 'true'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    role: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('global.roles.operator'),
            value: 'operator'
          },
          {
            text: 'Inspector',
            value: 'inspector'
          },
          {
            text: t('global.rolesNames.quality'),
            value: 'quality'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    email: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('signinView.emailInput.title') || ''}
        id='input-filter'
      />
    ),
    clientStatus: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('global.active'),
            value: 'active'
          },
          {
            text: t('global.inactive'),
            value: 'inactive'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    taskStatus: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('global.status.scheduled'),
            value: 'scheduled'
          },
          {
            text: t('global.status.in-progress'),
            value: 'in-progress'
          },
          {
            text: t('tasks.status.concluded'),
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    inspectionStatus: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('global.status.in-progress'),
            value: 'in-progress'
          },
          {
            text: t('global.status.in-review'),
            value: 'in-review'
          },
          {
            text: t('tasks.status.concluded'),
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    shortName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.shortName') || ''}
        id='input-filter'
      />
    ),
    person: (
      <Select
        options={getCustomSelectOptions() || []}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    clientID: (
      <Select
        options={getCustomSelectOptions() || []}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    name: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.name') || ''}
        id='input-filter'
      />
    ),
    petitionNumber: (
      <Input
        type='petitionNumber'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.petitionNumber') || ''}
        id='input-filter'
      />
    ),
    clientBusinessName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('clients.bussinessName') || ''}
        id='input-filter'
      />
    ),
    inspectorName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.inspectorName') || ''}
        id='input-filter'
      />
    ),
    collaboratorName: (
      <Input
        type='text'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.collaboratorName') || ''}
        id='input-filter'
      />
    ),
    createdAt: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    updatedAt: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    onSiteVisitDate: (
      <Input
        type='date'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    requestNumber: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.requestNumber') || ''}
      />
    ),
    productStatus: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: i18n.language === 'es' ? statusDictionaryEs['invoice-assigned'] : statusDictionaryEn['invoice-assigned'],
            value: 'invoice-assigned'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['validation-in-progress'] : statusDictionaryEn['validation-in-progress'],
            value: 'validation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.validated : statusDictionaryEn.validated,
            value: 'validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['not-validated'] : statusDictionaryEn['not-validated'],
            value: 'not-validated'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['cancellation-in-progress'] : statusDictionaryEn['cancellation-in-progress'],
            value: 'cancellation-in-progress'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs['in-inspection'] : statusDictionaryEn['in-inspection'],
            value: 'in-inspection'
          },
          {
            text: i18n.language === 'es' ? statusDictionaryEs.concluded : statusDictionaryEn.concluded,
            value: 'concluded'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    invoice: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.invoices') || ''}
      />
    ),
    normCode: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: '003',
            value: '003'
          },
          {
            text: '004',
            value: '004'
          },
          {
            text: '015',
            value: '015'
          },
          {
            text: '024',
            value: '024'
          },
          {
            text: '020',
            value: '020'
          },
          {
            text: '050',
            value: '050'
          },
          {
            text: '051',
            value: '051'
          },
          {
            text: '141',
            value: '141'
          },
          {
            text: '142',
            value: '142'
          },
          {
            text: '173',
            value: '173'
          },
          {
            text: '187',
            value: '187'
          },
          {
            text: '189',
            value: '189'
          },
          {
            text: '235',
            value: '235'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
      />
    ),
    serviceCode: (
      <Select
        options={[
          {
            text: t('services.dictum'),
            value: 'DC'
          },
          {
            text: t('services.constancy'),
            value: 'CC'
          },
          {
            text: t('services.dictumNegation'),
            value: 'DN'
          },
          {
            text: t('services.constancyNegation'),
            value: 'CN'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
      />
    ),
    brand: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.brand') || ''}
      />
    ),
    number: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.number') || ''}
      />
    ),
    complies: (
      <RadioList
        options={[
          {
            label: t('tasks.filters.complies'),
            value: 'true'
          },
          {
            label: t('tasks.filters.notComplies'),
            value: 'false'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        orientation={'horizontal'}
      />
    ),
    listCompleted: (
      <RadioList
        options={[
          {
            label: t('services.complete'),
            value: 'true'
          },
          {
            label: t('services.notComplete'),
            value: 'false'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
        orientation={'horizontal'}
      />
    ),
    serviceNumber: (
      <Input
        type='number'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('services.serviceNumber') || ''}
      />
    ),
    taskType: (
      <Select
        options={getCustomSelectOptions() || [
          {
            text: t('tasks.form.revisionInspection'),
            value: 'revision-inspection'
          },
          {
            text: t('tasks.form.constancyInspection'),
            value: 'constancy-inspection'
          },
          {
            text: t('tasks.form.remoteInspection'),
            value: 'remote-inspection'
          },
          {
            text: t('tasks.form.inSiteInspection'),
            value: 'onsite-inspection'
          }
        ]}
        value={filterValue}
        onChange={onChangeInput}
      />
    ),
    productCode: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('tasks.filters.productCode') || ''}
      />
    ),
    productInvoice: (
      <Input
        type='text'
        id='input-filter'
        value={filterValue}
        onChange={onChangeInput}
        placeholder={t('tasks.filters.invoice') || ''}
      />
    ),
    approver: (
      <Select
        options={[
          {
            text: t('global.yes'),
            value: 'true'
          },
          {
            text: 'No',
            value: 'false'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
      />
    ),
    all: (
      <Select
        options={[
          {
            text: t('global.all'),
            value: 'all'
          }
        ]}
        onChange={onChangeInput}
        value={filterValue}
      />
    )
  };

  const handleSearch = () => {
    if (filterValue === '' || filterValue === 'none') {
      Modal.fireError(t('errorCodes.selectDataFilter'), setOpenModal, soundEffects);
    } else {
      onTriggerFilter(selectedFilter, filterValue);
    }
  };

  const handleClearAll = () => {
    setFilterValue('');
    setSelectedFilter('none');
    onTriggerFilter('none', '');
  };

  const handleChangeSelectedFilter = (value: string) => {
    setSelectedFilter(value as FilterTypes);

    if (value === 'none') {
      handleClearAll();
    } else {
      setFilterValue('');
    }
  };

  return (
    <div className={`filters filters--${orientation}`}>
      <Select
        options={options}
        onChange={handleChangeSelectedFilter}
        value={selectedFilter}
      />
      {filtersInputs[selectedFilter]}
      {
        filtersInputs[selectedFilter] && (
          <>
            <Button
              onClick={handleSearch}
              type='secondary-outlined'
              label={t('global.search') || ''}
              fullWidth
              iconPosition='left'
              submit={true}
            />
            <Button
              onClick={handleClearAll}
              type='primary-outlined'
              fullWidth
              label={t('global.clearSearch') || ''}
            />
          </>
        )
      }
    </div>
  );
};

export default Filters;
