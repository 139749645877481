import i18next from 'i18next';
import {
  ActionEntry,
  ClientReference,
  PersonReference,
  DictumService,
  DictumProduct,
  Collaborator,
  Client,
  ClientContact,
  ClientAddress,
  ServiceReference,
  DictumProductIndicators,
  ConstancyService,
  ConstancyProduct,
  User,
  Task,
  DatesRegistry,
  TaskIndicators,
  Inspection,
  Sampling,
  Packing,
  InspectionList,
  Query,
  Round,
  InspectionIndicators,
  Error,
  DictumServiceByID,
  DictumProductByID,
  ConstancyServiceByID,
  ConstancyProductByID,
  InspectionByID,
  TaskByID,
  RevisionService,
  RevisionProduct,
  RevisionServiceByID,
  RevisionProductByID,
  MonthlyClosing,
  SelectedInspection,
  CorrectiveActions,
  TrainingRecord
} from './interfaces';

export const emptyActionEntry: ActionEntry = {
  action: '',
  author: '',
  date: new Date()
};

export const emptyClientReference: ClientReference = {
  id: 'none',
  businessName: '',
  shortName: ''
};

export const emptyCollaborator: Collaborator = {
  id: '',
  email: '',
  password: '',
  name: '',
  phone: '',
  client: emptyClientReference,
  isActive: false,
  toursDone: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyPersonReference: PersonReference = {
  id: 'none',
  name: ''
};

export const emptyTrainingRecord: TrainingRecord = {
  id: '',
  theme: '',
  date: null,
  type: 'in-person',
  duration: '',
  instructor: '',
  reviewedTopics: '',
  involved: [],
  createdBy: emptyPersonReference,
  createdAt: new Date()
};

export const emptyDictumService: DictumService = {
  id: '',
  petitionNumber: '',
  rectifiedPetitionNumber: null,
  status: 'deleted',
  documents: [],
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  inspectionAddress: '',
  proposedValidationDate: null,
  outOfCustomsDate: null,
  indicators: {
    requestIsCompleted: false,
    isReadyForVisit: false,
    waitingToExpired: false,
    invoiced: false,
    invoicedInfo: {
      invoiceNumber: '',
      date: null,
      UUID: ''
    }
  },
  products: [],
  tracking: [],
  actionsEnabled: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyDictumProductIndicators: DictumProductIndicators = {
  isGrouped: false,
  previousVersionsIds: null,
  attachedToTheService: null,
  SEInvalidationReason: null,
  sentToSEforConclusion: null,
  sentInLayout1Date: null,
  sentInLayout2Date: null,
  documentReleaseDate: null,
  waitingToExpired: false,
  NOM003Complement: false
};

export const emptyServiceReference: ServiceReference = {
  id: '',
  code: 'CC',
  number: ''
};

export const emptyDictumProduct: DictumProduct = {
  id: '',
  invoice: '',
  subInvoice: 0,
  status: 'deleted',
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  service: emptyServiceReference,
  inspector: null,
  approver: null,
  inspection: null,
  tariffFraction: '',
  normCode: 'none',
  fase: 'none',
  labelingMode: 'none',
  umc: '',
  umcQuantity: 0,
  labelsToPut: 0,
  code: '',
  code2: '',
  brand: '',
  presentation: i18next.language === 'es' ? 'Por definir' : 'Undefined',
  description: '',
  indicators: emptyDictumProductIndicators,
  round: 1,
  task: null,
  tracking: [],
  actionsEnabled: [],
  documents: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null
};

export const emptyClientContact: ClientContact = {
  name: '',
  email: '',
  phone: ''
};

export const emptyClientAddress: ClientAddress = {
  id: '',
  street: '',
  number: '',
  int: '',
  neighborhood: '',
  city: '',
  postalCode: '',
  state: '',
  country: ''
};

export const emptyClient: Client = {
  id: '',
  rfc: '',
  shortName: '',
  businessName: '',
  contractNumber: '',
  contractDate: new Date(),
  legalRepresentative: '',
  personType: 'physcial',
  nationalityType: 'national',
  contact: emptyClientContact,
  fiscalAddress: emptyClientAddress,
  inspectionAddresses: [],
  collaborators: [],
  status: 'active',
  signedContract: false,
  tax_system: 'none',
  creditDays: 0,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyConstancyProduct: ConstancyProduct = {
  id: '',
  invoice: '',
  status: 'deleted',
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  service: emptyServiceReference,
  inspector: null,
  approver: null,
  inspection: null,
  normCode: 'none',
  fase: 'none',
  code: '',
  brand: '',
  presentation: '',
  description: '',
  documents: [],
  task: null,
  tracking: [],
  indicators: {
    documentReleaseDate: null,
    design: false,
    package: 'cylindrical',
    long: '',
    high: '',
    radio: '',
    urgent: false,
    NOM003Complement: false
  },
  round: 1,
  actionsEnabled: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  documentsAlert: false
};

export const emptyRevisionProduct: RevisionProduct = {
  id: '',
  invoice: '',
  status: 'deleted',
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  service: emptyServiceReference,
  inspector: null,
  approver: null,
  inspection: null,
  normCode: 'none',
  fase: 'none',
  code: '',
  brand: '',
  presentation: '',
  description: '',
  documents: [],
  task: null,
  tracking: [],
  indicators: {
    documentReleaseDate: null,
    design: false,
    package: 'cylindrical',
    long: '',
    high: '',
    radio: '',
    urgent: false,
    NOM003Complement: false
  },
  round: 1,
  actionsEnabled: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  documentsAlert: false
};

export const emptyConstancyService: ConstancyService = {
  id: '',
  requestNumber: '',
  status: 'deleted',
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  products: [],
  tracking: [],
  proposedValidationDate: null,
  indicators: {
    requestIsCompleted: false,
    invoiced: false,
    invoicedInfo: {
      invoiceNumber: '',
      date: null,
      UUID: ''
    },
    urgent: false
  },
  actionsEnabled: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyRevisionService: RevisionService = {
  id: '',
  requestNumber: '',
  status: 'deleted',
  client: emptyClientReference,
  collaborator: emptyPersonReference,
  products: [],
  tracking: [],
  proposedValidationDate: null,
  indicators: {
    requestIsCompleted: false,
    invoiced: false,
    invoicedInfo: {
      invoiceNumber: '',
      date: null,
      UUID: ''
    },
    urgent: false
  },
  actionsEnabled: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyUser: User = {
  id: '',
  email: '',
  name: '',
  password: '',
  signature: '',
  role: 'inspector',
  approver: false,
  substituteTechnicalManager: false,
  technicalManager: false,
  followUpInOperationsReport: false,
  norms: [],
  picture: '',
  isActive: false,
  phoneNumber: null,
  toursDone: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyDatesRegistry: DatesRegistry = {
  scheduledTo: new Date(),
  startedAt: null,
  endedAt: null
};

export const emptyTaskIndicators: TaskIndicators = {
  inspectionsQuantity: 0,
  completedInspectionsQuantity: 0,
  completedInspectionListsQuantity: 0,
  editInspection: false,
  waitingToExpired: false,
  urgent: false
};

export const emptyTask: Task = {
  id: '',
  number: '',
  type: 'onsite-inspection',
  status: 'concluded',
  responsible: {
    id: '',
    name: '',
    picture: ''
  },
  client: emptyClientReference,
  visit: null,
  invoices: [],
  services: [],
  comments: null,
  datesRegistry: emptyDatesRegistry,
  indicators: emptyTaskIndicators,
  tracking: [],
  inspections: [],
  products: [],
  createdAt: new Date(),
  updatedAt: new Date()
};

export const emptyInspectionIndicators: InspectionIndicators = {
  sendToNegation: false,
  waitingForFilesForRemoteRound: false,
  changedFilesForRemoteRound: false,
  editInspection: false,
  waitingToExpired: false,
  inspectionDate: null,
  isReadyForVisit: false,
  design: false,
  urgent: false
};

export const emptyInspection: Inspection = {
  id: '',
  number: '',
  scheduled: false,
  status: 'in-progress',
  complies: false,
  approved: null,
  approver: null,
  inspector: null,
  reviewComments: [],
  service: emptyServiceReference,
  product: emptyDictumProduct,
  client: emptyClientReference,
  collaborator: emptyCollaborator,
  listCompleted: false,
  evidence: [],
  task: null,
  rounds: [],
  norm: '051',
  fase: null,
  tracking: [],
  indicators: emptyInspectionIndicators,
  releaseDate: new Date(),
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: null
};

export const emptySampling: Sampling = {
  lot: 0,
  sample: 0,
  accepted: 0,
  rejected: 0,
  comments: ''
};

export const emptyPacking: Packing = {
  type: 'none',
  height: null,
  width: null,
  radius: null,
  content: 0,
  productType: 'none'
};

export const emptyInspectionList: InspectionList = {
  inspectorName: '',
  measurementEquipment: 'none',
  clientBusinessName: '',
  productDescription: '',
  complies: false,
  generalObservations: '',
  questions: [],
  packing: emptyPacking,
  sampling: null,
  model: '',
  section: '',
  answers: {},
  sign: null
};

export const emptyQuery: Query = {
  resourcePath: ''
};

export const emptyRound: Round = {
  id: -1,
  steps: [],
  complies: null,
  files: [],
  task: null,
  productNotPresented: false,
  inspector: null,
  approver: null,
  list: null,
  listDate: null,
  startAt: null,
  endsAt: null,
  inspectionListDate: null,
  uploadedEvidenceDate: null,
  approvedDate: null,
  inspectionListVersion: 1,
  pausePeriods: []
};

export const emptyError: Error = {
  id: '',
  status: '',
  code: '',
  endpoint: '',
  method: '',
  body: '',
  params: '',
  query: '',
  user: '',
  collaborator: '',
  cause: '',
  createdAt: new Date()
};

export const emptySelectedInspection: SelectedInspection = {
  id: '',
  norm: '003',
  client: emptyClientReference,
  product: {
    id: '',
    code: '',
    code2: '',
    invoice: '',
    subInvoice: 0,
    description: '',
    fase: ''
  },
  inspectionDate: null,
  inspector: null,
  roundID: 0,
  approver: null,
  serviceCode: 'CC',
  supervised: null,
  reviewDate: null,
  complies: null,
  comments: null
};

export const emptyMonthlyClosing: MonthlyClosing = {
  id: '',
  status: 'not-available',
  inspections: [],
  approver: null,
  comments: '',
  detectionOfRisksToImpartiality: false,
  detectionOfTrainingNeeds: false,
  risksToImpartiality: null,
  trainingNeeds: null,
  supervisionDate: null,
  beginningPeriod: new Date(),
  endPeriod: new Date(),
  createdAt: new Date(),
  notConcluded: null
};

export const emptyCorrectiveACtion: CorrectiveActions = {
  id: '',
  number: '',
  type: 'preventive',
  situation: '',
  involved: '',
  cause: '',
  descriptionOfActionsTaken: '',
  status: 'open',
  responsible: {
    id: '',
    name: ''
  },
  estimatedClosingDate: null,
  closingDate: null,
  approver: null,
  createdAt: new Date(),
  comments: []
};

export const emptyDictumServiceByID: DictumServiceByID = {
  ...emptyDictumService,
  lookInTheTrash: false
};
export const emptyDictumProductByID: DictumProductByID = {
  ...emptyDictumProduct,
  lookInTheTrash: false
};
export const emptyConstancyServiceByID: ConstancyServiceByID = {
  ...emptyConstancyService,
  lookInTheTrash: false
};
export const emptyRevisionServiceByID: RevisionServiceByID = {
  ...emptyRevisionService,
  lookInTheTrash: false
};
export const emptyConstancyProductByID: ConstancyProductByID = {
  ...emptyConstancyProduct,
  lookInTheTrash: false
};
export const emptyRevisionProductByID: RevisionProductByID = {
  ...emptyConstancyProduct,
  lookInTheTrash: false
};
export const emptyInspectionByID: InspectionByID = {
  ...emptyInspection,
  lookInTheTrash: false
};
export const emptTaskByID: TaskByID = {
  ...emptyTask,
  lookInTheTrash: false
};
