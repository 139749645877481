import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import es from './es.json';

const resources = {
  en: {
    translation: en
  },
  es: {
    translation: es
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    ns: ['translation'],
    defaultNS: 'translation',
    supportedLngs: ['en', 'es'],
    preload: ['en', 'es'],
    debug: true,
    fallbackLng: 'es',
    keySeparator: '.',
    nsSeparator: false,
    initImmediate: false
  });

export default i18n;
