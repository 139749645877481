/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import JSZip from 'jszip';

import { saveAs } from 'file-saver';
import { useResource } from '../../../../hooks';

import File from '../../../../components/File';

import '../../styles.scss';
import documentsDictionary from '../../../../dictionaries/documents';
import { Modal, SubTitle, Title } from '../../../../components';
import {
  DictumProduct,
  DictumService,
  Inspection,
  Visit
} from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';

type FileType = 'commercialInvoice' | 'petition' | 'paidPetition' | 'rectifiedPetition';

interface PlanificationItem {
  taskNumber: string
  taskID: string
  clientName: string
  visit: Visit | null
  endedAt: Date | null
}

interface Props {
  dictum: DictumService
  dictumServiceID: string
  clientShortName: string
  petitionNumber: string
  files: string[]
  onDownloadFileError: (_error: any) => void
}

const getFileName = (filePath: string): string => {
  const fullFileName = filePath.split('/').pop() || '';
  return fullFileName.split('__').shift() || '';
};

const getFileType = (filePath: string): FileType => {
  const fullFileName = filePath.split('/').pop() || '';
  const fileNamePlusExtension = fullFileName.split('__').pop() || '';
  let fileType = fileNamePlusExtension.split('.').shift() || '';

  if (fileType === 'paid-petition') fileType = 'paidPetition';
  if (fileType === 'rectified-petition') fileType = 'rectifiedPetition';
  if (fileType === 'commercial-invoice') fileType = 'commercialInvoice';

  return fileType as FileType;
};

const getFileLabel = (filePath: string): string => {
  const fileType = getFileType(filePath);

  if (fileType === 'commercialInvoice') return `${documentsDictionary[fileType]} ${getFileName(filePath)}`;

  return (documentsDictionary[fileType]);
};

const DictumServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    dictumServiceID,
    petitionNumber,
    files,
    onDownloadFileError = (_error: any) => { },
    clientShortName,
    dictum
  } = props;

  const { t } = useTranslation();

  const {
    fetchResource
  } = useResource<string>();

  const {
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getDictumDocuments
  } = useResource<any>();

  const {
    fetchResource: getDictumProductDocumentPDF
  } = useResource<any>();

  const {
    createResource: getDictumProductDocument,
    createResource: getDictumInspectionCertificateDocument
  } = useResource<any>();

  const {
    fetchResources
  } = useResource<any>();

  const {
    createResource
  } = useResource<PlanificationItem[]>();

  const {
    apiType,
    soundEffects,
    setOpenModal,
    userRole
  } = useContext(AppContext);

  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [tasksIDs, setTasksIDs] = useState<PlanificationItem[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [ableDownloadAllDocuments, setAbleDownloadAllDocuments] = useState<boolean>(true);
  // eslint-disable-next-line no-unused-vars
  const [productsCertificatesLimit, setProductsCertificatesLimit] = useState<string>('');

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `${apiType}/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => {
        onDownloadFileError(error);
      }
    );
  };

  const generateDictumDocuments = async () => {
    fetchResources(
      {
        resourcePath: `/${apiType}/dictum-products`,
        filters: {
          s_status: 'concluded',
          s_serviceID: dictum.id
        }
      },
      async (data) => {
        const productsList: { [name: string]: DictumProduct[] } = {};
        const finalList: DictumProduct[] = [];

        for (let index = 0; index < data.items.length; index += 1) {
          if (productsList[data.items[index].invoice] === undefined
            || productsList[data.items[index].invoice].length === 0) {
            productsList[data.items[index].invoice] = [data.items[index]];
          }
        }

        const keys = Object.keys(productsList);

        for (let index = 0; index < keys.length; index += 1) {
          finalList.push(productsList[keys[index]][0]);
        }

        let documentsDownloaded: number = 0;

        Modal.fireLoading(undefined, 0, t('global.downloading') || '');

        const zip = new JSZip();

        // eslint-disable-next-line no-restricted-syntax
        for (const product of finalList) {
          // eslint-disable-next-line no-await-in-loop, no-loop-func
          await new Promise<void>((resolve, reject) => {
            getDictumProductDocumentPDF(
              `/${apiType}/dictum-products/${product.id}/dictum-of-inspection`,
              (data2) => {
                const bytes = new Uint8Array(data2.file.data);
                const blob = new Blob([bytes], { type: 'application/pdf' });

                zip.file(`${product.invoice}-${dictum.petitionNumber}-Dictamen.pdf`, blob);

                documentsDownloaded += 1;

                // Actualizar el porcentaje y continuar con el siguiente documento
                const porcentaje = (documentsDownloaded / finalList.length) * 100;
                Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)), t('global.downloading') || '');

                resolve();
              },
              (error) => {
                Modal.fireError(error as string, undefined, soundEffects);
                reject(error);
              },
              false
            );
          });
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        saveAs(zipBlob, `Dictamenes_${petitionNumber}.zip`);

        Modal.fireSuccess(
          t('global.correct'),
          t('global.downloadedFiles'),
          setOpenModal,
          () => {
            Modal.close();
          },
          soundEffects
        );
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  };

  // const generateDictumDocuments = async () => {
  //   getDictumDocuments(
  //     `/${apiType}/dictum-services/${dictumServiceID}/bulk/dictum-of-inspection`,
  //     (data) => {
  //       Modal.fireLoading();
  //       const keys = Object.keys(data.files);

  //       const zip = new JSZip();

  //       const bytes: any[] = [];
  //       const blob: Blob[] = [];

  //       for (let i = 0; i < keys.length; i += 1) {
  //         bytes[i] = new Uint8Array(data.files[keys[i]].data);
  //         blob[i] = new Blob([bytes[i]], { type: 'application/pdf' });
  //         zip.file(`${keys[i]}-${petitionNumber}-Dictamen.pdf`, blob[i]);
  //       }

  //       zip.generateAsync({ type: 'blob' }).then((content) => {
  //         saveAs(content, `Dictamenes_${petitionNumber}.zip`);
  //       });

  //       Modal.close();
  //     },
  //     (error) => Modal.fireError(error as string, undefined, soundEffects)
  //   );
  // };

  const handleGenerateServiceRequestDocument = async () => {
    getDictumDocuments(
      `/${apiType}/documents/service-request/DC/${dictumServiceID}`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${petitionNumber} - SOLICITUD DE SERVICIOS - ${clientShortName}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  const checkAbleDownloadAllDictums = async () => {
    if (products.some((item) => item.status === 'concluded')) {
      const inspectionsStatus: string[] = [];
      const productsStatus: string[] = [];

      // eslint-disable-next-line array-callback-return, max-len
      await Promise.all(products.filter(item => item.indicators.attachedToTheService === true).map(async (product) => {
        productsStatus.push(product.status);

        const inspectionID = product.inspection?.id;

        if (inspectionID) {
          await getInspection(
            `/${apiType}/inspections/${inspectionID}`,
            (data) => {
              inspectionsStatus.push(data.status);
            },
            (error: string) => Modal.fireError(error, undefined, soundEffects)
          );
        }
      }));

      if (inspectionsStatus.every(status => status === 'concluded')
        && productsStatus.every(status => status === 'concluded')) {
        setAbleDownloadAllDocuments(false);
      }

      if (products.length > 80) {
        setProductsCertificatesLimit(t('global.limitCertificateGeneration') || '');
      }
    }
  };

  useEffect(() => {
    checkAbleDownloadAllDictums();
  }, [products]);

  useEffect(() => {
    fetchResources(
      {
        resourcePath: `/${apiType}/dictum-products`,
        filters: {
          s_serviceID: dictumServiceID
        }
      },
      (data) => {
        setProducts(data.items);
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, [dictumServiceID]);

  const handleGenerateInspectionCertificateDocument = async (
    taskID: string,
    _petitionNumber: string,
    taskNumber: string,
    clientName: string
  ) => {
    getDictumInspectionCertificateDocument(
      `/${apiType}/tasks/${taskID}/inspection-certificate`,
      {
        petitionNumber: _petitionNumber
      },
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `ACTA DE INSPECCION - ${petitionNumber} - ${clientName} - ${taskNumber}.pdf`;
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleGenerateAssignmentAndInspectionPlanningDocument = async (
    taskID: string,
    taskNumber: string,
    clientName: string
  ) => {
    getDictumProductDocument(
      `/${apiType}/tasks/${taskID}/assignation-and-planification-of-the-inspection`,
      {
        petitionNumber
      },
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `PLANIFICACION - ${petitionNumber} - ${clientName} - ${taskNumber}.pdf`;
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    setTasksIDs([]);
    const inspectionIDs: string[] = [];

    products.forEach((product: DictumProduct) => {
      if (product.inspection) {
        inspectionIDs.push(product.inspection.id);
      }
    });

    if (inspectionIDs.length > 0
      && apiType === 'admin') {
      createResource(
        '/admin/inspections/visit-documents',
        {
          inspectionIDs
        },
        (data) => {
          setTasksIDs(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [products, apiType]);

  const setdisableInspectionCertificate = (visit: Visit | null, endedAt: Date | null): boolean => {
    if (visit) {
      if (visit?.witnesses.length > 0
        && visit?.visitorStatements !== null
        && endedAt !== null) {
        return false;
      }
    }

    return true;
  };

  return (
    <div className="dictum-service-detail__documents" id="data-section">
      <Title title={t('services.documents')} type="primary" />
      <br />
      <div>
        <Title title={t('services.documentsCECSA')} type="secondary" />
        <div className="dictum-service-detail__documents__container">
          <File
            name={'Solicitud de servicio'}
            type={'service'}
            label={'Solicitud de servicio'}

            disabled={dictum.indicators.requestIsCompleted === false}
            onDownload={handleGenerateServiceRequestDocument}
            orientation='horizontal'
            showToolTip={false}
            hoverEffect={false}
          />
          {
            productsCertificatesLimit !== '' && (
              <SubTitle subTitle={t('global.limitCertificateGeneration')} red={true} />
            )
          }
          <File
            name='Descargar Dictamenes de Cumplimiento.zip'
            onDownload={generateDictumDocuments}
            disabled={ableDownloadAllDocuments || productsCertificatesLimit !== ''}
            orientation='horizontal'
            showToolTip={false}
          />
        </div>
      </div>
      <div className='dictum-service-detail__separator'></div>
      <div>
        <Title title={t('services.documentsRequest')} type="secondary" />
        <div className="dictum-service-detail__documents__files">
          {
            files.map((file: string) => {
              return (
                <div key={file}>
                  <File
                    name={getFileName(file)}
                    type={(getFileType(file) as FileType)}
                    label={getFileLabel(file)}

                    disabled={false}
                    onDownload={() => {
                      handleDownloadFile(file, getFileName(file));
                    }}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                </div>
              );
            })
          }
        </div>
      </div>
      {
        products.some((product) => product.task !== null) && userRole !== 'collaborator' && (
          <>
            <div className='dictum-service-detail__separator'></div>
            <div>
              <Title title={t('services.visitDocuments')} type="secondary" />
              <div className="dictum-service-detail__documents__files">
                {
                  tasksIDs.map((item: PlanificationItem, index: number) => {
                    if (item.taskID !== '') {
                      return (
                        <div key={index}>
                          <File
                            name={`ACTA DE INSPECCION - ${petitionNumber} - ${item.clientName} - ${item.taskNumber}.pdf`}
                            onDownload={() => {
                              handleGenerateInspectionCertificateDocument(
                                item.taskID,
                                petitionNumber,
                                item.taskNumber,
                                item.clientName
                              );
                            }}
                            disabled={setdisableInspectionCertificate(item.visit, item.endedAt)}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                          <File
                            name={`PLANIFICACION - ${petitionNumber} - ${item.clientName} - ${item.taskNumber}.pdf`}
                            onDownload={() => {
                              handleGenerateAssignmentAndInspectionPlanningDocument(
                                item.taskID,
                                item.taskNumber,
                                item.clientName
                              );
                            }}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </div>
                      );
                    }

                    return (<></>);
                  })
                }
              </div>
            </div>
            <div className='dictum-service-detail__separator'></div>
          </>
        )
      }
    </div>
  );
};

export default DictumServiceDetailDocuments;
