/* eslint-disable no-console */
/* eslint-disable max-len */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import saveAs from 'file-saver';
import axios from 'axios';
import dayjs from 'dayjs';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import {
  Icon,
  InspectionRound,
  Separator,
  Title,
  File as FileComponent,
  Modal,
  FileInput,
  Button,
  Spinner,
  SubTitle
} from '../../..';
import { AppContext } from '../../../../context/AppContext';
import { emptyRound } from '../../../../emptyObjects';
import { useResource } from '../../../../hooks';

import {
  FileInputData,
  Inspection,
  Round,
  SignedFile,
  User
} from '../../../../interfaces';
import { utils } from '../../../../helpers';
import './styles.scss';
import { IconTypes } from '../../../Icon/iconList';

interface TaskDetailModal {
  visible: boolean
  taskID: string
}

export interface FileToSign {
  path: string
  type: string
}

interface Props {
  user: User
  setInspection: (_value: Inspection) => void
  preselectedRoundID?: number
  setVisibleTaskDetailModal: (_value: TaskDetailModal) => void
  evidenceUploaded: boolean
  setEvidenceUploaded: (_value: boolean) => void
  design: boolean
  inspectionIsTheUser: boolean
}

const Rounds: React.FC<Props> = (props) => {
  const {
    user,
    setInspection,
    preselectedRoundID = -1,
    setVisibleTaskDetailModal,
    evidenceUploaded,
    setEvidenceUploaded,
    inspectionIsTheUser,
    design
  } = props;

  const {
    fetchResource
  } = useResource<string>();

  const {
    eraseResource,
    updateResource
  } = useResource<Inspection>();

  const {
    fetchResource: getInspectionFormatDocument,
    createResource
  } = useResource<any>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    apiType,
    setOpenModal,
    inspection,
    soundEffects,
    setVisibleInspectionDetailModal,
    userRole,
    approver
  } = useContext(AppContext);

  useMemo(() => {
    inspection.rounds.sort((a, b) => {
      return a.id - b.id;
    });
  }, [inspection]);

  const { t } = useTranslation();

  const acceptedFileFormats = '.jpg, .jpeg, .png, .ai, .PNG, .JPG, JPEG';
  const maxFilesQuantity = 30;
  const [selectedRound, setSelectedRound] = useState<Round>(emptyRound);
  const [selectedEvidenceImageSRC, setSelectedEvidenceImageSRC] = useState<string>('');
  const [roundsList, setRoundsList] = useState<Round[]>([]);
  const webcamRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [openCamera, setOpenCamera] = useState<boolean>(false);
  const [cameraType, setCameraType] = useState<'user' | 'environment'>('environment');
  const [loadingImagePreview, setLoadingImagePreview] = useState<boolean>(false);
  const [pdfURL, setPdfURL] = useState<string>('');
  const [rotation, setRotation] = useState(0);

  const rotateLeft = () => {
    setRotation(prevRotation => prevRotation - 90);
  };

  const rotateRight = () => {
    setRotation(prevRotation => prevRotation + 90);
  };

  const setComplies = () => {
    if (selectedRound.complies === null) {
      return t('inspections.inProgress');
    }

    if (selectedRound.complies) {
      return t('tasks.filters.complies');
    }

    return t('tasks.filters.notComplies');
  };

  const handleLoadImage = (filePath: string) => {
    if (!selectedEvidenceImageSRC.includes(filePath)) {
      setLoadingImagePreview(true);
      fetchResource(
        `${apiType}/documents/signature?s_filePath=${filePath}`,
        (data) => {
          setSelectedEvidenceImageSRC(data);
          setRotation(0);
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `${apiType}/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const handleDownloadInspectionFormat = () => {
    getInspectionFormatDocument(
      `/${apiType}/inspections/${inspection.id}/${selectedRound.id}/inspection-format`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `Formato de Inspección-${inspection.product.invoice}-ronda-${selectedRound.id}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  const ifSelectedRoundAcceptEvidence = (): boolean => {
    if (selectedRound.files.filter(item => item.includes('__evidence')).length <= maxFilesQuantity
      && inspection.status === 'in-progress'
      && selectedRound.steps.find(element => element === 'dictaminate') === undefined
      && user.id === inspection.inspector?.id
      && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
      && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
      && selectedRound.steps.find(element => element === 'list-completed') !== undefined
      && evidenceUploaded === false) {
      return true;
    }

    return false;
  };

  const ableConfirmEvidenceButton = (): boolean => {
    if (selectedRound.files.some((file) => file.includes('__evidence'))
      && inspection.status === 'in-progress'
      && selectedRound.steps.find(element => element === 'list-completed') !== undefined
      && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
      && evidenceUploaded === false
      && inspection.status === 'in-progress'
      && inspectionIsTheUser) {
      return true;
    }

    return false;
  };

  const ableConfirmDesignButton = (): boolean => {
    if ((selectedRound.id === 1
      && ['CC', 'CN', 'REV'].includes(inspection.service.code)
      && design)
      && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
      && selectedRound.files.some((file) => file.includes('__design'))
      && selectedRound.files.some((file) => file.includes('__original-format-design'))
      && selectedRound.files.some((file) => file.includes('__evidence'))
      && inspection.status === 'in-progress'
      && inspectionIsTheUser
      && evidenceUploaded === false) {
      return true;
    }

    return false;
  };

  const handleDeleteFile = (fileName: string) => {
    eraseResource(
      `/admin/inspections/${inspection.id}/documents/${selectedRound.id}?s_documentPath=${fileName}`,
      {},
      (_data: Inspection) => {
        if (fileName.includes(selectedEvidenceImageSRC.split('/').pop() || '')) {
          setSelectedEvidenceImageSRC('');
        }
        setInspection(_data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleUploadEvidence = async (paramFiles: FileInputData[]) => {
    if ((paramFiles.length + selectedRound.files.filter(item => item.includes('__evidence')).length) > maxFilesQuantity
      || (paramFiles.length > maxFilesQuantity)) {
      Modal.fireError(`${t('services.onlyProductsUploaded')} ${maxFilesQuantity} ${t('global.files')}`, setOpenModal, soundEffects);
      return;
    }

    const payload = new FormData();

    for (let index = 0; index < paramFiles.length; index += 1) {
      payload.append('documents', paramFiles[index].data, paramFiles[index].fileName.replace(/[%&?¿=#/+]/g, ''));
    }

    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/evidence/rounds/${selectedRound.id}/documents`,
      payload,
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            const file = paramFiles.find((fileItem) => item.documentName.endsWith(fileItem.fileName));

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          Modal.fireLoading();
          updateResource(
            `/admin/inspections/${inspection.id}/${selectedRound.id}/evidence/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (data2) => {
              setInspection(data2);
              setImgSrc(null);

              // eslint-disable-next-line no-undef
              const errorElement = document.querySelectorAll('#accept-evidence-button');

              if (errorElement[0]) {
                errorElement[0].scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleUploadDesign = (paramFiles: FileInputData[]) => {
    if ((paramFiles.length + selectedRound.files.filter(item => item.includes('__design.')).length) > 3
      || (paramFiles.length > 3)) {
      Modal.fireError(`${t('services.onlyProductsUploaded')} 3 ${t('global.files')}`, setOpenModal, soundEffects);
      return;
    }

    const filePaths: FileToSign[] = [];

    for (let index = 0; index < paramFiles.length; index += 1) {
      filePaths.push({
        path: paramFiles[index].fileName.replace(/[%&?¿=#/+]/g, ''),
        type: paramFiles[index].data.type
      });
    }

    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/rounds/${selectedRound.id}/documents/design`,
      {
        filePaths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            const file = paramFiles.find((fileItem) => item.documentName.endsWith(fileItem.fileName));

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/admin/inspections/${inspection.id}/${selectedRound.id}/design/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (data2) => {
              setInspection(data2);

              setSelectedRound(
                data2.rounds.find(element => element.id === selectedRound.id)
                || emptyRound
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleUploadDesignOriginalFormat = (paramFiles: FileInputData[]) => {
    if ((paramFiles.length + selectedRound.files.filter(item => item.includes('__original-format-design.')).length) > 3
      || (paramFiles.length > 3)) {
      Modal.fireError(`${t('services.onlyProductsUploaded')} 3 ${t('global.files')}`, setOpenModal, soundEffects);
      return;
    }

    const filePaths: FileToSign[] = [];

    for (let index = 0; index < paramFiles.length; index += 1) {
      filePaths.push({
        path: paramFiles[index].fileName.replace(/[%&?¿=#/+]/g, ''),
        type: paramFiles[index].data.type
      });
    }

    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/rounds/${selectedRound.id}/documents/original-format-design`,
      {
        filePaths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            const file = paramFiles.find((fileItem) => item.documentName.endsWith(fileItem.fileName));

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/admin/inspections/${inspection.id}/${selectedRound.id}/original-format-design/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (data2) => {
              setInspection(data2);

              setSelectedRound(
                data2.rounds.find(element => element.id === selectedRound.id)
                || emptyRound
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const setUploadedEvidence = () => {
    updateResource(
      `/admin/inspections/${inspection.id}/uploaded-evidence`,
      {},
      (data) => {
        setInspection(data);
        setEvidenceUploaded(true);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    const round = inspection.rounds.find(element => element.id === selectedRound.id);

    if (round) {
      setSelectedRound(round);
    }
  }, [inspection.rounds]);

  useEffect(() => {
    if (preselectedRoundID) {
      const round = inspection.rounds.find(element => element.id === preselectedRoundID);

      if (round) {
        setSelectedRound(round);
      }
    }
  }, [preselectedRoundID, inspection.rounds]);

  useEffect(() => {
    const auxRounds: Round[] = [];

    for (let index = 0; index < inspection.rounds.length; index += 1) {
      auxRounds.push(inspection.rounds[index]);
    }

    setRoundsList(auxRounds);
  }, [inspection.rounds]);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      setImgSrc(webcamRef.current.getScreenshot());
    }
  }, [webcamRef]);

  const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: 'image/jpeg' });
  };

  const handleUploadCameraEvidence = async (paramFiles: FileInputData[]) => {
    if ((paramFiles.length + selectedRound.files.filter(item => item.includes('__evidence')).length) > maxFilesQuantity
      || (paramFiles.length > maxFilesQuantity)) {
      Modal.fireError(`${t('services.onlyProductsUploaded')} ${maxFilesQuantity} ${t('global.files')}`, setOpenModal, soundEffects);
      return;
    }

    const filePaths: FileToSign[] = [];

    for (let index = 0; index < paramFiles.length; index += 1) {
      filePaths.push({
        path: paramFiles[index].fileName.replace(/[%&?¿=#/+]/g, ''),
        type: paramFiles[index].data.type
      });
    }

    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/rounds/${selectedRound.id}/documents/evidence`,
      {
        filePaths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            const file = paramFiles.find((fileItem) => item.documentName.endsWith(fileItem.fileName));

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/admin/inspections/${inspection.id}/${selectedRound.id}/evidence/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (data2) => {
              setInspection(data2);
              setImgSrc(null);

              // eslint-disable-next-line no-undef
              const errorElement = document.querySelectorAll('#accept-evidence-button');

              if (errorElement[0]) {
                errorElement[0].scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                });
              }
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };
  useEffect(() => {
    if (imgSrc) {
      const id = Math.floor(Math.random() * 1000);

      handleUploadCameraEvidence([{
        data: dataURLtoFile(imgSrc, `capture-${id}.jpeg`),
        fileName: `capture-${id}.jpeg`.replace(/%/g, '')
      }]);
    }
  }, [imgSrc]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const errorElement = document.querySelectorAll('#image-preview');

    if (errorElement[0]) {
      errorElement[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [selectedEvidenceImageSRC]);

  const icons: { [name: string]: IconTypes } = {
    true: 'roundComplies',
    false: 'roundNotComplies',
    null: 'roundInProgress'
  };

  const setDate = (date: string | Date | null | undefined) => {
    if (date === 'null'
      || date === 'undefined'
      || date === ''
      || date === null
      || date === undefined) {
      return t('global.undefined');
    }

    return dayjs(new Date(date)).format('DD/MMM/YYYY hh:mm a');
  };

  const downloadAllEvidence = async () => {
    createResource(
      `/${apiType}/inspections/${inspection.id}/${selectedRound.id}/bulk-inspection-evidence`,
      {},
      (data) => {
        Modal.fireLoading();

        const blob = new Blob([data], { type: 'application/zip' });
        saveAs(blob, `Evidencia inspeccion ${inspection.number} ronda #${selectedRound.id}.zip`);

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects),
      {
        responseType: 'blob'
      }
    );
  };

  if ((selectedRound.id === 1
    && ['CC', 'CN', 'REV'].includes(inspection.service.code)
    && design) === false) {
    return (
      <div className='inspection-detail__rounds-container'>
        <Title title={t('inspections.rounds') || ''} />
        <div className='inspection-detail__rounds'>
          <div className={`inspection-detail__rounds__list inspection-detail__rounds__list${selectedRound.id !== -1 ? '--opened' : ''}`}>
            {
              roundsList.map((round: Round, index: number) => (
                <InspectionRound
                  title={`${t('inspections.round')} ${round.id}`}
                  complies={round.complies}
                  onClick={() => {
                    setSelectedRound(round);
                  }}
                  isSelected={selectedRound.id === round.id}
                  key={index}
                />
              ))
            }
          </div>
          {
            selectedRound.id !== -1 && (
              <>
                <Separator orientation='vertical' />
                <div className='inspection-detail__rounds__documents'>
                  <div className='inspection-detail__rounds__documents__header'>
                    <Icon type={icons[`${selectedRound.complies}`]} className='inspection-detail__rounds__documents__header__icon' />
                    <div className='inspection-detail__rounds__documents__header__label'>
                      <p className='inspection-detail__rounds__documents__header__label__round'>
                        {t('inspections.round')}
                        {' '}{selectedRound.id}
                        <p>
                          {setComplies()}
                          {selectedRound.productNotPresented ? ` - ${t('services.notPresentedProduct')}` : ''}
                        </p>
                        {
                          apiType === 'admin' && (
                            <>
                              <p className='inspection-detail__rounds__inspector'>Inspector: <b>{selectedRound.inspector?.name || t('global.undefined')}</b></p>
                              <p className='inspection-detail__rounds__inspector'>{t('global.approver')}: <b>{selectedRound.approver?.name || t('global.undefined')}</b></p>
                            </>
                          )
                        }
                        {
                          (['master', 'sys-admin'].includes(userRole) || approver === true) && (
                            <>
                              <p className='inspection-detail__rounds__inspector'>{t('global.inspectionStartAt')}: <b>{setDate(`${selectedRound.startAt}`)}</b></p>
                              <p className='inspection-detail__rounds__inspector'>{t('global.inspectionEndAt')}: <b>{setDate(`${selectedRound.endsAt}`)}</b></p>
                              <p className='inspection-detail__rounds__inspector'>{t('global.inspectionApprovedAt')}: <b>{setDate(`${selectedRound.approvedDate}`)}</b></p>
                            </>
                          )
                        }
                        {
                          inspection.indicators.waitingToExpired && inspection.rounds.at(-1)?.id === selectedRound.id && (
                            <p className='inspection-detail__rounds__inspector inspection-detail__rounds__inspector--alert'><b>{t('global.expiredInvoice')}</b></p>
                          )
                        }
                      </p>
                      {
                        selectedRound.task
                        && apiType === 'admin'
                        && selectedRound.task?.id && (
                          <Button
                            onClick={() => {
                              setVisibleTaskDetailModal({ taskID: selectedRound.task?.id || '', visible: true });
                              setVisibleInspectionDetailModal({
                                visible: false,
                                inspectionID: inspection.id
                              });
                            }}
                            label={`${t('inspections.seeTask')} ${selectedRound.task.number}` || ''}
                            type='secondary-outlined'
                          />
                        )
                      }
                    </div>
                    <Separator orientation={'horizontal'} />
                  </div>
                  <Icon
                    type={'crossMark'}
                    alt={'x'}
                    onClick={() => {
                      setSelectedRound(emptyRound);
                      setImgSrc(null);
                      setOpenCamera(false);
                      setSelectedEvidenceImageSRC('');
                      setRotation(0);
                    }}
                    className='inspection-detail__rounds__documents__close-icon'
                  />
                  <div className='inspection-detail__rounds__documents__documents-list'>
                    {
                      selectedRound.files.find(element => element.includes('__round-feedback')) && (
                        <p className="evidence-title">
                          {t('services.inspectionFormat')}
                        </p>
                      )
                    }
                    <div className='inspection-detail__rounds__documents__documents-list__list'>
                      {
                        selectedRound.files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                          <>
                            {
                              selectedRound.files.map((file: string, index: number) => (
                                file.includes('__round-feedback.pdf') && (
                                  <FileComponent
                                    name={file.split('/')[file.split('/').length - 1]}
                                    key={index}
                                    disabled={false}
                                    onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1])}
                                    onDelete={inspection.status === 'in-progress'
                                      && (['DC', 'DN'].includes(inspection.service.code)
                                        ? user.id === utils.getCurrentRound(inspection.rounds).inspector?.id
                                        : user.id === inspection.inspector?.id)
                                      // eslint-disable-next-line max-len
                                      && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
                                      && evidenceUploaded === false
                                      && selectedRound.steps.find(element => element === 'list-completed') !== undefined
                                      && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
                                      && inspectionIsTheUser
                                      ? () => handleDeleteFile(file) : undefined
                                    }
                                    orientation='horizontal'
                                  />
                                )
                              ))
                            }
                          </>
                        ) : (
                          <>
                            {
                              selectedRound.steps.find(element => element === 'list-completed') !== undefined && (
                                <>
                                  <b><SubTitle subTitle={t('services.inspectionFormat')} /></b>
                                  <FileComponent
                                    name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-${selectedRound.id}.pdf`}
                                    disabled={userRole === 'collaborator' && selectedRound.approver === null}
                                    onDownload={selectedRound.steps.find(element => element === 'list-completed') !== undefined
                                      ? () => handleDownloadInspectionFormat()
                                      : undefined}
                                    orientation='horizontal'
                                  />
                                </>
                              )
                            }
                          </>
                        )
                      }
                      <br />
                      {
                        pdfURL !== '' && (
                          <div className="inspection-upload-evidence__pdf-container">
                            <Button
                              type={'primary'}
                              label={`${t('global.close')} PDF`}
                              onClick={() => setPdfURL('')}
                              icon='closeWhite'
                            />
                            <object
                              aria-label="Document Viewer"
                              data={pdfURL}
                              style={{
                                width: '100%',
                                height: '600px',
                                display: 'flex',
                                alignItems: 'center',
                                textAlign: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              Tu navegador no soporta la visualización de este archivo.
                            </object>
                          </div>
                        )
                      }
                    </div>
                    {
                      (ifSelectedRoundAcceptEvidence())
                      && (
                        <div className="inspection-upload-evidence__container">
                          <p className="inspection-upload-evidence__text inspection-upload-evidence__text--big">
                            {t('services.inspectionFiles1')}
                          </p>
                          <p className="inspection-upload-evidence__text inspection-upload-evidence__text--medium">
                            {t('services.inspectionFiles2', { maxFilesQuantity })}
                          </p>
                          <br />
                          <div className="inspection-upload-evidence__file-input">
                            {
                              openCamera ? (
                                <div className="inspection-upload-evidence__file-input__webcam">
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    videoConstraints={{
                                      facingMode: cameraType
                                    }}
                                  />
                                  <div className="inspection-upload-evidence__file-input__webcam__buttons">
                                    <Button
                                      type={'red'}
                                      onClick={() => setOpenCamera(false)}
                                      label={t('global.cancel') || ''}
                                      icon='crossMark'
                                      fullWidth={true}
                                      size='big'
                                    />
                                    <Button
                                      type={'secondary'}
                                      onClick={() => {
                                        if (cameraType === 'environment') {
                                          setCameraType('user');
                                        } else {
                                          setCameraType('environment');
                                        }
                                      }}
                                      icon="cameraSwitch"
                                      label={t('global.switchCamera') || ''}
                                      fullWidth={true}
                                      size='big'
                                    />
                                    <Button
                                      type={'primary'}
                                      onClick={() => {
                                        capture();
                                        setSelectedEvidenceImageSRC('');
                                      }}
                                      label={t('global.takePhoto') || ''}
                                      icon='camera'
                                      fullWidth={true}
                                      size='big'
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <FileInput
                                    isMultiple={true}
                                    acceptedFileFormats={acceptedFileFormats}
                                    onSelectFile={handleUploadEvidence}
                                    fileName={t('services.inspectionFiles') || ''}
                                    helperText={t('services.inspectionFilesHelperText', { acceptedFileFormats }) || ''}
                                  />
                                  <Button
                                    type={'secondary'}
                                    onClick={() => {
                                      setOpenCamera(true);
                                      setSelectedEvidenceImageSRC('');
                                    }}
                                    label={t('global.cameraButton') || ''}
                                    icon='camera'
                                  />
                                </>
                              )
                            }
                          </div>
                          <p className="inspection-upload-evidence__text inspection-upload-evidence__text--small">
                            {t('services.files', { filesQuantity: selectedRound.files.filter(item => item.includes('__evidence')).length })}
                          </p>
                        </div>
                      )
                    }
                    <div className='inspection-detail__rounds__documents__documents-list__list'>
                      {
                        selectedRound.files.filter(item => item.includes('__evidence')).length > 0 && (
                          <div className='inspection-detail__rounds__documents__documents-list__title'>
                            <p className="evidence-title">
                              {t('services.evidences')}
                            </p>
                            <Button
                              type={'secondary-outlined'}
                              onClick={downloadAllEvidence}
                              label={t('global.downloadAllEvidence') || ''}
                              icon='downloadWhite'
                              fullWidth={true}
                            />
                          </div>
                        )
                      }
                      {
                        selectedRound.files.map((file: string, index: number) => (
                          file.includes('__evidence') && (
                            <FileComponent
                              name={file.split('/')[file.split('/').length - 1]}
                              key={index}

                              disabled={false}
                              onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1])}
                              orientation='horizontal'
                              showToolTip={false}
                              onClick={() => handleLoadImage(file)}
                              onDelete={inspection.status === 'in-progress'
                                && (['DC', 'DN'].includes(inspection.service.code)
                                  ? user.id === utils.getCurrentRound(inspection.rounds).inspector?.id
                                  : user.id === inspection.inspector?.id)
                                // eslint-disable-next-line max-len
                                && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
                                && evidenceUploaded === false
                                && selectedRound.steps.find(element => element === 'list-completed') !== undefined
                                && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
                                && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                                && inspectionIsTheUser
                                ? () => handleDeleteFile(file) : undefined}
                              hoverEffect={true}
                            />
                          )
                        ))
                      }
                    </div>
                    {
                      selectedEvidenceImageSRC && selectedRound.files.filter(item => item.includes('__evidence')).length > 0 && (
                        <>
                          <TransformWrapper
                            initialScale={1}
                            minScale={0.5}
                            maxScale={3}
                          >
                            {({
                              zoomIn,
                              zoomOut,
                              resetTransform
                            }) => (
                              <div id='image-viewer'>
                                <div className='rotate-container'>
                                  <button onClick={rotateRight}>
                                    <Icon type={'rotateRigth'} />
                                  </button>
                                  <button onClick={rotateLeft}>
                                    <Icon type={'rotateLeft'} />
                                  </button>
                                  <button onClick={() => zoomIn()}>
                                    <Icon type={'zoomIn'} />
                                  </button>
                                  <button onClick={() => zoomOut()}>
                                    <Icon type={'zoomOut'} />
                                  </button>
                                  <button onClick={() => {
                                    resetTransform();
                                    setRotation(0);
                                  }}>Reset</button>
                                </div>
                                <TransformComponent>
                                  {
                                    loadingImagePreview && (
                                      <div className='inspection-detail__rounds__documents__documents-list__image-preview__spinner'>
                                        <Spinner />
                                      </div>
                                    )
                                  }
                                  <img
                                    src={selectedEvidenceImageSRC}
                                    alt="Image Viewer"
                                    style={{
                                      transform: `rotate(${rotation}deg)`,
                                      maxWidth: '100%'
                                    }}
                                    onLoad={() => {
                                      // eslint-disable-next-line no-undef
                                      const element = document.getElementById('image-viewer');

                                      if (element) {
                                        element.scrollIntoView({
                                          behavior: 'smooth',
                                          block: 'nearest'
                                        });
                                      }

                                      setLoadingImagePreview(false);
                                      resetTransform();
                                      setRotation(0);
                                    }}
                                  />
                                </TransformComponent>
                              </div>
                            )}
                          </TransformWrapper>
                        </>
                      )
                    }
                    {
                      ableConfirmEvidenceButton() && (
                        <Button
                          onClick={setUploadedEvidence}
                          type='primary'
                          label={t('services.confirmEvidence') || ''}
                          fullWidth={true}
                          id='accept-evidence-button'
                          size='big'
                        />
                      )
                    }
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div >
    );
  }

  return (
    <div className='inspection-detail__rounds-container'>
      <Title title={t('inspections.rounds') || ''} />
      <div className='inspection-detail__rounds'>
        <div className={`inspection-detail__rounds__list inspection-detail__rounds__list${selectedRound.id !== -1 ? '--opened' : ''}`}>
          {
            roundsList.map((round: Round, index: number) => (
              <InspectionRound
                title={`${t('inspections.round')} ${round.id}`}
                complies={round.complies}
                onClick={() => {
                  setSelectedRound(round);
                }}
                isSelected={selectedRound.id === round.id}
                key={index}
              />
            ))
          }
        </div>
        {
          selectedRound.id !== -1 && (
            <>
              <Separator orientation='vertical' />
              <div className='inspection-detail__rounds__documents'>
                <div className='inspection-detail__rounds__documents__header'>
                  <Icon type={icons[`${selectedRound.complies}`]} className='inspection-detail__rounds__documents__header__icon' />
                  <div className='inspection-detail__rounds__documents__header__label'>
                    <p className='inspection-detail__rounds__documents__header__label__round'>
                      {t('inspections.round')}
                      {' '}{selectedRound.id}{' '}{t('global.forDesign')}
                      <p>
                        {setComplies()}
                        {selectedRound.productNotPresented ? ` - ${t('services.notPresentedProduct')}` : ''}
                      </p>
                      <p className='inspection-detail__rounds__inspector'>Inspector: <b>{selectedRound.inspector?.name || t('global.undefined')}</b></p>
                      <p className='inspection-detail__rounds__inspector'>{t('global.approver')}: <b>{selectedRound.approver?.name || t('global.undefined')}</b></p>
                      {
                        (['master', 'sys-admin'].includes(userRole) || approver === true) && (
                          <>
                            <p className='inspection-detail__rounds__inspector'>{t('global.inspectionStartAt')}: <b>{setDate(`${selectedRound.startAt}`)}</b> </p>
                            <p className='inspection-detail__rounds__inspector'>{t('global.inspectionEndAt')}: <b>{setDate(`${selectedRound.endsAt}`)}</b></p>
                            <p className='inspection-detail__rounds__inspector'>{t('global.inspectionApprovedAt')}: <b>{setDate(`${selectedRound.approvedDate}`)}</b></p>
                          </>
                        )
                      }
                      {
                        inspection.indicators.waitingToExpired && inspection.rounds.at(-1)?.id === selectedRound.id && (
                          <p className='inspection-detail__rounds__inspector inspection-detail__rounds__inspector--alert'><b>{t('global.expiredInvoice')}</b></p>
                        )
                      }
                    </p>
                    {
                      selectedRound.task
                      && apiType === 'admin'
                      && selectedRound.task?.id && (
                        <Button
                          onClick={() => {
                            setVisibleTaskDetailModal({ taskID: selectedRound.task?.id || '', visible: true });
                            setVisibleInspectionDetailModal({
                              visible: false,
                              inspectionID: inspection.id
                            });
                          }}
                          label={`${t('inspections.seeTask')} ${selectedRound.task.number}` || ''}
                          type='secondary-outlined'
                        />
                      )
                    }
                  </div>
                  <Separator orientation={'horizontal'} />
                </div>
                <Icon
                  type={'crossMark'}
                  alt={'x'}
                  onClick={() => {
                    setSelectedRound(emptyRound);
                    setImgSrc(null);
                    setOpenCamera(false);
                    setSelectedEvidenceImageSRC('');
                  }}
                  className='inspection-detail__rounds__documents__close-icon'
                />
                <div className='inspection-detail__rounds__documents__documents-list'>
                  {
                    selectedRound.files.filter(element => element.includes('__design.')).length < 3
                    && inspection.status === 'in-progress'
                    && inspectionIsTheUser
                    && selectedRound.steps.find(element => element === 'product-updated') !== undefined
                    && selectedRound.steps.find(element => element === 'list-completed') !== undefined
                    && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                    && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined && (
                      <div className='upload-file-modal'>
                        <Title title={`${t('global.design')} (${t('global.evaluate')})`} type='primary' />
                        <FileInput
                          isMultiple={true}
                          acceptedFileFormats='.pdf,.qdf'
                          onSelectFile={handleUploadDesign}
                        />
                      </div>
                    )
                  }
                  {
                    selectedRound.files.find(element => element.includes('__design.')) && (
                      <p className="evidence-title">
                        {`${t('global.design')} (${t('global.evaluate')})`}
                      </p>
                    )
                  }
                  {
                    selectedRound.files.map((file: string, index: number) => (
                      file.includes('__design.') && (
                        <FileComponent
                          name={file.split('/')[file.split('/').length - 1]}
                          key={index}
                          disabled={false}
                          orientation='horizontal'
                          onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1])}
                          onDelete={inspection.status === 'in-progress'
                            // eslint-disable-next-line max-len
                            && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
                            && evidenceUploaded === false
                            && selectedRound.steps.find(element => element === 'product-updated') !== undefined
                            && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                            && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
                            && inspectionIsTheUser
                            ? () => handleDeleteFile(file) : undefined
                          }
                        />
                      )
                    ))
                  }
                  <Separator orientation={'horizontal'} />
                  {
                    selectedRound.files.filter(element => element.includes('__original-format-design')).length < 3
                    && inspection.status === 'in-progress'
                    && inspectionIsTheUser
                    && selectedRound.steps.find(element => element === 'product-updated') !== undefined
                    && selectedRound.steps.find(element => element === 'list-completed') !== undefined
                    && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                    && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined && (
                      <div className='upload-file-modal'>
                        <Title title={`${t('global.design')} (${t('global.origialFormat')})`} type='primary' />
                        <FileInput
                          isMultiple={true}
                          acceptedFileFormats='.ai,.pdf,.docx,.doc,.qdf'
                          onSelectFile={handleUploadDesignOriginalFormat}
                        />
                      </div>
                    )
                  }
                  <div className='inspection-detail__rounds__documents__documents-list__list'>
                    {
                      selectedRound.files.find(element => element.includes('__original-format-design') && userRole !== 'collaborator') && (
                        <p className="evidence-title">
                          {`${t('global.design')} (${t('global.origialFormat')})`}
                        </p>
                      )
                    }
                    {
                      selectedRound.files.map((file: string, index: number) => (
                        file.includes('__original-format-design')
                        && userRole !== 'collaborator' && (
                          <FileComponent
                            name={file.split('/')[file.split('/').length - 1]}
                            key={index}
                            disabled={false}
                            orientation='horizontal'
                            onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1])}
                            onDelete={inspection.status === 'in-progress'
                              // eslint-disable-next-line max-len
                              && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
                              && evidenceUploaded === false
                              && selectedRound.steps.find(element => element === 'product-updated') !== undefined
                              && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                              && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
                              && inspectionIsTheUser
                              ? () => handleDeleteFile(file) : undefined
                            }
                          />
                        )
                      ))
                    }
                    {
                      pdfURL !== '' && (
                        <div className="inspection-upload-evidence__pdf-container">
                          <Button
                            type={'primary'}
                            label={`${t('global.close')} PDF`}
                            onClick={() => setPdfURL('')}
                            icon='closeWhite'
                          />
                          <object
                            aria-label="Document Viewer"
                            data={pdfURL}
                            style={{
                              width: '100%',
                              height: '600px',
                              display: 'flex',
                              alignItems: 'center',
                              textAlign: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            Tu navegador no soporta la visualización de este archivo.
                          </object>
                        </div>
                      )
                    }
                  </div>
                  <br />
                  <Separator orientation='horizontal' />
                  {
                    (ifSelectedRoundAcceptEvidence())
                    && (
                      <div className="inspection-upload-evidence__container">
                        <p className="inspection-upload-evidence__text inspection-upload-evidence__text--big">
                          {t('services.inspectionFiles1')}
                        </p>
                        <p className="inspection-upload-evidence__text inspection-upload-evidence__text--medium">
                          {t('services.inspectionFiles2', { maxFilesQuantity })}
                        </p>
                        <br />
                        <div className="inspection-upload-evidence__file-input">
                          {
                            openCamera ? (
                              <div className="inspection-upload-evidence__file-input__webcam">
                                <Webcam
                                  audio={false}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={{
                                    facingMode: cameraType
                                  }}
                                />
                                <div className="inspection-upload-evidence__file-input__webcam__buttons">
                                  <Button
                                    type={'red'}
                                    onClick={() => setOpenCamera(false)}
                                    label={t('global.cancel') || ''}
                                    icon='crossMark'
                                    fullWidth={true}
                                    size='big'
                                  />
                                  <Button
                                    type={'secondary'}
                                    onClick={() => {
                                      if (cameraType === 'environment') {
                                        setCameraType('user');
                                      } else {
                                        setCameraType('environment');
                                      }
                                    }}
                                    icon="cameraSwitch"
                                    label={t('global.switchCamera') || ''}
                                    fullWidth={true}
                                    size='big'
                                  />
                                  <Button
                                    type={'primary'}
                                    onClick={() => {
                                      capture();
                                      setSelectedEvidenceImageSRC('');
                                    }}
                                    label={t('global.takePhoto') || ''}
                                    icon='camera'
                                    fullWidth={true}
                                    size='big'
                                  />
                                </div>
                              </div>
                            ) : (
                              <>
                                <FileInput
                                  isMultiple={true}
                                  acceptedFileFormats={acceptedFileFormats}
                                  onSelectFile={handleUploadEvidence}
                                  fileName={t('services.inspectionFiles') || ''}
                                  helperText={t('services.inspectionFilesHelperText', { acceptedFileFormats }) || ''}
                                />
                                <Button
                                  type={'secondary'}
                                  onClick={() => {
                                    setOpenCamera(true);
                                    setSelectedEvidenceImageSRC('');
                                  }}
                                  label={t('global.cameraButton') || ''}
                                  icon='camera'
                                />
                              </>
                            )
                          }
                        </div>
                        <p className="inspection-upload-evidence__text inspection-upload-evidence__text--small">
                          {t('services.files', { filesQuantity: selectedRound.files.filter(item => item.includes('__evidence')).length })}
                        </p>
                      </div>
                    )
                  }
                  <div className='inspection-detail__rounds__documents__documents-list__list'>
                    {
                      selectedRound.files.filter(item => item.includes('__evidence')).length > 0 && (
                        <div className='inspection-detail__rounds__documents__documents-list__title'>
                          <p className="evidence-title">
                            {t('services.evidences')}
                          </p>
                          <Button
                            type={'secondary-outlined'}
                            onClick={downloadAllEvidence}
                            label={t('global.downloadAllEvidence') || ''}
                            icon='downloadWhite'
                            fullWidth={true}
                          />
                        </div>
                      )
                    }
                    {
                      selectedRound.files.map((file: string, index: number) => (
                        file.includes('__evidence') && (
                          <FileComponent
                            name={file.split('/')[file.split('/').length - 1]}
                            key={index}

                            disabled={false}
                            onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1])}
                            orientation='horizontal'
                            showToolTip={false}
                            onClick={() => handleLoadImage(file)}
                            onDelete={inspection.status === 'in-progress'
                              && (['DC', 'DN'].includes(inspection.service.code)
                                ? user.id === utils.getCurrentRound(inspection.rounds).inspector?.id
                                : user.id === inspection.inspector?.id)
                              // eslint-disable-next-line max-len
                              && utils.getCurrentRound(inspection.rounds).id === selectedRound.id
                              && evidenceUploaded === false
                              && selectedRound.steps.find(element => element === 'list-completed') !== undefined
                              && selectedRound.steps.find(element => element === 'uploaded-evidence') === undefined
                              && selectedRound.steps.find(element => element === 'dictaminate') === undefined
                              && inspectionIsTheUser
                              ? () => handleDeleteFile(file) : undefined}
                            hoverEffect={true}
                          />
                        )
                      ))
                    }
                  </div>
                  {
                    selectedEvidenceImageSRC && selectedRound.files.filter(item => item.includes('__evidence')).length > 0 && (
                      <>
                        <TransformWrapper
                          initialScale={1}
                          minScale={0.5}
                          maxScale={3}
                        >
                          {({
                            zoomIn,
                            zoomOut,
                            resetTransform
                          }) => (
                            <div id='image-viewer'>
                              <div className='rotate-container'>
                                <button onClick={rotateRight}>
                                  <Icon type={'rotateRigth'} />
                                </button>
                                <button onClick={rotateLeft}>
                                  <Icon type={'rotateLeft'} />
                                </button>
                                <button onClick={() => zoomIn()}>
                                  <Icon type={'zoomIn'} />
                                </button>
                                <button onClick={() => zoomOut()}>
                                  <Icon type={'zoomOut'} />
                                </button>
                                <button onClick={() => {
                                  resetTransform();
                                  setRotation(0);
                                }}>Reset</button>
                              </div>
                              <TransformComponent>
                                {
                                  loadingImagePreview && (
                                    <div className='inspection-detail__rounds__documents__documents-list__image-preview__spinner'>
                                      <Spinner />
                                    </div>
                                  )
                                }
                                <img
                                  src={selectedEvidenceImageSRC}
                                  alt="Image Viewer"
                                  style={{
                                    transform: `rotate(${rotation}deg)`,
                                    maxWidth: '100%'
                                  }}
                                  onLoad={() => {
                                    // eslint-disable-next-line no-undef
                                    const element = document.getElementById('image-viewer');

                                    if (element) {
                                      element.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'nearest'
                                      });
                                    }

                                    setLoadingImagePreview(false);
                                    resetTransform();
                                    setRotation(0);
                                  }}
                                />
                              </TransformComponent>
                            </div>
                          )}
                        </TransformWrapper>
                      </>
                    )
                  }
                  {
                    ableConfirmDesignButton() && (
                      <Button
                        onClick={setUploadedEvidence}
                        type='primary'
                        label={t('services.confirmEvidence') || ''}
                        fullWidth={true}
                        id='accept-evidence-button'
                        size='big'
                      />
                    )
                  }
                </div>
              </div>
            </>
          )
        }
      </div>
    </div >
  );
};

export default Rounds;
