/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-47',
    documentDescription: 'Lista de inspección de Dictamen para la NOM-024-SCFI-2013, Información comercial para empaques, instructivos y garantías de los productos electrónicos, eléctricos y electrodomésticos.',
    documentIssueDate: '01/10/2021',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />La información contenida en los empaques o envases de los productos eléctricos, electrónicos y electrodomésticos objeto de esta Norma Oficial Mexicana, debe ser veraz, describirse y presentarse de forma tal que no induzca a error al consumidor con respecto a la naturaleza y características del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1 Los productos objeto de esta Norma Oficial Mexicana deben tener impresos o en etiqueta adherida en el empaque o envase, de manera clara y legible, como mínimo, los siguientes datos en idioma español:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,
            <table className="table-content__inspector-table" id='table-on-site-1'>

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el responsable de la etiqueta con el responsable del pedimento. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>¿Coincide el responsable del pedimento con el de la etiqueta?</td>
                        <td>
                          <p id='question-48-alert-text' className="table-content__inspector-table__table__error-text"></p>
                          <select defaultValue='' id='question-48-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                            <option value="">Selecciona...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
            <br />- Tensión de alimentación, en volts.
            <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
            <br />- Frecuencia, en hertz.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            Aquellos productos que se comercialicen como sistemas (conjunto de varias unidades y que por su configuración de conexión no puedan ser operados de manera independiente), deben indicar al menos las características eléctricas nominales de aquella que se desempeñe como unidad principal, según determine el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            e)     Para el caso de productos reconstruidos, usados o de segunda mano, el tamaño de la letra que indique esta circunstancia debe ser cuando menos dos veces mayor que el del resto de la información descrita en este inciso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Los repuestos, accesorios y consumibles destinados al consumidor final y que se encuentren en el punto de venta, deben incluir cuando menos la información contenida en los literales a), b) y c) del inciso 5.1 de esta Norma Oficial Mexicana.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                      </tr>
                      <tr>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                      </tr>
                      <tr>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones conforme al estándar A/53 del Advanced Television Systems Committee, deberán incluir la leyenda "No sintoniza transmisiones de televisión digital conforme al estándar A/53 del Advanced Television Systems Committee" o "NO recibe señales de televisión digital", en etiqueta adherida en el empaque o envase, con un tamaño de letra dos veces mayor que la información solicitada en los incisos a) a d) del apartado 5.1.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                      </tr>
                      <tr>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                      </tr>
                      <tr>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                      </tr>
                      <tr>
                        <td>d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
                          <br />- Tensión de alimentación, en volts.
                          <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
                          <br />- Frecuencia, en hertz.</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2.1 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones de televisión
            digital deben incluir la leyenda "NO recibe señales de televisión digital", de manera clara y visible al consumidor, con un tamaño de letra dos veces mayor que la letra de la marca del aparato, en donde se exhiban para su comercialización. Esta leyenda debe estar visible al consumidor antes de que tome su decisión de compra y de que pague el precio del aparato receptor de televisión, incluyendo de manera enunciativa mas no limitativa, una etiqueta adherida a la pantalla del aparato o un aviso en la cenefa.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-46',
    documentDescription: 'Lista de inspección de Constancia para la NOM-024-SCFI-2013, Información comercial para empaques, instructivos y garantías de los productos electrónicos, eléctricos y electrodomésticos.',
    documentIssueDate: '01/10/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />
            La información contenida en los empaques o envases de los
            productos eléctricos, electrónicos y electrodomésticos objeto
            de esta Norma Oficial Mexicana, debe ser veraz, describirse y
            presentarse de forma tal que no induzca a error al consumidor
            con respecto a la naturaleza y características del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1 Los productos objeto de esta Norma Oficial Mexicana deben tener impresos o en etiqueta adherida en el empaque o envase, de manera clara y legible, como mínimo, los siguientes datos en idioma español:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
            <br />- Tensión de alimentación, en volts.
            <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
            <br />- Frecuencia, en hertz.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            Aquellos productos que se comercialicen como sistemas (conjunto de varias unidades y que por su configuración de conexión no puedan ser operados de manera independiente), deben indicar al menos las características eléctricas nominales de aquella que se desempeñe como unidad principal, según determine el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            e)     Para el caso de productos reconstruidos, usados o de segunda mano, el tamaño de la letra que indique esta circunstancia debe ser cuando menos dos veces mayor que el del resto de la información descrita en este inciso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Los repuestos, accesorios y consumibles destinados al consumidor final y que se encuentren en el punto de venta, deben incluir cuando menos la información contenida en los literales a), b) y c) del inciso 5.1 de esta Norma Oficial Mexicana.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                      </tr>
                      <tr>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                      </tr>
                      <tr>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones conforme al estándar A/53 del Advanced Television Systems Committee, deberán incluir la leyenda "No sintoniza transmisiones de televisión digital conforme al estándar A/53 del Advanced Television Systems Committee" o "NO recibe señales de televisión digital", en etiqueta adherida en el empaque o envase, con un tamaño de letra dos veces mayor que la información solicitada en los incisos a) a d) del apartado 5.1.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                      </tr>
                      <tr>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                      </tr>
                      <tr>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                      </tr>
                      <tr>
                        <td>d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
                          - T<br />- Tensión de alimentación, en volts.
                          - C<br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
                          - F<br />- Frecuencia, en hertz.</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2.1 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones de televisión
            digital deben incluir la leyenda "NO recibe señales de televisión digital", de manera clara y visible al consumidor, con un tamaño de letra dos veces mayor que la letra de la marca del aparato, en donde se exhiban para su comercialización. Esta leyenda debe estar visible al consumidor antes de que tome su decisión de compra y de que pague el precio del aparato receptor de televisión, incluyendo de manera enunciativa mas no limitativa, una etiqueta adherida a la pantalla del aparato o un aviso en la cenefa.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos y advertencias
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Requisitos
            <br />Los productos electrónicos, eléctricos y electrodomésticos que se encuentren en el punto de venta al momento de su comercialización, deben ir acompañados o tener impresos sobre el empaque, sin cargo adicional, los instructivos y advertencias necesarios que contengan las indicaciones claras y precisas para su uso normal, conservación y mejor aprovechamiento; así como las advertencias para el manejo seguro y confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            En el caso de productos electrónicos, equipos de procesamiento de datos, de informática y sus accesorios, éstos podrán cumplir con lo indicado en el párrafo anterior; o bien, ir acompañados de instructivos de inicio rápido impresos con las instrucciones básicas para operar y, en su caso, armar el producto. En estos casos, los instructivos con la información detallada para el uso normal, conservación y mejor aprovechamiento de los equipos y sus accesorios, podrán proporcionarse a través de medios de almacenamiento de información electrónica, tales como CD, DVD, memorias electrónicas y direcciones de Internet.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 14,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6.1.1 Salvo en el caso establecido por el segundo párrafo del inciso 6.1, los instructivos deben indicar al momento de la comercialización del producto, la siguiente información:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            a)     Leyenda o representación gráfica que invite a leer el instructivo, excepto si esta leyenda aparece en la etiqueta del producto, como: Véase Instructivo Anexo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            b)    Nombre, denominación o razón social del fabricante nacional, o importador, domicilio y teléfono, excepto si estos datos aparecen en la garantía del producto,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            c)     Marca, modelo, imagen o forma en que el fabricante o el importador identifique o represente su producto,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            d)    Cuando aplique, precauciones para el usuario,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            e)    Indicaciones de conexión para su adecuado funcionamiento,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            f)     Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
            <br />- Tensión de alimentación, en volts.
            <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
            <br />- Frecuencia, en hertz.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            Aquellos productos que se comercialicen como sistemas (conjunto de varias unidades), deben indicar al menos las características eléctricas nominales de aquella que se desempeñe como unidad principal, según determine el fabricante.
            <br />g)    Tratándose de aparatos receptores de televisión que no sintonicen transmisiones conforme al estándar A/53 del Advanced Television Systems Committee, deberán indicar "No sintoniza transmisiones de televisión digital conforme al estándar A/53 del Advanced Television Systems Committee" o "NO recibe señales de televisión digital".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Advertencias para productos peligrosos
            <br />Los fabricantes nacionales o importadores de productos peligrosos o de productos que puedan presentar condiciones de peligro, tienen la obligación de informar las advertencias necesarias en forma clara y ostensible para el manejo seguro y confiable de los mismos.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  <div>
                    <table className="table-content__inspector-table__table text-left">
                      <tr>
                        Capítulo 4. Clasificación
                        <br />Los productos electrónicos, eléctricos y electrodomésticos se clasifican en:
                        <br />Productos peligrosos por su diseño,
                        <br />Productos peligrosos por su uso, y
                        <br /><br />
                        <b>4.1 Son productos peligrosos por su diseño:</b>
                        <br />a) Aquéllos cuyo fin sea provocar una descarga eléctrica.
                        <br />b) Aquéllos cuya función sea alcanzar una temperatura mayor de 60 °C en las partes accesibles.
                        <br />c) Aquéllos que contengan piezas punzocortantes en las partes accesibles.
                        <br />d) Aquéllos destinados a emitir radiación
                        <br /><br />
                        <b>4.2 Son productos peligrosos por su uso:</b>
                        <br />a) Aquellos susceptibles de transmitir energía tal que pueda afectar la salud o integridad de las personas o la seguridad de sus bienes,
                        <br />b) Aquéllos que emitan radiación,
                        <br />c) Aquéllos que contengan corrosivos o produzcan sustancias tóxicas,
                        <br />d) Aquéllos que contengan partes cuyo movimiento pueda ocasionar lesiones o daños materiales,
                        <br />e) Aquéllos que puedan ocasionar implosión o explosión, y
                        <br />f) Aquellos que, no estando diseñados para ello, puedan alcanzar temperaturas mayores de 60 °C en partes accesibles.
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Características
            <br />Los instructivos y las advertencias deben redactarse en idioma español y en términos comprensibles y legibles, sin perjuicio de que además se expresen en otros idiomas. Cuando las indicaciones se refieran a unidades de medida, éstas deben corresponder a las previstas en la NOM-008-SCFI-2002, pudiéndose expresar además en otros sistemas de unidades de medida.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>
                          <b>Magnitudes de base</b>
                        </td>
                        <td colSpan={2}>
                          Unidades SI de base
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Símbolo</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          tiempo
                        </td>
                        <td>
                          segundo
                        </td>
                        <td>
                          s
                        </td>
                      </tr>
                      <tr>
                        <td>
                          longitud
                        </td>
                        <td>
                          metro
                        </td>
                        <td>
                          m
                        </td>
                      </tr>
                      <tr>
                        <td>
                          masa
                        </td>
                        <td>
                          kilogramo
                        </td>
                        <td>
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td>
                          corriente eléctrica
                        </td>
                        <td>
                          ampere
                        </td>
                        <td>
                          A
                        </td>
                      </tr>
                      <tr>
                        <td>
                          temperatura termodinámica
                        </td>
                        <td>
                          kelvin
                        </td>
                        <td>
                          K
                        </td>
                      </tr>
                      <tr>
                        <td>
                          cantidad de sustancia
                        </td>
                        <td>
                          mol
                        </td>
                        <td>
                          mol
                        </td>
                      </tr>
                      <tr>
                        <td>
                          intensidad luminosa
                        </td>
                        <td>
                          candela
                        </td>
                        <td>
                          cd
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Instalación
            <br />6.4.1 Cuando los productos <b>no representen peligro para el usuario, de acuerdo a las características establecidas en el capítulo 4 de la presente Norma Oficial Mexicana</b>, pero el peligro pueda depender o dependa de su correcta instalación, se debe incluir en el instructivo la forma correcta de hacer la instalación y, de ser necesario, que ésta solamente deba ser efectuada por una persona con los conocimientos técnicos necesarios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6.4.2 Cuando se trate de productos eléctricos o electrónicos para uso industrial, comercial o de servicios exclusivamente, y cuya instalación deba ser efectuada por una persona con los conocimientos técnicos necesarios, los instructivos para dicha instalación, uso y mantenimiento, deben ser redactados en términos técnicos y, de ser necesario, acompañarse de los diagramas correspondientes.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            6.5 Excepciones
            <br />6.5.1 Para los efectos de esta Norma Oficial Mexicana, los equipos altamente especializados que no se expendan al público directamente y cuya comercialización no está destinada al uso doméstico, sino para fines especiales de acuerdo con las necesidades expresadas en un contrato donde se incluya la información comercial, garantía e instalación, tampoco requieren de instructivos, etiquetas, ni advertencias por ser instalados por personal técnico especializado del proveedor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 27,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7. Garantías
            <br />Las garantías que ofrezcan los proveedores deben estar redactadas en los términos establecidos en la Ley Federal de Protección al Consumidor, estar incluidas en el instructivo o anexas en los productos que se encuentran en el punto de venta al momento de su comercialización, y cumplir con lo siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 28,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7.1.1 Las pólizas de garantía deben estar impresas en caracteres tipográficos y en idioma español, y contener como mínimo los siguientes datos:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            a)     Nombre, denominación o razón social y domicilio del o de los fabricantes nacionales, importadores o comercializadores responsables de productos eléctricos, electrónicos o electrodomésticos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            b)    Identificación del modelo(s) y marca(s), de los productos, misma que podrá ser incorporada al momento de su venta.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            c)     Nombre y dirección de los establecimientos en la República Mexicana donde se pueda hacer efectiva la garantía.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            d)    Lugar en donde los consumidores puedan obtener las partes, componentes, consumibles y accesorios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            e)     Duración de la garantía, la cual no puede ser menor a tres meses para el caso de productos eléctricos y electrónicos y, para el caso de electrodomésticos, no puede ser menor de un año.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            f)     Conceptos que cubre la garantía y limitaciones o excepciones que existan.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            g)    Procedimiento para hacer efectiva la garantía.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            7.1.2 Las pólizas de garantía deben contemplar el espacio para precisar la fecha en la que el consumidor recibió el producto o, en su caso, cuando se trate de productos que requieran de enseñanza o adiestramiento en su manejo o de la instalación de accesorios, la fecha en que hubiere quedado operando normalmente el producto después de su instalación, en el domicilio que señale el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Para hacer efectiva la garantía no deben exigirse mayores requisitos que la presentación del producto, acompañado de la póliza correspondiente, debidamente sellada por el establecimiento que lo vendió, o la factura, o recibo o comprobante, en el que consten los datos específicos del producto objeto de la compraventa.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            7.2 Contenido
            <br />Las garantías deben amparar todas las piezas y componentes del producto e incluir la mano de obra.
            <br /><br />
            En consecuencia, los fabricantes nacionales e importadores están obligados a reemplazar cualquier pieza o componente defectuoso sin costo adicional para el consumidor. Dichas garantías deben incluir los gastos de transportación del producto que deriven de su cumplimiento, dentro de su red de servicio.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 39,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7.3 Excepciones
            <br />Los fabricantes nacionales, importadores o comercializadores responsables de los productos a los que se refiere esta Norma Oficial Mexicana, sólo pueden eximirse de hacer efectiva la garantía en los siguientes casos:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            a)     Cuando el producto se hubiese utilizado en condiciones distintas a las normales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            b)    Cuando el producto no hubiese sido operado de acuerdo con el instructivo de uso que se le acompaña.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            c)     Cuando el producto hubiese sido alterado o reparado por personas no autorizadas por el fabricante nacional, importador o comercializador responsable respectivo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            Las excepciones a que se refiere este artículo deben quedar claramente señaladas en la póliza de garantía correspondiente. En caso contrario el fabricante nacional, importador o comercializador responsable no quedará liberado de la obligación de hacer efectiva la garantía.
            <br />Asimismo, el consumidor puede solicitar que se haga efectiva la garantía ante la propia casa comercial donde adquirió su producto, siempre y cuando el fabricante, importador o comercializador no cuente con talleres de servicio.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-46',
    documentDescription: 'Lista de inspección de Revisión para la NOM-024-SCFI-2013, Información comercial para empaques, instructivos y garantías de los productos electrónicos, eléctricos y electrodomésticos.',
    documentIssueDate: '01/04/2024',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />
            La información contenida en los empaques o envases de los
            productos eléctricos, electrónicos y electrodomésticos objeto
            de esta Norma Oficial Mexicana, debe ser veraz, describirse y
            presentarse de forma tal que no induzca a error al consumidor
            con respecto a la naturaleza y características del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1 Los productos objeto de esta Norma Oficial Mexicana deben tener impresos o en etiqueta adherida en el empaque o envase, de manera clara y legible, como mínimo, los siguientes datos en idioma español:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
            <br />- Tensión de alimentación, en volts.
            <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
            <br />- Frecuencia, en hertz.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            Aquellos productos que se comercialicen como sistemas (conjunto de varias unidades y que por su configuración de conexión no puedan ser operados de manera independiente), deben indicar al menos las características eléctricas nominales de aquella que se desempeñe como unidad principal, según determine el fabricante.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            e)     Para el caso de productos reconstruidos, usados o de segunda mano, el tamaño de la letra que indique esta circunstancia debe ser cuando menos dos veces mayor que el del resto de la información descrita en este inciso.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Los repuestos, accesorios y consumibles destinados al consumidor final y que se encuentren en el punto de venta, deben incluir cuando menos la información contenida en los literales a), b) y c) del inciso 5.1 de esta Norma Oficial Mexicana.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones conforme al estándar A/53 del Advanced Television Systems Committee, deberán incluir la leyenda "No sintoniza transmisiones de televisión digital conforme al estándar A/53 del Advanced Television Systems Committee" o "NO recibe señales de televisión digital", en etiqueta adherida en el empaque o envase, con un tamaño de letra dos veces mayor que la información solicitada en los incisos a) a d) del apartado 5.1.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  En el caso de los productos importados debe de coincidir el país de origen del pedimento con el país declarado en la etiqueta. <b>Revisar pedimento</b>

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>a)     La representación gráfica o el nombre del producto, salvo que éste sea obvio,</td>
                        <td>b)    Nombre, denominación o razón social y domicilio del fabricante nacional o importador,</td>
                        <td>c)     La leyenda que identifique al país de origen del mismo (ejemplo: "Hecho en...", "Manufacturado en...", u otros análogos),</td>
                        <td>d)    Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
                          - T<br />- Tensión de alimentación, en volts.
                          - C<br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
                          - F<br />- Frecuencia, en hertz.</td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2.1 En su caso, los aparatos receptores de televisión que no sintonicen transmisiones de televisión
            digital deben incluir la leyenda "NO recibe señales de televisión digital", de manera clara y visible al consumidor, con un tamaño de letra dos veces mayor que la letra de la marca del aparato, en donde se exhiban para su comercialización. Esta leyenda debe estar visible al consumidor antes de que tome su decisión de compra y de que pague el precio del aparato receptor de televisión, incluyendo de manera enunciativa mas no limitativa, una etiqueta adherida a la pantalla del aparato o un aviso en la cenefa.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 11,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6. Instructivos y advertencias
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Requisitos
            <br />Los productos electrónicos, eléctricos y electrodomésticos que se encuentren en el punto de venta al momento de su comercialización, deben ir acompañados o tener impresos sobre el empaque, sin cargo adicional, los instructivos y advertencias necesarios que contengan las indicaciones claras y precisas para su uso normal, conservación y mejor aprovechamiento; así como las advertencias para el manejo seguro y confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            En el caso de productos electrónicos, equipos de procesamiento de datos, de informática y sus accesorios, éstos podrán cumplir con lo indicado en el párrafo anterior; o bien, ir acompañados de instructivos de inicio rápido impresos con las instrucciones básicas para operar y, en su caso, armar el producto. En estos casos, los instructivos con la información detallada para el uso normal, conservación y mejor aprovechamiento de los equipos y sus accesorios, podrán proporcionarse a través de medios de almacenamiento de información electrónica, tales como CD, DVD, memorias electrónicas y direcciones de Internet.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 14,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            6.1.1 Salvo en el caso establecido por el segundo párrafo del inciso 6.1, los instructivos deben indicar al momento de la comercialización del producto, la siguiente información:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            a)     Leyenda o representación gráfica que invite a leer el instructivo, excepto si esta leyenda aparece en la etiqueta del producto, como: Véase Instructivo Anexo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            b)    Nombre, denominación o razón social del fabricante nacional, o importador, domicilio y teléfono, excepto si estos datos aparecen en la garantía del producto,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            c)     Marca, modelo, imagen o forma en que el fabricante o el importador identifique o represente su producto,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            d)    Cuando aplique, precauciones para el usuario,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            e)    Indicaciones de conexión para su adecuado funcionamiento,
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            f)     Las características eléctricas nominales aplicables al producto, determinadas por el fabricante, como por ejemplo:
            <br />- Tensión de alimentación, en volts.
            <br />- Consumo de potencia, en watts o consumo de corriente, en amperes.
            <br />- Frecuencia, en hertz.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            Aquellos productos que se comercialicen como sistemas (conjunto de varias unidades), deben indicar al menos las características eléctricas nominales de aquella que se desempeñe como unidad principal, según determine el fabricante.
            <br />g)    Tratándose de aparatos receptores de televisión que no sintonicen transmisiones conforme al estándar A/53 del Advanced Television Systems Committee, deberán indicar "No sintoniza transmisiones de televisión digital conforme al estándar A/53 del Advanced Television Systems Committee" o "NO recibe señales de televisión digital".
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Advertencias para productos peligrosos
            <br />Los fabricantes nacionales o importadores de productos peligrosos o de productos que puedan presentar condiciones de peligro, tienen la obligación de informar las advertencias necesarias en forma clara y ostensible para el manejo seguro y confiable de los mismos.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  <div>
                    <table className="table-content__inspector-table__table text-left">
                      <tr>
                        Capítulo 4. Clasificación
                        <br />Los productos electrónicos, eléctricos y electrodomésticos se clasifican en:
                        <br />Productos peligrosos por su diseño,
                        <br />Productos peligrosos por su uso, y
                        <br /><br />
                        <b>4.1 Son productos peligrosos por su diseño:</b>
                        <br />a) Aquéllos cuyo fin sea provocar una descarga eléctrica.
                        <br />b) Aquéllos cuya función sea alcanzar una temperatura mayor de 60 °C en las partes accesibles.
                        <br />c) Aquéllos que contengan piezas punzocortantes en las partes accesibles.
                        <br />d) Aquéllos destinados a emitir radiación
                        <br /><br />
                        <b>4.2 Son productos peligrosos por su uso:</b>
                        <br />a) Aquellos susceptibles de transmitir energía tal que pueda afectar la salud o integridad de las personas o la seguridad de sus bienes,
                        <br />b) Aquéllos que emitan radiación,
                        <br />c) Aquéllos que contengan corrosivos o produzcan sustancias tóxicas,
                        <br />d) Aquéllos que contengan partes cuyo movimiento pueda ocasionar lesiones o daños materiales,
                        <br />e) Aquéllos que puedan ocasionar implosión o explosión, y
                        <br />f) Aquellos que, no estando diseñados para ello, puedan alcanzar temperaturas mayores de 60 °C en partes accesibles.
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Características
            <br />Los instructivos y las advertencias deben redactarse en idioma español y en términos comprensibles y legibles, sin perjuicio de que además se expresen en otros idiomas. Cuando las indicaciones se refieran a unidades de medida, éstas deben corresponder a las previstas en la NOM-008-SCFI-2002, pudiéndose expresar además en otros sistemas de unidades de medida.
            <table className="table-content__inspector-table">

              <tr className="table-content__inspector-table__header">
                <td>Apoyo para el inspector</td>
              </tr>
              <tr className="table-content__inspector-table__text">
                <td>
                  Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base

                  <div>
                    <table className="table-content__inspector-table__table">
                      <tr>
                        <td>
                          <b>Magnitudes de base</b>
                        </td>
                        <td colSpan={2}>
                          Unidades SI de base
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Nombre</b>
                        </td>
                        <td>
                          <b>Símbolo</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          tiempo
                        </td>
                        <td>
                          segundo
                        </td>
                        <td>
                          s
                        </td>
                      </tr>
                      <tr>
                        <td>
                          longitud
                        </td>
                        <td>
                          metro
                        </td>
                        <td>
                          m
                        </td>
                      </tr>
                      <tr>
                        <td>
                          masa
                        </td>
                        <td>
                          kilogramo
                        </td>
                        <td>
                          kg
                        </td>
                      </tr>
                      <tr>
                        <td>
                          corriente eléctrica
                        </td>
                        <td>
                          ampere
                        </td>
                        <td>
                          A
                        </td>
                      </tr>
                      <tr>
                        <td>
                          temperatura termodinámica
                        </td>
                        <td>
                          kelvin
                        </td>
                        <td>
                          K
                        </td>
                      </tr>
                      <tr>
                        <td>
                          cantidad de sustancia
                        </td>
                        <td>
                          mol
                        </td>
                        <td>
                          mol
                        </td>
                      </tr>
                      <tr>
                        <td>
                          intensidad luminosa
                        </td>
                        <td>
                          candela
                        </td>
                        <td>
                          cd
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>

            </table>
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            6.4 Instalación
            <br />6.4.1 Cuando los productos <b>no representen peligro para el usuario, de acuerdo a las características establecidas en el capítulo 4 de la presente Norma Oficial Mexicana</b>, pero el peligro pueda depender o dependa de su correcta instalación, se debe incluir en el instructivo la forma correcta de hacer la instalación y, de ser necesario, que ésta solamente deba ser efectuada por una persona con los conocimientos técnicos necesarios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            6.4.2 Cuando se trate de productos eléctricos o electrónicos para uso industrial, comercial o de servicios exclusivamente, y cuya instalación deba ser efectuada por una persona con los conocimientos técnicos necesarios, los instructivos para dicha instalación, uso y mantenimiento, deben ser redactados en términos técnicos y, de ser necesario, acompañarse de los diagramas correspondientes.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            6.5 Excepciones
            <br />6.5.1 Para los efectos de esta Norma Oficial Mexicana, los equipos altamente especializados que no se expendan al público directamente y cuya comercialización no está destinada al uso doméstico, sino para fines especiales de acuerdo con las necesidades expresadas en un contrato donde se incluya la información comercial, garantía e instalación, tampoco requieren de instructivos, etiquetas, ni advertencias por ser instalados por personal técnico especializado del proveedor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 27,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7. Garantías
            <br />Las garantías que ofrezcan los proveedores deben estar redactadas en los términos establecidos en la Ley Federal de Protección al Consumidor, estar incluidas en el instructivo o anexas en los productos que se encuentran en el punto de venta al momento de su comercialización, y cumplir con lo siguiente:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 28,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7.1.1 Las pólizas de garantía deben estar impresas en caracteres tipográficos y en idioma español, y contener como mínimo los siguientes datos:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            a)     Nombre, denominación o razón social y domicilio del o de los fabricantes nacionales, importadores o comercializadores responsables de productos eléctricos, electrónicos o electrodomésticos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            b)    Identificación del modelo(s) y marca(s), de los productos, misma que podrá ser incorporada al momento de su venta.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            c)     Nombre y dirección de los establecimientos en la República Mexicana donde se pueda hacer efectiva la garantía.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            d)    Lugar en donde los consumidores puedan obtener las partes, componentes, consumibles y accesorios.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            e)     Duración de la garantía, la cual no puede ser menor a tres meses para el caso de productos eléctricos y electrónicos y, para el caso de electrodomésticos, no puede ser menor de un año.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            f)     Conceptos que cubre la garantía y limitaciones o excepciones que existan.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            g)    Procedimiento para hacer efectiva la garantía.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            7.1.2 Las pólizas de garantía deben contemplar el espacio para precisar la fecha en la que el consumidor recibió el producto o, en su caso, cuando se trate de productos que requieran de enseñanza o adiestramiento en su manejo o de la instalación de accesorios, la fecha en que hubiere quedado operando normalmente el producto después de su instalación, en el domicilio que señale el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Para hacer efectiva la garantía no deben exigirse mayores requisitos que la presentación del producto, acompañado de la póliza correspondiente, debidamente sellada por el establecimiento que lo vendió, o la factura, o recibo o comprobante, en el que consten los datos específicos del producto objeto de la compraventa.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            7.2 Contenido
            <br />Las garantías deben amparar todas las piezas y componentes del producto e incluir la mano de obra.
            <br /><br />
            En consecuencia, los fabricantes nacionales e importadores están obligados a reemplazar cualquier pieza o componente defectuoso sin costo adicional para el consumidor. Dichas garantías deben incluir los gastos de transportación del producto que deriven de su cumplimiento, dentro de su red de servicio.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 39,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7.3 Excepciones
            <br />Los fabricantes nacionales, importadores o comercializadores responsables de los productos a los que se refiere esta Norma Oficial Mexicana, sólo pueden eximirse de hacer efectiva la garantía en los siguientes casos:
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            a)     Cuando el producto se hubiese utilizado en condiciones distintas a las normales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            b)    Cuando el producto no hubiese sido operado de acuerdo con el instructivo de uso que se le acompaña.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            c)     Cuando el producto hubiese sido alterado o reparado por personas no autorizadas por el fabricante nacional, importador o comercializador responsable respectivo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            Las excepciones a que se refiere este artículo deben quedar claramente señaladas en la póliza de garantía correspondiente. En caso contrario el fabricante nacional, importador o comercializador responsable no quedará liberado de la obligación de hacer efectiva la garantía.
            <br />Asimismo, el consumidor puede solicitar que se haga efectiva la garantía ante la propia casa comercial donde adquirió su producto, siempre y cuando el fabricante, importador o comercializador no cuente con talleres de servicio.
          </p>
        )
      }
    ]
  }
};
