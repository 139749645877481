/* eslint-disable max-len */

import storage from '../../../../../../storage';

const updateLocalStorage = (key: string, value: string) => {
  storage.set(key, value);
  // eslint-disable-next-line no-undef
  window.dispatchEvent(new CustomEvent('custom-storage-change', { detail: { key, newValue: value } }));
};

const remoteList = {
  documentCode: 'F7-34',
  documentIssueDate: '01/07/2024',
  hasPackingTable: true,
  hasSamplingTable: true,
  hasMeasurementEquipment: true,
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      text: (
        <p className="table-content__row__cell--text">
          5. Información comercial
          <br />
          5.1 Requisitos generales
          <br />
          5.1.1 La información acerca de los productos debe ser veraz y
          describirse y presentarse deforma tal que no induzca a error
          al consumidor con respecto a la naturaleza y características
          de los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.2 Información comercial
          <br />
          5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
          deben contener en sus etiquetas, cuando menos, la siguiente información
          comercial obligatoria:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,
      text: (
        <p className="table-content__row__cell--text">
          <b>a) Nombre o denominación genérica del producto, cuando no sea identificable a simple vista por el consumidor.</b>
          <br />
          Un producto es identificable a simple vista si éste está contenido en un empaque que permite ver su contenido; o bien, si el empaque presenta el gráfico del producto, siempre y cuando en este gráfico no aparezcan otros productos no incluidos en el empaque.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,
      text: (
        <p className="table-content__row__cell--text">
          <b>b) Indicación de cantidad</b> conforme a la NOM-030-SCFI-2006 en el entendido de que, si el contenido o número de piezas de un producto puede identificarse a simple vista, no será necesario indicar la declaración de cantidad.
          <br /><br />
          En ese sentido, resultará irrelevante que se indique o no en dichos productos la declaración de cantidad y también la forma en que se haga (en idioma distinto al español, en un sitio distinto a la superficie principal de exhibición, en un tamaño menor al requerido, etc.), siempre y cuando dicha declaración corresponda al producto que la ostente.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>Por superficie principal de exhibición</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-1">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-2">...</td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>
                        El siguiente producto <b>podría</b> omitir la cantidad ya que se ven a simple vista.
                        <br />En ambos casos el producto cumple.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/indicaci%C3%B3n_de_cantidad.png" alt="Indicación de cantidad" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      text: (
        <p className="table-content__row__cell--text">
          En caso de envase múltiple o colectivo, cuyo contenido no sea inidentificable a simple vista, éste debe ostentar la declaración de cantidad (solamente la que corresponde al envase múltiple o colectivo, no la que corresponde a cada uno de los envases de los productos en lo individual), de conformidad con la Norma Oficial Mexicana NOM-030-SCFI-1993 (ver referencias). La descripción de los componentes puede aparecer en la superficie de información y debe incluir el nombre o denominación genérica de los productos, así como su contenido o contenido neto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      text: (
        <p className="table-content__row__cell--text">
          <b>c) Nombre, denominación o razón social y domicilio fiscal</b>, incluyendo código postal, ciudad o estado del fabricante o responsable de la fabricación para productos nacionales o bien del importador.
          <br /><br />
          Para el caso de productos importados, esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de la comercialización del producto. Dicha información debe ser proporcionada a la Secretaría por el importador a solicitud de ésta. Asimismo, la Secretaría debe proporcionar esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Razón Social</td>
                      <td>Dirección Fiscal</td>
                    </tr>
                    <tr>
                      <td id='businessName-item'></td>
                      <td id='fiscalAddress-item'></td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable en la etiqueta con lo de arriba?</td>
                      <td className="cell-result">
                        <p id='question-38-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-38-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      text: (
        <p className="table-content__row__cell--text">
          d) La leyenda que identifique al país de origen del producto, por ejemplo Producto de... , Hecho en... , Manufacturado en... , Producido en... , u otros análogos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      text: (
        <p className="table-content__row__cell--text">
          e) Las advertencias de riesgos por medio de leyendas, gráficas o símbolos precautorios en el caso de productos peligrosos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de leyendas precautorias:
                <br />• Objeto punzo cortante, mantenga fuera del alcance de los niños
                <br />• Este producto está fabricado con materiales plásticos y puede ser susceptible a la combustión si se expone a altas temperaturas, llamas o fuentes de calor extremas
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      text: (
        <p className="table-content__row__cell--text">
          f) Cuando el uso, manejo o conservación del producto requiera de instrucciones, debe presentarse esa información. En caso de que dicha información se encuentre en un instructivo o manual de operación anexo, se debe indicar en la respectiva etiqueta: <b>VEASE INSTRUCTIVO ANEXO O MANUAL DE OPERACION</b>, u otras leyendas análogas, las cuales podrán presentarse indistintamente en mayúsculas, minúsculas o en una combinación de ambas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      text: (
        <p className="table-content__row__cell--text">
          g) <b>Cuando corresponda, la fecha de caducidad o de consumo preferente.</b>
          <br />Nota: Cuando la información comercial obligatoria de la mercancía se encuentre en su envase o empaque de presentación final al público, no será necesario que dicha información también aparezca en la superficie propia de la mercancía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Idioma y términos<br />
          La información que se ostente en las etiquetas de los productos debe:
          <br /><br />
          <b>a) Expresarse en idioma español, sin perjuicio de que se exprese también en otros idiomas.</b>
          <br /><br />
          Cuando la información comercial se exprese en otros idiomas debe aparecer también en español, expresarse en términos comprensibles y legibles, de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura a simple vista.
          <br />En todos los casos debe indicarse cuando menos la información establecida en el inciso 5.2.1 de la presente Norma Oficial Mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      text: (
        <p className="table-content__row__cell--text">
          b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan expresar en otros sistemas de unidades.
          <br /><br />
          La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida puede aparecer después de este último.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base</b>
                <br />

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td><b>Magnitudes de base</b></td>
                      <td colSpan={2}><b>Unidades SI de base</b></td>
                    </tr>
                    <tr>
                      <td><b>Nombre</b></td>
                      <td><b>Nombre</b></td>
                      <td><b>Símbolo</b></td>
                    </tr>
                    <tr>
                      <td>tiempo</td>
                      <td>segundo</td>
                      <td>t</td>
                    </tr>
                    <tr>
                      <td>longitud</td>
                      <td>metro</td>
                      <td>m</td>
                    </tr>
                    <tr>
                      <td>masa</td>
                      <td>kilogramo</td>
                      <td>kg</td>
                    </tr>
                    <tr>
                      <td>corriente eléctrica</td>
                      <td>ampere</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>temperatura termodinámica</td>
                      <td>kelvin</td>
                      <td>K</td>
                    </tr>
                    <tr>
                      <td>cantidad de sustancia</td>
                      <td>mol</td>
                      <td>mol</td>
                    </tr>
                    <tr>
                      <td>intensidad luminosa </td>
                      <td>candela</td>
                      <td>cd</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      text: (
        <p className="table-content__row__cell--text">
          c) Presentarse en etiqueta fijada de manera tal que permanezca disponible hasta el momento de su venta o adquisición en condiciones normales, la cual debe aplicarse en cada unidad o envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      text: (
        <p className="table-content__row__cell--text">
          c.1) Cuando la forma de presentación del producto al consumidor final sea un envase múltiple o colectivo que no permita ver el contenido, toda la información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo, incorporando la leyenda “No etiquetado para su venta individual”.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de etiquetado
                <br />
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-etiquetado.png" alt="ejemplo-etiquetado" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      text: (
        <p className="table-content__row__cell--text">
          c.2) Si la forma de presentación del producto al consumidor final es un envase múltiple o colectivo que permite ver su contenido, la información comercial obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno de los productos preenvasados en lo individual, o bien, una parte de la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante en todos y cada uno de los envases de los productos en lo individual, siempre que la información comercial obligatoria que aparezca en cada uno de los envases de los productos en lo individual, se vea a simple vista desde el exterior del envase múltiple o colectivo, sin necesidad de que este último se abra.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      text: (
        <p className="table-content__row__cell--text">
          c.3) Si los envases múltiples o colectivos se abren y se extraen los productos preenvasados contenidos en ellos con el objeto de destinarlos individualmente a un consumidor final, dichos productos deben contener en lo individual toda la información comercial obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      text: (
        <p className="table-content__row__cell--text">
          d) Estar colocada en la superficie principal de exhibición, tratándose al menos de la siguiente información:
          <br />i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a),
          <br />ii) Declaración de cantidad.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3 Instructivos o manuales de operación y garantías
            <br />5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Manual de Operación?</td>
              <td className="cell-result">
                <p id='question-operation-manual-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-operation-manual-alert-text', e.target.value)} defaultValue='' id='question-operation-manual-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.1 Idioma</b>
          <br />Los instructivos o <b>manuales de operación</b> y garantías deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      text: (
        <p className="table-content__row__cell--text">
          5.3.2 Contenido
          <br />Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o conservación requiera de instrucciones, deben ir acompañados, sin cargo adicional para el consumidor, de los instructivos o manuales de operación y, en su caso, garantías, <b>los cuales deben contener indicaciones claras y precisas para el uso normal, manejo, conservación, ensamble</b> y aprovechamiento de los productos, así como las advertencias para el manejo seguro y confiable de los mismos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social del productor nacional, o importador, domicilio fiscal y teléfono de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      text: (
        <p className="table-content__row__cell--text">
          c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      text: (
        <p className="table-content__row__cell--text">
          d) Cuando proceda, las indicaciones para su instalación, conexión, ensamble o mantenimiento para su adecuado funcionamiento.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.3 Garantías
            <br />Cuando se ofrezcan garantías, éstas deben expedirse en los términos y forma establecidos en la Ley Federal de Protección al Consumidor e indicar y cumplir con lo siguiente:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Garantía?</td>
              <td className="cell-result">
                <p id='question-warranty-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-warranty-alert-text', e.target.value)} defaultValue='' id='question-warranty-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      text: (
        <p className="table-content__row__cell--text">
          5.3.1 Idioma
          <br />Los instructivos o manuales de operación y <b>garantías</b> deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social y domicilio fiscal del productor nacional o importador del producto y teléfonos de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos y/o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      text: (
        <p className="table-content__row__cell--text">
          c) Nombre y domicilio del establecimiento en la República Mexicana donde puede hacerse efectiva la garantía en los términos de la misma, así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
          <br />
          Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva en cualquiera de las sucursales a nivel nacional del proveedor que la ofrezca, sin necesidad de especificar los domicilios de las mismas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      text: (
        <p className="table-content__row__cell--text">
          d) Duración de la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      text: (
        <p className="table-content__row__cell--text">
          e) Conceptos que cubre la garantía y limitaciones o excepciones.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      text: (
        <p className="table-content__row__cell--text">
          f) Procedimiento para hacer efectiva la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      text: (
        <p className="table-content__row__cell--text">
          g) Precisar la fecha en que el consumidor recibió el producto o indicar los documentos de referencia donde ésta se señale.
          <br />
          Es responsabilidad del comerciante asegurarse que esta información esté presente al momento de la venta del producto al consumidor, de no hacerlo así, el comerciante debe cumplir con los términos de la garantía directamente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 34,
      text: (
        <p className="table-content__row__cell--text">
          h) Para hacer efectiva la garantía no pueden exigirse otros requisitos más que la presentación del producto, la garantía vigente y comprobante de venta.
          <br />
          <br />
          Nota: La vigencia de la póliza de garantía da inicio a partir de la fecha de adquisición del producto, la cual debe quedar establecida en la póliza de garantía o en el comprobante de venta correspondiente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 35,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    // LISTA 003
    {
      pageNumber: 2,
      questionNumber: 36,
      text: (
        <p className="table-content__row__cell--text">
          <b style={{
            textAlign: 'center',
            width: '100%',
            color: 'red',
            fontSize: '2rem'
          }}>NOM-003-SSA1-2006</b>
          <br />
          <br />
          5. Información comercial
          <br />
          5.1 Requisitos generales
          <br />
          5.1.1 La información acerca de los productos debe ser veraz y
          describirse y presentarse deforma tal que no induzca a error
          al consumidor con respecto a la naturaleza y características
          de los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 37,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.2 Información comercial
          <br />
          5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
          deben contener en sus etiquetas, cuando menos, la siguiente información
          comercial obligatoria:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 38,
      text: (
        <p className="table-content__row__cell--text">
          <b>a) Nombre o denominación genérica del producto, cuando no sea identificable a simple vista por el consumidor.</b>
          <br />
          Un producto es identificable a simple vista si éste está contenido en un empaque que permite ver su contenido; o bien, si el empaque presenta el gráfico del producto, siempre y cuando en este gráfico no aparezcan otros productos no incluidos en el empaque.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 39,
      text: (
        <p className="table-content__row__cell--text">
          <b>b) Indicación de cantidad</b> conforme a la NOM-030-SCFI-2006 en el entendido de que, si el contenido o número de piezas de un producto puede identificarse a simple vista, no será necesario indicar la declaración de cantidad.
          <br /><br />
          En ese sentido, resultará irrelevante que se indique o no en dichos productos la declaración de cantidad y también la forma en que se haga (en idioma distinto al español, en un sitio distinto a la superficie principal de exhibición, en un tamaño menor al requerido, etc.), siempre y cuando dicha declaración corresponda al producto que la ostente.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>Por superficie principal de exhibición</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-1-003">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-2-003">...</td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>
                        El siguiente producto <b>podría</b> omitir la cantidad ya que se ven a simple vista.
                        <br />En ambos casos el producto cumple.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/indicaci%C3%B3n_de_cantidad.png" alt="Indicación de cantidad" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 40,
      text: (
        <p className="table-content__row__cell--text">
          En caso de envase múltiple o colectivo, cuyo contenido no sea inidentificable a simple vista, éste debe ostentar la declaración de cantidad (solamente la que corresponde al envase múltiple o colectivo, no la que corresponde a cada uno de los envases de los productos en lo individual), de conformidad con la Norma Oficial Mexicana NOM-030-SCFI-1993 (ver referencias). La descripción de los componentes puede aparecer en la superficie de información y debe incluir el nombre o denominación genérica de los productos, así como su contenido o contenido neto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 41,
      text: (
        <p className="table-content__row__cell--text">
          <b>c) Nombre, denominación o razón social y domicilio fiscal</b>, incluyendo código postal, ciudad o estado del fabricante o responsable de la fabricación para productos nacionales o bien del importador.
          <br /><br />
          Para el caso de productos importados, esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de la comercialización del producto. Dicha información debe ser proporcionada a la Secretaría por el importador a solicitud de ésta. Asimismo, la Secretaría debe proporcionar esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Razón Social</td>
                      <td>Dirección Fiscal</td>
                    </tr>
                    <tr>
                      <td id='businessName-item'></td>
                      <td id='fiscalAddress-item'></td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable en la etiqueta con lo de arriba?</td>
                      <td className="cell-result">
                        <p id='question-38-alert-text-003' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-38-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 42,
      text: (
        <p className="table-content__row__cell--text">
          d) La leyenda que identifique al país de origen del producto, por ejemplo Producto de... , Hecho en... , Manufacturado en... , Producido en... , u otros análogos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 43,
      text: (
        <p className="table-content__row__cell--text">
          e) Las advertencias de riesgos por medio de leyendas, gráficas o símbolos precautorios en el caso de productos peligrosos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de leyendas precautorias:
                <br />• Objeto punzo cortante, mantenga fuera del alcance de los niños
                <br />• Este producto está fabricado con materiales plásticos y puede ser susceptible a la combustión si se expone a altas temperaturas, llamas o fuentes de calor extremas
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 44,
      text: (
        <p className="table-content__row__cell--text">
          f) Cuando el uso, manejo o conservación del producto requiera de instrucciones, debe presentarse esa información. En caso de que dicha información se encuentre en un instructivo o manual de operación anexo, se debe indicar en la respectiva etiqueta: <b>VEASE INSTRUCTIVO ANEXO O MANUAL DE OPERACION</b>, u otras leyendas análogas, las cuales podrán presentarse indistintamente en mayúsculas, minúsculas o en una combinación de ambas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 45,
      text: (
        <p className="table-content__row__cell--text">
          g) <b>Cuando corresponda, la fecha de caducidad o de consumo preferente.</b>
          <br />Nota: Cuando la información comercial obligatoria de la mercancía se encuentre en su envase o empaque de presentación final al público, no será necesario que dicha información también aparezca en la superficie propia de la mercancía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 46,
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Idioma y términos<br />
          La información que se ostente en las etiquetas de los productos debe:
          <br /><br />
          <b>a) Expresarse en idioma español, sin perjuicio de que se exprese también en otros idiomas.</b>
          <br /><br />
          Cuando la información comercial se exprese en otros idiomas debe aparecer también en español, expresarse en términos comprensibles y legibles, de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura a simple vista.
          <br />En todos los casos debe indicarse cuando menos la información establecida en el inciso 5.2.1 de la presente Norma Oficial Mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 47,
      text: (
        <p className="table-content__row__cell--text">
          b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan expresar en otros sistemas de unidades.
          <br /><br />
          La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida puede aparecer después de este último.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base</b>
                <br />

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td><b>Magnitudes de base</b></td>
                      <td colSpan={2}><b>Unidades SI de base</b></td>
                    </tr>
                    <tr>
                      <td><b>Nombre</b></td>
                      <td><b>Nombre</b></td>
                      <td><b>Símbolo</b></td>
                    </tr>
                    <tr>
                      <td>tiempo</td>
                      <td>segundo</td>
                      <td>t</td>
                    </tr>
                    <tr>
                      <td>longitud</td>
                      <td>metro</td>
                      <td>m</td>
                    </tr>
                    <tr>
                      <td>masa</td>
                      <td>kilogramo</td>
                      <td>kg</td>
                    </tr>
                    <tr>
                      <td>corriente eléctrica</td>
                      <td>ampere</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>temperatura termodinámica</td>
                      <td>kelvin</td>
                      <td>K</td>
                    </tr>
                    <tr>
                      <td>cantidad de sustancia</td>
                      <td>mol</td>
                      <td>mol</td>
                    </tr>
                    <tr>
                      <td>intensidad luminosa </td>
                      <td>candela</td>
                      <td>cd</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 48,
      text: (
        <p className="table-content__row__cell--text">
          c) Presentarse en etiqueta fijada de manera tal que permanezca disponible hasta el momento de su venta o adquisición en condiciones normales, la cual debe aplicarse en cada unidad o envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 49,
      text: (
        <p className="table-content__row__cell--text">
          c.1) Cuando la forma de presentación del producto al consumidor final sea un envase múltiple o colectivo que no permita ver el contenido, toda la información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo, incorporando la leyenda “No etiquetado para su venta individual”.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de etiquetado
                <br />
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-etiquetado.png" alt="ejemplo-etiquetado" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 50,
      text: (
        <p className="table-content__row__cell--text">
          c.2) Si la forma de presentación del producto al consumidor final es un envase múltiple o colectivo que permite ver su contenido, la información comercial obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno de los productos preenvasados en lo individual, o bien, una parte de la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante en todos y cada uno de los envases de los productos en lo individual, siempre que la información comercial obligatoria que aparezca en cada uno de los envases de los productos en lo individual, se vea a simple vista desde el exterior del envase múltiple o colectivo, sin necesidad de que este último se abra.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 51,
      text: (
        <p className="table-content__row__cell--text">
          c.3) Si los envases múltiples o colectivos se abren y se extraen los productos preenvasados contenidos en ellos con el objeto de destinarlos individualmente a un consumidor final, dichos productos deben contener en lo individual toda la información comercial obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 52,
      text: (
        <p className="table-content__row__cell--text">
          d) Estar colocada en la superficie principal de exhibición, tratándose al menos de la siguiente información:
          <br />i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a),
          <br />ii) Declaración de cantidad.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 53,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3 Instructivos o manuales de operación y garantías
            <br />5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Manual de Operación?</td>
              <td className="cell-result">
                <p id='question-operation-manual-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-operation-manual-alert-text-003', e.target.value)} defaultValue='' id='question-operation-manual-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 54,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.1 Idioma</b>
          <br />Los instructivos o <b>manuales de operación</b> y garantías deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 55,
      text: (
        <p className="table-content__row__cell--text">
          5.3.2 Contenido
          <br />Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o conservación requiera de instrucciones, deben ir acompañados, sin cargo adicional para el consumidor, de los instructivos o manuales de operación y, en su caso, garantías, <b>los cuales deben contener indicaciones claras y precisas para el uso normal, manejo, conservación, ensamble</b> y aprovechamiento de los productos, así como las advertencias para el manejo seguro y confiable de los mismos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 56,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social del productor nacional, o importador, domicilio fiscal y teléfono de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 57,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 58,
      text: (
        <p className="table-content__row__cell--text">
          c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 59,
      text: (
        <p className="table-content__row__cell--text">
          d) Cuando proceda, las indicaciones para su instalación, conexión, ensamble o mantenimiento para su adecuado funcionamiento.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 60,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 61,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.3 Garantías
            <br />Cuando se ofrezcan garantías, éstas deben expedirse en los términos y forma establecidos en la Ley Federal de Protección al Consumidor e indicar y cumplir con lo siguiente:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Garantía?</td>
              <td className="cell-result">
                <p id='question-warranty-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-warranty-alert-text-003', e.target.value)} defaultValue='' id='question-warranty-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 62,
      text: (
        <p className="table-content__row__cell--text">
          5.3.1 Idioma
          <br />Los instructivos o manuales de operación y <b>garantías</b> deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 63,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social y domicilio fiscal del productor nacional o importador del producto y teléfonos de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 64,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos y/o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 65,
      text: (
        <p className="table-content__row__cell--text">
          c) Nombre y domicilio del establecimiento en la República Mexicana donde puede hacerse efectiva la garantía en los términos de la misma, así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
          <br />
          Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva en cualquiera de las sucursales a nivel nacional del proveedor que la ofrezca, sin necesidad de especificar los domicilios de las mismas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 66,
      text: (
        <p className="table-content__row__cell--text">
          d) Duración de la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 67,
      text: (
        <p className="table-content__row__cell--text">
          e) Conceptos que cubre la garantía y limitaciones o excepciones.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 68,
      text: (
        <p className="table-content__row__cell--text">
          f) Procedimiento para hacer efectiva la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 69,
      text: (
        <p className="table-content__row__cell--text">
          g) Precisar la fecha en que el consumidor recibió el producto o indicar los documentos de referencia donde ésta se señale.
          <br />
          Es responsabilidad del comerciante asegurarse que esta información esté presente al momento de la venta del producto al consumidor, de no hacerlo así, el comerciante debe cumplir con los términos de la garantía directamente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 70,
      text: (
        <p className="table-content__row__cell--text">
          h) Para hacer efectiva la garantía no pueden exigirse otros requisitos más que la presentación del producto, la garantía vigente y comprobante de venta.
          <br />
          <br />
          Nota: La vigencia de la póliza de garantía da inicio a partir de la fecha de adquisición del producto, la cual debe quedar establecida en la póliza de garantía o en el comprobante de venta correspondiente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 71,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 72,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 73,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          A) Los productos con plomo formulados en base disolvente
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 74,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 75,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5.2.1 Los productos con plomo formulados en base disolvente, deben ostentar las leyendas siguientes:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 76,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 77,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          b) ¡PRECAUCION! PRODUCTO INFLAMABLE MANTENGALO APARTADO DE ALTAS TEMPERATURAS, CHISPAS Y FLAMAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 78,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          c) PROHIBIDO UTILIZAR ESTE PRODUCTO EN LA ELABORACION, ACABADO O IMPRESION DE JUGUETES, OBJETOS SUSCEPTIBLES DE LLEVARSE A LA BOCA, DE ARTICULOS PARA USO DOMESTICO Y/O ESCOLARES USADOS POR NIÑOS, ASICOMO EN LA PINTURA DE INTERIORES DE CASAS HABITACION, RESTAURANTES, AREAS DE JUEGOS INFANTILES, ESCUELAS Y GUARDERIAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 79,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 80,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 81,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 82,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 83,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          h) CONTIENE COMPUESTOS DE PLOMO, DISOLVENTES Y SUSTANCIAS TOXICAS, CUYO CONTACTO O INHALACION PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 84,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 85,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          En los envases con capacidad de 250 ml o menos, podrán excluirse las leyendas a que se refieren los incisos
          <br /><br />
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
          <br /><br />
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
          <br /><br />
          del punto5.1.1.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 86,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 87,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 88,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 89,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 90,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 91,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 92,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 93,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 os productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 94,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 95,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          <br />a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 96,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <br />
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          <br />b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 97,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 98,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          B) Los productos formulados en base acuosa o aceite vegetal con plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 99,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 100,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Los productos formulados en base acuosa o aceite vegetal con plomo deben ostentar las leyendas a que se refieren los incisos a), c), d), e) y f) del punto 5.1.1 de esta Norma Oficial Mexicana, así como la leyenda siguiente:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 101,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 102,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          c) PROHIBIDO UTILIZAR ESTE PRODUCTO EN LA ELABORACION, ACABADO O IMPRESION DE JUGUETES, OBJETOS SUSCEPTIBLES DE LLEVARSE A LA BOCA, DE ARTICULOS PARA USO DOMESTICO Y/O ESCOLARES USADOS POR NIÑOS, ASICOMO EN LA PINTURA DE INTERIORES DE CASAS HABITACION, RESTAURANTES, AREAS DE JUEGOS INFANTILES, ESCUELAS Y GUARDERIAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 103,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 104,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 105,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 106,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          CONTIENE COMPUESTOS DE PLOMO, CUYO CONTACTO ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 107,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 108,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 109,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 110,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 111,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 112,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 113,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 114,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 115,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 116,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 117,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 118,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 119,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          C) Los productos formulados en base disolvente sin plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 120,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 121,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5.2.3 Los productos formulados en base disolvente sin plomo deben ostentar las leyendas a que se refieren los incisos a), b), d), e), f), g) e i) del punto 5.1.1, así como la leyenda siguiente:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 122,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 123,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          b) ¡PRECAUCION! PRODUCTO INFLAMABLE MANTENGALO APARTADO DE ALTAS TEMPERATURAS, CHISPAS Y FLAMAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 124,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 125,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 126,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 127,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 128,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 129,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          CONTIENE DISOLVENTES Y SUSTANCIAS TOXICAS, CUYO CONTACTO O INHALACION PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 130,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 131,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 132,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 133,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 134,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 135,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 136,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 137,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 138,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 139,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 140,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 141,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 142,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          D) Los productos formulados en base acuosa o aceite vegetal sin plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 143,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 144,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          5.2.4 Los productos formulados en base acuosa o aceite vegetal sin plomo, deben ostentar las leyendas a que se refiere el inciso a) y f) del punto 5.1.1, así como la leyenda:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 145,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 146,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 147,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          EL USO DE ESTE PRODUCTO POR NIÑOS DEBE SER SUPERVISADO POR ADULTOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 148,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 149,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 150,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 151,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 152,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 153,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 154,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 155,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 156,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 157,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 158,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 159,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 160,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          E) os productos fabricados con fines de uso escolar
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 161,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 162,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          5.2.4.1 Los productos fabricados con fines de uso escolar deben ostentar las leyendas a que se refiere el inciso a) del punto 5.1.1, así como la leyenda:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 163,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 164,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 165,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 166,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 167,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 168,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 169,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 170,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 171,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 172,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 173,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 174,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 175,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <br /><b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    }
  ]
};

const list = {
  documentCode: 'F7-35',
  documentIssueDate: '01/04/2024',
  hasPackingTable: true,
  hasSamplingTable: true,
  hasMeasurementEquipment: true,
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      text: (
        <p className="table-content__row__cell--text">
          5. Información comercial
          <br />
          5.1 Requisitos generales
          <br />
          5.1.1 La información acerca de los productos debe ser veraz y
          describirse y presentarse deforma tal que no induzca a error
          al consumidor con respecto a la naturaleza y características
          de los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.2 Información comercial
          <br />
          5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
          deben contener en sus etiquetas, cuando menos, la siguiente información
          comercial obligatoria:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,
      text: (
        <p className="table-content__row__cell--text">
          <b>a) Nombre o denominación genérica del producto, cuando no sea identificable a simple vista por el consumidor.</b>
          <br />
          Un producto es identificable a simple vista si éste está contenido en un empaque que permite ver su contenido; o bien, si el empaque presenta el gráfico del producto, siempre y cuando en este gráfico no aparezcan otros productos no incluidos en el empaque.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,
      text: (
        <p className="table-content__row__cell--text">
          <b>b) Indicación de cantidad</b> conforme a la NOM-030-SCFI-2006 en el entendido de que, si el contenido o número de piezas de un producto puede identificarse a simple vista, no será necesario indicar la declaración de cantidad.
          <br /><br />
          En ese sentido, resultará irrelevante que se indique o no en dichos productos la declaración de cantidad y también la forma en que se haga (en idioma distinto al español, en un sitio distinto a la superficie principal de exhibición, en un tamaño menor al requerido, etc.), siempre y cuando dicha declaración corresponda al producto que la ostente.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>Por superficie principal de exhibición</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-1">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-2">...</td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>
                        El siguiente producto <b>podría</b> omitir la cantidad ya que se ven a simple vista.
                        <br />En ambos casos el producto cumple.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/indicaci%C3%B3n_de_cantidad.png" alt="Indicación de cantidad" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      text: (
        <p className="table-content__row__cell--text">
          En caso de <b>envase múltiple o colectivo, cuyo contenido no sea inidentificable a simple vista</b>, éste debe ostentar la declaración de cantidad (solamente la que corresponde al envase múltiple o colectivo, no la que corresponde a cada uno de los envases de los productos en lo individual), de conformidad con la Norma Oficial Mexicana NOM-030-SCFI-1993 (ver referencias). La descripción de los componentes puede aparecer en la superficie de información y debe incluir el nombre o denominación genérica de los productos, así como su contenido o contenido neto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      text: (
        <p className="table-content__row__cell--text">
          <b>c) Nombre, denominación o razón social y domicilio fiscal</b>, incluyendo código postal, ciudad o estado del fabricante o responsable de la fabricación para productos nacionales o bien del importador.
          <br />
          <br />
          Para el caso de productos importados, esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de la comercialización del producto. Dicha información debe ser proporcionada a la Secretaría por el importador a solicitud de ésta. Asimismo, la Secretaría debe proporcionar esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Razón Social</td>
                      <td>Dirección Fiscal</td>
                    </tr>
                    <tr>
                      <td id='businessName-item'></td>
                      <td id='fiscalAddress-item'></td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable en la etiqueta con lo de arriba?</td>
                      <td className="cell-result">
                        <p id='question-38-alert-text' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-38-select' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      text: (
        <p className="table-content__row__cell--text">
          d) La leyenda que identifique al <b>país de origen</b> del producto, por ejemplo Producto de... , Hecho en... , Manufacturado en... , Producido en... , u otros análogos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      text: (
        <p className="table-content__row__cell--text">
          e) Las advertencias de riesgos por medio de leyendas, gráficas o símbolos precautorios en el caso de productos peligrosos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de leyendas precautorias:
                <br />• Objeto punzo cortante, mantenga fuera del alcance de los niños
                <br />• Este producto está fabricado con materiales plásticos y puede ser susceptible a la combustión si se expone a altas temperaturas, llamas o fuentes de calor extremas
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      text: (
        <p className="table-content__row__cell--text">
          f) Cuando el uso, manejo o conservación del producto requiera de instrucciones, debe presentarse esa información. En caso de que dicha información se encuentre en un instructivo o manual de operación anexo, se debe indicar en la respectiva etiqueta: <b>VEASE INSTRUCTIVO ANEXO O MANUAL DE OPERACION</b>, u otras leyendas análogas, las cuales podrán presentarse indistintamente en mayúsculas, minúsculas o en una combinación de ambas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      text: (
        <p className="table-content__row__cell--text">
          g) <b>Cuando corresponda, la fecha de caducidad o de consumo preferente.</b>
          <br />Nota: Cuando la información comercial obligatoria de la mercancía se encuentre en su envase o empaque de presentación final al público, no será necesario que dicha información también aparezca en la superficie propia de la mercancía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Idioma y términos<br />
          La información que se ostente en las etiquetas de los productos debe:
          <br /><br />
          <b>a) Expresarse en idioma español, sin perjuicio de que se exprese también en otros idiomas.</b>
          <br /><br />
          Cuando la información comercial se exprese en otros idiomas debe aparecer también en español, expresarse en términos comprensibles y legibles, de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura a simple vista.
          <br />En todos los casos debe indicarse cuando menos la información establecida en el inciso 5.2.1 de la presente Norma Oficial Mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      text: (
        <p className="table-content__row__cell--text">
          b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan expresar en otros sistemas de unidades.
          <br /><br />
          La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida puede aparecer después de este último.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base</b>
                <br />

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td><b>Magnitudes de base</b></td>
                      <td colSpan={2}><b>Unidades SI de base</b></td>
                    </tr>
                    <tr>
                      <td><b>Nombre</b></td>
                      <td><b>Nombre</b></td>
                      <td><b>Símbolo</b></td>
                    </tr>
                    <tr>
                      <td>tiempo</td>
                      <td>segundo</td>
                      <td>t</td>
                    </tr>
                    <tr>
                      <td>longitud</td>
                      <td>metro</td>
                      <td>m</td>
                    </tr>
                    <tr>
                      <td>masa</td>
                      <td>kilogramo</td>
                      <td>kg</td>
                    </tr>
                    <tr>
                      <td>corriente eléctrica</td>
                      <td>ampere</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>temperatura termodinámica</td>
                      <td>kelvin</td>
                      <td>K</td>
                    </tr>
                    <tr>
                      <td>cantidad de sustancia</td>
                      <td>mol</td>
                      <td>mol</td>
                    </tr>
                    <tr>
                      <td>intensidad luminosa </td>
                      <td>candela</td>
                      <td>cd</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      text: (
        <p className="table-content__row__cell--text">
          c) Presentarse en etiqueta fijada de manera tal que permanezca disponible hasta el momento de su venta o adquisición en condiciones normales, la cual debe aplicarse en cada unidad o envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      text: (
        <p className="table-content__row__cell--text">
          c.1) Cuando la forma de presentación del producto al consumidor final sea un envase múltiple o colectivo que no permita ver el contenido, toda la información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo, incorporando la leyenda “No etiquetado para su venta individual”.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de etiquetado
                <br />
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-etiquetado.png" alt="ejemplo-etiquetado" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      text: (
        <p className="table-content__row__cell--text">
          c.2) Si la forma de presentación del producto al consumidor final es un envase múltiple o colectivo que permite ver su contenido, la información comercial obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno de los productos preenvasados en lo individual, o bien, una parte de la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante en todos y cada uno de los envases de los productos en lo individual, siempre que la información comercial obligatoria que aparezca en cada uno de los envases de los productos en lo individual, se vea a simple vista desde el exterior del envase múltiple o colectivo, sin necesidad de que este último se abra.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      text: (
        <p className="table-content__row__cell--text">
          c.3) Si los envases múltiples o colectivos se abren y se extraen los productos preenvasados contenidos en ellos con el objeto de destinarlos individualmente a un consumidor final, dichos productos deben contener en lo individual toda la información comercial obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      text: (
        <p className="table-content__row__cell--text">
          d) Estar colocada en la superficie principal de exhibición, tratándose al menos de la siguiente información:
          <br />i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a),
          <br />ii) Declaración de cantidad.
        </p>
      )
    },
    // LISTA 003
    {
      pageNumber: 2,
      questionNumber: 18,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      text: (
        <p className="table-content__row__cell--text">
          <b style={{
            textAlign: 'center',
            width: '100%',
            color: 'red',
            fontSize: '2rem'
          }}>NOM-003-SSA1-2006</b>
          <br />
          <br />
          5. Información comercial
          <br />
          5.1 Requisitos generales
          <br />
          5.1.1 La información acerca de los productos debe ser veraz y
          describirse y presentarse deforma tal que no induzca a error
          al consumidor con respecto a la naturaleza y características
          de los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.2 Información comercial
          <br />
          5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
          deben contener en sus etiquetas, cuando menos, la siguiente información
          comercial obligatoria:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      text: (
        <p className="table-content__row__cell--text">
          <b>a) Nombre o denominación genérica del producto, cuando no sea identificable a simple vista por el consumidor.</b>
          <br />
          Un producto es identificable a simple vista si éste está contenido en un empaque que permite ver su contenido; o bien, si el empaque presenta el gráfico del producto, siempre y cuando en este gráfico no aparezcan otros productos no incluidos en el empaque.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      text: (
        <p className="table-content__row__cell--text">
          <b>b) Indicación de cantidad</b> conforme a la NOM-030-SCFI-2006 en el entendido de que, si el contenido o número de piezas de un producto puede identificarse a simple vista, no será necesario indicar la declaración de cantidad.
          <br /><br />
          En ese sentido, resultará irrelevante que se indique o no en dichos productos la declaración de cantidad y también la forma en que se haga (en idioma distinto al español, en un sitio distinto a la superficie principal de exhibición, en un tamaño menor al requerido, etc.), siempre y cuando dicha declaración corresponda al producto que la ostente.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>Por superficie principal de exhibición</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-1-003">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-2-003">...</td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>
                        El siguiente producto <b>podría</b> omitir la cantidad ya que se ven a simple vista.
                        <br />En ambos casos el producto cumple.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/indicaci%C3%B3n_de_cantidad.png" alt="Indicación de cantidad" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      text: (
        <p className="table-content__row__cell--text">
          En caso de envase múltiple o colectivo, cuyo contenido no sea inidentificable a simple vista, éste debe ostentar la declaración de cantidad (solamente la que corresponde al envase múltiple o colectivo, no la que corresponde a cada uno de los envases de los productos en lo individual), de conformidad con la Norma Oficial Mexicana NOM-030-SCFI-1993 (ver referencias). La descripción de los componentes puede aparecer en la superficie de información y debe incluir el nombre o denominación genérica de los productos, así como su contenido o contenido neto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      text: (
        <p className="table-content__row__cell--text">
          <b>c) Nombre, denominación o razón social y domicilio fiscal</b>, incluyendo código postal, ciudad o estado del fabricante o responsable de la fabricación para productos nacionales o bien del importador.
          <br /><br />
          Para el caso de productos importados, esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de la comercialización del producto. Dicha información debe ser proporcionada a la Secretaría por el importador a solicitud de ésta. Asimismo, la Secretaría debe proporcionar esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Razón Social</td>
                      <td>Dirección Fiscal</td>
                    </tr>
                    <tr>
                      <td id='businessName-item'></td>
                      <td id='fiscalAddress-item'></td>
                    </tr>
                  </table>
                  <br />
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>¿Coincide el responsable en la etiqueta con lo de arriba?</td>
                      <td className="cell-result">
                        <p id='question-38-alert-text-003' className="table-content__inspector-table__table__error-text"></p>
                        <select defaultValue='' id='question-38-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                          <option value="">Selecciona...</option>
                          <option value="SI">SI</option>
                          <option value="NO">NO</option>
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      text: (
        <p className="table-content__row__cell--text">
          d) La leyenda que identifique al país de origen del producto, por ejemplo Producto de... , Hecho en... , Manufacturado en... , Producido en... , u otros análogos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      text: (
        <p className="table-content__row__cell--text">
          e) Las advertencias de riesgos por medio de leyendas, gráficas o símbolos precautorios en el caso de productos peligrosos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de leyendas precautorias:
                <br />• Objeto punzo cortante, mantenga fuera del alcance de los niños
                <br />• Este producto está fabricado con materiales plásticos y puede ser susceptible a la combustión si se expone a altas temperaturas, llamas o fuentes de calor extremas
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      text: (
        <p className="table-content__row__cell--text">
          f) Cuando el uso, manejo o conservación del producto requiera de instrucciones, debe presentarse esa información. En caso de que dicha información se encuentre en un instructivo o manual de operación anexo, se debe indicar en la respectiva etiqueta: <b>VEASE INSTRUCTIVO ANEXO O MANUAL DE OPERACION</b>, u otras leyendas análogas, las cuales podrán presentarse indistintamente en mayúsculas, minúsculas o en una combinación de ambas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      text: (
        <p className="table-content__row__cell--text">
          g) <b>Cuando corresponda, la fecha de caducidad o de consumo preferente.</b>
          <br />Nota: Cuando la información comercial obligatoria de la mercancía se encuentre en su envase o empaque de presentación final al público, no será necesario que dicha información también aparezca en la superficie propia de la mercancía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Idioma y términos<br />
          La información que se ostente en las etiquetas de los productos debe:
          <br /><br />
          <b>a) Expresarse en idioma español, sin perjuicio de que se exprese también en otros idiomas.</b>
          <br /><br />
          Cuando la información comercial se exprese en otros idiomas debe aparecer también en español, expresarse en términos comprensibles y legibles, de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura a simple vista.
          <br />En todos los casos debe indicarse cuando menos la información establecida en el inciso 5.2.1 de la presente Norma Oficial Mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      text: (
        <p className="table-content__row__cell--text">
          b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan expresar en otros sistemas de unidades.
          <br /><br />
          La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida puede aparecer después de este último.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base</b>
                <br />

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td><b>Magnitudes de base</b></td>
                      <td colSpan={2}><b>Unidades SI de base</b></td>
                    </tr>
                    <tr>
                      <td><b>Nombre</b></td>
                      <td><b>Nombre</b></td>
                      <td><b>Símbolo</b></td>
                    </tr>
                    <tr>
                      <td>tiempo</td>
                      <td>segundo</td>
                      <td>t</td>
                    </tr>
                    <tr>
                      <td>longitud</td>
                      <td>metro</td>
                      <td>m</td>
                    </tr>
                    <tr>
                      <td>masa</td>
                      <td>kilogramo</td>
                      <td>kg</td>
                    </tr>
                    <tr>
                      <td>corriente eléctrica</td>
                      <td>ampere</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>temperatura termodinámica</td>
                      <td>kelvin</td>
                      <td>K</td>
                    </tr>
                    <tr>
                      <td>cantidad de sustancia</td>
                      <td>mol</td>
                      <td>mol</td>
                    </tr>
                    <tr>
                      <td>intensidad luminosa </td>
                      <td>candela</td>
                      <td>cd</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      text: (
        <p className="table-content__row__cell--text">
          c) Presentarse en etiqueta fijada de manera tal que permanezca disponible hasta el momento de su venta o adquisición en condiciones normales, la cual debe aplicarse en cada unidad o envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      text: (
        <p className="table-content__row__cell--text">
          c.1) Cuando la forma de presentación del producto al consumidor final sea un envase múltiple o colectivo que no permita ver el contenido, toda la información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo, incorporando la leyenda “No etiquetado para su venta individual”.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de etiquetado
                <br />
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-etiquetado.png" alt="ejemplo-etiquetado" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      text: (
        <p className="table-content__row__cell--text">
          c.2) Si la forma de presentación del producto al consumidor final es un envase múltiple o colectivo que permite ver su contenido, la información comercial obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno de los productos preenvasados en lo individual, o bien, una parte de la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante en todos y cada uno de los envases de los productos en lo individual, siempre que la información comercial obligatoria que aparezca en cada uno de los envases de los productos en lo individual, se vea a simple vista desde el exterior del envase múltiple o colectivo, sin necesidad de que este último se abra.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      text: (
        <p className="table-content__row__cell--text">
          c.3) Si los envases múltiples o colectivos se abren y se extraen los productos preenvasados contenidos en ellos con el objeto de destinarlos individualmente a un consumidor final, dichos productos deben contener en lo individual toda la información comercial obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      text: (
        <p className="table-content__row__cell--text">
          d) Estar colocada en la superficie principal de exhibición, tratándose al menos de la siguiente información:
          <br />i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a),
          <br />ii) Declaración de cantidad.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 34,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3 Instructivos o manuales de operación y garantías
            <br />5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Manual de Operación?</td>
              <td className="cell-result">
                <p id='question-operation-manual-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-operation-manual-alert-text-003', e.target.value)} defaultValue='' id='question-operation-manual-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 35,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.1 Idioma</b>
          <br />Los instructivos o <b>manuales de operación</b> y garantías deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 36,
      text: (
        <p className="table-content__row__cell--text">
          5.3.2 Contenido
          <br />Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o conservación requiera de instrucciones, deben ir acompañados, sin cargo adicional para el consumidor, de los instructivos o manuales de operación y, en su caso, garantías, <b>los cuales deben contener indicaciones claras y precisas para el uso normal, manejo, conservación, ensamble</b> y aprovechamiento de los productos, así como las advertencias para el manejo seguro y confiable de los mismos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 37,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social del productor nacional, o importador, domicilio fiscal y teléfono de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 38,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 39,
      text: (
        <p className="table-content__row__cell--text">
          c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 40,
      text: (
        <p className="table-content__row__cell--text">
          d) Cuando proceda, las indicaciones para su instalación, conexión, ensamble o mantenimiento para su adecuado funcionamiento.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 41,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 42,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3.3 Garantías
            <br />Cuando se ofrezcan garantías, éstas deben expedirse en los términos y forma establecidos en la Ley Federal de Protección al Consumidor e indicar y cumplir con lo siguiente:</b>
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td colSpan={2}>Apoyo para el inspector</td>
            </tr>
            <tr>
              <td>¿El producto cuenta con Garantía?</td>
              <td className="cell-result">
                <p id='question-warranty-alert-text' className="table-content__inspector-table__table__error-text"></p>
                <select onChange={(e: any) => updateLocalStorage('question-warranty-alert-text-003', e.target.value)} defaultValue='' id='question-warranty-select-003' name="select" className="table-content__inspector-table__input table-content__inspector-table__input--select" placeholder="Resultado">
                  <option value="">Selecciona...</option>
                  <option value="SI">SI</option>
                  <option value="NO">NO</option>
                </select>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 43,
      text: (
        <p className="table-content__row__cell--text">
          5.3.1 Idioma
          <br />Los instructivos o manuales de operación y <b>garantías</b> deben <b>expresarse en idioma español</b> y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 44,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social y domicilio fiscal del productor nacional o importador del producto y teléfonos de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 45,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos y/o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 46,
      text: (
        <p className="table-content__row__cell--text">
          c) Nombre y domicilio del establecimiento en la República Mexicana donde puede hacerse efectiva la garantía en los términos de la misma, así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
          <br />
          Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva en cualquiera de las sucursales a nivel nacional del proveedor que la ofrezca, sin necesidad de especificar los domicilios de las mismas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 47,
      text: (
        <p className="table-content__row__cell--text">
          d) Duración de la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 48,
      text: (
        <p className="table-content__row__cell--text">
          e) Conceptos que cubre la garantía y limitaciones o excepciones.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 49,
      text: (
        <p className="table-content__row__cell--text">
          f) Procedimiento para hacer efectiva la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 50,
      text: (
        <p className="table-content__row__cell--text">
          g) Precisar la fecha en que el consumidor recibió el producto o indicar los documentos de referencia donde ésta se señale.
          <br />
          Es responsabilidad del comerciante asegurarse que esta información esté presente al momento de la venta del producto al consumidor, de no hacerlo así, el comerciante debe cumplir con los términos de la garantía directamente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 51,
      text: (
        <p className="table-content__row__cell--text">
          h) Para hacer efectiva la garantía no pueden exigirse otros requisitos más que la presentación del producto, la garantía vigente y comprobante de venta.
          <br />
          <br />
          Nota: La vigencia de la póliza de garantía da inicio a partir de la fecha de adquisición del producto, la cual debe quedar establecida en la póliza de garantía o en el comprobante de venta correspondiente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 52,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 53,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 54,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          A) Los productos con plomo formulados en base disolvente
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 55,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 56,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          5.2.1 Los productos con plomo formulados en base disolvente, deben ostentar las leyendas siguientes:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 57,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 58,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          b) ¡PRECAUCION! PRODUCTO INFLAMABLE MANTENGALO APARTADO DE ALTAS TEMPERATURAS, CHISPAS Y FLAMAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 59,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          c) PROHIBIDO UTILIZAR ESTE PRODUCTO EN LA ELABORACION, ACABADO O IMPRESION DE JUGUETES, OBJETOS SUSCEPTIBLES DE LLEVARSE A LA BOCA, DE ARTICULOS PARA USO DOMESTICO Y/O ESCOLARES USADOS POR NIÑOS, ASICOMO EN LA PINTURA DE INTERIORES DE CASAS HABITACION, RESTAURANTES, AREAS DE JUEGOS INFANTILES, ESCUELAS Y GUARDERIAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 60,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 61,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 62,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 63,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 64,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          h) CONTIENE COMPUESTOS DE PLOMO, DISOLVENTES Y SUSTANCIAS TOXICAS, CUYO CONTACTO O INHALACION PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 65,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 66,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          En los envases con capacidad de 250 ml o menos, podrán excluirse las leyendas a que se refieren los incisos
          <br /><br />
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
          <br /><br />
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
          <br /><br />
          del punto5.1.1.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 67,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 68,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 69,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 70,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 71,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 72,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 73,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 74,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 os productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 75,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 76,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          <br />a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 77,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <br />
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          <br />b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 78,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 79,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          B) Los productos formulados en base acuosa o aceite vegetal con plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 80,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 81,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Los productos formulados en base acuosa o aceite vegetal con plomo deben ostentar las leyendas a que se refieren los incisos a), c), d), e) y f) del punto 5.1.1 de esta Norma Oficial Mexicana, así como la leyenda siguiente:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 82,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 83,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          c) PROHIBIDO UTILIZAR ESTE PRODUCTO EN LA ELABORACION, ACABADO O IMPRESION DE JUGUETES, OBJETOS SUSCEPTIBLES DE LLEVARSE A LA BOCA, DE ARTICULOS PARA USO DOMESTICO Y/O ESCOLARES USADOS POR NIÑOS, ASICOMO EN LA PINTURA DE INTERIORES DE CASAS HABITACION, RESTAURANTES, AREAS DE JUEGOS INFANTILES, ESCUELAS Y GUARDERIAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 84,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 85,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 86,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 87,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          CONTIENE COMPUESTOS DE PLOMO, CUYO CONTACTO ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 88,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 89,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 90,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 91,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 92,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 93,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 94,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 95,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 96,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 97,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 98,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 99,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 100,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          C) Los productos formulados en base disolvente sin plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 101,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 102,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          5.2.3 Los productos formulados en base disolvente sin plomo deben ostentar las leyendas a que se refieren los incisos a), b), d), e), f), g) e i) del punto 5.1.1, así como la leyenda siguiente:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 103,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 104,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          b) ¡PRECAUCION! PRODUCTO INFLAMABLE MANTENGALO APARTADO DE ALTAS TEMPERATURAS, CHISPAS Y FLAMAS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 105,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          d) PROHIBIDA SU VENTA A MENORES DE EDAD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 106,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          e) NO SE DEJE AL ALCANCE DE LOS NIÑOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 107,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 108,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          g) APLIQUE EN ESPACIOS CON VENTILACION ADECUADA;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 109,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          i) CIERRE BIEN EL ENVASE DESPUES DE CADA USO.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 110,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          CONTIENE DISOLVENTES Y SUSTANCIAS TOXICAS, CUYO CONTACTO O INHALACION PROLONGADA O REITERADA ORIGINA GRAVES DAÑOS A LA SALUD;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 111,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 112,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 113,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 114,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 115,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 116,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 117,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 118,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 119,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 120,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 121,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 122,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 123,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          D) Los productos formulados en base acuosa o aceite vegetal sin plomo
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 124,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 125,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          5.2.4 Los productos formulados en base acuosa o aceite vegetal sin plomo, deben ostentar las leyendas a que se refiere el inciso a) y f) del punto 5.1.1, así como la leyenda:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 126,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 127,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          f) EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO. EVITE EL CONTACTODIRECTO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 128,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          EL USO DE ESTE PRODUCTO POR NIÑOS DEBE SER SUPERVISADO POR ADULTOS;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 129,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 130,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 131,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 132,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 133,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 134,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 135,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 136,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 137,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 138,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 139,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 140,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text text-center">
          NOM-003-SSA1-2006
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 141,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text cell-blue">
          E) os productos fabricados con fines de uso escolar
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 142,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          5. Especificaciones
          <br />5.1 Para la clasificación de los productos en base al contenido de plomo, consultar el Apéndice informativo A.
          <br />5.2 Requisitos de información sanitaria
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 143,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          5.2.4.1 Los productos fabricados con fines de uso escolar deben ostentar las leyendas a que se refiere el inciso a) del punto 5.1.1, así como la leyenda:
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Nota: deben de ir en mayusculas. Tal cual se encuentran a continuación</b>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 144,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          a) NO FUME Y NO INGIERA ALIMENTOS DURANTE SU APLICACION;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 145,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          EN CASO DE INGESTION, NO SE PROVOQUE EL VOMITO. SOLICITE ATENCION MEDICA DE INMEDIATO;
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 146,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6. Características del etiquetado de envases
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 147,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.1 Las leyendas sanitarias deben ir precedidas de la palabra ADVERTENCIA, colocada en el centro del recuadro.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ADVERTENCIA.png" alt="ADVERTENCIA" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 148,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.2 Las leyendas a que se refieren los puntos 5.2.1, 5.2.2, 5.2.3, 5.2.4 y 5.2.4.1 de esta Norma Oficial Mexicana deben colocarse de manera tal que queden escritas y enmarcadas por una línea de color contrastante con el fondo de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 149,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.3 La superficie enmarcada que contenga las leyendas de carácter sanitario, debe ser en el caso de los productos con plomo, así como los productos base disolvente, igual o superior al 10% de la superficie total de la etiqueta; y en caso de los productos con base acuosa sin plomo, igual o superior al 5% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 150,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.4 En el caso de que los productos que contengan minio, litargirio o albayalde, de acuerdo a lo establecido en la Modificación a la NOM-004-SSA1-1993, la superficie marcada que contenga leyendas de carácter sanitario, debe ser igual o superior al 20% de la superficie total de la etiqueta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 151,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.5 Cuando los productos sean de importación, la información debe aparecer en idioma español y cumplir con los requisitos señalados en la presente Norma Oficial Mexicana, así como el gentilicio del país de origen precedido de la palabra "producto" y el nombre y domicilio comercial del importador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 152,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          6.6 Los productos, al momento de la venta, deben estar envasados en recipientes cerrados, en buen estado, seguros, provistos de etiquetas con indicaciones legibles. No se podrán usar envases destinados para contener productos de consumo humano.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 153,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7. Requisitos de información de carácter general
          <br />7.1 Los productos objeto de esta Norma Oficial Mexicana deben cumplir con lo establecido en la Norma Oficial Mexicana NOM-050-SCFI-2004, Información comercial-Etiquetado general de productos, cuya observancia, corresponde a la Secretaría de Economía
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 154,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.2 El producto debe contener el número o clave de fabricación, asignado por el productor para su identificación, así como el número de lote y fecha de fabricación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 155,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><b>a) Para todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION.png" alt="NOCIVO EN CASO DE INGESTION" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/NOCIVO+EN+CASO+DE+INGESTION-2.png" alt="NOCIVO EN CASO DE INGESTION-2" />
                </td>
              </tr>
            </table>
          </div>
          a) Todos los productos objeto de esta Norma, excepto los fabricados con fines de uso escolar:
          <br />ATENCION NOCIVO EN CASO DE INGESTION
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 156,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          7.3 La etiqueta debe incluir el pictograma del Apéndice normativo A en un área no menor de 1.5% de la superficie total de la etiqueta.
          <br /><br />
          El marco del pictograma debe ser de color rojo, el símbolo negro y el fondo blanco además de la siguiente leyenda:
          <br /><br />
          <b>APENDICE NORMATIVO A</b>
          <br />Las leyendas del pictograma de advertencia podrán colocarse en una sola línea, o bien, en dos líneas como se muestra a continuación:
          <br /><br />
          <br /><b>b) Para los productos fabricados con fines de uso escolar:</b>
          <div>
            <table className="table-content__inspector-table__table">
              <tr>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA.png" alt="ATENCION NO SE INGIERA" />
                </td>
                <td>
                  <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ATENCION%C2%A0NO+SE+INGIERA-2.png" alt="ATENCION NO SE INGIERA-2" />
                </td>
              </tr>
            </table>
          </div>
          b) Los productos fabricados con fines de uso escolar:
          <br />ATENCION NO SE INGIERA
        </p>
      )
    }
  ]
};

export default {
  DC: {
    documentDescription: 'F7-35 Lista de inspección para Dictamen para la NOM-050-SCFI-2004',
    ...list
  },
  CC: {
    documentDescription: 'F7-35 Lista de inspección para Constancia para la NOM-050-SCFI-2004',
    ...remoteList,
    hasSamplingTable: false
  },
  REV: {
    documentDescription: 'F7-35 Lista de inspección para Revision para la NOM-050-SCFI-2004',
    ...remoteList,
    hasSamplingTable: false
  }
};
