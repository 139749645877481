import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { DictumServiceStatus } from '../../../../interfaces';

interface Props {
  status: DictumServiceStatus
}

const DictumServiceDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const dictumServiceStatuses: DictumServiceStatus[] = ['validating-invoices', 'inspecting-products', 'concluded'];

  const translatedDictumServiceStatuses: string[] = [];

  dictumServiceStatuses.forEach((element: DictumServiceStatus) => {
    translatedDictumServiceStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="dictum-service-detail__progress-indicator" id="data-section">
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedDictumServiceStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default DictumServiceDetailDocuments;
