import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Icon,
  Modal,
  ModalView
} from '../../../../components';
import { Inspection } from '../../../../interfaces';
import { useResource } from '../../../../hooks';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  inspection: Inspection
  setInspection: (_value: Inspection) => void
  visible: boolean
  setVisible: (_value: boolean) => void
  dictaminate: boolean
  roundNumber: number
  haveQuestionInspectionListAsNotComplies: boolean
  setHaveQuestionInspectionListAsNotComplies: (_value: boolean) => void
}

const DictaminateInspectionModal: React.FC<Props> = (props) => {
  const {
    inspection,
    setInspection,
    visible,
    setVisible,
    dictaminate,
    roundNumber,
    haveQuestionInspectionListAsNotComplies,
    setHaveQuestionInspectionListAsNotComplies
  } = props;

  const {
    updateResource
  } = useResource<Inspection>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const handleDictaminate = (complies: boolean) => {
    if (haveQuestionInspectionListAsNotComplies
      && complies === true) {
      Modal.fireError(
        t('inspections.complyInspectionWhitNotComplyInspectionList'),
        setOpenModal,
        soundEffects
      );
    } else if (haveQuestionInspectionListAsNotComplies === false
      && complies === false
      && inspection.indicators.design === false) {
      Modal.fireError(
        t('inspections.notComplyInspectionWhitComplyInspectionList'),
        setOpenModal,
        soundEffects
      );
    } else {
      updateResource(
        `/admin/inspections/${inspection.id}/round/complies`,
        { complies },
        (data: Inspection) => {
          setVisible(false);

          setHaveQuestionInspectionListAsNotComplies(false);
          if (complies) {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.markRoundCopy1'),
              setOpenModal,
              () => setInspection(data),
              soundEffects
            );
          } else {
            Modal.fireSuccess(
              t('global.correct'),
              t('services.markRoundCopy2'),
              setOpenModal,
              () => setInspection(data),
              soundEffects
            );
          }
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  return (
    <ModalView
      visible={visible}
      onClose={() => setVisible(false)}
      onConfirm={() => handleDictaminate(dictaminate)}
      fullSzie={false}
      customComponent={
        <div className="inspection-dictaminate">
          <div className="inspection-dictaminate__container">
            {t('services.dictaminateRound')} {dictaminate ? t('global.yes') : 'NO'} {t('services.round')} #{roundNumber}?
            <div className="inspection-dictaminate__complies-box">
              <div className='inspection-dictaminate__complies-box__option'>
                <Icon type={dictaminate ? 'agree' : 'disagree'} className="inspection-dictaminate__complies-box__option__img" />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default DictaminateInspectionModal;
