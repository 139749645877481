import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import {
  BubbleElements,
  Button,
  CheckBox,
  Modal,
  Separator,
  Table
} from '../../../../components';
import { useResource } from '../../../../hooks';
import {
  DictumProduct,
  Ordering,
  TableHeader,
  TableOrderDirection
} from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';

interface CustomItem extends DictumProduct {
  checkBox: ReactElement
}

interface Props {
  selectedFilter: string
  filterValue: string
  onError: (_error: string) => void
  fetchProducts: boolean
  setFetchProducts: (_value: boolean) => void
}

export const CancellationLayout: React.FC<Props> = (props) => {
  const {
    selectedFilter,
    filterValue,
    onError,
    fetchProducts,
    setFetchProducts
  } = props;

  const { t } = useTranslation();

  const { fetchResources } = useResource<DictumProduct>();
  const { createResource } = useResource<any>();

  const {
    userRole
  } = useContext(AppContext);

  const [refetch, setRefetch] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [checkBoxValues, setCheckBoxValues] = useState<{ [invoice: string]: boolean }>({});
  const [customProducts, setCustomProducts] = useState<CustomItem[]>([]);
  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [ordering, setOrdering] = useState<Ordering>({
    orderBy: 'invoice',
    orderDirection: 'ASC'
  });

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('services.petitionNumber'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('clients.titleS'),
      value: 'client.businessName',
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: t('global.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: 'UMC',
      value: 'umc',
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: 'umcQuantity',
      format: 'none'
    },
    {
      label: t('global.cancel'),
      value: 'checkBox',
      format: 'custom-component'
    }
  ];

  const handelChangeOrder = (order: string, direction: TableOrderDirection) => {
    setOrdering({
      orderBy: order,
      orderDirection: direction
    });
  };

  const getInvoicesToCancel = (): string[] => {
    const invoicesToCancel: string[] = [];

    Object.keys(checkBoxValues).forEach((key: string) => {
      if (checkBoxValues[key]) {
        invoicesToCancel.push(key);
      }
    });

    return invoicesToCancel;
  };

  const sendLayout = () => {
    createResource(
      '/admin/se-procedures/cancellation-layout',
      getInvoicesToCancel(),
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        const now = new Date();
        const date: string = dayjs(now).format('DD-MMM-YYYY');
        const time: string = dayjs(now).format('hh:mm a');

        link.download = `Layout de cancelacion del ${date}, ${time}.csv`;
        link.click();

        setCheckBoxValues({});
        setRefetch(!refetch);
        setFetchProducts(!fetchProducts);
        Modal.close();
      },
      (error: string) => onError(error)
    );
  };

  const deleteInvoiceToBeCancelled = (invoice: string) => {
    checkBoxValues[invoice] = false;

    setCheckBoxValues({ ...checkBoxValues });
  };

  const handleCheckItem = (invoice: string) => {
    checkBoxValues[invoice] = !checkBoxValues[invoice];

    setCheckBoxValues({ ...checkBoxValues });
  };

  const handleSelectAll = () => {
    const _selectAll = !selectAll;

    customProducts.forEach((product: DictumProduct) => {
      checkBoxValues[product.invoice] = _selectAll;
    });

    setCheckBoxValues({ ...checkBoxValues });
    setSelectAll(_selectAll);
  };

  useEffect(() => {
    const keys = Object.keys(checkBoxValues);
    let result = true;

    for (let i = 0; i < keys.length; i += 1) {
      if (checkBoxValues[keys[i]] === false) {
        result = false;
      }
    }

    setSelectAll(result);
  }, [checkBoxValues]);

  useEffect(() => {
    const _customItems: CustomItem[] = [];

    products.forEach((product: DictumProduct) => {
      _customItems.push({
        ...product,
        checkBox: (
          <div className='procedures-se__checkbox-container'>
            <CheckBox
              label=''
              checked={checkBoxValues[product.invoice]}
              onChange={() => { handleCheckItem(product.invoice); }}
            />
          </div>
        )
      });
    });

    setCustomProducts(_customItems);
  }, [checkBoxValues]);

  useEffect(() => {
    const _checkBoxValues: { [invoice: string]: boolean } = {};

    products.forEach((product: DictumProduct) => {
      _checkBoxValues[product.invoice] = selectAll;
    });

    setCheckBoxValues({ ..._checkBoxValues, ...checkBoxValues });
  }, [products]);

  const getMoreInvoices = (lastItems: DictumProduct[]) => {
    const filters: { [filter: string]: string | boolean } = {
      s_status: 'validated',
      b_attachedToTheService: true,
      b_distinctInvoices: true,
      b_waitingToExpired: true
    };

    if (selectedFilter !== 'none') {
      filters[`s_${selectedFilter === 'petitionNumber' ? 'serviceNumber' : selectedFilter}`] = filterValue;
    }

    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters
      },
      (data) => {
        setProducts([...lastItems, ...data.items]);
      },
      // eslint-disable-next-line no-console
      (error: string) => console.log(error)
    );
  };

  useEffect(() => {
    const filters: { [filter: string]: string | boolean } = {
      s_status: 'validated',
      b_attachedToTheService: false,
      b_distinctInvoices: true
    };

    filters[`s_${selectedFilter === 'petitionNumber' ? 'serviceNumber' : selectedFilter}`] = filterValue;

    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters
      },
      (data) => {
        getMoreInvoices(data.items);
      },
      (error: string) => onError(error)
    );
  }, [
    refetch,
    filterValue,
    ordering,
    fetchProducts
  ]);

  return (
    <>
      {
        customProducts.length > 0 && (
          <div>
            <CheckBox
              label={t('global.cancelAll')}
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </div>
        )
      }
      <Table
        headers={tableHeaders}
        items={customProducts}
        orderBy={ordering.orderBy}
        orderDirection={ordering.orderDirection}
        onChangeOrder={handelChangeOrder}
      />
      <Separator orientation='horizontal' />
      <BubbleElements
        title={t('global.selectedInvoices') || ''}
        elements={getInvoicesToCancel()}
        onDelete={deleteInvoiceToBeCancelled}
        emptyStateTitle={t('tasks.form.emptyInvoices') || ''}
      />
      <Button
        onClick={sendLayout}
        type='primary'
        label={t('seProcedures.downloadOutOfDateInvoicesButton') || ''}
        iconPosition='right'
        icon='downloadWhite'
        alignContent='left'
        disabled={getInvoicesToCancel().length <= 0 || !['master', 'operator'].includes(userRole)}
      />
    </>
  );
};

export default CancellationLayout;
