import React from 'react';
import { useTranslation } from 'react-i18next';
import ProgressIndicator from '../../../../components/ProgressIndicator';
import statusDictionaryEn from '../../../../dictionaries/statusDictionary-en';
import statusDictionaryEs from '../../../../dictionaries/statusDictionary-es';
import { ConstancyProductStatus } from '../../../../interfaces';

interface Props {
  status: ConstancyProductStatus
}

const ConstancyProductDetailDocuments: React.FC<Props> = (props) => {
  const {
    status
  } = props;

  const { t, i18n } = useTranslation();

  const constancyProductStatuses: ConstancyProductStatus[] = [
    'in-inspection',
    'concluded'
  ];

  const translatedConstancyProductStatuses: string[] = [];

  constancyProductStatuses.forEach((element: ConstancyProductStatus) => {
    translatedConstancyProductStatuses.push(i18n.language === 'es' ? statusDictionaryEs[element] : statusDictionaryEn[element]);
  });

  return (
    <div className="constancy-product-detail__progress-indicator">
      <ProgressIndicator
        progressTitle={t('services.tracing')}
        status={translatedConstancyProductStatuses}
        currenStatus={i18n.language === 'es' ? statusDictionaryEs[status] : statusDictionaryEn[status]}
      />
    </div>
  );
};

export default ConstancyProductDetailDocuments;
