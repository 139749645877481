import React from 'react';
import { useTranslation } from 'react-i18next';
import DataDisplay from '../../../DataDisplay';
import { DataDisplayItem, Inspection } from '../../../../interfaces';

interface Props {
  inspection: Inspection
  serviceLookInTheTrash: boolean
  productLookInTheTrash: boolean
}

const InspectionGeneralInfo: React.FC<Props> = (props) => {
  const {
    inspection,
    serviceLookInTheTrash,
    productLookInTheTrash
  } = props;

  const { t } = useTranslation();

  const getServiceName = () => {
    if (['CC', 'CN'].includes(inspection.service.code)) {
      return t('services.constancy');
    }
    if (['DC', 'DN'].includes(inspection.service.code)) {
      return t('services.dictum');
    }
    return 'Revision';
  };

  const getServiceDetailPath = () => {
    if (['CC', 'CN'].includes(inspection.service.code)) {
      return `/constancy-services/detail/${inspection.service.id}/${serviceLookInTheTrash ? 'true' : 'none'}`;
    }
    if (['DC', 'DN'].includes(inspection.service.code)) {
      return `/dictum-services/detail/${inspection.service.id}/${serviceLookInTheTrash ? 'true' : 'none'}`;
    }
    return `/revision-services/detail/${inspection.service.id}/${serviceLookInTheTrash ? 'true' : 'none'}`;
  };

  const getProductDetailPath = () => {
    if (['CC', 'CN'].includes(inspection.service.code)) {
      return `/constancy-products/detail/${inspection.product.id}/${productLookInTheTrash ? 'true' : 'none'}/false`;
    }
    if (['DC', 'DN'].includes(inspection.service.code)) {
      return `/dictum-products/detail/${inspection.product.id}/${productLookInTheTrash ? 'true' : 'none'}`;
    }
    return `/revision-products/detail/${inspection.product.id}/${serviceLookInTheTrash ? 'true' : 'none'}/false`;
  };

  const itemsDisplay: DataDisplayItem[] = [
    {
      label: t('tasks.detail.status'),
      value: inspection.status,
      format: 'status'
    },
    {
      label: t('tasks.filters.number'),
      value: inspection.number,
      format: 'none'
    },
    {
      label: t('inspections.complies'),
      value: inspection.complies,
      format: 'complies'
    },
    {
      label: t('inspections.approved'),
      value: inspection.approved,
      format: 'boolean'
    },
    {
      label: t('tasks.filters.client'),
      value: inspection.client.businessName,
      format: 'none',
      navigateRoute: `/client/detail/${inspection.client.id}`,
      onlyAdmin: true
    },
    {
      label: t('services.collaborator'),
      value: inspection.collaborator.name,
      format: 'none'
    },
    {
      label: t('tasks.table.invoice'),
      value: inspection.product.invoice,
      format: 'none',
      navigateRoute: getProductDetailPath()
    },
    {
      label: t('tasks.table.norm'),
      value: inspection.norm,
      format: 'none'
    },
    {
      label: 'Fase',
      value: inspection.fase,
      format: 'none'
    },
    {
      label: t('services.service'),
      value: getServiceName(),
      format: 'none',
      navigateRoute: getServiceDetailPath()
    },
    {
      label: t('tasks.table.model'),
      value: inspection.product.code,
      format: 'none'
    },
    {
      label: t('services.roundsNumber'),
      value: inspection.rounds.length,
      format: 'none'
    },
    {
      label: t('global.listDone'),
      value: inspection.listCompleted,
      format: 'boolean'
    },
    {
      label: t('global.creationDate'),
      value: inspection.createdAt,
      format: 'date-time'
    }
  ];

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={inspection.deletedAt ? itemsDisplay.concat([
        {
          label: t('global.deletedAt'),
          value: inspection.deletedAt,
          format: 'date-time'
        }])
        : itemsDisplay}
    />
  );
};

export default InspectionGeneralInfo;
