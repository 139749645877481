import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  ModalView,
  RadioList,
  Table,
  Title
} from '../../../../components';

import {
  DictumProduct,
  Pagination,
  TableHeader
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';
import { utils } from '../../../../helpers';

interface CustomProduct extends DictumProduct {
  'radio-buttons': ReactElement,
  'invalidation-reason-input': ReactElement
}

interface Props {
  visible: boolean
  onClose: () => void
  onDone: (_wasSuccessful: boolean, _message: string) => void
}

export const InvoiceValidation: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onDone
  } = props;
  const {
    fetchResources,
    updateResources
  } = useResource<DictumProduct>();

  const { t } = useTranslation();

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('services.petitionNumber'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('clients.titleS'),
      value: 'client.shortName',
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: t('global.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: 'UMC',
      value: 'umc',
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: 'umcQuantity',
      format: 'none'
    },
    {
      label: t('global.options'),
      value: 'radio-buttons',
      format: 'custom-component'
    },
    {
      label: t('seProcedures.invalidationReason'),
      value: 'invalidation-reason-input',
      format: 'custom-component',
      helperText: t('seProcedures.invalidationReasonHelperText') || ''
    }
  ];

  const rejectReasons = {
    1: t('global.rejectReasons.1'),
    2: t('global.rejectReasons.2'),
    3: 'NOM',
    4: t('global.rejectReasons.4'),
    5: 'RFC',
    6: t('global.rejectReasons.6'),
    7: t('global.rejectReasons.7'),
    8: t('global.rejectReasons.8'),
    9: t('global.rejectReasons.9'),
    10: t('global.rejectReasons.10'),
    11: t('global.rejectReasons.11'),
    12: t('global.rejectReasons.12'),
    13: t('global.rejectReasons.13'),
    14: t('global.rejectReasons.14'),
    15: 'UMC',
    16: t('global.rejectReasons.16'),
    17: t('global.rejectReasons.17'),
    18: t('global.rejectReasons.18'),
    19: t('global.rejectReasons.19'),
    20: t('global.rejectReasons.20'),
    21: t('global.rejectReasons.21'),
    22: t('global.rejectReasons.22'),
    23: t('global.rejectReasons.23')
  };

  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [customProducts, setCustomProducts] = useState<CustomProduct[]>([]);
  const [radioButtonValues, setRadioButtonValues] = useState<{ [invoice: string]: string }>({});
  const [inputValues, setInputValues] = useState<{ [invoice: string]: string }>({});
  const [pagination, setPagination] = useState<Pagination>({
    perPage: 20,
    currentPage: 1,
    totalItems: 0
  });

  const handleChangePage = (page: number) => setPagination({ ...pagination, currentPage: page });

  const handleUpdateInvoicesStatus = () => {
    const dataToSent: any[] = [];
    let hasError = false;

    Object.keys(radioButtonValues).forEach((key: string) => {
      if (radioButtonValues[key] !== 'validation-in-progress') {
        const data: { [invoice: string]: string } = {
          invoice: key,
          status: radioButtonValues[key]
        };

        if (radioButtonValues[key] === 'not-validated') {
          if (!(rejectReasons as { [name: string]: string })[inputValues[key]] && !hasError) {
            hasError = true;
            return;
          }

          data.invalidationReason = inputValues[key];
        }

        dataToSent.push(data);
      }
    });

    if (hasError) {
      return;
    }

    updateResources(
      '/admin/dictum-products/se/status',
      dataToSent,
      () => onDone(true, t('seProcedures.invoicesChanges')),
      (error: string) => onDone(false, error)
    );
  };

  useEffect(() => {
    setCustomProducts(
      products.map((product: DictumProduct) => {
        // eslint-disable-next-line max-len
        const invalidationReason = (rejectReasons as { [name: string]: string })[inputValues[product.invoice]];
        let helperText = '';
        let hasError = false;

        if (!invalidationReason && radioButtonValues[product.invoice] === 'not-validated') {
          helperText = t('seProcedures.invalidationReasonNotFound');
          hasError = true;
        }

        return {
          ...product,
          'radio-buttons': (
            <RadioList
              value={radioButtonValues[product.invoice]}
              options={[
                { label: t('global.undefined'), value: 'validation-in-progress' },
                { label: t('global.status.validated'), value: 'validated' },
                { label: t('global.status.not-validated'), value: 'not-validated' }
              ]}
              onChange={(value) => {
                radioButtonValues[product.invoice] = value as string;
                setRadioButtonValues({ ...radioButtonValues });
              }}
            />
          ),
          'invalidation-reason-input': (
            <Input
              title={`${t('seProcedures.invalidationReason')}${invalidationReason !== undefined ? ` - ${invalidationReason}` : ''}`}
              placeholder={t('seProcedures.invalidationReason') || ''}
              helperText={helperText}
              type='number'
              value={inputValues[product.invoice]}
              hasError={hasError}
              disabled={radioButtonValues[product.invoice] !== 'not-validated'}
              onChange={(value: string | number) => {
                inputValues[product.invoice] = `${value}`;
                setInputValues({ ...inputValues });
              }}
              className='procedures-se__invalidationReason'
            />
          )
        };
      })
    );
  }, [products, radioButtonValues, inputValues]);

  useEffect(() => {
    if (visible) {
      fetchResources(
        {
          resourcePath: '/admin/dictum-products',
          filters: {
            s_status: 'validation-in-progress',
            b_distinctInvoices: true
          },
          pagination: {
            n_currentPage: pagination.currentPage,
            n_perPage: pagination.perPage,
            n_totalItems: pagination.totalItems
          },
          ordering: {
            s_orderBy: 'invoice',
            s_orderDirection: 'ASC'
          }
        },
        (data) => {
          const _radioButtonValues: { [invoice: string]: string } = {};
          const _inputValues: { [invoice: string]: string } = {};

          data.items.forEach((product: DictumProduct) => {
            _radioButtonValues[product.invoice] = 'validation-in-progress';
            _inputValues[product.invoice] = '';
          });

          setProducts(data.items);
          setRadioButtonValues({ ..._radioButtonValues, ...radioButtonValues });
          setInputValues({ ..._inputValues, ...inputValues });

          if (utils.paginationHasChange(pagination, data.pagination)) {
            setPagination(data.pagination);
          }
        },
        (error: string) => onDone(false, error)
      );
    } else {
      setProducts([]);
      setRadioButtonValues({});
      setInputValues({});
    }
  }, [visible, pagination]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={customProducts.length > 0 ? handleUpdateInvoicesStatus : null}
      customComponent={(
        <>
          <Title title={t('seProcedures.markInvoices') || ''} />
          <Table
            headers={tableHeaders}
            items={customProducts}
            currentPage={pagination.currentPage}
            perPage={pagination.perPage}
            totalItems={pagination.totalItems}
            onChangeCurrentPage={handleChangePage}
            orderBy=''
            orderDirection='ASC'
          />
        </>
      )}
    />
  );
};

export default InvoiceValidation;
