import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  Header,
  Input,
  Modal,
  ModalView,
  SubTitle,
  Switch
} from '../../components';
import {
  CorrectiveActions as CorrectiveActionsType,
  User
} from '../../interfaces';

import './styles.scss';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { emptyCorrectiveACtion, emptyUser } from '../../emptyObjects';
import { validate } from '../../helpers';

const CorrectiveActionForm = () => {
  const params = useParams();
  const { correctiveActionID } = params;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    fetchResource
  } = useResource<CorrectiveActionsType>();

  const {
    updateResource,
    createResource
  } = useResource<CorrectiveActionsType>();

  const {
    fetchResource: getActionDocument
  } = useResource<any>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const {
    soundEffects,
    setOpenModal,
    userRole,
    manager
  } = useContext(AppContext);

  // eslint-disable-next-line max-len
  const [correctiveAction, setCorrectiveAction] = useState<CorrectiveActionsType>(emptyCorrectiveACtion);
  const [estimatedClosingDate, setEstimatedClosingDate] = useState<string>('');
  const [user, setUser] = useState<User>(emptyUser);
  const [actionIsTheUser, setActionIsTheUser] = useState<boolean>(false);
  const [commentReview, setCommentReview] = useState<string>('');
  const [
    showMessagePostDisaproveActionModal,
    setShowMessagePostDisaproveActionModal] = useState<boolean>(false);
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const validData = (): boolean => {
    if (validate.text(correctiveAction.situation, 1) !== 'success') {
      setErrors({
        situation: t('global.dataError')
      });

      return false;
    }
    if (validate.text(correctiveAction.involved, 1) !== 'success') {
      setErrors({
        involved: t('global.dataError')
      });

      return false;
    }
    if (validate.text(correctiveAction.cause, 1) !== 'success') {
      setErrors({
        cause: t('global.dataError')
      });

      return false;
    }

    if (correctiveAction.status === 'open') {
      if (correctiveAction.estimatedClosingDate === null) {
        setErrors({
          estimatedClosingDate: t('global.dataError')
        });

        return false;
      }
    }

    if (correctiveAction.status === 'close') {
      if (validate.text(correctiveAction.descriptionOfActionsTaken, 1) !== 'success') {
        setErrors({
          descriptionOfActionsTaken: t('global.dataError')
        });

        return false;
      }
    }

    return true;
  };

  const uploadReviewComment = () => {
    updateResource(
      `admin/corrective-actions/approve/${correctiveAction.id}`,
      {
        approve: false,
        comment: commentReview
      },
      (data) => {
        setCorrectiveAction(data);
        setShowMessagePostDisaproveActionModal(false);
        Modal.fireSuccess(
          t('global.correct'),
          t('global.disaprovedAction'),
          setOpenModal,
          undefined
        );
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const handleApproveCorrectiveAction = (approve: boolean) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      approve ? t('global.approveCorrectiveAction') : t('global.disaproveCorrectiveAction'),
      () => {
        if (approve === true) {
          updateResource(
            `admin/corrective-actions/approve/${correctiveAction.id}`,
            {
              approve: true
            },
            (data) => {
              setCorrectiveAction(data);
              Modal.fireSuccess(
                t('global.correct'),
                t('global.approvedAction'),
                setOpenModal,
                undefined
              );
            },
            (error: string) => {
              Modal.fireError(error, setOpenModal, soundEffects);
            }
          );
        } else {
          Modal.close(setOpenModal);
          setShowMessagePostDisaproveActionModal(true);
        }
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const handleEditCorrectiveAction = () => {
    if (validData()) {
      updateResource(
        `admin/corrective-actions/${correctiveAction.id}`,
        correctiveAction,
        (data) => {
          setCorrectiveAction(data);
          Modal.fireSuccess(t('global.correct'), t('global.updatedCorrectiveAction'), setOpenModal, () => navigate('/corrective-actions'), soundEffects);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const handleCreateCorrectiveAction = () => {
    if (validData()) {
      createResource(
        'admin/corrective-actions',
        {
          ...correctiveAction,
          responsible: {
            name: user.name,
            id: user.id
          }
        },
        (data) => {
          setCorrectiveAction(data);
          Modal.fireSuccess(
            t('global.correct'),
            t('global.createdCorrectiveAction'),
            setOpenModal,
            () => navigate('/corrective-actions'),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const handleGenerateActionDocument = async () => {
    getActionDocument(
      `/admin/corrective-actions/${correctiveActionID}/document`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = 'F8-01 SOLICITUD DE ACCIÓN CORRECTIVA Y PREVENTIVA V2.pdf';
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  useEffect(() => {
    if (correctiveActionID && correctiveActionID !== 'none') {
      fetchResource(
        `/admin/corrective-actions/${correctiveActionID}`,
        (data) => {
          setCorrectiveAction(data);
          setEstimatedClosingDate(dayjs(data.estimatedClosingDate).add(1, 'days').format('YYYY-MM-DD'));

          getUser(
            '/admin/users/me/data',
            (userData: User) => {
              setUser(userData);

              if (correctiveAction.id !== '') {
                setActionIsTheUser(correctiveAction.responsible.id === user.id);
              } else {
                setActionIsTheUser(true);
              }
            },
            (error: string) => Modal.fireError(error, undefined, soundEffects)
          );
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    } else {
      setActionIsTheUser(true);
    }
  }, [correctiveActionID]);

  useEffect(() => {
    getUser(
      '/admin/users/me/data',
      (userData: User) => {
        setUser(userData);

        if (correctiveAction.id !== '') {
          setActionIsTheUser(correctiveAction.responsible.id === user.id);
        } else {
          setActionIsTheUser(true);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  }, []);

  useEffect(() => {
    if (correctiveAction.id !== '') {
      setActionIsTheUser(correctiveAction.responsible.id === user.id);
    } else {
      setActionIsTheUser(true);
    }
  }, [correctiveAction, user]);

  const setTitle = (): string => {
    if (correctiveAction.status === 'approved') {
      return `${t('global.ac-apSingular')} ${correctiveAction.number}`;
    }

    if (correctiveAction.id === '') {
      return t('global.newac-ap');
    }

    return `${t('global.editac-ap')} ${correctiveAction.number}`;
  };

  return (
    <div className='corrective-actions-form__main'>
      <ModalView
        ableFuncionalityEnterToConfirm={false}
        visible={showMessagePostDisaproveActionModal}
        onClose={() => {
          if (commentReview.length === 0) {
            setErrors({
              commentReview: t('inspections.reviewActionMessageError')
            });
          } else {
            uploadReviewComment();
            setErrors({});
            setCommentReview('');
            setShowMessagePostDisaproveActionModal(false);
          }
        }}
        fullSzie={false}
        onConfirm={uploadReviewComment}
        customComponent={
          <div className='dictaminate-inspection-modal'>
            <span>{t('inspections.reviewActionMessage')}</span>
            <Input
              type={'textarea'}
              value={commentReview}
              placeholder={t('global.comments') || ''}
              hasError={!!errors.commentReview}
              helperText={errors.commentReview}
              onChange={(value: string | number) => {
                setCommentReview(`${value}`);
              }}
              makeValidations={false}
              maxLength={1000}
              autofocus={true}
            />
          </div>
        }
      />
      <Header
        title={setTitle()}
        showBackbutton={true}
      />
      <div className="corrective-actions-form__container">
        {
          correctiveAction.status === 'approved' && (
            <div className="corrective-actions-form__container__button-container">
              <Button
                type={'primary'}
                onClick={() => handleGenerateActionDocument()}
                label={t('global.downloadDocument') || ''}
                icon='downloadWhite'
                size='big'
              />
            </div>
          )
        }
        <SubTitle subTitle={'Tipo de acción'} />
        <Switch
          leftLabel={{
            text: 'Correctiva',
            value: 'corrective'
          }}
          rigthLabel={{
            text: 'Preventiva',
            value: 'preventive'
          }}
          disabled={actionIsTheUser === false}
          defaultChecked={!(correctiveAction.type === 'preventive')}
          onChange={(value: string | number) => {
            setCorrectiveAction({
              ...correctiveAction,
              type: `${value}` as 'corrective' | 'preventive'
            });
          }}
        />
        <br />
        <Input
          type={'textarea'}
          value={correctiveAction.situation}
          title='Situación presentada'
          maxLength={3000}
          onChange={(value: string | number) => {
            setCorrectiveAction({
              ...correctiveAction,
              situation: `${value}`
            });
          }}
          disabled={actionIsTheUser === false}
          hasError={!!errors.situation}
          helperText={errors.situation}
        />
        <br />
        <Input
          type={'textarea'}
          value={correctiveAction.involved}
          title='Involucrados'
          maxLength={3000}
          onChange={(value: string | number) => {
            setCorrectiveAction({
              ...correctiveAction,
              involved: `${value}`
            });
          }}
          disabled={actionIsTheUser === false}
          hasError={!!errors.involved}
          helperText={errors.involved}
        />
        <br />
        <Input
          type={'textarea'}
          value={correctiveAction.cause}
          title='Análisis de causa/raíz'
          maxLength={3000}
          onChange={(value: string | number) => {
            setCorrectiveAction({
              ...correctiveAction,
              cause: `${value}`
            });
          }}
          disabled={actionIsTheUser === false}
          hasError={!!errors.cause}
          helperText={errors.cause}
        />
        <br />
        <SubTitle subTitle={'Acción cerrada o abierta'} />
        <Switch
          leftLabel={{
            text: 'Abierta',
            value: 'open'
          }}
          rigthLabel={{
            text: 'Cerrada',
            value: 'close'
          }}
          disabled={actionIsTheUser === false}
          defaultChecked={!(correctiveAction.status === 'close')}
          onChange={(value: string | number) => {
            setCorrectiveAction({
              ...correctiveAction,
              status: `${value}` as 'open' | 'close'
            });
          }}
        />
        <br />
        {
          correctiveAction.status === 'open' && (
            <Input
              type={'date'}
              value={estimatedClosingDate}
              title='Fecha estimada de cierre'
              onChange={(value: string | number) => {
                setCorrectiveAction({
                  ...correctiveAction,
                  estimatedClosingDate: new Date(value)
                });
                setEstimatedClosingDate(`${value}`);
              }}
              disabled={actionIsTheUser === false}
              hasError={!!errors.estimatedClosingDate}
              helperText={errors.estimatedClosingDate}
            />
          )
        }
        {
          ['close', 'approved'].includes(correctiveAction.status) && (
            <Input
              type={'textarea'}
              value={correctiveAction.descriptionOfActionsTaken}
              maxLength={3000}
              title='Descripción de acciones tomadas'
              onChange={(value: string | number) => {
                setCorrectiveAction({
                  ...correctiveAction,
                  descriptionOfActionsTaken: `${value}`
                });
              }}
              disabled={actionIsTheUser === false}
              hasError={!!errors.descriptionOfActionsTaken}
              helperText={errors.descriptionOfActionsTaken}
            />
          )
        }
        {
          correctiveAction.comments.length > 0 && (
            <div className="corrective-actions-form__container__comments-container">
              <SubTitle subTitle={t('global.comments')} />
              {
                correctiveAction.comments.map((comment) => (
                  <div className="corrective-actions-form__container__comments-container__comment">
                    <span>{comment.author}</span>
                    <p>{comment.comment}</p>
                    <span>{dayjs(comment.date).format('DD/MM/YYYY hh:mm a')}</span>
                  </div>
                ))
              }
            </div>
          )
        }
        {
          (['inspector', 'quality'].includes(userRole)
            || (userRole === 'inspector' ? manager : false)) && (
            <div className="corrective-actions-form__container__approve-buttons">
              <br />
              {
                actionIsTheUser === false
                && correctiveAction.status === 'close' && (
                  <>
                    <Button
                      type={'red'}
                      onClick={() => handleApproveCorrectiveAction(false)}
                      label={t('global.unapprove') || ''}
                      icon='dislike'
                      size='big'
                    />
                    <Button
                      type={'primary'}
                      onClick={() => handleApproveCorrectiveAction(true)}
                      label={t('global.approve') || ''}
                      icon='like'
                      size='big'
                    />
                  </>
                )
              }
              {
                correctiveAction.status !== 'approved'
                && actionIsTheUser === true && (
                  <Button
                    type={'primary'}
                    onClick={() => {
                      if (correctiveAction.id === '') {
                        handleCreateCorrectiveAction();
                      } else {
                        handleEditCorrectiveAction();
                      }
                    }}
                    label={t('global.save') || ''}
                    icon='addWhite'
                    size='big'
                  />
                )
              }
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CorrectiveActionForm;
