import React, { useContext, useState } from 'react';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

import {
  Title,
  File,
  Separator,
  SubTitle,
  Modal,
  Spinner
} from '../../..';

import '../../styles.scss';
import { useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  documents: string[]
}

const ProductDocuments: React.FC<Props> = (props) => {
  const {
    documents
  } = props;

  const {
    fetchResource
  } = useResource<string>();

  const { t } = useTranslation();

  const {
    soundEffects,
    apiType,
    setOpenModal
  } = useContext(AppContext);

  const [selectedEvidenceImageSRC, setSelectedEvidenceImageSRC] = useState<string>('');
  const [loadingImagePreview, setLoadingImagePreview] = useState<boolean>(false);
  const [resourceURL, setResourceURL] = useState<string>('');
  const [docURL, setDocURL] = useState<string>('');

  const handleLoadImage = (filePath: string) => {
    if (!selectedEvidenceImageSRC.includes(filePath)) {
      setLoadingImagePreview(true);
      fetchResource(
        `${apiType}/documents/signature?s_filePath=${filePath}`,
        (data) => {
          const file = filePath.toLocaleLowerCase();
          if (file.endsWith('.pdf')) {
            setResourceURL(data);
            setSelectedEvidenceImageSRC('');
            setDocURL('');
          } else if (file.endsWith('.docx')
            || file.endsWith('.xlsx')
            || file.endsWith('.ods')) {
            setDocURL(`https://docs.google.com/viewer?url=${encodeURIComponent(data)}&embedded=true`);
            setSelectedEvidenceImageSRC('');
            setResourceURL('');
          } else {
            setSelectedEvidenceImageSRC(data);
            setResourceURL('');
            setDocURL('');
          }
        },
        (error: string) => {
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `admin/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const getDocumentName = (document: string, round: '1' | '2' | '3') => {
    const nameSplit = document.split('/');

    return nameSplit[nameSplit.length - 1].replace(`round-${round}`, '');
  };

  return (
    <div className="constancy-product-detail__documents">
      <Title title={t('services.productDocuments')} type="primary" />
      <br />
      <div className="constancy-product-detail__documents__container">
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-1')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 1`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-1')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '1')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '1'))}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                    onClick={() => handleLoadImage(document)}
                    hoverEffect={true}
                  />
                ))
              }
            </>
          )
        }
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-2')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 2`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-2')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '2')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '2'))}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                    onClick={() => handleLoadImage(document)}
                    hoverEffect={true}
                  />
                ))
              }
            </>
          )
        }
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-3')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 3`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-3')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '3')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '3'))}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                    onClick={() => handleLoadImage(document)}
                    hoverEffect={true}
                  />
                ))
              }
            </>
          )
        }
      </div>
      {
        selectedEvidenceImageSRC && (
          <div className='inspection-detail__rounds__documents__documents-list__image-preview' id="image-preview">
            {
              loadingImagePreview && (
                <div className='inspection-detail__rounds__documents__documents-list__image-preview__spinner'>
                  <Spinner />
                </div>
              )
            }
            <img
              src={selectedEvidenceImageSRC}
              alt="evidence-image"
              onLoad={() => setLoadingImagePreview(false)}
            />
          </div>
        )
      }
      {
        resourceURL && (
          <div className='inspection-detail__rounds__documents__documents-list__content-preview' id="image-preview">
            {
              loadingImagePreview && (
                <div className='inspection-detail__rounds__documents__documents-list__image-preview__spinner'>
                  <Spinner />
                </div>
              )
            }
            <object
              aria-label="Document Viewer"
              data={resourceURL}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center'
              }}
              onLoad={() => setLoadingImagePreview(false)}
            >
              Tu navegador no soporta la visualización de este archivo.
            </object>
          </div>
        )
      }
      {
        docURL && (
          <div className='inspection-detail__rounds__documents__documents-list__content-preview' id="image-preview">
            {
              loadingImagePreview && (
                <div className='inspection-detail__rounds__documents__documents-list__image-preview__spinner'>
                  <Spinner />
                </div>
              )
            }
            <object
              aria-label="Document Viewer"
              data={docURL}
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center'
              }}
              onLoad={() => setLoadingImagePreview(false)}
            >
              Tu navegador no soporta la visualización de este archivo.
            </object>
          </div>
        )
      }
    </div>
  );
};

export default ProductDocuments;
