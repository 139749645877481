/* eslint-disable max-len */
/* eslint-disable no-loop-func */
import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  Button,
  Modal,
  Separator,
  Table
} from '../../../../components';
import { useResource } from '../../../../hooks';
import {
  Layout2DocumentProductData,
  TableHeader
} from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';
import '../../styles.scss';

interface Props {
  filterValue: string
}

export const Layout2: React.FC<Props> = (props) => {
  const {
    filterValue
  } = props;

  const { t } = useTranslation();

  const { fetchResource } = useResource<Layout2DocumentProductData[]>();
  const { createResource } = useResource<any>();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [products, setProducts] = useState<Layout2DocumentProductData[]>([]);

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('global.dictumNumber'),
      value: 'dictumNumber',
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: 'norm',
      format: 'none'
    },
    {
      label: t('global.rejectReasons.4'),
      value: 'acreditation',
      format: 'none'
    },
    {
      label: t('global.document'),
      value: 'document',
      format: 'none'
    },
    {
      label: t('global.patent'),
      value: 'patent',
      format: 'none'
    },
    {
      label: t('global.customs'),
      value: 'customs',
      format: 'none'
    },
    {
      label: t('global.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: t('global.verificationResult'),
      value: 'result',
      format: 'none'
    },
    {
      label: t('global.dateofSubmissionoftheOpinion'),
      value: 'date',
      format: 'date'
    },
    {
      label: t('global.description'),
      value: 'description',
      format: 'none'
    },
    {
      label: t('global.numberofVerifiedLabels'),
      value: 'numbersOfVerifiedLabels',
      format: 'none'
    }
  ];

  const downloadLayout2 = () => {
    createResource(
      '/admin/se-procedures/layout-2',
      {},
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        const now = new Date();
        const date: string = dayjs(now).format('DD-MMM-YYYY');
        const time: string = dayjs(now).format('hh mm a');

        link.download = `Layout 2 del ${date}, ${time}.csv`;
        link.click();

        setProducts([]);
        Modal.close();
      },
      (error: string) => Modal.fireError(
        error,
        () => { },
        soundEffects
      )
    );
  };

  const getProductsToLayout2 = () => {
    setProducts([]);

    fetchResource(
      '/admin/se-procedures/layout-2',
      (data) => {
        setProducts(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    getProductsToLayout2();
  }, [
    filterValue
  ]);

  return (
    <>
      <Table
        headers={tableHeaders}
        items={products}
      />
      <Separator orientation='horizontal' />
      <Button
        onClick={downloadLayout2}
        type='primary'
        label={t('global.downloadLayout', { number: 2 }) || ''}
        iconPosition='right'
        icon='downloadWhite'
        alignContent='left'
        disabled={products.length <= 0 || !['master', 'operator'].includes(userRole)}
      />
    </>
  );
};

export default Layout2;
