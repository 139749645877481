import React, { useEffect } from 'react';

import { useNavigate } from '../../hooks';
import { Button, Icon } from '../../components';

import Storage from '../../storage';

import './styles.scss';

const Error404: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Storage.get('sessionToken')) {
      navigate('/signin/no-route');
    }
  }, []);

  return (
    <div className='error-404'>
      <Icon type='bulletLeftTop' alt='Bullet 1' className='error-404__icon-1'/>
      <Icon type='bulletBlue' alt='Bullet 2' className='error-404__icon-2'/>
      <Icon type='bulletYellow' alt='Bullet 3' className='error-404__icon-3'/>
      <Icon type='bulletRightBottom' alt='Bullet 4' className='error-404__icon-4'/>
      <Icon type='stain' alt='stain' className='error-404__icon-5'/>
      <p>Ups! parece que aquí no hay nada...</p>
      <Icon type='error404' alt='Error 404' className='error-404__icon'/>
      <p>No pudimos encontrar lo que buscabas :(</p>
      <Button
        type='primary'
        icon='leftArrow'
        onClick={() => navigate(-1)}
        label='Regresar'
        iconPosition='left'
      />
    </div>
  );
};

export default Error404;
