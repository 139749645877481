import React, {
  ReactElement,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input } from '../../../../components';
import { Task } from '../../../../interfaces';
import DayComponent from './components';

import './styles.scss';

const dayjs = require('dayjs');

let monthsByNumber: { [name: number]: string } = {
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
  10: '',
  11: '',
  12: ''
};

const weekDays: string[] = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'];

interface Props {
  tasks: Task[]
  currentDate: Date
  setCurrentDate: (_date: Date) => void
}

const Calendar: React.FC<Props> = (props) => {
  const {
    tasks,
    currentDate,
    setCurrentDate
  } = props;

  const { t } = useTranslation();

  monthsByNumber = {
    1: t('global.months.january'),
    2: t('global.months.febrary'),
    3: t('global.months.march'),
    4: t('global.months.april'),
    5: t('global.months.may'),
    6: t('global.months.june'),
    7: t('global.months.july'),
    8: t('global.months.august'),
    9: t('global.months.september'),
    10: t('global.months.october'),
    11: t('global.months.november'),
    12: t('global.months.december')
  };

  const [days, setDays] = useState<ReactElement[]>([]);

  const previusMonthToGetDays = () => {
    if (Number(dayjs(currentDate).format('MM')) - 1 === 0) {
      return 12;
    }
    return Number(dayjs(currentDate).format('MM')) - 1;
  };

  const alerts: { [name: string]: ReactElement } = {
    'revision-inspection': (
      <div className="root-calendar__content__left__calendar__item__remote-inspection-alert"></div>
    ),
    'constancy-inspection': (
      <div className="root-calendar__content__left__calendar__item__remote-inspection-alert"></div>
    ),
    'onsite-inspection': (
      <div className="root-calendar__content__left__calendar__item__onsite-inspection-alert"></div>
    ),
    'remote-inspection': (
      <div className="root-calendar__content__left__calendar__item__remote-inspection-alert"></div>
    )
  };

  const getNextMonth = () => {
    if (Number(dayjs(currentDate).format('MM')) + 1 === 13) {
      return 1;
    }
    return Number(dayjs(currentDate).format('MM')) + 1;
  };

  const getNextYear = () => {
    if (Number(dayjs(currentDate).format('MM')) + 1 === 13) {
      return Number(dayjs(currentDate).format('YYYY')) + 1;
    }
    return Number(dayjs(currentDate).format('YYYY'));
  };

  const getpreviusYear = () => {
    if (Number(dayjs(currentDate).format('MM')) - 1 === 0) {
      return Number(dayjs(currentDate).format('YYYY')) - 1;
    }
    return Number(dayjs(currentDate).format('YYYY'));
  };

  const previusMonth = () => {
    const res = new Date(currentDate);
    res.setMonth(res.getMonth() - 1);
    setCurrentDate(res);
  };

  const nextMonth = () => {
    const res = new Date(currentDate);
    res.setMonth(res.getMonth() + 1);
    setCurrentDate(res);
  };

  const getDays = () => {
    const today = Number(dayjs(new Date()).format('D'));
    const month = Number(dayjs(new Date()).format('MM'));
    const year = Number(dayjs(new Date()).format('YYYY'));
    const dayOfFirstDayOfMonth = Number(dayjs(`${Number(dayjs(currentDate).format('YYYY'))}-${Number(dayjs(currentDate).format('MM'))}-1`).format('d'));
    const daysQuantityOfMonth = new Date(Number(dayjs(currentDate).format('YYYY')), Number(dayjs(currentDate).format('MM')), 0).getDate();

    const auxDays: ReactElement[] = [];
    let dayCount = 1;
    let nextMonthDayCount = 1;
    const previusMonthDayCount = new Date(Number(dayjs(currentDate).format('YYYY')), previusMonthToGetDays(), 0).getDate();

    for (let i = 0; i < 42; i += 1) {
      if (dayOfFirstDayOfMonth <= i) {
        if (dayCount <= daysQuantityOfMonth) {
          if (dayCount === today && Number(dayjs(currentDate).format('MM')) === month && Number(dayjs(currentDate).format('YYYY')) === year) {
            auxDays.push(
              <DayComponent
                type="today"
                number={dayCount}
                selectedDate={false}
                setAlertSquares={setAlertSquares}
                setCurrentDate={setCurrentDate}
                month={Number(dayjs(currentDate).format('MM'))}
                year={Number(dayjs(currentDate).format('YYYY'))}
                key={i}
              />
            );
            dayCount += 1;
          } else if (i === 0 || i === 7 || i === 14 || i === 21 || i === 28 || i === 35) {
            if (dayCount === Number(dayjs(currentDate).format('D'))) {
              auxDays.push(
                <DayComponent
                  type="sunday"
                  selectedDate={true}
                  number={dayCount}
                  setAlertSquares={setAlertSquares}
                  setCurrentDate={setCurrentDate}
                  month={Number(dayjs(currentDate).format('MM'))}
                  year={Number(dayjs(currentDate).format('YYYY'))}
                  key={i}
                />
              );
              dayCount += 1;
            } else {
              auxDays.push(
                <DayComponent
                  type="sunday"
                  number={dayCount}
                  selectedDate={false}
                  setAlertSquares={setAlertSquares}
                  setCurrentDate={setCurrentDate}
                  month={Number(dayjs(currentDate).format('MM'))}
                  year={Number(dayjs(currentDate).format('YYYY'))}
                  key={i}
                />
              );
              dayCount += 1;
            }
          } else if (dayCount === Number(dayjs(currentDate).format('D'))) {
            auxDays.push(
              <DayComponent
                type="normal"
                number={dayCount}
                selectedDate={true}
                setAlertSquares={setAlertSquares}
                setCurrentDate={setCurrentDate}
                month={Number(dayjs(currentDate).format('MM'))}
                year={Number(dayjs(currentDate).format('YYYY'))}
                key={i}
              />
            );
            dayCount += 1;
          } else {
            auxDays.push(
              <DayComponent
                type="normal"
                number={dayCount}
                selectedDate={false}
                setAlertSquares={setAlertSquares}
                setCurrentDate={setCurrentDate}
                month={Number(dayjs(currentDate).format('MM'))}
                year={Number(dayjs(currentDate).format('YYYY'))}
                key={i}
              />
            );
            dayCount += 1;
          }
        } else if (dayOfFirstDayOfMonth <= i) {
          auxDays.push(
            <DayComponent
              type="disabled"
              selectedDate={false}
              number={nextMonthDayCount}
              setAlertSquares={setAlertSquares}
              setCurrentDate={setCurrentDate}
              month={getNextMonth()}
              year={getNextYear()}
              key={i}
            />
          );
          nextMonthDayCount += 1;
        }
      } else {
        auxDays.push(
          <DayComponent
            type="disabled"
            selectedDate={false}
            number={previusMonthDayCount - (dayOfFirstDayOfMonth - i) + 1}
            setAlertSquares={setAlertSquares}
            setCurrentDate={setCurrentDate}
            month={previusMonthToGetDays()}
            year={getpreviusYear()}
            key={i}
          />
        );
      }
    }

    setDays(auxDays);
  };

  const setAlertSquares = (day: string): ReactElement[] => {
    const auxAlertsquares: ReactElement[] = [];
    let haveRemoteAlert: boolean = false;
    let haveOnsiteAlert: boolean = false;
    let haveOutOfTimeAlert: boolean = false;

    for (let x = 0; x < tasks.length; x += 1) {
      if (
        day === dayjs(String(tasks[x].datesRegistry.scheduledTo).substring(0, 10)).format('D')
        && dayjs(currentDate).format('MM/YYYY') === dayjs(String(tasks[x].datesRegistry.scheduledTo).substring(0, 10)).format('MM/YYYY')
        && auxAlertsquares.length < 3
      ) {
        if (!haveRemoteAlert && ['remote-inspection', 'constancy-inspection', 'revision-inspection'].includes(tasks[x].type)) {
          auxAlertsquares.push(alerts[tasks[x].type]);
          haveRemoteAlert = true;
        }
        if (!haveOnsiteAlert && tasks[x].type === 'onsite-inspection') {
          auxAlertsquares.push(alerts[tasks[x].type]);
          haveOnsiteAlert = true;
        }

        if (['in-progress', 'scheduled'].includes(tasks[x].status)
          // eslint-disable-next-line max-len
          && dayjs(String(tasks[x].datesRegistry.scheduledTo).substring(0, 10)).isBefore(dayjs(new Date()))
          && !haveOutOfTimeAlert) {
          auxAlertsquares.push(<Icon type="outOfTime" className="root-calendar__content__left__calendar__item__out-of-time-alert " />);
          haveOutOfTimeAlert = true;
        }
      }
    }

    return auxAlertsquares;
  };

  useEffect(() => {
    days.length = 0;
    setDays(days);

    getDays();
  }, [currentDate, tasks]);

  return (
    <div className="root-calendar__content__left">
      <div className="root-calendar__content__left__calendar__top">
        <div className="root-calendar__content__left__calendar__top__date">
          {monthsByNumber[Number(dayjs(currentDate).format('M'))]}{' '}
          {dayjs(currentDate).format('YYYY')}
          {
            (
              Number(dayjs(currentDate).format('YYYY')) !== Number(dayjs(new Date()).format('YYYY'))
              || Number(dayjs(currentDate).format('MM')) !== Number(dayjs(new Date()).format('MM'))
              || Number(dayjs(currentDate).format('D')) !== Number(dayjs(new Date()).format('D'))
            ) && (
              // eslint-disable-next-line max-len
              <p className="root-calendar__content__left__calendar__top__today" onClick={() => setCurrentDate(new Date())}>{t('tasks.goToday')}</p>
            )
          }
        </div>
        <div className="root-calendar__content__left__calendar__top__arrows-container">
          <Icon type="leftArrowCalendar" className="root-calendar__content__left__calendar__top__arrows-container__icon" onClick={previusMonth} />
          <Icon type="rightArrowCalendar" className="root-calendar__content__left__calendar__top__arrows-container__icon" onClick={nextMonth} />
        </div>
      </div>
      <div className="root-calendar__content__left__calendar__days">
        {
          weekDays.map((weekDay: string, index: number) => (
            <div className="root-calendar__content__left__calendar__days__day" key={index}>
              {weekDay}
            </div>
          ))
        }
      </div>
      <div className="root-calendar__content__left__calendar">
        {
          days.map((day: ReactElement) => (
            day
          ))
        }
      </div>
      <Input
        type='date'
        value={dayjs(currentDate).format('YYYY-MM-DD')}
        onChange={(value: string | number) => {
          if (value !== '') {
            const splitDate = String(value).split('-');
            const res = new Date(`${splitDate[0]}-${splitDate[1]}-${Number(splitDate[2]) + 1}`);
            setCurrentDate(res);
          }
        }}
        title={t('tasks.showTasksFrom') || ''}
      />
    </div>
  );
};

export default Calendar;
