import React, { useState, useEffect, useContext } from 'react';
import { driver } from 'driver.js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FileInput,
  Select,
  Title,
  File,
  Button,
  Input,
  Modal,
  StepsIndicator
} from '../../../../components';
import {
  Client,
  Collaborator,
  ClientAddress,
  DictumService,
  SelectOption,
  FileInputData,
  SignedFile,
  FileToSign
} from '../../../../interfaces';
import { utils, validate } from '../../../../helpers';

import './styles.scss';
import { emptyDictumService } from '../../../../emptyObjects';
import { useNavigate, useResource } from '../../../../hooks';
import { AppContext } from '../../../../context/AppContext';

interface FileData {
  name: string
  data: any
}

export const Step1 = () => {
  const {
    createResource,
    fetchResource,
    updateResource,
    eraseResource
  } = useResource<DictumService>();
  const {
    fetchResources: fetchClients,
    fetchResource: fetchClient
  } = useResource<Client>();
  const {
    fetchResources: fetchCollaborators
  } = useResource<Collaborator>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    apiType,
    setOpenModal,
    soundEffects,
    userToursDone,
    userRole,
    setShowDictumRequestTour
  } = useContext(AppContext);

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const [dictumService, setDictumService] = useState<DictumService>(emptyDictumService);
  const [auxVerifficationAddress, setAuxVerifficationAddress] = useState<string>('');
  const [clients, setClients] = useState<Client[]>([]);
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [commercialInvoices, setCommercialInvoices] = useState<FileInputData[]>([]);
  const [commercialInvoicesIdentifiers, setCommercialInvoicesIdentifiers] = useState<string[]>([]);
  const [petitionFound, setPetitionFound] = useState<boolean>(false);
  const [petitionDocumentHasChanged, setPetitionDocumentHasChanged] = useState<boolean>(false);
  const [dataHasChanged, setDataHasChanged] = useState<boolean>(false);
  const [commercialInvoicesHasChanged, setCommercialInvoicesHasChanged] = useState<boolean>(false);
  const [petitionNumberHasChanged, setPetitionNumberHasChanged] = useState<boolean>(false);
  const [disableNextButton, setDisableNextButton] = useState<boolean>(false);
  const [dictumServiceFile, setDictumServiceFile] = useState<FileInputData>({
    fileName: '',
    data: null
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const validData = (): boolean => {
    if (apiType === 'admin') {
      if (dictumService.client.id === 'none') {
        setErrors({
          client: t('services.errors.client')
        });

        return false;
      }

      if (dictumService.collaborator.id === 'none') {
        setErrors({
          collaborator: t('services.errors.collaborator')
        });

        return false;
      }
    }

    if (dictumService.inspectionAddress === '' || dictumService.inspectionAddress === 'none') {
      setErrors({
        inspectionAddress: t('services.errors.inspectionAddress')
      });

      return false;
    }

    setErrors({});

    if (dictumServiceFile.fileName === '') {
      setErrors({
        dictumServiceFile: t('services.errors.dictumDocument')
      });

      return false;
    }

    if (dictumService.petitionNumber === '') {
      setErrors({
        petitionNumber: t('services.errors.dictumDocumentNumber')
      });

      return false;
    }

    if (validate.petitionNumber(dictumService.petitionNumber) !== 'success') {
      setErrors({
        petitionNumber: validate.petitionNumber(dictumService.petitionNumber)
      });

      return false;
    }

    setErrors({});

    if (commercialInvoices.length === 0) {
      setErrors({
        commercialInvoiceFile: t('services.errors.commercialInvoiceFile')
      });
      return false;
    }

    setErrors({});

    for (let index = 0; index < commercialInvoicesIdentifiers.length; index += 1) {
      if (validate.text(commercialInvoicesIdentifiers[index], 1) !== 'success') {
        setErrors({
          [`commercialInvoice-${index}`]: t('services.errors.commercialInvoiceFileNumber')
        });

        return false;
      }
    }

    return true;
  };

  const createDictumService = (): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      createResource(
        `/${apiType}/dictum-services`,
        {
          clientID: dictumService.client.id,
          collaboratorID: dictumService.collaborator.id,
          petitionNumber: dictumService.petitionNumber,
          inspectionAddress: dictumService.inspectionAddress
        },
        (data: DictumService) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const updateDictumService = (): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      updateResource(
        `/${apiType}/dictum-services/${dictumService.id}`,
        {
          petitionNumber: dictumService.petitionNumber,
          inspectionAddress: dictumService.inspectionAddress
        },
        (data: DictumService) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const formatFileName = (fileName: string) => {
    const nameArr1 = fileName.split('/');

    let name: string = '';

    for (let index = 0; index < nameArr1.length; index += 1) {
      name += nameArr1[index];
    }

    const nameArr2 = name.split('%');
    name = '';

    for (let index = 0; index < nameArr2.length; index += 1) {
      name += nameArr2[index];
    }

    return name.replace(/[%&?¿=.#/+]/g, '');
  };

  const uploadCommercialInvoicesFiles = (serviceID: string): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      const paths: FileToSign[] = [];
      const documentsData: FileData[] = [];

      commercialInvoices.forEach((fileItem, index) => {
        const file = commercialInvoices[index].data;

        if (typeof file !== 'string') {
          const nameArr = file.name.split('.');
          const fileExtension = nameArr[nameArr.length - 1].toLowerCase();

          paths.push({
            path: `${formatFileName(commercialInvoicesIdentifiers[index])}.${fileExtension}`,
            type: commercialInvoices[index].data.type,
            documentName: `${formatFileName(commercialInvoicesIdentifiers[index])}.${fileExtension}`
          });
          documentsData.push({
            name: `${formatFileName(commercialInvoicesIdentifiers[index])}.${fileExtension}`,
            data: file
          });
        }
      });

      if (paths.length > 0) {
        registerUploadedFiles(
          `/${apiType}/dictum-services/${serviceID}/documents/commercial-invoice`,
          {
            filePaths: paths
          },
          async (data) => {
            setDisableNextButton(true);
            const uploadedPaths: string[] = [];
            let errorMessage: string = '';

            Modal.fireLoading(undefined, 0);
            try {
              // eslint-disable-next-line array-callback-return, consistent-return
              await Promise.all(data.map((item) => {
                // eslint-disable-next-line max-len
                const file = documentsData.find((fileItem) => utils.deleteDotsOnFileName(fileItem.name) === item.documentName);

                const formData = new FormData();

                Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                  formData.append(key, value as string);
                });

                if (file) {
                  formData.append('file', file.data);

                  return axios.post(
                    item.signedURL.url,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      },
                      onUploadProgress: (progressEvent) => {
                        const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                        Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                      }
                    }
                  ).then(() => {
                    Modal.close();
                    uploadedPaths.push(item.url);
                  });
                }
              }));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              errorMessage = t('global.errorUploadingFile');
            } finally {
              if (errorMessage === '') {
                Modal.close();
              } else {
                Modal.fireError(errorMessage, setOpenModal, soundEffects);
              }
            }

            if (uploadedPaths.length > 0) {
              updateResource(
                `/${apiType}/dictum-services/${serviceID}/documents-paths/commercial-invoice`,
                {
                  filePaths: uploadedPaths
                },
                (data2) => {
                  setDisableNextButton(false);
                  resolve(data2);
                },
                (error: string) => {
                  setDisableNextButton(true);
                  reject(error);
                }
              );
            }
          },
          (error: string) => {
            setDisableNextButton(true);
            reject(error);
          }
        );
      }
    });
  };

  const uploadDictumFile = (serviceID: string): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      const file = dictumServiceFile.data;

      registerUploadedFiles(
        `/${apiType}/dictum-services/${serviceID}/documents/petition`,
        {
          filePaths: [{
            path: `${dictumService.petitionNumber}.pdf`.replace(/[%&?¿=#/+]/g, ''),
            type: 'application/pdf'
          }]
        },
        async (data) => {
          setDisableNextButton(true);
          const uploadedPaths: string[] = [];
          let errorMessage: string = '';

          Modal.fireLoading(undefined, 0);
          try {
            // eslint-disable-next-line array-callback-return, consistent-return
            await Promise.all(data.map((item) => {
              const formData = new FormData();

              Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                formData.append(key, value as string);
              });

              formData.append('file', file);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }));
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            errorMessage = t('global.errorUploadingFile');
          } finally {
            if (errorMessage === '') {
              Modal.close();
            } else {
              Modal.fireError(errorMessage, setOpenModal, soundEffects);
            }
          }

          if (uploadedPaths.length > 0) {
            updateResource(
              `/${apiType}/dictum-services/${serviceID}/documents-paths/petition`,
              {
                filePaths: uploadedPaths
              },
              (data2) => {
                setDisableNextButton(false);
                resolve(data2);
              },
              (error: string) => {
                setDisableNextButton(true);
                reject(error);
              }
            );
          }
        },
        (error: string) => {
          setDisableNextButton(true);
          reject(error);
        }
      );
    });
  };

  const getCollaborators = (clientID: string) => {
    fetchCollaborators(
      {
        resourcePath: `/${apiType}/collaborators`,
        filters: {
          s_clientID: clientID,
          b_isActive: true
        }
      },
      (data) => setCollaborators(data.items),
      (error) => {
        Modal.fireError(error as string, setOpenModal, soundEffects);
      }
    );
  };

  const deletePetitionDocument = (documentPath: string): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      eraseResource(
        `/${apiType}/dictum-services/${params.dictumServiceID}/documents`,
        {
          s_documentPath: documentPath
        },
        (data: DictumService) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const deleteCommercialInvoice = (documentPath: string): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      eraseResource(
        `/${apiType}/dictum-services/${params.dictumServiceID}/documents`,
        {
          s_documentPath: documentPath
        },
        (data: DictumService) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const onDeleteCommercialInvoice = async (
    commercialInvoice: FileInputData,
    index: number,
    fileName: string
  ) => {
    try {
      if (params.dictumServiceID !== 'none' && fileName.includes('__commercial-invoice')) {
        const auxDictumService = await deleteCommercialInvoice(commercialInvoice.data);
        setDictumService(auxDictumService);
      }

      setCommercialInvoices((items) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        items.filter((_item, stateIndex) => stateIndex !== index));

      setCommercialInvoicesIdentifiers((items) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        items.filter((_item, stateIndex) => stateIndex !== index));

      setCommercialInvoicesHasChanged(true);
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  const onDeleteDictumServiceFile = async (fileName: string) => {
    try {
      if (params.dictumServiceID !== 'none' && fileName.includes('__petition')) {
        const auxDictumService = await deletePetitionDocument(dictumServiceFile.data);
        setDictumService(auxDictumService);
      }

      setDictumServiceFile({
        data: null,
        fileName: ''
      });

      setPetitionDocumentHasChanged(true);
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  const handleContinue = async () => {
    if (validData()) {
      if (
        !petitionDocumentHasChanged
        && !dataHasChanged
        && !commercialInvoicesHasChanged
        && !petitionNumberHasChanged
      ) {
        navigate(`/dictum-request/step_2/${params.dictumServiceID}/${dictumService.petitionNumber}/${dictumService.client.id}/${dictumService.collaborator.id}`);
      } else {
        try {
          if (params.dictumServiceID === 'none') {
            if (dataHasChanged && petitionNumberHasChanged && dictumService.id === '') {
              const service = await createDictumService();

              setDictumService(service);
              setDataHasChanged(false);

              await uploadDictumFile(service.id);
              setPetitionDocumentHasChanged(false);

              await uploadCommercialInvoicesFiles(service.id);
              setCommercialInvoicesHasChanged(false);

              navigate(`/dictum-request/step_2/${service.id}/${dictumService.petitionNumber}/${dictumService.client.id}/${dictumService.collaborator.id}`);
            }
          } else {
            let auxService: DictumService = emptyDictumService;

            if (dataHasChanged || petitionNumberHasChanged) {
              auxService = await updateDictumService();
              setDictumService(auxService);
              setDataHasChanged(false);
            }

            if (petitionDocumentHasChanged) {
              auxService = await uploadDictumFile(params.dictumServiceID || '');
              setPetitionDocumentHasChanged(false);
            }

            if (commercialInvoicesHasChanged) {
              auxService = await uploadCommercialInvoicesFiles(params.dictumServiceID || '');
              setCommercialInvoicesHasChanged(false);
            }

            navigate(`/dictum-request/step_2/${auxService?.id}/${dictumService.petitionNumber}/${dictumService.client.id}/${dictumService.collaborator.id}`);
          }
        } catch (error) {
          Modal.fireError(error as string, setOpenModal, soundEffects);
        }
      }
    }
  };

  const handleChangeClient = (value: string) => {
    setDictumService({
      ...dictumService,
      client: {
        id: value,
        businessName: dictumService.client.businessName,
        shortName: dictumService.client.shortName
      },
      collaborator: {
        id: 'none',
        name: value !== 'none' ? dictumService.collaborator.name : ''
      },
      inspectionAddress: 'none'
    });

    if (value !== 'none') {
      getCollaborators(value);
    } else {
      setCollaborators([]);
      setDictumService(emptyDictumService);
    }
  };

  const handleChangeCollaborator = (value: string) => {
    setDictumService({
      ...dictumService,
      collaborator: {
        id: value,
        name: collaborators.find(element => element.id === value)?.name || ''
      }
    });
  };

  const handleChangeInspectionAddress = (value: string) => {
    setDictumService({
      ...dictumService,
      inspectionAddress: value
    });

    setDataHasChanged(true);
  };

  const handleChangePetitionNumber = (value: string | number) => {
    setDictumService({
      ...dictumService,
      petitionNumber: `${value}`
    });
    setPetitionNumberHasChanged(true);
  };

  const onUploadCommercialInvoice = (payload: FileInputData[]) => {
    const formatedPayload: any[] = [];

    for (let index = 0; index < payload.length; index += 1) {
      formatedPayload.push({
        data: payload[index].data,
        fileName: payload[index].fileName.replace(/%/g, '')
      });
    }

    setCommercialInvoices(commercialInvoices.concat(formatedPayload));

    for (let index = 0; index < formatedPayload.length; index += 1) {
      setCommercialInvoicesIdentifiers(state => [...state, '']);
    }

    setCommercialInvoicesHasChanged(true);
  };

  const handleCommercialInvoiceIdentifier = (value: string | number, index: number) => {
    setCommercialInvoicesIdentifiers(
      commercialInvoicesIdentifiers.map((_value: string | number, stateIndex: number) => {
        if (stateIndex === index) {
          return `${value}`;
        }

        return `${_value}`;
      })
    );
  };

  const setOptionsVerifficationAddresses = (): SelectOption[] => {
    // eslint-disable-next-line max-len
    const auxAddresses = clients.find(_clientFind => _clientFind.id === dictumService.client.id)?.inspectionAddresses.map(
      (adress: ClientAddress) => {
        const address = utils.formatValue('address', adress);
        return {
          text: address,
          value: address
        };
      }
    ) || [];

    if (
      !auxAddresses.find(address => address.value === auxVerifficationAddress)
      && auxVerifficationAddress
    ) {
      auxAddresses.push(
        {
          text: auxVerifficationAddress,
          value: auxVerifficationAddress
        }
      );
    }

    return auxAddresses;
  };

  const disableContinueButton = (!petitionFound && params.dictumServiceID !== 'none') || clients.length === 0 || disableNextButton;

  const alertEmptyCollaborators = collaborators.length === 0
    && dictumService.client.id !== 'none'
    && params.dictumServiceID === 'none';

  // eslint-disable-next-line max-len
  const alertEmptyVierifficationAddresses = clients.find(_clientFind => _clientFind.id === dictumService.client.id)?.inspectionAddresses.length === 0
    && dictumService.client.id !== 'none'
    && params.dictumServiceID === 'none';

  const disableVerifficationAddressInput = () => {
    if ((apiType === 'admin' && dictumService.client.id === 'none')
      // eslint-disable-next-line max-len
      || clients.find(_clientFind => _clientFind.id === dictumService.client.id)?.inspectionAddresses.length === 0) {
      return true;
    }

    if (apiType === 'public'
      // eslint-disable-next-line max-len
      && clients.find(_clientFind => _clientFind.id === dictumService.client.id)?.inspectionAddresses.length === 0) {
      return true;
    }

    return false;
  };

  const getClients = (): Promise<Client[]> => {
    return new Promise((resolve, reject) => {
      fetchClients(
        {
          resourcePath: '/admin/clients',
          ordering: {
            s_orderBy: 'businessName',
            s_orderDirection: 'ASC'
          }
        },
        (data) => resolve(data.items),
        (error) => reject(error)
      );
    });
  };

  const getClient = (): Promise<Client> => {
    return new Promise((resolve, reject) => {
      fetchClient(
        '/public/clients/me',
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const getDictumService = (): Promise<DictumService> => {
    return new Promise((resolve, reject) => {
      fetchResource(
        `/${apiType}/dictum-services/${params.dictumServiceID}`,
        (data) => resolve(data),
        (error) => reject(error)
      );
    });
  };

  const getData = async () => {
    try {
      if (params.dictumServiceID !== 'none') {
        const dictumServiceData = await getDictumService();

        setDictumService(dictumServiceData);
        setAuxVerifficationAddress(dictumServiceData.inspectionAddress);
        setPetitionFound(true);

        const auxCommercialInvoices: FileInputData[] = [];
        const auxCommercialInvoicesIdentifiers: string[] = [];

        for (let index = 0; index < dictumServiceData.documents.length; index += 1) {
          if ((dictumServiceData.documents as any)[index].indexOf('__petition') !== -1) {
            const nameArr = (dictumServiceData.documents as any)[index].split('/');

            setDictumServiceFile({
              data: (dictumServiceData.documents as any)[index],
              fileName: nameArr[nameArr.length - 1].replace(/%/g, '')
            });
          } else if (dictumServiceData.documents[index].indexOf('__commercial-invoice') !== -1) {
            const auxNameArr1 = dictumServiceData.documents[index].split('/');

            auxCommercialInvoices.push({
              data: dictumServiceData.documents[index],
              fileName: auxNameArr1[auxNameArr1.length - 1]
            });

            const auxNameArr2 = dictumServiceData.documents[index].split('__');

            const auxNameArr3 = auxNameArr2[auxNameArr2.length - 2].split('/');

            auxCommercialInvoicesIdentifiers.push(auxNameArr3[auxNameArr3.length - 1]);
          }
        }

        setCommercialInvoicesIdentifiers(auxCommercialInvoicesIdentifiers);
        setCommercialInvoices(auxCommercialInvoices);
      }

      if (apiType === 'admin') {
        setClients(await getClients());
      } else if (apiType === 'public') {
        const client = await getClient();

        setClients([client]);

        setDictumService(state => ({
          ...state,
          client: {
            id: client.id,
            businessName: client.businessName,
            shortName: client.shortName
          }
        }));
      }
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  useEffect(() => {
    getData();
  }, [apiType]);

  const driverObj = driver({
    showProgress: false,
    allowClose: false,
    smoothScroll: true,
    disableActiveInteraction: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#request-address', popover: { title: t('tours.dictumStep1') || '', description: t('tours.dictumStep1Description') || '' } },
      { element: '#request-petition', popover: { title: t('tours.dictumPetition') || '', description: t('tours.dictumPetitionDescription') || '' } },
      { element: '#request-commercial-invoices', popover: { title: t('tours.dictumComercialInvoices') || '', description: t('tours.dictumComercialInvoicesDescription') || '' } },
      { element: '#continue-button', popover: { title: t('tours.dictumCreateRequest') || '', description: t('tours.dictumCreateRequestDescription') || '' } }
    ]
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('dictum-request') || typeof userToursDone === 'string' || userToursDone === null) {
        driverObj.drive();
      } else {
        driverObj.destroy();
      }
    }
  }, [userToursDone]);

  return (
    <>
      <div className='step-1__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={2}
              currentStep={1}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={1}
            />
          )
        }
      </div>
      <div className='step-1__content'>
        <div className='step-1'>
          <div>
            <Button
              type={'primary'}
              onClick={() => {
                driverObj.drive();
                setShowDictumRequestTour(true);
              }}
              label={t('tours.showTour') || ''}
              icon='tour'
              rounded={true}
              fullWidth={false}
            />
          </div>
          <Title title={t('services.dictumStep1')} />
          {
            apiType === 'admin' && (
              <>
                <div className='step-1__input-container'>
                  <Title title={t('global.client')} type='secondary' />
                  {
                    params.dictumServiceID !== 'none' ? (
                      <Input
                        type='text'
                        value={dictumService.client.businessName}
                        onChange={() => { }}
                        disabled={true}
                      />
                    ) : (
                      <Select
                        options={
                          clients.map((_client: Client) => {
                            return {
                              text: _client.businessName,
                              value: _client.id
                            };
                          })
                        }
                        onChange={handleChangeClient}
                        value={dictumService.client.id}
                        disabled={clients.length === 0 || params.dictumServiceID !== 'none'}
                        hasError={clients.length === 0 && params.dictumServiceID === 'none' ? true : !!errors.client}
                        helperText={clients.length === 0 && params.dictumServiceID === 'none' ? t('global.clientsNotFound') || '' : errors.client || t('global.clientService') || ''}
                      />
                    )
                  }
                </div>
                <div className='step-1__input-container'>
                  <Title title={t('global.collaborator')} type='secondary' />
                  {
                    params.dictumServiceID !== 'none' ? (
                      <Input
                        type='text'
                        value={dictumService.collaborator.name}
                        onChange={() => { }}
                        disabled={true}
                      />
                    ) : (
                      <Select
                        options={
                          // eslint-disable-next-line max-len
                          collaborators.filter(objeto => objeto.isActive === true).map((_collaborator: Collaborator) => {
                            return {
                              text: _collaborator.name,
                              value: _collaborator.id
                            };
                          })
                        }
                        onChange={handleChangeCollaborator}
                        value={dictumService.collaborator.id}
                        disabled={collaborators.length === 0 || clients.length === 0}
                        hasError={alertEmptyCollaborators ? true : !!errors.collaborator}
                        helperText={alertEmptyCollaborators ? t('global.collaboratorsNotFound') || '' : errors.collaborator || t('global.collaboratorService') || ''}
                      />
                    )
                  }
                </div>
              </>
            )
          }
          <div className='step-1__input-container' id='request-address'>
            <Title title={t('services.inspectionAddress')} type='secondary' />
            <Select
              options={setOptionsVerifficationAddresses()}
              onChange={handleChangeInspectionAddress}
              value={dictumService.inspectionAddress}
              disabled={disableVerifficationAddressInput()}
              hasError={alertEmptyVierifficationAddresses ? true : !!errors.inspectionAddress}
              helperText={alertEmptyVierifficationAddresses
                ? t('services.errors.emptyInspectionAddress') || ''
                : errors.inspectionAddress || t('services.inspectionAddressExplication') || ''
              }
            />
          </div>
          <div className='step-1__file-container' id='request-petition'>
            <Title title={t('services.petition')} type='secondary' />
            {
              dictumServiceFile.fileName ? (
                <div className='step-1__file-container__item'>
                  <File
                    name={dictumServiceFile.fileName}
                    onDelete={() => onDeleteDictumServiceFile(dictumServiceFile.fileName)}
                  />
                  <div className='step-1__file-container__item__input-container'>
                    <Input
                      type='petitionNumber'
                      value={dictumService.petitionNumber}
                      onChange={(value: string | number) => {
                        handleChangePetitionNumber(value);
                      }}
                      title={t('services.petitionNumber') || ''}
                      placeholder={t('services.petitionNumberExample') || ''}
                      hasError={!!errors.petitionNumber}
                      helperText={errors.petitionNumber || t('services.petitionNumberExplication') || ''}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <FileInput
                    isMultiple={false}
                    acceptedFileFormats='.pdf'
                    onSelectFile={(payload: FileInputData[]) => {
                      setDictumServiceFile({
                        data: payload[0].data,
                        fileName: payload[0].fileName.replace(/%/g, '')
                      });
                      setPetitionDocumentHasChanged(true);
                    }}
                    hasError={!!errors.dictumServiceFile}
                    helperText={errors.dictumServiceFile || t('services.proformFileExplication') || ''}
                  />
                </>
              )
            }
          </div>
          <div className='step-1__file-container' id='request-commercial-invoices'>
            <Title title={t('services.commercialInvoice')} type='secondary' />
            {
              commercialInvoices.length < 20 && (
                <>
                  <FileInput
                    isMultiple={true}
                    acceptedFileFormats='.pdf,.png,.jpg,.jpeg,.JPEG,.PNG'
                    onSelectFile={(payload: FileInputData[]) => onUploadCommercialInvoice(payload)}
                    hasError={!!errors.commercialInvoiceFile}
                    helperText={errors.commercialInvoiceFile || t('services.commercialInvoiceExplication') || ''}
                  />
                </>
              )
            }
            {
              commercialInvoices.map((commercialInvoice: FileInputData, index: number) => (
                <div className='step-1__file-container__item' key={index}>
                  <File
                    name={commercialInvoice.fileName}
                    onDelete={() => onDeleteCommercialInvoice(
                      commercialInvoice,
                      index,
                      commercialInvoice.fileName
                    )}
                  />
                  <div className='step-1__file-container__item__input-container'>
                    <Input
                      type='text'
                      value={commercialInvoicesIdentifiers[index]}
                      onChange={(value: string | number) => {
                        handleCommercialInvoiceIdentifier(value, index);
                      }}
                      disabled={params.dictumServiceID !== 'none' && typeof commercialInvoice.data === 'string'}
                      title={t('services.commercialInvoiceNumber') || ''}
                      placeholder={t('services.commercialInvoiceNumberExample') || ''}
                      hasError={!!errors[`commercialInvoice-${index}`]}
                      helperText={errors[`commercialInvoice-${index}`] || t('services.commercialInvoiceNumberExplication') || ''}
                    />
                  </div>
                </div>
              ))
            }
          </div>
          <div className='step-1__button-container'>
            <Button
              onClick={handleContinue}
              type='primary'
              label={t('global.continue') || ''}
              icon='rightArrow'
              iconPosition='right'
              disabled={disableContinueButton}
              id='continue-button'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
