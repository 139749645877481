import { useTranslation } from 'react-i18next';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import {
  Header,
  Notification as NotificationComponent,
  Select,
  Spinner,
  Title
} from '../../components';
import { AppContext } from '../../context/AppContext';
import {
  Notification, NotificationTypes, SelectOption
} from '../../interfaces';

import './styles.scss';

interface Option {
  show: boolean,
  option: SelectOption
}

const Notifications = () => {
  const { t, i18n } = useTranslation();

  const {
    notifications,
    getNotifications,
    setGetNotifications
  } = useContext(AppContext);

  const [isPending, startTransition] = useTransition();
  const [quantityElementsOnList, setQuantityElementsOnList] = useState<number>(15);
  const [seeNotifications, setSeeNotifications] = useState<'all' | 'not-see'>('all');
  const [selectedFilter, setSelectedFilter] = useState<NotificationTypes | 'all'>('all');
  const [localNotifications, setLocalNotifications] = useState<Notification[]>([]);
  const [options, setOptions] = useState<{ [name: string]: Option }>({
    'inspection-in-review': {
      show: false,
      option: {
        text: t('notifications.select.inspection-in-review'),
        value: 'inspection-in-review'
      }
    },
    'inspection-waiting-for-files-for-remote-round': {
      show: false,
      option: {
        text: t('notifications.select.inspection-waiting-for-files-for-remote-round'),
        value: 'inspection-waiting-for-files-for-remote-round'
      }
    },
    'inspection-waiting-for-assign': {
      show: false,
      option: {
        text: t('notifications.select.inspection-waiting-for-assign'),
        value: 'inspection-waiting-for-assign'
      }
    },
    'inspection-whitout-inspection-list': {
      show: false,
      option: {
        text: t('notifications.select.inspection-whitout-inspection-list'),
        value: 'inspection-whitout-inspection-list'
      }
    },
    'inspection-in-progress': {
      show: false,
      option: {
        text: t('notifications.select.inspection-in-progress'),
        value: 'inspection-in-progress'
      }
    },
    'dictum-whitout-paid-petition': {
      show: false,
      option: {
        text: t('notifications.select.dictum-whitout-paid-petition'),
        value: 'dictum-whitout-paid-petition'
      }
    },
    'dictum-product-invoice-assigned': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-invoice-assigned'),
        value: 'dictum-product-invoice-assigned'
      }
    },
    'dictum-product-not-validated': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-not-validated'),
        value: 'dictum-product-not-validated'
      }
    },
    'dictum-product-validation-in-progress': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-validation-in-progress'),
        value: 'dictum-product-validation-in-progress'
      }
    },
    'dictum-product-cancellation-in-progress': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-cancellation-in-progress'),
        value: 'dictum-product-cancellation-in-progress'
      }
    },
    'dictum-product-ready-to-send-in-layout-2': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-ready-to-send-in-layout-2'),
        value: 'dictum-product-ready-to-send-in-layout-2'
      }
    },
    'dictum-whitout-out-of-customs-date': {
      show: false,
      option: {
        text: t('notifications.select.dictum-whitout-out-of-customs-date'),
        value: 'dictum-whitout-out-of-customs-date'
      }
    },
    'dictum-service-soon-to-expire': {
      show: false,
      option: {
        text: t('notifications.select.dictum-service-soon-to-expire'),
        value: 'dictum-service-soon-to-expire'
      }
    },
    'dictum-whit-not-requested-visit': {
      show: false,
      option: {
        text: t('notifications.select.dictum-whit-not-requested-visit'),
        value: 'dictum-whit-not-requested-visit'
      }
    },
    'task-assigned': {
      show: false,
      option: {
        text: t('notifications.select.task-assigned'),
        value: 'task-assigned'
      }
    },
    'task-in-progress': {
      show: false,
      option: {
        text: t('notifications.select.task-in-progress'),
        value: 'task-in-progress'
      }
    },
    'constancy-incomplete': {
      show: false,
      option: {
        text: t('notifications.select.constancy-incomplete'),
        value: 'constancy-incomplete'
      }
    },
    'revision-incomplete': {
      show: false,
      option: {
        text: t('notifications.select.revision-incomplete'),
        value: 'revision-incomplete'
      }
    },
    'dictum-incomplete': {
      show: false,
      option: {
        text: t('notifications.select.dictum-incomplete'),
        value: 'dictum-incomplete'
      }
    },
    'constancy-service-soon-to-expire': {
      show: false,
      option: {
        text: t('notifications.select.constancy-service-soon-to-expire'),
        value: 'constancy-service-soon-to-expire'
      }
    },
    'dictum-service-soon-to-expire-from-creation': {
      show: false,
      option: {
        text: t('notifications.select.dictum-service-soon-to-expire-from-creation'),
        value: 'dictum-service-soon-to-expire-from-creation'
      }
    },
    'dictum-product-expired': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-expired'),
        value: 'dictum-product-expired'
      }
    },
    'dictum-product-soon-to-expire-from-sentInLayout1Date': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-soon-to-expire-from-sentInLayout1Date'),
        value: 'dictum-product-soon-to-expire-from-sentInLayout1Date'
      }
    },
    'dictum-product-soon-to-expire-from-creation': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-soon-to-expire-from-creation'),
        value: 'dictum-product-soon-to-expire-from-creation'
      }
    },
    'dictum-whitout-products': {
      show: false,
      option: {
        text: t('notifications.select.dictum-whitout-products'),
        value: 'dictum-whitout-products'
      }
    },
    'constancy-whitout-products': {
      show: false,
      option: {
        text: t('notifications.select.constancy-whitout-products'),
        value: 'constancy-whitout-products'
      }
    },
    'revision-whitout-products': {
      show: false,
      option: {
        text: t('notifications.select.revision-whitout-products'),
        value: 'revision-whitout-products'
      }
    },
    'dictum-product-not-atached-ready-to-cancel': {
      show: false,
      option: {
        text: t('notifications.select.dictum-product-not-atached-ready-to-cancel'),
        value: 'dictum-product-not-atached-ready-to-cancel'
      }
    },
    'inspection-in-progress-by-round': {
      show: false,
      option: {
        text: t('notifications.select.inspection-in-progress-by-round'),
        value: 'inspection-in-progress-by-round'
      }
    },
    'inspection-not-complies-whit-inspection-format': {
      show: false,
      option: {
        text: t('notifications.select.inspection-not-complies-whit-inspection-format'),
        value: 'inspection-not-complies-whit-inspection-format'
      }
    },
    'inspection-remote-waiting-for-files': {
      show: false,
      option: {
        text: t('notifications.select.inspection-remote-waiting-for-files'),
        value: 'inspection-remote-waiting-for-files'
      }
    },
    'task-waiting-for-close': {
      show: false,
      option: {
        text: t('notifications.select.task-waiting-for-close'),
        value: 'task-waiting-for-close'
      }
    },
    'training-record-waiting-for-sign': {
      show: false,
      option: {
        text: t('notifications.select.training-record-waiting-for-sign'),
        value: 'training-record-waiting-for-sign'
      }
    }
  });

  const ifNotSee = (notificationID: string): boolean => {
    let result: boolean = false;

    if (notifications) {
      for (let index = 0; index < notifications.notifications.length; index += 1) {
        if (notifications.notifications[index].id === notificationID) {
          result = notifications.notifications[index].alert;
        }
      }
    }

    return result;
  };

  const setNotificationsQuantity = (type: 'all' | 'not-see'): number => {
    let quantity: number = 0;

    if (notifications) {
      for (let index = 0; index < notifications.notifications.length; index += 1) {
        if (type === 'all' && (selectedFilter === 'all' ? true : selectedFilter === notifications.notifications[index].type)) {
          quantity += 1;
        }
        if (type === 'not-see' && notifications.notifications[index].alert && (selectedFilter === 'all' ? true : selectedFilter === notifications.notifications[index].type)) {
          quantity += 1;
        }
      }
    }

    return quantity;
  };

  useEffect(() => {
    const auxOptions: { [name: string]: Option } = options;

    for (let index = 0; index < localNotifications.length; index += 1) {
      auxOptions[localNotifications[index].type].show = true;
    }

    setOptions(auxOptions);
  }, [i18n.language, localNotifications]);

  useEffect(() => {
    setGetNotifications(!getNotifications);
  }, []);

  const setOptionsSelect = useCallback((): SelectOption[] => {
    const auxOptions: SelectOption[] = [
      {
        text: t('global.allNotifications'),
        value: 'all'
      }
    ];

    const optionsKeys = Object.keys(options);

    for (let index = 0; index < optionsKeys.length; index += 1) {
      if (options[optionsKeys[index]].show) {
        auxOptions.push(options[optionsKeys[index]].option);
      }
    }

    return auxOptions;
  }, [i18n.language, options]);

  useEffect(() => {
    startTransition(() => {
      setLocalNotifications(notifications.notifications.slice(0, quantityElementsOnList));
    });
  }, [notifications, quantityElementsOnList]);

  const getMoreItems = () => {
    startTransition(() => {
      setQuantityElementsOnList(quantityElementsOnList + 15);
    });
  };

  return (
    <div>
      <Header
        title={`${t('menu.notifications')}`}
        showBackbutton={true}
      />
      <div className="notifications__main">
        <div className="notifications__main__select">
          <Title title={t('global.filer')} />
          <Select
            options={setOptionsSelect()}
            onChange={(_value: string) => {
              startTransition(() => {
                if (_value !== 'all') {
                  setSelectedFilter(_value as NotificationTypes);
                }
                if (_value === 'none' || _value === 'all') {
                  setSelectedFilter('all');
                }
              });
            }}
            value={selectedFilter}
          />
        </div>
        <div className="notifications__main__filter">
          <div key={1} className={`notifications__main__filter__item notifications__main__filter__item${seeNotifications === 'all' ? '--active' : ''}`} onClick={() => setSeeNotifications('all')}>
            <span>{t('global.allNotifications')} ({setNotificationsQuantity('all')})</span>
          </div>
          <div key={2} className={`notifications__main__filter__item notifications__main__filter__item${seeNotifications === 'not-see' ? '--active' : ''}`} onClick={() => setSeeNotifications('not-see')}>
            <span>{t('global.notSee')} ({setNotificationsQuantity('not-see')})</span>
          </div>
        </div>
        {
          seeNotifications === 'all' && (
            <>
              {localNotifications.map((item: Notification) => (
                <>
                  {(item.type === selectedFilter || selectedFilter === 'all') && (
                    < NotificationComponent key={item.id} item={item} />
                  )}
                </>
              ))}
            </>
          )
        }
        {
          seeNotifications === 'not-see' && (
            <>
              {localNotifications.map((item: Notification) => (
                ifNotSee(item.id) && (
                  <>
                    {(item.type === selectedFilter || selectedFilter === 'all') && (
                      < NotificationComponent key={item.id} item={item} />
                    )}
                  </>
                )
              ))}
            </>
          )
        }
        {
          isPending && (
            <div className="notifications__main__spinner">
              <Spinner />
            </div>
          )
        }
        <p
          className='notifications__main__loading-label'
          onClick={getMoreItems}
        >
          {
            notifications.notifications.length > quantityElementsOnList && (
              t('tasks.showMore')
            )
          }
        </p>
      </div>
    </div>
  );
};

export default Notifications;
