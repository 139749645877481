import error404DarkMode from './iconsDarkMode/error-404.svg';
import editDarkMode from './iconsDarkMode/edit.svg';
import uploadDarkMode from './iconsDarkMode/upload.svg';
import needAprovalDarkMode from './iconsDarkMode/need-aproval.svg';
import inspectorDarkMode from './iconsDarkMode/inspector.svg';
import editFileDarkMode from './iconsDarkMode/edit-file.svg';
import paperShredderDarkMode from './iconsDarkMode/paper-shredder.svg';
import addDarkMode from './iconsDarkMode/add.svg';
import CCIcon from './iconsDarkMode/CC.svg';
import paidPetitionFileDarkMode from './iconsDarkMode/paid-petition-file.svg';
import calendarDarkMode from './iconsDarkMode/calendar.svg';
import rectifiedPetitionFileDarkMode from './iconsDarkMode/rectified-petition-file.svg';
import onSiteVisitDarkMode from './iconsDarkMode/on-site-visit.svg';
import jpgDarkMode from './iconsDarkMode/jpg-file.svg';
import pngDarkMode from './iconsDarkMode/png-file.svg';
import jpegDarkMode from './iconsDarkMode/jpeg-file.svg';
import csvDarkMode from './iconsDarkMode/csv-file.svg';
import xlsxDarkMode from './iconsDarkMode/xlsx-file.svg';
import pdfDarkMode from './iconsDarkMode/pdf-file.svg';
import aiDarkMode from './iconsDarkMode/ai-file.svg';
import docxDarkMode from './iconsDarkMode/docx-file.svg';
import zipDarkMode from './iconsDarkMode/zip.svg';
import defaultFileDarkMode from './iconsDarkMode/default-file.svg';
import deleteFileDarkMode from './iconsDarkMode/delete-file.svg';
import invoiceDarkMode from './iconsDarkMode/invoice.svg';
import commercialInvoiceFileDarkMode from './iconsDarkMode/invoice-file.svg';
import petitionFileDarkMode from './iconsDarkMode/petition-file.svg';
import serviceFileDarkMode from './iconsDarkMode/service-file.svg';
import validInvoicesDarkMode from './iconsDarkMode/mark-valid-invoices.svg';
import cancelInvoicesDarkMode from './iconsDarkMode/mark-cancel-invoices.svg';
import plusWhiteDarkMode from './iconsDarkMode/plus-white.svg';
import signatureDarkMode from './iconsDarkMode/signature.svg';
import changePasswordDarkMode from './iconsDarkMode/change-password.svg';
import inspectionListDarkMode from './iconsDarkMode/inspection-lists.svg';
import clockDarkMode from './iconsDarkMode/clock.svg';
import magnifyingGlassDarkMode from './iconsDarkMode/magnifying-glass.svg';
import closedDarkMode from './iconsDarkMode/closed.svg';
import mapDotDarkMode from './iconsDarkMode/map-dot.svg';
import trashDarkMode from './iconsDarkMode/trash.svg';
import clockDetailedDarkMode from './iconsDarkMode/clock-detailed.svg';
import inReviewDarkMode from './iconsDarkMode/review-icon.svg';
import updateProductDarkMode from './iconsDarkMode/update-product.svg';
import roundNotCompliesDarkMode from './iconsDarkMode/round-not-complies.svg';
import roundCompliesDarkMode from './iconsDarkMode/round-complies.svg';
import sendDarkMode from './iconsDarkMode/send.svg';
import taskDocumentDarkMode from './iconsDarkMode/task-document.svg';
import taskStatusDarkMode from './iconsDarkMode/task-status.svg';
import inspectorGreyDarkMode from './iconsDarkMode/inspector-grey.svg';
import checkInDarkMode from './iconsDarkMode/check-in.svg';
import editServiceDarkMode from './iconsDarkMode/edit-service.svg';
import homeDarkMode from './iconsDarkMode/home.svg';
import orderDarkMode from './iconsDarkMode/order.svg';
import filterActiveDarkMode from './iconsDarkMode/filter-active.svg';
import resetFilterDarkMode from './iconsDarkMode/reset-filter.svg';
import filterDarkMode from './iconsDarkMode/filter.svg';
import SELogoDarkMode from './iconsDarkMode/SELogo.svg';
import languaje from './iconsDarkMode/languaje.svg';
import share from './iconsDarkMode/share-dark.svg';
import sound from './iconsDarkMode/sound.svg';
import notSound from './iconsDarkMode/notSound.svg';
import chart from './icons/chart-646.svg';
import download from './icons/download.svg';
import rotateLeftDarkMode from './iconsDarkMode/rotate-left.svg';
import rotateRigthDarkMode from './iconsDarkMode/rotate-rigth.svg';
import zoomInDarkMode from './iconsDarkMode/zoom-in.svg';
import zoomOutDarkMode from './iconsDarkMode/zoom-out.svg';
import tourDarkMode from './iconsDarkMode/tour.svg';

export type IconTypesDarkMode =
  'error404DarkMode'
  | 'editDarkMode'
  | 'uploadDarkMode'
  | 'needAprovalDarkMode'
  | 'inspectorDarkMode'
  | 'editFileDarkMode'
  | 'paperShredderDarkMode'
  | 'addDarkMode'
  | 'paidPetitionFileDarkMode'
  | 'calendarDarkMode'
  | 'rectifiedPetitionFileDarkMode'
  | 'onSiteVisitDarkMode'
  | 'jpgDarkMode'
  | 'pngDarkMode'
  | 'jpegDarkMode'
  | 'csvDarkMode'
  | 'xlsxDarkMode'
  | 'pdfDarkMode'
  | 'aiDarkMode'
  | 'docxDarkMode'
  | 'zipDarkMode'
  | 'defaultFileDarkMode'
  | 'deleteFileDarkMode'
  | 'invoiceDarkMode'
  | 'petitionFileDarkMode'
  | 'serviceFileDarkMode'
  | 'commercialInvoiceFileDarkMode'
  | 'validInvoicesDarkMode'
  | 'cancelInvoicesDarkMode'
  | 'plusWhiteDarkMode'
  | 'signatureDarkMode'
  | 'changePasswordDarkMode'
  | 'inspectionListDarkMode'
  | 'clockDarkMode'
  | 'magnifyingGlassDarkMode'
  | 'closedDarkMode'
  | 'mapDotDarkMode'
  | 'trashDarkMode'
  | 'clockDetailedDarkMode'
  | 'inReviewDarkMode'
  | 'updateProductDarkMode'
  | 'roundNotCompliesDarkMode'
  | 'roundCompliesDarkMode'
  | 'sendDarkMode'
  | 'taskDocumentDarkMode'
  | 'taskStatusDarkMode'
  | 'inspectorGreyDarkMode'
  | 'checkInDarkMode'
  | 'editServiceDarkMode'
  | 'homeDarkMode'
  | 'orderDarkMode'
  | 'filterActiveDarkMode'
  | 'filterDarkMode'
  | 'resetFilterDarkMode'
  | 'SELogoDarkMode'
  | 'languaje'
  | 'share'
  | 'sound'
  | 'notSound'
  | 'chart'
  | 'CCIcon'
  | 'download'
  | 'rotateLeftDarkMode'
  | 'rotateRigthDarkMode'
  | 'zoomInDarkMode'
  | 'zoomOutDarkMode'
  | 'zoomInDarkMode'
  | 'zoomOutDarkMode'
  | 'tourDarkMode';

export default {
  error404DarkMode,
  editDarkMode,
  uploadDarkMode,
  needAprovalDarkMode,
  inspectorDarkMode,
  editFileDarkMode,
  paperShredderDarkMode,
  addDarkMode,
  paidPetitionFileDarkMode,
  calendarDarkMode,
  rectifiedPetitionFileDarkMode,
  onSiteVisitDarkMode,
  jpgDarkMode,
  pngDarkMode,
  jpegDarkMode,
  csvDarkMode,
  xlsxDarkMode,
  pdfDarkMode,
  aiDarkMode,
  docxDarkMode,
  zipDarkMode,
  defaultFileDarkMode,
  deleteFileDarkMode,
  invoiceDarkMode,
  petitionFileDarkMode,
  serviceFileDarkMode,
  commercialInvoiceFileDarkMode,
  validInvoicesDarkMode,
  cancelInvoicesDarkMode,
  plusWhiteDarkMode,
  signatureDarkMode,
  changePasswordDarkMode,
  inspectionListDarkMode,
  clockDarkMode,
  magnifyingGlassDarkMode,
  closedDarkMode,
  mapDotDarkMode,
  trashDarkMode,
  clockDetailedDarkMode,
  inReviewDarkMode,
  updateProductDarkMode,
  roundNotCompliesDarkMode,
  roundCompliesDarkMode,
  sendDarkMode,
  taskDocumentDarkMode,
  taskStatusDarkMode,
  inspectorGreyDarkMode,
  checkInDarkMode,
  editServiceDarkMode,
  homeDarkMode,
  orderDarkMode,
  filterActiveDarkMode,
  filterDarkMode,
  resetFilterDarkMode,
  SELogoDarkMode,
  languaje,
  share,
  sound,
  notSound,
  chart,
  CCIcon,
  download,
  rotateLeftDarkMode,
  rotateRigthDarkMode,
  zoomInDarkMode,
  zoomOutDarkMode,
  tourDarkMode
};
