/* eslint-disable max-len */
const list = {
  documentCode: 'F7-35',
  documentIssueDate: '01/04/2024',
  hasPackingTable: true,
  hasSamplingTable: true,
  hasMeasurementEquipment: true,
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      text: (
        <p className="table-content__row__cell--text">
          5. Información comercial
          <br />
          5.1 Requisitos generales
          <br />
          5.1.1 La información acerca de los productos debe ser veraz y
          describirse y presentarse deforma tal que no induzca a error
          al consumidor con respecto a la naturaleza y características
          de los productos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.2 Información comercial
          <br />
          5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
          deben contener en sus etiquetas, cuando menos, la siguiente información
          comercial obligatoria:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,
      text: (
        <p className="table-content__row__cell--text">
          <b>a) Nombre o denominación genérica del producto, cuando no sea identificable a simple vista por el consumidor.</b>
          <br />
          Un producto es identificable a simple vista si éste está contenido en un empaque que permite ver su contenido; o bien, si el empaque presenta el gráfico del producto, siempre y cuando en este gráfico no aparezcan otros productos no incluidos en el empaque.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,
      text: (
        <p className="table-content__row__cell--text">
          <b>b) Indicación de cantidad</b> conforme a la NOM-030-SCFI-2006 en el entendido de que, si el contenido o número de piezas de un producto puede identificarse a simple vista, no será necesario indicar la declaración de cantidad.
          <br /><br />
          En ese sentido, resultará irrelevante que se indique o no en dichos productos la declaración de cantidad y también la forma en que se haga (en idioma distinto al español, en un sitio distinto a la superficie principal de exhibición, en un tamaño menor al requerido, etc.), siempre y cuando dicha declaración corresponda al producto que la ostente.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>Medidas mínimas para la denominación</td>
                    </tr>
                    <tr>
                      <td>Por superficie principal de exhibición</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-1">...</td>
                    </tr>
                    <tr>
                      <td className="cell-result" id="nom-050-result-2">...</td>
                    </tr>
                  </table>
                </div>
                <br />
                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td>
                        El siguiente producto <b>podría</b> omitir la cantidad ya que se ven a simple vista.
                        <br />En ambos casos el producto cumple.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/indicaci%C3%B3n_de_cantidad.png" alt="Indicación de cantidad" />
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      text: (
        <p className="table-content__row__cell--text">
          En caso de envase múltiple o colectivo, cuyo contenido no sea inidentificable a simple vista, éste debe ostentar la declaración de cantidad (solamente la que corresponde al envase múltiple o colectivo, no la que corresponde a cada uno de los envases de los productos en lo individual), de conformidad con la Norma Oficial Mexicana NOM-030-SCFI-1993 (ver referencias). La descripción de los componentes puede aparecer en la superficie de información y debe incluir el nombre o denominación genérica de los productos, así como su contenido o contenido neto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      text: (
        <p className="table-content__row__cell--text">
          <b>c) Nombre, denominación o razón social y domicilio fiscal</b>, incluyendo código postal, ciudad o estado del fabricante o responsable de la fabricación para productos nacionales o bien del importador.
          <br /><br />
          Para el caso de productos importados, esta información puede incorporarse al producto en territorio nacional, después del despacho aduanero y antes de la comercialización del producto. Dicha información debe ser proporcionada a la Secretaría por el importador a solicitud de ésta. Asimismo, la Secretaría debe proporcionar esta información a los consumidores que así lo soliciten cuando existan quejas sobre los productos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                • Razón social y
                <br />• Domicilio fiscal del responsable del producto de manera enunciativa mas no limitativa:
                <br />◦ Calle,
                <br />◦ Número,
                <br />◦ Código postal
                <br />◦ Entidad federativa en que se encuentre.
                <br />◦ Ciudad
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      text: (
        <p className="table-content__row__cell--text">
          d) La leyenda que identifique al país de origen del producto, por ejemplo Producto de... , Hecho en... , Manufacturado en... , Producido en... , u otros análogos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      text: (
        <p className="table-content__row__cell--text">
          e) Las advertencias de riesgos por medio de leyendas, gráficas o símbolos precautorios en el caso de productos peligrosos.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de leyendas precautorias:
                <br />• Objeto punzo cortante, mantenga fuera del alcance de los niños
                <br />• Este producto está fabricado con materiales plásticos y puede ser susceptible a la combustión si se expone a altas temperaturas, llamas o fuentes de calor extremas
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      text: (
        <p className="table-content__row__cell--text">
          f) Cuando el uso, manejo o conservación del producto requiera de instrucciones, debe presentarse esa información. En caso de que dicha información se encuentre en un instructivo o manual de operación anexo, se debe indicar en la respectiva etiqueta: <b>VEASE INSTRUCTIVO ANEXO O MANUAL DE OPERACION</b>, u otras leyendas análogas, las cuales podrán presentarse indistintamente en mayúsculas, minúsculas o en una combinación de ambas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      text: (
        <p className="table-content__row__cell--text">
          g) Cuando corresponda, la fecha de caducidad o de consumo preferente.
          <br />Nota: Cuando la información comercial obligatoria de la mercancía se encuentre en su envase o empaque de presentación final al público, no será necesario que dicha información también aparezca en la superficie propia de la mercancía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      text: (
        <p className="table-content__row__cell--text">
          5.2.2 Idioma y términos<br />
          La información que se ostente en las etiquetas de los productos debe:
          <br /><br />
          <b>a) Expresarse en idioma español, sin perjuicio de que se exprese también en otros idiomas.</b>
          <br /><br />
          Cuando la información comercial se exprese en otros idiomas debe aparecer también en español, expresarse en términos comprensibles y legibles, de manera tal que el tamaño y tipo de letra permitan al consumidor su lectura a simple vista.
          <br />En todos los casos debe indicarse cuando menos la información establecida en el inciso 5.2.1 de la presente Norma Oficial Mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      text: (
        <p className="table-content__row__cell--text">
          b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan expresar en otros sistemas de unidades.
          <br /><br />
          La información que se exprese en un sistema de unidades distinto al Sistema General de Unidades de Medida puede aparecer después de este último.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>Tabla 1 - Magnitudes y unidades de base del SGUM, y símbolos de las unidades de base</b>
                <br />

                <div>
                  <table className="table-content__inspector-table__table">
                    <tr>
                      <td><b>Magnitudes de base</b></td>
                      <td colSpan={2}><b>Unidades SI de base</b></td>
                    </tr>
                    <tr>
                      <td><b>Nombre</b></td>
                      <td><b>Nombre</b></td>
                      <td><b>Símbolo</b></td>
                    </tr>
                    <tr>
                      <td>tiempo</td>
                      <td>segundo</td>
                      <td>t</td>
                    </tr>
                    <tr>
                      <td>longitud</td>
                      <td>metro</td>
                      <td>m</td>
                    </tr>
                    <tr>
                      <td>masa</td>
                      <td>kilogramo</td>
                      <td>kg</td>
                    </tr>
                    <tr>
                      <td>corriente eléctrica</td>
                      <td>ampere</td>
                      <td>A</td>
                    </tr>
                    <tr>
                      <td>temperatura termodinámica</td>
                      <td>kelvin</td>
                      <td>K</td>
                    </tr>
                    <tr>
                      <td>cantidad de sustancia</td>
                      <td>mol</td>
                      <td>mol</td>
                    </tr>
                    <tr>
                      <td>intensidad luminosa </td>
                      <td>candela</td>
                      <td>cd</td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      text: (
        <p className="table-content__row__cell--text">
          c) Presentarse en etiqueta fijada de manera tal que permanezca disponible hasta el momento de su venta o adquisición en condiciones normales, la cual debe aplicarse en cada unidad o envase múltiple o colectivo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      text: (
        <p className="table-content__row__cell--text">
          c.1) Cuando la forma de presentación del producto al consumidor final sea un envase múltiple o colectivo que no permita ver el contenido, toda la información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo, incorporando la leyenda “No etiquetado para su venta individual”.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                Ejemplo de etiquetado
                <br />
                <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/ejemplo-etiquetado.png" alt="ejemplo-etiquetado" />
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      text: (
        <p className="table-content__row__cell--text">
          c.2) Si la forma de presentación del producto al consumidor final es un envase múltiple o colectivo que permite ver su contenido, la información comercial obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno de los productos preenvasados en lo individual, o bien, una parte de la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante en todos y cada uno de los envases de los productos en lo individual, siempre que la información comercial obligatoria que aparezca en cada uno de los envases de los productos en lo individual, se vea a simple vista desde el exterior del envase múltiple o colectivo, sin necesidad de que este último se abra.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      text: (
        <p className="table-content__row__cell--text">
          c.3) Si los envases múltiples o colectivos se abren y se extraen los productos preenvasados contenidos en ellos con el objeto de destinarlos individualmente a un consumidor final, dichos productos deben contener en lo individual toda la información comercial obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      text: (
        <p className="table-content__row__cell--text">
          d) Estar colocada en la superficie principal de exhibición, tratándose al menos de la siguiente información:
          <br />i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a),
          <br />ii) Declaración de cantidad.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      text: (
        <p className="table-content__row__cell--text">
          5.3 Instructivos o manuales de operación y garantías
          <br />5.3.1 Idioma
          <br />Los instructivos o manuales de operación y garantías deben expresarse en idioma español y de acuerdo al Sistema General de Unidades de Medida, sin perjuicio de que además se expresen en otros idiomas y sistemas de unidades (NOM-008-SCFI-2002). Cuando la información se exprese en otros idiomas, debe aparecer también en idioma español, cuidando que por lo menos sea con el mismo tamaño.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      text: (
        <p className="table-content__row__cell--text">
          5.3.2 Contenido
          <br />Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o conservación requiera de instrucciones, deben ir acompañados, sin cargo adicional para el consumidor, de los instructivos o manuales de operación y, en su caso, garantías, los cuales deben contener indicaciones claras y precisas para el uso normal, manejo, conservación, ensamble y aprovechamiento de los productos, así como las advertencias para el manejo seguro y confiable de los mismos.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social del productor nacional, o importador, domicilio fiscal y teléfono de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      text: (
        <p className="table-content__row__cell--text">
          c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      text: (
        <p className="table-content__row__cell--text">
          d) Cuando proceda, las indicaciones para su instalación, conexión, ensamble o mantenimiento para su adecuado funcionamiento.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      isATitle: true,
      text: (
        <p className="table-content__row__cell--text">
          5.3.3 Garantías
          <br />Cuando se ofrezcan garantías, éstas deben expedirse en los términos y forma establecidos en la Ley Federal de Protección al Consumidor e indicar y cumplir con lo siguiente:
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      text: (
        <p className="table-content__row__cell--text">
          a) Nombre, denominación o razón social y domicilio fiscal del productor nacional o importador del producto y teléfonos de servicio en territorio nacional.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      text: (
        <p className="table-content__row__cell--text">
          b) Identificación de los productos y/o modelos a los que aplica.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      text: (
        <p className="table-content__row__cell--text">
          c) Nombre y domicilio del establecimiento en la República Mexicana donde puede hacerse efectiva la garantía en los términos de la misma, así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
          <br />
          Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva en cualquiera de las sucursales a nivel nacional del proveedor que la ofrezca, sin necesidad de especificar los domicilios de las mismas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      text: (
        <p className="table-content__row__cell--text">
          d) Duración de la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      text: (
        <p className="table-content__row__cell--text">
          e) Conceptos que cubre la garantía y limitaciones o excepciones.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      text: (
        <p className="table-content__row__cell--text">
          f) Procedimiento para hacer efectiva la garantía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      text: (
        <p className="table-content__row__cell--text">
          g) Precisar la fecha en que el consumidor recibió el producto o indicar los documentos de referencia donde ésta se señale.
          <br />
          Es responsabilidad del comerciante asegurarse que esta información esté presente al momento de la venta del producto al consumidor, de no hacerlo así, el comerciante debe cumplir con los términos de la garantía directamente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      text: (
        <p className="table-content__row__cell--text">
          h) Para hacer efectiva la garantía no pueden exigirse otros requisitos más que la presentación del producto, la garantía vigente y comprobante de venta.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      text: (
        <p className="table-content__row__cell--text">
          5.3.4 Incorporación de los instructivos o manuales de operación y garantías En todos los casos, los instructivos o manuales de operación y garantías deben entregarse al consumidor en el establecimiento comercial cuando adquiera los productos.
        </p>
      )
    }
  ]
};

export default {
  DC: {
    documentDescription: 'F7-35 Lista de inspección para Dictamen para la NOM-050-SCFI-2004',
    ...list
  },
  CC: {
    documentDescription: 'F7-35 Lista de inspección para Constancia para la NOM-050-SCFI-2004',
    ...list,
    hasSamplingTable: false
  },
  REV: {
    documentDescription: 'F7-35 Lista de inspección para Revision para la NOM-050-SCFI-2004',
    ...list,
    hasSamplingTable: false
  }
};
