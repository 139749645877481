import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Input,
  ModalView
} from '../../../../components';

import { useResource } from '../../../../hooks';
import {
  Client,
  ClientAddress
} from '../../../../interfaces';

import '../ClientForm/styles.scss';
import { emptyClientAddress } from '../../../../emptyObjects';
import { validate } from '../../../../helpers';

interface Props {
  visible: boolean
  onClose: () => void
  onSuccess: (_data: Client) => void
  onError: (_error: string) => void
  title: string
  clientID: string
  clientAddress?: ClientAddress
}

const InspectionAddressForm: React.FC<Props> = (props) => {
  const {
    visible = false,
    onClose = () => { },
    onSuccess = (_data: Client) => { },
    onError = (_error: string) => { },
    clientID,
    clientAddress = emptyClientAddress
  } = props;

  const { t } = useTranslation();

  const {
    createResource,
    updateResource
  } = useResource<Client>();

  const [
    formInspectionAddress,
    setFormInspectionAddress
  ] = useState<ClientAddress>(clientAddress);

  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const onChangeInput = (value: string | number, id: string) => {
    if (id === 'postalCode') {
      if (String(value).length <= 5) {
        setFormInspectionAddress({
          ...formInspectionAddress,
          postalCode: String(value)
        });
      }
    } else {
      setFormInspectionAddress({
        ...formInspectionAddress,
        [id]: value
      });
    }
  };

  const validData = (): boolean => {
    if (validate.text(formInspectionAddress.street, 1) !== 'success') {
      setErrors({
        street: t('clients.errors.fiscalAddressStreet')
      });

      return false;
    }
    if (validate.text(formInspectionAddress.number, 1) !== 'success') {
      setErrors({
        number: t('clients.errors.fiscalAddressNumber')
      });

      return false;
    }
    if (validate.text(formInspectionAddress.neighborhood, 1) !== 'success') {
      setErrors({
        neighborhood: t('clients.errors.fiscalAddressNeighborhood')
      });

      return false;
    }
    if (validate.text(formInspectionAddress.postalCode, 1, 5) !== 'success') {
      setErrors({
        postalCode: validate.text(formInspectionAddress.postalCode, 1, 5)
      });

      return false;
    }
    if (formInspectionAddress.postalCode.length !== 5) {
      setErrors({
        postalCode: t('clients.errors.fiscalAddressPostalCode')
      });

      return false;
    }
    if (validate.number(formInspectionAddress.postalCode) !== 'success') {
      setErrors({
        postalCode: validate.number(formInspectionAddress.postalCode)
      });

      return false;
    }
    if (validate.text(formInspectionAddress.city) !== 'success') {
      setErrors({
        city: t('clients.errors.fiscalAddressCity')
      });

      return false;
    }
    if (validate.text(formInspectionAddress.state) !== 'success') {
      setErrors({
        state: t('clients.errors.fiscalAddressState')
      });

      return false;
    }
    if (validate.text(formInspectionAddress.country) !== 'success') {
      setErrors({
        country: t('clients.errors.fiscalAddressCountry')
      });

      return false;
    }

    return true;
  };

  const handleCreateVerifficationAddress = () => {
    if (validData()) {
      createResource(
        `admin/clients/${clientID}/inspection-address`,
        {
          street: formInspectionAddress.street,
          number: formInspectionAddress.number,
          neighborhood: formInspectionAddress.neighborhood,
          city: formInspectionAddress.city,
          postalCode: formInspectionAddress.postalCode,
          state: formInspectionAddress.state,
          country: formInspectionAddress.country
        },
        (data: Client) => {
          setFormInspectionAddress(clientAddress);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  const handleUpdateVerifficationAddress = () => {
    if (validData()) {
      updateResource(
        `admin/clients/${clientID}/inspection-address/${formInspectionAddress.id}`,
        {
          street: formInspectionAddress.street,
          number: formInspectionAddress.number,
          neighborhood: formInspectionAddress.neighborhood,
          city: formInspectionAddress.city,
          postalCode: formInspectionAddress.postalCode,
          state: formInspectionAddress.state,
          country: formInspectionAddress.country
        },
        (data: Client) => {
          setFormInspectionAddress(clientAddress);
          onSuccess(data);
        },
        (error: string) => {
          onError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (clientAddress) {
      setFormInspectionAddress(clientAddress);
    }
  }, [clientAddress]);

  useEffect(() => {
    setErrors({});
  }, [visible]);

  return (
    <ModalView
      onClose={onClose}
      onConfirm={() => {
        if (clientAddress.id !== '') {
          handleUpdateVerifficationAddress();
        } else {
          handleCreateVerifficationAddress();
        }
      }}
      visible={visible}
      customComponent={
        <div className="client-form">
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.street') || ''}
              type="text"
              value={formInspectionAddress.street}
              id='street'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Matamoros`}
              hasError={!!errors.street}
              helperText={errors.street}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.extNumber') || ''}
              type="text"
              value={formInspectionAddress.number}
              id='number'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: 34 B`}
              hasError={!!errors.number}
              helperText={errors.number}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.cologne') || ''}
              type="text"
              value={formInspectionAddress.neighborhood}
              id='neighborhood'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Las Lomas`}
              hasError={!!errors.neighborhood}
              helperText={errors.neighborhood}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.postalCode') || ''}
              type="number"
              value={formInspectionAddress.postalCode}
              id='postalCode'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: 47180`}
              hasError={!!errors.postalCode}
              helperText={errors.postalCode}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.city') || ''}
              type="text"
              value={formInspectionAddress.city}
              id='city'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Guadalajara`}
              hasError={!!errors.city}
              helperText={errors.city}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.state') || ''}
              type="text"
              value={formInspectionAddress.state}
              id='state'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Jalisco`}
              hasError={!!errors.state}
              helperText={errors.state}
            />
          </div>
          <div className="client-form--one-thirds">
            <Input
              title={t('clients.country') || ''}
              type="text"
              value={formInspectionAddress.country}
              id='country'
              onChange={onChangeInput}
              placeholder={`${t('global.example')}: Mexico`}
              hasError={!!errors.country}
              helperText={errors.country}
            />
          </div>
        </div>
      }
    />
  );
};

export default InspectionAddressForm;
