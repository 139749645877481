/* eslint-disable max-len */
import React, {
  useContext,
  useRef,
  useState,
  useId,
  useEffect
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import SignatureCanvas from 'react-signature-canvas';

import { useParams } from 'react-router-dom';
import { useResource, useNavigate } from '../../../../hooks';
import {
  FileInputData,
  FileToSign,
  SignedFile,
  Task
} from '../../../../interfaces';

import {
  Button,
  Header,
  Input,
  Modal,
  File as FileElement,
  Spinner,
  ModalView,
  PDFViewer
} from '../../..';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';
import { emptyTask } from '../../../../emptyObjects';

interface WitnessData {
  data: any,
  name: string
}

interface Witness {
  name: string
  signature: string | any
  fileInputData: FileInputData
}

export const WitnessesInformation = () => {
  const params = useParams();
  const canvasID = useId();

  const {
    updateResource,
    fetchResource
  } = useResource<Task>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    createResource: getDictumInspectionCertificateDocument
  } = useResource<any>();

  const {
    createResource: sendTaskDocuments
  } = useResource<any>();

  const {
    setOpenModal,
    soundEffects,
    setTaskIDModalAfterActions
  } = useContext(AppContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [witness1, setWitness1] = useState<Witness>({
    name: '',
    signature: '',
    fileInputData: {
      data: {},
      fileName: ''
    }
  });
  const [witness2, setWitness2] = useState<Witness>({
    name: '',
    signature: '',
    fileInputData: {
      data: {},
      fileName: ''
    }
  });
  const [inspector, setInspector] = useState({
    name: '',
    signature: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});
  const [pdfBlob, setPdfBlob] = useState<any[]>([]);
  const [currentWitness, setCurrentWitness] = useState<number>(1);
  const [showSignature, setShowSignature] = useState<boolean>(false);
  const [showInspectorSignature, setShowInspectorSignature] = useState<boolean>(false);
  const sigCanvas = useRef<any>();
  const [task, setTask] = useState<Task>(emptyTask);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');

  const validEmails = (): boolean => {
    if (emails.length === 0) {
      setErrors({
        emails: t('global.dataError')
      });
      return false;
    }

    return true;
  };

  const handleSendTaskDocuments = () => {
    if (validEmails()) {
      sendTaskDocuments(
        `admin/tasks/send-task-documents/${task.id}`,
        {
          type: 'certificate',
          emails
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('global.sendedFiles'),
            setOpenModal,
            () => setShowModal(false),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const handleShowInspectionCertificateDocument = async () => {
    setPdfBlob([]);
    Modal.fireLoading(setOpenModal);
    // eslint-disable-next-line array-callback-return
    await Promise.all(task.services.map((service) => {
      getDictumInspectionCertificateDocument(
        `/admin/tasks/${task.id}/inspection-certificate`,
        {
          petitionNumber: service
        },
        (data) => {
          const bytes = new Uint8Array(data.file.data);

          const blob = new Blob([bytes], { type: 'application/pdf' });

          setPdfBlob([...pdfBlob, blob]);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }));
    Modal.close(setOpenModal);
  };

  useEffect(() => {
    fetchResource(
      `/admin/tasks/${params.taskID}`,
      (data) => {
        setTask(data);
        setInspector({
          ...inspector,
          name: data.responsible.name
        });
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, [params.taskID]);

  useEffect(() => {
    handleShowInspectionCertificateDocument();
  }, [task]);

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const uploadSigns = () => {
    if (currentWitness === 1) {
      if (witness1.name === '') {
        setErrors({
          'name-1': t('tasks.errors.witnessName1')
        });

        return;
      }

      setShowSignature(true);

      if (witness1.fileInputData.fileName === '') {
        if (sigCanvas.current.isEmpty()) {
          Modal.fireError(t('tasks.errors.sign', { witnessNumber: currentWitness }), undefined, soundEffects);

          return;
        }

        setWitness1({
          ...witness1,
          signature: sigCanvas.current.toDataURL('image/png')
        });

        sigCanvas.current.clear();
      }

      setCurrentWitness(2);
      setShowSignature(false);
    } else if (currentWitness === 2) {
      if (witness2.name === '') {
        setErrors({
          'name-2': t('tasks.errors.witnessName2')
        });

        return;
      }

      setShowSignature(true);

      if (witness2.fileInputData.fileName === '') {
        if (sigCanvas.current.isEmpty()) {
          Modal.fireError(t('tasks.errors.sign', { witnessNumber: currentWitness }), undefined, soundEffects);

          return;
        }

        setWitness2({
          ...witness2,
          signature: sigCanvas.current.toDataURL('image/png')
        });
      } else {
        setShowInspectorSignature(true);
      }
    }
  };

  useEffect(() => {
    setTaskIDModalAfterActions(params.taskID || '');
  }, [params.taskID]);

  useEffect(() => {
    if (witness2.signature !== '' && witness2.name !== '') {
      setShowInspectorSignature(true);
    }
  }, [witness2]);

  const uploadSignsDB = () => {
    const paths: FileToSign[] = [];
    const witnessData: WitnessData[] = [];

    if (witness1.signature !== '' && witness1.name !== '') {
      paths.push({
        path: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, ''),
        type: 'image/png',
        documentName: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
      witnessData.push({
        data: dataURLtoBlob(witness1.signature),
        name: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
    }

    if (witness1.fileInputData.fileName !== '' && witness1.name !== '') {
      paths.push({
        path: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, ''),
        type: 'image/png',
        documentName: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
      witnessData.push({
        data: dataURLtoBlob(witness1.fileInputData.data),
        name: `${witness1.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
    }

    if (witness2.signature !== '' && witness2.name !== '') {
      paths.push({
        path: `${witness2.name}.png`.replace(/[%&?¿=#/+]/g, ''),
        type: 'image/png',
        documentName: `${witness2.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
      witnessData.push({
        data: dataURLtoBlob(witness2.signature),
        name: `${witness2.name}.png`.replace(/[%&?¿=#/+]/g, '')
      });
    }

    if (witness2.fileInputData.fileName !== '' && witness2.name !== '') {
      paths.push({
        path: `${witness2.name}.png`.replace(/[%&?¿=#/+]/g, ''),
        type: 'image/png',
        documentName: `${witness2.name.replace(/[%&?¿=.#/+]/g, '')}.png`
      });
      witnessData.push({
        data: dataURLtoBlob(witness2.fileInputData.data),
        name: `${witness2.name.replace(/[%&?¿=.#/+]/g, '')}.png`
      });
    }

    paths.push({
      path: `${inspector.name}__inspector.png`.replace(/[%&?¿=#/+]/g, ''),
      type: 'image/png',
      documentName: `${inspector.name.replace(/[%&?¿=.#/+]/g, '')}.png`
    });
    witnessData.push({
      data: dataURLtoBlob(sigCanvas.current.toDataURL('image/png')),
      name: `${inspector.name.replace(/[%&?¿=.#/+]/g, '')}__inspector.png`
    });

    registerUploadedFiles(
      `/admin/tasks/${params.taskID}/documents/witness`,
      {
        filePaths: paths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const file = witnessData.find((witnessDataItem) => witnessDataItem.name === item.documentName);

            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/admin/tasks/${params.taskID}/documents-paths/witness`,
            {
              filePaths: uploadedPaths
            },
            () => {
              Modal.fireSuccess(
                t('global.correct'),
                t('tasks.savedSigns'),
                setOpenModal,
                () => navigate(-1),
                soundEffects
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const showCanvas = (): boolean => {
    let result: boolean = false;

    if (currentWitness === 1) {
      result = witness1.name !== '' && witness1.fileInputData.fileName === '' && showSignature;
    }

    if (currentWitness === 2) {
      result = witness1.name !== '' && witness2.fileInputData.fileName === '' && showSignature;
    }

    return result;
  };

  const showFileUploaded = (): boolean => {
    let result: boolean = false;

    if (currentWitness === 1) {
      result = witness1.fileInputData.fileName !== '';
    }

    if (currentWitness === 2) {
      result = witness2.fileInputData.fileName !== '';
    }

    return result;
  };

  if (showInspectorSignature) {
    return (
      <div className='witnesses' >
        <Header
          title={t('tasks.sings') || ''}
          showBackbutton={true}
        />
        <div className='witnesses__content'>
          <p className='witnesses__content__title'>
            {t('tasks.inspectorSign')}
          </p>
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ className: 'witnesses__content__canvas-draw' }}
            key={canvasID}
          />
          <div className='witnesses__content__action-buttons'>
            <Button
              type='secondary-outlined'
              onClick={() => sigCanvas.current.clear()}
              label='Limpiar'
            />
            <Button
              type='primary'
              onClick={uploadSignsDB}
              label={t('global.save') || ''}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='witnesses' >
      <ModalView
        visible={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleSendTaskDocuments}
        customComponent={
          <div className='dictum-service-detail__assing-out-of-customs-date'>
            <Input
              title={t('global.emails') || ''}
              type="text"
              value={emails}
              onChange={(_value: string | number, _id: string) => {
                setEmails(`${_value}`.toLocaleLowerCase());
              }}
              hasError={!!errors.emails}
              helperText={errors.emails || ''}
            />
          </div>
        }
        fullSzie={false}
      />
      <Header
        title={t('tasks.sings') || ''}
        showBackbutton={true}
      />
      <div className='witnesses__content'>
        <p className='witnesses__content__title'>
          {t('tasks.witnessSigns1')}
        </p>
        <p className='witnesses__content__sub-title'>
          {t('tasks.witnessSigns2')}
        </p>
        <div className='witnesses__content__loading-page'>
          {
            pdfBlob.length > 0 ? (
              <>
                {
                  pdfBlob.map((item) => (
                    <PDFViewer
                      pdfUrl={`${URL.createObjectURL(item)}#toolbar=0`}
                    />
                  ))
                }
              </>
            ) : (
              <>
                <Spinner />
                <p>{t('global.loadingFiles')}</p>
              </>
            )
          }
          <Button
            type={'secondary-outlined'}
            onClick={() => setShowModal(true)}
            label={t('global.sendFiles') || ''}
            icon='sendEmail'
          />
        </div>
        {
          showCanvas() && (
            <>
              <p className='witnesses__content__sign-title'>{t('tasks.witnessSign')} {currentWitness}</p>
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'witnesses__content__canvas-draw' }}
                key={canvasID}
              />
              <div className='witnesses__content__action-buttons'>
                <Button
                  type='secondary-outlined'
                  onClick={() => sigCanvas.current.clear()}
                  label='Limpiar'
                />
              </div>
              {/* <SubTitle subTitle={t('global.orUploadSign')} />
              <FileInput
                onSelectFile={(file: FileInputData[]) => {
                  if (currentWitness === 1) {
                    setWitness1({
                      ...witness1,
                      fileInputData: file[0]
                    });
                  } else if (currentWitness === 2) {
                    setWitness2({
                      ...witness2,
                      fileInputData: file[0]
                    });
                  }
                }}
                acceptedFileFormats=".png"
                isMultiple={false}
              /> */}
            </>
          )
        }
        {
          showFileUploaded() && (
            <div className='witnesses__content__sign-file'>
              <FileElement
                name={currentWitness === 1
                  ? witness1.fileInputData.fileName
                  : witness2.fileInputData.fileName
                }

                disabled={false}
                onDelete={() => {
                  if (currentWitness === 1) {
                    setWitness1({
                      ...witness1,
                      fileInputData: {
                        fileName: '',
                        data: {}
                      }
                    });
                  } else if (currentWitness === 2) {
                    setWitness2({
                      ...witness2,
                      fileInputData: {
                        fileName: '',
                        data: {}
                      }
                    });
                  }
                }}
              />
            </div>
          )
        }
        <div className='witnesses__content__sign-list'>
          {
            currentWitness === 1 && (
              <div className='witnesses__content__sign-list__item'>
                <Input
                  title={`${t('tasks.witness')}1`}
                  placeholder={`${t('global.example')}: María Morales Rizo`}
                  type='text'
                  value={witness1.name}
                  onChange={(value: string | number) => {
                    setWitness1({
                      ...witness1,
                      name: String(value)
                    });

                    setErrors({});
                  }}
                  disabled={witness2.signature !== '' || witness2.name !== '' || showCanvas() || showFileUploaded()}
                  hasError={!!errors['name-1']}
                  helperText={errors['name-1'] || t('tasks.errors.witnessFullName') || ''}
                  key={1}
                />
              </div>
            )
          }
          {
            currentWitness === 2 && (
              <div className='witnesses__content__sign-list__item'>
                <Input
                  title={`${t('tasks.witness')}2`}
                  placeholder={`${t('global.example')}: María Morales Rizo`}
                  type='text'
                  value={witness2.name}
                  onChange={(value: string | number) => {
                    setWitness2({
                      ...witness2,
                      name: String(value)
                    });

                    setErrors({});
                  }}
                  hasError={!!errors['name-2']}
                  helperText={errors['name-2'] || t('tasks.errors.witnessFullName') || ''}
                  disabled={showCanvas() || showFileUploaded()}
                  key={2}
                />
              </div>
            )
          }
        </div>
        <div className='witnesses__content__footer-buttons'>
          {
            showSignature && (
              <Button
                type='secondary-outlined'
                onClick={() => {
                  setShowSignature(false);
                  if (currentWitness === 1) {
                    setWitness1({
                      ...witness1,
                      fileInputData: {
                        fileName: '',
                        data: {}
                      }
                    });
                  } else if (currentWitness === 2) {
                    setWitness2({
                      ...witness2,
                      fileInputData: {
                        fileName: '',
                        data: {}
                      }
                    });
                  }
                }}
                label={t('global.back') || ''}
              />
            )
          }
          {
            currentWitness === 2
            && showCanvas() === false && (
              <Button
                type='primary'
                onClick={() => setShowInspectorSignature(true)}
                label={t('tasks.whitoutwitness2') || ''}
              />
            )
          }
          <Button
            type='primary'
            onClick={uploadSigns}
            label={t('global.save') || ''}
          />
        </div>
      </div>
    </div >
  );
};

export default WitnessesInformation;
