import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataDisplay } from '../../../../components';
import { Client } from '../../../../interfaces';

interface Props {
  client: Client
}

export const GeneralInfo: React.FC<Props> = (props) => {
  const {
    client
  } = props;

  const { t } = useTranslation();

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={[
        {
          label: t('clients.shortName'),
          value: client.shortName,
          format: 'none'
        },
        {
          label: t('clients.bussinessName'),
          value: client.businessName,
          format: 'none'
        },
        {
          label: t('global.status.title'),
          value: client.status,
          format: 'client-status'
        },
        {
          label: 'RFC',
          value: client.rfc,
          format: 'none'
        },
        {
          label: t('clients.personType'),
          value: client.personType,
          format: 'person-type'
        },
        {
          label: t('clients.contractNumber'),
          value: client.contractNumber,
          format: 'none'
        },
        {
          label: t('clients.contractDate'),
          value: client.contractDate,
          format: 'date'
        },
        {
          label: t('clients.legalRepresentative'),
          value: client.legalRepresentative,
          format: 'none'
        },
        {
          label: t('clients.clientType'),
          value: client.nationalityType,
          format: 'nationality-type'
        },
        {
          label: t('global.signedContract'),
          value: client.signedContract,
          format: 'boolean'
        },
        {
          label: t('clients.taxSystem'),
          value: client.tax_system,
          format: 'taxSystem'
        },
        {
          label: t('clients.creditDays'),
          value: client.creditDays,
          format: 'none'
        }
      ]}
    />
  );
};

export default GeneralInfo;
