import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  Separator,
  ConstancyProductForm,
  Switch
} from '../../../../../../components';
import { AppContext } from '../../../../../../context/AppContext';
import { emptyConstancyProduct } from '../../../../../../emptyObjects';
import { useNavigate, useResource } from '../../../../../../hooks';
import { ConstancyProduct, ConstancyService } from '../../../../../../interfaces';
import CSVForm from './components';
import './styles.scss';

interface ProductPayload {
  normCode: string
  fase: string
  code: string
  brand: string
  presentation: string
  description: string
  package: string
  long: string
  high: string
  radius: string
}

interface Props {
  clientID: string
  collaboratorID: string
  constancyServiceID: string
  preloadedProduct?: ConstancyProduct
  onChangeCurrentComponent: (_value: 'product-list') => void
}

export const ProductForm: React.FC<Props> = (props) => {
  const {
    createResource,
    updateResource
  } = useResource<ConstancyProduct | ConstancyProduct[]>();

  const navigate = useNavigate();

  const {
    clientID,
    collaboratorID,
    preloadedProduct = emptyConstancyProduct,
    onChangeCurrentComponent
  } = props;

  const { t } = useTranslation();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [currentForm, setCurrentForm] = useState<'manual-form' | 'csv-form' | 'none'>('manual-form');
  const [constancyServiceID, setConstancyServiceID] = useState<string>(props.constancyServiceID);

  const buildBody = (serviceID: string, products: ProductPayload[]): any => {
    if (apiType === 'admin') {
      return {
        clientID,
        collaboratorID,
        constancyServiceID: serviceID,
        products
      };
    }

    return {
      constancyServiceID: serviceID,
      products
    };
  };

  const handleChangeProductInputMethod = (value: string | number) => {
    setCurrentForm(value as 'manual-form' | 'csv-form' | 'none');
  };

  const handleCreateConstacyService = (): Promise<ConstancyService> => {
    return new Promise((resolve, reject) => {
      createResource(
        '/public/constancy-services',
        {},
        (data: any) => {
          navigate(`/constancy-request/step_2/${data.id}/${data.requestNumber}/${data.client.id}/${data.collaborator.id}`);
          setConstancyServiceID(data.id);
          resolve(data);
        },
        (error: string) => reject(error)
      );
    });
  };

  const handleCreateSingleProduct = (
    serviceID: string,
    product: ConstancyProduct
  ): Promise<ConstancyProduct> => {
    return new Promise((resolve, reject) => {
      createResource(
        `/${apiType}/constancy-products`,
        buildBody(
          serviceID,
          [
            {
              ...product,
              package: product.indicators.package,
              long: product.indicators.long,
              high: product.indicators.high,
              radius: product.indicators.radio
            }
          ]
        ),
        (data: ConstancyProduct | ConstancyProduct[]) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.registeredProduct'),
            setOpenModal,
            () => onChangeCurrentComponent('product-list'),
            soundEffects
          );

          resolve((data as ConstancyProduct[])[0]);
        },
        (error: string) => reject(error)
      );
    });
  };

  const handleCreateManyProducts = (
    products: ProductPayload[],
    serviceID: string
  ): Promise<ConstancyProduct[]> => {
    return new Promise((resolve, reject) => {
      createResource(
        `/${apiType}/constancy-products`,
        buildBody(serviceID, products),
        (data: ConstancyProduct | ConstancyProduct[]) => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.registeredProducts'),
            setOpenModal,
            () => onChangeCurrentComponent('product-list'),
            soundEffects
          );
          resolve((data as ConstancyProduct[]));
        },
        (error: string) => reject(error)
      );
    });
  };

  const handleUpdateProduct = async (product: ConstancyProduct) => {
    updateResource(
      `/${apiType}/constancy-products/${product.id}`,
      product,
      (_data: ConstancyProduct | ConstancyProduct[]) => {
        Modal.fireSuccess(
          t('global.correct'),
          t('services.updatedProduct'),
          setOpenModal,
          () => onChangeCurrentComponent('product-list'),
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleCreateProductAndServiceIfDoesntExist = async (product: ConstancyProduct) => {
    try {
      if (constancyServiceID === 'none' && apiType === 'public') {
        const service = await handleCreateConstacyService();
        await handleCreateSingleProduct(service.id, product);
      } else {
        await handleCreateSingleProduct(constancyServiceID, product);
      }
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  const handleCreateProductsAndServiceIfDoesntExist = async (products: ProductPayload[]) => {
    try {
      if (constancyServiceID === 'none' && apiType === 'public') {
        const service = await handleCreateConstacyService();
        await handleCreateManyProducts(products, service.id);
      } else {
        await handleCreateManyProducts(products, constancyServiceID);
      }
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  const formType = {
    'manual-form': (<ConstancyProductForm
      preloadedProduct={preloadedProduct}
      onCreateProduct={(product: ConstancyProduct) => {
        handleCreateProductAndServiceIfDoesntExist(product);
      }}
      onUpdateProduct={(product: ConstancyProduct) => {
        handleUpdateProduct(product);
      }}
    />),
    'csv-form': (<CSVForm onCreateProducts={handleCreateProductsAndServiceIfDoesntExist} />),
    none: (<p>{t('services.creationproductMethod')}</p>)
  };

  return (
    <div className='product-form'>
      <div className='product-form__top-container'>
        <Switch
          leftLabel={{
            text: t('services.creationproductMethodForm'),
            value: 'manual-form'
          }}
          rigthLabel={{
            text: t('services.creationproductMethodTemplate'),
            value: 'csv-form'
          }}
          onChange={handleChangeProductInputMethod}
        />
        <br />
        <Separator orientation='horizontal' />
        <br />
      </div>
      {formType[currentForm]}
      <br />
      <Button
        onClick={() => onChangeCurrentComponent('product-list')}
        type='primary'
        label={t('global.list') || ''}
        icon='leftArrow'
        iconPosition='left'
      />
    </div>
  );
};

export default ProductForm;
