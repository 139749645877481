import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { useResource } from '../../hooks';

import {
  Accordeon,
  ActionButtons,
  Button,
  Header,
  LateralMenu,
  Modal
} from '../../components';

import {
  ActionButton,
  Client,
  Collaborator,
  LateralMenuOption
} from '../../interfaces';

import './styles.scss';

import {
  GeneralInfo,
  Contact,
  FiscalAddress,
  InspectionAdresses,
  Collaborators,
  ClientForm,
  CollaboratorForm,
  InspectionAddressForm
} from './components';

import { emptyClient } from '../../emptyObjects';
import { AppContext } from '../../context/AppContext';
import configuration from '../../configuration';

type ClientDetailContentType = 'info' | 'contact' | 'fiscal-adress' | 'inspection-adresses' | 'collaborators';

const ClientDetail = () => {
  const params = useParams();
  const { clientID } = params;

  const {
    fetchResources: fetchCollaborators
  } = useResource<Collaborator>();

  const {
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  const [buttons, setButtons] = useState<ActionButton[]>([]);
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [client, setClient] = useState<Client>(emptyClient);
  const [lateralMenuValue, setLateralMenuValue] = useState<ClientDetailContentType>('info');
  const [editClientModalVisible, setEditClientModalVisible] = useState<boolean>(false);
  const [
    createCollaboratorModalVisible,
    setCreateCollaboratorModalVisible
  ] = useState<boolean>(false);
  const [
    createVerifficationAddessModalVisible,
    setCreateVerifficationAddessModalVisible
  ] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const {
    fetchResource
  } = useResource<Client>();

  const lateralMenuOptions: LateralMenuOption[] = [
    {
      label: t('services.generalInformation'),
      value: 'info'
    },
    {
      label: t('clients.legalRepresentative'),
      value: 'contact'
    },
    {
      label: t('clients.fiscalAddress'),
      value: 'fiscal-adress'
    },
    {
      label: t('clients.adresses'),
      value: 'inspection-adresses'
    },
    {
      label: t('clients.collaborators'),
      value: 'collaborators'
    }
  ];

  const getContent = useCallback(() => {
    const contents = {
      info: (<GeneralInfo client={client} />),
      contact: (<Contact client={client} />),
      'fiscal-adress': (<FiscalAddress client={client} />),
      'inspection-adresses': (<InspectionAdresses client={client} onChangeClient={setClient} />),
      collaborators: (<Collaborators clientID={client.id} collaborators={collaborators} />)
    };

    return contents[lateralMenuValue];
  }, [lateralMenuValue, client, collaborators]);

  useEffect(() => {
    if (clientID && clientID !== 'none') {
      fetchResource(
        `/admin/clients/${clientID}`,
        (data) => {
          setClient(data);
        },
        (error) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [clientID]);

  useEffect(() => {
    if (lateralMenuValue === 'collaborators') {
      fetchCollaborators(
        {
          resourcePath: '/admin/collaborators',
          filters: { s_clientID: client.id }
        },
        (data) => {
          setCollaborators(data.items);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [client, refetch, lateralMenuValue]);

  useEffect(() => {
    setButtons([
      {
        button: (
          <Button
            onClick={() => {
              setModalTitle(t('clients.editClientButton') || '');
              setEditClientModalVisible(true);
            }}
            type='primary-outlined'
            label={t('clients.editClientButton') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='edit'
            alignContent='left'
          />
        ),
        available: ['master', 'finance'].includes(userRole),
        specialValidation: ['master', 'finance'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              setModalTitle('Registrar dirección de inspección');
              setCreateVerifficationAddessModalVisible(true);
            }}
            type='primary-outlined'
            label={t('clients.addAddressess') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='add'
            alignContent='left'
          />
        ),
        available: ['master', 'finance'].includes(userRole),
        specialValidation: ['master', 'finance'].includes(userRole)
      },
      {
        button: (
          <Button
            onClick={() => {
              setModalTitle('Registrar colaborador');
              setCreateCollaboratorModalVisible(true);
            }}
            type='primary-outlined'
            label={t('clients.createCollaborator') || ''}
            iconPosition='left'
            fullWidth={true}
            size='big'
            icon='add'
            alignContent='left'
          />
        ),
        available: ['master', 'finance'].includes(userRole),
        specialValidation: ['master', 'finance'].includes(userRole)
      }
    ]);
  }, [client, i18n.language]);

  return (
    <div className="client-service-detail">
      <Header
        title={t('clients.titleS')}
        showBackbutton={true}
      />
      <div className="client-service-detail__main">
        <div className="client-service-detail__left-container">
          <Accordeon
            items={[
              {
                title: 'Menu',
                element: (
                  <div >
                    <LateralMenu
                      value="info"
                      onSelect={(value: string) => {
                        setLateralMenuValue((value as ClientDetailContentType));
                      }}
                      options={lateralMenuOptions}
                    />
                  </div>
                )
              },
              {
                title: t('tasks.actions'),
                element: (
                  <ActionButtons buttons={buttons} />
                )
              }
            ]}
          />
        </div>
        <ClientForm
          visible={editClientModalVisible}
          title={modalTitle}
          client={client}
          onClose={() => {
            setEditClientModalVisible(false);
          }}
          onSuccess={(_client: Client) => {
            setEditClientModalVisible(false);

            Modal.fireSuccess(
              t('global.correct'),
              t('clients.updatedClient'),
              setOpenModal,
              () => {
                setClient(_client);
              },
              soundEffects
            );
          }}
          onError={(error: string) => {
            setEditClientModalVisible(false);
            Modal.fireError(error, setOpenModal, soundEffects);
          }}
        />
        <CollaboratorForm
          visible={createCollaboratorModalVisible}
          title={modalTitle}
          clientID={client.id}
          onClose={() => {
            setCreateCollaboratorModalVisible(false);
          }}
          onSuccess={(_collaborator: Collaborator) => {
            setCreateCollaboratorModalVisible(false);

            Modal.fireSuccess(
              t('global.correct'),
              t('clients.createdCollaborator'),
              setOpenModal,
              () => {
                setRefetch(!refetch);
                setCreateCollaboratorModalVisible(false);
              },
              soundEffects
            );
          }}
          onError={(error: string) => {
            setCreateCollaboratorModalVisible(false);
            Modal.fireError(error, setOpenModal, soundEffects);
          }}
        />
        <InspectionAddressForm
          visible={createVerifficationAddessModalVisible}
          title={modalTitle}
          clientID={client.id}
          onClose={() => {
            setCreateVerifficationAddessModalVisible(false);
          }}
          onSuccess={(_client: Client) => {
            setCreateVerifficationAddessModalVisible(false);

            Modal.fireSuccess(
              t('global.correct'),
              t('clients.savedAddress'),
              setOpenModal,
              () => {
                setCreateVerifficationAddessModalVisible(false);
                setClient(_client);
              },
              soundEffects
            );
          }}
          onError={(error: string) => {
            setCreateVerifficationAddessModalVisible(false);
            Modal.fireError(error, setOpenModal, soundEffects);
          }}
        />
        <div className="client-service-detail__right-container">
          <Button
            type='secondary-outlined'
            onClick={async () => {
              try {
                // eslint-disable-next-line no-undef
                await navigator.clipboard.writeText(`${configuration.webAppBaseUrl}/signin/client.detail.${client.id}`).then(() => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('global.shareSuccess'),
                    setOpenModal,
                    undefined,
                    soundEffects
                  );
                });
              } catch (err) {
                Modal.fireSuccess(
                  t('global.correct'),
                  `${t('global.shareInstruccions')}:  ${configuration.webAppBaseUrl}/signin/client.detail.${client.id}`,
                  setOpenModal,
                  undefined,
                  soundEffects
                );
              }
            }}
            icon='share'
            rounded={true}
            label={t('global.share') || ''}
          />
          {getContent()}
        </div>
      </div>
    </div>
  );
};

export default ClientDetail;
