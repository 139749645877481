import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import {
  Header,
  Icon
} from '../../components';

import './styles.scss';

export const ConstancyRequest = () => {
  const { t } = useTranslation();

  return (
    <div className='constancy-request'>
      <Header
        title={t('services.constancyRequest')}
        showBackbutton={true}
      />
      <Outlet />
      <Icon type='personConstancy' className='constancy-request__icon' />
    </div>
  );
};

export default ConstancyRequest;
