import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  Separator,
  StepsIndicator,
  TermsAndConditions,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import { RevisionService } from '../../../../interfaces';

import './styles.scss';

export const Step4 = () => {
  const {
    updateResource
  } = useResource<RevisionService>();

  const navigate = useNavigate();
  const params = useParams();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [checkedTermsAndConditions, setCheckedTermsAndConditions] = useState<boolean>(false);

  const updateRevisionService = () => {
    if (checkedTermsAndConditions) {
      updateResource(
        `/${apiType}/revision-services/${params.revisionServiceID}`,
        {
          requestIsCompleted: true
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.requestSaved'),
            setOpenModal,
            () => navigate(`/revision-services/detail/${params.revisionServiceID}/none`),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    } else {
      Modal.fireError(t('services.errors.termsAndConditions'), setOpenModal, soundEffects);
    }
  };

  useKeyPress(
    openModal ? () => { } : () => updateRevisionService(),
    [checkedTermsAndConditions, openModal]
  );

  return (
    <div className='step-4-revision'>
      <div className='step-4-revision__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={4}
              currentStep={4}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={3}
            />
          )
        }
      </div>
      <Title title={apiType === 'admin' ? t('services.termsAndConditions') : t('services.termsAndConditions3')} />
      <TermsAndConditions
        onAccept={(checked: boolean) => {
          setCheckedTermsAndConditions(checked);
        }}
      />
      <Separator orientation='horizontal' />
      <div className='step-4-revision__button-container'>
        <Button
          onClick={() => navigate(`/revision-request/step_3/${params.revisionServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`)}
          type='primary'
          label={t('global.goBack') || ''}
          icon='leftArrow'
          iconPosition='left'
        />
        <Button
          onClick={updateRevisionService}
          type='primary'
          label={t('global.finish') || ''}
          icon='rightArrow'
          iconPosition='right'
        />
      </div>
    </div>
  );
};

export default Step4;
