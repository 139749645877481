import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  FileInput,
  ModalView,
  Modal,
  Table
} from '../../../../components';

import {
  DictumProduct,
  DictumService,
  FileInputData,
  SignedFile,
  TableHeader
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface ModalViewOptions {
  customComponent?: ReactElement
  onConfirm?: () => void
}

interface Response {
  foundProducts: DictumProduct[],
  notFoundProducts: DictumProduct[],
}

interface Props {
  visible: boolean
  dictumServiceID: string
  onClose: () => void
  setRefetchDictum: (_item: boolean) => void
  refetchDictum: boolean
}

export const UploadPaidPetition: React.FC<Props> = (props) => {
  const {
    visible,
    dictumServiceID,
    onClose,
    setRefetchDictum,
    refetchDictum
  } = props;

  const { t } = useTranslation();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    updateResource
  } = useResource<DictumService>();

  const { createResource } = useResource<Response>();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [modalViewOptions, setModalViewOptions] = useState<ModalViewOptions>({});

  const tableHeaders: { [name: string]: TableHeader[] } = {
    admin: [
      {
        label: t('global.invoice'),
        value: 'invoice',
        format: 'none'
      },
      {
        label: t('global.status.title'),
        value: 'usedOnPaidPetition',
        format: 'custom-component'
      },
      {
        label: t('tasks.table.model'),
        value: 'code',
        format: 'none',
        limitLength: 40
      },
      {
        label: t('global.model2'),
        value: 'code2',
        format: 'none',
        limitLength: 40
      },
      {
        label: t('services.petitionTitle'),
        value: 'service.number',
        format: 'none'
      },
      {
        label: t('tasks.filters.client'),
        value: 'client.businessName',
        format: 'none',
        onlyAdmin: true
      },
      {
        label: t('global.norm'),
        value: 'normCode',
        format: 'none'
      },
      {
        label: t('global.tariffFraction'),
        value: 'tariffFraction',
        format: 'none'
      },
      {
        label: 'UMC',
        value: 'umc',
        format: 'none'
      },
      {
        label: t('services.umcQuantity'),
        value: 'umcQuantity',
        format: 'none'
      },
      {
        label: t('global.status.title'),
        value: 'status',
        format: 'status'
      }
    ],
    public:
      [
        {
          label: t('global.invoice'),
          value: 'invoice',
          format: 'none'
        },
        {
          label: t('global.status.title'),
          value: 'usedOnPaidPetition',
          format: 'custom-component'
        },
        {
          label: t('tasks.table.model'),
          value: 'code',
          format: 'none',
          limitLength: 40
        },
        {
          label: t('global.model2'),
          value: 'code2',
          format: 'none',
          limitLength: 40
        },
        {
          label: t('services.petitionTitle'),
          value: 'service.number',
          format: 'none'
        },
        {
          label: t('global.norm'),
          value: 'normCode',
          format: 'none'
        },
        {
          label: t('global.tariffFraction'),
          value: 'tariffFraction',
          format: 'none'
        },
        {
          label: 'UMC',
          value: 'umc',
          format: 'none'
        },
        {
          label: t('services.umcQuantity'),
          value: 'umcQuantity',
          format: 'none'
        },
        {
          label: t('global.status.title'),
          value: 'status',
          format: 'status'
        }
      ]
  };

  const uploadPaidPetition = (file: FileInputData, petitionDocumentInvoicesResult: Response) => {
    registerUploadedFiles(
      `/${apiType}/dictum-services/${dictumServiceID}/documents/paid-petition`,
      {
        filePaths: [{
          path: file.fileName,
          type: file.data.type,
          documentName: file.fileName
        }]
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', file.data);

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPaths.push(item.url);
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/${apiType}/dictum-services/${dictumServiceID}/documents-paths/paid-petition`,
            {
              filePaths: uploadedPaths,
              documentInvoicesResult: petitionDocumentInvoicesResult
            },
            () => {
              Modal.fireSuccess(
                t('global.correct'),
                t('services.paidPetitionSaved'),
                setOpenModal,
                () => {
                  setRefetchDictum(!refetchDictum);
                  onClose();
                },
                soundEffects
              );
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const showProductsFromPaidPetition = (file: FileInputData) => {
    const payloadInvoice = new FormData();

    payloadInvoice.append('documents', file.data);

    createResource(
      `/${apiType}/dictum-services/${dictumServiceID}/documents-upload-paid-petition`,
      payloadInvoice,
      (data: Response) => {
        let fondProductsCount = 0;

        const products = [];

        products.push(...data.foundProducts.map((product: DictumProduct) => {
          fondProductsCount += 1;
          return {
            ...product,
            usedOnPaidPetition: (
              <p className='paid-petition-modal__used-invoice'>
                {t('global.used')}
              </p>
            )
          };
        }));

        products.push(...data.notFoundProducts.map((product: DictumProduct) => {
          return {
            ...product,
            usedOnPaidPetition: (
              <p className='paid-petition-modal__not-used-invoice'>
                {t('global.notUsed')}
              </p>
            )
          };
        }));

        setModalViewOptions({
          onConfirm: () => { uploadPaidPetition(file, data); },
          customComponent: (
            <div className='dictum-service-detail__table-paid-petition'>
              <p className='dictum-service-detail__title-paid-petition'>{t('services.productsPaidPetition')}</p>
              <p className='dictum-service-detail__text-paid-petition'>{t('services.productsPaidPetitionDescription')}</p>
              <p className='dictum-service-detail__title-paid-petition'>{t('services.productsPaidPetitionAsk')}</p>
              <p className='dictum-service-detail__text-paid-petition'>{t('services.fondProducts')}: {fondProductsCount}</p>
              <br />
              <Table
                headers={tableHeaders[apiType]}
                items={products}
                currentPage={0}
                perPage={0}
                totalItems={0}
                onChangeCurrentPage={() => { }}
                orderBy=''
                orderDirection='ASC'
              />
            </div>
          )
        });
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  useEffect(() => {
    if (visible) {
      setModalViewOptions({
        customComponent: (
          <div className='paid-petition-modal'>
            <p className='paid-petition-modal__title'>{t('services.uploadPaidPetitionTitle')}</p>
            <p className='paid-petition-modal__text'>{t('services.uploadPaidPetitionTitleExplication')}</p>
            <FileInput
              isMultiple={false}
              acceptedFileFormats='.pdf'
              onSelectFile={(payload: FileInputData[]) => {
                showProductsFromPaidPetition(payload[0]);
              }}
              fileName={t('services.petition') || ''}
            />
          </div>
        )
      });
    }
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={modalViewOptions.onConfirm}
      customComponent={modalViewOptions.customComponent}
    />
  );
};

export default UploadPaidPetition;
