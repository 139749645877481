/* eslint-disable max-len */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  Modal,
  Select,
  Separator,
  Spinner,
  Switch,
  Table,
  Title
} from '../../../components';

import './styles.scss';
import { useResource } from '../../../hooks';
import { AppContext } from '../../../context/AppContext';
import {
  AsssignedOnSiteVisits,
  // RemoteInvoicesProposedValidationDate,
  RemoteTasksEfficiency,
  // RemoteTasksClosedInTime,
  SelectOption,
  ValidatedInvoices
} from '../../../interfaces';
import { utils } from '../../../helpers';

const ClientServiceReports = () => {
  const { t } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    fetchResource: getOperations
  } = useResource<ValidatedInvoices[]>();

  const {
    fetchResource: getAssignedVisits
  } = useResource<AsssignedOnSiteVisits[]>();

  const {
    fetchResource: getRemoteInvoicesInTime
  } = useResource<RemoteTasksEfficiency[]>();

  // const {
  //   fetchResource: getRemoteTasksInTime
  // } = useResource<RemoteTasksClosedInTime[]>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [validatedInvoices, setValidatedInvoices] = useState<ValidatedInvoices[]>([]);
  const [assignedOnSiteVisits, setAssignedOnSiteVisits] = useState<AsssignedOnSiteVisits[]>([]);
  const [remoteInvoicesEfficiency, setRemoteInvoicesEfficiency] = useState<RemoteTasksEfficiency[]>([]);
  // const [remoteInvoicesInTime, setRemoteInvoicesInTime] = useState<RemoteInvoicesProposedValidationDate[]>([]);
  // const [remoteTasksInTime, setRemoteTasksInTime] = useState<RemoteTasksClosedInTime[]>([]);
  const [operationsPeriod, setOperationsPeriod] = useState<'weekly' | 'monthly'>('weekly');
  const [operationsPeriodAssignedVisits, setOperationsPeriodAssignedVisits] = useState<'weekly' | 'monthly'>('weekly');
  const [operationsPeriodRemoteInvoicesEfficiency, setOperationsPeriodRemoteInvoicesEfficiency] = useState<'weekly' | 'monthly'>('weekly');
  // const [operationsPeriodRemoteInvoicesInTime, setOperationsPeriodRemoteInvoicesInTime] = useState<'weekly' | 'monthly'>('weekly');
  // const [operationsPeriodRemoteTasksInTime, setOperationsPeriodRemoteTasksInTime] = useState<'weekly' | 'monthly'>('weekly');
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());
  const [selectedYearTasksAssigned, setSelectedYearTasksAssigned] = useState<number>(dayjs().year());
  const [selectedYearRemoteInvoicesEfficiency, setSelectedYearRemoteInvoicesEfficiency] = useState<number>(dayjs().year());
  // const [selectedYearRemoteInvoicesInTime, setSelectedYearRemoteInvoicesInTime] = useState<number>(dayjs().year());
  // const [selectedYearRemoteTasksInTime, setSelectedYearRemoteTasksInTime] = useState<number>(dayjs().year());

  const getOperationsReport = () => {
    getOperations(
      `admin/notifications/validated-invoices-report?operationsPeriod=${operationsPeriod}`,
      (data) => {
        setValidatedInvoices(data);
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const getAssignedVisitsReport = () => {
    getAssignedVisits(
      `admin/notifications/assigned-visits-report?operationsPeriod=${operationsPeriodAssignedVisits}`,
      (data) => {
        setAssignedOnSiteVisits(data);
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  // const gerRemoteInvoicesInTimeList = () => {
  //   getRemoteInvoicesInTime(
  //     `admin/notifications/remote-invoices-in-time-report?operationsPeriod=${operationsPeriodRemoteInvoicesInTime}`,
  //     (data) => {
  //       setRemoteInvoicesInTime(data);
  //     },
  //     (error: string) => {
  //       Modal.fireError(error, setOpenModal, soundEffects);
  //     }
  //   );
  // };

  // const gerRemoteTasksInTimeList = () => {
  //   getRemoteTasksInTime(
  //     `admin/notifications/remote-tasks-in-time-report?operationsPeriod=${operationsPeriodRemoteTasksInTime}`,
  //     (data) => {
  //       setRemoteTasksInTime(data);
  //     },
  //     (error: string) => {
  //       Modal.fireError(error, setOpenModal, soundEffects);
  //     }
  //   );
  // };

  const getRemoteServicesEfficiency = () => {
    getRemoteInvoicesInTime(
      `admin/notifications/remote-invoices-efficiency-report?operationsPeriod=${operationsPeriodRemoteInvoicesEfficiency}`,
      (data) => {
        setRemoteInvoicesEfficiency(data);
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  const throttledHandleRequest = utils.throttle(getOperationsReport, 1000);

  const throttledHandleRequestAssignedVisits = utils.throttle(getAssignedVisitsReport, 1000);

  const throttledHandleRequestRemoteInvoicesEfficiency = utils.throttle(getRemoteServicesEfficiency, 1000);

  // const throttledHandleRequestRemoteInvoicesInTimeList = utils.throttle(gerRemoteInvoicesInTimeList, 1000);

  // const throttledHandleRequestRemoteTasksInTimeList = utils.throttle(gerRemoteTasksInTimeList, 1000);

  const getYearsFromDates = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    validatedInvoices.forEach(operation => {
      years.add(operation.year);
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  useEffect(() => {
    throttledHandleRequest();
  }, [operationsPeriod]);

  useEffect(() => {
    throttledHandleRequestAssignedVisits();
  }, [operationsPeriodAssignedVisits]);

  useEffect(() => {
    throttledHandleRequestRemoteInvoicesEfficiency();
  }, [operationsPeriodRemoteInvoicesEfficiency]);

  // useEffect(() => {
  //   throttledHandleRequestRemoteInvoicesInTimeList();
  // }, [operationsPeriodRemoteInvoicesInTime]);

  // useEffect(() => {
  //   throttledHandleRequestRemoteTasksInTimeList();
  // }, [operationsPeriodRemoteTasksInTime]);

  return (
    <div className="reports">
      <Header
        title={t('menu.customerService') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          <Title title='Validación de folios' />
          {
            validatedInvoices.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYear(Number(_value));
                      }}
                      value={selectedYear}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriod(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none'
                      },
                      {
                        label: 'folios ingresados totales',
                        value: 'createdInvoices',
                        format: 'none',
                        helperText: 'Total de folios creados',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Folios de dictamen',
                        value: 'dictumInvoices',
                        format: 'none'
                      },
                      {
                        label: 'Enviados a validar',
                        value: 'sendToValidate',
                        format: 'none',
                        helperText: 'Folios de dictamen enviados en layout 1',
                        helperTextTooltip: true
                      },
                      {
                        label: 'validados',
                        value: 'validated',
                        format: 'none'
                      },
                      {
                        label: 'No validados',
                        value: 'notValidated',
                        format: 'none'
                      },
                      {
                        label: '% No validados',
                        value: 'porcentNotValidated',
                        format: 'none'
                      },
                      {
                        label: 'Tiempo promedio en validación (horas)',
                        value: 'averageValidationTime',
                        format: 'none'
                      }
                    ]}
                    items={validatedInvoices.filter(item => item.year === selectedYear)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
          <Separator orientation='horizontal' />
          <Title title='Eficiencia en la programación de visitas en sitio' />
          {
            assignedOnSiteVisits.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYearTasksAssigned(Number(_value));
                      }}
                      value={selectedYearTasksAssigned}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriodAssignedVisits(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none'
                      },
                      {
                        label: 'Tareas totales asignadas',
                        value: 'totalTasks',
                        format: 'none',
                        helperText: operationsPeriod === 'weekly' ? 'Total de tareas creadas para esta semana' : 'Total de tareas creadas para este mes',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Visitas en sitio asignadas',
                        value: 'assignedVisits',
                        format: 'none',
                        helperText: 'Cantidad de tareas en sitio del total de tareas',
                        helperTextTooltip: true
                      },
                      {
                        label: 'asignadas en tiempo',
                        value: 'assignedOnTime',
                        format: 'none',
                        helperText: 'Tareas en sitio y agrupadas por dirección de inspección e inspector, que se asignaron en menos de 24hrs',
                        helperTextTooltip: true
                      },
                      {
                        label: '% de eficiencia',
                        value: 'efficiencyPercentage',
                        format: 'none'
                      }
                    ]}
                    items={assignedOnSiteVisits.filter(item => item.year === selectedYearTasksAssigned)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }<Separator orientation='horizontal' />
          <Title title='Eficiencia en la fecha de entrega (servicios remotos)' />
          {
            remoteInvoicesEfficiency.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYearRemoteInvoicesEfficiency(Number(_value));
                      }}
                      value={selectedYearRemoteInvoicesEfficiency}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriodRemoteInvoicesEfficiency(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none'
                      },
                      {
                        label: 'Cantidad de tareas',
                        value: 'tasksQuantity',
                        format: 'none',
                        helperText: 'Total de tareas agendadas',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Cerradas en tiempo',
                        value: 'closedInTime',
                        format: 'none',
                        helperText: 'Tareas asignadas que se terminaron en fecha o antes',
                        helperTextTooltip: true
                      },
                      {
                        label: 'Promedio de días de entrega',
                        value: 'deliveryDate',
                        format: 'none'
                      },
                      // {
                      //   label: 'Promedio de días de asignación',
                      //   value: 'averageDaysAssignment',
                      //   format: 'none',
                      //   helperText: 'Promedio de días de asignación de folios',
                      //   helperTextTooltip: true
                      // },
                      {
                        label: '% de eficiencia',
                        value: 'efficiencyPercentage',
                        format: 'none',
                        helperText: 'Eficiencia de los folios liberados en 3 días o menos',
                        helperTextTooltip: true,
                        helperTextTooltipPosition: 'left'
                      }
                    ]}
                    items={remoteInvoicesEfficiency.filter(item => item.year === selectedYearRemoteInvoicesEfficiency)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
          {/* <Separator orientation='horizontal' />
          <Title title='Eficiencia en la fecha estimada de entrega (servicios remotos)' />
          {
            remoteInvoicesInTime.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYearRemoteInvoicesInTime(Number(_value));
                      }}
                      value={selectedYearRemoteInvoicesInTime}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriodRemoteInvoicesInTime(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none'
                      },
                      {
                        label: 'Recepciones totales folio',
                        value: 'invoicesQuantity',
                        format: 'none'
                      },
                      {
                        label: 'Fechas estimadas de entrega en 3 días o menos',
                        value: 'invoicesInRange',
                        format: 'none'
                      },
                      {
                        label: 'Promedio de días de asignación',
                        value: 'efficiencyDays',
                        format: 'none'
                      },
                      {
                        label: '% de eficiencia',
                        value: 'efficiencyPercentage',
                        format: 'none'
                      }
                    ]}
                    items={remoteInvoicesInTime.filter(item => item.year === selectedYearRemoteInvoicesInTime)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          }
          <Separator orientation='horizontal' />
          <Title title='Cumplimiento de fecha estimada de entrega al cliente (servicios remotos)' />
          {
            remoteTasksInTime.length === 0 ? (
              <div className="reports__main__container__spinner">
                <Spinner />
              </div>
            ) : (
              <>
                {
                  getYearsFromDates().length > 1 && (
                    <Select
                      options={getYearsFromDates()}
                      onChange={(_value: string, _id: string) => {
                        setSelectedYearRemoteTasksInTime(Number(_value));
                      }}
                      value={selectedYearRemoteTasksInTime}
                      title={t('global.year') || ''}
                    />
                  )
                }
                <Switch
                  leftLabel={{
                    text: 'Semanal',
                    value: 'weekly'
                  }}
                  rigthLabel={{
                    text: 'Mensual',
                    value: 'monthly'
                  }}
                  onChange={(value: string | number) => {
                    setOperationsPeriodRemoteTasksInTime(`${value}` as 'weekly' | 'monthly');
                  }}
                />
                <div className="reports__main__container__table-container">
                  <Table
                    headers={[
                      {
                        label: operationsPeriod === 'weekly' ? 'Semana' : 'Mes',
                        value: 'date',
                        format: 'none'
                      },
                      {
                        label: 'Cantidad de tareas',
                        value: 'tasksQuantity',
                        format: 'none'
                      },
                      {
                        label: 'Cerradas en tiempo',
                        value: 'tasksCosedInTime',
                        format: 'none'
                      },
                      {
                        label: '% de cumplimiento',
                        value: 'percentageOfCompliance',
                        format: 'none'
                      }
                    ]}
                    items={remoteTasksInTime.filter(item => item.year === selectedYearRemoteTasksInTime)}
                    floatingHeader={true}
                  />
                </div>
              </>
            )
          } */}
        </div>
      </div>
    </div>
  );
};

export default ClientServiceReports;
