/* eslint-disable max-len */
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import saveAs from 'file-saver';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Header,
  LateralMenu,
  Modal,
  Separator,
  SubTitle
} from '../../components';

import './styles.scss';
import { useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { LateralMenuOption, S3Object, TabItem } from '../../interfaces';
import Tabs from '../../components/Tabs';

const UsersList = () => {
  const { t } = useTranslation();

  dayjs.extend(weekOfYear);

  const {
    fetchResources
  } = useResource<S3Object>();

  const {
    fetchResource
  } = useResource<string>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [cancelationLayouts, setCancelationLayouts] = useState<S3Object[]>([]);
  const [layout1, setLayout1] = useState<S3Object[]>([]);
  const [layout2, setLayout2] = useState<S3Object[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [yearsCencelationLayouts, setYearsCencelationLayouts] = useState<string[]>([]);
  const [monthsCancelationLayouts, setMonthsCancelationLayouts] = useState<string[]>([]);
  const [yearSelectedCencelationLayouts, setYearSelectedCencelationLayouts] = useState<string>('');
  const [monthSelectedCancelationLayouts, setMonthSelectedCancelationLayouts] = useState<string>('');
  const [yearsLayout1, setYearsLayout1] = useState<string[]>([]);
  const [monthsLayout1, setMonthsLayout1] = useState<string[]>([]);
  const [yearSelectedLayout1, setYearSelectedLayout1] = useState<string>('');
  const [monthSelectedLayout1, setMonthSelectedLayout1] = useState<string>('');
  const [yearsLayout2, setYearsLayout2] = useState<string[]>([]);
  const [monthsLayout2, setMonthsLayout2] = useState<string[]>([]);
  const [yearSelectedLayout2, setYearSelectedLayout2] = useState<string>('');
  const [monthSelectedLayout2, setMonthSelectedLayout2] = useState<string>('');

  const getLayoutsBackupList = () => {
    fetchResources(
      {
        resourcePath: '/admin/notifications/layouts-list'
      },
      (data) => {
        const layouts1 = data.items.filter(item => item.key.startsWith('layouts-backup/layout-1/'));
        const layouts2 = data.items.filter(item => item.key.startsWith('layouts-backup/layout-2/'));
        const cancelation = data.items.filter(item => item.key.startsWith('layouts-backup/cancelation-layout/'));

        const yearsCencelation: string[] = [];

        for (let index = 0; index < cancelation.length; index += 1) {
          const splited = cancelation[index].key.split('/');
          yearsCencelation.push(splited[2]);
        }

        setYearsCencelationLayouts(Array.from(new Set(yearsCencelation)));
        setYearSelectedCencelationLayouts(Array.from(new Set(yearsCencelation))[0]);

        const monthsCencelation: string[] = [];

        for (let index = 0; index < cancelation.length; index += 1) {
          const splited = cancelation[index].key.split('/');
          monthsCencelation.push(splited[3]);
        }

        setMonthsCancelationLayouts(Array.from(new Set(monthsCencelation)));
        setMonthSelectedCancelationLayouts(Array.from(new Set(monthsCencelation))[0]);

        const yearsLayout1Items: string[] = [];

        for (let index = 0; index < layouts1.length; index += 1) {
          const splited = layouts1[index].key.split('/');
          yearsLayout1Items.push(splited[2]);
        }

        setYearsLayout1(Array.from(new Set(yearsLayout1Items)));
        setYearSelectedLayout1(Array.from(new Set(yearsLayout1Items))[0]);

        const monthsLayout1Items: string[] = [];

        for (let index = 0; index < layouts1.length; index += 1) {
          const splited = layouts1[index].key.split('/');
          monthsLayout1Items.push(splited[3]);
        }

        setMonthsLayout1(Array.from(new Set(monthsLayout1Items)));
        setMonthSelectedLayout1(Array.from(new Set(monthsLayout1Items))[0]);

        const yearsLayout2Items: string[] = [];

        for (let index = 0; index < layouts2.length; index += 1) {
          const splited = layouts2[index].key.split('/');
          yearsLayout2Items.push(splited[2]);
        }

        setYearsLayout2(Array.from(new Set(yearsLayout2Items)));
        setYearSelectedLayout2(Array.from(new Set(yearsLayout2Items))[0]);

        const monthsLayout2Items: string[] = [];

        for (let index = 0; index < layouts2.length; index += 1) {
          const splited = layouts2[index].key.split('/');
          monthsLayout2Items.push(splited[3]);
        }

        setMonthsLayout2(Array.from(new Set(monthsLayout2Items)));
        setMonthSelectedLayout2(Array.from(new Set(monthsLayout2Items))[0]);

        setCancelationLayouts(cancelation);
        setLayout1(layouts1);
        setLayout2(layouts2);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    getLayoutsBackupList();
  }, []);

  const getYearsCancelations = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    for (let index = 0; index < yearsCencelationLayouts.length; index += 1) {
      list.push({
        label: yearsCencelationLayouts[index],
        value: yearsCencelationLayouts[index]
      });
    }

    return list;
  };

  const getMonthsCancelations = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    const months: { [name: string]: string } = {
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    };

    for (let index = 0; index < monthsCancelationLayouts.length; index += 1) {
      list.push({
        label: months[monthsCancelationLayouts[index]],
        value: monthsCancelationLayouts[index]
      });
    }

    return list;
  };

  const getYearsLayout1 = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    for (let index = 0; index < yearsLayout1.length; index += 1) {
      list.push({
        label: yearsLayout1[index],
        value: yearsLayout1[index]
      });
    }

    return list;
  };

  const getMonthsLayout1 = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    const months: { [name: string]: string } = {
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    };

    for (let index = 0; index < monthsLayout1.length; index += 1) {
      list.push({
        label: months[monthsLayout1[index]],
        value: monthsLayout1[index]
      });
    }

    return list;
  };

  const getYearsLayout2 = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    for (let index = 0; index < yearsLayout2.length; index += 1) {
      list.push({
        label: yearsLayout2[index],
        value: yearsLayout2[index]
      });
    }

    return list;
  };

  const getMonthsLayout2 = (): LateralMenuOption[] => {
    const list: LateralMenuOption[] = [];

    const months: { [name: string]: string } = {
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    };

    for (let index = 0; index < monthsLayout2.length; index += 1) {
      list.push({
        label: months[monthsLayout2[index]],
        value: monthsLayout2[index]
      });
    }

    return list;
  };

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `admin/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  return (
    <div className="layouts-backup">
      <Header
        title={t('global.layouts-backup') || ''}
        showBackbutton={true}
      />
      <div className="layouts-backup__main">
        <div className="layouts-backup__main__container">
          <Tabs
            items={[
              {
                id: 1,
                label: 'Layouts de cancelación'
              },
              {
                id: 2,
                label: 'Layout 1'
              },
              {
                id: 3,
                label: 'Layout 2'
              }
            ]}
            onSelectTab={(_value: TabItem) => {
              setSelectedTab(_value.id);
            }}
          />
          {
            selectedTab === 1 && (
              <div className="layouts-backup__main__container__list">
                <div className="layouts-backup__main__container__menu">
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Año'} />
                    <LateralMenu
                      value={dayjs().format('YYYY')}
                      onSelect={(value: string) => {
                        setYearSelectedCencelationLayouts(value);
                      }}
                      options={
                        getYearsCancelations()
                      }
                    />
                  </div>
                  <Separator orientation={'vertical'} />
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Mes'} />
                    <LateralMenu
                      value={monthsCancelationLayouts[0]}
                      onSelect={(value: string) => {
                        setMonthSelectedCancelationLayouts(value);
                      }}
                      options={
                        getMonthsCancelations()
                      }
                    />
                  </div>
                </div>
                <div className="layouts-backup__main__container__items">
                  {cancelationLayouts.filter(item => item.key.startsWith(`layouts-backup/cancelation-layout/${yearSelectedCencelationLayouts}/${monthSelectedCancelationLayouts}/`)).map((item, index: number) => (
                    <p key={index} onClick={() => handleDownloadFile(item.key, item.key.replace(`layouts-backup/cancelation-layout/${yearSelectedCencelationLayouts}/${monthSelectedCancelationLayouts}/`, ''))}>
                      {item.key.replace(`layouts-backup/cancelation-layout/${yearSelectedCencelationLayouts}/${monthSelectedCancelationLayouts}/`, '')}
                    </p>
                  ))}
                </div>
              </div>
            )
          }
          {
            selectedTab === 2 && (
              <div className="layouts-backup__main__container__list">
                <div className="layouts-backup__main__container__menu">
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Año'} />
                    <LateralMenu
                      value={yearsLayout1[0]}
                      onSelect={(value: string) => {
                        setYearSelectedLayout1(value);
                      }}
                      options={
                        getYearsLayout1()
                      }
                    />
                  </div>
                  <Separator orientation={'vertical'} />
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Mes'} />
                    <LateralMenu
                      value={monthsLayout2[0]}
                      onSelect={(value: string) => {
                        setMonthSelectedLayout1(value);
                      }}
                      options={
                        getMonthsLayout1()
                      }
                    />
                  </div>
                </div>
                <div className="layouts-backup__main__container__items">
                  {layout1.filter(item => item.key.startsWith(`layouts-backup/layout-1/${yearSelectedLayout1}/${monthSelectedLayout1}/`)).map((item, index: number) => (
                    <p key={index} onClick={() => handleDownloadFile(item.key, item.key.replace(`layouts-backup/cancelation-layout/${yearSelectedCencelationLayouts}/${monthSelectedCancelationLayouts}/`, ''))}>
                      {item.key.replace(`layouts-backup/layout-1/${yearSelectedLayout1}/${monthSelectedLayout1}/`, '')}
                    </p>
                  ))}
                </div>
              </div>
            )
          }
          {
            selectedTab === 3 && (
              <div className="layouts-backup__main__container__list">
                <div className="layouts-backup__main__container__menu">
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Año'} />
                    <LateralMenu
                      value={yearsLayout2[0]}
                      onSelect={(value: string) => {
                        setYearSelectedLayout2(value);
                      }}
                      options={
                        getYearsLayout2()
                      }
                    />
                  </div>
                  <Separator orientation={'vertical'} />
                  <div className="layouts-backup__main__container__menu__vertical">
                    <SubTitle subTitle={'Mes'} />
                    <LateralMenu
                      value={monthsLayout2[0]}
                      onSelect={(value: string) => {
                        setMonthSelectedLayout2(value);
                      }}
                      options={
                        getMonthsLayout2()
                      }
                    />
                  </div>
                </div>
                <div className="layouts-backup__main__container__items">
                  {layout2.filter(item => item.key.startsWith(`layouts-backup/layout-2/${yearSelectedLayout2}/${monthSelectedLayout2}/`)).map((item, index: number) => (
                    <p key={index} onClick={() => handleDownloadFile(item.key, item.key.replace(`layouts-backup/cancelation-layout/${yearSelectedCencelationLayouts}/${monthSelectedCancelationLayouts}/`, ''))}>
                      {item.key.replace(`layouts-backup/layout-2/${yearSelectedLayout2}/${monthSelectedLayout2}/`, '')}
                    </p>
                  ))}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UsersList;
