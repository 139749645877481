export default {
  DC: {
    documentCode: 'F7-38',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-141-SSA1/SCFI-2012, Etiquetado para productos cosméticos preenvasados. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Requisitos de etiquetado
            <br />
            5.1 Requisitos generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Presentación de la información.
            <br />
            Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información establecida en esta norma en
            idioma español, independientemente de que también pueda estar en otros idiomas,
            cuidando que los caracteres sean mayores o al menos iguales a aquellos en los que
            se presenta la información en otros idiomas; los cuales deben ser claros, visibles,
            indelebles y en colores contrastantes, fáciles de leer por el consumidor en
            circunstancias normales de compra y uso. No será necesario utilizar las comillas
            en el etiquetado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2. La información que se presente al consumidor, debe ser veraz y comprobable.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3. Las etiquetas que ostenten los productos objeto de esta norma, deben fijarse de
            manera tal que permanezcan disponibles hasta el momento de su compra y uso en
            condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Cuando por las características del producto, no sea posible que la(s) etiqueta(s)
            se encuentre(n) directamente sobre el envase primario o secundario se podrán
            anexar al mismo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Denominación genérica y específica del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.1 Todos los productos deben ostentar la denominación genérica, conforme
            a lo que se establece en el apéndice informativo "A" de esta norma,
            pudiendo incluir la específica. En caso de productos cuya denominación
            no se encuentre dentro del ordenamiento antes citado, su denominación
            será aquella que mejor los describa o la más común o usual, podrá
            usarse una ilustración o viñeta que represente el uso del producto cosmético.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 La información del numeral anterior, debe presentarse en español a
            excepción de las formas cosméticas que podrán declararse en su idioma
            original. Esta información podrá presentarse en la superficie principal
            del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6 Identificación del responsable del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.1 Debe figurar en la superficie de información del envase primario o
            secundario, el nombre, denominación o razón social y domicilio (calle, número,
            código postal, ciudad y estado) del responsable del producto. Tratándose de
            productos importados, estos datos podrán incorporarse al producto, en el
            Territorio Nacional después del despacho aduanero y antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.2 Leyenda que identifique al país de origen del producto o gentilicio,
            por ejemplo "Producto de...", "Hecho en...", "Manufacturado en..." u otras
            análogas, sujeto a lo dispuesto en los tratados internacionales de los
            cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7 Declaración de lote.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7.1 En cualquier parte del envase primario o secundario, debe
            figurar en todos los productos objeto de esta norma, la identificació
            del lote con una indicación en clave o en lenguaje claro, ya sea
            grabado, marcado con tinta indeleble o de cualquier otro modo similar,
            siempre y cuando éste sea claro y asegure su permanencia en condiciones normales de uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8 Instrucciones o modo de uso.
            <br />
            5.1.8.1 Deben figurar las instrucciones de uso u otros análogos en la
            superficie de información del envase primario o secundario o instructivo
            anexo de los siguientes productos: tintes, colorantes, coloración,
            decolorantes; permanentes; alaciadores permanentes; en productos para
            la piel cuya función primaria sea la protección solar, bronceadores,
            autobronceadores, depilatorios, epilatorios o en cualquier otro
            producto que lo requiera.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.2 En caso de que las instrucciones o modo de uso estén en un
            instructivo anexo deberá señalarse esta situación mediante la
            oración "léase instructivo anexo" o equivalente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.3 En productos para la piel cuya función primaria sea la
            protección solar, indicar mediante las frases siguientes o equivalentes:
            <br />
            5.1.8.3.1 Que se aplique antes de la exposición al sol.
            <br />
            5.1.8.3.2 Que para mantener la protección, se repita con frecuencia la
            aplicación del producto, especialmente después de transpirar, bañarse o secarse.
            <br />
            5.1.8.3.3. Que se aplique a la piel la cantidad suficiente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9 Declaraciones prohibidas de propiedades.
            <br />
            Se prohíbe el uso de las siguientes declaraciones:
            <br />
            5.1.9.1 Declaración de propiedades que no pueden comprobarse.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.2 No podrán atribuirse a los productos cosméticos,
            acciones propias de los medicamentos.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.3 En la comercialización de los productos cosméticos,
            el etiquetado no utilizará textos, denominaciones, marcas,
            imágenes o cualquier otro símbolo figurativo o no, con el
            fin de atribuir a estos productos características o propiedades de las que carecen.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10 En los envases múltiples o colectivos será necesario declarar únicamente,
            la información de etiquetado que no contengan los productos, de forma individual.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.1 La información anterior debe aparecer en la superficie principal de
            exhibición o en la de información, sin restricción en el tamaño de la letra
            utilizada, siempre que sea fácil de leer por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2 Para los productos que se comercialicen en envases múltiples o colectivos:
            5.1.10.2.1 La declaración de la cantidad puede expresarse indistintamente por cuenta
            numérica por los envases que contiene o por contenido neto, excepto cuando el
            contenido o contenido neto sea obvio, no siendo restrictivo la ubicación y
            tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2.2 Los envases individuales deben contener la información completa que establece
            este ordenamiento, la declaración de cantidad del dato cuantitativo de acuerdo con lo
            establecido en este ordenamiento. En el caso de que los envases individuales no
            contengan la declaración de cantidad, ésta debe declararse en el envase múltiple
            o colectivo, no siendo restrictivo la ubicación y tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 23,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2. Información Comercial
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Se debe cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002
            Sistema General de Unidades de Medida, sin perjuicio de que además se puedan
            utilizar unidades de medida de otro sistema.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Para la declaración de cantidad se considera suficiente que los
            envases ostenten el dato cuantitativo, seguido de la unidad correspondiente
            a la magnitud aplicable, sin que sea necesario ostentar las leyendas
            "CONTENIDO", "CONTENIDO NETO", O SUS ABREVIATURAS, "CONT", "CONT.NET".
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 La declaración anterior debe aparecer en la superficie principal
            de exhibición o en la de información del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 El tamaño de la declaración de contenido o contenido neto debe
            ser de acuerdo a lo establecido en la Norma Oficial Mexicana NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 En las muestras o ayudas de venta y amenidades debe figurar,
            en cualquier parte del envase la siguiente información: denominación
            del producto, nombre del responsable del producto, número de lote y
            cuando aplique, considerando lo establecido en esta norma, incluir
            las instrucciones de uso y/o leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 En las muestras o ayudas de venta de lociones y fragancias cuyo
            contenido neto sea menor o igual a 2 ml debe figurar en el envase secundario
            al menos la marca comercial, la denominación del producto, para los productos
            importados ésta podrá ser la del país de origen, la razón social del fabricante
            del mismo, y cuando aplique leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Información Sanitaria
            <br />
            5.3.1. En los productos objeto de esta norma, debe figurar en caracteres
            visibles, en cualesquiera de las etiquetas que se encuentran en la superficie
            de información del envase primario o secundario, la lista de los nombres de
            los ingredientes de la fórmula debe ir precedida por el término
            "ingredientes" y enlistarse:
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 por orden cuantitativo decreciente; o
            <br />
            5.3.1.2 por orden cuantitativo decreciente aquellos ingredientes
            cuya concentración sea superior al 1% seguido por aquellos
            ingredientes en concentración inferior o igual al 1% que
            podrán mencionarse en cualquier orden.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Quedan exceptuadas de la declaración de los nombres de
            los ingredientes, los perfumes y fragancias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Para la nomenclatura de los ingredientes, puede emplearse a
            elección del fabricante cualquiera de las establecidas en los Acuerdos,
            o el nombre químico más usual o el nombre tal cual como aparece en
            la Nomenclatura Internacional de Ingredientes Cosméticos (INCI).
            <br />
            Las fragancias y sabores pueden designarse con el nombre genérico.
            <br />
            Los materiales de origen botánico deben designarse con el nombre
            científico de la planta, siendo opcional el nombre común de la misma.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Para la declaración de los nombres de los ingredientes en los
            productos con una o más presentaciones, en los que la fórmula base
            es la misma y sólo varía el uso de los colorantes, se incluirá la
            lista con los nombres de los ingredientes comunes de la fórmula,
            seguida de otra con todos los colorantes usados para las diversas
            presentaciones, anteponiendo a esta última el texto "puede contener"
            o "contiene uno o más" o "+/-".o equivalentes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            5.3.5 Para la declaración de los nombres de los ingredientes en aquellos
            productos que por su tamaño carecen de espacio, (como son lápices de cejas,
            delineadores, entre otros) ésta podrá figurar en el envase secundario si lo
            hubiere o bien en un volante impreso anexo al producto o en una etiqueta de bandera.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            5.3.6 En productos con una duración menor o igual a 24 meses debe figurar,
            en cualquier parte del envase primario o secundario, la fecha hasta la
            cual un producto, en condiciones adecuadas de almacenamiento, es seguro
            para la salud del consumidor, indicando al menos el mes y el año, o bien
            por el día, el mes y el año Este dato podrá o no ir precedido por la
            leyenda, a elección del fabricante:
            <br />
            Caducidad, Consumo preferente, Vencimiento, Duración mínima, Validez,
            Expiración, o equivalentes o sus abreviaturas.
            <br />
            Quedan exceptuados de la declaración de esta fecha, los productos que
            por sus características no permiten el crecimiento microbiano o que
            tienen una alta rotación de venta y uso, tales como: Aceites, Jabones
            sólidos, sales de baño, perfumes y derivados, desodorantes que no
            sean emulsiones, antitranspirantes, depilatorios, tintes y decolorantes,
            shampoo, acondicionadores, permanentes, relajantes permanentes de rizos
            y alaciadores permanentes, fijadores, oxidantes, productos para uñas,
            brillantinas, unidosis y productos en envases presurizados.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            <b>5.3.7.1 En desodorantes o antitranspirantes</b>
            <br />
            5.3.7.1.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.1.2 Que descontinúe su uso en caso de presentarse
            irritación, enrojecimiento o alguna molestia
            <br />
            5.3.7.1.3 Que no se deje al alcance de los niños
            <br />
            5.3.7.1.4 En caso de contener Fenolsulfonato de zinc
            mencionar que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2 En tintes, colorantes, coloración y otros relacionados:
            <br />
            5.3.7.2.1 Los colorantes del cabello pueden causar reacciones alérgicas graves
            <br />
            5.3.7.2.2 Lea y siga las instrucciones
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2.3 Este producto no está destinado a utilizarse en
            personas menores de dieciséis años.
            <br />
            5.3.7.2.4 Los tatuajes temporales de «henna negra» pueden aumentar el riesgo de alergia
            <br />
            5.3.7.2.5 No utilice el tinte capilar
            <br />
            5.3.7.2.5.1 Si tiene una erupción cutánea en el rostro o
            tiene el cuero cabelludo sensible, irritado o dañado
            <br />
            5.3.7.2.5.2 Si alguna vez ha experimentado cualquier tipo
            de reacción después de la coloración del cabello
            <br />
            5.3.7.2.5.3 Si alguna vez ha experimentado una reacción a los tatuajes
            temporales de «henna negra»
            <br />
            5.3.7.2.6 Que se realice una prueba preliminar de acuerdo a las instrucciones
            <br />
            5.3.7.2.7 Que puede causar alergia en algunas personas
            <br />
            5.3.7.2.8 Que suspenda su empleo en caso de irritación
            <br />
            5.3.7.2.9 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.2.10 Que se evite el contacto con los ojos
            <br />
            5.3.7.2.11 Que no se deje al alcance de los niños
            <br />
            5.3.7.2.12 Indicaciones de primeros auxilios para el caso
            <br />
            5.3.7.2.13 Que se usen guantes apropiados
            <br />
            5.3.7.2.14 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.2.15 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.3 En tintes, colorantes, coloración y otros que
            contengan alguna de las siguientes sustancias, además de
            las leyendas anteriores, se deberán indicar que la contienen:
            <br />
            5.3.7.3.1 Diaminobenceno y sus derivados
            <br />
            5.3.7.3.2 Diaminotolueno y sus derivados
            <br />
            5.3.7.3.3 Diaminofenol
            <br />
            5.3.7.3.4 Hidroquinona
            <br />
            5.3.7.3.5 Resorcinol
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.4 En permanentes y alaciadores permanentes
            <br />
            5.3.7.4.1 Que se destaque(n) la(s) sustancia(s)
            que puedan causar daño al cabello y piel cabelluda
            <br />
            5.3.7.4.2 Que no se aplique a cejas o pestañas
            <br />
            5.3.7.4.3 Que se evite el contacto con los ojos. Que puede causar ceguera
            <br />
            5.3.7.4.4 Que se use exclusivamente conforme al instructivo
            <br />
            5.3.7.4.5 Que no se deje al alcance de los niños
            <br />
            5.3.7.4.6 Además deben incluirse las indicaciones de primeros auxilios
            para el caso y recomendar la consulta a un médico
            <br />
            5.3.7.4.7 En productos profesionales además se debe indicar: Reservado a profesionales
            <br />
            5.3.7.4.8 En los productos que contengan alguna de las siguientes
            sustancias, además de lo anterior, se deberá indicar que las contienen:
            <br />
            5.3.7.4.8.1 Hidróxido de sodio
            <br />
            5.3.7.4.8.2 Hidróxido de potasio
            <br />
            5.3.7.4.8.3 Hidróxido de litio
            <br />
            5.3.7.4.8.4 Hidróxido de calcio
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.5 En decolorantes:
            <br />
            5.3.7.5.1 Que se destaque(n) la(s) sustancia(s) que puede causar daño
            <br />
            5.3.7.5.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.5.3 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.5.4 Que se usen guantes apropiados
            <br />
            5.3.7.5.5 Que no se aplique si la piel cabelluda está irritada
            <br />
            5.3.7.5.6 Que se suspenda su empleo en caso de irritación
            <br />
            5.3.7.5.7 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.5.8 Que no se deje al alcance de los niños
            <br />
            5.3.7.5.9 Que se den indicaciones de primeros auxilios para el caso
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.6 En depilatorios:
            <br />
            5.3.7.6.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.6.2 Que no se deje al alcance de los niños
            <br />
            5.3.7.6.3 Que se evite el contacto con los ojos
            <br />
            5.3.7.6.4 Los productos que contengan alguna de las siguientes
            sustancias además de las anteriores, se debe indicar que la contiene:
            <br />
            5.3.7.6.4.1 Hidróxido de sodio
            <br />
            5.3.7.6.4.2 Hidróxido de potasio
            <br />
            5.3.7.6.4.3 Hidróxido de litio
            <br />
            5.3.7.6.4.4 Hidróxido de calcio
            <br />
            Se podrá indicar de manera genérica "Contiene un agente alcalino"
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.7 En los endurecedores de uñas que contengan formaldehido:
            <br />
            5.3.7.7.1 Que se proteja la cutícula con sustancias grasosas
            <br />
            5.3.7.7.2 Indicar que lo contiene mediante
            la leyenda correspondiente (sólo si la concentración es superior a 0.05%)
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.9 En sistemas de uñas profesionales que
            contengan peróxido de benzoílo y/o hidroquinona:
            <br />
            5.3.7.9.1 Que está reservado a los profesionales
            <br />
            5.3.7.9.2 Que se evite el contacto con la piel
            <br />
            5.3.7.9.3 Que se lean las instrucciones de uso
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.10 En los productos para la piel
            cuya función primaria sea la de ofrecer protección solar:
            <br />
            5.3.7.10.1 Que se indique el valor del Factor de Protección Solar
            (FPS), en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.10.2 Que protege contra UVB y UVA
            <br />
            5.3.7.10.3 Que no permanezca mucho tiempo expuesto
            al sol, aunque emplee un producto de protección solar
            <br />
            5.3.7.10.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.10.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.10.6 Que suspenda su empleo si se presentan signos de irritación o salpullido
            <br />
            5.3.7.10.7 Evite el contacto con los ojos, puede causar irritación
            <br />
            5.3.7.10.8 Se podrá incluir el logotipo del Factor UVA, el cual deberá
            indicarse mediante las siglas "UVA" impresas dentro de un círculo simple
            y cuyo diámetro no deberá exceder la altura con que se indique el número FPS.
            <br />
            <img src="/images/UVA.png" alt="UVA" style={{ width: '60px' }} />
            <br />
            Los productos que ofrezcan protección solar como
            función secunda ria, no se consideran protectores
            solares por lo que no les aplican estas leyendas
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.11 En productos cuya función primaria sea
            la de broncear éstos deben tener un FPS de 2 a 4 (valor medido 2 a 5.9) y declararlo:
            <br />
            5.3.7.11.1 Que se indique el valor del Factor de Protección Solar (FPS),
            en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.11.2 Que suspenda su empleo si se presentan signos de irritación
            <br />
            5.3.7.11.3 Que no permanezca mucho tiempo expuesto al sol
            <br />
            5.3.7.11.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.11.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.11.6 Que no se recomienda para niños y personas con piel sensible al sol
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.12 En desodorantes femeninos en aerosol previstos para el uso en el área genital:
            <br />
            5.3.7.12.1 Este producto es exclusivo para uso externo solamente
            y no debe ser aplicado a piel con heridas, irritada o con escozor
            <br />
            5.3.7.13 En productos cuya presentación sea en ampolletas, iguales a la
            presentación farmacéutica, debe figurar en el envase múltiple o en cada
            ampolleta el texto: "no ingerible" "no inyectable"
            <br />
            5.3.7.14 En los productos cuya presentación es en envases presurizados (aerosol),
            además de las leyendas precautorias que se requieran conforme al producto de que
            se trate, las siguientes:
            <br />
            5.3.7.14.1 Que no se aplique cerca de los ojos o flama
            <br />
            5.3.7.14.2 Que no se exponga al calor
            <br />
            5.3.7.14.3 Que no se queme, ni perfore el envase
            <br />
            5.3.7.14.4 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.15 En los productos inflamables, además de las leyendas
            precautorias que se requieran conforme al producto de que se trate:
            <br />
            5.3.7.15.1 Que es inflamable
            <br />
            5.3.7.15.2 Que no se aplique cerca de los ojos o piel irritada
            <br />
            5.3.7.15.3 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.16 En productos que contengan ácido bórico y boratos
            (exceptuando productos para el baño y para la ondulación del cabello):
            <br />
            5.3.7.16.1 Que no se aplique a niños menores de tres años
            <br />
            5.3.7.16.2 Que no se aplique en piel irritada o lastimada
            (sólo si la concentración de borato soluble libre excede
            1.5% expresado en ácido bórico masa / masa)
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.17 En productos que contengan tetraboratos:
            <br />
            5.3.7.17.1 En productos para el baño:
            <br />
            5.3.7.17.1.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.2 En productos para el cabello:
            <br />
            5.3.7.17.2.1 Enjuagar abundantemente
            <br />
            5.3.7.17.3 En talcos:
            <br />
            5.3.7.17.3.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.3.2 No utilizar en pieles escoriadas o irritadas
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.18 En productos para niños que contengan ácido salicílico y sus sales:
            <br />
            5.3.7.18.1 Que no se use en niños menores de tres años
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.19 En los productos que contengan diclorofeno, clorobutanol,
            cloroacetamida, timerosal compuestos fenilmercúricos
            (ya sea ácido o sales) u oxibenzona:
            <br />
            5.3.7.19.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.20 Si el producto contiene más de 0,05% de glutaraldehído en el producto final:
            <br />
            5.3.7.20.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.21 En productos que permanezcan en la piel,
            si la concentración de yodopropinil butil carbamato es superior a 0.02%:
            <br />
            5.3.7.21.1 Indicar que contiene yodo
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.22 En productos que contengan más de 2% de amoniaco se indicará que lo contiene.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.23 En productos que contengan ácido tioglicólico, sus sales o esteres:
            <br />
            5.3.7.23.1 Para todos los productos:
            <br />
            5.3.7.23.1.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.23.1.2 Que se mantenga fuera del alcance de los niños
            <br />
            5.3.7.23.1.3 Que se siga el modo de empleo
            <br />
            5.3.7.23.1.4 Que se evite el contacto con los ojos
            <br />
            5.3.7.23.1.5 En caso de contacto con los ojos enjuague
            con abundante agua. Consulte al médico
            <br />
            5.3.7.23.2 Para el caso de productos para el cabello además de lo anterior:
            <br />
            5.3.7.23.2.1 Utilizar guantes adecuados
            <br />
            5.3.7.23.3 Para el caso de los Esteres del ácido tioglicólico además de lo anterior:
            <br />
            5.3.7.23.3.1 Puede causar sensibilización en caso de contacto con la piel
            <br />
            5.3.7.23.4 Para los productos para el cabello
            de uso profesional además de lo anterior, indicar:
            <br />
            5.3.7.23.4.1 Que es para uso profesional
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.24 En productos que contengan
            clorhidrato de aluminio/zirconio y sus complejos de glicina:
            <br />
            5.3.7.24.1 Que no se aplique sobre la piel irritada, o lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.25 En productos que contengan disulfuro de selenio:
            <br />
            5.3.7.25.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.25.2 Que se evite el contacto con los ojos y la piel lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.26 En productos que contengan
            peróxidos excepto cuando su uso sea como conservador:
            <br />
            5.3.7.26.1 Se deben usar guantes protectores
            (sólo cuando se trate de tratamientos capilares)
            <br />
            5.3.7.26.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.26.3 Que en caso de contacto con los ojos se laven inmediatamente con agua
            <br />
            5.3.7.26.4 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.26.5 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.27 En productos que contengan benzalconio como cloruro, bromuro o sacarinato:
            <br />
            5.3.7.27.1 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.28 En productos que contengan ácido oxálico sus ésteres y sus sales:
            <br />
            5.3.7.28.1 Reservado a los profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29 En productos que contengan Sulfuros alcalinos y alcalinotérreos:
            <br />
            5.3.7.29.1 Que se mantenga fuera del alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.30 En productos que contengan Hidróxido de estroncio:
            <br />
            5.3.7.30.1 Que se mantenga alejado del alcance de los niños
            <br />
            5.3.7.30.2 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.31 En productos que contengan Nitrato de plata:
            <br />
            5.3.7.31.1 Indicar que lo contiene
            <br />
            5.3.7.31.2 Que en caso de contacto con los ojos, lavarse inmediatamente con agua
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-38',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-141-SSA1/SCFI-2012, Etiquetado para productos cosméticos preenvasados. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Requisitos de etiquetado
            <br />
            5.1 Requisitos generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Presentación de la información.
            <br />
            Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información establecida en esta norma en
            idioma español, independientemente de que también pueda estar en otros idiomas,
            cuidando que los caracteres sean mayores o al menos iguales a aquellos en los que
            se presenta la información en otros idiomas; los cuales deben ser claros, visibles,
            indelebles y en colores contrastantes, fáciles de leer por el consumidor en
            circunstancias normales de compra y uso. No será necesario utilizar las comillas
            en el etiquetado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2. La información que se presente al consumidor, debe ser veraz y comprobable.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3. Las etiquetas que ostenten los productos objeto de esta norma, deben fijarse de
            manera tal que permanezcan disponibles hasta el momento de su compra y uso en
            condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Cuando por las características del producto, no sea posible que la(s) etiqueta(s)
            se encuentre(n) directamente sobre el envase primario o secundario se podrán
            anexar al mismo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Denominación genérica y específica del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.1 Todos los productos deben ostentar la denominación genérica, conforme
            a lo que se establece en el apéndice informativo "A" de esta norma,
            pudiendo incluir la específica. En caso de productos cuya denominación
            no se encuentre dentro del ordenamiento antes citado, su denominación
            será aquella que mejor los describa o la más común o usual, podrá
            usarse una ilustración o viñeta que represente el uso del producto cosmético.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 La información del numeral anterior, debe presentarse en español a
            excepción de las formas cosméticas que podrán declararse en su idioma
            original. Esta información podrá presentarse en la superficie principal
            del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6 Identificación del responsable del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.1 Debe figurar en la superficie de información del envase primario o
            secundario, el nombre, denominación o razón social y domicilio (calle, número,
            código postal, ciudad y estado) del responsable del producto. Tratándose de
            productos importados, estos datos podrán incorporarse al producto, en el
            Territorio Nacional después del despacho aduanero y antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.2 Leyenda que identifique al país de origen del producto o gentilicio,
            por ejemplo "Producto de...", "Hecho en...", "Manufacturado en..." u otras
            análogas, sujeto a lo dispuesto en los tratados internacionales de los
            cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7 Declaración de lote.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7.1 En cualquier parte del envase primario o secundario, debe
            figurar en todos los productos objeto de esta norma, la identificació
            del lote con una indicación en clave o en lenguaje claro, ya sea
            grabado, marcado con tinta indeleble o de cualquier otro modo similar,
            siempre y cuando éste sea claro y asegure su permanencia en condiciones normales de uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8 Instrucciones o modo de uso.
            <br />
            5.1.8.1 Deben figurar las instrucciones de uso u otros análogos en la
            superficie de información del envase primario o secundario o instructivo
            anexo de los siguientes productos: tintes, colorantes, coloración,
            decolorantes; permanentes; alaciadores permanentes; en productos para
            la piel cuya función primaria sea la protección solar, bronceadores,
            autobronceadores, depilatorios, epilatorios o en cualquier otro
            producto que lo requiera.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.2 En caso de que las instrucciones o modo de uso estén en un
            instructivo anexo deberá señalarse esta situación mediante la
            oración "léase instructivo anexo" o equivalente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.3 En productos para la piel cuya función primaria sea la
            protección solar, indicar mediante las frases siguientes o equivalentes:
            <br />
            5.1.8.3.1 Que se aplique antes de la exposición al sol.
            <br />
            5.1.8.3.2 Que para mantener la protección, se repita con frecuencia la
            aplicación del producto, especialmente después de transpirar, bañarse o secarse.
            <br />
            5.1.8.3.3. Que se aplique a la piel la cantidad suficiente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9 Declaraciones prohibidas de propiedades.
            <br />
            Se prohíbe el uso de las siguientes declaraciones:
            <br />
            5.1.9.1 Declaración de propiedades que no pueden comprobarse.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.2 No podrán atribuirse a los productos cosméticos,
            acciones propias de los medicamentos.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.3 En la comercialización de los productos cosméticos,
            el etiquetado no utilizará textos, denominaciones, marcas,
            imágenes o cualquier otro símbolo figurativo o no, con el
            fin de atribuir a estos productos características o propiedades de las que carecen.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10 En los envases múltiples o colectivos será necesario declarar únicamente,
            la información de etiquetado que no contengan los productos, de forma individual.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.1 La información anterior debe aparecer en la superficie principal de
            exhibición o en la de información, sin restricción en el tamaño de la letra
            utilizada, siempre que sea fácil de leer por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2 Para los productos que se comercialicen en envases múltiples o colectivos:
            5.1.10.2.1 La declaración de la cantidad puede expresarse indistintamente por cuenta
            numérica por los envases que contiene o por contenido neto, excepto cuando el
            contenido o contenido neto sea obvio, no siendo restrictivo la ubicación y
            tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2.2 Los envases individuales deben contener la información completa que establece
            este ordenamiento, la declaración de cantidad del dato cuantitativo de acuerdo con lo
            establecido en este ordenamiento. En el caso de que los envases individuales no
            contengan la declaración de cantidad, ésta debe declararse en el envase múltiple
            o colectivo, no siendo restrictivo la ubicación y tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 23,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2. Información Comercial
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Se debe cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002
            Sistema General de Unidades de Medida, sin perjuicio de que además se puedan
            utilizar unidades de medida de otro sistema.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Para la declaración de cantidad se considera suficiente que los
            envases ostenten el dato cuantitativo, seguido de la unidad correspondiente
            a la magnitud aplicable, sin que sea necesario ostentar las leyendas
            "CONTENIDO", "CONTENIDO NETO", O SUS ABREVIATURAS, "CONT", "CONT.NET".
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 La declaración anterior debe aparecer en la superficie principal
            de exhibición o en la de información del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 El tamaño de la declaración de contenido o contenido neto debe
            ser de acuerdo a lo establecido en la Norma Oficial Mexicana NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 En las muestras o ayudas de venta y amenidades debe figurar,
            en cualquier parte del envase la siguiente información: denominación
            del producto, nombre del responsable del producto, número de lote y
            cuando aplique, considerando lo establecido en esta norma, incluir
            las instrucciones de uso y/o leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 En las muestras o ayudas de venta de lociones y fragancias cuyo
            contenido neto sea menor o igual a 2 ml debe figurar en el envase secundario
            al menos la marca comercial, la denominación del producto, para los productos
            importados ésta podrá ser la del país de origen, la razón social del fabricante
            del mismo, y cuando aplique leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Información Sanitaria
            <br />
            5.3.1. En los productos objeto de esta norma, debe figurar en caracteres
            visibles, en cualesquiera de las etiquetas que se encuentran en la superficie
            de información del envase primario o secundario, la lista de los nombres de
            los ingredientes de la fórmula debe ir precedida por el término
            "ingredientes" y enlistarse:
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 por orden cuantitativo decreciente; o
            <br />
            5.3.1.2 por orden cuantitativo decreciente aquellos ingredientes
            cuya concentración sea superior al 1% seguido por aquellos
            ingredientes en concentración inferior o igual al 1% que
            podrán mencionarse en cualquier orden.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Quedan exceptuadas de la declaración de los nombres de
            los ingredientes, los perfumes y fragancias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Para la nomenclatura de los ingredientes, puede emplearse a
            elección del fabricante cualquiera de las establecidas en los Acuerdos,
            o el nombre químico más usual o el nombre tal cual como aparece en
            la Nomenclatura Internacional de Ingredientes Cosméticos (INCI).
            <br />
            Las fragancias y sabores pueden designarse con el nombre genérico.
            <br />
            Los materiales de origen botánico deben designarse con el nombre
            científico de la planta, siendo opcional el nombre común de la misma.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Para la declaración de los nombres de los ingredientes en los
            productos con una o más presentaciones, en los que la fórmula base
            es la misma y sólo varía el uso de los colorantes, se incluirá la
            lista con los nombres de los ingredientes comunes de la fórmula,
            seguida de otra con todos los colorantes usados para las diversas
            presentaciones, anteponiendo a esta última el texto "puede contener"
            o "contiene uno o más" o "+/-".o equivalentes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            5.3.5 Para la declaración de los nombres de los ingredientes en aquellos
            productos que por su tamaño carecen de espacio, (como son lápices de cejas,
            delineadores, entre otros) ésta podrá figurar en el envase secundario si lo
            hubiere o bien en un volante impreso anexo al producto o en una etiqueta de bandera.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            5.3.6 En productos con una duración menor o igual a 24 meses debe figurar,
            en cualquier parte del envase primario o secundario, la fecha hasta la
            cual un producto, en condiciones adecuadas de almacenamiento, es seguro
            para la salud del consumidor, indicando al menos el mes y el año, o bien
            por el día, el mes y el año Este dato podrá o no ir precedido por la
            leyenda, a elección del fabricante:
            <br />
            Caducidad, Consumo preferente, Vencimiento, Duración mínima, Validez,
            Expiración, o equivalentes o sus abreviaturas.
            <br />
            Quedan exceptuados de la declaración de esta fecha, los productos que
            por sus características no permiten el crecimiento microbiano o que
            tienen una alta rotación de venta y uso, tales como: Aceites, Jabones
            sólidos, sales de baño, perfumes y derivados, desodorantes que no
            sean emulsiones, antitranspirantes, depilatorios, tintes y decolorantes,
            shampoo, acondicionadores, permanentes, relajantes permanentes de rizos
            y alaciadores permanentes, fijadores, oxidantes, productos para uñas,
            brillantinas, unidosis y productos en envases presurizados.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            <b>5.3.7.1 En desodorantes o antitranspirantes</b>
            <br />
            5.3.7.1.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.1.2 Que descontinúe su uso en caso de presentarse
            irritación, enrojecimiento o alguna molestia
            <br />
            5.3.7.1.3 Que no se deje al alcance de los niños
            <br />
            5.3.7.1.4 En caso de contener Fenolsulfonato de zinc
            mencionar que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2 En tintes, colorantes, coloración y otros relacionados:
            <br />
            5.3.7.2.1 Los colorantes del cabello pueden causar reacciones alérgicas graves
            <br />
            5.3.7.2.2 Lea y siga las instrucciones
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2.3 Este producto no está destinado a utilizarse en
            personas menores de dieciséis años.
            <br />
            5.3.7.2.4 Los tatuajes temporales de «henna negra» pueden aumentar el riesgo de alergia
            <br />
            5.3.7.2.5 No utilice el tinte capilar
            <br />
            5.3.7.2.5.1 Si tiene una erupción cutánea en el rostro o
            tiene el cuero cabelludo sensible, irritado o dañado
            <br />
            5.3.7.2.5.2 Si alguna vez ha experimentado cualquier tipo
            de reacción después de la coloración del cabello
            <br />
            5.3.7.2.5.3 Si alguna vez ha experimentado una reacción a los tatuajes
            temporales de «henna negra»
            <br />
            5.3.7.2.6 Que se realice una prueba preliminar de acuerdo a las instrucciones
            <br />
            5.3.7.2.7 Que puede causar alergia en algunas personas
            <br />
            5.3.7.2.8 Que suspenda su empleo en caso de irritación
            <br />
            5.3.7.2.9 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.2.10 Que se evite el contacto con los ojos
            <br />
            5.3.7.2.11 Que no se deje al alcance de los niños
            <br />
            5.3.7.2.12 Indicaciones de primeros auxilios para el caso
            <br />
            5.3.7.2.13 Que se usen guantes apropiados
            <br />
            5.3.7.2.14 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.2.15 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.3 En tintes, colorantes, coloración y otros que
            contengan alguna de las siguientes sustancias, además de
            las leyendas anteriores, se deberán indicar que la contienen:
            <br />
            5.3.7.3.1 Diaminobenceno y sus derivados
            <br />
            5.3.7.3.2 Diaminotolueno y sus derivados
            <br />
            5.3.7.3.3 Diaminofenol
            <br />
            5.3.7.3.4 Hidroquinona
            <br />
            5.3.7.3.5 Resorcinol
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.4 En permanentes y alaciadores permanentes
            <br />
            5.3.7.4.1 Que se destaque(n) la(s) sustancia(s)
            que puedan causar daño al cabello y piel cabelluda
            <br />
            5.3.7.4.2 Que no se aplique a cejas o pestañas
            <br />
            5.3.7.4.3 Que se evite el contacto con los ojos. Que puede causar ceguera
            <br />
            5.3.7.4.4 Que se use exclusivamente conforme al instructivo
            <br />
            5.3.7.4.5 Que no se deje al alcance de los niños
            <br />
            5.3.7.4.6 Además deben incluirse las indicaciones de primeros auxilios
            para el caso y recomendar la consulta a un médico
            <br />
            5.3.7.4.7 En productos profesionales además se debe indicar: Reservado a profesionales
            <br />
            5.3.7.4.8 En los productos que contengan alguna de las siguientes
            sustancias, además de lo anterior, se deberá indicar que las contienen:
            <br />
            5.3.7.4.8.1 Hidróxido de sodio
            <br />
            5.3.7.4.8.2 Hidróxido de potasio
            <br />
            5.3.7.4.8.3 Hidróxido de litio
            <br />
            5.3.7.4.8.4 Hidróxido de calcio
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.5 En decolorantes:
            <br />
            5.3.7.5.1 Que se destaque(n) la(s) sustancia(s) que puede causar daño
            <br />
            5.3.7.5.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.5.3 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.5.4 Que se usen guantes apropiados
            <br />
            5.3.7.5.5 Que no se aplique si la piel cabelluda está irritada
            <br />
            5.3.7.5.6 Que se suspenda su empleo en caso de irritación
            <br />
            5.3.7.5.7 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.5.8 Que no se deje al alcance de los niños
            <br />
            5.3.7.5.9 Que se den indicaciones de primeros auxilios para el caso
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.6 En depilatorios:
            <br />
            5.3.7.6.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.6.2 Que no se deje al alcance de los niños
            <br />
            5.3.7.6.3 Que se evite el contacto con los ojos
            <br />
            5.3.7.6.4 Los productos que contengan alguna de las siguientes
            sustancias además de las anteriores, se debe indicar que la contiene:
            <br />
            5.3.7.6.4.1 Hidróxido de sodio
            <br />
            5.3.7.6.4.2 Hidróxido de potasio
            <br />
            5.3.7.6.4.3 Hidróxido de litio
            <br />
            5.3.7.6.4.4 Hidróxido de calcio
            <br />
            Se podrá indicar de manera genérica "Contiene un agente alcalino"
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.7 En los endurecedores de uñas que contengan formaldehido:
            <br />
            5.3.7.7.1 Que se proteja la cutícula con sustancias grasosas
            <br />
            5.3.7.7.2 Indicar que lo contiene mediante
            la leyenda correspondiente (sólo si la concentración es superior a 0.05%)
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.9 En sistemas de uñas profesionales que
            contengan peróxido de benzoílo y/o hidroquinona:
            <br />
            5.3.7.9.1 Que está reservado a los profesionales
            <br />
            5.3.7.9.2 Que se evite el contacto con la piel
            <br />
            5.3.7.9.3 Que se lean las instrucciones de uso
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.10 En los productos para la piel
            cuya función primaria sea la de ofrecer protección solar:
            <br />
            5.3.7.10.1 Que se indique el valor del Factor de Protección Solar
            (FPS), en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.10.2 Que protege contra UVB y UVA
            <br />
            5.3.7.10.3 Que no permanezca mucho tiempo expuesto
            al sol, aunque emplee un producto de protección solar
            <br />
            5.3.7.10.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.10.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.10.6 Que suspenda su empleo si se presentan signos de irritación o salpullido
            <br />
            5.3.7.10.7 Evite el contacto con los ojos, puede causar irritación
            <br />
            5.3.7.10.8 Se podrá incluir el logotipo del Factor UVA, el cual deberá
            indicarse mediante las siglas "UVA" impresas dentro de un círculo simple
            y cuyo diámetro no deberá exceder la altura con que se indique el número FPS.
            <br />
            <img src="/images/UVA.png" alt="UVA" style={{ width: '60px' }} />
            <br />
            Los productos que ofrezcan protección solar como
            función secunda ria, no se consideran protectores
            solares por lo que no les aplican estas leyendas
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.11 En productos cuya función primaria sea
            la de broncear éstos deben tener un FPS de 2 a 4 (valor medido 2 a 5.9) y declararlo:
            <br />
            5.3.7.11.1 Que se indique el valor del Factor de Protección Solar (FPS),
            en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.11.2 Que suspenda su empleo si se presentan signos de irritación
            <br />
            5.3.7.11.3 Que no permanezca mucho tiempo expuesto al sol
            <br />
            5.3.7.11.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.11.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.11.6 Que no se recomienda para niños y personas con piel sensible al sol
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.12 En desodorantes femeninos en aerosol previstos para el uso en el área genital:
            <br />
            5.3.7.12.1 Este producto es exclusivo para uso externo solamente
            y no debe ser aplicado a piel con heridas, irritada o con escozor
            <br />
            5.3.7.13 En productos cuya presentación sea en ampolletas, iguales a la
            presentación farmacéutica, debe figurar en el envase múltiple o en cada
            ampolleta el texto: "no ingerible" "no inyectable"
            <br />
            5.3.7.14 En los productos cuya presentación es en envases presurizados (aerosol),
            además de las leyendas precautorias que se requieran conforme al producto de que
            se trate, las siguientes:
            <br />
            5.3.7.14.1 Que no se aplique cerca de los ojos o flama
            <br />
            5.3.7.14.2 Que no se exponga al calor
            <br />
            5.3.7.14.3 Que no se queme, ni perfore el envase
            <br />
            5.3.7.14.4 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.15 En los productos inflamables, además de las leyendas
            precautorias que se requieran conforme al producto de que se trate:
            <br />
            5.3.7.15.1 Que es inflamable
            <br />
            5.3.7.15.2 Que no se aplique cerca de los ojos o piel irritada
            <br />
            5.3.7.15.3 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.16 En productos que contengan ácido bórico y boratos
            (exceptuando productos para el baño y para la ondulación del cabello):
            <br />
            5.3.7.16.1 Que no se aplique a niños menores de tres años
            <br />
            5.3.7.16.2 Que no se aplique en piel irritada o lastimada
            (sólo si la concentración de borato soluble libre excede
            1.5% expresado en ácido bórico masa / masa)
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.17 En productos que contengan tetraboratos:
            <br />
            5.3.7.17.1 En productos para el baño:
            <br />
            5.3.7.17.1.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.2 En productos para el cabello:
            <br />
            5.3.7.17.2.1 Enjuagar abundantemente
            <br />
            5.3.7.17.3 En talcos:
            <br />
            5.3.7.17.3.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.3.2 No utilizar en pieles escoriadas o irritadas
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.18 En productos para niños que contengan ácido salicílico y sus sales:
            <br />
            5.3.7.18.1 Que no se use en niños menores de tres años
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.19 En los productos que contengan diclorofeno, clorobutanol,
            cloroacetamida, timerosal compuestos fenilmercúricos
            (ya sea ácido o sales) u oxibenzona:
            <br />
            5.3.7.19.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.20 Si el producto contiene más de 0,05% de glutaraldehído en el producto final:
            <br />
            5.3.7.20.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.21 En productos que permanezcan en la piel,
            si la concentración de yodopropinil butil carbamato es superior a 0.02%:
            <br />
            5.3.7.21.1 Indicar que contiene yodo
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.22 En productos que contengan más de 2% de amoniaco se indicará que lo contiene.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.23 En productos que contengan ácido tioglicólico, sus sales o esteres:
            <br />
            5.3.7.23.1 Para todos los productos:
            <br />
            5.3.7.23.1.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.23.1.2 Que se mantenga fuera del alcance de los niños
            <br />
            5.3.7.23.1.3 Que se siga el modo de empleo
            <br />
            5.3.7.23.1.4 Que se evite el contacto con los ojos
            <br />
            5.3.7.23.1.5 En caso de contacto con los ojos enjuague
            con abundante agua. Consulte al médico
            <br />
            5.3.7.23.2 Para el caso de productos para el cabello además de lo anterior:
            <br />
            5.3.7.23.2.1 Utilizar guantes adecuados
            <br />
            5.3.7.23.3 Para el caso de los Esteres del ácido tioglicólico además de lo anterior:
            <br />
            5.3.7.23.3.1 Puede causar sensibilización en caso de contacto con la piel
            <br />
            5.3.7.23.4 Para los productos para el cabello
            de uso profesional además de lo anterior, indicar:
            <br />
            5.3.7.23.4.1 Que es para uso profesional
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.24 En productos que contengan
            clorhidrato de aluminio/zirconio y sus complejos de glicina:
            <br />
            5.3.7.24.1 Que no se aplique sobre la piel irritada, o lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.25 En productos que contengan disulfuro de selenio:
            <br />
            5.3.7.25.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.25.2 Que se evite el contacto con los ojos y la piel lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.26 En productos que contengan
            peróxidos excepto cuando su uso sea como conservador:
            <br />
            5.3.7.26.1 Se deben usar guantes protectores
            (sólo cuando se trate de tratamientos capilares)
            <br />
            5.3.7.26.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.26.3 Que en caso de contacto con los ojos se laven inmediatamente con agua
            <br />
            5.3.7.26.4 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.26.5 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.27 En productos que contengan benzalconio como cloruro, bromuro o sacarinato:
            <br />
            5.3.7.27.1 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.28 En productos que contengan ácido oxálico sus ésteres y sus sales:
            <br />
            5.3.7.28.1 Reservado a los profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29 En productos que contengan Sulfuros alcalinos y alcalinotérreos:
            <br />
            5.3.7.29.1 Que se mantenga fuera del alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.30 En productos que contengan Hidróxido de estroncio:
            <br />
            5.3.7.30.1 Que se mantenga alejado del alcance de los niños
            <br />
            5.3.7.30.2 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.31 En productos que contengan Nitrato de plata:
            <br />
            5.3.7.31.1 Indicar que lo contiene
            <br />
            5.3.7.31.2 Que en caso de contacto con los ojos, lavarse inmediatamente con agua
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-38',
    documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-141-SSA1/SCFI-2012, Etiquetado para productos cosméticos preenvasados. Etiquetado sanitario y comercial.',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Requisitos de etiquetado
            <br />
            5.1 Requisitos generales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1 Presentación de la información.
            <br />
            Los productos destinados a ser comercializados en el mercado nacional,
            deben ostentar una etiqueta con la información establecida en esta norma en
            idioma español, independientemente de que también pueda estar en otros idiomas,
            cuidando que los caracteres sean mayores o al menos iguales a aquellos en los que
            se presenta la información en otros idiomas; los cuales deben ser claros, visibles,
            indelebles y en colores contrastantes, fáciles de leer por el consumidor en
            circunstancias normales de compra y uso. No será necesario utilizar las comillas
            en el etiquetado.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2. La información que se presente al consumidor, debe ser veraz y comprobable.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3. Las etiquetas que ostenten los productos objeto de esta norma, deben fijarse de
            manera tal que permanezcan disponibles hasta el momento de su compra y uso en
            condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Cuando por las características del producto, no sea posible que la(s) etiqueta(s)
            se encuentre(n) directamente sobre el envase primario o secundario se podrán
            anexar al mismo.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Denominación genérica y específica del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.1 Todos los productos deben ostentar la denominación genérica, conforme
            a lo que se establece en el apéndice informativo "A" de esta norma,
            pudiendo incluir la específica. En caso de productos cuya denominación
            no se encuentre dentro del ordenamiento antes citado, su denominación
            será aquella que mejor los describa o la más común o usual, podrá
            usarse una ilustración o viñeta que represente el uso del producto cosmético.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 La información del numeral anterior, debe presentarse en español a
            excepción de las formas cosméticas que podrán declararse en su idioma
            original. Esta información podrá presentarse en la superficie principal
            del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6 Identificación del responsable del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.1 Debe figurar en la superficie de información del envase primario o
            secundario, el nombre, denominación o razón social y domicilio (calle, número,
            código postal, ciudad y estado) del responsable del producto. Tratándose de
            productos importados, estos datos podrán incorporarse al producto, en el
            Territorio Nacional después del despacho aduanero y antes de su comercialización.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.1.6.2 Leyenda que identifique al país de origen del producto o gentilicio,
            por ejemplo "Producto de...", "Hecho en...", "Manufacturado en..." u otras
            análogas, sujeto a lo dispuesto en los tratados internacionales de los
            cuales México sea parte.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7 Declaración de lote.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            5.1.7.1 En cualquier parte del envase primario o secundario, debe
            figurar en todos los productos objeto de esta norma, la identificació
            del lote con una indicación en clave o en lenguaje claro, ya sea
            grabado, marcado con tinta indeleble o de cualquier otro modo similar,
            siempre y cuando éste sea claro y asegure su permanencia en condiciones normales de uso.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8 Instrucciones o modo de uso.
            <br />
            5.1.8.1 Deben figurar las instrucciones de uso u otros análogos en la
            superficie de información del envase primario o secundario o instructivo
            anexo de los siguientes productos: tintes, colorantes, coloración,
            decolorantes; permanentes; alaciadores permanentes; en productos para
            la piel cuya función primaria sea la protección solar, bronceadores,
            autobronceadores, depilatorios, epilatorios o en cualquier otro
            producto que lo requiera.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.2 En caso de que las instrucciones o modo de uso estén en un
            instructivo anexo deberá señalarse esta situación mediante la
            oración "léase instructivo anexo" o equivalente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            5.1.8.3 En productos para la piel cuya función primaria sea la
            protección solar, indicar mediante las frases siguientes o equivalentes:
            <br />
            5.1.8.3.1 Que se aplique antes de la exposición al sol.
            <br />
            5.1.8.3.2 Que para mantener la protección, se repita con frecuencia la
            aplicación del producto, especialmente después de transpirar, bañarse o secarse.
            <br />
            5.1.8.3.3. Que se aplique a la piel la cantidad suficiente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9 Declaraciones prohibidas de propiedades.
            <br />
            Se prohíbe el uso de las siguientes declaraciones:
            <br />
            5.1.9.1 Declaración de propiedades que no pueden comprobarse.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.2 No podrán atribuirse a los productos cosméticos,
            acciones propias de los medicamentos.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            5.1.9.3 En la comercialización de los productos cosméticos,
            el etiquetado no utilizará textos, denominaciones, marcas,
            imágenes o cualquier otro símbolo figurativo o no, con el
            fin de atribuir a estos productos características o propiedades de las que carecen.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10 En los envases múltiples o colectivos será necesario declarar únicamente,
            la información de etiquetado que no contengan los productos, de forma individual.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.1 La información anterior debe aparecer en la superficie principal de
            exhibición o en la de información, sin restricción en el tamaño de la letra
            utilizada, siempre que sea fácil de leer por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2 Para los productos que se comercialicen en envases múltiples o colectivos:
            5.1.10.2.1 La declaración de la cantidad puede expresarse indistintamente por cuenta
            numérica por los envases que contiene o por contenido neto, excepto cuando el
            contenido o contenido neto sea obvio, no siendo restrictivo la ubicación y
            tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 22,
        text: (
          <p className="table-content__row__cell--text">
            5.1.10.2.2 Los envases individuales deben contener la información completa que establece
            este ordenamiento, la declaración de cantidad del dato cuantitativo de acuerdo con lo
            establecido en este ordenamiento. En el caso de que los envases individuales no
            contengan la declaración de cantidad, ésta debe declararse en el envase múltiple
            o colectivo, no siendo restrictivo la ubicación y tamaño de la letra utilizada.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 23,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5.2. Información Comercial
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            5.2.1 Se debe cumplir con lo que establece la Norma Oficial Mexicana NOM-008-SCFI-2002
            Sistema General de Unidades de Medida, sin perjuicio de que además se puedan
            utilizar unidades de medida de otro sistema.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Para la declaración de cantidad se considera suficiente que los
            envases ostenten el dato cuantitativo, seguido de la unidad correspondiente
            a la magnitud aplicable, sin que sea necesario ostentar las leyendas
            "CONTENIDO", "CONTENIDO NETO", O SUS ABREVIATURAS, "CONT", "CONT.NET".
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 La declaración anterior debe aparecer en la superficie principal
            de exhibición o en la de información del envase primario o secundario.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 El tamaño de la declaración de contenido o contenido neto debe
            ser de acuerdo a lo establecido en la Norma Oficial Mexicana NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 28,
        text: (
          <p className="table-content__row__cell--text">
            5.2.5 En las muestras o ayudas de venta y amenidades debe figurar,
            en cualquier parte del envase la siguiente información: denominación
            del producto, nombre del responsable del producto, número de lote y
            cuando aplique, considerando lo establecido en esta norma, incluir
            las instrucciones de uso y/o leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            5.2.6 En las muestras o ayudas de venta de lociones y fragancias cuyo
            contenido neto sea menor o igual a 2 ml debe figurar en el envase secundario
            al menos la marca comercial, la denominación del producto, para los productos
            importados ésta podrá ser la del país de origen, la razón social del fabricante
            del mismo, y cuando aplique leyendas precautorias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Información Sanitaria
            <br />
            5.3.1. En los productos objeto de esta norma, debe figurar en caracteres
            visibles, en cualesquiera de las etiquetas que se encuentran en la superficie
            de información del envase primario o secundario, la lista de los nombres de
            los ingredientes de la fórmula debe ir precedida por el término
            "ingredientes" y enlistarse:
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 31,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 por orden cuantitativo decreciente; o
            <br />
            5.3.1.2 por orden cuantitativo decreciente aquellos ingredientes
            cuya concentración sea superior al 1% seguido por aquellos
            ingredientes en concentración inferior o igual al 1% que
            podrán mencionarse en cualquier orden.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Quedan exceptuadas de la declaración de los nombres de
            los ingredientes, los perfumes y fragancias.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 33,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Para la nomenclatura de los ingredientes, puede emplearse a
            elección del fabricante cualquiera de las establecidas en los Acuerdos,
            o el nombre químico más usual o el nombre tal cual como aparece en
            la Nomenclatura Internacional de Ingredientes Cosméticos (INCI).
            <br />
            Las fragancias y sabores pueden designarse con el nombre genérico.
            <br />
            Los materiales de origen botánico deben designarse con el nombre
            científico de la planta, siendo opcional el nombre común de la misma.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Para la declaración de los nombres de los ingredientes en los
            productos con una o más presentaciones, en los que la fórmula base
            es la misma y sólo varía el uso de los colorantes, se incluirá la
            lista con los nombres de los ingredientes comunes de la fórmula,
            seguida de otra con todos los colorantes usados para las diversas
            presentaciones, anteponiendo a esta última el texto "puede contener"
            o "contiene uno o más" o "+/-".o equivalentes.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 35,
        text: (
          <p className="table-content__row__cell--text">
            5.3.5 Para la declaración de los nombres de los ingredientes en aquellos
            productos que por su tamaño carecen de espacio, (como son lápices de cejas,
            delineadores, entre otros) ésta podrá figurar en el envase secundario si lo
            hubiere o bien en un volante impreso anexo al producto o en una etiqueta de bandera.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            5.3.6 En productos con una duración menor o igual a 24 meses debe figurar,
            en cualquier parte del envase primario o secundario, la fecha hasta la
            cual un producto, en condiciones adecuadas de almacenamiento, es seguro
            para la salud del consumidor, indicando al menos el mes y el año, o bien
            por el día, el mes y el año Este dato podrá o no ir precedido por la
            leyenda, a elección del fabricante:
            <br />
            Caducidad, Consumo preferente, Vencimiento, Duración mínima, Validez,
            Expiración, o equivalentes o sus abreviaturas.
            <br />
            Quedan exceptuados de la declaración de esta fecha, los productos que
            por sus características no permiten el crecimiento microbiano o que
            tienen una alta rotación de venta y uso, tales como: Aceites, Jabones
            sólidos, sales de baño, perfumes y derivados, desodorantes que no
            sean emulsiones, antitranspirantes, depilatorios, tintes y decolorantes,
            shampoo, acondicionadores, permanentes, relajantes permanentes de rizos
            y alaciadores permanentes, fijadores, oxidantes, productos para uñas,
            brillantinas, unidosis y productos en envases presurizados.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 37,
        text: (
          <p className="table-content__row__cell--text">
            <b>5.3.7.1 En desodorantes o antitranspirantes</b>
            <br />
            5.3.7.1.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.1.2 Que descontinúe su uso en caso de presentarse
            irritación, enrojecimiento o alguna molestia
            <br />
            5.3.7.1.3 Que no se deje al alcance de los niños
            <br />
            5.3.7.1.4 En caso de contener Fenolsulfonato de zinc
            mencionar que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2 En tintes, colorantes, coloración y otros relacionados:
            <br />
            5.3.7.2.1 Los colorantes del cabello pueden causar reacciones alérgicas graves
            <br />
            5.3.7.2.2 Lea y siga las instrucciones
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.2.3 Este producto no está destinado a utilizarse en
            personas menores de dieciséis años.
            <br />
            5.3.7.2.4 Los tatuajes temporales de «henna negra» pueden aumentar el riesgo de alergia
            <br />
            5.3.7.2.5 No utilice el tinte capilar
            <br />
            5.3.7.2.5.1 Si tiene una erupción cutánea en el rostro o
            tiene el cuero cabelludo sensible, irritado o dañado
            <br />
            5.3.7.2.5.2 Si alguna vez ha experimentado cualquier tipo
            de reacción después de la coloración del cabello
            <br />
            5.3.7.2.5.3 Si alguna vez ha experimentado una reacción a los tatuajes
            temporales de «henna negra»
            <br />
            5.3.7.2.6 Que se realice una prueba preliminar de acuerdo a las instrucciones
            <br />
            5.3.7.2.7 Que puede causar alergia en algunas personas
            <br />
            5.3.7.2.8 Que suspenda su empleo en caso de irritación
            <br />
            5.3.7.2.9 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.2.10 Que se evite el contacto con los ojos
            <br />
            5.3.7.2.11 Que no se deje al alcance de los niños
            <br />
            5.3.7.2.12 Indicaciones de primeros auxilios para el caso
            <br />
            5.3.7.2.13 Que se usen guantes apropiados
            <br />
            5.3.7.2.14 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.2.15 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.3 En tintes, colorantes, coloración y otros que
            contengan alguna de las siguientes sustancias, además de
            las leyendas anteriores, se deberán indicar que la contienen:
            <br />
            5.3.7.3.1 Diaminobenceno y sus derivados
            <br />
            5.3.7.3.2 Diaminotolueno y sus derivados
            <br />
            5.3.7.3.3 Diaminofenol
            <br />
            5.3.7.3.4 Hidroquinona
            <br />
            5.3.7.3.5 Resorcinol
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 41,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.4 En permanentes y alaciadores permanentes
            <br />
            5.3.7.4.1 Que se destaque(n) la(s) sustancia(s)
            que puedan causar daño al cabello y piel cabelluda
            <br />
            5.3.7.4.2 Que no se aplique a cejas o pestañas
            <br />
            5.3.7.4.3 Que se evite el contacto con los ojos. Que puede causar ceguera
            <br />
            5.3.7.4.4 Que se use exclusivamente conforme al instructivo
            <br />
            5.3.7.4.5 Que no se deje al alcance de los niños
            <br />
            5.3.7.4.6 Además deben incluirse las indicaciones de primeros auxilios
            para el caso y recomendar la consulta a un médico
            <br />
            5.3.7.4.7 En productos profesionales además se debe indicar: Reservado a profesionales
            <br />
            5.3.7.4.8 En los productos que contengan alguna de las siguientes
            sustancias, además de lo anterior, se deberá indicar que las contienen:
            <br />
            5.3.7.4.8.1 Hidróxido de sodio
            <br />
            5.3.7.4.8.2 Hidróxido de potasio
            <br />
            5.3.7.4.8.3 Hidróxido de litio
            <br />
            5.3.7.4.8.4 Hidróxido de calcio
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.5 En decolorantes:
            <br />
            5.3.7.5.1 Que se destaque(n) la(s) sustancia(s) que puede causar daño
            <br />
            5.3.7.5.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.5.3 En caso de que el producto entre en
            contacto con los ojos, que se enjuaguen inmediatamente con agua
            <br />
            5.3.7.5.4 Que se usen guantes apropiados
            <br />
            5.3.7.5.5 Que no se aplique si la piel cabelluda está irritada
            <br />
            5.3.7.5.6 Que se suspenda su empleo en caso de irritación
            <br />
            5.3.7.5.7 Que no se aplique en cejas o pestañas
            <br />
            5.3.7.5.8 Que no se deje al alcance de los niños
            <br />
            5.3.7.5.9 Que se den indicaciones de primeros auxilios para el caso
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.6 En depilatorios:
            <br />
            5.3.7.6.1 Que no se aplique sobre piel irritada o lastimada
            <br />
            5.3.7.6.2 Que no se deje al alcance de los niños
            <br />
            5.3.7.6.3 Que se evite el contacto con los ojos
            <br />
            5.3.7.6.4 Los productos que contengan alguna de las siguientes
            sustancias además de las anteriores, se debe indicar que la contiene:
            <br />
            5.3.7.6.4.1 Hidróxido de sodio
            <br />
            5.3.7.6.4.2 Hidróxido de potasio
            <br />
            5.3.7.6.4.3 Hidróxido de litio
            <br />
            5.3.7.6.4.4 Hidróxido de calcio
            <br />
            Se podrá indicar de manera genérica "Contiene un agente alcalino"
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.7 En los endurecedores de uñas que contengan formaldehido:
            <br />
            5.3.7.7.1 Que se proteja la cutícula con sustancias grasosas
            <br />
            5.3.7.7.2 Indicar que lo contiene mediante
            la leyenda correspondiente (sólo si la concentración es superior a 0.05%)
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 45,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.8 En removedores de cutícula que contengan hidróxido de sodio o potasio:
            <br />
            5.3.7.8.1 Que contiene potasa o sosa, según
            corresponda o que contiene un agente alcalino
            <br />
            5.3.7.8.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.8.3 Que puede causar ceguera
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.9 En sistemas de uñas profesionales que
            contengan peróxido de benzoílo y/o hidroquinona:
            <br />
            5.3.7.9.1 Que está reservado a los profesionales
            <br />
            5.3.7.9.2 Que se evite el contacto con la piel
            <br />
            5.3.7.9.3 Que se lean las instrucciones de uso
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.10 En los productos para la piel
            cuya función primaria sea la de ofrecer protección solar:
            <br />
            5.3.7.10.1 Que se indique el valor del Factor de Protección Solar
            (FPS), en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.10.2 Que protege contra UVB y UVA
            <br />
            5.3.7.10.3 Que no permanezca mucho tiempo expuesto
            al sol, aunque emplee un producto de protección solar
            <br />
            5.3.7.10.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.10.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.10.6 Que suspenda su empleo si se presentan signos de irritación o salpullido
            <br />
            5.3.7.10.7 Evite el contacto con los ojos, puede causar irritación
            <br />
            5.3.7.10.8 Se podrá incluir el logotipo del Factor UVA, el cual deberá
            indicarse mediante las siglas "UVA" impresas dentro de un círculo simple
            y cuyo diámetro no deberá exceder la altura con que se indique el número FPS.
            <br />
            <img src="/images/UVA.png" alt="UVA" style={{ width: '60px' }} />
            <br />
            Los productos que ofrezcan protección solar como
            función secunda ria, no se consideran protectores
            solares por lo que no les aplican estas leyendas
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.11 En productos cuya función primaria sea
            la de broncear éstos deben tener un FPS de 2 a 4 (valor medido 2 a 5.9) y declararlo:
            <br />
            5.3.7.11.1 Que se indique el valor del Factor de Protección Solar (FPS),
            en caso de que se utilicen las siglas FPS o SPF, señalar su significado
            <br />
            5.3.7.11.2 Que suspenda su empleo si se presentan signos de irritación
            <br />
            5.3.7.11.3 Que no permanezca mucho tiempo expuesto al sol
            <br />
            5.3.7.11.4 Que se mantenga a los bebés y niños pequeños fuera de la luz solar directa
            <br />
            5.3.7.11.5 Que la exposición excesiva al sol es un riesgo importante para la salud
            <br />
            5.3.7.11.6 Que no se recomienda para niños y personas con piel sensible al sol
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.12 En desodorantes femeninos en aerosol previstos para el uso en el área genital:
            <br />
            5.3.7.12.1 Este producto es exclusivo para uso externo solamente
            y no debe ser aplicado a piel con heridas, irritada o con escozor
            <br />
            5.3.7.13 En productos cuya presentación sea en ampolletas, iguales a la
            presentación farmacéutica, debe figurar en el envase múltiple o en cada
            ampolleta el texto: "no ingerible" "no inyectable"
            <br />
            5.3.7.14 En los productos cuya presentación es en envases presurizados (aerosol),
            además de las leyendas precautorias que se requieran conforme al producto de que
            se trate, las siguientes:
            <br />
            5.3.7.14.1 Que no se aplique cerca de los ojos o flama
            <br />
            5.3.7.14.2 Que no se exponga al calor
            <br />
            5.3.7.14.3 Que no se queme, ni perfore el envase
            <br />
            5.3.7.14.4 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.15 En los productos inflamables, además de las leyendas
            precautorias que se requieran conforme al producto de que se trate:
            <br />
            5.3.7.15.1 Que es inflamable
            <br />
            5.3.7.15.2 Que no se aplique cerca de los ojos o piel irritada
            <br />
            5.3.7.15.3 Que no se deje al alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.16 En productos que contengan ácido bórico y boratos
            (exceptuando productos para el baño y para la ondulación del cabello):
            <br />
            5.3.7.16.1 Que no se aplique a niños menores de tres años
            <br />
            5.3.7.16.2 Que no se aplique en piel irritada o lastimada
            (sólo si la concentración de borato soluble libre excede
            1.5% expresado en ácido bórico masa / masa)
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 53,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.17 En productos que contengan tetraboratos:
            <br />
            5.3.7.17.1 En productos para el baño:
            <br />
            5.3.7.17.1.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.2 En productos para el cabello:
            <br />
            5.3.7.17.2.1 Enjuagar abundantemente
            <br />
            5.3.7.17.3 En talcos:
            <br />
            5.3.7.17.3.1 Que no se use en niños menores de tres años
            <br />
            5.3.7.17.3.2 No utilizar en pieles escoriadas o irritadas
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.18 En productos para niños que contengan ácido salicílico y sus sales:
            <br />
            5.3.7.18.1 Que no se use en niños menores de tres años
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.19 En los productos que contengan diclorofeno, clorobutanol,
            cloroacetamida, timerosal compuestos fenilmercúricos
            (ya sea ácido o sales) u oxibenzona:
            <br />
            5.3.7.19.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.20 Si el producto contiene más de 0,05% de glutaraldehído en el producto final:
            <br />
            5.3.7.20.1 Indicar que lo contiene mediante la leyenda correspondiente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.21 En productos que permanezcan en la piel,
            si la concentración de yodopropinil butil carbamato es superior a 0.02%:
            <br />
            5.3.7.21.1 Indicar que contiene yodo
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 58,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.22 En productos que contengan más de 2% de amoniaco se indicará que lo contiene.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.23 En productos que contengan ácido tioglicólico, sus sales o esteres:
            <br />
            5.3.7.23.1 Para todos los productos:
            <br />
            5.3.7.23.1.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.23.1.2 Que se mantenga fuera del alcance de los niños
            <br />
            5.3.7.23.1.3 Que se siga el modo de empleo
            <br />
            5.3.7.23.1.4 Que se evite el contacto con los ojos
            <br />
            5.3.7.23.1.5 En caso de contacto con los ojos enjuague
            con abundante agua. Consulte al médico
            <br />
            5.3.7.23.2 Para el caso de productos para el cabello además de lo anterior:
            <br />
            5.3.7.23.2.1 Utilizar guantes adecuados
            <br />
            5.3.7.23.3 Para el caso de los Esteres del ácido tioglicólico además de lo anterior:
            <br />
            5.3.7.23.3.1 Puede causar sensibilización en caso de contacto con la piel
            <br />
            5.3.7.23.4 Para los productos para el cabello
            de uso profesional además de lo anterior, indicar:
            <br />
            5.3.7.23.4.1 Que es para uso profesional
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.24 En productos que contengan
            clorhidrato de aluminio/zirconio y sus complejos de glicina:
            <br />
            5.3.7.24.1 Que no se aplique sobre la piel irritada, o lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.25 En productos que contengan disulfuro de selenio:
            <br />
            5.3.7.25.1 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.25.2 Que se evite el contacto con los ojos y la piel lastimada
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.26 En productos que contengan
            peróxidos excepto cuando su uso sea como conservador:
            <br />
            5.3.7.26.1 Se deben usar guantes protectores
            (sólo cuando se trate de tratamientos capilares)
            <br />
            5.3.7.26.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.26.3 Que en caso de contacto con los ojos se laven inmediatamente con agua
            <br />
            5.3.7.26.4 Indicar que lo contiene mediante la leyenda correspondiente
            <br />
            5.3.7.26.5 En productos profesionales además se debe indicar: Reservado a profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.27 En productos que contengan benzalconio como cloruro, bromuro o sacarinato:
            <br />
            5.3.7.27.1 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.28 En productos que contengan ácido oxálico sus ésteres y sus sales:
            <br />
            5.3.7.28.1 Reservado a los profesionales
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29 En productos que contengan Sulfuros alcalinos y alcalinotérreos:
            <br />
            5.3.7.29.1 Que se mantenga fuera del alcance de los niños
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.29.2 Que se evite el contacto con los ojos
            <br />
            5.3.7.30 En productos que contengan Hidróxido de estroncio:
            <br />
            5.3.7.30.1 Que se mantenga alejado del alcance de los niños
            <br />
            5.3.7.30.2 Que se evite el contacto con los ojos
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            5.3.7.31 En productos que contengan Nitrato de plata:
            <br />
            5.3.7.31.1 Indicar que lo contiene
            <br />
            5.3.7.31.2 Que en caso de contacto con los ojos, lavarse inmediatamente con agua
          </p>
        )
      }
    ]
  }
};
