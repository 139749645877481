/* eslint-disable no-console */
import React, { useEffect, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '..';
import { FileInputData } from '../../interfaces';

import './styles.scss';

interface Props {
  onSelectFile: (_file: FileInputData[]) => void
  acceptedFileFormats: string
  fileName?: string
  isMultiple: boolean
  hasError?: boolean
  helperText?: string
  id?: string
}

const FileInput: React.FC<Props> = (props) => {
  const {
    onSelectFile,
    acceptedFileFormats,
    fileName = '',
    isMultiple,
    hasError = false,
    helperText = '',
    id = useId()
  } = props;

  const { t, i18n } = useTranslation();

  const handleSelectFile = (filesData: FileInputData[]) => {
    onSelectFile(filesData);

    // eslint-disable-next-line no-undef
    const fileInput: any = document.getElementById(id);

    if (fileInput) {
      fileInput.value = null;
    }
  };

  const onSetFile = async (event: any) => {
    try {
      const filesData: FileInputData[] = [];

      const { files } = event.target;

      for (let index = 0; index < files.length; index += 1) {
        filesData.push({
          fileName: files[index].name.replace(/[%&?¿=#/+]/g, ''),
          data: files[index]
        });
      }

      handleSelectFile(filesData);
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const dropHandler = (event: any) => {
    const filesData: FileInputData[] = [];
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();

          filesData.push({
            fileName: file.name.replace(/[%&?¿=#/+]/g, ''),
            data: file
          });
        }
      }

      handleSelectFile(filesData);
    } else {
      for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
        filesData.push({
          fileName: event.dataTransfer.files[i].name.replace(/[%&?¿=#/+]/g, ''),
          data: event.dataTransfer.files[i]
        });
      }

      handleSelectFile(filesData);
    }

    removeDragData(event);
  };

  const dragOverHandler = (event: any) => {
    event.preventDefault();
  };

  const removeDragData = (event: any) => {
    if (event.dataTransfer.items) {
      event.dataTransfer.items.clear();
    } else {
      event.dataTransfer.clearData();
    }
  };

  const fixedFileName = fileName && fileName !== '' ? fileName.split('/').pop() : fileName;

  useEffect(() => {
    if (hasError) {
      // eslint-disable-next-line no-undef
      const errorElement = document.querySelectorAll('#file-input-error');

      if (errorElement[0]) {
        errorElement[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [hasError, helperText]);

  return (
    <div
      className='file-input__container'
      id="file-input-container"
    >
      {
        fixedFileName && (
          <span className={`file-input-title file-input-title${hasError ? '--error' : ''}`}>{fixedFileName.replace(/[%&?¿=#/+]/g, '')}</span>
        )
      }
      <input
        id={id}
        name='file-input'
        aria-label={t('global.file') || ''}
        type='file'
        accept={acceptedFileFormats}
        onChange={onSetFile}
        multiple={isMultiple}
        className={`file-input file-input${hasError ? '--error' : ''}`}
      />
      <label
        htmlFor={id}
        id={hasError ? 'file-input-error' : ''}
        className={`file-input__label file-input__label${hasError ? '--error' : '--empty'}`}
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
      >
        <Icon type='upload' className='file-input__icon' />
        {
          isMultiple ? (
            <p className={`file-input__label__multiple-${i18n.language}`} />
          ) : (
            <p className={`file-input__label__single-${i18n.language}`} />
          )
        }
        <div className={`file-input__button file-input__button${hasError ? '--error' : ''}`}>{t('global.clickHere')}</div>
      </label>
      {
        helperText && (
          <p className={`file-input__helperText file-input__helperText${hasError ? '--error' : ''}`}>{helperText}</p>
        )
      }
    </div>
  );
};

export default FileInput;
