import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import storage from '../../storage';

import { useNavigate, useResource } from '../../hooks';
import { Session } from '../../interfaces';
import { Modal } from '../../components';
import { AppContext } from '../../context/AppContext';

const elementsToSaveOnLocalStorage: string[] = [
  'mainMenuSelectedIndex',
  'lateralMenuInspectionsValue',
  'statusInspectionsValue',
  'lastTaskDetailID',
  'lastInspectionDetailID',
  'filterValueOnTasksView',
  'lateralMenuSEProceduresValue'
];

const Autologin: React.FC = () => {
  const params = useParams();

  const { userToken } = params;

  const navigate = useNavigate();

  const {
    setApiType,
    setUserRole,
    setUserName,
    soundEffects,
    setUserID
  } = useContext(AppContext);

  const {
    updateResource
  } = useResource<Session>();

  useEffect(() => {
    storage.set('sessionToken', userToken);

    updateResource(
      `/sessions/${userToken}`,
      {},
      (data) => {
        storage.set('sessionToken', data.token);
        storage.set('apiType', data.role === 'collaborator' ? 'public' : 'admin');
        storage.set('selectedListOnTasksView', 'tasks');
        storage.set('selectedListOnApproverView', 'inspections');
        storage.set('selectedOptionOnTasksView', 'none');
        storage.set('userRole', data.role);
        storage.set('userName', data.name);
        storage.set('userID', data.userID);

        setApiType(data.role === 'collaborator' ? 'public' : 'admin');
        setUserRole(data.role);
        setUserName(data.name);
        setUserID(data.userID);

        for (let index = 0; index < elementsToSaveOnLocalStorage.length; index += 1) {
          storage.set(elementsToSaveOnLocalStorage[index], '');
        }

        const binnacleFilters = {
          elementsType: 'binnacle',
          element: 'services',
          type: 'dictum',
          pagination: {
            currentPage: 1,
            perPage: 20,
            totalItems: 0
          },
          orderBy: 's_createdAt',
          orderDirection: 'DESC',
          filters: {}
        };

        storage.set('binnacleFilters', JSON.stringify(binnacleFilters));

        if (data.role === 'finance') {
          navigate('/binnacle');
        } else if (data.role === 'collaborator') {
          navigate('/binnacle');
        } else if (data.role === 'trainee') {
          navigate('/binnacle');
        } else if (data.role === 'quality') {
          navigate('/tasks/none');
        } else if (data.approver && data.role !== 'master') {
          navigate('/approver-view');
        } else {
          navigate('/tasks/none');
        }
      },
      (error: string) => {
        Modal.fireError(error, undefined, soundEffects);
      }
    );
  }, []);

  return (
    <>
    </>
  );
};

export default Autologin;
