/* eslint-disable max-len */
export default {
  DC: {
    documentCode: 'F7-51 V1',
    documentDescription: 'Lista de inspección para la NOM-173-SE-2021',
    documentIssueDate: '01/03/2023',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones
            <br />4.1 Requisitos generales de etiquetado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 La información contenida en las etiquetas de los
            alimentos y bebidas no alcohólicas preenvasados debe ser
            veraz y describirse y presentarse de forma tal que no
            induzca a error al consumidor con respecto a la naturaleza
            y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Los productos preenvasados deben presentarse con una
            etiqueta en la que se describa o se empleen palabras,
            ilustraciones u otras representaciones gráficas que se refieran al producto.
            <br />
            <br />
            Las etiquetas que contengan los productos preenvasados pueden
            incorporar la descripción gráfica o descriptiva de la sugerencia
            de uso, empleo o preparación, a condición de que aparezca una
            leyenda alusiva al respecto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Los alimentos y bebidas no alcohólicas preenvasados no
            deberán describirse ni presentarse con una etiqueta en los que
            se utilicen palabras, textos, diálogos, ilustraciones, imágenes,
            denominaciones de origen y otras descripciones que se refieran o
            sugieran, directa o indirectamente a cualquier otro producto
            con el que pueda confundirse, o que pueda inducir al consumidor
            a suponer que el alimento se relaciona en forma alguna con aquel
            otro producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 La etiqueta de los productos preenvasados que no contengan
            los sellos y leyendas precautorias, puede declararlo únicamente
            de forma escrita mediante la frase “Este producto no contiene
            sellos ni leyendas” y no debe utilizar elementos gráficos o
            descriptivo alusivos a los mismos. La declaración debe ser
            colocada en la superficie de información y, su tipografía y
            tamaño debe ser igual o menor al tamaño mínimo cuantitativo
            del contenido neto conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4. Bis En caso de que el producto ostente uno o mas sellos de advertencia o la leyenda de edulcorantes el producto no debe de: incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos  o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes; asi como hacer referencias a productos ajenos al mismo pero con la finalidad anteriormente mencionada
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Los productos preenvasados que ostenten uno o más sellos de advertencia o la leyenda de edulcorantes, no deben:
            <br /> a) incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos visual – espaciales o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes, y
            <br /> b) hacer referencia en la etiqueta a elementos ajenos al mismo con las mismas finalidades del párrafo anterior. La aplicación de este numeral se debe hacer en concordancia con lo dispuesto por otros ordenamientos
            legales aplicables.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Salvo lo indicado en el siguiente párrafo, son requisitos obligatorios de información comercial y sanitaria los siguientes.
            <br /> A menos de que se trate de especias y de hierbas aromáticas, los productos pequeños en que la superficie más amplia sea inferior a 10 cm2 podrán quedar exentos de los requisitos: lista de ingredientes, instrucciones de uso, lote y fecha de caducidad o de consumo preferente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1 Nombre o denominación de los productos preenvasados
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1. La denominación del producto preenvasado debe aparecer en negrillas dentro de la superficie principal de exhibición de la etiqueta, en línea paralela a la base como se encuentra diseñado el producto y cumpliendo con las disposiciones de denominación contenidas en una Norma Oficial Mexicana de producto preenvasado.
            <br />Junto a la denominación pueden adicionarse las palabras o frases necesarias para evitar que se induzca al error o engaño al consumidor con respecto a la naturaleza y que incluyen, pero no se limitan a:
            <br />a) el tipo de medio de cobertura;
            <br />b) la forma de presentación o su condición;
            <br />c) en el caso de que haya sido objeto de algún tipo de tratamiento, se puede indicar el nombre de éste, con excepción de aquellos que de acuerdo con los ordenamientos correspondientes sean de carácter obligatorio.
            <br /><br />
            Los elementos descritos anteriormente, forman parte de la denominación del producto preenvasado y deben describirse en forma conjunta, con un tamaño igual o mayor al del dato cuantitativo del contenido neto conforme a la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas) y con la misma proporcionalidad tipográfica, para ser igualmente visibles en la etiqueta y cumplir con lo establecido en este numeral.
            Para el caso de los productos imitación, la denominación del mismo aparecerá en la parte superior izquierda de la superficie principal de exhibición, colocando la palabra IMITACIÓN al principio en mayúsculas, con negrillas en fondo claro en un tamaño del doble al resto de la denominación. No se permite el uso de la palabra imitación en productos preenvasados que cuenten con denominación de origen o indicación geográfica protegida o reconocida por el Estado mexicano.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.1. La denominación del producto preenvasado debe corresponder a los establecidos en las Normas Oficiales Mexicanas u ordenamientos jurídicos específicos y en ausencia de éstos, se debe usar el siguiente orden de prelación para el nombre de una denominación de producto preenvasado:
            <br />a) Nombre de uso común;
            <br />b) Descripción de acuerdo con las características básicas de la composición y naturaleza del producto
            preenvasado, o
            <br />c) Norma internacional del Codex Alimentarius, en su caso.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.2. Los productos imitación no deben hacer uso de las palabras tales como “tipo”, “estilo” o algún otro término similar, en la denominación del producto preenvasado o dentro de la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2 Lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1. En la etiqueta del producto preenvasado cuya comercialización se haga en forma individual, debe figurar una lista de ingredientes, salvo cuando se trate de alimentos de un único ingrediente y no incluya algún aditivo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.1 La lista de ingredientes debe ir encabezada o precedida por el término Ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.2 Los ingredientes del alimento o bebida no alcohólica preenvasado deben enumerarse por orden cuantitativo decreciente (m/m).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.3. Los ingredientes compuestos deben declararse como tal en la lista de ingredientes, siempre que vayan acompañados inmediatamente de una lista entre paréntesis de sus ingredientes por orden decreciente de proporciones (m/m). Cuando un ingrediente compuesto, constituya menos del 5 por ciento del producto preenvasado, no será necesario declarar los ingredientes que lo conforman, salvo los aditivos alimentarios que desempeñan una función tecnológica en el producto terminado, o aditivos e ingredientes que se asocien a reacciones alérgicas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.4. Se debe indicar en la lista de ingredientes el agua añadida por orden de predominio, excepto cuando ésta forme parte de un ingrediente compuesto, por ejemplo, de manera enunciativa más no limitativa: la salmuera, el jarabe o el caldo, empleados y declarado como tal en la lista y la que se utilice en los procesos de cocción y reconstitución. No es necesario declarar el agua u otros ingredientes volátiles que se evaporan durante la fabricación.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.5 Cuando se trate de alimentos deshidratados o condensados, destinados a ser reconstituidos, pueden enumerarse sus ingredientes por orden cuantitativo decreciente (m/m) en el producto reconstituido, siempre que se incluya una indicación como la que sigue: "ingredientes del producto cuando se prepara según las instrucciones de la etiqueta", u otras leyendas análogas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.6 En la lista de ingredientes debe emplearse una denominación específica de acuerdo con lo previsto en el punto 4.2.2.1, incluyendo los ingredientes mencionados en 4.2.2.2.3, con excepción de los ingredientes cuyas denominaciones genéricas están señalados en la tabla 1.  Revisar Tabla 1 Denominación genérica de ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.7 No obstante lo estipulado en el punto anterior, la manteca de cerdo, la grasa de bovino o sebo, se deben declarar siempre por sus denominaciones específicas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.8 Los azúcares añadidos se deben declarar conforme a lo siguiente:
            a) agrupados anteponiendo las palabras “azúcares añadidos” seguido de la lista entre paréntesis con las denominaciones específicas de todos los azúcares libres añadidos presentes en el producto preenvasado, excepto de aquellos que formen parte de un ingrediente compuesto, en caso de existir;
            b) en orden cuantitativo decreciente m/m según corresponda a la suma de todos los azúcares añadidos considerados en el inciso a), y
            c) cuando existan ingredientes compuestos en los que formen parte varios azúcares añadidos, éstos también deben agruparse dentro del mismo, conforme a lo establecido en los incisos a) y b),
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2 Coadyuvantes de elaboración y transferencia de aditivos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.1 Debe ser incluido en la lista de ingredientes todo aditivo que haya sido empleado en los ingredientes de un alimento o bebida no alcohólica preenvasado y que se transfiera a otro producto preenvasado en cantidad notable o suficiente para desempeñar en él una función tecnológica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.2 Están exentos de su declaración en la lista de ingredientes los aditivos transferidos a los alimentos y bebidas no alcohólicas preenvasados que ya no cumplen una función tecnológica en el producto terminado, así como los coadyuvantes de elaboración, excepto aquellos que puedan causar hipersensibilidad
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
            <br />-Crustáceos y sus productos.
            <br />-Pescado y sus productos. Se exceptúan: gelatina de pescado utilizada como soporte de vitaminas, aromatizantes o preparados de carotenoides.
            <br />-Moluscos y sus productos.
            <br />-Cacahuate y sus productos.
            <br />-Soya y sus productos. Se exceptúan: aceite y grasa de soya totalmente refinados; tocoferoles naturales mezclados, d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soya; fitoesteroles y ésteres de fitoesteroles derivados de aceites vegetales de soya; ésteres de fitoestanol derivados de fitoesteroles de aceite de soya.
            <br />-Leche, productos de la leche y derivados lácteos (lactosa incluida). Se exceptúa el lactitol.
            <br />-Nueces de árboles y sus productos derivados, tales como las almendras (Prunus amygdalus) y nueces (especies del género Juglans), pero se aplica de modo general a todas las nueces producidas por árboles, incluidas las avellanas (Corylus spp.), pecanas (Carya illinoensis), nuez del Brasil (Bertholletia excelsa), nuez de la india (Anacardium occidentale), castañas (Castanae spp.), nuez de macadamia (Macadamia spp.).
            <br />Sulfito en concentraciones de 10 mg/kg o más.
            <br />b) Cuando el alimento, ingrediente o derivado sea o contenga alguno de los causantes de hipersensibilidad (alérgenos alimentarios) reconocidos en el listado correspondiente, el o los alérgenos deberán declararse al final de la lista de ingredientes.
            <br />i) con letra en negrillas de igual o mayor tamaño a las letras de los ingredientes generales;
            <br />ii) anteponiendo la palabra bajo el título “Contiene”, y
            <br />iii) si el ingrediente es un derivado que contiene albúmina, caseína o gluten puede rotularse declarando su
            origen, como el ejemplo siguiente: contiene: caseína (leche) o caseína de leche.
            <br /><br />
            c) Si existe la posibilidad de contaminación durante el proceso de producción o elaboración hasta el envasado, por parte del fabricante, se deberá incluir al final de la lista de ingredientes, la siguiente frase: "Puede contener", con letra en negrillas, de igual o mayor tamaño a las letras de los ingredientes generales, indicando el alérgeno de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.4. En la declaración de aditivos utilizados en la producción de productos preenvasados, debe utilizarse el nombre común o en su defecto, alguno de los sinónimos establecidos en el Acuerdo.
            <br /><br />
            Las enzimas y saborizantes, saboreador o aromatizantes pueden ser declarados como denominaciones genéricas, excepto la cafeína, la cual debe ser declarada de forma específica.
            <br /><br />
            Los saborizantes, saboreadores o aromatizantes pueden estar calificados con los términos "natural", "idéntico al natural", "artificial" o con una combinación de los mismos según corresponda, a menos que se destaque su presencia de alguna manera, lo cual obliga a la declaración con el término específico.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 28,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3. Etiquetado cuantitativo de los ingredientes
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.1 En todo alimento o bebida no alcohólica preenvasado que se venda como mezcla o combinación, se declarará el porcentaje del ingrediente, con respecto al peso o al volumen que corresponda del ingrediente, al momento de la elaboración del alimento (incluyendo los ingredientes compuestos o categorías de ingredientes), cuando este ingrediente:
            <br />
            <br />
            a) se enfatiza en la etiqueta como presente, por medio de palabras o imágenes o gráficos; o
            <br />b) no figura en el nombre o denominación del alimento o bebida no alcohólica preenvasados y es esencial para caracterizar al mismo, ya que los consumidores asumen su presencia en el producto preenvasado y la omisión de la declaración cuantitativa de ingredientes engaña o lleva a error al consumidor.
            Tales declaraciones no se requieren cuando:
            <br />c) el ingrediente es utilizado en pequeñas cantidades con el propósito de impartir sabor y/o aroma; Respecto al numeral 4.2.2.3.1 (a):
            <br />d) La referencia en el nombre del alimento, ingrediente o categoría de ingredientes enfatizados en la etiqueta no requiera una declaración cuantitativa si es que:
            <br />La referencia al ingrediente enfatizado no conduce a error o engaño, o crea una impresión errónea en el consumidor respecto a la naturaleza del alimento o bebida no alcohólica preenvasado, porque la variación en la cantidad del ingrediente o ingredientes entre productos no es necesaria para caracterizar al mismo o distinguirlo de similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.2 La información requerida en el numeral 4.2.2.3.1 será declarada en la etiqueta del producto como un porcentaje numérico.
            <br />
            El porcentaje del ingrediente, por peso o volumen, según corresponda, se declarará en la etiqueta muy cerca de las palabras o imágenes o gráficos que enfaticen el ingrediente particular, o al lado del nombre común o denominación del alimento o bebida no alcohólica preenvasado, o adyacente al ingrediente que corresponda en la lista de ingredientes. Se declarará como un porcentaje mínimo cuando el énfasis sea sobre la presencia del ingrediente, y como un porcentaje máximo cuando el énfasis sea sobre el bajo nivel del ingrediente.
            <br /><br />
            Para alimentos que han perdido humedad luego de un tratamiento térmico u otro tratamiento, el porcentaje (con respecto al peso o al volumen) corresponderá a la cantidad del ingrediente o ingredientes usados, en relación con el producto terminado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 31,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3 Contenido neto y masa drenada
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3.1 Debe declararse el contenido neto y cuando aplique, la masa drenada en unidades del Sistema General de Unidades de Medida de conformidad a lo que establece la NOM-030-SCFI-2006, independientemente de que también pueda expresarse en otras unidades (véase Referencias).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4 Nombre, denominación o razón social y domicilio fiscal.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4.1. En un producto preenvasado, debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto de manera enunciativa mas no limitativa: calle, número, código postal y entidad federativa en que se encuentre.
            <br />
            En el caso de los productos importados, el nombre y domicilio del importador, en ambos casos, puede incluirse la expresión “fabricado o envasado por o para”, seguido por el nombre y domicilio según corresponda.
            <br /><br />
            4.2.4.2. Para productos preenvasados importados debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto. Esta información puede incorporarse al producto preenvasado en territorio nacional, después del despacho aduanero y antes de la comercialización del producto.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 35,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5 País de origen
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5.1 Los alimentos y bebidas no alcohólicas preenvasados nacionales o de procedencia extranjera deben incorporar la leyenda que identifique el país de origen de los productos, por ejemplo: "Hecho en..."; "Producto de ..."; "Fabricado en ...", u otras análogas, seguida del país de origen del producto, sujeto a lo dispuesto en los tratados internacionales de que los Estados Unidos Mexicanos sean parte. Se permite el uso de gentilicios y otros términos análogos, siempre y cuando sean precisos y no induzcan a error en cuanto al origen del producto. Por ejemplo: “Producto español”, “Producto estadounidense”, entre otros.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 37,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6 Identificación del lote
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.2 La identificación del lote que incorpore el fabricante en el producto preenvasado debe marcarse en forma indeleble y permanente, y no debe ser alterada u ocultada de forma alguna hasta que sea adquirido por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.3 La clave del lote debe ser precedida por cualquiera de las siguientes indicaciones: “LOTE”, “Lot”, “L”, “Lote”, “lote”, “lot”, “l”, “lt”, “LT”, “LOT”, o bien incluir una referencia al lugar donde aparece.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 41,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7 Fecha de caducidad o de consumo preferente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.1 Si no está determinado de otra manera en una Norma Oficial Mexicana la fecha de caducidad o la fecha de consumo preferente deberá cumplir con lo siguiente:
            <br /><br />
            i) El fabricante debe declararla en el envase o etiqueta, la cual debe consistir por lo menos de:
            - El día y el mes para los productos de duración máxima de tres meses;
            - El mes y el año para productos de duración superior a tres meses.
            <br /><br />ii) La fecha debe estar precedida por una leyenda que especifique que dicha fecha se refiere a la fecha de caducidad o al consumo preferente.
            - Para el caso de fecha de caducidad, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Fecha de caducidad ___”, “Caducidad ____”, “Fech Cad ____”, CAD, Cad, cad, Fecha de expiración,
            Expira, Exp, EXP, exp, Fecha de vencimiento, Vencimiento.
            - Para el caso de consumo preferente, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Consumir preferentemente antes del____”, “Cons. Pref. antes del ___”. y “Cons Pref”.

            <br /><br /> iii) Las palabras prescritas en el apartado ii) deberán ir acompañadas de:
            • la fecha misma; o
            • una referencia al lugar donde aparece la fecha.
            Tratándose de productos de importación, cuando el codificado de la fecha de caducidad o de consumo preferente no corresponda al formato establecido en el numeral 4.2.7.1 inciso i), éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En ninguno de estos casos los ajustes serán considerados como alteración.

            <br /><br />4.2.7.2 Al declarar la fecha de caducidad o de consumo preferente se debe indicar en la etiqueta cualquiera de las condiciones especiales que se requieran para la conservación del alimento o bebida no alcohólica preenvasado, si de su cumplimiento depende la validez de la fecha.
            <br />Por ejemplo, se pueden incluir leyendas como: "manténgase en refrigeración"; "consérvese en congelación"; "una vez descongelado no deberá volverse a congelar"; "una vez abierto, consérvese en refrigeración", u otras análogas.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.3 La fecha de caducidad o de consumo preferente que incorpore el fabricante en el producto preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.4 No se requerirá la declaración de fecha de caducidad o consumo preferente, para:
            <br /> ● Vinagre;
            <br /> ● Sal de calidad alimentaria;
            <br /> ● Azúcar sólido;
            <br /> ● Productos de confitería consistentes en azúcares aromatizados y/o coloreados;
            <br /> ● Goma de mascar.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 45,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8 Productos preenvasados con Norma Oficial Mexicana
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.1. Los productos preenvasados deben exhibir la contraseña oficial cuando así lo determine la Norma Oficial Mexicana que regule su denominación o la Ley Federal sobre Metrología y Normalización, lo que se hará considerando lo establecido en el numeral 4.2.8.3 de esta Norma Oficial Mexicana y de conformidad a lo establecido en la NOM-106-SCFI-2017 (ver 2.4 Referencias Normativas).
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.2. Los productos preenvasados, cuya presentación individual indique la leyenda de “No etiquetado para su venta individual” o similar, y requieran usar la contraseña oficial en términos del numeral 4.2.8.1, lo debe hacer únicamente en el empaque múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.3. Los productos preenvasados que ostenten la contraseña oficial incluida en un producto preenvasado conforme al numeral 4.2.8.1 deben incluir, ya sea debajo de la contraseña oficial o del lado derecho de la misma, los tres dígitos correspondientes a la clave o código de la norma oficial mexicana específica para la denominación de producto, con la misma proporcionalidad y tipografía.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Instrucciones para el uso
            <br /> La etiqueta debe contener las instrucciones de uso cuando sean necesarias sobre el modo de empleo, incluida la reconstitución, si es el caso, para asegurar una correcta utilización del producto preenvasado.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Información adicional <br />
            En la etiqueta puede presentarse cualquier información o representación gráfica, así como materia escrita, impresa o gráfica, siempre que no esté en contradicción con los requisitos obligatorios de la presente Norma Oficial Mexicana, incluidos los referentes a la declaración de propiedades establecidos en el apartado 4.1.1.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1 Cuando se empleen designaciones de calidad, éstas deben ser fácilmente comprensibles, evitando ser equívocas o engañosas en forma alguna para el consumidor
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2 Asimismo, en la etiqueta puede presentarse cualquier información o representación gráfica que indique que el envase que contiene el producto preenvasado no afecta al ambiente, evitando que sea falsa o equívoca para el consumidor.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 53,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5. Etiquetado nutrimental <br />
            4.5.1 Componentes<br />
            El etiquetado nutrimental es obligatorio en la etiqueta de los productos preenvasados, y comprende la declaración nutrimental y la información nutrimental complementaria.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Declaración nutrimental
            <br /> Se deben declarar los nutrimentos siguientes, excepto en el producto preenvasado regulado por otros ordenamientos jurídicos aplicables:
            <br /> <br />
            a) el contenido de energía;
            <br /> b) la cantidad de proteína;
            <br /> c) la cantidad de hidratos de carbono disponibles, indicando la cantidad correspondiente a azúcares y a azúcares añadidos.
            <br /> d) la cantidad de grasas especificando la cantidad que corresponda a grasas saturadas y a grasas trans, no incluyendo las grasas trans presentes en ingredientes lácteos y cárnicos de manera natural.
            <br /> e) la cantidad de fibra dietética;
            <br /> f) la cantidad de sodio;
            <br /> g) la cantidad de cualquier otro nutrimento acerca del cual se haga una declaración de propiedades;
            <br /> h) la cantidad de cualquier otro nutrimento que se considere importante, regulado por los ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.1 Cuando se haga una declaración específica de propiedades referente a la cantidad o tipo de hidrato de carbono, pueden indicarse también las cantidades de almidón y, o en su caso, de otros tipos de hidratos de carbono.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.2 Cuando se haga una declaración de propiedades con respecto a la cantidad o al tipo de grasas o la cantidad de colesterol deben declararse las cantidades de: grasas monoinsaturadas, grasas poliinsaturadas y colesterol.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.3 Quedan exceptuados de incluir la declaración nutrimental los productos siguientes, siempre y cuando no incluyan alguna declaración de propiedades nutrimentales o saludables:
            <br /><br />
            productos que incluyan un solo ingrediente;
            <br />ii. hierbas, especias o mezcla de ellas;
            <br />iii. extractos de café, granos de café enteros o molidos descafeinados o no y que no contengan ingredientes añadidos diferentes a aromas;
            <br />iv. infusiones de hierbas, té descafeinado o no, instantáneo y/o soluble que no contengan ingredientes añadidos;
            <br />v. vinagres fermentados y sucedáneos;
            <br />vi. agua para consumo humano y agua mineral natural; y
            <br />vii. los productos en que la superficie más amplia sea inferior a 78 centímetros cuadrados, siempre que incluyan un número telefónico o página Web en la que el consumidor pueda obtener información sobre la declaración nutrimental. Por ejemplo, “Para información sobre declaración nutrimental llame, 800-123-4567”, “Declaración nutrimental disponible en (indicar página Web o número telefónico de atención a clientes) o leyendas análogas". En este caso, los productos no deben incluir alguna declaración de propiedades en el producto mismo, su etiqueta o su publicidad.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 58,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4 Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.1 La declaración nutrimental debe hacerse en las unidades que correspondan al Sistema General de Unidades de Medida NOM-008-SCFI-2002, citada en el capítulo de referencias. Adicionalmente, se pueden emplear otras unidades de medidas. Tratándose de vitaminas y de nutrimentos inorgánicos (minerales), éstos se deben sujetar a lo establecido en el inciso 4.5.2.4.5
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.2 La declaración del contenido energético (Calorías) debe expresarse en kcal (kJ) por 100 g, o por 100 ml, así como por el contenido total del envase. Adicionalmente se puede declarar por porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.3 La declaración sobre la cantidad de proteínas, de hidratos de carbono disponibles, de grasas, de fibra dietética y de sodio que contienen los alimentos y las bebidas no alcohólicas preenvasados debe expresarse en unidades de medida por 100 g o por 100 mL. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.4 La declaración numérica sobre vitaminas y nutrimentos inorgánicos (minerales) debe expresarse en unidades de medida por 100 g o por 100 mL o en porcentaje de los valores nutrimentales de referencia por porción. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.6 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la declaración nutrimental debe realizarse de acuerdo con las instrucciones para el uso indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7 La declaración nutrimental puede presentarse de la siguiente manera o en cualquier otro formato que contenga la información requerida conforme lo indicado en la tabla 3:
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7. BIS La información impresa en la declaración nutrimental debe presentarse en un tamaño de fuente de cuando menos 1.5 mm de altura, y destacarse en negrillas la declaración y la cantidad el contenido energético, la cantidad de grasa saturada, la cantidad de azúcares añadidos, la cantidad de grasas trans y la cantidad de sodio
            <br />
            <br />
            4.5.2.4.7 BIS-1 No obstante lo establecido en 4.5.2.4.7 BIS, la declaración nutrimental debe mostrarse, al menos, en un tamaño de fuente de 1 mm de altura en los siguientes casos:
            <br />a) productos cuya superficie principal de exhibición sea igual o inferior a 32 cm2,
            <br />b) productos obligados a declarar más de 20 nutrimentos, y su superficie principal de exhibición, sea igual o inferior a 161 cm2, y
            <br />c) en envases retornables en los que la información se encuentra en la corcholata o taparrosca. Tabla 3-Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.8 La declaración del contenido de vitaminas y de nutrimentos inorgánicos (minerales) es opcional, excepto en los alimentos y en las bebidas no alcohólicas modificados en su composición, debiendo cumplir con la NOM-086-SSA1-1996 (Ver referencias).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.9 La inclusión de uno de los siguientes nutrimentos no obliga a incluir uno de los otros y sólo se realiza si se tiene asignado un VNR y el contenido de la porción sea igual o esté por arriba del 5% del VNR referido (ya sea IDR o IDS).
            <br />
            <br />
            Vitamina A (% VNR), Vitamina E (% VNR), Vitamina C (% VNR), Vitamina B1 (% VNR), Vitamina B2 (% VNR), Vitamina B6 (% VNR), Vitamina B12 (% VNR), Vitamina D (% VNR), Vitamina K (% VNR), Ácido pantoténico (% VNR), Ácido fólico (% VNR), Niacina (% VNR), Calcio (% VNR), Fósforo (% VNR), Magnesio (% VNR), Hierro (% VNR), Zinc (% VNR), Yodo (% VNR), Cobre (% VNR), Cromo (% VNR), Flúor (% VNR),
            Selenio (% VNR).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 68,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.10 Todos o ninguno de los siguientes
            <br /> Grasa poliinsaturada ___ g; grasa monoinsaturada __ g; colesterol ___ mg.
            <br />4.5.2.4.11 La inclusión de uno de los siguientes no obliga a incluir a los otros
            <br />Almidones ___ g; polialcoholes ___ g; polidextrosas ___ g.
            <br />4.5.2.4.12 Se puede señalar el número de porciones contenidas en el envase, usando el término “aproximadamente” o “aprox”.
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.13 Se puede declarar información basada en valores de referencia recomendados para poblaciones distintas a la mexicana, siempre que ésta se presente junto con la información indicada en 4.5.2.4.7 y se le distinga claramente. Dicha información puede presentarse conforme a lo indicado en la tabla 4 o en cualquier otro formato que contenga la información requerida. Revisar Tabla 4-Presentación de la declaración nutrimental de vitaminas y de minerales basada en porcentaje
            del valor nutrimental de referencia
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.14 Tolerancias y cumplimiento
            <br /> La Secretaría de Salud puede establecer límites de tolerancia en relación con las exigencias de salud pública, en materia de la declaración nutrimental. La estabilidad en almacén, la precisión de los análisis, el diverso grado de elaboración y la inestabilidad y variabilidad propias del nutrimento en el producto, dependiendo de si el nutrimento ha sido añadido al producto o se encuentra naturalmente presente en él, se regularán a través de normas oficiales mexicanas.
            <br />4.5.2.4.15 Los valores de composición bromatológica que figuren en la declaración nutrimental del producto preenvasado, deben ser valores medios ponderados derivados por análisis, bases de datos o tablas reconocidas internacionalmente.
            <br /><br />
            Para cumplir con el contenido declarado de vitaminas y minerales hasta el final de la vida útil se acepta una cantidad superior a lo declarado, dentro de las buenas prácticas de manufactura, siempre y cuando las empresas mantengan los antecedentes técnicos que lo justifiquen.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.16 Para la expresión de la declaración nutrimental se puede utilizar los parámetros de redondeo de la tabla 5, conforme corresponda al nutrimento respectivo. Revisar Tabla 5. Parámetros de redondeo
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 72,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Información nutrimental complementaria.
            <br />
            <br />
            El cálculo y evaluación de esta sección será de acuerdo a los lineamientos de la modificación a la NOM 051 en cualquiera de sus tres etapas y de acuerdo a lo indicado por el cliente en la solicitud de servicios y en lo declarado al inicio de esta lista de inspección.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 73,
        fase: 'I',
        text: (
          <p className="table-content__row__cell--text">
            FASE I
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            Revisar Perfiles Nutrimentales de la Primera Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+I.PNG" alt="Tabla+Fase+I" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 20,
        questionNumber: 74,
        fase: 'II',
        text: (
          <p className="table-content__row__cell--text">
            FASE II
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            <br />Revisar Perfiles Nutrimentales de la Segunda Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+II.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 21,
        questionNumber: 75,
        fase: 'III',
        text: (
          <p className="table-content__row__cell--text">
            FASE III
            <br /><br />
            Debe incluirse la información nutrimental complementaria en la etiqueta de los productos
            preenvasados que:
            <br />a) contengan añadidos: azúcares libres, grasas o sodio; y
            <br />b) el valor de energía, la cantidad de azúcares libres, de grasa saturada, grasas trans y de sodio cumplan con los perfiles nutrimentales establecidos en la Tabla 6.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+III.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br />a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se
            haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos
            e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se
            haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente
            que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.2 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la información nutrimental complementaria se debe declarar conforme a los contenidos de energía, de azúcares libres, grasas saturadas, grasas trans (con excepción de las presentes en productos lácteos y cárnicos de manera natural para el caso de las grasas trans), o de sodio del producto tal como se consume, de acuerdo con las instrucciones indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.3 Quedan exceptuados de la información nutrimental complementaria los productos siguientes:
            <br /> a) los productos que están exceptuados de la declaración nutrimental, conforme se establece en el numeral 4.5.2.3 excepto los señalados en el inciso vii;
            <br /> b) las fórmulas para lactantes, las fórmulas para lactantes con necesidades especiales de nutrición, las fórmulas de continuación y las fórmulas de continuación para necesidades especiales de nutrición;
            <br /> c) los alimentos y las bebidas no alcohólicas para lactantes y para niños de corta edad que tengan especificaciones nutrimentales para alguno de los siguientes nutrimentos: grasas, azúcares y sodio; conforme se establece en los ordenamientos jurídicos aplicables.
            <br /> d) aceites vegetales, grasas vegetales o animales; azúcar, miel, sal yodada y sal yodada fluorurada, así como harinas de cereal.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4 Sistema de etiquetado frontal<br />
            El sistema de etiquetado frontal incluye la información nutrimental complementaria y las leyendas precautorias descritas en los numerales 7.1.3 y 7.1.4.
            <br /><br />
            4.5.3.4.1 La información nutrimental complementaria debe realizarse utilizando los sellos, según corresponda y conforme a lo establecido en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos1.PNG" alt="Sellos 1" style={{ width: '70%' }} />
            <br />
            4.5.3.4.2 Los productos cuya superficie principal de exhibición sea ≤40 cm2 sólo deben incluir un sello con el número que corresponda a la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 en un tamaño mínimo de conformidad a lo establecido en la tabla A1 del Apéndice A (Normativo) de la presente Norma.
            <br />Aquellos productos cuya superficie principal de exhibición sea ≤ 5 cm2 el sello descrito en el párrafo anterior debe de cumplir con las características descritas en el numeral A.4.5 del Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos2.PNG" alt="Sellos 2" style={{ width: '70%' }} />
            <br />
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.3 Para el caso de productos en envases retornables utilizados como contenedores para más de un tipo de producto o de sabor, los productores deben expresar únicamente en la parte externa de la tapa el sello correspondiente al número de la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 y conforme se establece en 4.5.3.4.2.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 80,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Envases Múltiples
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 81,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.4 Los productos cuya presentación individual indique la leyenda de “No etiquetado para su venta individual”, o similar, y que se encuentren en un empaque múltiple o colectivo, únicamente éste debe incluir los sellos que correspondan, conforme a lo establecido en los numerales 4.5.3 y 4.5.3.4.1
            <br />
            <br />
            4.5.3.4.5 Aquellos envases colectivos que contengan más de un tipo de producto deben estar etiquetados de manera individual. Adicionalmente, el envase colectivo debe incluir tantos sellos como corresponda a los productos que contiene, conforme se establece en 4.5.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 82,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.6 Ubicación y orden de los sellos
            <br />El o los sellos deben colocarse en la esquina superior derecha de la superficie principal de exhibición,
            conforme se establece en el Apéndice A (Normativo). En aquellos productos con superficie principal de exhibición menor a 60 cm2 se podrán colocar los sellos en cualquier área de dicha superficie.
            <br />Cuando se deban incluir más de un sello, el orden de inclusión debe ser de izquierda a derecha el siguiente:
            <br />1. EXCESO CALORÍAS
            <br />2. EXCESO AZÚCARES
            <br />3. EXCESO GRASAS SATURADAS
            <br />4. EXCESO GRASAS TRANS
            <br />5. EXCESO SODIO
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 83,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.7 Cuando proceda incluir las leyendas “CONTIENE CAFEÍNA EVITAR EN NIÑOS” o “CONTIENE EDULCORANTES - NO RECOMENDABLE EN NIÑOS”, deben ir en la parte superior derecha de la superficie principal de exhibición y en caso de que el producto preenvasado tenga sellos, deben ir debajo de los mismos, conforme se establece en el Apéndice A (Normativo). Ver Apéndice A (Normativo).
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 84,
        text: (
          <p className="table-content__row__cell--text">
            4.6 Declaración de propiedades nutrimentales
            <br /> 4.6.1 No obstante lo establecido en la presente Norma Oficial Mexicana, toda declaración respecto de las
            propiedades nutrimentales debe sujetarse a lo dispuesto en la NOM-086-SSA1-1994 (ver 2.3 Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 85,
        text: (
          <p className="table-content__row__cell--text">
            4.7 Presentación de los requisitos obligatorios
            <br />4.7.1 Generalidades
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 86,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.1 Las etiquetas que ostenten los productos preenvasados deben fijarse de manera tal que permanezcan disponibles hasta el momento del consumo en condiciones normales, y deben aplicarse por cada unidad, envase múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 87,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.2 Cuando la información comercial obligatoria de los productos preenvasados que van destinados al consumidor final se encuentre en un envase múltiple o colectivo, no será necesario que dicha información aparezca en la superficie del producto individual. Sin embargo, la indicación del lote y la fecha de caducidad o de consumo preferente deben aparecer en el producto preenvasado individual. Además, en el producto preenvasado se debe indicar siempre en lo individual la leyenda "No etiquetado para su venta individual", cuando éstos no tengan toda la información obligatoria o una frase equivalente.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 88,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.3 Los datos que deben aparecer en la etiqueta deben indicarse con caracteres claros, visibles, indelebles y en colores contrastantes, fáciles de leer por el consumidor en circunstancias normales de compra y uso. El dato relativo al lote, fecha de caducidad o de consumo preferente puede ser colocado en cualquier parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 89,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.4 Cuando el envase esté cubierto por una envoltura, debe figurar en ésta toda la información aplicable, a menos de que la etiqueta del envase pueda leerse fácilmente a través de la envoltura exterior.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 90,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.5 Deben aparecer en la superficie principal de exhibición del producto cuando menos la marca, la declaración de cantidad, la denominación del producto preenvasado, el etiquetado frontal y aquella cuya ubicación se haya especificado. El resto de la información a que se refiere esta Norma Oficial Mexicana, puede incorporarse en cualquier otra parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 91,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.8 Idioma
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 92,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 93,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 94,
        text: (
          <p className="table-content__row__cell--text">
            4.8.2 La presentación de información o representación gráfica adicional en la etiqueta a la señalada en esta Norma Oficial Mexicana, que puede estar presente en otro idioma, es facultativa y, en su caso, no debe sustituir, sino añadirse a los requisitos de etiquetado de la presente Norma Oficial Mexicana, siempre y cuando dicha información resulte necesaria para evitar que se induzca a error o engaño al consumidor
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 95,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Cálculos
            <br /> 5.1 Cálculo de nutrimentos
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 96,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Cálculos de energía
            <br /> La cantidad de energía que debe declararse debe calcularse utilizando los siguientes factores de conversión:
            <br /> Hidratos de carbono disponibles 4 kcal/g-17 kJ/g
            <br /> Proteínas 4 kcal/g-17 kJ/g
            <br /> Grasas 9 kcal/g-37 kJ/g
            <br /> Alcohol (etanol) 7 kcal/g-29 kJ/g
            <br /> Polioles (*) 2.4 kcal/g-10 kJ/g
            (sorbitol, xilitol, maltitol, isomalt, isomaltitol, lactitol, manitol)
            <br /> Eritritol (*) 0 kcal/g-0 kJ/g
            <br /> Alulosa (*) 0 kcal/g-0 kJ/g
            <br /> Tagatosa (*) 1.5 kcal/g-6.276 kJ/g
            <br /> (*) Cuando se hace un cálculo teórico del contenido energético se deben usar los factores de conversión específicos para polioles, eritritol, tagatosa y alulosa y no calcularse dentro de los hidratos de carbono disponibles
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 97,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            . Declaraciones de Propiedades
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 98,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Se prohíbe el uso de las siguientes declaraciones:
            <br />6.1.1 De propiedades.
            <br />● Declaraciones que hagan suponer que una alimentación equilibrada a base de alimentos ordinarios no puede suministrar cantidades suficientes de todos los elementos nutritivos.
            <br />● Declaraciones que no pueden comprobarse.
            <br />● Declaraciones sobre la utilidad de un alimento o bebida no alcohólica, para prevenir, aliviar, tratar o curar una enfermedad, trastorno o estado fisiológico.
            <br />● Declaraciones que pueden suscitar dudas sobre la inocuidad de alimentos o bebidas no alcohólicas análogos, o puedan suscitar o provocar miedo en el consumidor.
            <br />● Declaraciones que afirmen que un determinado alimento constituye una fuente adecuada de todos los nutrimentos esenciales.
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 99,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 De propiedades potencialmente engañosas
            <br />A continuación, se presentan ejemplos de declaraciones de propiedades que pueden ser engañosas:
            <br />● Declaraciones de propiedades que carecen de sentido, incluidos los comparativos y superlativos incompletos.
            <br />● Declaraciones de propiedades respecto a prácticas correctas de higiene o comercio, tales como: "genuinidad", "salubridad", "sanidad", “sano”, “saludable”, excepto las señaladas en otros ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 27,
        questionNumber: 100,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Declaraciones de propiedades condicionales
            <br />Se permiten las siguientes declaraciones de propiedades condicionadas a la particular condición asignada a cada una de ellas:
            <br /><br />
            a) Puede indicarse que un alimento ha adquirido un valor nutritivo especial o superior gracias a la adición de nutrimentos, tales como vitaminas, nutrimentos inorgánicos (minerales) y aminoácidos, sólo si dicha adición ha sido hecha sobre la base de consideraciones nutrimentales de acuerdo con el marco jurídico aplicable
            <br /><br />
            b) Las indicaciones de que el alimento tiene cualidades nutricionales especiales gracias a la reducción u omisión de un nutrimento, se deberán hacer sobre la base de consideraciones nutrimentales y estar sujetas al marco jurídico aplicable.
            <br /><br />
            c) Términos como “orgánico”, “ecológico”. “biológico” y las denominaciones con prefijos “bio” y “eco”, deben ajustarse a lo establecido en la Ley de Productos Orgánicos (ver Bibliografía), y aplicar los demás términos que se establezcan en alguna otra Norma Oficial Mexicana o marco jurídico aplicable.
            <br /><br />
            El uso de estos términos debe estar en consonancia con las prohibiciones establecidas en el numeral 6.1.
            <br />d) Declaraciones de propiedades que afirmen que el alimento tiene características especiales cuando todos los alimentos de ese tipo tienen esas mismas características, si este hecho es aparente en la declaración de propiedades.
            <br />e) Pueden utilizarse declaraciones de propiedades que destaquen la ausencia o no adición de determinadas sustancias a los alimentos, siempre que no sean engañosas y la sustancia:
            <br /><br />
            i. no esté sujeta a requisitos específicos en ninguna norma;
            <br />ii. sea una de las que los consumidores esperan encontrar normalmente en el alimento;
            <br />iii. no haya sido sustituida por otra que confiera al alimento características equivalentes a menos que la naturaleza de la sustitución se declare explícitamente con igual prominencia; y
            <br />iv. sea un ingrediente cuya presencia o adición en el alimento esté permitida.
            <br />f) Las declaraciones de propiedades que pongan de relieve la ausencia o no adición de uno o más nutrimentos deberán considerarse como declaraciones de propiedades nutrimentales y, por consiguiente, deberán ajustarse a la declaración obligatoria de nutrimentos, estipulada en el marco jurídico aplicable.
            <br />g) Puede declararse la preparación ritual o religiosa de un alimento (ejemplo, Halal, Kosher), siempre que se ajuste a las exigencias de las autoridades religiosas o del ritual competente y sin importar la presencia de sellos de advertencia.
          </p>
        )
      },
      {
        pageNumber: 28,
        questionNumber: 101,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Declaraciones nutrimentales y saludables
            <br />Este tipo de declaraciones pueden referirse al valor de energía, proteínas, hidratos de carbono, grasas y los derivados de las mismas, fibra dietética, sodio, vitaminas y nutrimentos inorgánicos (minerales) para los cuales se han establecido valores nutrimentales de referencia.
            <br />Sin embargo, en el caso de que el producto preenvasado incluya en su etiquetado alguno de los sellos señalados en 4.5.3.4.1 y cualquiera de las leyendas establecidas en 7.1.3 y 7.1.4, la declaración de propiedades nutrimentales y saludables debe cumplir con lo siguiente:
            <br />a) no deben realizarse declaraciones de propiedades saludables;
            <br />b) no deben realizarse declaraciones de propiedades nutrimentales relacionadas directamente con el sello que haya sido declarado en la etiqueta, y
            <br />c) las declaraciones de propiedades nutrimentales que pueden realizarse deben ostentarse en la superficie de información con una altura máxima de la letra que debe corresponder con la altura mínima de la letra establecida en 4.1.3 de la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas).
            <br /><br />
            6.3.1 Por declaración de propiedades relativas al contenido de nutrimentos se entiende una declaración de propiedades nutrimentales que describe el nivel de un determinado nutrimento contenido en un alimento. (Ejemplos: “Fuente de calcio”; “alto contenido de fibra y bajo en grasa”.)
            <br />6.3.2 Por declaración de propiedades de comparación de nutrimentos se entiende una declaración de propiedades que compara los niveles de nutrimentos y/o el valor energético de dos o más alimentos. (Ejemplos: “reducido”; “menos que”; “menos”; “aumentado”; “más que”.)
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 102,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 Declaración de propiedades saludables significa cualquier representación que declara, sugiere o implica que existe una relación entre un alimento, o un constituyente de dicho alimento, y la salud. Las declaraciones de propiedades saludables incluyen lo siguiente:
            <br />6.3.3.1 Por declaración de función de los nutrimentos se entiende una declaración de propiedades nutrimentales que describe la función fisiológica del nutrimento en el crecimiento, el desarrollo y las funciones normales del organismo.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 103,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3.2 Otras Declaraciones de propiedades de función - Estas declaraciones de propiedades conciernen efectos benéficos específicos del consumo de alimentos o sus constituyentes en el contexto de una dieta total sobre las funciones o actividades biológicas normales del organismo. Tales declaraciones de propiedades se relacionan a una contribución positiva a la salud o a la mejora de una función o la modificación o preservación de la salud. Ejemplo: “La sustancia A (nombrando los efectos de la sustancia A sobre el mejoramiento o modificación de una función fisiológica o la actividad biológica asociada con la salud). El alimento Y contiene X gramos de sustancia A”.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 104,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 No se podrán hacer declaraciones de propiedades cuando se pretenda atribuir al producto características que no contiene o posee ni declaraciones asociadas a la disminución o reducción de riesgo de enfermedad.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 105,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7. Leyendas
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 106,
        text: (
          <p className="table-content__row__cell--text">
            7.1 Leyendas precautorias
            <br /> 7.1.1 Las leyendas precautorias deben hacer referencia al ingrediente u origen del ingrediente que, basado en información científica reconocida, se asocie a riesgos reales o potenciales relacionados con la intolerancia digestiva, alergias o enfermedades metabólicas o toxicidad.
            <br />7.1.2 Las leyendas precautorias específicas por producto, se establecerán en las normas oficiales mexicanas correspondientes u otros ordenamientos jurídicos.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 107,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Si la lista de ingredientes incluye edulcorantes, se debe colocar la leyenda precautoria frontal en
            letras mayúsculas “CONTIENE EDULCORANTES, NO RECOMENDABLE EN NIÑOS”.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/edulcorantes.png" alt="Edulcorantes" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 108,
        text: (
          <p className="table-content__row__cell--text">
            7.1.4. Cuando el producto preenvasado contenga cafeína adicionada dentro de la lista de ingredientes en cualquier cantidad, se debe incluir la leyenda precautoria en letras mayúsculas “CONTIENE CAFEÍNA EVITAR EN NIÑOS”, la cual forma parte del sistema de etiquetado frontal, conforme se establece en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cafeine.PNG" alt="Cafeina" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 109,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Jugos
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 110,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Jugos<br />
            Los jugos deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.14, 3.16 y 3.17 de esta Norma Oficial Mexicana, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.1 y 5.3.1 de esta Norma Oficial Mexicana, asimismo, el contenido de jugo de vegetales o frutas u hortalizas debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana.
            <br /><br />
            Nota 1: Los jugos deben cumplir con las especificaciones descritas en la Tabla 1.
            <br />5.1.1.1 Jugo de __________ (el nombre del vegetal o fruta u hortaliza).
            <br />5.1.1.2 Jugo de __________ (el nombre del vegetal o fruta u hortaliza) de concentrado.
            <br />5.1.1.3 Jugo mixto de __________ (describir los vegetales o frutas o agua de coco o coco u hortalizas que contiene el producto).
            <br />Nota 2: Los jugos mixtos con más de dos vegetales o frutas u hortalizas, agua de coco o coco, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.1.
            <br /><br />
            Se pueden elaborar junto a sus pepitas, semillas y pieles que normalmente no se incorporan al jugo, aunque pueden ser aceptables algunas partes o componentes de pepitas, semillas y pieles que no puedan eliminarse mediante los procesos físicos industriales convencionales.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 111,
        text: (
          <p className="table-content__row__cell--text">
            Tabla 1
            Especificaciones para Jugos, Agua de coco y Jugos de Concentrados una vez Reconstituidos
            <br />
            imagen
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 112,
        text: (
          <p className="table-content__row__cell--text">
            Nota 9: Cuando un jugo proceda de una fruta no mencionada en la Tabla 1, el nivel mínimo de grados Brix de la fruta será el normado internacionalmente por el Codex Alimentarius.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 113,
        text: (
          <p className="table-content__row__cell--text">
            Nota 10: Para el caso del jugo de uva se puede tener una relación isotópica en el intervalo de -23 a -24 únicamente cuando lauva provenga de una región geográfica distinta a México, lo cual debe ser comprobable en la actividad de verificación.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 114,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en jugos<br />
            Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 115,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en jugos
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 116,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 117,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Agua de coco
            <br />El agua de coco debe cumplir con cualquiera de las definiciones establecidas en los incisos 3.2, 3.4 y cumplir con lo establecido en el inciso 5.3.1, usando las siguientes denominaciones de producto según corresponda, el contenido de agua de coco debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana:

            <br /><br />5.1.2.1. Agua de coco
            <br />5.1.2.2 Agua de coco de concentrado

            <br /><br />3.2 agua de coco
            líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.

            <br /><br />3.4 agua de coco de concentrado
            agua de coco elaborada a partir de agua de coco concentrada.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 118,
        text: (
          <p className="table-content__row__cell--text">
            Nota 3: El agua de coco y agua de coco de concentrado debe cumplir con las especificaciones descritas en la Tabla 1.
            <br />
            <table className="table-norms">
              <tr>
                <th>Parámetro</th>
                <th>Agua de Coco</th>
              </tr>
              <tr>
                <td>* Sólidosdisueltosmínimos de lafrutacorrespondiente(°Brix)</td>
                <td>5</td>
              </tr>
              <tr>
                <td>RelaciónIsotópica deCarbono (13C/12C), expresadaen d13CVPDB (%).</td>
                <td>-23 a -25</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 119,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en agua de coco
            <br />Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 120,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en agua de coco
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 121,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 122,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Néctares
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 123,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Néctares
            <br />Los néctares deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.19 y 3.20, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.2 y 5.3.2.
            <br /><br />
            5.1.3.1 Néctar de ___________ (el nombre del vegetal o fruta o coco u hortaliza).
            <br /><br />
            5.1.3.2 Néctar mixto de ________ (describir los vegetales o frutas o coco u hortalizas, según corresponda la combinación entre éstos).
            <br /><br />
            Nota 4: Los néctares mixtos con más de dos vegetales o frutas o coco u hortalizas, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.2.
            <br /><br />
            3.19 néctar
            producto líquido, con pulpa o sin pulpa, elaborado con jugo o pulpa o puré de vegetales o frutas, o coco u hortalizas (maduras y sanas), de la variedad correspondiente, lavadas, finamente divididas y tamizadas, de concentrados o no, de congelados o no y deben cumplir con los parámetros establecidos en la Tabla 2 de esta Norma Oficial Mexicana, pudiendo ser adicionado de agua potable, azúcares añadidos, edulcorantes, así como los aditivos y coadyuvantes permitidos en la Referencia normativa 2.10.
            <br /><br />
            3.20 néctar mixto
            néctar puede elaborarse a partir de la mezcla de dos o más vegetales o frutas u hortalizas o agua de coco o coco y que cumplen con las definiciones establecidas en el inciso 3.19.
          </p>
        )
      },
      {
        pageNumber: 36,
        questionNumber: 124,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Néctares<br />
            Los néctares descritos en los incisos 3.19, 3.20 y 5.1.3 deben cumplir con lo establecido en la Tabla 2.
            <br /><br />
            Tabla 2
            Especificaciones para néctares naturales o reconstituidos
            <br />
            <table className="table-norms">
              <tr>
                <th>Nombre botánico</th>
                <th>Nombre Común</th>
                <th>Valor de °Bx del jugo separado</th>
                <th>Contenido minimo de jugo y/o pulpa (% v/v) en néctares de frutas</th>
              </tr>
              <tr>
                <td>Cocos nucifera L.</td>
                <td>Coco</td>
                <td>5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L.</td>
                <td>Melon</td>
                <td>8</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Cucumis melo L. subsp. melo var.inodorus H. Jacq</td>
                <td>Melon casaba</td>
                <td>7.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L subsp. melo var.inodorus H. Jacq.</td>
                <td>Melon dulce de piel lisa</td>
                <td>10</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Fragaria x. ananassaDuchense(Fragaria chiloensisDuchesne x Fragaria virginianaDuchesne)</td>
                <td>Fresa</td>
                <td>7.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Lycopersicum esculentum L.</td>
                <td>Tomate</td>
                <td>5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Malus domestica Borkh.</td>
                <td>Manzana</td>
                <td>11.5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Mangifera indica L.</td>
                <td>Mango</td>
                <td>13.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus persica (L.) Batsch var.persica</td>
                <td>Durazno</td>
                <td>10.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Psidium guajava L.</td>
                <td>Guayaba</td>
                <td>8.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Pyrus communis L</td>
                <td>Pera</td>
                <td>12</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Vitis Vinifera L. o sus híbridos VitisLabrusca o sus híbridos</td>
                <td>Uva</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Ananas comosus (L.)MerrillAnanas sativis L. Schult. f.</td>
                <td>Piña</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus sinensis (L.)</td>
                <td>Naranja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus paradisi Macfad</td>
                <td>Toronja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Punica granatum L.</td>
                <td>Granada</td>
                <td>12</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Citrus reticulata Blanca</td>
                <td>Mandarina</td>
                <td>11.8</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Carica papaya L.</td>
                <td>Papaya</td>
                <td>11.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Annona muricata L</td>
                <td>Guanábana</td>
                <td>14.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Tamarindus indica</td>
                <td>Tamarindo</td>
                <td>13</td>
                <td>0.5</td>
              </tr>
              <tr>
                <td>Musa species incluidas M.acuminata y M. paradisiaca peroexcluyendo los otros plátanos</td>
                <td>Platano</td>
                <td>22</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus armeniaca L.</td>
                <td>Chabacano</td>
                <td>11.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Apium graveolens</td>
                <td>Apio</td>
                <td>3.1</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Daucus carota</td>
                <td>Zanahoria</td>
                <td>8</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Vaccinium macrocarpon AitonVaccinium oxycoccos L.</td>
                <td>Arandano agrio</td>
                <td>7.5</td>
                <td>30</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 125,
        text: (
          <p className="table-content__row__cell--text">
            Nota 11: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de fruta, deben contener un porcentaje total mínimo de 25 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 126,
        text: (
          <p className="table-content__row__cell--text">
            Nota 12: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de vegetales u hortalizas deben contener un porcentaje total mínimo de 20 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 127,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Ingredientes en néctares
            <br /> Se pueden añadir los siguientes ingredientes a los néctares:
            <br />a) Agua potable
            <br />b) Extractos de vegetales o frutas u hortalizas
            <br />c) Azúcares añadidos
            <br />d) Edulcorantes conforme a la Referencia normativa 2.10
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 128,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2.1 Ingredientes opcionales en néctares
            <br />Se pueden añadir otros ingredientes opcionales a los productos que usen la denominación de producto néctar y su uso debe ser declarado dentro de la denominación del producto.
            <br />Nota 14: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 129,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 130,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas no alcohólicas con vegetal o fruta u hortaliza
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 131,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Estas bebidas no alcohólicas deben cumplir con la definición establecida en el inciso 3.7 y describir el porcentaje de vegetal o fruta, agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, usando la siguiente denominación de producto
            <br /><br />
            5.1.4.1 Bebida con ____ % (dos dígitos) de __________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 5: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo el Aloe vera) deben representar números enteros a través de dos dígitos (mayores a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 10.9, 11.4, 13.6,etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            3.7 bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 132,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Las Bebidas descritas en 5.1.4 deben tener un porcentaje mayor o igual del 10 % del contenido de vegetales o frutas o aguade coco o coco u hortaliza (incluyendo al Aloe vera) y debe ser menor al contenido mínimo de jugo y/o pulpa (% v/v) en néctares de frutas, descrito en la Tabla 2.
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 133,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Ingredientes en bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas descritas en el inciso 5.1.4, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 134,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas descritas en el inciso 5.1.4, pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 135,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas saborizadas no alcohólicas
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 136,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza deben cumplir con la definición establecida en el inciso 3.8 y su denominación de producto debe ser como a continuación se describe.
            <br /><br />
            5.1.5.1 Las bebidas saborizadas no alcohólicas con un porcentaje menor al 10 % de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _____ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera) con ___ % (un dígito) de _________________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 6: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo al Aloe vera) deben representar números enteros a través de un dígito (menor a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 9.9, 5.4, 3.6, etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            Nota 7: En caso de que a una bebida saborizada no alcohólica se le adicione un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza distinto al sabor que dice contener, debe declarar de igual forma el porcentaje de contenido al final de la denominación del producto conforme al 5.1.5.1.
            <br /><br />
            3.8 bebidas saborizadas no alcohólicas
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contenga nningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, aguade coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 137,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 Las bebidas saborizadas no alcohólicas que no contengan un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _______ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso decombinaciones entre éstos, incluyendo al Aloe vera).
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 138,
        text: (
          <p className="table-content__row__cell--text">
            Nota 8: Para el caso de las bebidas saborizadas no alcohólicas de este inciso 5.1.5, pueden usar alguna otra denominación establecida en un instrumento normativo o jurídico en lugar de la palabra "bebida", con la finalidad de describir al consumidor final correctamente en la etiqueta el contenido real del producto, como puede ser: agua mineral, agua, bebida carbonatada o refresco u otro cumpliendo con lo establecido en los incisos 5.1.5.1 y 5.1.5.2. Se puede utilizar el término de uso común después de la palabra Bebida como parte de la denominación y que no incurra en engaño al consumidor. No se considera que alimento líquido sea un término de uso común.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 139,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Bebidas saborizadas no alcohólicas
            Las Bebidas descritas en el inciso 5.1.5 contienen un porcentaje menor al 10 % del contenido de vegetales o frutas o agua de coco o coco u hortalizas (incluyendo al Aloe vera) o contienen únicamente extractos, saborizantes naturales o artificiales.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 140,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Ingredientes en bebidas saborizadas no alcohólicas
            <br />Las bebidas descritas en el inciso 5.1.5 que adicionen menos del 10 % de vegetal o fruta o agua de coco u hortaliza, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 42,
        questionNumber: 141,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza descritas en el inciso 5.1.5,pueden hacer uso de dibujos, sin ser animados en caso de colocar sellos o la leyenda de edulcorantes conforme a la Referencia normativa 2.4, de vegetales o frutas o coco u hortalizas (incluyendo al Aloe vera), dichos dibujos no deben tener un tamaño superior al 5 % del área de la superficie principal de exhibición y deben incluir la frase "dibujo ilustrativo al sabor", esta frase debe tener un tamaño de al menos el 50 % de la declaración cuantitativa del contenido neto (ver Referencia normativa 2.3) y ambos elementos deben ir cercanos a la denominación del producto.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-51 V1',
    documentDescription: 'Lista de inspección para la NOM-173-SE-2021',
    documentIssueDate: '01/03/2023',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones
            <br />4.1 Requisitos generales de etiquetado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 La información contenida en las etiquetas de los
            alimentos y bebidas no alcohólicas preenvasados debe ser
            veraz y describirse y presentarse de forma tal que no
            induzca a error al consumidor con respecto a la naturaleza
            y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Los productos preenvasados deben presentarse con una
            etiqueta en la que se describa o se empleen palabras,
            ilustraciones u otras representaciones gráficas que se refieran al producto.
            <br />
            <br />
            Las etiquetas que contengan los productos preenvasados pueden
            incorporar la descripción gráfica o descriptiva de la sugerencia
            de uso, empleo o preparación, a condición de que aparezca una
            leyenda alusiva al respecto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Los alimentos y bebidas no alcohólicas preenvasados no
            deberán describirse ni presentarse con una etiqueta en los que
            se utilicen palabras, textos, diálogos, ilustraciones, imágenes,
            denominaciones de origen y otras descripciones que se refieran o
            sugieran, directa o indirectamente a cualquier otro producto
            con el que pueda confundirse, o que pueda inducir al consumidor
            a suponer que el alimento se relaciona en forma alguna con aquel
            otro producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 La etiqueta de los productos preenvasados que no contengan
            los sellos y leyendas precautorias, puede declararlo únicamente
            de forma escrita mediante la frase “Este producto no contiene
            sellos ni leyendas” y no debe utilizar elementos gráficos o
            descriptivo alusivos a los mismos. La declaración debe ser
            colocada en la superficie de información y, su tipografía y
            tamaño debe ser igual o menor al tamaño mínimo cuantitativo
            del contenido neto conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4. Bis En caso de que el producto ostente uno o mas sellos de advertencia o la leyenda de edulcorantes el producto no debe de: incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos  o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes; asi como hacer referencias a productos ajenos al mismo pero con la finalidad anteriormente mencionada
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Los productos preenvasados que ostenten uno o más sellos de advertencia o la leyenda de edulcorantes, no deben:
            <br /> a) incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos visual – espaciales o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes, y
            <br /> b) hacer referencia en la etiqueta a elementos ajenos al mismo con las mismas finalidades del párrafo anterior. La aplicación de este numeral se debe hacer en concordancia con lo dispuesto por otros ordenamientos
            legales aplicables.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Salvo lo indicado en el siguiente párrafo, son requisitos obligatorios de información comercial y sanitaria los siguientes.
            <br /> A menos de que se trate de especias y de hierbas aromáticas, los productos pequeños en que la superficie más amplia sea inferior a 10 cm2 podrán quedar exentos de los requisitos: lista de ingredientes, instrucciones de uso, lote y fecha de caducidad o de consumo preferente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1 Nombre o denominación de los productos preenvasados
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1. La denominación del producto preenvasado debe aparecer en negrillas dentro de la superficie principal de exhibición de la etiqueta, en línea paralela a la base como se encuentra diseñado el producto y cumpliendo con las disposiciones de denominación contenidas en una Norma Oficial Mexicana de producto preenvasado.
            <br />Junto a la denominación pueden adicionarse las palabras o frases necesarias para evitar que se induzca al error o engaño al consumidor con respecto a la naturaleza y que incluyen, pero no se limitan a:
            <br />a) el tipo de medio de cobertura;
            <br />b) la forma de presentación o su condición;
            <br />c) en el caso de que haya sido objeto de algún tipo de tratamiento, se puede indicar el nombre de éste, con excepción de aquellos que de acuerdo con los ordenamientos correspondientes sean de carácter obligatorio.
            <br /><br />
            Los elementos descritos anteriormente, forman parte de la denominación del producto preenvasado y deben describirse en forma conjunta, con un tamaño igual o mayor al del dato cuantitativo del contenido neto conforme a la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas) y con la misma proporcionalidad tipográfica, para ser igualmente visibles en la etiqueta y cumplir con lo establecido en este numeral.
            Para el caso de los productos imitación, la denominación del mismo aparecerá en la parte superior izquierda de la superficie principal de exhibición, colocando la palabra IMITACIÓN al principio en mayúsculas, con negrillas en fondo claro en un tamaño del doble al resto de la denominación. No se permite el uso de la palabra imitación en productos preenvasados que cuenten con denominación de origen o indicación geográfica protegida o reconocida por el Estado mexicano.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.1. La denominación del producto preenvasado debe corresponder a los establecidos en las Normas Oficiales Mexicanas u ordenamientos jurídicos específicos y en ausencia de éstos, se debe usar el siguiente orden de prelación para el nombre de una denominación de producto preenvasado:
            <br />a) Nombre de uso común;
            <br />b) Descripción de acuerdo con las características básicas de la composición y naturaleza del producto
            preenvasado, o
            <br />c) Norma internacional del Codex Alimentarius, en su caso.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.2. Los productos imitación no deben hacer uso de las palabras tales como “tipo”, “estilo” o algún otro término similar, en la denominación del producto preenvasado o dentro de la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2 Lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1. En la etiqueta del producto preenvasado cuya comercialización se haga en forma individual, debe figurar una lista de ingredientes, salvo cuando se trate de alimentos de un único ingrediente y no incluya algún aditivo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.1 La lista de ingredientes debe ir encabezada o precedida por el término Ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.2 Los ingredientes del alimento o bebida no alcohólica preenvasado deben enumerarse por orden cuantitativo decreciente (m/m).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.3. Los ingredientes compuestos deben declararse como tal en la lista de ingredientes, siempre que vayan acompañados inmediatamente de una lista entre paréntesis de sus ingredientes por orden decreciente de proporciones (m/m). Cuando un ingrediente compuesto, constituya menos del 5 por ciento del producto preenvasado, no será necesario declarar los ingredientes que lo conforman, salvo los aditivos alimentarios que desempeñan una función tecnológica en el producto terminado, o aditivos e ingredientes que se asocien a reacciones alérgicas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.4. Se debe indicar en la lista de ingredientes el agua añadida por orden de predominio, excepto cuando ésta forme parte de un ingrediente compuesto, por ejemplo, de manera enunciativa más no limitativa: la salmuera, el jarabe o el caldo, empleados y declarado como tal en la lista y la que se utilice en los procesos de cocción y reconstitución. No es necesario declarar el agua u otros ingredientes volátiles que se evaporan durante la fabricación.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.5 Cuando se trate de alimentos deshidratados o condensados, destinados a ser reconstituidos, pueden enumerarse sus ingredientes por orden cuantitativo decreciente (m/m) en el producto reconstituido, siempre que se incluya una indicación como la que sigue: "ingredientes del producto cuando se prepara según las instrucciones de la etiqueta", u otras leyendas análogas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.6 En la lista de ingredientes debe emplearse una denominación específica de acuerdo con lo previsto en el punto 4.2.2.1, incluyendo los ingredientes mencionados en 4.2.2.2.3, con excepción de los ingredientes cuyas denominaciones genéricas están señalados en la tabla 1.  Revisar Tabla 1 Denominación genérica de ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.7 No obstante lo estipulado en el punto anterior, la manteca de cerdo, la grasa de bovino o sebo, se deben declarar siempre por sus denominaciones específicas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.8 Los azúcares añadidos se deben declarar conforme a lo siguiente:
            a) agrupados anteponiendo las palabras “azúcares añadidos” seguido de la lista entre paréntesis con las denominaciones específicas de todos los azúcares libres añadidos presentes en el producto preenvasado, excepto de aquellos que formen parte de un ingrediente compuesto, en caso de existir;
            b) en orden cuantitativo decreciente m/m según corresponda a la suma de todos los azúcares añadidos considerados en el inciso a), y
            c) cuando existan ingredientes compuestos en los que formen parte varios azúcares añadidos, éstos también deben agruparse dentro del mismo, conforme a lo establecido en los incisos a) y b),
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2 Coadyuvantes de elaboración y transferencia de aditivos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.1 Debe ser incluido en la lista de ingredientes todo aditivo que haya sido empleado en los ingredientes de un alimento o bebida no alcohólica preenvasado y que se transfiera a otro producto preenvasado en cantidad notable o suficiente para desempeñar en él una función tecnológica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.2 Están exentos de su declaración en la lista de ingredientes los aditivos transferidos a los alimentos y bebidas no alcohólicas preenvasados que ya no cumplen una función tecnológica en el producto terminado, así como los coadyuvantes de elaboración, excepto aquellos que puedan causar hipersensibilidad
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
            <br />-Crustáceos y sus productos.
            <br />-Pescado y sus productos. Se exceptúan: gelatina de pescado utilizada como soporte de vitaminas, aromatizantes o preparados de carotenoides.
            <br />-Moluscos y sus productos.
            <br />-Cacahuate y sus productos.
            <br />-Soya y sus productos. Se exceptúan: aceite y grasa de soya totalmente refinados; tocoferoles naturales mezclados, d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soya; fitoesteroles y ésteres de fitoesteroles derivados de aceites vegetales de soya; ésteres de fitoestanol derivados de fitoesteroles de aceite de soya.
            <br />-Leche, productos de la leche y derivados lácteos (lactosa incluida). Se exceptúa el lactitol.
            <br />-Nueces de árboles y sus productos derivados, tales como las almendras (Prunus amygdalus) y nueces (especies del género Juglans), pero se aplica de modo general a todas las nueces producidas por árboles, incluidas las avellanas (Corylus spp.), pecanas (Carya illinoensis), nuez del Brasil (Bertholletia excelsa), nuez de la india (Anacardium occidentale), castañas (Castanae spp.), nuez de macadamia (Macadamia spp.).
            <br />Sulfito en concentraciones de 10 mg/kg o más.
            <br />b) Cuando el alimento, ingrediente o derivado sea o contenga alguno de los causantes de hipersensibilidad (alérgenos alimentarios) reconocidos en el listado correspondiente, el o los alérgenos deberán declararse al final de la lista de ingredientes.
            <br />i) con letra en negrillas de igual o mayor tamaño a las letras de los ingredientes generales;
            <br />ii) anteponiendo la palabra bajo el título “Contiene”, y
            <br />iii) si el ingrediente es un derivado que contiene albúmina, caseína o gluten puede rotularse declarando su
            origen, como el ejemplo siguiente: contiene: caseína (leche) o caseína de leche.
            <br /><br />
            c) Si existe la posibilidad de contaminación durante el proceso de producción o elaboración hasta el envasado, por parte del fabricante, se deberá incluir al final de la lista de ingredientes, la siguiente frase: "Puede contener", con letra en negrillas, de igual o mayor tamaño a las letras de los ingredientes generales, indicando el alérgeno de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.4. En la declaración de aditivos utilizados en la producción de productos preenvasados, debe utilizarse el nombre común o en su defecto, alguno de los sinónimos establecidos en el Acuerdo.
            <br /><br />
            Las enzimas y saborizantes, saboreador o aromatizantes pueden ser declarados como denominaciones genéricas, excepto la cafeína, la cual debe ser declarada de forma específica.
            <br /><br />
            Los saborizantes, saboreadores o aromatizantes pueden estar calificados con los términos "natural", "idéntico al natural", "artificial" o con una combinación de los mismos según corresponda, a menos que se destaque su presencia de alguna manera, lo cual obliga a la declaración con el término específico.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 28,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3. Etiquetado cuantitativo de los ingredientes
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.1 En todo alimento o bebida no alcohólica preenvasado que se venda como mezcla o combinación, se declarará el porcentaje del ingrediente, con respecto al peso o al volumen que corresponda del ingrediente, al momento de la elaboración del alimento (incluyendo los ingredientes compuestos o categorías de ingredientes), cuando este ingrediente:
            <br />
            <br />
            a) se enfatiza en la etiqueta como presente, por medio de palabras o imágenes o gráficos; o
            <br />b) no figura en el nombre o denominación del alimento o bebida no alcohólica preenvasados y es esencial para caracterizar al mismo, ya que los consumidores asumen su presencia en el producto preenvasado y la omisión de la declaración cuantitativa de ingredientes engaña o lleva a error al consumidor.
            Tales declaraciones no se requieren cuando:
            <br />c) el ingrediente es utilizado en pequeñas cantidades con el propósito de impartir sabor y/o aroma; Respecto al numeral 4.2.2.3.1 (a):
            <br />d) La referencia en el nombre del alimento, ingrediente o categoría de ingredientes enfatizados en la etiqueta no requiera una declaración cuantitativa si es que:
            <br />La referencia al ingrediente enfatizado no conduce a error o engaño, o crea una impresión errónea en el consumidor respecto a la naturaleza del alimento o bebida no alcohólica preenvasado, porque la variación en la cantidad del ingrediente o ingredientes entre productos no es necesaria para caracterizar al mismo o distinguirlo de similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.2 La información requerida en el numeral 4.2.2.3.1 será declarada en la etiqueta del producto como un porcentaje numérico.
            <br />
            El porcentaje del ingrediente, por peso o volumen, según corresponda, se declarará en la etiqueta muy cerca de las palabras o imágenes o gráficos que enfaticen el ingrediente particular, o al lado del nombre común o denominación del alimento o bebida no alcohólica preenvasado, o adyacente al ingrediente que corresponda en la lista de ingredientes. Se declarará como un porcentaje mínimo cuando el énfasis sea sobre la presencia del ingrediente, y como un porcentaje máximo cuando el énfasis sea sobre el bajo nivel del ingrediente.
            <br /><br />
            Para alimentos que han perdido humedad luego de un tratamiento térmico u otro tratamiento, el porcentaje (con respecto al peso o al volumen) corresponderá a la cantidad del ingrediente o ingredientes usados, en relación con el producto terminado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 31,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3 Contenido neto y masa drenada
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3.1 Debe declararse el contenido neto y cuando aplique, la masa drenada en unidades del Sistema General de Unidades de Medida de conformidad a lo que establece la NOM-030-SCFI-2006, independientemente de que también pueda expresarse en otras unidades (véase Referencias).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4 Nombre, denominación o razón social y domicilio fiscal.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4.1. En un producto preenvasado, debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto de manera enunciativa mas no limitativa: calle, número, código postal y entidad federativa en que se encuentre.
            <br />
            En el caso de los productos importados, el nombre y domicilio del importador, en ambos casos, puede incluirse la expresión “fabricado o envasado por o para”, seguido por el nombre y domicilio según corresponda.
            <br /><br />
            4.2.4.2. Para productos preenvasados importados debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto. Esta información puede incorporarse al producto preenvasado en territorio nacional, después del despacho aduanero y antes de la comercialización del producto.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 35,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5 País de origen
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5.1 Los alimentos y bebidas no alcohólicas preenvasados nacionales o de procedencia extranjera deben incorporar la leyenda que identifique el país de origen de los productos, por ejemplo: "Hecho en..."; "Producto de ..."; "Fabricado en ...", u otras análogas, seguida del país de origen del producto, sujeto a lo dispuesto en los tratados internacionales de que los Estados Unidos Mexicanos sean parte. Se permite el uso de gentilicios y otros términos análogos, siempre y cuando sean precisos y no induzcan a error en cuanto al origen del producto. Por ejemplo: “Producto español”, “Producto estadounidense”, entre otros.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 37,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6 Identificación del lote
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.2 La identificación del lote que incorpore el fabricante en el producto preenvasado debe marcarse en forma indeleble y permanente, y no debe ser alterada u ocultada de forma alguna hasta que sea adquirido por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.3 La clave del lote debe ser precedida por cualquiera de las siguientes indicaciones: “LOTE”, “Lot”, “L”, “Lote”, “lote”, “lot”, “l”, “lt”, “LT”, “LOT”, o bien incluir una referencia al lugar donde aparece.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 41,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7 Fecha de caducidad o de consumo preferente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.1 Si no está determinado de otra manera en una Norma Oficial Mexicana la fecha de caducidad o la fecha de consumo preferente deberá cumplir con lo siguiente:
            <br /><br />
            i) El fabricante debe declararla en el envase o etiqueta, la cual debe consistir por lo menos de:
            - El día y el mes para los productos de duración máxima de tres meses;
            - El mes y el año para productos de duración superior a tres meses.
            <br /><br />ii) La fecha debe estar precedida por una leyenda que especifique que dicha fecha se refiere a la fecha de caducidad o al consumo preferente.
            - Para el caso de fecha de caducidad, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Fecha de caducidad ___”, “Caducidad ____”, “Fech Cad ____”, CAD, Cad, cad, Fecha de expiración,
            Expira, Exp, EXP, exp, Fecha de vencimiento, Vencimiento.
            - Para el caso de consumo preferente, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Consumir preferentemente antes del____”, “Cons. Pref. antes del ___”. y “Cons Pref”.

            <br /><br /> iii) Las palabras prescritas en el apartado ii) deberán ir acompañadas de:
            • la fecha misma; o
            • una referencia al lugar donde aparece la fecha.
            Tratándose de productos de importación, cuando el codificado de la fecha de caducidad o de consumo preferente no corresponda al formato establecido en el numeral 4.2.7.1 inciso i), éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En ninguno de estos casos los ajustes serán considerados como alteración.

            <br /><br />4.2.7.2 Al declarar la fecha de caducidad o de consumo preferente se debe indicar en la etiqueta cualquiera de las condiciones especiales que se requieran para la conservación del alimento o bebida no alcohólica preenvasado, si de su cumplimiento depende la validez de la fecha.
            <br />Por ejemplo, se pueden incluir leyendas como: "manténgase en refrigeración"; "consérvese en congelación"; "una vez descongelado no deberá volverse a congelar"; "una vez abierto, consérvese en refrigeración", u otras análogas.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.3 La fecha de caducidad o de consumo preferente que incorpore el fabricante en el producto preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.4 No se requerirá la declaración de fecha de caducidad o consumo preferente, para:
            <br /> ● Vinagre;
            <br /> ● Sal de calidad alimentaria;
            <br /> ● Azúcar sólido;
            <br /> ● Productos de confitería consistentes en azúcares aromatizados y/o coloreados;
            <br /> ● Goma de mascar.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 45,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8 Productos preenvasados con Norma Oficial Mexicana
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.1. Los productos preenvasados deben exhibir la contraseña oficial cuando así lo determine la Norma Oficial Mexicana que regule su denominación o la Ley Federal sobre Metrología y Normalización, lo que se hará considerando lo establecido en el numeral 4.2.8.3 de esta Norma Oficial Mexicana y de conformidad a lo establecido en la NOM-106-SCFI-2017 (ver 2.4 Referencias Normativas).
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.2. Los productos preenvasados, cuya presentación individual indique la leyenda de “No etiquetado para su venta individual” o similar, y requieran usar la contraseña oficial en términos del numeral 4.2.8.1, lo debe hacer únicamente en el empaque múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.3. Los productos preenvasados que ostenten la contraseña oficial incluida en un producto preenvasado conforme al numeral 4.2.8.1 deben incluir, ya sea debajo de la contraseña oficial o del lado derecho de la misma, los tres dígitos correspondientes a la clave o código de la norma oficial mexicana específica para la denominación de producto, con la misma proporcionalidad y tipografía.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Instrucciones para el uso
            <br /> La etiqueta debe contener las instrucciones de uso cuando sean necesarias sobre el modo de empleo, incluida la reconstitución, si es el caso, para asegurar una correcta utilización del producto preenvasado.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Información adicional <br />
            En la etiqueta puede presentarse cualquier información o representación gráfica, así como materia escrita, impresa o gráfica, siempre que no esté en contradicción con los requisitos obligatorios de la presente Norma Oficial Mexicana, incluidos los referentes a la declaración de propiedades establecidos en el apartado 4.1.1.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1 Cuando se empleen designaciones de calidad, éstas deben ser fácilmente comprensibles, evitando ser equívocas o engañosas en forma alguna para el consumidor
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2 Asimismo, en la etiqueta puede presentarse cualquier información o representación gráfica que indique que el envase que contiene el producto preenvasado no afecta al ambiente, evitando que sea falsa o equívoca para el consumidor.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 53,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5. Etiquetado nutrimental <br />
            4.5.1 Componentes<br />
            El etiquetado nutrimental es obligatorio en la etiqueta de los productos preenvasados, y comprende la declaración nutrimental y la información nutrimental complementaria.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Declaración nutrimental
            <br /> Se deben declarar los nutrimentos siguientes, excepto en el producto preenvasado regulado por otros ordenamientos jurídicos aplicables:
            <br /> <br />
            a) el contenido de energía;
            <br /> b) la cantidad de proteína;
            <br /> c) la cantidad de hidratos de carbono disponibles, indicando la cantidad correspondiente a azúcares y a azúcares añadidos.
            <br /> d) la cantidad de grasas especificando la cantidad que corresponda a grasas saturadas y a grasas trans, no incluyendo las grasas trans presentes en ingredientes lácteos y cárnicos de manera natural.
            <br /> e) la cantidad de fibra dietética;
            <br /> f) la cantidad de sodio;
            <br /> g) la cantidad de cualquier otro nutrimento acerca del cual se haga una declaración de propiedades;
            <br /> h) la cantidad de cualquier otro nutrimento que se considere importante, regulado por los ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.1 Cuando se haga una declaración específica de propiedades referente a la cantidad o tipo de hidrato de carbono, pueden indicarse también las cantidades de almidón y, o en su caso, de otros tipos de hidratos de carbono.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.2 Cuando se haga una declaración de propiedades con respecto a la cantidad o al tipo de grasas o la cantidad de colesterol deben declararse las cantidades de: grasas monoinsaturadas, grasas poliinsaturadas y colesterol.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.3 Quedan exceptuados de incluir la declaración nutrimental los productos siguientes, siempre y cuando no incluyan alguna declaración de propiedades nutrimentales o saludables:
            <br /><br />
            productos que incluyan un solo ingrediente;
            <br />ii. hierbas, especias o mezcla de ellas;
            <br />iii. extractos de café, granos de café enteros o molidos descafeinados o no y que no contengan ingredientes añadidos diferentes a aromas;
            <br />iv. infusiones de hierbas, té descafeinado o no, instantáneo y/o soluble que no contengan ingredientes añadidos;
            <br />v. vinagres fermentados y sucedáneos;
            <br />vi. agua para consumo humano y agua mineral natural; y
            <br />vii. los productos en que la superficie más amplia sea inferior a 78 centímetros cuadrados, siempre que incluyan un número telefónico o página Web en la que el consumidor pueda obtener información sobre la declaración nutrimental. Por ejemplo, “Para información sobre declaración nutrimental llame, 800-123-4567”, “Declaración nutrimental disponible en (indicar página Web o número telefónico de atención a clientes) o leyendas análogas". En este caso, los productos no deben incluir alguna declaración de propiedades en el producto mismo, su etiqueta o su publicidad.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 58,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4 Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.1 La declaración nutrimental debe hacerse en las unidades que correspondan al Sistema General de Unidades de Medida NOM-008-SCFI-2002, citada en el capítulo de referencias. Adicionalmente, se pueden emplear otras unidades de medidas. Tratándose de vitaminas y de nutrimentos inorgánicos (minerales), éstos se deben sujetar a lo establecido en el inciso 4.5.2.4.5
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.2 La declaración del contenido energético (Calorías) debe expresarse en kcal (kJ) por 100 g, o por 100 ml, así como por el contenido total del envase. Adicionalmente se puede declarar por porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.3 La declaración sobre la cantidad de proteínas, de hidratos de carbono disponibles, de grasas, de fibra dietética y de sodio que contienen los alimentos y las bebidas no alcohólicas preenvasados debe expresarse en unidades de medida por 100 g o por 100 mL. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.4 La declaración numérica sobre vitaminas y nutrimentos inorgánicos (minerales) debe expresarse en unidades de medida por 100 g o por 100 mL o en porcentaje de los valores nutrimentales de referencia por porción. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.6 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la declaración nutrimental debe realizarse de acuerdo con las instrucciones para el uso indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7 La declaración nutrimental puede presentarse de la siguiente manera o en cualquier otro formato que contenga la información requerida conforme lo indicado en la tabla 3:
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7. BIS La información impresa en la declaración nutrimental debe presentarse en un tamaño de fuente de cuando menos 1.5 mm de altura, y destacarse en negrillas la declaración y la cantidad el contenido energético, la cantidad de grasa saturada, la cantidad de azúcares añadidos, la cantidad de grasas trans y la cantidad de sodio
            <br />
            <br />
            4.5.2.4.7 BIS-1 No obstante lo establecido en 4.5.2.4.7 BIS, la declaración nutrimental debe mostrarse, al menos, en un tamaño de fuente de 1 mm de altura en los siguientes casos:
            <br />a) productos cuya superficie principal de exhibición sea igual o inferior a 32 cm2,
            <br />b) productos obligados a declarar más de 20 nutrimentos, y su superficie principal de exhibición, sea igual o inferior a 161 cm2, y
            <br />c) en envases retornables en los que la información se encuentra en la corcholata o taparrosca. Tabla 3-Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.8 La declaración del contenido de vitaminas y de nutrimentos inorgánicos (minerales) es opcional, excepto en los alimentos y en las bebidas no alcohólicas modificados en su composición, debiendo cumplir con la NOM-086-SSA1-1996 (Ver referencias).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.9 La inclusión de uno de los siguientes nutrimentos no obliga a incluir uno de los otros y sólo se realiza si se tiene asignado un VNR y el contenido de la porción sea igual o esté por arriba del 5% del VNR referido (ya sea IDR o IDS).
            <br />
            <br />
            Vitamina A (% VNR), Vitamina E (% VNR), Vitamina C (% VNR), Vitamina B1 (% VNR), Vitamina B2 (% VNR), Vitamina B6 (% VNR), Vitamina B12 (% VNR), Vitamina D (% VNR), Vitamina K (% VNR), Ácido pantoténico (% VNR), Ácido fólico (% VNR), Niacina (% VNR), Calcio (% VNR), Fósforo (% VNR), Magnesio (% VNR), Hierro (% VNR), Zinc (% VNR), Yodo (% VNR), Cobre (% VNR), Cromo (% VNR), Flúor (% VNR),
            Selenio (% VNR).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 68,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.10 Todos o ninguno de los siguientes
            <br /> Grasa poliinsaturada ___ g; grasa monoinsaturada __ g; colesterol ___ mg.
            <br />4.5.2.4.11 La inclusión de uno de los siguientes no obliga a incluir a los otros
            <br />Almidones ___ g; polialcoholes ___ g; polidextrosas ___ g.
            <br />4.5.2.4.12 Se puede señalar el número de porciones contenidas en el envase, usando el término “aproximadamente” o “aprox”.
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.13 Se puede declarar información basada en valores de referencia recomendados para poblaciones distintas a la mexicana, siempre que ésta se presente junto con la información indicada en 4.5.2.4.7 y se le distinga claramente. Dicha información puede presentarse conforme a lo indicado en la tabla 4 o en cualquier otro formato que contenga la información requerida. Revisar Tabla 4-Presentación de la declaración nutrimental de vitaminas y de minerales basada en porcentaje
            del valor nutrimental de referencia
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.14 Tolerancias y cumplimiento
            <br /> La Secretaría de Salud puede establecer límites de tolerancia en relación con las exigencias de salud pública, en materia de la declaración nutrimental. La estabilidad en almacén, la precisión de los análisis, el diverso grado de elaboración y la inestabilidad y variabilidad propias del nutrimento en el producto, dependiendo de si el nutrimento ha sido añadido al producto o se encuentra naturalmente presente en él, se regularán a través de normas oficiales mexicanas.
            <br />4.5.2.4.15 Los valores de composición bromatológica que figuren en la declaración nutrimental del producto preenvasado, deben ser valores medios ponderados derivados por análisis, bases de datos o tablas reconocidas internacionalmente.
            <br /><br />
            Para cumplir con el contenido declarado de vitaminas y minerales hasta el final de la vida útil se acepta una cantidad superior a lo declarado, dentro de las buenas prácticas de manufactura, siempre y cuando las empresas mantengan los antecedentes técnicos que lo justifiquen.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.16 Para la expresión de la declaración nutrimental se puede utilizar los parámetros de redondeo de la tabla 5, conforme corresponda al nutrimento respectivo. Revisar Tabla 5. Parámetros de redondeo
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 72,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Información nutrimental complementaria.
            <br />
            <br />
            El cálculo y evaluación de esta sección será de acuerdo a los lineamientos de la modificación a la NOM 051 en cualquiera de sus tres etapas y de acuerdo a lo indicado por el cliente en la solicitud de servicios y en lo declarado al inicio de esta lista de inspección.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 73,
        fase: 'I',
        text: (
          <p className="table-content__row__cell--text">
            FASE I
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            Revisar Perfiles Nutrimentales de la Primera Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+I.PNG" alt="Tabla+Fase+I" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 20,
        questionNumber: 74,
        fase: 'II',
        text: (
          <p className="table-content__row__cell--text">
            FASE II
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            <br />Revisar Perfiles Nutrimentales de la Segunda Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+II.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 21,
        questionNumber: 75,
        fase: 'III',
        text: (
          <p className="table-content__row__cell--text">
            FASE III
            <br /><br />
            Debe incluirse la información nutrimental complementaria en la etiqueta de los productos
            preenvasados que:
            <br />a) contengan añadidos: azúcares libres, grasas o sodio; y
            <br />b) el valor de energía, la cantidad de azúcares libres, de grasa saturada, grasas trans y de sodio cumplan con los perfiles nutrimentales establecidos en la Tabla 6.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+III.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br />a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se
            haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos
            e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se
            haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente
            que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.2 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la información nutrimental complementaria se debe declarar conforme a los contenidos de energía, de azúcares libres, grasas saturadas, grasas trans (con excepción de las presentes en productos lácteos y cárnicos de manera natural para el caso de las grasas trans), o de sodio del producto tal como se consume, de acuerdo con las instrucciones indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.3 Quedan exceptuados de la información nutrimental complementaria los productos siguientes:
            <br /> a) los productos que están exceptuados de la declaración nutrimental, conforme se establece en el numeral 4.5.2.3 excepto los señalados en el inciso vii;
            <br /> b) las fórmulas para lactantes, las fórmulas para lactantes con necesidades especiales de nutrición, las fórmulas de continuación y las fórmulas de continuación para necesidades especiales de nutrición;
            <br /> c) los alimentos y las bebidas no alcohólicas para lactantes y para niños de corta edad que tengan especificaciones nutrimentales para alguno de los siguientes nutrimentos: grasas, azúcares y sodio; conforme se establece en los ordenamientos jurídicos aplicables.
            <br /> d) aceites vegetales, grasas vegetales o animales; azúcar, miel, sal yodada y sal yodada fluorurada, así como harinas de cereal.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4 Sistema de etiquetado frontal<br />
            El sistema de etiquetado frontal incluye la información nutrimental complementaria y las leyendas precautorias descritas en los numerales 7.1.3 y 7.1.4.
            <br /><br />
            4.5.3.4.1 La información nutrimental complementaria debe realizarse utilizando los sellos, según corresponda y conforme a lo establecido en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos1.PNG" alt="Sellos 1" style={{ width: '70%' }} />
            <br />
            4.5.3.4.2 Los productos cuya superficie principal de exhibición sea ≤40 cm2 sólo deben incluir un sello con el número que corresponda a la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 en un tamaño mínimo de conformidad a lo establecido en la tabla A1 del Apéndice A (Normativo) de la presente Norma.
            <br />Aquellos productos cuya superficie principal de exhibición sea ≤ 5 cm2 el sello descrito en el párrafo anterior debe de cumplir con las características descritas en el numeral A.4.5 del Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos2.PNG" alt="Sellos 2" style={{ width: '70%' }} />
            <br />
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.3 Para el caso de productos en envases retornables utilizados como contenedores para más de un tipo de producto o de sabor, los productores deben expresar únicamente en la parte externa de la tapa el sello correspondiente al número de la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 y conforme se establece en 4.5.3.4.2.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 80,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Envases Múltiples
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 81,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.4 Los productos cuya presentación individual indique la leyenda de “No etiquetado para su venta individual”, o similar, y que se encuentren en un empaque múltiple o colectivo, únicamente éste debe incluir los sellos que correspondan, conforme a lo establecido en los numerales 4.5.3 y 4.5.3.4.1
            <br />
            <br />
            4.5.3.4.5 Aquellos envases colectivos que contengan más de un tipo de producto deben estar etiquetados de manera individual. Adicionalmente, el envase colectivo debe incluir tantos sellos como corresponda a los productos que contiene, conforme se establece en 4.5.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 82,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.6 Ubicación y orden de los sellos
            <br />El o los sellos deben colocarse en la esquina superior derecha de la superficie principal de exhibición,
            conforme se establece en el Apéndice A (Normativo). En aquellos productos con superficie principal de exhibición menor a 60 cm2 se podrán colocar los sellos en cualquier área de dicha superficie.
            <br />Cuando se deban incluir más de un sello, el orden de inclusión debe ser de izquierda a derecha el siguiente:
            <br />1. EXCESO CALORÍAS
            <br />2. EXCESO AZÚCARES
            <br />3. EXCESO GRASAS SATURADAS
            <br />4. EXCESO GRASAS TRANS
            <br />5. EXCESO SODIO
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 83,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.7 Cuando proceda incluir las leyendas “CONTIENE CAFEÍNA EVITAR EN NIÑOS” o “CONTIENE EDULCORANTES - NO RECOMENDABLE EN NIÑOS”, deben ir en la parte superior derecha de la superficie principal de exhibición y en caso de que el producto preenvasado tenga sellos, deben ir debajo de los mismos, conforme se establece en el Apéndice A (Normativo). Ver Apéndice A (Normativo).
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 84,
        text: (
          <p className="table-content__row__cell--text">
            4.6 Declaración de propiedades nutrimentales
            <br /> 4.6.1 No obstante lo establecido en la presente Norma Oficial Mexicana, toda declaración respecto de las
            propiedades nutrimentales debe sujetarse a lo dispuesto en la NOM-086-SSA1-1994 (ver 2.3 Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 85,
        text: (
          <p className="table-content__row__cell--text">
            4.7 Presentación de los requisitos obligatorios
            <br />4.7.1 Generalidades
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 86,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.1 Las etiquetas que ostenten los productos preenvasados deben fijarse de manera tal que permanezcan disponibles hasta el momento del consumo en condiciones normales, y deben aplicarse por cada unidad, envase múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 87,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.2 Cuando la información comercial obligatoria de los productos preenvasados que van destinados al consumidor final se encuentre en un envase múltiple o colectivo, no será necesario que dicha información aparezca en la superficie del producto individual. Sin embargo, la indicación del lote y la fecha de caducidad o de consumo preferente deben aparecer en el producto preenvasado individual. Además, en el producto preenvasado se debe indicar siempre en lo individual la leyenda "No etiquetado para su venta individual", cuando éstos no tengan toda la información obligatoria o una frase equivalente.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 88,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.3 Los datos que deben aparecer en la etiqueta deben indicarse con caracteres claros, visibles, indelebles y en colores contrastantes, fáciles de leer por el consumidor en circunstancias normales de compra y uso. El dato relativo al lote, fecha de caducidad o de consumo preferente puede ser colocado en cualquier parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 89,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.4 Cuando el envase esté cubierto por una envoltura, debe figurar en ésta toda la información aplicable, a menos de que la etiqueta del envase pueda leerse fácilmente a través de la envoltura exterior.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 90,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.5 Deben aparecer en la superficie principal de exhibición del producto cuando menos la marca, la declaración de cantidad, la denominación del producto preenvasado, el etiquetado frontal y aquella cuya ubicación se haya especificado. El resto de la información a que se refiere esta Norma Oficial Mexicana, puede incorporarse en cualquier otra parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 91,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.8 Idioma
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 92,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 93,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 94,
        text: (
          <p className="table-content__row__cell--text">
            4.8.2 La presentación de información o representación gráfica adicional en la etiqueta a la señalada en esta Norma Oficial Mexicana, que puede estar presente en otro idioma, es facultativa y, en su caso, no debe sustituir, sino añadirse a los requisitos de etiquetado de la presente Norma Oficial Mexicana, siempre y cuando dicha información resulte necesaria para evitar que se induzca a error o engaño al consumidor
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 95,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Cálculos
            <br /> 5.1 Cálculo de nutrimentos
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 96,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Cálculos de energía
            <br /> La cantidad de energía que debe declararse debe calcularse utilizando los siguientes factores de conversión:
            <br /> Hidratos de carbono disponibles 4 kcal/g-17 kJ/g
            <br /> Proteínas 4 kcal/g-17 kJ/g
            <br /> Grasas 9 kcal/g-37 kJ/g
            <br /> Alcohol (etanol) 7 kcal/g-29 kJ/g
            <br /> Polioles (*) 2.4 kcal/g-10 kJ/g
            (sorbitol, xilitol, maltitol, isomalt, isomaltitol, lactitol, manitol)
            <br /> Eritritol (*) 0 kcal/g-0 kJ/g
            <br /> Alulosa (*) 0 kcal/g-0 kJ/g
            <br /> Tagatosa (*) 1.5 kcal/g-6.276 kJ/g
            <br /> (*) Cuando se hace un cálculo teórico del contenido energético se deben usar los factores de conversión específicos para polioles, eritritol, tagatosa y alulosa y no calcularse dentro de los hidratos de carbono disponibles
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 97,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            . Declaraciones de Propiedades
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 98,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Se prohíbe el uso de las siguientes declaraciones:
            <br />6.1.1 De propiedades.
            <br />● Declaraciones que hagan suponer que una alimentación equilibrada a base de alimentos ordinarios no puede suministrar cantidades suficientes de todos los elementos nutritivos.
            <br />● Declaraciones que no pueden comprobarse.
            <br />● Declaraciones sobre la utilidad de un alimento o bebida no alcohólica, para prevenir, aliviar, tratar o curar una enfermedad, trastorno o estado fisiológico.
            <br />● Declaraciones que pueden suscitar dudas sobre la inocuidad de alimentos o bebidas no alcohólicas análogos, o puedan suscitar o provocar miedo en el consumidor.
            <br />● Declaraciones que afirmen que un determinado alimento constituye una fuente adecuada de todos los nutrimentos esenciales.
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 99,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 De propiedades potencialmente engañosas
            <br />A continuación, se presentan ejemplos de declaraciones de propiedades que pueden ser engañosas:
            <br />● Declaraciones de propiedades que carecen de sentido, incluidos los comparativos y superlativos incompletos.
            <br />● Declaraciones de propiedades respecto a prácticas correctas de higiene o comercio, tales como: "genuinidad", "salubridad", "sanidad", “sano”, “saludable”, excepto las señaladas en otros ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 27,
        questionNumber: 100,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Declaraciones de propiedades condicionales
            <br />Se permiten las siguientes declaraciones de propiedades condicionadas a la particular condición asignada a cada una de ellas:
            <br /><br />
            a) Puede indicarse que un alimento ha adquirido un valor nutritivo especial o superior gracias a la adición de nutrimentos, tales como vitaminas, nutrimentos inorgánicos (minerales) y aminoácidos, sólo si dicha adición ha sido hecha sobre la base de consideraciones nutrimentales de acuerdo con el marco jurídico aplicable
            <br /><br />
            b) Las indicaciones de que el alimento tiene cualidades nutricionales especiales gracias a la reducción u omisión de un nutrimento, se deberán hacer sobre la base de consideraciones nutrimentales y estar sujetas al marco jurídico aplicable.
            <br /><br />
            c) Términos como “orgánico”, “ecológico”. “biológico” y las denominaciones con prefijos “bio” y “eco”, deben ajustarse a lo establecido en la Ley de Productos Orgánicos (ver Bibliografía), y aplicar los demás términos que se establezcan en alguna otra Norma Oficial Mexicana o marco jurídico aplicable.
            <br /><br />
            El uso de estos términos debe estar en consonancia con las prohibiciones establecidas en el numeral 6.1.
            <br />d) Declaraciones de propiedades que afirmen que el alimento tiene características especiales cuando todos los alimentos de ese tipo tienen esas mismas características, si este hecho es aparente en la declaración de propiedades.
            <br />e) Pueden utilizarse declaraciones de propiedades que destaquen la ausencia o no adición de determinadas sustancias a los alimentos, siempre que no sean engañosas y la sustancia:
            <br /><br />
            i. no esté sujeta a requisitos específicos en ninguna norma;
            <br />ii. sea una de las que los consumidores esperan encontrar normalmente en el alimento;
            <br />iii. no haya sido sustituida por otra que confiera al alimento características equivalentes a menos que la naturaleza de la sustitución se declare explícitamente con igual prominencia; y
            <br />iv. sea un ingrediente cuya presencia o adición en el alimento esté permitida.
            <br />f) Las declaraciones de propiedades que pongan de relieve la ausencia o no adición de uno o más nutrimentos deberán considerarse como declaraciones de propiedades nutrimentales y, por consiguiente, deberán ajustarse a la declaración obligatoria de nutrimentos, estipulada en el marco jurídico aplicable.
            <br />g) Puede declararse la preparación ritual o religiosa de un alimento (ejemplo, Halal, Kosher), siempre que se ajuste a las exigencias de las autoridades religiosas o del ritual competente y sin importar la presencia de sellos de advertencia.
          </p>
        )
      },
      {
        pageNumber: 28,
        questionNumber: 101,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Declaraciones nutrimentales y saludables
            <br />Este tipo de declaraciones pueden referirse al valor de energía, proteínas, hidratos de carbono, grasas y los derivados de las mismas, fibra dietética, sodio, vitaminas y nutrimentos inorgánicos (minerales) para los cuales se han establecido valores nutrimentales de referencia.
            <br />Sin embargo, en el caso de que el producto preenvasado incluya en su etiquetado alguno de los sellos señalados en 4.5.3.4.1 y cualquiera de las leyendas establecidas en 7.1.3 y 7.1.4, la declaración de propiedades nutrimentales y saludables debe cumplir con lo siguiente:
            <br />a) no deben realizarse declaraciones de propiedades saludables;
            <br />b) no deben realizarse declaraciones de propiedades nutrimentales relacionadas directamente con el sello que haya sido declarado en la etiqueta, y
            <br />c) las declaraciones de propiedades nutrimentales que pueden realizarse deben ostentarse en la superficie de información con una altura máxima de la letra que debe corresponder con la altura mínima de la letra establecida en 4.1.3 de la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas).
            <br /><br />
            6.3.1 Por declaración de propiedades relativas al contenido de nutrimentos se entiende una declaración de propiedades nutrimentales que describe el nivel de un determinado nutrimento contenido en un alimento. (Ejemplos: “Fuente de calcio”; “alto contenido de fibra y bajo en grasa”.)
            <br />6.3.2 Por declaración de propiedades de comparación de nutrimentos se entiende una declaración de propiedades que compara los niveles de nutrimentos y/o el valor energético de dos o más alimentos. (Ejemplos: “reducido”; “menos que”; “menos”; “aumentado”; “más que”.)
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 102,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 Declaración de propiedades saludables significa cualquier representación que declara, sugiere o implica que existe una relación entre un alimento, o un constituyente de dicho alimento, y la salud. Las declaraciones de propiedades saludables incluyen lo siguiente:
            <br />6.3.3.1 Por declaración de función de los nutrimentos se entiende una declaración de propiedades nutrimentales que describe la función fisiológica del nutrimento en el crecimiento, el desarrollo y las funciones normales del organismo.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 103,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3.2 Otras Declaraciones de propiedades de función - Estas declaraciones de propiedades conciernen efectos benéficos específicos del consumo de alimentos o sus constituyentes en el contexto de una dieta total sobre las funciones o actividades biológicas normales del organismo. Tales declaraciones de propiedades se relacionan a una contribución positiva a la salud o a la mejora de una función o la modificación o preservación de la salud. Ejemplo: “La sustancia A (nombrando los efectos de la sustancia A sobre el mejoramiento o modificación de una función fisiológica o la actividad biológica asociada con la salud). El alimento Y contiene X gramos de sustancia A”.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 104,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 No se podrán hacer declaraciones de propiedades cuando se pretenda atribuir al producto características que no contiene o posee ni declaraciones asociadas a la disminución o reducción de riesgo de enfermedad.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 105,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7. Leyendas
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 106,
        text: (
          <p className="table-content__row__cell--text">
            7.1 Leyendas precautorias
            <br /> 7.1.1 Las leyendas precautorias deben hacer referencia al ingrediente u origen del ingrediente que, basado en información científica reconocida, se asocie a riesgos reales o potenciales relacionados con la intolerancia digestiva, alergias o enfermedades metabólicas o toxicidad.
            <br />7.1.2 Las leyendas precautorias específicas por producto, se establecerán en las normas oficiales mexicanas correspondientes u otros ordenamientos jurídicos.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 107,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Si la lista de ingredientes incluye edulcorantes, se debe colocar la leyenda precautoria frontal en
            letras mayúsculas “CONTIENE EDULCORANTES, NO RECOMENDABLE EN NIÑOS”.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/edulcorantes.png" alt="Edulcorantes" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 108,
        text: (
          <p className="table-content__row__cell--text">
            7.1.4. Cuando el producto preenvasado contenga cafeína adicionada dentro de la lista de ingredientes en cualquier cantidad, se debe incluir la leyenda precautoria en letras mayúsculas “CONTIENE CAFEÍNA EVITAR EN NIÑOS”, la cual forma parte del sistema de etiquetado frontal, conforme se establece en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cafeine.PNG" alt="Cafeina" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 109,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Jugos
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 110,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Jugos<br />
            Los jugos deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.14, 3.16 y 3.17 de esta Norma Oficial Mexicana, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.1 y 5.3.1 de esta Norma Oficial Mexicana, asimismo, el contenido de jugo de vegetales o frutas u hortalizas debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana.
            <br /><br />
            Nota 1: Los jugos deben cumplir con las especificaciones descritas en la Tabla 1.
            <br />5.1.1.1 Jugo de __________ (el nombre del vegetal o fruta u hortaliza).
            <br />5.1.1.2 Jugo de __________ (el nombre del vegetal o fruta u hortaliza) de concentrado.
            <br />5.1.1.3 Jugo mixto de __________ (describir los vegetales o frutas o agua de coco o coco u hortalizas que contiene el producto).
            <br />Nota 2: Los jugos mixtos con más de dos vegetales o frutas u hortalizas, agua de coco o coco, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.1.
            <br /><br />
            Se pueden elaborar junto a sus pepitas, semillas y pieles que normalmente no se incorporan al jugo, aunque pueden ser aceptables algunas partes o componentes de pepitas, semillas y pieles que no puedan eliminarse mediante los procesos físicos industriales convencionales.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 111,
        text: (
          <p className="table-content__row__cell--text">
            Tabla 1
            Especificaciones para Jugos, Agua de coco y Jugos de Concentrados una vez Reconstituidos
            <br />
            imagen
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 112,
        text: (
          <p className="table-content__row__cell--text">
            Nota 9: Cuando un jugo proceda de una fruta no mencionada en la Tabla 1, el nivel mínimo de grados Brix de la fruta será el normado internacionalmente por el Codex Alimentarius.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 113,
        text: (
          <p className="table-content__row__cell--text">
            Nota 10: Para el caso del jugo de uva se puede tener una relación isotópica en el intervalo de -23 a -24 únicamente cuando lauva provenga de una región geográfica distinta a México, lo cual debe ser comprobable en la actividad de verificación.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 114,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en jugos<br />
            Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 115,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en jugos
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 116,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 117,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Agua de coco
            <br />El agua de coco debe cumplir con cualquiera de las definiciones establecidas en los incisos 3.2, 3.4 y cumplir con lo establecido en el inciso 5.3.1, usando las siguientes denominaciones de producto según corresponda, el contenido de agua de coco debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana:

            <br /><br />5.1.2.1. Agua de coco
            <br />5.1.2.2 Agua de coco de concentrado

            <br /><br />3.2 agua de coco
            líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.

            <br /><br />3.4 agua de coco de concentrado
            agua de coco elaborada a partir de agua de coco concentrada.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 118,
        text: (
          <p className="table-content__row__cell--text">
            Nota 3: El agua de coco y agua de coco de concentrado debe cumplir con las especificaciones descritas en la Tabla 1.
            <br />
            <table className="table-norms">
              <tr>
                <th>Parámetro</th>
                <th>Agua de Coco</th>
              </tr>
              <tr>
                <td>* Sólidosdisueltosmínimos de lafrutacorrespondiente(°Brix)</td>
                <td>5</td>
              </tr>
              <tr>
                <td>RelaciónIsotópica deCarbono (13C/12C), expresadaen d13CVPDB (%).</td>
                <td>-23 a -25</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 119,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en agua de coco
            <br />Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 120,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en agua de coco
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 121,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 122,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Néctares
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 123,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Néctares
            <br />Los néctares deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.19 y 3.20, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.2 y 5.3.2.
            <br /><br />
            5.1.3.1 Néctar de ___________ (el nombre del vegetal o fruta o coco u hortaliza).
            <br /><br />
            5.1.3.2 Néctar mixto de ________ (describir los vegetales o frutas o coco u hortalizas, según corresponda la combinación entre éstos).
            <br /><br />
            Nota 4: Los néctares mixtos con más de dos vegetales o frutas o coco u hortalizas, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.2.
            <br /><br />
            3.19 néctar
            producto líquido, con pulpa o sin pulpa, elaborado con jugo o pulpa o puré de vegetales o frutas, o coco u hortalizas (maduras y sanas), de la variedad correspondiente, lavadas, finamente divididas y tamizadas, de concentrados o no, de congelados o no y deben cumplir con los parámetros establecidos en la Tabla 2 de esta Norma Oficial Mexicana, pudiendo ser adicionado de agua potable, azúcares añadidos, edulcorantes, así como los aditivos y coadyuvantes permitidos en la Referencia normativa 2.10.
            <br /><br />
            3.20 néctar mixto
            néctar puede elaborarse a partir de la mezcla de dos o más vegetales o frutas u hortalizas o agua de coco o coco y que cumplen con las definiciones establecidas en el inciso 3.19.
          </p>
        )
      },
      {
        pageNumber: 36,
        questionNumber: 124,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Néctares<br />
            Los néctares descritos en los incisos 3.19, 3.20 y 5.1.3 deben cumplir con lo establecido en la Tabla 2.
            <br /><br />
            Tabla 2
            Especificaciones para néctares naturales o reconstituidos
            <br />
            <table className="table-norms">
              <tr>
                <th>Nombre botánico</th>
                <th>Nombre Común</th>
                <th>Valor de °Bx del jugo separado</th>
                <th>Contenido minimo de jugo y/o pulpa (% v/v) en néctares de frutas</th>
              </tr>
              <tr>
                <td>Cocos nucifera L.</td>
                <td>Coco</td>
                <td>5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L.</td>
                <td>Melon</td>
                <td>8</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Cucumis melo L. subsp. melo var.inodorus H. Jacq</td>
                <td>Melon casaba</td>
                <td>7.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L subsp. melo var.inodorus H. Jacq.</td>
                <td>Melon dulce de piel lisa</td>
                <td>10</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Fragaria x. ananassaDuchense(Fragaria chiloensisDuchesne x Fragaria virginianaDuchesne)</td>
                <td>Fresa</td>
                <td>7.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Lycopersicum esculentum L.</td>
                <td>Tomate</td>
                <td>5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Malus domestica Borkh.</td>
                <td>Manzana</td>
                <td>11.5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Mangifera indica L.</td>
                <td>Mango</td>
                <td>13.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus persica (L.) Batsch var.persica</td>
                <td>Durazno</td>
                <td>10.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Psidium guajava L.</td>
                <td>Guayaba</td>
                <td>8.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Pyrus communis L</td>
                <td>Pera</td>
                <td>12</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Vitis Vinifera L. o sus híbridos VitisLabrusca o sus híbridos</td>
                <td>Uva</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Ananas comosus (L.)MerrillAnanas sativis L. Schult. f.</td>
                <td>Piña</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus sinensis (L.)</td>
                <td>Naranja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus paradisi Macfad</td>
                <td>Toronja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Punica granatum L.</td>
                <td>Granada</td>
                <td>12</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Citrus reticulata Blanca</td>
                <td>Mandarina</td>
                <td>11.8</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Carica papaya L.</td>
                <td>Papaya</td>
                <td>11.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Annona muricata L</td>
                <td>Guanábana</td>
                <td>14.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Tamarindus indica</td>
                <td>Tamarindo</td>
                <td>13</td>
                <td>0.5</td>
              </tr>
              <tr>
                <td>Musa species incluidas M.acuminata y M. paradisiaca peroexcluyendo los otros plátanos</td>
                <td>Platano</td>
                <td>22</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus armeniaca L.</td>
                <td>Chabacano</td>
                <td>11.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Apium graveolens</td>
                <td>Apio</td>
                <td>3.1</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Daucus carota</td>
                <td>Zanahoria</td>
                <td>8</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Vaccinium macrocarpon AitonVaccinium oxycoccos L.</td>
                <td>Arandano agrio</td>
                <td>7.5</td>
                <td>30</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 125,
        text: (
          <p className="table-content__row__cell--text">
            Nota 11: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de fruta, deben contener un porcentaje total mínimo de 25 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 126,
        text: (
          <p className="table-content__row__cell--text">
            Nota 12: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de vegetales u hortalizas deben contener un porcentaje total mínimo de 20 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 127,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Ingredientes en néctares
            <br /> Se pueden añadir los siguientes ingredientes a los néctares:
            <br />a) Agua potable
            <br />b) Extractos de vegetales o frutas u hortalizas
            <br />c) Azúcares añadidos
            <br />d) Edulcorantes conforme a la Referencia normativa 2.10
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 128,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2.1 Ingredientes opcionales en néctares
            <br />Se pueden añadir otros ingredientes opcionales a los productos que usen la denominación de producto néctar y su uso debe ser declarado dentro de la denominación del producto.
            <br />Nota 14: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 129,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 130,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas no alcohólicas con vegetal o fruta u hortaliza
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 131,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Estas bebidas no alcohólicas deben cumplir con la definición establecida en el inciso 3.7 y describir el porcentaje de vegetal o fruta, agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, usando la siguiente denominación de producto
            <br /><br />
            5.1.4.1 Bebida con ____ % (dos dígitos) de __________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 5: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo el Aloe vera) deben representar números enteros a través de dos dígitos (mayores a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 10.9, 11.4, 13.6,etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            3.7 bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 132,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Las Bebidas descritas en 5.1.4 deben tener un porcentaje mayor o igual del 10 % del contenido de vegetales o frutas o aguade coco o coco u hortaliza (incluyendo al Aloe vera) y debe ser menor al contenido mínimo de jugo y/o pulpa (% v/v) en néctares de frutas, descrito en la Tabla 2.
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 133,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Ingredientes en bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas descritas en el inciso 5.1.4, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 134,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas descritas en el inciso 5.1.4, pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 135,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas saborizadas no alcohólicas
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 136,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza deben cumplir con la definición establecida en el inciso 3.8 y su denominación de producto debe ser como a continuación se describe.
            <br /><br />
            5.1.5.1 Las bebidas saborizadas no alcohólicas con un porcentaje menor al 10 % de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _____ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera) con ___ % (un dígito) de _________________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 6: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo al Aloe vera) deben representar números enteros a través de un dígito (menor a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 9.9, 5.4, 3.6, etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            Nota 7: En caso de que a una bebida saborizada no alcohólica se le adicione un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza distinto al sabor que dice contener, debe declarar de igual forma el porcentaje de contenido al final de la denominación del producto conforme al 5.1.5.1.
            <br /><br />
            3.8 bebidas saborizadas no alcohólicas
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contenga nningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, aguade coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 137,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 Las bebidas saborizadas no alcohólicas que no contengan un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _______ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso decombinaciones entre éstos, incluyendo al Aloe vera).
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 138,
        text: (
          <p className="table-content__row__cell--text">
            Nota 8: Para el caso de las bebidas saborizadas no alcohólicas de este inciso 5.1.5, pueden usar alguna otra denominación establecida en un instrumento normativo o jurídico en lugar de la palabra "bebida", con la finalidad de describir al consumidor final correctamente en la etiqueta el contenido real del producto, como puede ser: agua mineral, agua, bebida carbonatada o refresco u otro cumpliendo con lo establecido en los incisos 5.1.5.1 y 5.1.5.2. Se puede utilizar el término de uso común después de la palabra Bebida como parte de la denominación y que no incurra en engaño al consumidor. No se considera que alimento líquido sea un término de uso común.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 139,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Bebidas saborizadas no alcohólicas
            Las Bebidas descritas en el inciso 5.1.5 contienen un porcentaje menor al 10 % del contenido de vegetales o frutas o agua de coco o coco u hortalizas (incluyendo al Aloe vera) o contienen únicamente extractos, saborizantes naturales o artificiales.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 140,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Ingredientes en bebidas saborizadas no alcohólicas
            <br />Las bebidas descritas en el inciso 5.1.5 que adicionen menos del 10 % de vegetal o fruta o agua de coco u hortaliza, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 42,
        questionNumber: 141,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza descritas en el inciso 5.1.5,pueden hacer uso de dibujos, sin ser animados en caso de colocar sellos o la leyenda de edulcorantes conforme a la Referencia normativa 2.4, de vegetales o frutas o coco u hortalizas (incluyendo al Aloe vera), dichos dibujos no deben tener un tamaño superior al 5 % del área de la superficie principal de exhibición y deben incluir la frase "dibujo ilustrativo al sabor", esta frase debe tener un tamaño de al menos el 50 % de la declaración cuantitativa del contenido neto (ver Referencia normativa 2.3) y ambos elementos deben ir cercanos a la denominación del producto.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-51 V1',
    documentDescription: 'Lista de inspección para la NOM-173-SE-2021',
    documentIssueDate: '01/03/2023',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4. Especificaciones
            <br />4.1 Requisitos generales de etiquetado
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            4.1.1 La información contenida en las etiquetas de los
            alimentos y bebidas no alcohólicas preenvasados debe ser
            veraz y describirse y presentarse de forma tal que no
            induzca a error al consumidor con respecto a la naturaleza
            y características del producto
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            4.1.2 Los productos preenvasados deben presentarse con una
            etiqueta en la que se describa o se empleen palabras,
            ilustraciones u otras representaciones gráficas que se refieran al producto.
            <br />
            <br />
            Las etiquetas que contengan los productos preenvasados pueden
            incorporar la descripción gráfica o descriptiva de la sugerencia
            de uso, empleo o preparación, a condición de que aparezca una
            leyenda alusiva al respecto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            4.1.3 Los alimentos y bebidas no alcohólicas preenvasados no
            deberán describirse ni presentarse con una etiqueta en los que
            se utilicen palabras, textos, diálogos, ilustraciones, imágenes,
            denominaciones de origen y otras descripciones que se refieran o
            sugieran, directa o indirectamente a cualquier otro producto
            con el que pueda confundirse, o que pueda inducir al consumidor
            a suponer que el alimento se relaciona en forma alguna con aquel
            otro producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4 La etiqueta de los productos preenvasados que no contengan
            los sellos y leyendas precautorias, puede declararlo únicamente
            de forma escrita mediante la frase “Este producto no contiene
            sellos ni leyendas” y no debe utilizar elementos gráficos o
            descriptivo alusivos a los mismos. La declaración debe ser
            colocada en la superficie de información y, su tipografía y
            tamaño debe ser igual o menor al tamaño mínimo cuantitativo
            del contenido neto conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            4.1.4. Bis En caso de que el producto ostente uno o mas sellos de advertencia o la leyenda de edulcorantes el producto no debe de: incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos  o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes; asi como hacer referencias a productos ajenos al mismo pero con la finalidad anteriormente mencionada
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            4.1.5 Los productos preenvasados que ostenten uno o más sellos de advertencia o la leyenda de edulcorantes, no deben:
            <br /> a) incluir en la etiqueta personajes infantiles, animaciones, dibujos animados, celebridades, deportistas o mascotas, elementos interactivos, tales como, juegos visual – espaciales o descargas digitales, que, estando dirigidos a niños, inciten, promueven o fomenten el consumo, compra o elección de productos con exceso de nutrimentos críticos o con edulcorantes, y
            <br /> b) hacer referencia en la etiqueta a elementos ajenos al mismo con las mismas finalidades del párrafo anterior. La aplicación de este numeral se debe hacer en concordancia con lo dispuesto por otros ordenamientos
            legales aplicables.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            4.2 Salvo lo indicado en el siguiente párrafo, son requisitos obligatorios de información comercial y sanitaria los siguientes.
            <br /> A menos de que se trate de especias y de hierbas aromáticas, los productos pequeños en que la superficie más amplia sea inferior a 10 cm2 podrán quedar exentos de los requisitos: lista de ingredientes, instrucciones de uso, lote y fecha de caducidad o de consumo preferente.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1 Nombre o denominación de los productos preenvasados
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1. La denominación del producto preenvasado debe aparecer en negrillas dentro de la superficie principal de exhibición de la etiqueta, en línea paralela a la base como se encuentra diseñado el producto y cumpliendo con las disposiciones de denominación contenidas en una Norma Oficial Mexicana de producto preenvasado.
            <br />Junto a la denominación pueden adicionarse las palabras o frases necesarias para evitar que se induzca al error o engaño al consumidor con respecto a la naturaleza y que incluyen, pero no se limitan a:
            <br />a) el tipo de medio de cobertura;
            <br />b) la forma de presentación o su condición;
            <br />c) en el caso de que haya sido objeto de algún tipo de tratamiento, se puede indicar el nombre de éste, con excepción de aquellos que de acuerdo con los ordenamientos correspondientes sean de carácter obligatorio.
            <br /><br />
            Los elementos descritos anteriormente, forman parte de la denominación del producto preenvasado y deben describirse en forma conjunta, con un tamaño igual o mayor al del dato cuantitativo del contenido neto conforme a la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas) y con la misma proporcionalidad tipográfica, para ser igualmente visibles en la etiqueta y cumplir con lo establecido en este numeral.
            Para el caso de los productos imitación, la denominación del mismo aparecerá en la parte superior izquierda de la superficie principal de exhibición, colocando la palabra IMITACIÓN al principio en mayúsculas, con negrillas en fondo claro en un tamaño del doble al resto de la denominación. No se permite el uso de la palabra imitación en productos preenvasados que cuenten con denominación de origen o indicación geográfica protegida o reconocida por el Estado mexicano.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.1. La denominación del producto preenvasado debe corresponder a los establecidos en las Normas Oficiales Mexicanas u ordenamientos jurídicos específicos y en ausencia de éstos, se debe usar el siguiente orden de prelación para el nombre de una denominación de producto preenvasado:
            <br />a) Nombre de uso común;
            <br />b) Descripción de acuerdo con las características básicas de la composición y naturaleza del producto
            preenvasado, o
            <br />c) Norma internacional del Codex Alimentarius, en su caso.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            4.2.1.1.2. Los productos imitación no deben hacer uso de las palabras tales como “tipo”, “estilo” o algún otro término similar, en la denominación del producto preenvasado o dentro de la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2 Lista de ingredientes
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 13,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1. En la etiqueta del producto preenvasado cuya comercialización se haga en forma individual, debe figurar una lista de ingredientes, salvo cuando se trate de alimentos de un único ingrediente y no incluya algún aditivo.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 14,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.1 La lista de ingredientes debe ir encabezada o precedida por el término Ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 15,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.2 Los ingredientes del alimento o bebida no alcohólica preenvasado deben enumerarse por orden cuantitativo decreciente (m/m).
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 16,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.3. Los ingredientes compuestos deben declararse como tal en la lista de ingredientes, siempre que vayan acompañados inmediatamente de una lista entre paréntesis de sus ingredientes por orden decreciente de proporciones (m/m). Cuando un ingrediente compuesto, constituya menos del 5 por ciento del producto preenvasado, no será necesario declarar los ingredientes que lo conforman, salvo los aditivos alimentarios que desempeñan una función tecnológica en el producto terminado, o aditivos e ingredientes que se asocien a reacciones alérgicas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 17,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.4. Se debe indicar en la lista de ingredientes el agua añadida por orden de predominio, excepto cuando ésta forme parte de un ingrediente compuesto, por ejemplo, de manera enunciativa más no limitativa: la salmuera, el jarabe o el caldo, empleados y declarado como tal en la lista y la que se utilice en los procesos de cocción y reconstitución. No es necesario declarar el agua u otros ingredientes volátiles que se evaporan durante la fabricación.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 18,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.5 Cuando se trate de alimentos deshidratados o condensados, destinados a ser reconstituidos, pueden enumerarse sus ingredientes por orden cuantitativo decreciente (m/m) en el producto reconstituido, siempre que se incluya una indicación como la que sigue: "ingredientes del producto cuando se prepara según las instrucciones de la etiqueta", u otras leyendas análogas.
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 19,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.6 En la lista de ingredientes debe emplearse una denominación específica de acuerdo con lo previsto en el punto 4.2.2.1, incluyendo los ingredientes mencionados en 4.2.2.2.3, con excepción de los ingredientes cuyas denominaciones genéricas están señalados en la tabla 1.  Revisar Tabla 1 Denominación genérica de ingredientes
          </p>
        )
      },
      {
        pageNumber: 5,
        questionNumber: 20,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.7 No obstante lo estipulado en el punto anterior, la manteca de cerdo, la grasa de bovino o sebo, se deben declarar siempre por sus denominaciones específicas.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 21,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.1.8 Los azúcares añadidos se deben declarar conforme a lo siguiente:
            a) agrupados anteponiendo las palabras “azúcares añadidos” seguido de la lista entre paréntesis con las denominaciones específicas de todos los azúcares libres añadidos presentes en el producto preenvasado, excepto de aquellos que formen parte de un ingrediente compuesto, en caso de existir;
            b) en orden cuantitativo decreciente m/m según corresponda a la suma de todos los azúcares añadidos considerados en el inciso a), y
            c) cuando existan ingredientes compuestos en los que formen parte varios azúcares añadidos, éstos también deben agruparse dentro del mismo, conforme a lo establecido en los incisos a) y b),
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 22,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2 Coadyuvantes de elaboración y transferencia de aditivos
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 23,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.1 Debe ser incluido en la lista de ingredientes todo aditivo que haya sido empleado en los ingredientes de un alimento o bebida no alcohólica preenvasado y que se transfiera a otro producto preenvasado en cantidad notable o suficiente para desempeñar en él una función tecnológica.
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 24,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.2 Están exentos de su declaración en la lista de ingredientes los aditivos transferidos a los alimentos y bebidas no alcohólicas preenvasados que ya no cumplen una función tecnológica en el producto terminado, así como los coadyuvantes de elaboración, excepto aquellos que puedan causar hipersensibilidad
          </p>
        )
      },
      {
        pageNumber: 6,
        questionNumber: 25,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 26,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.3 Se deben declarar todos aquellos ingredientes o aditivos que pueden causar hipersensibilidad, intolerancia o alergia, de conformidad con los ordenamientos jurídicos correspondientes.
            <br /> a) Los siguientes alimentos e ingredientes pueden causar hipersensibilidad y deben declararse siempre:
            <br />-Cereales que contienen gluten (trigo, centeno, avena, cebada, espelta o sus cepas híbridas, y productos de estos). Se exceptúan: jarabes de glucosa a base de trigo (incluida la dextrosa), maltodextrinas a base de trigo, jarabes de glucosa a base de cebada.
            <br />-Huevos, sus productos y sus derivados.
            <br />-Crustáceos y sus productos.
            <br />-Pescado y sus productos. Se exceptúan: gelatina de pescado utilizada como soporte de vitaminas, aromatizantes o preparados de carotenoides.
            <br />-Moluscos y sus productos.
            <br />-Cacahuate y sus productos.
            <br />-Soya y sus productos. Se exceptúan: aceite y grasa de soya totalmente refinados; tocoferoles naturales mezclados, d-alfa tocoferol natural, acetato de d-alfa tocoferol natural y succinato de d-alfa tocoferol natural derivados de la soya; fitoesteroles y ésteres de fitoesteroles derivados de aceites vegetales de soya; ésteres de fitoestanol derivados de fitoesteroles de aceite de soya.
            <br />-Leche, productos de la leche y derivados lácteos (lactosa incluida). Se exceptúa el lactitol.
            <br />-Nueces de árboles y sus productos derivados, tales como las almendras (Prunus amygdalus) y nueces (especies del género Juglans), pero se aplica de modo general a todas las nueces producidas por árboles, incluidas las avellanas (Corylus spp.), pecanas (Carya illinoensis), nuez del Brasil (Bertholletia excelsa), nuez de la india (Anacardium occidentale), castañas (Castanae spp.), nuez de macadamia (Macadamia spp.).
            <br />Sulfito en concentraciones de 10 mg/kg o más.
            <br />b) Cuando el alimento, ingrediente o derivado sea o contenga alguno de los causantes de hipersensibilidad (alérgenos alimentarios) reconocidos en el listado correspondiente, el o los alérgenos deberán declararse al final de la lista de ingredientes.
            <br />i) con letra en negrillas de igual o mayor tamaño a las letras de los ingredientes generales;
            <br />ii) anteponiendo la palabra bajo el título “Contiene”, y
            <br />iii) si el ingrediente es un derivado que contiene albúmina, caseína o gluten puede rotularse declarando su
            origen, como el ejemplo siguiente: contiene: caseína (leche) o caseína de leche.
            <br /><br />
            c) Si existe la posibilidad de contaminación durante el proceso de producción o elaboración hasta el envasado, por parte del fabricante, se deberá incluir al final de la lista de ingredientes, la siguiente frase: "Puede contener", con letra en negrillas, de igual o mayor tamaño a las letras de los ingredientes generales, indicando el alérgeno de que se trate.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 27,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.2.4. En la declaración de aditivos utilizados en la producción de productos preenvasados, debe utilizarse el nombre común o en su defecto, alguno de los sinónimos establecidos en el Acuerdo.
            <br /><br />
            Las enzimas y saborizantes, saboreador o aromatizantes pueden ser declarados como denominaciones genéricas, excepto la cafeína, la cual debe ser declarada de forma específica.
            <br /><br />
            Los saborizantes, saboreadores o aromatizantes pueden estar calificados con los términos "natural", "idéntico al natural", "artificial" o con una combinación de los mismos según corresponda, a menos que se destaque su presencia de alguna manera, lo cual obliga a la declaración con el término específico.
          </p>
        )
      },
      {
        pageNumber: 7,
        questionNumber: 28,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3. Etiquetado cuantitativo de los ingredientes
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 29,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.1 En todo alimento o bebida no alcohólica preenvasado que se venda como mezcla o combinación, se declarará el porcentaje del ingrediente, con respecto al peso o al volumen que corresponda del ingrediente, al momento de la elaboración del alimento (incluyendo los ingredientes compuestos o categorías de ingredientes), cuando este ingrediente:
            <br />
            <br />
            a) se enfatiza en la etiqueta como presente, por medio de palabras o imágenes o gráficos; o
            <br />b) no figura en el nombre o denominación del alimento o bebida no alcohólica preenvasados y es esencial para caracterizar al mismo, ya que los consumidores asumen su presencia en el producto preenvasado y la omisión de la declaración cuantitativa de ingredientes engaña o lleva a error al consumidor.
            Tales declaraciones no se requieren cuando:
            <br />c) el ingrediente es utilizado en pequeñas cantidades con el propósito de impartir sabor y/o aroma; Respecto al numeral 4.2.2.3.1 (a):
            <br />d) La referencia en el nombre del alimento, ingrediente o categoría de ingredientes enfatizados en la etiqueta no requiera una declaración cuantitativa si es que:
            <br />La referencia al ingrediente enfatizado no conduce a error o engaño, o crea una impresión errónea en el consumidor respecto a la naturaleza del alimento o bebida no alcohólica preenvasado, porque la variación en la cantidad del ingrediente o ingredientes entre productos no es necesaria para caracterizar al mismo o distinguirlo de similares.
          </p>
        )
      },
      {
        pageNumber: 8,
        questionNumber: 30,
        text: (
          <p className="table-content__row__cell--text">
            4.2.2.3.2 La información requerida en el numeral 4.2.2.3.1 será declarada en la etiqueta del producto como un porcentaje numérico.
            <br />
            El porcentaje del ingrediente, por peso o volumen, según corresponda, se declarará en la etiqueta muy cerca de las palabras o imágenes o gráficos que enfaticen el ingrediente particular, o al lado del nombre común o denominación del alimento o bebida no alcohólica preenvasado, o adyacente al ingrediente que corresponda en la lista de ingredientes. Se declarará como un porcentaje mínimo cuando el énfasis sea sobre la presencia del ingrediente, y como un porcentaje máximo cuando el énfasis sea sobre el bajo nivel del ingrediente.
            <br /><br />
            Para alimentos que han perdido humedad luego de un tratamiento térmico u otro tratamiento, el porcentaje (con respecto al peso o al volumen) corresponderá a la cantidad del ingrediente o ingredientes usados, en relación con el producto terminado.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 31,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3 Contenido neto y masa drenada
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 32,
        text: (
          <p className="table-content__row__cell--text">
            4.2.3.1 Debe declararse el contenido neto y cuando aplique, la masa drenada en unidades del Sistema General de Unidades de Medida de conformidad a lo que establece la NOM-030-SCFI-2006, independientemente de que también pueda expresarse en otras unidades (véase Referencias).
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 33,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4 Nombre, denominación o razón social y domicilio fiscal.
          </p>
        )
      },
      {
        pageNumber: 9,
        questionNumber: 34,
        text: (
          <p className="table-content__row__cell--text">
            4.2.4.1. En un producto preenvasado, debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto de manera enunciativa mas no limitativa: calle, número, código postal y entidad federativa en que se encuentre.
            <br />
            En el caso de los productos importados, el nombre y domicilio del importador, en ambos casos, puede incluirse la expresión “fabricado o envasado por o para”, seguido por el nombre y domicilio según corresponda.
            <br /><br />
            4.2.4.2. Para productos preenvasados importados debe indicarse en la etiqueta el nombre, denominación o razón social y domicilio fiscal del responsable del producto. Esta información puede incorporarse al producto preenvasado en territorio nacional, después del despacho aduanero y antes de la comercialización del producto.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 35,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5 País de origen
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 36,
        text: (
          <p className="table-content__row__cell--text">
            4.2.5.1 Los alimentos y bebidas no alcohólicas preenvasados nacionales o de procedencia extranjera deben incorporar la leyenda que identifique el país de origen de los productos, por ejemplo: "Hecho en..."; "Producto de ..."; "Fabricado en ...", u otras análogas, seguida del país de origen del producto, sujeto a lo dispuesto en los tratados internacionales de que los Estados Unidos Mexicanos sean parte. Se permite el uso de gentilicios y otros términos análogos, siempre y cuando sean precisos y no induzcan a error en cuanto al origen del producto. Por ejemplo: “Producto español”, “Producto estadounidense”, entre otros.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 37,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6 Identificación del lote
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 38,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.1 Cada envase debe llevar grabada o marcada de cualquier modo la identificación del lote al que pertenece, con una indicación en clave que permita su rastreabilidad.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 39,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.2 La identificación del lote que incorpore el fabricante en el producto preenvasado debe marcarse en forma indeleble y permanente, y no debe ser alterada u ocultada de forma alguna hasta que sea adquirido por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 10,
        questionNumber: 40,
        text: (
          <p className="table-content__row__cell--text">
            4.2.6.3 La clave del lote debe ser precedida por cualquiera de las siguientes indicaciones: “LOTE”, “Lot”, “L”, “Lote”, “lote”, “lot”, “l”, “lt”, “LT”, “LOT”, o bien incluir una referencia al lugar donde aparece.
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 41,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7 Fecha de caducidad o de consumo preferente
          </p>
        )
      },
      {
        pageNumber: 11,
        questionNumber: 42,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.1 Si no está determinado de otra manera en una Norma Oficial Mexicana la fecha de caducidad o la fecha de consumo preferente deberá cumplir con lo siguiente:
            <br /><br />
            i) El fabricante debe declararla en el envase o etiqueta, la cual debe consistir por lo menos de:
            - El día y el mes para los productos de duración máxima de tres meses;
            - El mes y el año para productos de duración superior a tres meses.
            <br /><br />ii) La fecha debe estar precedida por una leyenda que especifique que dicha fecha se refiere a la fecha de caducidad o al consumo preferente.
            - Para el caso de fecha de caducidad, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Fecha de caducidad ___”, “Caducidad ____”, “Fech Cad ____”, CAD, Cad, cad, Fecha de expiración,
            Expira, Exp, EXP, exp, Fecha de vencimiento, Vencimiento.
            - Para el caso de consumo preferente, ésta debe indicarse anteponiendo alguna de las siguientes leyendas, sus abreviaturas o leyendas análogas:
            “Consumir preferentemente antes del____”, “Cons. Pref. antes del ___”. y “Cons Pref”.

            <br /><br /> iii) Las palabras prescritas en el apartado ii) deberán ir acompañadas de:
            • la fecha misma; o
            • una referencia al lugar donde aparece la fecha.
            Tratándose de productos de importación, cuando el codificado de la fecha de caducidad o de consumo preferente no corresponda al formato establecido en el numeral 4.2.7.1 inciso i), éste podrá ajustarse a efecto de cumplir con la formalidad establecida, o en su caso, la etiqueta o el envase debe contener la interpretación de la fecha señalada. En ninguno de estos casos los ajustes serán considerados como alteración.

            <br /><br />4.2.7.2 Al declarar la fecha de caducidad o de consumo preferente se debe indicar en la etiqueta cualquiera de las condiciones especiales que se requieran para la conservación del alimento o bebida no alcohólica preenvasado, si de su cumplimiento depende la validez de la fecha.
            <br />Por ejemplo, se pueden incluir leyendas como: "manténgase en refrigeración"; "consérvese en congelación"; "una vez descongelado no deberá volverse a congelar"; "una vez abierto, consérvese en refrigeración", u otras análogas.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 43,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.3 La fecha de caducidad o de consumo preferente que incorpore el fabricante en el producto preenvasado no puede ser alterada en ningún caso y bajo ninguna circunstancia.
          </p>
        )
      },
      {
        pageNumber: 12,
        questionNumber: 44,
        text: (
          <p className="table-content__row__cell--text">
            4.2.7.4 No se requerirá la declaración de fecha de caducidad o consumo preferente, para:
            <br /> ● Vinagre;
            <br /> ● Sal de calidad alimentaria;
            <br /> ● Azúcar sólido;
            <br /> ● Productos de confitería consistentes en azúcares aromatizados y/o coloreados;
            <br /> ● Goma de mascar.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 45,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8 Productos preenvasados con Norma Oficial Mexicana
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 46,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.1. Los productos preenvasados deben exhibir la contraseña oficial cuando así lo determine la Norma Oficial Mexicana que regule su denominación o la Ley Federal sobre Metrología y Normalización, lo que se hará considerando lo establecido en el numeral 4.2.8.3 de esta Norma Oficial Mexicana y de conformidad a lo establecido en la NOM-106-SCFI-2017 (ver 2.4 Referencias Normativas).
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 47,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.2. Los productos preenvasados, cuya presentación individual indique la leyenda de “No etiquetado para su venta individual” o similar, y requieran usar la contraseña oficial en términos del numeral 4.2.8.1, lo debe hacer únicamente en el empaque múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 48,
        text: (
          <p className="table-content__row__cell--text">
            4.2.8.3. Los productos preenvasados que ostenten la contraseña oficial incluida en un producto preenvasado conforme al numeral 4.2.8.1 deben incluir, ya sea debajo de la contraseña oficial o del lado derecho de la misma, los tres dígitos correspondientes a la clave o código de la norma oficial mexicana específica para la denominación de producto, con la misma proporcionalidad y tipografía.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 49,
        text: (
          <p className="table-content__row__cell--text">
            4.3 Instrucciones para el uso
            <br /> La etiqueta debe contener las instrucciones de uso cuando sean necesarias sobre el modo de empleo, incluida la reconstitución, si es el caso, para asegurar una correcta utilización del producto preenvasado.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 50,
        text: (
          <p className="table-content__row__cell--text">
            4.4 Información adicional <br />
            En la etiqueta puede presentarse cualquier información o representación gráfica, así como materia escrita, impresa o gráfica, siempre que no esté en contradicción con los requisitos obligatorios de la presente Norma Oficial Mexicana, incluidos los referentes a la declaración de propiedades establecidos en el apartado 4.1.1.
          </p>
        )
      },
      {
        pageNumber: 13,
        questionNumber: 51,
        text: (
          <p className="table-content__row__cell--text">
            4.4.1 Cuando se empleen designaciones de calidad, éstas deben ser fácilmente comprensibles, evitando ser equívocas o engañosas en forma alguna para el consumidor
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 52,
        text: (
          <p className="table-content__row__cell--text">
            4.4.2 Asimismo, en la etiqueta puede presentarse cualquier información o representación gráfica que indique que el envase que contiene el producto preenvasado no afecta al ambiente, evitando que sea falsa o equívoca para el consumidor.
          </p>
        )
      },
      {
        pageNumber: 14,
        questionNumber: 53,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5. Etiquetado nutrimental <br />
            4.5.1 Componentes<br />
            El etiquetado nutrimental es obligatorio en la etiqueta de los productos preenvasados, y comprende la declaración nutrimental y la información nutrimental complementaria.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 54,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2 Declaración nutrimental
            <br /> Se deben declarar los nutrimentos siguientes, excepto en el producto preenvasado regulado por otros ordenamientos jurídicos aplicables:
            <br /> <br />
            a) el contenido de energía;
            <br /> b) la cantidad de proteína;
            <br /> c) la cantidad de hidratos de carbono disponibles, indicando la cantidad correspondiente a azúcares y a azúcares añadidos.
            <br /> d) la cantidad de grasas especificando la cantidad que corresponda a grasas saturadas y a grasas trans, no incluyendo las grasas trans presentes en ingredientes lácteos y cárnicos de manera natural.
            <br /> e) la cantidad de fibra dietética;
            <br /> f) la cantidad de sodio;
            <br /> g) la cantidad de cualquier otro nutrimento acerca del cual se haga una declaración de propiedades;
            <br /> h) la cantidad de cualquier otro nutrimento que se considere importante, regulado por los ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 55,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.1 Cuando se haga una declaración específica de propiedades referente a la cantidad o tipo de hidrato de carbono, pueden indicarse también las cantidades de almidón y, o en su caso, de otros tipos de hidratos de carbono.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 56,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.2 Cuando se haga una declaración de propiedades con respecto a la cantidad o al tipo de grasas o la cantidad de colesterol deben declararse las cantidades de: grasas monoinsaturadas, grasas poliinsaturadas y colesterol.
          </p>
        )
      },
      {
        pageNumber: 15,
        questionNumber: 57,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.3 Quedan exceptuados de incluir la declaración nutrimental los productos siguientes, siempre y cuando no incluyan alguna declaración de propiedades nutrimentales o saludables:
            <br /><br />
            productos que incluyan un solo ingrediente;
            <br />ii. hierbas, especias o mezcla de ellas;
            <br />iii. extractos de café, granos de café enteros o molidos descafeinados o no y que no contengan ingredientes añadidos diferentes a aromas;
            <br />iv. infusiones de hierbas, té descafeinado o no, instantáneo y/o soluble que no contengan ingredientes añadidos;
            <br />v. vinagres fermentados y sucedáneos;
            <br />vi. agua para consumo humano y agua mineral natural; y
            <br />vii. los productos en que la superficie más amplia sea inferior a 78 centímetros cuadrados, siempre que incluyan un número telefónico o página Web en la que el consumidor pueda obtener información sobre la declaración nutrimental. Por ejemplo, “Para información sobre declaración nutrimental llame, 800-123-4567”, “Declaración nutrimental disponible en (indicar página Web o número telefónico de atención a clientes) o leyendas análogas". En este caso, los productos no deben incluir alguna declaración de propiedades en el producto mismo, su etiqueta o su publicidad.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 58,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4 Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 59,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.1 La declaración nutrimental debe hacerse en las unidades que correspondan al Sistema General de Unidades de Medida NOM-008-SCFI-2002, citada en el capítulo de referencias. Adicionalmente, se pueden emplear otras unidades de medidas. Tratándose de vitaminas y de nutrimentos inorgánicos (minerales), éstos se deben sujetar a lo establecido en el inciso 4.5.2.4.5
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 60,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.2 La declaración del contenido energético (Calorías) debe expresarse en kcal (kJ) por 100 g, o por 100 ml, así como por el contenido total del envase. Adicionalmente se puede declarar por porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 61,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.3 La declaración sobre la cantidad de proteínas, de hidratos de carbono disponibles, de grasas, de fibra dietética y de sodio que contienen los alimentos y las bebidas no alcohólicas preenvasados debe expresarse en unidades de medida por 100 g o por 100 mL. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 16,
        questionNumber: 62,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.4 La declaración numérica sobre vitaminas y nutrimentos inorgánicos (minerales) debe expresarse en unidades de medida por 100 g o por 100 mL o en porcentaje de los valores nutrimentales de referencia por porción. Adicionalmente se puede declarar por porción en envases que contengan varias porciones, o por envase cuando éste contiene sólo una porción.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 63,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.6 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la declaración nutrimental debe realizarse de acuerdo con las instrucciones para el uso indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 64,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7 La declaración nutrimental puede presentarse de la siguiente manera o en cualquier otro formato que contenga la información requerida conforme lo indicado en la tabla 3:
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 65,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.7. BIS La información impresa en la declaración nutrimental debe presentarse en un tamaño de fuente de cuando menos 1.5 mm de altura, y destacarse en negrillas la declaración y la cantidad el contenido energético, la cantidad de grasa saturada, la cantidad de azúcares añadidos, la cantidad de grasas trans y la cantidad de sodio
            <br />
            <br />
            4.5.2.4.7 BIS-1 No obstante lo establecido en 4.5.2.4.7 BIS, la declaración nutrimental debe mostrarse, al menos, en un tamaño de fuente de 1 mm de altura en los siguientes casos:
            <br />a) productos cuya superficie principal de exhibición sea igual o inferior a 32 cm2,
            <br />b) productos obligados a declarar más de 20 nutrimentos, y su superficie principal de exhibición, sea igual o inferior a 161 cm2, y
            <br />c) en envases retornables en los que la información se encuentra en la corcholata o taparrosca. Tabla 3-Presentación de la declaración nutrimental
          </p>
        )
      },
      {
        pageNumber: 17,
        questionNumber: 66,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.8 La declaración del contenido de vitaminas y de nutrimentos inorgánicos (minerales) es opcional, excepto en los alimentos y en las bebidas no alcohólicas modificados en su composición, debiendo cumplir con la NOM-086-SSA1-1996 (Ver referencias).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 67,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.9 La inclusión de uno de los siguientes nutrimentos no obliga a incluir uno de los otros y sólo se realiza si se tiene asignado un VNR y el contenido de la porción sea igual o esté por arriba del 5% del VNR referido (ya sea IDR o IDS).
            <br />
            <br />
            Vitamina A (% VNR), Vitamina E (% VNR), Vitamina C (% VNR), Vitamina B1 (% VNR), Vitamina B2 (% VNR), Vitamina B6 (% VNR), Vitamina B12 (% VNR), Vitamina D (% VNR), Vitamina K (% VNR), Ácido pantoténico (% VNR), Ácido fólico (% VNR), Niacina (% VNR), Calcio (% VNR), Fósforo (% VNR), Magnesio (% VNR), Hierro (% VNR), Zinc (% VNR), Yodo (% VNR), Cobre (% VNR), Cromo (% VNR), Flúor (% VNR),
            Selenio (% VNR).
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 68,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.10 Todos o ninguno de los siguientes
            <br /> Grasa poliinsaturada ___ g; grasa monoinsaturada __ g; colesterol ___ mg.
            <br />4.5.2.4.11 La inclusión de uno de los siguientes no obliga a incluir a los otros
            <br />Almidones ___ g; polialcoholes ___ g; polidextrosas ___ g.
            <br />4.5.2.4.12 Se puede señalar el número de porciones contenidas en el envase, usando el término “aproximadamente” o “aprox”.
          </p>
        )
      },
      {
        pageNumber: 18,
        questionNumber: 69,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.13 Se puede declarar información basada en valores de referencia recomendados para poblaciones distintas a la mexicana, siempre que ésta se presente junto con la información indicada en 4.5.2.4.7 y se le distinga claramente. Dicha información puede presentarse conforme a lo indicado en la tabla 4 o en cualquier otro formato que contenga la información requerida. Revisar Tabla 4-Presentación de la declaración nutrimental de vitaminas y de minerales basada en porcentaje
            del valor nutrimental de referencia
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 70,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.14 Tolerancias y cumplimiento
            <br /> La Secretaría de Salud puede establecer límites de tolerancia en relación con las exigencias de salud pública, en materia de la declaración nutrimental. La estabilidad en almacén, la precisión de los análisis, el diverso grado de elaboración y la inestabilidad y variabilidad propias del nutrimento en el producto, dependiendo de si el nutrimento ha sido añadido al producto o se encuentra naturalmente presente en él, se regularán a través de normas oficiales mexicanas.
            <br />4.5.2.4.15 Los valores de composición bromatológica que figuren en la declaración nutrimental del producto preenvasado, deben ser valores medios ponderados derivados por análisis, bases de datos o tablas reconocidas internacionalmente.
            <br /><br />
            Para cumplir con el contenido declarado de vitaminas y minerales hasta el final de la vida útil se acepta una cantidad superior a lo declarado, dentro de las buenas prácticas de manufactura, siempre y cuando las empresas mantengan los antecedentes técnicos que lo justifiquen.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 71,
        text: (
          <p className="table-content__row__cell--text">
            4.5.2.4.16 Para la expresión de la declaración nutrimental se puede utilizar los parámetros de redondeo de la tabla 5, conforme corresponda al nutrimento respectivo. Revisar Tabla 5. Parámetros de redondeo
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 72,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3 Información nutrimental complementaria.
            <br />
            <br />
            El cálculo y evaluación de esta sección será de acuerdo a los lineamientos de la modificación a la NOM 051 en cualquiera de sus tres etapas y de acuerdo a lo indicado por el cliente en la solicitud de servicios y en lo declarado al inicio de esta lista de inspección.
          </p>
        )
      },
      {
        pageNumber: 19,
        questionNumber: 73,
        fase: 'I',
        text: (
          <p className="table-content__row__cell--text">
            FASE I
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            Revisar Perfiles Nutrimentales de la Primera Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+I.PNG" alt="Tabla+Fase+I" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 20,
        questionNumber: 74,
        fase: 'II',
        text: (
          <p className="table-content__row__cell--text">
            FASE II
            <br /><br />
            El cálculo y evaluación
            de la información nutrimental complementaria se realizará con los siguientes criterios y valores:
            <br />1.- Nutrimentos críticos añadidos al alimento o bebida no alcohólica preenvasado:
            <br />a) Si se agregan azúcares añadidos, se deberán evaluar azúcares y calorías
            <br />b) Si se agregan grasas, se deberán evaluar grasas saturadas, grasas trans y calorías
            <br />c) Si se agrega sodio, sólo se deberá evaluar sodio.
            <br />Revisar Perfiles Nutrimentales de la Segunda Fase
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+II.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br /><br />
            a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 21,
        questionNumber: 75,
        fase: 'III',
        text: (
          <p className="table-content__row__cell--text">
            FASE III
            <br /><br />
            Debe incluirse la información nutrimental complementaria en la etiqueta de los productos
            preenvasados que:
            <br />a) contengan añadidos: azúcares libres, grasas o sodio; y
            <br />b) el valor de energía, la cantidad de azúcares libres, de grasa saturada, grasas trans y de sodio cumplan con los perfiles nutrimentales establecidos en la Tabla 6.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/Tabla+Fase+III.PNG" alt="Tabla+Fase+II" style={{ width: '70%' }} />
            <br />
            4.5.3.1 Para los efectos del inciso anterior se entiende por:
            <br />a) producto preenvasado añadido de azúcares libres, aquellos a los que durante el proceso de elaboración se les haya añadido azúcares libres, e ingredientes que contengan agregados azúcares libres.
            <br />b) producto preenvasado añadido de grasas, aquellos a los que durante el proceso de elaboración se
            haya añadido grasas vegetales o animales, aceites vegetales parcialmente hidrogenados o productos
            e ingredientes que los contengan agregados; y
            <br />c) producto preenvasado añadido de sodio, aquellos a los que durante el proceso de elaboración se
            haya utilizado como ingrediente o aditivo cualquier sal que contenga sodio o cualquier ingrediente
            que contenga sales de sodio agregadas.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 76,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.2 En los productos destinados a ser reconstituidos o que requieran preparación antes de ser consumidos, la información nutrimental complementaria se debe declarar conforme a los contenidos de energía, de azúcares libres, grasas saturadas, grasas trans (con excepción de las presentes en productos lácteos y cárnicos de manera natural para el caso de las grasas trans), o de sodio del producto tal como se consume, de acuerdo con las instrucciones indicadas en la etiqueta.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 77,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.3 Quedan exceptuados de la información nutrimental complementaria los productos siguientes:
            <br /> a) los productos que están exceptuados de la declaración nutrimental, conforme se establece en el numeral 4.5.2.3 excepto los señalados en el inciso vii;
            <br /> b) las fórmulas para lactantes, las fórmulas para lactantes con necesidades especiales de nutrición, las fórmulas de continuación y las fórmulas de continuación para necesidades especiales de nutrición;
            <br /> c) los alimentos y las bebidas no alcohólicas para lactantes y para niños de corta edad que tengan especificaciones nutrimentales para alguno de los siguientes nutrimentos: grasas, azúcares y sodio; conforme se establece en los ordenamientos jurídicos aplicables.
            <br /> d) aceites vegetales, grasas vegetales o animales; azúcar, miel, sal yodada y sal yodada fluorurada, así como harinas de cereal.
          </p>
        )
      },
      {
        pageNumber: 22,
        questionNumber: 78,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4 Sistema de etiquetado frontal<br />
            El sistema de etiquetado frontal incluye la información nutrimental complementaria y las leyendas precautorias descritas en los numerales 7.1.3 y 7.1.4.
            <br /><br />
            4.5.3.4.1 La información nutrimental complementaria debe realizarse utilizando los sellos, según corresponda y conforme a lo establecido en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos1.PNG" alt="Sellos 1" style={{ width: '70%' }} />
            <br />
            4.5.3.4.2 Los productos cuya superficie principal de exhibición sea ≤40 cm2 sólo deben incluir un sello con el número que corresponda a la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 en un tamaño mínimo de conformidad a lo establecido en la tabla A1 del Apéndice A (Normativo) de la presente Norma.
            <br />Aquellos productos cuya superficie principal de exhibición sea ≤ 5 cm2 el sello descrito en el párrafo anterior debe de cumplir con las características descritas en el numeral A.4.5 del Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/sellos2.PNG" alt="Sellos 2" style={{ width: '70%' }} />
            <br />
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 79,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.3 Para el caso de productos en envases retornables utilizados como contenedores para más de un tipo de producto o de sabor, los productores deben expresar únicamente en la parte externa de la tapa el sello correspondiente al número de la cantidad de nutrimentos que cumplen con el perfil establecido en 4.5.3 y conforme se establece en 4.5.3.4.2.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 80,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Envases Múltiples
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 81,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.4 Los productos cuya presentación individual indique la leyenda de “No etiquetado para su venta individual”, o similar, y que se encuentren en un empaque múltiple o colectivo, únicamente éste debe incluir los sellos que correspondan, conforme a lo establecido en los numerales 4.5.3 y 4.5.3.4.1
            <br />
            <br />
            4.5.3.4.5 Aquellos envases colectivos que contengan más de un tipo de producto deben estar etiquetados de manera individual. Adicionalmente, el envase colectivo debe incluir tantos sellos como corresponda a los productos que contiene, conforme se establece en 4.5.3 de la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 82,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.6 Ubicación y orden de los sellos
            <br />El o los sellos deben colocarse en la esquina superior derecha de la superficie principal de exhibición,
            conforme se establece en el Apéndice A (Normativo). En aquellos productos con superficie principal de exhibición menor a 60 cm2 se podrán colocar los sellos en cualquier área de dicha superficie.
            <br />Cuando se deban incluir más de un sello, el orden de inclusión debe ser de izquierda a derecha el siguiente:
            <br />1. EXCESO CALORÍAS
            <br />2. EXCESO AZÚCARES
            <br />3. EXCESO GRASAS SATURADAS
            <br />4. EXCESO GRASAS TRANS
            <br />5. EXCESO SODIO
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 83,
        text: (
          <p className="table-content__row__cell--text">
            4.5.3.4.7 Cuando proceda incluir las leyendas “CONTIENE CAFEÍNA EVITAR EN NIÑOS” o “CONTIENE EDULCORANTES - NO RECOMENDABLE EN NIÑOS”, deben ir en la parte superior derecha de la superficie principal de exhibición y en caso de que el producto preenvasado tenga sellos, deben ir debajo de los mismos, conforme se establece en el Apéndice A (Normativo). Ver Apéndice A (Normativo).
          </p>
        )
      },
      {
        pageNumber: 23,
        questionNumber: 84,
        text: (
          <p className="table-content__row__cell--text">
            4.6 Declaración de propiedades nutrimentales
            <br /> 4.6.1 No obstante lo establecido en la presente Norma Oficial Mexicana, toda declaración respecto de las
            propiedades nutrimentales debe sujetarse a lo dispuesto en la NOM-086-SSA1-1994 (ver 2.3 Referencias normativas).
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 85,
        text: (
          <p className="table-content__row__cell--text">
            4.7 Presentación de los requisitos obligatorios
            <br />4.7.1 Generalidades
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 86,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.1 Las etiquetas que ostenten los productos preenvasados deben fijarse de manera tal que permanezcan disponibles hasta el momento del consumo en condiciones normales, y deben aplicarse por cada unidad, envase múltiple o colectivo.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 87,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.2 Cuando la información comercial obligatoria de los productos preenvasados que van destinados al consumidor final se encuentre en un envase múltiple o colectivo, no será necesario que dicha información aparezca en la superficie del producto individual. Sin embargo, la indicación del lote y la fecha de caducidad o de consumo preferente deben aparecer en el producto preenvasado individual. Además, en el producto preenvasado se debe indicar siempre en lo individual la leyenda "No etiquetado para su venta individual", cuando éstos no tengan toda la información obligatoria o una frase equivalente.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 88,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.3 Los datos que deben aparecer en la etiqueta deben indicarse con caracteres claros, visibles, indelebles y en colores contrastantes, fáciles de leer por el consumidor en circunstancias normales de compra y uso. El dato relativo al lote, fecha de caducidad o de consumo preferente puede ser colocado en cualquier parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 89,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.4 Cuando el envase esté cubierto por una envoltura, debe figurar en ésta toda la información aplicable, a menos de que la etiqueta del envase pueda leerse fácilmente a través de la envoltura exterior.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 90,
        text: (
          <p className="table-content__row__cell--text">
            4.7.1.5 Deben aparecer en la superficie principal de exhibición del producto cuando menos la marca, la declaración de cantidad, la denominación del producto preenvasado, el etiquetado frontal y aquella cuya ubicación se haya especificado. El resto de la información a que se refiere esta Norma Oficial Mexicana, puede incorporarse en cualquier otra parte del envase.
          </p>
        )
      },
      {
        pageNumber: 24,
        questionNumber: 91,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            4.8 Idioma
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 92,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 93,
        text: (
          <p className="table-content__row__cell--text">
            4.8.1 El producto preenvasado debe ostentar la información obligatoria a que se refiere esta Norma Oficial Mexicana en idioma español, sin perjuicio de que se exprese en otros idiomas. Cuando la información obligatoria se exprese en otros idiomas debe aparecer también en español, de conformidad con lo establecido en la presente Norma Oficial Mexicana.
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 94,
        text: (
          <p className="table-content__row__cell--text">
            4.8.2 La presentación de información o representación gráfica adicional en la etiqueta a la señalada en esta Norma Oficial Mexicana, que puede estar presente en otro idioma, es facultativa y, en su caso, no debe sustituir, sino añadirse a los requisitos de etiquetado de la presente Norma Oficial Mexicana, siempre y cuando dicha información resulte necesaria para evitar que se induzca a error o engaño al consumidor
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 95,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            5. Cálculos
            <br /> 5.1 Cálculo de nutrimentos
          </p>
        )
      },
      {
        pageNumber: 25,
        questionNumber: 96,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Cálculos de energía
            <br /> La cantidad de energía que debe declararse debe calcularse utilizando los siguientes factores de conversión:
            <br /> Hidratos de carbono disponibles 4 kcal/g-17 kJ/g
            <br /> Proteínas 4 kcal/g-17 kJ/g
            <br /> Grasas 9 kcal/g-37 kJ/g
            <br /> Alcohol (etanol) 7 kcal/g-29 kJ/g
            <br /> Polioles (*) 2.4 kcal/g-10 kJ/g
            (sorbitol, xilitol, maltitol, isomalt, isomaltitol, lactitol, manitol)
            <br /> Eritritol (*) 0 kcal/g-0 kJ/g
            <br /> Alulosa (*) 0 kcal/g-0 kJ/g
            <br /> Tagatosa (*) 1.5 kcal/g-6.276 kJ/g
            <br /> (*) Cuando se hace un cálculo teórico del contenido energético se deben usar los factores de conversión específicos para polioles, eritritol, tagatosa y alulosa y no calcularse dentro de los hidratos de carbono disponibles
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 97,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            . Declaraciones de Propiedades
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 98,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Se prohíbe el uso de las siguientes declaraciones:
            <br />6.1.1 De propiedades.
            <br />● Declaraciones que hagan suponer que una alimentación equilibrada a base de alimentos ordinarios no puede suministrar cantidades suficientes de todos los elementos nutritivos.
            <br />● Declaraciones que no pueden comprobarse.
            <br />● Declaraciones sobre la utilidad de un alimento o bebida no alcohólica, para prevenir, aliviar, tratar o curar una enfermedad, trastorno o estado fisiológico.
            <br />● Declaraciones que pueden suscitar dudas sobre la inocuidad de alimentos o bebidas no alcohólicas análogos, o puedan suscitar o provocar miedo en el consumidor.
            <br />● Declaraciones que afirmen que un determinado alimento constituye una fuente adecuada de todos los nutrimentos esenciales.
          </p>
        )
      },
      {
        pageNumber: 26,
        questionNumber: 99,
        text: (
          <p className="table-content__row__cell--text">
            6.1.2 De propiedades potencialmente engañosas
            <br />A continuación, se presentan ejemplos de declaraciones de propiedades que pueden ser engañosas:
            <br />● Declaraciones de propiedades que carecen de sentido, incluidos los comparativos y superlativos incompletos.
            <br />● Declaraciones de propiedades respecto a prácticas correctas de higiene o comercio, tales como: "genuinidad", "salubridad", "sanidad", “sano”, “saludable”, excepto las señaladas en otros ordenamientos jurídicos aplicables.
          </p>
        )
      },
      {
        pageNumber: 27,
        questionNumber: 100,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Declaraciones de propiedades condicionales
            <br />Se permiten las siguientes declaraciones de propiedades condicionadas a la particular condición asignada a cada una de ellas:
            <br /><br />
            a) Puede indicarse que un alimento ha adquirido un valor nutritivo especial o superior gracias a la adición de nutrimentos, tales como vitaminas, nutrimentos inorgánicos (minerales) y aminoácidos, sólo si dicha adición ha sido hecha sobre la base de consideraciones nutrimentales de acuerdo con el marco jurídico aplicable
            <br /><br />
            b) Las indicaciones de que el alimento tiene cualidades nutricionales especiales gracias a la reducción u omisión de un nutrimento, se deberán hacer sobre la base de consideraciones nutrimentales y estar sujetas al marco jurídico aplicable.
            <br /><br />
            c) Términos como “orgánico”, “ecológico”. “biológico” y las denominaciones con prefijos “bio” y “eco”, deben ajustarse a lo establecido en la Ley de Productos Orgánicos (ver Bibliografía), y aplicar los demás términos que se establezcan en alguna otra Norma Oficial Mexicana o marco jurídico aplicable.
            <br /><br />
            El uso de estos términos debe estar en consonancia con las prohibiciones establecidas en el numeral 6.1.
            <br />d) Declaraciones de propiedades que afirmen que el alimento tiene características especiales cuando todos los alimentos de ese tipo tienen esas mismas características, si este hecho es aparente en la declaración de propiedades.
            <br />e) Pueden utilizarse declaraciones de propiedades que destaquen la ausencia o no adición de determinadas sustancias a los alimentos, siempre que no sean engañosas y la sustancia:
            <br /><br />
            i. no esté sujeta a requisitos específicos en ninguna norma;
            <br />ii. sea una de las que los consumidores esperan encontrar normalmente en el alimento;
            <br />iii. no haya sido sustituida por otra que confiera al alimento características equivalentes a menos que la naturaleza de la sustitución se declare explícitamente con igual prominencia; y
            <br />iv. sea un ingrediente cuya presencia o adición en el alimento esté permitida.
            <br />f) Las declaraciones de propiedades que pongan de relieve la ausencia o no adición de uno o más nutrimentos deberán considerarse como declaraciones de propiedades nutrimentales y, por consiguiente, deberán ajustarse a la declaración obligatoria de nutrimentos, estipulada en el marco jurídico aplicable.
            <br />g) Puede declararse la preparación ritual o religiosa de un alimento (ejemplo, Halal, Kosher), siempre que se ajuste a las exigencias de las autoridades religiosas o del ritual competente y sin importar la presencia de sellos de advertencia.
          </p>
        )
      },
      {
        pageNumber: 28,
        questionNumber: 101,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Declaraciones nutrimentales y saludables
            <br />Este tipo de declaraciones pueden referirse al valor de energía, proteínas, hidratos de carbono, grasas y los derivados de las mismas, fibra dietética, sodio, vitaminas y nutrimentos inorgánicos (minerales) para los cuales se han establecido valores nutrimentales de referencia.
            <br />Sin embargo, en el caso de que el producto preenvasado incluya en su etiquetado alguno de los sellos señalados en 4.5.3.4.1 y cualquiera de las leyendas establecidas en 7.1.3 y 7.1.4, la declaración de propiedades nutrimentales y saludables debe cumplir con lo siguiente:
            <br />a) no deben realizarse declaraciones de propiedades saludables;
            <br />b) no deben realizarse declaraciones de propiedades nutrimentales relacionadas directamente con el sello que haya sido declarado en la etiqueta, y
            <br />c) las declaraciones de propiedades nutrimentales que pueden realizarse deben ostentarse en la superficie de información con una altura máxima de la letra que debe corresponder con la altura mínima de la letra establecida en 4.1.3 de la NOM-030-SCFI-2006 (ver 2.2 Referencias Normativas).
            <br /><br />
            6.3.1 Por declaración de propiedades relativas al contenido de nutrimentos se entiende una declaración de propiedades nutrimentales que describe el nivel de un determinado nutrimento contenido en un alimento. (Ejemplos: “Fuente de calcio”; “alto contenido de fibra y bajo en grasa”.)
            <br />6.3.2 Por declaración de propiedades de comparación de nutrimentos se entiende una declaración de propiedades que compara los niveles de nutrimentos y/o el valor energético de dos o más alimentos. (Ejemplos: “reducido”; “menos que”; “menos”; “aumentado”; “más que”.)
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 102,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3 Declaración de propiedades saludables significa cualquier representación que declara, sugiere o implica que existe una relación entre un alimento, o un constituyente de dicho alimento, y la salud. Las declaraciones de propiedades saludables incluyen lo siguiente:
            <br />6.3.3.1 Por declaración de función de los nutrimentos se entiende una declaración de propiedades nutrimentales que describe la función fisiológica del nutrimento en el crecimiento, el desarrollo y las funciones normales del organismo.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
            <br />Ejemplo: “El nutrimento A (nombrando un papel fisiológico del nutrimento A en el organismo respecto al mantenimiento de la salud y la promoción del crecimiento y del desarrollo normal). El alimento X es una fuente del/alto en el nutrimento A”.
          </p>
        )
      },
      {
        pageNumber: 29,
        questionNumber: 103,
        text: (
          <p className="table-content__row__cell--text">
            6.3.3.2 Otras Declaraciones de propiedades de función - Estas declaraciones de propiedades conciernen efectos benéficos específicos del consumo de alimentos o sus constituyentes en el contexto de una dieta total sobre las funciones o actividades biológicas normales del organismo. Tales declaraciones de propiedades se relacionan a una contribución positiva a la salud o a la mejora de una función o la modificación o preservación de la salud. Ejemplo: “La sustancia A (nombrando los efectos de la sustancia A sobre el mejoramiento o modificación de una función fisiológica o la actividad biológica asociada con la salud). El alimento Y contiene X gramos de sustancia A”.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 104,
        text: (
          <p className="table-content__row__cell--text">
            6.3.4 No se podrán hacer declaraciones de propiedades cuando se pretenda atribuir al producto características que no contiene o posee ni declaraciones asociadas a la disminución o reducción de riesgo de enfermedad.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 105,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            7. Leyendas
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 106,
        text: (
          <p className="table-content__row__cell--text">
            7.1 Leyendas precautorias
            <br /> 7.1.1 Las leyendas precautorias deben hacer referencia al ingrediente u origen del ingrediente que, basado en información científica reconocida, se asocie a riesgos reales o potenciales relacionados con la intolerancia digestiva, alergias o enfermedades metabólicas o toxicidad.
            <br />7.1.2 Las leyendas precautorias específicas por producto, se establecerán en las normas oficiales mexicanas correspondientes u otros ordenamientos jurídicos.
          </p>
        )
      },
      {
        pageNumber: 30,
        questionNumber: 107,
        text: (
          <p className="table-content__row__cell--text">
            7.1.3 Si la lista de ingredientes incluye edulcorantes, se debe colocar la leyenda precautoria frontal en
            letras mayúsculas “CONTIENE EDULCORANTES, NO RECOMENDABLE EN NIÑOS”.
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/edulcorantes.png" alt="Edulcorantes" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 108,
        text: (
          <p className="table-content__row__cell--text">
            7.1.4. Cuando el producto preenvasado contenga cafeína adicionada dentro de la lista de ingredientes en cualquier cantidad, se debe incluir la leyenda precautoria en letras mayúsculas “CONTIENE CAFEÍNA EVITAR EN NIÑOS”, la cual forma parte del sistema de etiquetado frontal, conforme se establece en el Apéndice A (Normativo).
            <br />
            <img src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cafeine.PNG" alt="Cafeina" style={{ width: '70%' }} />
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 109,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Jugos
          </p>
        )
      },
      {
        pageNumber: 31,
        questionNumber: 110,
        text: (
          <p className="table-content__row__cell--text">
            5.1.1. Jugos<br />
            Los jugos deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.14, 3.16 y 3.17 de esta Norma Oficial Mexicana, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.1 y 5.3.1 de esta Norma Oficial Mexicana, asimismo, el contenido de jugo de vegetales o frutas u hortalizas debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana.
            <br /><br />
            Nota 1: Los jugos deben cumplir con las especificaciones descritas en la Tabla 1.
            <br />5.1.1.1 Jugo de __________ (el nombre del vegetal o fruta u hortaliza).
            <br />5.1.1.2 Jugo de __________ (el nombre del vegetal o fruta u hortaliza) de concentrado.
            <br />5.1.1.3 Jugo mixto de __________ (describir los vegetales o frutas o agua de coco o coco u hortalizas que contiene el producto).
            <br />Nota 2: Los jugos mixtos con más de dos vegetales o frutas u hortalizas, agua de coco o coco, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.1.
            <br /><br />
            Se pueden elaborar junto a sus pepitas, semillas y pieles que normalmente no se incorporan al jugo, aunque pueden ser aceptables algunas partes o componentes de pepitas, semillas y pieles que no puedan eliminarse mediante los procesos físicos industriales convencionales.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 111,
        text: (
          <p className="table-content__row__cell--text">
            Tabla 1
            Especificaciones para Jugos, Agua de coco y Jugos de Concentrados una vez Reconstituidos
            <br />
            imagen
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 112,
        text: (
          <p className="table-content__row__cell--text">
            Nota 9: Cuando un jugo proceda de una fruta no mencionada en la Tabla 1, el nivel mínimo de grados Brix de la fruta será el normado internacionalmente por el Codex Alimentarius.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 113,
        text: (
          <p className="table-content__row__cell--text">
            Nota 10: Para el caso del jugo de uva se puede tener una relación isotópica en el intervalo de -23 a -24 únicamente cuando lauva provenga de una región geográfica distinta a México, lo cual debe ser comprobable en la actividad de verificación.
          </p>
        )
      },
      {
        pageNumber: 32,
        questionNumber: 114,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en jugos<br />
            Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 115,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en jugos
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 116,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 117,
        text: (
          <p className="table-content__row__cell--text">
            5.1.2 Agua de coco
            <br />El agua de coco debe cumplir con cualquiera de las definiciones establecidas en los incisos 3.2, 3.4 y cumplir con lo establecido en el inciso 5.3.1, usando las siguientes denominaciones de producto según corresponda, el contenido de agua de coco debe ser del 100 %, el cual se puede determinar conforme a lo indicado en el capítulo 7 de esta Norma Oficial Mexicana:

            <br /><br />5.1.2.1. Agua de coco
            <br />5.1.2.2 Agua de coco de concentrado

            <br /><br />3.2 agua de coco
            líquido que se extrae directamente del fruto (Cocos nucifera L.) sin exprimir la pulpa.

            <br /><br />3.4 agua de coco de concentrado
            agua de coco elaborada a partir de agua de coco concentrada.
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 118,
        text: (
          <p className="table-content__row__cell--text">
            Nota 3: El agua de coco y agua de coco de concentrado debe cumplir con las especificaciones descritas en la Tabla 1.
            <br />
            <table className="table-norms">
              <tr>
                <th>Parámetro</th>
                <th>Agua de Coco</th>
              </tr>
              <tr>
                <td>* Sólidosdisueltosmínimos de lafrutacorrespondiente(°Brix)</td>
                <td>5</td>
              </tr>
              <tr>
                <td>RelaciónIsotópica deCarbono (13C/12C), expresadaen d13CVPDB (%).</td>
                <td>-23 a -25</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 33,
        questionNumber: 119,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1 Ingredientes en agua de coco
            <br />Los productos que usen esta denominación de producto no deben ser adicionados con los siguientes ingredientes:
            <br />a) Espesantes
            <br />b) Azúcares añadidos
            <br />c) Colorantes
            <br />d) Edulcorantes
            <br />e) Saborizantes
            <br />f) Acidulantes
            <br /><br />
            Lo anterior, toda vez que un producto que adicione cualquiera de los elementos anteriores se considera como un producto adulterado o producto imitación conforme a la Referencia normativa 2.4.
            <br />Para el caso de los jugos y néctares mixtos, se deben declarar en la lista de ingredientes los nombres comunes o científicos de los vegetales o frutas o agua de coco u hortalizas, que componen su mezcla en orden cuantitativo decreciente de proporciones del peso (m/m).
            <br />Nota 13: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original.
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 120,
        text: (
          <p className="table-content__row__cell--text">
            5.3.1.1 Ingredientes opcionales en agua de coco
            <br />Se pueden añadir los siguientes ingredientes opcionales a los productos que usen la denominación de producto jugo y aguade coco, siempre y cuando sea con fines organolépticos o nutricionales y su uso debe ser declarado dentro de la denominación del producto, se pueden emplear términos generales o específicos para su declaración:
            <br />a) Sal
            <br />b) Especias
            <br />c) Hierbas aromáticas (y sus extractos naturales)
            <br />d) Semillas
            <br />e) La adición de nutrimentos debe cumplir con lo establecido en la Referencia normativa 2.5
          </p>
        )
      },
      {
        pageNumber: 34,
        questionNumber: 121,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 122,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Néctares
          </p>
        )
      },
      {
        pageNumber: 35,
        questionNumber: 123,
        text: (
          <p className="table-content__row__cell--text">
            5.1.3 Néctares
            <br />Los néctares deben cumplir con cualquiera de las definiciones establecidas en los incisos 3.19 y 3.20, usando las siguientes denominaciones de producto según corresponda y deben cumplir con lo establecido en los incisos 5.2.2 y 5.3.2.
            <br /><br />
            5.1.3.1 Néctar de ___________ (el nombre del vegetal o fruta o coco u hortaliza).
            <br /><br />
            5.1.3.2 Néctar mixto de ________ (describir los vegetales o frutas o coco u hortalizas, según corresponda la combinación entre éstos).
            <br /><br />
            Nota 4: Los néctares mixtos con más de dos vegetales o frutas o coco u hortalizas, pueden utilizar un nombre genérico en la denominación de producto y cumplir con lo establecido en el inciso 5.3.2.
            <br /><br />
            3.19 néctar
            producto líquido, con pulpa o sin pulpa, elaborado con jugo o pulpa o puré de vegetales o frutas, o coco u hortalizas (maduras y sanas), de la variedad correspondiente, lavadas, finamente divididas y tamizadas, de concentrados o no, de congelados o no y deben cumplir con los parámetros establecidos en la Tabla 2 de esta Norma Oficial Mexicana, pudiendo ser adicionado de agua potable, azúcares añadidos, edulcorantes, así como los aditivos y coadyuvantes permitidos en la Referencia normativa 2.10.
            <br /><br />
            3.20 néctar mixto
            néctar puede elaborarse a partir de la mezcla de dos o más vegetales o frutas u hortalizas o agua de coco o coco y que cumplen con las definiciones establecidas en el inciso 3.19.
          </p>
        )
      },
      {
        pageNumber: 36,
        questionNumber: 124,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Néctares<br />
            Los néctares descritos en los incisos 3.19, 3.20 y 5.1.3 deben cumplir con lo establecido en la Tabla 2.
            <br /><br />
            Tabla 2
            Especificaciones para néctares naturales o reconstituidos
            <br />
            <table className="table-norms">
              <tr>
                <th>Nombre botánico</th>
                <th>Nombre Común</th>
                <th>Valor de °Bx del jugo separado</th>
                <th>Contenido minimo de jugo y/o pulpa (% v/v) en néctares de frutas</th>
              </tr>
              <tr>
                <td>Cocos nucifera L.</td>
                <td>Coco</td>
                <td>5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L.</td>
                <td>Melon</td>
                <td>8</td>
                <td>35</td>
              </tr>
              <tr>
                <td>Cucumis melo L. subsp. melo var.inodorus H. Jacq</td>
                <td>Melon casaba</td>
                <td>7.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Cucumis melo L subsp. melo var.inodorus H. Jacq.</td>
                <td>Melon dulce de piel lisa</td>
                <td>10</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Fragaria x. ananassaDuchense(Fragaria chiloensisDuchesne x Fragaria virginianaDuchesne)</td>
                <td>Fresa</td>
                <td>7.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Lycopersicum esculentum L.</td>
                <td>Tomate</td>
                <td>5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Malus domestica Borkh.</td>
                <td>Manzana</td>
                <td>11.5</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Mangifera indica L.</td>
                <td>Mango</td>
                <td>13.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus persica (L.) Batsch var.persica</td>
                <td>Durazno</td>
                <td>10.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Psidium guajava L.</td>
                <td>Guayaba</td>
                <td>8.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Pyrus communis L</td>
                <td>Pera</td>
                <td>12</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Vitis Vinifera L. o sus híbridos VitisLabrusca o sus híbridos</td>
                <td>Uva</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Ananas comosus (L.)MerrillAnanas sativis L. Schult. f.</td>
                <td>Piña</td>
                <td>16</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus sinensis (L.)</td>
                <td>Naranja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Citrus paradisi Macfad</td>
                <td>Toronja</td>
                <td>10</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Punica granatum L.</td>
                <td>Granada</td>
                <td>12</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Citrus reticulata Blanca</td>
                <td>Mandarina</td>
                <td>11.8</td>
                <td>50</td>
              </tr>
              <tr>
                <td>Carica papaya L.</td>
                <td>Papaya</td>
                <td>11.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Annona muricata L</td>
                <td>Guanábana</td>
                <td>14.5</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Tamarindus indica</td>
                <td>Tamarindo</td>
                <td>13</td>
                <td>0.5</td>
              </tr>
              <tr>
                <td>Musa species incluidas M.acuminata y M. paradisiaca peroexcluyendo los otros plátanos</td>
                <td>Platano</td>
                <td>22</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Prunus armeniaca L.</td>
                <td>Chabacano</td>
                <td>11.5</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Apium graveolens</td>
                <td>Apio</td>
                <td>3.1</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Daucus carota</td>
                <td>Zanahoria</td>
                <td>8</td>
                <td>20</td>
              </tr>
              <tr>
                <td>Vaccinium macrocarpon AitonVaccinium oxycoccos L.</td>
                <td>Arandano agrio</td>
                <td>7.5</td>
                <td>30</td>
              </tr>
            </table>
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 125,
        text: (
          <p className="table-content__row__cell--text">
            Nota 11: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de fruta, deben contener un porcentaje total mínimo de 25 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 126,
        text: (
          <p className="table-content__row__cell--text">
            Nota 12: Los néctares mixtos de las frutas, vegetales u hortalizas con mayor proporción de vegetales u hortalizas deben contener un porcentaje total mínimo de 20 % de jugo, puré y/o pulpa (% v/v).
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 127,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Ingredientes en néctares
            <br /> Se pueden añadir los siguientes ingredientes a los néctares:
            <br />a) Agua potable
            <br />b) Extractos de vegetales o frutas u hortalizas
            <br />c) Azúcares añadidos
            <br />d) Edulcorantes conforme a la Referencia normativa 2.10
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 128,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2.1 Ingredientes opcionales en néctares
            <br />Se pueden añadir otros ingredientes opcionales a los productos que usen la denominación de producto néctar y su uso debe ser declarado dentro de la denominación del producto.
            <br />Nota 14: Se permite la restitución de los componentes volátiles propios del vegetal o fruta o agua de coco u hortaliza, sin que éstos rebasen las porciones en relación al contenido de dichos componentes en el alimento original
          </p>
        )
      },
      {
        pageNumber: 37,
        questionNumber: 129,
        text: (
          <p className="table-content__row__cell--text">
            6.1 Jugos, Agua de coco y Néctares
            <br />Los jugos, agua de coco y néctares mixtos pueden declarar el número de esta combinación o mezcla de dos o más jugos, agua de coco o néctares en cualquier lugar de la superficie principal de exhibición o superficie de información sin que forme parte de la denominación del producto. Pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 130,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas no alcohólicas con vegetal o fruta u hortaliza
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 131,
        text: (
          <p className="table-content__row__cell--text">
            5.1.4 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Estas bebidas no alcohólicas deben cumplir con la definición establecida en el inciso 3.7 y describir el porcentaje de vegetal o fruta, agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, usando la siguiente denominación de producto
            <br /><br />
            5.1.4.1 Bebida con ____ % (dos dígitos) de __________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 5: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo el Aloe vera) deben representar números enteros a través de dos dígitos (mayores a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 10.9, 11.4, 13.6,etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            3.7 bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje mayor o igual al 10% en su composición final de vegetales o fruta, agua de coco o coco u hortaliza, o la combinación de los mismos, así como uso de extractos naturales, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 38,
        questionNumber: 132,
        text: (
          <p className="table-content__row__cell--text">
            5.2.3 Bebidas no alcohólicas con contenido de vegetal o fruta u hortaliza
            <br />Las Bebidas descritas en 5.1.4 deben tener un porcentaje mayor o igual del 10 % del contenido de vegetales o frutas o aguade coco o coco u hortaliza (incluyendo al Aloe vera) y debe ser menor al contenido mínimo de jugo y/o pulpa (% v/v) en néctares de frutas, descrito en la Tabla 2.
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 133,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Ingredientes en bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas descritas en el inciso 5.1.4, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 39,
        questionNumber: 134,
        text: (
          <p className="table-content__row__cell--text">
            6.2 Bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas
            <br />Las bebidas no alcohólicas con contenido de vegetal o fruta u hortalizas descritas en el inciso 5.1.4, pueden hacer uso de imágenes reales a vegetales o frutas o coco u hortalizas, así como la combinación entre éstos, en cualquier lugar de la superficie principal de exhibición o superficie de información, siempre y cuando corresponda con la contenida realmente en el producto
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 135,
        isATitle: true,
        text: (
          <p className="table-content__row__cell--text">
            Bebidas saborizadas no alcohólicas
          </p>
        )
      },
      {
        pageNumber: 40,
        questionNumber: 136,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza deben cumplir con la definición establecida en el inciso 3.8 y su denominación de producto debe ser como a continuación se describe.
            <br /><br />
            5.1.5.1 Las bebidas saborizadas no alcohólicas con un porcentaje menor al 10 % de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _____ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera) con ___ % (un dígito) de _________________ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera).
            <br /><br />
            Nota 6: Los dígitos que se deben expresar en la denominación de producto para informar sobre el contenido real de vegetal o fruta o agua de coco o coco u hortaliza (incluyendo al Aloe vera) deben representar números enteros a través de un dígito (menor a 10) y, en caso de contener una fracción puede expresarlo a través de un dígito decimal, por ejemplo: 9.9, 5.4, 3.6, etc. En caso de no utilizar el dígito decimal se debe redondear hacia el número entero inmediato inferior.
            <br /><br />
            Nota 7: En caso de que a una bebida saborizada no alcohólica se le adicione un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza distinto al sabor que dice contener, debe declarar de igual forma el porcentaje de contenido al final de la denominación del producto conforme al 5.1.5.1.
            <br /><br />
            3.8 bebidas saborizadas no alcohólicas
            aquellos líquidos naturales o transformados que proporcionan al organismo elementos para su nutrición y que contienen un porcentaje menor al 10% en su composición final de vegetales o fruta, agua de coco o coco, u hortaliza, o que no contenga nningún porcentaje de los mismos y hagan uso de extractos o saborizantes naturales o artificiales a cualquier vegetal o fruta, aguade coco o coco, u hortaliza, o la combinación de los mismos, incluyendo leguminosas como soya, arroz, entre otros del reino vegetal.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 137,
        text: (
          <p className="table-content__row__cell--text">
            5.1.5.2 Las bebidas saborizadas no alcohólicas que no contengan un porcentaje de vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso de combinaciones entre éstos, incluyendo al Aloe vera, deben usar la siguiente denominación de producto:
            <br /><br />
            Bebida sabor _______ (vegetal o fruta o agua de coco o coco u hortaliza, o un nombre genérico para el caso decombinaciones entre éstos, incluyendo al Aloe vera).
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 138,
        text: (
          <p className="table-content__row__cell--text">
            Nota 8: Para el caso de las bebidas saborizadas no alcohólicas de este inciso 5.1.5, pueden usar alguna otra denominación establecida en un instrumento normativo o jurídico en lugar de la palabra "bebida", con la finalidad de describir al consumidor final correctamente en la etiqueta el contenido real del producto, como puede ser: agua mineral, agua, bebida carbonatada o refresco u otro cumpliendo con lo establecido en los incisos 5.1.5.1 y 5.1.5.2. Se puede utilizar el término de uso común después de la palabra Bebida como parte de la denominación y que no incurra en engaño al consumidor. No se considera que alimento líquido sea un término de uso común.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 139,
        text: (
          <p className="table-content__row__cell--text">
            5.2.4 Bebidas saborizadas no alcohólicas
            Las Bebidas descritas en el inciso 5.1.5 contienen un porcentaje menor al 10 % del contenido de vegetales o frutas o agua de coco o coco u hortalizas (incluyendo al Aloe vera) o contienen únicamente extractos, saborizantes naturales o artificiales.
          </p>
        )
      },
      {
        pageNumber: 41,
        questionNumber: 140,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Ingredientes en bebidas saborizadas no alcohólicas
            <br />Las bebidas descritas en el inciso 5.1.5 que adicionen menos del 10 % de vegetal o fruta o agua de coco u hortaliza, deben declarar en la lista de ingredientes los jugos o néctares utilizados en la elaboración del producto y declarados en la denominación de producto, con el nombre de los vegetales o frutas o agua de coco o coco u hortalizas, o las distintas combinaciones entre éstos en orden cuantitativo decreciente en proporción del peso (m/m).
          </p>
        )
      },
      {
        pageNumber: 42,
        questionNumber: 141,
        text: (
          <p className="table-content__row__cell--text">
            6.3 Bebidas saborizadas no alcohólicas
            <br />Las bebidas saborizadas no alcohólicas a vegetal o fruta o agua de coco o coco u hortaliza descritas en el inciso 5.1.5,pueden hacer uso de dibujos, sin ser animados en caso de colocar sellos o la leyenda de edulcorantes conforme a la Referencia normativa 2.4, de vegetales o frutas o coco u hortalizas (incluyendo al Aloe vera), dichos dibujos no deben tener un tamaño superior al 5 % del área de la superficie principal de exhibición y deben incluir la frase "dibujo ilustrativo al sabor", esta frase debe tener un tamaño de al menos el 50 % de la declaración cuantitativa del contenido neto (ver Referencia normativa 2.3) y ambos elementos deben ir cercanos a la denominación del producto.
          </p>
        )
      }
    ]
  }
};
