export default {
  601: '601 - General Law of Legal Entities',
  603: '603 - Non-Profit Legal Entities',
  605: '605 - Salaries and Wages and Income Similar to Wages',
  606: '606 - Rental Income',
  608: '608 - Other Income',
  609: '609 - Consolidation',
  610: '610 - Foreign Residents without Permanent Establishment in Mexico',
  611: '611 - Income from Dividends (partners and shareholders)',
  612: '612 - Individuals with Business and Professional Activities',
  614: '614 - Income from Interests',
  616: '616 - Without Fiscal Obligations',
  620: '620 - Cooperative Production Societies that choose to defer their income',
  621: '621 - Fiscal Incorporation',
  622: '622 - Agricultural, Livestock, Forestry, and Fishing Activities',
  623: '623 - Optional for Group of Companies',
  624: '624 - Coordinated',
  628: '628 - Hydrocarbons',
  607: '607 - Regime for the Disposal or Acquisition of Goods',
  629: '629 - Preferential Tax Regimes and Multinational Companies',
  630: '630 - Sale of Shares in the Stock Exchange',
  615: '615 - Regime for Income from Winning Prizes',
  625: '625 - Regime for Business Activities with Income through Digital Platforms',
  626: '626 - Simplified Trust Regime'
};
