import React, { useContext, useEffect, useState } from 'react';
import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import {
  Button,
  Modal,
  Separator,
  StepsIndicator,
  TermsAndConditions,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import { Collaborator, DictumService, User } from '../../../../interfaces';

import './styles.scss';
import storage from '../../../../storage';

export const Step3 = () => {
  const {
    updateResource
  } = useResource<DictumService>();

  const {
    updateResource: updateUser
  } = useResource<User>();

  const {
    updateResource: updateCollaborator
  } = useResource<Collaborator>();

  const navigate = useNavigate();
  const params = useParams();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects,
    userToursDone,
    userRole,
    userID,
    setUserToursDone,
    showDictumRequestTour,
    setShowDictumRequestTour
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [checkedTermsAndConditions, setCheckedTermsAndConditions] = useState<boolean>(false);

  const updateDictumService = () => {
    if (checkedTermsAndConditions) {
      updateResource(
        `/${apiType}/dictum-services/${params.dictumServiceID}`,
        {
          requestIsCompleted: true
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('services.requestSaved'),
            setOpenModal,
            () => navigate(`/dictum-services/detail/${params.dictumServiceID}/none`),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    } else {
      Modal.fireError(t('services.errors.termsAndConditions'), setOpenModal, soundEffects);
    }
  };

  useKeyPress(
    openModal ? () => { } : () => updateDictumService(),
    [checkedTermsAndConditions, openModal]
  );

  const driverObj = driver({
    showProgress: false,
    allowClose: false,
    smoothScroll: true,
    disableActiveInteraction: true,
    doneBtnText: t('tours.done') || '',
    nextBtnText: t('tours.next') || '',
    prevBtnText: t('tours.former') || '',
    steps: [
      { element: '#finish-request', popover: { title: t('tours.finishRequest') || '', description: t('tours.finishRequestDescription') || '' } }
    ],
    onDestroyed: () => {
      if (showDictumRequestTour) {
        setShowDictumRequestTour(false);
      } else {
        if (apiType === 'admin') {
          updateUser(
            `admin/users/${userID}/tour-done`,
            {
              tourDone: 'dictum-request'
            },
            (data) => {
              setUserToursDone(data.toursDone || []);
              storage.set('userToursDone', JSON.stringify(data.toursDone));
            },
            // eslint-disable-next-line no-console
            (error: string) => console.log(error)
          );
        }

        if (apiType === 'public') {
          updateCollaborator(
            'public/collaborators/tour-done',
            {
              tourDone: 'dictum-request'
            },
            (data) => {
              setUserToursDone(data.toursDone || []);
              storage.set('userToursDone', JSON.stringify(data.toursDone));
            },
            // eslint-disable-next-line no-console
            (error: string) => console.log(error)
          );
        }
      }
    }
  });

  useEffect(() => {
    if (userToursDone !== false && userRole === 'collaborator') {
      if (userToursDone === undefined || !userToursDone.includes('dictum-request') || typeof userToursDone === 'string' || userToursDone === null) {
        setTimeout(() => { driverObj.drive(); }, 700);
      } else {
        driverObj.destroy();
      }
    }

    if (showDictumRequestTour) {
      driverObj.drive();
    }
  }, [userToursDone, showDictumRequestTour]);

  return (
    <div className='step-3'>
      <div className='step-3__steps'>
        <StepsIndicator
          stepsQuantity={3}
          currentStep={3}
        />
      </div>
      <Title title={t('services.termsAndConditions3') || ''} />
      <TermsAndConditions
        onAccept={(checked: boolean) => {
          setCheckedTermsAndConditions(checked);
        }}
      />
      <Separator orientation='horizontal' />
      <div className='step-3__button-container'>
        <Button
          onClick={() => navigate(`/dictum-request/step_2/${params.dictumServiceID}/${params.petitionNumber}/${params.clientID}/${params.collaboratorID}`)}
          type='primary'
          label={t('global.goBack') || ''}
          icon='leftArrow'
          iconPosition='left'
        />
        <Button
          onClick={updateDictumService}
          type='primary'
          label={t('global.finish') || ''}
          icon='rightArrow'
          iconPosition='right'
          id='finish-request'
        />
      </div>
    </div>
  );
};

export default Step3;
