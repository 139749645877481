import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataDisplay from '../../../../components/DataDisplay';
import { emptyDictumService } from '../../../../emptyObjects';
import { DataDisplayItem, DictumService } from '../../../../interfaces';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  dictum: DictumService
}

const DictumServiceDetailGeneralInfo: React.FC<Props> = (props) => {
  const {
    dictum = emptyDictumService
  } = props;

  const {
    userRole
  } = useContext(AppContext);

  const { t } = useTranslation();

  const dataItems: DataDisplayItem[] = [
    {
      label: t('services.petitionTitle'),
      value: dictum.petitionNumber,
      format: 'none'
    },
    {
      label: t('services.rectifiedPetitionNumberInput'),
      value: dictum.rectifiedPetitionNumber,
      format: 'rectifiedPetitionNumber'
    },
    {
      label: t('global.status.title'),
      value: dictum.status,
      format: 'status'
    },
    {
      label: t('global.client'),
      value: dictum.client.businessName,
      format: 'none',
      navigateRoute: `/client/detail/${dictum.client.id}`,
      onlyAdmin: true
    },
    {
      label: t('global.collaborator'),
      value: dictum.collaborator.name,
      format: 'none'
    },
    {
      label: t('services.inspectionAddressheader'),
      value: dictum.inspectionAddress,
      format: 'none'
    },
    {
      label: t('global.creationDate'),
      value: dictum.createdAt,
      format: 'date-time'
    },
    {
      label: t('services.porposeValidationDate'),
      value: dictum.proposedValidationDate,
      format: 'date'
    },
    {
      label: t('services.customsClearanceDate'),
      value: String(dictum.outOfCustomsDate).substring(0, 10),
      format: 'date'
    },
    {
      label: t('global.expirationDate'),
      value: dictum.createdAt,
      auxValue: dictum.outOfCustomsDate,
      format: 'dictum-expired-date'
    },
    {
      label: t('services.requestIsCompleted'),
      value: dictum.indicators.requestIsCompleted,
      format: 'boolean'
    }
  ];

  const getItems = (): DataDisplayItem[] => {
    if (['finance', 'master'].includes(userRole)) {
      dataItems.push(
        {
          label: t('global.invoiced'),
          value: dictum.indicators.invoiced,
          format: 'boolean'
        },
        {
          label: t('global.invoiceNumber'),
          value: dictum.indicators.invoicedInfo.invoiceNumber,
          format: 'none'
        },
        {
          label: t('global.dateBilling'),
          value: dictum.indicators.invoicedInfo.date,
          format: 'date'
        },
        {
          label: 'UUID',
          value: dictum.indicators.invoicedInfo.UUID,
          format: 'none'
        }
      );
      return dataItems;
    }

    return dataItems;
  };

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={getItems()}
    />
  );
};

export default DictumServiceDetailGeneralInfo;
