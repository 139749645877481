import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import './styles.scss';
import { Notification as NotificationType } from '../../interfaces';
import Icon from '../Icon';
import { IconTypes } from '../Icon/iconList';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';

interface Props {
  item: NotificationType
}

const Notification: React.FC<Props> = (props) => {
  const {
    item
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    createResource
  } = useResource<any>();

  const {
    notifications,
    setNotifications,
    apiType
  } = useContext(AppContext);

  const setLabelNotification = () => {
    const labelsNotification = {
      'inspection-in-review': t('notifications.inspectionInReview', { inspectionNumber: item.reference.number }) || '',
      'inspection-waiting-for-files-for-remote-round': t('notifications.inspectionWaitingForFilesForRemoteRound', { invoice: item.reference.invoice }) || '',
      'inspection-waiting-for-assign': item.reference.subInvoice
        ? t('notifications.inspectionWaitingForAssign', { inspectionNumber: item.reference.number, invoice: item.reference.invoice, subInvoice: item.reference.subInvoice.split('/')[0] }) || ''
        : t('notifications.inspectionWaitingForAssignWhitoutSubInvoice', { inspectionNumber: item.reference.number, invoice: item.reference.invoice }) || '',
      'inspection-whitout-inspection-list': t('notifications.inspectionWhitoutInspectionList', { inspectionNumber: item.reference.number, invoice: item.reference.invoice }) || '',
      'inspection-in-progress': t('notifications.inspectionInProgress', { inspectionNumber: item.reference.number, invoice: item.reference.invoice }) || '',
      'dictum-whitout-paid-petition': t('notifications.dictumWhitoutPaidPetition', { petitionNumber: item.reference.number }) || '',
      'dictum-product-invoice-assigned': t('notifications.dictumProductInInvoiceAsigned', { invoice: item.reference.invoice }) || '',
      'dictum-product-not-validated': t('notifications.dictumProductNotValidated', { invoice: item.reference.invoice }) || '',
      'dictum-product-validation-in-progress': t('notifications.dictumProductInValidationInProgress', { invoice: item.reference.invoice }) || '',
      'dictum-product-cancellation-in-progress': t('notifications.dictumProductIncancellationInProgress', { invoice: item.reference.invoice }) || '',
      'dictum-product-ready-to-send-in-layout-2': t('notifications.readyToSendInLayout2', { invoice: item.reference.invoice }) || '',
      'dictum-whitout-out-of-customs-date': t('notifications.dictumWhitoutOutOfCustomsDate', { petitionNumber: item.reference.number }) || '',
      'dictum-service-soon-to-expire': setLabelDictumServiceSoonToExpire(),
      'dictum-whit-not-requested-visit': t('notifications.dictumWhitNotRequestedVisit', { petitionNumber: item.reference.number }) || '',
      'task-assigned': t('notifications.taskAssigned', { number: item.reference.number }) || '',
      'task-in-progress': t('notifications.taskInProgress', { number: item.reference.number }) || '',
      'constancy-incomplete': t('notifications.constancyIncomplete', { number: item.reference.number }) || '',
      'revision-incomplete': t('notifications.revisionIncomplete', { number: item.reference.number }) || '',
      'dictum-incomplete': t('notifications.dictumIncomplete', { petitionNumber: item.reference.number }) || '',
      'constancy-service-soon-to-expire': setLabelConstancyServiceSoonToExpire(),
      'dictum-service-soon-to-expire-from-creation': setLabelDictumServiceSoonToExpireFromCreation(),
      'dictum-product-expired': t('notifications.expiredDictumProduct', { invoice: item.reference.invoice }) || '',
      'dictum-product-soon-to-expire-from-sentInLayout1Date': setLabelDictumProductSoonToExpireFromLayout1Date(),
      'dictum-product-soon-to-expire-from-creation': setLabelDictumProductSoonToExpireFromCreation(),
      'dictum-whitout-products': t('notifications.dictumWhitoutProducts', { petitionNumber: item.reference.number }) || '',
      'constancy-whitout-products': t('notifications.constancyWhitoutProducts', { number: item.reference.number }) || '',
      'revision-whitout-products': t('notifications.constancyWhitoutProducts', { number: item.reference.number }) || '',
      'dictum-product-not-atached-ready-to-cancel': t('notifications.dictumProductReadyToCancel', { invoice: item.reference.invoice }) || '',
      'inspection-in-progress-by-round': t('notifications.inspectionInProgressByRound', { round: item.reference.round, invoice: item.reference.invoice }) || '',
      'inspection-not-complies-whit-inspection-format': t('notifications.inspectionNotCompliesWhitInspectionFormat', { inspectionNumber: item.reference.number, invoice: item.reference.invoice }) || '',
      'inspection-remote-waiting-for-files': t('notifications.inspectionRemoteWaitingForFiles', { invoice: item.reference.invoice }) || '',
      'task-waiting-for-close': t('notifications.tasksWaitingForClose', { taskNumber: item.reference.number }) || '',
      'training-record-waiting-for-sign': t('notifications.trainingRecordToBeSigned')
    };

    return labelsNotification[item.type];
  };

  const setLabelDictumServiceSoonToExpireFromCreation = () => {
    if (item.entityRemainingDaysToClose === 0) {
      return t('notifications.dictumServiceSoonToExpireToday', { petitionNumber: item.reference.number }) || '';
    }

    if (item.entityRemainingDaysToClose > 0) {
      return t('notifications.dictumServiceSoonToExpireFromCreation') || '';
    }

    return t('notifications.dictumServiceExpiredFromCreation') || '';
  };

  const setLabelDictumProductSoonToExpireFromCreation = () => {
    if (item.entityRemainingDaysToClose === 0) {
      return t('notifications.dictumProductExpireToday', { invoice: item.reference.invoice }) || '';
    }

    if (item.entityRemainingDaysToClose > 0) {
      return t('notifications.soonToExpireDictumProduct', { invoice: item.reference.invoice }) || '';
    }

    return t('notifications.dictumProductExpiredFromCreation') || '';
  };

  const setLabelDictumProductSoonToExpireFromLayout1Date = () => {
    if (item.entityRemainingDaysToClose === 0) {
      return t('notifications.expiredDictumProductFromLayot1DateToday', { invoice: item.reference.invoice }) || '';
    }

    if (item.entityRemainingDaysToClose > 0) {
      return t('notifications.soonToExpireDictumProductFromLayot1Date', { invoice: item.reference.invoice }) || '';
    }

    return t('notifications.expiredDictumProductFromLayot1Date') || '';
  };

  const setLabelConstancyServiceSoonToExpire = () => {
    if (item.entityRemainingDaysToClose === 0) {
      return t('notifications.constancyServiceSoonToExpireToday', { number: item.reference.number }) || '';
    }

    if (item.entityRemainingDaysToClose > 0) {
      return t('notifications.constancyServiceSoonToExpire') || '';
    }

    return t('notifications.constancyServiceExpired') || '';
  };

  const setLabelDictumServiceSoonToExpire = () => {
    if (item.entityRemainingDaysToClose === 0) {
      return t('notifications.dictumServiceSoonToExpireToday', { petitionNumber: item.reference.number }) || '';
    }

    if (item.entityRemainingDaysToClose > 0) {
      return t('notifications.dictumServiceSoonToExpire') || '';
    }

    return t('notifications.dictumServiceExpired') || '';
  };

  const setDaysDictumProductSoonToExpire = (): ReactElement => {
    if (item.entityRemainingDaysToClose === 0) {
      return (<></>);
    }

    if (item.type === 'dictum-product-soon-to-expire-from-sentInLayout1Date' || item.type === 'dictum-product-soon-to-expire-from-creation') {
      return (<><b>{item.entityRemainingDaysToClose < 0 ? item.entityRemainingDaysToClose * -1 : item.entityRemainingDaysToClose}</b> {t('notifications.days', { petitionNumber: item.reference.invoice })}</>);
    }

    return (<></>);
  };

  const setDaysDictumServiceSoonToExpire = (): ReactElement => {
    if (item.entityRemainingDaysToClose === 0) {
      return (<></>);
    }

    if (item.type === 'dictum-service-soon-to-expire' || item.type === 'dictum-service-soon-to-expire-from-creation') {
      return (<><b>{item.entityRemainingDaysToClose < 0 ? item.entityRemainingDaysToClose * -1 : item.entityRemainingDaysToClose}</b> {t('notifications.days', { petitionNumber: item.reference.number })}</>);
    }

    return (<></>);
  };

  const setDaysConstancyServiceSoonToExpire = (): ReactElement => {
    if (item.entityRemainingDaysToClose === 0) {
      return (<></>);
    }

    if (item.type === 'constancy-service-soon-to-expire') {
      return (<><b>{item.entityRemainingDaysToClose < 0 ? item.entityRemainingDaysToClose * -1 : item.entityRemainingDaysToClose}</b> {t('notifications.days', { petitionNumber: item.reference.number })}</>);
    }

    return (<></>);
  };

  const notificationIcon: { [name: string]: IconTypes } = {
    'inspection-in-review': 'inspector',
    'inspection-waiting-for-files-for-remote-round': 'folderOpen',
    'inspection-waiting-for-assign': 'inspector',
    'inspection-whitout-inspection-list': 'inspector',
    'inspection-in-progress': 'inspector',
    'dictum-whitout-paid-petition': 'DCIcon',
    'dictum-product-invoice-assigned': 'sendIcon',
    'dictum-product-not-validated': 'cancelIcon',
    'dictum-product-validation-in-progress': 'validInvoices',
    'dictum-product-cancellation-in-progress': 'validInvoices',
    'dictum-product-ready-to-send-in-layout-2': 'taskDocument',
    'dictum-whitout-out-of-customs-date': 'DCIcon',
    'dictum-service-soon-to-expire': 'DCIcon',
    'dictum-whit-not-requested-visit': 'DCIcon',
    'task-assigned': 'taskDocument',
    'task-in-progress': 'inProgress',
    'constancy-incomplete': 'CCIcon',
    'revision-incomplete': 'REV',
    'revision-whitout-products': 'REV',
    'dictum-incomplete': 'DCIcon',
    'constancy-service-soon-to-expire': 'CCIcon',
    'dictum-service-soon-to-expire-from-creation': 'DCIcon',
    'dictum-product-expired': 'cancelIcon',
    'dictum-product-soon-to-expire-from-sentInLayout1Date': 'paperShredder',
    'dictum-product-soon-to-expire-from-creation': 'paperShredder',
    'dictum-whitout-products': 'DCIcon',
    'constancy-whitout-products': 'CCIcon',
    'dictum-product-not-atached-ready-to-cancel': 'cancelIcon',
    'inspection-in-progress-by-round': 'inspector',
    'inspection-not-complies-whit-inspection-format': 'inspector',
    'inspection-remote-waiting-for-files': 'folderOpen',
    'task-waiting-for-close': 'closed',
    'training-record-waiting-for-sign': 'signature'
  };

  const setAlertColor = (): string => {
    let color: '__4-red' | '__3-orange' | '__2-yellow' | '__1-green' | '__none' = '__none';

    if (item.type === 'constancy-service-soon-to-expire') {
      if (item.entityRemainingDaysToClose <= 90 && item.entityRemainingDaysToClose >= 61) {
        color = '__1-green';
      }

      if (item.entityRemainingDaysToClose <= 60 && item.entityRemainingDaysToClose >= 31) {
        color = '__3-orange';
      }

      if ((item.entityRemainingDaysToClose <= 30 && item.entityRemainingDaysToClose >= 1)
        || item.entityRemainingDaysToClose <= 0) {
        color = '__4-red';
      }
    }

    if (['dictum-product-soon-to-expire-from-sentInLayout1Date',
      'dictum-product-soon-to-expire-from-creation',
      'dictum-service-soon-to-expire',
      'dictum-service-soon-to-expire-from-creation'].includes(item.type)) {
      if (item.entityRemainingDaysToClose <= 30 && item.entityRemainingDaysToClose >= 20) {
        color = '__1-green';
      }

      if (item.entityRemainingDaysToClose <= 21 && item.entityRemainingDaysToClose >= 14) {
        color = '__2-yellow';
      }

      if (item.entityRemainingDaysToClose <= 15 && item.entityRemainingDaysToClose >= 6) {
        color = '__3-orange';
      }

      if ((item.entityRemainingDaysToClose <= 7 && item.entityRemainingDaysToClose >= 1)
        || item.entityRemainingDaysToClose <= 0) {
        color = '__4-red';
      }
    }

    return color;
  };

  const setViewNotification = (serviceCode: string) => {
    if (notifications.id !== 'none' && item.alert === true) {
      createResource(
        `/${apiType}/notifications/mark-viewed-notification`,
        {
          alert: false,
          notificationID: item.id,
          entityID: notifications.id
        },
        (data) => {
          setNotifications({
            id: data.id,
            notifications: data.notifications
          });
        },
        // eslint-disable-next-line no-console
        (error: string) => console.log(error)
      );
    }

    const setNavigateRoute = {
      'inspection-in-review': () => navigate(`/inspection/detail/${item.reference.id}/none/none/none`),
      'inspection-waiting-for-files-for-remote-round': ['CC', 'CN'].includes(serviceCode) ? () => navigate(`/constancy-products/detail/${item.reference.id}/none/false`) : () => navigate(`/revision-products/detail/${item.reference.id}/none/false`),
      'inspection-waiting-for-assign': () => navigate(`/inspection/detail/${item.reference.id}/none/none/none`),
      'inspection-whitout-inspection-list': () => navigate(`/inspection/detail/${item.reference.id}/none/inspection-list/none`),
      'inspection-in-progress': () => navigate(`/inspection/detail/${item.reference.id}/none/none/none`),
      'dictum-whitout-paid-petition': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'dictum-product-invoice-assigned': () => navigate('/se-procedures/none/none/none'),
      'dictum-product-not-validated': () => navigate(`/dictum-products/detail/${item.reference.id}/false`),
      'dictum-product-validation-in-progress': () => navigate('/se-procedures/none/validate/none'),
      'dictum-product-cancellation-in-progress': () => navigate('/se-procedures/none/cancelate/none'),
      'dictum-product-ready-to-send-in-layout-2': () => navigate('/se-procedures/none/none/layout-2'),
      'dictum-whitout-out-of-customs-date': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'dictum-service-soon-to-expire': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'dictum-whit-not-requested-visit': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'task-assigned': () => navigate(`/tasks/${item.reference.id}`),
      'task-in-progress': () => navigate(`/tasks/${item.reference.id}`),
      'constancy-incomplete': () => navigate(`/constancy-services/detail/${item.reference.id}/none`),
      'revision-incomplete': () => navigate(`/revision-services/detail/${item.reference.id}/none`),
      'dictum-incomplete': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'constancy-service-soon-to-expire': () => navigate(`/constancy-services/detail/${item.reference.id}/none`),
      'dictum-service-soon-to-expire-from-creation': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'dictum-product-expired': () => navigate('/se-procedures/none/none/cancellation'),
      'dictum-product-soon-to-expire-from-sentInLayout1Date': () => navigate(`/dictum-products/detail/${item.reference.id}/false`),
      'dictum-product-soon-to-expire-from-creation': () => navigate(`/dictum-products/detail/${item.reference.id}/false`),
      'dictum-whitout-products': () => navigate(`/dictum-services/detail/${item.reference.id}/none`),
      'constancy-whitout-products': () => navigate(`/constancy-services/detail/${item.reference.id}/none`),
      'revision-whitout-products': () => navigate(`/revision-services/detail/${item.reference.id}/none`),
      'dictum-product-not-atached-ready-to-cancel': () => navigate('/se-procedures/none/none/cancellation'),
      'inspection-in-progress-by-round': () => navigate(`/inspection/detail/${item.reference.id}/none/rounds/none`),
      'inspection-not-complies-whit-inspection-format': () => navigate(`/inspection/detail/${item.reference.id}/none/rounds/none`),
      'inspection-remote-waiting-for-files': () => navigate(`/constancy-products/detail/${item.reference.productID}/none/true`),
      'task-waiting-for-close': () => navigate(`/tasks/${item.reference.id}`),
      'training-record-waiting-for-sign': () => navigate(`/training-records/${item.reference.id}`)
    };

    setNavigateRoute[item.type]();
  };

  return (
    <div className={`notification ${item.alert ? '' : 'notification--shadow'}`} onClick={() => setViewNotification(item.reference.serviceCode)}>
      <div className="notification__content">
        <div className={`notification__alert notification__alert${item.alert ? '--active' : ''}`}></div>
        <div className='notification__icon-container'>
          <Icon type={notificationIcon[item.type]} className='notification__icon-container__icon' />
        </div>
        <div className={`notification${setAlertColor()}`}></div>
        <b className="notification__label" >{setLabelNotification()} {setDaysDictumServiceSoonToExpire()}{setDaysConstancyServiceSoonToExpire()}{setDaysDictumProductSoonToExpire()}</b>
      </div>
      <p className="notification__date">{dayjs(item.date).format('DD MMM YYYY hh:mm a')}</p>
    </div>
  );
};

export default Notification;
