import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Inspection } from '../../interfaces';

import './styles.scss';
import storage from '../../storage';
import { AppContext } from '../../context/AppContext';
import Icon from '../Icon';

interface Props {
  inspection: Inspection
}

const InspectionCard: React.FC<Props> = (props: Props) => {
  const {
    inspection
  } = props;

  const { t } = useTranslation();

  const {
    setVisibleInspectionDetailModal
  } = useContext(AppContext);

  const compliesText: { [name: string]: string } = {
    true: ` - ${t('global.complies')}`,
    false: ` - ${t('global.notComplies')}`
  };

  return (
    <div className='inspection-card' onClick={() => {
      setVisibleInspectionDetailModal({
        visible: true,
        inspectionID: inspection.id
      });
      storage.set('lastTaskDetailID', '');
      storage.set('lastInspectionDetailID', inspection.id);
    }}>
      <div className={`inspection-card__left-bar inspection-card__left-bar--${['CC', 'CN', 'REV'].includes(inspection.service.code) ? 'remote' : 'on-site'}`} />
      <div className={`inspection-card__content inspection-card__content--${['CC', 'CN', 'REV'].includes(inspection.service.code) ? 'remote' : 'on-site'}`}>
        <div className="inspection-card__header">
          {
            inspection.indicators.editInspection && (
              <>
                <Icon type={'alertRed'} />{' '}
              </>
            )
          }
          <span className="inspection-card__header__title">{t('services.product')} {inspection.complies !== null ? compliesText[`${inspection.complies}`] : ''}</span>
          {
            inspection.indicators.urgent === true && (
              <span className="inspection-card__header__alert"> {t('global.urgent').toLocaleUpperCase()}</span>
            )
          }
          <span className='inspection-card__header__type'>{inspection.number}</span>
        </div>
        <div className="inspection-card__list">
          <span className="inspection-card__list__line">
            <b>{t('services.service')}: </b>{inspection.service.number}{' '}
            {
              inspection.inspector && (
                (<><b>{t('services.inspectorName')}: </b>{inspection.inspector.name}</>)
              )
            }
            {' '}
            <b>{t('global.invoice')}: </b>{inspection.product.invoice}
            {' '}
            {
              `${inspection.product.subInvoice}`.includes('/') && (
                (<><b>{t('global.subInvoice')}: </b>{inspection.product.subInvoice}</>)
              )
            }
            {' '}
            <b>{t('services.code')}:</b> {inspection.product.code}
            {' '}
            <b>{t('global.norm')}:</b> {inspection.norm}
            {' '}
            <b>{t('services.roundTitle')}:</b> {inspection.rounds.length}
            {' '}
            <b>{t('global.client')}:</b> {inspection.client.shortName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InspectionCard;
