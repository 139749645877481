import React, { useEffect, useId, useState } from 'react';
import {
  PhoneInput,
  defaultCountries,
  parseCountry
} from 'react-international-phone';
import 'react-international-phone/style.css';

import { ToolTip } from '..';
import Icon from '../Icon';
import { IconTypes } from '../Icon/iconList';

import './styles.scss';
import { utils } from '../../helpers';

interface Props {
  title?: string
  helperText?: string
  toolTipText?: string
  type: 'text' | 'number' | 'decimal' | 'email' | 'password' | 'petitionNumber' | 'textarea' | 'date' | 'time' | 'year' | 'mobilePhoneNumber'
  value: string | number
  onChange: (_value: string | number, _id: string) => void
  toolTipPosition?: 'bottom' | 'left' | 'right'
  placeholder?: string
  id?: string
  icon?: IconTypes
  disabled?: boolean
  hasError?: boolean
  ableAutocomplete?: boolean
  maxDate?: string // YYYY-MM-DD
  minDate?: string // YYYY-MM-DD
  autofocus?: boolean
  inputMode?: 'decimal' | 'email' | 'none' | 'text' | 'tel' | 'url' | 'numeric'
  maxLength?: number
  className?: string
  upperCase?: boolean
  cleanInput?: boolean
  color?: 'yellow'
  makeValidations?: boolean
  allowLineBreaks?: boolean
}

const Input: React.FC<Props> = (props) => {
  const {
    title,
    helperText,
    toolTipText,
    type,
    value,
    onChange,
    toolTipPosition,
    placeholder = '',
    icon,
    disabled = false,
    hasError = false,
    ableAutocomplete = false,
    id = useId(),
    maxDate,
    minDate,
    autofocus,
    inputMode = 'text',
    maxLength: _maxLength = 250,
    className,
    upperCase = true,
    cleanInput = false,
    color = '',
    makeValidations = true,
    allowLineBreaks
  } = props;

  const [inputClasses, setInputClasses] = useState<string>('input__area');
  const [helperTextClasses, setHelperTextClasses] = useState<string>('input__helper-text');
  const [maxLength, setMaxLength] = useState<number>(_maxLength);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (makeValidations) {
      const inputValue = cleanInput ? utils.removeAccents(event.target.value.replace(/[,áéíóúÁÉÍÓÚ´]/g, '')) : utils.removeAccents(event.target.value);

      if (type === 'number') {
        onChange(inputValue.replace(/[a-zA-Z ^\s@$%^&*()\-/.´+{},:¨*_|°"#?¡¿!='ÑñáéíóúÁÉÍÓÚ´]/g, ''), id);
      } else if (type === 'decimal') {
        onChange(inputValue.replace(/[a-zA-Z ^\s@$%^&*()\-/´+{},:¨*_|°"#?¡¿!='ÑñáéíóúÁÉÍÓÚ´]/g, ''), id);
      } else if (type === 'email') {
        onChange(inputValue.toLowerCase().replace(/[áéíóúÁÉÍÓÚ´]/g, ''), id);
      } else if (type === 'textarea') {
        if (upperCase) {
          if (cleanInput) {
            onChange(inputValue.toLocaleUpperCase()
              .replace(/\n\s*\n/g, '\n')
              .replace(/,/g, '')
              .replace(/(\r\n|\n|\r)/gm, '')
              .replace(/[áéíóúÁÉÍÓÚ´]/g, ''), id);
          } else {
            onChange(inputValue.toLocaleUpperCase(), id);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (cleanInput) {
            onChange(inputValue
              .replace(/\n\s*\n/g, '\n')
              .replace(/,/g, '')
              .replace(/(\r\n|\n|\r)/gm, '')
              .replace(/[áéíóúÁÉÍÓÚ´]/g, ''), id);
          } else {
            onChange(inputValue, id);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (upperCase) {
          onChange(cleanInput ? inputValue.toLocaleUpperCase().replace(/,áéíóúÁÉÍÓÚ´/g, '') : inputValue.toLocaleUpperCase(), id);
        } else {
          onChange(cleanInput ? inputValue.replace(/,áéíóúÁÉÍÓÚ´/g, '') : inputValue, id);
        }
      }
    } else {
      onChange(utils.removeAccents(event.target.value), id);
    }
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    event.preventDefault();

    const pastedText = event.clipboardData.getData('text/plain');

    if (allowLineBreaks) {
      onChange(`${value}${pastedText}`, id);
    } else {
      const processedText = pastedText.replace(/\n\s*\n/g, '\n');

      onChange(`${value}${processedText.replace(/\n+$/, '')}`, id);
    }
  };

  const onChangePetitionNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    let userInput = event.target.value;

    if (userInput.length > `${value}`.length) {
      if (userInput.length === 2 && userInput[2] !== ' ') {
        userInput += ' ';
      }
      if (userInput.length === 5 && userInput[5] !== ' ') {
        userInput += ' ';
      }
      if (userInput.length === 10 && userInput[10] !== ' ') {
        userInput += ' ';
      }
    }

    if (userInput.length <= 18) {
      onChange(userInput, id);
    }
  };

  const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['mx'].includes(iso2);
  });

  const typeInputs = {
    text: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='text'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode={inputMode}
        maxLength={maxLength}
        key='1'
      />
    ),
    number: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='text'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode='numeric'
        maxLength={maxLength}
        key='2'
      />
    ),
    year: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='number'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode='numeric'
        maxLength={maxLength}
        min={2024}
        max={3000}
        step={1}
        key='3'
      />
    ),
    decimal: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='text'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode='decimal'
        maxLength={maxLength}
        key='4'
      />
    ),
    email: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='email'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode='email'
        maxLength={maxLength}
        key='5'
      />
    ),
    password: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='password'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode={inputMode}
        maxLength={maxLength}
        key='6'
      />
    ),
    petitionNumber: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='text'
        onChange={onChangePetitionNumber}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode='numeric'
        maxLength={maxLength}
        key='7'
      />
    ),
    date: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='date'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        max={maxDate || ''}
        min={minDate || ''}
        autoFocus={autofocus}
        inputMode={inputMode}
        maxLength={maxLength}
        key='8'
      />
    ),
    time: (
      <input
        id={id}
        name="input-text"
        aria-label="Input text"
        type='time'
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={`${ableAutocomplete ? 'on' : 'off'}`}
        autoFocus={autofocus}
        inputMode={inputMode}
        maxLength={maxLength}
        key='9'
      />
    ),
    textarea: (
      <textarea
        id={id}
        name="input-text"
        aria-label="Input text"
        onChange={handleChange}
        value={value || ''}
        className={inputClasses}
        disabled={disabled}
        placeholder={placeholder}
        autoFocus={autofocus}
        inputMode={inputMode}
        maxLength={maxLength}
        onPaste={handlePaste}
        key='10'
      >
      </textarea>
    ),
    mobilePhoneNumber: (
      <PhoneInput
        defaultCountry="mx"
        value={`${value}`}
        onChange={(phone) => onChange(phone, id)}
        countries={countries}
        placeholder={placeholder}
        key='11'
      />
    )
  };

  useEffect(() => {
    const classNameType = type === 'textarea' ? 'textarea' : 'area';
    let _inputClasses = `input__${classNameType}`;
    let _helperTextClasses = `input__helper-text input__helper-text--${classNameType}`;

    if (icon) {
      _inputClasses += ' input__area--with-icon';
    }

    if (hasError) {
      _inputClasses += ` input__${classNameType}--with-error`;
      _helperTextClasses += ' input__helper-text--with-error';
    }

    if (disabled) {
      _inputClasses += ` input__${classNameType}--disabled`;
      _helperTextClasses += ' input__helper-text--disabled';
    }

    setInputClasses(`${_inputClasses} ${color}`);
    setHelperTextClasses(_helperTextClasses);
  }, [icon, disabled, hasError]);

  useEffect(() => {
    setMaxLength(_maxLength);
  }, [_maxLength]);

  useEffect(() => {
    if (hasError) {
      // eslint-disable-next-line no-undef
      const errorElement: any = document.querySelectorAll(`#input-error ${type === 'textarea' ? 'textarea' : 'input'}`);

      if (errorElement[0]) {
        errorElement[0].focus();
        errorElement[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    }
  }, [hasError, helperText]);

  return (
    <div className={`${className} input input--${type} input--${color}`} id={hasError ? 'input-error' : ''}>
      {
        (title || toolTipText) && (
          <div className='input__title-container'>
            <p className={`input__title-container__title input__title-container__title${hasError ? '--whit-error' : ''}`}>{title}</p>
            {
              toolTipText && (
                <ToolTip text={toolTipText} position={toolTipPosition} component={<div className='input__title-container__tooltip'>?</div>} />
              )
            }
          </div>
        )
      }
      <div className='input__input-container'>
        {typeInputs[type]}
        {
          icon && (
            <Icon type={icon} className='input__input-container__icon' />
          )
        }
      </div>
      {
        helperText && (
          <p className={helperTextClasses}>{helperText}</p>
        )
      }
    </div>
  );
};

export default Input;
