import React, { useEffect, useId, useState } from 'react';

import { LateralMenuOption } from '../../interfaces';

import Icon from '../Icon';

import './styles.scss';

interface Props {
  options: LateralMenuOption[]
  onSelect: (_value: string) => void
  value: string
}

const LateralMenu: React.FC<Props> = (props) => {
  const {
    options,
    onSelect,
    value
  } = props;

  const id = useId();

  const [selectedOption, setSelectedOption] = useState<string>(value);
  // eslint-disable-next-line no-undef
  let selectedComponent = document.getElementById(`selected-component-${id}`);

  const getIndexOfSelectedOption = (valueSelected: string) => {
    for (let index = 0; index < options.length; index += 1) {
      if (valueSelected === options[index].value) {
        return index;
      }
    }

    return 0;
  };

  const onSelectOption = (optionvalue: string) => {
    setSelectedOption(optionvalue);

    if (selectedComponent) {
      selectedComponent.style.top = `${(6 * getIndexOfSelectedOption(optionvalue)) + 1}rem`;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    selectedComponent = document.getElementById(`selected-component-${id}`);
  }, []);

  useEffect(() => {
    if (selectedComponent) {
      selectedComponent.style.top = `${(6 * getIndexOfSelectedOption(value)) + 1}rem`;
    }

    onSelectOption(value);
  }, [value]);

  return (
    <div className='lateral-menu'>
      <div id={`selected-component-${id}`} className='lateral-menu__selected-component'></div>
      {
        options.map((option: LateralMenuOption, index: number) => (
          <div onClick={() => { onSelect(option.value); onSelectOption(option.value); }} className={`lateral-menu__option ${selectedOption === option.value ? 'lateral-menu__option--selected' : ''}`} key={index}>
            {
              option.icon && (
                <Icon type={option.icon} className='lateral-menu__option__icon' />
              )
            }
            <p>{option.label}</p>
            {
              option.alert === true && (
                <Icon type="alertRed" />
              )
            }
          </div>
        ))
      }
    </div>
  );
};

export default LateralMenu;
