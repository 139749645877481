import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Header,
  Modal,
  Table
} from '../../components';
import {
  TrainingRecord,
  TableOrderDirection
} from '../../interfaces';

import './styles.scss';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';

const CorrectiveActions = () => {
  const { t } = useTranslation();

  const {
    fetchResources
  } = useResource<TrainingRecord>();

  const navigate = useNavigate();

  const {
    soundEffects,
    userRole,
    manager
  } = useContext(AppContext);

  const [trainingRecords, setTrainingRecords] = useState<TrainingRecord[]>([]);
  const [orderBy, setOrderBy] = useState<string>('number');
  const [orderDirection, setOrderDirection] = useState<TableOrderDirection>('DESC');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const throttledGetCorrectiveActions = utils.throttle(
    () => {
      const request: any = {
        resourcePath: 'admin/training-records',
        filters: {
          // s_status: filterBy === 'clientStatus' ? filterValue : '',
          // s_shortName: filterBy === 'shortName' ? filterValue : ''
        },
        pagination: {
          n_currentPage: currentPage,
          n_perPage: 10,
          n_totalItems: 10
        },
        ordering: {
          s_orderBy: orderBy,
          s_orderDirection: orderDirection
        }
      };

      fetchResources(
        request,
        (data) => {
          setTrainingRecords(data.items);
          setCurrentPage(data.pagination.currentPage);
          setTotalItems(data.pagination.totalItems);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledGetCorrectiveActions();
  }, [
    orderBy,
    orderDirection,
    currentPage
  ]);

  return (
    <div className='training-records__main'>
      <Header
        title={t('global.trainingRecords')}
        showBackbutton={true}
      />
      <div className="training-records__container">
        {
          (['quality'].includes(userRole)
            || (['inspector'].includes(userRole) && manager)) && (
            <div>
              <Button
                type={'primary'}
                onClick={() => navigate('/training-records/none')}
                label={t('global.newTrainingRecord') || ''}
                icon='addWhite'
                size='big'
              />
            </div>
          )
        }
        <Table
          headers={[
            {
              label: t('global.date'),
              value: 'date',
              format: 'date'
            },
            {
              label: t('global.theme'),
              value: 'theme',
              format: 'none'
            },
            {
              label: 'Instructor',
              value: 'instructor',
              format: 'none'
            },
            {
              label: t('tasks.filters.type'),
              value: 'type',
              format: 'training-records-type'
            }
          ]}
          items={trainingRecords}
          onChangeOrder={(newOrderBy: string, newOrderDirection: TableOrderDirection) => {
            setOrderBy(newOrderBy);
            setOrderDirection(newOrderDirection);
          }}
          onChangeCurrentPage={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
          onClickRow={(item) => {
            navigate(`/training-records/${item.id}`);
          }}
          totalItems={totalItems}
          currentPage={currentPage}
          perPage={10}
          orderBy={orderBy}
          orderDirection={orderDirection}
        />
      </div>
    </div>
  );
};

export default CorrectiveActions;
