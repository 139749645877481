import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '../Icon';

import './styles.scss';

interface Props {
  currentPage: number,
  perPage: number,
  totalItems: number,
  onChangeCurrentPage: (_: number) => void
}

const PAGE_BUTTONS_LIMIT = 3;

const Pagination: React.FC<Props> = (props) => {
  const {
    currentPage,
    perPage,
    totalItems,
    onChangeCurrentPage
  } = props;

  const { t } = useTranslation();

  const [pageButtons, setPageButtons] = useState<ReactElement[]>([]);
  const [firstPageOnTheList, setFirstPageOnTheList] = useState<number>(-PAGE_BUTTONS_LIMIT);
  const totalPages = useMemo(() => Math.ceil(totalItems / perPage), [totalItems, perPage]);

  const getPageButtons = useCallback((offset: number, selectedPage: number) => {
    const buttons: ReactElement[] = [];

    if (offset > 1) {
      buttons.push(
        <button
          key={-1}
          onClick={() => handleChangeCurrentPage(1)}
          className='pagination__pages__button'
        >
          1
        </button>
      );
      buttons.push(<span key={-2}>...</span>);
    }

    for (let i = 0; i < PAGE_BUTTONS_LIMIT; i += 1) {
      const pageNumber = i + offset;

      if (pageNumber >= 1 && pageNumber <= totalPages) {
        buttons.push(
          <button
            key={i}
            onClick={() => handleChangeCurrentPage(pageNumber)}
            className='pagination__pages__button'
          >
            {pageNumber}
            {
              pageNumber === selectedPage && (
                <hr className='pagination__pages__button--current__underline'/>
              )
            }
          </button>
        );
      }
    }

    if (offset + PAGE_BUTTONS_LIMIT <= totalPages) {
      buttons.push(<span key={-3}>...</span>);
      buttons.push(
        <button
          key={-4}
          onClick={() => handleChangeCurrentPage(totalPages)}
          className='pagination__pages__button'
        >
          {totalPages}
        </button>
      );
    }

    setPageButtons(buttons);
  }, [
    currentPage,
    perPage,
    totalItems
  ]);

  const handleChangeCurrentPage = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages && totalItems > 0) {
      onChangeCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const isOutOfRange = currentPage < firstPageOnTheList
      || currentPage >= firstPageOnTheList + PAGE_BUTTONS_LIMIT;

    if (isOutOfRange) {
      const isGoingToBack = currentPage < firstPageOnTheList;
      let newFirstPageOnTheList = isGoingToBack
        ? (currentPage - PAGE_BUTTONS_LIMIT) + 1
        : currentPage;

      if (newFirstPageOnTheList < 1) {
        newFirstPageOnTheList = 1;
      } else if (newFirstPageOnTheList >= totalPages) {
        newFirstPageOnTheList = totalPages - PAGE_BUTTONS_LIMIT + 1;
      }

      getPageButtons(newFirstPageOnTheList, currentPage);
      setFirstPageOnTheList(newFirstPageOnTheList);
    } else {
      getPageButtons(firstPageOnTheList, currentPage);
    }
  }, [
    currentPage,
    perPage,
    totalItems
  ]);

  let displayedElementsQuantity = currentPage * perPage;

  if (totalItems < displayedElementsQuantity) {
    displayedElementsQuantity = totalItems;
  }

  return (
    <div className='pagination'>
      <div className='pagination__results'>
      {t('global.results')} {((currentPage - 1) * perPage) + 1} - {displayedElementsQuantity} {t('global.of')} {totalItems}
      </div>
      <div className='pagination__pages'>
        <button
          onClick={() => { handleChangeCurrentPage(currentPage - 1); }}
          className='pagination__pages__button'
        >
          <Icon
            type='thickerLeftArrow'
            alt='<'
            className='pagination__pages__arrow'
          />
        </button>
        { pageButtons }
        <button
          onClick={() => { handleChangeCurrentPage(currentPage + 1); }}
          className='pagination__pages__button'
        >
          <Icon
            type='thickerRightArrow'
            alt='>'
            className='pagination__pages__arrow'
          />
        </button>
      </div>
      <br />
    </div>
  );
};

export default Pagination;
