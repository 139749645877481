/* eslint-disable no-undef */
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Modal,
  Spinner
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useNavigate, useResource } from '../../../../hooks';
import { BinnacleTableHeader, ElementTypes, TableOrderDirection } from '../../../../interfaces';

import '../../styles.scss';
import { utils } from '../../../../helpers';

interface Option {
  label: string
  type: string
}

interface Props {
  element: ElementTypes
  setElement: (_value: ElementTypes) => void
  type: string
  setType: (_value: string) => void
  setFilters: (_value: { [name: string]: string }) => void
  headers: BinnacleTableHeader[]
  elementsType: 'binnacle' | 'trashCan'
  orderBy: string
  orderDirection: TableOrderDirection | 'none'
  filters: { [name: string]: string },
  driverObj: any
  isPending: boolean
}

const Binnacle: React.FC<Props> = (props) => {
  const {
    element,
    setElement,
    type,
    setType,
    setFilters,
    headers,
    elementsType,
    orderBy,
    orderDirection,
    filters,
    driverObj,
    isPending
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    fetchResources
  } = useResource<any>();

  const {
    apiType,
    userRole,
    soundEffects
  } = useContext(AppContext);

  const enumsElement: { [name: string]: string[] } = {
    services: ['master', 'operator', 'inspector', 'collaborator', 'trainee', 'quality', 'finance', 'sys-admin'],
    products: ['master', 'operator', 'inspector', 'collaborator', 'trainee', 'quality', 'finance', 'sys-admin'],
    inspections: ['master', 'operator', 'inspector', 'collaborator', 'trainee', 'quality', 'sys-admin'],
    tasks: ['master', 'operator', 'inspector', 'collaborator', 'trainee', 'quality', 'sys-admin']
  };

  const finnanceOptions: { [name: string]: Option[] } = {
    services: [
      {
        label: t('services.dictumServices'),
        type: 'dictum'
      },
      {
        label: t('services.constancyServices'),
        type: 'constancy'
      },
      {
        label: t('services.revisionServices'),
        type: 'revision'
      }
    ],
    products: [
      {
        label: t('services.dictumProducts'),
        type: 'dictum'
      },
      {
        label: t('services.constancyProducts'),
        type: 'constancy'
      },
      {
        label: t('services.revisionProducts'),
        type: 'revision'
      }
    ]
  };

  const options: { [name: string]: Option[] } = {
    services: [
      {
        label: t('services.dictumServices'),
        type: 'dictum'
      },
      {
        label: t('services.constancyServices'),
        type: 'constancy'
      },
      {
        label: t('services.revisionServices'),
        type: 'revision'
      }
    ],
    products: [
      {
        label: t('services.dictumProducts'),
        type: 'dictum'
      },
      {
        label: t('services.constancyProducts'),
        type: 'constancy'
      },
      {
        label: t('services.revisionProducts'),
        type: 'revision'
      }
    ],
    inspections: [
      {
        label: t('services.onSite'),
        type: 'DC'
      },
      {
        label: t('services.remote'),
        type: 'CC'
      },
      {
        label: 'Revision',
        type: 'REV'
      }
    ],
    tasks: [
      {
        label: t('tasks.form.inSiteInspection'),
        type: 'onsite-inspection'
      },
      {
        label: t('tasks.form.remoteInspection'),
        type: 'remote-inspection'
      },
      {
        label: t('tasks.form.revisionInspection'),
        type: 'revision-inspection'
      }
    ]
  };

  const handleSelectElement = (_element: ElementTypes) => {
    setElement(_element);
    setFilters({});

    setType(options[_element][0].type);
  };

  const navigateByRole = () => {
    driverObj.destroy();
    if (userRole === 'finance') {
      navigate('/clients');
      return;
    }

    navigate(apiType === 'admin' && userRole !== 'trainee' ? '/tasks/none' : '/profile');
  };

  const formatFilters = (): { [name: string]: string } => {
    const auxFilters: { [name: string]: string } = filters;
    const keys = Object.keys(auxFilters);

    for (let index = 0; index < keys.length; index += 1) {
      if (auxFilters[keys[index]] === 'none') {
        auxFilters[keys[index]] = '';
      }
    }

    return auxFilters;
  };

  const downloadCSVData = () => {
    const querys = {
      services: {
        dictum: 'dictum-services',
        constancy: 'constancy-services',
        revision: 'revision-services'
      },
      products: {
        dictum: 'dictum-products',
        constancy: 'constancy-products',
        revision: 'revision-products'
      },
      inspections: {
        DC: 'inspections',
        CC: 'inspections',
        DN: 'inspections',
        CN: 'inspections',
        REV: 'inspections'
      },
      tasks: {
        'remote-inspection': 'tasks',
        'constancy-inspection': 'tasks',
        'onsite-inspection': 'tasks',
        'revision-inspection': 'tasks'
      }
    };

    fetchResources(
      {
        resourcePath: `/${apiType}/${(querys[element] as any)[type]}`,
        filters: {
          ...formatFilters(),
          s_serviceCode: element === 'inspections' ? type : '',
          s_type: element === 'tasks' ? type : '',
          b_lookInTheTrash: elementsType === 'trashCan' ? 'true' : ''
        },
        ordering: {
          s_orderBy: orderBy,
          s_orderDirection: orderDirection === 'none' ? '' : orderDirection
        }
      },
      (data) => {
        const fileNames: any = {
          services: {
            dictum: 'Servicios de dictamen',
            constancy: 'Servicios de constancia',
            revision: 'Servicios de revisión'
          },
          products: {
            dictum: 'Folios de dictamen',
            constancy: 'Folios de constancia',
            revision: 'Folios de revisión'
          },
          inspections: {
            DC: 'Inspecciones de dictamen',
            CC: 'Inspeccciones de constancia',
            REV: 'Inspecciones de revisión'
          },
          tasks: {
            'onsite-inspection': 'Tareas en sitio',
            'remote-inspection': 'Tareas de constancia',
            'revision-inspection': 'Tareas de revisión'
          }
        };

        const csv = utils.createCSVDataString(headers, data.items);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileNames[element][type]}.csv`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const items: { [name: string]: ReactElement[] } = {
    'sys-admin': [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'tasks' ? '--selected' : ''}`} onClick={() => handleSelectElement('tasks')}>{t('global.tasks')}</div>
    ],
    master: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'tasks' ? '--selected' : ''}`} onClick={() => handleSelectElement('tasks')}>{t('global.tasks')}</div>
    ],
    operator: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'tasks' ? '--selected' : ''}`} onClick={() => handleSelectElement('tasks')}>{t('global.tasks')}</div>
    ],
    inspector: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'tasks' ? '--selected' : ''}`} onClick={() => handleSelectElement('tasks')}>{t('global.tasks')}</div>
    ],
    collaborator: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>
    ],
    trainee: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>
    ],
    quality: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'inspections' ? '--selected' : ''}`} onClick={() => handleSelectElement('inspections')}>{t('global.inspections')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'tasks' ? '--selected' : ''}`} onClick={() => handleSelectElement('tasks')}>{t('global.tasks')}</div>
    ],
    finance: [
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'services' ? '--selected' : ''}`} onClick={() => handleSelectElement('services')}>{t('global.requests')}</div>,
      <div className={`binnacle__footer__elements__option binnacle__footer__elements__option${element === 'products' ? '--selected' : ''}`} onClick={() => handleSelectElement('products')}>{t('global.invoices')}</div>
    ]
  };

  const setOptions = () => {
    if (userRole === 'finance') {
      return finnanceOptions;
    }

    return options;
  };

  if (Object.keys(options).length > 0) {
    return (
      <div className='binnacle__footer binnacle__footer' id='binnacle-footer'>
        {
          isPending ? (
            <div className='binnacle__footer__spinner'>
              <Spinner />
            </div>
          ) : (
            <>
              <div className='binnacle__footer__options'>
                {
                  setOptions()[element].map((item: Option, index: number) => (
                    enumsElement[element].includes(userRole) && (
                      <div
                        className={`binnacle__footer__options__item binnacle__footer__options__item${type === item.type ? '' : '--selected'}`}
                        onClick={() => {
                          setType(item.type);
                          setFilters({});
                        }}
                        key={index}
                      >
                        {item.label}
                        <div className={`binnacle__footer__options__item__selected binnacle__footer__options__item__selected${type === item.type ? '' : '--hidden'}`}></div>
                      </div>
                    )
                  ))
                }
              </div>
              <div className='binnacle__footer__elements'>
                <div className='binnacle__footer__elements__home-button-container' onClick={navigateByRole} id='home-button'>
                  <Icon type='home' className='binnacle__footer__elements__home-button' />
                </div>
                {
                  items[userRole].map((item) => (
                    item
                  ))
                }
                <div className='binnacle__footer__elements__csv-button-container'>
                  <Button
                    type={'secondary-outlined'}
                    onClick={downloadCSVData}
                    label='Descargar datos en csv'
                    icon='csvReportsGrey'
                    onlyIconOnMobile={true}
                    id='csv-report'
                  />
                </div>
              </div>
            </>
          )
        }
      </div >
    );
  }

  return (<></>);
};

export default Binnacle;
