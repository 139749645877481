import React from 'react';
import { useTranslation } from 'react-i18next';

import { Title, Table } from '../../..';

import { ActionEntry, TableOrderDirection } from '../../../../interfaces';

import '../../styles.scss';

interface Props {
  history: ActionEntry[]
}

const InspectionHistory: React.FC<Props> = (props) => {
  const {
    history
  } = props;

  const { t } = useTranslation();

  return (
    <div className='inspection-detail__history'>
      <Title title="Historial" type="primary"/>
      <br/>
      <Table
        headers={[
          {
            label: 'Autor',
            value: 'author',
            format: 'none'
          },
          {
            label: t('global.action'),
            value: 'action',
            format: 'none'
          },
          {
            label: t('global.date'),
            value: 'date',
            format: 'date-time'
          }
        ]}
        items={history}
        orderBy={'date'}
        orderDirection={'DESC'}
        onChangeOrder={(_orderBy: string, _orderDirection: TableOrderDirection) => {}}
      />
    </div>
  );
};

export default InspectionHistory;
