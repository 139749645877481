import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ToolTip } from '..';
import { ExcelTableHeader } from '../../interfaces';

import './styles.scss';
import { utils } from '../../helpers';

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

interface Props {
  headers: ExcelTableHeader[]
  disable?: boolean
  inputsErrors: { [name: string]: string }
  rowsQuantity?: number
}

const increaseRows = 10;
const maxRows = 400;

const ExcelTable: React.FC<Props> = (props) => {
  const {
    headers,
    disable = false,
    inputsErrors,
    rowsQuantity = 0
  } = props;

  const { t } = useTranslation();

  const [rows, setRows] = useState<number>(rowsQuantity || 50);

  const renderMoreRows = () => {
    if (rows + increaseRows <= maxRows) {
      setRows(currentState => currentState + increaseRows);
    }
  };

  const setInput = (type: 'text' | 'number' | 'norm' | 'fase' | 'requireDesign' | 'labelingMode', id: string) => {
    const inputTypes = {
      text: (
        <input
          type="text"
          className='excel-table__input'
          id={id}
          disabled={disable}
          maxLength={200}
        />
      ),
      number: (
        <input
          type="number"
          className='excel-table__input'
          id={id}
          disabled={disable}
          maxLength={200}
          min={0}
          onChange={() => {
            // eslint-disable-next-line no-undef
            const element = document.getElementById(id) as HTMLInputElement;

            if (element) {
              element.value = `${utils.removeAccents(element.value)}`.replace(/-+/g, '');
            }
          }}
        />
      ),
      norm: (
        <select className='excel-table__input' defaultValue={'004'} id={id} disabled={disable}>
          <option>003</option>
          <option>004</option>
          <option>015</option>
          <option>024</option>
          <option>020</option>
          <option>141</option>
          <option>142</option>
          <option>050</option>
          <option>051</option>
          <option>173</option>
          <option>187</option>
          <option>189</option>
          <option>235</option>
        </select>
      ),
      fase: (
        <select className='excel-table__input' id={id} disabled={disable}>
          <option>II</option>
          <option>III</option>
          <option>N/A</option>
        </select>
      ),
      requireDesign: (
        <select className='excel-table__input' id={id} disabled={disable}>
          <option>{t('global.yes')}</option>
          <option>No</option>
        </select>
      ),
      labelingMode: (
        <select className='excel-table__input' id={id} disabled={disable}>
          <option>PA</option>
          <option>PB</option>
        </select>
      )
    };

    return inputTypes[type];
  };

  const renderRows = () => {
    const rowsAux: any = [];

    for (let index = 0; index < rows; index += 1) {
      rowsAux.push(
        <tr key={index}>
          <td className='excel-table__cell excel-table__cell--first'>{index + 2}</td>
          {
            headers.map((_header: ExcelTableHeader, hIndex: number) => (
              <td className='excel-table__cell' key={hIndex}>
                <ToolTip
                  text={inputsErrors[`${alphabet[hIndex]}${index + 2}`] || ''}
                  position='bottom'
                  component={setInput(_header.type, `${alphabet[hIndex]}${index + 2}`)}
                />
              </td>
            ))
          }
        </tr>
      );
    }

    return rowsAux;
  };

  useEffect(() => {
    setRows(rowsQuantity || 50);
  }, [rowsQuantity]);

  return (
    <div className='excel-table-container'>
      <table className='excel-table'>
        <tbody>
          <tr>
            <td className='excel-table__cell-border excel-table__cell-border--first'></td>
            {
              alphabet.slice(0, headers.length).map((letter: string, index: number) => (
                <td className='excel-table__cell-border' key={index}>{letter}</td>
              ))
            }
          </tr>
          <tr>
            <td className='excel-table__cell excel-table__cell--first'>1</td>
            {
              headers.map((header: ExcelTableHeader, index: number) => (
                <td className='excel-table__cell excel-table__cell--header' key={index}>{header.label}</td>
              ))
            }
          </tr>
          {renderRows()}
          {
            (rows < maxRows && !disable && rowsQuantity === 0) && (
              <tr>
                <td colSpan={headers.length + 1} className='excel-table__more-button' onClick={() => renderMoreRows()}>+ {increaseRows}</td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default ExcelTable;
