import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ModalView,
  Modal,
  Input
} from '../../../../components';

import {
  DictumService
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  visible: boolean
  dictumServiceID: string
  onOutOfCustomsDateAssigned: (_service: DictumService) => void
  onClose: () => void
  proposedValidationDate: Date | null
}

export const ChangeProposedValdiationDate: React.FC<Props> = (props) => {
  const {
    visible,
    dictumServiceID,
    onOutOfCustomsDateAssigned = (_service: DictumService) => { },
    onClose,
    proposedValidationDate
  } = props;

  const { t } = useTranslation();

  const { updateResource } = useResource<DictumService>();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [date, setDate] = useState<string>('');

  const onConfirmDate = () => {
    if (date === '') {
      setErrors({
        date: t('services.errors.emptyCustomsClearanceDate')
      });
    } else {
      setErrors({});
      updateResource(
        `/${apiType}/dictum-services/proposed-validation-date/${dictumServiceID}`,
        { date },
        (data: DictumService) => {
          onOutOfCustomsDateAssigned((data));
          onClose();
        },
        (error: string) => {
          onClose();
          Modal.fireError(error, setOpenModal, soundEffects);
        }
      );
    }
  };

  useEffect(() => {
    if (proposedValidationDate) {
      const data = new Date(proposedValidationDate).toISOString();

      const splited = data.split('-');

      setDate(`${splited[0]}-${splited[1]}-${splited[2].substring(0, 2)}`);
    }
  }, [visible]);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      onConfirm={onConfirmDate}
      customComponent={
        <div className='dictum-service-detail__assing-out-of-customs-date'>
          <Input
            title={t('services.proposedValidationDate') || ''}
            type="date"
            value={String(date)}
            onChange={(_value: string | number) => {
              setDate(String(_value));
            }}
            toolTipPosition="left"
            hasError={!!errors.date}
            helperText={errors.date || ''}
          />
        </div>
      }
      fullSzie={false}
    />
  );
};

export default ChangeProposedValdiationDate;
