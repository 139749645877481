import React, { useContext, useEffect, useState } from 'react';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

import File from '../File';
import FileInput from '../FileInput';
import Icon from '../Icon';

import './styles.scss';
import { FileInputData } from '../../interfaces';
import Modal from '../Modal';
import { AppContext } from '../../context/AppContext';
import { useResource } from '../../hooks';

interface Props {
  folderName: string
  filesQuantity: number
  files: string[]
  onClose: () => void
  onUploadFile: (_files: FileInputData[]) => void
  onDelete: (_documentPath: string) => void
  filesLimit: number
  requestIsCompleted: boolean
}

const FolderExpanded: React.FC<Props> = (props: Props) => {
  const {
    folderName = '',
    filesQuantity = 0,
    onClose = () => { },
    onUploadFile,
    onDelete,
    filesLimit,
    requestIsCompleted
  } = props;

  const {
    fetchResource
  } = useResource<string>();

  const { t } = useTranslation();

  const {
    soundEffects
  } = useContext(AppContext);

  const [files, setFiles] = useState<string[]>(props.files || []);

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `admin/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  useEffect(() => {
    setFiles(props.files || []);
  }, [props.files]);

  return (<div className='folder-expanded'>
    <div className={`folder-expanded__icon-outer-box folder-expanded__icon-outer-box${filesQuantity === 0 ? '--empty' : ''}`}>
      <div className='folder-expanded__icon-outer-box__inner-box'>
        <Icon
          type={filesQuantity === 0 ? 'folderInactiveOpen' : 'folderOpen'}
          alt={'Carpeta'}
          onClick={() => { }}
          className="folder-expanded__icon-outer-box__inner-box__icon"
        ></Icon>
      </div>
    </div>
    <div className="folder-expanded__title">
      <span>{folderName}</span>
      <span className="folder-expanded__title__files-quantity">{`${filesQuantity} ${t('global.files')}`}</span>
    </div>
    <div className="folder-expanded__cross-mark">
      <Icon
        type={'crossMark'}
        alt={'x'}
        onClick={onClose}
        className=""
      ></Icon>
    </div>
    <br />
    <br />
    {
      files.length < filesLimit && (
        <>
          <FileInput
            onSelectFile={(file: FileInputData[]) => onUploadFile(file)}
            acceptedFileFormats=".jpg, .jpeg, .png, .csv, .doc, .docx, .ai, .pdf, .msg, .xlsl, .ods"
            isMultiple={true}
          />
          <br />
          <div className="folder-expanded__label">
            <span>{t('global.filesInspection')}
            </span>
          </div>
          <br />
        </>
      )
    }
    <div className="folder-expanded__files">
      {
        files.map((file: string, index: number) => (
          <File
            name={file.split('/')[file.split('/').length - 1].substring(7)}
            onDelete={requestIsCompleted ? undefined : () => onDelete(file)}
            key={index}
            onDownload={() => handleDownloadFile(file, file.split('/')[file.split('/').length - 1].substring(7))}
          />
        ))
      }
    </div>
  </div>
  );
};

export default FolderExpanded;
