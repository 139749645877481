export default {
  DC: {
    documentCode: 'F7-35',
    documentDescription: 'F7-35 Lista de inspección para Dictamen para la NOM-050-SCFI-2004',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: true,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />
            5.1 Requisitos generales
            <br />
            5.1.1 La información acerca de los productos debe ser veraz y
            describirse y presentarse deforma tal que no induzca a error
            al consumidor con respecto a la naturaleza y características
            de los productos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Información comercial
            <br />
            5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
            deben contener en sus etiquetas, cuando menos, la siguiente información
            comercial obligatoria:
            <br />
            a) Nombre o denominación genérica del producto, cuando no sea
            identificable a simple vista por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal, incluyendo
            código postal, ciudad o estado del fabricante o responsable de la
            fabricación para productos nacionales o bien del importador.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            d) La leyenda que identifique al país de origen del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            e) Las advertencias de riesgos por medio de leyendas, gráficas o
            símbolos precautorios en el caso de productos peligrosos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Idioma y términos
            <br />
            La información que se ostente en las etiquetas de los productos debe:
            <br />
            a) Expresarse en idioma español, sin perjuicio de que
            se exprese también en otros idiomas.
            <br />
            b) Cumplir con lo que establecen las normas oficiales mexicanas NOM-008-SCFI y
            NOM-030-SCFI (ver referencias), sin perjuicio de que además se puedan
            expresar en otros sistemas de unidades.
            <br />
            c) Presentarse en etiqueta fijada de manera tal que permanezca disponible
            hasta el momento de su venta o adquisición en condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            c.1) Cuando la forma de presentación del producto al consumidor final sea un
            envase múltiple o colectivo que no permita ver el contenido, toda la
            información comercial obligatoria prevista en el inciso 5.2.1 de esta
            Norma Oficial Mexicana, debe presentarse en el envase múltiple o colectivo,
            incorporando la leyenda <b>“No etiquetado para su venta individual”</b>.
            <br />
            c.2) Si la forma de presentación del producto al consumidor final es un envase
            múltiple o colectivo que permite ver su contenido, la información comercial
            obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y
            cada uno de los productos preenvasados en lo individual, o bien, una parte de
            la información comercial obligatoria podrá aparecer en el envase múltiple o colectivo
            y la restante en todos y cada uno de los envases de los productos en lo individual,
            siempre que la información comercial obligatoria que aparezca en cada uno de los
            envases de los productos en lo individual, se vea a simple vista desde el exterior
            del envase múltiple o colectivo, sin necesidad de que este último se abra.
            <br />
            c.3) Si los envases múltiples o colectivos se abren y se extraen los productos
            preenvasados contenidos en
            ellos con el objeto de destinarlos individualmente a un consumidor final, dichos
            productos deben contener en lo individual toda la información comercial
            obligatoria que establece esta Norma, siempre que no estén comprendidos en cualquiera
            de los supuestos indicados en el inciso 2.2 de la misma NOM.
            <br />
            d) Estar colocada en la superficie principal de exhibición, tratándose al
            menos de la siguiente información:
            <br />
            i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a), e
            <br />
            ii) Declaración de cantidad.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            d) Estar colocada en la superficie principal de exhibición, tratándose al
            menos de la siguiente información:
            <br />
            i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a), e
            <br />
            ii) Declaración de cantidad.
          </p>
        )
      }
    ]
  },
  CC: {
    documentCode: 'F7-34',
    documentDescription: 'F7-34 Lista de inspección para Constancia para la NOM-050-SCFI-2004',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />
            5.1 Requisitos generales
            <br />
            5.1.1 La información acerca de los productos debe ser veraz y
            describirse y presentarse deforma tal que no induzca a error
            al consumidor con respecto a la naturaleza y características
            de los productos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Información comercial
            <br />
            5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
            deben contener en sus etiquetas, cuando menos, la siguiente información
            comercial obligatoria:
            <br />
            a) Nombre o denominación genérica del producto, cuando no sea
            identificable a simple vista por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal,
            incluyendo código postal, ciudad o estado del fabricante o
            responsable de la fabricación para productos nacionales o bien del importador.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            d) La leyenda que identifique al país de origen del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Idioma y términos
            <br />
            La información que se ostente en las etiquetas de los productos debe:
            <br />
            a) Expresarse en idioma español, sin perjuicio de que
            se exprese también en otros idiomas.
            <br />
            b) Cumplir con lo que establecen las normas oficiales mexicanas
            NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de
            que además se puedan expresar en otros sistemas de unidades.
            <br />
            c) Presentarse en etiqueta fijada de manera tal que permanezca
            disponible hasta el momento de su venta o adquisición en condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            c.1) Cuando la forma de presentación del producto al consumidor final sea
            un envase múltiple o colectivo que no permita ver el contenido, toda la
            información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma
            Oficial Mexicana, debe presentarse en el envase múltiple o colectivo,
            incorporando la leyenda <b>“No etiquetado para su venta individual”</b>.
            <br />
            c.2) Si la forma de presentación del producto al consumidor final es un envase
            múltiple o colectivo que permite ver su contenido, la información comercial
            obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno
            de los productos preenvasados en lo individual, o bien, una parte de la información
            comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante
            en todos y cada uno de los envases de los productos en lo individual, siempre
            que la información comercial obligatoria que aparezca en cada uno de los envases
            de los productos en lo individual, se vea a simple vista desde el exterior del
            envase múltiple o colectivo, sin necesidad de que este último se abra.
            <br />
            c.3) Si los envases múltiples o colectivos se abren y se extraen los productos
            preenvasados contenidos en ellos con el objeto de destinarlos individualmente a
            un consumidor final, dichos productos deben contener en lo individual toda la
            información comercial obligatoria que establece esta Norma, siempre que no estén
            comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            d) Estar colocada en la superficie principal de exhibición,
            tratándose al menos de la siguiente información:
            <br />
            i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a), e
            <br />
            ii) Declaración de cantidad.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Instructivos o manuales de operación y garantías
            <br />
            5.3.1 Idioma
            <br />
            Los instructivos o manuales de operación y garantías deben expresarse en idioma
            español y de acuerdo al Sistema General de Unidades de Medida. Cuando la
            información se exprese en otros idiomas, debe aparecer también en idioma español,
            cuidando que por lo menos sea con el mismo tamaño.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Contenido
            <br />
            Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o
            conservación requiera de instrucciones, deben ir acompañados, sin cargo
            adicional para el consumidor, de los instructivos o manuales de operación
            y, en su caso, garantías, los cuales deben contener indicaciones claras y
            precisas para el uso normal, manejo, conservación, ensamble y aprovechamiento
            de los productos, así como las advertencias para el manejo seguro y
            confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:
            <br />
            a) Nombre, denominación o razón social del productor nacional, o
            importador, domicilio fiscal y teléfono de servicio en territorio nacional.
            <br />
            b) Identificación de los productos o modelos a los que aplica.
            <br />
            c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
            <br />
            d) Cuando proceda, las indicaciones para su instalación, conexión,
            ensamble o mantenimiento para su adecuado funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Garantías
            <br />
            Cuando se ofrezcan garantías, éstas deben expedirse en los términos y
            forma establecidos en la Ley Federal de Protección al Consumidor e
            indicar y cumplir con lo siguiente:
            <br />
            a) Nombre, denominación o razón social y domicilio fiscal del
            productor nacional o importador del producto y teléfonos de
            servicio en territorio nacional.
            <br />
            b) Identificación de los productos y/o modelos a los que aplica.
            <br />
            c) Nombre y domicilio del establecimiento en la República Mexicana
            donde puede hacerse efectiva la garantía en los términos de la misma,
            así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
            Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva
            en cualquiera de las sucursales a nivel nacional del proveedor que la
            ofrezca, sin necesidad de especificar los domicilios de las mismas.
            <br />
            d) Duración de la garantía.
            <br />
            e) Conceptos que cubre la garantía y limitaciones o excepciones.
            <br />
            f) Procedimiento para hacer efectiva la garantía.
            <br />
            g) Precisar la fecha en que el consumidor recibió el producto
            o indicar los documentos de referencia donde ésta se señale. Es
            responsabilidad del comerciante asegurarse que esta información
            esté presente al momento de la venta del producto al consumidor,
            de no hacerlo así, el comerciante debe cumplir con los términos
            de la garantía directamente.
            <br />
            h) Para hacer efectiva la garantía no pueden exigirse otros requisitos
            más que la presentación del producto, la garantía vigente y comprobante de venta.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Incorporación de los instructivos o manuales de operación y
            garantías En todos los casos, los instructivos o manuales de operación
            y garantías deben entregarse al consumidor en el establecimiento
            comercial cuando adquiera los productos.
          </p>
        )
      }
    ]
  },
  REV: {
    documentCode: 'F7-34',
    documentDescription: 'F7-34 Lista de inspección para Revision para la NOM-050-SCFI-2004',
    documentIssueDate: '01/06/2021',
    hasPackingTable: true,
    hasSamplingTable: false,
    hasMeasurementEquipment: true,
    questions: [
      {
        pageNumber: 2,
        questionNumber: 0,
        text: (
          <p className="table-content__row__cell--text">
            5. Información comercial
            <br />
            5.1 Requisitos generales
            <br />
            5.1.1 La información acerca de los productos debe ser veraz y
            describirse y presentarse deforma tal que no induzca a error
            al consumidor con respecto a la naturaleza y características
            de los productos.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 1,
        text: (
          <p className="table-content__row__cell--text">
            5.2 Información comercial
            <br />
            5.2.1 Los productos sujetos a la aplicación de esta Norma Oficial Mexicana,
            deben contener en sus etiquetas, cuando menos, la siguiente información
            comercial obligatoria:
            <br />
            a) Nombre o denominación genérica del producto, cuando no sea
            identificable a simple vista por el consumidor.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 2,
        text: (
          <p className="table-content__row__cell--text">
            b) Indicación de cantidad conforme a la NOM-030-SCFI-2006
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 3,
        text: (
          <p className="table-content__row__cell--text">
            c) Nombre, denominación o razón social y domicilio fiscal,
            incluyendo código postal, ciudad o estado del fabricante o
            responsable de la fabricación para productos nacionales o bien del importador.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 4,
        text: (
          <p className="table-content__row__cell--text">
            d) La leyenda que identifique al país de origen del producto.
          </p>
        )
      },
      {
        pageNumber: 2,
        questionNumber: 5,
        text: (
          <p className="table-content__row__cell--text">
            5.2.2 Idioma y términos
            <br />
            La información que se ostente en las etiquetas de los productos debe:
            <br />
            a) Expresarse en idioma español, sin perjuicio de que
            se exprese también en otros idiomas.
            <br />
            b) Cumplir con lo que establecen las normas oficiales mexicanas
            NOM-008-SCFI y NOM-030-SCFI (ver referencias), sin perjuicio de
            que además se puedan expresar en otros sistemas de unidades.
            <br />
            c) Presentarse en etiqueta fijada de manera tal que permanezca
            disponible hasta el momento de su venta o adquisición en condiciones normales.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 6,
        text: (
          <p className="table-content__row__cell--text">
            c.1) Cuando la forma de presentación del producto al consumidor final sea
            un envase múltiple o colectivo que no permita ver el contenido, toda la
            información comercial obligatoria prevista en el inciso 5.2.1 de esta Norma
            Oficial Mexicana, debe presentarse en el envase múltiple o colectivo,
            incorporando la leyenda <b>“No etiquetado para su venta individual”</b>.
            <br />
            c.2) Si la forma de presentación del producto al consumidor final es un envase
            múltiple o colectivo que permite ver su contenido, la información comercial
            obligatoria puede aparecer en el envase múltiple o colectivo, o en todos y cada uno
            de los productos preenvasados en lo individual, o bien, una parte de la información
            comercial obligatoria podrá aparecer en el envase múltiple o colectivo y la restante
            en todos y cada uno de los envases de los productos en lo individual, siempre
            que la información comercial obligatoria que aparezca en cada uno de los envases
            de los productos en lo individual, se vea a simple vista desde el exterior del
            envase múltiple o colectivo, sin necesidad de que este último se abra.
            <br />
            c.3) Si los envases múltiples o colectivos se abren y se extraen los productos
            preenvasados contenidos en ellos con el objeto de destinarlos individualmente a
            un consumidor final, dichos productos deben contener en lo individual toda la
            información comercial obligatoria que establece esta Norma, siempre que no estén
            comprendidos en cualquiera de los supuestos indicados en el inciso 2.2 de la misma NOM.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 7,
        text: (
          <p className="table-content__row__cell--text">
            d) Estar colocada en la superficie principal de exhibición,
            tratándose al menos de la siguiente información:
            <br />
            i) Nombre o denominación genérica del producto en los términos del inciso 5.2.1 a), e
            <br />
            ii) Declaración de cantidad.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 8,
        text: (
          <p className="table-content__row__cell--text">
            5.3 Instructivos o manuales de operación y garantías
            <br />
            5.3.1 Idioma
            <br />
            Los instructivos o manuales de operación y garantías deben expresarse en idioma
            español y de acuerdo al Sistema General de Unidades de Medida. Cuando la
            información se exprese en otros idiomas, debe aparecer también en idioma español,
            cuidando que por lo menos sea con el mismo tamaño.
          </p>
        )
      },
      {
        pageNumber: 3,
        questionNumber: 9,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2 Contenido
            <br />
            Los productos objeto de esta Norma Oficial Mexicana, cuyo uso, manejo o
            conservación requiera de instrucciones, deben ir acompañados, sin cargo
            adicional para el consumidor, de los instructivos o manuales de operación
            y, en su caso, garantías, los cuales deben contener indicaciones claras y
            precisas para el uso normal, manejo, conservación, ensamble y aprovechamiento
            de los productos, así como las advertencias para el manejo seguro y
            confiable de los mismos.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 10,
        text: (
          <p className="table-content__row__cell--text">
            5.3.2.1 Los instructivos o manuales de operación adicionalmente deben indicar:
            <br />
            a) Nombre, denominación o razón social del productor nacional, o
            importador, domicilio fiscal y teléfono de servicio en territorio nacional.
            <br />
            b) Identificación de los productos o modelos a los que aplica.
            <br />
            c) Precauciones para el usuario o consumidor (cuando se trate de un producto peligroso).
            <br />
            d) Cuando proceda, las indicaciones para su instalación, conexión,
            ensamble o mantenimiento para su adecuado funcionamiento.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 11,
        text: (
          <p className="table-content__row__cell--text">
            5.3.3 Garantías
            <br />
            Cuando se ofrezcan garantías, éstas deben expedirse en los términos y
            forma establecidos en la Ley Federal de Protección al Consumidor e
            indicar y cumplir con lo siguiente:
            <br />
            a) Nombre, denominación o razón social y domicilio fiscal del
            productor nacional o importador del producto y teléfonos de
            servicio en territorio nacional.
            <br />
            b) Identificación de los productos y/o modelos a los que aplica.
            <br />
            c) Nombre y domicilio del establecimiento en la República Mexicana
            donde puede hacerse efectiva la garantía en los términos de la misma,
            así como aquéllos donde el consumidor pueda adquirir partes y refacciones.
            Adicionalmente, la garantía puede indicar que ésta puede hacerse efectiva
            en cualquiera de las sucursales a nivel nacional del proveedor que la
            ofrezca, sin necesidad de especificar los domicilios de las mismas.
            <br />
            d) Duración de la garantía.
            <br />
            e) Conceptos que cubre la garantía y limitaciones o excepciones.
            <br />
            f) Procedimiento para hacer efectiva la garantía.
            <br />
            g) Precisar la fecha en que el consumidor recibió el producto
            o indicar los documentos de referencia donde ésta se señale. Es
            responsabilidad del comerciante asegurarse que esta información
            esté presente al momento de la venta del producto al consumidor,
            de no hacerlo así, el comerciante debe cumplir con los términos
            de la garantía directamente.
            <br />
            h) Para hacer efectiva la garantía no pueden exigirse otros requisitos
            más que la presentación del producto, la garantía vigente y comprobante de venta.
          </p>
        )
      },
      {
        pageNumber: 4,
        questionNumber: 12,
        text: (
          <p className="table-content__row__cell--text">
            5.3.4 Incorporación de los instructivos o manuales de operación y
            garantías En todos los casos, los instructivos o manuales de operación
            y garantías deben entregarse al consumidor en el establecimiento
            comercial cuando adquiera los productos.
          </p>
        )
      }
    ]
  }
};
