import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataDisplay } from '../../../../components';
import { Client } from '../../../../interfaces';

interface Props {
  client: Client
}

export const FiscalAddress: React.FC<Props> = (props) => {
  const {
    client
  } = props;

  const { t } = useTranslation();

  return (
    <DataDisplay
      dataTitle={t('clients.fiscalAddress')}
      items={[
        {
          label: t('clients.street'),
          value: client.fiscalAddress.street,
          format: 'none'
        },
        {
          label: t('clients.extNumber'),
          value: client.fiscalAddress.number,
          format: 'none'
        },
        {
          label: t('clients.intNumber'),
          value: client.fiscalAddress.int,
          format: 'none'
        },
        {
          label: t('clients.cologne'),
          value: client.fiscalAddress.neighborhood,
          format: 'none'
        },
        {
          label: t('clients.city'),
          value: client.fiscalAddress.city,
          format: 'none'
        },
        {
          label: t('clients.state'),
          value: client.fiscalAddress.state,
          format: 'none'
        },
        {
          label: t('clients.postalCode'),
          value: client.fiscalAddress.postalCode,
          format: 'none'
        },
        {
          label: t('clients.country'),
          value: client.fiscalAddress.country,
          format: 'none'
        }
      ]}
    />
  );
};

export default FiscalAddress;
