/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition
} from 'react';

import SignatureCanvas from 'react-signature-canvas';

import saveAs from 'file-saver';
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CommonInspectionFormatQuestion,
  PackingType,
  MeasurementEquipment,
  Inspection,
  InspectionList,
  ConstancyProductIndicators,
  NormCodes,
  RevisionProductIndicators,
  FileInputData,
  SignedFile,
  SectionsNOM004,
  SectionsNOM003,
  ReviewComment,
  User,
  Question,
  Client
} from '../../../../../interfaces';
import {
  Button,
  File as FileElement,
  FileInput,
  Icon,
  Input,
  Modal,
  RadioList,
  Select,
  Spinner,
  SubTitle,
  Title,
  Switch,
  ModalView,
  ToolTip
} from '../../../..';
import { useNavigate, useResource } from '../../../../../hooks';
import { utils } from '../../../../../helpers';

import './styles.scss';
import { AppContext } from '../../../../../context/AppContext';
import configuration from '../../../../../configuration';

const packingTypes: { [name: string]: string } = {
  cylindrical: 'Cilindro',
  circular: 'Circular',
  rectangular: 'Rectángulo'
};

interface SamplingInformation {
  maxValue: number
  label: string
}

interface Props {
  hasPackingTable: boolean
  hasSamplingTable: boolean
  hasMeasurementEquipment: boolean
  documentCode: string
  documentDescription: string
  documentIssueDate: string
  questions: CommonInspectionFormatQuestion[]
  onFetchInspection?: (_value: Inspection) => void
  labelsToPut: number
  isGrouped: boolean
  roundID: number
  normCode: NormCodes
  productIndicators?: ConstancyProductIndicators | RevisionProductIndicators
  disabled: boolean
  norm004Section?: SectionsNOM004
  setNorm004Section?: (_value: SectionsNOM004) => void
  norm020Section?: SectionsNOM004
  setNorm020Section?: (_value: SectionsNOM004) => void
  norm003Section?: SectionsNOM003
  setNorm003Section?: (_value: SectionsNOM003) => void
  norm173Section?: SectionsNOM003
  setNorm173Section?: (_value: SectionsNOM003) => void
  user: User
  setNormSectionChanged?: (_value: boolean) => void
  lastRoundQuestions?: Question[]
  client: Client
  showNavigateButtons?: boolean
  NOM003Complement: boolean
}

const CommonList: React.FC<Props> = (props: Props) => {
  const {
    hasPackingTable,
    hasSamplingTable,
    hasMeasurementEquipment,
    documentCode,
    documentDescription,
    documentIssueDate,
    disabled,
    questions,
    onFetchInspection,
    labelsToPut,
    isGrouped,
    roundID,
    normCode,
    productIndicators,
    norm004Section,
    setNorm004Section,
    norm020Section,
    setNorm020Section,
    norm003Section,
    setNorm003Section,
    norm173Section,
    setNorm173Section,
    user,
    setNormSectionChanged,
    lastRoundQuestions,
    client,
    showNavigateButtons = true,
    NOM003Complement
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const {
    createResource,
    fetchResources,
    eraseResource
  } = useResource<Inspection>();

  const {
    fetchResource: signFile
  } = useResource<string>();

  const {
    fetchResource
  } = useResource<string>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    userRole,
    setOpenModal,
    inspection,
    soundEffects,
    commonFormat,
    setCommonFormat,
    setInspection,
    setNotReFetchInspection
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [isPending, startTransition] = useTransition();
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [progressList, setProgressList] = useState<number>(0);
  const [sampling, setSampling] = useState<number>(1);
  const [pages, setPages] = useState<ReactElement[]>([]);
  const [showObservations, setShowObservations] = useState<boolean>(false);
  const [remainigProducts, setRemainigProducts] = useState<number>(0);
  const [lot, setLot] = useState<number>(0);
  const [labelsToPutTotal, setLabelsToPutTotal] = useState<number>(0);
  const [disableSamplingOrPacking, setDisableSamplingOrPacking] = useState<boolean>(false);
  const [questionsNumber, setQuestionsNumber] = useState<number>(0);
  const [loadedAnswers, setLoadedAnswers] = useState<boolean>(false);
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [onFinish, setOnFinish] = useState<boolean>(false);
  const [expandedImagePath, setExpandedImagePath] = useState<string>('');
  const [samplingInputInfo, setSamplingInputInfo] = useState<SamplingInformation>({
    label: 'Cantidad de piezas del Lote',
    maxValue: 0
  });
  const [remainingLot, setRemainingLot] = useState<number>(0);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [inspectionListDate, setInspectionListDate] = useState<Date | null>(null);

  const sigCanvas = useRef<any>();

  useEffect(() => {
    setInspectionListDate(inspection.rounds.find((item) => item.id === roundID)!.inspectionListDate);
  }, [roundID, inspection]);

  const updateMinimunSizedenomination = () => {
    setTimeout(() => {
      if (!disabled) {
        utils.calculateNOM51();
      }

      const SPE = document.getElementById('SPE-value');
      const content: HTMLInputElement | null = document.getElementById('content') as HTMLInputElement;
      const productType: HTMLInputElement | null = document.getElementById('productType') as HTMLInputElement;

      const result1 = document.getElementById('nom-050-result-1');
      const result2 = document.getElementById('nom-050-result-2');
      const result1003 = document.getElementById('nom-050-result-1-003');
      const result2003 = document.getElementById('nom-050-result-2-003');

      const element1 = document.getElementById('question-7-cell-1');
      const element2 = document.getElementById('question-7-cell-2');
      const element3 = document.getElementById('question-7-cell-3');
      const element4 = document.getElementById('question-7-cell-4');
      const element5 = document.getElementById('question-7-cell-5');

      const question45Element1 = document.getElementById('question-45-cell-1');
      const question45Element2 = document.getElementById('question-45-cell-2');
      const question45Element3 = document.getElementById('question-45-cell-3');
      const question45Element4 = document.getElementById('question-45-cell-4');
      const question45Element5 = document.getElementById('question-45-cell-5');

      const businessName = document.querySelectorAll('#businessName-item');
      const fiscalAddress = document.querySelectorAll('#fiscalAddress-item');

      if (businessName) {
        for (let index = 0; index < businessName.length; index += 1) {
          businessName[index].textContent = client.businessName;
        }
      }

      if (fiscalAddress) {
        for (let index = 0; index < fiscalAddress.length; index += 1) {
          fiscalAddress[index].textContent = `${client.fiscalAddress.street} #${client.fiscalAddress.number}${client.fiscalAddress.int ? ` Int: ${client.fiscalAddress.int}` : ''}, ${client.fiscalAddress.neighborhood}, ${client.fiscalAddress.city}, ${client.fiscalAddress.state}, C.P.:${client.fiscalAddress.postalCode}, ${client.fiscalAddress.country}`;
        }
      }

      let speCalculedValue: number = 0;
      let contentCalculedValue: number = 0;

      if (result1 && SPE) {
        result1.textContent = `${SPE.textContent} cm2`;
      }

      if (result1003 && SPE) {
        result1003.textContent = `${SPE.textContent} cm2`;
      }

      if (element1 && SPE) {
        element1.textContent = `${SPE.textContent} cm2`;
      }
      if (question45Element1 && SPE) {
        question45Element1.textContent = `${SPE.textContent} cm2`;
      }

      if (inspection.norm === '142') {
        if (element2 && content) {
          element2.textContent = `${content.value} ml`;
        }
      }

      if (element2 && productType && content) {
        element2.textContent = `${Number(content.value) <= 0 ? 'Se debe ingresar el "Contenido neto"' : content.value} ${productType.value === 'solido g' ? 'g' : 'ml'}`;
      }

      if (question45Element2 && productType && content) {
        question45Element2.textContent = `${Number(content.value) <= 0 ? 'Se debe ingresar el "Contenido neto"' : content.value} ${productType.value === 'solido g' ? 'g' : 'ml'}`;
      }

      if (result2) {
        if (SPE) {
          const spe: number = parseFloat((SPE.textContent || '').replace(/,/g, ''));

          if (spe >= 1 && spe <= 32) {
            result2.textContent = '1.5 mm';
          }
          if (spe > 32 && spe <= 161) {
            result2.textContent = '3 mm';
          }
          if (spe > 161 && spe <= 645) {
            result2.textContent = '4.5 mm';
          }
          if (spe > 645 && spe <= 2580) {
            result2.textContent = '6 mm';
          }
          if (spe > 2580) {
            result2.textContent = '12 mm';
          }
        }
      }

      if (result2003) {
        if (SPE) {
          const spe: number = parseFloat((SPE.textContent || '').replace(/,/g, ''));

          if (spe >= 1 && spe <= 32) {
            result2003.textContent = '1.5 mm';
          }
          if (spe > 32 && spe <= 161) {
            result2003.textContent = '3 mm';
          }
          if (spe > 161 && spe <= 645) {
            result2003.textContent = '4.5 mm';
          }
          if (spe > 645 && spe <= 2580) {
            result2003.textContent = '6 mm';
          }
          if (spe > 2580) {
            result2003.textContent = '12 mm';
          }
        }
      }

      if (element3) {
        if (SPE) {
          const spe: number = parseFloat((SPE.textContent || '').replace(/[,]/g, ''));

          if (spe !== 0) {
            if (spe >= 1 && spe <= 32) {
              speCalculedValue = 1.5;
              element3.textContent = '1.5 mm';
            }
            if (spe > 32 && spe <= 161) {
              speCalculedValue = 3;
              element3.textContent = '3 mm';
            }
            if (spe > 161 && spe <= 645) {
              speCalculedValue = 4.5;
              element3.textContent = '4.5 mm';
            }
            if (spe > 645 && spe <= 2580) {
              speCalculedValue = 6;
              element3.textContent = '6 mm';
            }
            if (spe > 2580) {
              speCalculedValue = 12;
              element3.textContent = '12 mm';
            }
          } else {
            speCalculedValue = 0;
            element3.textContent = '...';
          }
        }
      }

      if (question45Element3) {
        if (SPE) {
          const spe: number = parseFloat((SPE.textContent || '').replace(/,/g, ''));

          if (spe !== 0) {
            if (spe >= 1 && spe <= 32) {
              question45Element3.textContent = '1.5 mm';
            }
            if (spe > 32 && spe <= 161) {
              question45Element3.textContent = '3 mm';
            }
            if (spe > 161 && spe <= 645) {
              question45Element3.textContent = '4.5 mm';
            }
            if (spe > 645 && spe <= 2580) {
              question45Element3.textContent = '6 mm';
            }
            if (spe > 2580) {
              question45Element3.textContent = '12 mm';
            }
          } else {
            question45Element3.textContent = '...';
          }
        }
      }

      if (element4) {
        if (content) {
          const contentNumber = Number(content.value);

          if (contentNumber !== 0) {
            if (contentNumber <= 50) {
              contentCalculedValue = 1.5;
              element4.textContent = '1.5 mm';
            }
            if (contentNumber > 50 && contentNumber <= 200) {
              contentCalculedValue = 2;
              element4.textContent = '2 mm';
            }
            if (contentNumber > 200 && contentNumber <= 750) {
              contentCalculedValue = 3;
              element4.textContent = '3 mm';
            }
            if (contentNumber > 750 && contentNumber <= 1000) {
              contentCalculedValue = 4.5;
              element4.textContent = '4.5 mm';
            }
            if (contentNumber > 1000 && contentNumber <= 5000) {
              contentCalculedValue = 5;
              element4.textContent = '5 mm';
            }
            if (contentNumber > 5000) {
              contentCalculedValue = 6;
              element4.textContent = '6 mm';
            }
          } else {
            contentCalculedValue = 0;
            element4.textContent = '...';
          }
        }
      }

      if (question45Element4) {
        if (content) {
          const contentNumber = Number(content.value);

          if (contentNumber !== 0) {
            if (contentNumber <= 50) {
              question45Element4.textContent = '1.5 mm';
            }
            if (contentNumber > 50 && contentNumber <= 200) {
              question45Element4.textContent = '2 mm';
            }
            if (contentNumber > 200 && contentNumber <= 750) {
              question45Element4.textContent = '3 mm';
            }
            if (contentNumber > 750 && contentNumber <= 1000) {
              question45Element4.textContent = '4.5 mm';
            }
            if (contentNumber > 1000 && contentNumber <= 5000) {
              question45Element4.textContent = '5 mm';
            }
            if (contentNumber > 5000) {
              question45Element4.textContent = '6 mm';
            }
          } else {
            question45Element4.textContent = '...';
          }
        }
      }

      if (element5) {
        if (contentCalculedValue <= 0 && speCalculedValue <= 0) {
          element5.textContent = '...';
        } else {
          element5.textContent = `${Math.min(contentCalculedValue, speCalculedValue)} mm`;
        }
      }

      if (question45Element5) {
        if (contentCalculedValue <= 0 && speCalculedValue <= 0) {
          question45Element5.textContent = '...';
        } else {
          question45Element5.textContent = `${Math.min(contentCalculedValue, speCalculedValue)} mm`;
        }
      }

      const element1Q8 = document.getElementById('question-8-cell-1');
      const element2Q8 = document.getElementById('question-8-cell-2');

      if (element1Q8 && element2Q8 && element5) {
        const minimunSize = Number(element5.textContent?.replace(' mm', ''));

        if (!Number.isNaN(minimunSize)) {
          element1Q8.textContent = element5.textContent;
          element2Q8.textContent = `${minimunSize * 2} mm`;
        } else {
          element1Q8.textContent = '...';
          element2Q8.textContent = '...';
        }
      }

      const tamañoMinimo = document.getElementById('tamaño-minimo');

      if (tamañoMinimo) {
        if (commonFormat.packing!.content <= 0 && commonFormat.packing!.content <= 50) {
          tamañoMinimo.textContent = '1,5 mm';
        }
        if (commonFormat.packing!.content > 50 && commonFormat.packing!.content <= 190) {
          tamañoMinimo.textContent = '2,0 mm';
        }
        if (commonFormat.packing!.content > 190 && commonFormat.packing!.content <= 500) {
          tamañoMinimo.textContent = '2,5 mm';
        }
        if (commonFormat.packing!.content > 500 && commonFormat.packing!.content <= 1000) {
          tamañoMinimo.textContent = '3,0 mm';
        }
        if (commonFormat.packing!.content > 1000 && commonFormat.packing!.content <= 4000) {
          tamañoMinimo.textContent = '5,0 mm';
        }
        if (commonFormat.packing!.content > 4000) {
          tamañoMinimo.textContent = '7,0 mm';
        }
      }
    }, 1500);
  };

  const getSPENumber = () => {
    const height = Number(commonFormat.packing?.height) || 0;
    const width = Number(commonFormat.packing?.width) || 0;
    const radio = Number(commonFormat.packing?.radius) || 0;
    let surface = 0;

    if (commonFormat.packing?.type === 'rectangular') {
      surface = height * width;
    } else if (commonFormat.packing?.type === 'circular') {
      // eslint-disable-next-line no-restricted-properties
      surface = Math.PI * Math.pow(radio, 2);
    } else if (commonFormat.packing?.type === 'cylindrical') {
      surface = ((radio * 2) * height * 40 * Math.PI) / 100;
    }

    const value = `${surface.toFixed(2)}`.split('.');

    let parteEntera = value[0];

    const parteDecimal = value.length > 1 ? `.${value[1]}` : '';

    parteEntera = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    const SPE = `${parteEntera}${parteDecimal}`;

    const elementResult = document.getElementById('question-87-result');

    if (elementResult) {
      const spe: number = parseFloat((SPE || '').replace(/,/g, ''));

      if (spe <= 32) {
        elementResult.textContent = '1mm';
      }
      if (spe > 32) {
        elementResult.textContent = '1.5mm';
      }
    }

    if (!disabled) {
      utils.calculateNOM51();
    }

    return SPE;
  };

  const checkCompliesSelectQuestion17 = (
    questionNumber: number
  ) => {
    const selectQuestion17: HTMLInputElement | null = document.getElementById('question-17-select') as HTMLInputElement;
    const alertTextQuestion17 = document.getElementById('question-17-alert-text');

    if (selectQuestion17) {
      if (selectQuestion17.value === '') {
        selectQuestion17.classList.add('text-red');
        selectQuestion17.classList.add('border-red');
        selectQuestion17.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion17.classList.remove('text-red');
        selectQuestion17.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer !== false
        && selectQuestion17.value === 'NO') {
        selectQuestion17.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = '';
        }
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion17.value === 'SI') {
        selectQuestion17.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = '';
        }
      }
      if (commonFormat.questions[questionNumber].answer !== null
        && selectQuestion17.value === 'No aplica') {
        selectQuestion17.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion17) {
          alertTextQuestion17.textContent = '';
        }
      }
    }

    return true;
  };

  const checkCompliesSelectQuestion38 = (
    questionNumber: number
  ) => {
    const selectQuestion38: HTMLInputElement | null = document.getElementById('question-38-select') as HTMLInputElement;
    const alertTextQuestion38 = document.getElementById('question-38-alert-text');

    if (selectQuestion38) {
      if (selectQuestion38.value === '') {
        selectQuestion38.classList.add('text-red');
        selectQuestion38.classList.add('border-red');
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion38.classList.remove('text-red');
        selectQuestion38.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer === true
        && selectQuestion38.value === 'NO') {
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion38) {
          alertTextQuestion38.textContent = 'El inciso se marcó como "Cumple" pero se marcó como que "NO coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion38.value === 'SI') {
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion38) {
          alertTextQuestion38.textContent = 'El inciso se marcó como "NO Cumple" pero se marcó como que "SI coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
    }

    return true;
  };

  const checkCompliesSelectQuestion38NOM003 = (
    questionNumber: number
  ) => {
    const selectQuestion38: HTMLInputElement | null = document.getElementById('question-38-select-003') as HTMLInputElement;
    const alertTextQuestion38 = document.getElementById('question-38-alert-text-003');

    if (selectQuestion38) {
      if (selectQuestion38.value === '') {
        selectQuestion38.classList.add('text-red');
        selectQuestion38.classList.add('border-red');
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion38.classList.remove('text-red');
        selectQuestion38.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer === true
        && selectQuestion38.value === 'NO') {
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion38) {
          alertTextQuestion38.textContent = 'El inciso se marcó como "Cumple" pero se marcó como que "NO coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion38.value === 'SI') {
        selectQuestion38.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion38) {
          alertTextQuestion38.textContent = 'El inciso se marcó como "NO Cumple" pero se marcó como que "SI coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
    }

    return true;
  };

  const checkCompliesSelectQuestion30 = (
    questionNumber: number
  ) => {
    const selectQuestion30: HTMLInputElement | null = document.getElementById('question-30-select') as HTMLInputElement;
    const alertTextQuestion30 = document.getElementById('question-30-alert-text');

    if (selectQuestion30) {
      if (selectQuestion30.value === '') {
        selectQuestion30.classList.add('text-red');
        selectQuestion30.classList.add('border-red');
        selectQuestion30.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion30.classList.remove('text-red');
        selectQuestion30.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer !== false
        && selectQuestion30.value === 'NO') {
        selectQuestion30.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = '';
        }
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion30.value === 'SI') {
        selectQuestion30.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = '';
        }
      }
      if (commonFormat.questions[questionNumber].answer !== null
        && selectQuestion30.value === 'No aplica') {
        selectQuestion30.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = 'Las respuesta de la ayuda para el inspector y la respuesta del inciso no son iguales, verifica los datos.';
        }

        return false;
      } else {
        if (alertTextQuestion30) {
          alertTextQuestion30.textContent = '';
        }
      }
    }

    return true;
  };

  const checkCompliesSelect1NOM189 = (): boolean => {
    const selectQuestion48: HTMLSelectElement | null = document.getElementById('select-1-189') as HTMLSelectElement;
    const alertTextQuestion48 = document.getElementById('alert-1-189');

    if (selectQuestion48) {
      if (selectQuestion48.value === '') {
        selectQuestion48.classList.add('text-red');
        selectQuestion48.classList.add('border-red');
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = 'Se debe seleccionar una opción';
        }

        return false;
      } else {
        selectQuestion48.classList.remove('text-red');
        selectQuestion48.classList.remove('border-red');

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = '';
        }
      }
    }

    return true;
  };

  const checkCompliesSelect2NOM189 = (): boolean => {
    const selectQuestion48: HTMLSelectElement | null = document.getElementById('select-2-189') as HTMLSelectElement;
    const alertTextQuestion48 = document.getElementById('alert-2-189');

    if (selectQuestion48) {
      if (selectQuestion48.value === '') {
        selectQuestion48.classList.add('text-red');
        selectQuestion48.classList.add('border-red');
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = 'Se debe seleccionar una opción';
        }

        return false;
      } else {
        selectQuestion48.classList.remove('text-red');
        selectQuestion48.classList.remove('border-red');

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = '';
        }
      }
    }

    return true;
  };

  const checkCompliesSelect3NOM189 = (): boolean => {
    const selectQuestion48: HTMLSelectElement | null = document.getElementById('select-3-189') as HTMLSelectElement;
    const alertTextQuestion48 = document.getElementById('alert-3-189');

    if (selectQuestion48) {
      if (selectQuestion48.value === '') {
        selectQuestion48.classList.add('text-red');
        selectQuestion48.classList.add('border-red');
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = 'Se debe seleccionar una opción';
        }

        return false;
      } else {
        selectQuestion48.classList.remove('text-red');
        selectQuestion48.classList.remove('border-red');

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = '';
        }
      }
    }

    return true;
  };

  const checkCompliesSelectQuestion48 = (
    questionNumber: number
  ) => {
    const selectQuestion48: HTMLInputElement | null = document.getElementById('question-48-select') as HTMLInputElement;
    const alertTextQuestion48 = document.getElementById('question-48-alert-text');

    if (selectQuestion48) {
      if (selectQuestion48.value === '') {
        selectQuestion48.classList.add('text-red');
        selectQuestion48.classList.add('border-red');
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion48.classList.remove('text-red');
        selectQuestion48.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer === true
        && selectQuestion48.value === 'NO') {
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = 'El inciso se marcó como "Cumple" pero se marcó como que "NO coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion48.value === 'SI') {
        selectQuestion48.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion48) {
          alertTextQuestion48.textContent = 'El inciso se marcó como "NO Cumple" pero se marcó como que "SI coincide" el responsable, las respuestas no coiniciden.';
        }

        return false;
      }
    }

    return true;
  };

  // eslint-disable-next-line no-unused-vars
  const checkCompliesSelectQuestion50 = (
    questionNumber: number
  ) => {
    const selectQuestion50: HTMLInputElement | null = document.getElementById('question-50-select') as HTMLInputElement;
    const alertTextQuestion50 = document.getElementById('question-50-alert-text');

    if (selectQuestion50) {
      if (selectQuestion50.value === '') {
        selectQuestion50.classList.add('text-red');
        selectQuestion50.classList.add('border-red');
        selectQuestion50.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        return false;
      } else {
        selectQuestion50.classList.remove('text-red');
        selectQuestion50.classList.remove('border-red');
      }
      if (commonFormat.questions[questionNumber].answer === true
        && selectQuestion50.value === 'NO') {
        selectQuestion50.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion50) {
          alertTextQuestion50.textContent = 'El inciso se marcó como "Cumple" pero se marcó como que "NO coincide" el país de origen, las respuestas no coiniciden.';
        }

        return false;
      }
      if (commonFormat.questions[questionNumber].answer !== true
        && selectQuestion50.value === 'SI') {
        selectQuestion50.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        if (alertTextQuestion50) {
          alertTextQuestion50.textContent = 'El inciso se marcó como "NO Cumple" pero se marcó como que "SI coincide" el país de origen, las respuestas no coiniciden.';
        }

        return false;
      }
    }

    return true;
  };

  const isInspectionListvalid = () => {
    if (['003', '050', '141', '142', '173', '187', '189', '235', '051', '189'].includes(inspection.norm)) {
      if (commonFormat.packing?.type === 'none') {
        setErrors({
          packing: t('inspections.errors.packingTypePackage')
        });

        return false;
      }

      switch (commonFormat.packing?.type) {
        case 'cylindrical':
          if (Number(commonFormat.packing?.height) <= 0
            || Number.isNaN(Number(commonFormat.packing?.height))) {
            setErrors({
              height: t('inspections.errors.heightPackage')
            });

            return false;
          }

          if (Number(commonFormat.packing?.radius) <= 0
            || Number.isNaN(Number(commonFormat.packing?.radius))) {
            setErrors({
              radio: t('inspections.errors.radiusPackage')
            });

            return false;
          }
          break;
        case 'circular':
          if (Number(commonFormat.packing?.radius) <= 0
            || Number.isNaN(Number(commonFormat.packing?.radius))) {
            setErrors({
              radio: t('inspections.errors.radiusPackage')
            });

            return false;
          }
          break;
        case 'rectangular':
          if (Number(commonFormat.packing?.height) <= 0
            || Number.isNaN(Number(commonFormat.packing?.height))) {
            setErrors({
              height: t('inspections.errors.heightPackage')
            });

            return false;
          }

          if (Number(commonFormat.packing?.width) <= 0
            || Number.isNaN(Number(commonFormat.packing?.width))) {
            setErrors({
              width: t('inspections.errors.widthPackage')
            });

            return false;
          }
          break;
        default:
          break;
      }

      if (['051', '142', '173', '187', '235'].includes(inspection.norm)) {
        if (Number(commonFormat.packing?.content) <= 0
          || Number.isNaN(Number(commonFormat.packing?.content))
          || commonFormat.packing?.content === 0) {
          setErrors({
            content: t('inspections.errors.content')
          });

          return false;
        }
      }

      if (['051', '173', '187', '235'].includes(inspection.norm)) {
        if (commonFormat.packing?.productType !== 'liquido ml'
          && commonFormat.packing?.productType !== 'solido g') {
          setErrors({
            productType: t('inspections.errors.productType')
          });

          return false;
        }
      }
    }

    if (inspection.norm === '004') {
      if (norm004Section === 'none') {
        const sectionNorm: any = document.getElementById('section-norm');

        if (sectionNorm) {
          sectionNorm.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
        Modal.fireWarning(
          t('inspections.errors.sectionNorm'),
          () => {
            Modal.close();
          }
        );
        return false;
      }
    }
    if (inspection.norm === '020') {
      if (norm020Section === 'none') {
        const sectionNorm: any = document.getElementById('section-norm');

        if (sectionNorm) {
          sectionNorm.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
        Modal.fireWarning(
          t('inspections.errors.sectionNorm'),
          () => {
            Modal.close();
          }
        );
        return false;
      }
    }
    if (inspection.norm === '003' || NOM003Complement) {
      if (norm003Section === 'none') {
        const sectionNorm: any = document.getElementById('section-norm');

        if (sectionNorm) {
          sectionNorm.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
        Modal.fireWarning(
          t('inspections.errors.sectionNorm'),
          () => {
            Modal.close();
          }
        );
        return false;
      }
    }
    if (inspection.norm === '173') {
      if (norm173Section === 'none') {
        const sectionNorm: any = document.getElementById('section-norm');

        if (sectionNorm) {
          sectionNorm.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }
        Modal.fireWarning(
          t('inspections.errors.sectionNorm'),
          () => {
            Modal.close();
          }
        );
        return false;
      }
    }

    if (['051', '173', '187', '235', '050', '003'].includes(inspection.norm)) {
      const result17 = checkCompliesSelectQuestion17(17);
      const result30 = checkCompliesSelectQuestion30(30);
      const result38 = checkCompliesSelectQuestion38(['050', '003'].includes(inspection.norm) ? 5 : 38);
      let result38003and050 = true;

      if (NOM003Complement) {
        result38003and050 = checkCompliesSelectQuestion38NOM003(['CC', 'CN', 'REV'].includes(inspection.service.code) ? 41 : 22);
      }

      const addedAlertText = document.getElementById('added-alert-text');
      const addedEdulcorantesCafeinaAlertText = document.getElementById('added-edulcorntes-cafeina-alert-text');
      const sellosLeyendasAlertText = document.getElementById('sellos-leyendas-alert-text');
      const dibujosAnimadosAlertText = document.getElementById('dibujos-animados-alert-text');
      const aceitesAlertText = document.getElementById('aceites-alert-text');

      if (addedAlertText) {
        const azucaresSelect: HTMLInputElement | null = document.getElementById('azucares-select') as HTMLInputElement;
        const grasasSelect: HTMLInputElement | null = document.getElementById('grasas-select') as HTMLInputElement;
        const sodioSelect: HTMLInputElement | null = document.getElementById('sodio-select') as HTMLInputElement;

        if (azucaresSelect && grasasSelect && sodioSelect) {
          if (azucaresSelect.value === '' || grasasSelect.value === '' || sodioSelect.value === '') {
            addedAlertText.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            addedAlertText.textContent = 'Se deben responder si tiene añadidos';

            return false;
          } else {
            addedAlertText.textContent = '';
          }
        }
      }

      if (addedEdulcorantesCafeinaAlertText) {
        const edulcorantesSelect: HTMLInputElement | null = document.getElementById('edulcorantes-select') as HTMLInputElement;
        const cafeinaSelect: HTMLInputElement | null = document.getElementById('cafeina-select') as HTMLInputElement;

        if (edulcorantesSelect && cafeinaSelect) {
          if (edulcorantesSelect.value === '' || cafeinaSelect.value === '') {
            addedEdulcorantesCafeinaAlertText.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            addedEdulcorantesCafeinaAlertText.textContent = 'Se debe responder si ostenta edulcorantes o cafeína';

            return false;
          } else {
            addedEdulcorantesCafeinaAlertText.textContent = '';
          }
        }
      }

      if (sellosLeyendasAlertText) {
        const select: HTMLInputElement | null = document.getElementById('select-leyenda-question-144') as HTMLInputElement;

        if (select) {
          if (select.value === '') {
            sellosLeyendasAlertText.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            sellosLeyendasAlertText.textContent = 'Se debe responder si ostenta la leyenda "Este producto no contiene sellos ni leyendas"';

            return false;
          } else {
            sellosLeyendasAlertText.textContent = '';
          }
        }
      }

      if (dibujosAnimadosAlertText) {
        const select: HTMLInputElement | null = document.getElementById('question-145-select') as HTMLInputElement;

        if (select) {
          if (select.value === '') {
            dibujosAnimadosAlertText.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            dibujosAnimadosAlertText.textContent = 'Se debe seleccionar una respuesta en el input';

            return false;
          } else {
            dibujosAnimadosAlertText.textContent = '';
          }
        }
      }

      if (aceitesAlertText) {
        const select: HTMLInputElement | null = document.getElementById('article-216-select') as HTMLInputElement;

        if (select) {
          if (select.value === '') {
            aceitesAlertText.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            aceitesAlertText.textContent = 'Se debe seleccionar si contiene "aceites vegetales" o "aceites vegetales parcialmente hidrogenados"';

            return false;
          } else {
            aceitesAlertText.textContent = '';
          }
        }
      }

      if (result17 === false || result30 === false || result38 === false || result38003and050 === false) {
        return false;
      }
    }

    if (['189'].includes(inspection.norm)) {
      const resultAlert1 = checkCompliesSelect1NOM189();
      const resultAlert2 = checkCompliesSelect2NOM189();
      const resultAlert3 = checkCompliesSelect3NOM189();

      if (resultAlert1 === false || resultAlert2 === false || resultAlert3 === false) {
        return false;
      }
    }

    if (['DC', 'DN'].includes(inspection.service.code)) {
      let result48: boolean;

      if (['051', '235', '187', '173'].includes(inspection.norm)) {
        result48 = checkCompliesSelectQuestion48(48);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '189') {
        result48 = checkCompliesSelectQuestion48(3);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '015') {
        result48 = checkCompliesSelectQuestion48(6);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '141') {
        result48 = checkCompliesSelectQuestion48(9);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '142') {
        result48 = checkCompliesSelectQuestion48(15);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '024') {
        result48 = checkCompliesSelectQuestion48(3);

        if (result48 === false) {
          return false;
        }
      }

      if (inspection.norm === '020') {
        if (norm020Section === 'A') {
          result48 = checkCompliesSelectQuestion48(2);

          if (result48 === false) {
            return false;
          }
        }

        if (norm020Section === 'B') {
          result48 = checkCompliesSelectQuestion48(11);

          if (result48 === false) {
            return false;
          }
        }

        if (norm020Section === 'C') {
          result48 = checkCompliesSelectQuestion48(18);

          if (result48 === false) {
            return false;
          }
        }

        if (norm020Section === 'D') {
          result48 = checkCompliesSelectQuestion48(25);

          if (result48 === false) {
            return false;
          }
        }

        if (norm020Section === 'F') {
          result48 = checkCompliesSelectQuestion48(35);

          if (result48 === false) {
            return false;
          }
        }
      }
    }

    const question98Alert1 = document.getElementById('question-98-alert-1');
    const question98Alert2 = document.getElementById('question-98-alert-2');
    const question98Alert3 = document.getElementById('question-98-alert-3');
    const question98Alert4 = document.getElementById('question-98-alert-4');
    const question98Alert5 = document.getElementById('question-98-alert-5');
    const question98Alert6 = document.getElementById('question-98-alert-6');

    // eslint-disable-next-line no-unused-vars
    const buttonAlert = document.getElementById('button-alerts-messages');

    const saturatedFat = document.getElementById('saturatedFat-input') as HTMLInputElement;
    const totalFat = document.getElementById('totalFat-input') as HTMLInputElement;
    const carbohydrates = document.getElementById('carbohydrates-input') as HTMLInputElement;
    const sugars = document.getElementById('sugars-input') as HTMLInputElement;
    const sugarsAdded = document.getElementById('sugarsAdded-input') as HTMLInputElement;
    const selectAddedSugars = document.getElementById('azucares-select') as HTMLSelectElement;
    const grasasSelect = document.getElementById('grasas-select') as HTMLSelectElement;

    const leyend216Select = document.getElementById('article-216-select') as HTMLSelectElement;

    if (question98Alert6 && buttonAlert && leyend216Select) {
      if (grasasSelect.value === 'NO'
        && leyend216Select.value === 'SI'
      ) {
        question98Alert6.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert6.textContent = 'EN INGREDIENTES AÑADIDOS INDICASTE QUE NO TIENE GRASAS AÑADIDAS, PERO ESTAS INDICANDO EN EL ARTICULO 216-BIS QUE SI OSTENTA';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert6.textContent = '';
      }
    }

    if (question98Alert1 && buttonAlert) {
      if (selectAddedSugars.value === 'NO'
        && Number(sugarsAdded.value) > 0) {
        question98Alert1.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert1.textContent = 'DECLARASTE AZUCARES AÑADIDOS EN LA DECLARACION NUTRIMENTAL, Y ESTAS INDICANDO QUE NO TIENE AZUCARES AÑADIDOS';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert1.textContent = '';
      }
    }

    if (question98Alert2 && buttonAlert) {
      if (selectAddedSugars.value === 'SI'
        && Number(sugarsAdded.value) === 0) {
        question98Alert2.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });

        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert2.textContent = 'DECLARASTE QUE NO HAY AZUCARES AÑADIDOS EN LA TABLA, PERO ESTAS INDICANDO QUE SI TIENE AÑADIDOS';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert2.textContent = '';
      }
    }

    if (question98Alert4 && sugars && buttonAlert) {
      if (Number(sugarsAdded.value) > Number(sugars.value)) {
        question98Alert4.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert4.textContent = 'AZUCARES AÑADIDOS NO PUEDE SER MAYOR QUE AZUCARES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert4.textContent = '';
      }
    }

    if (question98Alert5 && sugars && buttonAlert) {
      if (Number(sugars.value) > Number(carbohydrates.value)) {
        question98Alert5.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert5.textContent = 'AZUCARES NO PUEDE SER MAYOR QUE HIDRATOS DE CARBONO DISPONIBLES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert5.textContent = '';
      }
    }

    if (question98Alert4 && buttonAlert) {
      if (Number(sugarsAdded.value) > Number(sugars.value)) {
        question98Alert4.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert4.textContent = 'AZUCARES AÑADIDOS NO PUEDE SER MAYOR QUE AZUCARES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert4.textContent = '';
      }
    }

    if (question98Alert5 && buttonAlert) {
      if (Number(sugars.value) > Number(carbohydrates.value)) {
        question98Alert5.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert5.textContent = 'AZUCARES NO PUEDE SER MAYOR QUE HIDRATOS DE CARBONO DISPONIBLES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert5.textContent = '';
      }
    }

    if (question98Alert3 && buttonAlert) {
      if (Number(saturatedFat.value) > Number(totalFat.value)) {
        question98Alert3.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert3.textContent = 'GRASAS SATURADAS NO PUEDE SER MAYOR QUE GRASAS TOTALES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert3.textContent = '';
      }
    }

    if (question98Alert3 && buttonAlert) {
      if (Number(saturatedFat.value) > Number(totalFat.value)) {
        question98Alert3.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        buttonAlert.classList.add('apear');
        buttonAlert.classList.remove('disapear');
        question98Alert3.textContent = 'GRASAS SATURADAS NO PUEDE SER MAYOR QUE GRASAS TOTALES';

        return false;
      } else {
        buttonAlert.classList.remove('apear');
        buttonAlert.classList.add('disapear');
        question98Alert3.textContent = '';
      }
    }

    let questionsNotAnsweredQuantity = 0;
    let firstNotAnsweredQuestionID = '';

    for (let index = 0; index < commonFormat.questions.length; index += 1) {
      if (commonFormat.questions[index].answer === 0
        || commonFormat.questions[index].answer === 'title') {
        questionsNotAnsweredQuantity += 1;
      }

      if (commonFormat.questions[index].answer === 0
        && commonFormat.questions[index].answer !== 'title') {
        firstNotAnsweredQuestionID = `q-${index}`;
      }
    }

    const questionsNotAnswered = questionsNotAnsweredQuantity === commonFormat.questions.length;

    if ((progressList === 0 || progressList < 100) && !questionsNotAnswered) {
      const notAnsweredQuestion = document.getElementById(firstNotAnsweredQuestionID);

      if (notAnsweredQuestion) {
        notAnsweredQuestion.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }

      Modal.fireWarning(
        t('inspections.errors.notComplete'),
        () => {
          Modal.close();
          return true;
        }
      );

      return false;
    }

    const alertMessageQuestion135 = document.getElementById('alert-message-question-135');
    const result3 = document.getElementById('result-3-validation');
    const result2 = document.getElementById('result-2-validation');
    const result1 = document.getElementById('result-1-validation');

    const questionNumber: number = (inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion || configuration.inspectionsListsVersion) === 2 ? 136 : 89;

    if (result1 && alertMessageQuestion135) {
      if (result1.textContent === 'false' && commonFormat.questions[questionNumber].answer === true) {
        result1.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        alertMessageQuestion135.textContent = 'El análisis del sistema declara que no cumple pero marcaste el apartado como cumple';

        return false;
      }
    }
    if (result2 && alertMessageQuestion135) {
      if (result2.textContent === 'false' && commonFormat.questions[questionNumber].answer === true) {
        result2.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        alertMessageQuestion135.textContent = 'El análisis del sistema declara que no cumple pero marcaste el apartado como cumple';

        return false;
      }
    }
    if (result3 && alertMessageQuestion135) {
      if (result3.textContent === 'false' && commonFormat.questions[questionNumber].answer === true) {
        result3.scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
        alertMessageQuestion135.textContent = 'El análisis del sistema declara que no cumple pero marcaste el apartado como cumple';

        return false;
      }
    }

    if (hasSamplingTable && commonFormat.sampling) {
      if (commonFormat.sampling.lot === null || commonFormat.sampling.lot <= 0) {
        setErrors({
          samplingLot: t('inspections.errors.productSize')
        });

        return false;
      }

      if (remainingLot > 0 && remainingLot !== commonFormat.sampling?.lot) {
        setErrors({
          samplingLot: t('inspections.errors.remaininigLot', { quantity: remainingLot })
        });

        return false;
      }

      const haveNotComplyResponse: boolean = commonFormat.questions.some((question) => question.answer === false);

      if (haveNotComplyResponse && commonFormat.sampling.rejected === 0) {
        Modal.fireWarning(
          t('inspections.errors.notRejectedWhitNotComplyAnswer'),
          () => {
            Modal.close();
            return true;
          }
        );

        return false;
      }

      const haveResponseInspectionList: boolean = commonFormat.questions.some((question) => question.answer === true || question.answer === false || question.answer === null);

      if (
        commonFormat.sampling.rejected > 0
        && haveResponseInspectionList
        && haveNotComplyResponse === false) {
        Modal.fireWarning(
          t('inspections.errors.rejectedSamplingWhitoutConComplyResponse'),
          () => {
            Modal.close();
            return true;
          }
        );

        return false;
      }

      if (commonFormat.sampling.accepted === 0
        && commonFormat.sampling.rejected === 0) {
        setErrors({
          samplingAccepted: t('inspections.errors.acceptedAndRejectedSamples'),
          samplingRejected: t('inspections.errors.acceptedAndRejectedSamples')
        });

        return false;
      }

      if (Number.isNaN(Number(commonFormat.sampling.accepted))) {
        setErrors({
          samplingAccepted: t('inspections.errors.acceptedSamplesNotExist')
        });

        return false;
      }

      if (Number.isNaN(Number(commonFormat.sampling.rejected))) {
        setErrors({
          samplingRejected: t('inspections.errors.rejectedSamplesNotExist')
        });

        return false;
      }

      if (Number(commonFormat.sampling.rejected) > sampling) {
        setErrors({
          samplingRejected: t('inspections.errors.rejectedSamplesDoesntBigger')
        });

        return false;
      }

      if (Number(commonFormat.sampling.accepted) > sampling) {
        setErrors({
          samplingAccepted: t('inspections.errors.acceptedSamplesDoesntBigger')
        });

        return false;
      }

      if (
        (
          Number(commonFormat.sampling.rejected)
          + Number(commonFormat.sampling.accepted)
        ) > sampling
      ) {
        setErrors({
          samplingAccepted: t('inspections.errors.samplesDoesntBigger'),
          samplingRejected: t('inspections.errors.samplesDoesntBigger')
        });

        return false;
      }

      if (
        (
          Number(commonFormat.sampling.rejected)
          + Number(commonFormat.sampling.accepted)
        ) < sampling
      ) {
        setErrors({
          samplingAccepted: t('inspections.errors.samplesDoesntSmaller'),
          samplingRejected: t('inspections.errors.samplesDoesntSmaller')
        });

        return false;
      }

      let haveNotCompliesResultInInspectionList: boolean = false;

      for (let index = 0; index < commonFormat.questions.length; index += 1) {
        if (commonFormat.questions[index].answer === false) {
          haveNotCompliesResultInInspectionList = true;
        }
      }

      if (haveNotCompliesResultInInspectionList
        && Number(commonFormat.sampling.rejected) === 0) {
        setErrors({
          samplingRejected: t('inspections.errors.rejectedIsCero')
        });

        return false;
      }
    }

    setErrors({});

    if (commonFormat.measurementEquipment === 'none' && hasMeasurementEquipment) {
      setErrors({
        measurementEquipment: t('inspections.errors.measurementEquipment')
      });

      return false;
    }

    let firstErroredRadioButton = true;
    let firstErroredEvidence = true;
    let resultNotCompliesAndObvervationsValidation = true;

    for (let index = 0; index < commonFormat.questions.length; index += 1) {
      const radioNC: any = document.getElementById(`radio-${index}`);
      const textareaNC: any = document.getElementById(`textarea-${index}`);
      const evidenceNC: any = document.getElementById(`evidence-${index}`);
      const evidenceAlertNC: any = document.getElementById(`evidence-alert-${index}`);

      if (radioNC && textareaNC) {
        if (radioNC.checked && textareaNC.value === '') {
          textareaNC.classList.add('table-content__row__cell-text-area__text-area--error');
          textareaNC.placeholder = 'Si el inciso no cumple, las observaciones son obligatorias';

          if (firstErroredRadioButton) {
            radioNC.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            firstErroredRadioButton = false;
          }

          resultNotCompliesAndObvervationsValidation = false;
        } else {
          textareaNC.classList.remove('table-content__row__cell-text-area__text-area--error');
          textareaNC.placeholder = 'Observaciones';
        }
      }

      if (evidenceNC && evidenceAlertNC) {
        if (commonFormat.questions[index].haveEvidence === true && commonFormat.questions[index].evidence === '') {
          if (firstErroredEvidence) {
            evidenceAlertNC.innerText = 'Se debe subir un archivo de evidencia';
            evidenceNC.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
            firstErroredEvidence = false;
          }

          resultNotCompliesAndObvervationsValidation = false;
        }
      }
    }

    if (resultNotCompliesAndObvervationsValidation === false) {
      return false;
    }

    if (!showObservations
      && commonFormat.generalObservations === ''
      && inspection.status === 'in-progress'
      && roundID === inspection.rounds[inspection.rounds.length - 1].id) {
      setShowObservations(true);

      Modal.fireWarning(t('inspections.errors.makeComments'), () => Modal.close());

      return false;
    }

    return true;
  };

  const setSectionToSave = () => {
    if (inspection.norm === '004') {
      return norm004Section;
    }
    if (inspection.norm === '020') {
      return norm020Section;
    }
    if (inspection.norm === '003' || NOM003Complement) {
      return norm003Section;
    }
    if (inspection.norm === '173') {
      return norm173Section;
    }

    return '';
  };

  const getAnswers = (norm: NormCodes) => {
    const answersData: { [name: string]: string } = {};

    const answersByNorm: { [name: string]: string[] } = {
      '003': [
        'nom-050-result-1:textContent',
        'nom-050-result-2:textContent',
        'question-operation-manual-select:value',
        'question-warranty-select:value',
        'question-38-select:value'
      ],
      '004': [],
      '015': [
        'question-48-select:value'
      ],
      '020': [
        'question-48-select:value'
      ],
      '024': [
        'question-48-select:value'
      ],
      '050': [
        'nom-050-result-1:textContent',
        'nom-050-result-2:textContent',
        'question-operation-manual-select:value',
        'question-warranty-select:value',
        'question-38-select:value'
      ],
      '051': [
        'question-17-select:value',
        'question-30-select:value',
        'question-38-select:value',
        'question-7-cell-1:textContent',
        'question-7-cell-2:textContent',
        'question-7-cell-3:textContent',
        'question-7-cell-4:textContent',
        'question-7-cell-5:textContent',
        'question-8-cell-1:textContent',
        'question-8-cell-2:textContent',
        'question-45-cell-1:textContent',
        'question-45-cell-2:textContent',
        'question-45-cell-3:textContent',
        'question-45-cell-4:textContent',
        'question-45-cell-5:textContent',
        'question-48-select:value',
        'require-preparation:value',
        'total-mix:value',
        'product-portion:value',
        'Current portion:value',
        'proteins-input:value',
        'result-2:textContent',
        'totalFat-input:value',
        'result-3:textContent',
        'saturatedFat-input:value',
        'result-4:textContent',
        'transFat-input:value',
        'result-5:textContent',
        'carbohydrates-input:value',
        'result-6:textContent',
        'sugars-input:value',
        'result-7:textContent',
        'sugarsAdded-input:value',
        'result-8:textContent',
        'fiber-input:value',
        'result-9:textContent',
        'sodium-input:value',
        'result-10:textContent',
        'result-1-table-2:textContent',
        'result-2-table-2:textContent',
        'result-3-table-2:textContent',
        'result-4-table-2:textContent',
        'result-5-table-2:textContent',
        'result-6-table-2:textContent',
        'result-7-table-2:textContent',
        'result-8-table-2:textContent',
        'result-9-table-2:textContent',
        'result-10-table-2:textContent',
        'result-11-table-2:textContent',
        'declaration:innerHTML',
        'question-87-result:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos:innerHTML',
        'ubicación-de-los-sellos:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos-2:innerHTML',
        'ubicación-de-los-sellos-2:textContent',
        'imagenes-sellos-3:innerHTML',
        'ubicación-de-los-sellos-3:textContent',
        'edulcorantes-select:value',
        'cafeina-select:value',
        'strips-images-1:innerHTML',
        'strips-images-2:innerHTML',
        'measures-images-1:textContent',
        'measures-images-2:textContent',
        'EXCESO CALORIAS:textContent',
        'EXCESO AZUCARES:textContent',
        'EXCESO GRASAS SATURADAS:textContent',
        'EXCESO GRASAS TRANS:textContent',
        'EXCESO SODIO:textContent',
        'EDULCORANTES:textContent',
        'CAFEINA:textContent',
        'Por lo tanto:textContent',
        'input-1-question-135:value',
        'select-2-question-135:value',
        'select-3-question-135:value',
        'input-4-question-135:value',
        'result-1-question-135:textContent',
        'result-2-question-135:textContent',
        'input-5-question-135:value',
        'select-6-question-135:value',
        'select-7-question-135:value',
        'input-8-question-135:value',
        'result-3-question-135:textContent',
        'result-4-question-135:textContent',
        'input-9-question-135:value',
        'select-10-question-135:value',
        'select-11-question-135:value',
        'input-12-question-135:value',
        'result-5-question-135:textContent',
        'result-6-question-135:textContent',
        'strips-images-1-question-141:innerHTML',
        'measures-images-1-question-141:textContent',
        'strips-images-2-question-142:innerHTML',
        'measures-images-2-question-142:textContent',
        'select-leyenda-question-144:value',
        'select-superficie-leyenda-question-144:value',
        'input-medida-leyenda-question-144:value',
        'result-question-144:textContent',
        'question-145-select:value',
        'result-question-145:textContent',
        'article-216-select:value',
        'leyend-216:textContent'
      ],
      141: [
        'question-48-select:value',
        'nom-050-result-1:textContent',
        'nom-050-result-2:textContent'
      ],
      142: [
        'question-48-select:value',
        'tamaño-minimo:textContent',
        'question-7-cell-1:textContent',
        'question-7-cell-2:textContent',
        'question-7-cell-3:textContent',
        'question-7-cell-4:textContent',
        'question-7-cell-5:textContent'
      ],
      173: [
        'question-17-select:value',
        'question-30-select:value',
        'question-38-select:value',
        'question-7-cell-1:textContent',
        'question-7-cell-2:textContent',
        'question-7-cell-3:textContent',
        'question-7-cell-4:textContent',
        'question-7-cell-5:textContent',
        'question-8-cell-1:textContent',
        'question-8-cell-2:textContent',
        'question-45-cell-1:textContent',
        'question-45-cell-2:textContent',
        'question-45-cell-3:textContent',
        'question-45-cell-4:textContent',
        'question-45-cell-5:textContent',
        'question-48-select:value',
        'require-preparation:value',
        'total-mix:value',
        'product-portion:value',
        'Current portion:value',
        'proteins-input:value',
        'result-2:textContent',
        'totalFat-input:value',
        'result-3:textContent',
        'saturatedFat-input:value',
        'result-4:textContent',
        'transFat-input:value',
        'result-5:textContent',
        'carbohydrates-input:value',
        'result-6:textContent',
        'sugars-input:value',
        'result-7:textContent',
        'sugarsAdded-input:value',
        'result-8:textContent',
        'fiber-input:value',
        'result-9:textContent',
        'sodium-input:value',
        'result-10:textContent',
        'result-1-table-2:textContent',
        'result-2-table-2:textContent',
        'result-3-table-2:textContent',
        'result-4-table-2:textContent',
        'result-5-table-2:textContent',
        'result-6-table-2:textContent',
        'result-7-table-2:textContent',
        'result-8-table-2:textContent',
        'result-9-table-2:textContent',
        'result-10-table-2:textContent',
        'result-11-table-2:textContent',
        'declaration:innerHTML',
        'question-87-result:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos:innerHTML',
        'ubicación-de-los-sellos:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos-2:innerHTML',
        'ubicación-de-los-sellos-2:textContent',
        'imagenes-sellos-3:innerHTML',
        'ubicación-de-los-sellos-3:textContent',
        'edulcorantes-select:value',
        'cafeina-select:value',
        'strips-images-1:innerHTML',
        'strips-images-2:innerHTML',
        'measures-images-1:textContent',
        'measures-images-2:textContent',
        'EXCESO CALORIAS:textContent',
        'EXCESO AZUCARES:textContent',
        'EXCESO GRASAS SATURADAS:textContent',
        'EXCESO GRASAS TRANS:textContent',
        'EXCESO SODIO:textContent',
        'EDULCORANTES:textContent',
        'CAFEINA:textContent',
        'Por lo tanto:textContent',
        'input-1-question-135:value',
        'select-2-question-135:value',
        'select-3-question-135:value',
        'input-4-question-135:value',
        'result-1-question-135:textContent',
        'result-2-question-135:textContent',
        'input-5-question-135:value',
        'select-6-question-135:value',
        'select-7-question-135:value',
        'input-8-question-135:value',
        'result-3-question-135:textContent',
        'result-4-question-135:textContent',
        'input-9-question-135:value',
        'select-10-question-135:value',
        'select-11-question-135:value',
        'input-12-question-135:value',
        'result-5-question-135:textContent',
        'result-6-question-135:textContent',
        'strips-images-1-question-141:innerHTML',
        'measures-images-1-question-141:textContent',
        'strips-images-2-question-142:innerHTML',
        'measures-images-2-question-142:textContent',
        'select-leyenda-question-144:value',
        'select-superficie-leyenda-question-144:value',
        'input-medida-leyenda-question-144:value',
        'result-question-144:textContent',
        'question-145-select:value',
        'result-question-145:textContent',
        'article-216-select:value',
        'leyend-216:textContent'
      ],
      187: [
        'question-17-select:value',
        'question-30-select:value',
        'question-38-select:value',
        'question-7-cell-1:textContent',
        'question-7-cell-2:textContent',
        'question-7-cell-3:textContent',
        'question-7-cell-4:textContent',
        'question-7-cell-5:textContent',
        'question-8-cell-1:textContent',
        'question-8-cell-2:textContent',
        'question-45-cell-1:textContent',
        'question-45-cell-2:textContent',
        'question-45-cell-3:textContent',
        'question-45-cell-4:textContent',
        'question-45-cell-5:textContent',
        'question-48-select:value',
        'require-preparation:value',
        'total-mix:value',
        'product-portion:value',
        'Current portion:value',
        'proteins-input:value',
        'result-2:textContent',
        'totalFat-input:value',
        'result-3:textContent',
        'saturatedFat-input:value',
        'result-4:textContent',
        'transFat-input:value',
        'result-5:textContent',
        'carbohydrates-input:value',
        'result-6:textContent',
        'sugars-input:value',
        'result-7:textContent',
        'sugarsAdded-input:value',
        'result-8:textContent',
        'fiber-input:value',
        'result-9:textContent',
        'sodium-input:value',
        'result-10:textContent',
        'result-1-table-2:textContent',
        'result-2-table-2:textContent',
        'result-3-table-2:textContent',
        'result-4-table-2:textContent',
        'result-5-table-2:textContent',
        'result-6-table-2:textContent',
        'result-7-table-2:textContent',
        'result-8-table-2:textContent',
        'result-9-table-2:textContent',
        'result-10-table-2:textContent',
        'result-11-table-2:textContent',
        'declaration:innerHTML',
        'question-87-result:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos:innerHTML',
        'ubicación-de-los-sellos:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos-2:innerHTML',
        'ubicación-de-los-sellos-2:textContent',
        'imagenes-sellos-3:innerHTML',
        'ubicación-de-los-sellos-3:textContent',
        'edulcorantes-select:value',
        'cafeina-select:value',
        'strips-images-1:innerHTML',
        'strips-images-2:innerHTML',
        'measures-images-1:textContent',
        'measures-images-2:textContent',
        'EXCESO CALORIAS:textContent',
        'EXCESO AZUCARES:textContent',
        'EXCESO GRASAS SATURADAS:textContent',
        'EXCESO GRASAS TRANS:textContent',
        'EXCESO SODIO:textContent',
        'EDULCORANTES:textContent',
        'CAFEINA:textContent',
        'Por lo tanto:textContent',
        'input-1-question-135:value',
        'select-2-question-135:value',
        'select-3-question-135:value',
        'input-4-question-135:value',
        'result-1-question-135:textContent',
        'result-2-question-135:textContent',
        'input-5-question-135:value',
        'select-6-question-135:value',
        'select-7-question-135:value',
        'input-8-question-135:value',
        'result-3-question-135:textContent',
        'result-4-question-135:textContent',
        'input-9-question-135:value',
        'select-10-question-135:value',
        'select-11-question-135:value',
        'input-12-question-135:value',
        'result-5-question-135:textContent',
        'result-6-question-135:textContent',
        'strips-images-1-question-141:innerHTML',
        'measures-images-1-question-141:textContent',
        'strips-images-2-question-142:innerHTML',
        'measures-images-2-question-142:textContent',
        'select-leyenda-question-144:value',
        'select-superficie-leyenda-question-144:value',
        'input-medida-leyenda-question-144:value',
        'result-question-144:textContent',
        'question-145-select:value',
        'result-question-145:textContent',
        'article-216-select:value',
        'leyend-216:textContent'
      ],
      189: [
        'question-48-select:value',
        'nom-050-result-1:textContent',
        'nom-050-result-2:textContent',
        'select-1-189:value',
        'select-2-189:value',
        'select-3-189:value'
      ],
      235: [
        'question-17-select:value',
        'question-30-select:value',
        'question-38-select:value',
        'question-7-cell-1:textContent',
        'question-7-cell-2:textContent',
        'question-7-cell-3:textContent',
        'question-7-cell-4:textContent',
        'question-7-cell-5:textContent',
        'question-8-cell-1:textContent',
        'question-8-cell-2:textContent',
        'question-45-cell-1:textContent',
        'question-45-cell-2:textContent',
        'question-45-cell-3:textContent',
        'question-45-cell-4:textContent',
        'question-45-cell-5:textContent',
        'question-48-select:value',
        'require-preparation:value',
        'total-mix:value',
        'product-portion:value',
        'Current portion:value',
        'proteins-input:value',
        'result-2:textContent',
        'totalFat-input:value',
        'result-3:textContent',
        'saturatedFat-input:value',
        'result-4:textContent',
        'transFat-input:value',
        'result-5:textContent',
        'carbohydrates-input:value',
        'result-6:textContent',
        'sugars-input:value',
        'result-7:textContent',
        'sugarsAdded-input:value',
        'result-8:textContent',
        'fiber-input:value',
        'result-9:textContent',
        'sodium-input:value',
        'result-10:textContent',
        'result-1-table-2:textContent',
        'result-2-table-2:textContent',
        'result-3-table-2:textContent',
        'result-4-table-2:textContent',
        'result-5-table-2:textContent',
        'result-6-table-2:textContent',
        'result-7-table-2:textContent',
        'result-8-table-2:textContent',
        'result-9-table-2:textContent',
        'result-10-table-2:textContent',
        'result-11-table-2:textContent',
        'declaration:innerHTML',
        'question-87-result:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos:innerHTML',
        'ubicación-de-los-sellos:textContent',
        'azucares-select:value',
        'grasas-select:value',
        'sodio-select:value',
        'imagenes-sellos-2:innerHTML',
        'ubicación-de-los-sellos-2:textContent',
        'imagenes-sellos-3:innerHTML',
        'ubicación-de-los-sellos-3:textContent',
        'edulcorantes-select:value',
        'cafeina-select:value',
        'strips-images-1:innerHTML',
        'strips-images-2:innerHTML',
        'measures-images-1:textContent',
        'measures-images-2:textContent',
        'EXCESO CALORIAS:textContent',
        'EXCESO AZUCARES:textContent',
        'EXCESO GRASAS SATURADAS:textContent',
        'EXCESO GRASAS TRANS:textContent',
        'EXCESO SODIO:textContent',
        'EDULCORANTES:textContent',
        'CAFEINA:textContent',
        'Por lo tanto:textContent',
        'input-1-question-135:value',
        'select-2-question-135:value',
        'select-3-question-135:value',
        'input-4-question-135:value',
        'result-1-question-135:textContent',
        'result-2-question-135:textContent',
        'input-5-question-135:value',
        'select-6-question-135:value',
        'select-7-question-135:value',
        'input-8-question-135:value',
        'result-3-question-135:textContent',
        'result-4-question-135:textContent',
        'input-9-question-135:value',
        'select-10-question-135:value',
        'select-11-question-135:value',
        'input-12-question-135:value',
        'result-5-question-135:textContent',
        'result-6-question-135:textContent',
        'strips-images-1-question-141:innerHTML',
        'measures-images-1-question-141:textContent',
        'strips-images-2-question-142:innerHTML',
        'measures-images-2-question-142:textContent',
        'select-leyenda-question-144:value',
        'select-superficie-leyenda-question-144:value',
        'input-medida-leyenda-question-144:value',
        'result-question-144:textContent',
        'question-145-select:value',
        'result-question-145:textContent',
        'article-216-select:value',
        'leyend-216:textContent'
      ]
    };

    if (NOM003Complement) {
      const dataAnswers = [
        'nom-050-result-1:textContent',
        'nom-050-result-2:textContent',
        'question-operation-manual-select:value',
        'question-warranty-select:value',
        'question-38-select:value',
        'nom-050-result-1-003:textContent',
        'nom-050-result-2-003:textContent',
        'question-operation-manual-select-003:value',
        'question-warranty-select-003:value',
        'question-38-select-003:value'
      ];

      for (let index = 0; index < dataAnswers.length; index += 1) {
        const data = dataAnswers[index].split(':');

        if (data[1] === 'value') {
          const element = document.getElementById(data[0]) as HTMLInputElement | HTMLSelectElement;

          if (element) {
            answersData[dataAnswers[index]] = element.value;
          }
        }
        if (data[1] === 'textContent') {
          const element = document.getElementById(data[0]);

          if (element) {
            answersData[dataAnswers[index]] = `${element.textContent}`;
          }
        }
        if (data[1] === 'innerHTML') {
          const element = document.getElementById(data[0]);

          if (element) {
            answersData[dataAnswers[index]] = element.innerHTML;
          }
        }
      }
    } else {
      for (let index = 0; index < answersByNorm[norm].length; index += 1) {
        const data = answersByNorm[norm][index].split(':');

        if (data[1] === 'value') {
          const element = document.getElementById(data[0]) as HTMLInputElement | HTMLSelectElement;

          if (element) {
            answersData[answersByNorm[norm][index]] = element.value;
          }
        }
        if (data[1] === 'textContent') {
          const element = document.getElementById(data[0]);

          if (element) {
            answersData[answersByNorm[norm][index]] = `${element.textContent}`;
          }
        }
        if (data[1] === 'innerHTML') {
          const element = document.getElementById(data[0]);

          if (element) {
            answersData[answersByNorm[norm][index]] = element.innerHTML;
          }
        }
      }
    }

    return answersData;
  };

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const saveInspectionList = async () => {
    if (isInspectionListvalid()) {
      if (commonFormat.sign === undefined || commonFormat.sign === null || commonFormat.sign?.length === 0) {
        if (sigCanvas.current.isEmpty()) {
          const element = document.getElementById('signature');

          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }

          Modal.fireError(t('global.emptyInspectorSign'), undefined, soundEffects);

          return;
        }

        const signatureData = dataURLtoBlob(sigCanvas.current.toDataURL('image/png'));

        const uploadedPaths: string[] = [];

        await registerUploadedFiles(
          `/admin/inspections/${inspection.id}/inspection-list-sign`,
          {
            filePaths: [{
              path: `${inspection.inspector?.name}-sign.png`,
              type: 'image/png'
            }]
          },
          async (data) => {
            let errorMessage: string = '';

            Modal.fireLoading(undefined, 0);
            try {
              // eslint-disable-next-line array-callback-return, consistent-return
              await Promise.all(data.map((item) => {
                const formData = new FormData();

                Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                  formData.append(key, value as string);
                });

                formData.append('file', signatureData);

                return axios.post(
                  item.signedURL.url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                      const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                      Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                    }
                  }
                ).then(() => {
                  Modal.close();
                  uploadedPaths.push(item.url);
                });
              }));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              errorMessage = t('global.errorUploadingFile');
            } finally {
              if (errorMessage === '') {
                Modal.close();
              } else {
                Modal.fireError(errorMessage, setOpenModal, soundEffects);
              }
            }

            if (uploadedPaths.length === 1) {
              saveAllInspectionListData(uploadedPaths[0]);
            }
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else {
        saveAllInspectionListData(commonFormat.sign);
      }
    }
  };

  const saveAllInspectionListData = (signPath: string) => {
    let questionsNotAnsweredQuantity = 0;

    for (let index = 0; index < commonFormat.questions.length; index += 1) {
      if (commonFormat.questions[index].answer === 0
        || commonFormat.questions[index].answer === 'title') {
        questionsNotAnsweredQuantity += 1;
      }
    }

    const questionsNotAnswered = questionsNotAnsweredQuantity === commonFormat.questions.length;

    const requestData: any = {
      roundID,
      questions: questionsNotAnswered
        ? commonFormat.questions.map((_item) => {
          return {
            observations: '',
            answer: 0
          };
        })
        : commonFormat.questions.map((item) => {
          if (item.answer === 0) {
            return {
              ...item,
              answer: null
            };
          }
          if (item.answer === 'title') {
            return {
              ...item,
              answer: 'title'
            };
          }

          return item;
        }),
      generalObservations: commonFormat.generalObservations,
      version: inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion || configuration.inspectionsListsVersion,
      section: setSectionToSave(),
      answers: getAnswers(inspection.norm),
      sign: signPath
    };

    if (hasMeasurementEquipment) {
      requestData.measurementEquipment = commonFormat.measurementEquipment;
    }

    if (commonFormat.packing) {
      commonFormat.packing.height = Number(commonFormat.packing.height);
      commonFormat.packing.width = Number(commonFormat.packing.width);
      commonFormat.packing.radius = Number(commonFormat.packing.radius);

      requestData.packing = {};
      requestData.packing.type = commonFormat.packing.type;
      requestData.packing.height = commonFormat.packing.height;
      requestData.packing.width = commonFormat.packing.width;
      requestData.packing.radius = commonFormat.packing.radius;
      requestData.packing.content = commonFormat.packing.content;
      requestData.packing.productType = commonFormat.packing.productType;
    }

    if (commonFormat.sampling) {
      commonFormat.sampling.lot = Number(commonFormat.sampling.lot);
      commonFormat.sampling.sample = sampling;
      commonFormat.sampling.rejected = Number(commonFormat.sampling.rejected);
      commonFormat.sampling.accepted = Number(commonFormat.sampling.accepted);

      requestData.sampling = {};
      requestData.sampling.lot = commonFormat.sampling.lot;
      requestData.sampling.sample = commonFormat.sampling.sample;
      requestData.sampling.accepted = commonFormat.sampling.accepted;
      requestData.sampling.rejected = commonFormat.sampling.rejected;
      requestData.sampling.comments = commonFormat.sampling.comments || '';
    }

    createResource(
      `/admin/inspections/${inspection.id}/list`,
      requestData,
      (data2) => {
        setOnFinish(true);
        Modal.fireSuccess(
          t('global.correct'),
          t('inspections.successList'),
          setOpenModal,
          () => {
            if (onFetchInspection) {
              setNotReFetchInspection(false);
              onFetchInspection(data2);
            }

            setCommonFormat({
              inspectorName: '',
              measurementEquipment: 'none',
              clientBusinessName: '',
              productDescription: '',
              complies: false,
              generalObservations: '',
              questions: [],
              packing: {
                type: 'none',
                height: null,
                width: null,
                radius: null,
                content: 0,
                productType: 'none'
              },
              sampling: null,
              model: '',
              section: '',
              answers: {},
              sign: null
            });

            if (userRole === 'trainee') {
              navigate('/binnacle');
            } else {
              navigate(`/inspection/detail/${inspection.id}/none/none/none`);
            }
          },
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const savePartialInspectionList = async () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('global.partialSaveInspectionList'),
      async () => {
        if (sigCanvas.current === undefined || sigCanvas.current.isEmpty()) {
          savePartialData(null);
        } else {
          const signatureData = dataURLtoBlob(sigCanvas.current.toDataURL('image/png'));

          const uploadedPaths: string[] = [];

          await registerUploadedFiles(
            `/admin/inspections/${inspection.id}/inspection-list-sign`,
            {
              filePaths: [{
                path: `${inspection.inspector?.name}-sign.png`,
                type: 'image/png'
              }]
            },
            async (data) => {
              let errorMessage: string = '';

              Modal.fireLoading(undefined, 0);
              try {
                // eslint-disable-next-line array-callback-return, consistent-return
                await Promise.all(data.map((item) => {
                  const formData = new FormData();

                  Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                    formData.append(key, value as string);
                  });

                  formData.append('file', signatureData);

                  return axios.post(
                    item.signedURL.url,
                    formData,
                    {
                      headers: {
                        'Content-Type': 'multipart/form-data'
                      },
                      onUploadProgress: (progressEvent) => {
                        const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                        Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                      }
                    }
                  ).then(() => {
                    Modal.close();
                    uploadedPaths.push(item.url);
                  });
                }));
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                errorMessage = t('global.errorUploadingFile');
              } finally {
                if (errorMessage === '') {
                  Modal.close();
                } else {
                  Modal.fireError(errorMessage, setOpenModal, soundEffects);
                }
              }

              if (uploadedPaths.length === 1) {
                savePartialData(uploadedPaths[0]);
              }
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const savePartialData = (signPath: string | null) => {
    const requestData: any = {
      roundID,
      questions: commonFormat.questions.map((item) => item),
      generalObservations: commonFormat.generalObservations,
      version: inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion || configuration.inspectionsListsVersion,
      section: setSectionToSave(),
      answers: getAnswers(inspection.norm),
      sign: signPath
    };

    if (hasMeasurementEquipment) {
      requestData.measurementEquipment = commonFormat.measurementEquipment;
    }

    if (commonFormat.packing) {
      commonFormat.packing.height = Number(commonFormat.packing.height);
      commonFormat.packing.width = Number(commonFormat.packing.width);
      commonFormat.packing.radius = Number(commonFormat.packing.radius);

      requestData.packing = {};
      requestData.packing.type = commonFormat.packing.type;
      requestData.packing.height = commonFormat.packing.height;
      requestData.packing.width = commonFormat.packing.width;
      requestData.packing.radius = commonFormat.packing.radius;
      requestData.packing.content = commonFormat.packing.content;
      requestData.packing.productType = commonFormat.packing.productType;
    }

    if (commonFormat.sampling) {
      commonFormat.sampling.lot = Number(commonFormat.sampling.lot);
      commonFormat.sampling.sample = sampling;
      commonFormat.sampling.rejected = Number(commonFormat.sampling.rejected);
      commonFormat.sampling.accepted = Number(commonFormat.sampling.accepted);

      requestData.sampling = {};
      requestData.sampling.lot = commonFormat.sampling.lot;
      requestData.sampling.sample = commonFormat.sampling.sample;
      requestData.sampling.accepted = commonFormat.sampling.accepted;
      requestData.sampling.rejected = commonFormat.sampling.rejected;
      requestData.sampling.comments = commonFormat.sampling.comments || '';
    }

    createResource(
      `/admin/inspections/${inspection.id}/list-partial-save`,
      requestData,
      (data) => {
        Modal.fireSuccess(
          t('global.correct'),
          t('inspections.successPartialList'),
          setOpenModal,
          () => {
            if (onFetchInspection) {
              setNotReFetchInspection(false);
              onFetchInspection(data);
            }

            setCommonFormat({
              inspectorName: '',
              measurementEquipment: 'none',
              clientBusinessName: '',
              productDescription: '',
              complies: false,
              generalObservations: '',
              questions: [],
              packing: {
                type: 'none',
                height: null,
                width: null,
                radius: null,
                content: 0,
                productType: 'none'
              },
              sampling: null,
              model: '',
              section: '',
              answers: {},
              sign: null
            });

            if (userRole === 'trainee') {
              navigate('/binnacle');
            } else {
              navigate(`/inspection/detail/${inspection.id}/none/none/none`);
            }
          },
          soundEffects
        );
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  // eslint-disable-next-line no-unused-vars
  const cleanInspectionList = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('inspections.cleanInspectionListAsk'),
      async () => {
        // eslint-disable-next-line array-callback-return
        await Promise.all(commonFormat.questions.map(async (_item, index) => {
          commonFormat.questions[index].observations = '';
          commonFormat.questions[index].answer = 0;

          for (let x = 0; x < questionsNumber; x += 1) {
            const textArea = document.getElementById(`textarea-${x}`) as HTMLTextAreaElement;

            if (textArea) {
              textArea.value = '';
            }
          }

          if (commonFormat.questions[index].evidence !== '') {
            await eraseResource(
              `/admin/inspections/${inspection.id}/list/${inspection.rounds.at(-1)?.id}/delete-list-evidence`,
              {
                s_documentPath: commonFormat.questions[index].evidence
              },
              (_data) => {
                commonFormat.questions[index].evidence = '';
                commonFormat.questions[index].haveEvidence = false;
              },
              (error: string) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        }));

        setCommonFormatState({ ...commonFormat });

        Modal.close();
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    startTransition(() => {
      setPorcentOfQuestionsAnswered(commonFormat);
    });
  }, [commonFormat, commonFormat.questions]);

  const setCommonFormatState = (data: InspectionList) => {
    setCommonFormat(data);

    startTransition(() => {
      setPorcentOfQuestionsAnswered(data);
    });
  };

  const setPorcentOfQuestionsAnswered = (_commonFormat: InspectionList) => {
    if (_commonFormat && _commonFormat.questions.length > 0) {
      let answeredQuestions = 0;
      let auxTotalQuestions = 0;

      for (let index = 0; index < _commonFormat.questions.length; index += 1) {
        if (
          _commonFormat.questions[index].answer !== 0
          && _commonFormat.questions[index].answer !== 'title'
        ) {
          answeredQuestions += 1;
        }

        if (_commonFormat.questions[index].answer !== 'title') {
          auxTotalQuestions += 1;
        }
      }

      let porcent: number = 0;

      if (!('inspectionListVersion' in inspection.rounds[inspection.rounds.length - 1])
        || inspection.rounds[inspection.rounds.length - 1].inspectionListVersion === undefined
        || (inspection.rounds[inspection.rounds.length - 1].inspectionListVersion || configuration.inspectionsListsVersion) === 1) {
        porcent = (answeredQuestions * 100) / (auxTotalQuestions - (['051', '173', '187'].includes(inspection.norm) ? 2 : 0));
      } else {
        porcent = (answeredQuestions * 100) / (auxTotalQuestions - (['051', '173', '187', '235', '142'].includes(inspection.norm) ? 1 : 0));
      }

      setProgressList(porcent > 100 ? 100 : porcent);
    }
  };

  const pageHeader = (
    <>
      <table className="table-head">
        <tbody>
          <tr className="table-head__row">
            <td rowSpan={2} className="table-head__row__col-1 table-head__row__col-1--transparent">
              <div className="table-head__row__col-1--center">
                <Icon type="CECSALogoColor" className="table-head__row__icon" />
              </div>
            </td>
            <td className="table-head__row__col-2">CECSA ENTERPRISE SOLUTIONS SC</td>
            <td className="table-head__row__col-3">Código: {documentCode}</td>
          </tr>
          <tr className="table-head__row">
            <td className="table-head__row__col-2">{documentDescription}</td>
            <td className="table-head__row__col-3">Emisión: {documentIssueDate}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
    </>
  );

  const setPagesInformation = () => {
    let pagesNumber = 1;

    const _pages: ReactElement[] = [];

    questions.forEach((question) => {
      if (question.pageNumber > pagesNumber) {
        pagesNumber = question.pageNumber;
      }
    });

    setQuestionsNumber(questions.at(-1)!.questionNumber);

    for (let i = 2; i <= pagesNumber; i += 1) {
      let currentQuestions: CommonInspectionFormatQuestion[] = [];

      currentQuestions = questions.filter((question) => {
        const matchesPageNumber = question.pageNumber === i;
        const matchesFase = 'fase' in question ? question.fase === inspection.fase : true;
        let matchSectionNOM004 = true;
        let matchSectionNOM020 = true;
        let matchSectionNOM003 = true;
        let matchSectionNOM173 = true;

        if (!disabled) {
          if (inspection.norm === '004') {
            matchSectionNOM004 = 'section' in question ? question.section === norm004Section : true;
          }
          if (inspection.norm === '020') {
            matchSectionNOM020 = 'section' in question ? question.section === norm020Section : true;
          }
          if (inspection.norm === '003' || NOM003Complement) {
            matchSectionNOM003 = 'section' in question ? question.section === norm003Section : true;
          }
          if (inspection.norm === '173') {
            matchSectionNOM173 = 'section' in question ? question.section === norm173Section : true;
          }
        }
        return matchesPageNumber && matchesFase && matchSectionNOM004 && matchSectionNOM020 && matchSectionNOM003 && matchSectionNOM173;
      });

      if (currentQuestions.length > 0) {
        _pages.push(
          (
            <div
              id={`page${i}`}
              key={i}
              className={'inspection-list__page'}
            >
              {
                disabled && (
                  pageHeader
                )
              }
              <table className={disabled ? 'table-content table-content--disabled' : 'table-content'}>
                <thead className="table-thead">
                  <tr className="table-content__row">
                    <td className="table-content__row__cell table-content__row__cell--criterion">CRITERIO</td>
                    <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`}>C</td>
                    <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`}>NC</td>
                    <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`}>NA</td>
                    <td className="table-content__row__cell table-content__row__cell--observations">Observaciones</td>
                    <td className="table-content__row__cell table-content__row__cell--evidence">Evidencia</td>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {
                    currentQuestions.map((question) => (
                      <tr className="table-content__row" key={question.questionNumber}>
                        <td
                          className="table-content__row__cell table-content__row__cell--question"
                          colSpan={question.isATitle ? 6 : 1}
                        >
                          {question.text}
                        </td>
                        {!question.isATitle && radioButtonsAndtextAreaRow(question.questionNumber)}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          )
        );
      }
    }

    setPages(_pages);
  };

  const handleUploadEvidence = (paramFiles: FileInputData, questionNumber: number) => {
    registerUploadedFiles(
      `/admin/inspections/${inspection.id}/list/${inspection.rounds.at(-1)?.id}/${questionNumber}/upload-list-evidence`,
      {
        filePaths: [{
          path: paramFiles.fileName.replace(/[%&?¿=#/+]/g, ''),
          type: paramFiles.data.type
        }]
      },
      async (data) => {
        let uploadedPath: string = '';
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            formData.append('file', paramFiles.data);

            return axios.post(
              item.signedURL.url,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                  const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                  Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                }
              }
            ).then(() => {
              Modal.close();
              uploadedPath = item.url;
            });
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPath) {
          commonFormat.questions[questionNumber].evidence = uploadedPath;
          setCommonFormatState({ ...commonFormat });
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleDownloadCommentFile = async (filePath: string, documentName: string) => {
    Modal.fireLoading();
    try {
      const response = await fetch(filePath);

      if (!response.ok) {
        throw new Error(`${t('global.alertMessage')}: ${response.status}`);
      }

      const blob = await response.blob();
      saveAs(blob, documentName);

      Modal.close();
    } catch (error) {
      Modal.fireError(`${error}`, undefined, soundEffects);
    }
  };

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `admin/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const handleDeleteFile = (documentPath: string, questionNumber: number) => {
    eraseResource(
      `/admin/inspections/${inspection.id}/list/${inspection.rounds.at(-1)?.id}/delete-list-evidence`,
      {
        s_documentPath: documentPath
      },
      (_data) => {
        commonFormat.questions[questionNumber].evidence = '';
        setCommonFormatState({ ...commonFormat });
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const updateCommonFormatObvervations = (questionNumber: number) => {
    const textArea = document.getElementById(`textarea-${questionNumber}`) as HTMLTextAreaElement;

    commonFormat.questions[questionNumber].observations = textArea.value;
    setCommonFormatState({ ...commonFormat });
  };

  const convertTextToBold = (text: string) => {
    const splited = text.split('*');
    const component: ReactElement[] = [];

    if (splited.length > 1) {
      let bold: boolean = false;
      for (let index = 0; index < splited.length; index += 1) {
        if (bold) {
          component.push(<b>{splited[index]}</b>);
          bold = false;
        } else {
          component.push(<>{splited[index]}</>);
          bold = true;
        }
      }
    }

    return text.replace(/\*(.*?)\*/g, '<b>$1</b>').replace(/\n/g, '<br>');
  };

  const radioButtonsAndtextAreaRow = useCallback((questionNumber: number) => {
    return (
      <>
        <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`} key={`q-${questionNumber}`} id={`q-${questionNumber}`}>
          <div className="radio-container">
            <div></div>
            <label className={disabled ? 'radio-container__radio radio-container__radio--disabled' : 'radio-container__radio'}>
              {
                disabled ? (
                  <div className="table-head__row__col-1--center">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === true ? 'X' : ''
                        : ''
                    }
                  </div>
                ) : (
                  <>
                    <input
                      type="radio"
                      disabled={disabled}
                      onChange={() => {
                        commonFormat.questions[questionNumber].answer = true;
                        commonFormat.questions[questionNumber].haveEvidence = false;
                        setCommonFormatState({ ...commonFormat });
                      }}
                      checked={commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === true
                        : false
                      }
                    />
                    <span className='radio-container__radio__check'></span>
                  </>
                )
              }
            </label>
          </div>
        </td>
        <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`}>
          <div className="radio-container">
            <div></div>
            <label className={disabled ? 'radio-container__radio radio-container__radio--disabled' : 'radio-container__radio'}>
              {
                disabled ? (
                  <div className="table-head__row__col-1--center">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === false ? 'X' : ''
                        : ''
                    }
                  </div>
                ) : (
                  <>
                    <input
                      type="radio"
                      disabled={disabled}
                      id={`radio-${questionNumber}`}
                      onChange={() => {
                        commonFormat.questions[questionNumber].answer = false;
                        commonFormat.questions[questionNumber].haveEvidence = true;
                        setCommonFormatState({ ...commonFormat });
                      }}
                      checked={commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === false
                        : false
                      }
                    />
                    <span className='radio-container__radio__check'></span>
                  </>
                )
              }
            </label>
          </div>
        </td>
        <td className={`table-content__row__cell table-content__row__cell--response table-content__row__cell${disabled ? '--disabled' : ''}`}>
          <div className="radio-container">
            <div></div>
            <label className={disabled ? 'radio-container__radio radio-container__radio--disabled' : 'radio-container__radio'}>
              {
                disabled ? (
                  <div className="table-head__row__col-1--center">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === null ? 'X' : ''
                        : ''
                    }
                  </div>
                ) : (
                  <>
                    <input
                      type="radio"
                      disabled={disabled}
                      onChange={() => {
                        commonFormat.questions[questionNumber].answer = null;
                        setCommonFormatState({ ...commonFormat });
                      }}
                      checked={commonFormat.questions[questionNumber] !== undefined
                        ? commonFormat.questions[questionNumber].answer === null
                        : false
                      }
                    />
                    <span className='radio-container__radio__check'></span>
                  </>
                )
              }
            </label>
          </div>
        </td>
        <td className='table-content__row__cell-text-area'>
          {
            disabled ? (
              <div
                className="table-head__row__col-1--center"
              >
                <p dangerouslySetInnerHTML={{ __html: convertTextToBold(commonFormat.questions && commonFormat.questions[questionNumber] ? commonFormat.questions[questionNumber].observations || '' : '') }}></p>
              </div>
            ) : (
              <>
                {
                  lastRoundQuestions !== undefined && lastRoundQuestions.length > 0 && lastRoundQuestions[questionNumber].answer === false && (
                    <ToolTip text={lastRoundQuestions[questionNumber].observations} position='bottom' component={<div className='table-content__row__cell-text-area__tooltip-container'> <Icon type={'alertRed'} className='table-content__row__cell-text-area__tooltip' /> No cumplimento de ronda anterior</div>} />
                  )
                }
                <textarea
                  rows={5}
                  cols={40}
                  id={`textarea-${questionNumber}`}
                  className={`table-content__row__cell-text-area__text-area ${[0, 1, 2, 3].includes(questionNumber) ? 'table-content__row__cell-text-area__text-area--sizing-animation' : ''}`}
                  placeholder="Observaciones"
                  disabled={disabled}
                  onBlur={() => updateCommonFormatObvervations(questionNumber)}
                >
                </textarea>
              </>
            )
          }
        </td>
        <td className='table-content__row__cell-evidence' id={`evidence-${questionNumber}`}>
          {
            commonFormat.questions[questionNumber].answer === false && (
              <>
                {
                  commonFormat.questions[questionNumber].evidence === '' && !disabled && (
                    <b id={`evidence-alert-${questionNumber}`} className='table-content__row__cell-evidence__alert'></b>
                  )
                }
                {
                  commonFormat.questions[questionNumber].evidence === '' && !disabled && (
                    <div className='table-content__row__cell-evidence__switch'>
                      <Switch
                        leftLabel={{
                          text: 'No existe evidencia',
                          value: 1
                        }}
                        rigthLabel={{
                          text: 'Subir evidencia',
                          value: 2
                        }}
                        onChange={(_value: string | number) => {
                          commonFormat.questions[questionNumber].haveEvidence = _value === '2';
                          setCommonFormatState({ ...commonFormat });
                        }}
                        defaultChecked={!commonFormat.questions[questionNumber].haveEvidence}
                      />
                    </div>
                  )
                }
                {
                  commonFormat.questions[questionNumber].haveEvidence
                  && commonFormat.questions[questionNumber].evidence === ''
                  && !disabled && (
                    <FileInput
                      onSelectFile={(_file: FileInputData[]) => {
                        handleUploadEvidence(_file[0], questionNumber);
                      }}
                      acceptedFileFormats={'.jpg,.jpeg,.png,.JPG,.JPEG,.PNG,.svg,.SVG'}
                      isMultiple={false}
                    />
                  )
                }
                {
                  commonFormat.questions[questionNumber].evidence !== '' && (
                    <>
                      <SubTitle subTitle={t('services.evidence')} />
                      <FileElement
                        name={commonFormat.questions[questionNumber].evidence.split('/').pop() || ''}
                        onDelete={disabled ? undefined : () => {
                          handleDeleteFile(commonFormat.questions[questionNumber].evidence, questionNumber);
                        }}
                        onDownload={() => handleDownloadFile(commonFormat.questions[questionNumber].evidence, commonFormat.questions[questionNumber].evidence.split('/').pop() || '')}
                      />
                    </>
                  )
                }
              </>
            )
          }
        </td>
      </>
    );
  }, [commonFormat]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (['050', '003'].includes(inspection.norm) && NOM003Complement === false) {
      const handleStorageChange = (event: any) => {
        if (event.key === 'question-operation-manual-alert-text' || event.detail.key === 'question-operation-manual-alert-text') {
          const value: string = (event.newValue || event.detail.newValue);

          if (value === 'NO') {
            commonFormat.questions[18].answer = null;
            commonFormat.questions[19].answer = null;
            commonFormat.questions[20].answer = null;
            commonFormat.questions[21].answer = null;
            commonFormat.questions[22].answer = null;
            commonFormat.questions[23].answer = null;
            commonFormat.questions[24].answer = null;
            setCommonFormatState({ ...commonFormat });
          } else {
            commonFormat.questions[18].answer = 0;
            commonFormat.questions[19].answer = 0;
            commonFormat.questions[20].answer = 0;
            commonFormat.questions[21].answer = 0;
            commonFormat.questions[22].answer = 0;
            commonFormat.questions[23].answer = 0;
            commonFormat.questions[24].answer = 0;
            setCommonFormatState({ ...commonFormat });
          }
        }
        if (event.key === 'question-warranty-alert-text' || event.detail.key === 'question-warranty-alert-text') {
          const value: string = (event.newValue || event.detail.newValue);

          if (value === 'NO') {
            commonFormat.questions[26].answer = null;
            commonFormat.questions[27].answer = null;
            commonFormat.questions[28].answer = null;
            commonFormat.questions[29].answer = null;
            commonFormat.questions[30].answer = null;
            commonFormat.questions[31].answer = null;
            commonFormat.questions[32].answer = null;
            commonFormat.questions[33].answer = null;
            commonFormat.questions[34].answer = null;
            commonFormat.questions[35].answer = null;
            setCommonFormatState({ ...commonFormat });
          } else {
            commonFormat.questions[26].answer = 0;
            commonFormat.questions[27].answer = 0;
            commonFormat.questions[28].answer = 0;
            commonFormat.questions[29].answer = 0;
            commonFormat.questions[30].answer = 0;
            commonFormat.questions[31].answer = 0;
            commonFormat.questions[32].answer = 0;
            commonFormat.questions[33].answer = 0;
            commonFormat.questions[34].answer = 0;
            commonFormat.questions[35].answer = 0;
            setCommonFormatState({ ...commonFormat });
          }
        }
      };

      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('custom-storage-change', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        window.removeEventListener('custom-storage-change', handleStorageChange);
      };
    }
  }, [commonFormat, NOM003Complement]);

  useEffect(() => {
    if (disabled === false) {
      for (let index = 0; index < commonFormat.questions.length; index += 1) {
        commonFormat.questions[index].observations = commonFormat.questions[index].observations ? commonFormat.questions[index].observations : '';
      }
      setCommonFormatState({ ...commonFormat });
    }
  }, [disabled]);

  useEffect(() => {
    if (disabled === true && firstRender === false) {
      const inputs = document.querySelectorAll('input');
      const selects = document.querySelectorAll('select');

      for (let index = 0; index < inputs.length; index += 1) {
        if (inputs[index] instanceof HTMLInputElement) {
          inputs[index].disabled = true;
        }
      }
      for (let index = 0; index < selects.length; index += 1) {
        if (selects[index] instanceof HTMLSelectElement) {
          selects[index].disabled = true;
        }
      }
    }
  }, [firstRender, disabled]);

  useEffect(() => {
    if (firstRender) {
      setPages([<div className='loading-page'><Spinner /></div>]);

      setTimeout(() => {
        setPagesInformation();
        setFirstRender(false);

        updateMinimunSizedenomination();
      }, 2000);

      if (!disabled) {
        setTimeout(() => {
          utils.calculateNOM51();
        }, 2500);
      }
    } else {
      setPagesInformation();
    }
  }, [
    commonFormat,
    questions,
    disabled,
    norm004Section,
    norm020Section,
    norm173Section,
    norm003Section,
    client
  ]);

  useEffect(() => {
    if (hasSamplingTable && !commonFormat.sampling) {
      setCommonFormatState({
        ...commonFormat,
        sampling: {
          lot: 0,
          sample: 0,
          accepted: 0,
          rejected: 0,
          comments: ''
        }
      });
    }
  }, [commonFormat]);

  useEffect(() => {
    if (labelsToPut !== 0 && !isGrouped) {
      commonFormat.sampling = {
        lot: labelsToPut,
        sample: commonFormat.sampling?.sample || 0,
        accepted: commonFormat.sampling?.accepted || 0,
        rejected: commonFormat.sampling?.rejected || 0,
        comments: commonFormat.sampling?.comments || ''
      };
      setCommonFormatState({ ...commonFormat });
    }
  }, [labelsToPut]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const progressBar = document.getElementById('progress-bar');

    if (progressBar) {
      progressBar.style.width = `${progressList}%`;
    }
  }, [progressList]);

  useEffect(() => {
    const _lot = commonFormat.sampling?.lot;

    if (_lot && hasSamplingTable) {
      if (_lot >= 2 && _lot <= 8) {
        setSampling(2);
      }
      if (_lot >= 9 && _lot <= 15) {
        setSampling(2);
      }
      if (_lot >= 16 && _lot <= 25) {
        setSampling(3);
      }
      if (_lot >= 16 && _lot <= 25) {
        setSampling(3);
      }
      if (_lot >= 26 && _lot <= 50) {
        setSampling(5);
      }
      if (_lot >= 51 && _lot <= 90) {
        setSampling(5);
      }
      if (_lot >= 91 && _lot <= 150) {
        setSampling(8);
      }
      if (_lot >= 151 && _lot <= 280) {
        setSampling(13);
      }
      if (_lot >= 281 && _lot <= 500) {
        setSampling(20);
      }
      if (_lot >= 501 && _lot <= 1200) {
        setSampling(32);
      }
      if (_lot >= 1201 && _lot <= 3200) {
        setSampling(50);
      }
      if (_lot >= 3201 && _lot <= 10000) {
        setSampling(80);
      }
      if (_lot >= 10001 && _lot <= 35000) {
        setSampling(125);
      }
      if (_lot >= 35001) {
        setSampling(200);
      }
    } else {
      setSampling(1);
    }
  }, [commonFormat]);

  const setCompliesText = (): string => {
    const roundComplies = inspection.rounds.find((round) => round.id === roundID)?.complies;

    if (roundComplies === null) {
      return '';
    }

    if (roundComplies === true) {
      return 'Cumple';
    }

    return 'No cumple';
  };

  useEffect(() => {
    if (inspection.rounds.length > 0) {
      setDisableSamplingOrPacking(
        inspection.status !== 'in-progress'
        || Number(roundID) !== inspection.rounds[inspection.rounds.length - 1].id
      );
    }
  }, [inspection, roundID]);

  useEffect(() => {
    if (inspection.product.invoice) {
      fetchResources(
        {
          resourcePath: '/admin/inspections',
          filters: {
            s_productInvoice: inspection.product.invoice
          }
        },
        (data) => {
          let labelsToPutTotalaux = 0;
          let remainingGroupedProductsQuantity = 0;
          let auxRemainingLot = 0;
          let remainining: number = 0;

          for (let index = 0; index < data.items.length; index += 1) {
            const currentRound = utils.getCurrentRound(data.items[index].rounds);

            if ((currentRound.list === null
              || currentRound.list?.sampling === undefined
              || currentRound.list?.sampling?.lot === undefined
              || currentRound.pausePeriods.length >= 1
              || currentRound.steps.find((step) => step === 'list-completed') === undefined)
              && data.items[index].indicators.editInspection === false) {
              remainining += 1;
            } else if (currentRound.list?.sampling?.lot !== undefined) {
              auxRemainingLot += currentRound.list?.sampling?.lot;
            }

            if (currentRound.list?.sampling?.lot
              && data.items[index].rounds.at(-1)?.productNotPresented === false
              && inspection.id !== data.items[index].id) {
              labelsToPutTotalaux += currentRound.list?.sampling?.lot || 0;
            }

            if (inspection.id === data.items[index].id) {
              setLot(currentRound.list?.sampling?.lot || 0);
            }

            if (currentRound.list?.sampling === undefined
              && inspection.id !== data.items[index].id) {
              remainingGroupedProductsQuantity += 1;
            }
          }

          if (remainining === 1
            && inspection.indicators.editInspection === false
          ) {
            setRemainingLot(labelsToPut - auxRemainingLot);
          }

          setRemainigProducts(remainingGroupedProductsQuantity);
          setLabelsToPutTotal(labelsToPut - labelsToPutTotalaux);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [inspection, labelsToPut]);

  useEffect(() => {
    let auxSamplingInformation: SamplingInformation = samplingInputInfo;
    const ableLabelsToPut = isGrouped ? labelsToPutTotal - remainigProducts : labelsToPut;

    if (ableLabelsToPut > 0) {
      auxSamplingInformation = {
        maxValue: ableLabelsToPut,
        label: `Cantidad de piezas del Lote${disabled || disableSamplingOrPacking ? '' : `, máximo ${ableLabelsToPut.toLocaleString()}.`}`
      };
    }

    setSamplingInputInfo(auxSamplingInformation);
  }, [
    labelsToPut,
    disabled,
    disableSamplingOrPacking,
    inspection,
    labelsToPutTotal,
    remainigProducts,
    remainingLot,
    isGrouped
  ]);

  const handleChangeRange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (Number(event.target.value) <= samplingInputInfo.maxValue) {
      commonFormat.sampling = {
        lot: Number(event.target.value) || 0,
        sample: commonFormat.sampling?.sample || 0,
        accepted: commonFormat.sampling?.accepted || 0,
        rejected: commonFormat.sampling?.rejected || 0,
        comments: commonFormat.sampling?.comments || ''
      };
      setCommonFormatState({ ...commonFormat });
    }
  };

  useEffect(() => {
    if (location.pathname.includes('/inspection/inspection-list/')
      && utils.getCurrentRound(inspection.rounds).steps.find(element => element === 'list-completed') !== undefined) {
      navigate(-2);
    }
  }, [inspection]);

  useEffect(() => {
    setTimeout(() => {
      if (inspection.norm === '020') {
        const tableOnSite1 = document.getElementById('table-on-site-1-020-1');
        const tableOnSite2 = document.getElementById('table-on-site-2-020-2');
        const tableOnSite3 = document.getElementById('table-on-site-1-020-3');
        const tableOnSite4 = document.getElementById('table-on-site-2-020-4');
        const tableOnSite5 = document.getElementById('table-on-site-1-020-5');
        const tableOnSite6 = document.getElementById('table-on-site-2-020-6');
        const tableOnSite7 = document.getElementById('table-on-site-1-020-7');
        const tableOnSite8 = document.getElementById('table-on-site-2-020-8');
        const tableOnSite9 = document.getElementById('table-on-site-1-020-9');
        const tableOnSite10 = document.getElementById('table-on-site-2-020-10');

        if (!['DC', 'DN'].includes(inspection.service.code)) {
          if (tableOnSite1) {
            tableOnSite1.classList.add('display-none');
          }
          if (tableOnSite2) {
            tableOnSite2.classList.add('display-none');
          }
          if (tableOnSite3) {
            tableOnSite3.classList.add('display-none');
          }
          if (tableOnSite4) {
            tableOnSite4.classList.add('display-none');
          }
          if (tableOnSite5) {
            tableOnSite5.classList.add('display-none');
          }
          if (tableOnSite6) {
            tableOnSite6.classList.add('display-none');
          }
          if (tableOnSite7) {
            tableOnSite7.classList.add('display-none');
          }
          if (tableOnSite8) {
            tableOnSite8.classList.add('display-none');
          }
          if (tableOnSite9) {
            tableOnSite9.classList.add('display-none');
          }
          if (tableOnSite10) {
            tableOnSite10.classList.add('display-none');
          }
        } else {
          if (tableOnSite1) {
            if (tableOnSite1.classList.contains('display-none')) {
              tableOnSite1.classList.remove('display-none');
            }
          }
          if (tableOnSite2) {
            if (tableOnSite2.classList.contains('display-none')) {
              tableOnSite2.classList.remove('display-none');
            }
          }
          if (tableOnSite3) {
            if (tableOnSite3.classList.contains('display-none')) {
              tableOnSite3.classList.remove('display-none');
            }
          }
          if (tableOnSite4) {
            if (tableOnSite4.classList.contains('display-none')) {
              tableOnSite4.classList.remove('display-none');
            }
          }
          if (tableOnSite5) {
            if (tableOnSite5.classList.contains('display-none')) {
              tableOnSite5.classList.remove('display-none');
            }
          }
          if (tableOnSite6) {
            if (tableOnSite6.classList.contains('display-none')) {
              tableOnSite6.classList.remove('display-none');
            }
          }
          if (tableOnSite7) {
            if (tableOnSite7.classList.contains('display-none')) {
              tableOnSite7.classList.remove('display-none');
            }
          }
          if (tableOnSite8) {
            if (tableOnSite8.classList.contains('display-none')) {
              tableOnSite8.classList.remove('display-none');
            }
          }
          if (tableOnSite9) {
            if (tableOnSite9.classList.contains('display-none')) {
              tableOnSite9.classList.remove('display-none');
            }
          }
          if (tableOnSite10) {
            if (tableOnSite10.classList.contains('display-none')) {
              tableOnSite10.classList.remove('display-none');
            }
          }
        }
      } else {
        const tableOnSite1 = document.getElementById('table-on-site-1');
        const tableOnSite2 = document.getElementById('table-on-site-2');

        if (!['DC', 'DN'].includes(inspection.service.code)) {
          if (tableOnSite1) {
            tableOnSite1.classList.add('display-none');
          }
          if (tableOnSite2) {
            tableOnSite2.classList.add('display-none');
          }
        } else {
          if (tableOnSite1) {
            if (tableOnSite1.classList.contains('display-none')) {
              tableOnSite1.classList.remove('display-none');
            }
          }
          if (tableOnSite2) {
            if (tableOnSite2.classList.contains('display-none')) {
              tableOnSite2.classList.remove('display-none');
            }
          }
        }
      }
    }, 3000);
  }, [inspection]);

  useEffect(() => {
    setTimeout(() => {
      if (inspection.norm === '020') {
        const tableOnSite1 = document.getElementById('table-on-site-1-020-1');
        const tableOnSite2 = document.getElementById('table-on-site-2-020-2');
        const tableOnSite3 = document.getElementById('table-on-site-1-020-3');
        const tableOnSite4 = document.getElementById('table-on-site-2-020-4');
        const tableOnSite5 = document.getElementById('table-on-site-1-020-5');
        const tableOnSite6 = document.getElementById('table-on-site-2-020-6');
        const tableOnSite7 = document.getElementById('table-on-site-1-020-7');
        const tableOnSite8 = document.getElementById('table-on-site-2-020-8');
        const tableOnSite9 = document.getElementById('table-on-site-1-020-9');
        const tableOnSite10 = document.getElementById('table-on-site-2-020-10');

        if (!['DC', 'DN'].includes(inspection.service.code)) {
          if (tableOnSite1) {
            tableOnSite1.classList.add('display-none');
          }
          if (tableOnSite2) {
            tableOnSite2.classList.add('display-none');
          }
          if (tableOnSite3) {
            tableOnSite3.classList.add('display-none');
          }
          if (tableOnSite4) {
            tableOnSite4.classList.add('display-none');
          }
          if (tableOnSite5) {
            tableOnSite5.classList.add('display-none');
          }
          if (tableOnSite6) {
            tableOnSite6.classList.add('display-none');
          }
          if (tableOnSite7) {
            tableOnSite7.classList.add('display-none');
          }
          if (tableOnSite8) {
            tableOnSite8.classList.add('display-none');
          }
          if (tableOnSite9) {
            tableOnSite9.classList.add('display-none');
          }
          if (tableOnSite10) {
            tableOnSite10.classList.add('display-none');
          }
        } else {
          if (tableOnSite1) {
            if (tableOnSite1.classList.contains('display-none')) {
              tableOnSite1.classList.remove('display-none');
            }
          }
          if (tableOnSite2) {
            if (tableOnSite2.classList.contains('display-none')) {
              tableOnSite2.classList.remove('display-none');
            }
          }
          if (tableOnSite3) {
            if (tableOnSite3.classList.contains('display-none')) {
              tableOnSite3.classList.remove('display-none');
            }
          }
          if (tableOnSite4) {
            if (tableOnSite4.classList.contains('display-none')) {
              tableOnSite4.classList.remove('display-none');
            }
          }
          if (tableOnSite5) {
            if (tableOnSite5.classList.contains('display-none')) {
              tableOnSite5.classList.remove('display-none');
            }
          }
          if (tableOnSite6) {
            if (tableOnSite6.classList.contains('display-none')) {
              tableOnSite6.classList.remove('display-none');
            }
          }
          if (tableOnSite7) {
            if (tableOnSite7.classList.contains('display-none')) {
              tableOnSite7.classList.remove('display-none');
            }
          }
          if (tableOnSite8) {
            if (tableOnSite8.classList.contains('display-none')) {
              tableOnSite8.classList.remove('display-none');
            }
          }
          if (tableOnSite9) {
            if (tableOnSite9.classList.contains('display-none')) {
              tableOnSite9.classList.remove('display-none');
            }
          }
          if (tableOnSite10) {
            if (tableOnSite10.classList.contains('display-none')) {
              tableOnSite10.classList.remove('display-none');
            }
          }
        }
      } else {
        const tableOnSite1 = document.getElementById('table-on-site-1');
        const tableOnSite2 = document.getElementById('table-on-site-2');

        if (!['DC', 'DN'].includes(inspection.service.code)) {
          if (tableOnSite1) {
            tableOnSite1.classList.add('display-none');
          }
          if (tableOnSite2) {
            tableOnSite2.classList.add('display-none');
          }
        } else {
          if (tableOnSite1) {
            if (tableOnSite1.classList.contains('display-none')) {
              tableOnSite1.classList.remove('display-none');
            }
          }
          if (tableOnSite2) {
            if (tableOnSite2.classList.contains('display-none')) {
              tableOnSite2.classList.remove('display-none');
            }
          }
        }
      }
    }, 500);
  }, [norm020Section, pages, inspection]);

  useEffect(() => {
    if (['003', '050', '051', '141', '142', '173', '187', '189', '235'].includes(normCode)
      && !disabled
      && hasPackingTable
      && productIndicators) {
      setCommonFormatState({
        ...commonFormat,
        packing: {
          type: productIndicators?.package ? (productIndicators?.package).toLowerCase() as PackingType || 'none' : commonFormat.packing?.type.toLowerCase() as PackingType || 'none',
          width: productIndicators?.long || '',
          height: productIndicators?.high || '',
          radius: productIndicators?.radio || '',
          content: commonFormat.packing?.content || 0,
          productType: commonFormat.packing?.productType || 'solido g'
        }
      });
    }
  }, [productIndicators]);

  useEffect(() => {
    if (inspection.norm === '004') {
      if (setNorm004Section && commonFormat.section) {
        setNorm004Section(commonFormat.section as SectionsNOM004);
      }
    }
    if (inspection.norm === '020') {
      if (setNorm020Section && commonFormat.section) {
        setNorm020Section(commonFormat.section as SectionsNOM004);
      }
    }
    if (inspection.norm === '003' || NOM003Complement) {
      if (setNorm003Section && commonFormat.section) {
        setNorm003Section(commonFormat.section as SectionsNOM003);
      }
    }
    if (inspection.norm === '173') {
      if (setNorm173Section && commonFormat.section) {
        setNorm173Section(commonFormat.section as SectionsNOM003);
      }
    }
  }, [commonFormat.section, inspection]);

  useEffect(() => {
    let timeoutId: any = 0;

    if (commonFormat.answers && Object.keys(commonFormat.answers).length > 0) {
      if (loadedAnswers === false) {
        timeoutId = setTimeout(() => {
          const objectKeys = Object.keys(commonFormat.answers);

          for (let index = 0; index < objectKeys.length; index += 1) {
            const data = objectKeys[index].split(':');

            if (data[1] === 'value') {
              const element = document.getElementById(data[0]) as HTMLInputElement | HTMLSelectElement;

              if (element) {
                element.value = commonFormat.answers[objectKeys[index]];
              }
            }
            if (data[1] === 'textContent') {
              const element = document.getElementById(data[0]);

              if (element) {
                element.textContent = commonFormat.answers[objectKeys[index]];
              }
            }
            if (data[1] === 'innerHTML') {
              const element = document.getElementById(data[0]);

              if (element) {
                element.innerHTML = commonFormat.answers[objectKeys[index]];
              }
            }
          }

          setLoadedAnswers(true);
        }, 4000);
      } else if (disabled) {
        const objectKeys = Object.keys(commonFormat.answers);

        for (let index = 0; index < objectKeys.length; index += 1) {
          const data = objectKeys[index].split(':');

          if (data[1] === 'value') {
            const element = document.getElementById(data[0]) as HTMLInputElement | HTMLSelectElement;

            if (element) {
              element.value = commonFormat.answers[objectKeys[index]];
            }
          }
          if (data[1] === 'textContent') {
            const element = document.getElementById(data[0]);

            if (element) {
              element.textContent = commonFormat.answers[objectKeys[index]];
            }
          }
          if (data[1] === 'innerHTML') {
            const element = document.getElementById(data[0]);

            if (element) {
              element.innerHTML = commonFormat.answers[objectKeys[index]];
            }
          }
        }
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [commonFormat, inspection.norm, roundID, disabled]);

  useEffect(() => {
    if (pages.length > 0) {
      for (let index = 0; index < questions.length; index += 1) {
        if (commonFormat.questions[questions[index].questionNumber].observations && commonFormat.questions[questions[index].questionNumber].observations !== '') {
          const obvervations = document.getElementById(`textarea-${questions[index].questionNumber}`) as HTMLTextAreaElement;

          if (obvervations) {
            obvervations.value = commonFormat.questions[questions[index].questionNumber].observations;
          }
        }
      }
    }
  }, [questions, pages]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!disabled && onFinish === false) {
      const handleBeforeUnload = (event: any) => {
        event.preventDefault();

        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [disabled, onFinish]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!disabled) {
      const channel = new BroadcastChannel('cecsa_trade_channel');

      channel.onmessage = (event) => {
        if (event.data.type === 'new_tab') {
          channel.postMessage({ type: 'tab_opened' });
        }
      };

      return () => {
        channel.close();
      };
    }
  }, [disabled]);

  const renderRoundSeparator = (lastComment: ReviewComment | undefined, comment: ReviewComment) => {
    if (lastComment === undefined) {
      return false;
    }

    if ('round' in lastComment) {
      if (comment.round !== lastComment.round) {
        return true;
      }
    }

    if (!('round' in lastComment) && 'round' in comment) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (inspection.reviewComments.some((comment: ReviewComment) => comment.isAFile === true && !comment.text.includes('https://s3.amazonaws.com/'))) {
      startTransition(() => {
        const auxInspection = inspection;

        inspection.reviewComments.forEach((comment: ReviewComment, index: number) => {
          if (comment.isAFile === true) {
            signFile(
              `/admin/documents/signature?s_filePath=${comment.text}`,
              (signedPath: string) => {
                auxInspection.reviewComments[index].text = signedPath;
              },
              (error: string) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        });

        setInspection(auxInspection);
      });
    }
  }, [inspection]);

  useEffect(() => {
    setTimeout(() => {
      if (commonFormat.packing && disabled === false) {
        const alertProductType = document.getElementById('alert-product-type');
        const alertProductType2 = document.getElementById('alert-product-type-2');
        const alertProductType3 = document.getElementById('alert-product-type-3');

        const requirePreparation = document.getElementById('require-preparation') as HTMLSelectElement;
        const azucaresSelect = document.getElementById('azucares-select') as HTMLSelectElement;
        const grasasSelect = document.getElementById('grasas-select') as HTMLSelectElement;
        const sodioSelect = document.getElementById('sodio-select') as HTMLSelectElement;
        const edulcorantesSelect = document.getElementById('edulcorantes-select') as HTMLSelectElement;
        const cafeinaSelect = document.getElementById('cafeina-select') as HTMLSelectElement;

        const totalMix = document.getElementById('total-mix') as HTMLInputElement;
        const productPortion = document.getElementById('product-portion') as HTMLInputElement;
        const currentPortion = document.getElementById('Current portion') as HTMLInputElement;
        const proteinsInput = document.getElementById('proteins-input') as HTMLInputElement;
        const totalFatInput = document.getElementById('totalFat-input') as HTMLInputElement;
        const saturatedFatInput = document.getElementById('saturatedFat-input') as HTMLInputElement;
        const transFatInput = document.getElementById('transFat-input') as HTMLInputElement;
        const carbohydratesInput = document.getElementById('carbohydrates-input') as HTMLInputElement;
        const sugarsInput = document.getElementById('sugars-input') as HTMLInputElement;
        const sugarsAddedInput = document.getElementById('sugarsAdded-input') as HTMLInputElement;
        const fiberInput = document.getElementById('fiber-input') as HTMLInputElement;
        const sodiumInput = document.getElementById('sodium-input') as HTMLInputElement;

        if (commonFormat.packing.productType === 'none') {
          if (alertProductType) {
            alertProductType.innerHTML = `
            <td style="color: red;">
              Se debe selecionar el tipo de producto (sólido ó líquido)
            </td>`;
          }
          if (alertProductType2) {
            alertProductType2.innerHTML = `
            <td style="color: red;">
              Se debe selecionar el tipo de producto (sólido ó líquido)
            </td>`;
          }
          if (alertProductType3) {
            alertProductType3.innerHTML = `
            <td style="color: red;">
              Se debe selecionar el tipo de producto (sólido ó líquido)
            </td>`;
          }

          if (requirePreparation) {
            requirePreparation.disabled = true;
          }
          if (azucaresSelect) {
            azucaresSelect.disabled = true;
          }
          if (grasasSelect) {
            grasasSelect.disabled = true;
          }
          if (sodioSelect) {
            sodioSelect.disabled = true;
          }
          if (edulcorantesSelect) {
            edulcorantesSelect.disabled = true;
          }
          if (cafeinaSelect) {
            cafeinaSelect.disabled = true;
          }
          if (totalMix) {
            totalMix.disabled = true;
          }
          if (productPortion) {
            productPortion.disabled = true;
          }
          if (currentPortion) {
            currentPortion.disabled = true;
          }
          if (proteinsInput) {
            proteinsInput.disabled = true;
          }
          if (totalFatInput) {
            totalFatInput.disabled = true;
          }
          if (saturatedFatInput) {
            saturatedFatInput.disabled = true;
          }
          if (transFatInput) {
            transFatInput.disabled = true;
          }
          if (carbohydratesInput) {
            carbohydratesInput.disabled = true;
          }
          if (sugarsInput) {
            sugarsInput.disabled = true;
          }
          if (sugarsAddedInput) {
            sugarsAddedInput.disabled = true;
          }
          if (fiberInput) {
            fiberInput.disabled = true;
          }
          if (sodiumInput) {
            sodiumInput.disabled = true;
          }
        } else {
          if (alertProductType) {
            alertProductType.innerHTML = '';
          }
          if (alertProductType2) {
            alertProductType2.innerHTML = '';
          }
          if (alertProductType3) {
            alertProductType3.innerHTML = '';
          }

          if (requirePreparation) {
            requirePreparation.disabled = false;
          }
          if (azucaresSelect) {
            azucaresSelect.disabled = false;
          }
          if (grasasSelect) {
            grasasSelect.disabled = false;
          }
          if (sodioSelect) {
            sodioSelect.disabled = false;
          }
          if (edulcorantesSelect) {
            edulcorantesSelect.disabled = false;
          }
          if (cafeinaSelect) {
            cafeinaSelect.disabled = false;
          }
          if (totalMix) {
            totalMix.disabled = false;
          }
          if (productPortion) {
            productPortion.disabled = false;
          }
          if (currentPortion) {
            currentPortion.disabled = false;
          }
          if (proteinsInput) {
            proteinsInput.disabled = false;
          }
          if (totalFatInput) {
            totalFatInput.disabled = false;
          }
          if (saturatedFatInput) {
            saturatedFatInput.disabled = false;
          }
          if (transFatInput) {
            transFatInput.disabled = false;
          }
          if (carbohydratesInput) {
            carbohydratesInput.disabled = false;
          }
          if (sugarsInput) {
            sugarsInput.disabled = false;
          }
          if (sugarsAddedInput) {
            sugarsAddedInput.disabled = false;
          }
          if (fiberInput) {
            fiberInput.disabled = false;
          }
          if (sodiumInput) {
            sodiumInput.disabled = false;
          }
        }
      }
    }, 500);
  }, [commonFormat.packing?.productType, pages, disabled]);

  const renderComments = (comments: ReviewComment[]) => {
    if (comments.length === 0) {
      return (<div className='review-comments__comments review-comments__comments--empty'>
        <p>{t('inspections.emptyComments')}</p>
      </div>);
    }

    return (<div className='review-comments__comments review-comments__comments--small'>
      {
        comments.map((comment: ReviewComment, index: number) => (
          // eslint-disable-next-line max-len
          renderRoundSeparator(comments[index - 1], comment) ? (
            <>
              <div className='review-comments-separator'>
                <span className='review-comments-separator__title'>{t('inspections.round')} {comment.round}</span>
                <div className='review-comments-separator__separator'></div>
              </div>
              <div key={index} className={`review-comments__comments${user.id === comment.author.id ? '__own-comment' : '__comment'}`} id={`comment-${comment.id}`}>
                <div className={`review-comments__comments__arrow review-comments__comments__arrow${user.id === comment.author.id ? '--right' : '--left'}`}></div>
                <div>
                  <p className='review-comments__comments__author review-comments__comments__author--small'>{comment.author.name}</p>
                  {
                    comment.isAFile ? (
                      <img src={comment.text} alt={`image-${index}`} className='review-comments__comments__image review-comments__comments__image--clieckable' onClick={() => setExpandedImagePath(comment.text)} />
                    ) : (
                      <p className='review-comments__comments__message'>{comment.text}</p>
                    )
                  }
                  {
                    comment.isAFile && (
                      <Button
                        type={user.id === comment.author.id ? 'secondary-outlined' : 'secondary'}
                        onClick={() => handleDownloadCommentFile(comment.text, `image-${comment.author.name}-${comment.date}`)}
                        label={t('global.download') || ''}
                        icon='downloadWhite'
                      />
                    )
                  }
                  <p className='review-comments__comments__date'>{comment.date}</p>
                </div>
              </div>
            </>
          ) : (
            <div key={index} className={`review-comments__comments${user.id === comment.author.id ? '__own-comment' : '__comment'}`} id={`comment-${comment.id}`}>
              <div className={`review-comments__comments__arrow review-comments__comments__arrow${user.id === comment.author.id ? '--right' : '--left'}`}></div>
              <div>
                <p className='review-comments__comments__author review-comments__comments__author--small'>{comment.author.name}</p>
                {
                  comment.isAFile ? (
                    <div className='image-container-comments' onClick={() => setExpandedImagePath(comment.text)}>
                      <Icon type='expand' className='image-container-comments__icon' />
                      <img src={comment.text} alt={`image-${index}`} className='review-comments__comments__image review-comments__comments__image--clieckable image-container-comments__image' />
                    </div>
                  ) : (
                    <p className='review-comments__comments__message'>{comment.text}</p>
                  )
                }
                {
                  comment.isAFile && (
                    <Button
                      type={user.id === comment.author.id ? 'secondary-outlined' : 'secondary'}
                      onClick={() => handleDownloadCommentFile(comment.text, `image-${comment.author.name}-${comment.date}`)}
                      label={t('global.download') || ''}
                      icon='downloadWhite'
                    />
                  )
                }
                <p className='review-comments__comments__date'>{comment.date}</p>
              </div>
            </div>
          )
        ))
      }
      <div id="bottom-comments"></div>
    </div>);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (['050'].includes(inspection.norm)) {
      const handleStorageChange = (event: any) => {
        if (event.key === 'question-operation-manual-alert-text' || event.detail.key === 'question-operation-manual-alert-text') {
          const value: string = (event.newValue || event.detail.newValue);

          if (value === 'NO') {
            commonFormat.questions[18].answer = null;
            commonFormat.questions[19].answer = null;
            commonFormat.questions[20].answer = null;
            commonFormat.questions[21].answer = null;
            commonFormat.questions[22].answer = null;
            commonFormat.questions[23].answer = null;
            commonFormat.questions[24].answer = null;
            setCommonFormatState({ ...commonFormat });
          } else {
            commonFormat.questions[18].answer = 0;
            commonFormat.questions[19].answer = 0;
            commonFormat.questions[20].answer = 0;
            commonFormat.questions[21].answer = 0;
            commonFormat.questions[22].answer = 0;
            commonFormat.questions[23].answer = 0;
            commonFormat.questions[24].answer = 0;
            setCommonFormatState({ ...commonFormat });
          }
        }
        if (event.key === 'question-warranty-alert-text' || event.detail.key === 'question-warranty-alert-text') {
          const value: string = (event.newValue || event.detail.newValue);

          if (value === 'NO') {
            commonFormat.questions[27].answer = null;
            commonFormat.questions[28].answer = null;
            commonFormat.questions[29].answer = null;
            commonFormat.questions[30].answer = null;
            commonFormat.questions[31].answer = null;
            commonFormat.questions[32].answer = null;
            commonFormat.questions[33].answer = null;
            commonFormat.questions[34].answer = null;
            commonFormat.questions[35].answer = null;
            setCommonFormatState({ ...commonFormat });
          } else {
            commonFormat.questions[27].answer = 0;
            commonFormat.questions[28].answer = 0;
            commonFormat.questions[29].answer = 0;
            commonFormat.questions[30].answer = 0;
            commonFormat.questions[31].answer = 0;
            commonFormat.questions[32].answer = 0;
            commonFormat.questions[33].answer = 0;
            commonFormat.questions[34].answer = 0;
            commonFormat.questions[35].answer = 0;
            setCommonFormatState({ ...commonFormat });
          }
        }
      };

      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('custom-storage-change', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        window.removeEventListener('custom-storage-change', handleStorageChange);
      };
    }
  }, [inspection, commonFormat]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (['050'].includes(inspection.norm)) {
      const handleStorageChange = (event: any) => {
        if (event.key === 'question-operation-manual-alert-text-003' || event.detail.key === 'question-operation-manual-alert-text-003') {
          const value: string = (event.newValue || event.detail.newValue);

          if (NOM003Complement) {
            if (['CC', 'CN', 'REV'].includes(inspection.service.code)) {
              if (value === 'NO') {
                commonFormat.questions[54].answer = null;
                commonFormat.questions[55].answer = null;
                commonFormat.questions[56].answer = null;
                commonFormat.questions[57].answer = null;
                commonFormat.questions[58].answer = null;
                commonFormat.questions[59].answer = null;
                commonFormat.questions[60].answer = null;
                setCommonFormatState({ ...commonFormat });
              } else {
                commonFormat.questions[54].answer = 0;
                commonFormat.questions[55].answer = 0;
                commonFormat.questions[56].answer = 0;
                commonFormat.questions[57].answer = 0;
                commonFormat.questions[58].answer = 0;
                commonFormat.questions[59].answer = 0;
                commonFormat.questions[60].answer = 0;
                setCommonFormatState({ ...commonFormat });
              }
            } else {
              if (value === 'NO') {
                commonFormat.questions[35].answer = null;
                commonFormat.questions[36].answer = null;
                commonFormat.questions[37].answer = null;
                commonFormat.questions[38].answer = null;
                commonFormat.questions[39].answer = null;
                commonFormat.questions[40].answer = null;
                commonFormat.questions[41].answer = null;
                setCommonFormatState({ ...commonFormat });
              } else {
                commonFormat.questions[35].answer = 0;
                commonFormat.questions[36].answer = 0;
                commonFormat.questions[37].answer = 0;
                commonFormat.questions[38].answer = 0;
                commonFormat.questions[39].answer = 0;
                commonFormat.questions[40].answer = 0;
                commonFormat.questions[41].answer = 0;
                setCommonFormatState({ ...commonFormat });
              }
            }
          }
        }
        if (event.key === 'question-warranty-alert-text-003' || event.detail.key === 'question-warranty-alert-text-003') {
          const value: string = (event.newValue || event.detail.newValue);

          if (NOM003Complement) {
            if (['CC', 'CN', 'REV'].includes(inspection.service.code)) {
              if (value === 'NO') {
                commonFormat.questions[63].answer = null;
                commonFormat.questions[64].answer = null;
                commonFormat.questions[65].answer = null;
                commonFormat.questions[66].answer = null;
                commonFormat.questions[67].answer = null;
                commonFormat.questions[68].answer = null;
                commonFormat.questions[69].answer = null;
                commonFormat.questions[70].answer = null;
                commonFormat.questions[71].answer = null;
                setCommonFormatState({ ...commonFormat });
              } else {
                commonFormat.questions[63].answer = 0;
                commonFormat.questions[64].answer = 0;
                commonFormat.questions[65].answer = 0;
                commonFormat.questions[66].answer = 0;
                commonFormat.questions[67].answer = 0;
                commonFormat.questions[68].answer = 0;
                commonFormat.questions[69].answer = 0;
                commonFormat.questions[70].answer = 0;
                commonFormat.questions[71].answer = 0;
                setCommonFormatState({ ...commonFormat });
              }
            } else {
              if (value === 'NO') {
                commonFormat.questions[43].answer = null;
                commonFormat.questions[44].answer = null;
                commonFormat.questions[45].answer = null;
                commonFormat.questions[46].answer = null;
                commonFormat.questions[47].answer = null;
                commonFormat.questions[48].answer = null;
                commonFormat.questions[49].answer = null;
                commonFormat.questions[50].answer = null;
                commonFormat.questions[51].answer = null;
                commonFormat.questions[52].answer = null;
                setCommonFormatState({ ...commonFormat });
              } else {
                commonFormat.questions[43].answer = 0;
                commonFormat.questions[44].answer = 0;
                commonFormat.questions[45].answer = 0;
                commonFormat.questions[46].answer = 0;
                commonFormat.questions[47].answer = 0;
                commonFormat.questions[48].answer = 0;
                commonFormat.questions[49].answer = 0;
                commonFormat.questions[50].answer = 0;
                commonFormat.questions[51].answer = 0;
                commonFormat.questions[52].answer = 0;
                setCommonFormatState({ ...commonFormat });
              }
            }
          }
        }
      };

      window.addEventListener('storage', handleStorageChange);
      window.addEventListener('custom-storage-change', handleStorageChange);

      return () => {
        window.removeEventListener('storage', handleStorageChange);
        window.removeEventListener('custom-storage-change', handleStorageChange);
      };
    }
  }, [NOM003Complement, inspection, commonFormat]);

  return (
    <div className='inspection-list'>
      <ModalView
        ableFuncionalityEnterToConfirm={false}
        visible={expandedImagePath !== ''}
        onClose={() => setExpandedImagePath('')}
        fullSzie={false}
        customComponent={
          <div className='expanded-image-modal'>
            <img src={expandedImagePath} alt='expanded-image' className='expanded-image-modal__image' />
          </div>
        }
      />
      <div id="up-item"></div>
      <div className='inspection-list__sticky-item'>
        {
          !disabled && (
            <div className='inspection-list__progress-bar-container'>
              <div className='inspection-list__progress-bar-container__label'>
                <p>{t('inspections.inspectionListProgress')}</p>
                <p>{progressList.toFixed(2)}%</p>
              </div>
              <div className='inspection-list__progress-bar-container__bar'>
                <div className='inspection-list__progress-bar-container__bar__progress' id='progress-bar'></div>
              </div>
            </div>
          )
        }
      </div>
      <div
        id="page1"
        className='inspection-list__page'
      >
        {
          disabled && (
            <>
              {pageHeader}
              <table className="information-table">
                <tr>
                  <td className="information-table__col-title">Fecha:</td>
                  <td className="information-table__col-data">{dayjs(inspectionListDate).format('DD/MM/YYYY')}</td>
                  <td className="information-table__col-title">Folio de solicitud:</td>
                  <td className="information-table__col-data">{inspection.product.invoice} {inspection.product.subInvoice}</td>
                </tr>
                <tr>
                  <td className="information-table__col-title">Cliente:</td>
                  <td className="information-table__col-data">{commonFormat.clientBusinessName}</td>
                  <td className="information-table__col-title">Inspector:</td>
                  <td className="information-table__col-data">{commonFormat.inspectorName}</td>
                </tr>
                <tr>
                  <td className="information-table__col-title">Producto:</td>
                  <td className="information-table__col-data">{inspection.product.description}</td>
                  <td className="information-table__col-title">Fase:</td>
                  <td className="information-table__col-data">{inspection.product.fase}</td>
                </tr>
                <tr>
                  <td className="information-table__col-title">Pedimento:</td>
                  <td className="information-table__col-data">{inspection.service.code === 'DC' ? inspection.service.number : 'N/A'}</td>
                  <td className="information-table__col-title">Servicio:</td>
                  <td className="information-table__col-data">
                    {['DC', 'DN'].includes(inspection.service.code) ? 'Dictamen' : ''}
                    {['CC', 'CN'].includes(inspection.service.code) ? 'Constancia' : ''}
                    {['REV'].includes(inspection.service.code) ? 'Revision' : ''}
                  </td>
                </tr>
                <tr>
                  <td className="information-table__col-title">Modelo:</td>
                  <td className="information-table__col-data">{commonFormat.model}</td>
                  <td className="information-table__col-title">Equipo de Medición:</td>
                  <td className="information-table__col-data">{hasMeasurementEquipment ? commonFormat.measurementEquipment : 'N/A'}</td>
                </tr>
                <tr>
                  <td className="information-table__col-title">Modelo 2:</td>
                  <td className="information-table__col-data">{inspection.product.code2 || 'N/A'}</td>
                  <td className="information-table__col-title">Ronda:</td>
                  <td className="information-table__col-data">#{roundID}</td>
                </tr>
              </table>
              <br />
              <br />
              <table className="table-head">
                <tbody>
                  <tr className="table-head__row">
                    <td className="table-head__row__col-2" style={{ width: '10%' }}>Dictamen Global</td>
                    <td className="table-head__row__col-3">{setCompliesText()}</td>
                  </tr>
                  <div id="comments"></div>
                  <tr className="table-head__row">
                    <td className="table-head__row__col-2" style={{ width: '10%' }}>Observaciones Generales</td>
                    <td className="table-head__row__col-3">{commonFormat.generalObservations}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
            </>
          )
        }
        {
          hasPackingTable && disabled && (
            <table className="table-head">
              <thead>
                <tr className="table-head__row">
                  <th className="table-head__row__col-2" colSpan={['051', '142', '173', '187', '235'].includes(inspection.norm) && commonFormat.packing?.productType ? 6 : 5}>Información del envase</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-head__row">
                  <td className="table-head__row__col-1 table-head__row__col-1--dark">
                    Tipo de envase
                  </td>
                  <td className="table-head__row__col-1 table-head__row__col-1--dark">
                    Largo (cm)
                  </td>
                  <td className="table-head__row__col-1 table-head__row__col-1--dark">
                    Alto (cm)
                  </td>
                  <td className="table-head__row__col-1 table-head__row__col-1--dark">
                    Radio (cm)
                  </td>
                  <td className="table-head__row__col-1 table-head__row__col-1--dark">
                    Superficie Principal de Exhibición (cm2)
                  </td>
                  {
                    ['051', '142', '173', '187', '235'].includes(inspection.norm)
                    && commonFormat.packing?.productType && (
                      <td className="table-head__row__col-1 table-head__row__col-1--dark">
                        Contenido Neto del Envase ({inspection.norm === '142' ? 'liquido ml' : commonFormat.packing?.productType})
                      </td>
                    )
                  }
                </tr>
                <tr className="table-head__row">
                  <td className="table-head__row__col-1">
                    <div className="table-head__row__col-1--center">
                      {packingTypes[commonFormat.packing?.type as string]}
                    </div>
                  </td>
                  <td className="table-head__row__col-1">
                    <div className="table-head__row__col-1--center">
                      {commonFormat.packing?.width} cm
                    </div>
                  </td>
                  <td className="table-head__row__col-1">
                    <div className="table-head__row__col-1--center">
                      {commonFormat.packing?.height} cm
                    </div>
                  </td>
                  <td className="table-head__row__col-1">
                    <div className="table-head__row__col-1--center">
                      {commonFormat.packing?.radius} cm
                    </div>
                  </td>
                  <td className="table-head__row__col-1">
                    <div className="table-head__row__col-1--center">
                      {getSPENumber()}
                    </div>
                  </td>
                  {
                    ['051', '142', '173', '187', '235'].includes(inspection.norm)
                    && commonFormat.packing?.productType
                    && commonFormat.packing?.content && (
                      <td className="table-head__row__col-1">
                        <div className="table-head__row__col-1--center">
                          {commonFormat.packing?.content} {inspection.norm === '142' ? 'ml' : commonFormat.packing?.productType.split(' ').pop()}
                        </div>
                      </td>
                    )
                  }
                </tr>
              </tbody>
            </table>
          )
        }
        {
          hasSamplingTable && disabled && (
            <table className="sampling-table">
              <tbody>
                <tr className="sampling-table__row">
                  <td colSpan={7} className="sampling-table__cell">MUESTREO</td>
                </tr>
                <tr className="sampling-table__row">
                  <td rowSpan={2} className="sampling-table__cell sampling-table__cell--grey">Tamaño de lote</td>
                  <td rowSpan={2} className="sampling-table__cell sampling-table__cell--grey">Niveles.<br />Letra Clave</td>
                  <td rowSpan={2} className="sampling-table__cell sampling-table__cell--grey">Tamaño de la muestra</td>
                  <td colSpan={2} className="sampling-table__cell sampling-table__cell--grey">NCA</td>
                  <td colSpan={2} className="sampling-table__cell sampling-table__cell--grey">Información</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">AC</td>
                  <td className="sampling-table__cell">RE</td>
                  <td className="sampling-table__cell sampling-table__cell--text-left"></td>
                  <td className="sampling-table__cell sampling-table__cell--text-left"></td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">2 a 8</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">A</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">2</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                  <td className="sampling-table__cell sampling-table__cell--grey sampling-table__cell--text-left">Lote:</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">
                    {lot}
                  </td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">9 a 15</td>
                  <td className="sampling-table__cell">A</td>
                  <td className="sampling-table__cell">2</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                  <td className="sampling-table__cell sampling-table__cell--text-left">Muestreo:</td>
                  <td className="sampling-table__cell">
                    {commonFormat.sampling?.sample}
                  </td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">16 a 25</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">B</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">3</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                  <td className="sampling-table__cell sampling-table__cell--grey sampling-table__cell--text-left">Aceptados:</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">
                    {commonFormat.sampling?.accepted}
                  </td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">26 a 50</td>
                  <td className="sampling-table__cell">C</td>
                  <td className="sampling-table__cell">5</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                  <td className="sampling-table__cell sampling-table__cell--text-left">Rechazados:</td>
                  <td className="sampling-table__cell">
                    {commonFormat.sampling?.rejected}
                  </td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">51 a 90</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">C</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">5</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                  <td className="sampling-table__cell sampling-table__cell--grey sampling-table__cell--comments" rowSpan={9} colSpan={2}>
                    <p className='sampling-table__cell--comments__text'>Comentarios: {commonFormat.sampling?.comments}</p>
                  </td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">91 a 150</td>
                  <td className="sampling-table__cell">D</td>
                  <td className="sampling-table__cell">8</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">151 a 280</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">E</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">13</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">281 a 500</td>
                  <td className="sampling-table__cell">F</td>
                  <td className="sampling-table__cell">20</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">501 a 1200</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">G</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">32</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">1201 a 3200</td>
                  <td className="sampling-table__cell">H</td>
                  <td className="sampling-table__cell">50</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">3201 a 10000</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">J</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">80</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell">10001 a 35000</td>
                  <td className="sampling-table__cell">K</td>
                  <td className="sampling-table__cell">125</td>
                  <td className="sampling-table__cell">0</td>
                  <td className="sampling-table__cell">1</td>
                </tr>
                <tr className="sampling-table__row">
                  <td className="sampling-table__cell sampling-table__cell--grey">35001 a más</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">L</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">200</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">0</td>
                  <td className="sampling-table__cell sampling-table__cell--grey">1</td>
                </tr>
              </tbody>
            </table>
          )
        }
      </div>
      <div className="inputs-container">
        {
          hasPackingTable && !disabled && (
            <>
              {
                ['003', '050', '141', '142', '173', '187', '189', '235', '051', '189'].includes(inspection.norm) && (
                  <>
                    <Select
                      title='Información del envase'
                      id='packing'
                      key='packing'
                      options={[
                        {
                          text: 'Cilindro',
                          value: 'cylindrical'
                        },
                        {
                          text: 'Circular',
                          value: 'circular'
                        },
                        {
                          text: 'Rectángulo',
                          value: 'rectangular'
                        }
                      ]}
                      type='yellow'
                      onChange={(_value: string) => {
                        commonFormat.packing = {
                          type: _value as PackingType,
                          height: commonFormat.packing?.height || 0,
                          width: commonFormat.packing?.width || 0,
                          radius: commonFormat.packing?.width || 0,
                          content: commonFormat.packing?.content || 0,
                          productType: commonFormat.packing?.productType || 'none'
                        };
                        setCommonFormatState({ ...commonFormat });

                        updateMinimunSizedenomination();
                      }}
                      value={commonFormat.packing?.type}
                      disabled={disabled || disableSamplingOrPacking}
                      hasError={!!errors.packing}
                      helperText={errors.packing}
                    />
                    <Input
                      color='yellow'
                      title='Largo (cm)'
                      placeholder="Largo (cm)"
                      id="width"
                      value={commonFormat.packing?.width || ''}
                      disabled={commonFormat.packing?.type === 'none' || commonFormat.packing?.type !== 'rectangular' || disabled || disableSamplingOrPacking}
                      onChange={(value: string | number) => {
                        commonFormat.packing = {
                          type: commonFormat.packing?.type || 'circular',
                          height: commonFormat.packing?.height || 0,
                          width: value || 0,
                          radius: commonFormat.packing?.radius || 0,
                          content: commonFormat.packing?.content || 0,
                          productType: commonFormat.packing?.productType || 'solido g'
                        };
                        setCommonFormatState({ ...commonFormat });

                        updateMinimunSizedenomination();
                      }}
                      type="decimal"
                      hasError={!!errors.width}
                      helperText={errors.width}
                    />
                    <Input
                      color='yellow'
                      title='Alto (cm)'
                      placeholder="Alto (cm)"
                      id="height"
                      value={commonFormat.packing?.height || ''}
                      onChange={(value: string | number) => {
                        commonFormat.packing = {
                          type: commonFormat.packing?.type || 'circular',
                          height: value || 0,
                          width: commonFormat.packing?.width || 0,
                          radius: commonFormat.packing?.radius || 0,
                          content: commonFormat.packing?.content || 0,
                          productType: commonFormat.packing?.productType || 'solido g'
                        };
                        setCommonFormatState({ ...commonFormat });

                        updateMinimunSizedenomination();
                      }}
                      disabled={commonFormat.packing?.type === 'none' || commonFormat.packing?.type === 'circular' || disabled || disableSamplingOrPacking}
                      type="decimal"
                      hasError={!!errors.height}
                      helperText={errors.height}
                    />
                    <Input
                      color='yellow'
                      title='Radio (cm)'
                      placeholder="Radio (cm)"
                      id="radio"
                      value={commonFormat.packing?.radius || ''}
                      onChange={(value: string | number) => {
                        commonFormat.packing = {
                          type: commonFormat.packing?.type || 'circular',
                          height: commonFormat.packing?.height || 0,
                          width: commonFormat.packing?.width || 0,
                          radius: value || 0,
                          content: commonFormat.packing?.content || 0,
                          productType: commonFormat.packing?.productType || 'solido g'
                        };
                        setCommonFormatState({ ...commonFormat });

                        updateMinimunSizedenomination();
                      }}
                      disabled={commonFormat.packing?.type === 'none' || commonFormat.packing?.type === 'rectangular' || disabled || disableSamplingOrPacking}
                      type="decimal"
                      hasError={!!errors.radio}
                      helperText={errors.radio}
                    />
                  </>
                )
              }
              {
                ['051', '142', '173', '187', '235'].includes(inspection.norm) && (
                  <>
                    <Input
                      title={`Contenido Neto del Envase (${inspection.norm === '142' ? 'ml' : 'g o ml'})`}
                      placeholder={`Contenido Neto del Envase (${inspection.norm === '142' ? 'ml' : 'g o ml'})`}
                      id="content"
                      value={commonFormat.packing?.content || '0'}
                      onChange={(value: string | number) => {
                        commonFormat.packing = {
                          type: commonFormat.packing?.type || 'circular',
                          height: commonFormat.packing?.height || 0,
                          width: commonFormat.packing?.width || 0,
                          radius: commonFormat.packing?.radius || 0,
                          content: ((value as string)[0] === '0' ? (value as string).substring(1) : value) as number || 0,
                          productType: commonFormat.packing?.productType || 'solido g'
                        };
                        setCommonFormatState({ ...commonFormat });

                        updateMinimunSizedenomination();
                      }}
                      type="decimal"
                      hasError={!!errors.content}
                      helperText={errors.content}
                      color='yellow'
                    />
                  </>
                )
              }
              {
                ['051', '173', '187', '235'].includes(inspection.norm) && (
                  <>
                    <Select
                      title='Tipo de producto (solido g / liquido ml )'
                      id='productType'
                      key='productType'
                      options={[
                        {
                          text: 'solido g',
                          value: 'solido g'
                        },
                        {
                          text: 'liquido ml',
                          value: 'liquido ml'
                        }
                      ]}
                      type='yellow'
                      onChange={(_value: string) => {
                        if (_value !== 'none') {
                          commonFormat.packing = {
                            type: commonFormat.packing?.type as PackingType,
                            height: commonFormat.packing?.height || 0,
                            width: commonFormat.packing?.width || 0,
                            radius: commonFormat.packing?.radius || 0,
                            content: commonFormat.packing?.content || 0,
                            productType: _value as 'solido g' | 'liquido ml'
                          };
                          setCommonFormatState({ ...commonFormat });

                          const label = document.getElementById('solid-liquid-label');

                          if (label) {
                            label.textContent = `${_value === 'liquido ml' ? 'Por 100ml' : 'Por 100g'}`;
                          }

                          updateMinimunSizedenomination();
                        }
                      }}
                      value={commonFormat.packing?.productType}
                      disabled={disabled || disableSamplingOrPacking}
                      hasError={!!errors.productType}
                      helperText={errors.productType}
                    />
                  </>
                )
              }
              {
                ['003', '050', '141', '142', '173', '187', '189', '235', '051', '189'].includes(inspection.norm) && (
                  <p className="inputs-container__title" >SPE:<b id='SPE-value'>{getSPENumber()}</b>cm<sup>2</sup></p>
                )
              }
            </>
          )
        }
      </div>
      <div id="section-norm"></div>
      {
        inspection.norm === '004'
        && !disabled
        && inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion >= 2 && (
          <>
            <Title title={'Selecciona la sección a contestar de la NOM-004-SE-2021'} />
            <RadioList
              options={inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion === 2 ? [
                {
                  label: 'A) Prendas de vestir, accesorios y ropa de casa (cobijas y cobertores) elaborados con productos textiles aun cuando contengan plásticos u otros materiales',
                  value: 'A'
                },
                {
                  label: 'B) Ropa de casa (excepto cobijas y cobertores) a) Sábanas. c) Sobrecamas. d) Manteles. e) Manteles individuales. f) Servilletas. g) Protectores. h) Cortinas confeccionadas. i) Toallas. j) Colchones y bases de colchones elaborados o forrados con textiles. k) Prendas reversibles.',
                  value: 'B'
                },
                {
                  label: 'C) Textiles Producto elaborado a partir de fibras naturales y/o fibras químicas o una mezcla de éstas, incluyéndose entre ellos, en forma enunciativa mas no limitativa:  hilados,  hilos de coser,  hilos de bordar,  estambres,  telas en crudo y acabadas, tejidas y no tejidas,  casimires,  pasamanerías (encajes, listones, bordados, elásticos), y similares.',
                  value: 'C'
                },
                {
                  label: 'D) Para los siguientes productos a) Pantimedias. b) Medias y tobimedias. c) Calcetines y calcetas. d) Bandas elásticas para la cabeza. e) Muñequeras.',
                  value: 'D'
                },
                {
                  label: 'E) 5.2 Textiles y otros productos elaborados con fibras o telas a) Cortes de tela acondicionados para la venta al por menor, que contengan 45 % o más de lana peinada, que no excedan de cinco metros (Casimires). b) Bolsos de mano. c) Maletas. d) Monederos. e) Billeteras. f) Estuches g) Mochilas. h) Paraguas y parasoles. i) Cubreasientos. j) Artículos para cubrir electrodomésticos y domésticos. k) Cubiertas para planchadores. l) Cubiertas para muebles de baño. m) Cubiertas para muebles. n) Cojines. ñ) Artículos de limpieza. o) Pañales. p) Lienzos para pintores. q) Canguro para bebé. r) Pañaleras. s) Baberos. t) Cambiadores. u) Cinturones textiles.',
                  value: 'E'
                },
                {
                  label: 'F) En los siguientes productos a) Telas tejidas y no tejidas de cualquier índole. b) Alfombras, bajo alfombras y tapetes de materiales textiles. c) Pelucas. d) Artículos para el cabello (salvo aquellos que por sus pequeñas dimensiones deban empacarse a granel). Cuando la presentación para su venta al consumidor final de estos artículos se presente en paquete, la información comercial debe estar contenida en el paquete. e) Corbatas de moño. f) Artículos destinados a ser utilizados en una sola ocasión (desechables). En este caso, la información a que se refiere el inciso 4.1.2 puede presentarse en el envase.',
                  value: 'F'
                }
              ] : [
                {
                  label: 'A) Prendas de vestir, accesorios y ropa de casa (cobijas y cobertores) elaborados con productos textiles aun cuando contengan plásticos u otros materiales',
                  value: 'A'
                },
                {
                  label: 'B) Ropa de casa (excepto cobijas y cobertores) a) Sábanas. c) Sobrecamas. d) Manteles. e) Manteles individuales. f) Servilletas. g) Protectores. h) Cortinas confeccionadas. i) Toallas. j) Colchones y bases de colchones elaborados o forrados con textiles. k) Prendas reversibles.',
                  value: 'B'
                },
                {
                  label: 'C) Para los siguientes productos a) Pantimedias. b) Medias y tobimedias. c) Calcetines y calcetas. d) Bandas elásticas para la cabeza. e) Muñequeras.',
                  value: 'C'
                },
                {
                  label: 'D) 5.2 Textiles y otros productos elaborados con fibras o telas a) Cortes de tela acondicionados para la venta al por menor, que contengan 45 % o más de lana peinada, que no excedan de cinco metros (Casimires). b) Bolsos de mano. c) Maletas. d) Monederos. e) Billeteras. f) Estuches g) Mochilas. h) Paraguas y parasoles. i) Cubreasientos. j) Artículos para cubrir electrodomésticos y domésticos. k) Cubiertas para planchadores. l) Cubiertas para muebles de baño. m) Cubiertas para muebles. n) Cojines. ñ) Artículos de limpieza. o) Pañales. p) Lienzos para pintores. q) Canguro para bebé. r) Pañaleras. s) Baberos. t) Cambiadores. u) Cinturones textiles.',
                  value: 'D'
                },
                {
                  label: 'E) En los siguientes productos a) Telas tejidas y no tejidas de cualquier índole. b) Alfombras, bajo alfombras y tapetes de materiales textiles. c) Pelucas. d) Artículos para el cabello (salvo aquellos que por sus pequeñas dimensiones deban empacarse a granel). Cuando la presentación para su venta al consumidor final de estos artículos se presente en paquete, la información comercial debe estar contenida en el paquete. e) Corbatas de moño. f) Artículos destinados a ser utilizados en una sola ocasión (desechables). En este caso, la información a que se refiere el inciso 4.1.2 puede presentarse en el envase.',
                  value: 'E'
                }
              ]}
              value={`${norm004Section}`}
              disabled={firstRender}
              orientation={'vertical'}
              onChange={(_value: string | number) => {
                if (setNormSectionChanged) {
                  setNormSectionChanged(true);
                }
                if (setNorm004Section) {
                  setNorm004Section(`${_value as SectionsNOM004}`);
                }
              }}
            />
          </>
        )
      }
      {
        inspection.norm === '020'
        && !disabled
        && inspection.rounds.find((round) => round.id === roundID)!.inspectionListVersion >= 2 && (
          <>
            <Title title={'Selecciona la sección a contestar de la NOM-020-SCFI-1997'} />
            <RadioList
              options={[
                {
                  label: 'A) Calzado',
                  value: 'A'
                },
                {
                  label: 'B) En las prendas de vestir como abrigos, chamarras, sacos, pantalones, faldas, bolsas para dama, guantes, cinturones y artículos para viaje como velices, portafolios, mochilas, etc., la parte externa del producto y el forro.',
                  value: 'B'
                },
                {
                  label: 'C) En artículos como monederos, carteras, billeteras, llaveros, estuches, artículos para escritorio, para oficina, deportivos y otros no especificados, se deben indicar el material de la vista, sin considerar armazones, divisiones y otras partes, excepto el forro cuando éste sea piel.',
                  value: 'C'
                },
                {
                  label: 'D) En los artículos de cuero para uso industrial, como son los rollos de banda de cuero, artículos tales como refacciones de cuero, empaques de cuero, etc., únicamente debe marcarse en un lugar visible de su embalaje.',
                  value: 'D'
                },
                {
                  label: 'E) Las pieles o cueros curtidos',
                  value: 'E'
                },
                {
                  label: 'F) Etiquetado y/o marcado de materiales artificiales o sintéticos',
                  value: 'F'
                }
              ]}
              value={`${norm020Section}`}
              disabled={firstRender}
              orientation={'vertical'}
              onChange={(_value: string | number) => {
                if (setNormSectionChanged) {
                  setNormSectionChanged(true);
                }
                if (setNorm020Section) {
                  setNorm020Section(`${_value as SectionsNOM004}`);
                }
              }}
            />
          </>
        )
      }
      {
        (inspection.norm === '003' || NOM003Complement)
        && !disabled && (
          <>
            <Title title={'Selecciona la sección a contestar de la NOM-003-SSA1-2006'} />
            <RadioList
              options={[
                {
                  label: 'A) Los productos con plomo formulados en base disolvente',
                  value: 'A'
                },
                {
                  label: 'B) Los productos formulados en base acuosa o aceite vegetal con plomo',
                  value: 'B'
                },
                {
                  label: 'C) Los productos formulados en base disolvente sin plomo',
                  value: 'C'
                },
                {
                  label: 'D) Los productos formulados en base acuosa o aceite vegetal sin plomo',
                  value: 'D'
                },
                {
                  label: 'E) Los productos fabricados con fines de uso escolar',
                  value: 'E'
                }
              ]}
              value={`${norm003Section}`}
              disabled={firstRender}
              orientation={'vertical'}
              onChange={(_value: string | number) => {
                if (setNormSectionChanged) {
                  setNormSectionChanged(true);
                }
                if (setNorm003Section) {
                  setNorm003Section(`${_value as SectionsNOM003}`);
                }
              }}
            />
          </>
        )
      }
      {
        inspection.norm === '173'
        && !disabled && (
          <>
            <Title title={'Selecciona la sección a contestar de la NOM-173-SE-2021'} />
            <RadioList
              options={[
                {
                  label: 'A) Jugos',
                  value: 'A'
                },
                {
                  label: 'B) Agua de Coco',
                  value: 'B'
                },
                {
                  label: 'C) Néctares',
                  value: 'C'
                },
                {
                  label: 'D) Bebidas no alcohólicas con vegetal o fruta u hortaliza',
                  value: 'D'
                },
                {
                  label: 'E) Bebidas saborizadas no alcohólicas',
                  value: 'E'
                }
              ]}
              value={`${norm173Section}`}
              disabled={firstRender}
              orientation={'vertical'}
              onChange={(_value: string | number) => {
                if (setNormSectionChanged) {
                  setNormSectionChanged(true);
                }
                if (setNorm173Section) {
                  setNorm173Section(`${_value as SectionsNOM003}`);
                }
              }}
            />
          </>
        )
      }
      {pages.map((page) => page)}
      {/* {
        !disabled && (
          <Button
            type={'secondary-outlined'}
            onClick={cleanInspectionList}
            label='Limpiar lista de inspección'
            fullWidth={true}
            icon='paperShredder'
            size='big'
            disabled={!commonFormat.questions.some((question) => question.answer === true || question.answer === false || question.answer === null || question.observations.length > 0)}
          />
        )
      } */}
      {
        hasSamplingTable && !disabled && (
          <>
            <div className="sampling-form" id='sampling'>
              <div className='sampling-form__center'>
                <div className="sampling-form__item">
                  <div className="sampling-form__item sampling-form__item--input-lot">
                    <Input
                      type="number"
                      id="accpeted"
                      placeholder="Cantidad de piezas del Lote"
                      title={samplingInputInfo.label}
                      value={commonFormat.sampling?.lot !== undefined ? commonFormat.sampling?.lot.toLocaleString() : 0}
                      disabled={disabled || disableSamplingOrPacking}
                      onChange={(value: string | number) => {
                        if (labelsToPut !== 0) {
                          if (Number(value) <= samplingInputInfo.maxValue) {
                            commonFormat.sampling = {
                              lot: Number(value) || 0,
                              sample: commonFormat.sampling?.sample || 0,
                              accepted: commonFormat.sampling?.accepted || 0,
                              rejected: commonFormat.sampling?.rejected || 0,
                              comments: commonFormat.sampling?.comments || ''
                            };
                            setCommonFormatState({ ...commonFormat });
                          }
                        } else {
                          commonFormat.sampling = {
                            lot: Number(value) || 0,
                            sample: commonFormat.sampling?.sample || 0,
                            accepted: commonFormat.sampling?.accepted || 0,
                            rejected: commonFormat.sampling?.rejected || 0,
                            comments: commonFormat.sampling?.comments || ''
                          };
                          setCommonFormatState({ ...commonFormat });
                        }
                      }}
                      hasError={!!errors.samplingLot}
                      helperText={errors.samplingLot}
                    />
                    {
                      remainingLot > 0 && (
                        <span className='inspection-list__alert-label'>El lote debe ser de <b>{remainingLot.toLocaleString()}</b> piezas, ya que es el último/único modelo inspeccionado
                          y se deben de completar el numero de etiquetas a colocar totales.</span>
                      )
                    }
                    <div className="sampling-form__item sampling-form__item--input-range">
                      <input
                        type="range"
                        id="range"
                        value={commonFormat.sampling?.lot}
                        name="lot"
                        min="0"
                        max={labelsToPut}
                        step="1"
                        onChange={handleChangeRange}
                        disabled={disabled || disableSamplingOrPacking}
                      />
                      <label htmlFor="range">Cantidad de piezas del Lote: {commonFormat.sampling?.lot.toLocaleString()} de {labelsToPut.toLocaleString()}</label>
                    </div>
                  </div>
                </div>
                <br />
                <div className="sampling-form__item sampling-form__item-left">
                  <p className="sampling-form__item__label sampling-form__item--input">Muestreo:</p>
                  <p className="sampling-form__item__label sampling-form__item--input">{sampling}</p>
                </div>
                <div className="sampling-form__item sampling-form__item--input">
                  <Input
                    type="number"
                    id="accpeted"
                    placeholder="Aceptados"
                    title='Aceptados'
                    value={commonFormat.sampling?.accepted || 0}
                    disabled={disabled || disableSamplingOrPacking}
                    onChange={(value: string | number) => {
                      commonFormat.sampling = {
                        lot: commonFormat.sampling?.lot || 0,
                        sample: commonFormat.sampling?.sample || 0,
                        accepted: Number(value) || 0,
                        rejected: commonFormat.sampling?.rejected || 0,
                        comments: commonFormat.sampling?.comments || ''
                      };
                      setCommonFormatState({ ...commonFormat });
                    }}
                    hasError={!!errors.samplingAccepted}
                    helperText={errors.samplingAccepted}
                  />
                </div>
                <div className="sampling-form__item sampling-form__item--input">
                  <Input
                    type="number"
                    id="reject"
                    placeholder="Rechazados"
                    title='Rechazados'
                    value={commonFormat.sampling?.rejected || 0}
                    disabled={disabled || disableSamplingOrPacking}
                    onChange={(value: string | number) => {
                      commonFormat.sampling = {
                        lot: commonFormat.sampling?.lot || 0,
                        sample: commonFormat.sampling?.sample || 0,
                        accepted: commonFormat.sampling?.accepted || 0,
                        rejected: Number(value) || 0,
                        comments: commonFormat.sampling?.comments || ''
                      };
                      setCommonFormatState({ ...commonFormat });
                    }}
                    hasError={!!errors.samplingRejected}
                    helperText={errors.samplingRejected}
                  />
                </div>
                <br />
              </div>
              <Input
                title='Comentarios del muestreo (opcionales)'
                placeholder="Comentarios del muestreo (opcionales)"
                id="comments"
                value={commonFormat.sampling?.comments || ''}
                disabled={disabled || disableSamplingOrPacking}
                onChange={(value: string | number) => {
                  commonFormat.sampling = {
                    lot: commonFormat.sampling?.lot || 0,
                    sample: commonFormat.sampling?.sample || 0,
                    accepted: commonFormat.sampling?.accepted || 0,
                    rejected: commonFormat.sampling?.rejected || 0,
                    comments: String(value)
                  };
                  setCommonFormatState({ ...commonFormat });
                }}
                type="textarea"
              />
            </div>
            <br />
          </>
        )
      }
      {
        disabled === false && (commonFormat.sign === undefined || commonFormat.sign === null || commonFormat.sign?.length === 0) && (
          <div className='sign-container'>
            <div className='sign-container__item'>
              <p className='witnesses__content__sign-title' id='signature'>{t('global.inspectorSign')}</p>
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'witnesses__content__canvas-draw' }}
              />
              <div className='witnesses__content__action-buttons'>
                <Button type='secondary-outlined' onClick={() => sigCanvas.current.clear()} label={t('services.clean') || ''} />
                {/* <Button type='secondary-outlined' onClick={saveSignature} label={t('global.save') || ''} /> */}
              </div>
            </div>
          </div>
        )
      }
      <div className="inputs-container">
        {
          hasMeasurementEquipment && !disabled && (
            <Select
              title='Equipo de medición'
              id='measurementEquipment'
              key='measurementEquipment'
              options={[
                {
                  text: 'M-01',
                  value: 'M-01'
                },
                {
                  text: 'M-02',
                  value: 'M-02'
                },
                {
                  text: 'M-03',
                  value: 'M-03'
                },
                {
                  text: 'M-04',
                  value: 'M-04'
                },
                {
                  text: 'M-05',
                  value: 'M-05'
                },
                {
                  text: 'M-06',
                  value: 'M-06'
                },
                {
                  text: 'M-07',
                  value: 'M-07'
                },
                {
                  text: 'M-08',
                  value: 'M-08'
                },
                {
                  text: 'M-09',
                  value: 'M-09'
                },
                {
                  text: 'M-10',
                  value: 'M-10'
                },
                {
                  text: 'M-11',
                  value: 'M-11'
                },
                {
                  text: 'M-12',
                  value: 'M-12'
                },
                {
                  text: 'M-13',
                  value: 'M-13'
                },
                {
                  text: 'M-14',
                  value: 'M-14'
                },
                {
                  text: 'M-15',
                  value: 'M-15'
                },
                {
                  text: 'M-16',
                  value: 'M-16'
                },
                {
                  text: 'M-17',
                  value: 'M-17'
                },
                {
                  text: 'M-18',
                  value: 'M-18'
                },
                {
                  text: 'M-19',
                  value: 'M-19'
                },
                {
                  text: 'M-20',
                  value: 'M-20'
                },
                {
                  text: 'M-21',
                  value: 'M-21'
                }
              ]}
              onChange={(_value: string) => {
                commonFormat.measurementEquipment = _value as MeasurementEquipment;

                setCommonFormatState({ ...commonFormat });
              }}
              value={commonFormat.measurementEquipment}
              disabled={disabled || disableSamplingOrPacking}
              hasError={!!errors.measurementEquipment}
              helperText={errors.measurementEquipment}
            />
          )
        }
      </div>
      {
        !disabled && (
          <Input
            title='Observaciones Generales'
            placeholder="Observaciones Generales"
            id="general-observations"
            value={commonFormat.generalObservations}
            disabled={disabled || disableSamplingOrPacking}
            onChange={(value: string | number) => {
              commonFormat.generalObservations = String(value);
              setCommonFormatState({ ...commonFormat });
            }}
            type="textarea"
          />
        )
      }
      {
        !disabled && (
          <div className={'cta-save-inspection-list-button'}>
            <Button
              type={'primary'}
              label={t('global.saveList') || ''}
              onClick={saveInspectionList}
              size='big'
            />
            <Button
              type={'primary'}
              label={t('global.makePartialSave') || ''}
              onClick={savePartialInspectionList}
              size='big'
            />
          </div>
        )
      }
      {
        inspection.reviewComments.length > 0 && !disabled && (
          <div className='comments-in-list'>
            <div className='comments-in-list__header' onClick={() => setCommentsOpen(!commentsOpen)}>
              {t('services.reviews')}
              <Icon type={'downArrow'} className={`comments-in-list__header__icon comments-in-list__header__icon${commentsOpen ? '--open' : ''}`} />
            </div>
            <div className={`comments-in-list__content comments-in-list__content${commentsOpen ? '--open' : ''}`}>
              {
                isPending ? (
                  <Spinner />
                ) : (
                  renderComments(inspection.reviewComments)
                )
              }
            </div>
          </div>
        )
      }
      {
        showNavigateButtons && (
          <div className='navigate-container'>
            <Button
              type={'primary-outlined'}
              label='Ir arriba'
              onClick={() => {
                const upItem = document.getElementById('up-item');

                if (upItem) {
                  upItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }
              }}
              icon='upArrow'
              size='small'
            />
            <Button
              type={'primary-outlined'}
              label='Ir abajo'
              onClick={() => {
                const downItem = document.getElementById('down-item');

                if (downItem) {
                  downItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }
              }}
              icon='downArrow'
              size='small'
            />
          </div>
        )
      }
      <div id="down-item"></div>
    </div>
  );
};

export default CommonList;
