/* eslint-disable max-len */

const list = {
  documentCode: 'F7-50',
  documentDescription: 'Lista de inspección para la NORMA Oficial Mexicana NOM-004-SE-2021, Información comercial-Etiquetado de productos textiles, prendas de vestir, sus accesorios y ropa de casa',
  documentIssueDate: '01/04/2024',
  questions: [
    {
      pageNumber: 2,
      questionNumber: 0,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          A) Prendas de vestir, accesorios y ropa de casa (cobijas y cobertores) elaborados con productos textiles aun cuando contengan plásticos u otros materiales
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 1,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 2,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 3,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Las prendas de vestir, sus accesorios y ropa de casa, deben ostentar la siguiente información en forma legible, en una o más etiquetas permanentes colocadas en la parte inferior del cuello o cintura, o en cualquier otro lugar visible, de acuerdo con las características de la prenda, sus accesorios y ropa de casa en los casos y términos que señala esta Norma Oficial Mexicana, pudiéndose adicionar etiquetas códigos QR.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>3.8.1 etiqueta permanente</b>
                <br />es aquella incorporada al producto, elaborada de tela o de cualquier otro material que tenga una duración cuando menos iguala la del producto al que se aplique, cosida o adherida por un proceso de termo fijación o similar que garantice su durabilidad, pudiendo también estar bordada, impresa o estampada en el producto.
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 4,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          a) Marca comercial (ver inciso 4.2).
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 5,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2 Marca comercial</b>
          <br />
          Debe señalarse la marca comercial del producto.
          <br />Cuando el producto objeto de esta Norma Oficial Mexicana ostente el nombre, denominación o razón social del responsable del producto y dicha persona utilice una marca comercial que es igual a su nombre, denominación o razón social, no es obligatorio señalar la marca comercial aludida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 6,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          b) Descripción de insumos
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 7,
      isATitle: true,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br />
          <b>4.3 Descripción de insumos</b>
          <br />
          Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          <br />
          Normas que se deben de consultar
          <br />
          NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 8,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b> La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br />
          <br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.
          <br />
          <br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.
          <br />
          Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).
          <br />
          Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 9,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br />
          <br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 10,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br />
          <br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 11,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br />
          <br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />
          Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />
          Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 12,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br />
          <br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana.
          <br />
          Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 13,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />
          <br />
          Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />
          Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />
          Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 14,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />
          <br />
          Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />
          Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />
          Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 15,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />
          <br />
          Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />
          Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />
          Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 16,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 17,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 18,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br />Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 19,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 20,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 21,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 22,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br /><br />Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br /><br />Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 23,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          c) Talla para prendas de vestir y sus accesorios o medidas para ropa de casa.
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 24,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.4.1</b> Las tallas de las prendas de vestir deben expresarse en idioma español, sin perjuicio de que puedan indicarse además en cualquier otro idioma en segundo término, admitiéndose para tal efecto las expresiones o abreviaturas que tradicionalmente se vienen utilizando de acuerdo con el uso cotidiano y las costumbres o aquellas abreviaturas nuevas que reflejen la inclusión detallas nuevas, acorde a las necesidades de la población mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 25,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.4.2</b> Las medidas de la ropa de casa y de los textiles deben expresarse de acuerdo con las unidades de medida y símbolos que correspondan al Sistema General de Unidades de Medida, como son: m, cm, mm (véase NOM-008-SCFI-2002), sin perjuicio de que se exprese en otros sistemas de unidades de medida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 26,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          d) Instrucciones de cuidado (ver inciso 4.5).
          <br /><br />
          Las prendas de vestir, ropa de casa y los accesorios deben ostentar exclusivamente la información relativa al tratamiento adecuado e instrucciones de cuidado y conservación que requiera el producto y determine el fabricante, debiendo comprender, en su caso, los siguientes datos:
          <br /><br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 27,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.1</b> Lavado
          <br />
          a) A mano, en lavadora, en seco o proceso especial o recomendación en contrario de alguno de estos tipos de lavado.
          <br />b) Temperatura del agua.
          <br />c) Con jabón o detergente.
          <br />d) No lavar.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 28,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.2</b> Blanqueo
          <br />
          a) Uso o no de compuestos clorados u otros blanqueadores.
          <br />b) No usar blanqueador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 29,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.3</b> Secado
          <br />
          a) Exprimir o no exprimir, centrifugar o no centrifugar.
          <br />b) Al sol o a la sombra.
          <br />c) Secado de línea o secado horizontal.
          <br />d) Uso o recomendación en contrario de equipo especial, máquina o secadora limpieza en húmedo profesional.
          <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 30,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.4</b> Planchado
          <br />
          a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
          <br />b) Condiciones especiales, si las hubiere.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 31,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.5</b> Recomendaciones particulares, haciendo mención específica de las tendencias al encogimiento o deformación cuando lesean propias, indicando instrucciones para atenderlas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 32,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.6</b> Las instrucciones de cuidado y conservación del producto deben indicarse por medio de leyendas breves y claras o los símbolos, conforme a lo dispuesto en la Norma Mexicana NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas), sin quesea indispensable que éstos se acompañen de leyendas.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-3758-INNTEX-2014
                <br />
                Textiles - Código de generación de etiquetas de cuidado con el uso de símbolos
                <br />
                3. Descripción y definición de símbolos
                <br />
                3.1 Símbolos
                <br />
                3.1.1 Aspectos generales
                <br />
                3.1.2 Símbolos principales

                <table className="table-content__inspector-table__table">
                  <tr>
                    <td>Figura 1 - Lavado, general</td>
                    <td>3.1.2.2 Símbolos para el proceso de blanqueo</td>
                    <td>3.1 .2.3 Símbolos para el proceso de secado</td>
                    <td>3.1.2.4 Símbolos para el proceso de planchado y presionado</td>
                    <td>3.1 .2.5 Símbolos para el proceso de cuidado textil profesional</td>
                  </tr>
                  <tr>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/lavado.png" alt="lavado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/blanqueo.png" alt="blanqueo" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/secado.png" alt="secado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/planchado.png" alt="planchado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cuidado_textil.png" alt="cuidado_textil" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          Pueden utilizarse símbolos distintos a los previstos en dicha norma, sólo cuando además aparezca en idioma español, la leyenda relativa al tratamiento adecuado e instrucciones de cuidado y conservación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 33,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          e) País de origen.
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 34,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          La información de país de origen debe cumplir con lo siguiente:
          <br />
          <br />
          <b>4.6.1</b> Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br />
          <br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br />
          <br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 35,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6.2</b> Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br />
          <br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 36,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          f) Responsable del producto.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 37,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />
          • Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />
          • Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 38,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 39,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 40,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 41,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b>
          <br />
          La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 42,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b>
          <br />
          Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 43,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b> La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 44,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3</b> Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 45,
      section: 'A',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.4</b> ALa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 46,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          Ropa de casa (excepto cobijas y cobertores)
          <br />
          a) Sábanas.
          <br />
          c) Sobrecamas.
          <br />
          d) Manteles.
          <br />
          e) Manteles individuales.
          <br />
          f) Servilletas.
          <br />
          g) Protectores.
          <br />
          h) Cortinas confeccionadas.
          <br />
          i) Toallas.
          <br />
          j) Colchones y bases de colchones elaborados o forrados con textiles.
          <br />
          k) Prendas reversibles.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 47,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 48,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 49,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          Las prendas de vestir, sus accesorios y ropa de casa, deben ostentar la siguiente información en forma legible, <b>en una o más etiquetas permanentes colocadas</b> en la parte inferior del cuello o cintura, o en cualquier otro lugar visible, de acuerdo con las características de la prenda, sus accesorios y ropa de casa en los casos y términos que señala esta Norma Oficial Mexicana, pudiéndose adicionar etiquetas códigos QR.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                <b>3.8.1 etiqueta permanente</b>
                <br />es aquella incorporada al producto, elaborada de tela o de cualquier otro material que tenga una duración cuando menos iguala la del producto al que se aplique, cosida o adherida por un proceso de termo fijación o similar que garantice su durabilidad, pudiendo también estar bordada, impresa o estampada en el producto.
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 50,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          a) Marca comercial (ver inciso 4.2).
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 51,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2 Marca comercial</b>
          <br />
          Debe señalarse la marca comercial del producto.
          <br />Cuando el producto objeto de esta Norma Oficial Mexicana ostente el nombre, denominación o razón social del responsable del producto y dicha persona utilice una marca comercial que es igual a su nombre, denominación o razón social, no es obligatorio señalar la marca comercial aludida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 52,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          b) Descripción de insumos
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 53,
      isATitle: true,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br />
          <b>4.3 Descripción de insumos</b>
          <br />
          Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          Normas que se deben de consultar
          <br />NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 54,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b> La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br />
          <br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.
          <br />
          <br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.
          <br />
          Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).
          <br />
          Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 55,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 56,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br />
          <br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 57,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br />
          <br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />
          Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />
          Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 58,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br />
          <br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana. Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 59,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 60,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 61,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 62,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 63,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br /><br />
          Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          <br />Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 64,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 65,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 66,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 67,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br />
          <br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br />
          <br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />
          No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 68,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          c) Medidas para ropa de casa.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 69,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.4.1 Las tallas de las prendas de vestir deben expresarse en idioma español, sin perjuicio de que puedan indicarse además en cualquier otro idioma en segundo término, admitiéndose para tal efecto las expresiones o abreviaturas que tradicionalmente se vienen utilizando de acuerdo con el uso cotidiano y las costumbres o aquellas abreviaturas nuevas que reflejen la inclusión detallas nuevas, acorde a las necesidades de la población mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 70,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.4.2 Las medidas de la ropa de casa y de los textiles deben expresarse de acuerdo con las unidades de medida y símbolos que correspondan al Sistema General de Unidades de Medida, como son: m, cm, mm (véase NOM-008-SCFI-2002), sin perjuicio de que se exprese en otros sistemas de unidades de medida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 71,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          d) Instrucciones de cuidado (ver inciso 4.5).
          <br />
          <br />
          Las prendas de vestir, ropa de casa y los accesorios deben ostentar exclusivamente la información relativa al tratamiento adecuado e instrucciones de cuidado y conservación que requiera el producto y determine el fabricante, debiendo comprender, en su caso, los siguientes datos:
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 72,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.1 Lavado</b>
          <br />
          a) A mano, en lavadora, en seco o proceso especial o recomendación en contrario de alguno de estos tipos de lavado.
          <br />b) Temperatura del agua.
          <br />c) Con jabón o detergente.
          <br />d) No lavar.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 73,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.2 Blanqueo</b>
          <br />
          a) Uso o no de compuestos clorados u otros blanqueadores.
          <br />b) No usar blanqueador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 74,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.3 Secado</b>
          <br />
          a) Exprimir o no exprimir, centrifugar o no centrifugar.
          <br />b) Al sol o a la sombra.
          <br />c) Secado de línea o secado horizontal.
          <br />d) Uso o recomendación en contrario de equipo especial, máquina o secadora limpieza en húmedo profesional.
          <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 75,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.4 Planchado</b>
          <br />
          a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
          <br />b) Condiciones especiales, si las hubiere.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 76,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.5.5 Recomendaciones particulares, haciendo mención específica de las tendencias al encogimiento o deformación cuando lesean propias, indicando instrucciones para atenderlas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 77,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.5.6 Las instrucciones de cuidado y conservación del producto deben indicarse por medio de leyendas breves y claras o los símbolos, conforme a lo dispuesto en la Norma Mexicana NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas), sin quesea indispensable que éstos se acompañen de leyendas.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-3758-INNTEX-2014
                <br />
                Textiles - Código de generación de etiquetas de cuidado con el uso de símbolos
                <br />
                3. Descripción y definición de símbolos
                <br />
                3.1 Símbolos
                <br />
                3.1.1 Aspectos generales
                <br />
                3.1.2 Símbolos principales

                <table className="table-content__inspector-table__table">
                  <tr>
                    <td>Figura 1 - Lavado, general</td>
                    <td>3.1.2.2 Símbolos para el proceso de blanqueo</td>
                    <td>3.1 .2.3 Símbolos para el proceso de secado</td>
                    <td>3.1.2.4 Símbolos para el proceso de planchado y presionado</td>
                    <td>3.1 .2.5 Símbolos para el proceso de cuidado textil profesional</td>
                  </tr>
                  <tr>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/lavado.png" alt="lavado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/blanqueo.png" alt="blanqueo" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/secado.png" alt="secado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/planchado.png" alt="planchado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cuidado_textil.png" alt="cuidado_textil" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          Pueden utilizarse símbolos distintos a los previstos en dicha norma, sólo cuando además aparezca en idioma español, la leyenda relativa al tratamiento adecuado e instrucciones de cuidado y conservación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 78,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          e) País de origen.
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 79,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6 País de origen</b>
          <br />
          La información de país de origen debe cumplir con lo siguiente:
          <br />
          <br />
          4.6.1 Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br />
          <br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br />
          <br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 80,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          4.6.2 Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br /><br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 81,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          f) Responsable del producto.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 82,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 83,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 84,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 85,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 86,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b> <br />
          La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 87,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b> <br />
          Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br /><br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 88,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b>
          La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 89,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3</b>
          Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 90,
      section: 'B',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.4</b>
          BLa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 91,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          Textiles
          <br />
          <br />
          Producto elaborado a partir de fibras naturales y/o fibras químicas o una mezcla de éstas, incluyéndose entre ellos, en forma enunciativa mas no limitativa:
          <br />
          <br />
          hilados,
          <br />
          hilos de coser,
          <br />
          hilos de bordar,
          <br />
          estambres,
          <br />
          telas en crudo y acabadas,
          <br />
          tejidas y no tejidas,
          <br />
          casimires,
          <br />
          pasamanerías (encajes, listones, bordados, elásticos), y similares.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 92,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 93,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 94,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          a) Descripción de insumos
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 95,
      isATitle: true,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br />
          <br />
          <b>4.3 Descripción de insumos</b>
          <br />Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          Normas que se deben de consultar
          <br />
          NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 96,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b> La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.
          <br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.
          <br />Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).
          <br />Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 97,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 98,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 99,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 100,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana. Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 101,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 102,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 103,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 104,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 105,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br />Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          <br />Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 106,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 107,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 108,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 109,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />
          No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 110,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          b) País de origen.
          <br />
          <b>Nota: Este dato debe de estar en etiqueta permanente.</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 111,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6 País de origen</b>
          <br />
          La información de país de origen debe cumplir con lo siguiente:
          <br /><br />
          4.6.1 Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br /><br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br /><br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 112,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6.2</b>
          <br />
          Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br /><br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 113,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          c) Responsable del producto.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 114,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 115,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 116,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 117,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 118,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b>
          <br />
          La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 119,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b>
          <br />
          Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br /><br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 120,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b> La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br />
          <br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 121,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3</b> Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 122,
      section: 'C',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.4</b> CLa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 123,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          Para los siguientes productos
          <br />
          a) Pantimedias.
          <br />
          b) Medias y tobimedias.
          <br />
          c) Calcetines y calcetas.
          <br />
          d) Bandas elásticas para la cabeza.
          <br />
          e) Muñequeras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 124,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 125,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 126,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          a) Marca comercial (ver inciso 4.2).
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 127,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.2 Marca comercial</b>
          <br />
          Debe señalarse la marca comercial del producto.
          <br />Cuando el producto objeto de esta Norma Oficial Mexicana ostente el nombre, denominación o razón social del responsable del producto y dicha persona utilice una marca comercial que es igual a su nombre, denominación o razón social, no es obligatorio señalar la marca comercial aludida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 128,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          b) Descripción de insumos
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo </b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 129,
      isATitle: true,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br />
          <b>(porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.</b>
          <br />
          Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          <br />
          Normas que se deben de consultar
          <br />
          NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 130,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b> La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br />
          <br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.
          <br /><br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.
          <br />Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).
          <br />Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 131,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br /><br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 132,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br /><br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 133,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br /><br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 134,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br /><br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana. Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 135,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 136,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 137,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 138,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 139,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br />Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          <br />Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 140,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 141,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 142,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 143,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />
          No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 144,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          c) Medidas o tallas
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo </b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 145,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          4.4.1 Las tallas de las prendas de vestir deben expresarse en idioma español, sin perjuicio de que puedan indicarse además en cualquier otro idioma en segundo término, admitiéndose para tal efecto las expresiones o abreviaturas que tradicionalmente se vienen utilizando de acuerdo con el uso cotidiano y las costumbres o aquellas abreviaturas nuevas que reflejen la inclusión detallas nuevas, acorde a las necesidades de la población mexicana.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 146,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          4.4.2 Las medidas de la ropa de casa y de los textiles deben expresarse de acuerdo con las unidades de medida y símbolos que correspondan al Sistema General de Unidades de Medida, como son: m, cm, mm (véase NOM-008-SCFI-2002), sin perjuicio de que se exprese en otros sistemas de unidades de medida.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 147,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          d) Instrucciones de cuidado (ver inciso 4.5).
          <br />
          <br />
          Las prendas de vestir, ropa de casa y los accesorios deben ostentar exclusivamente la información relativa al tratamiento adecuado e instrucciones de cuidado y conservación que requiera el producto y determine el fabricante, debiendo comprender, en su caso, los siguientes datos:
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 148,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.1 Lavado </b>
          <br />a) A mano, en lavadora, en seco o proceso especial o recomendación en contrario de alguno de estos tipos de lavado.
          <br />b) Temperatura del agua.
          <br />c) Con jabón o detergente.
          <br />d) No lavar.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 149,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.2 Blanqueo </b>
          <br />a) Uso o no de compuestos clorados u otros blanqueadores.
          <br />b) No usar blanqueador.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 150,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.3 Secado </b>
          <br />a) Exprimir o no exprimir, centrifugar o no centrifugar.
          <br />b) Al sol o a la sombra.
          <br />c) Secado de línea o secado horizontal.
          <br />d) Uso o recomendación en contrario de equipo especial, máquina o secadora limpieza en húmedo profesional.
          <br />e) Recomendaciones específicas de temperatura o ciclo de secado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 151,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.4 Planchado</b>
          <br />a) Con plancha tibia, caliente o vapor, o recomendación de no planchar.
          <br />b) Condiciones especiales, si las hubiere.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 152,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.5</b>
          Recomendaciones particulares, haciendo mención específica de las tendencias al encogimiento o deformación cuando lesean propias, indicando instrucciones para atenderlas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 153,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.5.6</b> Las instrucciones de cuidado y conservación del producto deben indicarse por medio de leyendas breves y claras o los símbolos, conforme a lo dispuesto en la Norma Mexicana NMX-A-3758-INNTEX-2014 (véase 2. Referencias normativas), sin quesea indispensable que éstos se acompañen de leyendas.
          <br />
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-3758-INNTEX-2014
                <br />
                Textiles - Código de generación de etiquetas de cuidado con el uso de símbolos
                <br />
                3. Descripción y definición de símbolos
                <br />
                3.1 Símbolos
                <br />
                3.1.1 Aspectos generales
                <br />
                3.1.2 Símbolos principales

                <table className="table-content__inspector-table__table">
                  <tr>
                    <td>Figura 1 - Lavado, general</td>
                    <td>3.1.2.2 Símbolos para el proceso de blanqueo</td>
                    <td>3.1 .2.3 Símbolos para el proceso de secado</td>
                    <td>3.1.2.4 Símbolos para el proceso de planchado y presionado</td>
                    <td>3.1 .2.5 Símbolos para el proceso de cuidado textil profesional</td>
                  </tr>
                  <tr>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/lavado.png" alt="lavado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/blanqueo.png" alt="blanqueo" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/secado.png" alt="secado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/planchado.png" alt="planchado" />
                    </td>
                    <td>
                      <img className="table-content__inspector-table__table__image" src="https://s3.amazonaws.com/images.cecsa.com/documents-factory/cuidado_textil.png" alt="cuidado_textil" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          Pueden utilizarse símbolos distintos a los previstos en dicha norma, sólo cuando además aparezca en idioma español, la leyenda relativa al tratamiento adecuado e instrucciones de cuidado y conservación.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 154,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          e) País de origen.
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 155,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6 País de origen</b>
          <br />
          a información de país de origen debe cumplir con lo siguiente:
          <br /><br />
          <b>4.6.1</b> Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br /><br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br /><br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 156,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6.2</b>Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br />
          <br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 157,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          f) Responsable del producto.
          <br />
          <b>Esta información debe presentarse en etiqueta temporal o permanente, en la caja, contenedor, empaque, fajilla en el que se ofrezca al público consumidor, o en el producto mismo </b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 158,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 159,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 160,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 161,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 162,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b> La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 163,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b> Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br />
          <br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 164,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b> La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br />
          <br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 165,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3</b> Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 166,
      section: 'D',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.4</b> DLa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 167,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.2 Textiles y otros productos elaborados con fibras o telas</b>
          <br />
          <br />
          a) Cortes de tela acondicionados para la venta al por menor, que contengan 45 % o más de lana peinada, que no excedan de cinco metros (Casimires).
          <br />
          b) Bolsos de mano.
          <br />
          c) Maletas.
          <br />
          d) Monederos.
          <br />
          e) Billeteras.
          <br />
          f) Estuches
          <br />
          g) Mochilas.
          <br />
          h) Paraguas y parasoles.
          <br />
          i) Cubreasientos.
          <br />
          j) Artículos para cubrir electrodomésticos y domésticos.
          <br />
          k) Cubiertas para planchadores.
          <br />
          l) Cubiertas para muebles de baño.
          <br />
          m) Cubiertas para muebles.
          <br />
          n) Cojines.
          <br />
          ñ) Artículos de limpieza.
          <br />
          o) Pañales.
          <br />
          p) Lienzos para pintores.
          <br />
          q) Canguro para bebé.
          <br />
          r) Pañaleras.
          <br />
          s) Baberos.
          <br />
          t) Cambiadores.
          <br />
          u) Cinturones textiles.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 168,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 169,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 170,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          a) Descripción de insumos
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 171,
      isATitle: true,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br />4.3 Descripción de insumos
          <br />Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          <br />
          Normas que se deben de consultar
          <br />
          NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 172,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b>  La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br />
          <br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.

          <br />
          <br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.

          <br />Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).

          <br />Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 173,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br /><br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 174,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br />
          <br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).

        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 175,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br /><br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 176,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br /><br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana. Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 177,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 178,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 179,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 180,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 181,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br />Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          <br />Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 182,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 183,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 184,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 185,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />
          No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 186,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          b) País de origen.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 187,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6 País de origen</b>
          <br />
          La información de país de origen debe cumplir con lo siguiente:
          <br /><br />
          4.6.1 Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br /><br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br /><br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 188,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6.2</b>Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br />
          <br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 189,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          c) Responsable del producto.
          <br />
          <b>Nota: Este dato puede estar en etiqueta temporal o permanente</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 190,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 191,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 192,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 193,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 194,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b> La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 195,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b> Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 196,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b>
          <br />
          La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 197,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.3</b>
          <br />
          Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 198,
      section: 'E',
      text: (
        <p className="table-content__row__cell--text">
          <b>5.4</b>
          <br />
          ELa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 199,
      isATitle: true,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>En los siguientes productos</b>
          <br />
          <br />
          a) Telas tejidas y no tejidas de cualquier índole.
          <br />
          b) Alfombras, bajo alfombras y tapetes de materiales textiles.
          <br />
          c) Pelucas.
          <br />
          d) Artículos para el cabello (salvo aquellos que por sus pequeñas dimensiones deban empacarse a granel). Cuando la presentación para su venta al consumidor final de estos artículos se presente en paquete, la información comercial debe estar contenida en el paquete.
          <br />
          e) Corbatas de moño.
          <br />
          f) Artículos destinados a ser utilizados en una sola ocasión (desechables). En este caso, la información a que se refiere el inciso 4.1.2 puede presentarse en el envase.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 200,
      isATitle: true,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          4 Especificaciones de información
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 201,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          4.1 Información comercial
          <br />La información acerca de los productos objeto de esta Norma Oficial Mexicana, debe presentarse en idioma español, ser veraz, describirse y presentarse de forma tal que no induzca a error con respecto a la naturaleza y características del producto.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 202,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          a) Descripción de insumos
          <br />
          <b>Nota: Este dato puede estar en etiqueta permanente o temporal adherida o amarrada al producto</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 203,
      isATitle: true,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          (porcentaje en masa en orden de predominio, conforme a lo dispuesto en las Normas MexicanasNMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013), véase 2. Referencias normativas debiendo ser con número arábigo del 1 al 100.
          <br /><br />
          4.3 Descripción de insumos
          <br />Para efectos de esta Norma Oficial Mexicana, el responsable del producto debe expresar los insumos en porcentaje, con relación a la masa de las diferentes fibras que integran el producto en orden del predominio de dicho porcentaje, conforme a las siguientes indicaciones:
          <br />
          Normas que se deben de consultar
          <br />
          NMX-A-2076-INNTEX-2013, Industria textil-Fibras químicas-Nombres genéricos (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-2076-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
                <br />TABLA 2 - Índice alfabético de nombres genéricos.

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre</td>
                  </tr>
                  <tr>
                    <td>acetato</td>
                  </tr>
                  <tr>
                    <td>acrílico</td>
                  </tr>
                  <tr>
                    <td>alginato</td>
                  </tr>
                  <tr>
                    <td>aramida</td>
                  </tr>
                  <tr>
                    <td>carbón</td>
                  </tr>
                  <tr>
                    <td>clorofibra</td>
                  </tr>
                  <tr>
                    <td>cupro</td>
                  </tr>
                  <tr>
                    <td>elastano</td>
                  </tr>
                  <tr>
                    <td>elastodieno</td>
                  </tr>
                  <tr>
                    <td>elastolefin o lastol</td>
                  </tr>
                  <tr>
                    <td>elastomultiéster o elasterell - p</td>
                  </tr>
                  <tr>
                    <td>fluorofibra</td>
                  </tr>
                  <tr>
                    <td>vidrio</td>
                  </tr>
                  <tr>
                    <td>lyocell</td>
                  </tr>
                  <tr>
                    <td>metal</td>
                  </tr>
                  <tr>
                    <td>modacrílica</td>
                  </tr>
                  <tr>
                    <td>modal</td>
                  </tr>
                  <tr>
                    <td>poliamida o nylon</td>
                  </tr>
                  <tr>
                    <td>poliéster</td>
                  </tr>
                  <tr>
                    <td>polietileno</td>
                  </tr>
                  <tr>
                    <td>polilactida o PLA</td>
                  </tr>
                  <tr>
                    <td>poliimida</td>
                  </tr>
                  <tr>
                    <td>polipropileno</td>
                  </tr>
                  <tr>
                    <td>triacetato</td>
                  </tr>
                  <tr>
                    <td>vinilo</td>
                  </tr>
                  <tr>
                    <td>viscosa o rayón</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <br />
          NMX-A-6938-INNTEX-2013, Industria textil-Fibras naturales-Nombres genéricos y definiciones (Cancela a la NMX-A-099-INNTEX-2007), publicada su declaratoria de vigencia en el Diario Oficial de la Federación el 06 de noviembre de 2014.
          <table className="table-content__inspector-table">
            <tr className="table-content__inspector-table__header">
              <td>Apoyo para el inspector</td>
            </tr>
            <tr className="table-content__inspector-table__text">
              <td>
                NMX-A-6938-INNTEX-2013
                <br />INDUSTRIA TEXTIL - FIBRAS NATURALES - NOMBRES GENÉRICOS Y DEFINICIONES
                <br />4 LISTA DE NOMBRES COMUNES, NOMBRES GENÉRICOS EQUIVALENTES Y EL NÚMERO CORRESPONDIENTE AL ÍNDICE

                <table className="table-content__inspector-table__table" style={{ width: '260px' }}>
                  <tr>
                    <td>Nombre común</td>
                    <td>Nombre genérico equivalente</td>
                  </tr>
                  <tr>
                    <td>ABACA</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>ABUTILON</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>ADELFA AZULOSO</td>
                    <td>ADELFA AZULOSO</td>
                  </tr>
                  <tr>
                    <td>Ake ire</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>AKUND</td>
                    <td>AKUND</td>
                  </tr>
                  <tr>
                    <td>ALFA</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>ALGODÓN</td>
                    <td>ALGODÓN</td>
                  </tr>
                  <tr>
                    <td>ÁLOE</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>ALPACA</td>
                    <td>ALPACA</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>ANGORA</td>
                    <td>ANGORA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARAMINA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>ARMIÑO</td>
                    <td>ARMIÑO</td>
                  </tr>
                  <tr>
                    <td>ASBESTO o AMIANTO</td>
                    <td>ASBESTO o AMIANTO</td>
                  </tr>
                  <tr>
                    <td>BAMBÚ</td>
                    <td>BAMBÚ</td>
                  </tr>
                  <tr>
                    <td>BAMIA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Ban okra</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Bimbli</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>BISO</td>
                    <td>BISO</td>
                  </tr>
                  <tr>
                    <td>Bolo-Bolo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CABALLO</td>
                    <td>CABALLO</td>
                  </tr>
                  <tr>
                    <td>CABRA</td>
                    <td>CABRA</td>
                  </tr>
                  <tr>
                    <td>CAMELLO</td>
                    <td>CAMELLO</td>
                  </tr>
                  <tr>
                    <td>Candillo - a</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Canhamo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Cantalá</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>CÁÑAMO</td>
                    <td>CÁÑAMO</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Benaris ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Bombay</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Brown ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Coconada ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Guinea ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de la India ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Madras ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Manila ª</td>
                    <td>ABACA</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Mauricio ª</td>
                    <td>ÁLOE</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Nueva Zelanda a</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Deccan</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Gambo ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Itersi ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Jubblepore ª</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo negro de Philibit</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo Sta Elena</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>Cáñamo de Seonie</td>
                    <td>Sunn</td>
                  </tr>
                  <tr>
                    <td>Carrapicho</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>CASHMERE</td>
                    <td>CASHMERE</td>
                  </tr>
                  <tr>
                    <td>CASTOR</td>
                    <td>CASTOR</td>
                  </tr>
                  <tr>
                    <td>César malezas</td>
                    <td>UEÑA</td>
                  </tr>
                  <tr>
                    <td>Chingma</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>CIERVO</td>
                    <td>CIERVO</td>
                  </tr>
                  <tr>
                    <td>COCO</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>Cojín Roio</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>COMADREJA</td>
                    <td>COMADREJA</td>
                  </tr>
                  <tr>
                    <td>CONEJO</td>
                    <td>CONEJO</td>
                  </tr>
                  <tr>
                    <td>Culotan</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Culut</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Da, Dha, Dah</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Esparto</td>
                    <td>ALFA</td>
                  </tr>
                  <tr>
                    <td>Fibra de coco</td>
                    <td>COCO</td>
                  </tr>
                  <tr>
                    <td>FIBRA DE JUNCO</td>
                    <td>FIBRA DE JUNCO</td>
                  </tr>
                  <tr>
                    <td>FIQUE</td>
                    <td>FIQUE</td>
                  </tr>
                  <tr>
                    <td>FOCA</td>
                    <td>FOCA</td>
                  </tr>
                  <tr>
                    <td>FORMIO</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>GEMA</td>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>Gogu (forma de gallo)</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Gonama</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>GUANACO</td>
                    <td>GUANACO</td>
                  </tr>
                  <tr>
                    <td>Guaxima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Guiazo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>HENEQUÉN</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Hierba China</td>
                    <td>RAMlO</td>
                  </tr>
                  <tr>
                    <td>HOJA DE PINA</td>
                    <td>HOJA DE PINA</td>
                  </tr>
                  <tr>
                    <td>IXTLE DE PALMA</td>
                    <td>IXTLE DE PALMA</td>
                  </tr>
                  <tr>
                    <td>KAPOK</td>
                    <td>KAPOK</td>
                  </tr>
                  <tr>
                    <td>KENAF</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>LANA</td>
                    <td>LANA</td>
                  </tr>
                  <tr>
                    <td>LlEBRE</td>
                    <td>LlEBRE</td>
                  </tr>
                  <tr>
                    <td>LINO</td>
                    <td>LINO</td>
                  </tr>
                  <tr>
                    <td>Lino de Nueva Zelanda ª</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>LLAMA</td>
                    <td>LLAMA</td>
                  </tr>
                  <tr>
                    <td>MAGUEY</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Malva</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>MARTA</td>
                    <td>MARTA</td>
                  </tr>
                  <tr>
                    <td>Mesta</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>MIJO</td>
                    <td>MIJO</td>
                  </tr>
                  <tr>
                    <td>MOHAIR</td>
                    <td>MOHAIR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>Nanas Sabrong</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>NUTRIA</td>
                    <td>NUTRIA</td>
                  </tr>
                  <tr>
                    <td>Ñandu</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>ORTlGA</td>
                    <td>ORTlGA</td>
                  </tr>
                  <tr>
                    <td>OSO</td>
                    <td>OSO</td>
                  </tr>
                  <tr>
                    <td>Ototo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Paka</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PHORMIUM</td>
                    <td>PHORMIUM</td>
                  </tr>
                  <tr>
                    <td>Piña</td>
                    <td>HOJA DE PIÑA</td>
                  </tr>
                  <tr>
                    <td>PITA</td>
                    <td>PITA</td>
                  </tr>
                  <tr>
                    <td>Poepoes</td>
                    <td>MAGUEY</td>
                  </tr>
                  <tr>
                    <td>Primo</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>PUNGA</td>
                    <td>PUNGA</td>
                  </tr>
                  <tr>
                    <td>RAMIO</td>
                    <td>RAMIO</td>
                  </tr>
                  <tr>
                    <td>RATA ALMIZCLERA</td>
                    <td>RATA ALMIZCLERA</td>
                  </tr>
                  <tr>
                    <td>RENO</td>
                    <td>RENO</td>
                  </tr>
                  <tr>
                    <td>RESIDUOS DE CÁÑAMO ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>RICINO</td>
                    <td>RICINO</td>
                  </tr>
                  <tr>
                    <td>ROSELLA</td>
                    <td>ROSELLA</td>
                  </tr>
                  <tr>
                    <td>SABLE</td>
                    <td>SABLE</td>
                  </tr>
                  <tr>
                    <td>SEDA</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td>Seda Mora</td>
                    <td>SEDA</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda no de Morera</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td rowSpan={4}>Seda Salvaje</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>MUGA</td>
                  </tr>
                  <tr>
                    <td>ERI</td>
                  </tr>
                  <tr>
                    <td>ANAPHE</td>
                  </tr>
                  <tr>
                    <td>Sireta</td>
                    <td>CÁNAMO</td>
                  </tr>
                  <tr>
                    <td>SISAL</td>
                    <td>SISAL</td>
                  </tr>
                  <tr>
                    <td>Sisal Cubano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Sisal Mexicano ª</td>
                    <td>HENEQUÉN</td>
                  </tr>
                  <tr>
                    <td>Stockroos</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>SUNN</td>
                    <td>SUNN</td>
                  </tr>
                  <tr>
                    <td>TAMPICO</td>
                    <td>TAMPICO</td>
                  </tr>
                  <tr>
                    <td>TASAR</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>Teal</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>Toja</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>TORO</td>
                    <td>TORO</td>
                  </tr>
                  <tr>
                    <td>Tusor</td>
                    <td>TASAR</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>VICUÑA</td>
                    <td>VICUÑA</td>
                  </tr>
                  <tr>
                    <td>VlSON</td>
                    <td>VlSON</td>
                  </tr>
                  <tr>
                    <td>Vocima</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>YAK</td>
                    <td>YAK</td>
                  </tr>
                  <tr>
                    <td>YUTE</td>
                    <td>YUTE</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Yute Brasileño ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Chino ª</td>
                    <td>ABUTILON</td>
                  </tr>
                  <tr>
                    <td>Yute Congo ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute Cubano ª</td>
                    <td>UREÑA</td>
                  </tr>
                  <tr>
                    <td>Yute de Java ª</td>
                    <td>ROSELLE</td>
                  </tr>
                  <tr>
                    <td>Yute SIAM ª</td>
                    <td>KENAF</td>
                  </tr>
                  <tr>
                    <td>ZORRO DEL ÁRTICO</td>
                    <td>ZORRO DEL ÁRTICO</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 204,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.1</b> La denominación de las fibras, debe señalarse con los nombres genéricos conforme a lo establecido en las Normas Mexicanas NMX-A-2076-INNTEX-2013 y NMX-A-6938-INNTEX-2013, (véase 2. Referencias normativas), por lo que hace aquellas fibras no comprendidas en dichas Normas Mexicanas, debe señalarse el nombre técnico.
          <br /><br />
          Para estos efectos, es obligatorio el uso de nombres genéricos de las fibras, contenidos en las normas antes señaladas, por lo que no es aceptable el uso de abreviaturas o nombres diferentes a los ahí indicados. Cuando la norma citada contemple más de un término para denominar una fibra se podrá utilizar cualquiera de los términos señalados siempre que corresponda a la fibra de que se trate.
          <br /><br />
          Ejemplo 1.-: 70 % poliéster 30% Algodón.
          <br />Ejemplo 2.- Suéter Tela 100% acrílico; insertos 100% poliéster (en el caso de los suéteres que tengan parches en los codos).
          <br />Ejemplo 3.- Sostén o brasier Tela 80% poliamida 20% elastano; encaje 100% poliéster; forro de encaje 100% poliamida; copa100% poliéster; tirante 75% poliamida- 25% elastano; resto 85% poliamida 15% elastano (datos reales de un sostén).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 205,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          De acuerdo al apartado 2.2 Nombre genérico de las NMX-A-2076-INNTEX-2013 INDUSTRIA TEXTIL - FIBRAS QUÍMICAS - NOMBRES GENÉRICOS
          <br /><br />
          El nombre genérico deberá ser escrito sin mayúsculas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 206,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.2</b> Toda fibra que se encuentre presente en un porcentaje igual o mayor al 5 % del total de la masa, debe expresarse por su nombre genérico.
          <br />
          Puede usarse el nombre comercial o la marca registrada de alguna fibra si se tiene la autorización del titular, siempre que se use en conjunción al nombre genérico de la fibra en caracteres de igual tamaño dentro de paréntesis o comillas, por ejemplo: 100% elastano (spandex).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 207,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.3</b> Las fibras presentes en un porcentaje menor al 5 % del total, pueden designarse como "otras" u "otras fibras", siendo indistinto el uso de letras mayúsculas, minúsculas o letra inicial mayúscula y el resto en minúsculas, en este caso en particular.
          <br /><br />
          Cuando estas fibras o insumos están presentes en más de dos fibras menores al 5 %, pueden ser sumadas al rubro de "otras" u "otras fibras".
          <br />Ejemplo 1: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras.
          <br />Ejemplo 2: 60 % Algodón, 30 % poliéster, 4 % poliamida, 4 % elastano y 2 % acrílico; puede declararse como: 60 % Algodón,30 % poliéster, 10 % otras fibras.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 208,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.4</b> En los productos objeto de esta norma integrados por dos o más fibras, debe mencionarse cada una de aquellas fibras que representen cuando menos 5 % hasta completar 100 %.
          <br />
          El término "Lana" incluye fibra proveniente del pelaje de oveja o cordero, o de pelo de Angora o Cachemira (y puede incluir fibras  provenientes del pelo de camello, alpaca, llama y vicuña), la cual nunca ha sido obtenida de algún tejido o producto fieltrado de lana. Ejemplo: 55 % Llama, 45 % Alpaca; se puede expresar como: 100 % Lana, exclusivamente para los artículos de calcetería. Para las demás prendas, telas e hilados debe expresarse el nombre correspondiente de acuerdo con la Norma Mexicana NMX-A-6938-INNTEX-2013 (véase 2. Referencias normativas).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 209,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.5</b> Cuando los productos objeto de esta norma, hayan sido elaborados o confeccionados con desperdicios, sobrantes, lotes diferentes, subproductos textiles, que sean desconocidos o cuyo origen no se pueda demostrar, debe indicarse el porcentaje de fibras que encuadren en este supuesto, o en su defecto con la leyenda ".... (porcentaje) de fibras recuperadas".
          <br />Ejemplo 1. 70 % Algodón lotes diferentes / 30 % fibras recuperadas;
          <br />Ejemplo 2. 80 % Algodón / 20 % fibras recuperadas o
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 210,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.6</b> Cuando se usen fibras recuperadas o recicladas o mezclas de éstas con otras fibras vírgenes o con otras fibras recuperadas o recicladas, deben señalarse los porcentajes y los nombres genéricos de cada una de las fibras que integren los productos, anotando las palabras "recuperado(a)" o reciclado(a)" después del nombre de la fibra.
          <br />Ejemplo 1. 65 % poliéster / 35 % fibras recuperadas (Algodón / viscosa / acrílico);
          <br />Ejemplo 2. 55 % poliéster / 22 % poliéster reciclado (PET) / 20 % acrílico / 3 % elastodieno.
          <br />Ejemplo 3. 100 % fibras recuperadas
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 211,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.7</b> Sólo se permite utilizar los términos "virgen" o "nuevo" cuando la totalidad de las fibras integrantes de los productos objeto de esta norma sean vírgenes o nuevas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 212,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.8</b> No se debe utilizar el nombre de animal alguno al referirse a las fibras que integren a los productos objeto de está norma, a menos que la fibra o el textil estén elaborados con el pelo desprendido de la piel del animal de que se trate.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 213,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.9</b> Se permite una tolerancia de ± 3 puntos porcentuales para los insumos de textiles, sobre lo declarado en la etiqueta permanente para, ropa de casa, prendas de vestir y sus accesorios cuando hay presencia de dos o más fibras o insumos presentes.
          <br />Ejemplo: cuando se declara 40 % Algodón, la cantidad de fibra puede variar de 37 % de Algodón como mínimo hasta 43 % de Algodón como máximo.
          <br /><br />
          Para el caso de textiles técnicos que contengan un contenido de fibra iguales o menores al 4.9 %, se permite una tolerancia de+/- 0.5 porcentual y hasta valores máximos de 4.4 % y mínimos de 1 % sin que rebase el porcentaje marcado.
          <br /><br />
          Ejemplo: cuando se declara 3 % de grafeno, la cantidad de fibra puede variar de 2.5 % a 3.5 %.
          <br /><br />
          Para los otros productos objetos de esta norma que declaren contenidos de fibras inferiores al 4.9 %, el mínimo contenido debe ser al menos 1 %.
          <br /><br />
          La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto.
          <br /><br />
          Excepto para lo dispuesto en 4.3.10 y 4.3.11 de la presente Norma Oficial Mexicana.
          <br />Cuando se utilicen expresiones como "100 %", "Pura..." o "Todo..." al referirse a los insumos del producto, no aplica tolerancia alguna."
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 214,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.10</b> Se permite una tolerancia de ± 3 puntos porcentuales considerada sobre lo declarado en la etiqueta. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto ,y lo señalado en el inciso 4.3.9, en los siguientes casos:
          <br />a) cintas elásticas;
          <br />b) medias y pantimedias en cuya manufactura intervienen insumos elaborados con fibras elastoméricas de origen natural osintético;
          <br />c) entorchados, hilos, hilados e hilazas de fantasía.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 215,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.11</b> Para los productos siguientes se permite una tolerancia de ± 6 puntos porcentuales cuando hay presencia de dos o más fibras presentes, considerados sobre la información comercial que se indique en la etiqueta de cada una de las fibras o insumos. La evaluación de dicha tolerancia debe considerarse sobre la masa que represente el porcentaje señalado, respecto de la masa total del producto:
          <br />a) Calcetas, calcetines, tobilleras, tines y calcetas deportivas.
          <br />Ejemplo 1.- Calcetín 95% algodón 5% elastodieno; dibujo 100% acrílico (en el caso de dibujos que formen parte del tejido).
          <br />Ejemplo 2.- Calcetín 95% algodón 5% elastodieno; bordado 100% acrílico (en el caso de dibujos sea por bordado).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 216,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.12</b> Deben indicarse en la etiqueta aquellos insumos de los productos objeto de esta norma que hayan sido incorporados a las mismas exclusivamente para efectos ornamentales, de protección o armado, cuando su masa exceda de 5 % sobre la masa total del producto o su superficie exceda de 15 % de la superficie total del mismo. Pudiéndose declarar por separado.
          <br />Ejemplo 1: Vestido: Tela 100% algodón; ornamentos 100% poliéster.
          <br />Ejemplo 2: Cuerpo 96% viscosa; encaje 4% poliéster.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 217,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.3.13</b> Para el caso de los productos objeto de esta norma elaborados o confeccionados con materiales textiles, aun cuando contengan plásticos u otros materiales, la declaración de los insumos diferentes a las fibras textiles se deberá realizar conforme a lo siguiente:
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que contengan plásticos u otros materiales, dichos materiales deben denominarse por su nombre genérico o específico pudiendo utilizarse abreviaturas de dominio públicocomo, por ejemplo: policloruro de vinilo (PVC), etil-vinil-acetato (EVA), acrilonitrilo -butadieno-estireno (ABS).
          <br /><br />
          Para el caso de productos textiles elaborados con más del 50 % de materia textil y que estén combinados con insumos de piel, debe declararse dicho porcentaje con el nombre específico o común del animal.
          <br />Ejemplo 1. EXTERIOR: 100 % poliéster, BASE: 100% piel vacuna, FORRO: 100 % poliéster.
          <br />Nota: el ejemplo 1 corresponde a un producto denominado mochila, objeto de la presente NOM.
          <br />Ejemplo 2. CUERPO: 100 % poliéster, MANGAS: 100 % piel ovina, FORRO: 100 % poliéster.
          <br /><br />
          No debe utilizarse la mezcla de palabras que impliquen o tiendan a hacer creer la existencia de componentes derivados de la piel o el pelo o producto de animal alguno si no están presentes en los productos objeto de esta Norma.
          <br />Queda prohibido emplear los términos "piel", "cuero", "piel sintética" o similares para referirse a materiales sintéticos o artificiales.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 218,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          b) País de origen.
          <br />
          <b>Nota: Este dato puede estar en etiqueta permanente o temporal adherida o amarrada al producto</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 219,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6 País de origen</b> La información de país de origen debe cumplir con lo siguiente:
          <br /><br />
          4.6.1 Cuando el producto terminado, así como todos sus insumos se hayan elaborado o producido en el mismo país, se debe utilizar preferentemente la expresión "hecho en ... (país de origen)", "elaborado en ... (país de origen)" u otra análoga.
          <br /><br />
          El país de origen será expresado en idioma español o conforme a las claves de países que las Reglas de Carácter General en Materia de Comercio Exterior vigentes.
          <br /><br />
          Podrán utilizarse las siguientes abreviaturas, Estados Unidos de América: EUA, USA, EE.UU., en mayúsculas o minúsculas, con puntos o sin puntos. Para Reino Unido de la Gran Bretaña e Irlanda del Norte: GBR, Reino Unido.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 220,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.6.2</b> Cuando el producto haya sido elaborado en un país con insumos de otros, se debe utilizar la leyenda "Hecho en... (país de elaboración) con insumos importados", pudiéndose adicionar de manera voluntaria el origen de los insumos utilizados.
          <br /><br />
          El país de elaboración será expresado en idioma español o conforme al listado de países indicados en las Reglas Generalesde Comercio Exterior vigentes.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 221,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          c) Responsable del producto.
          <br />
          <b>Nota: Este dato puede estar en etiqueta permanente o temporal adherida o amarrada al producto</b>
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 222,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          Para productos nacionales se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del fabricante. En el caso de
          <br />Marcas internacionales puede ser el licenciatario.
          <br /><br />
          Para productos importados se debe incluir lo siguiente:
          <br />• Para personas físicas o morales: nombre, domicilio fiscal y RFC del importador.
          <br />Los datos referidos en el inciso f), deben presentarse en cualquiera de las etiquetas permanentes o temporal (ver 3.8.1, 3.8.3) o en su empaque cerrado.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 223,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.3</b> Cuando el producto se comercialice en empaque cerrado que no permita ver el contenido, adicionalmente a la información señalada anteriormente, en dicho empaque debe indicarse la denominación del producto y la cantidad de productos contenidos en el mismo.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 224,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.4</b> Toda la información comercial requerida en la presente Norma Oficial Mexicana debe presentarse en idioma español, en los términos de la Ley Federal de Protección al Consumidor, sin perjuicio de que además pueda presentarse en cualquier otro idioma.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 225,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.5</b> Cuando las prendas de vestir se comercialicen como pares confeccionados del mismo material, pueden presentar la etiqueta en una sola de las piezas.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 226,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.1.6 Cuando el producto tenga forro</b> <br />La información comercial puede presentarse en la misma etiqueta o en otra, siempre que se indique expresamente que es la información correspondiente al forro, mediante la indicación "forro: ..." u otra equivalente.
          <br />Cuando el forro sea del mismo contenido de fibra que el de la prenda de vestir no será obligatorio declarar la información del forro.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 227,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7 Acabados</b> <br />Cuando se utilice información sobre acabado del producto, ésta debe acompañarse del nombre del proceso, por ejemplo: "Impermeabilizado, pre encogido, mercerizado, etc.", mencionado de manera enunciativa más no limitativa.
          <br /><br />
          Para los textiles o ropa de protección debe indicar como parte del etiquetado la protección de acuerdo con el Apéndice A (normativo).
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 228,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          <b>4.7.1</b> <br />La leyenda "Hecha a mano" puede utilizarse únicamente cuando el producto haya sido confeccionado, elaborado o producido totalmente a mano.
          <br /><br />
          La indicación "a mano" debe ir acompañada de la descripción de aquella parte del proceso que se haya realizado a mano, por ejemplo, "cosida a mano".
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 229,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          5.3 Cuando se comercialicen conjuntos que incluyan diferentes productos sujetos a la presente Norma Oficial Mexicana, cada uno de ellos debe cumplirla individualmente.
        </p>
      )
    },
    {
      pageNumber: 2,
      questionNumber: 230,
      section: 'F',
      text: (
        <p className="table-content__row__cell--text">
          5.4 FLa inclusión de una etiqueta con código QR, en cualquier caso, se constituye como estrictamente OPCIONAL y ADICIONAL, y la misma no sustituye al etiquetado temporal y/o permanente.
        </p>
      )
    }
  ]
};

export default {
  DC: {
    hasPackingTable: false,
    hasSamplingTable: true,
    hasMeasurementEquipment: false,
    ...list
  },
  CC: {
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    ...list
  },
  REV: {
    hasPackingTable: false,
    hasSamplingTable: false,
    hasMeasurementEquipment: false,
    ...list
  }
};
