import dayjs from 'dayjs';

import { useLocation, useParams } from 'react-router-dom';
import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Header,
  Icon,
  Modal,
  Select,
  Table,
  Title,
  File
} from '../../components';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import { useNavigate, useResource } from '../../hooks';
import {
  MonthlyClosing,
  SelectOption,
  SelectedInspection,
  User
} from '../../interfaces';
import monthsEs from '../../dictionaries/months-es';
import { emptyMonthlyClosing, emptyUser } from '../../emptyObjects';
import { utils } from '../../helpers';
import monthsEn from '../../dictionaries/months-en';

interface LocalSelectedInspection extends SelectedInspection {
  'complies-icon': ReactElement
}

const MonthlyClosings = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const {
    monthlyClosingID
  } = params;

  const {
    setOpenModal,
    soundEffects,
    userRole
  } = useContext(AppContext);

  const {
    createResource
  } = useResource<any>();

  const {
    fetchResources
  } = useResource<MonthlyClosing>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const [monthlyClosings, setMonthlyClosings] = useState<MonthlyClosing[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());
  const [selectedItem, setSelectedItem] = useState<MonthlyClosing>(emptyMonthlyClosing);
  const [customInspections, setCustomInspections] = useState<LocalSelectedInspection[]>([]);
  const [user, setUser] = useState<User>(emptyUser);

  const throttledGetUser = utils.throttle(
    () => {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledGetUser();
  }, []);

  const getMonthlyClosings = () => {
    fetchResources(
      {
        resourcePath: 'admin/monthly-closings'
      },
      (data) => {
        setMonthlyClosings(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const throttledGetMonthlyClosings = utils.throttle(getMonthlyClosings, 1000);

  const getYearsFromDates = (): SelectOption[] => {
    const years = new Set<number>();
    const data: SelectOption[] = [];

    monthlyClosings.forEach(dateString => {
      const date = dayjs(dateString.createdAt);
      years.add(date.year());
    });

    const yearsArray = Array.from(years);

    for (let index = 0; index < yearsArray.length; index += 1) {
      data.push({
        value: yearsArray[index],
        text: `${yearsArray[index]}`
      });
    }

    return data;
  };

  useEffect(() => {
    throttledGetMonthlyClosings();
  }, [location.pathname]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    setSelectedItem(monthlyClosings.find((item) => item.id === monthlyClosingID) || emptyMonthlyClosing);
  }, [monthlyClosingID, monthlyClosings]);

  const setCompliesIcon = (complies: boolean | null) => {
    if (complies === false) {
      return 'circleError';
    }
    if (complies === true) {
      return 'circleSucces';
    }

    return 'circleUnconcluded';
  };

  const handleGenerateDetectionOfRisksToImpartiality = async () => {
    createResource(
      `/admin/monthly-closings/${selectedItem.id}/detection-of-risks-to-impartiality`,
      {},
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = 'F4-01 DETECCION RIESGOS A LA IMPARCIALIDAD.pdf';
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleGenerateDetectionOfTrainingNeeds = async () => {
    createResource(
      `/admin/monthly-closings/${selectedItem.id}/detection-of-training-needs`,
      {},
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = 'F6-04 DETECCION NECESIDADES DE CAPACITACIÓN.pdf';
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const handleGenerateReportingSupervision = async () => {
    createResource(
      `/admin/monthly-closings/${selectedItem.id}/reporting-supervision`,
      {},
      (data) => {
        Modal.fireLoading(setOpenModal);

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = 'SUPERVISION DE INFORMES.pdf';
        link.click();

        Modal.close(setOpenModal);
      },
      (error) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    setCustomInspections(
      selectedItem.inspections.map((inspection: SelectedInspection) => {
        return ({
          ...inspection,
          'complies-icon': (
            <div className='monthly-closings__icon-status'>
              <Icon type={setCompliesIcon(inspection.complies)} className='task-detail__alert-status__icon' />
            </div>
          )
        });
      })
    );
  }, [selectedItem.inspections]);

  return (
    <div className="monthly-closings">
      {
        selectedItem.id !== '' ? (
          <>
            <Header
              title={t('global.monthlyClosing', {
                month: i18n.language === 'es' ? (monthsEs as any)[dayjs(selectedItem.beginningPeriod).format('MMMM').toUpperCase()] : (monthsEn as any)[dayjs(selectedItem.beginningPeriod).format('MMMM').toUpperCase()],
                year: selectedYear,
                invoice: '',
                status: utils.formatValue('status', selectedItem.status)
              }) || ''}
              showBackbutton={true}
              onGoBack={() => navigate('/monthly-closings')}
            />
            <div className="monthly-closings__main">
              <Title title={t('global.inspections')} />
              <Table
                headers={[
                  {
                    label: t('global.norm'),
                    value: 'norm',
                    format: 'none'
                  },
                  {
                    label: t('global.invoice'),
                    value: 'product.invoice',
                    format: 'none'
                  },
                  {
                    label: t('global.subInvoice'),
                    value: 'product.subInvoice',
                    format: 'none'
                  },
                  {
                    label: t('services.inspectorName'),
                    value: 'inspector.name',
                    format: 'none'
                  },
                  {
                    label: t('global.approver'),
                    value: 'approver.name',
                    format: 'none'
                  },
                  {
                    label: t('global.supervised'),
                    value: 'supervised.name',
                    format: 'none'
                  },
                  {
                    label: t('global.result'),
                    value: 'complies-icon',
                    format: 'custom-component'
                  },
                  {
                    label: t('global.approveDate'),
                    value: 'reviewDate',
                    format: 'date-time'
                  }
                ]}
                items={customInspections}
                onClickRow={(item: any) => {
                  navigate(`/monthly-closings/${selectedItem.id}/${item.id}`);
                }}
              />
              {
                ['in-progress', 'available'].includes(selectedItem.status)
                && userRole !== 'master' && (
                  <div className="monthly-closings__main__finish-button">
                    <Button
                      type={'primary'}
                      onClick={() => navigate(`/monthly-closings-finish/${selectedItem.id}`)}
                      label={t('global.finish') || ''}
                      size='big'
                      icon='check'
                      disabled={
                        !customInspections.every((item) => item.complies !== null)
                        || user.technicalManager === false
                        || !['in-progress', 'available'].includes(selectedItem.status)
                      }
                    />
                  </div>
                )
              }
            </div>
            {
              selectedItem.status === 'concluded' && (
                <div className="monthly-closings__documents">
                  <File
                    name={'SUPERVISION DE INFORMES.pdf'}
                    onDownload={handleGenerateReportingSupervision}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                  {
                    selectedItem.detectionOfRisksToImpartiality && (
                      <File
                        name={'F4-01 DETECCION RIESGOS A LA IMPARCIALIDAD.pdf'}
                        onDownload={handleGenerateDetectionOfRisksToImpartiality}
                        orientation='horizontal'
                        showToolTip={false}
                      />
                    )
                  }
                  {
                    selectedItem.detectionOfTrainingNeeds && (
                      <File
                        name={'F6-04 DETECCION NECESIDADES DE CAPACITACIÓN.pdf'}
                        onDownload={handleGenerateDetectionOfTrainingNeeds}
                        orientation='horizontal'
                        showToolTip={false}
                      />
                    )
                  }
                </div>
              )
            }
          </>
        ) : (
          <>
            <Header
              title={t('menu.monthlyClosings') || ''}
              showBackbutton={false}
            />
            <div className="monthly-closings__main">
              <Select
                options={getYearsFromDates()}
                onChange={(_value: string, _id: string) => {
                  setSelectedYear(Number(_value));
                }}
                value={selectedYear}
                title={t('global.year') || ''}
              />
              <Table
                headers={[
                  {
                    label: t('global.month'),
                    value: 'beginningPeriod',
                    format: 'month'
                  },
                  {
                    label: t('global.status.title'),
                    value: 'status',
                    format: 'status'
                  }
                ]}
                items={monthlyClosings.filter(obj => {
                  const date = dayjs(obj.createdAt);
                  return date.year() === selectedYear;
                })}
                onClickRow={(item: MonthlyClosing) => {
                  navigate(`/monthly-closings/${item.id}`);
                }}
              />
            </div>
          </>
        )
      }
    </div>
  );
};

export default MonthlyClosings;
