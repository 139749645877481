import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import {
  Button,
  Input,
  Modal,
  Select,
  StepsIndicator,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { emptyRevisionService } from '../../../../emptyObjects';
import { useNavigate, useResource } from '../../../../hooks';
import {
  Client,
  Collaborator,
  RevisionService
} from '../../../../interfaces';

import './styles.scss';

export const Step1 = () => {
  const {
    createResource,
    fetchResource
  } = useResource<RevisionService>();
  const {
    fetchResources: fetchClients
  } = useResource<Client>();
  const {
    fetchResources: fetchCollaborators
  } = useResource<Collaborator>();

  const navigate = useNavigate();
  const params = useParams();

  const {
    apiType,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [revisionService, setRevisionService] = useState<RevisionService>(emptyRevisionService);
  const [revisionFound, setRevisionFound] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});

  const validData = (): boolean => {
    if (revisionService.client.id === 'none') {
      setErrors({
        client: t('services.errors.client')
      });

      return false;
    }

    if (revisionService.collaborator.id === 'none') {
      setErrors({
        collaborator: t('services.errors.collaborator')
      });

      return false;
    }

    return true;
  };

  const getCollaborators = (clientID: string) => {
    fetchCollaborators(
      {
        resourcePath: '/admin/collaborators',
        filters: { s_clientID: clientID }
      },
      (data) => setCollaborators(data.items),
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getClients = (): Promise<Client[]> => {
    return new Promise((resolve, reject) => {
      fetchClients(
        {
          resourcePath: '/admin/clients',
          ordering: {
            s_orderBy: 'businessName',
            s_orderDirection: 'ASC'
          }
        },
        (data) => resolve(data.items),
        (error: string) => reject(error)
      );
    });
  };

  const handleChangeClient = (value: string) => {
    setRevisionService({
      ...revisionService,
      client: {
        id: value,
        businessName: revisionService.client.businessName,
        shortName: revisionService.client.shortName
      },
      collaborator: {
        id: 'none',
        name: value !== 'none' ? revisionService.collaborator.name : ''
      }
    });

    if (value !== 'none' && apiType === 'admin') {
      getCollaborators(value);
    } else {
      setCollaborators([]);
      setRevisionService(emptyRevisionService);
    }
  };

  const handleChangeCollaborator = (value: string) => {
    setRevisionService({
      ...revisionService,
      collaborator: {
        id: value,
        name: collaborators.find(element => element.id === value)?.name || ''
      }
    });
  };

  const getRevisionService = (): Promise<RevisionService> => {
    return new Promise((resolve, reject) => {
      fetchResource(
        `/${apiType}/revision-services/${params.revisionServiceID}`,
        (data: RevisionService) => {
          resolve(data);
          setRevisionService(data);
        },
        (error: string) => reject(error)
      );
    });
  };

  const createRevisionService = (): Promise<RevisionService> => {
    return new Promise((resolve, reject) => {
      createResource(
        `/${apiType}/revision-services`,
        {
          clientID: revisionService.client.id,
          collaboratorID: revisionService.collaborator.id
        },
        (data: RevisionService) => resolve(data),
        (error: string) => reject(error)
      );
    });
  };

  const handleContinue = async () => {
    if (validData()) {
      try {
        if (params.revisionServiceID === 'none') {
          if (revisionService.id === '') {
            const service = await createRevisionService();

            setRevisionService(service);

            navigate(`/revision-request/step_2/${service.id}/${service.requestNumber}/${revisionService.client.id}/${revisionService.collaborator.id}`);
          }
        } else {
          navigate(`/revision-request/step_2/${revisionService.id}/${revisionService.requestNumber}/${revisionService.client.id}/${revisionService.collaborator.id}`);
        }
      } catch (error) {
        Modal.fireError(error as string, setOpenModal, soundEffects);
      }
    }
  };

  const disableContinueButton = (!revisionFound && params.revisionServiceID !== 'none') || clients.length === 0;

  const alertEmptyCollaborators = collaborators.length === 0
    && revisionService.client.id !== 'none'
    && params.revisionServiceID === 'none';

  const getData = async () => {
    try {
      if (params.revisionServiceID !== 'none') {
        await getRevisionService();

        setRevisionFound(true);
      }

      if (apiType === 'admin') {
        setClients(await getClients());
      }
    } catch (error) {
      Modal.fireError(error as string, setOpenModal, soundEffects);
    }
  };

  useEffect(() => {
    getData();
  }, [apiType]);

  return (
    <>
      <div className='step-1-revision__steps'>
        <StepsIndicator
          stepsQuantity={apiType === 'admin' ? 3 : 4}
          currentStep={1}
        />
      </div>
      <div className='step-1-revision__content'>
        <div className='step-1-revision'>
          <Title title={t('services.constancyStep1')} />
          <div className='step-1-revision__input-container'>
            <Title title={t('global.client')} type='secondary' />
            {
              params.revisionServiceID !== 'none' ? (
                <Input
                  type='text'
                  value={revisionService.client.businessName}
                  onChange={() => { }}
                  disabled={true}
                />
              ) : (
                <Select
                  options={
                    clients.map((_client: Client) => {
                      return {
                        text: _client.businessName,
                        value: _client.id
                      };
                    })
                  }
                  onChange={handleChangeClient}
                  value={revisionService.client.id}
                  disabled={clients.length === 0 || params.revisionServiceID !== 'none'}
                  hasError={clients.length === 0 && params.revisionServiceID === 'none' ? true : !!errors.client}
                  helperText={clients.length === 0 && params.revisionServiceID === 'none' ? t('global.clientsNotFound') || '' : errors.client || t('global.clientService') || ''}
                />
              )
            }
          </div>
          <div className='step-1-revision__input-container'>
            <Title title={t('global.collaborator')} type='secondary' />
            {
              params.revisionServiceID !== 'none' ? (
                <Input
                  type='text'
                  value={revisionService.collaborator.name}
                  onChange={() => { }}
                  disabled={true}
                />
              ) : (
                <Select
                  options={
                    // eslint-disable-next-line max-len
                    collaborators.filter(objeto => objeto.isActive === true).map((_collaborator: Collaborator) => {
                      return {
                        text: _collaborator.name,
                        value: _collaborator.id
                      };
                    })
                  }
                  onChange={handleChangeCollaborator}
                  value={revisionService.collaborator.id}
                  disabled={collaborators.length === 0 || clients.length === 0}
                  hasError={alertEmptyCollaborators ? true : !!errors.collaborator}
                  helperText={alertEmptyCollaborators ? t('global.collaboratorsNotFound') || '' : errors.collaborator || t('global.collaboratorService') || ''}
                />
              )
            }
          </div>
          <div className='step-1-revision__button-container'>
            <Button
              onClick={handleContinue}
              type='primary'
              label={t('global.continue') || ''}
              icon='rightArrow'
              iconPosition='right'
              disabled={disableContinueButton}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
