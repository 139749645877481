import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  ModalComponent,
  ModalComponentTypes,
  ModalComponentProps
} from './ModalComponent';

// eslint-disable-next-line no-undef
const container = document.getElementById('modal');

const modal = createRoot(container!);

class Modal {
  fireLoading(setOpenModal?: (_value: boolean) => void, loadProgress?: number, loadLabel?: string) {
    this.render({
      type: 'loading',
      title: '',
      subtitle: '',
      visible: true,
      fullSize: false,
      onConfirm: null,
      onCancel: () => { },
      setOpenModal,
      loadProgress,
      loadLabel
    });
  }

  fireSuccess(
    title: string,
    subtitle: string,
    setOpenModal?: (_value: boolean) => void,
    onConfirm?: () => void,
    sound?: boolean
  ) {
    this.render({
      type: 'success',
      title,
      subtitle,
      visible: true,
      fullSize: false,
      onConfirm: null,
      onCancel: () => {
        this.close(setOpenModal);

        if (onConfirm) {
          onConfirm();
        }
      },
      setOpenModal,
      sound
    });
  }

  fireError(
    subtitle: string,
    setOpenModal?: (_value: boolean) => void,
    sound?: boolean
  ) {
    this.render({
      type: 'error',
      title: '¡Oh no!',
      subtitle,
      visible: true,
      fullSize: false,
      onConfirm: null,
      onCancel: () => this.close(setOpenModal),
      setOpenModal,
      sound
    });
  }

  fireWarning(title: string, onConfirm: () => void, setOpenModal?: (_value: boolean) => void) {
    this.render({
      type: 'warning',
      title,
      visible: true,
      fullSize: false,
      onConfirm,
      onCancel: () => this.close(setOpenModal),
      setOpenModal
    });
  }

  fire(
    type: ModalComponentTypes,
    title: string,
    subtitle: string,
    onConfirm: () => void,
    onCancel?: () => void,
    confirmationButonLabel?: string,
    cancelButonLabel?: string,
    setOpenModal?: (_value: boolean) => void,
    sound?: boolean
  ) {
    this.render({
      type,
      title,
      subtitle,
      visible: true,
      fullSize: false,
      onConfirm,
      onCancel: () => {
        if (onCancel) {
          onCancel();
        }

        this.close(setOpenModal);
      },
      setOpenModal,
      sound,
      confirmationButonLabel,
      cancelButonLabel
    });
  }

  close(setOpenModal?: (_value: boolean) => void) {
    modal.render(
      React.createElement(ModalComponent, {
        type: 'success',
        title: '',
        subtitle: '',
        visible: false,
        fullSize: false,
        onConfirm: null,
        onCancel: () => { },
        setOpenModal
      })
    );
  }

  private render(props: ModalComponentProps) {
    modal.render(
      React.createElement(ModalComponent, props)
    );
  }
}

const singleton = new Modal();

export default singleton;
