import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  Select
} from '../../../../../../components';
import {
  BinnacleTableHeader,
  FilterTypes,
  TableOrderDirection
} from '../../../../../../interfaces';

import '../../styles.scss';
import Filters from '../Filters';

const orderingTranslation = {
  DESC: 'Descendente',
  ASC: 'Ascendente'
};

interface Props {
  handleClearFilter: () => void
  headers: BinnacleTableHeader[]
  setFilters: (_value: { [name: string]: string }) => void
  onChangeOrder: (_order: string, _direction: TableOrderDirection | 'none') => void
  orderBy: string
  orderDirection: TableOrderDirection | 'none'
}

const Table: React.FC<Props> = (props) => {
  const {
    handleClearFilter,
    headers,
    setFilters,
    onChangeOrder,
    orderBy,
    orderDirection
  } = props;

  const { t } = useTranslation();

  const [headerFilter, setHeaderFilter] = useState<FilterTypes>('none');
  const [filter, setFilter] = useState<string>('none');
  const [localFilterValue, setLocalFilterValue] = useState<string>('');
  const [localOrderBy, setLocalOrderBy] = useState<string>(orderBy);
  const [
    localOrderDirection,
    setLocalOrderDirection
  ] = useState<TableOrderDirection | 'none'>(orderDirection);

  const onChangeInput = (value: string | number) => {
    setLocalFilterValue(`${value}`);
    setFilters({
      [headerFilter]: `${value}`
    });
  };

  const onDeleteFilter = (type: 'ordering' | 'filter') => {
    if (type === 'ordering') {
      setLocalOrderBy('none');
      setLocalOrderDirection('none');
      onChangeOrder('none', 'none');
    }

    if (type === 'filter') {
      setFilter('none');
      setHeaderFilter('none');
      setLocalFilterValue('');
      setFilters({});
    }
  };

  return (
    <div className='mobile-filter'>
      <div className='mobile-filter__title'>
        <Icon
          type='order'
          className='mobile-filter__title__icon'
          id='filter-modal-icon'
        />
        <p>{t('global.orderBy')}</p>
      </div>
      <Select
        options={headers.map((header: BinnacleTableHeader) => (
          {
            text: header.label,
            value: header.value
          }
        ))}
        onChange={(value: string) => {
          setLocalOrderBy(`${value}`);

          if (localOrderDirection !== 'none') {
            onChangeOrder(value, localOrderDirection);
          }
        }}
        title='Selecciona una columna'
        value={localOrderBy}
      />
      <Select
        options={[
          {
            text: t('global.falling'),
            value: 'DESC'
          },
          {
            text: t('global.upward'),
            value: 'ASC'
          }
        ]}
        disabled={localOrderBy === 'none'}
        onChange={(value: string) => {
          setLocalOrderDirection(value as TableOrderDirection);

          onChangeOrder(localOrderBy, value as TableOrderDirection);
        }}
        title={t('global.selectAOption') || ''}
        value={localOrderDirection}
      />
      <div className='mobile-filter__title'>
        <Icon
          type='filterActive'
          alt='Filter'
          className='mobile-filter__title__icon'
        />
        <p>{t('global.filer')}</p>
      </div>
      <Select
        options={headers.map((header: BinnacleTableHeader) => (
          {
            text: header.label,
            value: header.filterName
          }
        ))}
        onChange={(value: string) => {
          setFilter(value);
          setHeaderFilter(value as FilterTypes);
          setLocalFilterValue('');
        }}
        title={t('global.selectColumn') || ''}
        value={filter}
      />
      <div className='mobile-filter__filter-container'>
        <Filters
          filterValue={localFilterValue}
          onChangeInput={onChangeInput}
          selectedFilter={headers.find(element => element.filterName === headerFilter)?.filterType || ''}
        />
      </div>
      <div className='mobile-filter__reset-filter-container'>
        <Button
          type='secondary-outlined'
          transparent={true}
          border='secondary-outlined'
          onClick={() => {
            handleClearFilter();
            setFilter('none');
            setHeaderFilter('none');
            setLocalFilterValue('');
            setLocalOrderBy('none');
            setLocalOrderDirection('none');
          }}
          label={t('services.resetAllfilters') || ''}
          icon='resetFilter'
          iconPosition='left'
        />
      </div>
      {
        ((localOrderBy !== 'none' && localOrderDirection !== 'none')
          || (filter !== 'none' && localFilterValue !== '')) && (
          <div className='mobile-filter__title mobile-filter__title--white'>
            <p>{t('global.appliedFilters')}</p>
          </div >
        )
      }
      <div className='mobile-filter__filters-bubbles'>
        {
          (localOrderBy !== 'none' && localOrderDirection !== 'none') && (
            <div className='mobile-filter__filters-bubbles__item'>
              <p>
                {headers.find(element => element.value === localOrderBy)?.label}:
              </p>
              <p>
                {orderingTranslation[localOrderDirection]}
              </p>
              <Icon type='close' onClick={() => onDeleteFilter('ordering')} className='mobile-filter__filters-bubbles__item__icon' />
            </div>
          )
        }
        {
          (filter !== 'none' && localFilterValue !== '') && (
            <div className='mobile-filter__filters-bubbles__item'>
              <p>
                {headers.find(element => element.filterName === filter)?.label}:
              </p>
              <p>
                {localFilterValue}
              </p>
              <Icon type='close' onClick={() => onDeleteFilter('filter')} className='mobile-filter__filters-bubbles__item__icon' />
            </div>
          )
        }
      </div>
    </div >
  );
};

export default Table;
