import React, {
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';

import { utils } from '../../helpers';
import { TableHeader } from '../../interfaces';
import Icon from '../Icon';
import Pagination from '../Pagination';

import './styles.scss';
import { AppContext } from '../../context/AppContext';
import ToolTip from '../ToolTip';

export type TableOrderDirection = 'ASC' | 'DESC';

interface Props {
  headers: TableHeader[]
  items: any[]
  currentPage?: number
  perPage?: number
  totalItems?: number
  orderBy?: string
  orderDirection?: TableOrderDirection
  onChangeCurrentPage?: (_currentPage: number) => void
  onChangeOrder?: (_orderBy: string, _orderDirection: TableOrderDirection) => void
  onClickRow?: (_item: any) => void,
  ranking?: boolean
  floatingHeader?: boolean
  disableAnimation?: boolean
}

const Table: React.FC<Props> = (props) => {
  const {
    headers = [],
    items = [],
    currentPage = 0,
    perPage = 0,
    totalItems = 0,
    orderBy = '',
    orderDirection = 'ASC',
    onChangeCurrentPage = (_currentPage: number) => { },
    onChangeOrder = (_orderBy: string, _orderDirection: TableOrderDirection) => { },
    onClickRow,
    ranking = false,
    floatingHeader = false,
    disableAnimation = false
  } = props;

  const { apiType } = useContext(AppContext);
  const { i18n } = useTranslation();

  const [colSpans, setColSpans] = useState<(number | boolean)[]>([]);

  useEffect(() => {
    const auxArr: (number | boolean)[] = [];

    for (let index = 0; index < items.length; index += 1) {
      auxArr.push(true);
    }

    setColSpans(auxArr);
  }, [items]);

  useEffect(() => {
    if (headers.some(item => item.rowSpan === true)) {
      const auxArr: (number | boolean)[] = [];

      for (let index = 0; index < items.length; index += 1) {
        auxArr[index] = true;
      }

      items.forEach((item, index) => {
        if (items[index + 1]) {
          if (items[index - 1]) {
            if (item.date === items[index - 1].date) {
              auxArr[index] = false;
            }
          }
          if (item.date === items[index + 1].date && auxArr[index] === true) {
            let quantityOfSameValue = 0;

            for (let index2 = index; index2 < items.length; index2 += 1) {
              if (item.date === items[index2].date) {
                quantityOfSameValue += 1;
                auxArr[index2] = false;
              }
            }

            auxArr[index] = quantityOfSameValue;
          }
        }
      });

      setColSpans(auxArr);
    }
  }, [headers, items]);

  const handleChangeOrder = (value: string) => {
    if (items.length > 0) {
      onChangeOrder(value, orderDirection === 'ASC' ? 'DESC' : 'ASC');
    }
  };

  const setHeaderClassName = (): string => {
    if (totalItems <= 0 || currentPage === 0 || !orderBy) {
      return 'entity-table__header-not-hover-effect';
    }

    return '';
  };

  const showColumnValidator = (
    onlyAdmin: boolean | undefined
  ): boolean => {
    if (onlyAdmin !== undefined) {
      if (apiType === 'public' && onlyAdmin === true) {
        return false;
      }
    }

    return true;
  };

  const medals: { [name: string]: ReactElement } = {
    1: (<Icon type={'gold'} className='medal' />),
    2: (<Icon type={'silver'} className='medal' />),
    3: (<Icon type={'cooper'} className='medal' />)
  };

  // eslint-disable-next-line no-unused-vars
  const setRowSpan = (
    previusValue: string,
    currectValue: string,
    rowSpan: boolean | undefined,
    value: number
  ): number => {
    if (rowSpan === true
      && previusValue !== currectValue
    ) {
      return value || 1;
    }

    return 1;
  };

  return (
    <div className={`entity-table-container entity-table-container${floatingHeader ? '--flaoting-header' : ''}`}>
      <table className={`entity-table entity-table${disableAnimation ? '--no-animation' : '--animation'}`}>
        <thead className={floatingHeader ? 'entity-table__floating-head' : ''}>
          <tr>
            {
              headers.map((header: TableHeader, index: number) => (
                <>
                  {
                    showColumnValidator(header.onlyAdmin) && (
                      <th
                        key={index}
                        onClick={() => { handleChangeOrder(header.value); }}
                        className={setHeaderClassName()}
                      >
                        {
                          header.helperText ? (
                            <>
                              {
                                header.helperTextTooltip ? (
                                  <ToolTip
                                    text={header.helperText}
                                    position={header.helperTextTooltipPosition || 'bottom'}
                                    component={<span>{header.label}</span>}
                                  />
                                ) : (
                                  <p>{header.helperText}</p>
                                )
                              }
                            </>
                          ) : (
                            <span>{header.label}</span>
                          )
                        }
                        {
                          orderBy === header.value && (
                            <Icon
                              type={orderDirection === 'ASC' ? 'upArrow' : 'downArrow'}
                              alt={'V'}
                              className={'entity-table__header__down-arrow-icon'}
                            />
                          )
                        }
                      </th>
                    )
                  }
                </>
              ))
            }
          </tr>
        </thead>
        <tbody>
          {
            items.length <= 0 ? (
              <tr className='empty-state'>
                <td className='empty-state__row' colSpan={headers.length}>
                  <p className='empty-state__row__empty-message'>{i18n.language === 'es' ? '¡Oh no!, No se encontró ningún elemento' : 'Oh no, items not found'}</p>
                  <Icon type='emptyState' className='empty-state__icon' />
                </td>
              </tr>
            ) : (
              items.map((item: any, index: number) => (
                <tr
                  className={onClickRow ? 'entity-table__row' : 'entity-table__row entity-table__row--disabled'}
                  key={index}
                  onClick={onClickRow ? () => onClickRow(item) : () => { }}
                  id={`row-${index}`}
                >
                  {
                    headers.map((header: TableHeader, _index: number) => (
                      <>
                        {
                          showColumnValidator(header.onlyAdmin)
                          // eslint-disable-next-line max-len
                          && (_index === 0 ? ((colSpans as any)[index] === true || Number.isInteger((colSpans as any)[index])) : true)
                          && (
                            <td
                              key={header.value}
                              // eslint-disable-next-line max-len
                              rowSpan={_index === 0 && Number.isInteger(colSpans[index]) ? Number(colSpans[index]) : 1}
                              id={`${header.value}-${_index}`}
                              // eslint-disable-next-line max-len
                              className={Number.isInteger(colSpans[index]) && Number(colSpans[index]) > 1 && _index === 0
                                ? 'bordered-cell'
                                : ''}
                            >
                              <div className='entity-table__cell'>
                                <div className='entity-table__cell__label'>
                                  {header.label}
                                </div>
                                <div className='entity-table__cell__value'>
                                  {
                                    header.value === 'ranking' && ranking && (
                                      medals[utils.formatValue(
                                        header.format,
                                        utils.getNestedValue(item, header.value),
                                        utils.getNestedValue(item, header.auxiliarValue || ''),
                                        _index + 1
                                      )]
                                    )
                                  }
                                  {
                                    header.format === 'custom-component' ? (
                                      item[header.value]
                                    ) : (
                                      <>
                                        {
                                          header.limitLength ? (
                                            utils.limitValueLength(
                                              utils.formatValue(
                                                header.format,
                                                utils.getNestedValue(item, header.value),
                                                '',
                                                _index + 1
                                              ),
                                              header.limitLength
                                            )
                                          ) : (
                                            utils.formatValue(
                                              header.format,
                                              utils.getNestedValue(item, header.value),
                                              utils.getNestedValue(item, header.auxiliarValue || ''),
                                              _index + 1
                                            )
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            </td>
                          )
                        }
                      </>
                    ))
                  }
                </tr>
              ))
            )
          }
        </tbody>
      </table>
      <br />
      {
        totalItems > 0 && perPage > 0 && (
          <Pagination
            currentPage={currentPage}
            perPage={perPage}
            totalItems={totalItems}
            onChangeCurrentPage={onChangeCurrentPage}
          />
        )
      }
    </div>
  );
};

export default Table;
