import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { unparse } from 'papaparse';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  BubbleElements,
  Button,
  CheckBox,
  Header,
  Input,
  Modal,
  Select,
  Separator,
  Table
} from '../../../../components';
import { emptyTask } from '../../../../emptyObjects';

import { utils, validate } from '../../../../helpers';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import {
  Client,
  ConstancyService,
  DictumProduct,
  DictumService,
  Inspection,
  NormCodes,
  RevisionService,
  SelectOption,
  ServiceCodes,
  TableHeader,
  Task,
  User
} from '../../../../interfaces';

import './styles.scss';
import storage from '../../../../storage';
import { AppContext } from '../../../../context/AppContext';

const dayjs = require('dayjs');

interface InspectionsCount {
  'on-site': number
  constancy: number
  revision: number
}

interface CSVData {
  Folio: string
  Subfolio: string
  Ronda: string
  Modelo: string
  'Modelo 2'?: string
  'Numero de solicitud': string
  'Diseño de etiquetas': string
  Norma: string
  'Fecha limite de cierre': string
}

interface CustomInspection extends Inspection {
  proposedValidationDate: Date
}

interface LocalInspection extends Inspection {
  'check-box': ReactElement
}

interface FormValues {
  type: string
  date: string
  client: string
  direction: string
  time: string
  assigned: string
  comments: string
}

interface CheckBoxValue {
  selected: boolean
  invoice: string
  norm: NormCodes
  inspectionID: string
}

const TaskForm: React.FC = () => {
  const params = useParams();
  const {
    taskID,
    serviceID,
    serviceType
  } = params;

  const {
    fetchResources
  } = useResource<Client>();

  const {
    fetchResource: getInspectionsCount
  } = useResource<{ [id: string]: InspectionsCount }>();

  const {
    fetchResources: getInspections
  } = useResource<CustomInspection>();

  const {
    fetchResources: getUsers
  } = useResource<User>();

  const {
    fetchResources: getDictumProducts
  } = useResource<DictumProduct>();

  const {
    createResource: createTask,
    updateResource: updateTask,
    fetchResource: getTask,
    eraseResource: deleteTask
  } = useResource<Task>();

  const {
    fetchResource: getDictum
  } = useResource<DictumService>();

  const {
    fetchResource: getConstancy
  } = useResource<ConstancyService>();

  const {
    fetchResource: getRevision
  } = useResource<RevisionService>();

  const {
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const tableHeadersOnSite: TableHeader[] = [
    {
      label: t('services.closedDte'),
      value: 'proposedValidationDate',
      format: 'date'
    },
    {
      label: t('tasks.table.invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: t('global.subInvoice'),
      value: 'product.subInvoice',
      format: 'none'
    },
    {
      label: t('inspections.round'),
      value: 'rounds',
      format: 'quantity'
    },
    {
      label: t('tasks.table.model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: t('tasks.table.model2'),
      value: 'product.code2',
      format: 'none'
    },
    {
      label: t('tasks.table.service'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('tasks.table.norm'),
      value: 'norm',
      format: 'none'
    },
    {
      label: t('tasks.table.select'),
      value: 'check-box',
      format: 'custom-component'
    }
  ];

  const tableHeadersRemote: TableHeader[] = [
    {
      label: t('services.closedDte'),
      value: 'proposedValidationDate',
      format: 'constancy-expired-date'
    },
    {
      label: t('tasks.table.invoice'),
      value: 'product.invoice',
      format: 'none'
    },
    {
      label: t('global.subInvoice'),
      value: 'product.subInvoice',
      format: 'none'
    },
    {
      label: t('inspections.round'),
      value: 'rounds',
      format: 'quantity'
    },
    {
      label: t('tasks.table.model'),
      value: 'product.code',
      format: 'none'
    },
    {
      label: t('tasks.table.service'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('global.design'),
      value: 'indicators.design',
      format: 'boolean'
    },
    {
      label: t('tasks.table.norm'),
      value: 'norm',
      format: 'none'
    },
    {
      label: t('tasks.table.select'),
      value: 'check-box',
      format: 'custom-component'
    }
  ];

  const navigate = useNavigate();

  const [checkBoxValues, setCheckBoxValues] = useState<{ [id: string]: CheckBoxValue }>({});
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [task, setTask] = useState<Task>(emptyTask);
  const [inspections, setInspections] = useState<CustomInspection[]>([]);
  const [customInspections, setCustomInspections] = useState<LocalInspection[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedInspector, setSelectedInspector] = useState<string>('');
  const [inputsNumbers, setInputsNumbers] = useState<number[]>([1, 2, 3, 0, 4, 5, 6]);
  const [inspectors, setInspectors] = useState<{ [normCode: string]: User[] }>({});
  const [ableInspectors, setAbleInspectors] = useState<ReactElement[]>([]);
  const [productsIdsFromDB, setProductsIdsFromDB] = useState<string[]>([]);
  const [formValues, setFormValues] = useState<FormValues>({
    type: 'none',
    date: '',
    client: 'none',
    direction: 'none',
    time: '',
    assigned: '',
    comments: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});
  const [inspectionsCount, setInspectionsCount] = useState<{ [id: string]: InspectionsCount }>({});

  const exportToCSV = () => {
    Modal.fireLoading();

    const data: CSVData[] = [];

    if (['constancy-inspection', 'revision-inspection'].includes(formValues.type)) {
      inspections.forEach(inspection => {
        data.push({
          Folio: inspection.product.invoice,
          Subfolio: `${inspection.product.subInvoice || 'Sin definir'}`,
          Ronda: `${inspection.rounds.length}`,
          Modelo: inspection.product.code,
          'Numero de solicitud': inspection.service.number,
          'Diseño de etiquetas': inspection.indicators.design ? 'Si' : 'No',
          Norma: inspection.norm,
          'Fecha limite de cierre': dayjs(inspection.proposedValidationDate).add(90, 'day').format('DD/MM/YYYY hh:mm a')
        });
      });
    } else {
      inspections.forEach(inspection => {
        data.push({
          Folio: inspection.product.invoice,
          Subfolio: `${inspection.product.subInvoice || 'Sin definir'}`,
          Ronda: `${inspection.rounds.length}`,
          Modelo: inspection.product.code,
          'Modelo 2': inspection.product.code2,
          'Numero de solicitud': inspection.service.number,
          'Diseño de etiquetas': inspection.indicators.design ? 'Si' : 'No',
          Norma: inspection.norm,
          'Fecha limite de cierre': dayjs(String(inspection.proposedValidationDate).substring(0, 10)).format('DD/MM/YYYY')
        });
      });
    }

    const csv = unparse(data);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    // eslint-disable-next-line no-undef
    link.href = window.URL.createObjectURL(blob);

    const inspectionsTypes: { [name: string]: string } = {
      'onsite-inspection': 'en sitio',
      'constancy-inspection': 'de constancia',
      'revision-inspection': 'de revision'
    };

    link.download = `Inspecciones ${inspectionsTypes[formValues.type]} por agendar - ${clients.find(client => client.id === formValues.client)?.businessName} - ${dayjs().format('DD-MMM-YYYY')}.csv`;
    link.click();
    Modal.close();
  };

  const validData = (): boolean => {
    if (formValues.type === 'none') {
      setErrors({
        type: t('tasks.form.errors.type')
      });

      return false;
    }

    if (validate.text(formValues.date, 1) !== 'success') {
      setErrors({
        date: t('tasks.form.errors.date')
      });

      return false;
    }

    const date1Year = Number(dayjs(formValues.date).format('YYYY'));
    const date1Month = Number(dayjs(formValues.date).format('MM'));
    const date1Day = Number(dayjs(formValues.date).format('DD'));

    const date2Year = Number(dayjs(new Date()).format('YYYY'));
    const date2Month = Number(dayjs(new Date()).format('MM'));
    const date2Day = Number(dayjs(new Date()).format('DD'));

    if (date1Year < date2Year) {
      setErrors({
        date: t('tasks.form.errors.dateBefore')
      });

      return false;
    }

    if (
      date1Year === date2Year
      && date1Month < date2Month
    ) {
      setErrors({
        date: t('tasks.form.errors.dateBefore')
      });

      return false;
    }

    if (
      date1Year === date2Year
      && date1Month === date2Month
      && date1Day < date2Day
    ) {
      setErrors({
        date: t('tasks.form.errors.dateBefore')
      });

      return false;
    }

    if (formValues.client === 'none') {
      setErrors({
        client: t('tasks.form.errors.client')
      });

      return false;
    }

    if (formValues.type === 'onsite-inspection' && formValues.direction === 'none') {
      setErrors({
        direction: t('tasks.form.errors.address')
      });

      return false;
    }

    if (formValues.type === 'onsite-inspection' && formValues.time === '') {
      setErrors({
        time: t('tasks.form.errors.time')
      });

      return false;
    }

    setErrors({});

    if (customInspections.length <= 0) {
      Modal.fireError(t('tasks.form.errors.invoicesNotFound'), setOpenModal, soundEffects);

      return false;
    }

    let validCheckedInvoices: boolean = false;

    Object.keys(checkBoxValues).forEach((key: string) => {
      if (checkBoxValues[key]) {
        validCheckedInvoices = true;
      }
    });

    if (!validCheckedInvoices && task.id === '') {
      Modal.fireError(t('tasks.form.errors.invoicesNotSelected'), setOpenModal, soundEffects);

      return false;
    }

    if (selectedInspector === '') {
      Modal.fireError(t('tasks.form.errors.responsibleNotSelected'), setOpenModal, soundEffects);

      return false;
    }

    return true;
  };

  const onChangeType = (value: string) => {
    setFormValues({
      ...formValues,
      type: value
    });
  };

  const onChangeDate = (value: string | number) => {
    setFormValues({
      ...formValues,
      date: String(value)
    });
  };

  const onChangeTime = (value: string | number) => {
    setFormValues({
      ...formValues,
      time: String(value)
    });
  };

  const onChangeClient = (value: string) => {
    setFormValues({
      ...formValues,
      client: value
    });
  };

  const onChangeDirection = (value: string) => {
    setFormValues({
      ...formValues,
      direction: value
    });
  };

  const onChangeComments = (value: string | number) => {
    setFormValues({
      ...formValues,
      comments: String(value)
    });
  };

  const getSelectedInvoices = (): string[] => {
    const selectedInvoices: string[] = [];

    Object.keys(checkBoxValues).forEach((key: string) => {
      if (checkBoxValues[key].selected) {
        selectedInvoices.push(checkBoxValues[key].invoice);
      }
    });

    return selectedInvoices;
  };

  const handleSelectAll = (value: boolean) => {
    setSelectedInspector('');

    customInspections.forEach((inspection: LocalInspection) => {
      checkBoxValues[inspection.product.id] = {
        ...checkBoxValues[inspection.product.id],
        selected: value
      };
    });

    setCheckBoxValues({ ...checkBoxValues });
    setSelectAll(value);
  };

  const getInspectionsIDs = (): string[] => {
    const auxIDs: string[] = [];

    Object.keys(checkBoxValues).forEach((key: string) => {
      if (checkBoxValues[key].selected === true) {
        auxIDs.push(checkBoxValues[key].inspectionID);
      }
    });

    return auxIDs;
  };

  const onSaveTask = () => {
    if (validData()) {
      const body: any = {
        type: formValues.type,
        responsibleID: selectedInspector,
        scheduledTo: formValues.date,
        inspectionIDs: getInspectionsIDs()
      };

      if (formValues.comments) {
        body.comments = formValues.comments;
      }

      if (formValues.direction !== 'none') {
        body.visitAddress = formValues.direction;
      }

      if (formValues.time !== '') {
        body.time = formValues.time;
      }

      if (taskID !== 'none') {
        updateTask(
          `/admin/tasks/${taskID}/${task.type}/update`,
          body,
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('tasks.form.taskUpdated'),
              setOpenModal,
              () => {
                navigate('/tasks/none');
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else {
        createTask(
          '/admin/tasks',
          body,
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('tasks.form.taskCreated'),
              setOpenModal,
              () => {
                navigate('/tasks/none');
              },
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  };

  const setVerrifficationAddessessOptions = (): SelectOption[] => {
    const auxClientAddesses: SelectOption[] = [];

    for (let index = 0; index < clients.length; index += 1) {
      if (clients[index].id === formValues.client) {
        for (let y = 0; y < clients[index].inspectionAddresses.length; y += 1) {
          auxClientAddesses.push(
            {
              text: utils.formatValue(
                'address',
                clients[index].inspectionAddresses[y]
              ),
              value: utils.formatValue(
                'address',
                clients[index].inspectionAddresses[y]
              )
            }
          );
        }
      }
    }

    return auxClientAddesses;
  };

  const checkUserHaveNorms = (norms: NormCodes[], user: User): boolean => {
    let hasAllTheRequiredNorms: boolean = true;

    norms.forEach((norm: NormCodes) => {
      if (!user.norms.find(item => item.norm === norm)) {
        hasAllTheRequiredNorms = false;
      }
    });

    return hasAllTheRequiredNorms;
  };

  useEffect(() => {
    const getAbleInspectorsByNorm = () => {
      const auxUsers: User[] = [];
      const norms: NormCodes[] = [];

      Object.keys(checkBoxValues).forEach((key: string) => {
        if (checkBoxValues[key].selected) {
          norms.push(checkBoxValues[key].norm);
        }
      });

      const dataArr = new Set(norms);

      const resultNorms = [...dataArr];

      for (let index1 = 0; index1 < resultNorms.length; index1 += 1) {
        for (let index = 0; index < inspectors[resultNorms[index1]].length; index += 1) {
          if (checkUserHaveNorms(resultNorms, inspectors[resultNorms[index1]][index])) {
            auxUsers.push(inspectors[resultNorms[index1]][index]);
          }
        }
      }

      const cleanUsers = auxUsers.filter((item, index) => {
        return auxUsers.indexOf(item) === index;
      });

      if (cleanUsers.length <= 0) {
        setAbleInspectors([<p>{t('tasks.form.errors.inspectorsNotFound')}</p>]);
      } else {
        const result = [];

        for (let index = 0; index < cleanUsers.length; index += 1) {
          selectedInspector === cleanUsers[index].id ? (
            result.push(
              <Button
                type='primary'
                border='primary'
                onClick={undefined}
                label={cleanUsers[index].name}
              />
            )
          ) : (
            result.push(<Button
              type='secondary-outlined'
              transparent={true}
              border='secondary-outlined'
              onClick={() => setSelectedInspector(cleanUsers[index].id)}
              label={cleanUsers[index].name}
            />)
          );
        }

        setAbleInspectors(result);
      }
    };

    getAbleInspectorsByNorm();
  }, [
    checkBoxValues,
    selectedInspector
  ]);

  const deleteInvoiceFromBubbleElement = (invoice: string) => {
    setSelectedInspector('');

    const result = Object.keys(checkBoxValues);

    for (let index = 0; index < result.length; index += 1) {
      if (checkBoxValues[result[index]].invoice === invoice) {
        checkBoxValues[result[index]].selected = false;
      }
    }

    setCheckBoxValues({ ...checkBoxValues });
  };

  const getAbleInspections = (_inspections: CustomInspection[]): CustomInspection[] => {
    const auxInspectionsItems: CustomInspection[] = [];

    for (let index = 0; index < _inspections.length; index += 1) {
      if (task.id !== '') {
        if (
          task.invoices.includes(_inspections[index].product.invoice)
          || !_inspections[index].rounds[_inspections[index].rounds.length - 1].task
        ) {
          auxInspectionsItems.push(_inspections[index]);
        }
      } else {
        auxInspectionsItems.push(_inspections[index]);
      }
    }

    // eslint-disable-next-line max-len
    return auxInspectionsItems.sort((a, b) => dayjs(a.proposedValidationDate).diff(dayjs(b.proposedValidationDate)));
  };

  const handleDeleteTask = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('tasks.form.askDeleteTask'),
      () => {
        deleteTask(
          `admin/tasks/${taskID}/${task.type}/delete`,
          {},
          () => {
            if (storage.get('lastTaskDetailID') === taskID) {
              storage.set('lastTaskDetailID', '');
            }

            Modal.fireSuccess(
              t('global.correct'),
              t('tasks.form.taskDeleted'),
              setOpenModal,
              () => navigate(-1),
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    const auxInspectors: { [normCode: string]: User[] } = {
      141: [],
      142: [],
      '050': [],
      '051': [],
      '004': [],
      '003': [],
      189: [],
      '015': [],
      '024': [],
      '020': [],
      173: [],
      187: [],
      235: []
    };

    for (let index = 0; index < users.length; index += 1) {
      for (let index2 = 0; index2 < users[index].norms.length; index2 += 1) {
        auxInspectors[users[index].norms[index2].norm].push(users[index]);
      }
    }

    setInspectors(auxInspectors);
  }, [users]);

  useEffect(() => {
    getUsers(
      {
        resourcePath: 'admin/users',
        filters: {
          s_role: 'inspector',
          b_isActive: true
        }
      },
      (data) => {
        setUsers(data.items.filter(user => user.isActive === true));
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );

    fetchResources(
      {
        resourcePath: 'admin/clients'
      },
      (data) => {
        setClients(data.items);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );

    getInspectionsCount(
      'admin/inspections/count/to-assign',
      (data) => {
        setInspectionsCount(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  }, []);

  const fillCheckBoxValues = (_inspections: CustomInspection[]) => {
    const auxCheckBoxValues: { [id: string]: CheckBoxValue } = {};

    for (let index = 0; index < _inspections.length; index += 1) {
      auxCheckBoxValues[_inspections[index].product.id] = {
        invoice: _inspections[index].product.invoice,
        selected: false,
        norm: _inspections[index].norm,
        inspectionID: _inspections[index].id
      };
    }
    setCheckBoxValues(auxCheckBoxValues);
  };

  useEffect(() => {
    setCheckBoxValues({});

    if (
      formValues.client !== 'none'
      && formValues.type !== 'none'
      && taskID === 'none'
    ) {
      let filters: any = {};
      let serviceCode: ServiceCodes = 'DC';

      if (['constancy-inspection', 'remote-inspection'].includes(formValues.type)) {
        serviceCode = 'CC';
      }

      if (formValues.type === 'revision-inspection') {
        serviceCode = 'REV';
      }

      filters = {
        b_scheduled: false,
        s_clientID: formValues.client,
        s_serviceCode: serviceCode,
        s_status: 'in-progress',
        b_requestIsCompleted: true,
        b_isReadyForVisit: true,
        b_waitingToExpired: false
      };

      if (['constancy-inspection', 'revision-inspection', 'remote-inspection'].includes(formValues.type)) {
        filters.b_waitingForFilesForRemoteRound = true;
        filters.b_changedFilesForRemoteRound = true;
      }

      getInspections(
        {
          resourcePath: 'admin/inspections',
          filters
        },
        (data) => {
          if (data.items.length === 0) {
            let filters2: any = {};

            filters2 = {
              b_scheduled: false,
              s_clientID: formValues.client,
              s_serviceCode: serviceCode,
              s_status: 'in-progress',
              b_requestIsCompleted: true,
              b_isReadyForVisit: true,
              b_waitingToExpired: false
            };

            if (['remote-inspection', 'constancy-inspection', 'revision-inspection'].includes(formValues.type)) {
              filters2.b_waitingForFilesForRemoteRound = false;
              filters2.b_changedFilesForRemoteRound = false;
            }

            getInspections(
              {
                resourcePath: 'admin/inspections',
                filters: filters2
              },
              (data2) => {
                setInspections(getAbleInspections(data2.items));
                fillCheckBoxValues(getAbleInspections(data2.items));
              },
              (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
            );
          } else {
            setInspections(getAbleInspections(data.items));
            fillCheckBoxValues(getAbleInspections(data.items));
          }
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    } else {
      setInspections([]);
      setCheckBoxValues({});
    }
  }, [formValues.client, formValues.type, taskID]);

  useEffect(() => {
    let selectedAllAux: boolean = true;

    const keys = Object.keys(checkBoxValues);

    for (let index = 0; index < keys.length; index += 1) {
      if (checkBoxValues[keys[index]].selected === false) {
        selectedAllAux = false;
      }
    }

    setSelectAll(selectedAllAux);
  }, [checkBoxValues]);

  useEffect(() => {
    if (formValues.type === 'onsite-inspection') {
      setInputsNumbers([1, 2, 3, 4, 5, 6, 7, 8]);
    } else if (['constancy-inspection', 'revision-inspection', 'remote-inspection'].includes(formValues.type) || formValues.type === 'none') {
      setInputsNumbers([1, 2, 3, 0, 0, 4, 5, 6]);
    }
  }, [formValues.type]);

  useEffect(() => {
    if (taskID !== 'none') {
      getTask(
        `/admin/tasks/${taskID}`,
        (data) => {
          setFormValues({
            type: data.type,
            date: dayjs(String(data.datesRegistry.scheduledTo).substring(0, 10)).format('YYYY-MM-DD'),
            client: data.client.id,
            direction: data.visit?.address || 'none',
            time: data.visit?.time || '',
            assigned: data.responsible.id,
            comments: data.comments || ''
          });
          setTask(data);
          setSelectedInspector(data.responsible.id);

          getDictumProducts(
            {
              resourcePath: '/admin/dictum-products',
              filters: {
                s_manyInvoices: [...new Set(data.invoices)].join(','),
                s_taskNumber: data.number
              }
            },
            (products) => {
              const ids: string[] = [];

              for (let index = 0; index < products.items.length; index += 1) {
                ids.push(products.items[index].id);
              }

              setProductsIdsFromDB(ids);
            },
            // eslint-disable-next-line no-console
            (error: string) => console.log(error)
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [taskID]);

  useEffect(() => {
    if (task.id.length > 0 && taskID !== 'none') {
      setTimeout(() => getInspections(
        {
          resourcePath: 'admin/inspections',
          filters: {
            s_taskID: ['constancy-inspection', 'revision-inspection', 'remote-inspection'].includes(formValues.type) ? task.id : '',
            s_roundTaskID: task.type === 'onsite-inspection' ? task.id : ''
          }
        },
        (_inspections2) => {
          let filters: any = {};
          let serviceCode: ServiceCodes = 'DC';

          if (['constancy-inspection', 'remote-inspection'].includes(formValues.type)) {
            serviceCode = 'CC';
          }

          if (formValues.type === 'revision-inspection') {
            serviceCode = 'REV';
          }

          filters = {
            s_clientID: task.client.id,
            s_serviceCode: serviceCode,
            b_scheduled: false,
            s_status: 'in-progress',
            b_requestIsCompleted: true,
            b_isReadyForVisit: true
          };

          if (['remote-inspection', 'constancy-inspection', 'revision-inspection'].includes(formValues.type)) {
            filters.b_waitingForFilesForRemoteRound = true;
            filters.b_changedFilesForRemoteRound = true;
          }

          getInspections(
            {
              resourcePath: 'admin/inspections',
              filters
            },
            (_inspections) => {
              // eslint-disable-next-line max-len
              setInspections([..._inspections.items, ..._inspections2.items].sort((a, b) => dayjs(a.proposedValidationDate).diff(dayjs(b.proposedValidationDate))));
              fillCheckBoxValues([..._inspections.items, ..._inspections2.items]);
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      ), 500);
    }
  }, [task, taskID]);

  useEffect(() => {
    if (serviceID !== 'none') {
      if (['DC', 'DN'].includes(serviceType || 'DC')) {
        getDictum(
          `/admin/dictum-services/${serviceID}`,
          (data: DictumService) => {
            setFormValues({
              ...formValues,
              type: 'onsite-inspection',
              client: data.client.id
            });
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
      if (['CC', 'CN'].includes(serviceType || 'CC')) {
        getConstancy(
          `/admin/constancy-services/${serviceID}`,
          (data: ConstancyService) => {
            setFormValues({
              ...formValues,
              type: 'constancy-inspection',
              client: data.client.id
            });
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
      if (['REV'].includes(serviceType || 'REV')) {
        getRevision(
          `/admin/revision-services/${serviceID}`,
          (data: RevisionService) => {
            setFormValues({
              ...formValues,
              type: 'revision-inspection',
              client: data.client.id
            });
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  }, [serviceID, serviceType]);

  useKeyPress(
    openModal ? () => { } : () => onSaveTask(),
    [
      formValues,
      customInspections,
      checkBoxValues,
      selectedInspector,
      openModal,
      selectAll,
      clients,
      task,
      inspections,
      users
    ]
  );

  useEffect(() => {
    if (inspections.length === Object.keys(checkBoxValues).length) {
      setCustomInspections(
        // eslint-disable-next-line max-len
        inspections.map((inspection: Inspection, index: number) => {
          return ({
            ...inspection,
            'check-box': (
              <CheckBox
                key={index}
                label=''
                checked={checkBoxValues[inspection.product.id].selected}
                onChange={() => {
                  setSelectedInspector('');

                  // eslint-disable-next-line max-len
                  checkBoxValues[inspection.product.id].selected = !checkBoxValues[inspection.product.id].selected;
                  checkBoxValues[inspection.product.id].invoice = inspection.product.invoice;
                  checkBoxValues[inspection.product.id].norm = inspection.norm;
                  checkBoxValues[inspection.product.id].inspectionID = inspection.id;

                  setCheckBoxValues({ ...checkBoxValues });
                }}
              />
            )
          }
          );
        })
      );
    }
  }, [inspections, checkBoxValues, productsIdsFromDB]);

  useEffect(() => {
    if (productsIdsFromDB.length > 0) {
      for (let index = 0; index < inspections.length; index += 1) {
        if (productsIdsFromDB.find(element => element === inspections[index].product.id)) {
          checkBoxValues[inspections[index].product.id].selected = true;
        }
      }
    }

    setCheckBoxValues({ ...checkBoxValues });
  }, [inspections, productsIdsFromDB]);

  const getClients = (): SelectOption[] => {
    const options: SelectOption[] = [];

    for (let index = 0; index < clients.length; index += 1) {
      const client = clients.find((item) => item.id === task.client.id);

      if (inspectionsCount[clients[index].id] === undefined) {
        if (client) {
          if (client.id === clients[index].id) {
            options.push({
              text: client.businessName,
              value: client.id
            });
          }
        }
      } else if (formValues.type === 'none'
        && (formValues.client === clients[index].id
          || inspectionsCount[clients[index].id]['on-site'] > 0
          || inspectionsCount[clients[index].id].constancy > 0
          || inspectionsCount[clients[index].id].revision > 0)) {
        options.push({
          text: `${clients[index].businessName} (${inspectionsCount[clients[index].id]['on-site'] + inspectionsCount[clients[index].id].constancy + inspectionsCount[clients[index].id].revision})`,
          value: clients[index].id
        });
      } else if (formValues.type !== 'none') {
        let count: number = 0;

        if (formValues.type === 'onsite-inspection'
          && inspectionsCount[clients[index].id]['on-site'] !== undefined
          && inspectionsCount[clients[index].id]['on-site'] > 0) {
          count = inspectionsCount[clients[index].id]['on-site'];
        }
        if (formValues.type === 'constancy-inspection'
          && inspectionsCount[clients[index].id].constancy !== undefined
          && inspectionsCount[clients[index].id].constancy > 0) {
          count = inspectionsCount[clients[index].id].constancy;
        }
        if (formValues.type === 'revision-inspection'
          && inspectionsCount[clients[index].id].revision !== undefined
          && inspectionsCount[clients[index].id].revision > 0) {
          count = inspectionsCount[clients[index].id].revision;
        }

        if (count > 0) {
          options.push({
            text: `${clients[index].businessName} (${count})`,
            value: clients[index].id
          });
        }
      }
    }

    if (options.length === 0
      && taskID !== 'none') {
      const client = clients.find((item) => item.id === task.client.id);

      if (client) {
        options.push({
          text: client.businessName,
          value: client.id
        });
      }
    }

    return options;
  };

  const getCountInspections = useMemo(() => {
    const calculateCount = (type: 'on-site' | 'constancy' | 'revision'): string => {
      let quantity: number = 0;

      const objectKeys = Object.keys(inspectionsCount);

      for (let index = 0; index < objectKeys.length; index += 1) {
        if (objectKeys[index] === formValues.client || formValues.client === 'none') {
          if (type === 'on-site') {
            quantity += inspectionsCount[objectKeys[index]]['on-site'];
          }
          if (type === 'constancy') {
            quantity += inspectionsCount[objectKeys[index]].constancy;
          }
          if (type === 'revision') {
            quantity += inspectionsCount[objectKeys[index]].revision;
          }
        }
      }

      return `(${quantity})`;
    };

    return calculateCount;
  }, [inspectionsCount, formValues]);

  return (
    <div className='task-form-view'>
      <Header
        title={taskID !== 'none' ? t('tasks.form.editTitle') : t('tasks.form.title')}
        showBackbutton={true}
      />
      <div className='task-form-view__content'>
        <p className='task-form-view__content__text-bold'>
          {
            taskID !== 'none' ? (
              t('tasks.form.editInstructions')
            ) : (
              t('tasks.form.newTaskInstructions')
            )
          }
        </p>
        <p>
          {t('tasks.form.instructions')}
        </p>
        <div className='task-form-view__content__inputs-container'>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              type={'primary'}
              onClick={exportToCSV}
              label='Descargar información de inspecciones'
              icon='downloadWhite'
              disabled={customInspections.length === 0}
            />
          </div>
          <div className='task-form-view__content__inputs-container__item-one-third'>
            <Select
              value={getClients().find((item) => item.value === formValues.client) ? formValues.client : 'none'}
              options={getClients()}
              onChange={onChangeClient}
              title={`${inputsNumbers[0]}-. ${t('tasks.form.askClient')}`}
              hasError={!!errors.client}
              helperText={errors.client}
            />
          </div>
          <div className='task-form-view__content__inputs-container__item-one-third'>
            <Select
              value={formValues.type}
              options={[
                {
                  text: `${t('tasks.form.inSiteInspection')} ${getCountInspections('on-site')}`,
                  value: 'onsite-inspection'
                },
                {
                  text: `${t('tasks.form.constancyInspection')} ${getCountInspections('constancy')}`,
                  value: 'constancy-inspection'
                },
                {
                  text: `${t('tasks.form.revisionInspection')} ${getCountInspections('revision')}`,
                  value: 'revision-inspection'
                }
              ]}
              onChange={onChangeType}
              title={`${inputsNumbers[1]}-. ${t('tasks.form.askSchedule')}`}
              hasError={!!errors.type}
              helperText={errors.type}
            />
          </div>
          <div className='task-form-view__content__inputs-container__item-one-third'>
            <Input
              type='date'
              value={formValues.date}
              onChange={onChangeDate}
              title={`${inputsNumbers[2]}-. ${t('tasks.form.askDay')}`}
              hasError={!!errors.date}
              helperText={errors.date}
              minDate={dayjs(new Date()).format('YYYY-MM-DD')}
            />
          </div>
          {
            formValues.type === 'onsite-inspection' && (
              <>
                <div className='task-form-view__content__inputs-container__item-two-thirds'>
                  <Select
                    value={formValues.direction}
                    options={setVerrifficationAddessessOptions()}
                    onChange={onChangeDirection}
                    title={`${inputsNumbers[3]}-. ${t('tasks.form.askAddress')}`}
                    hasError={!!errors.direction}
                    helperText={errors.direction}
                    disabled={formValues.client === 'none'}
                  />
                </div>
                <div className='task-form-view__content__inputs-container__item-two-thirds'>
                  <Input
                    type='time'
                    value={formValues.time}
                    onChange={onChangeTime}
                    title={`${inputsNumbers[4]}-. ${t('tasks.form.askTime')}`}
                    hasError={!!errors.time}
                    helperText={errors.time}
                  />
                </div>
              </>
            )
          }
          <div className='task-form-view__content__inputs-container__item-full-width'>
            <p>{inputsNumbers[5]}-. {t('tasks.form.selectInvoices')}</p>
            {
              inspections.length > 0 && (
                <div className='task-form-view__content__inputs-container__item-full-width__checked-all-container'>
                  <div className='task-form-view__content__inputs-container__item-full-width__checked-all-container__checkbox'>
                    <CheckBox
                      label={t('tasks.form.selectAllInvoices')}
                      checked={selectAll}
                      onChange={() => handleSelectAll(!selectAll)}
                    />
                  </div>
                </div>
              )
            }
            <div className='task-form-view__content__inputs-container__item-full-width__table'>
              {/* {
                isPending ? (
                  <Spinner />
                ) : ( */}
              <Table
                disableAnimation={true}
                headers={['constancy-inspection', 'revision-inspection'].includes(formValues.type) ? tableHeadersRemote : tableHeadersOnSite}
                items={customInspections}
              />
              {/* )
               } */}
            </div>
            {
              (inspections.length > 0) && (
                <>
                  <Separator orientation='horizontal' />
                  <BubbleElements
                    title={`${t('tasks.form.selectedInvoices')} (${getSelectedInvoices().length}) :`}
                    elements={getSelectedInvoices()}
                    onDelete={deleteInvoiceFromBubbleElement}
                    emptyStateTitle={t('tasks.form.emptyInvoices') || ''}
                  />
                </>
              )
            }
          </div>
          <div className='task-form-view__content__inputs-container__item-full-width'>
            <p>{inputsNumbers[6]}-. {t('tasks.form.responsibleTask')}</p>
            <div className='task-form-view__content__inputs-container__buttons-container'>
              {
                // eslint-disable-next-line max-len
                Object.keys(checkBoxValues).filter(key => checkBoxValues[key].selected === true).length > 0 ? (
                  ableInspectors.map((element: ReactElement) => (
                    element
                  ))
                ) : (
                  <p>{t('tasks.form.shouldSelectInvoices')}</p>
                )
              }
            </div>
          </div>
          <div className='task-form-view__content__inputs-container__item-full-width'>
            <Input
              type='textarea'
              value={formValues.comments}
              onChange={onChangeComments}
              title={`${inputsNumbers[7]}-. ${t('tasks.form.comments')}`}
              hasError={!!errors.comments}
              helperText={errors.comments}
            />
          </div>
        </div>
        <div className='task-form-view__content__cta-button'>
          {
            taskID !== 'none' ? (
              <Button
                type='primary'
                border='primary'
                onClick={handleDeleteTask}
                label={t('tasks.form.deleteTask') || ''}
                icon='trash'
                iconPosition='right'
                fullWidth={false}
              />
            ) : (
              <div></div>
            )
          }
          <Button
            type='primary'
            border='primary'
            onClick={onSaveTask}
            label={taskID !== 'none' ? t('tasks.form.save') || '' : t('tasks.form.schedule') || ''}
            icon='rightArrow'
            iconPosition='right'
            fullWidth={false}
            submit={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
