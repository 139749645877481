/* eslint-disable max-len */
import dayjs from 'dayjs';

import axios from 'axios';
import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import {
  Button,
  Header,
  Input,
  Modal,
  SubTitle,
  Switch,
  Title
} from '../../components';

import './styles.scss';

import monthsEs from '../../dictionaries/months-es';
import { emptyMonthlyClosing } from '../../emptyObjects';
import {
  MonthlyClosing,
  RisksToImpartiality,
  SignedFile,
  TrainingNeeds
} from '../../interfaces';
import { useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import monthsEn from '../../dictionaries/months-en';

const MonthlyClosingnsFinish = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const {
    monthlyClosingID
  } = params;

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const {
    fetchResource,
    updateResource
  } = useResource<MonthlyClosing>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const [monthlyClosing, setMonthlyClosing] = useState<MonthlyClosing>(emptyMonthlyClosing);
  const [comments, setComments] = useState<string>('');
  const [detectionOfRisksToImpartiality, setDetectionOfRisksToImpartiality] = useState<boolean>(false);
  const [detectionOfTrainingNeeds, setDetectionOfTrainingNeeds] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [risksToImpartiality, setRisksToImpartiality] = useState<RisksToImpartiality>({
    involved: '',
    positionOfThoseInvolved: '',
    situation: '',
    riskToImpartiality: '',
    resultAndCountermeasure: ''
  });
  const [trainingNeeds, setTrainingNeeds] = useState<TrainingNeeds>({
    personEvaluated: '',
    positionOfThePersonBeingEvaluated: '',
    generalComments: '',
    correctiveAction: '',
    elaborates: '',
    sign: ''
  });

  const sigCanvas = useRef<any>();

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const getMonthlyClosing = () => {
    fetchResource(
      `admin/monthly-closings/${monthlyClosingID}`,
      (data) => {
        setMonthlyClosing(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const validData = () => {
    if (comments === '') {
      setErrors({
        comments: t('global.emptyComments')
      });

      return false;
    }

    if (detectionOfRisksToImpartiality) {
      if (risksToImpartiality.involved === '') {
        setErrors({
          involved: t('global.dataError')
        });

        return false;
      }

      if (risksToImpartiality.positionOfThoseInvolved === '') {
        setErrors({
          positionOfThoseInvolved: t('global.dataError')
        });

        return false;
      }

      if (risksToImpartiality.situation === '') {
        setErrors({
          situation: t('global.dataError')
        });

        return false;
      }

      if (risksToImpartiality.riskToImpartiality === '') {
        setErrors({
          riskToImpartiality: t('global.dataError')
        });

        return false;
      }

      if (risksToImpartiality.resultAndCountermeasure === '') {
        setErrors({
          resultAndCountermeasure: t('global.dataError')
        });

        return false;
      }
    }

    if (detectionOfTrainingNeeds) {
      if (trainingNeeds.personEvaluated === '') {
        setErrors({
          personEvaluated: t('global.dataError')
        });

        return false;
      }

      if (trainingNeeds.positionOfThePersonBeingEvaluated === '') {
        setErrors({
          positionOfThePersonBeingEvaluated: t('global.dataError')
        });

        return false;
      }

      if (trainingNeeds.generalComments === '') {
        setErrors({
          generalComments: t('global.dataError')
        });

        return false;
      }

      if (trainingNeeds.correctiveAction === '') {
        setErrors({
          correctiveAction: t('global.dataError')
        });

        return false;
      }

      if (trainingNeeds.elaborates === '') {
        setErrors({
          elaborates: t('global.dataError')
        });

        return false;
      }
    }

    return true;
  };

  const finishMonthClosign = () => {
    if (validData()) {
      if (detectionOfTrainingNeeds) {
        if (sigCanvas.current.isEmpty()) {
          Modal.fireError(t('tasks.errors.sign'), undefined, soundEffects);

          return;
        }

        trainingNeeds.sign = sigCanvas.current.toDataURL('image/png');

        sigCanvas.current.clear();

        setTrainingNeeds({ ...trainingNeeds });

        registerUploadedFiles(
          `/admin/monthly-closings/${monthlyClosingID}/documents/training-needs-sign`,
          {
            filePaths: [{
              path: 'training-needs-sign.png'.replace(/[%&?¿=#/+]/g, ''),
              type: 'image/png'
            }]
          },
          async (data) => {
            const uploadedPaths: string[] = [];
            let errorMessage: string = '';

            Modal.fireLoading(undefined, 0);
            try {
              // eslint-disable-next-line array-callback-return, consistent-return
              await Promise.all(data.map((item) => {
                const formData = new FormData();

                Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                  formData.append(key, value as string);
                });

                formData.append('file', dataURLtoBlob(trainingNeeds.sign));

                return axios.post(
                  item.signedURL.url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                      const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                      Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                    }
                  }
                ).then(() => {
                  Modal.close();
                  uploadedPaths.push(item.url);
                });
              }));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              errorMessage = t('global.errorUploadingFile');
            } finally {
              if (errorMessage === '') {
                Modal.close();
              } else {
                Modal.fireError(errorMessage, setOpenModal, soundEffects);
              }
            }

            if (uploadedPaths.length > 0) {
              updateResource(
                `/admin/monthly-closings/${monthlyClosingID}/documents-paths/training-needs-sign`,
                {
                  signPath: uploadedPaths[0]
                },
                () => {
                  updateResource(
                    `/admin/monthly-closings/${monthlyClosingID}`,
                    {
                      comments,
                      detectionOfRisksToImpartiality,
                      detectionOfTrainingNeeds,
                      risksToImpartiality,
                      trainingNeeds
                    },
                    () => {
                      Modal.fireSuccess(
                        t('global.correct'),
                        t('global.finishMonthlyClosing'),
                        setOpenModal,
                        () => navigate(`/monthly-closings/${monthlyClosingID}`),
                        soundEffects
                      );
                    },
                    (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
                  );
                },
                (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            }
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else {
        updateResource(
          `/admin/monthly-closings/${monthlyClosingID}`,
          {
            comments,
            detectionOfRisksToImpartiality,
            detectionOfTrainingNeeds,
            risksToImpartiality,
            trainingNeeds
          },
          () => {
            Modal.fireSuccess(
              t('global.correct'),
              t('global.finishMonthlyClosing'),
              setOpenModal,
              () => navigate(`/monthly-closings/${monthlyClosingID}`),
              soundEffects
            );
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  };

  useEffect(() => {
    getMonthlyClosing();
  }, [monthlyClosingID]);

  return (
    <div className="monthly-closings-finish">
      <Header
        title={t('global.monthlyClosing', {
          month: i18n.language === 'es' ? (monthsEs as any)[dayjs(monthlyClosing.createdAt).format('MMMM').toUpperCase()] : (monthsEn as any)[dayjs(monthlyClosing.createdAt).format('MMMM').toUpperCase()],
          year: dayjs(monthlyClosing.createdAt).year(),
          invoice: '',
          status: ''
        }) || ''}
        showBackbutton={true}
        onGoBack={() => navigate(`/monthly-closings/${monthlyClosingID}`)}
      />
      <div className="monthly-closings-finish__main">
        <Input
          type='textarea'
          value={comments}
          onChange={(value) => setComments(`${value}`)}
          placeholder={t('global.comments') || ''}
          title={t('global.comments') || ''}
          hasError={!!errors.comments}
          helperText={errors.comments}
          maxLength={1000}
        />
        <div className="monthly-closings-finish__main__switches">
          <div className="monthly-closings-finish__main__switches__item">
            <SubTitle subTitle={t('global.detectionOfRisksToImpartiality')} />
            <Switch
              leftLabel={{
                text: t('global.yes'),
                value: 'yes'
              }}
              rigthLabel={{
                text: 'No',
                value: 'no'
              }}
              onChange={(value: string | number) => {
                setDetectionOfRisksToImpartiality(value === 'yes');
              }}
              defaultChecked={false}
            />
          </div>
          <div className="monthly-closings-finish__main__switches__item">
            <SubTitle subTitle={t('global.detectionOfTrainingNeeds')} />
            <Switch
              leftLabel={{
                text: t('global.yes'),
                value: 'yes'
              }}
              rigthLabel={{
                text: 'No',
                value: 'no'
              }}
              onChange={(value: string | number) => {
                setDetectionOfTrainingNeeds(value === 'yes');
              }}
              defaultChecked={false}
            />
          </div>
        </div>
        {
          detectionOfRisksToImpartiality && (
            <div className="monthly-closings-finish__main__card-info">
              <Title title={t('global.detectionOfRisksToImpartiality')} />
              <Input
                type='text'
                value={risksToImpartiality.involved}
                onChange={(value) => setRisksToImpartiality({ ...risksToImpartiality, involved: `${value}` })}
                placeholder={'Nombre del/los involucrados'}
                title={'Nombre del/los involucrados'}
                hasError={!!errors.involved}
                helperText={errors.involved}
              />
              <Input
                type='text'
                value={risksToImpartiality.positionOfThoseInvolved}
                onChange={(value) => setRisksToImpartiality({ ...risksToImpartiality, positionOfThoseInvolved: `${value}` })}
                placeholder={'Puesto del/los involucrados'}
                title={'Puesto del/los involucrados'}
                hasError={!!errors.positionOfThoseInvolved}
                helperText={errors.positionOfThoseInvolved}
              />
              <Input
                type='textarea'
                value={risksToImpartiality.situation}
                onChange={(value) => setRisksToImpartiality({ ...risksToImpartiality, situation: `${value}` })}
                placeholder={'Actividades que pueden representan un riesgo en la confianza (Situación)'}
                title={'Actividades que pueden representan un riesgo en la confianza (Situación)'}
                hasError={!!errors.situation}
                helperText={errors.situation}
                maxLength={1000}
              />
              <Input
                type='textarea'
                value={risksToImpartiality.riskToImpartiality}
                onChange={(value) => setRisksToImpartiality({ ...risksToImpartiality, riskToImpartiality: `${value}` })}
                placeholder={'Riesgo a la imparcialidad'}
                title={'Riesgo a la imparcialidad'}
                hasError={!!errors.riskToImpartiality}
                helperText={errors.riskToImpartiality}
                maxLength={1000}
              />
              <Input
                type='textarea'
                value={risksToImpartiality.resultAndCountermeasure}
                onChange={(value) => setRisksToImpartiality({ ...risksToImpartiality, resultAndCountermeasure: `${value}` })}
                placeholder={'Resultado del análisis y contra medida a aplicar'}
                title={'Resultado del análisis y contra medida a aplicar'}
                hasError={!!errors.resultAndCountermeasure}
                helperText={errors.resultAndCountermeasure}
                maxLength={1000}
              />
            </div>
          )
        }
        {
          detectionOfTrainingNeeds && (
            <div className="monthly-closings-finish__main__card-info">
              <Title title={t('global.detectionOfTrainingNeeds')} />
              <Input
                type='text'
                value={trainingNeeds.personEvaluated}
                onChange={(value) => setTrainingNeeds({ ...trainingNeeds, personEvaluated: `${value}` })}
                placeholder={'Nombre de la persona evaluada'}
                title={'Nombre de la persona evaluada'}
                hasError={!!errors.personEvaluated}
                helperText={errors.personEvaluated}
              />
              <Input
                type='text'
                value={trainingNeeds.positionOfThePersonBeingEvaluated}
                onChange={(value) => setTrainingNeeds({ ...trainingNeeds, positionOfThePersonBeingEvaluated: `${value}` })}
                placeholder={'Puesto de la persona'}
                title={'Puesto de la persona'}
                hasError={!!errors.positionOfThePersonBeingEvaluated}
                helperText={errors.positionOfThePersonBeingEvaluated}
              />
              <Input
                type='textarea'
                value={trainingNeeds.generalComments}
                onChange={(value) => setTrainingNeeds({ ...trainingNeeds, generalComments: `${value}` })}
                placeholder={'Comentarios generales'}
                title={'Comentarios generales'}
                hasError={!!errors.generalComments}
                helperText={errors.generalComments}
                maxLength={1000}
              />
              <Input
                type='text'
                value={trainingNeeds.correctiveAction}
                onChange={(value) => setTrainingNeeds({ ...trainingNeeds, correctiveAction: `${value}` })}
                placeholder={'Acción correctiva/Preventiva'}
                title={'Acción correctiva/Preventiva'}
                hasError={!!errors.correctiveAction}
                helperText={errors.correctiveAction}
              />
              <Input
                type='text'
                value={trainingNeeds.elaborates}
                onChange={(value) => setTrainingNeeds({ ...trainingNeeds, elaborates: `${value}` })}
                placeholder={'Elabora'}
                title={'Elabora'}
                hasError={!!errors.elaborates}
                helperText={errors.elaborates}
              />
              <SubTitle subTitle={t('tasks.sign')} />
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'witnesses__content__canvas-draw' }}
              />
              <div className='monthly-closings-finish__main__card-info__action-buttons'>
                <Button type='secondary-outlined' onClick={() => sigCanvas.current.clear()} label={t('services.clean') || ''} />
              </div>
            </div>
          )
        }
        <div className="monthly-closings-finish__main__button-back">
          <Button
            type={'primary'}
            onClick={finishMonthClosign}
            label={t('global.finish') || ''}
            size='big'
            icon='check'
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyClosingnsFinish;
