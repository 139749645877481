export default {
  1: 'Folio Repetido',
  2: 'Folio de solicitud',
  3: 'NOM',
  4: 'Número de acreditación',
  5: 'RFC',
  6: 'Denominación social o nombre',
  7: 'Tipo de persona',
  8: 'Marca del producto',
  9: 'Descripción del producto',
  10: 'Fracción arancelaria',
  11: 'Fecha de envío de la solicitu',
  12: 'Vigencia de la solicitud',
  13: 'Modalidad de etiquetado',
  14: 'Modelo',
  15: 'UMC',
  16: 'Cantidad',
  17: 'Número de etiquetas a verificar',
  18: 'Diferente formato layout',
  19: 'Sin relación UVA-NOM',
  20: 'Año de la NOM',
  21: 'Caracteres excedidos',
  22: 'Celdas vacía',
  23: 'La fracción arancelaria declarada no corresponde a la nueva TIGIE'
};
