export default {
  active: 'Active',
  inactive: 'Inactive',
  'validating-invoices': 'Validating invoices',
  'inspecting-products': 'Inspecting products',
  concluded: 'Concluded',
  deleted: 'Deleted',
  'invoice-assigned': 'Assigned invoice',
  'validation-in-progress': 'Validation in progress',
  validated: 'Validated',
  'not-validated': 'Not validated',
  'cancellation-in-progress': 'Cancelation in progress',
  inspection: 'Inspecting',
  'in-inspection': 'Inspecting',
  cancelled: 'Cancelled',
  scheduled: 'Scheduled',
  'in-progress': 'In progress',
  'in-review': 'In review',
  'ghost-invoice': 'Ghost invoice',
  available: 'Available',
  'not-available': 'Not available',
  open: 'Open',
  close: 'Close',
  approved: 'Approved'
};
