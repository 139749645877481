import React from 'react';

import './styles.scss';

interface Props {
  orientation: 'vertical' | 'horizontal'
}

const Separator: React.FC<Props> = (props) => {
  const {
    orientation
  } = props;

  return (
    <div className={`separator separator--${orientation}`} />
  );
};

export default Separator;
