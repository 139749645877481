import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import {
  Button,
  Modal,
  Separator,
  Table
} from '../../../../components';
import { useResource } from '../../../../hooks';
import {
  DictumProduct,
  Ordering,
  Pagination,
  TableHeader,
  TableOrderDirection
} from '../../../../interfaces';
import { utils } from '../../../../helpers';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  selectedFilter: string
  filterValue: string
  onError: (_error: string) => void
  fetchProducts: boolean
  setFetchProducts: (_value: boolean) => void
}

export const Layout1: React.FC<Props> = (props) => {
  const {
    selectedFilter,
    filterValue,
    onError,
    fetchProducts,
    setFetchProducts
  } = props;

  const { t } = useTranslation();

  const { fetchResources } = useResource<DictumProduct>();
  const { createResource } = useResource<any>();

  const {
    userRole
  } = useContext(AppContext);

  const [refetch, setRefetch] = useState<boolean>(false);
  const [products, setProducts] = useState<DictumProduct[]>([]);
  const [ordering, setOrdering] = useState<Ordering>({
    orderBy: 'invoice',
    orderDirection: 'ASC'
  });
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    perPage: 10,
    totalItems: 0
  });

  const tableHeaders: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('services.petitionNumber'),
      value: 'service.number',
      format: 'none'
    },
    {
      label: t('clients.titleS'),
      value: 'client.shortName',
      format: 'none'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: t('global.tariffFraction'),
      value: 'tariffFraction',
      format: 'none'
    },
    {
      label: 'UMC',
      value: 'umc',
      format: 'none'
    },
    {
      label: t('services.umcQuantity'),
      value: 'umcQuantity',
      format: 'none'
    }
  ];

  const handelChangeOrder = (order: string, direction: TableOrderDirection) => {
    setOrdering({
      orderBy: order,
      orderDirection: direction
    });
  };

  const handleChangePage = (page: number) => {
    setPagination({
      ...pagination,
      currentPage: page
    });
  };

  const downloadLayout1 = () => {
    createResource(
      '/admin/se-procedures/layout-1',
      {},
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.buffer.data);

        const blob = new Blob([bytes], { type: 'text/csv;charset=utf-8;' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);

        const now = new Date();
        const date: string = dayjs(now).format('DD-MMM-YYYY');
        const time: string = dayjs(now).format('hh:mm a');

        link.download = `Layout 1 del ${date}, ${time}.csv`;
        link.click();

        setRefetch(!refetch);

        setFetchProducts(!fetchProducts);
        Modal.close();
      },
      (error: string) => onError(error)
    );
  };

  useEffect(() => {
    const filters: { [filter: string]: string | boolean } = {
      s_status: 'invoice-assigned',
      b_distinctInvoices: true,
      b_requestIsCompleted: true
    };

    filters[`s_${selectedFilter === 'petitionNumber' ? 'serviceNumber' : selectedFilter}`] = filterValue;

    fetchResources(
      {
        resourcePath: '/admin/dictum-products',
        filters,
        pagination: {
          n_perPage: pagination.perPage,
          n_currentPage: pagination.currentPage,
          n_totalItems: pagination.totalItems
        },
        ordering: {
          s_orderBy: ordering.orderBy,
          s_orderDirection: ordering.orderDirection
        }
      },
      (data) => {
        setProducts(data.items);

        if (utils.paginationHasChange(pagination, data.pagination)) {
          setPagination(data.pagination);
        }
      },
      (error: string) => onError(error)
    );
  }, [
    refetch,
    filterValue,
    ordering,
    pagination.currentPage
  ]);

  return (
    <>
      <Table
        headers={tableHeaders}
        items={products}
        currentPage={pagination.currentPage}
        perPage={pagination.perPage}
        totalItems={pagination.totalItems}
        orderBy={ordering.orderBy}
        orderDirection={ordering.orderDirection}
        onChangeCurrentPage={handleChangePage}
        onChangeOrder={handelChangeOrder}
      />
      <Separator orientation='horizontal' />
      <Button
        onClick={downloadLayout1}
        type='primary'
        label={t('global.downloadLayout', { number: 1 }) || ''}
        iconPosition='right'
        icon='downloadWhite'
        alignContent='left'
        disabled={products.length <= 0 || !['master', 'operator'].includes(userRole)}
      />
    </>
  );
};

export default Layout1;
