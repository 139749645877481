import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Header,
  Modal,
  Table
} from '../../components';
import {
  CorrectiveActions as CorrectiveActionsType,
  TableOrderDirection
} from '../../interfaces';

import './styles.scss';
import { useNavigate, useResource } from '../../hooks';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';

const CorrectiveActions = () => {
  const { t } = useTranslation();

  const {
    fetchResources
  } = useResource<CorrectiveActionsType>();

  const navigate = useNavigate();

  const {
    soundEffects,
    userRole,
    manager
  } = useContext(AppContext);

  const [correctiveActions, setCorrectiveActions] = useState<CorrectiveActionsType[]>([]);
  const [orderBy, setOrderBy] = useState<string>('number');
  const [orderDirection, setOrderDirection] = useState<TableOrderDirection>('DESC');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);

  const throttledGetCorrectiveActions = utils.throttle(
    () => {
      const request: any = {
        resourcePath: 'admin/corrective-actions',
        filters: {
          // s_status: filterBy === 'clientStatus' ? filterValue : '',
          // s_shortName: filterBy === 'shortName' ? filterValue : ''
        },
        pagination: {
          n_currentPage: currentPage,
          n_perPage: 10,
          n_totalItems: 10
        },
        ordering: {
          s_orderBy: orderBy,
          s_orderDirection: orderDirection
        }
      };

      fetchResources(
        request,
        (data) => {
          setCorrectiveActions(data.items);
          setCurrentPage(data.pagination.currentPage);
          setTotalItems(data.pagination.totalItems);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledGetCorrectiveActions();
  }, [
    orderBy,
    orderDirection,
    currentPage
  ]);

  return (
    <div className='corrective-actions__main'>
      <Header
        title={t('global.ac-ap')}
        showBackbutton={true}
      />
      <div className="corrective-actions__container">
        {
          (['inspector', 'quality'].includes(userRole)
            || (userRole === 'inspector' ? manager : false)) && (
            <div>
              <Button
                type={'primary'}
                onClick={() => navigate('/corrective-actions/none')}
                label={t('global.newAcAp') || ''}
                icon='addWhite'
                size='big'
              />
            </div>
          )
        }
        <Table
          headers={[
            {
              label: t('tasks.filters.number'),
              value: 'number',
              format: 'none'
            },
            {
              label: t('tasks.filters.type'),
              value: 'type',
              format: 'corrective-actions-type'
            },
            {
              label: t('global.status.title'),
              value: 'status',
              format: 'status'
            },
            {
              label: t('tasks.detail.responsible'),
              value: 'responsible.name',
              format: 'none'
            },
            {
              label: t('global.createdAt'),
              value: 'createdAt',
              format: 'date-time'
            },
            {
              label: t('global.estimatedClosedDate'),
              value: 'estimatedClosingDate',
              format: 'date'
            },
            {
              label: t('global.closedDate'),
              value: 'closingDate',
              format: 'date'
            },
            {
              label: t('global.approver'),
              value: 'approver.name',
              format: 'none'
            }
          ]}
          items={correctiveActions}
          onChangeOrder={(newOrderBy: string, newOrderDirection: TableOrderDirection) => {
            setOrderBy(newOrderBy);
            setOrderDirection(newOrderDirection);
          }}
          onChangeCurrentPage={(newCurrentPage: number) => {
            setCurrentPage(newCurrentPage);
          }}
          onClickRow={(action: CorrectiveActionsType) => {
            navigate(`/corrective-actions/${action.id}`);
          }}
          totalItems={totalItems}
          currentPage={currentPage}
          perPage={10}
          orderBy={orderBy}
          orderDirection={orderDirection}
        />
      </div>
    </div>
  );
};

export default CorrectiveActions;
