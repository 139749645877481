import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  Button,
  FullTable,
  Modal,
  StepsIndicator,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { emptyConstancyProduct } from '../../../../emptyObjects';
import { useKeyPress, useNavigate, useResource } from '../../../../hooks';
import {
  ConstancyProduct,
  Pagination,
  TableHeader
} from '../../../../interfaces';
import ProductForm from './components';

export const Step2 = () => {
  const {
    eraseResources,
    fetchResources
  } = useResource<ConstancyProduct>();

  const navigate = useNavigate();
  const params = useParams();

  const { t } = useTranslation();

  const {
    apiType,
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const [selectedConstancyProduct, setSelectedConstancyProduct] = useState<ConstancyProduct>(
    emptyConstancyProduct
  );
  const [constancyProducts, setConstancyProducts] = useState<ConstancyProduct[]>([]);
  const [currentComponent, setCurrentComponent] = useState<'product-list' | 'product-form'>('product-list');
  const [pagination, setPagination] = useState<Pagination>({
    currentPage: 1,
    perPage: 10,
    totalItems: 0
  });

  const headers: TableHeader[] = [
    {
      label: t('global.invoice'),
      value: 'invoice',
      format: 'none'
    },
    {
      label: t('global.status.title'),
      value: 'status',
      format: 'status'
    },
    {
      label: t('global.norm'),
      value: 'normCode',
      format: 'none'
    },
    {
      label: 'Fase',
      value: 'fase',
      format: 'none'
    },
    {
      label: t('global.brand'),
      value: 'brand',
      format: 'none'
    },
    {
      label: t('global.modelCode'),
      value: 'code',
      format: 'none'
    },
    {
      label: t('global.description'),
      value: 'description',
      format: 'none'
    },
    {
      label: t('global.presentation'),
      value: 'presentation',
      format: 'none'
    },
    {
      label: t('global.design'),
      value: 'indicators.design',
      format: 'boolean'
    },
    {
      label: t('global.urgent'),
      value: 'indicators.urgent',
      format: 'boolean'
    },
    {
      label: t('global.complement003'),
      value: 'indicators.NOM003Complement',
      format: 'boolean'
    }
  ];

  const handleContinue = () => {
    if (constancyProducts.length <= 0) {
      Modal.fireError(t('services.errors.atLeastOneProduct'), setOpenModal);
      return false;
    }

    navigate(`/constancy-request/step_3/${params.constancyServiceID}/${params.requestNumber}/${params.clientID}/${params.collaboratorID}`);
    return true;
  };

  const handleChangePage = (page: number) => {
    setPagination({
      ...pagination,
      currentPage: page
    });
  };

  const deleteConstancyProduct = (productID: string) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteProduct'),
      () => {
        eraseResources(
          `/${apiType}/constancy-products`,
          {
            s_serviceID: params.constancyServiceID,
            s_productID: productID
          },
          () => {
            const auxConstancyproducts = constancyProducts.filter(
              (constancyProduct) => constancyProduct.id !== productID
            );

            setConstancyProducts(auxConstancyproducts);
            Modal.fireSuccess(t('global.correct'), t('services.deletedProduct'), setOpenModal, undefined, soundEffects);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  const deleteAllproducts = () => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.deleteProducts'),
      () => {
        eraseResources(
          `/${apiType}/constancy-products`,
          {
            b_deleteAll: true,
            s_serviceID: params.constancyServiceID
          },
          () => {
            setConstancyProducts([]);
            Modal.fireSuccess(t('global.correct'), t('services.deletedProducts'), setOpenModal, undefined, soundEffects);
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    if (currentComponent === 'product-list') {
      fetchResources(
        {
          resourcePath: `/${apiType}/constancy-products`,
          filters: {
            s_serviceID: params.constancyServiceID || ''
          },
          pagination: {
            n_perPage: pagination.perPage,
            n_currentPage: pagination.currentPage,
            n_totalItems: pagination.totalItems
          }
        },
        (data) => {
          setConstancyProducts(data.items);
          setPagination(data.pagination);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [currentComponent, pagination.currentPage, apiType]);

  useKeyPress(
    openModal ? () => { } : () => handleContinue(),
    [
      constancyProducts,
      currentComponent,
      openModal,
      selectedConstancyProduct
    ],
    currentComponent === 'product-list'
  );

  const component = {
    'product-list': (
      <div className='step-2__full-table-container'>
        <div className='step-2__action-buttons-container'>
          <Button
            onClick={() => {
              setSelectedConstancyProduct(emptyConstancyProduct);
              setCurrentComponent('product-form');
            }}
            type='secondary-outlined'
            label={t('services.addProducts') || ''}
            icon='plusRounded'
            iconPosition='top'
            transparent={true}
          />
          <Button
            onClick={() => deleteAllproducts()}
            type='secondary-outlined'
            label={t('services.deleteAllProducts') || ''}
            icon='minusRounded'
            iconPosition='top'
            transparent={true}
            disabled={pagination.totalItems === 0}
          />
        </div>
        <FullTable
          headers={headers}
          items={constancyProducts}
          currentPage={pagination.currentPage}
          perPage={pagination.perPage}
          totalItems={pagination.totalItems}
          onChangeCurrentPage={handleChangePage}
          onDeleteItem={(item: any) => deleteConstancyProduct(item.id)}
          onEditItem={(item: any) => {
            setSelectedConstancyProduct(item);
            setCurrentComponent('product-form');
          }}
        />
        <div className='step-2__button-container'>
          <Button
            onClick={() => navigate(`/constancy-request/step_1/${params.constancyServiceID}`)}
            type='primary'
            label={t('global.goBack') || ''}
            icon='leftArrow'
            iconPosition='left'
          />
          <Button
            onClick={handleContinue}
            type='primary'
            label={t('global.continue') || ''}
            icon='rightArrow'
            iconPosition='right'
          />
        </div>
      </div>
    ),
    'product-form': (
      <ProductForm
        preloadedProduct={selectedConstancyProduct}
        clientID={params.clientID || ''}
        collaboratorID={params.collaboratorID || ''}
        constancyServiceID={params.constancyServiceID || ''}
        onChangeCurrentComponent={setCurrentComponent}
      />
    )
  };

  return (
    <>
      <div className='step-2__steps'>
        {
          apiType === 'admin' ? (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={2}
            />
          ) : (
            <StepsIndicator
              stepsQuantity={3}
              currentStep={1}
            />
          )
        }
      </div>
      <div className='step-2__content'>
        <div className='step-2'>
          <Title title={apiType === 'admin' ? t('services.step2Products') : t('services.step1Products')} />
          {component[currentComponent]}
        </div>
      </div>
    </>
  );
};

export default Step2;
