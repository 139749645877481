import { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { Modal } from '../components';
import { AppContext } from '../context/AppContext';

export default function useValidNavigate() {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const {
    soundEffects
  } = useContext(AppContext);

  const validNavigate = async (value: string | number, options?: NavigateOptions) => {
    if (typeof value === 'number') {
      navigate(value);
      return;
    }

    const auxValue = String(value);

    if (auxValue.includes('undefined') || auxValue.includes('null') || auxValue.includes('//')) {
      Modal.fireError(t('global.notFound'), undefined, soundEffects);
      return;
    }

    navigate(value, options);
  };

  return validNavigate;
}
