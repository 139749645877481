import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../../../components';

import Table from '../../../../components/Table';
import { useNavigate } from '../../../../hooks';
import {
  ConstancyProduct,
  TableOrderDirection
} from '../../../../interfaces';

interface Props {
  lookInTheTrash: boolean
  products: ConstancyProduct[]
  totalItems: number
  orderBy: string
  orderDirection: TableOrderDirection
  setOrderBy: (_value: string) => void
  setOrderDirection: (_value: TableOrderDirection) => void
  currentPage: number
  setCurrentPage: (_value: number) => void
}

const ConstancyProducts: React.FC<Props> = (props) => {
  const {
    lookInTheTrash,
    products,
    totalItems,
    orderBy,
    orderDirection,
    setOrderBy,
    setOrderDirection,
    currentPage,
    setCurrentPage
  } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="constancy-service-detail__table" id="data-section">
      <Title title={t('global.products')} type="primary" />
      <br />
      <Table
        headers={[
          {
            label: t('tasks.table.invoice'),
            value: 'invoice',
            format: 'none'
          },
          {
            label: t('global.status.title'),
            value: 'status',
            format: 'status'
          },
          {
            label: t('global.description'),
            value: 'description',
            format: 'none',
            limitLength: 30
          },
          {
            label: 'Inspector',
            value: 'inspector.name',
            format: 'person-name',
            onlyAdmin: true
          },
          {
            label: t('services.roundTitle'),
            value: 'round',
            format: 'none'
          },
          {
            label: t('global.norm'),
            value: 'normCode',
            format: 'none'
          },
          {
            label: t('global.code'),
            value: 'code',
            format: 'none',
            limitLength: 40
          },
          {
            label: t('global.brand'),
            value: 'brand',
            format: 'none'
          },
          {
            label: t('global.design'),
            value: 'indicators.design',
            format: 'boolean'
          },
          {
            label: t('global.urgent'),
            value: 'indicators.urgent',
            format: 'boolean'
          },
          {
            label: t('global.complement003'),
            value: 'indicators.NOM003Complement',
            format: 'boolean'
          },
          {
            label: t('global.approver'),
            value: 'approver.name',
            format: 'person-name',
            onlyAdmin: true
          }
        ]}
        items={products}
        currentPage={currentPage}
        perPage={10}
        totalItems={totalItems}
        orderBy={orderBy}
        orderDirection={orderDirection}
        onChangeCurrentPage={(newCurrentPage: number) => {
          setCurrentPage(newCurrentPage);
        }}
        onChangeOrder={(newOrderBy: string, newOrderDirection: TableOrderDirection) => {
          setOrderBy(newOrderBy);
          setOrderDirection(newOrderDirection);
          setCurrentPage(1);
        }}
        onClickRow={(product: ConstancyProduct) => {
          navigate(`/constancy-products/detail/${product.id}/${lookInTheTrash ? 'true' : 'none'}/false`);
        }}
      />
    </div>
  );
};

export default ConstancyProducts;
