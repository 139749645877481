import React from 'react';
import { useTranslation } from 'react-i18next';

import { DataDisplay } from '../../../../components';
import { User, UserNorms, ValueFormat } from '../../../../interfaces';

interface Item {
  label: string
  value: string | number | boolean | Date | string[] | UserNorms[]
  format: ValueFormat
}

interface Props {
  user: User
}

export const GeneralInfo: React.FC<Props> = (props) => {
  const {
    user
  } = props;

  const { t } = useTranslation();

  const items: Item[] = [
    {
      label: t('global.name'),
      value: user.name,
      format: 'none'
    },
    {
      label: 'E-mail',
      value: user.email,
      format: 'none'
    },
    {
      label: t('global.role'),
      value: user.role,
      format: 'role'
    },
    {
      label: t('users.able'),
      value: user.isActive,
      format: 'boolean'
    }
  ];

  const itemsInspector: Item[] = [
    {
      label: t('global.name'),
      value: user.name,
      format: 'none'
    },
    {
      label: 'E-mail',
      value: user.email,
      format: 'none'
    },
    {
      label: t('global.role'),
      value: user.role,
      format: 'role'
    },
    {
      label: t('users.approver'),
      value: user.approver,
      format: 'boolean'
    },
    {
      label: t('global.norms'),
      value: user.norms,
      format: 'list-user-norms'
    },
    {
      label: t('users.able'),
      value: user.isActive,
      format: 'boolean'
    },
    {
      label: t('clients.phone'),
      value: user.phoneNumber || '',
      format: 'none'
    },
    {
      label: t('users.followUpInOperationsReport'),
      value: user.followUpInOperationsReport,
      format: 'boolean'
    }
  ];

  return (
    <DataDisplay
      dataTitle={t('services.generalInformation')}
      items={user.role === 'inspector' ? itemsInspector : items}
    />
  );
};

export default GeneralInfo;
