import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalView } from '../..';
import { AppContext } from '../../../context/AppContext';
import { emptyDictumProduct } from '../../../emptyObjects';
import { useResource } from '../../../hooks';
import { DictumProduct, UpdatedDictumProduct } from '../../../interfaces';
import { DictumProductForm } from '../DictumProductForm/DictumProductForm';

import './styles.scss';

interface Props {
  visible: boolean
  title?: string
  clientID?: string
  collaboratorID?: string
  serviceID?: string
  preloadedProduct?: DictumProduct
  onCreateProduct?: (_product: DictumProduct) => void
  onUpdateProduct?: (_product: DictumProduct) => void
  onClose: () => void
  onError?: (_error: string) => void
}

const ModalViewDictumProductForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    visible = true,
    title = t('services.productFormTitle') || '',
    preloadedProduct = emptyDictumProduct,
    onCreateProduct = (_product: DictumProduct) => { },
    onUpdateProduct = (_product: DictumProduct) => { },
    onClose = () => { },
    onError = (_error: string) => { }
  } = props;

  let {
    clientID,
    collaboratorID,
    serviceID
  } = props;

  if (preloadedProduct) {
    if (!clientID) {
      clientID = preloadedProduct.client.id;
    }
    if (!collaboratorID) {
      collaboratorID = preloadedProduct.collaborator.id;
    }
    if (!serviceID) {
      serviceID = preloadedProduct.service.id;
    }
  }

  const {
    createResource
  } = useResource<DictumProduct[]>();

  const {
    updateResource: updateDictumProduct
  } = useResource<UpdatedDictumProduct>();

  const {
    apiType
  } = useContext(AppContext);

  return (
    <ModalView
      visible={visible}
      onClose={onClose}
      confirmationButonLabel={t('global.cancel') || ''}
      customComponent={
        <div className='form-container'>
          <DictumProductForm
            title={title}
            preloadedProduct={preloadedProduct}
            cleanStates={visible}
            onCreateProduct={(product: DictumProduct) => {
              createResource(
                `/${apiType}/dictum-products`,
                {
                  products: [product],
                  clientID,
                  collaboratorID,
                  dictumServiceID: serviceID
                },
                (data) => {
                  onCreateProduct(data[0]);
                },
                (error) => {
                  onError(error);
                }
              );
            }}
            onUpdateProduct={(product: DictumProduct) => {
              updateDictumProduct(
                `/${apiType}/dictum-products/${product.id}`,
                product,
                (data) => {
                  onUpdateProduct(data.product);
                  onClose();
                },
                (error) => {
                  onError(error);
                }
              );
            }}
          />
        </div>
      }
    />
  );
};

export default ModalViewDictumProductForm;
