import React, { ReactElement, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useKeyPress } from '../../hooks';

import Button from '../Button';
import Icon from '../Icon';
import Separator from '../Separator';

import './styles.scss';

export type ModalComponentTypes = 'success' | 'error' | 'warning' | 'loading' | 'custom';

export interface ModalComponentProps {
  type?: ModalComponentTypes
  title?: string
  subtitle?: string
  visible?: boolean
  fullSize?: boolean
  customComponent?: ReactElement | null
  onConfirm?: (() => void) | null
  onCancel?: (() => void)
  confirmationButonLabel?: string
  cancelButonLabel?: string
  setOpenModal?: (_value: boolean) => void
  sound?: boolean
  loadProgress?: number
  loadLabel?: string
  ableFuncionalityEnterToConfirm?: boolean
}

export const ModalComponent: React.FC<ModalComponentProps> = (props) => {
  const { t } = useTranslation();

  const {
    type = 'success',
    title = '',
    subtitle = '',
    visible = false,
    fullSize = false,
    customComponent = <></>,
    onConfirm = null,
    onCancel = () => { },
    confirmationButonLabel = 'Ok',
    cancelButonLabel = t('global.cancel'),
    setOpenModal,
    sound = true,
    loadProgress,
    loadLabel,
    ableFuncionalityEnterToConfirm = true
  } = props;

  const [modifierLoading, setModifierLoading] = useState<'christmas' | 'september' | 'february' | 'october' | ''>('');

  const noActions: boolean = type === 'loading';

  const getHeader = (): ReactElement => {
    if (type === 'loading') {
      return (
        <></>
      );
    }

    return (
      <div className='modal__header-container'>
        <Icon type='close' onClick={onCancel} className='modal__header-container__close-icon' />
      </div>
    );
  };

  useEffect(() => {
    const currentDate = dayjs();

    const startDateChristmas = dayjs().month(10).date(3);
    const endDateChristmas = dayjs().month(11).date(31);

    const startDateSeptember = dayjs().month(8).date(0);
    const endDateSeptember = dayjs().month(8).date(30);

    const startDateFebruary = dayjs().month(1).date(0);
    const endDateFebruary = dayjs().month(1).date(28);

    const startDateOctober = dayjs().month(9).date(0);
    const endDateOctober = dayjs().month(9).date(30);

    if (currentDate.isAfter(startDateChristmas) && currentDate.isBefore(endDateChristmas)) {
      setModifierLoading('christmas');
    }

    if (currentDate.isAfter(startDateSeptember) && currentDate.isBefore(endDateSeptember)) {
      setModifierLoading('september');
    }

    if (currentDate.isAfter(startDateFebruary) && currentDate.isBefore(endDateFebruary)) {
      setModifierLoading('february');
    }

    if (currentDate.isAfter(startDateOctober) && currentDate.isBefore(endDateOctober)) {
      setModifierLoading('october');
    }
  }, []);

  const getContent = (): ReactElement => {
    if (type === 'loading') {
      if (modifierLoading.length > 0) {
        if (modifierLoading === 'christmas') {
          return (
            <>
              <div className="modal__loading-container">
                <Icon type={'gift'} className={`modal__loading-container__square-1 modal__loading-container__square-1--${modifierLoading}`} />
                <Icon type={'santaclaus'} className={`modal__loading-container__square-2 modal__loading-container__square-2--${modifierLoading}`} />
                <Icon type={'christmasTree'} className={`modal__loading-container__square-3 modal__loading-container__square-3--${modifierLoading}`} />
              </div>
              <p>{loadLabel || t('global.loading')}</p>
            </>
          );
        }

        if (modifierLoading === 'september') {
          return (
            <>
              <div className="modal__loading-container">
                <Icon type={'mexican'} className={`modal__loading-container__square-1 modal__loading-container__square-1--${modifierLoading}`} />
                <Icon type={'mexicoFlag'} className={`modal__loading-container__square-2 modal__loading-container__square-2--${modifierLoading}`} />
                <Icon type={'agave'} className={`modal__loading-container__square-3 modal__loading-container__square-3--${modifierLoading}`} />
              </div>
              <p>{loadLabel || t('global.loading')}</p>
            </>
          );
        }

        if (modifierLoading === 'february') {
          return (
            <>
              <div className="modal__loading-container">
                <Icon type={'heart'} className={`modal__loading-container__square-1 modal__loading-container__square-1--${modifierLoading}`} />
                <Icon type={'romanticLetter'} className={`modal__loading-container__square-2 modal__loading-container__square-2--${modifierLoading}`} />
                <Icon type={'cupidBow'} className={`modal__loading-container__square-3 modal__loading-container__square-3--${modifierLoading}`} />
              </div>
              <p>{loadLabel || t('global.loading')}</p>
            </>
          );
        }

        if (modifierLoading === 'october') {
          return (
            <>
              <div className="modal__loading-container">
                <Icon type={'pumpkin'} className={`modal__loading-container__square-1 modal__loading-container__square-1--${modifierLoading}`} />
                <Icon type={'blackCat'} className={`modal__loading-container__square-2 modal__loading-container__square-2--${modifierLoading}`} />
                <Icon type={'ghost'} className={`modal__loading-container__square-3 modal__loading-container__square-3--${modifierLoading}`} />
              </div>
              <p>{loadLabel || t('global.loading')}</p>
            </>
          );
        }
      }

      return (
        <>
          <div className="modal__loading-container">
            <div className="modal__loading-container__square-1"></div>
            <div className="modal__loading-container__square-2"></div>
            <div className="modal__loading-container__square-3"></div>
          </div>
          <p>{loadLabel || t('global.loading')}</p>
        </>
      );
    }

    if (type === 'custom') {
      return (
        <div className='modal__custom-component'>
          {customComponent}
        </div>
      );
    }

    return (
      <>
        <Icon type={type} className='modal__icon' alt='Icon Modal' />
        <p className="modal__title">{title}</p>
        <p className="modal__sub-title">{subtitle}</p>
      </>
    );
  };

  useEffect(() => {
    if (loadProgress !== undefined) {
      // eslint-disable-next-line no-undef
      const progressBar = document.getElementById('progress-value');

      if (progressBar) {
        if (loadProgress <= 0) {
          progressBar.style.width = '0%';
        } else if (loadProgress >= 100) {
          progressBar.style.width = '100%';
        } else {
          progressBar.style.width = `${loadProgress}%`;
        }
      }
    }
  }, [loadProgress]);

  const getButtons = (): ReactElement => {
    if (type === 'loading') {
      if (loadProgress !== undefined) {
        let label: number = 0;

        if (loadProgress < 0) {
          label = 0;
        } else if (loadProgress > 100) {
          label = 100;
        } else {
          label = loadProgress;
        }

        return (
          <div className='loading-bar'>
            <div className='loading-bar__bar'>
              <div className='loading-bar__bar__label'>{label}%</div>
              <div className='loading-bar__bar__progress' id='progress-value'></div>
            </div>
          </div>
        );
      }

      return (<></>);
    }

    if (onConfirm) {
      return (
        <>
          <Separator orientation='horizontal' />
          <div className="modal__buttons-container">
            <Button onClick={onCancel} disabled={noActions} type='red' size='big' label={cancelButonLabel || t('global.cancel') || ''} />
            <Button onClick={onConfirm} disabled={noActions} type='primary' size='big' label={confirmationButonLabel || t('global.accept') || ''} submit={true} />
          </div>
        </>
      );
    }

    return (
      <>
        <Separator orientation='horizontal' />
        <div className="modal__buttons-container">
          <Button onClick={onCancel} disabled={noActions} type='primary' size='big' label={confirmationButonLabel} submit={true} />
        </div>
      </>
    );
  };

  useKeyPress(
    () => {
      if (type !== 'loading' && ableFuncionalityEnterToConfirm) {
        let alreadyShowModal: boolean = false;
        // eslint-disable-next-line no-undef
        const container = document.getElementById('modal');

        if (container) {
          // eslint-disable-next-line no-undef
          const modalComponent = container.querySelector('#modal-component');

          if (modalComponent) {
            if (modalComponent.classList.contains('modal--visible')) {
              alreadyShowModal = true;
            }
          }
        }

        if (onConfirm && alreadyShowModal === false) {
          onConfirm();
        } else {
          onCancel();
        }
      }
    },
    [
      type,
      visible,
      customComponent,
      onConfirm,
      onCancel,
      title,
      subtitle,
      fullSize,
      confirmationButonLabel,
      setOpenModal,
      sound
    ],
    visible
  );

  useKeyPress(
    () => {
      if (!noActions && type !== 'loading') {
        onCancel();
      }
    },
    [
      type,
      title,
      subtitle,
      fullSize,
      customComponent,
      onConfirm,
      onCancel,
      confirmationButonLabel
    ],
    visible,
    'Escape'
  );

  useEffect(() => {
    if (setOpenModal) {
      setOpenModal(visible);
    }
  }, [visible, setOpenModal]);

  useEffect(() => {
    if (type === 'success' && visible && sound) {
      // eslint-disable-next-line no-undef
      const audio = new Audio(`${process.env.PUBLIC_URL}/sounds/Success.mp3`);
      audio.play();
    }
  }, [visible, type, sound]);

  return ReactDOM.createPortal(
    (
      <div className={visible ? 'modal modal--visible' : 'modal modal--hidden'} id="modal-component" >
        <div onClick={noActions ? () => { } : onCancel} className='modal__modal-bg' />
        <div className={fullSize ? 'modal__modal-content modal__modal-content--fullSize' : 'modal__modal-content'}>
          {getHeader()}
          {getContent()}
          {getButtons()}
        </div>
      </div >
    ),
    // eslint-disable-next-line no-undef
    document.getElementById(type === 'custom' ? 'modal-view' : 'modal') as HTMLElement
  );
};
