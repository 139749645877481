export default {
  active: 'Activo',
  inactive: 'Inactivo',
  'validating-invoices': 'Validando folios',
  'inspecting-products': 'Inspeccionando productos',
  concluded: 'Concluido',
  deleted: 'Eliminado',
  'invoice-assigned': 'Folio asignado',
  'validation-in-progress': 'Validación en progreso',
  validated: 'Validado',
  'not-validated': 'No validado',
  'cancellation-in-progress': 'Cancelación en progreso',
  inspection: 'Inspeccionando',
  'in-inspection': 'Inspeccionando',
  cancelled: 'Cancelado',
  scheduled: 'Asignada',
  'in-progress': 'En progreso',
  'in-review': 'En aprobación',
  'ghost-invoice': 'Folio fantasma',
  available: 'Disponible',
  'not-available': 'No disponible',
  open: 'Abierto',
  close: 'Cerrado',
  approved: 'Aprobado'
};
