import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FullTable, Modal } from '../../../../components';
import { emptyClientAddress } from '../../../../emptyObjects';
import { useResource } from '../../../../hooks';
import {
  Client,
  ClientAddress,
  TableHeader
} from '../../../../interfaces';
import InspectionAddressForm from '../InspectionAddressForm';
import { AppContext } from '../../../../context/AppContext';

interface Props {
  client: Client
  onChangeClient: (_client: Client) => void
}

export const VerifficationAdresses: React.FC<Props> = (props) => {
  const {
    client,
    onChangeClient
  } = props;

  const {
    eraseResource
  } = useResource<Client>();

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const [
    localInspectionAddresses,
    setLocalInspectionAddresses
  ] = useState<ClientAddress[]>(client.inspectionAddresses);
  const [
    createVerifficationAddessModalVisible,
    setCreateVerifficationAddessModalVisible
  ] = useState<boolean>(false);
  const [
    selectedInspectionAddress,
    setSelectedInspectionAddress
  ] = useState<ClientAddress>(emptyClientAddress);

  const headers: TableHeader[] = [
    {
      label: t('clients.street'),
      value: 'street',
      format: 'none'
    },
    {
      label: t('clients.extNumber'),
      value: 'number',
      format: 'none'
    },
    {
      label: t('clients.cologne'),
      value: 'neighborhood',
      format: 'none'
    },
    {
      label: t('clients.city'),
      value: 'city',
      format: 'none'
    },
    {
      label: t('clients.postalCode'),
      value: 'postalCode',
      format: 'none'
    },
    {
      label: t('clients.state'),
      value: 'state',
      format: 'none'
    },
    {
      label: t('clients.country'),
      value: 'country',
      format: 'none'
    }
  ];

  const handleDeleteVerifficationAddress = (addressID: number) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('clients.deleteAddress'),
      () => {
        eraseResource(
          `admin/clients/${client.id}/inspection-address/${addressID}`,
          {},
          (data: Client) => {
            onChangeClient(data);
            Modal.fireSuccess(t('global.correct'), t('clients.deletedAdress'), setOpenModal, undefined, soundEffects);
          },
          (error: string) => Modal.fireError(error, undefined, soundEffects)
        );
      },
      undefined,
      undefined,
      undefined,
      setOpenModal
    );
  };

  useEffect(() => {
    setLocalInspectionAddresses(client.inspectionAddresses);
  }, [client]);

  return (
    <>
      <FullTable
        headers={headers}
        items={localInspectionAddresses}
        currentPage={0}
        perPage={0}
        totalItems={0}
        onChangeCurrentPage={() => { }}
        onDeleteItem={(item: ClientAddress) => {
          handleDeleteVerifficationAddress(Number(item.id));
        }}
        onEditItem={(item: ClientAddress) => {
          setSelectedInspectionAddress(item);
          setCreateVerifficationAddessModalVisible(true);
        }}
        type='inspection-addresses'
      />
      <InspectionAddressForm
        clientAddress={selectedInspectionAddress}
        visible={createVerifficationAddessModalVisible}
        title='Editar dirección de inspección'
        clientID={client.id}
        onClose={() => {
          setCreateVerifficationAddessModalVisible(false);
        }}
        onSuccess={(_client: Client) => {
          setCreateVerifficationAddessModalVisible(false);

          setLocalInspectionAddresses(_client.inspectionAddresses);

          Modal.fireSuccess(t('global.correct'), t('clients.updatedAdress'), setOpenModal, undefined, soundEffects);
        }}
        onError={(error: string) => {
          setCreateVerifficationAddessModalVisible(false);
          Modal.fireError(error, undefined, soundEffects);
        }}
      />
    </>
  );
};

export default VerifficationAdresses;
