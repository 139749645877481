import React, { useEffect, useState } from 'react';

import { TabItem } from '../../interfaces';

import './styles.scss';

interface Props {
  items: TabItem[]
  onSelectTab: (_value: TabItem) => void,
  refreshDependecies?: any
  preselectedTab?: number
}

const Tabs: React.FC<Props> = (props) => {
  const {
    items = [],
    onSelectTab,
    refreshDependecies,
    preselectedTab = 1
  } = props;

  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    setSelectedTab(1);
  }, [refreshDependecies]);

  useEffect(() => {
    setSelectedTab(preselectedTab);
  }, [preselectedTab]);

  return (
    <div className='tabs'>
      <div className='tabs__container'>
        {
          items.map((item: TabItem, index: number) => (
            <div
              className={selectedTab === item.id ? 'tabs__container__item tabs__container__item--selected' : 'tabs__container__item'}
              onClick={() => { onSelectTab(item); setSelectedTab(item.id); }}
              key={index}
            >
              {item.label}
            </div>
          ))
        }
      </div>
      <div className='tabs__separator'></div>
    </div>
  );
};

export default Tabs;
