export default {
  1: 'Repeat invoice',
  2: 'Application invoice',
  3: 'NOM',
  4: 'Accreditation number',
  5: 'RFC',
  6: 'Social denomination or name',
  7: 'Person type',
  8: 'Product brand',
  9: 'Product description',
  10: 'Tariff fraction',
  11: 'Application submission sate',
  12: 'Validity of the request',
  13: 'Labeling mode',
  14: 'Model',
  15: 'UMC',
  16: 'Quantity',
  17: 'Number of labels to verify',
  18: 'Different format layout',
  19: 'No UVA-NOM relationship',
  20: 'Year of the NOM',
  21: 'Exceeded characters',
  22: 'Empty cells',
  23: 'The declared tariff fraction does not correspond to the new TIGIE'
};
