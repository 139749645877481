import i18next from 'i18next';

class validate {
  email(field: string): string {
    const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (field.length <= 0) {
      return i18next.t('services.errors.email');
    }
    if (!isValidEmail.test(field)) {
      return i18next.t('services.errors.validEmail');
    }

    return 'success';
  }

  number(field: string): string {
    if (Number.isNaN(field)) {
      return i18next.t('services.errors.validNumber');
    }

    return 'success';
  }

  text(field: string, minLength?: number, maxLength?: number): string {
    if (field.length <= 0) {
      return i18next.t('services.errors.required');
    }

    if (minLength && (field.length < minLength)) {
      return `${i18next.t('services.errors.minimum')} ${minLength} ${i18next.t('services.errors.large')}`;
    }

    if (maxLength && (field.length > maxLength)) {
      return `${i18next.t('services.errors.maximum')} ${maxLength} ${i18next.t('services.errors.large')}`;
    }

    return 'success';
  }

  petitionNumber(field: string): string {
    const isValidpetitionNumber = /^[0-9]{2} [0-9]{2} [0-9]{4} [0-9]{7}$/;

    if (!isValidpetitionNumber.test(field)) {
      return i18next.t('services.errors.petition');
    }

    return 'success';
  }
}

export default validate;
