export default {
  ENERO: 'January',
  FEBRERO: 'February',
  MARZO: 'March',
  ABRIL: 'April',
  MAYO: 'May',
  JUNIO: 'June',
  JULIO: 'July',
  AGOSTO: 'August',
  SEPTIEMBRE: 'September',
  OCTUBRE: 'October',
  NOVIEMBRE: 'November',
  DICIEMBRE: 'December'
};
