import React, {
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { Spinner, Title } from '..';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';
import { useNavigate } from '../../hooks';

import { DataDisplayItem } from '../../interfaces';

import './styles.scss';

interface Props {
  dataTitle: string
  items: DataDisplayItem[]
}

const DataDisplay: React.FC<Props> = (props: Props) => {
  const {
    dataTitle,
    items
  } = props;

  const { apiType } = useContext(AppContext);

  const navigate = useNavigate();

  const [isPending, startTransition] = useTransition();
  const [localItems, setLocalItems] = useState<DataDisplayItem[]>(items);

  const handleNavigate = (route: string | undefined, onlyAdmin: boolean | undefined) => {
    if (apiType === 'public' && onlyAdmin) {
      () => { };
    } else if (route) {
      navigate(route);
    }
  };

  const setLinkClass = (link: string | undefined, onlyAdmin: boolean | undefined) => {
    if (apiType === 'public' && onlyAdmin) {
      return '';
    }
    if (link) {
      return '--link';
    }

    return '';
  };

  const showItem = (item: DataDisplayItem, index: number) => {
    if (item.onlyAdmin !== undefined) {
      if ((item.onlyAdmin === true && apiType === 'admin')) {
        return (
          <div className="data-display__row" key={index}>
            <div className='data-display__row__label'>{item.label}:</div>
            <div className="data-display__row__gap"></div>
            <div
              className={`data-display__row__value data-display__row__value${setLinkClass(item.navigateRoute, item.onlyAdmin)}`}
              onClick={() => handleNavigate(item.navigateRoute, item.onlyAdmin)}
            >
              {
                utils.formatValue(
                  item.format,
                  item.value,
                  item.auxValue,
                  index + 1
                )
              }
            </div>
          </div>);
      }
    } else {
      return (
        <div className="data-display__row" key={index}>
          <div className='data-display__row__label'>{item.label}:</div>
          <div className="data-display__row__gap"></div>
          <div
            className={`data-display__row__value data-display__row__value${setLinkClass(item.navigateRoute, item.onlyAdmin)}`}
            onClick={() => handleNavigate(item.navigateRoute, item.onlyAdmin)}
          >
            {
              utils.formatValue(
                item.format,
                item.value,
                item.auxValue,
                index + 1
              )
            }
          </div>
        </div>);
    }

    return (<></>);
  };

  useEffect(() => {
    startTransition(() => {
      setLocalItems(items);
    });
  }, [items]);

  return (
    <Suspense fallback={<Spinner />}>
      <div className={`data-display data-display${isPending ? '--loading' : ''}`} id="data-section">
        <Title title={dataTitle} type="primary" />
        <br />
        {
          localItems.map((item: DataDisplayItem, index: number) => (
            showItem(item, index)
          ))
        }
      </div>
    </Suspense>
  );
};

export default DataDisplay;
