import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import SignatureCanvas from 'react-signature-canvas';

import { useParams } from 'react-router-dom';
import { emptyTask } from '../../../../emptyObjects';
import { useResource, useNavigate, useKeyPress } from '../../../../hooks';
import {
  Task,
  PlanificationSignature as PlanificationSignatureType,
  FileInputData,
  SignedFile
} from '../../../../interfaces';

import {
  Button,
  Header,
  Input,
  Modal,
  File as FileElement,
  Spinner,
  ModalView,
  PDFViewer
} from '../../..';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';

interface File {
  data: any
  petitionNumber: string
}

export const PlanificationSignature = () => {
  const params = useParams();

  const {
    fetchResource: getTask,
    updateResource
  } = useResource<Task>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    createResource: getDictumProductDocument
  } = useResource<any>();

  const {
    createResource: sendTaskDocuments
  } = useResource<any>();

  const {
    setOpenModal,
    openModal,
    soundEffects
  } = useContext(AppContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [pdfBlob, setPdfBlob] = useState<File[]>([]);
  const [task, setTask] = useState<Task>(emptyTask);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>('');
  const [signature, setSignature] = useState<PlanificationSignatureType>({
    name: '',
    signature: ''
  });
  const [
    errors,
    setErrors
  ] = useState<{ [name: string]: string }>({});
  const [rectifiedPetitionFile, setRectifiedPetitionFile] = useState<FileInputData>({
    fileName: '',
    data: {}
  });

  const sigCanvas = useRef<any>();

  const dataURLtoBlob = (dataURL: string) => {
    const array = [];
    const binary = atob(dataURL.split(',')[1]);

    let i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i += 1;
    }

    return new File([new Uint8Array(array)], 'png');
  };

  const saveSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      Modal.fireError(t('tasks.errors.sign'), undefined, soundEffects);

      return;
    }

    signature.signature = sigCanvas.current.toDataURL('image/png');

    sigCanvas.current.clear();

    setSignature({ ...signature });
  };

  const handleShowAssignmentAndInspectionPlanningDocument = async () => {
    setPdfBlob([]);
    Modal.fireLoading(setOpenModal);
    // eslint-disable-next-line array-callback-return
    await Promise.all(task.services.map((service) => {
      getDictumProductDocument(
        `/admin/tasks/${task.id}/assignation-and-planification-of-the-inspection`,
        {
          petitionNumber: service
        },
        (data) => {
          const bytes = new Uint8Array(data.file.data);

          const blob = new Blob([bytes], { type: 'application/pdf' });

          setPdfBlob([
            ...pdfBlob,
            {
              data: blob,
              petitionNumber: service
            }
          ]);
        },
        (error) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }));
    Modal.close(setOpenModal);
  };

  const validData = () => {
    if (signature.signature === '' && rectifiedPetitionFile.fileName === '') {
      Modal.fireError(t('tasks.errors.atLastOnePlanificationSign'), undefined, soundEffects);

      return false;
    }

    if (signature.name === '') {
      setErrors({
        name: t('tasks.errors.namePlanificationSign')
      });

      return false;
    }

    return true;
  };

  const validEmails = (): boolean => {
    if (emails.length === 0) {
      setErrors({
        emails: t('global.dataError')
      });
      return false;
    }

    return true;
  };

  const handleSendTaskDocuments = () => {
    if (validEmails()) {
      sendTaskDocuments(
        `admin/tasks/send-task-documents/${task.id}`,
        {
          type: 'planification',
          emails
        },
        () => {
          Modal.fireSuccess(
            t('global.correct'),
            t('global.sendedFiles'),
            setOpenModal,
            () => setShowModal(false),
            soundEffects
          );
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  };

  const uploadSigns = () => {
    if (validData()) {
      if (rectifiedPetitionFile.fileName === '') {
        registerUploadedFiles(
          `/admin/tasks/${task.id}/documents/planification`,
          {
            filePaths: [{
              path: `${signature.name}.png`.replace(/[%&?¿=#/+]/g, ''),
              type: 'image/png'
            }]
          },
          async (data) => {
            const uploadedPaths: string[] = [];
            let errorMessage: string = '';

            Modal.fireLoading(undefined, 0);
            try {
              // eslint-disable-next-line array-callback-return, consistent-return
              await Promise.all(data.map((item) => {
                const formData = new FormData();

                Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                  formData.append(key, value as string);
                });

                formData.append('file', dataURLtoBlob(signature.signature));

                return axios.post(
                  item.signedURL.url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                      const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                      Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                    }
                  }
                ).then(() => {
                  Modal.close();
                  uploadedPaths.push(item.url);
                });
              }));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              errorMessage = t('global.errorUploadingFile');
            } finally {
              if (errorMessage === '') {
                Modal.close();
              } else {
                Modal.fireError(errorMessage, setOpenModal, soundEffects);
              }
            }

            if (uploadedPaths.length > 0) {
              updateResource(
                `/admin/tasks/${task.id}/documents-paths/planification`,
                {
                  filePaths: uploadedPaths
                },
                () => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('tasks.savedSign'),
                    setOpenModal,
                    () => navigate('/tasks/none'),
                    soundEffects
                  );
                },
                (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            }
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      } else {
        registerUploadedFiles(
          `/admin/tasks/${task.id}/documents/planification`,
          {
            filePaths: [{
              path: `${signature.name}.png`.replace(/[%&?¿=#/+]/g, ''),
              type: 'image/png'
            }]
          },
          async (data) => {
            const uploadedPaths: string[] = [];
            let errorMessage: string = '';

            Modal.fireLoading(undefined, 0);
            try {
              // eslint-disable-next-line array-callback-return, consistent-return
              await Promise.all(data.map((item) => {
                const formData = new FormData();

                Object.entries(item.signedURL.fields).forEach(([key, value]) => {
                  formData.append(key, value as string);
                });

                formData.append('file', rectifiedPetitionFile.data);

                return axios.post(
                  item.signedURL.url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                      const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                      Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                    }
                  }
                ).then(() => {
                  Modal.close();
                  uploadedPaths.push(item.url);
                });
              }));
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log(error);
              errorMessage = t('global.errorUploadingFile');
            } finally {
              if (errorMessage === '') {
                Modal.close();
              } else {
                Modal.fireError(errorMessage, setOpenModal, soundEffects);
              }
            }

            if (uploadedPaths.length > 0) {
              updateResource(
                `/admin/tasks/${task.id}/documents-paths/planification`,
                {
                  filePaths: uploadedPaths
                },
                () => {
                  Modal.fireSuccess(
                    t('global.correct'),
                    t('tasks.savedSign'),
                    setOpenModal,
                    () => navigate('/tasks/none'),
                    soundEffects
                  );
                },
                (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
              );
            }
          },
          (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
        );
      }
    }
  };

  useEffect(() => {
    if (params.taskID !== 'none') {
      getTask(
        `/admin/tasks/${params.taskID}`,
        (data) => {
          setTask(data);
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [params.taskID]);

  useEffect(() => {
    handleShowAssignmentAndInspectionPlanningDocument();
  }, [task]);

  useKeyPress(
    openModal ? () => { } : () => uploadSigns(),
    [
      openModal,
      task,
      signature
    ]
  );

  return (
    <div className='witnesses' >
      <ModalView
        visible={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleSendTaskDocuments}
        customComponent={
          <div className='dictum-service-detail__assing-out-of-customs-date'>
            <Input
              title={t('global.emails') || ''}
              type="text"
              value={emails}
              onChange={(_value: string | number, _id: string) => {
                setEmails(`${_value}`.toLocaleLowerCase());
              }}
              hasError={!!errors.emails}
              helperText={errors.emails || ''}
            />
          </div>
        }
        fullSzie={false}
      />
      <Header
        title={t('tasks.onSiteVisit') || ''}
        subTitle={[
          {
            label: task.number,
            title: t('tasks.titleSingle') || ''
          }
        ]}
        showBackbutton={true}
      />
      <div className='witnesses__content'>
        <p className='witnesses__content__title'>
          {t('tasks.planificationSign1')}
        </p>
        <p className='witnesses__content__sub-title'>
          {t('tasks.planificationSign2')}
        </p>
        <div className='witnesses__content__loading-page'>
          {
            pdfBlob.length > 0 ? (
              <>
                {
                  pdfBlob.map((item, index) => (
                    <PDFViewer
                      key={index}
                      pdfUrl={`${URL.createObjectURL(item.data)}#toolbar=0`}
                      data={item.data}
                      documentName={`PLANIFICACION - ${item.petitionNumber} - ${task.client.shortName} - ${task.number}.pdf`}
                    />
                  ))
                }
              </>
            ) : (
              <>
                <Spinner />
                <p>{t('global.loadingFiles')}</p>
              </>
            )
          }
          <Button
            type={'secondary-outlined'}
            onClick={() => setShowModal(true)}
            label={t('global.sendFiles') || ''}
            icon='sendEmail'
          />
        </div>
        {
          signature.signature === '' && rectifiedPetitionFile.fileName === '' && (
            <>
              <p className='witnesses__content__sign-title'>{t('tasks.planificationSign')}</p>
              <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{ className: 'witnesses__content__canvas-draw' }}
              />
              <div className='witnesses__content__action-buttons'>
                <Button type='secondary-outlined' onClick={() => sigCanvas.current.clear()} label={t('services.clean') || ''} />
                <Button type='secondary-outlined' onClick={saveSignature} label={t('global.save') || ''} />
              </div>
            </>
          )
        }
        {/* {
          signature.signature === '' && rectifiedPetitionFile.fileName === '' && (
            <>
              <SubTitle subTitle={t('global.orUploadSign')} />
              <FileInput
                onSelectFile={(file: FileInputData[]) => setRectifiedPetitionFile(file[0])}
                acceptedFileFormats=".png"
                isMultiple={false}
              />
            </>
          )
        } */}
        <div className='witnesses__content__sign-list'>
          {
            signature.signature && (
              <div className='witnesses__content__sign-list__item'>
                <FileElement
                  name={`${t('tasks.sign')}.png`}
                  onDelete={() => {
                    signature.signature = '';
                    setSignature({ ...signature });
                  }}
                />
                <Input
                  title={`${t('tasks.clientName')}`}
                  placeholder={`${t('global.example')}: María Morales Rizo`}
                  type='text'
                  value={signature.name}
                  onChange={(value: string | number) => {
                    signature.name = String(value);
                    setErrors({});
                    setSignature({ ...signature });
                  }}
                  hasError={!!errors.name}
                  helperText={errors.name || t('tasks.errors.clientName') || ''}
                  autofocus
                />
              </div>
            )
          }
          {
            rectifiedPetitionFile.fileName !== '' && (
              <div className='witnesses__content__sign-list__item'>
                <FileElement
                  name={rectifiedPetitionFile.fileName}

                  disabled={false}
                  onDelete={() => {
                    setRectifiedPetitionFile({
                      fileName: '',
                      data: {}
                    });
                  }}
                />
                <Input
                  title={`${t('tasks.clientName')}`}
                  placeholder={`${t('global.example')}: María Morales Rizo`}
                  type='text'
                  value={signature.name}
                  onChange={(value: string | number) => {
                    signature.name = String(value);
                    setErrors({});
                    setSignature({ ...signature });
                  }}
                  hasError={!!errors.name}
                  helperText={errors.name || t('tasks.errors.clientName') || ''}
                  autofocus
                />
              </div>
            )
          }
        </div>
        <div className='witnesses__content__footer-buttons'>
          {
            (signature.signature !== '' || rectifiedPetitionFile.fileName !== '') && (
              <Button
                type='primary'
                onClick={uploadSigns}
                label={t('global.save') || ''}
              />
            )
          }
        </div>
      </div>
    </div >
  );
};

export default PlanificationSignature;
