import React, { useCallback, useContext } from 'react';
import saveAs from 'file-saver';
import { useTranslation } from 'react-i18next';

import {
  File,
  Modal,
  Separator,
  SubTitle,
  Title
} from '../../../../components';
import { AppContext } from '../../../../context/AppContext';
import { useResource } from '../../../../hooks';
import {
  Client,
  ConstancyProduct,
  ConstancyProductStatus,
  Inspection
} from '../../../../interfaces';

interface Props {
  documents: string[]
  onChangeProduct: (_product: ConstancyProduct) => void
  productID: string
  productStatus: ConstancyProductStatus
  productInvoice: string
  inspection: Inspection
  ableDeleteDocument: boolean
  client: Client
}

const ConstancyProductDetailHistory: React.FC<Props> = (props) => {
  const {
    documents,
    onChangeProduct,
    productID,
    productStatus,
    productInvoice,
    inspection,
    ableDeleteDocument,
    client
  } = props;

  const { t } = useTranslation();

  const {
    fetchResource
  } = useResource<string>();

  const {
    fetchResource: getInspectionFormatDocument
  } = useResource<any>();

  const {
    fetchResource: getConstancyProductDocument
  } = useResource<any>();

  const {
    eraseResource
  } = useResource<ConstancyProduct>();

  const {
    apiType,
    soundEffects
  } = useContext(AppContext);

  const handleDownloadFile = (filePath: string, documentName: string) => {
    fetchResource(
      `${apiType}/documents/signature?s_filePath=${filePath}`,
      async (data) => {
        Modal.fireLoading();
        try {
          const response = await fetch(data);

          if (!response.ok) {
            throw new Error(`${t('global.alertMessage')}: ${response.status}`);
          }

          const blob = await response.blob();
          saveAs(blob, documentName);

          Modal.close();
        } catch (error) {
          Modal.fireError(`${error}`, undefined, soundEffects);
        }
      },
      (error: string) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const getDocumentName = (document: string, round: '1' | '2' | '3' | '4') => {
    const nameSplit = document.split('/');

    return nameSplit[nameSplit.length - 1].replace(`round-${round}`, '');
  };

  const handleDeleteFile = (documentPath: string) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.delteFileAsk'),
      () => {
        eraseResource(
          `/${apiType}/constancy-products/${productID}/documents`,
          {
            s_documentPath: documentPath
          },
          (data: ConstancyProduct) => {
            onChangeProduct(data);
          },
          (error: string) => Modal.fireError(error, undefined, soundEffects)
        );
      }
    );
  };

  const handleGenerateConstancyProductDocument = async () => {
    getConstancyProductDocument(
      `/${apiType}/constancy-products/${productID}/constancy-of-inspection`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `${productInvoice}-Constancia.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const setDisableConstancy = useCallback((): boolean => {
    if (inspection.status !== 'concluded') {
      return true;
    }
    if (productStatus !== 'concluded') {
      return true;
    }
    if (client.signedContract !== true) {
      return true;
    }

    return false;
  }, [
    inspection,
    productStatus,
    client
  ]);

  const handleDownloadInspectionFormat = (roundID: number) => {
    getInspectionFormatDocument(
      `/${apiType}/inspections/${inspection.id}/${roundID}/inspection-format`,
      (data) => {
        Modal.fireLoading();

        const bytes = new Uint8Array(data.file.data);

        const blob = new Blob([bytes], { type: 'application/pdf' });

        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        // eslint-disable-next-line no-undef
        link.href = window.URL.createObjectURL(blob);
        link.download = `Formato de Inspección-${inspection.product.invoice}-ronda-${roundID}.pdf`;
        link.click();

        Modal.close();
      },
      (error) => Modal.fireError(error as string, undefined, soundEffects)
    );
  };

  return (
    <div className="constancy-product-detail__documents">
      <Title title={t('services.documents')} type="primary" />
      <br />
      <div className="constancy-product-detail__documents__container">
        <File
          name={`${productInvoice}-Constancia.pdf`}
          onDownload={handleGenerateConstancyProductDocument}
          disabled={setDisableConstancy()}
          orientation='horizontal'
          showToolTip={false}
        />
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-1')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 1`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-1')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '1')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '1'))}
                    onDelete={ableDeleteDocument
                      && inspection.rounds.length === 1
                      ? () => handleDeleteFile(document)
                      : undefined}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                ))
              }
              {
                inspection.rounds[0].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[0].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[0].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-1.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[0].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(1)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
              {
                inspection.rounds[0].files.filter((file) => file.includes('__design')).map((item, index) => (
                  <>
                    <span className='constancy-product-detail__documents__container__title'>{t('global.design')}</span>
                    <File
                      name={item.split('/').at(-1) || item}
                      onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                      key={index}
                      orientation='horizontal'
                      showToolTip={false}
                    />
                  </>
                ))
              }
            </>
          )
        }
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-2')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 2`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-2')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '2')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '2'))}
                    onDelete={ableDeleteDocument
                      && inspection.rounds.length === 2
                      ? () => handleDeleteFile(document)
                      : undefined}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                ))
              }
              {
                inspection.rounds[1].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[1].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[1].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-2.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[1].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(2)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
              {
                inspection.rounds[1].files.filter((file) => file.includes('__design')).map((item, index) => (
                  <>
                    <span className='constancy-product-detail__documents__container__title'>{t('global.design')}</span>
                    <File
                      name={item.split('/').at(-1) || item}
                      onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                      key={index}
                      orientation='horizontal'
                      showToolTip={false}
                    />
                  </>
                ))
              }
            </>
          )
        }
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-3')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 3`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-3')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '3')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '3'))}
                    onDelete={ableDeleteDocument
                      && inspection.rounds.length === 3
                      ? () => handleDeleteFile(document)
                      : undefined}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                ))
              }
              {
                inspection.rounds[2].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[2].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[2].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-3.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[2].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(3)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
              {
                inspection.rounds[2].files.filter((file) => file.includes('__design')).map((item, index) => (
                  <>
                    <span className='constancy-product-detail__documents__container__title'>{t('global.design')}</span>
                    <File
                      name={item.split('/').at(-1) || item}
                      onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                      key={index}
                      orientation='horizontal'
                      showToolTip={false}
                    />
                  </>
                ))
              }
            </>
          )
        }
        {
          documents.some(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-4')) && (
            <>
              <Separator orientation='horizontal' />
              <SubTitle subTitle={`${t('services.roundTitle')} 4`} />
              {
                documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith('round-4')).map((document: string, index: number) => (
                  <File
                    name={getDocumentName(document, '4')}
                    onDownload={() => handleDownloadFile(document, getDocumentName(document, '4'))}
                    onDelete={ableDeleteDocument
                      && inspection.rounds.length === 3
                      ? () => handleDeleteFile(document)
                      : undefined}
                    key={index}
                    orientation='horizontal'
                    showToolTip={false}
                  />
                ))
              }
              {
                inspection.rounds[3].files.some(elemento => elemento.includes('__round-feedback.pdf')) ? (
                  <>
                    {
                      inspection.rounds[3].files.filter((file) => file.includes('__round-feedback')).map((item, index) => (
                        <>
                          <span className='constancy-product-detail__documents__container__title'> {t('services.inspectionFormat')}</span>
                          <File
                            name={item.split('/').at(-1) || item}
                            onDownload={() => handleDownloadFile(item, item.split('/').at(-1) || item)}
                            key={index}
                            orientation='horizontal'
                            showToolTip={false}
                          />
                        </>
                      ))
                    }
                  </>
                ) : (
                  <>
                    {
                      inspection.rounds[3].steps.find(element => element === 'list-completed') !== undefined && (
                        <>
                          <File
                            name={`${t('services.inspectionFormat')}-${inspection.product.invoice}-ronda-4.pdf`}
                            disabled={false}
                            onDownload={inspection.rounds[3].steps.find(element => element === 'list-completed') !== undefined
                              ? () => handleDownloadInspectionFormat(4)
                              : undefined}
                            orientation='horizontal'
                          />
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
        {
          documents.length === 0 && productStatus === 'in-inspection' && (
            <p className="constancy-product-detail__documents__container__empty-message">{t('services.notUploadedFiles')}</p>
          )
        }
      </div>
    </div>
  );
};

export default ConstancyProductDetailHistory;
