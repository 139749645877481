/* eslint-disable camelcase */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import errorsDictionaryEn from '../dictionaries/errorCodes-en';
import errorsDictionaryEs from '../dictionaries/errorCodes-es';
import api from '../api';
import { Pagination, Query } from '../interfaces';
import { Modal } from '../components';
import configuration from '../configuration';
import { AppContext } from '../context/AppContext';

const ERROR_EXCEPTION = ['WERE_NOT_FOUND', ''];
const ERROR_EXCEPTION_GET_RESOURCES = ['RESOURCE_WAS_NOT_UPDATED', ''];
let alertMessageModal = '';

interface ErrorData {
  code: string
  invoices: string
}

const getErrorCode = (error: any): ErrorData => {
  const { response = {} } = error;
  const { data = {} } = response;
  const { code = '' } = data;

  return {
    code: code.split(':')[0],
    invoices: code.split(':')[1]
  };
};

export default function useResource<T>() {
  interface FetchedResources {
    items: T[]
    pagination: Pagination
  }

  const {
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const { t, i18n } = useTranslation();

  // eslint-disable-next-line max-len
  alertMessageModal = t('global.alertMessageModal', { supportPhoneNumber: configuration.supportPhoneNumber });

  const createResource = async (
    resourcePath: string,
    resource: any,
    onSuccess: (_requestData: T) => void,
    onError: (_error: string) => void,
    extraData?: any,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._post(
        resourcePath,
        resource,
        extraData
      );
      if (showModal) {
        Modal.close();
      }

      onSuccess(data);
    } catch (error: any) {
      if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        if ((error as any).response.status === 413) {
          onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[413] : (errorsDictionaryEn as any)[413]);
        } else if (getErrorCode(error).code === 'INVOICES_ABOUT_TO_EXPIRE') {
          onError(i18n.language === 'es' ? `Uno o varios folios expirarán antes de la fecha de asignación de la tarea: ${getErrorCode(error).invoices}` : `One or more folios will expire before the task assignment date: ${getErrorCode(error).invoices}`);
        } else {
          onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
        }
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const createResources = async (
    resourcePath: string,
    resources: any[],
    onSuccess: (_requestData: T[]) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._post(
        resourcePath,
        resources
      );
      if (showModal) {
        Modal.close();
      }

      onSuccess(data);
    } catch (error) {
      if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        if ((error as any).response.status === 413) {
          onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[413] : (errorsDictionaryEn as any)[413]);
        } else if (getErrorCode(error).code === 'INVOICES_ABOUT_TO_EXPIRE') {
          onError(i18n.language === 'es' ? `Uno o varios folios expirarán antes de la fecha de asignación de la tarea: ${getErrorCode(error).invoices}` : `One or more folios will expire before the task assignment date: ${getErrorCode(error).invoices}`);
        } else {
          onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
        }
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const fetchResource = async (
    resourcePath: string,
    onSuccess: (_resource: T) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._get(resourcePath, {});
      if (showModal) {
        Modal.close();
      }

      onSuccess(data);
    } catch (error) {
      if (!ERROR_EXCEPTION_GET_RESOURCES.includes(getErrorCode(error).code)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const fetchResources = async (
    query: Query,
    onSuccess: (_requestData: FetchedResources) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._get(
        query.resourcePath,
        {
          ...query.filters,
          ...query.ordering,
          ...query.pagination
        }
      );
      if (showModal) {
        Modal.close();
      }

      onSuccess(data);
    } catch (error) {
      if (!ERROR_EXCEPTION_GET_RESOURCES.includes(getErrorCode(error).code)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const updateResource = async (
    resourcePath: string,
    resource: any,
    onSuccess: (_requestData: T) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._put(resourcePath, resource);

      if (showModal) {
        Modal.close();
      }
      onSuccess(data);
    } catch (error) {
      if (getErrorCode(error).code === 'INVOICES_ABOUT_TO_EXPIRE') {
        onError(i18n.language === 'es' ? `Uno o varios folios expirarán antes de la fecha de asignación de la tarea: ${getErrorCode(error).invoices}` : `One or more folios will expire before the task assignment date: ${getErrorCode(error).invoices}`);
      } else if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const updateResources = async (
    resourcePath: string,
    resources: any[],
    onSuccess: (_requestData: T[]) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._put(resourcePath, resources);

      if (showModal) {
        Modal.close();
      }
      onSuccess(data);
    } catch (error) {
      if (getErrorCode(error).code === 'INVOICES_ABOUT_TO_EXPIRE') {
        onError(i18n.language === 'es' ? `Uno o varios folios expirarán antes de la fecha de asignación de la tarea: ${getErrorCode(error).invoices}` : `One or more folios will expire before the task assignment date: ${getErrorCode(error).invoices}`);
      } else if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const eraseResource = async (
    resourcePath: string,
    resource: any,
    onSuccess: (_requestData: T) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._delete(resourcePath, resource);

      if (showModal) {
        Modal.close();
      }
      onSuccess(data);
    } catch (error) {
      if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  const eraseResources = async (
    resourcePath: string,
    resources: any,
    onSuccess: (_requestData: T[]) => void,
    onError: (_error: string) => void,
    showModal = true
  ) => {
    try {
      if (showModal) {
        Modal.fireLoading();
      }
      const { data } = await api._delete(resourcePath, resources);

      if (showModal) {
        Modal.close();
      }
      onSuccess(data);
    } catch (error) {
      if (!ERROR_EXCEPTION.includes(`${getErrorCode(error).code}`)) {
        onError(i18n.language === 'es' ? (errorsDictionaryEs as any)[getErrorCode(error).code] : (errorsDictionaryEn as any)[getErrorCode(error).code]);
      } else {
        Modal.fireError(alertMessageModal, setOpenModal, soundEffects);
      }
    }
  };

  return {
    createResource,
    createResources,
    fetchResource,
    fetchResources,
    updateResource,
    updateResources,
    eraseResource,
    eraseResources
  };
}
