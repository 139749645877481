import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

import {
  Button,
  Header,
  Input,
  Modal,
  Select,
  Title
} from '../../../components';

import './styles.scss';
import { useNavigate, useResource } from '../../../hooks';
import { AppContext } from '../../../context/AppContext';
import {
  Chart as ChartType
} from '../../../interfaces';

const UsersList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  dayjs.extend(weekOfYear);

  const {
    fetchResource
  } = useResource<ChartType[]>();

  const {
    setOpenModal,
    soundEffects,
    userRole,
    approver
  } = useContext(AppContext);

  const [charts, setCharts] = useState<ChartType[]>([]);
  const [year, setYear] = useState<string>(dayjs().format('YYYY'));
  const [month, setMonth] = useState<string>(dayjs().format('M'));

  const getCharts = () => {
    fetchResource(
      `admin/notifications/charts/${month}/${year}`,
      (data) => {
        setCharts(data);
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  useEffect(() => {
    getCharts();
  }, []);

  useEffect(() => {
    if (userRole === 'inspector' && approver === false) {
      navigate(-1);
    }
  }, []);

  return (
    <div className="reports">
      <Header
        title={t('menu.charts') || ''}
        showBackbutton={true}
      />
      <div className="reports__main">
        <div className="reports__main__container">
          {
            charts.map((chart: ChartType, index: number) => (
              <div className="reports__main__container__item" key={index}>
                <Title title={chart.title} />
                <Chart
                  options={chart.options}
                  series={chart.series}
                  type={chart.type}
                  height={chart.options.chart.height}
                  width={chart.options.chart.width}
                />
              </div>
            ))
          }
          {
            ['finance', 'master', 'sys-admin'].includes(userRole) && (
              <div className="reports__main__container__inputs-container">
                <Title title='Filtrar servicios facturados' />
                <div className="reports__main__container__inputs">
                  <Input
                    title={t('global.year') || ''}
                    type={'year'}
                    value={year}
                    onChange={(_value: string | number, _id: string) => {
                      setYear(`${_value}`);
                    }}
                  />
                  <Select
                    title={t('global.month') || ''}
                    value={month}
                    options={[
                      {
                        text: t('global.months.january'),
                        value: '1'
                      },
                      {
                        text: t('global.months.febrary'),
                        value: '2'
                      },
                      {
                        text: t('global.months.march'),
                        value: '3'
                      },
                      {
                        text: t('global.months.april'),
                        value: '4'
                      },
                      {
                        text: t('global.months.may'),
                        value: '5'
                      },
                      {
                        text: t('global.months.june'),
                        value: '6'
                      },
                      {
                        text: t('global.months.july'),
                        value: '7'
                      },
                      {
                        text: t('global.months.august'),
                        value: '8'
                      },
                      {
                        text: t('global.months.september'),
                        value: '9'
                      },
                      {
                        text: t('global.months.october'),
                        value: '10'
                      },
                      {
                        text: t('global.months.november'),
                        value: '11'
                      },
                      {
                        text: t('global.months.december'),
                        value: '12'
                      }
                    ]}
                    onChange={(_value: string, _id: string) => {
                      setMonth(`${_value}`);
                    }}
                  />
                  <Button
                    type={'secondary'}
                    onClick={getCharts}
                    label={t('global.search') || ''}
                    fullWidth={false}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default UsersList;
