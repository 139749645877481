import { useTranslation } from 'react-i18next';

import React, { useEffect, useState } from 'react';

import './styles.scss';
import { CheckBox, Separator, Switch } from '../../../../components';

interface NormCertified {
  norm: string
  certified: boolean
}

export interface ItemCheckBox {
  id: string
  label: string
  checked: boolean
  certified: boolean
}

interface Props {
  items: ItemCheckBox[]
  orientation?: 'vertical' | 'horizontal'
  onCheck: (_values: NormCertified[]) => void
}

const CheckList: React.FC<Props> = (props) => {
  const {
    items = [],
    orientation = 'vertical',
    onCheck = () => { }
  } = props;

  const { t } = useTranslation();

  const [checkedIDs, setCheckedIDs] = useState<NormCertified[]>([]);

  const onCertifiedItem = (id: string, certified: boolean) => {
    let indexItem: number = -1;

    checkedIDs.forEach((element, index) => {
      if (element.norm === id) {
        indexItem = index;
      }
    });

    if (indexItem === -1) {
      checkedIDs.push({
        certified,
        norm: id
      });
    } else {
      checkedIDs[indexItem] = {
        norm: id,
        certified
      };
    }

    setCheckedIDs([...checkedIDs]);
    onCheck([...checkedIDs]);
  };

  const onCheckItem = (id: string) => {
    let indexItem: number = -1;

    checkedIDs.forEach((element, index) => {
      if (element.norm === id) {
        indexItem = index;
      }
    });

    if (indexItem === -1) {
      checkedIDs.push({
        certified: false,
        norm: id
      });
    } else {
      checkedIDs.splice(indexItem, 1);
    }

    setCheckedIDs([...checkedIDs]);
    onCheck([...checkedIDs]);
  };

  useEffect(() => {
    const auxCheckedIDs: NormCertified[] = [];

    for (let index = 0; index < items.length; index += 1) {
      if (items[index].checked) {
        auxCheckedIDs.push({
          norm: items[index].id,
          certified: items[index].certified
        });
      }
    }

    setCheckedIDs(auxCheckedIDs);
  }, [items]);

  return (
    <div className={`check-list check-list--${orientation}`}>
      {
        items.map((item: ItemCheckBox, index: number) => (
          <div key={index}>
            <CheckBox
              label={item.label}
              checked={checkedIDs.some(checkedItem => checkedItem.norm === item.id)}
              onChange={() => onCheckItem(item.id)}
              orientation={orientation}
            />
            {
              checkedIDs.some(checkedItem => checkedItem.norm === item.id) && (
                <div>
                  <Switch
                    defaultChecked={!item.certified}
                    leftLabel={{
                      text: t('global.training'),
                      value: 1
                    }}
                    rigthLabel={{
                      text: t('global.certified'),
                      value: 0
                    }}
                    onChange={(_value: string | number) => {
                      onCertifiedItem(item.id, _value === '0');
                    }}
                  />
                </div>
              )
            }
            <Separator orientation='horizontal' />
          </div>
        ))
      }
    </div>
  );
};

export default CheckList;
