import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Header, Modal, Title } from '../../../../components';
import { ListForm } from '../../../../components/InspectionDetail/components';

import './styles.scss';
import { AppContext } from '../../../../context/AppContext';
import { Client, ConstancyProductIndicators, User } from '../../../../interfaces';
import { emptyUser } from '../../../../emptyObjects';
import { useResource } from '../../../../hooks';

interface Props {
  setInspectionID: (_value: string) => void
  setShowProductInformation: (_value: boolean) => void
  onFetchInspection: () => void
  labelsToPut: number
  isGrouped: boolean
  productIndicators?: ConstancyProductIndicators
  client: Client
  NOM003Complement: boolean
}

const InspectionList: React.FC<Props> = (props) => {
  const {
    setInspectionID,
    setShowProductInformation,
    onFetchInspection,
    labelsToPut,
    isGrouped,
    productIndicators,
    client,
    NOM003Complement
  } = props;

  const {
    inspection
  } = useContext(AppContext);

  const {
    apiType,
    userRole,
    setOpenModal,
    soundEffects
  } = useContext(AppContext);

  const {
    fetchResource: getUser
  } = useResource<User>();

  const { t } = useTranslation();

  const params = useParams();

  const { inspectionID, roundID } = params;

  const [user, setUser] = useState<User>(emptyUser);

  const getFirstRoundWithoutQuestions = (): number => {
    let auxRoundID: number = 1;

    for (let index = 0; index < inspection.rounds.length; index += 1) {
      if (inspection.rounds[index].list?.questions.some((question) => question.answer === 0)) {
        auxRoundID = inspection.rounds[index].id;
      }
    }

    return auxRoundID;
  };

  useEffect(() => {
    setShowProductInformation(true);
    setInspectionID(inspectionID || '');
  }, [inspectionID]);

  useEffect(() => {
    // setShowProductInformation(false);
    if (apiType === 'admin' && userRole !== 'collaborator') {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUser(data);
        },
        (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
      );
    }
  }, [apiType, userRole]);

  return (
    <div className="inspection-inspection-list">
      <Header
        title={t('services.makeList', { extraLabel: '' })}
        subTitle={[
          {
            label: `${roundID && Number(roundID) > 0 ? Number(roundID) : getFirstRoundWithoutQuestions()}`,
            title: t('services.roundTitle') || ''
          }
        ]}
        showBackbutton={true}
      />
      <Title title={t('services.listExplication1')} type='secondary' />
      <p className='inspection-inspection-list__text'>
        {t('services.listExplication2')}
      </p>
      <ListForm
        disabled={false}
        onFetchInspection={onFetchInspection}
        labelsToPut={labelsToPut}
        isGrouped={isGrouped}
        roundID={roundID && Number(roundID) > 0 ? Number(roundID) : getFirstRoundWithoutQuestions()}
        productIndicators={productIndicators}
        user={user}
        client={client}
        NOM003Complement={NOM003Complement}
      />
    </div>
  );
};

export default InspectionList;
