import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import {
  FileInput,
  ModalView,
  Modal,
  File
} from '../../../../components';

import {
  RevisionProduct,
  FileInputData,
  FileToSign,
  SignedFile
} from '../../../../interfaces';

import { useResource } from '../../../../hooks';

import '../../styles.scss';
import { AppContext } from '../../../../context/AppContext';
import { utils } from '../../../../helpers';

interface Props {
  visible: boolean
  onClose: () => void
  onChangeProduct: (_product: RevisionProduct) => void
  productID: string
  roundID: number
  require?: boolean
  productFiles: string[]
}

export const UploadFiles: React.FC<Props> = (props) => {
  const {
    visible,
    onClose,
    onChangeProduct,
    productID,
    roundID,
    require = false,
    productFiles
  } = props;

  const maxFilesQuantity = 10;

  const { t } = useTranslation();

  const {
    eraseResource,
    updateResource
  } = useResource<RevisionProduct>();

  const {
    createResource: registerUploadedFiles
  } = useResource<SignedFile[]>();

  const {
    apiType,
    soundEffects,
    setOpenModal
  } = useContext(AppContext);

  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [files, setFiles] = useState<string[]>([]);

  const handleDeleteFile = (documentPath: string) => {
    Modal.fire(
      'warning',
      t('global.alert'),
      t('services.delteFileAsk'),
      () => {
        eraseResource(
          `/${apiType}/revision-products/${productID}/documents`,
          {
            s_documentPath: documentPath
          },
          (data) => {
            onChangeProduct(data);
            setFiles(data.documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith(`round-${roundID}`)));
          },
          (error: string) => Modal.fireError(error, undefined, soundEffects)
        );
      }
    );
  };

  const handleUploadFile = (_files: FileInputData[]) => {
    if (_files.length > maxFilesQuantity) {
      Modal.fireError(t('services.uploadproductFilesError', { filesQuantity: maxFilesQuantity }), undefined, soundEffects);
      return;
    }

    const paths: FileToSign[] = [];

    _files.forEach(fileItem => {
      paths.push({
        path: fileItem.fileName,
        type: fileItem.data.type,
        documentName: utils.deleteDotsOnFileName(fileItem.fileName)
      });
    });

    registerUploadedFiles(
      `/${apiType}/revision-products/${productID}/documents`,
      {
        filePaths: paths
      },
      async (data) => {
        const uploadedPaths: string[] = [];
        let errorMessage: string = '';

        Modal.fireLoading(undefined, 0);
        try {
          // eslint-disable-next-line array-callback-return, consistent-return
          await Promise.all(data.map((item) => {
            // eslint-disable-next-line max-len
            const file = _files.find((fileItem) => item.url.endsWith(utils.deleteDotsOnFileName(fileItem.fileName)));

            const formData = new FormData();

            Object.entries(item.signedURL.fields).forEach(([key, value]) => {
              formData.append(key, value as string);
            });

            if (file) {
              formData.append('file', file.data);

              return axios.post(
                item.signedURL.url,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  },
                  onUploadProgress: (progressEvent) => {
                    const porcentaje = (progressEvent.loaded / progressEvent.total) * 100;

                    Modal.fireLoading(undefined, Number(porcentaje.toFixed(0)));
                  }
                }
              ).then(() => {
                Modal.close();
                uploadedPaths.push(item.url);
              });
            }
          }));
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          errorMessage = t('global.errorUploadingFile');
        } finally {
          if (errorMessage === '') {
            Modal.close();
          } else {
            Modal.fireError(errorMessage, setOpenModal, soundEffects);
          }
        }

        if (uploadedPaths.length > 0) {
          updateResource(
            `/${apiType}/revision-products/${productID}/update-documents-paths`,
            {
              filePaths: uploadedPaths
            },
            (product) => {
              setFiles(product.documents.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith(`round-${roundID}`)));
              setShowAlertMessage(false);
              onChangeProduct(product);
            },
            (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
          );
        }
      },
      (error: string) => Modal.fireError(error, setOpenModal, soundEffects)
    );
  };

  const getDocumentName = (document: string, round: number) => {
    const nameSplit = document.split('/');

    return nameSplit[nameSplit.length - 1].replace(`round-${round}`, '');
  };

  useEffect(() => {
    if (productFiles.length > 0) {
      setFiles([...productFiles.filter(elemento => elemento.split('/')[elemento.split('/').length - 1].startsWith(`round-${roundID}`))]);
    } else {
      setFiles([]);
    }
  }, [visible, productFiles]);

  const markChangesDocuments = () => {
    updateResource(
      `/${apiType}/revision-products/${productID}/mark-changed-files`,
      {},
      (data: RevisionProduct) => {
        Modal.fireSuccess(
          t('global.correct'),
          t('services.uploadedFiles'),
          setOpenModal,
          () => {
            onChangeProduct(data);
            onClose();
          },
          soundEffects
        );
      },
      (error: string) => {
        Modal.fireError(error, setOpenModal, soundEffects);
      }
    );
  };

  return (
    <ModalView
      visible={visible}
      onClose={require && files.length === 0 ? () => setShowAlertMessage(true) : onClose}
      onConfirm={files.length > 0
        ? () => markChangesDocuments()
        : () => setShowAlertMessage(true)
      }
      customComponent={
        <div className='upload-file-modal'>
          {
            showAlertMessage ? (
              <p className='upload-file-modal__title-normal upload-file-modal__title-normal--error'>{t('services.uploadproductFilesAlert')}</p>
            ) : (
              <p className='upload-file-modal__title-normal'>{t('services.uploadproductFiles', { filesQuantity: maxFilesQuantity })}</p>
            )
          }
          {
            files.length < maxFilesQuantity && (
              <FileInput
                isMultiple={true}
                hasError={showAlertMessage}
                acceptedFileFormats='.jpg,.png,.JPG,.PNG,.pdf,.PDF,.jpeg,.JPEG,.svg,.SVG'
                onSelectFile={(payload: FileInputData[]) => {
                  handleUploadFile(payload);
                }}
              />
            )
          }
          <div className='upload-file-modal__files'>
            {
              files.map((file: string, index: number) => (
                <File
                  name={getDocumentName(file, roundID)}
                  key={index}

                  disabled={false}
                  // orientation='horizontal'
                  showToolTip={false}
                  onDelete={() => handleDeleteFile(file)}
                />
              ))
            }
          </div>
        </div>
      }
    />
  );
};

export default UploadFiles;
