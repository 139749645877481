import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../components';
import DataDisplay from '../../../../components/DataDisplay';
import { AppContext } from '../../../../context/AppContext';
import {
  Collaborator,
  User,
  UserNorms,
  ValueFormat
} from '../../../../interfaces';

import '../../styles.scss';

interface Item {
  label: string
  value: string | number | boolean | Date | string[] | UserNorms[]
  format: ValueFormat
}

interface Props {
  collaborator: Collaborator
  user: User,
  showPicture: boolean
}

const DictumServiceDetailGeneralInfo: React.FC<Props> = (props) => {
  const {
    collaborator,
    user,
    showPicture
  } = props;

  const {
    apiType
  } = useContext(AppContext);

  const [items, setItems] = useState<Item[]>([]);
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const { t, i18n } = useTranslation();

  const translationsTaskDetail = (key: string): string => {
    const translations: any = {
      es: {
        name: 'Nombre',
        role: 'Rol',
        createdAt: 'Creado el',
        approver: 'Aprobador',
        norms: 'Normas',
        phoneNumber: 'Número de teléfono',
        company: 'Compañía'
      },
      en: {
        name: 'Name',
        role: 'Role',
        createdAt: 'Created at',
        approver: 'Approver',
        norms: 'Norms',
        phoneNumber: 'Phone number',
        company: 'Company'
      }
    };

    return translations[i18n.language][key];
  };

  useEffect(() => {
    if (apiType === 'admin') {
      if (user.role === 'inspector') {
        setItems([
          {
            label: translationsTaskDetail('name'),
            value: user.name,
            format: 'none'
          },
          {
            label: t('global.email'),
            value: user.email,
            format: 'none'
          },
          {
            label: translationsTaskDetail('role'),
            value: user.role,
            format: 'role'
          },
          {
            label: translationsTaskDetail('approver'),
            value: user.approver,
            format: 'boolean'
          },
          {
            label: translationsTaskDetail('norms'),
            value: user.norms,
            format: 'list-user-norms'
          },
          {
            label: translationsTaskDetail('createdAt'),
            value: user.createdAt,
            format: 'date-time'
          }
        ]);
      } else {
        setItems([
          {
            label: translationsTaskDetail('name'),
            value: user.name,
            format: 'none'
          },
          {
            label: t('global.email'),
            value: user.email,
            format: 'none'
          },
          {
            label: translationsTaskDetail('role'),
            value: user.role,
            format: 'role'
          },
          {
            label: translationsTaskDetail('createdAt'),
            value: user.createdAt,
            format: 'date-time'
          }
        ]);
      }
    } else if (apiType === 'public') {
      setItems([
        {
          label: translationsTaskDetail('name'),
          value: collaborator.name,
          format: 'none'
        },
        {
          label: t('global.email'),
          value: collaborator.email,
          format: 'none'
        },
        {
          label: translationsTaskDetail('phoneNumber'),
          value: collaborator.phone,
          format: 'none'
        },
        {
          label: translationsTaskDetail('company'),
          value: collaborator.client.businessName,
          format: 'none'
        },
        {
          label: translationsTaskDetail('createdAt'),
          value: collaborator.createdAt,
          format: 'date-time'
        }
      ]);
    }
  }, [apiType, user, collaborator, i18n.language]);

  return (
    <>
      {
        apiType === 'admin' && showPicture && user.picture && (
          <div className='profile__picture-container'>
            {
              user.picture.length > 0 && imageLoaded ? (
                <img
                  src={user.picture}
                  alt="User picture"
                  className='profile__picture-container__picture'
                  onError={handleImageError}
                />
              ) : (
                <div className='profile__picture-container__icon-container'>
                  <Icon type='userGrey' className='profile__picture-container__icon-container__icon' alt="User picture" />
                </div>
              )
            }
          </div>
        )
      }
      <DataDisplay
        dataTitle={t('services.generalInformation') || ''}
        items={items}
      />
    </>
  );
};

export default DictumServiceDetailGeneralInfo;
