import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../context/AppContext';

import '../../styles.scss';

interface Props {
  elementsType: 'binnacle' | 'trashCan'
  setElementsType: (_value: 'binnacle' | 'trashCan') => void
  setFilters: (_value: { [name: string]: string }) => void
}

const Binnacle: React.FC<Props> = (props) => {
  const {
    elementsType,
    setElementsType,
    setFilters
  } = props;

  const { t } = useTranslation();

  const { userRole } = useContext(AppContext);

  return (
    <div className='binnacle__header binnacle__header'>
      <div
        className={`binnacle__header__option binnacle__header__option${elementsType === 'binnacle' ? '--selected' : ''}`}
        onClick={() => {
          setElementsType('binnacle');
          setFilters({});
        }}
      >
        {t('services.binnacle')}
        <div className='binnacle__header__option__select'></div>
      </div>
      {
        ['inspector', 'master', 'operator', 'sys-admin'].includes(userRole) && (
          <div
            className={`binnacle__header__option binnacle__header__option${elementsType === 'trashCan' ? '--selected' : ''}`}
            onClick={() => {
              setElementsType('trashCan');
              setFilters({});
            }}
          >
            {t('services.paperBin')}
            <div className='binnacle__header__option__select'></div>
          </div>
        )
      }
    </div>
  );
};

export default Binnacle;
