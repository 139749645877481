import React, {
  ReactElement,
  Suspense,
  useContext,
  useEffect,
  useState,
  useTransition
} from 'react';
import { useTranslation } from 'react-i18next';
import { useResource } from '../../hooks';

import {
  Accordeon,
  Header,
  Title,
  Modal,
  SubTitle,
  Icon,
  TaskCard,
  TaskDetail,
  InspectionDetail,
  Spinner,
  Switch
} from '../../components';
import {
  ConstancyProduct,
  DictumProduct,
  FilterTypes,
  Inspection,
  RevisionProduct,
  Task,
  User
} from '../../interfaces';
import {
  Calendar,
  TasksList,
  InspectionsList
} from './components';
import { ModalInspectionDetail } from './components/ModalInspectionDetail/ModalInspectionDetail';
import './styles.scss';
import {
  emptyConstancyProduct,
  emptyDictumProduct,
  emptyRevisionProduct
} from '../../emptyObjects';
import storage from '../../storage';
import { AppContext } from '../../context/AppContext';
import { utils } from '../../helpers';

const dayjs = require('dayjs');

type ListsTypes = 'tasks' | 'inspections';

interface Counter {
  scheduled: number
  'in-progress': number
}

interface CounterInspections {
  'in-progress': number
  'in-review': number
  concluded: number
}

interface AccordeonItem {
  title: string
  element: ReactElement
}

const Tasks = () => {
  const {
    fetchResources
  } = useResource<Task>();

  const {
    fetchResources: fetchInspections,
    fetchResource: getInspection
  } = useResource<Inspection>();

  const {
    fetchResource: getDictumProduct
  } = useResource<DictumProduct>();

  const {
    fetchResource: getConstancyProduct
  } = useResource<ConstancyProduct>();

  const {
    fetchResource: getRevisionProduct
  } = useResource<RevisionProduct>();

  const {
    fetchResource: getUser
  } = useResource<User>();

  const { t } = useTranslation();

  const {
    userRole,
    soundEffects,
    inspection,
    setInspection,
    visibleInspectionDetailModal,
    setVisibleInspectionDetailModal,
    visibleTaskDetailModal,
    setVisibleTaskDetailModal,
    informationReceivedFromWebsocket
  } = useContext(AppContext);

  const [statusTasksCounter, setStatusTasksCounter] = useState<Counter>({
    scheduled: 0,
    'in-progress': 0
  });
  const [statusInspectionsCounter, setStatusInspectionsCounter] = useState<CounterInspections>({
    'in-progress': 0,
    'in-review': 0,
    concluded: 0
  });
  const [isPending, startTransition] = useTransition();
  const [selectedOption, setSelectedOption] = useState<FilterTypes>(storage.get('selectedOptionOnTasksView') as FilterTypes || 'none');
  const [filterValue, setFilterValue] = useState<string>(storage.get('filterValueOnTasksView') || '');
  const [tasks, setTasks] = useState<Task[]>([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [tasksCompleteList, setTasksCompleteList] = useState<Task[]>([]);
  const [inspectionsCompleteList, setInspectionsCompleteList] = useState<Inspection[]>([]);
  const [quantityElementsOnList, setQuantityElementsOnList] = useState<number>(50);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [itemsAccordeon, setItemsAccordeon] = useState<AccordeonItem[]>([]);
  const [reFetchData, setReFetchData] = useState<boolean>(false);
  const [selectedList, setSelectedList] = useState<ListsTypes>(storage.get('selectedListOnApproverView') as ListsTypes || 'tasks');
  const [dictumProduct, setDictumProduct] = useState<DictumProduct>(emptyDictumProduct);
  const [constancyProduct, setConstancyProduct] = useState<ConstancyProduct>(emptyConstancyProduct);
  const [revisionProduct, setRevisionProduct] = useState<RevisionProduct>(emptyRevisionProduct);
  const [userId, setUserId] = useState<string>('');

  const getTodayTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['scheduled', 'in-progress'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        if (tasks[index].status !== 'concluded') {
          auxTasks.push(
            <TaskCard
              key={index}
              task={tasks[index]}
              onClick={(_task: Task) => {
                storage.set('lastTaskDetailID', _task.id);
                setVisibleTaskDetailModal({
                  visible: true,
                  taskID: _task.id
                });
              }}
            />
          );
        }
      }
    }

    return auxTasks;
  };

  const getTodayConcludedTasks = (): ReactElement[] => {
    const auxTasks: ReactElement[] = [];
    const showTaksInStatuses = ['concluded'];

    for (let index = 0; index < tasks.length; index += 1) {
      if (
        dayjs(String(tasks[index].datesRegistry.scheduledTo).substring(0, 10)).format('DD/MM/YYYY')
        === dayjs(currentDate).format('DD/MM/YYYY')
        && showTaksInStatuses.includes(tasks[index].status)
      ) {
        auxTasks.push(
          <TaskCard
            key={index}
            task={tasks[index]}
            onClick={(_task: Task) => {
              storage.set('lastTaskDetailID', _task.id);
              setVisibleTaskDetailModal({
                visible: true,
                taskID: _task.id
              });
            }}
          />
        );
      }
    }

    return auxTasks;
  };

  const getMoreItems = () => {
    startTransition(() => {
      if (selectedList === 'inspections' && inspectionsCompleteList.length > inspections.length) {
        setQuantityElementsOnList(quantityElementsOnList + 50);
      } else if (selectedList === 'tasks' && tasksCompleteList.length > tasks.length) {
        setQuantityElementsOnList(quantityElementsOnList + 50);
      }
    });
  };

  const getTasks = (userID: string) => {
    fetchResources(
      {
        resourcePath: '/admin/tasks',
        filters: {
          s_status: 'in-progress'
        }
      },
      (data) => {
        const _tasks = data.items.filter((item) => item.responsible.id !== userID);
        setTasks(_tasks.slice(0, quantityElementsOnList));
        setTasksCompleteList(_tasks);
      },
      (error) => Modal.fireError(error, undefined, soundEffects)
    );
  };

  const throttledGetTasks = utils.throttle(getTasks, 1000);

  const getInspections = (userID: string) => {
    fetchInspections(
      {
        resourcePath: '/admin/inspections',
        filters: {
          s_status: 'in-review'
        }
      },
      (data) => {
        const _inspections = data.items.filter((item) => item.inspector?.id !== userID);
        setInspections(_inspections.slice(0, quantityElementsOnList));
        setInspectionsCompleteList(_inspections);
      },
      (error) => {
        Modal.fireError(error, undefined, soundEffects);
      }
    );
  };

  const throttledGetInspections = utils.throttle(getInspections, 1000);

  useEffect(() => {
    const auxSelectedOption = storage.get('selectedOptionOnTasksView');
    const auxFilterValue = storage.get('filterValueOnTasksView');

    if (auxSelectedOption !== 'none') {
      setSelectedOption(auxSelectedOption as FilterTypes);
    }

    if (auxFilterValue) {
      setFilterValue(auxFilterValue);
    }
  }, []);

  useEffect(() => {
    startTransition(() => {
      if (selectedList === 'inspections') {
        setInspections(inspectionsCompleteList.slice(0, quantityElementsOnList));
      }
      if (selectedList === 'tasks') {
        setTasks(tasksCompleteList.slice(0, quantityElementsOnList));
      }
    });
  }, [quantityElementsOnList]);

  const throttledGetMe = utils.throttle(
    () => {
      getUser(
        '/admin/users/me/data',
        (data) => {
          setUserId(data.id);
          if (selectedList === 'inspections') {
            throttledGetInspections(data.id);
          }
          if (selectedList === 'tasks') {
            throttledGetTasks(data.id);
          }
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    },
    1000
  );

  useEffect(() => {
    throttledGetMe();
  }, [
    filterValue,
    selectedOption,
    selectedList,
    userRole,
    reFetchData
  ]);

  useEffect(() => {
    if (selectedList === 'inspections'
      && informationReceivedFromWebsocket.action === 're-fetch-in-review-inspections'
    ) {
      throttledGetInspections(userId);
    }
  }, [informationReceivedFromWebsocket]);

  useEffect(() => {
    if (selectedList === 'inspections' && visibleInspectionDetailModal.visible) {
      getInspection(
        `/admin/inspections/${visibleInspectionDetailModal.inspectionID}`,
        (data) => {
          setInspection(data);

          if (['DC', 'DN'].includes(data.service.code)) {
            getDictumProduct(
              `/admin/dictum-products/${data.product.id}`,
              (productData) => {
                setDictumProduct(productData);

                setConstancyProduct(emptyConstancyProduct);
                setRevisionProduct(emptyRevisionProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['CC', 'CN'].includes(data.service.code)) {
            getConstancyProduct(
              `/admin/constancy-products/${data.product.id}`,
              (productData) => {
                setConstancyProduct(productData);

                setDictumProduct(emptyDictumProduct);
                setRevisionProduct(emptyRevisionProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          } else if (['REV'].includes(data.service.code)) {
            getRevisionProduct(
              `/admin/revision-products/${data.product.id}`,
              (productData) => {
                setRevisionProduct(productData);

                setDictumProduct(emptyDictumProduct);
                setConstancyProduct(emptyConstancyProduct);
              },
              (error) => Modal.fireError(error, undefined, soundEffects)
            );
          }
        },
        (error: string) => Modal.fireError(error, undefined, soundEffects)
      );
    }
  }, [selectedList, visibleInspectionDetailModal]);

  useEffect(() => {
    let scheduledCounter: number = 0;
    let inProgressCounter: number = 0;

    const setCounters: { [name: string]: () => void } = {
      scheduled: () => {
        scheduledCounter += 1;
      },
      'in-progress': () => {
        inProgressCounter += 1;
      },
      concluded: () => { },
      deleted: () => { }
    };

    for (let index = 0; index < tasks.length; index += 1) {
      setCounters[tasks[index].status]();
    }

    setStatusTasksCounter({
      scheduled: scheduledCounter,
      'in-progress': inProgressCounter
    });
  }, [tasks]);

  useEffect(() => {
    let concludedCounter: number = 0;
    let inProgressCounter: number = 0;
    let inReviewCounter: number = 0;

    const setCounters: { [name: string]: () => void } = {
      'in-progress': () => {
        inProgressCounter += 1;
      },
      'in-review': () => {
        inReviewCounter += 1;
      },
      concluded: () => {
        concludedCounter += 1;
      }
    };

    for (let index = 0; index < inspections.length; index += 1) {
      setCounters[inspections[index].status]();
    }

    setStatusInspectionsCounter({
      'in-progress': inProgressCounter,
      'in-review': inReviewCounter,
      concluded: concludedCounter
    });
  }, [inspections]);

  useEffect(() => {
    const auxItemsAccordeon: AccordeonItem[] = [];

    auxItemsAccordeon.push(
      {
        title: t('tasks.calendar'),
        element: (
          <>
            <Calendar
              tasks={tasks}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
            <div className="tasks-view__main__left-container__today-tasks">
              <Icon type='tape' className="tasks-view__main__left-container__today-tasks__icon" />
              {
                dayjs(currentDate).format('DD/MM/YYYY') !== dayjs(new Date()).format('DD/MM/YYYY') ? (
                  // eslint-disable-next-line max-len
                  <SubTitle subTitle={`${t('tasks.tasksTo')} ${dayjs(currentDate).format('DD MMM YYYY')} (${getTodayTasks().length})`} />
                ) : (
                  <SubTitle subTitle={`${t('tasks.todayTasks')} (${getTodayTasks().length})`} />
                )
              }
              {
                getTodayTasks().map((task: ReactElement) => (
                  task
                ))
              }
              {
                getTodayConcludedTasks().length > 0 && (
                  <div className='tasks-view__concluded-task'>
                    <div className='tasks-view__concluded-task__separator'></div>
                    <p className='tasks-view__concluded-task__text'>{t('tasks.concluded')}</p>
                    <div className='tasks-view__concluded-task__separator'></div>
                  </div>
                )
              }
              {
                getTodayConcludedTasks().map((task: ReactElement) => (
                  task
                ))
              }
              <p
                className='tasks-view__main__right-container__loading-label'
                onClick={getMoreItems}
              >
                {
                  selectedList === 'tasks' && tasksCompleteList.length > tasks.length && getTodayTasks().length + getTodayConcludedTasks().length > 0 && (
                    isPending ? <Spinner /> : t('tasks.showMore')
                  )
                }
              </p>
            </div>
          </>
        )
      }
    );

    setItemsAccordeon(auxItemsAccordeon);
  }, [
    userRole,
    tasks,
    selectedList,
    tasksCompleteList,
    isPending,
    currentDate,
    t
  ]);

  return (
    <div className='tasks-view'>
      <Header
        title={t('global.approverTitle')}
        showBackbutton={false}
      />
      <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
        <TaskDetail
          onClose={() => {
            setVisibleTaskDetailModal({
              taskID: '',
              visible: false
            });
            storage.set('lastTaskDetailID', '');
          }}
          setVisibleTaskDetailModal={setVisibleTaskDetailModal}
          visibleTaskDetailModal={visibleTaskDetailModal}
          lookInTheTrash={false}
          onDeletedTask={() => getTasks(userId)}
        />
        <ModalInspectionDetail
          type='custom'
          visible={visibleInspectionDetailModal.visible}
          customComponent={
            <>
              <Title title={`${t('tasks.inspection')}: ${inspection.number}`} />
              <InspectionDetail
                lookInTheTrash={false}
                setShowProductInformation={() => { }}
                dictumProduct={dictumProduct}
                constancyProduct={constancyProduct}
                revisionProduct={revisionProduct}
                getInspections={() => getInspections(userId)}
              />
            </>
          }
          onCancel={() => {
            setVisibleInspectionDetailModal({
              inspectionID: '',
              visible: false
            });
            storage.set('lastInspectionDetailID', '');
            setReFetchData(!reFetchData);
          }}
        />
      </Suspense>
      <div className="tasks-view__main">
        <Suspense fallback={<>{() => Modal.fireLoading()}</>}>
          <div className="tasks-view__main__left-container">
            <Accordeon items={itemsAccordeon} />
          </div>
        </Suspense>
        <div className="tasks-view__main__right-container">
          <div className="tasks-view__main__right-container__top-content">
            <div className='tasks-view__main__right-container__top-content__left-container'>
              <Title title={t('tasks.selectList')} type='secondary' />
              <Switch
                leftLabel={{
                  text: t('tasks.tasks'),
                  value: 'tasks'
                }}
                rigthLabel={{
                  text: t('tasks.inspections'),
                  value: 'inspections'
                }}
                defaultChecked={storage.get('selectedListOnApproverView') === 'tasks'}
                onChange={(_value: string | number) => {
                  if (selectedList === 'inspections') {
                    setSelectedList('tasks');
                    setQuantityElementsOnList(50);
                    storage.set('selectedListOnApproverView', 'tasks');
                  } else {
                    setSelectedList('inspections');
                    setQuantityElementsOnList(50);
                    storage.set('selectedListOnApproverView', 'inspections');
                  }
                }}
              />
            </div>
          </div>
          <div className="tasks-view__main__right-container__status-column-container">
            {
              selectedList === 'inspections' ? (
                <InspectionsList
                  items={inspections}
                  statusCounter={statusInspectionsCounter}
                />
              ) : (
                <TasksList
                  items={tasks}
                  statusCounter={statusTasksCounter}
                />
              )
            }
          </div>
          <p
            className='tasks-view__main__right-container__loading-label'
            onClick={getMoreItems}
          >
            {
              selectedList === 'inspections' && inspectionsCompleteList.length > inspections.length && (
                isPending ? <Spinner /> : t('tasks.showMore')
              )
            }
            {
              selectedList === 'tasks' && tasksCompleteList.length > tasks.length && (
                isPending ? <Spinner /> : t('tasks.showMore')
              )
            }
          </p>
        </div>
      </div>
    </div >
  );
};

export default Tasks;
