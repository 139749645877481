import React from 'react';
import { useTranslation } from 'react-i18next';

import { Outlet } from 'react-router-dom';

import {
  Header,
  Icon
} from '../../components';

import './styles.scss';

export const DictumRequest = () => {
  const { t } = useTranslation();

  return (
    <div className='dictum-request'>
      <Header
        title={t('services.dictumRequest') || ''}
        showBackbutton={true}
      />
      <Outlet />
      <Icon type='personDictum' className='dictum-request__icon' />
    </div>
  );
};

export default DictumRequest;
