import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../interfaces';

import './styles.scss';

interface Props {
  buttons: ActionButton[]
}

const ActionButtons: React.FC<Props> = (props) => {
  const {
    buttons
  } = props;

  const { t } = useTranslation();

  const [showEmptyActionsLabel, setShowEmptyActionsLabel] = useState<boolean>(false);
  const [ableButtons, setAbleButtons] = useState<ActionButton[]>([]);
  const [disabledButtons, setDisabledButtons] = useState<ActionButton[]>([]);

  useEffect(() => {
    setAbleButtons(buttons.filter((b: ActionButton) => b.available
      && !b.separator
      && (b.specialValidation === false || b.specialValidation === true
        ? b.specialValidation : true)));

    setDisabledButtons(buttons.filter((b: ActionButton) => !b.available
      && !b.separator
      && (b.specialValidation === false || b.specialValidation === true
        ? b.specialValidation : true)));
  }, [buttons]);

  useEffect(() => {
    setShowEmptyActionsLabel(ableButtons.length === 0 && disabledButtons.length === 0);
  }, [ableButtons, disabledButtons]);

  return (
    <div className='action-buttons' >
      {
        ableButtons.map((item: ActionButton, index: number) => (
          item.separator && index < ableButtons.length - 1 && index > 0 ? (
            <div className='action-buttons__separator' key={index} />
          ) : (
            <div key={index}>
              {item.button}
            </div>
          )
        ))
      }
      {
        disabledButtons && (
          <>
            {
              ableButtons.length > 0 && disabledButtons.length > 0 && (
                <div className='action-buttons__separator' />
              )
            }
            <div className='action-buttons__disabled'>
              {disabledButtons.map((item: ActionButton, index: number) => (
                item.separator && index < ableButtons.length - 1 && index > 0 ? (
                  <div className='action-buttons__separator' key={index} />
                ) : (
                  <div key={index}>
                    {item.button}
                  </div>
                )
              ))}
            </div>
          </>
        )
      }
      {
        showEmptyActionsLabel && (
          <p className='action-buttons__empty-label'>{t('global.emptyActions')}</p>
        )
      }
    </div>
  );
};

export default ActionButtons;
