import React, { useId, useState } from 'react';

import { RadioButton } from '../../interfaces';

import './styles.scss';

interface Props {
  options: RadioButton[]
  value: string | number
  onChange: (_value: string | number) => void
  orientation?: 'vertical' | 'horizontal'
  id?: string
  disabled?: boolean
}

const RadioList: React.FC<Props> = (props: Props) => {
  const {
    options,
    value,
    onChange,
    orientation = 'horizontal',
    id = useId(),
    disabled = false
  } = props;

  const [localValue, setLocalValue] = useState<string | number>(options[0].value);

  const showChecked = (buttonValue: string | number) => {
    if (value) {
      if (value === buttonValue) {
        return (<div className='radio-list__input__checked radio-list__input__checked--true'></div>);
      }
    } else if (localValue === buttonValue) {
      return (<div className='radio-list__input__checked radio-list__input__checked--true'></div>);
    }

    return (<></>);
  };

  return (
    <div className={`radio-list radio-list--${orientation}`} id={id}>
      {
        options.map((button: RadioButton, index: number) => (
          <label
            key={index}
            className={`radio-list__option radio-list__option--${orientation}`}
            onClick={() => {
              if (disabled) {
                () => { };
              } else {
                // eslint-disable-next-line no-lonely-if
                if (value) {
                  onChange(button.value);
                } else {
                  onChange(button.value);
                  setLocalValue(button.value);
                }
              }
            }}
          >
            <div className='radio-list__input'>
              {showChecked(button.value)}
            </div>
            <p className="radio-list__label">{button.label}</p>
          </label>
        ))
      }
    </div>
  );
};

export default RadioList;
