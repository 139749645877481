import ai from './icons/ai-file.svg';
import alertBullet from './icons/alert-bullet.svg';
import alertRed from './icons/alert-red.svg';
import arroba from './icons/arroba.svg';
import backButton from './icons/back-button.svg';
import bionYkLogo from './icons/bionYk-logo.svg';
import bulletBlue from './icons/bullet-blue.svg';
import bulletLeftTop from './icons/bullet-left-top.svg';
import bulletRightBottom from './icons/bullet-right-bottom.svg';
import bulletYellow from './icons/bullet-yellow.svg';
import calendar from './icons/calendar.svg';
import simpleCalendar from './icons/simple-calendar.svg';
import CCIcon from './icons/CC.svg';
import CECSAlogo from './icons/CECSA-logo.svg';
import CECSALogoColor from './icons/CECSA-logo-color.svg';
import checkMarkWhite from './icons/check-mark-white.svg';
import clientsLogo from './icons/clients-logo.svg';
import clientsLogoGrey from './icons/clients-logo-grey.svg';
import close from './icons/close.svg';
import closed from './icons/closed.svg';
import constancyFile from './icons/constancy-file.svg';
import csv from './icons/csv-file.svg';
import DCIcon from './icons/DC.svg';
import defaultFile from './icons/default-file.svg';
import deleteFile from './icons/delete-file.svg';
import dictumFile from './icons/dictum-file.svg';
import docx from './icons/docx-file.svg';
import doorWhite from './icons/door-white.svg';
import downArrow from './icons/down-arrow.svg';
import download from './icons/download.svg';
import emptyState from './icons/empty-state.svg';
import error404 from './icons/error-404.svg';
import error from './icons/error-icon.svg';
import fileWhite from './icons/file-white.svg';
import invoice from './icons/invoice.svg';
import hamburguerButton from './icons/hamburguer-button.svg';
import inProgress from './icons/in-progress.svg';
import inspectorWhite from './icons/inspector-white.svg';
import commercialInvoiceFile from './icons/invoice-file.svg';
import invoiceWhite from './icons/invoice-white.svg';
import thickerLeftArrow from './icons/thicker-left-arrow.svg';
import magnifyingGlass from './icons/magnifying-glass.svg';
import thickerRightArrow from './icons/thicker-right-arrow.svg';
import jpeg from './icons/jpeg-file.svg';
import jpg from './icons/jpg-file.svg';
import leftArrow from './icons/left-arrow.svg';
import plusRounded from './icons/plusRounded.svg';
import onSiteVisit from './icons/on-site-visit.svg';
import onSiteVisitWhite from './icons/on-site-visit-white.svg';
import openFolder from './icons/open-folder.svg';
import padlock from './icons/padlock.svg';
import paidPetitionFile from './icons/paid-petition-file.svg';
import pdf from './icons/pdf-file.svg';
import personConstancy from './icons/person-constancy.svg';
import personDictum from './icons/person-dictum.svg';
import petitionFile from './icons/petition-file.svg';
import minusRounded from './icons/minusRounded.svg';
import png from './icons/png-file.svg';
import rectifiedPetitionFile from './icons/rectified-petition-file.svg';
import rightArrow from './icons/right-arrow.svg';
import SELogo from './icons/SELogo.svg';
import SELogoDark from './icons/SELogo-dark.svg';
import serviceFile from './icons/service-file.svg';
import servicesIcon from './icons/services-icon.svg';
import stain from './icons/stain.svg';
import success from './icons/success-icon.svg';
import toExpire from './icons/to-expire.svg';
import upArrow from './icons/up-arrow.svg';
import upload from './icons/upload.svg';
import userWhite from './icons/user-white.svg';
import usersLogo from './icons/users-logo.svg';
import warning from './icons/warning-icon.svg';
import xlsx from './icons/xlsx-file.svg';
import check from './icons/check.svg';
import folder from './icons/folder.svg';
import folderInactive from './icons/folder-inactive.svg';
import crossMark from './icons/cross-mark.svg';
import editFile from './icons/edit-file.svg';
import paperShredder from './icons/paper-shredder.svg';
import sendIcon from './icons/send-icon.svg';
import cancelIcon from './icons/cancel-icon.svg';
import validInvoices from './icons/mark-valid-invoices.svg';
import cancelInvoices from './icons/mark-cancel-invoices.svg';
import plusWhite from './icons/plus-white.svg';
import editService from './icons/edit-service.svg';
import add from './icons/add.svg';
import edit from './icons/edit.svg';
import signature from './icons/signature.svg';
import changePassword from './icons/change-password.svg';
import picture from './icons/picture.svg';
import userGrey from './icons/user-grey.svg';
import downloadWhite from './icons/download-white.svg';
import closeWhite from './icons/close-white.svg';
import checkList from './icons/check-list.svg';
import inspectorGrey from './icons/inspector-grey.svg';
import reviews from './icons/reviews.svg';
import inspector from './icons/inspector.svg';
import leftArrowCalendar from './icons/left-arrow-calendar.svg';
import rightArrowCalendar from './icons/right-arrow-calendar.svg';
import tape from './icons/tape.svg';
import options from './icons/options.svg';
import simpleDocument from './icons/simple-document.svg';
import inspectionList from './icons/inspection-lists.svg';
import mapDot from './icons/map-dot.svg';
import clock from './icons/clock.svg';
import history from './icons/history.svg';
import trash from './icons/trash.svg';
import taskDocument from './icons/task-document.svg';
import calendarMinimal from './icons/calendar-minimal.svg';
import doubleTasks from './icons/double-tasks.svg';
import circleError from './icons/circle-error.svg';
import circleSucces from './icons/circle-success.svg';
import circleUnconcluded from './icons/circle-unconcluded.svg';
import onSite from './icons/on-site.svg';
import remote from './icons/remote.svg';
import like from './icons/like.svg';
import dislike from './icons/dislike.svg';
import agree from './icons/agree.svg';
import disagree from './icons/disagree.svg';
import folderOpen from './icons/folder-open.svg';
import folderInactiveOpen from './icons/folder-inactive-open.svg';
import roundNotComplies from './icons/round-not-complies.svg';
import roundInProgress from './icons/round-in-progress.svg';
import roundComplies from './icons/round-complies.svg';
import taskStatus from './icons/task-status.svg';
import needAproval from './icons/need-aproval.svg';
import inReview from './icons/review-icon.svg';
import send from './icons/send.svg';
import zip from './icons/zip.svg';
import home from './icons/home.svg';
import filter from './icons/filter.svg';
import filterActive from './icons/filter-active.svg';
import order from './icons/order.svg';
import DESC from './icons/DESC.svg';
import ASC from './icons/ASC.svg';
import resetFilter from './icons/reset-filter.svg';
import clockDetailed from './icons/clock-detailed.svg';
import checkIn from './icons/check-in.svg';
import rightArrowBlack from './icons/right-arrow-black.svg';
import leftArrowBlack from './icons/left-arrow-black.svg';
import updateProduct from './icons/update-product.svg';
import feedback from './icons/feedback.svg';
import darkMode from './icons/dark-mode.svg';
import lightMode from './icons/light-mode.svg';
import languaje from './icons/languaje.svg';
import share from './icons/share-light.svg';
import notification from './icons/notifications.svg';
import sound from './icons/sound.svg';
import notSound from './icons/notSound.svg';
import camera from './icons/camera.svg';
import chart from './icons/chart-646.svg';
import cameraSwitch from './icons/cameraSwitch.svg';
import gift from './icons/gift.svg';
import santaclaus from './icons/santaclaus.svg';
import christmasTree from './icons/christmas_tree.svg';
import mexican from './icons/mexican.svg';
import mexicoFlag from './icons/mexico-flag.svg';
import agave from './icons/agave.svg';
import blackCat from './icons/black_cat.svg';
import pumpkin from './icons/pumpkin.svg';
import ghost from './icons/ghost.svg';
import cupidBow from './icons/cupid_bow.svg';
import heart from './icons/heart.svg';
import romanticLetter from './icons/romantic_letter.svg';
import help from './icons/help.svg';
import creditCard from './icons/creditCard.svg';
import revision from './icons/rev.svg';
import REV from './icons/rev-service.svg';
import bug from './icons/bug.svg';
import expand from './icons/expand.svg';
import monthlyClosings from './icons/monthlyClosings.svg';
import outOfTime from './icons/out_of_time.svg';
import quality from './icons/quality.svg';
import downArrowWhite from './icons/downArrowWhite.svg';
import correctiveActions from './icons/correctiveActions.svg';
import addWhite from './icons/addWhite.svg';
import reports from './icons/reports.svg';
import work from './icons/work.svg';
import ranking from './icons/ranking.svg';
import charts from './icons/charts.svg';
import csvReports from './icons/csvReports.svg';
import csvReportsGrey from './icons/csvReportsGrey.svg';
import gold from './icons/gold_medal.svg';
import silver from './icons/silver_medal.svg';
import cooper from './icons/cooper_medal.svg';
import sendEmail from './icons/sendEmail.svg';
import rotateLeft from './icons/rotate-left.svg';
import rotateRigth from './icons/rotate-rigth.svg';
import zoomIn from './icons/zoom-in.svg';
import zoomOut from './icons/zoom-out.svg';
import internalAudit from './icons/internal-audit.svg';
import search from './icons/search.svg';
import tour from './icons/tour.svg';
import trainingRecord from './icons/training-record.svg';
import backup from './icons/backup.svg';

export type IconTypes =
  'ai'
  | 'alertBullet'
  | 'alertRed'
  | 'arroba'
  | 'backButton'
  | 'bionYkLogo'
  | 'bulletBlue'
  | 'bulletLeftTop'
  | 'bulletRightBottom'
  | 'bulletYellow'
  | 'calendar'
  | 'CCIcon'
  | 'CECSAlogo'
  | 'checkMarkWhite'
  | 'clientsLogo'
  | 'close'
  | 'closed'
  | 'constancyFile'
  | 'doorWhite'
  | 'downArrow'
  | 'fileWhite'
  | 'invoice'
  | 'hamburguerButton'
  | 'inProgress'
  | 'inspectorWhite'
  | 'commercialInvoiceFile'
  | 'invoiceWhite'
  | 'onSiteVisit'
  | 'onSiteVisitWhite'
  | 'paidPetitionFile'
  | 'thickerLeftArrow'
  | 'magnifyingGlass'
  | 'thickerRightArrow'
  | 'toExpire'
  | 'upArrow'
  | 'upload'
  | 'userWhite'
  | 'usersLogo'
  | 'csv'
  | 'DCIcon'
  | 'defaultFile'
  | 'deleteFile'
  | 'dictumFile'
  | 'docx'
  | 'download'
  | 'emptyState'
  | 'error404'
  | 'error'
  | 'jpeg'
  | 'jpg'
  | 'leftArrow'
  | 'plusRounded'
  | 'pdf'
  | 'personConstancy'
  | 'personDictum'
  | 'petitionFile'
  | 'minusRounded'
  | 'png'
  | 'rectifiedPetitionFile'
  | 'rightArrow'
  | 'SELogo'
  | 'serviceFile'
  | 'servicesIcon'
  | 'stain'
  | 'success'
  | 'toExpire'
  | 'upload'
  | 'warning'
  | 'xlsx'
  | 'upload'
  | 'check'
  | 'folder'
  | 'folderInactive'
  | 'openFolder'
  | 'padlock'
  | 'editFile'
  | 'paperShredder'
  | 'crossMark'
  | 'sendIcon'
  | 'cancelIcon'
  | 'validInvoices'
  | 'plusWhite'
  | 'cancelInvoices'
  | 'cancelInvoices'
  | 'editService'
  | 'add'
  | 'edit'
  | 'signature'
  | 'changePassword'
  | 'picture'
  | 'downloadWhite'
  | 'userGrey'
  | 'closeWhite'
  | 'userGrey'
  | 'checkList'
  | 'inspectorGrey'
  | 'reviews'
  | 'inspector'
  | 'leftArrowCalendar'
  | 'rightArrowCalendar'
  | 'tape'
  | 'options'
  | 'simpleDocument'
  | 'inspectionList'
  | 'clientsLogoGrey'
  | 'mapDot'
  | 'clock'
  | 'simpleCalendar'
  | 'history'
  | 'trash'
  | 'taskDocument'
  | 'calendarMinimal'
  | 'doubleTasks'
  | 'circleError'
  | 'circleSucces'
  | 'onSite'
  | 'remote'
  | 'inspector'
  | 'like'
  | 'dislike'
  | 'userGrey'
  | 'userGrey'
  | 'agree'
  | 'disagree'
  | 'CECSALogoColor'
  | 'folderOpen'
  | 'folderInactiveOpen'
  | 'roundNotComplies'
  | 'roundComplies'
  | 'circleUnconcluded'
  | 'taskStatus'
  | 'needAproval'
  | 'inReview'
  | 'send'
  | 'zip'
  | 'home'
  | 'filter'
  | 'filterActive'
  | 'order'
  | 'ASC'
  | 'DESC'
  | 'resetFilter'
  | 'clockDetailed'
  | 'checkIn'
  | 'rightArrowBlack'
  | 'leftArrowBlack'
  | 'updateProduct'
  | 'feedback'
  | 'darkMode'
  | 'lightMode'
  | 'SELogoDark'
  | 'languaje'
  | 'share'
  | 'notification'
  | 'sound'
  | 'notSound'
  | 'camera'
  | 'chart'
  | 'cameraSwitch'
  | 'gift'
  | 'santaclaus'
  | 'christmasTree'
  | 'mexican'
  | 'mexicoFlag'
  | 'agave'
  | 'blackCat'
  | 'pumpkin'
  | 'ghost'
  | 'cupidBow'
  | 'heart'
  | 'romanticLetter'
  | 'help'
  | 'roundInProgress'
  | 'creditCard'
  | 'revision'
  | 'REV'
  | 'bug'
  | 'expand'
  | 'monthlyClosings'
  | 'outOfTime'
  | 'quality'
  | 'downArrowWhite'
  | 'correctiveActions'
  | 'addWhite'
  | 'reports'
  | 'work'
  | 'ranking'
  | 'charts'
  | 'csvReports'
  | 'gold'
  | 'silver'
  | 'cooper'
  | 'csvReportsGrey'
  | 'sendEmail'
  | 'rotateLeft'
  | 'rotateRigth'
  | 'zoomIn'
  | 'zoomOut'
  | 'internalAudit'
  | 'search'
  | 'tour'
  | 'trainingRecord'
  | 'backup';

export default {
  ai,
  alertBullet,
  alertRed,
  arroba,
  backButton,
  bionYkLogo,
  bulletBlue,
  bulletLeftTop,
  bulletRightBottom,
  bulletYellow,
  calendar,
  CCIcon,
  CECSAlogo,
  checkMarkWhite,
  clientsLogo,
  close,
  closed,
  constancyFile,
  doorWhite,
  downArrow,
  fileWhite,
  invoice,
  hamburguerButton,
  inProgress,
  inspectorWhite,
  commercialInvoiceFile,
  invoiceWhite,
  onSiteVisit,
  onSiteVisitWhite,
  paidPetitionFile,
  thickerLeftArrow,
  magnifyingGlass,
  thickerRightArrow,
  upArrow,
  userWhite,
  usersLogo,
  csv,
  DCIcon,
  defaultFile,
  deleteFile,
  dictumFile,
  docx,
  download,
  emptyState,
  error404,
  error,
  jpeg,
  jpg,
  leftArrow,
  plusRounded,
  pdf,
  personConstancy,
  personDictum,
  petitionFile,
  minusRounded,
  png,
  rectifiedPetitionFile,
  rightArrow,
  SELogo,
  serviceFile,
  servicesIcon,
  stain,
  success,
  toExpire,
  upload,
  xlsx,
  warning,
  check,
  folder,
  folderInactive,
  openFolder,
  padlock,
  editFile,
  paperShredder,
  crossMark,
  sendIcon,
  cancelIcon,
  validInvoices,
  cancelInvoices,
  plusWhite,
  editService,
  add,
  edit,
  signature,
  changePassword,
  picture,
  userGrey,
  downloadWhite,
  closeWhite,
  checkList,
  inspectorGrey,
  reviews,
  inspector,
  leftArrowCalendar,
  rightArrowCalendar,
  tape,
  options,
  simpleDocument,
  inspectionList,
  clientsLogoGrey,
  mapDot,
  clock,
  simpleCalendar,
  history,
  trash,
  taskDocument,
  calendarMinimal,
  doubleTasks,
  circleError,
  circleSucces,
  onSite,
  like,
  dislike,
  remote,
  agree,
  disagree,
  CECSALogoColor,
  folderOpen,
  folderInactiveOpen,
  roundNotComplies,
  roundComplies,
  circleUnconcluded,
  taskStatus,
  needAproval,
  inReview,
  send,
  zip,
  home,
  filterActive,
  filter,
  order,
  ASC,
  DESC,
  resetFilter,
  clockDetailed,
  checkIn,
  rightArrowBlack,
  leftArrowBlack,
  updateProduct,
  feedback,
  darkMode,
  lightMode,
  SELogoDark,
  languaje,
  share,
  notification,
  sound,
  notSound,
  camera,
  chart,
  cameraSwitch,
  gift,
  santaclaus,
  christmasTree,
  mexican,
  mexicoFlag,
  agave,
  blackCat,
  pumpkin,
  ghost,
  cupidBow,
  heart,
  romanticLetter,
  help,
  roundInProgress,
  creditCard,
  revision,
  REV,
  bug,
  expand,
  monthlyClosings,
  outOfTime,
  quality,
  downArrowWhite,
  correctiveActions,
  addWhite,
  reports,
  work,
  ranking,
  charts,
  csvReports,
  gold,
  silver,
  cooper,
  csvReportsGrey,
  sendEmail,
  rotateLeft,
  rotateRigth,
  zoomIn,
  zoomOut,
  internalAudit,
  search,
  tour,
  trainingRecord,
  backup
};
