import React, { Suspense, useState } from 'react';

import {
  Spinner,
  Icon,
  Separator,
  Title
} from '..';
import { Item } from '../../interfaces';

import './styles.scss';

interface Props {
  items: Item[]
  alwaysOnFunctionality?: boolean
}

const Accordeon: React.FC<Props> = (props) => {
  const {
    items,
    alwaysOnFunctionality = false
  } = props;

  const [seletedTab, setSeletedTab] = useState<number>(-1);

  const handleSelectTab = (index: number) => {
    if (seletedTab !== index) {
      setSeletedTab(index);
    } else {
      setSeletedTab(-1);
    }
  };

  return (
    <div className='accordeon'>
      <Suspense fallback={<Spinner />}>
        {
          items.map((item: Item, index: number) => (
            <div key={index} id={item.id || `${index}`}>
              <div className='accordeon__item' >
                <div
                  className={`accordeon__item__title accordeon__item__title--${alwaysOnFunctionality ? 'active' : ''}`}
                  onClick={() => handleSelectTab(index)}
                >
                  <Title title={item.title.toLocaleUpperCase()} type='secondary' fontSize={2} />
                  <Icon
                    type='downArrow'
                    className={`accordeon__item__title__arrow
                    accordeon__item__title__arrow--${alwaysOnFunctionality ? 'visible' : ''}
                    accordeon__item__title__arrow--${seletedTab === index ? 'selected' : ''}
                    `}
                    onClick={() => handleSelectTab(index)}
                  />
                </div>
                <div
                  className={`accordeon__item__element
                  accordeon__item__element--${alwaysOnFunctionality ? 'active' : ''}
                  accordeon__item__element--${seletedTab === index ? 'open' : ''}
                  `}
                >
                  {item.element}
                </div>
              </div>
              {
                index < items.length - 1 && (
                  <Separator orientation='horizontal' />
                )
              }
            </div>
          ))
        }
      </Suspense >
    </div >
  );
};

export default Accordeon;
