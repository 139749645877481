import React, { ReactElement } from 'react';

import ReactDOM from 'react-dom';

import { Icon } from '../../..';
import { useKeyPress } from '../../../../hooks';

import './styles.scss';

export type ModalComponentTypes = 'success' | 'error' | 'warning' | 'loading' | 'custom';

export interface ModalComponentProps {
  type?: ModalComponentTypes
  visible?: boolean
  customComponent?: ReactElement | null
  onCancel?: (() => void)
}

export const ModalTaskDetail: React.FC<ModalComponentProps> = (props) => {
  const {
    type = 'success',
    visible = false,
    customComponent = <></>,
    onCancel = () => { }
  } = props;

  const noActions: boolean = type === 'loading';

  const getHeader = (): ReactElement => {
    if (type === 'loading') {
      return (
        <></>
      );
    }

    return (
      <div className='task-detail-modal__header-container'>
        <Icon type='close' onClick={onCancel} className='task-detail-modal__header-container__close-icon' />
      </div>
    );
  };

  useKeyPress(
    () => {
      if (type !== 'loading' && !noActions) {
        onCancel();
      }
    },
    [
      type,
      visible,
      customComponent,
      onCancel
    ],
    visible,
    'Escape'
  );

  return ReactDOM.createPortal(
    visible && (
      <div className='task-detail-modal task-detail-modal--visible' id="task-detail-modal-component" >
        <div onClick={noActions ? () => { } : onCancel} className='task-detail-modal__modal-bg' />
        <div className='task-detail-modal__modal-content task-detail-modal__modal-content--fullSize' >
          {getHeader()}
          <div className='task-detail-modal__custom-component'>
            {customComponent}
          </div>
        </div>
      </div >
    ),
    // eslint-disable-next-line no-undef
    document.getElementById(type === 'custom' ? 'modal-view' : 'modal') as HTMLElement
  );
};
